export const SAMPLE_ACTIONS = {
	SET: "set_samples",
	FETCH: "fetch_samples",
	FETCH_COMPLETE: 'fetch_samples_complete',
	UPLOAD: 'upload_samples',
	UPLOAD_COMPLETE: 'upload_samples_complete'
}

export const STAT_ACTIONS = {
	SET: "set_stats",
	UPDATE: "update_stats",
	SWAP: 'swap_stats',
	UPDATE_REPORT: 'update_report',
	UPDATE_SEQUENCE: 'update_sequence',
	MOVE_DN: 'movedown',
	MOVE_UP: 'moveup',
	VISIBILITY: 'visibility'
}