import { createSlice } from "@reduxjs/toolkit";

export const EditorSlice = createSlice({
	name: "editor",
	initialState: {
		reorder: false,
		preview: false,
		duplicates: true,
		title: "",
		changesSaved: true,
		trends: null,
		studyType: "",
	},

	reducers: {
		setReorder: (state, action) => {
			state.reorder = action.payload;
		},
		setPreview: (state, action) => {
			state.preview = action.payload;
		},
		setDuplicates: (state, action) => {
			state.duplicates = action.payload;
		},
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setChangesSaved: (state, action) => {
			state.changesSaved = action.payload;
		},
		setTrends: (state, action) => {
			state.trends = action.payload;
		},
		setStudyType: (state, action) => {
			state.studyType = action.payload;
		},
	}
});

export const {
	setReorder,
	setPreview,
	setDuplicates,
	setTitle,
	setChangesSaved,
	setTrends,
	setStudyType,
} = EditorSlice.actions;
export const editor = EditorSlice.reducer;