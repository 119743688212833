import {
	resultsFetch,
	resultsFetchSuccess,
	resultsFetchFailed,
} from "../reducers/search.reducer";
import { createRequestSagas } from '../helpers/saga.helpers';
import {
	searchProjectSampleSets,
	mockSearchSampleSets,
	isMock,
} from "../../api";

export const {
	searchSaga,
	searchSagas,
} = createRequestSagas("search",
	isMock ? mockSearchSampleSets : searchProjectSampleSets,
	resultsFetch,
	resultsFetchSuccess,
	resultsFetchFailed,
);