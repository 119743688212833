import React, { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux";
import MetaboliteTable from "@/containers/MetaboliteTable.jsx";
import Icon from "@/components/controls/Icon.jsx";
import { selectPreview, selectStudyType, selectTitle, selectTrends } from "@/hgt/redux/selectors/editor.selector.js";
import { selectStats } from "@/hgt/redux/selectors/stats.selector.js";
import { clone } from "@/utils/ObjectUtils.js";
import { selectMetabolites } from "@/hgt/redux/selectors/project.selector.js";
import { processStatDetails } from "../../../utils/MetaboliteUtils.js";

export const PreviewTable = ({ activeSampleSet, showHidden, sortedStats, testField }) => {
	const title = useSelector(selectTitle);
	const preview = useSelector(selectPreview);
	const trends = useSelector(selectTrends);
	const studyType = useSelector(selectStudyType);
	const [metabolites, setMetabolites] = useState([]);
	const selectedStatsDict = useSelector(selectStats);
	const statsDict = useMemo(() => clone(selectedStatsDict), [selectedStatsDict]);
	const { isLoading: metabolitesIsLoading, complete: metabolitesComplete, data: selectedSampleMetabolites } = useSelector(selectMetabolites);

	useEffect(() => {
		if (!selectedSampleMetabolites) return;
		let sampleMetabolites = clone(selectedSampleMetabolites);
		sampleMetabolites = processStatDetails(sampleMetabolites, selectedStatsDict, testField, clone(trends));
		setMetabolites(clone(sampleMetabolites));
	}, [selectedSampleMetabolites, selectedStatsDict, trends]);

	return (
		<div className="metabolite-table-container" data-testid="metabolite-table-container">
			{preview && statsDict &&
				<>
					<h3>Preview Table</h3>
					<div className="project-title">
						<h2>{title || activeSampleSet.sample.name}</h2>
					</div>
					{metabolitesIsLoading &&
						<Icon className="ml-2 fa-spin" icon="spinner" size="large" />
					}
					{metabolitesComplete &&
						<MetaboliteTable
							canExport={false}
							className={"pathway-viz " + studyType}
							studyType={studyType}
							metabolites={metabolites}
							showHidden={showHidden}
							stats={statsDict}
							activeStats={sortedStats}
						/>
					}
				</>
			}
		</div>
	)
}