import { createSelector } from '@reduxjs/toolkit';

export const authSelector = createSelector(
	(state) => state,
	(state) => state.auth
);

export const isAuthenticatedSelector = createSelector(
	authSelector,
	(auth) => auth?.isAuthenticated
);
