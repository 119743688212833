import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Material, SearchResult } from '../index.jsx';
import { getProjectItems } from "@/views/home/myprojects/MyProjects.jsx";
import './SearchBox.scss';
import { useSelector } from 'react-redux';
import { projectsDiscoveryPanelsSelector } from '@/redux/selectors/projects.selector.js';
import { searchResultsSelector } from '@/redux/selectors/searchResults.selector.js';
import { scrubHTML } from '@/utils/StringUtils';

const getStyles = (disabled, value) => {
	const nonEmpty = value.length > 0;

	return {

		iconButtonClose: {
			style: {
				transform: nonEmpty ? 'scale(1, 1)' : 'scale(0, 0)',
			}
		},
		iconButtonSearch: {
			style: {
				opacity: !disabled ? 0.54 : 0.38,
				transform: nonEmpty ? 'scale(0, 0)' : 'scale(1, 1)',
			}
		}
	};
};

export const ProjectSearchBox = (props) => {
	const [focus, setFocus] = useState(true);
	const [active, setActive] = useState(false);
	const inputText = useRef(null);
	const [value, setValue] = useState(props.value);
	const [dataSource, setDataSource] = useState([]);

	const { data, searchId, searchResultsIsLoading: isLoading } = useSelector(searchResultsSelector);
	const discoveryPanels = useSelector(projectsDiscoveryPanelsSelector);


	useEffect(() => {
		const projectResults = data?.filter((res) => res.type === 'Project')
			.map((result) => ({
				...result,
				detail: scrubHTML(result.detail)
			}));
		setDataSource(projectResults);
	}, [data]);

	useEffect(() => {
		if (props.clearText) onClickCancel();
	}, [props.clearText]);

	const handleInput = useCallback((e) => {
		setValue(e);
		if (props.onChange) props.onChange(e);
	}, [props.onChange]);

	const handleCancel = useCallback((e) => {
		setValue('');
		setActive(false);
		if (props.onKeyUp) props.onKeyUp('');
		setDataSource([]);
		inputText.current.value = '';
		if (props.handleCancel) props.handleCancel('');
	}, [props.handleCancel, props.onKeyUp]);

	const handleKeyDown = useCallback(() => {
		 
		// console.log(e.key);
	}, []);

	const onChange = useCallback((e) => {
		handleInput(e.target.value);
	}, [handleInput]);

	const onKeyPress = (e) => {
		// handleKeyPressed(e);
	};

	const onClickCancel = useCallback(() => {
		handleCancel();
	}, [handleCancel]);

	const onBlur = useCallback(() => {
		setFocus(false);
	}, []);

	const onFocus = useCallback(() => {
		setFocus(true);
		inputText.current.focus();
	}, []);

	const showResults = useMemo(() =>
		props.showResults && searchId === props.id && !isLoading, [props.id, props.showResults, searchId, isLoading]);

	const {
		disabled,
		onRequestSearch,
	} = props;
	const styles = getStyles(disabled, value);

	let inputClasses = ['searchContainer'];
	if (!value.length) inputClasses.push('empty');
	if (focus) inputClasses.push('focused');
	inputClasses = inputClasses.join(' ');

	return (
		<div id={props.id} className={inputClasses} onBlur={onBlur} onClick={onFocus} >
			<div className={"searchInput"}>
				<input
					 
					ref={inputText}
					placeholder={props.hintText}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					onKeyUp={props.onKeyUp} />

			</div>
			<div className={"icon-btn search" + (disabled ? ' disabled' : '')} onClick={onRequestSearch} style={styles.iconButtonSearch.style}>
				<Material icon={'search'} size="large" color={focus ? "tertiary" : props.iconColor} />
			</div>

			<div className="icon-btn close" onClick={onClickCancel} style={styles.iconButtonClose.style}>
				<Material icon={'close'} size="large" color="tertiary" />
			</div>

			<SearchResult showResults={showResults}>
				{getProjectItems(dataSource, discoveryPanels, props.mapData)}
			</SearchResult>

		</div>
	);
};

ProjectSearchBox.defaultProps = {
	dataSource: [],
	discoveryPanels: [],
	dataSourceConfig: { text: 'text', value: 'value' },
	disabled: false,
	hintText: 'Search',
	spellCheck: false,
	value: '',
	iconColor: 'white',
	onKeyUp: () => { }
};

ProjectSearchBox.propTypes = {
	/** Array of strings or nodes used to populate the list. */
	dataSource: PropTypes.array,
	/** Array of discover panel project ids. */
	discoveryPanels: PropTypes.array,
	/** Config for objects list dataSource. */
	dataSourceConfig: PropTypes.object,
	/** Disables text field. */
	disabled: PropTypes.bool,
	/** Sets hintText for the embedded text field. */
	hintText: PropTypes.string,
	/** Fired when the text value changes. */
	onChange: PropTypes.func.isRequired,
	/** Fired when the search icon is clicked. */
	onRequestSearch: PropTypes.func,
	/**mappingData */
	mapData: PropTypes.object.isRequired
};