import React, { useCallback } from "react";
import Select from "@/components/controls/Select.jsx";

export default function SampleSetDropdown({
	activeSampleSet,
	project,
	onSampleSetSelect,
}) {
	const getSelectKey = useCallback((option) => option.sampleSetName, []);

	return (
		<Select
			className="pathway-viz"
			color="primary"
			selection={project.sampleSets.find(
				(s) => s.sampleSetId === activeSampleSet.sampleSetId
			)}
			options={project.sampleSets}
			optionClass="alt2 primary"
			displayKey={getSelectKey}
			onSelect={onSampleSetSelect}
			alt2={true}
		/>
	);
}
