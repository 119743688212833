import React, { useCallback, useMemo } from "react";
import Button from "@/components/buttons/Button.jsx";
import Icon from "@/components/controls/Icon.jsx";
import Table from "../components/controls/Table/Table.jsx";
import { STAT_ACTIONS } from "../redux/actionTypes";
import  "./TestEditorTable.scss";

export default function TestEditorTable({ tests, onFieldAction }) {

	const onUpClick = useCallback((name) => () => {
		onFieldAction(STAT_ACTIONS.MOVE_UP, { name });
	}, [onFieldAction]);

	const onDownClick = useCallback((name) => () => {
		onFieldAction(STAT_ACTIONS.MOVE_DN, { name });
	}, [onFieldAction]);

  const columns = useMemo(
    () => [
      {
        header: "Test Name",
        id: "test",
        size: 350,
        accessorKey: "test",
      },
      {
        header: "Order",
        id: "order",
        accessorKey: "order",
        size: 170,
        cell: ({ row }) => {
          const {name, order} = row.original;
          return <div className="something">
            <Button
              onClick={onUpClick(name)}
              title="Move up"
              color="tertiary"
              alt2={true}
            >
              <Icon icon="arrow-up" />
            </Button>
            <span>{order}</span>
            <Button
              onClick={onDownClick(name)}
              title="Move down"
              color="tertiary"
              alt2={true}
            >
              <Icon icon="arrow-down" />
            </Button>
          </div>;
        },
      },
    ],
    [tests]
  );
  return (
    <Table
      columns={columns}
      data={tests}
      className={"test-editor-table"}
      tableId={"test-editor-table"}
      sortable
    />
  );
}
