import React, { useCallback, useEffect, useRef, useState } from "react";
import TableHeader from "./TableHeader.jsx";
import PropTypes from "prop-types";
import Button from "@/components/buttons/Button.jsx";
import {
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import TableBody from "./TableBody.jsx";
import TableFooter from "./TableFooter.jsx";
export default function Table(props) {
	const {
		data,
		columns,
		footer,
		className,
		resetButton,
		title,
		virtualize,
		draggable,
	} = props;

	const tableContainerRef = useRef(null);
	const [columnOrder, setColumnOrder] = useState(
		columns.map((column) => column.id)
	);
	useEffect(() => {
		if (!draggable) {
			setColumnOrder(columns.map((column) => column.id));
		}
	}, [columns]);

	const resetOrder = useCallback(() => setColumnOrder(columns.map((column) => column.id)), []);
	const [sorting, setSorting] = useState([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			columnOrder,
			sorting,
		},
		columnResizeMode: "onEnd",
		defaultColumn: { width: "20px" },
		onSortingChange: setSorting,
		onColumnOrderChange: setColumnOrder,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		meta: {
			getRowStyles: (row) => ({
				color: row.original.customerVisible === false ? "#d3dfe7" : "black",
			}),
		},
	});

	const isColumnResizing =
		table.options.state.columnSizingInfo.isResizingColumn;

	return (
		<div className="table-wrapper">
			<div className="title-and-reset-wrapper">
				<div>{title}</div>
				<div>
					{resetButton && (
						<Button onClick={resetOrder}>Reset Order and Size</Button>
					)}
				</div>
			</div>
			<div
				ref={tableContainerRef}
				className={`table ${className}`}
				id="table"
				data-testid="table"
			>
				<table cellSpacing="0">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr
								key={headerGroup.id}
								style={{
									position: "relative",
								}}
							>
								{headerGroup.headers.map((header, n) => (
									<TableHeader
										key={header.id}
										header={header}
										table={table}
										index={n}
										{...props}
									/>
								))}
							</tr>
						))}
					</thead>
					{
						<TableBody
							tableRef={tableContainerRef}
							table={table}
							isColumnResizing={isColumnResizing}
							virtualize={virtualize}
						/>
					}
					{footer && <TableFooter table={table} />}
				</table>
			</div>
		</div>
	);
}

Table.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	//Table id needed for storing settings to localstorage
	tableId: PropTypes.string.isRequired,
	className: PropTypes.string,
	title: PropTypes.element,
	footer: PropTypes.bool,
	//storeOpts should change of column order and size be persist
	storeOpts: PropTypes.bool,
	resetButton: PropTypes.bool,
	sortable: PropTypes.bool,
	resizable: PropTypes.bool,
	draggable: PropTypes.bool,
	virtualize: PropTypes.bool,
};
