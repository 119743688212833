import { createSelector } from '@reduxjs/toolkit';
import { prefixCamelCase } from '@/utils/StringUtils';

export const createStateSelector = (stateName) => {
	return createSelector(
		(state) => state,
		(state) => {
			if (stateName in state) return state[stateName];
			return state;
		}
	);
};

export const createKeySelector = (originSelector, key) => {
	return createSelector(
		originSelector,
		(state) => state[key]
	);
};

export const createRequestSelectors = (stateName, resourceKeys = []) => {
	const selectState = createStateSelector(stateName);
	const statePrefix = prefixCamelCase(stateName, "select");

	const selectors =  {
		[`${statePrefix}State`]: selectState
	};

	resourceKeys.forEach((key) => {
		const selectResource = createKeySelector(selectState, key);
		const selectorPrefix = prefixCamelCase(key, "select");

		selectors[selectorPrefix] = selectResource;
		selectors[`${selectorPrefix}Complete`] = createKeySelector(selectResource, "complete");
		selectors[`${selectorPrefix}Data`] = createKeySelector(selectResource, "data");
		selectors[`${selectorPrefix}IsLoading`] = createKeySelector(selectResource, "isLoading");
		selectors[`${selectorPrefix}HasErrored`] = createKeySelector(selectResource, "hasErrored");
	});

	return selectors;
};