import React, { useEffect } from "react";
import { flexRender } from "@tanstack/react-table";
import Icon from "@/components/controls/Icon.jsx";
import PropTypes from "prop-types";

function getItemLocalStorage(name) {
	const stringifiedItem = localStorage.getItem(name);
	return stringifiedItem ? JSON.parse(stringifiedItem) : stringifiedItem;
}

const TableHeader = ({
	header,
	table,
	tableId,
	resizable,
	sortable,
	storeOpts = false,
}) => {
	const { setColumnOrder, setColumnSizing } = table;
	const orderStorageString = tableId + "_order";
	const sizeStorageString = tableId + "_size";

	useEffect(() => {
		const columnOrder = getItemLocalStorage(orderStorageString);
		if (columnOrder) setColumnOrder(columnOrder);

		const columnSize = getItemLocalStorage(sizeStorageString);
		if (columnSize) setColumnSizing(columnSize);
	}, []);

	useEffect(() => {
		if (tableId && storeOpts) {
			const newWidths = table.getState().columnSizing;
			const oldWidths = getItemLocalStorage(sizeStorageString) || {};
			localStorage.setItem(
				sizeStorageString,
				JSON.stringify({ ...oldWidths, ...newWidths })
			);
		}
	}, [table.getState().columnSizing]);

	useEffect;

	return (
		<th
			colSpan={header.colSpan}
			style={{ width: header.getSize() }}
		>
			<div>
				{/* sort */}
				{header.isPlaceholder ? null : (
					<div
						{...{
							className:
								sortable && header.column.getCanSort() ? "pointer" : "",
							onClick: sortable && header.column.getToggleSortingHandler(),
						}}
					>
						<div className="sort-arrow">
							{{
								asc: <Icon icon={"long-arrow-up"} />,
								desc: <Icon icon={"long-arrow-down"} />,
							}[header.column.getIsSorted()] ?? null}
						</div>
						{flexRender(header.column.columnDef.header, header.getContext())}
					</div>
				)}
			</div>
			{/* resize */}
			{resizable && (
				<div
					{...{
						onMouseDown: header.getResizeHandler(),
						onTouchStart: header.getResizeHandler(),
						style: {
							transform: header.column.getIsResizing()
								? `translateX(${table.getState().columnSizingInfo.deltaOffset
								}px)`
								: "",
						},
					}}
					className={`resizer-wrapper`}>
					<div className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""
						}`} />

				</div>
			)}
		</th>
	);
};
export default TableHeader;

TableHeader.propTypes = {
	columns: PropTypes.array.isRequired,
	tableId: PropTypes.string.isRequired,
	storeOpts: PropTypes.bool,
	sortable: PropTypes.bool,
	resizable: PropTypes.bool,
	draggable: PropTypes.bool,
};
