import { toFixed } from '@/utils/MathUtils.js';
import SettingsMap from './SettingsMap.js';
import { formatDisplayString, makeNodeId } from '@/utils/StringUtils.js';
import CytoscapeStyle from '@/components/visualization/CytoscapeStyle.js';
import { clone, replaceKey, setDefault } from '@/utils/ObjectUtils.js';
import { processMetaboliteStats } from './MetaboliteUtils';
/** Static Sample set utility functions */
export default class SamplesetUtils {

	static parseSampleSet(sampleSet) {
		if (!sampleSet) return sampleSet;
		replaceKey(sampleSet, "Metabolites", "metabolites");
		replaceKey(sampleSet, "Sample", "sample");
		replaceKey(sampleSet, "Stats", "stats");
		replaceKey(sampleSet, "ProjectMdpId", "projectId");
		replaceKey(sampleSet, "SampleSetMdpId", "sampleSetId");
		return sampleSet;
	}

	static processStats(stats, override = false) {
		let activeStats = [], selection = null;

		let studyType, statsDict = {}, statsIds = [], trends = {};
		stats.forEach(s => {
			let stat = clone(s);
			studyType = stat.studyType = SamplesetUtils.determineStudyType(stat);
			setDefault(stat, 'swapped', false);
			setDefault(stat, 'customerVisible', true);
			setDefault(stat, 'customerSequence', stat.testSequence);

			if (override) {
				if (!stat.customerVisible) stat.customerVisible = true;
				if (stat.customerSequence === 0) stat.customerSequence = stat.testSequence;
			}
			statsIds.push(stat.statsId);
			if (stat.customerVisible) activeStats.push(stat.statsId);
			trends[stat.statsId] = { blank: [], trendUp: [], trendDown: [], sigUp: [], sigDown: [], nil: [], dead: [], pathways: {}, compounds: {}, count: 0, sig: 0 };
			statsDict[stat.statsId] = stat;
		});
		selection = activeStats.concat();

		let { order, sequences, sequenceOrder } = SamplesetUtils.groupSequences(statsIds, statsDict);
		return { activeStats, order, selection, stats, statsDict, sequences, sequenceOrder, statsIds, studyType, trends };
	}
	/* 
		the tests should be grouped by compSequence
		the order in which those appear is the testSequence
	 */
	static groupSequences(statsIds, statsDict) {
		let sequences = {};
		let count = 0;

		statsIds.forEach(statId => {
			let sequenceKey = statsDict[statId].compSequence;
			if (!sequences[sequenceKey]) {
				sequences[sequenceKey] = { tests: [], order: count, name: sequenceKey, test: statsDict[statId].test };
				count++;
			}
			sequences[sequenceKey].tests.push(statId);
		});

		return SamplesetUtils.sortSequences(sequences, statsDict);
	}

	static sortSequences(sequences, statsDict, key = 'customerSequence', dir = 1) {
		let order = [], sequenceOrder = [];
		let orderedSequences = Object.values(sequences).sort((a, b) => {
			return dir * (a.order - b.order);
		});

		orderedSequences.forEach(sequence => {
			sequence.tests.sort((a, b) => {
				return dir * (statsDict[a][key] - statsDict[b][key]);
			});
			order = order.concat(sequence.tests);
			sequenceOrder.push(sequence.name);
		});
		return { order, sequences, sequenceOrder };
	}

	static processMetabolites(setMetabolites, stats, trends, statsDict, testField = "foldChange", drawCompounds = false) {
		let superPathways = {}, subPathways = {}, metaboDeck = {};
		let graphElements = { nodes: [], edges: [] };

		let metabolites = setMetabolites.map((metabolite, mindex) => {
			if (metabolite.superPathway && metabolite.subPathway) {
				let superId = makeNodeId(metabolite.superPathway);
				let subId = makeNodeId(metabolite.subPathway);

				let superPath = superPathways[superId];
				if (!superPath) {
					superPath = CytoscapeStyle.createSuperNodeData(superId, metabolite.superPathway);

					superPathways[superId] = superPath;
					graphElements.nodes.push(superPath);
				}

				let subPath = subPathways[subId];
				if (!subPath) {
					subPath = CytoscapeStyle.createSubNodeData(subId, superId, metabolite.subPathway, drawCompounds ? superId : null);

					subPathways[subId] = subPath;
					graphElements.nodes.push(subPath);
					graphElements.edges.push(CytoscapeStyle.createEdgeData(
						superId + " - " + subId, superId, subId
					));
				}

				graphElements.edges.push(CytoscapeStyle.createEdgeData(
					subId + " - " + metabolite.metaboliteId, subId, metabolite.metaboliteId
				));
			}

			let chemName = metabolite.chemicalName,
				isUnknown = chemName.toLowerCase().includes("unknown");
			if (isUnknown) {
				chemName = "Unknown Compounds";
			}

			processMetaboliteStats(metabolite, isUnknown, testField, stats, statsDict, trends);

			let subId = metabolite.subPathway ? makeNodeId(metabolite.subPathway) : null;
			let superId = metabolite.superPathway ? makeNodeId(metabolite.superPathway) : null;
			let meta = CytoscapeStyle.createMetaNodeData(metabolite, subId, superId, isUnknown, drawCompounds && metabolite.subPathway ? makeNodeId(metabolite.subPathway) : null);

			graphElements.nodes.push(meta);
			metaboDeck[metabolite.chemicalId + '-' + metabolite.chemicalName] = meta;
			return metabolite;
		});

		return { metabolites, graphElements, superPathways, subPathways, metaboDeck };
	}

	static highlightNode(node, nameKey, getNodeScale, current, studyType, highlights, flags, cy, onSubmapSelect) {
		let chemId = node.data('chemicalId'), detail = node.data('detail-' + current), classes = [studyType];//this.getNodeId(node), 
		if (detail && detail.effectTest) {
			classes = ['effect'];
		}

		const { hideFinalPathways, hideIntermediates, hideMinorMetabolites, hideCofactors } = flags;

		let chemName = node.data(nameKey), isUnknown = false;
		if (node.data('pathwaySortOrder') === 0 || chemName && chemName.toLowerCase().includes("unknown")) {
			isUnknown = true;
			chemId = node.data('metaboliteId');
		}

		if (node.data('compoundType') == 'Class') {
			SamplesetUtils.styleMultiSpeciesNode(node, chemName, studyType, highlights, cy);
			return;
		}

		if (chemId) {
			if (highlights.trendUp.indexOf(chemId) >= 0) classes.push('trendUp');
			else if (highlights.trendDown.indexOf(chemId) >= 0) classes.push('trendDown');
			else if (highlights.sigUp.indexOf(chemId) >= 0) classes.push('sigUp');
			else if (highlights.sigDown.indexOf(chemId) >= 0) classes.push('sigDown');
			else if (highlights.nil.indexOf(chemId) >= 0) classes.push('nil');
			else if (highlights.dead.indexOf(chemId) >= 0) classes.push('dead');
			else if (highlights.blank.indexOf(chemId) >= 0) classes.push('blank');
		}

		switch (node.data('compoundType')) {
			case "Intermediate":
				if (!hideIntermediates) classes.push('show');
				break;

			case "Cofactor":
				if (!hideCofactors) classes.push('show');
				break;

			case "FinalPathway":
				if (!hideFinalPathways) classes.push('show');
				node.off('click');
				node.on('click', () => {
					let mapId = node.data('canonicalName');
					onSubmapSelect(mapId);
				});
				break;

			case "Minor Metabolite":
				if (!hideMinorMetabolites) classes.push('show');
				break;
		}

		node
			.removeClass('effect zscore standard show trendUp sigUp trendDown sigDown blank nil dead')
			.data({ weight: getNodeScale(node) })
			.addClass(classes.join(' '));
	}

	static styleMultiSpeciesNode(node, chemName, studyType, highlights, cy) {
		if (node.data('compoundType') != 'Class') return;

		let colors = SettingsMap.colors;
		//lookup subpathways based on chemId
		//pie fill nodes with metabolites stats
		//use cytoscape to get stats data?
		let id = makeNodeId(chemName);
		let group = cy.$(`#${id}`).neighborhood(`node[type!='superpathway']`);
		let count = group.length,
			pie = {
				trendUp: 0, trendDown: 0, sigUp: 0, sigDown: 0,
				nil: 0, dead: 0, blank: 0
			},
			keys = [];

		//loop thru metabolites
		group.forEach(m => {
			let mId = m.data('chemicalId'), mName = m.data('chemicalName');
			if (mName && mName.toLowerCase().includes("unknown")) {
				mId = m.data('metaboliteId');
			}
			if (mId) {
				for (let key in highlights) {
					if (highlights[key].indexOf(mId) < 0) continue;
					pie[key]++;
					if (keys.indexOf(key) < 0) keys.push(key);
					break;
				}
			}
		});

		let style = {
			'pie-size': '75%'
		};
		keys.forEach((key, n) => {
			style[`pie-${n + 1}-background-size`] = Math.round(100 * (pie[key] / count));
			style[`pie-${n + 1}-background-color`] = key in colors[studyType] ? colors[studyType][key] : colors.defaults[key];
		});
		node.style(style);
	}

	static getNodeScale(type, current, state) {
		if (!state) return SamplesetUtils.nodeBaseScale();
		const { metaboDeck, cy, cyMap, cyPath, foldChangeScale } = state;
		let nameKey = cy === cyMap ? "canonicalName" : 'chemicalName';

		if (!state.foldChangeScale) return SamplesetUtils.nodeBaseScale();
		if (type === "zscore") return SamplesetUtils.scaleZScoreNode(current, nameKey, metaboDeck);
		return SamplesetUtils.scaleNode(current, nameKey, metaboDeck);
	}

	static scaleZScoreNode(current, nameKey, metaboDeck) {
		return node => {
			let detail, chemId = node.data('chemicalId'), chemName = node.data(nameKey);
			if (!chemId || !chemName) {
				detail = node.data('detail-' + current);
			}
			else {
				let stats = metaboDeck[chemId + '-' + chemName];
				if (!stats) return 1;
				detail = stats.data['detail-' + current];
			}

			if (!detail) return 1;


			let val = Math.abs(detail.foldChange),
				factor = 2,
				max = 5,
				delta = Math.min(max, Math.floor(val));

			return Math.max(1, factor * delta);//*sign;
		};
	}

	static nodeBaseScale() {
		return node => 1;
	}

	static scaleNode(current, nameKey, metaboDeck) {
		return node => {
			let detail, chemId = node.data('chemicalId'), chemName = node.data(nameKey);
			if (!chemId || !chemName) {
				detail = node.data('detail-' + current);
			}
			else {
				let stats = metaboDeck[chemId + '-' + chemName];
				if (!stats) return 1;
				detail = stats.data['detail-' + current];
			}

			if (!detail) return 1;


			let val = detail.foldChange !== null ? detail.foldChange : 1;
			val = val >= 1 ? val : (val == 0 ? 0 : 1 / val);
			let sign = val == 0 ? 1 : val / Math.abs(val),
				factor = 1,
				max = 5,
				delta = Math.min(max, Math.floor(Math.abs(val)));

			return factor * delta;
		};
	}

	static gatherTrends(trends, highlights = {}) {
		if (!trends) return highlights;

		if (trends.trendUp.length) highlights.trendUp = highlights.trendUp.concat(trends.trendUp);
		if (trends.trendDown.length) highlights.trendDown = highlights.trendDown.concat(trends.trendDown);
		if (trends.sigUp.length) highlights.sigUp = highlights.sigUp.concat(trends.sigUp);
		if (trends.sigDown.length) highlights.sigDown = highlights.sigDown.concat(trends.sigDown);
		if (trends.dead.length) highlights.dead = highlights.dead.concat(trends.dead);
		if (trends.nil.length) highlights.nil = highlights.nil.concat(trends.nil);
		if (trends.blank.length) highlights.blank = highlights.blank.concat(trends.blank);

		return highlights;
	}

	static determineStudyType(s) {
		let type = 'standard', selfCompare = false;
		let stat = clone(s);
		if (stat.test && stat.test.toLowerCase() == 'zscore') type = 'zscore';
		if (stat.foldChange) {
			let fold = formatDisplayString(stat.foldChange);
			let fcParts = fold.split('/');
			selfCompare = fcParts[0] === fcParts[1];
			if (!selfCompare) {
				selfCompare = (stat.reportName && stat.reportName.toLowerCase().indexOf('effect') >= 0);
			}
			stat.selfCompare = selfCompare;
			if (selfCompare) type = 'effect';
		}
		else if (stat.isMainEffectTest) {
			type = 'effect';
		}
		else if (stat.test && stat.test.toLowerCase().indexOf('anova') >= 0) {
			type = 'effect';
		}

		return type;
	}

	static calculateEnrichments(trends) {
		let maxE = 0;
		for (let key in trends) {
			let trend = trends[key];
			for (let name in trend.pathways) {
				let p = trend.pathways[name];
				p.enrichment = SamplesetUtils.calculateEnrichment(p.sig, p.count, trend.sig, trend.count);
				maxE = Math.max(maxE, p.enrichment);
			}
			trend.maxEnrichment = maxE;
		}

		return trends;
	}

	//(k/m)/((n-k)/(N-m))
	static calculateEnrichment(k, m, n, N) {
		if (N == 0 || n == 0 || m == 0) return 0;
		//return parseFloat(toFixed((k/m)/(n/N))); //formula 1
		return parseFloat(toFixed((k / m) / ((n - k) / (N - m)))); //formula 2
	}



	static getStatTitle(data, fallback = "Stats") {
		let label = [];
		if (data.test) label.push(data.test);
		if (data.comparison) label.push(data.comparison);
		if (data.foldChange) label.push(data.foldChange);

		if (label.length <= 0) return fallback;

		return label.join(' ');
	}
}