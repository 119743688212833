import React, { useCallback, useEffect, useMemo, useState } from "react"
import classNamesBinder from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { HGT } from "@/views/routes";
import { Snackbar, Alert, Stack, Typography } from "@mui/material";
import { STAT_ACTIONS } from '../../redux/actionTypes.js';
import SamplesetUtils from '../../utils/SamplesetUtils.js'
import { PreviewTable } from "./components/PreviewTable.jsx";
import StatsEditorTable from '../../containers/StatsEditorTable.jsx'
import { ReorderSwitch } from "./components/ReorderSwitch.jsx";
import { ReorderFlyout } from "./components/ReorderFlyout.jsx";
import Icon from '@/components/controls/Icon.jsx';
import { setStats, updateStats } from '../../redux/reducers/stats.reducer.js';
import { setReorder, setPreview, setTitle, setTrends, setStudyType, setChangesSaved } from '../../redux/reducers/editor.reducer.js';
import { selectDuplicates, selectTitle, selectChangesSaved } from "../../redux/selectors/editor.selector.js";
import {
	propertyMapFetchLatest,
	propertyMapUpload,
	sampleFetch,
	statsFetch,
	sampleSetUpload,
	setProjectData,
	metabolitesClear,
	sampleClear,
	statsClear,
	propertyMapClear,
} from '../../redux/reducers/project.reducer.js';
import {
	resultsClear,
} from '../../redux/reducers/search.reducer.js';
import { selectStats } from '../../redux/selectors/stats.selector.js';
import {
	selectProjectData,
	selectPropertyMap,
	selectPropertyMapUpload,
	selectSample,
	selectStats as selectSampleStats
} from '../../redux/selectors/project.selector.js';
import { clone } from '@/utils/ObjectUtils.js';
import * as styles from './editor.module.scss'
import { selectResults } from '../../redux/selectors/search.selector.js';
import GenerateHeatmapModal from '../../containers/GenerateHeatmapModal.jsx';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { mockSearchSampleSets, searchProjectSampleSets, isMock } from '../../api/index.js';
import useWindowUnload from '../../hooks/windowUnload.hook.js';
import { TitleTextField } from "./components/TitleTextField.jsx";
import { PreviewSwitch } from "./components/PreviewSwitch.jsx";
import SampleSetDropdown from "./components/SampleSetDropdown.jsx";
import SampleSetArrows from "./components/SampleSetArrows.jsx";
import { dayjs } from '@/utils/DateUtils.js';
import { SearchHgt } from "../../containers/SearchHgt.jsx";
const classNames = classNamesBinder.bind(styles);

function Editor(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	let { projectCode, sampleSetId } = useParams();
	const { data: selectedSampleStats, isLoading: sampleSetIsLoading } = useSelector(selectSampleStats);
	const { data: selectedSample, isLoading: sampleIsLoading } = useSelector(selectSample);
	const project = useSelector(selectProjectData);
	const duplicates = useSelector(selectDuplicates);
	const changesSaved = useSelector(selectChangesSaved);
	const projectId = useMemo(() => project?.projectId, [project]);

	const selectedStatsDict = useSelector(selectStats);
	const { data: propertyMap, isLoading: propertyMapIsLoading, complete: propertyMapIsComplete } = useSelector(selectPropertyMap);
	const { isLoading: propertyMapIsUploading, complete: propertyMapUploadComplete } = useSelector(selectPropertyMapUpload);

	const activeSampleSet = useMemo(() => {
		if (!selectedSample) return null;
		return {
			projectId,
			sample: clone(selectedSample),
			sampleSetId,
			stats: clone(selectedSampleStats),
		}
	}, [selectedSampleStats, sampleSetId, projectId, selectedSample]);

	const statsDict = useMemo(() => clone(selectedStatsDict), [selectedStatsDict]);

	const [generateHeatmapModalVisible, setGenerateHeatmapModalVisible] = useState(false);
	const [showHidden] = useState(props.showHidden);
	const [order, setOrder] = useState([]);
	const [sequences, setSequences] = useState(null);
	const [sequenceOrder, setSequenceOrder] = useState(null);

	//const [preview, setPreview] = useState(false);
	//const [reorder, setReorder] = useState(false);

	const [activeStats, setActiveStats] = useState([]);
	const [currentSampleSet, setCurrentSampleSet] = useState(null);
	const [date, setDate] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const closeSuccess = useCallback(() => { setShowSuccess(false) }, []);
	//const [changesSaved, setChangesSaved] = useState(true);
	const unsavedWarning = "You have unsaved changes. Are you sure you want to leave?";
	useWindowUnload(unsavedWarning, changesSaved);

	const title = useSelector(selectTitle);

	useEffect(() => {
		if (propertyMapUploadComplete) {
			setShowSuccess(true);
		}
	}, [propertyMapUploadComplete]);

	useEffect(() => {
		const fetchProject = async (code) => {
			const { payload } = isMock ? await mockSearchSampleSets(code) : await searchProjectSampleSets(code);
			if (payload.length > 0) {
				dispatch(setProjectData(payload[0]));
			}
		};
		if (!project || (project.projectCode != projectCode)) {
			fetchProject(projectCode);
		}
	}, [projectCode, project]);

	useEffect(() => {
		if (!statsDict) return;
		setActiveStats(Object.values(statsDict).filter(stat => stat.customerVisible).map(stat => stat.statsId));
	}, [statsDict]);

	useEffect(() => {
		if (!projectId || !sampleSetId) return;

		dispatch(resultsClear());
		dispatch(metabolitesClear());
		dispatch(setPreview(false));
		dispatch(sampleFetch({ sampleSetId }));
		dispatch(propertyMapFetchLatest({ projectId, sampleSetId }));
		//dispatch(statsFetch({ sampleSetId }));

	}, [projectId, sampleSetId]);

	useEffect(() => {
		if (!projectId || !activeSampleSet || project.projectCode != projectCode) return;
		setCurrentSampleSet(project.sampleSets.findIndex(s => activeSampleSet.sampleSetId === s.sampleSetId));
		if (!activeSampleSet.stats) return;

		let statsData = SamplesetUtils.processStats(activeSampleSet.stats, true);
		dispatch(setTrends(statsData.trends));
		setActiveStats(statsData.activeStats);
		setOrder(statsData.order);
		setSequences(statsData.sequences);
		setSequenceOrder(statsData.sequenceOrder);
		dispatch(setStats(statsData.statsDict));
		dispatch(setStudyType(statsData.studyType));
		logEvent("SampleSetIDSelect", activeSampleSet ? activeSampleSet.sampleSetId : "Undefined");
	}, [activeSampleSet, projectId, project, projectCode]);

	const onSampleSetSelect = useCallback((sampleSet) => {
		if (sampleSet.sampleSetId === activeSampleSet.sampleSetId) return;
		setOrder([]);
		dispatch(setTitle(''));
		dispatch(setReorder(false));
		dispatch(setStats(null));
		setSequences(null);
		setSequenceOrder(null);
		dispatch(sampleClear());
		dispatch(metabolitesClear());
		dispatch(propertyMapClear());
		dispatch(statsClear());
		history.push(`/home/${HGT}/${projectCode}/${sampleSet.sampleSetId}`);

		//logEvent("SampleSetIDSelect", sampleSet ? sampleSet.sampleSetId : "Undefined");
	}, [activeSampleSet, history, projectCode]);

	const closeGenerateHeatmapModal = useCallback(() => {
		setGenerateHeatmapModalVisible(false);
	}, [generateHeatmapModalVisible, setGenerateHeatmapModalVisible]);

	const openGenerateHeatmapModal = useCallback(() => {
		setGenerateHeatmapModalVisible(true);
	}, [generateHeatmapModalVisible, setGenerateHeatmapModalVisible]);


	const logEvent = (property, value) => {
		//Log event
		/*let logFilterChange = new Log();
		logFilterChange.SetLevel(AppInsightLogLevel.EVENT);
		logFilterChange.SetName('PageEvent_HeatMap');
		logFilterChange.AddProperty(property,value);
		logger.doLog(logFilterChange);*/
		//console.log('LOG', property, value)
	};

	const onItemToggle = useCallback(stats => {
		//	dispatch(setChangesSaved(false));
		//setActiveStats(stats);
	}, []);


	const moveStat = useCallback((stat, dict, newPosition) => {
		let key = 'customerSequence',
			sequence = sequences[stat.compSequence],
			temp = stat[key],
			statB, statBId;
		if (newPosition > 0 && newPosition <= sequence.tests.length) {
			statBId = sequence.tests.find((statId) => dict[statId][key] === newPosition);
			statB = dict[statBId]
			stat[key] = statB[key];
			dict[stat.statsId][key] = statB[key]
			statB[key] = temp;
			dict[statBId][key] = temp;

			let results = SamplesetUtils.sortSequences({ ...sequences }, dict, key);
			setOrder(results.order);
			setSequences(results.sequences);
			setSequenceOrder(results.sequenceOrder);
			dispatch(updateStats({ [stat.statsId]: stat, [statB.statsId]: statB }));
		}
	}, [sequences, sequenceOrder]);
	const onStatsAction = useCallback((action, { statsId, ...data }) => {

		let stat = clone(statsDict[statsId]);

		dispatch(setChangesSaved(false));
		switch (action) {
			case STAT_ACTIONS.SWAP:
				stat.swapped = "swapped" in stat ? !stat.swapped : true;
				dispatch(updateStats({ [statsId]: stat }));
				return;

			case STAT_ACTIONS.UPDATE_REPORT:
				stat.customerName = data.customerName;
				dispatch(updateStats({ [statsId]: stat }));
				return;

			case STAT_ACTIONS.UPDATE_SEQUENCE: {
				const maxSeqNumber = sequences[stat.compSequence].tests.length;
				const seq = data.customerSequence > maxSeqNumber ? maxSeqNumber : data.customerSequence;
				moveStat(stat, { ...statsDict }, seq);
				return;
			}
			case STAT_ACTIONS.MOVE_DN:
				moveStat(stat, { ...statsDict }, stat.customerSequence + 1);
				return;

			case STAT_ACTIONS.MOVE_UP:
				moveStat(stat, { ...statsDict }, stat.customerSequence - 1);
				return;

			case STAT_ACTIONS.VISIBILITY:
				stat.customerVisible = stat.customerVisible !== undefined ? !stat.customerVisible : true;
				dispatch(updateStats({ [statsId]: stat }));
				return;
		}
	}, [statsDict, moveStat, dispatch]);

	const moveSequence = useCallback((name, dict, dir = 1) => {
		const s = clone(sequences);
		let sequence = s[name],
			temp = sequence ? sequence.order : -1,
			newIndex = temp + dir;

		if (!sequence || newIndex < 0 || newIndex >= sequenceOrder.length) return;

		let sequenceB = s[sequenceOrder[newIndex]];
		sequence.order = newIndex;
		sequenceB.order = temp;

		let results = SamplesetUtils.sortSequences(s, dict);

		setOrder(results.order);
		setSequences(results.sequences);
		setSequenceOrder(results.sequenceOrder);
	}, [sequences, sequenceOrder]);

	const onTestAction = useCallback((action, { name }) => {
		dispatch(setChangesSaved(false));
		switch (action) {
			case STAT_ACTIONS.MOVE_DN:
				moveSequence(name, { ...statsDict });
				return;

			case STAT_ACTIONS.MOVE_UP:
				moveSequence(name, { ...statsDict }, -1);
				return;

		}
	}, [sequences, statsDict, moveSequence, dispatch]);

	const sortedStats = useMemo(() => order.filter(statsId => activeStats.indexOf(statsId) >= 0), [order, activeStats]);

	const exportData = useCallback(() => {
		if (!activeSampleSet) {
			// eslint-disable-next-line
			console.warn("Cannot export", activeSampleSet, data);
			return;
		}

		let sampleSet = clone(activeSampleSet);
		sampleSet.sample.name = title;
		sampleSet.stats = sortedStats.map/* Object.values(statsDict).map */((statsId) => {
			const stat = statsDict[statsId]
			return {
				...stat,
				reportName: stat.customerName || stat.reportName
			}
		});

		const map = {
			lastModified: Date.now(),
			sampleSetId: sampleSet.sampleSetId,
			sample: {
				customerName: title,
			},
			sequences,
			stats: statsDict
		};

		return { sampleSet, map };
	}, [activeSampleSet, sortedStats, sequences, statsDict, title]);

	const importStats = useCallback((stats, map) => {
		let statsData = SamplesetUtils.processStats(stats);
		dispatch(setTrends(statsData.trends));
		setActiveStats(statsData.activeStats);

		const statIds = Object.keys(statsData.statsDict);
		const results = map?.sequences ? SamplesetUtils.sortSequences(clone(map.sequences), statsData.statsDict) : SamplesetUtils.groupSequences(statIds, stats);
		setOrder(results.order);
		setSequences(results.sequences);
		setSequenceOrder(results.sequenceOrder);

		dispatch(setStats(statsData.statsDict));
		dispatch(setStudyType(statsData.studyType));
	}, [dispatch]);

	const importData = useCallback((map) => {
		if (!selectedSample) return;

		if (map) {
			dispatch(setTitle(map.sample?.customerName || selectedSample.name));
			if (map.lastModified) setDate(`Last Updated ${dayjs(map.lastModified).format('MMM DD, YYYY')}`);
			else setDate('No previous revisions');

			const mapStats = map.stats;
			let stats = mapStats ? Object.values(mapStats) : [];
			if (stats && stats.length > 0) {
				importStats(stats, map);
			}
			else {
				dispatch(statsFetch({ sampleSetId }));
			}
		}
		else {
			dispatch(setTitle(selectedSample.name));
			dispatch(statsFetch({ sampleSetId }));
		}
	}, [selectedSample, dispatch, importStats, sampleSetId]);

	useEffect(() => {
		if (!propertyMapIsComplete) return;

		importData(propertyMap?.propertyMap);
	}, [propertyMap, propertyMapIsComplete, importData])

	const onDownload = useCallback(() => {
		if (!activeSampleSet || !statsDict) return;
		const sampleSetId = activeSampleSet.sampleSetId;
		const { sampleSet, map } = exportData();
		dispatch(propertyMapUpload({ projectId, sampleSetId, propertyMap: map }));
		dispatch(sampleSetUpload({ ...sampleSet, Settings: { DisplayExtraMeanPercent: !duplicates } }));
	}, [activeSampleSet, projectId, statsDict, dispatch, exportData, duplicates]);

	const onSave = useCallback(() => {
		if (!activeSampleSet || !statsDict) return;
		dispatch(setChangesSaved(true));
		const sampleSetId = activeSampleSet.sampleSetId;
		const { map } = exportData();
		dispatch(propertyMapUpload({ projectId, sampleSetId, propertyMap: map }));
	}, [activeSampleSet, projectId, statsDict, dispatch, exportData]);

	return (
		<div data-testid="editor" className={classNames("Editor", "page")}>
			<Prompt
				when={!changesSaved}
				message={unsavedWarning}
			/>
			<Snackbar open={showSuccess} autoHideDuration={5000} onClose={closeSuccess}>
				<Alert onClose={closeSuccess} severity="success" sx={{ width: '100%' }}>
					Your data has saved successfully!
				</Alert>
			</Snackbar>
			<GenerateHeatmapModal
				isOpen={generateHeatmapModalVisible}
				onClose={closeGenerateHeatmapModal}
				onDownload={onDownload}
			/>
			
			<SearchHgt activeSampleSet={activeSampleSet} changesSaved={changesSaved} nSave={onSave} onGenerate={openGenerateHeatmapModal} />

			{(sampleSetIsLoading || propertyMapIsLoading) &&
				<Icon className="ml-2 fa-spin" icon="spinner" size="large" />
			}
			{(!sampleSetIsLoading && !propertyMapIsLoading && propertyMapIsComplete) && activeSampleSet &&
				<div className={(styles.content)}>

					<div className="column-header project-code">
						{projectCode}
					</div>
					<Stack id={(styles["stats-ui"])}>

						<Stack direction="row" spacing={2} className="column-header sample-set">
							<Typography variant="caption">Sample set</Typography>
							{activeSampleSet.sampleSetId && (
								<Typography variant="caption">{activeSampleSet.sample.limsSampleSetId}</Typography>
							)}
							{activeSampleSet.sample?.matrix && (
								<Typography variant="caption">{activeSampleSet.sample.matrix}</Typography>
							)}
						</Stack>
						<Stack direction="row" justifyContent="space-between">
							<Stack direction="row"alignItems="center">
								<SampleSetDropdown project={project} activeSampleSet={activeSampleSet} onSampleSetSelect={onSampleSetSelect} />
								<SampleSetArrows project={project} currentSampleSet={currentSampleSet} projectCode={projectCode} />
							</Stack>

							<PreviewSwitch sampleSetId={sampleSetId} />
						</Stack>
					</Stack>

					<TitleTextField date={date} selectedSample={selectedSample} />

					<ReorderSwitch />

					{activeSampleSet && statsDict &&
						<>
							<div className={styles["edit-tables"]}>
								<div className={classNames({ full: !(sequences && order && order.length > 1) }, "left")}>
									<h3>Edit Stats Tests</h3>
									<StatsEditorTable
										onFieldAction={onStatsAction}
										showHidden={showHidden}
										sortedStats={order}
									/>
								</div>
								{sequences && order && order.length > 1 && <ReorderFlyout onTestAction={onTestAction} sequences={sequences} />}
							</div>
						</>
					}
					<PreviewTable
						activeSampleSet={activeSampleSet}
						showHidden={showHidden}
						sortedStats={sortedStats}
						testField={props.testField}
					/>
				</div>
			}
		</div>
	);
}

Editor.propTypes = {

}

Editor.defaultProps = {
	sampleSetIndex: 0,
	useWorker: true,
	showHidden: true,
	testField: 'foldChange'
}

export default Editor;
