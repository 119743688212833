import { editor } from './editor.reducer'
import { stats } from './stats.reducer'
import { search } from './search.reducer' 
import { project } from './project.reducer' 

export const rootReducer = {
	editor,
	project,
	search,
	stats
}
