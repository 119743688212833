import React, { useCallback } from "react";
import { HGT } from "@/views/routes";
import Button from "@/components/buttons/Button.jsx";
import Material from "@/components/controls/Material.jsx";
import { useHistory } from "react-router-dom";

export default function SampleSetArrows({
  project,
  currentSampleSet,
  projectCode,
}) {
  const history = useHistory();

  const onNextArrow = useCallback(() => {
    if (currentSampleSet < project.sampleSets.length - 1)
      history.push(
        `/home/${HGT}/${projectCode}/${
          project.sampleSets[currentSampleSet + 1].sampleSetId
        }`
      );
    //logEvent("Arrow", "Next");
  }, [project, currentSampleSet]);

  const onPrevArrow = useCallback(() => {
    if (currentSampleSet > 0)
      history.push(
        `/home/${HGT}/${projectCode}/${
          project.sampleSets[currentSampleSet - 1].sampleSetId
        }`
      );
    //logEvent("Arrow", "Previous");
  }, [project, currentSampleSet]);

  return (
    <div className="stats-switcher">
      <Button
        className="title primary alt2"
        title="Previous Test"
        testId="sample-set-arrow-left"
        disabled={!project.sampleSets || currentSampleSet === 0}
        onClick={onPrevArrow}
      >
        <Material size="large" icon="keyboard_arrow_left" />
      </Button>
      <Button
        className="title primary alt2"
        title="Next Test"
        testId="sample-set-arrow-right"
        disabled={
          !project.sampleSets ||
          currentSampleSet === project.sampleSets.length - 1
        }
        onClick={onNextArrow}
      >
        <Material size="large" icon="keyboard_arrow_right" />
      </Button>
    </div>
  );
}
