import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setReorder } from "../../../redux/reducers/editor.reducer";
import { selectReorder } from "../../../redux/selectors/editor.selector";
import { Switch } from "../../../components/controls/Switch.jsx";
import { Stack, Typography } from "@mui/material";
import * as styles from '../editor.module.scss'

export const ReorderSwitch = () => {

	const dispatch = useDispatch();
	const reorder = useSelector(selectReorder);

	const toggleReorder = useCallback(() => {
		dispatch(setReorder(!reorder));
	}, [dispatch, reorder]);

	return (
		<Stack className={styles["flyout-switch"]} direction="row-reverse" alignItems="center">
			<Stack>
				<Typography variant="caption">REORDER</Typography>
				<Switch textAlign="center" checked={reorder} onChange={toggleReorder} />
			</Stack>
		</Stack>
	)
}