import urljoin from 'url-join';
import { API_HGT_BASEPATH } from '@/config/env.config';
export const ENV="localhost";

export const API_BASEPATH = API_HGT_BASEPATH

export const API_ENDPOINT_HEATMAP = urljoin(API_BASEPATH, "mergeddata", "download-heatmap");

//search
export const API_ENDPOINT_SEARCH_PROJECTS = urljoin(`${API_BASEPATH}`,'search', 'projects');
export const API_ENDPOINT_SEARCH_PROJECT_SAMPLESETS = urljoin(`${API_BASEPATH}`,'search', 'samplesets');

//samplesets
export const API_ENDPOINT_SAMPLESET = urljoin(API_BASEPATH, "samplesets");

export const API_ENDPOINT_GET_RSD = urljoin(API_ENDPOINT_SAMPLESET, "rsdsummary");
export const API_ENDPOINT_GET_AGG = urljoin(API_ENDPOINT_SAMPLESET, "aggregatesampleset");
export const API_ENDPOINT_GET_STATS = urljoin(API_ENDPOINT_SAMPLESET, "stats");
export const API_ENDPOINT_GET_METABOLITES = urljoin(API_ENDPOINT_SAMPLESET, "metabolites");
export const API_ENDPOINT_GET_SAMPLE = urljoin(API_ENDPOINT_SAMPLESET, "sample");

export const API_ENDPOINT_PUT_RSD = urljoin(API_ENDPOINT_SAMPLESET, "rsdsummary", "update");
export const API_ENDPOINT_PUT_AGG = urljoin(API_ENDPOINT_SAMPLESET, "aggregatesampleset", "update");
export const API_ENDPOINT_PUT_STATS = urljoin(API_ENDPOINT_SAMPLESET, "stats", "update");
export const API_ENDPOINT_PUT_METABOLITES = urljoin(API_ENDPOINT_SAMPLESET, "metabolites", "update");

//propetery maps
export const API_ENDPOINT_PROPMAPS = urljoin(API_BASEPATH, "propertymaps");
export const API_ENDPOINT_PROPMAPS_LATEST = urljoin(API_ENDPOINT_PROPMAPS, "latest");
export const API_ENDPOINT_PROPMAPS_LIST = urljoin(API_ENDPOINT_PROPMAPS, "list");
export const API_ENDPOINT_PROPMAPS_REVISION = urljoin(API_ENDPOINT_PROPMAPS, "revision");
export const API_ENDPOINT_PROPMAPS_UPLOAD = urljoin(API_ENDPOINT_PROPMAPS, "upload");

export const API_ENDPOINT_PING = urljoin(API_BASEPATH, "status", "ping");