import { createSlice } from "@reduxjs/toolkit"
import { addRequest } from "../helpers/reducer.helpers";

export const getInitialState = () => (
	{
		data: null
	}
)


const slice = {
	name: "project",
	initialState: getInitialState(),
	reducers: {
		setProjectData: (state, action) => {
			state.data = action.payload;
		}
	}
};
addRequest(slice, "sampleSet", null);
addRequest(slice, "sample", null);
addRequest(slice, "sampleSetUpload", null, "");
addRequest(slice, "stats", null);
addRequest(slice, "statsUpload", null, "");
addRequest(slice, "metabolites", null);
addRequest(slice, "propertyMapUpload", null, "");
addRequest(slice, "propertyMap", null, "fetchLatest");

const ProjectSlice = createSlice(slice);

export const {
	sampleClear,
	sampleFetch,
	sampleFetchSuccess,
	sampleFetchFailed,
	sampleSetClear,
	sampleSetFetch,
	sampleSetFetchFailed,
	sampleSetFetchSuccess,
	sampleSetUpload,
	sampleSetUploadFailed,
	sampleSetUploadSuccess,
	statsClear,
	statsFetch,
	statsFetchFailed,
	statsFetchSuccess,
	statsUpload,
	statsUploadFailed,
	statsUploadSuccess,
	metabolitesClear,
	metabolitesFetch,
	metabolitesFetchFailed,
	metabolitesFetchSuccess,
	propertyMapUpload,
	propertyMapUploadFailed,
	propertyMapUploadSuccess,
	propertyMapClear,
	propertyMapFetchLatest,
	propertyMapFetchLatestSuccess,
	propertyMapFetchLatestFailed,
	setProjectData,
} = ProjectSlice.actions;
export const project = ProjectSlice.reducer;