import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/lenses/uilenses/components/Modal/Modal';
import Button from '@/components/buttons/Button.jsx'
import Icon from '@/components/controls/Icon.jsx';
import { useSelector } from 'react-redux';
import { selectSampleSetUpload, selectPropertyMap } from '../redux/selectors/project.selector';
import './GenerateHeatmapModal.scss';


const TITLE_ERROR = 'Error!';
const TITLE_LOADING = 'Loading...';
const TITLE_SUCCESS = 'Success!';
const TITLE_DEFAULT = 'Click below to download';

const ICON_SUCCESS = 'check';
const ICON_ERROR = 'times';
const ICON_LOADING = 'spinner';
const ICON_DEFAULT = 'download';

const SPIN_CLASS_NAME = 'fa-spin';


const GenerateHeatmapModal = ({ isOpen, onClose, onDownload, onGenerateNew }) => {

	const sampleSetUpload = useSelector(selectSampleSetUpload);
	const propertyMap = useSelector(selectPropertyMap);

	const isLoading = useMemo(
		() => sampleSetUpload.isLoading || propertyMap.isLoading,
		[sampleSetUpload.isLoading, propertyMap.isLoading]
	);

	const isComplete = useMemo(
		() => sampleSetUpload.complete && propertyMap.complete,
		[sampleSetUpload.complete, propertyMap.complete]
	);

	const hasErrored = useMemo(
		() => sampleSetUpload.hasErrored || propertyMap.hasErrored,
		[sampleSetUpload.hasErrored, propertyMap.hasErrored]
	);

	const [headIcon, title, iconClassName] = useMemo(
		() => {
			let icon = ICON_DEFAULT;
			let title = TITLE_DEFAULT;
			let iconClassName = '';

			if (isLoading) {
				icon = ICON_LOADING;
				title = TITLE_LOADING;
				iconClassName = SPIN_CLASS_NAME;
			} else if (hasErrored) {
				icon = ICON_ERROR;
				title = TITLE_ERROR;
			} else if (isComplete && !hasErrored) {
				icon = ICON_SUCCESS;
				title = TITLE_SUCCESS;
			}

			return [icon, title, iconClassName]
		},
		[isComplete, isLoading, hasErrored]
	);

	return (
		<Modal open={isOpen} onClose={onClose} className="GenerateHeatmapModal">
			<>
				<div className="head">
					<Icon icon={headIcon} className={iconClassName} size="large" />
				</div>

				<div className="body">
					<h3 className="title">{title}</h3>

					<div className="small-text">
						{sampleSetUpload.hasErrored && sampleSetUpload.hasErrored.error.detail}<br/>
						{propertyMap.hasErrored && propertyMap.hasErrored.error.detail}
					</div>

					<div>
						<Button className="download-button" onClick={onDownload}>Download Files <Icon icon="download" size="large" /></Button>
					</div>

					<div>
						<a className="link" onClick={close}>Back to Editor</a>
					</div>
				</div>
			</>

		</Modal>
	);
};


GenerateHeatmapModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onDownload: PropTypes.func,
	onGenerateNew: PropTypes.func
};


GenerateHeatmapModal.defaultProps = {
	onDownload: () => { },
	onGenerateNew: () => { }
}


export default GenerateHeatmapModal;
