import { createSlice } from "@reduxjs/toolkit";

export const getInitialState = () => ({
	dict: {},
	tests: {}
})

export const StatsSlice = createSlice({
	name: "stats",
	initialState: getInitialState(),

	reducers: {
		setStats: (state, action) => {
			state.dict = action.payload;
			
		},
		setTests: (state, action) => {
			let dict = action.payload;
			let tests = [];
			let order = 0;
			Object.values(dict).forEach(stat => {
				const name = stat.test;
				if (!(name in tests)) {
					tests[name] = { name, order };
					order++;
				}
			});
			state.tests = tests;
		},
		updateStats: (state, action) => {
			state.dict = { ...state.dict, ...action.payload };
		},
		updateTests: (state, action) => {
			state.tests = { ...state.tests, ...action.payload };
		}
	}
});
export const {
	setStats,
	updateStats,
} = StatsSlice.actions;
export const stats = StatsSlice.reducer;