import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";
import { selectPreview } from "../../../redux/selectors/editor.selector";
import { Switch } from "../../../components/controls/Switch.jsx";
import { selectMetabolites } from "../../../redux/selectors/project.selector";
import { metabolitesFetch } from "../../../redux/reducers/project.reducer";
import { setPreview } from "../../../redux/reducers/editor.reducer";
import { Stack, Typography } from "@mui/material";

export const PreviewSwitch = ( {sampleSetId} ) => {

	const dispatch = useDispatch();
	const preview = useSelector(selectPreview);
	const { data: selectedSampleMetabolites, isLoading: metabolitesIsLoading, complete: metabolitesComplete } = useSelector(selectMetabolites);

	const togglePreviewClick = useCallback(() => {
		if (!preview && !metabolitesIsLoading && !selectedSampleMetabolites) {
			dispatch(metabolitesFetch({ sampleSetId }))
		}
		dispatch(setPreview(!preview));
	}, [dispatch, preview, sampleSetId, selectedSampleMetabolites, metabolitesComplete, metabolitesIsLoading]);

	return (
		<Stack justifyContent="center" alignItems="center" className="preview-switcher">
			<Typography variant="caption" className="column-header">PREVIEW</Typography>
			<Switch textAlign="center" checked={preview} onChange={togglePreviewClick} />
		</Stack>
	)
}