
import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Input from "@/components/controls/Input.jsx";
import Icon from "@/components/controls/Icon.jsx";
import Button from "@/components/buttons/Button.jsx";
import { useDebounce } from "@/hooks/useDebounce";
import * as styles from "./search.module.scss";
import { resultsClear } from "../redux/reducers/search.reducer.js";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";

export const Search = ({
	className = "",
	isLoading = false,
	minQueryLength = 3,
	onSearch = null,
	placeholder = "",
	results = [],
	resultComponent = null,
	searchDelay = 500,
	searchOnInput = false,
}) => {
	const dispatch = useDispatch();
	const [query, setQuery] = useState("")
	const debouncedValue = useDebounce(query, searchDelay);

	useEffect(() => {
		if (!searchOnInput) return;
		search(debouncedValue);
	}, [debouncedValue, searchOnInput]);

	const search = useCallback((searchQuery) => {
		const canSearch = minQueryLength <= 0 || searchQuery.length >= minQueryLength;
		if (!onSearch || !searchQuery || !canSearch)
			return;
		onSearch(searchQuery);
	}, [onSearch]);

	const onChange = useCallback((e) => {
		setQuery(e.target.value);
	}, []);

	const onSubmit = useCallback(() => {
		search(query);
	}, [search, query]);

	const clear = useCallback(() => {
		setQuery("");
		dispatch(resultsClear());
	}, [dispatch]);

	const onKeyDown = useCallback((e) => {
		if (e.code === 'Escape') clear();
		if (e.code === 'Enter' || e.code === "NumpadEnter") onSubmit();
	}, [onSubmit, clear]);

	const Result = resultComponent || DefaultResult
	return (
		<Stack direction="row" alignItems="center" className={classNames(styles.search, "search", className)} >
			<Input
				className={classNames(styles.input, "input")}
				placeholder={placeholder}
				onChange={onChange}
				onKeyDown={onKeyDown}
				value={query}
				data-testid="search-input"
			/>
			{!searchOnInput && <Button className={styles.button} onClick={onSubmit} testId="search-button">
				Search&nbsp;
				{!isLoading && <Icon data-testid="search-icon" className="ml-2" icon="search" size="large" />}
				{isLoading && <Icon className="ml-2 fa-spin" icon="spinner" size="large" /> }
			</Button>}
			{results && results.length > 0 &&
				<div className={classNames(styles.results, "results")}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>CHOOSE PROJECT</div>
						<div className={styles.clear} onClick={clear}>Clear</div>
					</div>
					{results.map((result, i) =>
						<Result className={styles.result} key={i} data={result} />)
					}
				</div>
			}
		</Stack>
	);
};

const DefaultResult = ({
	className,
	text
}) => <div className={className}>{text}</div>;