'use strict';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '@/components/controls/Icon.jsx';
import * as styles from './Switch.module.scss';
import { Typography } from '@mui/material';

export const Switch = ({
	className,
	defaultIsChecked = false,
	checked = null,
	name,
	onChange,
	value,
	...props
}) => {
	const [isChecked, setIsChecked] = useState(checked !== null ? checked : defaultIsChecked);
	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const onClick = useCallback(() => {
		if (onChange) onChange({ target: { name, value, isChecked: !isChecked } });
	}, [isChecked, name, onChange, value]);

	return (
		<Typography component="span" data-testid="switch" className={classNames(className, styles.switch)} onClick={onClick} {...props}>
			<Icon size={'xlarge'} icon={isChecked ? 'toggle-on' : 'toggle-off'} />
		</Typography>
	)
}