import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { useStore } from './redux/store.js'
import Editor from './pages/editor/Editor.jsx'
import SettingsMap from './utils/SettingsMap.js'
import SearchView from './pages/SearchView.jsx'

const Hgt = ({ match }) => (
	<ReduxWrapper>
		<Switch>
			<Route path={match.url} exact>
				<SearchView />
			</Route>
			<Route path={match.url + "/:projectCode/:sampleSetId"}>
				<Editor />
			</Route>
		</Switch>
	</ReduxWrapper>
)

const ReduxWrapper = ({ children }) => {
	SettingsMap.initMap()
	const store = useStore()
	return (
		<Provider store={store}>
			{children}
		</Provider>
	)
}

export default Hgt