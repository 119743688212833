import { all } from 'redux-saga/effects';
import { 
	searchSagas,
} from './search.saga';
import { 
	sampleSetSagas,
	makeHeatmapSagas,
	uploadPropertyMapSagas,
	fetchLatestPropertyMapSagas,
	statsSagas,
	statsUploadSagas,
	metabolitesSagas,
	sampleSagas
} from './project.saga';

function* rootSaga() {
	try {
		yield all([
			searchSagas(),
			statsSagas(),
			statsUploadSagas(),
			metabolitesSagas(),
			sampleSagas(),
			sampleSetSagas(),
			makeHeatmapSagas(),
			uploadPropertyMapSagas(),
			fetchLatestPropertyMapSagas()
		]);
	} catch (error) {// eslint-disable-next-line
		console.error('rootSaga error:', error)
	}
} 

export default rootSaga