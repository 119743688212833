import { createSelector } from '@reduxjs/toolkit';
import { createRequestSelectors } from "../helpers/selector.helpers";

export const {
	selectProjectState,

	selectSample,
	selectSampleComplete,
	selectSampleData,
	selectSampleIsLoading,
	selectSampleHasErrored,

	selectSampleSet,
	selectSampleSetComplete,
	selectSampleSetData,
	selectSampleSetIsLoading,
	selectSampleSetHasErrored,

	selectSampleSets,
	selectSampleSetsComplete,
	selectSampleSetsData,
	selectSampleSetsIsLoading,
	selectSampleSetsHasErrored,

	selectSampleSetUpload,
	selectSampleSetUploadComplete,
	selectSampleSetUploadData,
	selectSampleSetUploadIsLoading,
	selectSampleSetUploadHasErrored,

	selectStats,
	selectStatsComplete,
	selectStatsData,
	selectStatsIsLoading,
	selectStatsHasErrored,

	selectStatsUpload,
	selectStatsUploadComplete,
	selectStatsUploadData,
	selectStatsUploadIsLoading,
	selectStatsUploadHasErrored,

	selectMetabolites,
	selectMetabolitesComplete,
	selectMetabolitesData,
	selectMetabolitesIsLoading,
	selectMetabolitesHasErrored,

	selectPropertyMap,
	selectPropertyMapComplete,
	selectPropertyMapData,
	selectPropertyMapIsLoading,
	selectPropertyMapHasErrored,

	selectPropertyMapUpload,
	selectPropertyMapUploadComplete,
	selectPropertyMapUploadData,
	selectPropertyMapUploadIsLoading,
	selectPropertyMapUploadHasErrored

} = createRequestSelectors("project", [
	"metabolites",
	"sample",
	"sampleSet",
	"sampleSets",
	"sampleSetUpload",
	"stats",
	"statsUpload",
	"propertyMap",
	"propertyMapUpload"
]);

export const selectProjectData = createSelector(
	selectProjectState,
	state => state.data
);
