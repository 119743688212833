'use strict';
import classNames from 'classnames';
import React from 'react';
import { useResize } from '@/hooks/window.hooks.jsx';
import * as styles from './flyout.module.scss';

export const Flyout = ({
	className,
	children,
	isActive = false
}) => {

	const [resizeListener, size] = useResize();

	return (
		<div data-testid="flyout-div" className={classNames(styles.flyout, className)} style={isActive ? { left: `calc(100% - ${size.width}px + 1rem)` } : null}>
			{children}
			{resizeListener}

		</div>
	)
}

export default Flyout;