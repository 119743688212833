import SamplesetUtils from '../../utils/SamplesetUtils';
import {
	sampleFetch,
	sampleFetchSuccess,
	sampleFetchFailed,
	sampleSetFetch,
	sampleSetFetchSuccess,
	sampleSetFetchFailed,
	sampleSetUpload,
	sampleSetUploadSuccess,
	sampleSetUploadFailed,
	statsFetch,
	statsFetchSuccess,
	statsFetchFailed,
	statsUpload,
	statsUploadSuccess,
	statsUploadFailed,
	metabolitesFetch,
	metabolitesFetchSuccess,
	metabolitesFetchFailed,
	propertyMapUpload,
	propertyMapUploadSuccess,
	propertyMapUploadFailed,
	propertyMapFetchLatest,
	propertyMapFetchLatestSuccess,
	propertyMapFetchLatestFailed,
} from "../reducers/project.reducer";
import { createRequestSagas } from '../helpers/saga.helpers';
import {
	fetchStats,
	uploadStats,
	fetchMetabolites,
	fetchSampleSet,
	fetchSample,
	generateHeatmap,
	uploadPropertyMap,
	fetchLatestPropertyMap,
	mockFetchSampleSet,
	mockGenerateHeatmap,
	mockUploadPropertyMap,
	mockFetchLatestPropertyMap,
	isMock
} from "../../api";

export const {
	statsSaga,
	statsSagas,
} = createRequestSagas("stats",
	fetchStats,
	statsFetch,
	statsFetchSuccess,
	statsFetchFailed,
	null,
	//SamplesetUtils.parseSampleSet
);

export const {
	statsUploadSaga,
	statsUploadSagas,
} = createRequestSagas("statsUpload",
	uploadStats,
	statsUpload,
	statsUploadSuccess,
	statsUploadFailed,
	null,
	//SamplesetUtils.parseSampleSet
);

export const {
	metabolitesSaga,
	metabolitesSagas,
} = createRequestSagas("metabolites",
	fetchMetabolites,
	metabolitesFetch,
	metabolitesFetchSuccess,
	metabolitesFetchFailed,
	null,
	//SamplesetUtils.parseSampleSet
);

export const {
	sampleSaga,
	sampleSagas,
} = createRequestSagas("sample",
	fetchSample,
	sampleFetch,
	sampleFetchSuccess,
	sampleFetchFailed,
	null,
	//SamplesetUtils.parseSampleSet
);



export const {
	sampleSetSaga,
	sampleSetSagas,
} = createRequestSagas("sampleSet",
	isMock ? mockFetchSampleSet : fetchSampleSet,
	sampleSetFetch,
	sampleSetFetchSuccess,
	sampleSetFetchFailed,
	null,
	SamplesetUtils.parseSampleSet
);

export const {
	uploadPropertyMapSaga,
	uploadPropertyMapSagas,
} = createRequestSagas("uploadPropertyMap",
	isMock ? mockUploadPropertyMap : uploadPropertyMap,
	propertyMapUpload,
	propertyMapUploadSuccess,
	propertyMapUploadFailed,
);

export const {
	fetchLatestPropertyMapSaga,
	fetchLatestPropertyMapSagas,
} = createRequestSagas("fetchLatestPropertyMap",
	isMock ? mockFetchLatestPropertyMap : fetchLatestPropertyMap,
	propertyMapFetchLatest,
	propertyMapFetchLatestSuccess,
	propertyMapFetchLatestFailed,
);

export const {
	makeHeatmapSaga,
	makeHeatmapSagas,
} = createRequestSagas("makeHeatmap",
	isMock ? mockGenerateHeatmap : generateHeatmap,
	sampleSetUpload,
	sampleSetUploadSuccess,
	sampleSetUploadFailed,
);