import { createSlice } from "@reduxjs/toolkit"
import { addRequest } from "../helpers/reducer.helpers";

const slice = {
	name: "search",
	initialState: {},
	reducers: {}
};
addRequest(slice, "results", []);

const SearchSlice = createSlice(slice);

export const {
	resultsClear,
	resultsFetch,
	resultsFetchFailed,
	resultsFetchSuccess,
} = SearchSlice.actions;
export const search = SearchSlice.reducer;