export const processStatDetails = (metabolites, statsDict, testField = 'foldChange', trends = null) => {
	return metabolites.map(metabolite => {

		let chemName = metabolite.chemicalName,
			isUnknown = chemName.toLowerCase().includes("unknown");

		return processMetaboliteStats(metabolite, isUnknown, testField, statsDict, trends);
	});
}

export const processMetaboliteStats = (metabolite, isUnknown, testField, statsDict, trends = null) => {
	metabolite.statsDetails.forEach(mStats => {
		//mStats and stats[n] DO NOT MATCH!!!!
		let stat = statsDict[mStats.statsId];
		if (!stat) return;
		let id = stat.statsId, statsField = 'detail-' + id;
		metabolite[statsField] = mStats;
		//get the statistical value being used, typically the pValue
		let status = determineDetailStatus(mStats, stat, testField);

		metabolite[statsField].status = status;
		metabolite[statsField].effectTest = stat.isMainEffectTest || stat.selfCompare || stat.studyType.indexOf('effect') >= 0;
		metabolite[statsField].studyType = stat.studyType;

		//collect enrichment data
		if (trends) collectEnrichmentData(metabolite, trends[id], status, isUnknown);
	});
	return metabolite;
}

export const determineDetailStatus = (detail, stat, testField) => {
	const val = detail[testField];
	const num = stat.swapped ? 1 / Number(val) : Number(val);
	const pVal = detail['pValue'] == null ? detail['pValue'] : Number(detail['pValue']);

	//set trend status based on where the value falls within the mins/maxes and signifiers
	let status = 'nil';

	//detect zscore test...
	if (stat.test.toLowerCase() == 'zscore') {
		if (isNaN(val) || val === null) status = 'dead';
		else if (num >= 1.5) {
			if (num < 2.0) status = 'trendUp';
			else status = 'sigUp';
		}
		else if (num <= -1.5) {
			if (num > -2.0) status = 'trendDown';
			else status = 'sigDown';
		}

	}
	else {
		if (stat.studyType.indexOf('effect') >= 0) {
			if (0.05 >= pVal) status = 'sigUp';
			else if (0.05 < pVal && pVal < 0.1) status = 'trendUp';
		}
		else if (isNaN(val) || val === null) status = 'blank';
		else if (!isNaN(val) && pVal !== null) {
			if (num > 1) {
				if (0.05 >= pVal) status = 'sigUp';
				else if (0.05 < pVal && pVal < 0.1) status = 'trendUp';
			}
			else {
				if (0.05 >= pVal) status = 'sigDown';
				else if (0.05 < pVal && pVal < 0.1) status = 'trendDown';
			}
		}
	}

	return status;
}

export const collectEnrichmentData = (metabolite, trend, status, isUnknown) => {
	//get an identifier for the metabolite to use as a lookup key
	let chemId = metabolite.chemicalId,
		chemName = isUnknown ? "Unknown Compounds" : metabolite.chemicalName,
		mId = metabolite.metaboliteId;

	if (isUnknown) trend[status].push(mId);
	else {
		trend[status].push(chemId);
		trend.count++;
	}
	//skip counts if unknown
	if (isUnknown) return;

	//register compounds and increment counts for totals
	if (!(chemId in trend.compounds)) {
		trend.compounds[chemId] = { name: chemName, count: 1 };
	}
	else {
		trend.compounds[chemId].count++;
	}

	//register pathways and increment counts for totals and significance
	if (metabolite.subPathway) {
		let sub = metabolite.subPathway;
		if (!(sub in trend.pathways)) {
			trend.pathways[sub] = { name: sub, count: 1, sig: 0 };
		}
		else {
			trend.pathways[sub].count++;
		}
		if (status.indexOf('sig') == 0) {
			trend.pathways[sub].sig++;
			trend.sig++;
		}
	}

	return trend;
}