import { useEffect } from "react";

const useWindowUnload = (confirmationMessage, changesSaved) => {
 
  useEffect(() => {
    const onUnload = (e) => {
      if (changesSaved) return null;
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage;                           //Webkit, Safari, Chrome
    };
    
    //window.addEventListener("beforeunload", onUnload);
    window.onbeforeunload = onUnload;

    return () => {
      //window.removeEventListener("beforeunload", onUnload);
      window.onbeforeunload = null;
    };
  }, [changesSaved]);
};

export default useWindowUnload;