import React from 'react';
import classNames from 'classnames';
import * as styles from './editor/editor.module.scss'
import { SearchHgt } from '../containers/SearchHgt.jsx';

function SearchView() {

	return (
		<div data-testid="editor" className={classNames(styles.Editor, styles.page)}>
			<SearchHgt />
		</div>
	);
}

export default SearchView;
