import { prefixCamelCase } from "@/utils/StringUtils";

export const createRequestState = (initialData = null, resourceName=null) => {
	const state = {
		complete: false,
		data: initialData,
		isLoading: false,
		hasErrored: null,
	};
	if (!resourceName) return state;
	return { [resourceName]: state };
};

export const createRequestAction = (resourceName, initialData = null) => {
	return (state) => {
		state[resourceName].data = initialData;
		state[resourceName].complete = false;
		state[resourceName].isLoading = true;
		state[resourceName].hasErrored = false;
	};
};

export const createSuccessAction = (resourceName) => {
	return (state, action) => {
		state[resourceName].isLoading = false;
		state[resourceName].hasErrored = false;
		state[resourceName].complete = true;
		state[resourceName].data = action.payload;
	};
};

export const createFailedAction = (resourceName) => {
	return (state, action) => {
		state[resourceName].isLoading = false;
		state[resourceName].complete = true;
		state[resourceName].hasErrored = action.payload;
	};
};

export const createClearAction = (resourceName, initialData = null) => {
	return (state) => {
		state[resourceName].data = initialData;
		state[resourceName].complete = false;
		state[resourceName].isLoading = false;
		state[resourceName].hasErrored = false;
	};
};

export const createRequestActions = (resourceName, requestType = "fetch", initialData = null) => {
	const actionPrefix = prefixCamelCase(requestType, resourceName);
	return {
		[`${actionPrefix}`]: createRequestAction(resourceName, initialData),
		[`${actionPrefix}Success`]: createSuccessAction(resourceName),
		[`${actionPrefix}Failed`]: createFailedAction(resourceName),
		[`${resourceName}Clear`]: createClearAction(resourceName, initialData)
	};
};

export const addRequest = (slice, resourceName, initialData = null, requestType = "fetch") => {
	if (!slice.initialState) slice.initialState = {};
	if (!slice.reducers) slice.reducers = {};
	slice.initialState[resourceName] = createRequestState(initialData);

	const actions = createRequestActions(resourceName, requestType, initialData);
	Object.entries(actions).forEach(([key, value]) => {
		slice.reducers[key] = value;
	});
};

export const addRequestAction = (slice, resourceName, requestName, requestType = "fetch") => {
	requestName = requestName || resourceName;
	requestName = prefixCamelCase(requestType, requestName);
	const action = createRequestAction(resourceName);
	slice.reducers[`${requestName}`] = action;
};