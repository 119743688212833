import { createSelector } from "@reduxjs/toolkit";

const selectEditorState = createSelector(
	state => state,
	state => state.editor
);

export const selectReorder = createSelector(
    selectEditorState,
    state => state.reorder
);

export const selectPreview = createSelector(
    selectEditorState,
    state => state.preview
);

export const selectDuplicates = createSelector(
    selectEditorState,
    state => state.duplicates
);

export const selectTitle = createSelector(
    selectEditorState,
    state => state.title
);

export const selectChangesSaved = createSelector(
    selectEditorState,
    state => state.changesSaved
);

export const selectTrends = createSelector(
    selectEditorState,
    state => state.trends
);

export const selectStudyType = createSelector(
    selectEditorState,
    state => state.studyType
);