export default {
	"00003aa3-0000-0000-0000-000000000000": {
		"propertyMap": {
			"lastModified": 1659703618554,
			"sampleSetId": "00003aa3-0000-0000-0000-000000000000",
			"sample": {
				"customerName": ""
			},
			"sequences": {
				"1": {
					"tests": [
						"3b9c6b02-0000-0000-0000-000000000000",
						"3b9c6b03-0000-0000-0000-000000000000",
						"3b9c6b04-0000-0000-0000-000000000000"
					],
					"order": 1,
					"name": 1
				},
				"3": {
					"tests": [
						"3b9c6b05-0000-0000-0000-000000000000",
						"3b9c6b06-0000-0000-0000-000000000000",
						"3b9c6b07-0000-0000-0000-000000000000",
						"3b9c6b08-0000-0000-0000-000000000000",
						"3b9c6b09-0000-0000-0000-000000000000",
						"3b9c6b0a-0000-0000-0000-000000000000",
						"3b9c6b0b-0000-0000-0000-000000000000",
						"3b9c6b0c-0000-0000-0000-000000000000",
						"3b9c6b0d-0000-0000-0000-000000000000",
						"3b9c6b0e-0000-0000-0000-000000000000",
						"3b9c6b0f-0000-0000-0000-000000000000",
						"3b9c6b10-0000-0000-0000-000000000000",
						"3b9c6b11-0000-0000-0000-000000000000",
						"3b9c6b12-0000-0000-0000-000000000000",
						"3b9c6b13-0000-0000-0000-000000000000",
						"3b9c6b14-0000-0000-0000-000000000000",
						"3b9c6b15-0000-0000-0000-000000000000",
						"3b9c6b16-0000-0000-0000-000000000000"
					],
					"order": 0,
					"name": 3
				},
				"5": {
					"tests": [
						"3b9c6b19-0000-0000-0000-000000000000"
					],
					"order": 2,
					"name": 5
				},
				"6": {
					"tests": [
						"3b9c6b18-0000-0000-0000-000000000000"
					],
					"order": 3,
					"name": 6
				},
				"7": {
					"tests": [
						"3b9c6b17-0000-0000-0000-000000000000"
					],
					"order": 4,
					"name": 7
				}
			},
			"stats": {
				"3b9c6b02-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b02-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
					"comparison": "FTEST - PARAM_DIET",
					"reportName": "Diet Main Effect",
					"foldChange": "(G1)/(G1)",
					"testSequence": 1,
					"compSequence": 1,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 0,
					"customerVisible": false,
					"studyType": "effect",
					"swapped": false,
					"customerSequence": 1
				},
				"3b9c6b03-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b03-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
					"comparison": "FTEST - PARAM_TIME_POINT",
					"reportName": "Time Point Main Effect",
					"foldChange": "(G19)/(G19)",
					"testSequence": 1,
					"compSequence": 2,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 1,
					"customerVisible": false,
					"studyType": "effect",
					"swapped": false,
					"customerSequence": 2
				},
				"3b9c6b04-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b04-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
					"comparison": "FTEST - PARAM_DIET:PARAM_TIME_POINT",
					"reportName": "Diet:Time Point Interaction",
					"foldChange": "(G2)/(G2)",
					"testSequence": 1,
					"compSequence": 3,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 2,
					"customerVisible": true,
					"studyType": "effect",
					"swapped": false,
					"customerSequence": 3
				},
				"3b9c6b05-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b05-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "CONTROL:2_WEEKS VS CONTROL:BASELINE",
					"reportName": "CRC Cntr 2W//CRC Cntr Base",
					"foldChange": "AVG_RAT_G22TOG19",
					"testSequence": 3,
					"compSequence": 1,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 3,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 1
				},
				"3b9c6b06-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b06-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "CONTROL:4_WEEKS VS CONTROL:BASELINE",
					"reportName": "CRC Cntr 4W//CRC Cntr Base",
					"foldChange": "AVG_RAT_G25TOG19",
					"testSequence": 3,
					"compSequence": 2,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 4,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 2
				},
				"3b9c6b07-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b07-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "CONTROL:4_WEEKS VS CONTROL:2_WEEKS",
					"reportName": "CRC Cntr 4W//CRC Cntr 2W",
					"foldChange": "AVG_RAT_G25TOG22",
					"testSequence": 3,
					"compSequence": 3,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 5,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 3
				},
				"3b9c6b08-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b08-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:2_WEEKS VS NAVY_BEANS:BASELINE",
					"reportName": "CRC NB 2W//CRC NB Base",
					"foldChange": "AVG_RAT_G23TOG20",
					"testSequence": 3,
					"compSequence": 4,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 6,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 4
				},
				"3b9c6b09-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b09-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:4_WEEKS VS NAVY_BEANS:BASELINE",
					"reportName": "CRC NB 4W//CRC NB Base",
					"foldChange": "AVG_RAT_G26TOG20",
					"testSequence": 3,
					"compSequence": 5,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 7,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 5
				},
				"3b9c6b0a-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:4_WEEKS VS NAVY_BEANS:2_WEEKS",
					"reportName": "CRC NB 4W//CRC NB 2W",
					"foldChange": "AVG_RAT_G26TOG23",
					"testSequence": 3,
					"compSequence": 6,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 8,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 6
				},
				"3b9c6b0b-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:2_WEEKS VS RICE_BRAN:BASELINE",
					"reportName": "CRC RB 2W//CRC RB Base",
					"foldChange": "AVG_RAT_G24TOG21",
					"testSequence": 3,
					"compSequence": 7,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 9,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 7
				},
				"3b9c6b0c-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:4_WEEKS VS RICE_BRAN:BASELINE",
					"reportName": "CRC RB 4W//CRC RB Base",
					"foldChange": "AVG_RAT_G27TOG21",
					"testSequence": 3,
					"compSequence": 8,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 10,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 8
				},
				"3b9c6b0d-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:4_WEEKS VS RICE_BRAN:2_WEEKS",
					"reportName": "CRC RB 4W//CRC RB 2W",
					"foldChange": "AVG_RAT_G27TOG24",
					"testSequence": 3,
					"compSequence": 9,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 11,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 9
				},
				"3b9c6b0e-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:BASELINE VS CONTROL:BASELINE",
					"reportName": "CRC NB Base//CRC Cntr Base",
					"foldChange": "(G20)/(G19)",
					"testSequence": 3,
					"compSequence": 10,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 12,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 10
				},
				"3b9c6b0f-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:BASELINE VS CONTROL:BASELINE",
					"reportName": "CRC RB Base//CRC Cntr Base",
					"foldChange": "(G21)/(G19)",
					"testSequence": 3,
					"compSequence": 11,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 13,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 11
				},
				"3b9c6b10-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b10-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:BASELINE VS NAVY_BEANS:BASELINE",
					"reportName": "CRC RB Base//CRC NB Base",
					"foldChange": "(G21)/(G20)",
					"testSequence": 3,
					"compSequence": 12,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 14,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 12
				},
				"3b9c6b11-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b11-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:2_WEEKS VS CONTROL:2_WEEKS",
					"reportName": "CRC NB 2W//CRC Cntr 2W",
					"foldChange": "(G23)/(G22)",
					"testSequence": 3,
					"compSequence": 13,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 15,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 13
				},
				"3b9c6b12-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b12-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:2_WEEKS VS CONTROL:2_WEEKS",
					"reportName": "CRC RB 2W//CRC Cntr 2W",
					"foldChange": "(G24)/(G22)",
					"testSequence": 3,
					"compSequence": 14,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 16,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 14
				},
				"3b9c6b13-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b13-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:2_WEEKS VS NAVY_BEANS:2_WEEKS",
					"reportName": "CRC RB 2W//CRC NB 2W",
					"foldChange": "(G24)/(G23)",
					"testSequence": 3,
					"compSequence": 15,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 17,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 15
				},
				"3b9c6b14-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b14-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "NAVY_BEANS:4_WEEKS VS CONTROL:4_WEEKS",
					"reportName": "CRC NB 4W//CRC Cntr 4W",
					"foldChange": "(G26)/(G25)",
					"testSequence": 3,
					"compSequence": 16,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 18,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 16
				},
				"3b9c6b15-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b15-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:4_WEEKS VS CONTROL:4_WEEKS",
					"reportName": "CRC RB 4W//CRC Cntr 4W",
					"foldChange": "(G27)/(G25)",
					"testSequence": 3,
					"compSequence": 17,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 19,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 17
				},
				"3b9c6b16-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b16-0000-0000-0000-000000000000",
					"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
					"comparison": "RICE_BRAN:4_WEEKS VS NAVY_BEANS:4_WEEKS",
					"reportName": "CRC RB 4W//CRC NB 4W",
					"foldChange": "(G27)/(G26)",
					"testSequence": 3,
					"compSequence": 18,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 20,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 18
				},
				"3b9c6b17-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b17-0000-0000-0000-000000000000",
					"test": "WELCH TWO-SAMPLE T-TEST COMPARED G7TOG27",
					"comparison": "7 VS 27",
					"reportName": "CRC RB 4w//Child RB 4w",
					"foldChange": "(G27)/(G7)",
					"testSequence": 7,
					"compSequence": 1,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 21,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 1
				},
				"3b9c6b18-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b18-0000-0000-0000-000000000000",
					"test": "WELCH TWO-SAMPLE T-TEST COMPARED G6TOG26",
					"comparison": "6 VS 26",
					"reportName": "CRC NB 4w//Child NB 4w",
					"foldChange": "(G26)/(G6)",
					"testSequence": 6,
					"compSequence": 1,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 22,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 1
				},
				"3b9c6b19-0000-0000-0000-000000000000": {
					"statsId": "3b9c6b19-0000-0000-0000-000000000000",
					"test": "WELCH TWO-SAMPLE T-TEST COMPARE G5TOG25",
					"comparison": "5 VS 25",
					"reportName": "CRC Cntr 4w//Child Cntr 4w",
					"foldChange": "(G25)/(G5)",
					"testSequence": 5,
					"compSequence": 1,
					"enrichment": null,
					"customerName": "",
					"customerIndex": 23,
					"customerVisible": true,
					"studyType": "standard",
					"swapped": false,
					"customerSequence": 1
				}
			}
		}
	}
}