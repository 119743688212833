const data = [
	{
		"sampleSetId": "00003aa3-0000-0000-0000-000000000000",
		"projectId": "0000168b-0000-0000-0000-000000000000",
		"sample": {
			"sampleId": "00003aa3-0000-0000-0000-000000000000",
			"name": "Effect of diet on cancer survivors, serum COSU-0201-15VWBL",
			"organism": "MAMMAL",
			"species": "HUMAN",
			"matrix": "BLOOD",
			"matrixType": "SERUM"
		},
		"metabolites": [
			{
				"metaboliteId": "0078da99-0000-0000-0000-000000000000",
				"chemicalId": "05f5e687-0000-0000-0000-000000000000",
				"chemicalName": "N6-carbamoylthreonyladenosine",
				"plotName": null,
				"compoundId": "00008955-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB41623",
				"inChiKey": "GYCVHQYQICRFAX-GQFURFNTBM",
				"kegg": null,
				"mass": 412.134262,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3785,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".948952619420936",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".124075469329865",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".450871086634668",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".127368404634552",
						"qValue": "1",
						"foldChange": "0.9059"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".458069983220489",
						"qValue": "1",
						"foldChange": "0.9313"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".425726387461428",
						"qValue": "1",
						"foldChange": "1.1755"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".802136255803119",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".114001545795882",
						"qValue": ".707436023994459",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".068764869139204",
						"qValue": ".785109179715988",
						"foldChange": "0.8882"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".355699578961596",
						"qValue": "1",
						"foldChange": "0.9172"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".223274455241939",
						"qValue": "1",
						"foldChange": "0.8966"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".76453372519672",
						"qValue": "1",
						"foldChange": "0.9248"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".909419646478025",
						"qValue": "1",
						"foldChange": "0.9806"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".788174313788734",
						"qValue": "1",
						"foldChange": "1.0124"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".700061882708017",
						"qValue": "1",
						"foldChange": "1.0325"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".270876233818474",
						"qValue": "1",
						"foldChange": "1.1701"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".528460130762033",
						"qValue": "1",
						"foldChange": "1.1032"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".669935617095588",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".51945292900673",
						"qValue": "1",
						"foldChange": "0.9338"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".92581292799566",
						"qValue": "1",
						"foldChange": "0.9232"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".596648588720976",
						"qValue": ".985830071188835",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".0008619714493",
						"qValue": ".002339267509803",
						"foldChange": "2.1539"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000746491994083",
						"qValue": ".001927376258749",
						"foldChange": "2.0805"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00025696150576",
						"qValue": ".000392445008508",
						"foldChange": "2.0457"
					}
				]
			},
			{
				"metaboliteId": "0078da9a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c4-0000-0000-0000-000000000000",
				"chemicalName": "isovalerate (i5:0)",
				"plotName": null,
				"compoundId": "0000ae70-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00718",
				"inChiKey": "GWYFCOCPABKNJV-UHFFFAOYAV",
				"kegg": "C08262",
				"mass": 102.06808,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 304,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".763385124696356",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".84078200333434",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".961565617038665",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".706768957314163",
						"qValue": "1",
						"foldChange": "12.1393"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".656520117892049",
						"qValue": "1",
						"foldChange": "1.9632"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".945224708175086",
						"qValue": "1",
						"foldChange": "10.0986"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".818183603637308",
						"qValue": "1",
						"foldChange": "8.7903"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".817675509991285",
						"qValue": ".991769031882419",
						"foldChange": "12.8300"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".645491954199422",
						"qValue": ".973034272612281",
						"foldChange": "8.5920"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".995759369056863",
						"qValue": "1",
						"foldChange": "4.4097"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".546156669950222",
						"qValue": "1",
						"foldChange": "3.1440"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".542648114686299",
						"qValue": "1",
						"foldChange": "7.1306"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".719128759110144",
						"qValue": "1",
						"foldChange": "0.8908"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".396887030010377",
						"qValue": "1",
						"foldChange": "0.5759"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".602311388892186",
						"qValue": "1",
						"foldChange": "0.6465"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".850711435270312",
						"qValue": "1",
						"foldChange": "0.7616"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".639024467794225",
						"qValue": "1",
						"foldChange": "0.4254"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".765354718565579",
						"qValue": "1",
						"foldChange": "0.5585"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".719265492816194",
						"qValue": "1",
						"foldChange": "1.3223"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".791952727093112",
						"qValue": "1",
						"foldChange": "0.9796"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".937786764351207",
						"qValue": ".997573179629986",
						"foldChange": "0.7408"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002308699835066",
						"qValue": ".005095925278811",
						"foldChange": "0.2376"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001484974578107",
						"qValue": ".002742250837077",
						"foldChange": "0.2363"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001399623839474",
						"qValue": ".001323681244851",
						"foldChange": "0.2086"
					}
				]
			},
			{
				"metaboliteId": "0078da9b-0000-0000-0000-000000000000",
				"chemicalId": "000000e5-0000-0000-0000-000000000000",
				"chemicalName": "arachidonate (20:4n6)",
				"plotName": null,
				"compoundId": "00000456-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01043",
				"inChiKey": "YZXBAPSDXZZRGB-DOFZRALJBE",
				"kegg": "C00219",
				"mass": 304.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1400,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".624149159743417",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".235794399830654",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".608950811897932",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".720420741920733",
						"qValue": "1",
						"foldChange": "1.0921"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".751336643566434",
						"qValue": "1",
						"foldChange": "1.0668"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".967222127839498",
						"qValue": "1",
						"foldChange": "1.0771"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".893847540123549",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".109117135591454",
						"qValue": ".697458782960496",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".083644933330013",
						"qValue": ".796492767737576",
						"foldChange": "0.8710"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".57082862236132",
						"qValue": "1",
						"foldChange": "0.9632"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".184986643849955",
						"qValue": "1",
						"foldChange": "0.8725"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".442656932519886",
						"qValue": "1",
						"foldChange": "0.8989"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".816302984155622",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".683922771231783",
						"qValue": "1",
						"foldChange": "1.0178"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".521416746691869",
						"qValue": "1",
						"foldChange": "1.0641"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".683498284174653",
						"qValue": "1",
						"foldChange": "0.9714"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".778394455037694",
						"qValue": "1",
						"foldChange": "0.9199"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".914916299540691",
						"qValue": "1",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".105113098709384",
						"qValue": "1",
						"foldChange": "0.8693"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".403639434703597",
						"qValue": "1",
						"foldChange": "0.8327"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".465429017435936",
						"qValue": ".985830071188835",
						"foldChange": "0.9579"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".845764551629986",
						"qValue": ".358087832321377",
						"foldChange": "1.0604"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".675111643456085",
						"qValue": ".259300935537135",
						"foldChange": "1.1828"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000239209260771",
						"qValue": ".000379243018238",
						"foldChange": "1.4781"
					}
				]
			},
			{
				"metaboliteId": "0078da9c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fb-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetylglycine",
				"plotName": null,
				"compoundId": "00008499-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00821",
				"inChiKey": "UTYVDVLMYQPLQB-UHFFFAOYAZ",
				"kegg": "C05598",
				"mass": 193.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1040,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".541580857933592",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".992082047792618",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".373965286252173",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".780684926530733",
						"qValue": "1",
						"foldChange": "2.6590"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".145326832658195",
						"qValue": "1",
						"foldChange": "3.4412"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".235910231782269",
						"qValue": "1",
						"foldChange": "5.1923"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".786213248843722",
						"qValue": "1",
						"foldChange": "1.5621"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".628844214403765",
						"qValue": ".928868848501546",
						"foldChange": "1.2600"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".451428755229631",
						"qValue": ".957839854315834",
						"foldChange": "1.2585"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".486620560967569",
						"qValue": "1",
						"foldChange": "0.8763"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".267863566517625",
						"qValue": "1",
						"foldChange": "0.9628"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".676435682684169",
						"qValue": "1",
						"foldChange": "1.8310"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".89407506144638",
						"qValue": "1",
						"foldChange": "1.2919"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".836938301164638",
						"qValue": "1",
						"foldChange": "1.2940"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".934740652829019",
						"qValue": "1",
						"foldChange": "1.0016"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".908663835423216",
						"qValue": "1",
						"foldChange": "1.1289"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".526723648120701",
						"qValue": "1",
						"foldChange": "0.7105"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".447929151997675",
						"qValue": "1",
						"foldChange": "0.6294"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".124291256812111",
						"qValue": "1",
						"foldChange": "0.4466"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".05072534541663",
						"qValue": "1",
						"foldChange": "0.3371"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".596816142206078",
						"qValue": ".985830071188835",
						"foldChange": "0.7548"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".778589945351459",
						"qValue": ".341098964551616",
						"foldChange": "0.5004"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".016620077802829",
						"qValue": ".015561408031615",
						"foldChange": "3.8188"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".046683439663048",
						"qValue": ".01795591626777",
						"foldChange": "1.5030"
					}
				]
			},
			{
				"metaboliteId": "0078da9d-0000-0000-0000-000000000000",
				"chemicalId": "00000464-0000-0000-0000-000000000000",
				"chemicalName": "citrate",
				"plotName": null,
				"compoundId": "0000061c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00094",
				"inChiKey": "KRKNYBCHXYNGOX-UHFFFAOYAM",
				"kegg": "C00158",
				"mass": 192.027005,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1283,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".455762730013607",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".533324767089056",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".625501124959483",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".222622992520365",
						"qValue": "1",
						"foldChange": "6.9086"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".859857593751801",
						"qValue": "1",
						"foldChange": "0.9147"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".164078507111964",
						"qValue": "1",
						"foldChange": "0.8181"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".979737384229955",
						"qValue": "1",
						"foldChange": "2.7074"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".449435126264936",
						"qValue": ".901759479402622",
						"foldChange": "7.5494"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".43449683004918",
						"qValue": ".957839854315834",
						"foldChange": "9.8738"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".506593454871813",
						"qValue": "1",
						"foldChange": "2.6029"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".474888440587212",
						"qValue": "1",
						"foldChange": "4.7139"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".959504958057648",
						"qValue": "1",
						"foldChange": "3.6606"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".771196423433282",
						"qValue": "1",
						"foldChange": "1.3745"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".444557922195575",
						"qValue": "1",
						"foldChange": "0.2425"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".288173232712344",
						"qValue": "1",
						"foldChange": "0.1764"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".391739580864653",
						"qValue": "1",
						"foldChange": "0.5577"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".225051532448837",
						"qValue": "1",
						"foldChange": "0.2402"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".676272516936613",
						"qValue": "1",
						"foldChange": "0.4307"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".267863131863794",
						"qValue": "1",
						"foldChange": "1.9213"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".963159039383858",
						"qValue": "1",
						"foldChange": "0.9515"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".30449959799866",
						"qValue": ".985830071188835",
						"foldChange": "0.4952"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000052503065349",
						"qValue": ".000333697823959",
						"foldChange": "0.1471"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00095353681982",
						"qValue": ".002121444126035",
						"foldChange": "0.3012"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000055707840324",
						"qValue": ".000162845078659",
						"foldChange": "0.1538"
					}
				]
			},
			{
				"metaboliteId": "0078da9e-0000-0000-0000-000000000000",
				"chemicalId": "0000044b-0000-0000-0000-000000000000",
				"chemicalName": "guanosine",
				"plotName": null,
				"compoundId": "00000625-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00133",
				"inChiKey": "NYHBQMYGNKIUIF-UUOKFMHZBU",
				"kegg": "C00387",
				"mass": 283.09167,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3803,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".289883540720784",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".952620529181337",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".790643298639389",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".640196025433335",
						"qValue": "1",
						"foldChange": "2.5981"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".263701161688197",
						"qValue": "1",
						"foldChange": "0.7942"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".512071015864292",
						"qValue": "1",
						"foldChange": "1.3605"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".629348120432982",
						"qValue": "1",
						"foldChange": "1.5574"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".750486962323419",
						"qValue": ".960368665676976",
						"foldChange": "1.6755"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".868907869784422",
						"qValue": "1",
						"foldChange": "1.8493"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".791412038618022",
						"qValue": "1",
						"foldChange": "1.3690"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".601339812478774",
						"qValue": "1",
						"foldChange": "1.6224"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".795975173929721",
						"qValue": "1",
						"foldChange": "1.7126"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".692198281317924",
						"qValue": "1",
						"foldChange": "1.2990"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".662164359427417",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".948782998881285",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".24993718521414",
						"qValue": "1",
						"foldChange": "2.3923"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".310477020564575",
						"qValue": "1",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".938411927347352",
						"qValue": "1",
						"foldChange": "0.4163"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".120702822393898",
						"qValue": "1",
						"foldChange": "1.8932"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".085472589243709",
						"qValue": "1",
						"foldChange": "2.1139"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".790554612505562",
						"qValue": ".997573179629986",
						"foldChange": "1.1166"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".354852647007192",
						"qValue": ".196797943819081",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".667118606061224",
						"qValue": ".256584343679878",
						"foldChange": "0.2485"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".045819256832549",
						"qValue": ".017665787073329",
						"foldChange": "0.0915"
					}
				]
			},
			{
				"metaboliteId": "0078da9f-0000-0000-0000-000000000000",
				"chemicalId": "000004c8-0000-0000-0000-000000000000",
				"chemicalName": "cys-gly, oxidized",
				"plotName": null,
				"compoundId": "000047c0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KDJVKDYFFTWHBO-UHFFFAOYAK",
				"kegg": null,
				"mass": 354.06678,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".517777438958901",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".405451806375809",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".672411623559591",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".258236330243562",
						"qValue": "1",
						"foldChange": "1.1237"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".25339985519847",
						"qValue": "1",
						"foldChange": "0.8932"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".990586522968527",
						"qValue": "1",
						"foldChange": "1.1435"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".799036032472047",
						"qValue": "1",
						"foldChange": "1.0552"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".446548032681841",
						"qValue": ".900630141528144",
						"foldChange": "1.4616"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".311200585142076",
						"qValue": ".957839854315834",
						"foldChange": "1.4856"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".375546041890416",
						"qValue": "1",
						"foldChange": "0.9159"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".337637606036775",
						"qValue": "1",
						"foldChange": "0.8463"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".941256654346792",
						"qValue": "1",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".272800611559838",
						"qValue": "1",
						"foldChange": "0.7152"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".658189782247099",
						"qValue": "1",
						"foldChange": "1.0734"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".132273061937703",
						"qValue": "1",
						"foldChange": "1.5009"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".671483537235311",
						"qValue": "1",
						"foldChange": "0.6968"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".561213331122292",
						"qValue": "1",
						"foldChange": "0.9190"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".31616012316666",
						"qValue": "1",
						"foldChange": "1.3188"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".752388402069573",
						"qValue": "1",
						"foldChange": "1.0042"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".593510452464658",
						"qValue": "1",
						"foldChange": "0.9715"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".808990020574332",
						"qValue": ".997573179629986",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005168884326338",
						"qValue": ".009250643049471",
						"foldChange": "2.3453"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001448660183876",
						"qValue": ".002729417140557",
						"foldChange": "2.2190"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".194637622867931",
						"qValue": ".05451749659793",
						"foldChange": "1.7934"
					}
				]
			},
			{
				"metaboliteId": "0078daa0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e51b-0000-0000-0000-000000000000",
				"chemicalName": "1-methylhistidine",
				"plotName": null,
				"compoundId": "000076fc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00001",
				"inChiKey": "BRMWTNUJHUMWMS-LURJTMIEBU",
				"kegg": "C01152",
				"mass": 169.085127,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 68,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".672347032276478",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".235274825224406",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".673647194190559",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".19330979298513",
						"qValue": "1",
						"foldChange": "0.8958"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".156405710669758",
						"qValue": "1",
						"foldChange": "0.8708"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".904608176287102",
						"qValue": "1",
						"foldChange": "1.0640"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".555657148761582",
						"qValue": "1",
						"foldChange": "1.1170"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".90040783348928",
						"qValue": "1",
						"foldChange": "1.1376"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".47545967338649",
						"qValue": ".957839854315834",
						"foldChange": "1.0257"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".352156689330941",
						"qValue": "1",
						"foldChange": "0.8942"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".183944845647862",
						"qValue": "1",
						"foldChange": "0.8585"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".684660290163933",
						"qValue": "1",
						"foldChange": "0.9203"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".771898089828265",
						"qValue": "1",
						"foldChange": "0.9075"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".651066507650574",
						"qValue": "1",
						"foldChange": "0.8368"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".854866880749984",
						"qValue": "1",
						"foldChange": "0.9221"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".370153221416079",
						"qValue": "1",
						"foldChange": "1.1734"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".795127318608377",
						"qValue": "1",
						"foldChange": "0.8815"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".258660463196918",
						"qValue": "1",
						"foldChange": "0.7512"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".586285836051657",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655332234154964",
						"qValue": "1",
						"foldChange": "0.7743"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".327030970461972",
						"qValue": ".985830071188835",
						"foldChange": "0.7226"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000824859094235",
						"qValue": ".002290539946545",
						"foldChange": "3.6678"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000030011596669",
						"qValue": ".000334745077058",
						"foldChange": "3.5282"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002230889997852",
						"qValue": ".001868090592204",
						"foldChange": "2.8233"
					}
				]
			},
			{
				"metaboliteId": "0078daa1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5d0-0000-0000-0000-000000000000",
				"chemicalName": "5-dodecenoate (12:1n7)",
				"plotName": null,
				"compoundId": "000084b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00529",
				"inChiKey": "IJBFSOLHRKELLR-FPLPWBNLBG",
				"kegg": null,
				"mass": 198.16198,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1338,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".958291410842186",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".818466033877209",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".349248461233344",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".784820059754523",
						"qValue": "1",
						"foldChange": "1.1733"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".259756383942121",
						"qValue": "1",
						"foldChange": "1.2975"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".390865493729903",
						"qValue": "1",
						"foldChange": "1.4726"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".499651456361025",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".124067601367978",
						"qValue": ".723161155918832",
						"foldChange": "0.9409"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".380476912091822",
						"qValue": ".957839854315834",
						"foldChange": "0.9198"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".520736345317499",
						"qValue": "1",
						"foldChange": "1.2995"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".698609629344185",
						"qValue": "1",
						"foldChange": "1.0178"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".30516629022424",
						"qValue": "1",
						"foldChange": "0.8578"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".435367966366041",
						"qValue": "1",
						"foldChange": "1.3784"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".643154803409027",
						"qValue": "1",
						"foldChange": "1.1023"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".777051246774039",
						"qValue": "1",
						"foldChange": "0.7997"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".819748898396008",
						"qValue": "1",
						"foldChange": "1.2911"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".486014915839111",
						"qValue": "1",
						"foldChange": "1.1746"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".621078444479835",
						"qValue": "1",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".424771954167204",
						"qValue": "1",
						"foldChange": "0.9309"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".672868930508607",
						"qValue": "1",
						"foldChange": "0.8076"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".73154854490597",
						"qValue": ".997573179629986",
						"foldChange": "0.8675"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".570465032038038",
						"qValue": ".279436417845201",
						"foldChange": "0.8805"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".650337433037373",
						"qValue": ".250821962366268",
						"foldChange": "1.0767"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".735805219975268",
						"qValue": ".150679093764813",
						"foldChange": "0.9975"
					}
				]
			},
			{
				"metaboliteId": "0078daa2-0000-0000-0000-000000000000",
				"chemicalId": "000000cf-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 3',5'-cyclic monophosphate (cAMP)",
				"plotName": null,
				"compoundId": "00000b0f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00058",
				"inChiKey": "IVOMOUWHDPKRLL-UHFFFAOYAA",
				"kegg": "C00575",
				"mass": 329.052523,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3764,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".277276526666922",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".719799408681376",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".915511738290416",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".366710148108629",
						"qValue": "1",
						"foldChange": "0.9893"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".839277790357768",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".482773267555921",
						"qValue": "1",
						"foldChange": "1.4223"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".819160184065066",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".899674311808824",
						"qValue": "1",
						"foldChange": "1.0840"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".722936388823757",
						"qValue": ".976023509428735",
						"foldChange": "1.1040"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".797795141779172",
						"qValue": "1",
						"foldChange": "0.9693"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".465202461258434",
						"qValue": "1",
						"foldChange": "0.9350"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".634428604330425",
						"qValue": "1",
						"foldChange": "0.9138"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".239387468640841",
						"qValue": "1",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".311631935232023",
						"qValue": "1",
						"foldChange": "0.8451"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".916342370523891",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".595585402649553",
						"qValue": "1",
						"foldChange": "0.9282"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".653773560765728",
						"qValue": "1",
						"foldChange": "0.8998"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".956264859450826",
						"qValue": "1",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".38129250154315",
						"qValue": "1",
						"foldChange": "0.7944"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".129705537456163",
						"qValue": "1",
						"foldChange": "0.6824"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".475482647157641",
						"qValue": ".985830071188835",
						"foldChange": "0.8590"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".418525620172215",
						"qValue": ".220301899440409",
						"foldChange": "0.7550"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".506198432592105",
						"qValue": ".204325434076588",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".120441118574414",
						"qValue": ".037454606859028",
						"foldChange": "1.5171"
					}
				]
			},
			{
				"metaboliteId": "0078daa3-0000-0000-0000-000000000000",
				"chemicalId": "00000156-0000-0000-0000-000000000000",
				"chemicalName": "glycocholate",
				"plotName": null,
				"compoundId": "0000482c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00138",
				"inChiKey": "RFDAIACWWDREDC-FRVQLJSFBG",
				"kegg": "C01921",
				"mass": 465.309038,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3088,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".164039853039725",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".474110460637826",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".556845672320513",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".101663817875736",
						"qValue": "1",
						"foldChange": "2.3004"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".130952503158465",
						"qValue": "1",
						"foldChange": "2.2578"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".895378792601024",
						"qValue": "1",
						"foldChange": "1.3552"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".887375393771902",
						"qValue": "1",
						"foldChange": "1.8455"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".881982361838112",
						"qValue": "1",
						"foldChange": "6.1529"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".771826418077046",
						"qValue": ".98622264532067",
						"foldChange": "1.7142"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".580140699079906",
						"qValue": "1",
						"foldChange": "1.1499"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".667527919940095",
						"qValue": "1",
						"foldChange": "1.3592"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".327541317909165",
						"qValue": "1",
						"foldChange": "2.0652"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".391118158574633",
						"qValue": "1",
						"foldChange": "2.6428"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".496678390503586",
						"qValue": "1",
						"foldChange": "0.3813"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".130619394087143",
						"qValue": "1",
						"foldChange": "0.1443"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".758144115622409",
						"qValue": "1",
						"foldChange": "0.4267"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".043737744351394",
						"qValue": "1",
						"foldChange": "0.1597"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".074396802394287",
						"qValue": ".822195137696456",
						"foldChange": "0.3744"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".965683183499046",
						"qValue": "1",
						"foldChange": "0.7779"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".184047744278542",
						"qValue": "1",
						"foldChange": "0.2633"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".186919291468075",
						"qValue": ".985830071188835",
						"foldChange": "0.3385"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008153481484097",
						"qValue": ".01308579377988",
						"foldChange": "0.2663"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".92010160033287",
						"qValue": ".318716840755599",
						"foldChange": "1.0284"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".996666314348968",
						"qValue": ".192596087094092",
						"foldChange": "1.5399"
					}
				]
			},
			{
				"metaboliteId": "0078daa4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e466-0000-0000-0000-000000000000",
				"chemicalName": "saccharin",
				"plotName": null,
				"compoundId": "0000529f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29723",
				"inChiKey": "CVHZOJJKTDOEJC-UHFFFAOYAR",
				"kegg": "D01085",
				"mass": 182.999014,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4531,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".137386544692751",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".351143756500657",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".101404753314563",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".486068770138959",
						"qValue": "1",
						"foldChange": "1.0639"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".845297829930217",
						"qValue": "1",
						"foldChange": "1.0887"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".615297203627402",
						"qValue": "1",
						"foldChange": "2.0902"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".269119608103886",
						"qValue": ".901656639749634",
						"foldChange": "2.4738"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".483126071062782",
						"qValue": ".902562957068497",
						"foldChange": "1.9508"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".682714394437627",
						"qValue": ".976023509428735",
						"foldChange": "1.0645"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".010011290542527",
						"qValue": ".678448626775002",
						"foldChange": "1.3973"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".044775332658912",
						"qValue": "1",
						"foldChange": "1.7445"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".537476773075703",
						"qValue": "1",
						"foldChange": "1.8776"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".303299483329011",
						"qValue": "1",
						"foldChange": "0.5792"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".029366263062081",
						"qValue": "1",
						"foldChange": "5.6766"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".00170501329186",
						"qValue": ".725483155686294",
						"foldChange": "9.8004"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".704440314422606",
						"qValue": "1",
						"foldChange": "1.9038"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".548404510233572",
						"qValue": "1",
						"foldChange": "5.4192"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".804096803768563",
						"qValue": "1",
						"foldChange": "2.8466"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".734645815278514",
						"qValue": "1",
						"foldChange": "0.8212"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".474311362522262",
						"qValue": "1",
						"foldChange": "4.9882"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".290168203579026",
						"qValue": ".985830071188835",
						"foldChange": "6.0746"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".095962923798556",
						"qValue": ".079232970355339",
						"foldChange": "13.2240"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".975408988832218",
						"qValue": ".330485206882319",
						"foldChange": "0.4846"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".433657664982234",
						"qValue": ".099177397465125",
						"foldChange": "0.5641"
					}
				]
			},
			{
				"metaboliteId": "0078daa5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e327-0000-0000-0000-000000000000",
				"chemicalName": "4-methyl-2-oxopentanoate",
				"plotName": null,
				"compoundId": "00005664-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00695",
				"inChiKey": "BKAJNAXTPSGJCU-UHFFFAOYAG",
				"kegg": "C00233",
				"mass": 130.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 296,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".803588284997476",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".629003008734326",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".25111822552315",
						"qValue": ".962619864505407",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".214076410008163",
						"qValue": "1",
						"foldChange": "0.9481"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".783018942913374",
						"qValue": "1",
						"foldChange": "0.9882"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".330934419962309",
						"qValue": "1",
						"foldChange": "1.0706"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".825277509650665",
						"qValue": "1",
						"foldChange": "1.0241"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".10982194059275",
						"qValue": ".697458782960496",
						"foldChange": "0.9312"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".070306104033922",
						"qValue": ".785109179715988",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".653027902932294",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".674465533780249",
						"qValue": "1",
						"foldChange": "1.0318"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".385931577781639",
						"qValue": "1",
						"foldChange": "1.0053"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".893394672767901",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".677324552325527",
						"qValue": "1",
						"foldChange": "1.0553"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".766580867948298",
						"qValue": "1",
						"foldChange": "1.0259"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".477498279704809",
						"qValue": "1",
						"foldChange": "1.1187"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".481296061330275",
						"qValue": "1",
						"foldChange": "1.0586"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".97333160403026",
						"qValue": "1",
						"foldChange": "0.9463"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".714603034355978",
						"qValue": "1",
						"foldChange": "0.9360"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".493786033302939",
						"qValue": "1",
						"foldChange": "1.0155"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".294430776615474",
						"qValue": ".985830071188835",
						"foldChange": "1.0849"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".858866919314189",
						"qValue": ".361182939797515",
						"foldChange": "1.0825"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".961899208225133",
						"qValue": ".327976352737671",
						"foldChange": "1.0008"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".971290628198672",
						"qValue": ".189085134227061",
						"foldChange": "1.0269"
					}
				]
			},
			{
				"metaboliteId": "0078daa6-0000-0000-0000-000000000000",
				"chemicalId": "00000152-0000-0000-0000-000000000000",
				"chemicalName": "gluconate",
				"plotName": null,
				"compoundId": "0000024b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00625",
				"inChiKey": "RGHNJXZEOKUKBD-SQOUGZDYBY",
				"kegg": "C00257",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4316,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".200587063225661",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".654916875281132",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".805242951249045",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".225127736214887",
						"qValue": "1",
						"foldChange": "0.8390"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".261058171527946",
						"qValue": "1",
						"foldChange": "0.8715"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".92745639573467",
						"qValue": "1",
						"foldChange": "2.1990"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".928991685990211",
						"qValue": "1",
						"foldChange": "5.1523"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".575286319639738",
						"qValue": ".928868848501546",
						"foldChange": "1.7359"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".516410992620167",
						"qValue": ".957839854315834",
						"foldChange": "2.9035"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".783499386181824",
						"qValue": "1",
						"foldChange": "0.9814"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".873259738348175",
						"qValue": "1",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".9081899597913",
						"qValue": "1",
						"foldChange": "1.2463"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".41159646927056",
						"qValue": "1",
						"foldChange": "0.9116"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".518122921848734",
						"qValue": "1",
						"foldChange": "1.2033"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".147595036504604",
						"qValue": "1",
						"foldChange": "1.3199"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".87788652969776",
						"qValue": "1",
						"foldChange": "0.9981"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".163937673280704",
						"qValue": "1",
						"foldChange": "1.5584"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".200601974732253",
						"qValue": "1",
						"foldChange": "1.5614"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".545636139243093",
						"qValue": "1",
						"foldChange": "1.0513"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".156561675595623",
						"qValue": "1",
						"foldChange": "1.2866"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".381906737714624",
						"qValue": ".985830071188835",
						"foldChange": "1.2238"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000008145115495",
						"qValue": ".000112365462478",
						"foldChange": "5.6228"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00078103517942",
						"qValue": ".001998063121506",
						"foldChange": "4.4592"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".058929765696586",
						"qValue": ".021631249645911",
						"foldChange": "3.8767"
					}
				]
			},
			{
				"metaboliteId": "0078daa7-0000-0000-0000-000000000000",
				"chemicalId": "0000036e-0000-0000-0000-000000000000",
				"chemicalName": "fructose",
				"plotName": null,
				"compoundId": "00000241-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00660",
				"inChiKey": "BJHIKXHVCXFQLS-UYFOZJQFBH",
				"kegg": "C00095",
				"mass": 180.06339,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1193,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".173582595644712",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".74196830828115",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".05860143516844",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".728575911979117",
						"qValue": "1",
						"foldChange": "1.0263"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".058860526722024",
						"qValue": "1",
						"foldChange": "1.6319"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".026815848070736",
						"qValue": "1",
						"foldChange": "1.8497"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".204651712557001",
						"qValue": ".802574227585289",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".512250042675873",
						"qValue": ".908176638160766",
						"foldChange": "1.2978"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".534516887858836",
						"qValue": ".957839854315834",
						"foldChange": "1.8346"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".720944943038607",
						"qValue": "1",
						"foldChange": "1.1552"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".208861231038768",
						"qValue": "1",
						"foldChange": "0.8254"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".108987039199624",
						"qValue": ".918296736226537",
						"foldChange": "0.6452"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".070468751686833",
						"qValue": "1",
						"foldChange": "1.6901"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".042103072765467",
						"qValue": "1",
						"foldChange": "1.7567"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".733009039041619",
						"qValue": "1",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".293519005910467",
						"qValue": "1",
						"foldChange": "1.4587"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".009028129383623",
						"qValue": "1",
						"foldChange": "1.8073"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".088042997777033",
						"qValue": ".834952786556829",
						"foldChange": "1.2389"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".650088732681261",
						"qValue": "1",
						"foldChange": "0.9266"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".479969302969897",
						"qValue": "1",
						"foldChange": "0.7786"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".77554302732198",
						"qValue": ".997573179629986",
						"foldChange": "0.8403"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".334110163990915",
						"qValue": ".192863611674612",
						"foldChange": "1.6095"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".025701976861943",
						"qValue": ".021984370491169",
						"foldChange": "1.6535"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002977526448866",
						"qValue": ".002236983909578",
						"foldChange": "1.9742"
					}
				]
			},
			{
				"metaboliteId": "0078daa8-0000-0000-0000-000000000000",
				"chemicalId": "00000378-0000-0000-0000-000000000000",
				"chemicalName": "caprate (10:0)",
				"plotName": null,
				"compoundId": "0000066a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00511",
				"inChiKey": "GHVNFZFCNZKVNT-UHFFFAOYAC",
				"kegg": "C01571",
				"mass": 172.14633,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1332,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".696060979617998",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".727571732950978",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".151462267838218",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".695710182852656",
						"qValue": "1",
						"foldChange": "0.9880"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".136861146985381",
						"qValue": "1",
						"foldChange": "1.2375"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".062597434957686",
						"qValue": "1",
						"foldChange": "1.3153"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".149389323931113",
						"qValue": ".742487134132407",
						"foldChange": "0.8953"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".115759487869598",
						"qValue": ".707436023994459",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".891903225100208",
						"qValue": "1",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".698696026558871",
						"qValue": "1",
						"foldChange": "1.1329"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".660724046690348",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".410053968243271",
						"qValue": "1",
						"foldChange": "0.9146"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".445295685942217",
						"qValue": "1",
						"foldChange": "1.3242"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".376156616480345",
						"qValue": "1",
						"foldChange": "1.2266"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".865606547392337",
						"qValue": "1",
						"foldChange": "0.9263"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".812381787688439",
						"qValue": "1",
						"foldChange": "1.2425"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".200686742774553",
						"qValue": "1",
						"foldChange": "1.3485"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".276978425918759",
						"qValue": "1",
						"foldChange": "1.0853"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".401373465919866",
						"qValue": "1",
						"foldChange": "0.9334"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".916684058557392",
						"qValue": "1",
						"foldChange": "0.9459"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".479393059894783",
						"qValue": ".985830071188835",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".49917267905815",
						"qValue": ".250682137067916",
						"foldChange": "0.8981"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".740953891482183",
						"qValue": ".274385598597047",
						"foldChange": "1.0407"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".904978781988607",
						"qValue": ".179185710612388",
						"foldChange": "1.0115"
					}
				]
			},
			{
				"metaboliteId": "0078daa9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e57c-0000-0000-0000-000000000000",
				"chemicalName": "5-hydroxyhexanoate",
				"plotName": null,
				"compoundId": "00007cc2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00525",
				"inChiKey": "YDCRNMJQROAWFT-UHFFFAOYAI",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1758,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".510151666391967",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".526857542832726",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".578100060781627",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".78350251287984",
						"qValue": "1",
						"foldChange": "1.3328"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".349870805279192",
						"qValue": "1",
						"foldChange": "0.8384"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".507490933437302",
						"qValue": "1",
						"foldChange": "1.1403"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".786508735218446",
						"qValue": "1",
						"foldChange": "1.2562"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".476047450763283",
						"qValue": ".902562957068497",
						"foldChange": "2.8213"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".326811058002218",
						"qValue": ".957839854315834",
						"foldChange": "3.3143"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".272271060523255",
						"qValue": "1",
						"foldChange": "0.7718"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".799747440194567",
						"qValue": "1",
						"foldChange": "1.4639"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".178402086730323",
						"qValue": ".989315025499063",
						"foldChange": "2.1758"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".391337522011794",
						"qValue": "1",
						"foldChange": "0.8518"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".325760224133042",
						"qValue": "1",
						"foldChange": "0.6457"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".858715120548303",
						"qValue": "1",
						"foldChange": "0.7581"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".399101679491375",
						"qValue": "1",
						"foldChange": "0.5883"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".101413703947478",
						"qValue": "1",
						"foldChange": "0.3685"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".380753981510475",
						"qValue": "1",
						"foldChange": "0.6264"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".674578756747429",
						"qValue": "1",
						"foldChange": "1.2331"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".934267462806723",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".623439707863028",
						"qValue": ".985830071188835",
						"foldChange": "0.7159"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000265326581858",
						"qValue": ".000998262894081",
						"foldChange": "0.2855"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000859526900082",
						"qValue": ".002039209562601",
						"foldChange": "0.3377"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001518479689586",
						"qValue": ".001379293617932",
						"foldChange": "0.2940"
					}
				]
			},
			{
				"metaboliteId": "0078daaa-0000-0000-0000-000000000000",
				"chemicalId": "05f5e59d-0000-0000-0000-000000000000",
				"chemicalName": "docosapentaenoate (n3 DPA; 22:5n3)",
				"plotName": null,
				"compoundId": "00007ef8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06528",
				"inChiKey": "YUFFSWGQGVEMMI-RCHUDCCIBW",
				"kegg": "C16513",
				"mass": 330.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".393680060900757",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".15406196610192",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".7041483235599",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".513103041207575",
						"qValue": "1",
						"foldChange": "1.2572"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".864597721753969",
						"qValue": "1",
						"foldChange": "1.1612"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".628145997389695",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".449261566722219",
						"qValue": "1",
						"foldChange": "1.3266"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".207678514110279",
						"qValue": ".825038702902081",
						"foldChange": "1.0197"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".046865091264475",
						"qValue": ".679210111549633",
						"foldChange": "0.8439"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".715827781240914",
						"qValue": "1",
						"foldChange": "1.0530"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".224175342147875",
						"qValue": "1",
						"foldChange": "0.8666"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".391234823154652",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".454266473612919",
						"qValue": "1",
						"foldChange": "0.8933"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".685285329446007",
						"qValue": "1",
						"foldChange": "1.0758"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".256536820748734",
						"qValue": "1",
						"foldChange": "1.2043"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".482035995111071",
						"qValue": "1",
						"foldChange": "0.8460"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".770297842658394",
						"qValue": "1",
						"foldChange": "0.7853"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".701523343243604",
						"qValue": "1",
						"foldChange": "0.9282"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".089475575872265",
						"qValue": "1",
						"foldChange": "0.8464"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".563474588167988",
						"qValue": "1",
						"foldChange": "0.7499"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".283978382015909",
						"qValue": ".985830071188835",
						"foldChange": "0.8860"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".138924386228438",
						"qValue": ".104300582947379",
						"foldChange": "1.4947"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".43433024874633",
						"qValue": ".181304557147556",
						"foldChange": "1.9309"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000647507022858",
						"qValue": ".000771137317422",
						"foldChange": "2.4914"
					}
				]
			},
			{
				"metaboliteId": "0078daab-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74d-0000-0000-0000-000000000000",
				"chemicalName": "tetradecanedioate (C14-DC)",
				"plotName": null,
				"compoundId": "00008b55-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00872",
				"inChiKey": "HQHCYKULIHKCEB-UHFFFAOYAK",
				"kegg": null,
				"mass": 258.183109,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1515,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".904364120618034",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".640283292313097",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".675018641188262",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".331166834551065",
						"qValue": "1",
						"foldChange": "0.9518"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".74773909074356",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".513535447331757",
						"qValue": "1",
						"foldChange": "1.1441"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".957386203141178",
						"qValue": "1",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".326476978963979",
						"qValue": ".831655911352372",
						"foldChange": "0.9290"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".30121953344633",
						"qValue": ".957839854315834",
						"foldChange": "0.9594"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".497232503634515",
						"qValue": "1",
						"foldChange": "1.1470"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".809944164233171",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".35914124526281",
						"qValue": "1",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".796902744124421",
						"qValue": "1",
						"foldChange": "1.2884"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".887543588938402",
						"qValue": "1",
						"foldChange": "1.0885"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".916751542721686",
						"qValue": "1",
						"foldChange": "0.8449"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".474817114871806",
						"qValue": "1",
						"foldChange": "2.0186"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".392189897359362",
						"qValue": "1",
						"foldChange": "1.2976"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".851995300427587",
						"qValue": "1",
						"foldChange": "0.6428"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".986086987082605",
						"qValue": "1",
						"foldChange": "1.1025"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".86531075110783",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".848814512016502",
						"qValue": ".997573179629986",
						"foldChange": "0.9577"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".849482398022805",
						"qValue": ".359202591102342",
						"foldChange": "1.2218"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".781061027339325",
						"qValue": ".284700768424356",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".649261415942281",
						"qValue": ".135565716903519",
						"foldChange": "1.1151"
					}
				]
			},
			{
				"metaboliteId": "0078daac-0000-0000-0000-000000000000",
				"chemicalId": "05f5e749-0000-0000-0000-000000000000",
				"chemicalName": "7-alpha-hydroxy-3-oxo-4-cholestenoate (7-Hoca)",
				"plotName": null,
				"compoundId": "00008fa8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12458",
				"inChiKey": "SATGKQGFUDXGAX-MYWFJNCABU",
				"kegg": "C17337",
				"mass": 430.30831,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2891,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".630480910342811",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".957655497742332",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".226734333613865",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".262699485269824",
						"qValue": "1",
						"foldChange": "1.1239"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".359010361235378",
						"qValue": "1",
						"foldChange": "1.1002"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".836722215911622",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".579771419959593",
						"qValue": "1",
						"foldChange": "1.0800"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".673269868919661",
						"qValue": ".937729391899561",
						"foldChange": "1.1056"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".894556580373948",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".081867014255183",
						"qValue": ".886468253895902",
						"foldChange": "0.8605"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".08934956238868",
						"qValue": "1",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".965643451751496",
						"qValue": "1",
						"foldChange": "0.9517"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".78795614456411",
						"qValue": "1",
						"foldChange": "0.9635"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".829937260973643",
						"qValue": "1",
						"foldChange": "1.0553"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".631222495459467",
						"qValue": "1",
						"foldChange": "1.0953"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".53523859622703",
						"qValue": "1",
						"foldChange": "0.8733"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".152722126145679",
						"qValue": "1",
						"foldChange": "0.7701"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".37970699914869",
						"qValue": "1",
						"foldChange": "0.8819"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".56648366101682",
						"qValue": "1",
						"foldChange": "0.9210"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".195153823588549",
						"qValue": "1",
						"foldChange": "0.7703"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".433969682135995",
						"qValue": ".985830071188835",
						"foldChange": "0.8364"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".145063017688272",
						"qValue": ".106731111544603",
						"foldChange": "1.4904"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".058106971855165",
						"qValue": ".041178340734901",
						"foldChange": "1.3648"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002754815260826",
						"qValue": ".002133109795806",
						"foldChange": "1.7343"
					}
				]
			},
			{
				"metaboliteId": "0078daad-0000-0000-0000-000000000000",
				"chemicalId": "05f5e77a-0000-0000-0000-000000000000",
				"chemicalName": "taurolithocholate 3-sulfate",
				"plotName": null,
				"compoundId": "00008ff2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02580",
				"inChiKey": "HSNPMXROZIQAQD-GBURMNQMBI",
				"kegg": "C03642",
				"mass": 563.258659,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".49701467224672",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".898289803439849",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".206522032109912",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".554411603002074",
						"qValue": "1",
						"foldChange": "0.9676"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".717408383680068",
						"qValue": "1",
						"foldChange": "1.3357"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".342161791429567",
						"qValue": "1",
						"foldChange": "3.2700"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999762886311225",
						"qValue": "1",
						"foldChange": "1.2007"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".117683444475889",
						"qValue": ".710273838645257",
						"foldChange": "1.0085"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".117750552581922",
						"qValue": ".919318534378124",
						"foldChange": "0.8228"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".209203175031227",
						"qValue": "1",
						"foldChange": "7.4898"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".234229683922256",
						"qValue": "1",
						"foldChange": "6.8663"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".945943343475664",
						"qValue": "1",
						"foldChange": "0.9288"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".99367563587093",
						"qValue": "1",
						"foldChange": "1.2896"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".710062953834935",
						"qValue": "1",
						"foldChange": "1.5223"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".709023722312816",
						"qValue": "1",
						"foldChange": "1.1804"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".758882462073",
						"qValue": "1",
						"foldChange": "1.2689"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".200123330226342",
						"qValue": "1",
						"foldChange": "1.5495"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".306215549385453",
						"qValue": "1",
						"foldChange": "1.2212"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".352819032051422",
						"qValue": "1",
						"foldChange": "0.8283"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".422138003017775",
						"qValue": "1",
						"foldChange": "1.3923"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".090105359621265",
						"qValue": ".985830071188835",
						"foldChange": "1.6809"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".790422840907018",
						"qValue": ".344316995840098",
						"foldChange": "1.2075"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".248219506256861",
						"qValue": ".122238522848194",
						"foldChange": "0.6870"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".706982997036395",
						"qValue": ".14597033451552",
						"foldChange": "0.9623"
					}
				]
			},
			{
				"metaboliteId": "0078daae-0000-0000-0000-000000000000",
				"chemicalId": "05f5f149-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyibuprofen",
				"plotName": null,
				"compoundId": "0000a942-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60920",
				"inChiKey": null,
				"kegg": null,
				"mass": 222.125594,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4792,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".34091922398811",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".663245150741943",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".226099606787371",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".377467210599374",
						"qValue": "1",
						"foldChange": "3.2166"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".422173160497466",
						"qValue": "1",
						"foldChange": "2.6527"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".935590878639632",
						"qValue": "1",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".819793063253741",
						"qValue": "1",
						"foldChange": "0.9334"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".628799139958889",
						"qValue": ".928868848501546",
						"foldChange": "2.1478"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".477703725025146",
						"qValue": ".957839854315834",
						"foldChange": "2.2143"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".040396472490198",
						"qValue": ".821720595261736",
						"foldChange": "1.0358"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".091886913590839",
						"qValue": "1",
						"foldChange": "1.4208"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".700642467005557",
						"qValue": "1",
						"foldChange": "1.4767"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".856250922638689",
						"qValue": "1",
						"foldChange": "0.8092"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".046813419977621",
						"qValue": "1",
						"foldChange": "34.6353"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".027851305656997",
						"qValue": "1",
						"foldChange": "42.7998"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".298650840275943",
						"qValue": "1",
						"foldChange": "0.0854"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".78177313679713",
						"qValue": "1",
						"foldChange": "0.1357"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".467706042967969",
						"qValue": "1",
						"foldChange": "1.5885"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".651326512973662",
						"qValue": "1",
						"foldChange": "0.2438"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".932260847560209",
						"qValue": "1",
						"foldChange": "0.2389"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".599884090783291",
						"qValue": ".985830071188835",
						"foldChange": "0.9802"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".944745856189258",
						"qValue": ".384743497486363",
						"foldChange": "0.3533"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "2.2143"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".899471103913487",
						"qValue": ".178314790385976",
						"foldChange": "0.4472"
					}
				]
			},
			{
				"metaboliteId": "0078daaf-0000-0000-0000-000000000000",
				"chemicalId": "05f5f14f-0000-0000-0000-000000000000",
				"chemicalName": "warfarin",
				"plotName": null,
				"compoundId": "0000a947-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01935",
				"inChiKey": null,
				"kegg": "C01541",
				"mass": 308.104859,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4977,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".574913563606538",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".352052033828013",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".573839409262243",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".945723002818296",
						"qValue": "1",
						"foldChange": "1.0354"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".048426727229167",
						"qValue": ".678234398444917",
						"foldChange": "0.9099"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".056204991379738",
						"qValue": ".735853040987031",
						"foldChange": "0.9282"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".834908978208134",
						"qValue": "1",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".70178877200824",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".861302291825113",
						"qValue": "1",
						"foldChange": "0.9322"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".395541443722745",
						"qValue": "1",
						"foldChange": "159.5373"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".323177647154628",
						"qValue": "1",
						"foldChange": "299.0607"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".846802846724984",
						"qValue": "1",
						"foldChange": "1.8745"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".397951400321379",
						"qValue": "1",
						"foldChange": "84.3112"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".330029152824306",
						"qValue": "1",
						"foldChange": "529.0118"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".854935298986547",
						"qValue": "1",
						"foldChange": "6.2745"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".470780998769234",
						"qValue": "1",
						"foldChange": "80.3875"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".335849123941107",
						"qValue": "1",
						"foldChange": "356.0634"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".769623108438825",
						"qValue": ".997573179629986",
						"foldChange": "4.4293"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".169234873214926",
						"qValue": ".119471373382437",
						"foldChange": "356.0634"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "80.3875"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dab0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7dc-0000-0000-0000-000000000000",
				"chemicalName": "4-ethylphenylsulfate",
				"plotName": null,
				"compoundId": "00008d03-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB62551",
				"inChiKey": null,
				"kegg": "C13637",
				"mass": 202.029979,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4108,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".292418434723598",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".122910830579432",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".242170690582526",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".442723005274613",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".214071198633243",
						"qValue": "1",
						"foldChange": "1.3283"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".629792901143685",
						"qValue": "1",
						"foldChange": "1.3169"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".005908137039196",
						"qValue": ".228537482743433",
						"foldChange": "0.5919"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".02822333862821",
						"qValue": ".678234398444917",
						"foldChange": "0.7141"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".539162396799911",
						"qValue": ".957839854315834",
						"foldChange": "1.6000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".686645985538094",
						"qValue": "1",
						"foldChange": "2.3559"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".864425828394761",
						"qValue": "1",
						"foldChange": "1.4523"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".815823181294367",
						"qValue": "1",
						"foldChange": "1.0853"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".763097825345231",
						"qValue": "1",
						"foldChange": "0.4972"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".503262163441713",
						"qValue": "1",
						"foldChange": "0.3907"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".693049060172003",
						"qValue": "1",
						"foldChange": "0.7858"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".064581389989366",
						"qValue": "1",
						"foldChange": "0.2988"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".806429263680784",
						"qValue": "1",
						"foldChange": "1.6549"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".042270843556896",
						"qValue": ".822195137696456",
						"foldChange": "5.5377"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".312305631771476",
						"qValue": "1",
						"foldChange": "0.7101"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".671025388157858",
						"qValue": "1",
						"foldChange": "1.4049"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".159705001553994",
						"qValue": ".985830071188835",
						"foldChange": "1.9784"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00248993087979",
						"qValue": ".005353199256152",
						"foldChange": "2.7862"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".090716659209543",
						"qValue": ".057101619818097",
						"foldChange": "0.1883"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".066209428868579",
						"qValue": ".023446876420287",
						"foldChange": "1.6362"
					}
				]
			},
			{
				"metaboliteId": "0078dab1-0000-0000-0000-000000000000",
				"chemicalId": "05f5f193-0000-0000-0000-000000000000",
				"chemicalName": "gamma-CEHC glucuronide*",
				"plotName": null,
				"compoundId": "0000a58d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 440.168247,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3977,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".066556855689629",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".000270557329531",
						"qValue": ".04604885748617",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".435209136104668",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".021844220066937",
						"qValue": "1",
						"foldChange": "0.7754"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".87363509303604",
						"qValue": "1",
						"foldChange": "1.5922"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".031884570889367",
						"qValue": "1",
						"foldChange": "2.5253"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".010410153145678",
						"qValue": ".285775494418456",
						"foldChange": "0.6637"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".024005540081257",
						"qValue": ".678234398444917",
						"foldChange": "0.6570"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".738519902335128",
						"qValue": ".976023509428735",
						"foldChange": "1.2613"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".014240340217606",
						"qValue": ".712854677951913",
						"foldChange": "0.5514"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".022505078887063",
						"qValue": ".884352967171025",
						"foldChange": "0.6584"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".853259600188353",
						"qValue": "1",
						"foldChange": "1.1116"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".416592910260513",
						"qValue": "1",
						"foldChange": "1.1620"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".016185226619221",
						"qValue": "1",
						"foldChange": "2.1377"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".084422535354705",
						"qValue": "1",
						"foldChange": "1.8397"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".482420252282384",
						"qValue": "1",
						"foldChange": "1.3018"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".02729620024519",
						"qValue": "1",
						"foldChange": "1.8252"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".105798031419655",
						"qValue": ".891426977605215",
						"foldChange": "1.4021"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".542074450512212",
						"qValue": "1",
						"foldChange": "0.8101"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".347269974315151",
						"qValue": "1",
						"foldChange": "1.2308"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".123807500102782",
						"qValue": ".985830071188835",
						"foldChange": "1.5194"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".249246396500107",
						"qValue": ".156590392054259",
						"foldChange": "1.7186"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".331576818583526",
						"qValue": ".152009550549949",
						"foldChange": "0.9644"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".409161363886161",
						"qValue": ".094516228523022",
						"foldChange": "1.4374"
					}
				]
			},
			{
				"metaboliteId": "0078dab2-0000-0000-0000-000000000000",
				"chemicalId": "00000800-0000-0000-0000-000000000000",
				"chemicalName": "3-(N-acetyl-L-cystein-S-yl) acetaminophen",
				"plotName": null,
				"compoundId": "0000b299-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DVPRQNKJGQEICH-JTQLQIEIBK",
				"kegg": null,
				"mass": 312.077995,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4773,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".198510571300197",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".381615974328402",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".354691628817768",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".448382397672701",
						"qValue": "1",
						"foldChange": "3.7757"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".17877577257292",
						"qValue": "1",
						"foldChange": "0.9193"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".03842325591541",
						"qValue": "1",
						"foldChange": "0.9068"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".743184963023863",
						"qValue": "1",
						"foldChange": "1.9214"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".719665121041654",
						"qValue": ".950986052805043",
						"foldChange": "0.9286"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".493168081954909",
						"qValue": ".957839854315834",
						"foldChange": "0.9092"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".232678780896474",
						"qValue": "1",
						"foldChange": "0.8415"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".6395195725058",
						"qValue": "1",
						"foldChange": "0.8724"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".464472763062538",
						"qValue": "1",
						"foldChange": "4.1878"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".0932331354527",
						"qValue": "1",
						"foldChange": "0.0696"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".426606368225631",
						"qValue": "1",
						"foldChange": "0.7595"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".404040357916159",
						"qValue": "1",
						"foldChange": "10.9159"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".052909806736404",
						"qValue": "1",
						"foldChange": "0.0667"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".05431732037126",
						"qValue": "1",
						"foldChange": "0.0433"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".921883211229141",
						"qValue": "1",
						"foldChange": "0.6494"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".279309005405706",
						"qValue": "1",
						"foldChange": "0.1139"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".762198389835681",
						"qValue": "1",
						"foldChange": "0.8457"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".457426468041032",
						"qValue": ".985830071188835",
						"foldChange": "7.4229"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".172216550988442",
						"qValue": ".12021452780783",
						"foldChange": "7.4229"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".209510459521387",
						"qValue": ".057313684477154",
						"foldChange": "8.7768"
					}
				]
			},
			{
				"metaboliteId": "0078dab3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e637-0000-0000-0000-000000000000",
				"chemicalName": "eicosenoate (20:1)",
				"plotName": null,
				"compoundId": "00008333-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02231",
				"inChiKey": "BITHHVVYSMSWAG-KTKRTIGZBC",
				"kegg": "C16526",
				"mass": 310.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1370,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".886188567631415",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".104972902607909",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".217434303550302",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".698786741002958",
						"qValue": "1",
						"foldChange": "1.1365"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".842247213896302",
						"qValue": "1",
						"foldChange": "1.0779"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".850745677813628",
						"qValue": "1",
						"foldChange": "1.0753"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".043843684426429",
						"qValue": ".460629326504823",
						"foldChange": "1.5786"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".254466438609029",
						"qValue": ".825038702902081",
						"foldChange": "0.9676"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".002278769377639",
						"qValue": ".149171749259273",
						"foldChange": "0.7020"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".45156204833898",
						"qValue": "1",
						"foldChange": "1.2436"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".529088566085165",
						"qValue": "1",
						"foldChange": "1.2649"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".901045938626329",
						"qValue": "1",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".686234486295174",
						"qValue": "1",
						"foldChange": "0.9599"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".881166060072211",
						"qValue": "1",
						"foldChange": "0.9104"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".811537749599049",
						"qValue": "1",
						"foldChange": "0.9485"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".529104832846661",
						"qValue": "1",
						"foldChange": "1.2153"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".91324903901771",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".617971726277975",
						"qValue": "1",
						"foldChange": "0.7933"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".213562007735248",
						"qValue": "1",
						"foldChange": "0.7867"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".885406640229143",
						"qValue": "1",
						"foldChange": "0.9365"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".177353227584632",
						"qValue": ".985830071188835",
						"foldChange": "1.1904"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".08315726453397",
						"qValue": ".07159244455092",
						"foldChange": "1.6171"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".350786505208893",
						"qValue": ".154283231235719",
						"foldChange": "1.4168"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".068492593622444",
						"qValue": ".024149034672255",
						"foldChange": "1.5986"
					}
				]
			},
			{
				"metaboliteId": "0078dab4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f608-0000-0000-0000-000000000000",
				"chemicalName": "O-sulfo-L-tyrosine",
				"plotName": null,
				"compoundId": "0000b165-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CIQHWLTYGMYQQR-QMMMGPOBBK",
				"kegg": null,
				"mass": 261.030708,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".172878568078216",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".985712229912683",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".449253947716726",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".218194920284751",
						"qValue": "1",
						"foldChange": "0.9301"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".876037632527962",
						"qValue": "1",
						"foldChange": "0.9942"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".280776728548442",
						"qValue": "1",
						"foldChange": "1.1770"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".219455465537359",
						"qValue": ".842371543132976",
						"foldChange": "1.1818"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".634091061041152",
						"qValue": ".928868848501546",
						"foldChange": "1.1419"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".447898027512257",
						"qValue": ".957839854315834",
						"foldChange": "0.9673"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".792603065541961",
						"qValue": "1",
						"foldChange": "1.0364"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".730256887550458",
						"qValue": "1",
						"foldChange": "0.9999"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".543949990026335",
						"qValue": "1",
						"foldChange": "0.8944"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".993853804921379",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".243750838198151",
						"qValue": "1",
						"foldChange": "1.1691"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".235459143426254",
						"qValue": "1",
						"foldChange": "1.1694"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".058634742572634",
						"qValue": "1",
						"foldChange": "1.2558"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".023932625351231",
						"qValue": "1",
						"foldChange": "1.4222"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".612805204497995",
						"qValue": "1",
						"foldChange": "1.1325"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".624648627031983",
						"qValue": "1",
						"foldChange": "1.1238"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".312077312069077",
						"qValue": "1",
						"foldChange": "1.1319"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".571962562642257",
						"qValue": ".985830071188835",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000690791785256",
						"qValue": ".001988823131614",
						"foldChange": "1.9301"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001916396321123",
						"qValue": ".003278406712276",
						"foldChange": "1.8449"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000103430783592",
						"qValue": ".000210495942728",
						"foldChange": "1.5752"
					}
				]
			},
			{
				"metaboliteId": "0078dab5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e745-0000-0000-0000-000000000000",
				"chemicalName": "catechol sulfate",
				"plotName": null,
				"compoundId": "000089f8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB59724",
				"inChiKey": "MZPWKJZDOCIALD-UHFFFAOYAC",
				"kegg": null,
				"mass": 189.993594,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4087,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".249295737946924",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".224205521346497",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".970700409039223",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".85551535586113",
						"qValue": "1",
						"foldChange": "1.2090"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".611551128966435",
						"qValue": "1",
						"foldChange": "1.2536"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".7442356508986",
						"qValue": "1",
						"foldChange": "1.2035"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".701822309434825",
						"qValue": "1",
						"foldChange": "1.3106"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".237785269974635",
						"qValue": ".825038702902081",
						"foldChange": "1.7668"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".42177305130208",
						"qValue": ".957839854315834",
						"foldChange": "1.5063"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".971370522295162",
						"qValue": "1",
						"foldChange": "1.0948"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".256351976086781",
						"qValue": "1",
						"foldChange": "1.3693"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".241919595289771",
						"qValue": "1",
						"foldChange": "1.4030"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".92530005688237",
						"qValue": "1",
						"foldChange": "0.9258"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".262167543122512",
						"qValue": "1",
						"foldChange": "1.1986"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".216028794505556",
						"qValue": "1",
						"foldChange": "1.2947"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".967053072030353",
						"qValue": "1",
						"foldChange": "0.9503"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".332647281888291",
						"qValue": "1",
						"foldChange": "1.3932"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".340712797054932",
						"qValue": "1",
						"foldChange": "1.4661"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".713293327314437",
						"qValue": "1",
						"foldChange": "1.3176"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".108983774754193",
						"qValue": "1",
						"foldChange": "1.3125"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".194901599700325",
						"qValue": ".985830071188835",
						"foldChange": "0.9961"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".014087281463816",
						"qValue": ".019194100161579",
						"foldChange": "2.8652"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".429456213623498",
						"qValue": ".179808313552872",
						"foldChange": "1.7586"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02192480483255",
						"qValue": ".010129254845541",
						"foldChange": "2.1992"
					}
				]
			},
			{
				"metaboliteId": "0078dab6-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebbd-0000-0000-0000-000000000000",
				"chemicalName": "S-methylcysteine",
				"plotName": null,
				"compoundId": "00009aa8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02108",
				"inChiKey": "IDIDJDIHTAOVLG-VKHMYHEABD",
				"kegg": null,
				"mass": 135.035399,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 393,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".003808057034601",
						"qValue": ".750120401842876",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".014243961857577",
						"qValue": ".614455251574347",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".042867428548049",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".548485004472438",
						"qValue": "1",
						"foldChange": "1.2029"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".059277051417592",
						"qValue": "1",
						"foldChange": "1.4058"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".190568280496997",
						"qValue": "1",
						"foldChange": "1.3008"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000412157330973",
						"qValue": ".070149177731642",
						"foldChange": "1.8803"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".001576342286648",
						"qValue": ".173657990763309",
						"foldChange": "1.8750"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".657889460077406",
						"qValue": ".975068512044627",
						"foldChange": "1.0088"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".750408642714498",
						"qValue": "1",
						"foldChange": "1.1216"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".659824652427622",
						"qValue": "1",
						"foldChange": "1.0101"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".4492941100809",
						"qValue": "1",
						"foldChange": "0.8567"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".05799994949299",
						"qValue": "1",
						"foldChange": "1.5648"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".130337055476627",
						"qValue": "1",
						"foldChange": "1.4130"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".762256641898402",
						"qValue": "1",
						"foldChange": "0.9030"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".000133647003034",
						"qValue": ".056866799790957",
						"foldChange": "2.3587"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".181869999491359",
						"qValue": "1",
						"foldChange": "1.3598"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".011399726986737",
						"qValue": ".677029694485477",
						"foldChange": "0.5765"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".006232368045043",
						"qValue": "1",
						"foldChange": "1.9172"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".895201736299346",
						"qValue": "1",
						"foldChange": "0.8914"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".005502924610767",
						"qValue": ".893076453757469",
						"foldChange": "0.4650"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".024872356907452",
						"qValue": ".029687788285768",
						"foldChange": "1.7241"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005076726120539",
						"qValue": ".006523626782171",
						"foldChange": "2.1256"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001243637697196",
						"qValue": ".001211799975523",
						"foldChange": "1.8828"
					}
				]
			},
			{
				"metaboliteId": "0078dab7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec87-0000-0000-0000-000000000000",
				"chemicalName": "eicosanedioate (C20-DC)",
				"plotName": null,
				"compoundId": "00009b97-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JJOJFIHJIRWASH-UHFFFAOYAR",
				"kegg": null,
				"mass": 342.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1526,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".901974759597244",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".187428767860561",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".225428265836445",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".5812234096473",
						"qValue": "1",
						"foldChange": "1.0393"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".064765406807156",
						"qValue": "1",
						"foldChange": "0.8447"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".188199627621126",
						"qValue": "1",
						"foldChange": "0.9190"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".508050409534649",
						"qValue": "1",
						"foldChange": "1.0187"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".748970271819585",
						"qValue": ".960368665676976",
						"foldChange": "1.3065"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".327763939902851",
						"qValue": ".957839854315834",
						"foldChange": "1.2734"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".570996296470669",
						"qValue": "1",
						"foldChange": "1.1951"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".173376392277113",
						"qValue": "1",
						"foldChange": "0.8148"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".056726145146498",
						"qValue": ".727024127920748",
						"foldChange": "0.6888"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".342691809112055",
						"qValue": "1",
						"foldChange": "0.9296"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".594406676382599",
						"qValue": "1",
						"foldChange": "0.9751"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".706991077663737",
						"qValue": "1",
						"foldChange": "1.0489"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".306829571077511",
						"qValue": "1",
						"foldChange": "0.7953"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".60687112260246",
						"qValue": "1",
						"foldChange": "0.9588"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".131368995337893",
						"qValue": ".931625125271228",
						"foldChange": "1.2056"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".254411197610044",
						"qValue": "1",
						"foldChange": "1.2561"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".881046342485832",
						"qValue": "1",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".209372007900334",
						"qValue": ".985830071188835",
						"foldChange": "0.7026"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".660770336172862",
						"qValue": ".310318923161874",
						"foldChange": "0.8877"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".715888882014199",
						"qValue": ".269760901424905",
						"foldChange": "0.8451"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".356774501739228",
						"qValue": ".085104968917351",
						"foldChange": "1.0951"
					}
				]
			},
			{
				"metaboliteId": "0078dab8-0000-0000-0000-000000000000",
				"chemicalId": "05f5eda7-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyltaurine",
				"plotName": null,
				"compoundId": "00009b9b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LPDJCYFKKSLKRO-UHFFFAOYAM",
				"kegg": null,
				"mass": 363.244329,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1920,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".858205042338433",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".398923382261333",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".713712604936373",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".533962552082068",
						"qValue": "1",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".592485971217734",
						"qValue": "1",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".930522344519303",
						"qValue": "1",
						"foldChange": "1.1402"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".396875878013019",
						"qValue": ".973318075472851",
						"foldChange": "1.4525"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".825965662325323",
						"qValue": ".995604502321318",
						"foldChange": "1.3674"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".287331543177929",
						"qValue": ".957839854315834",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".484071147868852",
						"qValue": "1",
						"foldChange": "1.2079"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".153989464478963",
						"qValue": "1",
						"foldChange": "0.7859"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".460968448629547",
						"qValue": "1",
						"foldChange": "1.7812"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".363937098609659",
						"qValue": "1",
						"foldChange": "0.7614"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".932924379738671",
						"qValue": "1",
						"foldChange": "0.8709"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".334558971571642",
						"qValue": "1",
						"foldChange": "1.1438"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".701642047409908",
						"qValue": "1",
						"foldChange": "1.2611"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".98461509626734",
						"qValue": "1",
						"foldChange": "1.1228"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".695925954598241",
						"qValue": "1",
						"foldChange": "0.8903"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".5394751759469",
						"qValue": "1",
						"foldChange": "0.8751"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".445323425925021",
						"qValue": "1",
						"foldChange": "0.7289"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".850599434171423",
						"qValue": ".997573179629986",
						"foldChange": "0.8329"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".24896860874671",
						"qValue": ".156590392054259",
						"foldChange": "1.6154"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".092992275437752",
						"qValue": ".058140280417539",
						"foldChange": "1.8009"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005747321210736",
						"qValue": ".003595450817255",
						"foldChange": "2.5684"
					}
				]
			},
			{
				"metaboliteId": "0078dab9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e776-0000-0000-0000-000000000000",
				"chemicalName": "1-arachidonoyl-GPI (20:4)*",
				"plotName": null,
				"compoundId": "000085a6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61690",
				"inChiKey": null,
				"kegg": null,
				"mass": 620.296164,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2434,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".92087972267653",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".890478820881181",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".561646756393273",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".188951703422183",
						"qValue": "1",
						"foldChange": "0.9254"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".834877401887634",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".267013510206647",
						"qValue": "1",
						"foldChange": "1.4538"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".703915308809048",
						"qValue": "1",
						"foldChange": "1.2015"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".563719843090347",
						"qValue": ".927902488336335",
						"foldChange": "1.3165"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".843070372817287",
						"qValue": "1",
						"foldChange": "1.2287"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".902079745483879",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".474378751111835",
						"qValue": "1",
						"foldChange": "1.0493"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".402451006211155",
						"qValue": "1",
						"foldChange": "0.8735"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".706137548283106",
						"qValue": "1",
						"foldChange": "0.9481"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".778261502239957",
						"qValue": "1",
						"foldChange": "0.8006"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".938692943751186",
						"qValue": "1",
						"foldChange": "0.8444"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".368052016209617",
						"qValue": "1",
						"foldChange": "1.1926"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".44604000277619",
						"qValue": "1",
						"foldChange": "1.3193"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".92627497348934",
						"qValue": "1",
						"foldChange": "1.1063"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".843218147883594",
						"qValue": "1",
						"foldChange": "1.1102"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".498492069467375",
						"qValue": "1",
						"foldChange": "0.7210"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".377154559600906",
						"qValue": ".985830071188835",
						"foldChange": "0.6495"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".44440804054027",
						"qValue": ".230625894346148",
						"foldChange": "1.4582"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".011114165558281",
						"qValue": ".011108048433661",
						"foldChange": "2.2523"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".016240836848463",
						"qValue": ".007888626886908",
						"foldChange": "1.9707"
					}
				]
			},
			{
				"metaboliteId": "0078daba-0000-0000-0000-000000000000",
				"chemicalId": "05f5e59a-0000-0000-0000-000000000000",
				"chemicalName": "3-carboxy-4-methyl-5-propyl-2-furanpropanoate (CMPF)",
				"plotName": null,
				"compoundId": "00007c2b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61112",
				"inChiKey": "WMCQWXZMVIETAO-UHFFFAOYAJ",
				"kegg": null,
				"mass": 240.099774,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1529,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".02215863800718",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".847631178387833",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".456583291742002",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".46223685075457",
						"qValue": "1",
						"foldChange": "0.9455"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".805886409013984",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".623550325623913",
						"qValue": "1",
						"foldChange": "1.0855"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".94227275538111",
						"qValue": "1",
						"foldChange": "1.0895"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".231262903365805",
						"qValue": ".825038702902081",
						"foldChange": "0.9934"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".20486380674356",
						"qValue": ".957839854315834",
						"foldChange": "0.9261"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".254425046125479",
						"qValue": "1",
						"foldChange": "1.2055"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".375534800315795",
						"qValue": "1",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".796733962771958",
						"qValue": "1",
						"foldChange": "0.9259"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".008895808709115",
						"qValue": "1",
						"foldChange": "0.2476"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".371862946918731",
						"qValue": "1",
						"foldChange": "0.7683"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".081512929503241",
						"qValue": "1",
						"foldChange": "3.1034"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".013061060088732",
						"qValue": "1",
						"foldChange": "0.2320"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".599076028658784",
						"qValue": "1",
						"foldChange": "0.6853"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".051811249334423",
						"qValue": ".822195137696456",
						"foldChange": "2.9537"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".005742665886588",
						"qValue": "1",
						"foldChange": "0.2254"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".502785539564661",
						"qValue": "1",
						"foldChange": "0.6175"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".035106943719231",
						"qValue": ".985830071188835",
						"foldChange": "2.7401"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000026395449497",
						"qValue": ".000271485731442",
						"foldChange": "33.3347"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000106312626988",
						"qValue": ".000617602035247",
						"foldChange": "19.4506"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00000057397608",
						"qValue": ".000011535191609",
						"foldChange": "64.6693"
					}
				]
			},
			{
				"metaboliteId": "0078dabb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e779-0000-0000-0000-000000000000",
				"chemicalName": "glycolithocholate sulfate*",
				"plotName": null,
				"compoundId": "00007f6c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02639",
				"inChiKey": "FHXBAFXQVZOILS-OETIFKLTBP",
				"kegg": "C11301",
				"mass": 513.276023,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3129,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".882564761715057",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".909961872836584",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".502002027169795",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".909409834848316",
						"qValue": "1",
						"foldChange": "1.1864"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".553548090125642",
						"qValue": "1",
						"foldChange": "1.2738"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".631812966311693",
						"qValue": "1",
						"foldChange": "1.2427"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".792009943249494",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".227633744826522",
						"qValue": ".825038702902081",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".343395428779634",
						"qValue": ".957839854315834",
						"foldChange": "0.8779"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".492537238199447",
						"qValue": "1",
						"foldChange": "3.1550"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".239334974464855",
						"qValue": "1",
						"foldChange": "2.8492"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".619445442625535",
						"qValue": "1",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".993111945421996",
						"qValue": "1",
						"foldChange": "1.4484"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".965618049638948",
						"qValue": "1",
						"foldChange": "1.1431"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".958115931306123",
						"qValue": "1",
						"foldChange": "0.7892"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".874884870178241",
						"qValue": "1",
						"foldChange": "1.2926"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".823875037178769",
						"qValue": "1",
						"foldChange": "0.8852"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".703803080207579",
						"qValue": "1",
						"foldChange": "0.6848"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".432681598700319",
						"qValue": "1",
						"foldChange": "1.0981"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".80791680817947",
						"qValue": "1",
						"foldChange": "0.9915"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".315020842727491",
						"qValue": ".985830071188835",
						"foldChange": "0.9030"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".075691668138817",
						"qValue": ".067007484237114",
						"foldChange": "2.1261"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".853131462444053",
						"qValue": ".302662432812138",
						"foldChange": "1.3139"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013358176252076",
						"qValue": ".00681800980224",
						"foldChange": "2.2949"
					}
				]
			},
			{
				"metaboliteId": "0078dabc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e107-0000-0000-0000-000000000000",
				"chemicalName": "carnitine",
				"plotName": null,
				"compoundId": "00003c8c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00062",
				"inChiKey": "PHIQHXFUZVPYII-ZCFIWIBFBS",
				"kegg": "C00318",
				"mass": 161.105193,
				"pathwayType": "ANIMAL",
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1704,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".08359572676215",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".935163508554872",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".957872732979218",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".732745786673114",
						"qValue": "1",
						"foldChange": "1.0209"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".539530082617696",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".784893545797479",
						"qValue": "1",
						"foldChange": "1.0159"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".589632886752928",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".672687795360823",
						"qValue": ".937729391899561",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".906601289236478",
						"qValue": "1",
						"foldChange": "1.0071"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".879127766846981",
						"qValue": "1",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".933854153553075",
						"qValue": "1",
						"foldChange": "1.0086"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".814189333156402",
						"qValue": "1",
						"foldChange": "0.9613"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".041755144870342",
						"qValue": "1",
						"foldChange": "1.1450"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".975763680529308",
						"qValue": "1",
						"foldChange": "0.9870"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".051450010005959",
						"qValue": "1",
						"foldChange": "0.8620"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".091987097292914",
						"qValue": "1",
						"foldChange": "1.1278"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".855428035569318",
						"qValue": "1",
						"foldChange": "0.9806"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".070584580239473",
						"qValue": ".822195137696456",
						"foldChange": "0.8694"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".105804725485156",
						"qValue": "1",
						"foldChange": "1.1156"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".847580025375087",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".07963658461041",
						"qValue": ".985830071188835",
						"foldChange": "0.8321"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".993729014671678",
						"qValue": ".395449911449441",
						"foldChange": "1.0200"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008301600744734",
						"qValue": ".008770671777843",
						"foldChange": "1.2101"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".950850241789875",
						"qValue": ".185977887113386",
						"foldChange": "1.0096"
					}
				]
			},
			{
				"metaboliteId": "0078dabd-0000-0000-0000-000000000000",
				"chemicalId": "000004e8-0000-0000-0000-000000000000",
				"chemicalName": "choline",
				"plotName": null,
				"compoundId": "00003c92-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00097",
				"inChiKey": "CRBHXDCYXIISFC-UHFFFAOYAW",
				"kegg": "C00114",
				"mass": 103.099714,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1974,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".127993804730509",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".775861505736325",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".198066621552417",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".477438486749735",
						"qValue": "1",
						"foldChange": "1.0408"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".103900429067496",
						"qValue": "1",
						"foldChange": "1.0952"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".351079874518664",
						"qValue": "1",
						"foldChange": "1.0537"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".146847343654509",
						"qValue": ".742487134132407",
						"foldChange": "0.9443"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".075380668899063",
						"qValue": ".687896186587066",
						"foldChange": "0.9330"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".733153577706553",
						"qValue": ".976023509428735",
						"foldChange": "0.9955"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".600320798493545",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".5967893077578",
						"qValue": "1",
						"foldChange": "0.9849"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".995925795187723",
						"qValue": "1",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".505836326190062",
						"qValue": "1",
						"foldChange": "0.9628"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".834754888770805",
						"qValue": "1",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".665746658591403",
						"qValue": "1",
						"foldChange": "1.0189"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".051230464182359",
						"qValue": "1",
						"foldChange": "0.8491"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".336482626493129",
						"qValue": "1",
						"foldChange": "0.9094"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".348022689673911",
						"qValue": "1",
						"foldChange": "1.0710"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".007581642459781",
						"qValue": "1",
						"foldChange": "0.7939"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".131551993959162",
						"qValue": "1",
						"foldChange": "0.8205"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".25875884731444",
						"qValue": ".985830071188835",
						"foldChange": "1.0336"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".396517181475578",
						"qValue": ".213121928218518",
						"foldChange": "1.1745"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".110511801561768",
						"qValue": ".066128374630177",
						"foldChange": "1.1280"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002082221037901",
						"qValue": ".001789038355159",
						"foldChange": "1.3273"
					}
				]
			},
			{
				"metaboliteId": "0078dabe-0000-0000-0000-000000000000",
				"chemicalId": "000004d3-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylhistidine",
				"plotName": null,
				"compoundId": "00004745-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "PXVCMZCJAUJLJP-YUMQZZPRBD",
				"kegg": null,
				"mass": 284.112071,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 553,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".492993714505397",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".767189661916809",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".558373962568477",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".198727107142598",
						"qValue": "1",
						"foldChange": "0.9186"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".945990326135105",
						"qValue": "1",
						"foldChange": "1.0076"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".222808366665052",
						"qValue": "1",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".879579051113342",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".463188559305758",
						"qValue": ".902562957068497",
						"foldChange": "0.9915"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".376928145294948",
						"qValue": ".957839854315834",
						"foldChange": "0.9771"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".782628154025673",
						"qValue": "1",
						"foldChange": "1.0422"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".661020358482788",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".475817649008149",
						"qValue": "1",
						"foldChange": "0.8578"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".839703084623813",
						"qValue": "1",
						"foldChange": "1.0187"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".485983086353703",
						"qValue": "1",
						"foldChange": "1.0653"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".603889635158782",
						"qValue": "1",
						"foldChange": "1.0458"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".213679380386231",
						"qValue": "1",
						"foldChange": "1.1371"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".077346838669584",
						"qValue": "1",
						"foldChange": "1.1959"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".532867014369132",
						"qValue": "1",
						"foldChange": "1.0518"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".796704302056326",
						"qValue": "1",
						"foldChange": "0.9627"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".671944478448119",
						"qValue": "1",
						"foldChange": "0.9201"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".495123465107466",
						"qValue": ".985830071188835",
						"foldChange": "0.9557"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".040414944739795",
						"qValue": ".043444830913691",
						"foldChange": "1.8555"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000075772263176",
						"qValue": ".000526311519666",
						"foldChange": "2.0864"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003008932924602",
						"qValue": ".002239647970864",
						"foldChange": "1.8211"
					}
				]
			},
			{
				"metaboliteId": "0078dabf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2bd-0000-0000-0000-000000000000",
				"chemicalName": "theobromine",
				"plotName": null,
				"compoundId": "000047d8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02825",
				"inChiKey": "YAPQBXQYLJRXSA-UHFFFAOYAW",
				"kegg": "C07480",
				"mass": 180.064726,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4157,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".520120953370593",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".597784371512148",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".113728175347088",
						"qValue": ".828639845653926",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".041628251995939",
						"qValue": "1",
						"foldChange": "0.9818"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".152767387685164",
						"qValue": "1",
						"foldChange": "1.0130"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".524919716965229",
						"qValue": "1",
						"foldChange": "3.2739"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".374643424517101",
						"qValue": ".957150284015863",
						"foldChange": "2.1027"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".830733305935742",
						"qValue": ".997819772602882",
						"foldChange": "1.4572"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".27206765494675",
						"qValue": ".957839854315834",
						"foldChange": "1.0510"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".666175288000282",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".25076644195942",
						"qValue": "1",
						"foldChange": "2.4880"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".116915437477823",
						"qValue": ".938632427298369",
						"foldChange": "3.0671"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".237494668847218",
						"qValue": "1",
						"foldChange": "0.7322"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".737215990511925",
						"qValue": "1",
						"foldChange": "0.8353"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".420586636339555",
						"qValue": "1",
						"foldChange": "1.1408"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".268718467170243",
						"qValue": "1",
						"foldChange": "1.3085"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".385144216844594",
						"qValue": "1",
						"foldChange": "0.9542"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".853471000789172",
						"qValue": "1",
						"foldChange": "0.7292"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".831741851056156",
						"qValue": "1",
						"foldChange": "1.1205"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".103202423006968",
						"qValue": "1",
						"foldChange": "2.2453"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".062145162325392",
						"qValue": ".985830071188835",
						"foldChange": "2.0038"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".222403822695036",
						"qValue": ".14353959648982",
						"foldChange": "2.1945"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".897533288110617",
						"qValue": ".312451889214572",
						"foldChange": "0.7873"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".53361515179106",
						"qValue": ".116084243862522",
						"foldChange": "1.1289"
					}
				]
			},
			{
				"metaboliteId": "0078dac0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e732-0000-0000-0000-000000000000",
				"chemicalName": "gulonate*",
				"plotName": null,
				"compoundId": "0000b76d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03290",
				"inChiKey": "RGHNJXZEOKUKBD-NRXMZTRTBF",
				"kegg": "C00257",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3965,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".514044811628462",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".742731651048314",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".723373514086891",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".986045948576631",
						"qValue": "1",
						"foldChange": "1.0710"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".915349398111406",
						"qValue": "1",
						"foldChange": "0.9980"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".929235680420361",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".917864437861238",
						"qValue": "1",
						"foldChange": "1.0823"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".629673769546995",
						"qValue": ".928868848501546",
						"foldChange": "1.1583"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".704421483533023",
						"qValue": ".976023509428735",
						"foldChange": "1.0915"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".433210016506966",
						"qValue": "1",
						"foldChange": "1.1215"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".485742286628008",
						"qValue": "1",
						"foldChange": "0.9480"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".142012720046449",
						"qValue": ".960252848686774",
						"foldChange": "0.7967"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".600637916846875",
						"qValue": "1",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".564455341637425",
						"qValue": "1",
						"foldChange": "1.0903"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".275177893485181",
						"qValue": "1",
						"foldChange": "1.1896"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".651100350471633",
						"qValue": "1",
						"foldChange": "0.9013"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".28492920004958",
						"qValue": "1",
						"foldChange": "1.2979"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".128629543839724",
						"qValue": ".931625125271228",
						"foldChange": "1.4399"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".861745387751478",
						"qValue": "1",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".83774824872017",
						"qValue": "1",
						"foldChange": "0.9170"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".705276412440107",
						"qValue": ".995398138635986",
						"foldChange": "0.9569"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001846113786183",
						"qValue": ".00424575637254",
						"foldChange": "1.9497"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00709672649157",
						"qValue": ".007981958638321",
						"foldChange": "1.7689"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".009923850283199",
						"qValue": ".005426925740088",
						"foldChange": "1.8423"
					}
				]
			},
			{
				"metaboliteId": "0078dac1-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8e9-0000-0000-0000-000000000000",
				"chemicalName": "1-dihomo-linolenylglycerol (20:3)",
				"plotName": null,
				"compoundId": "0000bcd5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 380.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2563,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".668920755108852",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".85606477117667",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".77968903691237",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".242494181034531",
						"qValue": "1",
						"foldChange": "6.4467"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".842065722039767",
						"qValue": "1",
						"foldChange": "1.1245"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".330523710507528",
						"qValue": "1",
						"foldChange": "4.0696"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".760774066259177",
						"qValue": "1",
						"foldChange": "1.5283"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".785061308313775",
						"qValue": ".978751596872832",
						"foldChange": "1.7853"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".564317170990102",
						"qValue": ".957839854315834",
						"foldChange": "2.2655"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".99703893350805",
						"qValue": "1",
						"foldChange": "1.1328"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".770805527238125",
						"qValue": "1",
						"foldChange": "1.9985"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".773641357690906",
						"qValue": "1",
						"foldChange": "1.6578"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".550925705417489",
						"qValue": "1",
						"foldChange": "1.1346"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".864954641383001",
						"qValue": "1",
						"foldChange": "0.4534"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".452661481641517",
						"qValue": "1",
						"foldChange": "0.3996"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".524756432241869",
						"qValue": "1",
						"foldChange": "0.3457"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".271999378416694",
						"qValue": "1",
						"foldChange": "0.1503"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".607716101913658",
						"qValue": "1",
						"foldChange": "0.4347"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".518113685236334",
						"qValue": "1",
						"foldChange": "1.2294"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".934130926462444",
						"qValue": "1",
						"foldChange": "0.3871"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".477551200684943",
						"qValue": ".985830071188835",
						"foldChange": "0.3149"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000004952429117",
						"qValue": ".000086300141111",
						"foldChange": "0.1007"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000810266745934",
						"qValue": ".002007776002987",
						"foldChange": "0.3255"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000876161233469",
						"qValue": ".000971487097363",
						"foldChange": "0.2979"
					}
				]
			},
			{
				"metaboliteId": "0078dac2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4c3-0000-0000-0000-000000000000",
				"chemicalName": "homocitrulline",
				"plotName": null,
				"compoundId": "0000567a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00679",
				"inChiKey": "XIGSAGMEBXLVJJ-YFKPBYRVBI",
				"kegg": "C02427",
				"mass": 189.111341,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 425,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".854010030932211",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".533394006830716",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".593822101590943",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".466507074887553",
						"qValue": "1",
						"foldChange": "1.4951"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".649462565412852",
						"qValue": "1",
						"foldChange": "1.3384"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".783243169242856",
						"qValue": "1",
						"foldChange": "1.1917"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".832480002102717",
						"qValue": "1",
						"foldChange": "1.4653"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".571214242596093",
						"qValue": ".928868848501546",
						"foldChange": "1.6988"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".722281169706688",
						"qValue": ".976023509428735",
						"foldChange": "1.4124"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".512656831264957",
						"qValue": "1",
						"foldChange": "1.5373"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".296034538328769",
						"qValue": "1",
						"foldChange": "1.1221"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".092592853960086",
						"qValue": ".829437039158244",
						"foldChange": "0.7199"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".770094634582301",
						"qValue": "1",
						"foldChange": "1.3973"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".887391547395132",
						"qValue": "1",
						"foldChange": "1.2120"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".890150394914646",
						"qValue": "1",
						"foldChange": "0.8674"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".898551828968035",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".907689389975843",
						"qValue": "1",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".80860487466943",
						"qValue": "1",
						"foldChange": "0.9347"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".720301312404891",
						"qValue": "1",
						"foldChange": "1.1384"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".30286825188153",
						"qValue": "1",
						"foldChange": "0.6263"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".163014981590808",
						"qValue": ".985830071188835",
						"foldChange": "0.5502"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".157466734354088",
						"qValue": ".113585520852105",
						"foldChange": "1.4362"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002809247131484",
						"qValue": ".004280593251941",
						"foldChange": "2.7951"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00429465340634",
						"qValue": ".002931838133002",
						"foldChange": "2.8516"
					}
				]
			},
			{
				"metaboliteId": "0078dac3-0000-0000-0000-000000000000",
				"chemicalId": "000004da-0000-0000-0000-000000000000",
				"chemicalName": "N1-methyladenosine",
				"plotName": null,
				"compoundId": "00003d22-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03331",
				"inChiKey": "QQBGTSSELNKRID-IOSLPCCCBM",
				"kegg": "C02494",
				"mass": 281.112405,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3774,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".889623445293078",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".815573952255055",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".687846353547123",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".197293658218972",
						"qValue": "1",
						"foldChange": "0.9602"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".960175721733075",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".214750142064777",
						"qValue": "1",
						"foldChange": "1.0598"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".624254544782299",
						"qValue": "1",
						"foldChange": "1.0219"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".969985505746223",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".597983091290126",
						"qValue": ".962532817488692",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815402368920576",
						"qValue": "1",
						"foldChange": "0.9939"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".808643422176332",
						"qValue": "1",
						"foldChange": "0.9919"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".993041057691592",
						"qValue": "1",
						"foldChange": "0.9327"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".928314574318492",
						"qValue": "1",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".770482555674094",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".699660157547112",
						"qValue": "1",
						"foldChange": "1.0255"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".393838778715986",
						"qValue": "1",
						"foldChange": "1.0628"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".411129631856815",
						"qValue": "1",
						"foldChange": "1.0601"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".987405520644483",
						"qValue": "1",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".934383960291704",
						"qValue": "1",
						"foldChange": "0.9957"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".851747847683524",
						"qValue": "1",
						"foldChange": "0.9354"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".786310330730602",
						"qValue": ".997573179629986",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".447606092376675",
						"qValue": ".231922011680759",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".141483486546034",
						"qValue": ".080187330719371",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".192485817396408",
						"qValue": ".054273110107643",
						"foldChange": "1.1129"
					}
				]
			},
			{
				"metaboliteId": "0078dac4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e50a-0000-0000-0000-000000000000",
				"chemicalName": "indoleacetate",
				"plotName": null,
				"compoundId": "00006b79-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00197",
				"inChiKey": "SEOVTRFCIGRIMH-UHFFFAOYAT",
				"kegg": "C00954",
				"mass": 175.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 267,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".536221076678591",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".958924583118775",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".380720395052597",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".410893990493804",
						"qValue": "1",
						"foldChange": "1.1898"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".592225061614268",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".177438788911345",
						"qValue": "1",
						"foldChange": "0.9185"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".9189864538308",
						"qValue": "1",
						"foldChange": "1.1160"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".660261180225945",
						"qValue": ".934949271461149",
						"foldChange": "1.1011"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".735302918712968",
						"qValue": ".976023509428735",
						"foldChange": "1.1124"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".183789182764826",
						"qValue": "1",
						"foldChange": "0.9145"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".894875809353885",
						"qValue": "1",
						"foldChange": "1.0405"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".230015592125469",
						"qValue": "1",
						"foldChange": "1.1326"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".665567880738073",
						"qValue": "1",
						"foldChange": "0.8314"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".34956068503933",
						"qValue": "1",
						"foldChange": "1.3407"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".171991983391896",
						"qValue": "1",
						"foldChange": "1.6125"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".393415052316579",
						"qValue": "1",
						"foldChange": "0.7334"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".765539816448873",
						"qValue": "1",
						"foldChange": "0.7607"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".600206649648867",
						"qValue": "1",
						"foldChange": "1.0372"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".901884250766685",
						"qValue": "1",
						"foldChange": "0.9349"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".250500036554019",
						"qValue": "1",
						"foldChange": "1.1034"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".289051523710098",
						"qValue": ".985830071188835",
						"foldChange": "1.1802"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".225576312589074",
						"qValue": ".145021736993875",
						"foldChange": "1.5690"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".013780751839828",
						"qValue": ".01329658687817",
						"foldChange": "1.5112"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".226306771915296",
						"qValue": ".060339435568167",
						"foldChange": "1.4454"
					}
				]
			},
			{
				"metaboliteId": "0078dac5-0000-0000-0000-000000000000",
				"chemicalId": "00000111-0000-0000-0000-000000000000",
				"chemicalName": "cortisone",
				"plotName": null,
				"compoundId": "000006e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02802",
				"inChiKey": "IWIJFUQFXLWZIA-UHFFFAOYAP",
				"kegg": "C00762",
				"mass": 360.193674,
				"pathwayType": "ANIMAL",
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2990,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".118207991985825",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".998327941066227",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".010320741937068",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".022075129370099",
						"qValue": "1",
						"foldChange": "0.8317"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".26720293275304",
						"qValue": "1",
						"foldChange": "0.9168"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".219807229899631",
						"qValue": "1",
						"foldChange": "1.1411"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".004455234811986",
						"qValue": ".189570241250002",
						"foldChange": "1.4339"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".073082079408434",
						"qValue": ".687896186587066",
						"foldChange": "1.2943"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".254942044316419",
						"qValue": ".957839854315834",
						"foldChange": "0.9160"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".711736174246504",
						"qValue": "1",
						"foldChange": "0.9671"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".626966171963024",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".907013693595757",
						"qValue": "1",
						"foldChange": "0.9415"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".039802164600414",
						"qValue": "1",
						"foldChange": "0.8112"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".399908470597208",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".005568700807439",
						"qValue": "1",
						"foldChange": "1.3744"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".268401588362227",
						"qValue": "1",
						"foldChange": "1.2172"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".050569938737737",
						"qValue": "1",
						"foldChange": "1.2721"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".334116647175853",
						"qValue": "1",
						"foldChange": "1.0452"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".737602070969289",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".234300723309379",
						"qValue": "1",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".125722599565621",
						"qValue": ".985830071188835",
						"foldChange": "1.1427"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".841893381118818",
						"qValue": ".356905219903469",
						"foldChange": "1.0922"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".857210661219618",
						"qValue": ".302953285490382",
						"foldChange": "0.9804"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".515152120554554",
						"qValue": ".113584665384229",
						"foldChange": "1.0797"
					}
				]
			},
			{
				"metaboliteId": "0078dac6-0000-0000-0000-000000000000",
				"chemicalId": "00000806-0000-0000-0000-000000000000",
				"chemicalName": "ethylmalonate",
				"plotName": null,
				"compoundId": "00003d95-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00622",
				"inChiKey": "UKFXDFUAPNAMPJ-UHFFFAOYAZ",
				"kegg": null,
				"mass": 132.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 347,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".596409625395307",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".755354606350028",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".140843385568208",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".054585496368836",
						"qValue": "1",
						"foldChange": "0.8614"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".531695345654934",
						"qValue": "1",
						"foldChange": "0.9609"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".186446468007641",
						"qValue": "1",
						"foldChange": "1.1540"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".182689827349299",
						"qValue": ".797133099236872",
						"foldChange": "1.2080"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".596105258367283",
						"qValue": ".928868848501546",
						"foldChange": "1.0007"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".065404103280059",
						"qValue": ".783042523881455",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".885163191928824",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".887931278855054",
						"qValue": "1",
						"foldChange": "1.0078"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".99720270266478",
						"qValue": "1",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".455539342627155",
						"qValue": "1",
						"foldChange": "0.9800"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".226112722560702",
						"qValue": "1",
						"foldChange": "0.7542"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".597160234126487",
						"qValue": "1",
						"foldChange": "0.7695"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".651805530857941",
						"qValue": "1",
						"foldChange": "1.5236"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".555287848247876",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".301064199610199",
						"qValue": "1",
						"foldChange": "0.6093"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".483240538684013",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".294454057351496",
						"qValue": "1",
						"foldChange": "0.7795"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".687805024298305",
						"qValue": ".992105993541858",
						"foldChange": "0.8089"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".538021215992189",
						"qValue": ".266268702746747",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005755324599524",
						"qValue": ".007038823607937",
						"foldChange": "2.2305"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".040087537155344",
						"qValue": ".015835652826692",
						"foldChange": "1.4117"
					}
				]
			},
			{
				"metaboliteId": "0078dac7-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8c2-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxychlorothalonil",
				"plotName": null,
				"compoundId": "0000bd39-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "MDQKYGOECVSPIW-UHFFFAOYAI",
				"kegg": null,
				"mass": 245.915446,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5347,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".171163325749149",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".531540099455441",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".254442539658866",
						"qValue": ".966654469864711",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".273836997508333",
						"qValue": "1",
						"foldChange": "0.9438"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".4858932448531",
						"qValue": "1",
						"foldChange": "0.9627"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".687529569933987",
						"qValue": "1",
						"foldChange": "1.0413"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".075492029521749",
						"qValue": ".60040857124307",
						"foldChange": "1.1415"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".653934828780144",
						"qValue": ".934949271461149",
						"foldChange": "1.0078"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".027871889840881",
						"qValue": ".587380998803463",
						"foldChange": "0.8861"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".918611759740136",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".805348551904959",
						"qValue": "1",
						"foldChange": "0.9943"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".885259036008505",
						"qValue": "1",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".14798277506522",
						"qValue": "1",
						"foldChange": "0.7915"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".566958518567269",
						"qValue": "1",
						"foldChange": "1.2775"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".051443921736499",
						"qValue": "1",
						"foldChange": "1.6139"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".619227479110626",
						"qValue": "1",
						"foldChange": "0.9304"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".371699539576168",
						"qValue": "1",
						"foldChange": "1.2766"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".167569061102412",
						"qValue": "1",
						"foldChange": "1.3721"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".174791714809881",
						"qValue": "1",
						"foldChange": "0.8203"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".475054169506123",
						"qValue": "1",
						"foldChange": "1.1689"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".045778055717762",
						"qValue": ".985830071188835",
						"foldChange": "1.4251"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".327417273258471",
						"qValue": ".190014925400157",
						"foldChange": "1.3843"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".188795392170613",
						"qValue": ".099706293985613",
						"foldChange": "1.2963"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037640792405779",
						"qValue": ".01516382095168",
						"foldChange": "1.2821"
					}
				]
			},
			{
				"metaboliteId": "0078dac8-0000-0000-0000-000000000000",
				"chemicalId": "0000041d-0000-0000-0000-000000000000",
				"chemicalName": "3-ureidopropionate",
				"plotName": null,
				"compoundId": "00000c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00026",
				"inChiKey": "JSJWCHRYRHKBBW-UHFFFAOYAH",
				"kegg": "C02642",
				"mass": 132.053493,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".278928678137665",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".850845947329049",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".914972114545423",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".813964750129727",
						"qValue": "1",
						"foldChange": "2.2462"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".928021841319714",
						"qValue": "1",
						"foldChange": "1.0083"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".744772924823202",
						"qValue": "1",
						"foldChange": "1.9872"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".64575659995457",
						"qValue": "1",
						"foldChange": "1.1470"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".66137986691643",
						"qValue": ".934949271461149",
						"foldChange": "1.8876"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".370867894464435",
						"qValue": ".957839854315834",
						"foldChange": "2.5737"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".687138504726779",
						"qValue": "1",
						"foldChange": "1.2508"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".560929194374712",
						"qValue": "1",
						"foldChange": "1.8309"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".857638460748277",
						"qValue": "1",
						"foldChange": "1.5367"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".66520150695023",
						"qValue": "1",
						"foldChange": "0.9115"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".17979692739506",
						"qValue": "1",
						"foldChange": "0.4839"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".336800859131764",
						"qValue": "1",
						"foldChange": "0.5309"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".288469557159351",
						"qValue": "1",
						"foldChange": "0.3961"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".240710650710288",
						"qValue": "1",
						"foldChange": "0.3897"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".860732623287891",
						"qValue": "1",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".965069741181484",
						"qValue": "1",
						"foldChange": "1.0939"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".472597638625686",
						"qValue": "1",
						"foldChange": "0.7036"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".436649010962524",
						"qValue": ".985830071188835",
						"foldChange": "0.6432"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000032428055685",
						"qValue": ".000282285966353",
						"foldChange": "0.2031"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00053401687884",
						"qValue": ".001535141301162",
						"foldChange": "0.3469"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00044867110114",
						"qValue": ".000581737979366",
						"foldChange": "0.2933"
					}
				]
			},
			{
				"metaboliteId": "0078dac9-0000-0000-0000-000000000000",
				"chemicalId": "00000326-0000-0000-0000-000000000000",
				"chemicalName": "dimethylglycine",
				"plotName": null,
				"compoundId": "000013de-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00092",
				"inChiKey": "FFDGPVCHZBVARC-UHFFFAOYAX",
				"kegg": "C01026",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 5,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".665892697970457",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".464837979633506",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".199542589593912",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".436110525352164",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".107486762450881",
						"qValue": "1",
						"foldChange": "1.2525"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".01909199178464",
						"qValue": "1",
						"foldChange": "1.7684"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".882755304411333",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".464611181153771",
						"qValue": ".902562957068497",
						"foldChange": "0.9661"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".558690302962199",
						"qValue": ".957839854315834",
						"foldChange": "0.9746"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".389714829874277",
						"qValue": "1",
						"foldChange": "0.9115"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".412368897308154",
						"qValue": "1",
						"foldChange": "0.9234"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".967412358977949",
						"qValue": "1",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".824633345079319",
						"qValue": "1",
						"foldChange": "1.0318"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".268018388304547",
						"qValue": "1",
						"foldChange": "1.3822"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".355412935323751",
						"qValue": "1",
						"foldChange": "1.3396"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".559486377260906",
						"qValue": "1",
						"foldChange": "1.1146"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".299628264421925",
						"qValue": "1",
						"foldChange": "1.2764"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".614999030477284",
						"qValue": "1",
						"foldChange": "1.1452"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".276649892009909",
						"qValue": "1",
						"foldChange": "0.8097"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".822527579126203",
						"qValue": "1",
						"foldChange": "1.0355"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".40730939229663",
						"qValue": ".985830071188835",
						"foldChange": "1.2789"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".344832254268255",
						"qValue": ".192863611674612",
						"foldChange": "1.4581"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".335823072126939",
						"qValue": ".152009550549949",
						"foldChange": "1.1930"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".039384379312155",
						"qValue": ".015596206528884",
						"foldChange": "1.3606"
					}
				]
			},
			{
				"metaboliteId": "0078daca-0000-0000-0000-000000000000",
				"chemicalId": "05f5e45f-0000-0000-0000-000000000000",
				"chemicalName": "adipate (C6-DC)",
				"plotName": null,
				"compoundId": "0000528e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00448",
				"inChiKey": "WNLRTRBMVRJNCN-UHFFFAOYAY",
				"kegg": "C06104",
				"mass": 146.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".552452785761292",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".618935237033416",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".720726866950326",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".318938151573692",
						"qValue": "1",
						"foldChange": "82.1707"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".857388045240222",
						"qValue": "1",
						"foldChange": "0.8872"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".240769882764888",
						"qValue": "1",
						"foldChange": "1.0850"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".962354033766288",
						"qValue": "1",
						"foldChange": "81.9194"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".476364003080152",
						"qValue": ".902562957068497",
						"foldChange": "65.1437"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".447875141767195",
						"qValue": ".957839854315834",
						"foldChange": "240.8697"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".529838826375209",
						"qValue": "1",
						"foldChange": "14.9836"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".476363484401025",
						"qValue": "1",
						"foldChange": "42.5489"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".932654823510109",
						"qValue": "1",
						"foldChange": "16.8070"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".876854251612787",
						"qValue": "1",
						"foldChange": "1.8309"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".446075877151938",
						"qValue": "1",
						"foldChange": "0.0313"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".352696007559077",
						"qValue": "1",
						"foldChange": "0.0171"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".412365591751265",
						"qValue": "1",
						"foldChange": "0.5680"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".291837476928348",
						"qValue": "1",
						"foldChange": "0.1121"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".773215632328211",
						"qValue": "1",
						"foldChange": "0.1974"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".34125716035556",
						"qValue": "1",
						"foldChange": "2.3926"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".949507071099703",
						"qValue": "1",
						"foldChange": "0.6960"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".391040909679283",
						"qValue": ".985830071188835",
						"foldChange": "0.2909"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000032961250394",
						"qValue": ".000282285966353",
						"foldChange": "0.0922"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000896639920885",
						"qValue": ".002049384024503",
						"foldChange": "0.2357"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000042804382768",
						"qValue": ".000135948862004",
						"foldChange": "0.1157"
					}
				]
			},
			{
				"metaboliteId": "0078dacb-0000-0000-0000-000000000000",
				"chemicalId": "00000235-0000-0000-0000-000000000000",
				"chemicalName": "tryptophan",
				"plotName": null,
				"compoundId": "00000036-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00929",
				"inChiKey": "QIVBCDIJIAJPQS-VIFPVBQEBP",
				"kegg": "C00078",
				"mass": 204.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 229,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".742161984679646",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".324906487672104",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".691950598616852",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".196414623560945",
						"qValue": "1",
						"foldChange": "0.9575"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".812479617698709",
						"qValue": "1",
						"foldChange": "1.0140"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".128050096223526",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".265310241056202",
						"qValue": ".901656639749634",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".227563570578802",
						"qValue": ".825038702902081",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".924541323612421",
						"qValue": "1",
						"foldChange": "1.0010"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".809447257828986",
						"qValue": "1",
						"foldChange": "0.9945"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".777797195783619",
						"qValue": "1",
						"foldChange": "0.9950"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".967219599700796",
						"qValue": "1",
						"foldChange": "0.9424"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".64210769626772",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".598535107086345",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".324763845257474",
						"qValue": "1",
						"foldChange": "0.9459"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".546644370047083",
						"qValue": "1",
						"foldChange": "1.0353"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".962876710287861",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".591540014816731",
						"qValue": "1",
						"foldChange": "0.9518"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".733521289677144",
						"qValue": "1",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".413387027494143",
						"qValue": "1",
						"foldChange": "0.8928"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".609510440652069",
						"qValue": ".985830071188835",
						"foldChange": "0.9151"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".608740881436328",
						"qValue": ".290834600193072",
						"foldChange": "0.9802"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".028401546306177",
						"qValue": ".023782793130499",
						"foldChange": "1.1129"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".106218365041406",
						"qValue": ".034086537824213",
						"foldChange": "1.1007"
					}
				]
			},
			{
				"metaboliteId": "0078dacc-0000-0000-0000-000000000000",
				"chemicalId": "05f5f91b-0000-0000-0000-000000000000",
				"chemicalName": "eugenol sulfate",
				"plotName": null,
				"compoundId": "0000be4b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "XWAZTQUZONPJEW-UHFFFAOYAI",
				"kegg": null,
				"mass": 244.040544,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4610,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".894491528159136",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".648043341028514",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".123946755847255",
						"qValue": ".843829513808114",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".612801143539868",
						"qValue": "1",
						"foldChange": "3.4327"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".177301082700084",
						"qValue": "1",
						"foldChange": "0.8052"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".066374790838855",
						"qValue": "1",
						"foldChange": "0.7835"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".887771400444681",
						"qValue": "1",
						"foldChange": "2.6794"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".242164797064418",
						"qValue": ".825038702902081",
						"foldChange": "3.0456"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".302483140195166",
						"qValue": ".957839854315834",
						"foldChange": "2.4834"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".598540272552464",
						"qValue": "1",
						"foldChange": "3.3280"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".097065672751927",
						"qValue": "1",
						"foldChange": "6.4325"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".250857040796048",
						"qValue": "1",
						"foldChange": "2.7844"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".392289236688318",
						"qValue": "1",
						"foldChange": "1.6385"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".413212465052003",
						"qValue": "1",
						"foldChange": "1.0328"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".992585225022694",
						"qValue": "1",
						"foldChange": "0.6303"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".273210787945805",
						"qValue": "1",
						"foldChange": "0.8385"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".431811449163729",
						"qValue": "1",
						"foldChange": "0.2736"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".794378738248676",
						"qValue": "1",
						"foldChange": "0.3263"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".445302195671291",
						"qValue": "1",
						"foldChange": "1.8658"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".262398811167461",
						"qValue": "1",
						"foldChange": "1.1655"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".67858876980906",
						"qValue": ".990272584968167",
						"foldChange": "0.6247"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".228230434067979",
						"qValue": ".146266748811641",
						"foldChange": "1.2276"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".292129815634167",
						"qValue": ".137600269361708",
						"foldChange": "0.6545"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".284532330967121",
						"qValue": ".072960043892725",
						"foldChange": "2.5518"
					}
				]
			},
			{
				"metaboliteId": "0078dacd-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9e7-0000-0000-0000-000000000000",
				"chemicalName": "3-methoxycatechol sulfate (1)",
				"plotName": null,
				"compoundId": "0000be7b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 220.004159,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4122,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".716234790281103",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".647588407920491",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".226842217830986",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".021155159142911",
						"qValue": "1",
						"foldChange": "2.9512"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".083394764879692",
						"qValue": "1",
						"foldChange": "2.9723"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".541243271270378",
						"qValue": "1",
						"foldChange": "1.0569"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".770451498110959",
						"qValue": "1",
						"foldChange": "1.9812"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".862299791722839",
						"qValue": "1",
						"foldChange": "2.1141"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".642030667786712",
						"qValue": ".973034272612281",
						"foldChange": "1.8427"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".499106921744551",
						"qValue": "1",
						"foldChange": "1.2437"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".641609426053635",
						"qValue": "1",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".832503894272742",
						"qValue": "1",
						"foldChange": "1.5197"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".344414585478138",
						"qValue": "1",
						"foldChange": "0.9176"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".696125359672521",
						"qValue": "1",
						"foldChange": "0.7221"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".602952259615759",
						"qValue": "1",
						"foldChange": "0.7870"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".619967722487315",
						"qValue": "1",
						"foldChange": "0.5670"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".232208493154708",
						"qValue": "1",
						"foldChange": "0.3502"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".451976040595253",
						"qValue": "1",
						"foldChange": "0.6175"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".936634314336842",
						"qValue": "1",
						"foldChange": "1.0647"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".440692720142064",
						"qValue": "1",
						"foldChange": "0.6042"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".386729331322335",
						"qValue": ".985830071188835",
						"foldChange": "0.5674"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".738156146915639",
						"qValue": ".330265614336544",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".720739746643354",
						"qValue": ".270492211834564",
						"foldChange": "1.3251"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".31130962004573",
						"qValue": ".077719085139784",
						"foldChange": "2.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dace-0000-0000-0000-000000000000",
				"chemicalId": "00000166-0000-0000-0000-000000000000",
				"chemicalName": "hypotaurine",
				"plotName": null,
				"compoundId": "0000024e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00965",
				"inChiKey": "VVIUBCNYACGLLV-UHFFFAOYAM",
				"kegg": "C00519",
				"mass": 109.019751,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 405,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".432546977851398",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".447141470529089",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".191698188787752",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".960239841530578",
						"qValue": "1",
						"foldChange": "1.3165"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".10603754906043",
						"qValue": "1",
						"foldChange": "0.8511"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".096102542786962",
						"qValue": "1",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".151215902941479",
						"qValue": ".742487134132407",
						"foldChange": "0.8729"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".213232079792948",
						"qValue": ".825038702902081",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".844644751881817",
						"qValue": "1",
						"foldChange": "1.2203"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".576174468248553",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".389977540936003",
						"qValue": "1",
						"foldChange": "1.2745"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".159098225440115",
						"qValue": ".969566045102463",
						"foldChange": "1.3829"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".664723635397317",
						"qValue": "1",
						"foldChange": "0.7568"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".652059738672115",
						"qValue": "1",
						"foldChange": "0.6983"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".966380872546644",
						"qValue": "1",
						"foldChange": "0.9227"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".086315495579194",
						"qValue": "1",
						"foldChange": "0.6245"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".31608227786455",
						"qValue": "1",
						"foldChange": "0.6643"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".516861843803587",
						"qValue": "1",
						"foldChange": "1.0637"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".980082228981539",
						"qValue": "1",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".083380456012656",
						"qValue": "1",
						"foldChange": "1.3821"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".072498951461861",
						"qValue": ".985830071188835",
						"foldChange": "1.2986"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".481772196571235",
						"qValue": ".243527085616754",
						"foldChange": "1.2318"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".050627159090609",
						"qValue": ".037746532463098",
						"foldChange": "0.5003"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".018634071905121",
						"qValue": ".00883749283066",
						"foldChange": "0.6691"
					}
				]
			},
			{
				"metaboliteId": "0078dacf-0000-0000-0000-000000000000",
				"chemicalId": "00000197-0000-0000-0000-000000000000",
				"chemicalName": "lysine",
				"plotName": null,
				"compoundId": "00000515-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00182",
				"inChiKey": "KDXKERNSBIXSRK-YFKPBYRVBE",
				"kegg": "C00047",
				"mass": 146.105528,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 98,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".835480270616382",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".210317444558058",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".808789611487627",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".553526179010023",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".903223463688244",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".637335036836383",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".093911587278048",
						"qValue": ".655071809619825",
						"foldChange": "0.9504"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".101954725085035",
						"qValue": ".697458782960496",
						"foldChange": "0.9539"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".966967036228687",
						"qValue": "1",
						"foldChange": "1.0106"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".645422600658884",
						"qValue": "1",
						"foldChange": "0.9865"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".353362065669894",
						"qValue": "1",
						"foldChange": "0.9717"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".637561627473354",
						"qValue": "1",
						"foldChange": "0.9497"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".326135304635092",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".822833209047911",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".468338301747781",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".712501130122134",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".776301247063479",
						"qValue": "1",
						"foldChange": "0.9797"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".947353616986931",
						"qValue": "1",
						"foldChange": "0.9531"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".915629808715224",
						"qValue": "1",
						"foldChange": "1.0041"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".784003260133479",
						"qValue": "1",
						"foldChange": "0.9222"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".701578380006117",
						"qValue": ".995398138635986",
						"foldChange": "0.9185"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".218612427159562",
						"qValue": ".143177969939591",
						"foldChange": "1.1820"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000000278380139",
						"qValue": ".000025875103674",
						"foldChange": "1.3462"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004079577602567",
						"qValue": ".002852241898975",
						"foldChange": "1.2371"
					}
				]
			},
			{
				"metaboliteId": "0078dad0-0000-0000-0000-000000000000",
				"chemicalId": "00000401-0000-0000-0000-000000000000",
				"chemicalName": "pipecolate",
				"plotName": null,
				"compoundId": "000005a4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00070",
				"inChiKey": "HXEACLLIILLPRG-UHFFFAOYAL",
				"kegg": "C00408",
				"mass": 129.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 123,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".000677318170446",
						"qValue": ".288198881524596",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".033915222761249",
						"qValue": ".931027566768472",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".004377170236445",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".550590548787163",
						"qValue": "1",
						"foldChange": "1.2173"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".677805632823924",
						"qValue": "1",
						"foldChange": "1.1038"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".855483721584614",
						"qValue": "1",
						"foldChange": "1.0183"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000083301087479",
						"qValue": ".025307661163412",
						"foldChange": "2.5946"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".000046638343139",
						"qValue": ".014909821197287",
						"foldChange": "2.6702"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".86019377016279",
						"qValue": "1",
						"foldChange": "1.1136"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".71099649681864",
						"qValue": "1",
						"foldChange": "1.4863"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".414176442413503",
						"qValue": "1",
						"foldChange": "0.9602"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".654026155723515",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".058841990110123",
						"qValue": "1",
						"foldChange": "2.2444"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".102654485012033",
						"qValue": "1",
						"foldChange": "1.8803"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".865088167035268",
						"qValue": "1",
						"foldChange": "0.8378"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".000069612410854",
						"qValue": ".056866799790957",
						"foldChange": "3.3087"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".319997202476504",
						"qValue": "1",
						"foldChange": "1.5275"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".002446700466856",
						"qValue": ".605991101987089",
						"foldChange": "0.4617"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".000031244135374",
						"qValue": ".02658875920349",
						"foldChange": "3.8835"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".422234432712037",
						"qValue": "1",
						"foldChange": "1.2056"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".000678821047252",
						"qValue": ".577676711211385",
						"foldChange": "0.3104"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".466977224767576",
						"qValue": ".238230762907792",
						"foldChange": "1.1887"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".009425611076256",
						"qValue": ".009662860679716",
						"foldChange": "2.1211"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".409130034087924",
						"qValue": ".094516228523022",
						"foldChange": "0.9884"
					}
				]
			},
			{
				"metaboliteId": "0078dad1-0000-0000-0000-000000000000",
				"chemicalId": "00000459-0000-0000-0000-000000000000",
				"chemicalName": "4-acetamidobutanoate",
				"plotName": null,
				"compoundId": "00000616-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03681",
				"inChiKey": "UZTFMUBKZQVKLK-UHFFFAOYAY",
				"kegg": "C02946",
				"mass": 145.073894,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 484,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".854034059410747",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".467838527248093",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".313272869969267",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".062806002524545",
						"qValue": "1",
						"foldChange": "0.8824"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".990586350028703",
						"qValue": "1",
						"foldChange": "1.0668"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".064402183246035",
						"qValue": "1",
						"foldChange": "1.2205"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".409789112536819",
						"qValue": ".985114504996703",
						"foldChange": "1.1057"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".20401823924047",
						"qValue": ".825038702902081",
						"foldChange": "1.1867"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".650315964730984",
						"qValue": ".973034272612281",
						"foldChange": "1.1075"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".817232377994171",
						"qValue": "1",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".948653046249833",
						"qValue": "1",
						"foldChange": "1.0118"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".867569240555902",
						"qValue": "1",
						"foldChange": "0.9214"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".261616391565096",
						"qValue": "1",
						"foldChange": "0.7960"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".387054125127756",
						"qValue": "1",
						"foldChange": "0.8579"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".836711287870551",
						"qValue": "1",
						"foldChange": "1.0778"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".681176147718845",
						"qValue": "1",
						"foldChange": "1.1046"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".767135873577806",
						"qValue": "1",
						"foldChange": "1.1385"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".924394864487333",
						"qValue": "1",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".670915333361913",
						"qValue": "1",
						"foldChange": "0.9852"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".411095049111872",
						"qValue": "1",
						"foldChange": "0.8250"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".664817521887137",
						"qValue": ".990272584968167",
						"foldChange": "0.8374"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006408769671496",
						"qValue": ".010994214172589",
						"foldChange": "1.9386"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001930449392567",
						"qValue": ".003282310628423",
						"foldChange": "2.1061"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000848053089101",
						"qValue": ".000953472142124",
						"foldChange": "2.0433"
					}
				]
			},
			{
				"metaboliteId": "0078dad2-0000-0000-0000-000000000000",
				"chemicalId": "0000045a-0000-0000-0000-000000000000",
				"chemicalName": "3-aminoisobutyrate",
				"plotName": null,
				"compoundId": "0000061e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03911",
				"inChiKey": "QCHPKSFMDHPSNR-UHFFFAOYAN",
				"kegg": "C05145",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3895,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".072388316795506",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".868736182076965",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".260874084198254",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".311032026105858",
						"qValue": "1",
						"foldChange": "0.9260"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".969118204363667",
						"qValue": "1",
						"foldChange": "1.0449"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".293199227177128",
						"qValue": "1",
						"foldChange": "1.1477"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".268708816183779",
						"qValue": ".901656639749634",
						"foldChange": "0.9571"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".101252654640353",
						"qValue": ".697458782960496",
						"foldChange": "0.9192"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".583586484112773",
						"qValue": ".957839854315834",
						"foldChange": "0.9706"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".212639069693236",
						"qValue": "1",
						"foldChange": "1.1857"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".511956947080754",
						"qValue": "1",
						"foldChange": "1.1300"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".549721420533925",
						"qValue": "1",
						"foldChange": "0.9246"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".049712348487934",
						"qValue": "1",
						"foldChange": "0.5589"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".121078020293987",
						"qValue": "1",
						"foldChange": "0.5719"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".731691816369654",
						"qValue": "1",
						"foldChange": "1.0233"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".048058415306047",
						"qValue": "1",
						"foldChange": "0.5761"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".52334880963375",
						"qValue": "1",
						"foldChange": "0.7530"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".192112484933164",
						"qValue": "1",
						"foldChange": "1.3069"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".010346965445661",
						"qValue": "1",
						"foldChange": "0.5134"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".193703205581487",
						"qValue": "1",
						"foldChange": "0.6329"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".207973626628026",
						"qValue": ".985830071188835",
						"foldChange": "1.2328"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".135436307264718",
						"qValue": ".102378280720937",
						"foldChange": "1.3954"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".877410737983237",
						"qValue": ".30775202738327",
						"foldChange": "1.1705"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00263006269917",
						"qValue": ".002072797805554",
						"foldChange": "2.4382"
					}
				]
			},
			{
				"metaboliteId": "0078dad3-0000-0000-0000-000000000000",
				"chemicalId": "05f5f14b-0000-0000-0000-000000000000",
				"chemicalName": "carboxyibuprofen",
				"plotName": null,
				"compoundId": "0000a945-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60564",
				"inChiKey": "DIVLBIVDYADZPL-UHFFFAOYAN",
				"kegg": null,
				"mass": 236.104859,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4794,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".284298378308482",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".972237892556173",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".411280472487109",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".292376747209051",
						"qValue": "1",
						"foldChange": "4.4031"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".316275414648568",
						"qValue": "1",
						"foldChange": "6.2412"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".958822062132343",
						"qValue": "1",
						"foldChange": "1.1329"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".952139577516886",
						"qValue": "1",
						"foldChange": "2.3376"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".904702384202858",
						"qValue": "1",
						"foldChange": "14.8464"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".952386728105185",
						"qValue": "1",
						"foldChange": "1.7611"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".171568438493345",
						"qValue": "1",
						"foldChange": "0.8648"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".166170270506058",
						"qValue": "1",
						"foldChange": "1.0196"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".985670162156047",
						"qValue": "1",
						"foldChange": "1.1202"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".519041906923187",
						"qValue": "1",
						"foldChange": "0.8465"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".200888950829",
						"qValue": "1",
						"foldChange": "97.2047"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".056963495227986",
						"qValue": "1",
						"foldChange": "114.8290"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".224571569561041",
						"qValue": "1",
						"foldChange": "0.1041"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".930013365102716",
						"qValue": "1",
						"foldChange": "0.6442"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".275961744090869",
						"qValue": "1",
						"foldChange": "6.1901"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".248686347916465",
						"qValue": "1",
						"foldChange": "0.3529"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".944195241943174",
						"qValue": "1",
						"foldChange": "0.6050"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".294653374015585",
						"qValue": ".985830071188835",
						"foldChange": "1.7147"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".361963851124201",
						"qValue": ".19973804205116",
						"foldChange": "0.5326"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".083666494927727",
						"qValue": ".053814760149294",
						"foldChange": "16.1641"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".315860174784057",
						"qValue": ".07861100690158",
						"foldChange": "0.3911"
					}
				]
			},
			{
				"metaboliteId": "0078dad4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8ee-0000-0000-0000-000000000000",
				"chemicalName": "4-vinylguaiacol sulfate",
				"plotName": null,
				"compoundId": "0000bd3a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 230.024894,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4604,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".583397684255536",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".209187212699876",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".272653506387369",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".107096986457838",
						"qValue": "1",
						"foldChange": "12.2302"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".097101152929408",
						"qValue": "1",
						"foldChange": "11.6961"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".960327985667891",
						"qValue": "1",
						"foldChange": "1.5870"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".375661803597295",
						"qValue": ".957150284015863",
						"foldChange": "1.4945"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".228288463696039",
						"qValue": ".825038702902081",
						"foldChange": "69.9440"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".039683844930717",
						"qValue": ".675419040720797",
						"foldChange": "86.9188"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".424190899076993",
						"qValue": "1",
						"foldChange": "0.9425"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".87145046027141",
						"qValue": "1",
						"foldChange": "1.5684"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".523049967093267",
						"qValue": "1",
						"foldChange": "3.9033"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".45377855459032",
						"qValue": "1",
						"foldChange": "2.2517"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".080469694290094",
						"qValue": "1",
						"foldChange": "1.2154"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".279345016356205",
						"qValue": "1",
						"foldChange": "0.5398"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".198227804926129",
						"qValue": "1",
						"foldChange": "0.1385"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".86571457919063",
						"qValue": "1",
						"foldChange": "0.8673"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".281652539486049",
						"qValue": "1",
						"foldChange": "6.2623"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".755339719051848",
						"qValue": "1",
						"foldChange": "6.6026"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".747296530940012",
						"qValue": "1",
						"foldChange": "1.1777"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".977461258990033",
						"qValue": "1",
						"foldChange": "0.1784"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032861751754418",
						"qValue": ".036758311294647",
						"foldChange": "12.1367"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".481018776239862",
						"qValue": ".196097037882554",
						"foldChange": "5.7880"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".164482533017326",
						"qValue": ".048063078602301",
						"foldChange": "6.1217"
					}
				]
			},
			{
				"metaboliteId": "0078dad5-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8c9-0000-0000-0000-000000000000",
				"chemicalName": "isoeugenol sulfate",
				"plotName": null,
				"compoundId": "0000bf65-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 244.040544,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4616,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".706499566966255",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".457538130120926",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".583265443597252",
						"qValue": ".996704603416188",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".737826151179009",
						"qValue": "1",
						"foldChange": "1.0816"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".79888571495098",
						"qValue": "1",
						"foldChange": "1.0424"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".93619627312556",
						"qValue": "1",
						"foldChange": "1.0169"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".657483850617461",
						"qValue": "1",
						"foldChange": "0.9826"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".223067425696405",
						"qValue": ".825038702902081",
						"foldChange": "1.5344"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".099346939608928",
						"qValue": ".861821748215854",
						"foldChange": "1.5313"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".234368701129678",
						"qValue": "1",
						"foldChange": "0.9017"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".560144018363007",
						"qValue": "1",
						"foldChange": "1.0528"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".539751623959706",
						"qValue": "1",
						"foldChange": "1.1343"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".949047753012616",
						"qValue": "1",
						"foldChange": "0.8893"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".85637277163469",
						"qValue": "1",
						"foldChange": "0.9162"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".90168323824654",
						"qValue": "1",
						"foldChange": "1.0303"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".604777680828578",
						"qValue": "1",
						"foldChange": "0.7334"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".277701142081932",
						"qValue": "1",
						"foldChange": "0.5488"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".537328475983136",
						"qValue": "1",
						"foldChange": "0.7483"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".629417326376183",
						"qValue": "1",
						"foldChange": "1.0525"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".499119899281961",
						"qValue": "1",
						"foldChange": "0.6128"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".249117760930631",
						"qValue": ".985830071188835",
						"foldChange": "0.5822"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".273564527453027",
						"qValue": ".16742077809825",
						"foldChange": "0.4244"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".198010122910343",
						"qValue": ".102821623218658",
						"foldChange": "1.7291"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".48657553886877",
						"qValue": ".109017069361015",
						"foldChange": "0.7147"
					}
				]
			},
			{
				"metaboliteId": "0078dad6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e9-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/16:0, d18:1/16:1)*",
				"plotName": null,
				"compoundId": "0000a5db-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 700.5519,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2805,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".59413827245646",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".875573139686468",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".312590335966824",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".813226177367327",
						"qValue": "1",
						"foldChange": "0.9933"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".255028139736809",
						"qValue": "1",
						"foldChange": "1.0435"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".171072924136976",
						"qValue": "1",
						"foldChange": "1.0545"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".729984554821355",
						"qValue": "1",
						"foldChange": "1.0214"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".344808811362886",
						"qValue": ".847814688346659",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".1993760845748",
						"qValue": ".957839854315834",
						"foldChange": "0.9656"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".892294531291544",
						"qValue": "1",
						"foldChange": "0.9982"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".327449328675084",
						"qValue": "1",
						"foldChange": "0.9699"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".397766093322093",
						"qValue": "1",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".320383268621984",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".263529374955434",
						"qValue": "1",
						"foldChange": "1.0999"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".851661957443705",
						"qValue": "1",
						"foldChange": "1.0099"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".226563371928653",
						"qValue": "1",
						"foldChange": "1.1321"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".249511344381429",
						"qValue": "1",
						"foldChange": "1.1584"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".994433145109937",
						"qValue": "1",
						"foldChange": "1.0233"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".845520421035337",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".754196294446127",
						"qValue": "1",
						"foldChange": "1.0372"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".894817571993214",
						"qValue": ".997573179629986",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".973531442766422",
						"qValue": ".389869372516017",
						"foldChange": "0.9947"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".88475235639512",
						"qValue": ".309160458567174",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".424735003916012",
						"qValue": ".097553087669563",
						"foldChange": "1.0764"
					}
				]
			},
			{
				"metaboliteId": "0078dad7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebf7-0000-0000-0000-000000000000",
				"chemicalName": "venlafaxine",
				"plotName": null,
				"compoundId": "000096df-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05016",
				"inChiKey": "PNVNVHUZROJLTJ-UHFFFAOYAJ",
				"kegg": "C07187",
				"mass": 277.204179,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5093,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".44421693119313",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339933",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".116308759889679",
						"qValue": ".687352462959148",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".523215364331876",
						"qValue": ".910963185640849",
						"foldChange": "0.9934"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".029588072913235",
						"qValue": ".587380998803463",
						"foldChange": "0.9788"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".593398108408829",
						"qValue": "1",
						"foldChange": "1.0071"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".605007458076872",
						"qValue": "1",
						"foldChange": "0.9930"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".065949130376776",
						"qValue": "1",
						"foldChange": "1.0269"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".074656134863269",
						"qValue": ".822195137696456",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dad8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6dd-0000-0000-0000-000000000000",
				"chemicalName": "oleoylcarnitine (C18:1)",
				"plotName": null,
				"compoundId": "00008958-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05065",
				"inChiKey": "IPOLTUVFXFHAHI-WHIOSMTNBE",
				"kegg": null,
				"mass": 425.350509,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1667,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".25730128783177",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".031169238071514",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".34828823796993",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".26146169921713",
						"qValue": "1",
						"foldChange": "1.1204"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".573623746852391",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".571522698645888",
						"qValue": "1",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".891791354432506",
						"qValue": "1",
						"foldChange": "1.0481"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".429221766624826",
						"qValue": ".894260227315856",
						"foldChange": "0.9773"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".354963813062476",
						"qValue": ".957839854315834",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".017960872515139",
						"qValue": ".764235125519165",
						"foldChange": "1.2309"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".799680710353976",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".009408216032004",
						"qValue": ".566846104084484",
						"foldChange": "0.8014"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".402833798829418",
						"qValue": "1",
						"foldChange": "0.8770"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".185842205991563",
						"qValue": "1",
						"foldChange": "0.8089"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".57859895116105",
						"qValue": "1",
						"foldChange": "0.9224"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".150203592502705",
						"qValue": "1",
						"foldChange": "0.8149"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".62531497810097",
						"qValue": "1",
						"foldChange": "0.8612"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".364674264019018",
						"qValue": "1",
						"foldChange": "1.0569"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".104279758687925",
						"qValue": "1",
						"foldChange": "0.8039"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".073980129082869",
						"qValue": "1",
						"foldChange": "0.7529"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".788924672261384",
						"qValue": ".997573179629986",
						"foldChange": "0.9366"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".139326643358337",
						"qValue": ".104365929028258",
						"foldChange": "1.3004"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000131356876577",
						"qValue": ".000714140423892",
						"foldChange": "1.5150"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000009239788903",
						"qValue": ".000064533115359",
						"foldChange": "2.1197"
					}
				]
			},
			{
				"metaboliteId": "0078dad9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e110-0000-0000-0000-000000000000",
				"chemicalName": "suberate (C8-DC)",
				"plotName": null,
				"compoundId": "00003d72-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00893",
				"inChiKey": "TYFQFVWCELRYAO-UHFFFAOYAN",
				"kegg": "C08278",
				"mass": 174.08921,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1500,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".135488191952887",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".449759613452735",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".857003326576765",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".505743418655292",
						"qValue": "1",
						"foldChange": "1.4437"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".704254263309806",
						"qValue": "1",
						"foldChange": "1.1519"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".774084951857941",
						"qValue": "1",
						"foldChange": "1.0641"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".815448157938553",
						"qValue": "1",
						"foldChange": "1.7638"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".709933890193308",
						"qValue": ".950986052805043",
						"foldChange": "1.5529"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".545495907924846",
						"qValue": ".957839854315834",
						"foldChange": "1.9245"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".368830220726505",
						"qValue": "1",
						"foldChange": "1.6555"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".177993565496878",
						"qValue": "1",
						"foldChange": "1.9502"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".647739348156674",
						"qValue": "1",
						"foldChange": "1.1573"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".678024379452379",
						"qValue": "1",
						"foldChange": "1.2236"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".152435151853628",
						"qValue": "1",
						"foldChange": "0.6903"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".0634185498713",
						"qValue": "1",
						"foldChange": "0.5642"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".626439358813608",
						"qValue": "1",
						"foldChange": "0.9760"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".247230601215345",
						"qValue": "1",
						"foldChange": "0.6636"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".472923313201148",
						"qValue": "1",
						"foldChange": "0.6799"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".697996214859862",
						"qValue": "1",
						"foldChange": "1.1903"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".674149753387466",
						"qValue": "1",
						"foldChange": "0.7919"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".42069752021279",
						"qValue": ".985830071188835",
						"foldChange": "0.6653"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000043295411504",
						"qValue": ".000320994653109",
						"foldChange": "0.3268"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000041365934275",
						"qValue": ".000377054242849",
						"foldChange": "0.3284"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000036826056223",
						"qValue": ".000127928458776",
						"foldChange": "0.3237"
					}
				]
			},
			{
				"metaboliteId": "0078dada-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5b4-0000-0000-0000-000000000000",
				"chemicalName": "1-methylhistamine",
				"plotName": null,
				"compoundId": "0000ab37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00898",
				"inChiKey": "FHQDWPCFSJMNCT-UHFFFAOYAR",
				"kegg": "C05127",
				"mass": 125.095297,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 85,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".417130854845433",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".672260347492854",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".691112561028355",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".504943810394689",
						"qValue": "1",
						"foldChange": "1.3562"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".317001321775217",
						"qValue": "1",
						"foldChange": "0.8725"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".73579082887698",
						"qValue": "1",
						"foldChange": "1.1137"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".726616921740187",
						"qValue": "1",
						"foldChange": "1.1191"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".547511897336771",
						"qValue": ".917177375585502",
						"foldChange": "2.0573"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".34313038852585",
						"qValue": ".957839854315834",
						"foldChange": "2.2232"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".984117859303242",
						"qValue": "1",
						"foldChange": "1.1251"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".38178640720058",
						"qValue": "1",
						"foldChange": "1.7589"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".371176178176654",
						"qValue": "1",
						"foldChange": "1.5783"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".696458439380324",
						"qValue": "1",
						"foldChange": "1.0904"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".625974071793926",
						"qValue": "1",
						"foldChange": "0.8380"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".381514174430324",
						"qValue": "1",
						"foldChange": "0.7685"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".498285044070414",
						"qValue": "1",
						"foldChange": "1.2666"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".971062406934998",
						"qValue": "1",
						"foldChange": "0.7988"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".53587688916796",
						"qValue": "1",
						"foldChange": "0.6307"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".085373622657852",
						"qValue": "1",
						"foldChange": "2.3890"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".281925418794601",
						"qValue": "1",
						"foldChange": "1.6101"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".562815057312049",
						"qValue": ".985830071188835",
						"foldChange": "0.6740"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".030135507706218",
						"qValue": ".035009063309924",
						"foldChange": "2.5707"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".012629439984845",
						"qValue": ".012313546833222",
						"foldChange": "3.8143"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".035702267009573",
						"qValue": ".01475595078312",
						"foldChange": "1.5966"
					}
				]
			},
			{
				"metaboliteId": "0078dadb-0000-0000-0000-000000000000",
				"chemicalId": "05f5f31b-0000-0000-0000-000000000000",
				"chemicalName": "methionine sulfone",
				"plotName": null,
				"compoundId": "0000af4e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "UCUNFLYVYCGDHP-UHFFFAOYAG",
				"kegg": null,
				"mass": 181.040879,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 375,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".645316630025874",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".991634359231073",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".157546483113431",
						"qValue": ".892830373251164",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".13741648581503",
						"qValue": "1",
						"foldChange": "0.8782"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".899000561750743",
						"qValue": "1",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".107895775810004",
						"qValue": "1",
						"foldChange": "1.2503"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".578775703754148",
						"qValue": "1",
						"foldChange": "1.0773"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".289146711942065",
						"qValue": ".825038702902081",
						"foldChange": "0.9838"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".109471643925272",
						"qValue": ".893591860856987",
						"foldChange": "0.9176"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".352054813895487",
						"qValue": "1",
						"foldChange": "1.1142"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".510831953253608",
						"qValue": "1",
						"foldChange": "1.0779"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".782769409045407",
						"qValue": "1",
						"foldChange": "0.9367"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".713113961357061",
						"qValue": "1",
						"foldChange": "1.0880"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".712304765559459",
						"qValue": "1",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".982739870374655",
						"qValue": "1",
						"foldChange": "1.0151"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".172306287989352",
						"qValue": "1",
						"foldChange": "1.2790"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".127864465629991",
						"qValue": "1",
						"foldChange": "1.3321"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".805293415581868",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".840117313846077",
						"qValue": "1",
						"foldChange": "1.0167"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".52351750949357",
						"qValue": "1",
						"foldChange": "1.0654"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".397343891622897",
						"qValue": ".985830071188835",
						"foldChange": "1.0480"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".0003732760123",
						"qValue": ".001261103830151",
						"foldChange": "2.9692"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001479292302875",
						"qValue": ".002742250837077",
						"foldChange": "3.1607"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002812773279404",
						"qValue": ".002163761790037",
						"foldChange": "2.2021"
					}
				]
			},
			{
				"metaboliteId": "0078dadc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e5-0000-0000-0000-000000000000",
				"chemicalName": "2-methylbutyrylcarnitine (C5)",
				"plotName": null,
				"compoundId": "0000b027-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00378",
				"inChiKey": "IHCPDBBYTYJYIL-UHFFFAOYAT",
				"kegg": null,
				"mass": 245.162708,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".935261372605976",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".548506250290272",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".869821045070405",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".620450617919663",
						"qValue": "1",
						"foldChange": "1.0293"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".63979361084946",
						"qValue": "1",
						"foldChange": "1.0943"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".337199064313278",
						"qValue": "1",
						"foldChange": "1.1978"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".564279629210931",
						"qValue": "1",
						"foldChange": "0.9683"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".435727073665472",
						"qValue": ".897829878182365",
						"foldChange": "1.0030"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".838061398083125",
						"qValue": "1",
						"foldChange": "1.0592"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".418994405190265",
						"qValue": "1",
						"foldChange": "0.9151"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".457927775268714",
						"qValue": "1",
						"foldChange": "0.9344"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".946956275988585",
						"qValue": "1",
						"foldChange": "1.0088"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".590855487036198",
						"qValue": "1",
						"foldChange": "1.0225"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".746403783612211",
						"qValue": "1",
						"foldChange": "0.9777"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".849251129914571",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".615905752903993",
						"qValue": "1",
						"foldChange": "1.0380"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".932633906571096",
						"qValue": "1",
						"foldChange": "0.9730"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".689406767456689",
						"qValue": "1",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".763201071241907",
						"qValue": "1",
						"foldChange": "0.9299"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".616187810121787",
						"qValue": "1",
						"foldChange": "0.8523"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".824185105075265",
						"qValue": ".997573179629986",
						"foldChange": "0.9166"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".021111758496142",
						"qValue": ".026004026626802",
						"foldChange": "1.7919"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000149563736173",
						"qValue": ".000753498995453",
						"foldChange": "2.2895"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".025723159547986",
						"qValue": ".011487957398081",
						"foldChange": "1.6868"
					}
				]
			},
			{
				"metaboliteId": "0078dadd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e58a-0000-0000-0000-000000000000",
				"chemicalName": "propionylcarnitine (C3)",
				"plotName": null,
				"compoundId": "00007ec4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00824",
				"inChiKey": "UFAHZIUFPNSHSL-UHFFFAOYAT",
				"kegg": "C03017",
				"mass": 217.131408,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1595,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".321164479181965",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".61914741332805",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".308796658012276",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".111496033333458",
						"qValue": "1",
						"foldChange": "1.2213"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".150198582471823",
						"qValue": "1",
						"foldChange": "1.1821"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".874239276816836",
						"qValue": "1",
						"foldChange": "1.0406"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".281676803471746",
						"qValue": ".911265664999995",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".60195987360132",
						"qValue": ".928868848501546",
						"foldChange": "1.0044"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".575551344737564",
						"qValue": ".957839854315834",
						"foldChange": "1.0811"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".410790296981232",
						"qValue": "1",
						"foldChange": "0.9313"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".742367820448236",
						"qValue": "1",
						"foldChange": "1.0464"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".251637476820606",
						"qValue": "1",
						"foldChange": "1.0481"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".069726470025008",
						"qValue": "1",
						"foldChange": "1.3042"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".718527665523729",
						"qValue": "1",
						"foldChange": "0.9754"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".158241525820083",
						"qValue": "1",
						"foldChange": "0.7479"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".556735192007359",
						"qValue": "1",
						"foldChange": "1.0140"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".441890320550049",
						"qValue": "1",
						"foldChange": "0.8089"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".179228787044039",
						"qValue": "1",
						"foldChange": "0.7977"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".359781976157933",
						"qValue": "1",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".886445255844458",
						"qValue": "1",
						"foldChange": "0.8500"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".302719088465588",
						"qValue": ".985830071188835",
						"foldChange": "0.7717"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".686903514359283",
						"qValue": ".318080126193171",
						"foldChange": "1.1928"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".032141151828815",
						"qValue": ".026333063244051",
						"foldChange": "1.4184"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".943042394017603",
						"qValue": ".184675407176391",
						"foldChange": "1.0850"
					}
				]
			},
			{
				"metaboliteId": "0078dade-0000-0000-0000-000000000000",
				"chemicalId": "05f5e21d-0000-0000-0000-000000000000",
				"chemicalName": "N-alpha-acetylornithine",
				"plotName": null,
				"compoundId": "000080d8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03357",
				"inChiKey": "JRLGPAXAGHMNOL-LURJTMIEBY",
				"kegg": "C00437",
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 437,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".856197864193893",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".883996411098343",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".893443099298052",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".545896303359419",
						"qValue": "1",
						"foldChange": "1.3087"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".940769412035221",
						"qValue": "1",
						"foldChange": "1.0538"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".596117654239634",
						"qValue": "1",
						"foldChange": "1.0632"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999855876356487",
						"qValue": "1",
						"foldChange": "1.1606"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".641494998762427",
						"qValue": ".928868848501546",
						"foldChange": "1.2736"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".641366127258876",
						"qValue": ".973034272612281",
						"foldChange": "1.1928"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".603872436905314",
						"qValue": "1",
						"foldChange": "0.9276"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".810807054580838",
						"qValue": "1",
						"foldChange": "1.0838"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".449200187913897",
						"qValue": "1",
						"foldChange": "1.1301"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".707993822980727",
						"qValue": "1",
						"foldChange": "0.9908"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".966340551533226",
						"qValue": "1",
						"foldChange": "0.9342"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".684772557152438",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".399240583374886",
						"qValue": "1",
						"foldChange": "0.8143"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".420171445897395",
						"qValue": "1",
						"foldChange": "0.7875"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".99279265486131",
						"qValue": "1",
						"foldChange": "0.9672"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".929504292745816",
						"qValue": "1",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".861552732188639",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".791558149553787",
						"qValue": ".997573179629986",
						"foldChange": "0.9147"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000002200774199",
						"qValue": ".000069678697434",
						"foldChange": "5.4682"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000001337760077",
						"qValue": ".000074605927325",
						"foldChange": "6.5932"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000048120984674",
						"qValue": ".000145975389111",
						"foldChange": "3.8214"
					}
				]
			},
			{
				"metaboliteId": "0078dadf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e622-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylthreonine",
				"plotName": null,
				"compoundId": "00008254-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29159",
				"inChiKey": "GWNXFCYUJXASDX-JUZNGCLZBW",
				"kegg": null,
				"mass": 248.100836,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".277744010750789",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".43522287541884",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".386541047663454",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".896546334349451",
						"qValue": "1",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".595002338119492",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".508670878016445",
						"qValue": "1",
						"foldChange": "1.0844"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".442790755513827",
						"qValue": "1",
						"foldChange": "0.9900"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".027807734472705",
						"qValue": ".678234398444917",
						"foldChange": "0.8880"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".141454195009865",
						"qValue": ".957839854315834",
						"foldChange": "0.9120"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".86627699673146",
						"qValue": "1",
						"foldChange": "0.9922"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".546895620392154",
						"qValue": "1",
						"foldChange": "0.9475"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".663763895712992",
						"qValue": "1",
						"foldChange": "0.9208"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".086994851692491",
						"qValue": "1",
						"foldChange": "1.2456"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".118905990696778",
						"qValue": "1",
						"foldChange": "1.1499"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".946934281254321",
						"qValue": "1",
						"foldChange": "0.9232"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".177643176450071",
						"qValue": "1",
						"foldChange": "1.2445"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".125624959045771",
						"qValue": "1",
						"foldChange": "1.1852"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".786169240330743",
						"qValue": "1",
						"foldChange": "0.9524"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".958373055712676",
						"qValue": "1",
						"foldChange": "1.0863"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".38004947634593",
						"qValue": "1",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".395791668785911",
						"qValue": ".985830071188835",
						"foldChange": "0.9789"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".049679849593425",
						"qValue": ".049619497031124",
						"foldChange": "1.5993"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".025618111641045",
						"qValue": ".021980059266868",
						"foldChange": "1.7738"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".035347313210587",
						"qValue": ".014646899048312",
						"foldChange": "1.3234"
					}
				]
			},
			{
				"metaboliteId": "0078dae0-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed8a-0000-0000-0000-000000000000",
				"chemicalName": "valylleucine",
				"plotName": null,
				"compoundId": "00009c3a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29131",
				"inChiKey": "XCTHZFGSVQBHBW-UHFFFAOYAS",
				"kegg": null,
				"mass": 230.163043,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 942,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".290175765807852",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".859116094924483",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".997146564427347",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".930833314285312",
						"qValue": "1",
						"foldChange": "1.5879"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".669627264854623",
						"qValue": "1",
						"foldChange": "0.9333"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".733810287306167",
						"qValue": "1",
						"foldChange": "1.4578"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".934167005528745",
						"qValue": "1",
						"foldChange": "1.1416"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".961789445618707",
						"qValue": "1",
						"foldChange": "1.6981"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".972321129583328",
						"qValue": "1",
						"foldChange": "1.4113"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".992610403572711",
						"qValue": "1",
						"foldChange": "1.0077"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".686785812464524",
						"qValue": "1",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".693594090733387",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".857541181567946",
						"qValue": "1",
						"foldChange": "1.0030"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".331499408989012",
						"qValue": "1",
						"foldChange": "1.1994"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".410928904560063",
						"qValue": "1",
						"foldChange": "1.1958"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".851110740774614",
						"qValue": "1",
						"foldChange": "0.8372"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".29782552388445",
						"qValue": "1",
						"foldChange": "0.9734"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".375448212888655",
						"qValue": "1",
						"foldChange": "1.1627"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".582328375730075",
						"qValue": "1",
						"foldChange": "1.5278"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".331942744540119",
						"qValue": "1",
						"foldChange": "1.2184"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".642969510312177",
						"qValue": ".985830071188835",
						"foldChange": "0.7975"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".031473331813712",
						"qValue": ".036255122858497",
						"foldChange": "2.1302"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".021456376977769",
						"qValue": ".01930011084387",
						"foldChange": "2.7917"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003112214901288",
						"qValue": ".002295271635847",
						"foldChange": "2.2045"
					}
				]
			},
			{
				"metaboliteId": "0078dae1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e805-0000-0000-0000-000000000000",
				"chemicalName": "3-(cystein-S-yl)acetaminophen*",
				"plotName": null,
				"compoundId": "0000863d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LLHICPSCVFRWDT-UHFFFAOYAK",
				"kegg": null,
				"mass": 270.067428,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4779,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".25682708246392",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".631194225767326",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".321835299247928",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".467277369442309",
						"qValue": "1",
						"foldChange": "2.9097"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".321752176458234",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".089347636262641",
						"qValue": "1",
						"foldChange": "0.9349"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".937034780761",
						"qValue": "1",
						"foldChange": "1.2203"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".701518155287661",
						"qValue": ".950986052805043",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".644046137161361",
						"qValue": ".973034272612281",
						"foldChange": "0.9258"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".142189719852072",
						"qValue": "1",
						"foldChange": "0.8776"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".772796624322263",
						"qValue": "1",
						"foldChange": "0.9336"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".235317816369737",
						"qValue": "1",
						"foldChange": "4.4637"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".172003525280387",
						"qValue": "1",
						"foldChange": "0.2268"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".617567261177606",
						"qValue": "1",
						"foldChange": "1.3126"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".411435207064923",
						"qValue": "1",
						"foldChange": "5.7883"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".070783822149688",
						"qValue": "1",
						"foldChange": "0.1949"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".046868671069395",
						"qValue": "1",
						"foldChange": "0.1513"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".765175123628012",
						"qValue": "1",
						"foldChange": "0.7765"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".351838102632538",
						"qValue": "1",
						"foldChange": "0.2510"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".966867827548083",
						"qValue": "1",
						"foldChange": "1.1205"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".389740751280538",
						"qValue": ".985830071188835",
						"foldChange": "4.4637"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".174306354489282",
						"qValue": ".120524565802269",
						"foldChange": "4.4637"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".292683342300078",
						"qValue": ".074338758094144",
						"foldChange": "3.9836"
					}
				]
			},
			{
				"metaboliteId": "0078dae2-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed25-0000-0000-0000-000000000000",
				"chemicalName": "2-oxindole-3-acetate",
				"plotName": null,
				"compoundId": "00009e1f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB35514",
				"inChiKey": "ILGMGHZPXRDCCS-UHFFFAOYAC",
				"kegg": null,
				"mass": 191.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4290,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".630627021046566",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".245234518102857",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".881842242770809",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".600105194930756",
						"qValue": "1",
						"foldChange": "1.5156"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".685573884664805",
						"qValue": "1",
						"foldChange": "1.4457"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".354426624607689",
						"qValue": "1",
						"foldChange": "4.0738"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".357768241111144",
						"qValue": ".951252030575746",
						"foldChange": "3.0087"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".212006553733864",
						"qValue": ".825038702902081",
						"foldChange": "2.3053"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".738057730532113",
						"qValue": ".976023509428735",
						"foldChange": "2.6424"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".871975509388741",
						"qValue": "1",
						"foldChange": "1.8604"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".272529928253897",
						"qValue": "1",
						"foldChange": "2.8527"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".347885773260053",
						"qValue": "1",
						"foldChange": "2.4194"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".323563464122917",
						"qValue": "1",
						"foldChange": "0.6631"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".994430343518155",
						"qValue": "1",
						"foldChange": "1.4881"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".342376595967262",
						"qValue": "1",
						"foldChange": "2.2441"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".988514447374112",
						"qValue": "1",
						"foldChange": "0.8965"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".651386116736204",
						"qValue": "1",
						"foldChange": "0.6913"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".633556570187963",
						"qValue": "1",
						"foldChange": "0.7711"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".658761198027061",
						"qValue": "1",
						"foldChange": "0.9809"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".61856011234846",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".350072281785023",
						"qValue": ".985830071188835",
						"foldChange": "0.9974"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001858397063708",
						"qValue": ".00424575637254",
						"foldChange": "10.0369"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007986886450061",
						"qValue": ".008598899084868",
						"foldChange": "10.0626"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004573924818869",
						"qValue": ".003051356741156",
						"foldChange": "10.2582"
					}
				]
			},
			{
				"metaboliteId": "0078dae3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e689-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetylglutamine",
				"plotName": null,
				"compoundId": "00008936-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06344",
				"inChiKey": "JFLIEFSWGNOPJJ-UHFFFAOYAD",
				"kegg": "C04148",
				"mass": 264.111007,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1037,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".787437970104782",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".872982657143514",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".186858289528281",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".269379307028826",
						"qValue": "1",
						"foldChange": "1.3486"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".146510997193839",
						"qValue": "1",
						"foldChange": "1.4677"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".721578901106122",
						"qValue": "1",
						"foldChange": "1.0919"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".467203333559934",
						"qValue": "1",
						"foldChange": "1.4011"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".299514337792468",
						"qValue": ".825038702902081",
						"foldChange": "1.4813"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".753409939454412",
						"qValue": ".983361746128381",
						"foldChange": "1.0911"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".11164872749022",
						"qValue": "1",
						"foldChange": "0.7682"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".128171461268598",
						"qValue": "1",
						"foldChange": "0.8218"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".942465908185807",
						"qValue": "1",
						"foldChange": "1.0642"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".67541588633008",
						"qValue": "1",
						"foldChange": "0.8482"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".370612963803069",
						"qValue": "1",
						"foldChange": "1.1997"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".189133450239749",
						"qValue": "1",
						"foldChange": "1.4145"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".502705147771947",
						"qValue": "1",
						"foldChange": "0.8627"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".480794221216856",
						"qValue": "1",
						"foldChange": "0.8917"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".941457348228586",
						"qValue": "1",
						"foldChange": "1.0336"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".480746441628274",
						"qValue": "1",
						"foldChange": "0.8489"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".388336080713698",
						"qValue": "1",
						"foldChange": "0.8362"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".83935588190543",
						"qValue": ".997573179629986",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003309509053603",
						"qValue": ".006483711875898",
						"foldChange": "3.2345"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000880917745327",
						"qValue": ".002039209562601",
						"foldChange": "3.1035"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00908705479334",
						"qValue": ".005055292740992",
						"foldChange": "2.5634"
					}
				]
			},
			{
				"metaboliteId": "0078dae4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e73c-0000-0000-0000-000000000000",
				"chemicalName": "2-methylmalonylcarnitine (C4-DC)",
				"plotName": null,
				"compoundId": "00008a9a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13133",
				"inChiKey": "XROYFEWIXXCPAW-UHFFFAOYAB",
				"kegg": null,
				"mass": 261.121237,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1599,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".98606940409722",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".462843844663453",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".918753019313833",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".532384757253891",
						"qValue": "1",
						"foldChange": "1.1416"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".684878205633323",
						"qValue": "1",
						"foldChange": "0.9476"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".826316355915933",
						"qValue": "1",
						"foldChange": "1.5965"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".886621629001692",
						"qValue": "1",
						"foldChange": "1.2254"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".631367095282765",
						"qValue": ".928868848501546",
						"foldChange": "1.0778"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".534129061276809",
						"qValue": ".957839854315834",
						"foldChange": "1.0127"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".612183428981423",
						"qValue": "1",
						"foldChange": "0.9362"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".223149555276297",
						"qValue": "1",
						"foldChange": "0.8427"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".472658386402335",
						"qValue": "1",
						"foldChange": "0.8323"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".870204318231017",
						"qValue": "1",
						"foldChange": "0.9575"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".755602329297346",
						"qValue": "1",
						"foldChange": "1.0247"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".633536614175279",
						"qValue": "1",
						"foldChange": "1.0702"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".688901822944166",
						"qValue": "1",
						"foldChange": "1.0539"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".709680310925597",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".9948351593381",
						"qValue": "1",
						"foldChange": "0.9804"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".841383070212552",
						"qValue": "1",
						"foldChange": "0.9323"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".751588114202274",
						"qValue": "1",
						"foldChange": "0.8291"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".896144716148813",
						"qValue": ".997573179629986",
						"foldChange": "0.8893"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008401849951157",
						"qValue": ".013157324947472",
						"foldChange": "2.4842"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000151323228518",
						"qValue": ".000753498995453",
						"foldChange": "3.3597"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000346678286828",
						"qValue": ".000488925621061",
						"foldChange": "3.8089"
					}
				]
			},
			{
				"metaboliteId": "0078dae5-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0ce-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylcarnosine",
				"plotName": null,
				"compoundId": "0000a9e0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12881",
				"inChiKey": "BKAYIFDRRZZKNF-VIFPVBQEBE",
				"kegg": null,
				"mass": 268.117155,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 81,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".696522930188808",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".675866986653836",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".839141542690445",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".589445712930357",
						"qValue": "1",
						"foldChange": "0.9504"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".7258262232763",
						"qValue": "1",
						"foldChange": "0.9683"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".849684742589868",
						"qValue": "1",
						"foldChange": "1.0535"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".649489959677385",
						"qValue": "1",
						"foldChange": "1.2309"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".966447796510918",
						"qValue": "1",
						"foldChange": "1.1604"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".679950186786259",
						"qValue": ".976023509428735",
						"foldChange": "1.0178"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".543373888858261",
						"qValue": "1",
						"foldChange": "1.1041"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".529410864155754",
						"qValue": "1",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".218962878783195",
						"qValue": "1",
						"foldChange": "0.8511"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".536061763362635",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".362519989153259",
						"qValue": "1",
						"foldChange": "0.8117"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".735769439357816",
						"qValue": "1",
						"foldChange": "0.8744"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".865291828120199",
						"qValue": "1",
						"foldChange": "0.8839"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".692326023676226",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".809255544295335",
						"qValue": "1",
						"foldChange": "1.1474"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".660892118301481",
						"qValue": "1",
						"foldChange": "0.8268"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".295227877838881",
						"qValue": "1",
						"foldChange": "0.7646"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".513199787002829",
						"qValue": ".985830071188835",
						"foldChange": "0.9249"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".200868854345537",
						"qValue": ".134548373216769",
						"foldChange": "1.6578"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".025418599453401",
						"qValue": ".02194391949916",
						"foldChange": "1.6317"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".070123799362619",
						"qValue": ".02456256735405",
						"foldChange": "1.7891"
					}
				]
			},
			{
				"metaboliteId": "0078dae6-0000-0000-0000-000000000000",
				"chemicalId": "05f6042f-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-oleoyl-GPC (P-16:0/18:1)*",
				"plotName": null,
				"compoundId": "0000ccfe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 743.58289,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2509,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".784443083001317",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".03130059664635",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".055036581919702",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".788674806806676",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".441457973047931",
						"qValue": "1",
						"foldChange": "0.9689"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".614730863376245",
						"qValue": "1",
						"foldChange": "0.9775"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".035288601806367",
						"qValue": ".422966199115749",
						"foldChange": "0.9129"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".014319260679572",
						"qValue": ".567215491640796",
						"foldChange": "0.8875"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".709056692920791",
						"qValue": ".976023509428735",
						"foldChange": "0.9977"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".04972099738266",
						"qValue": ".821720595261736",
						"foldChange": "1.1375"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".34284324550067",
						"qValue": "1",
						"foldChange": "0.9495"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".00462344018749",
						"qValue": ".437982515918976",
						"foldChange": "0.8351"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".799686602468871",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".368128371253388",
						"qValue": "1",
						"foldChange": "0.8350"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".245767119596486",
						"qValue": "1",
						"foldChange": "0.8361"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".662425012568927",
						"qValue": "1",
						"foldChange": "0.9387"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".979181990979012",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".691878579894462",
						"qValue": "1",
						"foldChange": "1.0460"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".705028292903141",
						"qValue": "1",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".324221665940236",
						"qValue": "1",
						"foldChange": "0.8236"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".516381150197801",
						"qValue": ".985830071188835",
						"foldChange": "0.9135"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".066464588471212",
						"qValue": ".061127221801038",
						"foldChange": "1.2157"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".115042682644209",
						"qValue": ".068108795420522",
						"foldChange": "1.1765"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007065978941668",
						"qValue": ".004192026830263",
						"foldChange": "1.6677"
					}
				]
			},
			{
				"metaboliteId": "0078dae7-0000-0000-0000-000000000000",
				"chemicalId": "05f60455-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetylglutamate",
				"plotName": null,
				"compoundId": "0000cebd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB59772",
				"inChiKey": "PTSRBZOZSRJCKX-JTQLQIEIBG",
				"kegg": null,
				"mass": 265.095023,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1036,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".913635972676938",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".92835238877713",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".592142054966153",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".707683686169715",
						"qValue": "1",
						"foldChange": "1.5588"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".232658244512894",
						"qValue": "1",
						"foldChange": "1.6927"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".40975207045318",
						"qValue": "1",
						"foldChange": "1.3944"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".376917866801811",
						"qValue": ".957483894472659",
						"foldChange": "1.8208"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".850547655759948",
						"qValue": "1",
						"foldChange": "1.7618"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".485809943305389",
						"qValue": ".957839854315834",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".533510491298337",
						"qValue": "1",
						"foldChange": "0.9131"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".461953106850852",
						"qValue": "1",
						"foldChange": "0.9384"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".909398976841478",
						"qValue": "1",
						"foldChange": "1.1049"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".845947851582338",
						"qValue": "1",
						"foldChange": "0.9612"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".547695748255359",
						"qValue": "1",
						"foldChange": "1.2378"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".422437099026335",
						"qValue": "1",
						"foldChange": "1.2877"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".931632953072303",
						"qValue": "1",
						"foldChange": "1.0564"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".989040794210969",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".944964561479336",
						"qValue": "1",
						"foldChange": "0.9625"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".420396797749079",
						"qValue": "1",
						"foldChange": "0.7474"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".600508542823343",
						"qValue": "1",
						"foldChange": "0.8352"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".805459739945946",
						"qValue": ".997573179629986",
						"foldChange": "1.1175"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000078134653536",
						"qValue": ".000431160809712",
						"foldChange": "7.8742"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000740347819988",
						"qValue": ".001927376258749",
						"foldChange": "5.6435"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000240600630104",
						"qValue": ".000379243018238",
						"foldChange": "6.0815"
					}
				]
			},
			{
				"metaboliteId": "0078dae8-0000-0000-0000-000000000000",
				"chemicalId": "05f604c8-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-palmitoleoyl-GPC (P-16:0/16:1)*",
				"plotName": null,
				"compoundId": "0000cde9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11207",
				"inChiKey": null,
				"kegg": null,
				"mass": 715.55159,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2506,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".357741461038713",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".010572959031096",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".020718618846495",
						"qValue": ".622389736970234",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".090113891966278",
						"qValue": "1",
						"foldChange": "0.9206"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".07321536101619",
						"qValue": "1",
						"foldChange": "0.9161"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".919218103332593",
						"qValue": "1",
						"foldChange": "1.0056"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".026100660505517",
						"qValue": ".359575885726771",
						"foldChange": "0.8972"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".031940447087735",
						"qValue": ".678234398444917",
						"foldChange": "0.9027"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".931664051743947",
						"qValue": "1",
						"foldChange": "1.0145"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".046728320827859",
						"qValue": ".821720595261736",
						"foldChange": "1.1472"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".173367475607285",
						"qValue": "1",
						"foldChange": "0.9253"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".001274223776643",
						"qValue": ".306920185386474",
						"foldChange": "0.8152"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".970046801356976",
						"qValue": "1",
						"foldChange": "1.0063"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".079243364398623",
						"qValue": "1",
						"foldChange": "0.7986"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".078051761465226",
						"qValue": "1",
						"foldChange": "0.7936"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".824966557683743",
						"qValue": "1",
						"foldChange": "0.9676"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".770041845187897",
						"qValue": "1",
						"foldChange": "0.9671"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".931985755304048",
						"qValue": "1",
						"foldChange": "0.9995"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".883975847166635",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".104540687676541",
						"qValue": "1",
						"foldChange": "0.7733"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".126389727100278",
						"qValue": ".985830071188835",
						"foldChange": "0.8212"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".994924088042253",
						"qValue": ".395450184722645",
						"foldChange": "1.0186"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".209973069708462",
						"qValue": ".107629123601128",
						"foldChange": "1.1187"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".078447426421781",
						"qValue": ".026721307670966",
						"foldChange": "1.3518"
					}
				]
			},
			{
				"metaboliteId": "0078dae9-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e8-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/14:0, d16:1/16:0)*",
				"plotName": null,
				"compoundId": "0000a5df-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12097",
				"inChiKey": "KYICBZWZQPCUMO-PSALXKTOBW",
				"kegg": null,
				"mass": 674.536275,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2801,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".136995178403533",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".428068590133944",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".453284752361156",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".42933745256109",
						"qValue": "1",
						"foldChange": "1.0362"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".036988001082235",
						"qValue": "1",
						"foldChange": "1.0919"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".183802811867937",
						"qValue": "1",
						"foldChange": "1.0578"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".933930156282531",
						"qValue": "1",
						"foldChange": "1.0115"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".516937158427429",
						"qValue": ".908244770314396",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".571631577008639",
						"qValue": ".957839854315834",
						"foldChange": "1.0293"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".835391304480141",
						"qValue": "1",
						"foldChange": "1.0134"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".653061033865857",
						"qValue": "1",
						"foldChange": "0.9923"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".51160380770032",
						"qValue": "1",
						"foldChange": "0.9471"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".633429408589055",
						"qValue": "1",
						"foldChange": "1.0570"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".035828045108838",
						"qValue": "1",
						"foldChange": "1.2386"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".084394445045677",
						"qValue": "1",
						"foldChange": "1.1718"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".851331761559034",
						"qValue": "1",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".057416919994039",
						"qValue": "1",
						"foldChange": "1.2348"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".075506376267333",
						"qValue": ".822195137696456",
						"foldChange": "1.1942"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".889176588496651",
						"qValue": "1",
						"foldChange": "1.0051"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".244962980209414",
						"qValue": "1",
						"foldChange": "1.0975"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".186819946046929",
						"qValue": ".985830071188835",
						"foldChange": "1.0920"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".341286118750897",
						"qValue": ".192863611674612",
						"foldChange": "1.0908"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".506331995244995",
						"qValue": ".204325434076588",
						"foldChange": "1.0925"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".064294121029373",
						"qValue": ".023073541324252",
						"foldChange": "1.1905"
					}
				]
			},
			{
				"metaboliteId": "0078daea-0000-0000-0000-000000000000",
				"chemicalId": "05f5f997-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/22:1, d18:2/22:0, d16:1/24:1)*",
				"plotName": null,
				"compoundId": "0000bd6d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12104",
				"inChiKey": "VBFKEZGCUWHGSK-ADSSWVSQBB",
				"kegg": null,
				"mass": 784.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2814,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".326009668211509",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".68496329871409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".613201691996375",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".637714543473414",
						"qValue": "1",
						"foldChange": "0.9881"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".504788372910834",
						"qValue": "1",
						"foldChange": "1.0390"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".257503201308698",
						"qValue": "1",
						"foldChange": "1.0668"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".974936347885213",
						"qValue": "1",
						"foldChange": "1.0032"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".629543507719811",
						"qValue": ".928868848501546",
						"foldChange": "1.0248"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".607486345015753",
						"qValue": ".967408831530889",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".388786627861644",
						"qValue": "1",
						"foldChange": "0.9644"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".195219736232017",
						"qValue": "1",
						"foldChange": "0.9530"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".659106155224362",
						"qValue": "1",
						"foldChange": "0.9816"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".914968915272895",
						"qValue": "1",
						"foldChange": "0.9849"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".114680928941496",
						"qValue": "1",
						"foldChange": "1.1296"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".086760154485943",
						"qValue": "1",
						"foldChange": "1.1469"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".872457532504128",
						"qValue": "1",
						"foldChange": "1.0223"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".182732542706497",
						"qValue": "1",
						"foldChange": "1.1399"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".224922390729941",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".814954592993262",
						"qValue": "1",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".669572777139114",
						"qValue": "1",
						"foldChange": "1.0361"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".507617777767429",
						"qValue": ".985830071188835",
						"foldChange": "1.0639"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".206015909548688",
						"qValue": ".136693244804768",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".478915642252659",
						"qValue": ".195812203055629",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".416701950347971",
						"qValue": ".095844978231923",
						"foldChange": "1.0886"
					}
				]
			},
			{
				"metaboliteId": "0078daeb-0000-0000-0000-000000000000",
				"chemicalId": "05f603f9-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoleoylglycerol (16:1)*",
				"plotName": null,
				"compoundId": "0000ccd0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11565",
				"inChiKey": null,
				"kegg": null,
				"mass": 328.261353,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2570,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".845505983281725",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".487700303691463",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".841841116667027",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".207132068252709",
						"qValue": "1",
						"foldChange": "6.5592"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".511932301575253",
						"qValue": "1",
						"foldChange": "2.2771"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".539493676066921",
						"qValue": "1",
						"foldChange": "2.6341"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".83790613938317",
						"qValue": "1",
						"foldChange": "3.7501"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".464810990233186",
						"qValue": ".902562957068497",
						"foldChange": "3.5547"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".59776704057471",
						"qValue": ".962532817488692",
						"foldChange": "4.6070"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".561702358681203",
						"qValue": "1",
						"foldChange": "5.3914"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".871397398257877",
						"qValue": "1",
						"foldChange": "2.9173"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".458629496379823",
						"qValue": "1",
						"foldChange": "2.6295"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".984442354303125",
						"qValue": "1",
						"foldChange": "2.1834"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".927128051893854",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".940371687001876",
						"qValue": "1",
						"foldChange": "0.4626"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".319686160268485",
						"qValue": "1",
						"foldChange": "0.3469"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".621617252648405",
						"qValue": "1",
						"foldChange": "0.5448"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".644801162254499",
						"qValue": "1",
						"foldChange": "1.5704"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".955771495167623",
						"qValue": "1",
						"foldChange": "1.1979"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".516985138528537",
						"qValue": "1",
						"foldChange": "0.5592"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".473251834739631",
						"qValue": ".985830071188835",
						"foldChange": "0.4669"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".022521212176538",
						"qValue": ".027358577528654",
						"foldChange": "0.2632"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".073069893274059",
						"qValue": ".049097059516551",
						"foldChange": "0.5163"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".508207755979938",
						"qValue": ".112544862186006",
						"foldChange": "0.4971"
					}
				]
			},
			{
				"metaboliteId": "0078daec-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9f0-0000-0000-0000-000000000000",
				"chemicalName": "N-desmethyl tramadol",
				"plotName": null,
				"compoundId": "0000cc83-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61007",
				"inChiKey": null,
				"kegg": null,
				"mass": 249.172879,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4827,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".316797415981207",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".288699042107211",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320381924234875",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".15738129514818",
						"qValue": "1",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".244609764978387",
						"qValue": "1",
						"foldChange": "0.9782"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".011907736115553",
						"qValue": ".566846104084484",
						"foldChange": "0.9616"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".341849348455188",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".330299480850961",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".037774645263885",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".033504172503846",
						"qValue": ".822195137696456",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078daed-0000-0000-0000-000000000000",
				"chemicalId": "05f5f5fc-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-oleoyl)-GPE (P-18:1)*",
				"plotName": null,
				"compoundId": "0000ae4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 463.30508,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2538,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".815054392445303",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".806389412489621",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".693679128250222",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".940901941238643",
						"qValue": "1",
						"foldChange": "1.3547"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".269434097382921",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".239195156340357",
						"qValue": "1",
						"foldChange": "1.1583"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".507154619666165",
						"qValue": "1",
						"foldChange": "0.9714"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".814953635219169",
						"qValue": ".991769031882419",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".666989019191989",
						"qValue": ".976023509428735",
						"foldChange": "1.2814"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".706634715056029",
						"qValue": "1",
						"foldChange": "1.0213"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".761847469823609",
						"qValue": "1",
						"foldChange": "1.2230"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".497632512126885",
						"qValue": "1",
						"foldChange": "1.3043"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".627795567878387",
						"qValue": "1",
						"foldChange": "0.7978"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".624830381576928",
						"qValue": "1",
						"foldChange": "0.7429"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".974990435570769",
						"qValue": "1",
						"foldChange": "0.9311"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".254489658131765",
						"qValue": "1",
						"foldChange": "0.7037"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".364980389856572",
						"qValue": "1",
						"foldChange": "0.7174"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".858532470477446",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".72852398527896",
						"qValue": "1",
						"foldChange": "1.2427"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".437028895638553",
						"qValue": "1",
						"foldChange": "1.2016"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".644625451238026",
						"qValue": ".985830071188835",
						"foldChange": "0.9669"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000056179916183",
						"qValue": ".000338193484125",
						"foldChange": "3.0269"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".167796366749353",
						"qValue": ".091564422146223",
						"foldChange": "1.4921"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004482885581676",
						"qValue": ".003015648775841",
						"foldChange": "2.0714"
					}
				]
			},
			{
				"metaboliteId": "0078daee-0000-0000-0000-000000000000",
				"chemicalId": "05f6042d-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-oleoyl-GPE (P-16:0/18:1)*",
				"plotName": null,
				"compoundId": "0000ccfd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11342",
				"inChiKey": "ZVVYJAAMWXATNY-PRWZWGSOBE",
				"kegg": null,
				"mass": 701.53594,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2503,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".820009862810701",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".300989187258537",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".727441462497214",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".97229576734884",
						"qValue": "1",
						"foldChange": "1.1032"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".340779893428891",
						"qValue": "1",
						"foldChange": "0.9319"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".323719110482648",
						"qValue": "1",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".113632394779612",
						"qValue": ".678406740976754",
						"foldChange": "0.8767"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".461347926594699",
						"qValue": ".902562957068497",
						"foldChange": "0.9486"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".389232645843097",
						"qValue": ".957839854315834",
						"foldChange": "1.1522"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".486202161561128",
						"qValue": "1",
						"foldChange": "0.9328"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".451582206855366",
						"qValue": "1",
						"foldChange": "0.9327"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".954636188741005",
						"qValue": "1",
						"foldChange": "0.9613"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".412561370372781",
						"qValue": "1",
						"foldChange": "1.0913"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".747770454659732",
						"qValue": "1",
						"foldChange": "0.9812"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".640985776014279",
						"qValue": "1",
						"foldChange": "0.8990"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".743747877716777",
						"qValue": "1",
						"foldChange": "0.9180"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".82604609414416",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".926998774159823",
						"qValue": "1",
						"foldChange": "0.9732"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".314232248118923",
						"qValue": "1",
						"foldChange": "1.1972"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".666776341110423",
						"qValue": "1",
						"foldChange": "1.0439"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".59084557887667",
						"qValue": ".985830071188835",
						"foldChange": "0.8720"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".67088537679127",
						"qValue": ".313318826464865",
						"foldChange": "0.9754"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".767290146829894",
						"qValue": ".282094648105086",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".736414581220908",
						"qValue": ".150679093764813",
						"foldChange": "0.9874"
					}
				]
			},
			{
				"metaboliteId": "0078daef-0000-0000-0000-000000000000",
				"chemicalId": "05f6045e-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoyl-oleoyl-glycerol (16:1/18:1) [2]*",
				"plotName": null,
				"compoundId": "0000cd97-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 592.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2611,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".640133013986901",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".412651950515271",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".11989225197488",
						"qValue": ".834275595151197",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".552426249027296",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".477510037892561",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".906891966363803",
						"qValue": "1",
						"foldChange": "1.1066"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".23395602785339",
						"qValue": ".852077213869098",
						"foldChange": "2.0342"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".122514407302918",
						"qValue": ".719032831826091",
						"foldChange": "1.6954"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".715514959319873",
						"qValue": ".976023509428735",
						"foldChange": "1.2962"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".025818933050773",
						"qValue": ".821720595261736",
						"foldChange": "0.7161"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".613034560729702",
						"qValue": "1",
						"foldChange": "1.0808"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".079557723462317",
						"qValue": ".770965175880612",
						"foldChange": "2.1224"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".454870302856645",
						"qValue": "1",
						"foldChange": "0.8978"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".700469518745541",
						"qValue": "1",
						"foldChange": "0.8270"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".740011479874708",
						"qValue": "1",
						"foldChange": "0.9211"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".768171990840759",
						"qValue": "1",
						"foldChange": "1.2707"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".158185823245263",
						"qValue": "1",
						"foldChange": "0.6848"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".086018205661536",
						"qValue": ".834952786556829",
						"foldChange": "0.5389"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".566714666803426",
						"qValue": "1",
						"foldChange": "1.0181"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".775577508847489",
						"qValue": "1",
						"foldChange": "0.6358"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".398455477797667",
						"qValue": ".985830071188835",
						"foldChange": "0.6245"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".645918690757423",
						"qValue": ".304640456948306",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".014525095072961",
						"qValue": ".01394598245208",
						"foldChange": "2.2881"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".535020569319742",
						"qValue": ".116084243862522",
						"foldChange": "1.8820"
					}
				]
			},
			{
				"metaboliteId": "0078daf0-0000-0000-0000-000000000000",
				"chemicalId": "05f6045f-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linoleoyl-glycerol (16:0/18:2) [2]*",
				"plotName": null,
				"compoundId": "0000cd9a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07103",
				"inChiKey": null,
				"kegg": null,
				"mass": 606.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2608,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".911529005236415",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".355511438321113",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".040674151696836",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".930035794356331",
						"qValue": "1",
						"foldChange": "1.0536"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".642639999810622",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".581320220638735",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".004831455928729",
						"qValue": ".195788999778495",
						"foldChange": "1.5891"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".009770335067499",
						"qValue": ".567215491640796",
						"foldChange": "1.4592"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".792904470932765",
						"qValue": ".99456541772943",
						"foldChange": "1.0590"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".254914439954893",
						"qValue": "1",
						"foldChange": "0.9345"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".712438863632323",
						"qValue": "1",
						"foldChange": "1.1237"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".134308472643281",
						"qValue": ".960252848686774",
						"foldChange": "1.2011"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".246243400227175",
						"qValue": "1",
						"foldChange": "0.7872"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".888649987647016",
						"qValue": "1",
						"foldChange": "1.1034"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".324857510902707",
						"qValue": "1",
						"foldChange": "1.4017"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".934735143888479",
						"qValue": "1",
						"foldChange": "0.8741"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".524940150713859",
						"qValue": "1",
						"foldChange": "0.7220"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".566929874646055",
						"qValue": "1",
						"foldChange": "0.8261"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".967639625933495",
						"qValue": "1",
						"foldChange": "0.8893"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".851849431872385",
						"qValue": "1",
						"foldChange": "0.9438"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".879151738278216",
						"qValue": ".997573179629986",
						"foldChange": "1.0613"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".900572998957262",
						"qValue": ".371321601579918",
						"foldChange": "1.1835"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".134096022211706",
						"qValue": ".076623357735055",
						"foldChange": "1.4333"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".552973242625246",
						"qValue": ".11901577280376",
						"foldChange": "1.4985"
					}
				]
			},
			{
				"metaboliteId": "0078daf1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e854-0000-0000-0000-000000000000",
				"chemicalName": "sphinganine-1-phosphate",
				"plotName": null,
				"compoundId": "0000cd7d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01383",
				"inChiKey": "YHEDRJPUIRMZMP-UHFFFAOYAT",
				"kegg": null,
				"mass": 381.26441,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2697,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".258042261610901",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".692627778524207",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".917298152997379",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".909505018984711",
						"qValue": "1",
						"foldChange": "1.0953"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".467773962257402",
						"qValue": "1",
						"foldChange": "0.9425"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".539719376776086",
						"qValue": "1",
						"foldChange": "1.0130"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".639291367336616",
						"qValue": "1",
						"foldChange": "0.9739"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".909915289069632",
						"qValue": "1",
						"foldChange": "1.1066"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".561004260418272",
						"qValue": ".957839854315834",
						"foldChange": "1.2446"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".62439688867774",
						"qValue": "1",
						"foldChange": "0.9852"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".448186855294546",
						"qValue": "1",
						"foldChange": "0.9688"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".786860612594218",
						"qValue": "1",
						"foldChange": "1.1112"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".190926403735713",
						"qValue": "1",
						"foldChange": "0.7386"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".481450112075313",
						"qValue": "1",
						"foldChange": "0.8164"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".580748951333954",
						"qValue": "1",
						"foldChange": "1.1053"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".111367397691478",
						"qValue": "1",
						"foldChange": "0.7361"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".298926094867111",
						"qValue": "1",
						"foldChange": "0.7681"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".625015617867919",
						"qValue": "1",
						"foldChange": "1.0435"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".561012066371675",
						"qValue": "1",
						"foldChange": "0.9370"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".441867933912192",
						"qValue": "1",
						"foldChange": "0.9089"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".821396022679255",
						"qValue": ".997573179629986",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".061859067843409",
						"qValue": ".057784326780781",
						"foldChange": "1.7485"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".168402719464437",
						"qValue": ".091715818385629",
						"foldChange": "1.3382"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001160604667938",
						"qValue": ".001142136931488",
						"foldChange": "1.8226"
					}
				]
			},
			{
				"metaboliteId": "0078daf2-0000-0000-0000-000000000000",
				"chemicalId": "05f60436-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-arachidonoyl-GPC (P-16:0/20:4)*",
				"plotName": null,
				"compoundId": "0000cdd1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11220",
				"inChiKey": "IOYKZPNDXIIXLN-LOQSCQKMBD",
				"kegg": null,
				"mass": 765.56724,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2515,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".358255277466708",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".03926146155604",
						"qValue": ".982691287770291",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".831541007357404",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".326457782884317",
						"qValue": "1",
						"foldChange": "0.9443"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".273937934173704",
						"qValue": "1",
						"foldChange": "0.9292"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".908692493240864",
						"qValue": "1",
						"foldChange": "1.0011"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".04752047446704",
						"qValue": ".481106286508833",
						"foldChange": "0.9152"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".020014628100088",
						"qValue": ".630831426413896",
						"foldChange": "0.8714"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".711164989163807",
						"qValue": ".976023509428735",
						"foldChange": "0.9956"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".402579518957712",
						"qValue": "1",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".506301913810779",
						"qValue": "1",
						"foldChange": "0.9616"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".861917581009936",
						"qValue": "1",
						"foldChange": "1.0111"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".720310211546855",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".937998040833812",
						"qValue": "1",
						"foldChange": "0.9981"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".669859059973011",
						"qValue": "1",
						"foldChange": "1.0377"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".287356980634969",
						"qValue": "1",
						"foldChange": "0.9064"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".883598478929616",
						"qValue": "1",
						"foldChange": "1.0093"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".238695797728831",
						"qValue": "1",
						"foldChange": "1.1135"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".214585625508733",
						"qValue": "1",
						"foldChange": "0.8736"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".717390614712973",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".117966719120759",
						"qValue": ".985830071188835",
						"foldChange": "1.1612"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".408272085171398",
						"qValue": ".216501028847437",
						"foldChange": "0.9229"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003501767339634",
						"qValue": ".005085705003212",
						"foldChange": "0.7639"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".511472219232288",
						"qValue": ".113111990416085",
						"foldChange": "0.9468"
					}
				]
			},
			{
				"metaboliteId": "0078daf3-0000-0000-0000-000000000000",
				"chemicalId": "05f6047a-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPA (18:2)*",
				"plotName": null,
				"compoundId": "0000cdd2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07856",
				"inChiKey": null,
				"kegg": null,
				"mass": 434.24334,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2306,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".686413844958916",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".665420676116848",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".306296113306136",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".65708891494769",
						"qValue": "1",
						"foldChange": "2.7800"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".514381858902882",
						"qValue": "1",
						"foldChange": "0.8684"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".275261221149776",
						"qValue": "1",
						"foldChange": "2.7166"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".358794674348733",
						"qValue": ".951252030575746",
						"foldChange": "1.2368"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".866490316405151",
						"qValue": "1",
						"foldChange": "3.0151"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".452434850763692",
						"qValue": ".957839854315834",
						"foldChange": "4.2184"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".573696196282027",
						"qValue": "1",
						"foldChange": "0.9897"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".215278392338066",
						"qValue": "1",
						"foldChange": "4.7925"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".074647964721496",
						"qValue": ".765366481662566",
						"foldChange": "4.6091"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".824884614681104",
						"qValue": "1",
						"foldChange": "1.0983"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".434396427302644",
						"qValue": "1",
						"foldChange": "0.4357"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".310979115216182",
						"qValue": "1",
						"foldChange": "0.3967"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".282274564135193",
						"qValue": "1",
						"foldChange": "0.2848"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".082432598128269",
						"qValue": "1",
						"foldChange": "0.1904"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".45267000918598",
						"qValue": "1",
						"foldChange": "0.6685"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".47936526455246",
						"qValue": "1",
						"foldChange": "1.7880"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".290113998681757",
						"qValue": "1",
						"foldChange": "1.2144"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".688131816978273",
						"qValue": ".992105993541858",
						"foldChange": "0.6792"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000082366768076",
						"qValue": ".000439852611048",
						"foldChange": "0.2018"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000205701527509",
						"qValue": ".000869077862093",
						"foldChange": "0.2238"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000043124566949",
						"qValue": ".000135948862004",
						"foldChange": "0.1633"
					}
				]
			},
			{
				"metaboliteId": "0078daf4-0000-0000-0000-000000000000",
				"chemicalId": "05f605be-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoylcarnitine (C16:1)*",
				"plotName": null,
				"compoundId": "0000cfe7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JFAGPSOZZSTLRF-KHPPLWFEBO",
				"kegg": null,
				"mass": 397.319209,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1666,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".754651398695162",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".226502824857192",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".624910073012677",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".90483171726064",
						"qValue": "1",
						"foldChange": "1.0703"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".90559211005843",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999234025758001",
						"qValue": "1",
						"foldChange": "1.0663"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".639599758051578",
						"qValue": "1",
						"foldChange": "1.1575"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".807586834511615",
						"qValue": ".991417013240296",
						"foldChange": "1.0705"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".477378026861803",
						"qValue": ".957839854315834",
						"foldChange": "0.9952"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".059659056479378",
						"qValue": ".821720595261736",
						"foldChange": "1.3405"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".949737348744476",
						"qValue": "1",
						"foldChange": "1.0448"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".068215155701878",
						"qValue": ".727024127920748",
						"foldChange": "0.8216"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".568965781277143",
						"qValue": "1",
						"foldChange": "0.8440"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".363387864443497",
						"qValue": "1",
						"foldChange": "0.7918"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".702034714940416",
						"qValue": "1",
						"foldChange": "0.9381"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".721314678658668",
						"qValue": "1",
						"foldChange": "0.9465"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".781783924643686",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".529882827613591",
						"qValue": "1",
						"foldChange": "1.0705"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".42516645262344",
						"qValue": "1",
						"foldChange": "0.8556"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".346910029971576",
						"qValue": "1",
						"foldChange": "0.8440"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".846821081141877",
						"qValue": ".997573179629986",
						"foldChange": "0.9865"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".094365746784917",
						"qValue": ".078304789052164",
						"foldChange": "1.5099"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001855027941262",
						"qValue": ".00321284434642",
						"foldChange": "1.6682"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000930022796842",
						"qValue": ".001017178718143",
						"foldChange": "1.7838"
					}
				]
			},
			{
				"metaboliteId": "0078daf5-0000-0000-0000-000000000000",
				"chemicalId": "05f60510-0000-0000-0000-000000000000",
				"chemicalName": "thioproline",
				"plotName": null,
				"compoundId": "0000cfef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DZLNHFMRPBPULJ-VKHMYHEABX",
				"kegg": null,
				"mass": 133.019749,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5369,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".037912963234704",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".464224188134363",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".870492775053996",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".313583249579108",
						"qValue": "1",
						"foldChange": "0.9143"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".594398561309187",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".631735058799335",
						"qValue": "1",
						"foldChange": "1.1594"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".427122350354404",
						"qValue": ".99041177153024",
						"foldChange": "1.0037"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".675492662500511",
						"qValue": ".937755719066778",
						"foldChange": "1.0220"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".705821578265015",
						"qValue": ".976023509428735",
						"foldChange": "1.2703"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".916852875599195",
						"qValue": "1",
						"foldChange": "1.0021"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".390835817620178",
						"qValue": "1",
						"foldChange": "1.1781"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".336474806605657",
						"qValue": "1",
						"foldChange": "1.2011"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".507074874270953",
						"qValue": "1",
						"foldChange": "0.9122"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".460239215833125",
						"qValue": "1",
						"foldChange": "1.1093"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".164276856036648",
						"qValue": "1",
						"foldChange": "1.2161"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".658526845542125",
						"qValue": "1",
						"foldChange": "0.9065"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".143063686912569",
						"qValue": "1",
						"foldChange": "1.2012"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".055762108015899",
						"qValue": ".822195137696456",
						"foldChange": "1.3251"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".584643086532825",
						"qValue": "1",
						"foldChange": "0.9520"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".058196917212714",
						"qValue": "1",
						"foldChange": "1.3468"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".014736126142727",
						"qValue": ".985830071188835",
						"foldChange": "1.4148"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003655668864586",
						"qValue": ".006861860065306",
						"foldChange": "2.1417"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006430971720788",
						"qValue": ".007503153045567",
						"foldChange": "1.9899"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".011341762924049",
						"qValue": ".005978630747845",
						"foldChange": "1.8793"
					}
				]
			},
			{
				"metaboliteId": "0078daf6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9ee-0000-0000-0000-000000000000",
				"chemicalName": "O-desmethyltramadol",
				"plotName": null,
				"compoundId": "0000cff7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60997",
				"inChiKey": null,
				"kegg": null,
				"mass": 249.172879,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4825,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".316797415981208",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".28869904210721",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320381924234875",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".72149885199722",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".044135440163109",
						"qValue": "1",
						"foldChange": "0.9739"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".019087836455996",
						"qValue": ".605042531118158",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".136639849117415",
						"qValue": "1",
						"foldChange": "1.0330"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".127539977907188",
						"qValue": "1",
						"foldChange": "1.0330"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".082003017337036",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".075034974163287",
						"qValue": ".822195137696456",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078daf7-0000-0000-0000-000000000000",
				"chemicalId": "05f60577-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linolenoyl-choline",
				"plotName": null,
				"compoundId": "0000d00e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 391.34503,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1718,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".855939909324458",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".312956642407484",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".019685830950954",
						"qValue": ".620468227380083",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".41632044261795",
						"qValue": "1",
						"foldChange": "2.2971"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".125391534063778",
						"qValue": "1",
						"foldChange": "2.7522"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".463007641336419",
						"qValue": "1",
						"foldChange": "3.6459"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".113997842490806",
						"qValue": ".678406740976754",
						"foldChange": "0.8776"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".624814848784478",
						"qValue": ".928868848501546",
						"foldChange": "1.4686"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".269301750735309",
						"qValue": ".957839854315834",
						"foldChange": "3.0853"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".118743386266957",
						"qValue": "1",
						"foldChange": "0.8844"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".002802282073079",
						"qValue": ".522751459721039",
						"foldChange": "0.3297"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".124767059303368",
						"qValue": ".952073565941074",
						"foldChange": "0.6786"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".227124024487163",
						"qValue": "1",
						"foldChange": "2.4531"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".124634788693826",
						"qValue": "1",
						"foldChange": "2.1714"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".680504481994671",
						"qValue": "1",
						"foldChange": "0.8852"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".485496267783108",
						"qValue": "1",
						"foldChange": "0.6933"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".703920367700751",
						"qValue": "1",
						"foldChange": "0.7341"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".773793799766044",
						"qValue": "1",
						"foldChange": "1.0589"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".661932953001715",
						"qValue": "1",
						"foldChange": "1.4412"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".029076217317638",
						"qValue": "1",
						"foldChange": "0.2470"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".06692814775938",
						"qValue": ".985830071188835",
						"foldChange": "0.1714"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".168265609495633",
						"qValue": ".119295845865379",
						"foldChange": "1.3906"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003261084271368",
						"qValue": ".004836924167927",
						"foldChange": "10.5879"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001582549003325",
						"qValue": ".001413532073823",
						"foldChange": "5.5178"
					}
				]
			},
			{
				"metaboliteId": "0078daf8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c2-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxyisocaproate",
				"plotName": null,
				"compoundId": "00005674-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00746",
				"inChiKey": "LVRFTAZAXQPQHI-YFKPBYRVBW",
				"kegg": "C03264",
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 300,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".615577500680339",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".975375938551764",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".219122485501407",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".298441419112373",
						"qValue": "1",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".866464250648475",
						"qValue": "1",
						"foldChange": "1.0783"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".228338826537329",
						"qValue": "1",
						"foldChange": "1.2120"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".185251092141629",
						"qValue": ".797133099236872",
						"foldChange": "1.1909"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".500161169078382",
						"qValue": ".902562957068497",
						"foldChange": "1.0085"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".048616453083198",
						"qValue": ".689543359563353",
						"foldChange": "0.8705"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".996915095715507",
						"qValue": "1",
						"foldChange": "1.0370"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".766265783489535",
						"qValue": "1",
						"foldChange": "1.0624"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".769214993636688",
						"qValue": "1",
						"foldChange": "1.0138"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".317430140254307",
						"qValue": "1",
						"foldChange": "0.8779"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".310271106242388",
						"qValue": "1",
						"foldChange": "0.8652"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".942964408887682",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".511439042164862",
						"qValue": "1",
						"foldChange": "1.0990"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".759118849743255",
						"qValue": "1",
						"foldChange": "0.9459"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".343532542956445",
						"qValue": "1",
						"foldChange": "0.8607"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".117322229259733",
						"qValue": "1",
						"foldChange": "0.7643"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".359639952902112",
						"qValue": "1",
						"foldChange": "0.8239"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".55298017923287",
						"qValue": ".985830071188835",
						"foldChange": "1.0780"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".460256983731997",
						"qValue": ".235307630734525",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".254415021722378",
						"qValue": ".124025740207524",
						"foldChange": "0.8469"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".597653733639737",
						"qValue": ".126891905178055",
						"foldChange": "0.9462"
					}
				]
			},
			{
				"metaboliteId": "0078daf9-0000-0000-0000-000000000000",
				"chemicalId": "05f60476-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-2-linoleoyl-GPE (18:1/18:2)*",
				"plotName": null,
				"compoundId": "0000cdcf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05349",
				"inChiKey": null,
				"kegg": null,
				"mass": 741.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2171,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".214883672615041",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".336957839212951",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".547816863414223",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".161719186463589",
						"qValue": "1",
						"foldChange": "1.2667"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".725157478736918",
						"qValue": "1",
						"foldChange": "1.0737"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".291052710439034",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".288969331166513",
						"qValue": ".911265664999995",
						"foldChange": "1.2312"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".08133447508203",
						"qValue": ".697458782960496",
						"foldChange": "1.3212"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".482255106089125",
						"qValue": ".957839854315834",
						"foldChange": "1.1723"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".919696544698161",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".752764197559681",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".677699171232296",
						"qValue": "1",
						"foldChange": "0.9046"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".315401911815644",
						"qValue": "1",
						"foldChange": "1.3031"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".860047546363474",
						"qValue": "1",
						"foldChange": "0.9977"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".426765530603951",
						"qValue": "1",
						"foldChange": "0.7656"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".454567769544045",
						"qValue": "1",
						"foldChange": "1.0763"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".534665372865675",
						"qValue": "1",
						"foldChange": "0.8974"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".17722259218672",
						"qValue": "1",
						"foldChange": "0.8338"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".067108721756087",
						"qValue": "1",
						"foldChange": "1.3787"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".808113835431191",
						"qValue": "1",
						"foldChange": "0.8656"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".044545255985716",
						"qValue": ".985830071188835",
						"foldChange": "0.6278"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".966281973985955",
						"qValue": ".388186098375975",
						"foldChange": "0.9675"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008303700557442",
						"qValue": ".008770671777843",
						"foldChange": "1.7117"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".070797634912862",
						"qValue": ".024744684666512",
						"foldChange": "1.5441"
					}
				]
			},
			{
				"metaboliteId": "0078dafa-0000-0000-0000-000000000000",
				"chemicalId": "05f60b52-0000-0000-0000-000000000000",
				"chemicalName": "3-methylglutarate/2-methylglutarate",
				"plotName": null,
				"compoundId": "0000d594-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00752",
				"inChiKey": null,
				"kegg": null,
				"mass": 146.05791,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1486,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".824441982491913",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".52071977065353",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".224661864862352",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".176915613299869",
						"qValue": "1",
						"foldChange": "0.9544"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".244922613582596",
						"qValue": "1",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".847557870667073",
						"qValue": "1",
						"foldChange": "1.0945"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".105803385484254",
						"qValue": ".676892563582731",
						"foldChange": "0.8876"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".909092173045369",
						"qValue": "1",
						"foldChange": "1.1602"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".084235427845337",
						"qValue": ".796492767737576",
						"foldChange": "1.5791"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".363458662507554",
						"qValue": "1",
						"foldChange": "1.2085"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".811421996275708",
						"qValue": "1",
						"foldChange": "1.0298"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".252825023579388",
						"qValue": "1",
						"foldChange": "0.8115"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".857564943852784",
						"qValue": "1",
						"foldChange": "0.9369"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".301280847092611",
						"qValue": "1",
						"foldChange": "0.7082"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".374510714871984",
						"qValue": "1",
						"foldChange": "0.7559"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".774287165726963",
						"qValue": "1",
						"foldChange": "0.8352"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".849434666540549",
						"qValue": "1",
						"foldChange": "0.8741"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".637122928818267",
						"qValue": "1",
						"foldChange": "1.0465"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".593332129322307",
						"qValue": "1",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".578584871214619",
						"qValue": "1",
						"foldChange": "0.7508"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".280282174525626",
						"qValue": ".985830071188835",
						"foldChange": "0.7191"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".413355142929655",
						"qValue": ".218274319592173",
						"foldChange": "0.8060"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".114151007093373",
						"qValue": ".067724685295739",
						"foldChange": "1.3981"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".526374214483689",
						"qValue": ".115297410138189",
						"foldChange": "1.3878"
					}
				]
			},
			{
				"metaboliteId": "0078dafb-0000-0000-0000-000000000000",
				"chemicalId": "000000b3-0000-0000-0000-000000000000",
				"chemicalName": "9,10-DiHOME",
				"plotName": null,
				"compoundId": "000095ff-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04704",
				"inChiKey": "XEBKSQSGNGRGDW-YFHOEESVBC",
				"kegg": "C14828",
				"mass": 314.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".90560346373054",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".553612223898149",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".27938539549465",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".106306843848448",
						"qValue": "1",
						"foldChange": "1.3890"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".319466418077997",
						"qValue": "1",
						"foldChange": "1.2033"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".525238244442582",
						"qValue": "1",
						"foldChange": "0.9525"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".195427258856774",
						"qValue": ".797536225282113",
						"foldChange": "0.9229"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".602884564112599",
						"qValue": ".928868848501546",
						"foldChange": "1.0959"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".433831417142455",
						"qValue": ".957839854315834",
						"foldChange": "1.2587"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".444200687556378",
						"qValue": "1",
						"foldChange": "1.1692"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".203938374903509",
						"qValue": "1",
						"foldChange": "1.3088"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".607902113973577",
						"qValue": "1",
						"foldChange": "1.0852"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".388458668444745",
						"qValue": "1",
						"foldChange": "1.2270"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".776809436337351",
						"qValue": "1",
						"foldChange": "1.0316"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".584183576768995",
						"qValue": "1",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".229366704861212",
						"qValue": "1",
						"foldChange": "0.7623"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".780663105104562",
						"qValue": "1",
						"foldChange": "0.8497"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".376577313264265",
						"qValue": "1",
						"foldChange": "1.1146"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".830834721374462",
						"qValue": "1",
						"foldChange": "0.8755"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".597913572553328",
						"qValue": "1",
						"foldChange": "0.9250"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".455759401679492",
						"qValue": ".985830071188835",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".290731908169065",
						"qValue": ".174698002124906",
						"foldChange": "0.8726"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".035721239668422",
						"qValue": ".028459258735134",
						"foldChange": "0.7604"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".436372178387152",
						"qValue": ".099656447173719",
						"foldChange": "0.9496"
					}
				]
			},
			{
				"metaboliteId": "0078dafc-0000-0000-0000-000000000000",
				"chemicalId": "05f6057a-0000-0000-0000-000000000000",
				"chemicalName": "5-bromotryptophan",
				"plotName": null,
				"compoundId": "0000cffa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KZDNJQUJBMDHJW-UHFFFAOYAY",
				"kegg": null,
				"mass": 282.00039,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 285,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".754824639205746",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".476729105990442",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".640279650170018",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".099512881685663",
						"qValue": "1",
						"foldChange": "0.8830"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".432223832110038",
						"qValue": "1",
						"foldChange": "0.9385"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".379156446489734",
						"qValue": "1",
						"foldChange": "1.1472"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".93791125602577",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".378342135097412",
						"qValue": ".875723678608451",
						"foldChange": "0.9791"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".338152534397838",
						"qValue": ".957839854315834",
						"foldChange": "0.9387"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".953917871899469",
						"qValue": "1",
						"foldChange": "1.0047"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".742152949575051",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".786162363970048",
						"qValue": "1",
						"foldChange": "0.9276"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".732077594397778",
						"qValue": "1",
						"foldChange": "1.1019"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".913446293051694",
						"qValue": "1",
						"foldChange": "0.9750"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".658018039033855",
						"qValue": "1",
						"foldChange": "0.8849"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".187817596547838",
						"qValue": "1",
						"foldChange": "1.1958"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".451919023581119",
						"qValue": "1",
						"foldChange": "1.0416"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".605419995447924",
						"qValue": "1",
						"foldChange": "0.8710"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".754010002320802",
						"qValue": "1",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".899127012964608",
						"qValue": "1",
						"foldChange": "0.9456"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".861999831587847",
						"qValue": ".997573179629986",
						"foldChange": "0.8964"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".43045949997933",
						"qValue": ".225865435912912",
						"foldChange": "0.9266"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".957213967746393",
						"qValue": ".327102583807046",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".978796635326685",
						"qValue": ".190056679181539",
						"foldChange": "1.0053"
					}
				]
			},
			{
				"metaboliteId": "0078dafd-0000-0000-0000-000000000000",
				"chemicalId": "05f6050b-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPG (18:2)*",
				"plotName": null,
				"compoundId": "0000d665-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 508.28012,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2421,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".872953718741594",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".450998751344684",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".602155354599229",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".370056066718408",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".732937655213067",
						"qValue": "1",
						"foldChange": "1.1752"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".576965261230381",
						"qValue": "1",
						"foldChange": "1.4850"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".966503433839507",
						"qValue": "1",
						"foldChange": "1.1609"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".797707582939444",
						"qValue": ".985267275880213",
						"foldChange": "1.2896"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".830268413689423",
						"qValue": "1",
						"foldChange": "1.3092"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".928478811186861",
						"qValue": "1",
						"foldChange": "1.1944"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".136335291004301",
						"qValue": "1",
						"foldChange": "0.8086"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".115007166639045",
						"qValue": ".934715307502823",
						"foldChange": "0.6665"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".906785073258502",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".760952709091008",
						"qValue": "1",
						"foldChange": "0.9791"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".84280123255904",
						"qValue": "1",
						"foldChange": "0.9180"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".40336079633651",
						"qValue": "1",
						"foldChange": "1.2536"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".276753544015146",
						"qValue": "1",
						"foldChange": "1.3105"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".758193775012055",
						"qValue": "1",
						"foldChange": "1.0454"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".841985337532381",
						"qValue": "1",
						"foldChange": "1.1908"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".489896587108538",
						"qValue": "1",
						"foldChange": "0.7062"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".36876793049644",
						"qValue": ".985830071188835",
						"foldChange": "0.5931"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".764756988900739",
						"qValue": ".336707238153361",
						"foldChange": "0.9977"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".165212710027462",
						"qValue": ".090686961091539",
						"foldChange": "1.7775"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037705537725699",
						"qValue": ".01516382095168",
						"foldChange": "1.8819"
					}
				]
			},
			{
				"metaboliteId": "0078dafe-0000-0000-0000-000000000000",
				"chemicalId": "000005a2-0000-0000-0000-000000000000",
				"chemicalName": "beta-hydroxyisovalerate",
				"plotName": null,
				"compoundId": "00002f61-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00754",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 118.062995,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 313,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".96392045127902",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".475861296524667",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".851367866994724",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".984806411595753",
						"qValue": "1",
						"foldChange": "1.0606"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".373227160546317",
						"qValue": "1",
						"foldChange": "1.1174"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".383408832214114",
						"qValue": "1",
						"foldChange": "1.1561"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".512525906678413",
						"qValue": "1",
						"foldChange": "1.0633"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".541360657296528",
						"qValue": ".910963185640849",
						"foldChange": "1.1226"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".208458137226703",
						"qValue": ".957839854315834",
						"foldChange": "1.2095"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".585583378540662",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".612945277797194",
						"qValue": "1",
						"foldChange": "0.9568"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".968455756966264",
						"qValue": "1",
						"foldChange": "0.9333"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".721813243740807",
						"qValue": "1",
						"foldChange": "1.0338"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".70334181184531",
						"qValue": "1",
						"foldChange": "1.0577"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".963872943089456",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".899694095676776",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".964838465325056",
						"qValue": "1",
						"foldChange": "0.9142"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".938667787902432",
						"qValue": "1",
						"foldChange": "0.9559"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".904047888013395",
						"qValue": "1",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".525483526977002",
						"qValue": "1",
						"foldChange": "0.8472"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".443534303784945",
						"qValue": ".985830071188835",
						"foldChange": "0.8482"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".043819733634174",
						"qValue": ".045474940023209",
						"foldChange": "0.6466"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000635359484601",
						"qValue": ".001745094256805",
						"foldChange": "0.6458"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004238489498296",
						"qValue": ".00292466545953",
						"foldChange": "0.6239"
					}
				]
			},
			{
				"metaboliteId": "0078daff-0000-0000-0000-000000000000",
				"chemicalId": "0000039e-0000-0000-0000-000000000000",
				"chemicalName": "caproate (6:0)",
				"plotName": null,
				"compoundId": "00007ee9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00535",
				"inChiKey": "FUZZWVXGSFPDMH-UHFFFAOYAY",
				"kegg": "C01585",
				"mass": 116.08373,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".814512500265945",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".505537125858692",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".391822701910587",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".251415940750393",
						"qValue": "1",
						"foldChange": "1.7513"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".417728894044684",
						"qValue": "1",
						"foldChange": "1.4040"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".732721298919498",
						"qValue": "1",
						"foldChange": "1.2449"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".105173186567868",
						"qValue": ".676892563582731",
						"foldChange": "0.8928"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".764197709677617",
						"qValue": ".970645150650227",
						"foldChange": "1.6408"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".183418972648316",
						"qValue": ".957839854315834",
						"foldChange": "2.9904"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".517173116343826",
						"qValue": "1",
						"foldChange": "0.9493"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".697329292502569",
						"qValue": "1",
						"foldChange": "1.4344"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".30180708170386",
						"qValue": "1",
						"foldChange": "1.4926"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".312002009668639",
						"qValue": "1",
						"foldChange": "1.2652"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".850268805009206",
						"qValue": "1",
						"foldChange": "0.8408"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".430723757721966",
						"qValue": "1",
						"foldChange": "0.6645"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".168010597477552",
						"qValue": "1",
						"foldChange": "0.5986"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".17833300719433",
						"qValue": "1",
						"foldChange": "0.5623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".965561042889547",
						"qValue": "1",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".966193921792535",
						"qValue": "1",
						"foldChange": "1.0156"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".883466802196982",
						"qValue": "1",
						"foldChange": "0.7444"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".848376567793026",
						"qValue": ".997573179629986",
						"foldChange": "0.7329"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000120926440559",
						"qValue": ".00055607786971",
						"foldChange": "0.2822"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000219824485056",
						"qValue": ".000898002474794",
						"foldChange": "0.3140"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000424739489158",
						"qValue": ".000574189684068",
						"foldChange": "0.3340"
					}
				]
			},
			{
				"metaboliteId": "0078db00-0000-0000-0000-000000000000",
				"chemicalId": "05f5eab8-0000-0000-0000-000000000000",
				"chemicalName": "isoursodeoxycholate",
				"plotName": null,
				"compoundId": "0000e0e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00686",
				"inChiKey": "RUDATBOHQWOJDD-DNMBCGTGBA",
				"kegg": "C17662",
				"mass": 392.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3137,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".806239533710343",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".044351056903643",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".817454971476546",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".444205651108453",
						"qValue": "1",
						"foldChange": "1.0922"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".820049209041009",
						"qValue": "1",
						"foldChange": "1.1559"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".5898164627553",
						"qValue": "1",
						"foldChange": "1.3257"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".192610558563244",
						"qValue": ".797133099236872",
						"foldChange": "0.9116"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".509897632696275",
						"qValue": ".905893288986492",
						"foldChange": "1.0412"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".513991834469475",
						"qValue": ".957839854315834",
						"foldChange": "1.4406"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".030684394408554",
						"qValue": ".821720595261736",
						"foldChange": "0.7485"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".624994886250343",
						"qValue": "1",
						"foldChange": "1.0107"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".089179462234439",
						"qValue": ".81833158267883",
						"foldChange": "1.3982"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".557909912176814",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".413164616032234",
						"qValue": "1",
						"foldChange": "1.1410"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".784323158888236",
						"qValue": "1",
						"foldChange": "1.1588"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".694780495983818",
						"qValue": "1",
						"foldChange": "0.6363"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".829419717590775",
						"qValue": "1",
						"foldChange": "0.4397"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".873079453727911",
						"qValue": "1",
						"foldChange": "0.6910"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".670860595227281",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".478912854139099",
						"qValue": "1",
						"foldChange": "0.7418"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".752087600818405",
						"qValue": ".997573179629986",
						"foldChange": "0.7318"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".067427898629628",
						"qValue": ".061670560159164",
						"foldChange": "2.1832"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".04778938721316",
						"qValue": ".036113551631962",
						"foldChange": "3.3984"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".082496103316837",
						"qValue": ".027864258354397",
						"foldChange": "5.4331"
					}
				]
			},
			{
				"metaboliteId": "0078db01-0000-0000-0000-000000000000",
				"chemicalId": "05f60bae-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linolenoyl-glycerol (18:2/18:3) [1]*",
				"plotName": null,
				"compoundId": "0000d6b3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07249",
				"inChiKey": null,
				"kegg": null,
				"mass": 614.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2633,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".526093660714782",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".714348454924734",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".186268309711408",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".565704058723364",
						"qValue": "1",
						"foldChange": "1.0175"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".457125314228499",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".864741808468715",
						"qValue": "1",
						"foldChange": "1.0096"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".125867371427074",
						"qValue": ".714087553896269",
						"foldChange": "1.6966"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".092666261449556",
						"qValue": ".697458782960496",
						"foldChange": "1.7072"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".875142688831225",
						"qValue": "1",
						"foldChange": "1.7266"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".832887527441834",
						"qValue": "1",
						"foldChange": "1.2624"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".129893806660815",
						"qValue": "1",
						"foldChange": "0.7957"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".190193471403989",
						"qValue": ".994724181158891",
						"foldChange": "0.8550"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".08741175510794",
						"qValue": "1",
						"foldChange": "0.4970"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".915634752641123",
						"qValue": "1",
						"foldChange": "1.2627"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".0784100141757",
						"qValue": "1",
						"foldChange": "2.5408"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".599579337807773",
						"qValue": "1",
						"foldChange": "0.7266"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".761523959113119",
						"qValue": "1",
						"foldChange": "1.4244"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".41396703574908",
						"qValue": "1",
						"foldChange": "1.9604"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".737102510988329",
						"qValue": "1",
						"foldChange": "0.7024"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".697469869408151",
						"qValue": "1",
						"foldChange": "0.7840"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".941641514152075",
						"qValue": ".997575444606604",
						"foldChange": "1.1161"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".454957921313001",
						"qValue": ".233643038757568",
						"foldChange": "0.7132"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".799162809084969",
						"qValue": ".289032043144021",
						"foldChange": "0.9787"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".578478759115254",
						"qValue": ".123677467000873",
						"foldChange": "1.5666"
					}
				]
			},
			{
				"metaboliteId": "0078db02-0000-0000-0000-000000000000",
				"chemicalId": "05f5e368-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-arachidonoyl-GPI (18:0/20:4)",
				"plotName": null,
				"compoundId": "0000cce1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09815",
				"inChiKey": "KRTOMQDUKGRFDJ-OALUUITLBO",
				"kegg": null,
				"mass": 886.557129,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2276,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".358239705726694",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".81990985018969",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".309150515074619",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".472938362128643",
						"qValue": "1",
						"foldChange": "1.0486"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".601630731667793",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".843978074120963",
						"qValue": "1",
						"foldChange": "1.0031"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".232440532194548",
						"qValue": ".852077213869098",
						"foldChange": "1.0792"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".157041645845734",
						"qValue": ".784201030789377",
						"foldChange": "1.0923"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".820461247110322",
						"qValue": "1",
						"foldChange": "1.0159"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".435881221760979",
						"qValue": "1",
						"foldChange": "0.9598"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".119091459478246",
						"qValue": "1",
						"foldChange": "0.9318"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".426961301676439",
						"qValue": "1",
						"foldChange": "0.9120"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".881315686854204",
						"qValue": "1",
						"foldChange": "0.9518"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".067727749147856",
						"qValue": "1",
						"foldChange": "1.1568"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".045080522570819",
						"qValue": "1",
						"foldChange": "1.2153"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".916019939118534",
						"qValue": "1",
						"foldChange": "0.9916"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".328914548275609",
						"qValue": "1",
						"foldChange": "1.1303"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".36841514612486",
						"qValue": "1",
						"foldChange": "1.1399"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".723180815437723",
						"qValue": "1",
						"foldChange": "1.0230"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".541600015146713",
						"qValue": "1",
						"foldChange": "1.0353"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".776970036671909",
						"qValue": ".997573179629986",
						"foldChange": "1.0121"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".116026332475033",
						"qValue": ".0914648260473",
						"foldChange": "1.2551"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000379193497353",
						"qValue": ".001215365026073",
						"foldChange": "1.3048"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00007738850528",
						"qValue": ".000191418587678",
						"foldChange": "1.5775"
					}
				]
			},
			{
				"metaboliteId": "0078db03-0000-0000-0000-000000000000",
				"chemicalId": "05f60bc3-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-palmitoyl-glycerol (16:0/16:0) [1]*",
				"plotName": null,
				"compoundId": "0000d6cf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07098",
				"inChiKey": null,
				"kegg": null,
				"mass": 568.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2601,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".962108764286021",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".397966544030706",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".032299425232959",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".177679177561486",
						"qValue": "1",
						"foldChange": "1.3819"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".745410638873534",
						"qValue": "1",
						"foldChange": "1.0786"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".30288971224833",
						"qValue": "1",
						"foldChange": "0.8677"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".142428945978556",
						"qValue": ".742487134132407",
						"foldChange": "1.9022"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".316054779255086",
						"qValue": ".825038702902081",
						"foldChange": "1.4197"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".634742684788606",
						"qValue": ".973034272612281",
						"foldChange": "1.1275"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".05578684126465",
						"qValue": ".821720595261736",
						"foldChange": "0.7497"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".333332645160937",
						"qValue": "1",
						"foldChange": "1.3790"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".005070116422622",
						"qValue": ".437982515918976",
						"foldChange": "2.1413"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".925654697477783",
						"qValue": "1",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".951242879844085",
						"qValue": "1",
						"foldChange": "1.0453"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".87839681285227",
						"qValue": "1",
						"foldChange": "0.9060"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".938487441004966",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".291932738203578",
						"qValue": "1",
						"foldChange": "0.3498"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".314181170073973",
						"qValue": "1",
						"foldChange": "0.3873"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".899866945616753",
						"qValue": "1",
						"foldChange": "0.8866"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".769694160241677",
						"qValue": "1",
						"foldChange": "0.8790"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".858568376763537",
						"qValue": ".997573179629986",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".796514195429157",
						"qValue": ".344316995840098",
						"foldChange": "1.5988"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".250426669533012",
						"qValue": ".122941171803504",
						"foldChange": "1.8768"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".661363281519543",
						"qValue": ".137734832380579",
						"foldChange": "1.9795"
					}
				]
			},
			{
				"metaboliteId": "0078db04-0000-0000-0000-000000000000",
				"chemicalId": "05f60422-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-linoleoyl-GPI (18:0/18:2)",
				"plotName": null,
				"compoundId": "0000ccf4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09809",
				"inChiKey": "KZVRAFHIKMDULK-PDPKURFXBD",
				"kegg": null,
				"mass": 862.557129,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2265,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".904457149380828",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".940753119451458",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".03708844006003",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".380841596523782",
						"qValue": "1",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".319279715998444",
						"qValue": "1",
						"foldChange": "1.0926"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".903494724445039",
						"qValue": "1",
						"foldChange": "1.0237"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".100513381980456",
						"qValue": ".668256938010685",
						"foldChange": "1.2482"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".050210067099918",
						"qValue": ".678234398444917",
						"foldChange": "1.2416"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".739759299155739",
						"qValue": ".976023509428735",
						"foldChange": "1.0424"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".065602796418201",
						"qValue": ".821720595261736",
						"foldChange": "0.8653"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".031820513016874",
						"qValue": ".933767468184815",
						"foldChange": "0.8505"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".74510423539864",
						"qValue": "1",
						"foldChange": "0.9036"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".958942872444666",
						"qValue": "1",
						"foldChange": "1.0838"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".164382445602675",
						"qValue": "1",
						"foldChange": "1.3686"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".14129099963038",
						"qValue": "1",
						"foldChange": "1.2628"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".757814521456102",
						"qValue": "1",
						"foldChange": "1.0541"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".986506183215156",
						"qValue": "1",
						"foldChange": "1.1382"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".778400902509908",
						"qValue": "1",
						"foldChange": "1.0798"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".683419963089704",
						"qValue": "1",
						"foldChange": "1.0592"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".833961350974374",
						"qValue": "1",
						"foldChange": "0.9880"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".542770924622431",
						"qValue": ".985830071188835",
						"foldChange": "0.9328"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".927117311608106",
						"qValue": ".379350579448788",
						"foldChange": "1.0990"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".062574802095488",
						"qValue": ".043513119386549",
						"foldChange": "1.2167"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".039043374926825",
						"qValue": ".015537709298674",
						"foldChange": "1.3171"
					}
				]
			},
			{
				"metaboliteId": "0078db05-0000-0000-0000-000000000000",
				"chemicalId": "05f60421-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-GPI (16:0/20:4)*",
				"plotName": null,
				"compoundId": "0000ccf3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09789",
				"inChiKey": "KIQYUSYSJTUGFZ-GWAZTMTOBN",
				"kegg": null,
				"mass": 858.525829,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2261,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".326091089622765",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".288832818920797",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".157606121071296",
						"qValue": ".892830373251164",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".026645377624156",
						"qValue": "1",
						"foldChange": "1.2281"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".061654660825099",
						"qValue": "1",
						"foldChange": "1.1912"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".71042355506488",
						"qValue": "1",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".158302557807616",
						"qValue": ".742487134132407",
						"foldChange": "1.2053"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".213760508321605",
						"qValue": ".825038702902081",
						"foldChange": "1.1416"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".863279103026053",
						"qValue": "1",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".391882500771502",
						"qValue": "1",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".224760741530433",
						"qValue": "1",
						"foldChange": "0.9168"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".716114754016",
						"qValue": "1",
						"foldChange": "0.9007"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".121658148593194",
						"qValue": "1",
						"foldChange": "1.3211"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".05981887171562",
						"qValue": "1",
						"foldChange": "1.3825"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".648470371696886",
						"qValue": "1",
						"foldChange": "1.0465"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".240113517333277",
						"qValue": "1",
						"foldChange": "1.2616"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".509354477407434",
						"qValue": "1",
						"foldChange": "1.1381"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".637051616662647",
						"qValue": "1",
						"foldChange": "0.9021"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".207775919285822",
						"qValue": "1",
						"foldChange": "1.3519"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".513031744750347",
						"qValue": "1",
						"foldChange": "1.0903"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".573719469640676",
						"qValue": ".985830071188835",
						"foldChange": "0.8065"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".510772619076008",
						"qValue": ".25507090210048",
						"foldChange": "0.9285"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".256507813905664",
						"qValue": ".12434741170742",
						"foldChange": "1.3087"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".439117603860987",
						"qValue": ".099999344361132",
						"foldChange": "1.1295"
					}
				]
			},
			{
				"metaboliteId": "0078db06-0000-0000-0000-000000000000",
				"chemicalId": "05f61e90-0000-0000-0000-000000000000",
				"chemicalName": "linoleoylcholine*",
				"plotName": null,
				"compoundId": "0000e077-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 365.329376,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1719,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".837287724965657",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".286755679301645",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".060725220331783",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".681781302242685",
						"qValue": "1",
						"foldChange": "2.0557"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".158752527837194",
						"qValue": "1",
						"foldChange": "2.3129"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".313307246168223",
						"qValue": "1",
						"foldChange": "4.3214"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".173402148910049",
						"qValue": ".780768405938897",
						"foldChange": "0.8614"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".314606974486016",
						"qValue": ".825038702902081",
						"foldChange": "1.2507"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".71623518802066",
						"qValue": ".976023509428735",
						"foldChange": "2.5963"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".186915871949017",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".007463344696339",
						"qValue": ".522751459721039",
						"foldChange": "0.3652"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".15220959677978",
						"qValue": ".960252848686774",
						"foldChange": "0.6082"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".421879415172486",
						"qValue": "1",
						"foldChange": "1.5909"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".223598238028639",
						"qValue": "1",
						"foldChange": "1.8013"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".635472613122482",
						"qValue": "1",
						"foldChange": "1.1323"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".526424931720359",
						"qValue": "1",
						"foldChange": "0.5560"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".823545927624974",
						"qValue": "1",
						"foldChange": "0.8034"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".699540036879965",
						"qValue": "1",
						"foldChange": "1.4449"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".234481395087304",
						"qValue": "1",
						"foldChange": "0.9132"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".027745030588269",
						"qValue": "1",
						"foldChange": "0.2364"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".255517586885865",
						"qValue": ".985830071188835",
						"foldChange": "0.2589"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".080244726910882",
						"qValue": ".069916510585225",
						"foldChange": "1.7561"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001020024845005",
						"qValue": ".00216872027018",
						"foldChange": "11.5316"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000151544763395",
						"qValue": ".000283311033531",
						"foldChange": "9.6926"
					}
				]
			},
			{
				"metaboliteId": "0078db07-0000-0000-0000-000000000000",
				"chemicalId": "05f61ead-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/24:2)*",
				"plotName": null,
				"compoundId": "0000e087-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 808.6458,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2799,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".567760539709928",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".266780661918498",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".038229327713332",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".415458013332197",
						"qValue": "1",
						"foldChange": "1.1332"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".405613568140068",
						"qValue": "1",
						"foldChange": "1.1084"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".986040234739513",
						"qValue": "1",
						"foldChange": "1.0305"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".523086720276725",
						"qValue": "1",
						"foldChange": "0.9769"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".723113077812846",
						"qValue": ".952583946159028",
						"foldChange": "1.0767"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".322472235303847",
						"qValue": ".957839854315834",
						"foldChange": "1.1338"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".03905899379438",
						"qValue": ".821720595261736",
						"foldChange": "1.2637"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".256130912716419",
						"qValue": "1",
						"foldChange": "0.9270"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".001988491441434",
						"qValue": ".306920185386474",
						"foldChange": "0.7301"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".724598920298476",
						"qValue": "1",
						"foldChange": "1.1132"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".464967119849767",
						"qValue": "1",
						"foldChange": "0.8898"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".278592214867395",
						"qValue": "1",
						"foldChange": "0.7994"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".712057504667611",
						"qValue": "1",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".958390210074308",
						"qValue": "1",
						"foldChange": "1.0138"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".760954145966166",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".920353864706912",
						"qValue": "1",
						"foldChange": "1.0797"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".093165775810709",
						"qValue": "1",
						"foldChange": "0.7400"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".070536010709943",
						"qValue": ".985830071188835",
						"foldChange": "0.6854"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013893279383647",
						"qValue": ".019166391983209",
						"foldChange": "0.6445"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".97494190856974",
						"qValue": ".330485206882319",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".308180875243328",
						"qValue": ".077057642306895",
						"foldChange": "0.8841"
					}
				]
			},
			{
				"metaboliteId": "0078db08-0000-0000-0000-000000000000",
				"chemicalId": "05f5f995-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/20:2, d18:2/20:1, d16:1/22:2)*",
				"plotName": null,
				"compoundId": "0000e089-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 754.598875,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2811,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".845295032797776",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".620253743858522",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".269951338812667",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".766190791399939",
						"qValue": "1",
						"foldChange": "1.1197"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".678720691872067",
						"qValue": "1",
						"foldChange": "1.1111"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".906800351639483",
						"qValue": "1",
						"foldChange": "1.0423"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".757273369752581",
						"qValue": "1",
						"foldChange": "1.0484"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".615034269711163",
						"qValue": ".928868848501546",
						"foldChange": "1.2554"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".417947093550056",
						"qValue": ".957839854315834",
						"foldChange": "1.2165"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".17369643852409",
						"qValue": "1",
						"foldChange": "1.2526"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".391059106913601",
						"qValue": "1",
						"foldChange": "0.9220"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".02931215240539",
						"qValue": ".614826369149758",
						"foldChange": "0.7490"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".734582374643011",
						"qValue": "1",
						"foldChange": "0.9141"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".783786792132575",
						"qValue": "1",
						"foldChange": "0.8827"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".962184534009919",
						"qValue": "1",
						"foldChange": "0.9657"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".446471321474837",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".60835979544072",
						"qValue": "1",
						"foldChange": "1.1333"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".209558314111323",
						"qValue": "1",
						"foldChange": "1.2477"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".768948597070068",
						"qValue": "1",
						"foldChange": "1.0176"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".242856686508073",
						"qValue": "1",
						"foldChange": "0.8341"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".359497649060168",
						"qValue": ".985830071188835",
						"foldChange": "0.8197"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".08324939438884",
						"qValue": ".07159244455092",
						"foldChange": "0.7301"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".978341767665539",
						"qValue": ".331076602190156",
						"foldChange": "1.0699"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".953456611883603",
						"qValue": ".186261075041408",
						"foldChange": "0.9820"
					}
				]
			},
			{
				"metaboliteId": "0078db09-0000-0000-0000-000000000000",
				"chemicalId": "05f61ed9-0000-0000-0000-000000000000",
				"chemicalName": "arachidoylcarnitine (C20)*",
				"plotName": null,
				"compoundId": "0000e0a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06460",
				"inChiKey": "SVJLJQBGUITFLI-UHFFFAOYAV",
				"kegg": null,
				"mass": 455.397459,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1651,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".391226603161634",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".98594231846859",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".378983244658825",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".912210934280828",
						"qValue": "1",
						"foldChange": "1.0271"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".423364721008569",
						"qValue": "1",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".489374297881211",
						"qValue": "1",
						"foldChange": "0.9853"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".461926149900221",
						"qValue": "1",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".475989724467165",
						"qValue": ".902562957068497",
						"foldChange": "0.9695"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".981569240744011",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".372584906636797",
						"qValue": "1",
						"foldChange": "1.0828"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".099501600496964",
						"qValue": "1",
						"foldChange": "1.1613"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".439389595694109",
						"qValue": "1",
						"foldChange": "1.0452"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".492847015447692",
						"qValue": "1",
						"foldChange": "0.9138"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".936556772244789",
						"qValue": "1",
						"foldChange": "0.9957"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".559487518987925",
						"qValue": "1",
						"foldChange": "1.0896"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".328074523009137",
						"qValue": "1",
						"foldChange": "0.8637"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".671247826571589",
						"qValue": "1",
						"foldChange": "1.0580"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".170703888477277",
						"qValue": "1",
						"foldChange": "1.2249"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".533079977361139",
						"qValue": "1",
						"foldChange": "0.9590"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".256175844687761",
						"qValue": "1",
						"foldChange": "1.2243"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".081929638243802",
						"qValue": ".985830071188835",
						"foldChange": "1.2767"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000530394098588",
						"qValue": ".001688543224346",
						"foldChange": "2.0856"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".180090293855153",
						"qValue": ".096572187574458",
						"foldChange": "1.2945"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000106935306",
						"qValue": ".000004298155574",
						"foldChange": "1.9885"
					}
				]
			},
			{
				"metaboliteId": "0078db0a-0000-0000-0000-000000000000",
				"chemicalId": "05f61eda-0000-0000-0000-000000000000",
				"chemicalName": "lignoceroylcarnitine (C24)*",
				"plotName": null,
				"compoundId": "0000e0ab-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 511.460052,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1653,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".10762014863011",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".213033561036511",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".692204417003137",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".454298854482206",
						"qValue": "1",
						"foldChange": "0.9620"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".888596464876815",
						"qValue": "1",
						"foldChange": "1.0099"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".542398910062055",
						"qValue": "1",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".05176441866257",
						"qValue": ".50633931358445",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".102686655336121",
						"qValue": ".697458782960496",
						"foldChange": "0.8840"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".742050137478914",
						"qValue": ".977530444264018",
						"foldChange": "1.0521"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".669914239906857",
						"qValue": "1",
						"foldChange": "0.9826"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".745027759210315",
						"qValue": "1",
						"foldChange": "1.0875"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".453353530988925",
						"qValue": "1",
						"foldChange": "1.0941"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".633403362326099",
						"qValue": "1",
						"foldChange": "1.0555"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".12315803764737",
						"qValue": "1",
						"foldChange": "1.3156"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".25728113782124",
						"qValue": "1",
						"foldChange": "1.2464"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".875765654917158",
						"qValue": "1",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".090825123750906",
						"qValue": "1",
						"foldChange": "1.3803"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".061177390636315",
						"qValue": ".822195137696456",
						"foldChange": "1.4269"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".746688423832763",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".074390644826732",
						"qValue": "1",
						"foldChange": "1.4614"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".034469660120814",
						"qValue": ".985830071188835",
						"foldChange": "1.5096"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".050401073853814",
						"qValue": ".050025618150769",
						"foldChange": "1.6616"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".687241508602665",
						"qValue": ".261796241705589",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02294411380285",
						"qValue": ".01045003123108",
						"foldChange": "1.3581"
					}
				]
			},
			{
				"metaboliteId": "0078db0b-0000-0000-0000-000000000000",
				"chemicalId": "05f61edc-0000-0000-0000-000000000000",
				"chemicalName": "ximenoylcarnitine (C26:1)*",
				"plotName": null,
				"compoundId": "0000e0ad-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 537.475708,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1671,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".924421590688412",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".950898170903698",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".425600867185069",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".733217818186773",
						"qValue": "1",
						"foldChange": "1.0007"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".401759112297705",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".240358042684972",
						"qValue": "1",
						"foldChange": "1.0889"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".710125015391927",
						"qValue": "1",
						"foldChange": "1.0025"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".885263704625592",
						"qValue": "1",
						"foldChange": "1.0194"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".606166638600559",
						"qValue": ".967408831530889",
						"foldChange": "1.0416"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".305347019945334",
						"qValue": "1",
						"foldChange": "1.0762"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".670200145459809",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".14954791191825",
						"qValue": ".960252848686774",
						"foldChange": "0.8675"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".806944256194084",
						"qValue": "1",
						"foldChange": "0.9297"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".758820846257005",
						"qValue": "1",
						"foldChange": "0.9580"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".937877810663636",
						"qValue": "1",
						"foldChange": "1.0304"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".803083427659985",
						"qValue": "1",
						"foldChange": "0.9682"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".790141921127081",
						"qValue": "1",
						"foldChange": "1.1868"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".607732869642038",
						"qValue": "1",
						"foldChange": "1.2258"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".5883872886202",
						"qValue": "1",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".412224717934841",
						"qValue": "1",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".749751009043578",
						"qValue": ".997573179629986",
						"foldChange": "1.0042"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".023117412844494",
						"qValue": ".027934152813657",
						"foldChange": "1.6756"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000010337930151",
						"qValue": ".000169570296005",
						"foldChange": "1.6805"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000570464212",
						"qValue": ".000011535191609",
						"foldChange": "2.4742"
					}
				]
			},
			{
				"metaboliteId": "0078db0c-0000-0000-0000-000000000000",
				"chemicalId": "05f61eea-0000-0000-0000-000000000000",
				"chemicalName": "adrenoylcarnitine (C22:4)*",
				"plotName": null,
				"compoundId": "0000e0b8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 475.36615,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1680,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".679456669695169",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".158665830891878",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".178272066800615",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".129494335033588",
						"qValue": "1",
						"foldChange": "1.1737"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".348242442124676",
						"qValue": "1",
						"foldChange": "1.1088"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".554420769597384",
						"qValue": "1",
						"foldChange": "0.9678"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".262955396008795",
						"qValue": ".901656639749634",
						"foldChange": "0.9406"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".049132405400536",
						"qValue": ".678234398444917",
						"foldChange": "0.9020"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".380091866102081",
						"qValue": ".957839854315834",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".64675516247071",
						"qValue": "1",
						"foldChange": "0.9682"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".066809890826189",
						"qValue": "1",
						"foldChange": "0.8643"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".163713481780376",
						"qValue": ".969566045102463",
						"foldChange": "0.8235"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".517778144412775",
						"qValue": "1",
						"foldChange": "1.0529"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".961669061925563",
						"qValue": "1",
						"foldChange": "0.9158"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".499444551509268",
						"qValue": "1",
						"foldChange": "0.8698"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".55827940660556",
						"qValue": "1",
						"foldChange": "0.8427"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".342858886372777",
						"qValue": "1",
						"foldChange": "0.7767"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".682201047095792",
						"qValue": "1",
						"foldChange": "0.9216"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".483916298834392",
						"qValue": "1",
						"foldChange": "0.8837"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".180191377055435",
						"qValue": "1",
						"foldChange": "0.6906"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".478354463544682",
						"qValue": ".985830071188835",
						"foldChange": "0.7814"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".636772641032902",
						"qValue": ".301632829345998",
						"foldChange": "1.1006"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".283565896257653",
						"qValue": ".13435297241875",
						"foldChange": "1.2627"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008204961641239",
						"qValue": ".004651512063501",
						"foldChange": "1.8534"
					}
				]
			},
			{
				"metaboliteId": "0078db0d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6de-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyl-2-aminobutyrate",
				"plotName": null,
				"compoundId": "000090e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FUZOZPRKGAXGOB-UHFFFAOYAE",
				"kegg": null,
				"mass": 232.105922,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 566,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".038259464501735",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".660184848054398",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".064415859756623",
						"qValue": ".730905288705153",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".123108025076134",
						"qValue": "1",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".585484429576536",
						"qValue": "1",
						"foldChange": "1.6901"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".039329466390354",
						"qValue": "1",
						"foldChange": "3.9491"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".556179710541943",
						"qValue": "1",
						"foldChange": "3.2878"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".105141280501854",
						"qValue": ".697458782960496",
						"foldChange": "0.8993"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".029460441892302",
						"qValue": ".587380998803463",
						"foldChange": "1.1273"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".841185183643779",
						"qValue": "1",
						"foldChange": "1.1472"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".654718045623343",
						"qValue": "1",
						"foldChange": "1.0777"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".804762397723536",
						"qValue": "1",
						"foldChange": "1.7748"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".383603181897011",
						"qValue": "1",
						"foldChange": "0.8124"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".396904981766557",
						"qValue": "1",
						"foldChange": "1.0721"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".089455360423745",
						"qValue": "1",
						"foldChange": "1.3196"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".264047609290587",
						"qValue": "1",
						"foldChange": "1.4253"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".043489114815833",
						"qValue": "1",
						"foldChange": "1.7617"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".312981776841172",
						"qValue": "1",
						"foldChange": "1.2360"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".005531742848217",
						"qValue": "1",
						"foldChange": "0.2982"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".952762746909976",
						"qValue": "1",
						"foldChange": "0.8773"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".008395548331445",
						"qValue": ".893076453757469",
						"foldChange": "2.9421"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00032868289772",
						"qValue": ".001182437854903",
						"foldChange": "5.1704"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".121761241260031",
						"qValue": ".071030729713758",
						"foldChange": "1.8115"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000245038481384",
						"qValue": ".000382488228024",
						"foldChange": "5.6397"
					}
				]
			},
			{
				"metaboliteId": "0078db0e-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebd1-0000-0000-0000-000000000000",
				"chemicalName": "argininate*",
				"plotName": null,
				"compoundId": "0000e075-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03148",
				"inChiKey": "BMFMQGXDDJALKQ-BYPYZUCNBB",
				"kegg": null,
				"mass": 175.095691,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 450,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".928243714597953",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".564403044925862",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".125959931699033",
						"qValue": ".8440307234321",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".293538725273932",
						"qValue": "1",
						"foldChange": "1.2536"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".167915132320218",
						"qValue": "1",
						"foldChange": "1.2746"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".736783314186393",
						"qValue": "1",
						"foldChange": "1.1073"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".192960538710688",
						"qValue": ".797133099236872",
						"foldChange": "0.9273"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".067024027342236",
						"qValue": ".687896186587066",
						"foldChange": "0.8386"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".582426637391846",
						"qValue": ".957839854315834",
						"foldChange": "0.9748"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".127510789910677",
						"qValue": "1",
						"foldChange": "0.8511"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".833414698626816",
						"qValue": "1",
						"foldChange": "1.0561"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".186760813938082",
						"qValue": ".994724181158891",
						"foldChange": "1.1537"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".301027943175141",
						"qValue": "1",
						"foldChange": "1.1240"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".305880764059914",
						"qValue": "1",
						"foldChange": "1.1280"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".962326805949174",
						"qValue": "1",
						"foldChange": "1.0035"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".577326512285075",
						"qValue": "1",
						"foldChange": "0.9172"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".461899630445921",
						"qValue": "1",
						"foldChange": "0.8103"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".829661909430397",
						"qValue": "1",
						"foldChange": "0.8835"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".251770430500081",
						"qValue": "1",
						"foldChange": "0.7613"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".975301973753619",
						"qValue": "1",
						"foldChange": "0.8912"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".280350002982604",
						"qValue": ".985830071188835",
						"foldChange": "1.1705"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".877200383632756",
						"qValue": ".366245640678326",
						"foldChange": "1.1596"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".496200665292928",
						"qValue": ".201110159367765",
						"foldChange": "1.0666"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".45562569893687",
						"qValue": ".103029031372914",
						"foldChange": "1.2125"
					}
				]
			},
			{
				"metaboliteId": "0078db0f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f99a-0000-0000-0000-000000000000",
				"chemicalName": "lignoceroyl sphingomyelin (d18:1/24:0)",
				"plotName": null,
				"compoundId": "0000dff2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QEDPUVGSSDPBMD-XTAIVQBEBF",
				"kegg": null,
				"mass": 814.692775,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2796,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".012539698419022",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".74788942916882",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".649945666641268",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".797727869514673",
						"qValue": "1",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".627857369871196",
						"qValue": "1",
						"foldChange": "1.0727"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".45954843177966",
						"qValue": "1",
						"foldChange": "1.1163"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".334645950179647",
						"qValue": ".951252030575746",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".964456011586751",
						"qValue": "1",
						"foldChange": "1.0376"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".357126203222289",
						"qValue": ".957839854315834",
						"foldChange": "1.1099"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".890423711805693",
						"qValue": "1",
						"foldChange": "0.9903"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".286726244688775",
						"qValue": "1",
						"foldChange": "0.9274"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".35252901033523",
						"qValue": "1",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".185511155830631",
						"qValue": "1",
						"foldChange": "1.1743"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".003575476021184",
						"qValue": "1",
						"foldChange": "1.4902"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".069562942905328",
						"qValue": "1",
						"foldChange": "1.2690"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".359912256287273",
						"qValue": "1",
						"foldChange": "1.1439"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".002984356377645",
						"qValue": ".851813617313741",
						"foldChange": "1.5153"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".024319781623048",
						"qValue": ".822195137696456",
						"foldChange": "1.3247"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".317465396474641",
						"qValue": "1",
						"foldChange": "1.1592"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".040143188828936",
						"qValue": "1",
						"foldChange": "1.2913"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".240353148951338",
						"qValue": ".985830071188835",
						"foldChange": "1.1139"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".220546179054235",
						"qValue": ".143177969939591",
						"foldChange": "1.2444"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".250978062985038",
						"qValue": ".12299532458031",
						"foldChange": "1.1715"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".529516433558467",
						"qValue": ".115670505715581",
						"foldChange": "1.0871"
					}
				]
			},
			{
				"metaboliteId": "0078db10-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba5-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-oleoyl-glycerol (16:0/18:1) [2]*",
				"plotName": null,
				"compoundId": "0000d69e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07102",
				"inChiKey": null,
				"kegg": "C13861",
				"mass": 594.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2606,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".919233763210292",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".271107552084776",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".010326466203541",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".518069311991089",
						"qValue": "1",
						"foldChange": "1.0994"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".379733518804611",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".130690298253022",
						"qValue": "1",
						"foldChange": "0.8788"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000863141423754",
						"qValue": ".106267508679706",
						"foldChange": "1.5030"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".027803674879446",
						"qValue": ".678234398444917",
						"foldChange": "1.2978"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".204874338267279",
						"qValue": ".957839854315834",
						"foldChange": "0.9280"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".249788192116192",
						"qValue": "1",
						"foldChange": "0.9188"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".550785346629535",
						"qValue": "1",
						"foldChange": "1.0943"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".083790798683914",
						"qValue": ".783582084395718",
						"foldChange": "1.1648"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".476605548101778",
						"qValue": "1",
						"foldChange": "0.9003"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".741768079520009",
						"qValue": "1",
						"foldChange": "1.0203"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".723071824507705",
						"qValue": "1",
						"foldChange": "1.1332"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".838786255112595",
						"qValue": "1",
						"foldChange": "0.9509"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".354992729049811",
						"qValue": "1",
						"foldChange": "0.6618"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".254906452411323",
						"qValue": "1",
						"foldChange": "0.6959"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".755988792967021",
						"qValue": "1",
						"foldChange": "0.9607"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".875545285261149",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".888049384983861",
						"qValue": ".997573179629986",
						"foldChange": "1.0077"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".931085702391384",
						"qValue": ".379647432350138",
						"foldChange": "1.2521"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".083839019550855",
						"qValue": ".053814760149294",
						"foldChange": "1.5002"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".408389890237925",
						"qValue": ".094516228523022",
						"foldChange": "1.5773"
					}
				]
			},
			{
				"metaboliteId": "0078db11-0000-0000-0000-000000000000",
				"chemicalId": "05f61e08-0000-0000-0000-000000000000",
				"chemicalName": "N-behenoyl-sphingadienine (d18:2/22:0)*",
				"plotName": null,
				"compoundId": "0000e01c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 619.590345,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2722,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".461932882986154",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".874601329294424",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".310400262018892",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".125446747026261",
						"qValue": "1",
						"foldChange": "1.1633"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".406384543617288",
						"qValue": "1",
						"foldChange": "1.0798"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".473879909122603",
						"qValue": "1",
						"foldChange": "0.9747"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".655217120701909",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".441282509577498",
						"qValue": ".900630141528144",
						"foldChange": "0.9704"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".744985093516206",
						"qValue": ".979879929802615",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".156112985820987",
						"qValue": "1",
						"foldChange": "0.8958"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".350304155395822",
						"qValue": "1",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".620985310704272",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".856245575636805",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".255962900617935",
						"qValue": "1",
						"foldChange": "1.2554"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".182866924928353",
						"qValue": "1",
						"foldChange": "1.2641"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".303366079362655",
						"qValue": "1",
						"foldChange": "0.8586"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".910811025107477",
						"qValue": "1",
						"foldChange": "1.0540"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".376030868755487",
						"qValue": "1",
						"foldChange": "1.2276"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".392277066622019",
						"qValue": "1",
						"foldChange": "0.8760"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".690993436194608",
						"qValue": "1",
						"foldChange": "1.1201"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".220173492705909",
						"qValue": ".985830071188835",
						"foldChange": "1.2786"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".365142131946272",
						"qValue": ".200489426376528",
						"foldChange": "1.3084"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".734859507232011",
						"qValue": ".273842367601437",
						"foldChange": "1.0971"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004203140946303",
						"qValue": ".002912775241695",
						"foldChange": "1.6038"
					}
				]
			},
			{
				"metaboliteId": "0078db12-0000-0000-0000-000000000000",
				"chemicalId": "05f5f10e-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl catechol sulfate (2)",
				"plotName": null,
				"compoundId": "0000b454-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 204.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4092,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".809225387022183",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".483566196023439",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".57637002504668",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".68371703025512",
						"qValue": "1",
						"foldChange": "2.5571"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".565900388655495",
						"qValue": "1",
						"foldChange": "2.9493"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".867119171199205",
						"qValue": "1",
						"foldChange": "1.1567"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".293062186016538",
						"qValue": ".911265664999995",
						"foldChange": "0.9063"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".334532344024683",
						"qValue": ".837314778720603",
						"foldChange": "3.8655"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".047089772716132",
						"qValue": ".679210111549633",
						"foldChange": "3.9553"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".687209990583578",
						"qValue": "1",
						"foldChange": "1.0175"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".694638639835254",
						"qValue": "1",
						"foldChange": "1.1418"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".991940376042105",
						"qValue": "1",
						"foldChange": "1.0191"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".838272048379786",
						"qValue": "1",
						"foldChange": "1.3804"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".802494337235606",
						"qValue": "1",
						"foldChange": "0.7674"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".650103390990986",
						"qValue": "1",
						"foldChange": "0.5560"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".178715870505082",
						"qValue": "1",
						"foldChange": "0.3197"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".693590006698961",
						"qValue": "1",
						"foldChange": "0.8573"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".364985626730887",
						"qValue": "1",
						"foldChange": "2.6818"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".938376044982969",
						"qValue": "1",
						"foldChange": "1.0524"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".606893449549134",
						"qValue": "1",
						"foldChange": "0.9238"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".547437577090974",
						"qValue": ".985830071188835",
						"foldChange": "0.8778"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".383220939810422",
						"qValue": ".208685604074736",
						"foldChange": "3.3746"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".604327279133283",
						"qValue": ".237010564156036",
						"foldChange": "1.0622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".22627592876971",
						"qValue": ".060339435568167",
						"foldChange": "3.1439"
					}
				]
			},
			{
				"metaboliteId": "0078db13-0000-0000-0000-000000000000",
				"chemicalId": "05f5e165-0000-0000-0000-000000000000",
				"chemicalName": "pimelate (C7-DC)",
				"plotName": null,
				"compoundId": "00003d58-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00857",
				"inChiKey": "WLJVNTCWHIRURA-UHFFFAOYAR",
				"kegg": "C02656",
				"mass": 160.07356,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1497,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".681829329417643",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".573575407009865",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".923103906568501",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".392919343326113",
						"qValue": "1",
						"foldChange": "1.8067"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".626655319281456",
						"qValue": "1",
						"foldChange": "1.2009"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".7111519614925",
						"qValue": "1",
						"foldChange": "1.1000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".915724716955669",
						"qValue": "1",
						"foldChange": "1.8441"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".577169415907357",
						"qValue": ".928868848501546",
						"foldChange": "1.7635"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".507503245853622",
						"qValue": ".957839854315834",
						"foldChange": "2.3934"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".946836644324283",
						"qValue": "1",
						"foldChange": "1.0996"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".443503420826245",
						"qValue": "1",
						"foldChange": "1.9380"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".483819832279579",
						"qValue": "1",
						"foldChange": "1.5794"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".595908332838423",
						"qValue": "1",
						"foldChange": "1.2803"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".835882093099203",
						"qValue": "1",
						"foldChange": "0.7751"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".468428922450195",
						"qValue": "1",
						"foldChange": "0.6054"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".716344349268938",
						"qValue": "1",
						"foldChange": "0.8634"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".368580486053838",
						"qValue": "1",
						"foldChange": "0.5413"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".56798817032488",
						"qValue": "1",
						"foldChange": "0.6270"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".568640995580603",
						"qValue": "1",
						"foldChange": "1.2388"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".934200804987118",
						"qValue": "1",
						"foldChange": "0.8334"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".639864268359899",
						"qValue": ".985830071188835",
						"foldChange": "0.6727"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000032250028277",
						"qValue": ".000282285966353",
						"foldChange": "0.2627"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00007927332237",
						"qValue": ".000526311519666",
						"foldChange": "0.2467"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000095399554106",
						"qValue": ".000204229996389",
						"foldChange": "0.2559"
					}
				]
			},
			{
				"metaboliteId": "0078db14-0000-0000-0000-000000000000",
				"chemicalId": "05f5e413-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylaspartate (NAA)",
				"plotName": null,
				"compoundId": "000056a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00812",
				"inChiKey": "OTCCIMWXFLJLIA-BYPYZUCNBM",
				"kegg": "C01042",
				"mass": 175.048072,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 36,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".409461101918725",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".603824313804065",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".652604929174796",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".156166871378589",
						"qValue": "1",
						"foldChange": "0.8231"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".697486938290985",
						"qValue": "1",
						"foldChange": "0.9197"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".299147018960531",
						"qValue": "1",
						"foldChange": "2.0467"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".807773491650884",
						"qValue": "1",
						"foldChange": "1.7858"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".453802375912515",
						"qValue": ".902562957068497",
						"foldChange": "1.2113"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".322385791789263",
						"qValue": ".957839854315834",
						"foldChange": "1.6995"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".789315177809894",
						"qValue": "1",
						"foldChange": "1.0042"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".68053931406302",
						"qValue": "1",
						"foldChange": "1.2035"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".884886109753079",
						"qValue": "1",
						"foldChange": "1.2133"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".704590846720279",
						"qValue": "1",
						"foldChange": "0.9582"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".55873810339262",
						"qValue": "1",
						"foldChange": "1.1076"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".335308181077585",
						"qValue": "1",
						"foldChange": "1.1559"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".313885259158075",
						"qValue": "1",
						"foldChange": "1.3339"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".138827682016049",
						"qValue": "1",
						"foldChange": "1.4757"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".582216204511574",
						"qValue": "1",
						"foldChange": "1.1063"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".516489060653916",
						"qValue": "1",
						"foldChange": "0.9474"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".584458760894672",
						"qValue": "1",
						"foldChange": "1.2940"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".236668532130079",
						"qValue": ".985830071188835",
						"foldChange": "1.3659"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000332134840557",
						"qValue": ".001182437854903",
						"foldChange": "9.8525"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001997612561204",
						"qValue": ".003327413202008",
						"foldChange": "6.3941"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000165363106229",
						"qValue": ".000298723659296",
						"foldChange": "6.7317"
					}
				]
			},
			{
				"metaboliteId": "0078db15-0000-0000-0000-000000000000",
				"chemicalId": "000003a7-0000-0000-0000-000000000000",
				"chemicalName": "sucrose",
				"plotName": null,
				"compoundId": "000005ef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00258",
				"inChiKey": "CZMRCDWAGMRECN-UGDNZRGBBE",
				"kegg": "C00089",
				"mass": 342.116215,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".489813918407396",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".90504990622121",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".396599985852563",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".680586741312705",
						"qValue": "1",
						"foldChange": "1.5432"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".731051461786723",
						"qValue": "1",
						"foldChange": "2.0553"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".451020391861017",
						"qValue": "1",
						"foldChange": "1.7616"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".508680392102655",
						"qValue": "1",
						"foldChange": "3.8795"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".154425295135456",
						"qValue": ".784201030789377",
						"foldChange": "8.5341"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".438623226227528",
						"qValue": ".957839854315834",
						"foldChange": "3.8310"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".574560496605527",
						"qValue": "1",
						"foldChange": "1.3615"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".207486403138305",
						"qValue": "1",
						"foldChange": "0.7609"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".479588890898973",
						"qValue": "1",
						"foldChange": "0.8841"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".841849611614897",
						"qValue": "1",
						"foldChange": "1.8343"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".440188876841919",
						"qValue": "1",
						"foldChange": "1.2074"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".549591259943153",
						"qValue": "1",
						"foldChange": "0.6583"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".690321097666588",
						"qValue": "1",
						"foldChange": "1.5858"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".917386911789211",
						"qValue": "1",
						"foldChange": "1.1352"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".62274129144425",
						"qValue": "1",
						"foldChange": "0.7158"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".079781212654031",
						"qValue": "1",
						"foldChange": "2.8594"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".920251315958924",
						"qValue": "1",
						"foldChange": "0.7481"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".072287469839069",
						"qValue": ".985830071188835",
						"foldChange": "0.2616"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".027253863600792",
						"qValue": ".032108405861532",
						"foldChange": "0.5103"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".884207243760674",
						"qValue": ".309160458567174",
						"foldChange": "1.3943"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".262831707272128",
						"qValue": ".068156467360188",
						"foldChange": "0.6413"
					}
				]
			},
			{
				"metaboliteId": "0078db16-0000-0000-0000-000000000000",
				"chemicalId": "05f5e10f-0000-0000-0000-000000000000",
				"chemicalName": "xanthurenate",
				"plotName": null,
				"compoundId": "00003d3f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00881",
				"inChiKey": "FBZONXHGGPHHIY-UHFFFAOYAE",
				"kegg": "C02470",
				"mass": 205.037508,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 250,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".779705393043902",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".559037192257501",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".901961140656185",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".806007133237633",
						"qValue": "1",
						"foldChange": "1.1456"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".736607664782502",
						"qValue": "1",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".927582401533764",
						"qValue": "1",
						"foldChange": "1.3226"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".502495541895886",
						"qValue": "1",
						"foldChange": "1.2968"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".585123189394759",
						"qValue": ".928868848501546",
						"foldChange": "1.1097"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".226443446775832",
						"qValue": ".957839854315834",
						"foldChange": "0.8945"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".621584908906965",
						"qValue": "1",
						"foldChange": "0.9876"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".433930503805959",
						"qValue": "1",
						"foldChange": "0.9739"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".771326425646741",
						"qValue": "1",
						"foldChange": "1.0576"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".668750683845702",
						"qValue": "1",
						"foldChange": "1.0618"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".653487497497958",
						"qValue": "1",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".963692678037315",
						"qValue": "1",
						"foldChange": "0.9737"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".302565359485557",
						"qValue": "1",
						"foldChange": "1.1210"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".788872079587319",
						"qValue": "1",
						"foldChange": "0.8788"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".466602024062463",
						"qValue": "1",
						"foldChange": "0.7840"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".762201291195805",
						"qValue": "1",
						"foldChange": "0.8109"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".89964565791579",
						"qValue": "1",
						"foldChange": "0.8343"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".869705565766514",
						"qValue": ".997573179629986",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".015109241265408",
						"qValue": ".020171482312512",
						"foldChange": "3.4746"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00000676764679",
						"qValue": ".000146117023651",
						"foldChange": "3.9842"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".038406553277439",
						"qValue": ".015322205879831",
						"foldChange": "3.5180"
					}
				]
			},
			{
				"metaboliteId": "0078db17-0000-0000-0000-000000000000",
				"chemicalId": "000000b4-0000-0000-0000-000000000000",
				"chemicalName": "linoleate (18:2n6)",
				"plotName": null,
				"compoundId": "00000451-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00673",
				"inChiKey": "OYHQOLUKZRVURQ-HZJYTTRNBX",
				"kegg": "C01595",
				"mass": 280.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1394,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".764294870478205",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".118902850247713",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".375062085954692",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".719637723439028",
						"qValue": "1",
						"foldChange": "1.0908"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".691924058628407",
						"qValue": "1",
						"foldChange": "1.0683"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".970177220639064",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".155730488923021",
						"qValue": ".742487134132407",
						"foldChange": "1.2666"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".175894527296783",
						"qValue": ".796203418774266",
						"foldChange": "0.9825"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".007034969165515",
						"qValue": ".249448281660543",
						"foldChange": "0.7769"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".921534864344073",
						"qValue": "1",
						"foldChange": "1.0605"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".384205979816119",
						"qValue": "1",
						"foldChange": "0.9267"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".333356169379207",
						"qValue": "1",
						"foldChange": "0.8937"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".82555189804917",
						"qValue": "1",
						"foldChange": "1.0112"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".569694873290148",
						"qValue": "1",
						"foldChange": "1.1041"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".427079439201434",
						"qValue": "1",
						"foldChange": "1.0919"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".630515476263986",
						"qValue": "1",
						"foldChange": "1.1206"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".688867361866266",
						"qValue": "1",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".955349750889009",
						"qValue": "1",
						"foldChange": "0.9161"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".163950919623743",
						"qValue": "1",
						"foldChange": "0.8792"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".758778928431118",
						"qValue": "1",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".297521357696052",
						"qValue": ".985830071188835",
						"foldChange": "1.0274"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".520670611725786",
						"qValue": ".25845448324499",
						"foldChange": "1.1133"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".923756021410725",
						"qValue": ".319585706104857",
						"foldChange": "1.1569"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".133621015828429",
						"qValue": ".040842284912059",
						"foldChange": "1.2228"
					}
				]
			},
			{
				"metaboliteId": "0078db18-0000-0000-0000-000000000000",
				"chemicalId": "05f5e12b-0000-0000-0000-000000000000",
				"chemicalName": "4-acetamidophenylglucuronide",
				"plotName": null,
				"compoundId": "00003d78-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10316",
				"inChiKey": "IPROLSVTVHAQLE-UHFFFAOYAX",
				"kegg": null,
				"mass": 327.095419,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4775,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".176376648724252",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".877373865646088",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".777542795823999",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".634242290395837",
						"qValue": "1",
						"foldChange": "3.1571"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".851556652243336",
						"qValue": "1",
						"foldChange": "7.4339"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".772553302540901",
						"qValue": "1",
						"foldChange": "7.3948"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".825274569099474",
						"qValue": "1",
						"foldChange": "40.9526"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".255152586839396",
						"qValue": ".825038702902081",
						"foldChange": "0.8113"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".357171028681292",
						"qValue": ".957839854315834",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".594895117140468",
						"qValue": "1",
						"foldChange": "1.0071"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".978988907993786",
						"qValue": "1",
						"foldChange": "1.4511"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".576865192935433",
						"qValue": "1",
						"foldChange": "45.6563"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".183729340423638",
						"qValue": "1",
						"foldChange": "0.0338"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".36231081557422",
						"qValue": "1",
						"foldChange": "0.7498"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".715100306922513",
						"qValue": "1",
						"foldChange": "22.2029"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".092260775902588",
						"qValue": "1",
						"foldChange": "0.0420"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".152937572768178",
						"qValue": "1",
						"foldChange": "0.0162"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".858225809117865",
						"qValue": "1",
						"foldChange": "0.3850"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".047649589373735",
						"qValue": "1",
						"foldChange": "0.0043"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".321654860984045",
						"qValue": "1",
						"foldChange": "0.9812"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".344667294298499",
						"qValue": ".985830071188835",
						"foldChange": "227.9848"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".113622037519365",
						"qValue": ".090213878904339",
						"foldChange": "239.6533"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137916",
						"qValue": ".152009550549949",
						"foldChange": "1.0512"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".026169267592458",
						"qValue": ".011590600812547",
						"foldChange": "244.2566"
					}
				]
			},
			{
				"metaboliteId": "0078db19-0000-0000-0000-000000000000",
				"chemicalId": "05f5e10e-0000-0000-0000-000000000000",
				"chemicalName": "hippurate",
				"plotName": null,
				"compoundId": "00003d89-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00714",
				"inChiKey": "QIAFMBKCNZACKA-UHFFFAOYAD",
				"kegg": "C01586",
				"mass": 179.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4055,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".328091445859636",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".531351720054944",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".038704700269491",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".860698165021359",
						"qValue": "1",
						"foldChange": "1.3412"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".563168469562008",
						"qValue": "1",
						"foldChange": "1.1870"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".686727814991727",
						"qValue": "1",
						"foldChange": "1.0055"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".39010398902937",
						"qValue": ".964405849888462",
						"foldChange": "1.2915"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".001632507551241",
						"qValue": ".173657990763309",
						"foldChange": "2.9106"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".017047799194959",
						"qValue": ".500264728100336",
						"foldChange": "2.1936"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".662660152648588",
						"qValue": "1",
						"foldChange": "0.9315"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".455991379241842",
						"qValue": "1",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".755946744381571",
						"qValue": "1",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".046726671003819",
						"qValue": "1",
						"foldChange": "0.4477"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".83203385312453",
						"qValue": "1",
						"foldChange": "0.9137"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".03317751950861",
						"qValue": "1",
						"foldChange": "2.0407"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".154566416105057",
						"qValue": "1",
						"foldChange": "0.5443"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".961423617592464",
						"qValue": "1",
						"foldChange": "0.8196"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".1533690383089",
						"qValue": "1",
						"foldChange": "1.5058"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".83755090225557",
						"qValue": "1",
						"foldChange": "1.5448"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".927974882805116",
						"qValue": "1",
						"foldChange": "0.7778"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".915549013366087",
						"qValue": ".997573179629986",
						"foldChange": "0.5035"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".079393941250908",
						"qValue": ".069357749065392",
						"foldChange": "2.5700"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".042456969045087",
						"qValue": ".032716398129185",
						"foldChange": "4.5691"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".082455512310732",
						"qValue": ".027864258354397",
						"foldChange": "2.4788"
					}
				]
			},
			{
				"metaboliteId": "0078db1a-0000-0000-0000-000000000000",
				"chemicalId": "0000074d-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyhippurate (salicylurate)",
				"plotName": null,
				"compoundId": "00004769-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00840",
				"inChiKey": "ONJSZLXSECQROL-UHFFFAOYAK",
				"kegg": "C07588",
				"mass": 195.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4059,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".267242595881181",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".928592120555064",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".963874580562544",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".93648283486312",
						"qValue": "1",
						"foldChange": "2.1324"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".895417137159317",
						"qValue": "1",
						"foldChange": "3.9661"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".832802087785989",
						"qValue": "1",
						"foldChange": "2.0332"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".862184059925107",
						"qValue": "1",
						"foldChange": "1.1669"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".678225097084972",
						"qValue": ".94001556615523",
						"foldChange": "1.0766"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".80911674643734",
						"qValue": "1",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".517636826753445",
						"qValue": "1",
						"foldChange": "1.6365"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".58485926443429",
						"qValue": "1",
						"foldChange": "1.4943"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".919348758879689",
						"qValue": "1",
						"foldChange": "1.3580"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".161662317323503",
						"qValue": "1",
						"foldChange": "0.3116"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".203544537932455",
						"qValue": "1",
						"foldChange": "0.0999"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".953979398479321",
						"qValue": "1",
						"foldChange": "0.3205"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".13346399714784",
						"qValue": "1",
						"foldChange": "0.3312"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".303410094837036",
						"qValue": "1",
						"foldChange": "0.3215"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".676829086140437",
						"qValue": "1",
						"foldChange": "0.9706"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".131547027887247",
						"qValue": "1",
						"foldChange": "0.3300"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".323411279286242",
						"qValue": "1",
						"foldChange": "0.0854"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".639818471017681",
						"qValue": ".985830071188835",
						"foldChange": "0.2588"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00554822086929",
						"qValue": ".009876149054731",
						"foldChange": "6.5831"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".023372696551886",
						"qValue": ".020536236329704",
						"foldChange": "31.6837"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001359390784622",
						"qValue": ".001316610272184",
						"foldChange": "73.7495"
					}
				]
			},
			{
				"metaboliteId": "0078db1b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e561-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxate",
				"plotName": null,
				"compoundId": "00007b43-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00017",
				"inChiKey": "HXACOUQIXZGNBF-UHFFFAOYAG",
				"kegg": "C00847",
				"mass": 183.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4053,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444767952449031",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".633589762354089",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".624220579690926",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".502185475377123",
						"qValue": "1",
						"foldChange": "0.9695"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".841976311766862",
						"qValue": "1",
						"foldChange": "1.0841"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".636439696080912",
						"qValue": "1",
						"foldChange": "1.1338"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".72213915109121",
						"qValue": "1",
						"foldChange": "1.1849"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".339752993782608",
						"qValue": ".845408765230994",
						"foldChange": "1.3483"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".547004166981467",
						"qValue": ".957839854315834",
						"foldChange": "1.1574"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".224039706229015",
						"qValue": "1",
						"foldChange": "0.8869"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".233201537537898",
						"qValue": "1",
						"foldChange": "0.8759"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".980652257521784",
						"qValue": "1",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".313942780855734",
						"qValue": "1",
						"foldChange": "0.8830"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".488684488854463",
						"qValue": "1",
						"foldChange": "1.7362"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".097613941128804",
						"qValue": "1",
						"foldChange": "1.9661"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".544364628232907",
						"qValue": "1",
						"foldChange": "0.9416"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".64897082844781",
						"qValue": "1",
						"foldChange": "1.1820"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".295409826969137",
						"qValue": "1",
						"foldChange": "1.2553"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".571021108104024",
						"qValue": "1",
						"foldChange": "0.8306"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".775604238872445",
						"qValue": "1",
						"foldChange": "1.0499"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".402334984408764",
						"qValue": ".985830071188835",
						"foldChange": "1.2640"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".019390666212992",
						"qValue": ".024504073007039",
						"foldChange": "3.6750"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005119916837089",
						"qValue": ".006548947657498",
						"foldChange": "3.1238"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001374229796105",
						"qValue": ".001319147136066",
						"foldChange": "3.6852"
					}
				]
			},
			{
				"metaboliteId": "0078db1c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2ab-0000-0000-0000-000000000000",
				"chemicalName": "N-delta-acetylornithine",
				"plotName": null,
				"compoundId": "0000a8f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "SRXKAYJJGAAOBP-UHFFFAOYAF",
				"kegg": null,
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 436,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".638858202011301",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".027130510997604",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".082286212347797",
						"qValue": ".769244960704375",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".725432034521718",
						"qValue": "1",
						"foldChange": "1.1488"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".091902230628051",
						"qValue": "1",
						"foldChange": "1.2750"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".17812486308208",
						"qValue": "1",
						"foldChange": "1.3314"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".002755872535942",
						"qValue": ".145430199185623",
						"foldChange": "1.5454"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".002698666280917",
						"qValue": ".255173889451107",
						"foldChange": "1.6867"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".994126382676371",
						"qValue": "1",
						"foldChange": "1.0773"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".476265454295185",
						"qValue": "1",
						"foldChange": "1.1132"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".685642137729704",
						"qValue": "1",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".266101636781274",
						"qValue": "1",
						"foldChange": "0.8427"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".823540247905057",
						"qValue": "1",
						"foldChange": "0.8286"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".391637520828877",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".50655892281987",
						"qValue": "1",
						"foldChange": "1.1894"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".142091068638875",
						"qValue": "1",
						"foldChange": "1.2222"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".299094697912663",
						"qValue": "1",
						"foldChange": "1.0680"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".709098837389656",
						"qValue": "1",
						"foldChange": "0.8738"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".403817039028948",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".907185107067349",
						"qValue": "1",
						"foldChange": "0.7012"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".354298307991037",
						"qValue": ".985830071188835",
						"foldChange": "0.6815"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002235249269091",
						"qValue": ".00496691275541",
						"foldChange": "3.5964"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000009536282532",
						"qValue": ".000166197402902",
						"foldChange": "4.6815"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000970726112406",
						"qValue": ".001040462564054",
						"foldChange": "4.4541"
					}
				]
			},
			{
				"metaboliteId": "0078db1d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e399-0000-0000-0000-000000000000",
				"chemicalName": "docosahexaenoate (DHA; 22:6n3)",
				"plotName": null,
				"compoundId": "0000ae83-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02183",
				"inChiKey": "MBMBGCFOFBJSGT-KUBAVDMBBZ",
				"kegg": "C06429",
				"mass": 328.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1390,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".101432770790514",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".163175855395343",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".590232727723391",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".733309956289904",
						"qValue": "1",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".739140930766419",
						"qValue": "1",
						"foldChange": "1.1070"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".99381763265639",
						"qValue": "1",
						"foldChange": "1.1151"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".627622113026084",
						"qValue": "1",
						"foldChange": "1.1124"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".192913780700447",
						"qValue": ".82375925014267",
						"foldChange": "0.9595"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".07675388561225",
						"qValue": ".794743555017874",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".789904797206074",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".113613879655035",
						"qValue": "1",
						"foldChange": "0.8350"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".185383008081589",
						"qValue": ".994724181158891",
						"foldChange": "0.8211"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".094990135692652",
						"qValue": "1",
						"foldChange": "0.7716"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".832503192734103",
						"qValue": "1",
						"foldChange": "0.9190"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".15772129124297",
						"qValue": "1",
						"foldChange": "1.1911"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".108004884644317",
						"qValue": "1",
						"foldChange": "0.6636"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".592246490230077",
						"qValue": "1",
						"foldChange": "0.7387"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".303872814750974",
						"qValue": "1",
						"foldChange": "1.1131"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".013726081399067",
						"qValue": "1",
						"foldChange": "0.7431"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".208552833152375",
						"qValue": "1",
						"foldChange": "0.6366"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".238510025100158",
						"qValue": ".985830071188835",
						"foldChange": "0.8567"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".185746451154116",
						"qValue": ".126300900185341",
						"foldChange": "1.5149"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".169231176810061",
						"qValue": ".091829568368456",
						"foldChange": "2.2545"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000002039957325",
						"qValue": ".000021865067824",
						"foldChange": "3.0880"
					}
				]
			},
			{
				"metaboliteId": "0078db1e-0000-0000-0000-000000000000",
				"chemicalId": "00000163-0000-0000-0000-000000000000",
				"chemicalName": "histidine",
				"plotName": null,
				"compoundId": "0000003b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00177",
				"inChiKey": "HNDVDQJCIGZPNO-UHFFFAOYAG",
				"kegg": "C00135",
				"mass": 155.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 67,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".57835575513562",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".742809984544138",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".590078520791577",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".855212680080168",
						"qValue": "1",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".543993346708474",
						"qValue": "1",
						"foldChange": "1.0472"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".430762240970928",
						"qValue": "1",
						"foldChange": "1.0818"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".675874178659983",
						"qValue": "1",
						"foldChange": "1.0475"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".599372005197719",
						"qValue": ".928868848501546",
						"foldChange": "0.9993"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".347131656460121",
						"qValue": ".957839854315834",
						"foldChange": "0.9602"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".620938254347464",
						"qValue": "1",
						"foldChange": "0.9689"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".193466284324446",
						"qValue": "1",
						"foldChange": "0.9217"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".415626425419593",
						"qValue": "1",
						"foldChange": "0.9018"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".616254580298459",
						"qValue": "1",
						"foldChange": "1.0410"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".164579459565665",
						"qValue": "1",
						"foldChange": "1.1204"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".343898108091356",
						"qValue": "1",
						"foldChange": "1.0763"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".33594904687784",
						"qValue": "1",
						"foldChange": "1.0750"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".256079323664775",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".814788272696972",
						"qValue": "1",
						"foldChange": "0.9967"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".699940916109656",
						"qValue": "1",
						"foldChange": "0.9701"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".909667631039728",
						"qValue": "1",
						"foldChange": "0.9216"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".79697889120439",
						"qValue": ".997573179629986",
						"foldChange": "0.9500"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".012613074526442",
						"qValue": ".018078226379907",
						"foldChange": "1.3559"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000230844290045",
						"qValue": ".000898002474794",
						"foldChange": "1.5533"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001477211306777",
						"qValue": ".00135194064415",
						"foldChange": "1.4219"
					}
				]
			},
			{
				"metaboliteId": "0078db1f-0000-0000-0000-000000000000",
				"chemicalId": "00000217-0000-0000-0000-000000000000",
				"chemicalName": "uridine",
				"plotName": null,
				"compoundId": "0000025e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00296",
				"inChiKey": "DRTQHJPVMGBUCF-XVFCMESIBG",
				"kegg": "C00299",
				"mass": 244.069536,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3837,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".35274010361805",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".36721958128219",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".894401196918812",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".302480627058877",
						"qValue": "1",
						"foldChange": "0.9552"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".538382388822058",
						"qValue": "1",
						"foldChange": "0.9532"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".674457226961468",
						"qValue": "1",
						"foldChange": "1.1450"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".89679758462126",
						"qValue": "1",
						"foldChange": "1.0026"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".249251093487409",
						"qValue": ".825038702902081",
						"foldChange": "0.9485"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".305432994294342",
						"qValue": ".957839854315834",
						"foldChange": "0.9429"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".650666263794907",
						"qValue": "1",
						"foldChange": "0.9713"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".505588822404397",
						"qValue": "1",
						"foldChange": "0.9552"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".830626211269164",
						"qValue": "1",
						"foldChange": "0.9545"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".949933989638349",
						"qValue": "1",
						"foldChange": "0.9843"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".3535097913992",
						"qValue": "1",
						"foldChange": "1.1166"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".312405995608227",
						"qValue": "1",
						"foldChange": "1.1344"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".533128201510412",
						"qValue": "1",
						"foldChange": "1.0903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".188250582926478",
						"qValue": "1",
						"foldChange": "1.1455"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".451679920651026",
						"qValue": "1",
						"foldChange": "1.0506"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".6697048277365",
						"qValue": "1",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".387763278983932",
						"qValue": "1",
						"foldChange": "1.0201"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".196778899780178",
						"qValue": ".985830071188835",
						"foldChange": "1.1059"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011955720029457",
						"qValue": ".017438001784771",
						"foldChange": "1.4566"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".017012506491949",
						"qValue": ".015812922971375",
						"foldChange": "1.4695"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".113188586657473",
						"qValue": ".035893489642467",
						"foldChange": "1.3825"
					}
				]
			},
			{
				"metaboliteId": "0078db20-0000-0000-0000-000000000000",
				"chemicalId": "0000044e-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyltyrosine",
				"plotName": null,
				"compoundId": "00000aae-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11741",
				"inChiKey": "VVLXCWVSSLFQDS-QWRGUYRKBA",
				"kegg": null,
				"mass": 310.116488,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 562,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".734418143215679",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".492576511044538",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".84017735305401",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".561738499707914",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".992520971972798",
						"qValue": "1",
						"foldChange": "1.0215"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".568042835993875",
						"qValue": "1",
						"foldChange": "1.1987"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".566935123208404",
						"qValue": "1",
						"foldChange": "0.9838"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".284778892065073",
						"qValue": ".825038702902081",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".615749314131875",
						"qValue": ".967408831530889",
						"foldChange": "1.0126"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".973029198589792",
						"qValue": "1",
						"foldChange": "1.0001"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".329759411010293",
						"qValue": "1",
						"foldChange": "0.9220"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".346564303538551",
						"qValue": "1",
						"foldChange": "0.8559"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".508437972069041",
						"qValue": "1",
						"foldChange": "1.0210"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".454956117108464",
						"qValue": "1",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".899973352149292",
						"qValue": "1",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".492428594844143",
						"qValue": "1",
						"foldChange": "1.0851"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".273859935036501",
						"qValue": "1",
						"foldChange": "1.1212"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".644580517602397",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".977877107845006",
						"qValue": "1",
						"foldChange": "0.9095"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".932373975238211",
						"qValue": "1",
						"foldChange": "0.8633"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".909408475395952",
						"qValue": ".997573179629986",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".049755787340213",
						"qValue": ".049619497031124",
						"foldChange": "1.8551"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000016444626397",
						"qValue": ".000241343449888",
						"foldChange": "2.2701"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".029751609093341",
						"qValue": ".012755579596085",
						"foldChange": "2.0325"
					}
				]
			},
			{
				"metaboliteId": "0078db21-0000-0000-0000-000000000000",
				"chemicalId": "05f5e405-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyoctanoate",
				"plotName": null,
				"compoundId": "000055f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01954",
				"inChiKey": "NDPLAKGOSZHTPH-UHFFFAOYAI",
				"kegg": null,
				"mass": 160.109944,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".513238299451702",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".072504108615434",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".293892908509421",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".176744177552402",
						"qValue": "1",
						"foldChange": "0.9520"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".381744915319983",
						"qValue": "1",
						"foldChange": "0.9466"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".627613686490483",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".196822381919144",
						"qValue": ".797536225282113",
						"foldChange": "0.8972"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".042569694591075",
						"qValue": ".678234398444917",
						"foldChange": "0.8327"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".442430696260236",
						"qValue": ".957839854315834",
						"foldChange": "1.0033"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".330869100082611",
						"qValue": "1",
						"foldChange": "1.1530"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".262698599346435",
						"qValue": "1",
						"foldChange": "0.9021"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".039588167990998",
						"qValue": ".683421910757508",
						"foldChange": "0.7994"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".54380293935077",
						"qValue": "1",
						"foldChange": "0.8309"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".891405821467659",
						"qValue": "1",
						"foldChange": "0.9403"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".653381597163508",
						"qValue": "1",
						"foldChange": "1.1316"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".590586447874635",
						"qValue": "1",
						"foldChange": "0.9888"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".279465461917372",
						"qValue": "1",
						"foldChange": "1.2121"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".107588908536931",
						"qValue": ".897629031028707",
						"foldChange": "1.2259"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".23606540701508",
						"qValue": "1",
						"foldChange": "0.8230"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".765135765275658",
						"qValue": "1",
						"foldChange": "0.9144"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".396023038220761",
						"qValue": ".985830071188835",
						"foldChange": "1.1111"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".318965208927836",
						"qValue": ".186583709174118",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".19628575957208",
						"qValue": ".102305767322783",
						"foldChange": "0.8533"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".749613342859501",
						"qValue": ".152363812230288",
						"foldChange": "0.9662"
					}
				]
			},
			{
				"metaboliteId": "0078db22-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ff-0000-0000-0000-000000000000",
				"chemicalName": "hyocholate",
				"plotName": null,
				"compoundId": "0000852d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00760",
				"inChiKey": "DKPMWHFRUGMUKF-OAEKOJLIBK",
				"kegg": "C17649",
				"mass": 408.287574,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".649614233666763",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".109485190745557",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".938622980418767",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".102820023558744",
						"qValue": "1",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".368113267588964",
						"qValue": "1",
						"foldChange": "0.9268"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".454252328186443",
						"qValue": "1",
						"foldChange": "1.2726"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".468952714236224",
						"qValue": "1",
						"foldChange": "1.0395"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".8981057144295",
						"qValue": "1",
						"foldChange": "1.0607"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".550508248956019",
						"qValue": ".957839854315834",
						"foldChange": "1.1482"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".229884226427564",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".960698023694922",
						"qValue": "1",
						"foldChange": "1.0323"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".249139653746881",
						"qValue": "1",
						"foldChange": "1.2642"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".685968351006051",
						"qValue": "1",
						"foldChange": "1.2651"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".97496562138335",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".719137141819551",
						"qValue": "1",
						"foldChange": "0.7701"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".303248537782641",
						"qValue": "1",
						"foldChange": "1.2740"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".794250024979839",
						"qValue": "1",
						"foldChange": "1.0218"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".462994447501847",
						"qValue": "1",
						"foldChange": "0.8021"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".361897901072151",
						"qValue": "1",
						"foldChange": "1.2962"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".577606969279642",
						"qValue": "1",
						"foldChange": "1.0170"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".752112318704105",
						"qValue": ".997573179629986",
						"foldChange": "0.7846"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".01206921360091",
						"qValue": ".017526329205003",
						"foldChange": "0.5934"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".035094058674305",
						"qValue": ".028201306539165",
						"foldChange": "0.4944"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006808749494683",
						"qValue": ".00406945341931",
						"foldChange": "0.5805"
					}
				]
			},
			{
				"metaboliteId": "0078db23-0000-0000-0000-000000000000",
				"chemicalId": "000005cf-0000-0000-0000-000000000000",
				"chemicalName": "ibuprofen",
				"plotName": null,
				"compoundId": "00004587-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01925",
				"inChiKey": "HEFNNWSXXWATRW-UHFFFAOYAB",
				"kegg": "D00126",
				"mass": 206.13068,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4791,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".643140962975227",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".601499155405106",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".789125797373723",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".954668660810462",
						"qValue": "1",
						"foldChange": "1.5134"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".608760422495495",
						"qValue": "1",
						"foldChange": "2.3724"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".64896032946532",
						"qValue": "1",
						"foldChange": "1.3326"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".643063861013899",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".665580282609447",
						"qValue": ".93517659928016",
						"foldChange": "4.1927"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".97503331894492",
						"qValue": "1",
						"foldChange": "1.8392"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".211613618706055",
						"qValue": "1",
						"foldChange": "1.0673"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".237421975501679",
						"qValue": "1",
						"foldChange": "1.1053"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".944769211830323",
						"qValue": "1",
						"foldChange": "1.0393"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".590629563567049",
						"qValue": "1",
						"foldChange": "0.9722"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".636651434022318",
						"qValue": "1",
						"foldChange": "53.9610"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".317878959328903",
						"qValue": "1",
						"foldChange": "55.5034"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".452387094849003",
						"qValue": "1",
						"foldChange": "0.2900"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".921600315049946",
						"qValue": "1",
						"foldChange": "2.1128"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".529739468217973",
						"qValue": "1",
						"foldChange": "7.2863"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".350743353652674",
						"qValue": "1",
						"foldChange": "0.7048"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".798579604813207",
						"qValue": "1",
						"foldChange": "1.3421"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".517869161207017",
						"qValue": ".985830071188835",
						"foldChange": "1.9041"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".517102149125876",
						"qValue": ".257455121706476",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".150108763182397",
						"qValue": ".083882353523984",
						"foldChange": "11.1457"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".315343808869051",
						"qValue": ".078604172485184",
						"foldChange": "0.2980"
					}
				]
			},
			{
				"metaboliteId": "0078db24-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7e5-0000-0000-0000-000000000000",
				"chemicalName": "3-methyladipate",
				"plotName": null,
				"compoundId": "00008f8d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00555",
				"inChiKey": "SYEOWUNSTUDKGM-UHFFFAOYAW",
				"kegg": null,
				"mass": 160.073559,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1493,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".708423353861291",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".270852934119614",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".240619282345542",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".451095002705913",
						"qValue": "1",
						"foldChange": "1.7687"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".877850309741949",
						"qValue": "1",
						"foldChange": "1.5825"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".547847632947379",
						"qValue": "1",
						"foldChange": "1.0050"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".543137731168795",
						"qValue": "1",
						"foldChange": "1.7648"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".392882081288358",
						"qValue": ".880987329915339",
						"foldChange": "1.5897"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".803862848385366",
						"qValue": "1",
						"foldChange": "1.2685"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".783513972288678",
						"qValue": "1",
						"foldChange": "1.1704"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".045535622982978",
						"qValue": "1",
						"foldChange": "1.1098"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".024094276990058",
						"qValue": ".614826369149758",
						"foldChange": "0.9521"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".715123734532887",
						"qValue": "1",
						"foldChange": "0.8105"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".99903730430105",
						"qValue": "1",
						"foldChange": "0.8253"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".722748926883717",
						"qValue": "1",
						"foldChange": "1.0183"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".618145847831997",
						"qValue": "1",
						"foldChange": "0.8797"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".742081162524374",
						"qValue": "1",
						"foldChange": "0.9081"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".883822723575223",
						"qValue": "1",
						"foldChange": "1.0324"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".895356390185016",
						"qValue": "1",
						"foldChange": "1.2440"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".103464234571247",
						"qValue": "1",
						"foldChange": "0.5453"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".073649640654936",
						"qValue": ".985830071188835",
						"foldChange": "0.4384"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".715011891757594",
						"qValue": ".323863496327166",
						"foldChange": "1.3544"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004461410869677",
						"qValue": ".005952385377051",
						"foldChange": "3.8264"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".032751634933934",
						"qValue": ".013784487606295",
						"foldChange": "2.0201"
					}
				]
			},
			{
				"metaboliteId": "0078db25-0000-0000-0000-000000000000",
				"chemicalId": "000001bd-0000-0000-0000-000000000000",
				"chemicalName": "orotate",
				"plotName": null,
				"compoundId": "000005e1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00226",
				"inChiKey": "PXQPEWDEAKTCGB-UHFFFAOYAD",
				"kegg": "C00295",
				"mass": 156.017108,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3825,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".476224706872733",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".299532374269019",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".475092381950838",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".060793383511863",
						"qValue": "1",
						"foldChange": "0.8924"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".072006680928704",
						"qValue": "1",
						"foldChange": "0.8628"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".936192744915507",
						"qValue": "1",
						"foldChange": "1.0679"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".946915122985241",
						"qValue": "1",
						"foldChange": "1.0208"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".3070913459091",
						"qValue": ".825038702902081",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".339183889343302",
						"qValue": ".957839854315834",
						"foldChange": "0.9524"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".867621934674167",
						"qValue": "1",
						"foldChange": "1.0058"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".787823639117088",
						"qValue": "1",
						"foldChange": "1.0499"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".663192175085023",
						"qValue": "1",
						"foldChange": "0.9995"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".214456146912344",
						"qValue": "1",
						"foldChange": "0.5220"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".110941640865542",
						"qValue": "1",
						"foldChange": "0.4793"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".653588340707747",
						"qValue": "1",
						"foldChange": "0.9181"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".663044820392676",
						"qValue": "1",
						"foldChange": "0.7718"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".371660283475905",
						"qValue": "1",
						"foldChange": "0.6888"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".618881317042642",
						"qValue": "1",
						"foldChange": "0.8925"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".383524136913004",
						"qValue": "1",
						"foldChange": "0.6317"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".462154516129323",
						"qValue": "1",
						"foldChange": "0.6120"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".926086177497511",
						"qValue": ".997573179629986",
						"foldChange": "0.9687"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000032370324982",
						"qValue": ".000282285966353",
						"foldChange": "2.2206"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000001963451421",
						"qValue": ".000091250240186",
						"foldChange": "2.1733"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006896818021951",
						"qValue": ".00410682321616",
						"foldChange": "2.9172"
					}
				]
			},
			{
				"metaboliteId": "0078db26-0000-0000-0000-000000000000",
				"chemicalId": "0000033a-0000-0000-0000-000000000000",
				"chemicalName": "xylose",
				"plotName": null,
				"compoundId": "00003cdd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00098",
				"inChiKey": "PYMYPHUHKUWMLA-WISUUJSJBP",
				"kegg": "C00181",
				"mass": 150.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1117,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".684674939715595",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".597235102412799",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".113359017429696",
						"qValue": ".828639845653926",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".144821061918939",
						"qValue": "1",
						"foldChange": "1.2145"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".982820762504785",
						"qValue": "1",
						"foldChange": "2.3539"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".139171120683547",
						"qValue": "1",
						"foldChange": "2.8054"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".169047543473862",
						"qValue": ".765209890937533",
						"foldChange": "2.1077"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".416872283481138",
						"qValue": ".88884028156896",
						"foldChange": "1.8901"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".56638806258876",
						"qValue": ".957839854315834",
						"foldChange": "1.3093"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".137120924436958",
						"qValue": "1",
						"foldChange": "0.8023"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".083649878395495",
						"qValue": "1",
						"foldChange": "0.7027"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".800010012791122",
						"qValue": "1",
						"foldChange": "1.2222"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".092254403371317",
						"qValue": "1",
						"foldChange": "0.4866"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".763133052646165",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".053569324277707",
						"qValue": "1",
						"foldChange": "1.8858"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".317912549841589",
						"qValue": "1",
						"foldChange": "1.7451"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".84860073537342",
						"qValue": "1",
						"foldChange": "1.0126"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".439132774568601",
						"qValue": "1",
						"foldChange": "0.5802"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".331941446351929",
						"qValue": "1",
						"foldChange": "0.5333"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".157237183818702",
						"qValue": "1",
						"foldChange": "0.3797"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".605914903579373",
						"qValue": ".985830071188835",
						"foldChange": "0.7121"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".757381203514483",
						"qValue": ".334795447198846",
						"foldChange": "1.2464"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".103407615426424",
						"qValue": ".062684446861085",
						"foldChange": "2.1871"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".077354235363718",
						"qValue": ".026516290818571",
						"foldChange": "3.8986"
					}
				]
			},
			{
				"metaboliteId": "0078db27-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4fe-0000-0000-0000-000000000000",
				"chemicalName": "threonate",
				"plotName": null,
				"compoundId": "00006c5a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00943",
				"inChiKey": "JPIJQSOTBSSVTP-GBXIJSLDBD",
				"kegg": "C01620",
				"mass": 136.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3961,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".079685508485387",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".938938186083245",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".20128805802246",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".947452938211252",
						"qValue": "1",
						"foldChange": "1.1498"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".302213390972829",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".333633939349421",
						"qValue": "1",
						"foldChange": "0.8324"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".307564819015382",
						"qValue": ".921611482331303",
						"foldChange": "1.0474"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".712373833393403",
						"qValue": ".950986052805043",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".512123081117309",
						"qValue": ".957839854315834",
						"foldChange": "1.9392"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".162530443046571",
						"qValue": "1",
						"foldChange": "2.1401"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".067783022575688",
						"qValue": "1",
						"foldChange": "2.1466"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".654578517587848",
						"qValue": "1",
						"foldChange": "1.1176"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".073389468955503",
						"qValue": "1",
						"foldChange": "0.6314"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".021643511373977",
						"qValue": "1",
						"foldChange": "0.6614"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".513241131309768",
						"qValue": "1",
						"foldChange": "1.0475"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".016110603103132",
						"qValue": "1",
						"foldChange": "0.5295"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".202209424841183",
						"qValue": "1",
						"foldChange": "0.6294"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".280506489254073",
						"qValue": "1",
						"foldChange": "1.1888"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".190623888914025",
						"qValue": "1",
						"foldChange": "0.6844"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".771331171122842",
						"qValue": "1",
						"foldChange": "0.8054"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".328866921104904",
						"qValue": ".985830071188835",
						"foldChange": "1.1768"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".004387367582775",
						"qValue": ".008115173877052",
						"foldChange": "0.4881"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007560123765951",
						"qValue": ".008363039097487",
						"foldChange": "0.4557"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".336071427418908",
						"qValue": ".083126451095649",
						"foldChange": "0.6777"
					}
				]
			},
			{
				"metaboliteId": "0078db28-0000-0000-0000-000000000000",
				"chemicalId": "05f5e348-0000-0000-0000-000000000000",
				"chemicalName": "2-arachidonoylglycerol (20:4)",
				"plotName": null,
				"compoundId": "00004b42-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04666",
				"inChiKey": "RCRCTBLIHCHWDZ-DOFZRALJBN",
				"kegg": "C13856",
				"mass": 378.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2574,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".564296235700012",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".918249723505314",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".80256616090381",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".403524065789395",
						"qValue": "1",
						"foldChange": "3.4906"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".693591004617694",
						"qValue": "1",
						"foldChange": "1.1994"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".657296720571873",
						"qValue": "1",
						"foldChange": "3.1738"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".688080308859037",
						"qValue": "1",
						"foldChange": "1.9258"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".556244112235708",
						"qValue": ".922736334332529",
						"foldChange": "1.5112"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".851139336056788",
						"qValue": "1",
						"foldChange": "1.6073"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".587230065340158",
						"qValue": "1",
						"foldChange": "0.9123"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".396113726009609",
						"qValue": "1",
						"foldChange": "0.8853"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".758117837120656",
						"qValue": "1",
						"foldChange": "1.3652"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".673085004129557",
						"qValue": "1",
						"foldChange": "0.8493"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".791552365043531",
						"qValue": "1",
						"foldChange": "0.6291"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".89011202038823",
						"qValue": "1",
						"foldChange": "0.7408"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".971251795743952",
						"qValue": "1",
						"foldChange": "0.5834"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".388084823707372",
						"qValue": "1",
						"foldChange": "0.2995"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".358323809783762",
						"qValue": "1",
						"foldChange": "0.5134"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".574930086303393",
						"qValue": "1",
						"foldChange": "0.9859"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".441185614390942",
						"qValue": "1",
						"foldChange": "0.4149"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".185167926585631",
						"qValue": ".985830071188835",
						"foldChange": "0.4209"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000127967616716",
						"qValue": ".000580395531387",
						"foldChange": "0.1713"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00758786406759",
						"qValue": ".008363039097487",
						"foldChange": "0.3685"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004665877530667",
						"qValue": ".0030870813026",
						"foldChange": "0.4151"
					}
				]
			},
			{
				"metaboliteId": "0078db29-0000-0000-0000-000000000000",
				"chemicalId": "05f5e59e-0000-0000-0000-000000000000",
				"chemicalName": "docosadienoate (22:2n6)",
				"plotName": null,
				"compoundId": "00007e9f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61714",
				"inChiKey": "HVGRZDASOHMCSK-AVQMFFATBO",
				"kegg": "C16533",
				"mass": 336.302831,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1404,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".954274817660662",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".221644636624337",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".113647084481799",
						"qValue": ".828639845653926",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".854434598504694",
						"qValue": "1",
						"foldChange": "1.0103"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".99382600084994",
						"qValue": "1",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".848371292220631",
						"qValue": "1",
						"foldChange": "1.0884"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".012080770336077",
						"qValue": ".302374575176522",
						"foldChange": "1.5254"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".567029583206085",
						"qValue": ".927965721746881",
						"foldChange": "1.0768"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".002542652428882",
						"qValue": ".150104626324489",
						"foldChange": "0.7078"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".786440776441637",
						"qValue": "1",
						"foldChange": "1.0303"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".657587669533044",
						"qValue": "1",
						"foldChange": "1.0172"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".862936598954743",
						"qValue": "1",
						"foldChange": "1.0312"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".614032554549741",
						"qValue": "1",
						"foldChange": "0.9546"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".734097302995856",
						"qValue": "1",
						"foldChange": "1.0401"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".40417620470978",
						"qValue": "1",
						"foldChange": "1.0896"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".180922784205907",
						"qValue": "1",
						"foldChange": "1.2880"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".786548078511634",
						"qValue": "1",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".305200260938491",
						"qValue": "1",
						"foldChange": "0.7531"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".374383993180239",
						"qValue": "1",
						"foldChange": "0.8917"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".98203113438892",
						"qValue": "1",
						"foldChange": "0.9278"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".376991974707916",
						"qValue": ".985830071188835",
						"foldChange": "1.0405"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".173991338003447",
						"qValue": ".120524565802269",
						"foldChange": "1.4420"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".225039795409325",
						"qValue": ".113269938775178",
						"foldChange": "1.4825"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".090232112408241",
						"qValue": ".030160397010417",
						"foldChange": "1.4352"
					}
				]
			},
			{
				"metaboliteId": "0078db2a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6af-0000-0000-0000-000000000000",
				"chemicalName": "nicotinurate (nicotinoylglycine)",
				"plotName": null,
				"compoundId": "00008931-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03269",
				"inChiKey": "ZBSGKPYXQINNGF-UHFFFAOYAK",
				"kegg": "C05380",
				"mass": 180.053492,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3934,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".382696706677096",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".378404075121554",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".416838497242706",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".046924092090814",
						"qValue": "1",
						"foldChange": "0.9286"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".877390588332835",
						"qValue": "1",
						"foldChange": "1.0090"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".033062144911034",
						"qValue": "1",
						"foldChange": "1.2253"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".144970846451294",
						"qValue": "1",
						"foldChange": "0.8475"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".167644664753454",
						"qValue": "1",
						"foldChange": "0.8475"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".117427498571156",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".1382224207311",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.2253"
					}
				]
			},
			{
				"metaboliteId": "0078db2b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7c6-0000-0000-0000-000000000000",
				"chemicalName": "N6-acetyllysine",
				"plotName": null,
				"compoundId": "00008f90-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00206",
				"inChiKey": "DTERQYGMUDWYAZ-ZETCQYMHBE",
				"kegg": "C02727",
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 100,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".989997689035339",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".483345446492187",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".830055542844705",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".469223058709183",
						"qValue": "1",
						"foldChange": "1.0056"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".787364140358631",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".648817745619759",
						"qValue": "1",
						"foldChange": "1.2150"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".891794280149771",
						"qValue": "1",
						"foldChange": "1.0988"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".500184831347084",
						"qValue": ".902562957068497",
						"foldChange": "1.0594"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".418475353319281",
						"qValue": ".957839854315834",
						"foldChange": "0.9726"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".961357282263117",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".293802247870466",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".272468212387843",
						"qValue": "1",
						"foldChange": "0.8475"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".910910874742248",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".904529480168813",
						"qValue": "1",
						"foldChange": "0.9452"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".817152441089623",
						"qValue": "1",
						"foldChange": "0.9764"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".547236650149984",
						"qValue": "1",
						"foldChange": "1.0926"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".463750220152914",
						"qValue": "1",
						"foldChange": "1.0732"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".866323610875848",
						"qValue": "1",
						"foldChange": "0.9822"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".673005338307133",
						"qValue": "1",
						"foldChange": "0.9494"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".581303095472841",
						"qValue": "1",
						"foldChange": "0.8224"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".875599413329882",
						"qValue": ".997573179629986",
						"foldChange": "0.8662"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001392842174992",
						"qValue": ".003341716944038",
						"foldChange": "2.2994"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000516605981411",
						"qValue": ".001500559785693",
						"foldChange": "2.5145"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000162655982888",
						"qValue": ".000297172334425",
						"foldChange": "2.6818"
					}
				]
			},
			{
				"metaboliteId": "0078db2c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee8e-0000-0000-0000-000000000000",
				"chemicalName": "pregnanediol-3-glucuronide",
				"plotName": null,
				"compoundId": "00009f04-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10318",
				"inChiKey": "ZFFFJLDTCLJDHL-JQYCEVDMBA",
				"kegg": null,
				"mass": 496.303618,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2967,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".167370893194859",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".381867038369494",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".921246589763924",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".493134933565095",
						"qValue": "1",
						"foldChange": "1.3079"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".58389990133898",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".220256724806261",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".758692767168785",
						"qValue": "1",
						"foldChange": "1.1678"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".384673358365939",
						"qValue": ".880576140380748",
						"foldChange": "1.0444"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".572424753948343",
						"qValue": ".957839854315834",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".510134631371594",
						"qValue": "1",
						"foldChange": "1.2532"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".96184902165355",
						"qValue": "1",
						"foldChange": "1.2128"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".541135318618761",
						"qValue": "1",
						"foldChange": "0.8290"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".104862632396827",
						"qValue": "1",
						"foldChange": "0.2435"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".509816396991148",
						"qValue": "1",
						"foldChange": "0.4084"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".355976255704773",
						"qValue": "1",
						"foldChange": "1.6775"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".049670769751272",
						"qValue": "1",
						"foldChange": "0.0795"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".512814666745834",
						"qValue": "1",
						"foldChange": "0.1372"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".20199428691289",
						"qValue": "1",
						"foldChange": "1.7254"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".085393899905636",
						"qValue": "1",
						"foldChange": "0.3434"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655627907432691",
						"qValue": "1",
						"foldChange": "0.5326"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".216438085457242",
						"qValue": ".985830071188835",
						"foldChange": "1.5510"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".21553561698846",
						"qValue": ".141872395061209",
						"foldChange": "2.0509"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".442910345272542",
						"qValue": ".184609825943461",
						"foldChange": "1.5182"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".015723849582596",
						"qValue": ".007778511987178",
						"foldChange": "4.1599"
					}
				]
			},
			{
				"metaboliteId": "0078db2d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f156-0000-0000-0000-000000000000",
				"chemicalName": "3b-hydroxy-5-cholenoic acid",
				"plotName": null,
				"compoundId": "0000a9f3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00308",
				"inChiKey": "HIAJCGFYHIANNA-QIZZZRFXBQ",
				"kegg": null,
				"mass": 374.282095,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3166,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".425224558210402",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".189600822684783",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".76192169632799",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".83978310624518",
						"qValue": "1",
						"foldChange": "1.0974"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".840260456958033",
						"qValue": "1",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".686600740370646",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".342888001794835",
						"qValue": ".951252030575746",
						"foldChange": "1.2638"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".940034089740575",
						"qValue": "1",
						"foldChange": "1.1298"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".381996516097387",
						"qValue": ".957839854315834",
						"foldChange": "0.9424"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".626812457770559",
						"qValue": "1",
						"foldChange": "1.1504"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".173486961028508",
						"qValue": "1",
						"foldChange": "0.8886"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".067450291947539",
						"qValue": ".727024127920748",
						"foldChange": "0.7859"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".477191372283255",
						"qValue": "1",
						"foldChange": "1.5294"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".192121492679983",
						"qValue": "1",
						"foldChange": "1.5366"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".508771838927841",
						"qValue": "1",
						"foldChange": "1.0047"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".301384662510965",
						"qValue": "1",
						"foldChange": "1.4975"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".150623927839483",
						"qValue": "1",
						"foldChange": "1.4800"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".626751963856475",
						"qValue": "1",
						"foldChange": "0.9883"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".402695503270531",
						"qValue": "1",
						"foldChange": "1.4652"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".445787027535241",
						"qValue": "1",
						"foldChange": "1.1835"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".975726692054849",
						"qValue": "1",
						"foldChange": "0.8077"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".078589529401027",
						"qValue": ".068841403997043",
						"foldChange": "1.5906"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".112467617452728",
						"qValue": ".067011100167792",
						"foldChange": "1.8022"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".912037682606096",
						"qValue": ".180139678457214",
						"foldChange": "0.9431"
					}
				]
			},
			{
				"metaboliteId": "0078db2e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c4-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-pregnan-3beta,20alpha-diol disulfate",
				"plotName": null,
				"compoundId": "0000914e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 480.18516,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2964,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".283243402372771",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".286615897669714",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".855536907354643",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".415925013671494",
						"qValue": "1",
						"foldChange": "1.5724"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".669784794849991",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".217471856679445",
						"qValue": "1",
						"foldChange": "1.0334"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".754919363059609",
						"qValue": "1",
						"foldChange": "1.1186"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".148959383642578",
						"qValue": ".782571329595308",
						"foldChange": "0.9486"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".254691874819438",
						"qValue": ".957839854315834",
						"foldChange": "0.9189"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".819328167493438",
						"qValue": "1",
						"foldChange": "1.0863"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".913606005489158",
						"qValue": "1",
						"foldChange": "1.0280"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".736291042723398",
						"qValue": "1",
						"foldChange": "0.9118"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".216927504637269",
						"qValue": "1",
						"foldChange": "0.2562"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".604366745788206",
						"qValue": "1",
						"foldChange": "0.3270"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".498208792154482",
						"qValue": "1",
						"foldChange": "1.2761"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".100175078458813",
						"qValue": "1",
						"foldChange": "0.0898"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".467363770421555",
						"qValue": "1",
						"foldChange": "0.1150"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".380627083613246",
						"qValue": "1",
						"foldChange": "1.2804"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".112358242980972",
						"qValue": "1",
						"foldChange": "0.2535"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".686003818472082",
						"qValue": "1",
						"foldChange": "0.4083"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".252497495622444",
						"qValue": ".985830071188835",
						"foldChange": "1.6106"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".624611521656782",
						"qValue": ".297558269894937",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".399351669119434",
						"qValue": ".170271852793397",
						"foldChange": "0.9070"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".176997829770104",
						"qValue": ".050906957188614",
						"foldChange": "3.2784"
					}
				]
			},
			{
				"metaboliteId": "0078db2f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e744-0000-0000-0000-000000000000",
				"chemicalName": "hydroquinone sulfate",
				"plotName": null,
				"compoundId": "000089fa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02434",
				"inChiKey": "FPXPQMOQWJZYBL-UHFFFAOYAZ",
				"kegg": "C00530",
				"mass": 189.993594,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".069949907385807",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".009744872837349",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".150496196370487",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".060582684613909",
						"qValue": "1",
						"foldChange": "1.9022"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".007628993217617",
						"qValue": "1",
						"foldChange": "2.9796"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".392038538589045",
						"qValue": "1",
						"foldChange": "1.5107"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".343279093921584",
						"qValue": ".951252030575746",
						"foldChange": "1.3594"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".964489398846864",
						"qValue": "1",
						"foldChange": "1.9180"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".321406063850672",
						"qValue": ".957839854315834",
						"foldChange": "2.3748"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".090036818269902",
						"qValue": ".938232405704401",
						"foldChange": "2.6701"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".013182007526549",
						"qValue": ".623216022505187",
						"foldChange": "3.0374"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".402698068526114",
						"qValue": "1",
						"foldChange": "1.7006"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".132853381130223",
						"qValue": "1",
						"foldChange": "1.6102"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".06732075822597",
						"qValue": "1",
						"foldChange": "2.6014"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".664028802353174",
						"qValue": "1",
						"foldChange": "1.6156"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".633479708450903",
						"qValue": "1",
						"foldChange": "1.2263"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".075968090425393",
						"qValue": "1",
						"foldChange": "2.6350"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".024524268613819",
						"qValue": ".822195137696456",
						"foldChange": "2.1488"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".677608848217517",
						"qValue": "1",
						"foldChange": "1.0213"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".07269557718791",
						"qValue": "1",
						"foldChange": "1.8797"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".026822625267446",
						"qValue": ".985830071188835",
						"foldChange": "1.8405"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".021205939973484",
						"qValue": ".026004026626802",
						"foldChange": "2.8850"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".906852985767032",
						"qValue": ".315302653099196",
						"foldChange": "1.3754"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".619625262071321",
						"qValue": ".130223296845584",
						"foldChange": "1.3178"
					}
				]
			},
			{
				"metaboliteId": "0078db30-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c3-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3beta,17beta-diol disulfate",
				"plotName": null,
				"compoundId": "00009146-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00493",
				"inChiKey": null,
				"kegg": "C12525",
				"mass": 452.153859,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3053,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".701252872069831",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".742679400076806",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".161817535175828",
						"qValue": ".894199496328765",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".034171667424157",
						"qValue": "1",
						"foldChange": "0.7058"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".154797963271577",
						"qValue": "1",
						"foldChange": "0.7901"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".466088160174269",
						"qValue": "1",
						"foldChange": "1.2751"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".16150707260186",
						"qValue": ".742932533968558",
						"foldChange": "2.8085"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".392426963472298",
						"qValue": ".880987329915339",
						"foldChange": "2.2806"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".578798577591201",
						"qValue": ".957839854315834",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".818369114834179",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".567286981769183",
						"qValue": "1",
						"foldChange": "0.9221"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".731510086249617",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".229500980364869",
						"qValue": "1",
						"foldChange": "0.6386"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".930212066010423",
						"qValue": "1",
						"foldChange": "1.3299"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".280929111612493",
						"qValue": "1",
						"foldChange": "2.0824"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".954271877002975",
						"qValue": "1",
						"foldChange": "0.9625"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".608034300364874",
						"qValue": "1",
						"foldChange": "1.3367"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".561637389220172",
						"qValue": "1",
						"foldChange": "1.3888"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".6367174369072",
						"qValue": "1",
						"foldChange": "0.9450"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".863559874716623",
						"qValue": "1",
						"foldChange": "1.3185"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".527268829747973",
						"qValue": ".985830071188835",
						"foldChange": "1.3953"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".094179156171026",
						"qValue": ".078304789052164",
						"foldChange": "4.2508"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".247940710606521",
						"qValue": ".122238522848194",
						"foldChange": "2.0282"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013084929434228",
						"qValue": ".006721219102073",
						"foldChange": "3.9374"
					}
				]
			},
			{
				"metaboliteId": "0078db31-0000-0000-0000-000000000000",
				"chemicalId": "0000003e-0000-0000-0000-000000000000",
				"chemicalName": "12,13-DiHOME",
				"plotName": null,
				"compoundId": "000095fb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04705",
				"inChiKey": "CQSLTKIXAJTQGA-FLIBITNWBI",
				"kegg": "C14829",
				"mass": 314.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1794,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".986789096093982",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".939430651852267",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".84719080450106",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".765674324260869",
						"qValue": "1",
						"foldChange": "1.3721"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".99415085984458",
						"qValue": "1",
						"foldChange": "1.0829"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".760093388453097",
						"qValue": "1",
						"foldChange": "1.2922"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".582932444561151",
						"qValue": "1",
						"foldChange": "1.0056"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".726196103844463",
						"qValue": ".955166745551218",
						"foldChange": "1.3025"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".369936026957467",
						"qValue": ".957839854315834",
						"foldChange": "1.2305"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".840962547362278",
						"qValue": "1",
						"foldChange": "1.1386"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".587787064344534",
						"qValue": "1",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".458425625666069",
						"qValue": "1",
						"foldChange": "0.9358"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".884078266545602",
						"qValue": "1",
						"foldChange": "1.0961"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".988701836013729",
						"qValue": "1",
						"foldChange": "1.0418"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".899155982614914",
						"qValue": "1",
						"foldChange": "0.9505"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".738818351671971",
						"qValue": "1",
						"foldChange": "0.7082"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".695489945337187",
						"qValue": "1",
						"foldChange": "0.7875"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".469845594918323",
						"qValue": "1",
						"foldChange": "1.1119"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".89878154560794",
						"qValue": "1",
						"foldChange": "0.9815"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".633521197298127",
						"qValue": "1",
						"foldChange": "0.8183"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".541115513873865",
						"qValue": ".985830071188835",
						"foldChange": "0.8337"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".033693085193248",
						"qValue": ".037309242389558",
						"foldChange": "0.7825"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".271883407450274",
						"qValue": ".130713311366695",
						"foldChange": "0.8107"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".476838023185042",
						"qValue": ".107523243015924",
						"foldChange": "0.9295"
					}
				]
			},
			{
				"metaboliteId": "0078db32-0000-0000-0000-000000000000",
				"chemicalId": "05f5f25a-0000-0000-0000-000000000000",
				"chemicalName": "1-arachidonoyl-GPA (20:4)",
				"plotName": null,
				"compoundId": "0000b4f5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 458.24334,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2310,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".390967814270958",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".793882538361588",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".701507145004403",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".395486464352133",
						"qValue": "1",
						"foldChange": "2.5074"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".975194141039122",
						"qValue": "1",
						"foldChange": "1.0088"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".412812514907255",
						"qValue": "1",
						"foldChange": "1.6441"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".535594794734702",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".766760222171627",
						"qValue": ".97174445063751",
						"foldChange": "1.6117"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".360994019678412",
						"qValue": ".957839854315834",
						"foldChange": "1.6901"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".444222497779882",
						"qValue": "1",
						"foldChange": "1.6046"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".444222497779882",
						"qValue": "1",
						"foldChange": "1.5375"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".988521476296813",
						"qValue": "1",
						"foldChange": "0.8966"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".419243693022224",
						"qValue": "1",
						"foldChange": "0.5912"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".400247740293262",
						"qValue": "1",
						"foldChange": "0.6594"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".15260542748686",
						"qValue": "1",
						"foldChange": "0.4262"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".107653270777174",
						"qValue": "1",
						"foldChange": "0.3840"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".789056704994624",
						"qValue": "1",
						"foldChange": "0.9010"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".789429269447749",
						"qValue": "1",
						"foldChange": "0.9483"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".950178054169962",
						"qValue": "1",
						"foldChange": "0.8367"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".746972869120686",
						"qValue": ".997573179629986",
						"foldChange": "0.8823"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000026376199522",
						"qValue": ".000271485731442",
						"foldChange": "0.2855"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000020654434677",
						"qValue": ".000266117226655",
						"foldChange": "0.2751"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000255290582087",
						"qValue": ".000392445008508",
						"foldChange": "0.3466"
					}
				]
			},
			{
				"metaboliteId": "0078db33-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0f9-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxydecanoate",
				"plotName": null,
				"compoundId": "0000a5f9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GHPVDCPCKSNJDR-UHFFFAOYAC",
				"kegg": null,
				"mass": 188.141245,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1732,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".691685778065251",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".492029661300519",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".063804227070962",
						"qValue": ".730905288705153",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".180980530420328",
						"qValue": "1",
						"foldChange": "1.2577"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".17083461942369",
						"qValue": "1",
						"foldChange": "1.2647"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".973901874552327",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".022414022440031",
						"qValue": ".359575885726771",
						"foldChange": "0.8198"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".443821715947857",
						"qValue": ".900630141528144",
						"foldChange": "1.0356"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".118991564376532",
						"qValue": ".920562011676628",
						"foldChange": "1.2413"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".155004433906338",
						"qValue": "1",
						"foldChange": "1.2669"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".197909838283393",
						"qValue": "1",
						"foldChange": "1.2557"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".889861100910465",
						"qValue": "1",
						"foldChange": "0.9607"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".264537422165276",
						"qValue": "1",
						"foldChange": "1.3113"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".580030102572583",
						"qValue": "1",
						"foldChange": "1.1924"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".602898846548946",
						"qValue": "1",
						"foldChange": "0.9093"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".24396273739908",
						"qValue": "1",
						"foldChange": "0.7448"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".510262327439007",
						"qValue": "1",
						"foldChange": "1.0898"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".075173606667427",
						"qValue": ".822195137696456",
						"foldChange": "1.4632"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".815794979749588",
						"qValue": "1",
						"foldChange": "0.9217"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".582316951661232",
						"qValue": "1",
						"foldChange": "1.1564"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".431161265367086",
						"qValue": ".985830071188835",
						"foldChange": "1.2546"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".668745338688481",
						"qValue": ".313175797909121",
						"foldChange": "1.1687"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".181352114839453",
						"qValue": ".096930782863831",
						"foldChange": "1.3005"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".027192581780048",
						"qValue": ".011912567781405",
						"foldChange": "1.7194"
					}
				]
			},
			{
				"metaboliteId": "0078db34-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c6-0000-0000-0000-000000000000",
				"chemicalName": "taurocholenate sulfate*",
				"plotName": null,
				"compoundId": "00008027-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 561.243009,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".436851248919148",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".025621912480182",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".037094442008773",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".089015558592724",
						"qValue": "1",
						"foldChange": "0.8509"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".164225299997163",
						"qValue": "1",
						"foldChange": "0.8557"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".747947289516181",
						"qValue": "1",
						"foldChange": "1.1698"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".456250293856293",
						"qValue": "1",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".00318665704547",
						"qValue": ".27118451456953",
						"foldChange": "0.7194"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".022704625737837",
						"qValue": ".57754088580993",
						"foldChange": "0.8371"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".03952953193836",
						"qValue": ".821720595261736",
						"foldChange": "1.5024"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".98349689824082",
						"qValue": "1",
						"foldChange": "1.0458"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".041423310746794",
						"qValue": ".683421910757508",
						"foldChange": "0.7461"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".344860920881611",
						"qValue": "1",
						"foldChange": "1.3403"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".619884159521709",
						"qValue": "1",
						"foldChange": "1.1835"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".680175554079053",
						"qValue": "1",
						"foldChange": "0.8830"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".179876495137229",
						"qValue": "1",
						"foldChange": "1.3170"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".05219067085505",
						"qValue": "1",
						"foldChange": "1.5493"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".46412692237579",
						"qValue": "1",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".736200855007569",
						"qValue": "1",
						"foldChange": "0.9500"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".302354368605413",
						"qValue": "1",
						"foldChange": "1.1490"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".46162648827834",
						"qValue": ".985830071188835",
						"foldChange": "1.2094"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".273497137739909",
						"qValue": ".16742077809825",
						"foldChange": "0.5779"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".861488014285132",
						"qValue": ".304079578394996",
						"foldChange": "1.1635"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".213085740117575",
						"qValue": ".057675178595684",
						"foldChange": "0.5719"
					}
				]
			},
			{
				"metaboliteId": "0078db35-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec5f-0000-0000-0000-000000000000",
				"chemicalName": "glycoursodeoxycholate",
				"plotName": null,
				"compoundId": "000099d3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00708",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 449.314123,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3142,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".213173537397205",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".531526438200725",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".857257397752174",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".46131020252216",
						"qValue": "1",
						"foldChange": "2.0103"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".278580714642471",
						"qValue": "1",
						"foldChange": "2.4004"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".725433730654955",
						"qValue": "1",
						"foldChange": "1.8601"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".454260761459169",
						"qValue": "1",
						"foldChange": "1.0617"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".944493418491487",
						"qValue": "1",
						"foldChange": "2.8601"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".496971495155755",
						"qValue": ".957839854315834",
						"foldChange": "2.1699"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".836471942454464",
						"qValue": "1",
						"foldChange": "1.4473"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".462753257763827",
						"qValue": "1",
						"foldChange": "1.4652"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".596686182104555",
						"qValue": "1",
						"foldChange": "1.1817"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".070158283311109",
						"qValue": "1",
						"foldChange": "7.4872"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".933691143738324",
						"qValue": "1",
						"foldChange": "1.2711"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".093655223987434",
						"qValue": "1",
						"foldChange": "0.1698"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".297349865694365",
						"qValue": "1",
						"foldChange": "1.2123"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".850168184461194",
						"qValue": "1",
						"foldChange": "0.5938"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".230696837376646",
						"qValue": "1",
						"foldChange": "0.4898"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".229773146541914",
						"qValue": "1",
						"foldChange": "2.1852"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".937309453226895",
						"qValue": "1",
						"foldChange": "1.0335"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".214177748866131",
						"qValue": ".985830071188835",
						"foldChange": "0.4730"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".198210915888196",
						"qValue": ".1333857097504",
						"foldChange": "0.4957"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".475341125520203",
						"qValue": ".195204762397292",
						"foldChange": "1.3854"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".598786990563163",
						"qValue": ".126891905178055",
						"foldChange": "0.7973"
					}
				]
			},
			{
				"metaboliteId": "0078db36-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec60-0000-0000-0000-000000000000",
				"chemicalName": "tauroursodeoxycholate",
				"plotName": null,
				"compoundId": "000099d2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00874",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 499.296759,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3145,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".21798499983122",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".688003650929647",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".078412259402791",
						"qValue": ".766998077606613",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".085520273387967",
						"qValue": "1",
						"foldChange": "2.2521"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".210368114194226",
						"qValue": "1",
						"foldChange": "1.9571"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".629071809776731",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".102582193103425",
						"qValue": ".671518817930883",
						"foldChange": "0.8750"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".028464632198445",
						"qValue": ".678234398444917",
						"foldChange": "0.9944"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".554807234619794",
						"qValue": ".957839854315834",
						"foldChange": "1.3583"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".355135693248419",
						"qValue": "1",
						"foldChange": "1.5556"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".556932998981318",
						"qValue": "1",
						"foldChange": "1.4741"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".733731240435167",
						"qValue": "1",
						"foldChange": "1.2476"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".002333791328631",
						"qValue": "1",
						"foldChange": "8.9375"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".53943319096812",
						"qValue": "1",
						"foldChange": "1.3909"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".017392867831756",
						"qValue": "1",
						"foldChange": "0.1556"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".768036585003322",
						"qValue": "1",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".993720348177335",
						"qValue": "1",
						"foldChange": "0.8820"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".768944119229652",
						"qValue": "1",
						"foldChange": "0.8448"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".819764752277656",
						"qValue": "1",
						"foldChange": "1.1641"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".926407979567886",
						"qValue": "1",
						"foldChange": "0.8964"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".899636654419206",
						"qValue": ".997573179629986",
						"foldChange": "0.7700"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".576419801422658",
						"qValue": ".281485505109855",
						"foldChange": "0.5469"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".348349544636443",
						"qValue": ".153939826973754",
						"foldChange": "1.6096"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".804766662154303",
						"qValue": ".16112964031296",
						"foldChange": "1.0538"
					}
				]
			},
			{
				"metaboliteId": "0078db37-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e2-0000-0000-0000-000000000000",
				"chemicalName": "2-aminophenol sulfate",
				"plotName": null,
				"compoundId": "0000a902-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61116",
				"inChiKey": "VSTZVCJQGSLNLL-UHFFFAOYAM",
				"kegg": null,
				"mass": 189.009578,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5249,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".36192926720982",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".447380421575337",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".934691233863524",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".767655791318268",
						"qValue": "1",
						"foldChange": "2.2150"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".738429466127709",
						"qValue": "1",
						"foldChange": "1.2967"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".969248247986294",
						"qValue": "1",
						"foldChange": "3.2527"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".203123595614829",
						"qValue": ".802574227585289",
						"foldChange": "1.7947"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".548582002553491",
						"qValue": ".917177375585502",
						"foldChange": "2.2225"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495918560871907",
						"qValue": ".957839854315834",
						"foldChange": "1.4033"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".540152457747726",
						"qValue": "1",
						"foldChange": "1.4773"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".834306920559092",
						"qValue": "1",
						"foldChange": "1.1902"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".412150652887433",
						"qValue": "1",
						"foldChange": "0.7615"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".503332729578123",
						"qValue": "1",
						"foldChange": "0.8254"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".909941207668971",
						"qValue": "1",
						"foldChange": "0.6600"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".445273844706553",
						"qValue": "1",
						"foldChange": "0.7996"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".18799933849033",
						"qValue": "1",
						"foldChange": "1.1467"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".899184063569507",
						"qValue": "1",
						"foldChange": "0.6526"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".25046967988873",
						"qValue": "1",
						"foldChange": "0.5691"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".403070028243145",
						"qValue": "1",
						"foldChange": "1.7256"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".625783592716619",
						"qValue": "1",
						"foldChange": "0.5999"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".193167552603451",
						"qValue": ".985830071188835",
						"foldChange": "0.3477"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".186157024836868",
						"qValue": ".126300900185341",
						"foldChange": "1.6545"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".020294304783248",
						"qValue": ".018331301559304",
						"foldChange": "5.4897"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".066051551156223",
						"qValue": ".023442602561205",
						"foldChange": "2.1806"
					}
				]
			},
			{
				"metaboliteId": "0078db38-0000-0000-0000-000000000000",
				"chemicalId": "05f5f31a-0000-0000-0000-000000000000",
				"chemicalName": "3-methoxytyramine sulfate",
				"plotName": null,
				"compoundId": "0000ae4a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ORZHQEJEAKXCJA-UHFFFAOYAN",
				"kegg": null,
				"mass": 247.051443,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 188,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".956641700209691",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".176568887960669",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".369749074429586",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".449441400529148",
						"qValue": "1",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".963365254176551",
						"qValue": "1",
						"foldChange": "1.0411"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".477206293471481",
						"qValue": "1",
						"foldChange": "1.5829"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".025588390693533",
						"qValue": ".359575885726771",
						"foldChange": "0.7935"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".014521115560498",
						"qValue": ".567215491640796",
						"foldChange": "0.7769"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".817592603530174",
						"qValue": "1",
						"foldChange": "1.0838"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".948407226101359",
						"qValue": "1",
						"foldChange": "1.0815"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".84372400756344",
						"qValue": "1",
						"foldChange": "1.1096"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".894626911501047",
						"qValue": "1",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".339910490918332",
						"qValue": "1",
						"foldChange": "4.3607"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".859750327295183",
						"qValue": "1",
						"foldChange": "0.5726"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".269799921718207",
						"qValue": "1",
						"foldChange": "0.1313"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".737194040728021",
						"qValue": "1",
						"foldChange": "0.8438"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".675223158272107",
						"qValue": "1",
						"foldChange": "1.0523"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".451413803469839",
						"qValue": "1",
						"foldChange": "1.2471"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".23784639343736",
						"qValue": "1",
						"foldChange": "0.6784"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".750820827995853",
						"qValue": "1",
						"foldChange": "0.8249"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".411126078982877",
						"qValue": ".985830071188835",
						"foldChange": "1.2161"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005010723807213",
						"qValue": ".009115403870724",
						"foldChange": "2.1508"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008523978728325",
						"qValue": ".008935643358566",
						"foldChange": "1.7686"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001165040484809",
						"qValue": ".001142136931488",
						"foldChange": "2.6072"
					}
				]
			},
			{
				"metaboliteId": "0078db39-0000-0000-0000-000000000000",
				"chemicalId": "05f5e57e-0000-0000-0000-000000000000",
				"chemicalName": "propionylglycine",
				"plotName": null,
				"compoundId": "00007cbc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00783",
				"inChiKey": "WOMAZEJKVZLLFE-UHFFFAOYAY",
				"kegg": null,
				"mass": 131.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1596,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".454037300133047",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".261562678588547",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320144792114819",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".365431423113427",
						"qValue": "1",
						"foldChange": "1.0352"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".115316507681859",
						"qValue": "1",
						"foldChange": "1.3482"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".015209733412853",
						"qValue": "1",
						"foldChange": "1.6485"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".63986524525303",
						"qValue": "1",
						"foldChange": "1.0680"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".51899811045717",
						"qValue": ".908780641973358",
						"foldChange": "0.9532"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".858843347750326",
						"qValue": "1",
						"foldChange": "1.1234"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".851929283566406",
						"qValue": "1",
						"foldChange": "1.0593"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".499075619471782",
						"qValue": "1",
						"foldChange": "1.1789"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".623950329119262",
						"qValue": "1",
						"foldChange": "1.0799"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".176874872665801",
						"qValue": "1",
						"foldChange": "1.5324"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".952701359602907",
						"qValue": "1",
						"foldChange": "1.1106"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".211266060888685",
						"qValue": "1",
						"foldChange": "0.7247"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".103423331779722",
						"qValue": "1",
						"foldChange": "1.5648"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".477513573724225",
						"qValue": "1",
						"foldChange": "1.3110"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".384312124756048",
						"qValue": "1",
						"foldChange": "0.8378"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".990204683304422",
						"qValue": "1",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".647313428927113",
						"qValue": "1",
						"foldChange": "0.8395"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".647826838942495",
						"qValue": ".985830071188835",
						"foldChange": "0.8164"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00263508807061",
						"qValue": ".005592646573591",
						"foldChange": "3.7117"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002643875129456",
						"qValue": ".004092249995339",
						"foldChange": "2.9235"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000267001997321",
						"qValue": ".000400782154107",
						"foldChange": "4.2956"
					}
				]
			},
			{
				"metaboliteId": "0078db3a-0000-0000-0000-000000000000",
				"chemicalId": "05f5f03d-0000-0000-0000-000000000000",
				"chemicalName": "2-stearoyl-GPE (18:0)*",
				"plotName": null,
				"compoundId": "0000a104-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11129",
				"inChiKey": "KIHAGWUUUHJRMS-JOCHJYFZBB",
				"kegg": null,
				"mass": 481.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2379,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".490495834602732",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".771022858206088",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".077277333780418",
						"qValue": ".764686174966689",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".992281107202613",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".604731758065671",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".598026315290239",
						"qValue": "1",
						"foldChange": "1.0803"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".109181915830659",
						"qValue": ".678202995415259",
						"foldChange": "1.2927"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".894294320717262",
						"qValue": "1",
						"foldChange": "1.0650"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".08379337745462",
						"qValue": ".796492767737576",
						"foldChange": "0.8477"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".115281341158639",
						"qValue": "1",
						"foldChange": "0.8509"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".571411326073339",
						"qValue": "1",
						"foldChange": "1.1223"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".034678808130656",
						"qValue": ".614826369149758",
						"foldChange": "1.2600"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".862190482837712",
						"qValue": "1",
						"foldChange": "1.0400"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".188332519999425",
						"qValue": "1",
						"foldChange": "1.1869"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".237028290203418",
						"qValue": "1",
						"foldChange": "1.1413"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".149681151774648",
						"qValue": "1",
						"foldChange": "1.2526"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".990923811258721",
						"qValue": "1",
						"foldChange": "1.1162"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".166084055208466",
						"qValue": "1",
						"foldChange": "0.8911"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".720039321571093",
						"qValue": "1",
						"foldChange": "0.9841"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".167953371025426",
						"qValue": "1",
						"foldChange": "1.1631"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".080460171573235",
						"qValue": ".985830071188835",
						"foldChange": "1.1819"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000057601399469",
						"qValue": ".000340558595845",
						"foldChange": "3.1437"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00082239091343",
						"qValue": ".00201158578506",
						"foldChange": "1.7330"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001021517616631",
						"qValue": ".001067127706614",
						"foldChange": "2.0096"
					}
				]
			},
			{
				"metaboliteId": "0078db3b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e936-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-beta-alanine",
				"plotName": null,
				"compoundId": "00009238-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LJLLAWRMBZNPMO-UHFFFAOYAA",
				"kegg": "C01073",
				"mass": 131.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3858,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".861762899383227",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".547725037501",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".207632561582441",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".307152035887351",
						"qValue": "1",
						"foldChange": "0.9247"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".424813755041838",
						"qValue": "1",
						"foldChange": "1.0985"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".072382049640911",
						"qValue": "1",
						"foldChange": "1.1965"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".64207101553712",
						"qValue": "1",
						"foldChange": "1.0747"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".334063253939068",
						"qValue": ".837314778720603",
						"foldChange": "0.9297"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".155361422280975",
						"qValue": ".957839854315834",
						"foldChange": "0.9093"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".274111187261221",
						"qValue": "1",
						"foldChange": "0.9683"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".92648248970056",
						"qValue": "1",
						"foldChange": "1.0121"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".315883586944582",
						"qValue": "1",
						"foldChange": "1.1254"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".674882143499423",
						"qValue": "1",
						"foldChange": "1.0811"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".520227637344701",
						"qValue": "1",
						"foldChange": "1.1043"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".799982606480455",
						"qValue": "1",
						"foldChange": "1.0215"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".147021121322236",
						"qValue": "1",
						"foldChange": "1.2098"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".583119107727043",
						"qValue": "1",
						"foldChange": "1.0514"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".392995454056495",
						"qValue": "1",
						"foldChange": "0.8690"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".420924601121896",
						"qValue": "1",
						"foldChange": "0.9311"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".969212452231929",
						"qValue": "1",
						"foldChange": "0.9298"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".412853742197412",
						"qValue": ".985830071188835",
						"foldChange": "0.9986"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".460536629740193",
						"qValue": ".235307630734525",
						"foldChange": "1.1935"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".354737147565502",
						"qValue": ".155775104197002",
						"foldChange": "1.1726"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".117447149761787",
						"qValue": ".036947244627765",
						"foldChange": "1.2130"
					}
				]
			},
			{
				"metaboliteId": "0078db3c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f92f-0000-0000-0000-000000000000",
				"chemicalName": "p-cresol glucuronide*",
				"plotName": null,
				"compoundId": "0000bec9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11686",
				"inChiKey": "JPAUCQAJHLSMQW-XPORZQOIBX",
				"kegg": null,
				"mass": 284.089603,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 207,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".573854043138718",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".905362985495456",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".683273419858879",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".567047282772765",
						"qValue": "1",
						"foldChange": "2.6936"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".324233317494071",
						"qValue": "1",
						"foldChange": "3.4548"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".676622461152876",
						"qValue": "1",
						"foldChange": "1.3009"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".697078101385722",
						"qValue": "1",
						"foldChange": "1.3937"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".459362855292934",
						"qValue": ".902562957068497",
						"foldChange": "1.3244"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".724671789483219",
						"qValue": ".976023509428735",
						"foldChange": "1.0445"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".359674691905556",
						"qValue": "1",
						"foldChange": "0.8881"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".515595609075503",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".788437604949424",
						"qValue": "1",
						"foldChange": "1.4012"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".747425876259451",
						"qValue": "1",
						"foldChange": "0.6455"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".512817279999306",
						"qValue": "1",
						"foldChange": "1.6460"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".328264229992834",
						"qValue": "1",
						"foldChange": "2.5500"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".432817190403291",
						"qValue": "1",
						"foldChange": "1.0295"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".946573912080825",
						"qValue": "1",
						"foldChange": "1.0811"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".488821646428493",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".251055240321198",
						"qValue": "1",
						"foldChange": "0.9156"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".897363502585048",
						"qValue": "1",
						"foldChange": "1.1481"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".325302120514821",
						"qValue": ".985830071188835",
						"foldChange": "1.2539"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001225673439676",
						"qValue": ".003051195059985",
						"foldChange": "9.4972"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".011405855471054",
						"qValue": ".011341847215214",
						"foldChange": "8.4965"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010818954317325",
						"qValue": ".005739703612364",
						"foldChange": "4.3789"
					}
				]
			},
			{
				"metaboliteId": "0078db3d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4c1-0000-0000-0000-000000000000",
				"chemicalName": "homoarginine",
				"plotName": null,
				"compoundId": "00005679-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00670",
				"inChiKey": "QUOGESRFPZDMMT-YFKPBYRVBD",
				"kegg": "C01924",
				"mass": 188.127326,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 424,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".96193043137007",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".292853627325263",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".633262627702123",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".311599495151343",
						"qValue": "1",
						"foldChange": "0.9577"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".801311723135917",
						"qValue": "1",
						"foldChange": "0.9926"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".445306396292829",
						"qValue": "1",
						"foldChange": "1.0593"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".866943770055751",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".988265036966722",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".878524770311706",
						"qValue": "1",
						"foldChange": "1.0207"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".214993520198861",
						"qValue": "1",
						"foldChange": "0.9469"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".068483654576328",
						"qValue": "1",
						"foldChange": "0.9197"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".546604627284402",
						"qValue": "1",
						"foldChange": "0.9247"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".914972503571497",
						"qValue": "1",
						"foldChange": "0.8998"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".983238979648534",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".900575048944714",
						"qValue": "1",
						"foldChange": "1.0666"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".834099991564152",
						"qValue": "1",
						"foldChange": "0.9254"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".931078415486083",
						"qValue": "1",
						"foldChange": "0.8498"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".770789610354113",
						"qValue": "1",
						"foldChange": "0.9183"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".984714928841372",
						"qValue": "1",
						"foldChange": "0.9093"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".565422651254906",
						"qValue": "1",
						"foldChange": "0.7834"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".56844026890092",
						"qValue": ".985830071188835",
						"foldChange": "0.8616"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".679278737474889",
						"qValue": ".315092159646901",
						"foldChange": "1.0910"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000090546094172",
						"qValue": ".000587173398272",
						"foldChange": "1.6557"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".021616472804586",
						"qValue": ".010015585938118",
						"foldChange": "1.7603"
					}
				]
			},
			{
				"metaboliteId": "0078db3e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4ea-0000-0000-0000-000000000000",
				"chemicalName": "EDTA",
				"plotName": null,
				"compoundId": "00005693-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB15109",
				"inChiKey": "KCXVZYZYPLLWCC-UHFFFAOYAG",
				"kegg": "C00284",
				"mass": 292.090666,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5297,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".629656228635934",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".756964083480599",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".79319774454445",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".273264055999352",
						"qValue": "1",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".93482155950185",
						"qValue": "1",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".239708907236297",
						"qValue": "1",
						"foldChange": "1.1385"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".906017903481651",
						"qValue": "1",
						"foldChange": "1.0254"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".554922262321978",
						"qValue": ".922341494601569",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".636316083582678",
						"qValue": ".973034272612281",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".946998318970384",
						"qValue": "1",
						"foldChange": "0.9979"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".7763845099398",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".827723798240096",
						"qValue": "1",
						"foldChange": "0.9801"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".725021732366872",
						"qValue": "1",
						"foldChange": "0.9670"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".797793903686746",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".546814765645903",
						"qValue": "1",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".604993907664064",
						"qValue": "1",
						"foldChange": "1.0320"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".268012589220359",
						"qValue": "1",
						"foldChange": "1.0767"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".523685135781727",
						"qValue": "1",
						"foldChange": "1.0433"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".361042164236498",
						"qValue": "1",
						"foldChange": "0.9221"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".950670909230979",
						"qValue": "1",
						"foldChange": "0.9689"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".411019670870131",
						"qValue": ".985830071188835",
						"foldChange": "1.0508"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000171277802942",
						"qValue": ".000728359333549",
						"foldChange": "1.6814"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000236076039325",
						"qValue": ".000898002474794",
						"foldChange": "1.6001"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000063310904271",
						"qValue": ".000172721742087",
						"foldChange": "1.7353"
					}
				]
			},
			{
				"metaboliteId": "0078db3f-0000-0000-0000-000000000000",
				"chemicalId": "000004c5-0000-0000-0000-000000000000",
				"chemicalName": "creatine",
				"plotName": null,
				"compoundId": "00006c46-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00064",
				"inChiKey": "CVSVTCORWBXHQV-UHFFFAOYAV",
				"kegg": "C00300",
				"mass": 131.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 456,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".828069380294916",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".127232375463385",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".532243146488687",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".802387519914721",
						"qValue": "1",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".464497185710293",
						"qValue": "1",
						"foldChange": "0.9724"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".629386361242714",
						"qValue": "1",
						"foldChange": "0.9907"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".352033172474409",
						"qValue": ".951252030575746",
						"foldChange": "0.9589"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".12611443006299",
						"qValue": ".728442723450525",
						"foldChange": "0.9233"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".540214989801",
						"qValue": ".957839854315834",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".046011283852888",
						"qValue": ".821720595261736",
						"foldChange": "0.8699"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".525906298674146",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".165143117197869",
						"qValue": ".969566045102463",
						"foldChange": "1.0948"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".811155179248189",
						"qValue": "1",
						"foldChange": "0.9036"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".745398277037248",
						"qValue": "1",
						"foldChange": "0.8865"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".919257179124279",
						"qValue": "1",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".624093672833341",
						"qValue": "1",
						"foldChange": "0.8651"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".302594549086651",
						"qValue": "1",
						"foldChange": "0.7836"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".55630490345368",
						"qValue": "1",
						"foldChange": "0.9059"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".596807730003999",
						"qValue": "1",
						"foldChange": "0.8963"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".762232977341316",
						"qValue": "1",
						"foldChange": "0.9218"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".838822552773884",
						"qValue": ".997573179629986",
						"foldChange": "1.0284"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008366996940904",
						"qValue": ".013157324947472",
						"foldChange": "0.6848"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".046839248045482",
						"qValue": ".035783445552408",
						"foldChange": "0.7741"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".145319081708844",
						"qValue": ".043368579407762",
						"foldChange": "0.7819"
					}
				]
			},
			{
				"metaboliteId": "0078db40-0000-0000-0000-000000000000",
				"chemicalId": "05f5e502-0000-0000-0000-000000000000",
				"chemicalName": "galactonate",
				"plotName": null,
				"compoundId": "00006c47-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00565",
				"inChiKey": "RGHNJXZEOKUKBD-SQOUGZDYBY",
				"kegg": "C00880",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".975063695869678",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".92318436445833",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".918268220105767",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".549334216697717",
						"qValue": "1",
						"foldChange": "13.9170"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".739569016661805",
						"qValue": "1",
						"foldChange": "14.2444"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".789338146006089",
						"qValue": "1",
						"foldChange": "1.9238"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".951907701004043",
						"qValue": "1",
						"foldChange": "8.5705"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".72267420732526",
						"qValue": ".952583946159028",
						"foldChange": "3.9102"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".678099065211364",
						"qValue": ".976023509428735",
						"foldChange": "19.7109"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".93232747335616",
						"qValue": "1",
						"foldChange": "16.9560"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".574470805929229",
						"qValue": "1",
						"foldChange": "3.3975"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".518336111994754",
						"qValue": "1",
						"foldChange": "9.0928"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".962130354554831",
						"qValue": "1",
						"foldChange": "0.7901"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".705086476674311",
						"qValue": "1",
						"foldChange": "1.1596"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".732528462411508",
						"qValue": "1",
						"foldChange": "1.4678"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".587606766584157",
						"qValue": "1",
						"foldChange": "0.5498"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".956564205846814",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".638565286762144",
						"qValue": "1",
						"foldChange": "1.8065"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".959065080944865",
						"qValue": "1",
						"foldChange": "0.9558"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".681469692945109",
						"qValue": "1",
						"foldChange": "0.5969"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".638311628183206",
						"qValue": ".985830071188835",
						"foldChange": "0.6245"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".137846957215323",
						"qValue": ".103726887435349",
						"foldChange": "9.3288"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007294835583203",
						"qValue": ".008104139209635",
						"foldChange": "26.6575"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".023143137387332",
						"qValue": ".010510901764508",
						"foldChange": "17.1570"
					}
				]
			},
			{
				"metaboliteId": "0078db41-0000-0000-0000-000000000000",
				"chemicalId": "000001eb-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxal",
				"plotName": null,
				"compoundId": "00000673-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01545",
				"inChiKey": "RADKZDMFGJYCBB-UHFFFAOYAP",
				"kegg": "C00250",
				"mass": 167.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4052,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".616280429260451",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".413083252192727",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".859614172448551",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".535032900730312",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".533848997090178",
						"qValue": "1",
						"foldChange": "1.5188"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".216814467128701",
						"qValue": "1",
						"foldChange": "1.9603"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".690006164463972",
						"qValue": "1",
						"foldChange": "1.7094"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".626215448770837",
						"qValue": ".928868848501546",
						"foldChange": "1.6124"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".377119324788539",
						"qValue": ".957839854315834",
						"foldChange": "2.5268"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".423363288935368",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".452347696760974",
						"qValue": "1",
						"foldChange": "0.9284"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".960472482024181",
						"qValue": "1",
						"foldChange": "1.4109"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".583343742530257",
						"qValue": "1",
						"foldChange": "0.9774"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".520256701250628",
						"qValue": "1",
						"foldChange": "1.5906"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".236928925181857",
						"qValue": "1",
						"foldChange": "1.6275"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".698017940299753",
						"qValue": "1",
						"foldChange": "0.8328"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".617613273708641",
						"qValue": "1",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".37656139006904",
						"qValue": "1",
						"foldChange": "1.1256"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".512804607266367",
						"qValue": "1",
						"foldChange": "0.7238"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".804183489466646",
						"qValue": "1",
						"foldChange": "0.6197"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".703090297920194",
						"qValue": ".995398138635986",
						"foldChange": "0.8562"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".228396430739181",
						"qValue": ".146266748811641",
						"foldChange": "1.5785"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003495028361487",
						"qValue": ".005085705003212",
						"foldChange": "4.2030"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000045462344793",
						"qValue": ".000140562506688",
						"foldChange": "8.5297"
					}
				]
			},
			{
				"metaboliteId": "0078db42-0000-0000-0000-000000000000",
				"chemicalId": "000000f9-0000-0000-0000-000000000000",
				"chemicalName": "carnosine",
				"plotName": null,
				"compoundId": "000006e8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00033",
				"inChiKey": "CQOVPNPJLQNMDC-ZETCQYMHBX",
				"kegg": "C00386",
				"mass": 226.106591,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 79,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".485829354306533",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".982326756491944",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".82464255839296",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".669601382119433",
						"qValue": "1",
						"foldChange": "1.6574"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".428804302579377",
						"qValue": "1",
						"foldChange": "0.9449"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".714002001315436",
						"qValue": "1",
						"foldChange": "1.4563"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".942889284412482",
						"qValue": "1",
						"foldChange": "1.3898"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".882807840141143",
						"qValue": "1",
						"foldChange": "1.9958"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".939590595017271",
						"qValue": "1",
						"foldChange": "2.2333"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".637882459689616",
						"qValue": "1",
						"foldChange": "1.5754"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".357758523244846",
						"qValue": "1",
						"foldChange": "1.9805"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".651232711171091",
						"qValue": "1",
						"foldChange": "1.3734"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".28579523636621",
						"qValue": "1",
						"foldChange": "0.6460"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".119467646165044",
						"qValue": "1",
						"foldChange": "0.4653"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".567661071838503",
						"qValue": "1",
						"foldChange": "0.7204"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".518485836364847",
						"qValue": "1",
						"foldChange": "0.6262"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".413971268816109",
						"qValue": "1",
						"foldChange": "0.6433"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".83163181717866",
						"qValue": "1",
						"foldChange": "1.0273"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".787474810109144",
						"qValue": "1",
						"foldChange": "1.3478"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".895922939600278",
						"qValue": "1",
						"foldChange": "1.0267"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".89878700178892",
						"qValue": ".997573179629986",
						"foldChange": "0.7618"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".016033747666879",
						"qValue": ".021065964222001",
						"foldChange": "3.2046"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".030179596543036",
						"qValue": ".024971730247194",
						"foldChange": "4.2067"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00214241897961",
						"qValue": ".001822483514696",
						"foldChange": "3.1212"
					}
				]
			},
			{
				"metaboliteId": "0078db43-0000-0000-0000-000000000000",
				"chemicalId": "05f5e697-0000-0000-0000-000000000000",
				"chemicalName": "1-pentadecanoylglycerol (15:0)",
				"plotName": null,
				"compoundId": "0000bb1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QSKPZDMBULYMDQ-UHFFFAOYAZ",
				"kegg": null,
				"mass": 316.26136,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".459622534923339",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".931713089741614",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".851267485738582",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".599826396177953",
						"qValue": "1",
						"foldChange": "2.5968"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".641401303604164",
						"qValue": "1",
						"foldChange": "1.4360"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".952842978757016",
						"qValue": "1",
						"foldChange": "2.3276"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".505212414347648",
						"qValue": "1",
						"foldChange": "1.2537"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".401171018627621",
						"qValue": ".882161593933088",
						"foldChange": "1.2019"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".861273063203627",
						"qValue": "1",
						"foldChange": "1.0968"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".570889140301333",
						"qValue": "1",
						"foldChange": "1.8167"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".841194482698266",
						"qValue": "1",
						"foldChange": "1.3939"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".713595009558921",
						"qValue": "1",
						"foldChange": "1.0584"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".228470370697189",
						"qValue": "1",
						"foldChange": "1.7662"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".731628966720068",
						"qValue": "1",
						"foldChange": "0.7314"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".13069974102759",
						"qValue": "1",
						"foldChange": "0.4141"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".887072704531827",
						"qValue": "1",
						"foldChange": "0.8866"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".782454404907699",
						"qValue": "1",
						"foldChange": "0.6521"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".674319984525823",
						"qValue": "1",
						"foldChange": "0.7355"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".965191337894893",
						"qValue": "1",
						"foldChange": "0.7729"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".572970146352754",
						"qValue": "1",
						"foldChange": "0.5098"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".535586623711183",
						"qValue": ".985830071188835",
						"foldChange": "0.6597"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00597071936958",
						"qValue": ".010459518529907",
						"foldChange": "0.2994"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006181381135432",
						"qValue": ".007408084959211",
						"foldChange": "0.5244"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".091572000511878",
						"qValue": ".030481516989025",
						"foldChange": "0.7649"
					}
				]
			},
			{
				"metaboliteId": "0078db44-0000-0000-0000-000000000000",
				"chemicalId": "05f5e99e-0000-0000-0000-000000000000",
				"chemicalName": "alpha-CEHC",
				"plotName": null,
				"compoundId": "0000bc3c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01518",
				"inChiKey": "AXODOWFEFKOVSH-UHFFFAOYAF",
				"kegg": null,
				"mass": 278.151809,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3982,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".609163825680966",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".053711429784015",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".976253210250315",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".312792537612186",
						"qValue": "1",
						"foldChange": "0.9541"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".485242829104426",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".752793633838078",
						"qValue": "1",
						"foldChange": "1.1088"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".069791345831225",
						"qValue": ".593497652236823",
						"foldChange": "0.8400"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".496035441420218",
						"qValue": ".902562957068497",
						"foldChange": "1.0818"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".248293193896147",
						"qValue": ".957839854315834",
						"foldChange": "1.3535"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".149273660493123",
						"qValue": "1",
						"foldChange": "0.8141"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".369424798754779",
						"qValue": "1",
						"foldChange": "0.9038"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".578391700334075",
						"qValue": "1",
						"foldChange": "1.1602"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".583510424999489",
						"qValue": "1",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".710591386641353",
						"qValue": "1",
						"foldChange": "1.7110"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".364544049652175",
						"qValue": "1",
						"foldChange": "1.9421"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".388734583892954",
						"qValue": "1",
						"foldChange": "0.5046"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".877303906281086",
						"qValue": "1",
						"foldChange": "0.8768"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".322102913519719",
						"qValue": "1",
						"foldChange": "1.7375"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".598998215157165",
						"qValue": "1",
						"foldChange": "0.5762"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".789732065897281",
						"qValue": "1",
						"foldChange": "1.0263"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".435447491192694",
						"qValue": ".985830071188835",
						"foldChange": "1.7810"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".692884383843751",
						"qValue": ".319509052698889",
						"foldChange": "1.5486"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".679646543410409",
						"qValue": ".25961235604587",
						"foldChange": "0.7646"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".192790825735678",
						"qValue": ".054273110107643",
						"foldChange": "2.2882"
					}
				]
			},
			{
				"metaboliteId": "0078db45-0000-0000-0000-000000000000",
				"chemicalId": "05f5f60f-0000-0000-0000-000000000000",
				"chemicalName": "3-(3-hydroxyphenyl)propionate sulfate",
				"plotName": null,
				"compoundId": "0000b167-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "IQWLPDPKVFZEOK-UHFFFAOYAQ",
				"kegg": null,
				"mass": 246.019809,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4148,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".667422332049412",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".490855488736717",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".40742117529964",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".944110655701949",
						"qValue": "1",
						"foldChange": "2.8850"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".756469286552666",
						"qValue": "1",
						"foldChange": "2.2411"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".703875098619917",
						"qValue": "1",
						"foldChange": "1.6530"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".041571957237451",
						"qValue": ".453560712936804",
						"foldChange": "3.2383"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".314921865441957",
						"qValue": ".825038702902081",
						"foldChange": "2.3405"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".286266863935908",
						"qValue": ".957839854315834",
						"foldChange": "0.9116"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".989268470566828",
						"qValue": "1",
						"foldChange": "1.5741"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".348179880044169",
						"qValue": "1",
						"foldChange": "0.8171"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".341407359505596",
						"qValue": "1",
						"foldChange": "0.8103"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".911276182727289",
						"qValue": "1",
						"foldChange": "1.3104"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".314935030061737",
						"qValue": "1",
						"foldChange": "1.3447"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".256424549364504",
						"qValue": "1",
						"foldChange": "1.0262"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".215293017290795",
						"qValue": "1",
						"foldChange": "2.2007"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".290496177001433",
						"qValue": "1",
						"foldChange": "2.8928"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".903526859631347",
						"qValue": "1",
						"foldChange": "1.3145"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".749165398726555",
						"qValue": "1",
						"foldChange": "1.9504"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".852028467489849",
						"qValue": "1",
						"foldChange": "1.6167"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".905457584727355",
						"qValue": ".997573179629986",
						"foldChange": "0.8289"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002121498858433",
						"qValue": ".004772509861707",
						"foldChange": "9.8689"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00095859914409",
						"qValue": ".002121444126035",
						"foldChange": "11.9604"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000067680966742",
						"qValue": ".000172721742087",
						"foldChange": "6.2052"
					}
				]
			},
			{
				"metaboliteId": "0078db46-0000-0000-0000-000000000000",
				"chemicalId": "00000395-0000-0000-0000-000000000000",
				"chemicalName": "asparagine",
				"plotName": null,
				"compoundId": "00000200-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00168",
				"inChiKey": "DCXYFEDJOCDNAF-REOHCLBHBD",
				"kegg": "C00152",
				"mass": 132.053493,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 39,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".192417925959024",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".427026017853894",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".499351614867354",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".252462853031326",
						"qValue": "1",
						"foldChange": "0.9063"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".982350393020576",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".243594210696056",
						"qValue": "1",
						"foldChange": "2.0486"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".644565528179256",
						"qValue": "1",
						"foldChange": "1.1699"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".084609779233205",
						"qValue": ".697458782960496",
						"foldChange": "0.9482"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".200921107225496",
						"qValue": ".957839854315834",
						"foldChange": "1.3493"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".766629601840585",
						"qValue": "1",
						"foldChange": "0.9360"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".711190998324082",
						"qValue": "1",
						"foldChange": "0.9070"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".941330283892405",
						"qValue": "1",
						"foldChange": "0.9459"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".899048638327991",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".462068524486124",
						"qValue": "1",
						"foldChange": "1.1584"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".528012770171255",
						"qValue": "1",
						"foldChange": "1.0868"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".400681281056361",
						"qValue": "1",
						"foldChange": "1.1817"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".129039266746581",
						"qValue": "1",
						"foldChange": "1.3108"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".453237747761867",
						"qValue": "1",
						"foldChange": "1.1093"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".121735135598333",
						"qValue": "1",
						"foldChange": "0.8744"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".731319996379489",
						"qValue": "1",
						"foldChange": "0.9856"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".065555076962031",
						"qValue": ".985830071188835",
						"foldChange": "1.1272"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00000367888555",
						"qValue": ".00007612777433",
						"foldChange": "7.7066"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000346735659153",
						"qValue": ".001151023864247",
						"foldChange": "8.5328"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000014576304891",
						"qValue": ".000078117294713",
						"foldChange": "6.8292"
					}
				]
			},
			{
				"metaboliteId": "0078db47-0000-0000-0000-000000000000",
				"chemicalId": "0000032b-0000-0000-0000-000000000000",
				"chemicalName": "alanine",
				"plotName": null,
				"compoundId": "00000466-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00161",
				"inChiKey": "QNAYBMKLOCPYGJ-REOHCLBHBS",
				"kegg": "C00041",
				"mass": 89.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 28,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".988787699186186",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".422610086426438",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".217592889862352",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".119229588562315",
						"qValue": "1",
						"foldChange": "0.9447"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".731221650268733",
						"qValue": "1",
						"foldChange": "0.9915"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".220699819202184",
						"qValue": "1",
						"foldChange": "1.0567"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".556361402091335",
						"qValue": "1",
						"foldChange": "1.0305"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".531951470293324",
						"qValue": ".910963185640849",
						"foldChange": "1.0318"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".970493851769267",
						"qValue": "1",
						"foldChange": "1.0096"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".323942114358146",
						"qValue": "1",
						"foldChange": "0.9629"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".048549948852191",
						"qValue": "1",
						"foldChange": "0.9228"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".309372181775937",
						"qValue": "1",
						"foldChange": "0.9112"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".494598176592011",
						"qValue": "1",
						"foldChange": "0.9440"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".918130985628816",
						"qValue": "1",
						"foldChange": "0.9950"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".443707707129913",
						"qValue": "1",
						"foldChange": "1.0541"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".578580478125849",
						"qValue": "1",
						"foldChange": "1.0374"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".695309723041152",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".890622058791336",
						"qValue": "1",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".888502392232708",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".380393885863303",
						"qValue": "1",
						"foldChange": "0.8941"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".444624661150846",
						"qValue": ".985830071188835",
						"foldChange": "0.9042"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".208558297465304",
						"qValue": ".137827724300058",
						"foldChange": "1.1663"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00175577589927",
						"qValue": ".003138409365904",
						"foldChange": "1.3450"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002598578306737",
						"qValue": ".002058073005652",
						"foldChange": "1.2774"
					}
				]
			},
			{
				"metaboliteId": "0078db48-0000-0000-0000-000000000000",
				"chemicalId": "05f5e552-0000-0000-0000-000000000000",
				"chemicalName": "1,3-dimethylurate",
				"plotName": null,
				"compoundId": "00007e87-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01857",
				"inChiKey": "OTSBKHHWSQYEHK-UHFFFAOYAM",
				"kegg": null,
				"mass": 196.05964,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4161,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".171535041447441",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".098223410427045",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".958448205323743",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".753595458360148",
						"qValue": "1",
						"foldChange": "1.6848"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".15933051413058",
						"qValue": "1",
						"foldChange": "1.2442"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".270903417227682",
						"qValue": "1",
						"foldChange": "0.8019"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".317919943108157",
						"qValue": ".940254050726303",
						"foldChange": "0.9442"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".115235452216192",
						"qValue": ".707436023994459",
						"foldChange": "1.1412"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".554680241714461",
						"qValue": ".957839854315834",
						"foldChange": "1.1221"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".976940111849759",
						"qValue": "1",
						"foldChange": "1.2088"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".445557134666997",
						"qValue": "1",
						"foldChange": "0.8319"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".428667161213445",
						"qValue": "1",
						"foldChange": "0.8840"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".761726914319139",
						"qValue": "1",
						"foldChange": "0.8190"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".158729193138359",
						"qValue": "1",
						"foldChange": "1.1670"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".246302723706955",
						"qValue": "1",
						"foldChange": "1.4248"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".966597018721475",
						"qValue": "1",
						"foldChange": "0.6815"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".114701127503203",
						"qValue": "1",
						"foldChange": "1.4526"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".098369675535902",
						"qValue": ".863016431763424",
						"foldChange": "2.1313"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".79979143141048",
						"qValue": "1",
						"foldChange": "1.1205"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".088064536447279",
						"qValue": "1",
						"foldChange": "1.5614"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".130174465265557",
						"qValue": ".985830071188835",
						"foldChange": "1.3934"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000421886782",
						"qValue": ".000027936548762",
						"foldChange": "20.2268"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002673250132424",
						"qValue": ".004095748823636",
						"foldChange": "16.2676"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007931544241148",
						"qValue": ".004563117531926",
						"foldChange": "13.6351"
					}
				]
			},
			{
				"metaboliteId": "0078db49-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9d3-0000-0000-0000-000000000000",
				"chemicalName": "cis-4-decenoylcarnitine (C10:1)",
				"plotName": null,
				"compoundId": "00009522-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 313.225308,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1661,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".355980119689136",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".752245136206728",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".226170085702343",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".527187637856848",
						"qValue": "1",
						"foldChange": "0.9638"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".45830755053284",
						"qValue": "1",
						"foldChange": "1.1747"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".172573978619956",
						"qValue": "1",
						"foldChange": "1.3963"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".523588337679884",
						"qValue": "1",
						"foldChange": "1.1886"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".985252074189317",
						"qValue": "1",
						"foldChange": "1.2133"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".535617475334482",
						"qValue": ".957839854315834",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".399977121374312",
						"qValue": "1",
						"foldChange": "1.2129"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".270844425097826",
						"qValue": "1",
						"foldChange": "0.8556"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".055434385302415",
						"qValue": ".727024127920748",
						"foldChange": "0.7584"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".385552825650691",
						"qValue": "1",
						"foldChange": "0.8590"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".565368694417201",
						"qValue": "1",
						"foldChange": "1.2712"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".15668648879691",
						"qValue": "1",
						"foldChange": "1.4799"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".909322496561143",
						"qValue": "1",
						"foldChange": "0.9196"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".149617751814957",
						"qValue": "1",
						"foldChange": "1.4837"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".113977722808277",
						"qValue": ".908885675694672",
						"foldChange": "1.6135"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".193057900625195",
						"qValue": "1",
						"foldChange": "0.7856"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".594546749311071",
						"qValue": "1",
						"foldChange": "0.9544"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".467890903762055",
						"qValue": ".985830071188835",
						"foldChange": "1.2148"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".741276058152273",
						"qValue": ".330326907870435",
						"foldChange": "1.2394"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".361057672113263",
						"qValue": ".157080662814445",
						"foldChange": "1.3157"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".03225423000307",
						"qValue": ".013646588236352",
						"foldChange": "1.3603"
					}
				]
			},
			{
				"metaboliteId": "0078db4a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9ce-0000-0000-0000-000000000000",
				"chemicalName": "stearoyl ethanolamide",
				"plotName": null,
				"compoundId": "000096e1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13078",
				"inChiKey": "OTGQIQQTPXJQRG-UHFFFAOYAF",
				"kegg": null,
				"mass": 327.31373,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1909,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".576328466234894",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".482767265519934",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".893012383317171",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".585064067061987",
						"qValue": "1",
						"foldChange": "1.0737"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".679300956594037",
						"qValue": "1",
						"foldChange": "1.0725"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".894147594442406",
						"qValue": "1",
						"foldChange": "1.0158"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".931848970850318",
						"qValue": "1",
						"foldChange": "1.0939"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".323939967050213",
						"qValue": ".831655911352372",
						"foldChange": "0.9881"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".284393427675441",
						"qValue": ".957839854315834",
						"foldChange": "0.9314"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".747189965173841",
						"qValue": "1",
						"foldChange": "1.0852"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".569016658108008",
						"qValue": "1",
						"foldChange": "0.9600"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".373848647461923",
						"qValue": "1",
						"foldChange": "0.8923"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".972589597838703",
						"qValue": "1",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".414203133229579",
						"qValue": "1",
						"foldChange": "1.1198"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".421736588993957",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".807501972185115",
						"qValue": "1",
						"foldChange": "0.9997"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".482551723004784",
						"qValue": "1",
						"foldChange": "1.0792"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".341059487143578",
						"qValue": "1",
						"foldChange": "1.0795"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".440489106274876",
						"qValue": "1",
						"foldChange": "0.9261"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".806678296970178",
						"qValue": "1",
						"foldChange": "0.9626"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".32002967485574",
						"qValue": ".985830071188835",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".435587338692292",
						"qValue": ".227833916601623",
						"foldChange": "1.1755"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".416042418389685",
						"qValue": ".176042409350666",
						"foldChange": "1.2841"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".07705830781835",
						"qValue": ".026472479483055",
						"foldChange": "1.3631"
					}
				]
			},
			{
				"metaboliteId": "0078db4b-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebf4-0000-0000-0000-000000000000",
				"chemicalName": "sertraline",
				"plotName": null,
				"compoundId": "00009b4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05010",
				"inChiKey": "VGKDLMBJGBXTGI-SJCJKPOMBV",
				"kegg": "C07246",
				"mass": 305.073805,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5092,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".338697325350823",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".99200157897737",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".217824030520477",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".14098607644822",
						"qValue": ".742487134132407",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".14098607644822",
						"qValue": ".957839854315834",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".128725982218155",
						"qValue": "1",
						"foldChange": "0.9766"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".930867920719498",
						"qValue": "1",
						"foldChange": "0.9985"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".151196244453695",
						"qValue": ".960252848686774",
						"foldChange": "1.0240"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".166980836750519",
						"qValue": "1",
						"foldChange": "1.2623"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".157257575710141",
						"qValue": "1",
						"foldChange": "1.2623"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".813018157456397",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".256018274628482",
						"qValue": "1",
						"foldChange": "1.1687"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".346584846102",
						"qValue": "1",
						"foldChange": "1.1400"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".171245839455318",
						"qValue": "1",
						"foldChange": "1.2291"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".161423594049474",
						"qValue": ".985830071188835",
						"foldChange": "1.2291"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.2291"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078db4c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e89f-0000-0000-0000-000000000000",
				"chemicalName": "bilirubin (E,Z or Z,E)*",
				"plotName": null,
				"compoundId": "0000bb0e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00488",
				"inChiKey": "BPYKTIZUTYGOLE-VVCLLGATBN",
				"kegg": null,
				"mass": 584.263485,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4015,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".493755371177329",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".902743973525243",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".8460106958431",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".337072041078494",
						"qValue": "1",
						"foldChange": "1.0828"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".730130664196073",
						"qValue": "1",
						"foldChange": "1.0574"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".536428999749782",
						"qValue": "1",
						"foldChange": "1.5590"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".685535179034877",
						"qValue": "1",
						"foldChange": "1.2780"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".441927890830069",
						"qValue": ".900630141528144",
						"foldChange": "1.4593"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".714507905816828",
						"qValue": ".976023509428735",
						"foldChange": "1.2745"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".823308222312377",
						"qValue": "1",
						"foldChange": "1.2240"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".961086825504604",
						"qValue": "1",
						"foldChange": "1.0574"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".86144721494907",
						"qValue": "1",
						"foldChange": "0.9947"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".49367794941591",
						"qValue": "1",
						"foldChange": "0.8921"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".220443563351213",
						"qValue": "1",
						"foldChange": "0.7618"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".549368112561336",
						"qValue": "1",
						"foldChange": "0.8539"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".711760870355949",
						"qValue": "1",
						"foldChange": "0.9758"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".730217745027962",
						"qValue": "1",
						"foldChange": "0.7586"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".477772650489201",
						"qValue": "1",
						"foldChange": "0.7774"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".87546630606792",
						"qValue": "1",
						"foldChange": "1.1445"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".349013326960226",
						"qValue": "1",
						"foldChange": "0.6735"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".267563929846136",
						"qValue": ".985830071188835",
						"foldChange": "0.5885"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".201274602936529",
						"qValue": ".134548373216769",
						"foldChange": "0.7913"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".71979418467083",
						"qValue": ".270492211834564",
						"foldChange": "0.9605"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".97228071819802",
						"qValue": ".189085134227061",
						"foldChange": "1.0675"
					}
				]
			},
			{
				"metaboliteId": "0078db4d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f6-0000-0000-0000-000000000000",
				"chemicalName": "myristoylcarnitine (C14)",
				"plotName": null,
				"compoundId": "000084a0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05066",
				"inChiKey": "PSHXNVGSVNEJBD-UHFFFAOYAV",
				"kegg": null,
				"mass": 371.303559,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1646,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".500238512738547",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".583565516837557",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".799378759759294",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".976502554303031",
						"qValue": "1",
						"foldChange": "1.0353"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".692138467720431",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".670595731269706",
						"qValue": "1",
						"foldChange": "1.0846"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".735025393602972",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".897096902797311",
						"qValue": "1",
						"foldChange": "1.0459"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".640173801703873",
						"qValue": ".973034272612281",
						"foldChange": "0.9929"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".159894403077396",
						"qValue": "1",
						"foldChange": "1.2233"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".650913856693064",
						"qValue": "1",
						"foldChange": "1.0919"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".335821294328759",
						"qValue": "1",
						"foldChange": "0.9386"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".401989067052089",
						"qValue": "1",
						"foldChange": "0.8440"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".821071568367601",
						"qValue": "1",
						"foldChange": "0.9173"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".560193256244333",
						"qValue": "1",
						"foldChange": "1.0869"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".524494761128148",
						"qValue": "1",
						"foldChange": "0.9324"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".537672735806301",
						"qValue": "1",
						"foldChange": "1.0834"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".215623868780847",
						"qValue": "1",
						"foldChange": "1.1620"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".256855146264832",
						"qValue": "1",
						"foldChange": "0.8268"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".857203308524306",
						"qValue": "1",
						"foldChange": "0.9556"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".358472738547287",
						"qValue": ".985830071188835",
						"foldChange": "1.1558"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".112649264836122",
						"qValue": ".089656518319548",
						"foldChange": "1.4582"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".009486567157876",
						"qValue": ".009689727074406",
						"foldChange": "1.5315"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007378953107738",
						"qValue": ".004319531299569",
						"foldChange": "1.5679"
					}
				]
			},
			{
				"metaboliteId": "0078db4e-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8a3-0000-0000-0000-000000000000",
				"chemicalName": "myristoleoylcarnitine (C14:1)*",
				"plotName": null,
				"compoundId": "0000bc36-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 369.287909,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1665,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".63454543307388",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".330311177411701",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".63750053106092",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".983166473216226",
						"qValue": "1",
						"foldChange": "1.0991"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".693273257450742",
						"qValue": "1",
						"foldChange": "1.0937"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".677803902379352",
						"qValue": "1",
						"foldChange": "1.2012"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".475515626764683",
						"qValue": "1",
						"foldChange": "1.2993"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".825832232314457",
						"qValue": ".995604502321318",
						"foldChange": "1.1511"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".351702119269764",
						"qValue": ".957839854315834",
						"foldChange": "1.0217"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".134119439351181",
						"qValue": "1",
						"foldChange": "1.4240"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".874601047574634",
						"qValue": "1",
						"foldChange": "1.0342"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".098973470994184",
						"qValue": ".859453304245417",
						"foldChange": "0.8186"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".477943842195546",
						"qValue": "1",
						"foldChange": "0.8387"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".810664513682667",
						"qValue": "1",
						"foldChange": "0.8992"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".657555691695272",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".827228594938426",
						"qValue": "1",
						"foldChange": "0.9800"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".399393070962656",
						"qValue": "1",
						"foldChange": "1.1846"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".284015420167247",
						"qValue": "1",
						"foldChange": "1.2087"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".258871748154043",
						"qValue": "1",
						"foldChange": "0.7888"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".539852375126532",
						"qValue": "1",
						"foldChange": "0.8981"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".637314085239026",
						"qValue": ".985830071188835",
						"foldChange": "1.1385"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".307367551514769",
						"qValue": ".181809183403729",
						"foldChange": "1.2702"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".077606571658873",
						"qValue": ".051159140240915",
						"foldChange": "1.4468"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".097535211232446",
						"qValue": ".03219982217757",
						"foldChange": "1.3463"
					}
				]
			},
			{
				"metaboliteId": "0078db4f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e66f-0000-0000-0000-000000000000",
				"chemicalName": "stearoylcarnitine (C18)",
				"plotName": null,
				"compoundId": "00008669-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00848",
				"inChiKey": "FNPHNLNTJNMAEE-HSZRJFAPBW",
				"kegg": null,
				"mass": 427.366159,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1650,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".525176336659396",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".677132052321262",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".798818670248547",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".224482970274049",
						"qValue": "1",
						"foldChange": "1.1874"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".440256671402006",
						"qValue": "1",
						"foldChange": "1.0958"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".653062556503896",
						"qValue": "1",
						"foldChange": "1.0104"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".73656593990411",
						"qValue": "1",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".599988964090772",
						"qValue": ".928868848501546",
						"foldChange": "0.9944"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".850457069618887",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".562938022703933",
						"qValue": "1",
						"foldChange": "1.0749"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".530503253457903",
						"qValue": "1",
						"foldChange": "1.1043"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".960891838479842",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".987140257818808",
						"qValue": "1",
						"foldChange": "1.0145"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".611759306783073",
						"qValue": "1",
						"foldChange": "1.1031"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".592347719445297",
						"qValue": "1",
						"foldChange": "1.0873"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".321437708468161",
						"qValue": "1",
						"foldChange": "0.8244"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".887169174056203",
						"qValue": "1",
						"foldChange": "0.9499"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".269895156376748",
						"qValue": "1",
						"foldChange": "1.1523"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".411225438246597",
						"qValue": "1",
						"foldChange": "0.9061"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".657628393377312",
						"qValue": "1",
						"foldChange": "1.0613"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".214255668465859",
						"qValue": ".985830071188835",
						"foldChange": "1.1712"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".043475537265477",
						"qValue": ".045408016745153",
						"foldChange": "1.5941"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".099940631477817",
						"qValue": ".061248548176447",
						"foldChange": "1.2756"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000034806833633",
						"qValue": ".000126040201651",
						"foldChange": "1.8709"
					}
				]
			},
			{
				"metaboliteId": "0078db50-0000-0000-0000-000000000000",
				"chemicalId": "05f5e670-0000-0000-0000-000000000000",
				"chemicalName": "laurylcarnitine (C12)",
				"plotName": null,
				"compoundId": "000086e6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02250",
				"inChiKey": "FUJLYHJROOYKRA-UHFFFAOYAK",
				"kegg": null,
				"mass": 343.272259,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1645,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".251358579541349",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".794739698653082",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".535072822020283",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".745608923948695",
						"qValue": "1",
						"foldChange": "1.0397"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".447886569201208",
						"qValue": "1",
						"foldChange": "1.1576"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".280488647250369",
						"qValue": "1",
						"foldChange": "1.3404"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".753260068991599",
						"qValue": "1",
						"foldChange": "1.1336"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".777912258138273",
						"qValue": ".976406093916918",
						"foldChange": "1.1324"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".551473162758854",
						"qValue": ".957839854315834",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".27885885226463",
						"qValue": "1",
						"foldChange": "1.3120"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".84200410322799",
						"qValue": "1",
						"foldChange": "1.0366"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".201277088177254",
						"qValue": "1",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".417061999129808",
						"qValue": "1",
						"foldChange": "0.8552"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".688377987857927",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".233829054039516",
						"qValue": "1",
						"foldChange": "1.2887"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".695394929985439",
						"qValue": "1",
						"foldChange": "0.9123"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".181097904949029",
						"qValue": "1",
						"foldChange": "1.3301"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".083004675614496",
						"qValue": ".834952786556829",
						"foldChange": "1.4580"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".135310259293255",
						"qValue": "1",
						"foldChange": "0.7540"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".823847021108352",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".219978543917086",
						"qValue": ".985830071188835",
						"foldChange": "1.3094"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".310556342679726",
						"qValue": ".182957798755356",
						"foldChange": "1.3040"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".387360914346906",
						"qValue": ".166688599430696",
						"foldChange": "1.2802"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".128062849976729",
						"qValue": ".039322399916596",
						"foldChange": "1.2651"
					}
				]
			},
			{
				"metaboliteId": "0078db51-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea14-0000-0000-0000-000000000000",
				"chemicalName": "S-carboxymethyl-L-cysteine",
				"plotName": null,
				"compoundId": "0000bb19-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29415",
				"inChiKey": "GBFLZEXEOZUWRN-UHFFFAOYAN",
				"kegg": "C03727",
				"mass": 179.025228,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Other",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5174,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".613777215089851",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".910499673373941",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".392966337989319",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".242132078033586",
						"qValue": "1",
						"foldChange": "1.9632"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".480790358256589",
						"qValue": "1",
						"foldChange": "2.1530"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".637850384691217",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".158041437494454",
						"qValue": ".742487134132407",
						"foldChange": "0.9105"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".938850458735459",
						"qValue": "1",
						"foldChange": "1.1697"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".137302239936202",
						"qValue": ".957839854315834",
						"foldChange": "1.6144"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".835541534185714",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".688510555468634",
						"qValue": "1",
						"foldChange": "1.0038"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".846543087017518",
						"qValue": "1",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".230841680649617",
						"qValue": "1",
						"foldChange": "1.3675"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".179767574682258",
						"qValue": "1",
						"foldChange": "1.4775"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".827063837549649",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".672503117253341",
						"qValue": "1",
						"foldChange": "0.8000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".614883778719441",
						"qValue": "1",
						"foldChange": "0.8886"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".356884750617831",
						"qValue": "1",
						"foldChange": "1.1108"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".425523465101911",
						"qValue": "1",
						"foldChange": "1.4541"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".506555711070455",
						"qValue": "1",
						"foldChange": "1.0617"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".926197747963653",
						"qValue": ".997573179629986",
						"foldChange": "0.7301"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".160131801293797",
						"qValue": ".115256810126241",
						"foldChange": "0.7335"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".794148374286977",
						"qValue": ".287591490864831",
						"foldChange": "1.1154"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".270274952920852",
						"qValue": ".069749124296225",
						"foldChange": "0.8265"
					}
				]
			},
			{
				"metaboliteId": "0078db52-0000-0000-0000-000000000000",
				"chemicalId": "05f5e969-0000-0000-0000-000000000000",
				"chemicalName": "betonicine",
				"plotName": null,
				"compoundId": "000094d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29412",
				"inChiKey": "MUNWAHDYFVYIKH-RITPCOANBW",
				"kegg": "C08269",
				"mass": 159.089543,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4311,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".172839727714766",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".107460501124679",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".532341840160047",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".617423036212991",
						"qValue": "1",
						"foldChange": "3.3774"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".997482581988779",
						"qValue": "1",
						"foldChange": "2.4696"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".6152113850864",
						"qValue": "1",
						"foldChange": "2.4286"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".113760247671975",
						"qValue": ".678406740976754",
						"foldChange": "0.6708"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".032285237261877",
						"qValue": ".678234398444917",
						"foldChange": "0.6991"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".555290101257262",
						"qValue": ".957839854315834",
						"foldChange": "1.2718"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".355054608393449",
						"qValue": "1",
						"foldChange": "0.7042"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".123617841519378",
						"qValue": "1",
						"foldChange": "1.3590"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".529457960585538",
						"qValue": "1",
						"foldChange": "3.6926"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".618716701081932",
						"qValue": "1",
						"foldChange": "2.3567"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".07745978695321",
						"qValue": "1",
						"foldChange": "3.9601"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".178985734150133",
						"qValue": "1",
						"foldChange": "1.6803"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".358819114297365",
						"qValue": "1",
						"foldChange": "0.2181"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".420274793366453",
						"qValue": "1",
						"foldChange": "0.4389"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".090167096602138",
						"qValue": ".834952786556829",
						"foldChange": "2.0127"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".338410287063752",
						"qValue": "1",
						"foldChange": "0.8217"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".485642379360823",
						"qValue": "1",
						"foldChange": "1.4478"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".104293577928311",
						"qValue": ".985830071188835",
						"foldChange": "1.7620"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008990829880323",
						"qValue": ".013910183059419",
						"foldChange": "5.8975"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".954622631462428",
						"qValue": ".326617329430401",
						"foldChange": "2.2231"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".151423570191343",
						"qValue": ".044588395484966",
						"foldChange": "2.8013"
					}
				]
			},
			{
				"metaboliteId": "0078db53-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5b8-0000-0000-0000-000000000000",
				"chemicalName": "1-methyl-4-imidazoleacetate",
				"plotName": null,
				"compoundId": "00007e5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02820",
				"inChiKey": "ZHCKPJGJQOPTLB-UHFFFAOYAW",
				"kegg": "C05828",
				"mass": 140.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 86,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".728800565206086",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".789505396423708",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".474859728137818",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".122344390684775",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".959081487878448",
						"qValue": "1",
						"foldChange": "1.0018"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".134775195305638",
						"qValue": "1",
						"foldChange": "1.0984"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".800160525321612",
						"qValue": "1",
						"foldChange": "1.0247"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".667426155611777",
						"qValue": ".93517659928016",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495455580428013",
						"qValue": ".957839854315834",
						"foldChange": "0.9763"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".85435809137576",
						"qValue": "1",
						"foldChange": "1.0166"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".681005462612642",
						"qValue": "1",
						"foldChange": "0.9900"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".552547264050754",
						"qValue": "1",
						"foldChange": "0.9217"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".64890929338952",
						"qValue": "1",
						"foldChange": "1.0907"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".862446167596278",
						"qValue": "1",
						"foldChange": "0.9717"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".537031573125458",
						"qValue": "1",
						"foldChange": "0.8909"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".238921466869366",
						"qValue": "1",
						"foldChange": "1.2027"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".614495433996619",
						"qValue": "1",
						"foldChange": "1.0730"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".525367593568618",
						"qValue": "1",
						"foldChange": "0.8922"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".756234390569724",
						"qValue": "1",
						"foldChange": "1.0676"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".747870858882847",
						"qValue": "1",
						"foldChange": "0.8988"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".529738535401521",
						"qValue": ".985830071188835",
						"foldChange": "0.8419"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000044237443615",
						"qValue": ".000320994653109",
						"foldChange": "2.0334"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000808042211043",
						"qValue": ".002007776002987",
						"foldChange": "1.8917"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001022153478936",
						"qValue": ".001067127706614",
						"foldChange": "1.7482"
					}
				]
			},
			{
				"metaboliteId": "0078db54-0000-0000-0000-000000000000",
				"chemicalId": "05f5eb9d-0000-0000-0000-000000000000",
				"chemicalName": "hydroxycotinine",
				"plotName": null,
				"compoundId": "00009705-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01390",
				"inChiKey": "XOKCJXZZNAUIQN-IENPIDJEBV",
				"kegg": null,
				"mass": 192.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Tobacco Metabolite",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".458578723492029",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".274440941008831",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".330946671942499",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".990707664911666",
						"qValue": "1",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".90023026194423",
						"qValue": "1",
						"foldChange": "0.9881"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".909454515498598",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".028122988058084",
						"qValue": ".821720595261736",
						"foldChange": "1.7289"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".028122988058084",
						"qValue": ".614826369149758",
						"foldChange": "0.9052"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".241481389466734",
						"qValue": "1",
						"foldChange": "0.3301"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".267439846345599",
						"qValue": "1",
						"foldChange": "0.3301"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".243067387794979",
						"qValue": "1",
						"foldChange": "0.3096"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".745422921976161",
						"qValue": "1",
						"foldChange": "0.5101"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".419609742946227",
						"qValue": "1",
						"foldChange": "1.6479"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".258962427741134",
						"qValue": "1",
						"foldChange": "0.3332"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".285207690622472",
						"qValue": "1",
						"foldChange": "0.3332"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "3.0015"
					}
				]
			},
			{
				"metaboliteId": "0078db55-0000-0000-0000-000000000000",
				"chemicalId": "05f5e51f-0000-0000-0000-000000000000",
				"chemicalName": "isobutyrylcarnitine (C4)",
				"plotName": null,
				"compoundId": "000082a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00736",
				"inChiKey": null,
				"kegg": null,
				"mass": 231.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 363,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".192934454995285",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".471699247954701",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".369351048230457",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".157442684187918",
						"qValue": "1",
						"foldChange": "1.3337"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".152229474713492",
						"qValue": "1",
						"foldChange": "1.3233"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".985193860877586",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".244649637370429",
						"qValue": ".861146427249076",
						"foldChange": "1.0255"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".862153935599309",
						"qValue": "1",
						"foldChange": "1.2364"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".320739028574119",
						"qValue": ".957839854315834",
						"foldChange": "1.2371"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".311597792019615",
						"qValue": "1",
						"foldChange": "0.8612"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".950181885815834",
						"qValue": "1",
						"foldChange": "1.0253"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".283139305946413",
						"qValue": "1",
						"foldChange": "1.1368"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".360025516775132",
						"qValue": "1",
						"foldChange": "1.2812"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".572953088406688",
						"qValue": "1",
						"foldChange": "0.7179"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".146689227591523",
						"qValue": "1",
						"foldChange": "0.5603"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".449754484691136",
						"qValue": "1",
						"foldChange": "0.8357"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".037777395235636",
						"qValue": "1",
						"foldChange": "0.5418"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".152364643342374",
						"qValue": "1",
						"foldChange": "0.6483"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".884653217754988",
						"qValue": "1",
						"foldChange": "0.8580"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".158330075066613",
						"qValue": "1",
						"foldChange": "0.5229"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".190740209942976",
						"qValue": ".985830071188835",
						"foldChange": "0.6095"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".124453186736735",
						"qValue": ".09627401657465",
						"foldChange": "1.3915"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005394002744198",
						"qValue": ".006775218329365",
						"foldChange": "2.3586"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".193409631429619",
						"qValue": ".054273110107643",
						"foldChange": "1.6739"
					}
				]
			},
			{
				"metaboliteId": "0078db56-0000-0000-0000-000000000000",
				"chemicalId": "05f5f607-0000-0000-0000-000000000000",
				"chemicalName": "N-methylpipecolate",
				"plotName": null,
				"compoundId": "0000b7fd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BPSLZWSRHTULGU-ZCFIWIBFBM",
				"kegg": null,
				"mass": 143.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Bacterial/Fungal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4742,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".732945585961305",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".898261991596211",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".86129725218868",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".408575946240353",
						"qValue": "1",
						"foldChange": "0.9460"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".871611643936786",
						"qValue": "1",
						"foldChange": "1.0067"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".505292208300046",
						"qValue": "1",
						"foldChange": "1.0959"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".528555549049021",
						"qValue": "1",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".618379646990286",
						"qValue": ".928868848501546",
						"foldChange": "1.0434"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".894415419575445",
						"qValue": "1",
						"foldChange": "1.0504"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".552826082689907",
						"qValue": "1",
						"foldChange": "1.1078"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".770018688232745",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".762648810861312",
						"qValue": "1",
						"foldChange": "0.8815"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".788694758320591",
						"qValue": "1",
						"foldChange": "0.7355"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".73618618638566",
						"qValue": "1",
						"foldChange": "1.0561"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".546414413465188",
						"qValue": "1",
						"foldChange": "1.4360"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".832629749194417",
						"qValue": "1",
						"foldChange": "0.7636"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".499882974562268",
						"qValue": "1",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".372380182665832",
						"qValue": "1",
						"foldChange": "1.4569"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".730384368580637",
						"qValue": "1",
						"foldChange": "0.6223"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655293026778053",
						"qValue": "1",
						"foldChange": "0.7440"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".430932929011929",
						"qValue": ".985830071188835",
						"foldChange": "1.1956"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".868375575968501",
						"qValue": ".363477853700077",
						"foldChange": "1.2009"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".871511759783768",
						"qValue": ".306453912600224",
						"foldChange": "1.0308"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".100360968461857",
						"qValue": ".032862784360719",
						"foldChange": "3.1379"
					}
				]
			},
			{
				"metaboliteId": "0078db57-0000-0000-0000-000000000000",
				"chemicalId": "05f5e674-0000-0000-0000-000000000000",
				"chemicalName": "7-methylxanthine",
				"plotName": null,
				"compoundId": "00008656-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01991",
				"inChiKey": "PFWLFWPASULGAN-UHFFFAOYAY",
				"kegg": "C16353",
				"mass": 166.049075,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4167,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".267707126608061",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".568008119945231",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".488629795600941",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".341220610291542",
						"qValue": "1",
						"foldChange": "1.9775"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".728644152514662",
						"qValue": "1",
						"foldChange": "2.0102"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".543206115246493",
						"qValue": "1",
						"foldChange": "3.3759"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".734741538211611",
						"qValue": "1",
						"foldChange": "1.8624"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".911454750935891",
						"qValue": "1",
						"foldChange": "1.3951"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".652881985501307",
						"qValue": ".973034272612281",
						"foldChange": "1.0637"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".975603270716122",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".132547440212019",
						"qValue": "1",
						"foldChange": "2.2354"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".125122443588245",
						"qValue": ".952073565941074",
						"foldChange": "2.2842"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".976774046381934",
						"qValue": "1",
						"foldChange": "1.3680"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".524221337658343",
						"qValue": "1",
						"foldChange": "1.2538"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".496298324474534",
						"qValue": "1",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".367351236449629",
						"qValue": "1",
						"foldChange": "1.6661"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".205404284762078",
						"qValue": "1",
						"foldChange": "1.7757"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".666624669896672",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".882696282563964",
						"qValue": "1",
						"foldChange": "1.3134"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".050588602074841",
						"qValue": "1",
						"foldChange": "2.4128"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".062059432751599",
						"qValue": ".985830071188835",
						"foldChange": "1.8371"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".034187307142361",
						"qValue": ".037730319038261",
						"foldChange": "2.6422"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".361090155662896",
						"qValue": ".157080662814445",
						"foldChange": "1.4174"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".153202612349787",
						"qValue": ".045029782588726",
						"foldChange": "1.4800"
					}
				]
			},
			{
				"metaboliteId": "0078db58-0000-0000-0000-000000000000",
				"chemicalId": "05f5e683-0000-0000-0000-000000000000",
				"chemicalName": "beta-guanidinopropanoate",
				"plotName": null,
				"compoundId": "0000891d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13222",
				"inChiKey": "KMXXSJLYVJEBHI-UHFFFAOYAJ",
				"kegg": "C03065",
				"mass": 131.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".652475805262471",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".710761543964835",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".01037073211449",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".165440073377074",
						"qValue": "1",
						"foldChange": "0.8658"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".063106447204809",
						"qValue": "1",
						"foldChange": "0.7037"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".623488854064064",
						"qValue": "1",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".938845218025767",
						"qValue": "1",
						"foldChange": "1.3089"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".043415202388144",
						"qValue": ".678234398444917",
						"foldChange": "0.9537"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".036484553775701",
						"qValue": ".6465497153849",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".71683917585715",
						"qValue": "1",
						"foldChange": "1.2761"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".02494062422104",
						"qValue": ".884352967171025",
						"foldChange": "1.8238"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".057021827671333",
						"qValue": ".727024127920748",
						"foldChange": "1.6842"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".444124764593086",
						"qValue": "1",
						"foldChange": "0.7319"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".041675558142645",
						"qValue": "1",
						"foldChange": "0.4888"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".168177699936383",
						"qValue": "1",
						"foldChange": "0.6678"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".816328490060251",
						"qValue": "1",
						"foldChange": "0.9511"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".349623731161344",
						"qValue": "1",
						"foldChange": "0.6132"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".238565730167457",
						"qValue": "1",
						"foldChange": "0.6448"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".418512380416521",
						"qValue": "1",
						"foldChange": "0.8844"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".487873559262331",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".138765561779227",
						"qValue": ".985830071188835",
						"foldChange": "1.2094"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".493339153688512",
						"qValue": ".248237008726345",
						"foldChange": "1.2250"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".348933810524187",
						"qValue": ".153954036841707",
						"foldChange": "0.7936"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".78857328278132",
						"qValue": ".158479494613827",
						"foldChange": "0.9909"
					}
				]
			},
			{
				"metaboliteId": "0078db59-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef38-0000-0000-0000-000000000000",
				"chemicalName": "valylglutamine",
				"plotName": null,
				"compoundId": "0000a45f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29125",
				"inChiKey": "XXDVDTMEVBYRPK-UHFFFAOYAL",
				"kegg": null,
				"mass": 245.137556,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 938,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".316090437050996",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".721387647987015",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".353912026468659",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".892436862203036",
						"qValue": "1",
						"foldChange": "1.6555"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".848797784541617",
						"qValue": "1",
						"foldChange": "1.0848"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".744619805438312",
						"qValue": "1",
						"foldChange": "1.2186"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".608273043667499",
						"qValue": "1",
						"foldChange": "1.0911"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".505848907332043",
						"qValue": ".905893288986492",
						"foldChange": "1.3146"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".8779266825357",
						"qValue": "1",
						"foldChange": "1.1878"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".661924916479407",
						"qValue": "1",
						"foldChange": "1.4324"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".053273945971101",
						"qValue": "1",
						"foldChange": "2.0037"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".1297486677912",
						"qValue": ".960252848686774",
						"foldChange": "1.6907"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".838793468598312",
						"qValue": "1",
						"foldChange": "0.9181"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".912522179951118",
						"qValue": "1",
						"foldChange": "0.9094"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".932622685017729",
						"qValue": "1",
						"foldChange": "0.9905"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".457145225000924",
						"qValue": "1",
						"foldChange": "0.7480"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".872072255188025",
						"qValue": "1",
						"foldChange": "1.0458"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".376683282055487",
						"qValue": "1",
						"foldChange": "1.3981"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".556870809332148",
						"qValue": "1",
						"foldChange": "0.8957"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".080653311777818",
						"qValue": "1",
						"foldChange": "1.8566"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".019827565819183",
						"qValue": ".985830071188835",
						"foldChange": "2.0728"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".151495579601365",
						"qValue": ".110481852241634",
						"foldChange": "1.7230"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".536674107509537",
						"qValue": ".214397800817779",
						"foldChange": "0.7036"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".695101389583086",
						"qValue": ".144014904625273",
						"foldChange": "0.9709"
					}
				]
			},
			{
				"metaboliteId": "0078db5a-0000-0000-0000-000000000000",
				"chemicalId": "000001cd-0000-0000-0000-000000000000",
				"chemicalName": "phosphate",
				"plotName": null,
				"compoundId": "0000a47d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01429",
				"inChiKey": "NBIIXXVUZAFLBC-DFZHHIFOAA",
				"kegg": "C00009",
				"mass": 97.976898,
				"pathwayType": "ANIMAL",
				"subPathway": "Oxidative Phosphorylation",
				"superPathway": "Energy",
				"pathwaySortOrder": 1310,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".704650933191129",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".371064099771931",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".876223583871786",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".745152160841632",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".464052056340277",
						"qValue": "1",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".682774225985451",
						"qValue": "1",
						"foldChange": "1.0313"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".534788368520935",
						"qValue": "1",
						"foldChange": "1.0541"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".536360900051228",
						"qValue": ".910963185640849",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".998083241128525",
						"qValue": "1",
						"foldChange": "1.0037"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".170973102860987",
						"qValue": "1",
						"foldChange": "1.0904"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".701003451403778",
						"qValue": "1",
						"foldChange": "1.0296"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".320591730148136",
						"qValue": "1",
						"foldChange": "0.9085"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".916438523616393",
						"qValue": "1",
						"foldChange": "1.0021"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".646335753178016",
						"qValue": "1",
						"foldChange": "1.0224"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".567943179310563",
						"qValue": "1",
						"foldChange": "1.0202"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".964924799717265",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".290034164642824",
						"qValue": "1",
						"foldChange": "1.0694"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".297486688659986",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".851469713937627",
						"qValue": "1",
						"foldChange": "0.9947"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".775119020923504",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".635857210682259",
						"qValue": ".985830071188835",
						"foldChange": "0.9710"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006177954113751",
						"qValue": ".010709227622958",
						"foldChange": "0.6678"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000431014606484",
						"qValue": ".001350414484601",
						"foldChange": "0.6912"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00248807335123",
						"qValue": ".00200011142084",
						"foldChange": "0.7384"
					}
				]
			},
			{
				"metaboliteId": "0078db5b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cf-0000-0000-0000-000000000000",
				"chemicalName": "tryptophan betaine",
				"plotName": null,
				"compoundId": "000090e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61115",
				"inChiKey": "AOHCBEAZXHZMOR-ZDUSSCGKBF",
				"kegg": "C09213",
				"mass": 246.136828,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 238,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".582770910903078",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".063390766617186",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".005108042059383",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".629134822228127",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".183423291116721",
						"qValue": "1",
						"foldChange": "1.3104"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".072582041959066",
						"qValue": "1",
						"foldChange": "1.3470"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".002445487102193",
						"qValue": ".138740634931099",
						"foldChange": "0.7410"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".00008076370874",
						"qValue": ".017182479034363",
						"foldChange": "0.6822"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".272318683779447",
						"qValue": ".957839854315834",
						"foldChange": "0.8959"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".611530885089",
						"qValue": "1",
						"foldChange": "0.9379"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".827086451650194",
						"qValue": "1",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".771850055833526",
						"qValue": "1",
						"foldChange": "0.9950"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".220579105949898",
						"qValue": "1",
						"foldChange": "1.4250"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".250472943397372",
						"qValue": "1",
						"foldChange": "1.4390"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".990860006462451",
						"qValue": "1",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".691569553518742",
						"qValue": "1",
						"foldChange": "1.3476"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".257545952469454",
						"qValue": "1",
						"foldChange": "1.4899"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".43286614630617",
						"qValue": "1",
						"foldChange": "1.1056"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".576330023581906",
						"qValue": "1",
						"foldChange": "1.1179"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".506366470951745",
						"qValue": "1",
						"foldChange": "1.3653"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".226330438209639",
						"qValue": ".985830071188835",
						"foldChange": "1.2213"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".121712647665943",
						"qValue": ".095266932374809",
						"foldChange": "2.0487"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".735558727019803",
						"qValue": ".273842367601437",
						"foldChange": "1.8276"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".097943396473958",
						"qValue": ".032268319177367",
						"foldChange": "1.7335"
					}
				]
			},
			{
				"metaboliteId": "0078db5c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e93a-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/18:1, d18:2/18:0)",
				"plotName": null,
				"compoundId": "00009299-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12101",
				"inChiKey": "NBEADXWAAWCCDG-QDDWGVBQBP",
				"kegg": null,
				"mass": 728.583225,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2807,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".596236920667289",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".26412063847362",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".216648220145552",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".547685506610866",
						"qValue": "1",
						"foldChange": "1.0516"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".191017340087501",
						"qValue": "1",
						"foldChange": "1.0994"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".47454245703477",
						"qValue": "1",
						"foldChange": "1.0463"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".959164386555143",
						"qValue": "1",
						"foldChange": "1.0129"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".506153188501971",
						"qValue": ".905893288986492",
						"foldChange": "1.0604"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".539238130203569",
						"qValue": ".957839854315834",
						"foldChange": "1.0510"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".044486272447253",
						"qValue": ".821720595261736",
						"foldChange": "1.1357"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".885200026050716",
						"qValue": "1",
						"foldChange": "1.0014"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".032000407940657",
						"qValue": ".614826369149758",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".624312690488148",
						"qValue": "1",
						"foldChange": "0.9487"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".879640363318548",
						"qValue": "1",
						"foldChange": "0.9842"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".529647381494304",
						"qValue": "1",
						"foldChange": "1.0375"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".437087598701631",
						"qValue": "1",
						"foldChange": "0.9418"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".354758517312384",
						"qValue": "1",
						"foldChange": "1.1739"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".093943881807849",
						"qValue": ".841539404405048",
						"foldChange": "1.2465"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".397568348803002",
						"qValue": "1",
						"foldChange": "0.9386"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".564470146005264",
						"qValue": "1",
						"foldChange": "0.9673"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".816562559597426",
						"qValue": ".997573179629986",
						"foldChange": "1.0306"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".72197036491845",
						"qValue": ".326108587297259",
						"foldChange": "0.9505"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".695445620125778",
						"qValue": ".26456007671219",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".114524707378659",
						"qValue": ".036245638267018",
						"foldChange": "1.1468"
					}
				]
			},
			{
				"metaboliteId": "0078db5d-0000-0000-0000-000000000000",
				"chemicalId": "05f603e0-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxybutyrate/2-hydroxyisobutyrate",
				"plotName": null,
				"compoundId": "0000cc39-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 104.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 502,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".604891096597189",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".84601452233961",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".402925803860581",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".539050785920949",
						"qValue": "1",
						"foldChange": "0.9993"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".550782041089552",
						"qValue": "1",
						"foldChange": "0.9524"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".985828220185505",
						"qValue": "1",
						"foldChange": "1.0702"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".615798722401165",
						"qValue": "1",
						"foldChange": "1.1485"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".517047758805596",
						"qValue": ".908244770314396",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".252663600734376",
						"qValue": ".957839854315834",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".285728294925469",
						"qValue": "1",
						"foldChange": "1.2667"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".122456651703737",
						"qValue": "1",
						"foldChange": "1.3527"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".62447008650534",
						"qValue": "1",
						"foldChange": "1.1362"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".28441853722768",
						"qValue": "1",
						"foldChange": "0.7517"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".190816355353657",
						"qValue": "1",
						"foldChange": "0.6750"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".756026863817699",
						"qValue": "1",
						"foldChange": "0.8979"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".646597143412613",
						"qValue": "1",
						"foldChange": "0.9457"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".702582547124519",
						"qValue": "1",
						"foldChange": "0.8042"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".957559313458627",
						"qValue": "1",
						"foldChange": "0.8503"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".279879563542866",
						"qValue": "1",
						"foldChange": "0.7790"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".91020860866802",
						"qValue": "1",
						"foldChange": "0.8716"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".350301679689335",
						"qValue": ".985830071188835",
						"foldChange": "1.1189"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".139989215173506",
						"qValue": ".1046255348173",
						"foldChange": "0.7571"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".19361741354059",
						"qValue": ".101103982627117",
						"foldChange": "0.7719"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".59903843514238",
						"qValue": ".126891905178055",
						"foldChange": "0.9061"
					}
				]
			},
			{
				"metaboliteId": "0078db5e-0000-0000-0000-000000000000",
				"chemicalId": "05f603f8-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoylglycerol (16:1)*",
				"plotName": null,
				"compoundId": "0000cccf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11565",
				"inChiKey": null,
				"kegg": null,
				"mass": 328.261353,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2554,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".864352847777011",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".142752138997071",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".829272300513761",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".274289407823906",
						"qValue": "1",
						"foldChange": "2.6305"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".58696727906293",
						"qValue": "1",
						"foldChange": "1.3811"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".578786126209482",
						"qValue": "1",
						"foldChange": "2.5327"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".320408729659704",
						"qValue": ".942586781423443",
						"foldChange": "1.9873"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".301243993072271",
						"qValue": ".825038702902081",
						"foldChange": "1.8472"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".967450594421119",
						"qValue": "1",
						"foldChange": "1.5518"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".565558719629829",
						"qValue": "1",
						"foldChange": "1.5619"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".103788437338225",
						"qValue": "1",
						"foldChange": "2.9484"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".285696077348715",
						"qValue": "1",
						"foldChange": "1.7026"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".944369585959555",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".588061805640148",
						"qValue": "1",
						"foldChange": "0.6816"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".625903772753802",
						"qValue": "1",
						"foldChange": "0.6497"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".853108739637598",
						"qValue": "1",
						"foldChange": "0.6461"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".37396802914919",
						"qValue": "1",
						"foldChange": "0.3700"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".463558308017766",
						"qValue": "1",
						"foldChange": "0.5726"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".791375800175723",
						"qValue": "1",
						"foldChange": "1.2502"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".749311165547921",
						"qValue": "1",
						"foldChange": "0.8592"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".943248273620682",
						"qValue": ".997575444606604",
						"foldChange": "0.6872"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".045151002709679",
						"qValue": ".046425689911801",
						"foldChange": "0.5198"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".063492336410048",
						"qValue": ".043932039832042",
						"foldChange": "0.7004"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".07935011846293",
						"qValue": ".026971645591647",
						"foldChange": "0.6525"
					}
				]
			},
			{
				"metaboliteId": "0078db5f-0000-0000-0000-000000000000",
				"chemicalId": "05f5faf1-0000-0000-0000-000000000000",
				"chemicalName": "glycochenodeoxycholate 3-sulfate",
				"plotName": null,
				"compoundId": "0000ceee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 529.270938,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3108,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".141265052088801",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".254783375130449",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".19355470419922",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".553198876564868",
						"qValue": "1",
						"foldChange": "3.7571"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".15441761604578",
						"qValue": "1",
						"foldChange": "3.1496"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".399681808981274",
						"qValue": "1",
						"foldChange": "2.2568"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".148083338551446",
						"qValue": ".742487134132407",
						"foldChange": "4.8100"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".768488854616973",
						"qValue": ".97174445063751",
						"foldChange": "2.8733"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".083749387252658",
						"qValue": ".796492767737576",
						"foldChange": "0.6981"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".983357355311396",
						"qValue": "1",
						"foldChange": "6.6561"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".12213687267631",
						"qValue": "1",
						"foldChange": "8.8369"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".127068045097745",
						"qValue": ".956946074143198",
						"foldChange": "4.0278"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".143683609248043",
						"qValue": "1",
						"foldChange": "2.8414"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".86516521227118",
						"qValue": "1",
						"foldChange": "0.6390"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".112775178652202",
						"qValue": "1",
						"foldChange": "0.2249"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".038727580788804",
						"qValue": "1",
						"foldChange": "3.0189"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".548832059880149",
						"qValue": "1",
						"foldChange": "1.1296"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".009977413928475",
						"qValue": ".653136865625524",
						"foldChange": "0.3742"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".914010049039347",
						"qValue": "1",
						"foldChange": "2.0127"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".997298221546771",
						"qValue": "1",
						"foldChange": "1.2920"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".913944421992423",
						"qValue": ".997573179629986",
						"foldChange": "0.6419"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".054665231085608",
						"qValue": ".052767182633602",
						"foldChange": "0.4429"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".183532504314128",
						"qValue": ".097853509895055",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".360783421568713",
						"qValue": ".085876271639878",
						"foldChange": "0.4415"
					}
				]
			},
			{
				"metaboliteId": "0078db60-0000-0000-0000-000000000000",
				"chemicalId": "05f60511-0000-0000-0000-000000000000",
				"chemicalName": "palmitoylcholine",
				"plotName": null,
				"compoundId": "0000ced0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 341.32938,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1714,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".781810875381532",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".26638922188272",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".071779980003784",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".665461615517412",
						"qValue": "1",
						"foldChange": "1.9467"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".197121197279375",
						"qValue": "1",
						"foldChange": "2.2372"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".387076568260032",
						"qValue": "1",
						"foldChange": "3.6432"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".140572506152902",
						"qValue": ".742487134132407",
						"foldChange": "0.8126"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".279965504871562",
						"qValue": ".825038702902081",
						"foldChange": "1.2034"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".686775010018064",
						"qValue": ".976023509428735",
						"foldChange": "2.5423"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".222791067175742",
						"qValue": "1",
						"foldChange": "0.9543"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".00856485695683",
						"qValue": ".522751459721039",
						"foldChange": "0.3930"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".138503583458861",
						"qValue": ".960252848686774",
						"foldChange": "0.6084"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".374461487197933",
						"qValue": "1",
						"foldChange": "1.6748"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".275635134031228",
						"qValue": "1",
						"foldChange": "1.7305"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".795563469525874",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".48780001861179",
						"qValue": "1",
						"foldChange": "0.5542"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".753378579421542",
						"qValue": "1",
						"foldChange": "0.7444"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".725413538707557",
						"qValue": "1",
						"foldChange": "1.3432"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".268501424147762",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".023713008567412",
						"qValue": "1",
						"foldChange": "0.2546"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".200286105752037",
						"qValue": ".985830071188835",
						"foldChange": "0.2357"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".182796738611558",
						"qValue": ".125046033346388",
						"foldChange": "1.5896"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001697427851594",
						"qValue": ".003053688487966",
						"foldChange": "10.7408"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00064204747019",
						"qValue": ".000770341591376",
						"foldChange": "7.4942"
					}
				]
			},
			{
				"metaboliteId": "0078db61-0000-0000-0000-000000000000",
				"chemicalId": "05f60411-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-arachidonoyl-GPE (18:0/20:4)",
				"plotName": null,
				"compoundId": "0000ccdf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09003",
				"inChiKey": "ANRKEHNWXKCXDB-BHFWLYLHBY",
				"kegg": null,
				"mass": 767.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2165,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".962826410333528",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".995623568043968",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".580988813730486",
						"qValue": ".996704603416188",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".866488096244214",
						"qValue": "1",
						"foldChange": "1.0392"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".242805076203989",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".31588214251",
						"qValue": "1",
						"foldChange": "0.9563"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".505028336693035",
						"qValue": "1",
						"foldChange": "1.1144"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".389490019072336",
						"qValue": ".880987329915339",
						"foldChange": "1.1404"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".844841971908344",
						"qValue": "1",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".660825121583501",
						"qValue": "1",
						"foldChange": "0.9601"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".838339112317485",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".520944930001537",
						"qValue": "1",
						"foldChange": "1.0614"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".570180271955128",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".938149434703136",
						"qValue": "1",
						"foldChange": "0.9575"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".637761451903293",
						"qValue": "1",
						"foldChange": "1.0014"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".946906231198146",
						"qValue": "1",
						"foldChange": "0.9955"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".763839585964785",
						"qValue": "1",
						"foldChange": "0.9759"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".709768760697747",
						"qValue": "1",
						"foldChange": "0.9804"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".313691858695716",
						"qValue": "1",
						"foldChange": "1.1482"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".33633611577525",
						"qValue": "1",
						"foldChange": "1.1128"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".993144241648463",
						"qValue": "1",
						"foldChange": "0.9692"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000932724593034",
						"qValue": ".002497549400367",
						"foldChange": "1.7255"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00343314715574",
						"qValue": ".005038530876539",
						"foldChange": "1.5440"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000552794706779",
						"qValue": ".000673303621359",
						"foldChange": "1.6564"
					}
				]
			},
			{
				"metaboliteId": "0078db62-0000-0000-0000-000000000000",
				"chemicalId": "05f603d2-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-GPC (16:0/20:4n6)",
				"plotName": null,
				"compoundId": "0000ccee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07982",
				"inChiKey": "IIZPXYDJLKNOIY-JXPKJXOSBS",
				"kegg": null,
				"mass": 781.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2037,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".754518245808657",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".816403355338325",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".676250719116917",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".769412539926996",
						"qValue": "1",
						"foldChange": "0.9920"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".502542435555261",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".336712841248969",
						"qValue": "1",
						"foldChange": "1.0480"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".617510110797112",
						"qValue": "1",
						"foldChange": "1.0254"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".996275161325778",
						"qValue": "1",
						"foldChange": "1.0104"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".62078931340929",
						"qValue": ".967408831530889",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".266780393538638",
						"qValue": "1",
						"foldChange": "0.9553"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".462704513322131",
						"qValue": "1",
						"foldChange": "0.9783"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".703229956187521",
						"qValue": "1",
						"foldChange": "1.0051"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".910322969129887",
						"qValue": "1",
						"foldChange": "0.9841"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".29226686841821",
						"qValue": "1",
						"foldChange": "1.0585"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".331229973419089",
						"qValue": "1",
						"foldChange": "1.0756"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".576371611175322",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".569835274310168",
						"qValue": "1",
						"foldChange": "1.0427"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".967207420101969",
						"qValue": "1",
						"foldChange": "0.9978"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".782405965575156",
						"qValue": "1",
						"foldChange": "0.9872"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".794571089013581",
						"qValue": "1",
						"foldChange": "1.0021"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".593692858907624",
						"qValue": ".985830071188835",
						"foldChange": "1.0151"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".020863081102381",
						"qValue": ".025871048101588",
						"foldChange": "1.2295"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".021560060324612",
						"qValue": ".019313513607787",
						"foldChange": "1.2218"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002263536710219",
						"qValue": ".001885607248428",
						"foldChange": "1.2451"
					}
				]
			},
			{
				"metaboliteId": "0078db63-0000-0000-0000-000000000000",
				"chemicalId": "05f5e67a-0000-0000-0000-000000000000",
				"chemicalName": "5-acetylamino-6-formylamino-3-methyluracil",
				"plotName": null,
				"compoundId": "00008661-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11105",
				"inChiKey": "RDZNZFGKEVDNPK-UHFFFAOYAL",
				"kegg": "C16365",
				"mass": 226.070205,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4169,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".039099840273928",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".440294563098345",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".968235713679885",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".542855944398077",
						"qValue": "1",
						"foldChange": "1.1382"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".893539538258855",
						"qValue": "1",
						"foldChange": "1.3016"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".634586294258064",
						"qValue": "1",
						"foldChange": "2.3081"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".294078515752972",
						"qValue": ".911265664999995",
						"foldChange": "7.4677"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".255831165909167",
						"qValue": ".825038702902081",
						"foldChange": "4.0927"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".929293516125496",
						"qValue": "1",
						"foldChange": "8.8275"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".620266716334057",
						"qValue": "1",
						"foldChange": "0.9513"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".694567335392916",
						"qValue": "1",
						"foldChange": "0.9582"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".917864877591769",
						"qValue": "1",
						"foldChange": "1.3485"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".71253847055355",
						"qValue": "1",
						"foldChange": "2.0849"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".062539250038816",
						"qValue": "1",
						"foldChange": "3.1879"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".117798278687194",
						"qValue": "1",
						"foldChange": "1.5291"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".951940820909342",
						"qValue": "1",
						"foldChange": "1.3244"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".054656460579044",
						"qValue": "1",
						"foldChange": "3.2735"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".055946826292478",
						"qValue": ".822195137696456",
						"foldChange": "2.4717"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".703443092879211",
						"qValue": "1",
						"foldChange": "1.0660"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".096787552235867",
						"qValue": "1",
						"foldChange": "3.2919"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".040242094760557",
						"qValue": ".985830071188835",
						"foldChange": "3.0882"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00001207639566",
						"qValue": ".000153783883501",
						"foldChange": "81.9308"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004422380265905",
						"qValue": ".005928677879055",
						"foldChange": "26.5299"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001830494402732",
						"qValue": ".00160819356938",
						"foldChange": "24.8885"
					}
				]
			},
			{
				"metaboliteId": "0078db64-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f5-0000-0000-0000-000000000000",
				"chemicalName": "campesterol",
				"plotName": null,
				"compoundId": "000084cd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02869",
				"inChiKey": "SGNBVLSWZMBQTH-PODYLUTMBW",
				"kegg": "C01789",
				"mass": 400.370516,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2908,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".361083367104277",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".234710854250108",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".736725166945352",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".831389604105624",
						"qValue": "1",
						"foldChange": "1.0292"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".14292595256171",
						"qValue": "1",
						"foldChange": "0.8247"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".208337789466149",
						"qValue": "1",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".705556072611468",
						"qValue": "1",
						"foldChange": "1.0170"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".119669839300738",
						"qValue": ".716367109949013",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".234374405758159",
						"qValue": ".957839854315834",
						"foldChange": "0.9503"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".855848617659398",
						"qValue": "1",
						"foldChange": "0.9770"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".931945754501106",
						"qValue": "1",
						"foldChange": "1.0390"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".789474566829465",
						"qValue": "1",
						"foldChange": "1.0022"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".560512872602631",
						"qValue": "1",
						"foldChange": "0.9407"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".593495191019745",
						"qValue": "1",
						"foldChange": "1.5894"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".270320069491901",
						"qValue": "1",
						"foldChange": "1.6896"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".519315685579017",
						"qValue": "1",
						"foldChange": "0.8731"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".58782809972027",
						"qValue": "1",
						"foldChange": "1.5341"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".242185378343797",
						"qValue": "1",
						"foldChange": "1.7571"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".556590340636544",
						"qValue": "1",
						"foldChange": "0.9265"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".244599149112477",
						"qValue": "1",
						"foldChange": "1.8258"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".083166564617102",
						"qValue": ".985830071188835",
						"foldChange": "1.9707"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".79958450852557",
						"qValue": ".344316995840098",
						"foldChange": "1.4173"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".252763929670288",
						"qValue": ".123653196940108",
						"foldChange": "0.6945"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".87441452046617",
						"qValue": ".173991088769943",
						"foldChange": "0.8511"
					}
				]
			},
			{
				"metaboliteId": "0078db65-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c0-0000-0000-0000-000000000000",
				"chemicalName": "delta-tocopherol",
				"plotName": null,
				"compoundId": "0000828a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02902",
				"inChiKey": "GZIFEOYASATJEH-VHFRWLAGBS",
				"kegg": "C14151",
				"mass": 402.349781,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3972,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".492041477683352",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".136305758776684",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".597563107015799",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".030578096551202",
						"qValue": "1",
						"foldChange": "0.6690"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".204496448271288",
						"qValue": "1",
						"foldChange": "0.9150"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".35091398778877",
						"qValue": "1",
						"foldChange": "1.3447"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".973943152760051",
						"qValue": "1",
						"foldChange": "1.5887"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".959529734521097",
						"qValue": "1",
						"foldChange": "1.4135"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".985574255554448",
						"qValue": "1",
						"foldChange": "1.2868"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".218344326519256",
						"qValue": "1",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".405693388014281",
						"qValue": "1",
						"foldChange": "0.8901"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".684794511352357",
						"qValue": "1",
						"foldChange": "1.2376"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".178709213569815",
						"qValue": "1",
						"foldChange": "0.5896"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".850066128539547",
						"qValue": "1",
						"foldChange": "1.2240"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".136003311090132",
						"qValue": "1",
						"foldChange": "2.0761"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".84140382156369",
						"qValue": "1",
						"foldChange": "0.9485"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".43717175755518",
						"qValue": "1",
						"foldChange": "1.4359"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".546161611059488",
						"qValue": "1",
						"foldChange": "1.5139"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".661919034358347",
						"qValue": "1",
						"foldChange": "0.7741"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".654709832479818",
						"qValue": "1",
						"foldChange": "1.3028"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".379476994383186",
						"qValue": ".985830071188835",
						"foldChange": "1.6830"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".512120795350428",
						"qValue": ".255359000860688",
						"foldChange": "0.7178"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".041271812265904",
						"qValue": ".03197814818926",
						"foldChange": "0.4120"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".033114382345435",
						"qValue": ".013845486676469",
						"foldChange": "0.5124"
					}
				]
			},
			{
				"metaboliteId": "0078db66-0000-0000-0000-000000000000",
				"chemicalId": "00000451-0000-0000-0000-000000000000",
				"chemicalName": "alpha-tocopherol",
				"plotName": null,
				"compoundId": "00000619-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01893",
				"inChiKey": "GVJHHUAWPYXKBD-IEOSBIPEBS",
				"kegg": "C02477",
				"mass": 430.381081,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3968,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".202540449338563",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".17478130659398",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".06811656148008",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".110056965812398",
						"qValue": "1",
						"foldChange": "0.9431"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".963213885746012",
						"qValue": "1",
						"foldChange": "1.0004"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".120283837646283",
						"qValue": "1",
						"foldChange": "1.0841"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".059140438871967",
						"qValue": ".535409717872803",
						"foldChange": "1.0878"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".20296328711841",
						"qValue": ".825038702902081",
						"foldChange": "1.0612"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".523750960073674",
						"qValue": ".957839854315834",
						"foldChange": "0.9864"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".127612140854492",
						"qValue": "1",
						"foldChange": "0.9366"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".522951428623376",
						"qValue": "1",
						"foldChange": "1.0378"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".03311462991314",
						"qValue": ".614826369149758",
						"foldChange": "1.0917"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".036769984718189",
						"qValue": "1",
						"foldChange": "0.8238"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".998618787490837",
						"qValue": "1",
						"foldChange": "0.9994"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".042834120192345",
						"qValue": "1",
						"foldChange": "1.2131"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".597513955022378",
						"qValue": "1",
						"foldChange": "0.9500"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".99278765886319",
						"qValue": "1",
						"foldChange": "0.9899"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".615464510298813",
						"qValue": "1",
						"foldChange": "1.0421"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".125094575230397",
						"qValue": "1",
						"foldChange": "0.8624"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".746632835888177",
						"qValue": "1",
						"foldChange": "1.0113"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".07215950191495",
						"qValue": ".985830071188835",
						"foldChange": "1.1726"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000104415686233",
						"qValue": ".000494729217459",
						"foldChange": "1.5288"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00188518947253",
						"qValue": ".003244928243391",
						"foldChange": "1.3060"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000121799840983",
						"qValue": ".000241759032007",
						"foldChange": "1.5391"
					}
				]
			},
			{
				"metaboliteId": "0078db67-0000-0000-0000-000000000000",
				"chemicalId": "05f60501-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPE (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000cfb6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09093",
				"inChiKey": null,
				"kegg": null,
				"mass": 739.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2178,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".26393821276911",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".432841525101685",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".405682651589455",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".144247963860891",
						"qValue": "1",
						"foldChange": "1.7915"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".411123002984203",
						"qValue": "1",
						"foldChange": "1.3849"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".515453847534962",
						"qValue": "1",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".471966137064725",
						"qValue": "1",
						"foldChange": "1.7604"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".092928826126351",
						"qValue": ".697458782960496",
						"foldChange": "2.2260"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".327492307116182",
						"qValue": ".957839854315834",
						"foldChange": "2.1459"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".984768192431433",
						"qValue": "1",
						"foldChange": "1.4179"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".427926611564983",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".416996225583837",
						"qValue": "1",
						"foldChange": "0.7936"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".449390953058295",
						"qValue": "1",
						"foldChange": "1.7530"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".67042533879787",
						"qValue": "1",
						"foldChange": "1.3345"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".765675370736439",
						"qValue": "1",
						"foldChange": "0.7612"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".946946471109495",
						"qValue": "1",
						"foldChange": "0.9903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".438792736485858",
						"qValue": "1",
						"foldChange": "0.7917"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".39147086820829",
						"qValue": "1",
						"foldChange": "0.7995"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".150366429916517",
						"qValue": "1",
						"foldChange": "1.6766"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".343297285353728",
						"qValue": "1",
						"foldChange": "0.6926"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".019946364303047",
						"qValue": ".985830071188835",
						"foldChange": "0.4131"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".036206152425831",
						"qValue": ".039683444580175",
						"foldChange": "0.3578"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".783342524452676",
						"qValue": ".285159628192337",
						"foldChange": "1.1280"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".972617075904304",
						"qValue": ".189085134227061",
						"foldChange": "0.9126"
					}
				]
			},
			{
				"metaboliteId": "0078db68-0000-0000-0000-000000000000",
				"chemicalId": "05f603d7-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-stearoyl)-2-oleoyl-GPE (P-18:0/18:1)",
				"plotName": null,
				"compoundId": "0000cd86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11375",
				"inChiKey": "XVYPOHCSLJZFED-QZEVRULJBC",
				"kegg": null,
				"mass": 729.56724,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2511,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".979673291004512",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".660923474578014",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".733496199188362",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".49908552498605",
						"qValue": "1",
						"foldChange": "1.2181"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".643541587473705",
						"qValue": "1",
						"foldChange": "0.9646"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".25715464573422",
						"qValue": "1",
						"foldChange": "1.0216"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".326841223525843",
						"qValue": ".946060820477864",
						"foldChange": "0.9266"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".535162731426441",
						"qValue": ".910963185640849",
						"foldChange": "0.9639"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".715994898785225",
						"qValue": ".976023509428735",
						"foldChange": "1.1185"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".476446976904203",
						"qValue": "1",
						"foldChange": "0.9504"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".634344084636007",
						"qValue": "1",
						"foldChange": "0.9732"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".81213579384553",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".556961514494197",
						"qValue": "1",
						"foldChange": "1.1083"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".682628299856219",
						"qValue": "1",
						"foldChange": "1.0180"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".880191406088388",
						"qValue": "1",
						"foldChange": "0.9185"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".552424803815855",
						"qValue": "1",
						"foldChange": "0.8855"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".558129255913757",
						"qValue": "1",
						"foldChange": "0.8893"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".980517412206222",
						"qValue": "1",
						"foldChange": "1.0043"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".620517707071448",
						"qValue": "1",
						"foldChange": "1.1454"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".703992712313083",
						"qValue": "1",
						"foldChange": "1.0647"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".927729675611789",
						"qValue": ".997573179629986",
						"foldChange": "0.9295"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".959936933855252",
						"qValue": ".387592788209897",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".855488112344092",
						"qValue": ".302728192421515",
						"foldChange": "1.0499"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".486853102075065",
						"qValue": ".109017069361015",
						"foldChange": "1.0390"
					}
				]
			},
			{
				"metaboliteId": "0078db69-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecad-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-linoleoyl-glycerol (18:1/18:2) [1]",
				"plotName": null,
				"compoundId": "0000b6ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07219",
				"inChiKey": "BLZVZPYMHLXLHG-RQOIEFAZBI",
				"kegg": null,
				"mass": 618.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2626,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".697790308389687",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".391864391505123",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".040531679671839",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".4832382505742",
						"qValue": "1",
						"foldChange": "0.9471"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".19157811909882",
						"qValue": "1",
						"foldChange": "0.8962"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".539493299590829",
						"qValue": "1",
						"foldChange": "0.9624"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001438232547306",
						"qValue": ".1236170295117",
						"foldChange": "1.4727"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".062578186919214",
						"qValue": ".687896186587066",
						"foldChange": "1.2771"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".146545023202021",
						"qValue": ".957839854315834",
						"foldChange": "0.9054"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".892440130462952",
						"qValue": "1",
						"foldChange": "1.0470"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".786771020677585",
						"qValue": "1",
						"foldChange": "1.0753"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".685117594239793",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".084225150746108",
						"qValue": "1",
						"foldChange": "0.6763"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".645719148758018",
						"qValue": "1",
						"foldChange": "1.0035"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".219676552549686",
						"qValue": "1",
						"foldChange": "1.4838"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".934736190938476",
						"qValue": "1",
						"foldChange": "0.9324"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".817222313072204",
						"qValue": "1",
						"foldChange": "1.0468"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".874846938989838",
						"qValue": "1",
						"foldChange": "1.1227"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".675605477714317",
						"qValue": "1",
						"foldChange": "0.8207"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".843746721412863",
						"qValue": "1",
						"foldChange": "0.9936"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".544470952839308",
						"qValue": ".985830071188835",
						"foldChange": "1.2107"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".301119103405331",
						"qValue": ".180285175001588",
						"foldChange": "1.3609"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".027161126671728",
						"qValue": ".023020618576832",
						"foldChange": "1.4733"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".123450759543263",
						"qValue": ".038242600278016",
						"foldChange": "1.6445"
					}
				]
			},
			{
				"metaboliteId": "0078db6a-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9ed-0000-0000-0000-000000000000",
				"chemicalName": "tramadol",
				"plotName": null,
				"compoundId": "0000cff9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB14339",
				"inChiKey": "TVYLLZQTGLZFBW-ZBFHGGJFBS",
				"kegg": "C07153",
				"mass": 263.188529,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4824,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".316797415981208",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".288699042107209",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320381924234874",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".137396733023413",
						"qValue": "1",
						"foldChange": "0.9905"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".012112347947608",
						"qValue": ".606329888436129",
						"foldChange": "0.9841"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".278160155078661",
						"qValue": "1",
						"foldChange": "0.9938"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".035703770468024",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".031593408246651",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".369363411046489",
						"qValue": "1",
						"foldChange": "1.0065"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".357884248634289",
						"qValue": "1",
						"foldChange": "1.0065"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078db6b-0000-0000-0000-000000000000",
				"chemicalId": "05f60575-0000-0000-0000-000000000000",
				"chemicalName": "docosahexaenoylcholine",
				"plotName": null,
				"compoundId": "0000d00f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 413.32938,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1722,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".318871174489744",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".22875507477458",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".002957818855461",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".351947147852921",
						"qValue": "1",
						"foldChange": "2.0156"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".11030388279528",
						"qValue": "1",
						"foldChange": "2.2942"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".495416022926185",
						"qValue": "1",
						"foldChange": "2.5117"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".027114561451221",
						"qValue": ".366261774523635",
						"foldChange": "0.6151"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".476220643147506",
						"qValue": ".902562957068497",
						"foldChange": "1.0536"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".124951392761156",
						"qValue": ".932820956170539",
						"foldChange": "2.5565"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".282593265991969",
						"qValue": "1",
						"foldChange": "1.0535"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".000867825810485",
						"qValue": ".246173254907437",
						"foldChange": "0.3569"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".017300051255864",
						"qValue": ".597655844329562",
						"foldChange": "0.5322"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".998128056713706",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".371806453739819",
						"qValue": "1",
						"foldChange": "1.4261"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".361535034916551",
						"qValue": "1",
						"foldChange": "1.4300"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".033647782657189",
						"qValue": "1",
						"foldChange": "0.3276"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".622309990181442",
						"qValue": "1",
						"foldChange": "0.7321"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".113609944244738",
						"qValue": ".908885675694672",
						"foldChange": "2.2349"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".110292498792848",
						"qValue": "1",
						"foldChange": "0.7945"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".009607451858014",
						"qValue": "1",
						"foldChange": "0.2493"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".239906178034524",
						"qValue": ".985830071188835",
						"foldChange": "0.3138"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013015033965167",
						"qValue": ".018494228676856",
						"foldChange": "2.5992"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003971621918616",
						"qValue": ".005514500099902",
						"foldChange": "8.2680"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000022710528485",
						"qValue": ".000102368819781",
						"foldChange": "9.0216"
					}
				]
			},
			{
				"metaboliteId": "0078db6c-0000-0000-0000-000000000000",
				"chemicalId": "05f5eb3b-0000-0000-0000-000000000000",
				"chemicalName": "sedoheptulose",
				"plotName": null,
				"compoundId": "0000cff5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03219",
				"inChiKey": "HSNZZMHEPUFJNZ-SHUUEZRQBR",
				"kegg": null,
				"mass": 210.073953,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1136,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".618625371518517",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".556666826708524",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".203449350834439",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".848960540372097",
						"qValue": "1",
						"foldChange": "1.2295"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".942531549256689",
						"qValue": "1",
						"foldChange": "1.1671"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".905764933402361",
						"qValue": "1",
						"foldChange": "1.0460"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".511236863994775",
						"qValue": "1",
						"foldChange": "0.9913"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".776946748319895",
						"qValue": ".976406093916918",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".707876994818151",
						"qValue": ".976023509428735",
						"foldChange": "1.4075"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".017245040771277",
						"qValue": ".764235125519165",
						"foldChange": "2.9292"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".498332751518763",
						"qValue": "1",
						"foldChange": "1.4113"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".080629730497502",
						"qValue": ".770965175880612",
						"foldChange": "0.8857"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".716003063265882",
						"qValue": "1",
						"foldChange": "1.1901"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".911852578066362",
						"qValue": "1",
						"foldChange": "0.9634"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".641542661303085",
						"qValue": "1",
						"foldChange": "0.8096"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".974933370655477",
						"qValue": "1",
						"foldChange": "0.9545"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".060073614582557",
						"qValue": "1",
						"foldChange": "2.0967"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".058004735059614",
						"qValue": ".822195137696456",
						"foldChange": "2.1967"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".831152171735919",
						"qValue": "1",
						"foldChange": "1.0985"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".643655043979262",
						"qValue": "1",
						"foldChange": "1.2245"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".788933362697517",
						"qValue": ".997573179629986",
						"foldChange": "1.1147"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".720061824202308",
						"qValue": ".325690839639443",
						"foldChange": "0.9901"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".245232454535827",
						"qValue": ".121245030527525",
						"foldChange": "0.7622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".40560857946108",
						"qValue": ".094168981073947",
						"foldChange": "0.7155"
					}
				]
			},
			{
				"metaboliteId": "0078db6d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8f1-0000-0000-0000-000000000000",
				"chemicalName": "vanillactate",
				"plotName": null,
				"compoundId": "0000d67e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00913",
				"inChiKey": "SVYIZYRTOYHQRE-UHFFFAOYAV",
				"kegg": null,
				"mass": 212.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 183,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".475405262563862",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".552236774514315",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".777447065371226",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".958279124978677",
						"qValue": "1",
						"foldChange": "1.0773"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".982454698392882",
						"qValue": "1",
						"foldChange": "1.0026"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".940768961205592",
						"qValue": "1",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".181524975581323",
						"qValue": ".797133099236872",
						"foldChange": "1.1442"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".143182689869422",
						"qValue": ".77610489859158",
						"foldChange": "1.0820"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".895009558968791",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".857309253569867",
						"qValue": "1",
						"foldChange": "1.1060"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".659546516549894",
						"qValue": "1",
						"foldChange": "0.9126"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".535542043617439",
						"qValue": "1",
						"foldChange": "0.8470"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".347789127226044",
						"qValue": "1",
						"foldChange": "1.7264"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".561630682995569",
						"qValue": "1",
						"foldChange": "0.1768"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".134886000265918",
						"qValue": "1",
						"foldChange": "0.1024"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".778736361909622",
						"qValue": "1",
						"foldChange": "0.9006"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".645095952287719",
						"qValue": "1",
						"foldChange": "0.8518"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".841533985202637",
						"qValue": "1",
						"foldChange": "0.9458"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".743166521671839",
						"qValue": "1",
						"foldChange": "0.8827"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".33462736141699",
						"qValue": "1",
						"foldChange": "0.6729"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".500829681068624",
						"qValue": ".985830071188835",
						"foldChange": "0.7623"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".103609202716213",
						"qValue": ".084285091191492",
						"foldChange": "1.4623"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00889609473898",
						"qValue": ".009256135666673",
						"foldChange": "1.8787"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010852806123042",
						"qValue": ".005739703612364",
						"foldChange": "1.9149"
					}
				]
			},
			{
				"metaboliteId": "0078db6e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e37f-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPS (18:0/18:1)",
				"plotName": null,
				"compoundId": "00004b41-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10163",
				"inChiKey": "AJFWREUFUPEYII-PAHWMLEVBX",
				"kegg": null,
				"mass": 789.551984,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylserine (PS)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".761415328362751",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".582382677493679",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".953289447576353",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".961946108321219",
						"qValue": "1",
						"foldChange": "5.4321"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".276189205897185",
						"qValue": "1",
						"foldChange": "0.7753"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".297386905035912",
						"qValue": "1",
						"foldChange": "2.4703"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".98300234735481",
						"qValue": "1",
						"foldChange": "1.8532"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".87369944737536",
						"qValue": "1",
						"foldChange": "2.7781"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".890506342870718",
						"qValue": "1",
						"foldChange": "1.6872"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".876700237954309",
						"qValue": "1",
						"foldChange": "1.1785"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".689851711923177",
						"qValue": "1",
						"foldChange": "1.5084"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".807121834797284",
						"qValue": "1",
						"foldChange": "1.5751"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".723157516644779",
						"qValue": "1",
						"foldChange": "0.6764"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".862112983768335",
						"qValue": "1",
						"foldChange": "0.7832"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".602553567045197",
						"qValue": "1",
						"foldChange": "1.1579"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".744584092255354",
						"qValue": "1",
						"foldChange": "0.5487"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".952775434889701",
						"qValue": "1",
						"foldChange": "0.6467"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".706789240000697",
						"qValue": "1",
						"foldChange": "1.1787"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".532895826136077",
						"qValue": "1",
						"foldChange": "1.4544"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".406504887174349",
						"qValue": "1",
						"foldChange": "1.5749"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".804562719441332",
						"qValue": ".997573179629986",
						"foldChange": "1.0829"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".007316101457577",
						"qValue": ".012111461760992",
						"foldChange": "7.5521"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".358480749405784",
						"qValue": ".15667881068273",
						"foldChange": "1.3204"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".318180251273679",
						"qValue": ".07906603229129",
						"foldChange": "1.9379"
					}
				]
			},
			{
				"metaboliteId": "0078db6f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e99c-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-3-methylhistidine*",
				"plotName": null,
				"compoundId": "0000a8f8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FKTXRTPBUWLETL-UHFFFAOYAZ",
				"kegg": null,
				"mass": 211.095691,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 71,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".593510400702746",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".085357624472977",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".835200048201057",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".073669198687769",
						"qValue": "1",
						"foldChange": "0.7595"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".147933330478724",
						"qValue": "1",
						"foldChange": "0.9041"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".721852515124021",
						"qValue": "1",
						"foldChange": "1.9201"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".536258942074417",
						"qValue": "1",
						"foldChange": "1.5910"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".281096329838688",
						"qValue": ".825038702902081",
						"foldChange": "1.2074"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".642489429203086",
						"qValue": ".973034272612281",
						"foldChange": "3.1353"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".73244340419478",
						"qValue": "1",
						"foldChange": "1.1144"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".217459123770322",
						"qValue": "1",
						"foldChange": "0.9869"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".369538864019669",
						"qValue": "1",
						"foldChange": "1.1290"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".2623838442441",
						"qValue": "1",
						"foldChange": "0.4701"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".277912391993761",
						"qValue": "1",
						"foldChange": "0.5030"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".979567223868814",
						"qValue": "1",
						"foldChange": "1.0700"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".908889223282358",
						"qValue": "1",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".966647695651645",
						"qValue": "1",
						"foldChange": "1.0389"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".877865832337434",
						"qValue": "1",
						"foldChange": "1.0419"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".442492270566767",
						"qValue": "1",
						"foldChange": "0.6253"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".329866542036227",
						"qValue": "1",
						"foldChange": "0.5211"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".797630423969123",
						"qValue": ".997573179629986",
						"foldChange": "0.8333"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".03847718587361",
						"qValue": ".041498289781283",
						"foldChange": "3.6683"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".235274459297232",
						"qValue": ".117783564961606",
						"foldChange": "2.4568"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".032981850965158",
						"qValue": ".013845136971325",
						"foldChange": "6.1002"
					}
				]
			},
			{
				"metaboliteId": "0078db70-0000-0000-0000-000000000000",
				"chemicalId": "05f5e49c-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoylglycerol (18:1)",
				"plotName": null,
				"compoundId": "000052c0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11567",
				"inChiKey": "RZRNAYUHWVFMIP-KTKRTIGZBM",
				"kegg": null,
				"mass": 356.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2557,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".693159539844429",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".395001051756992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".985060671801503",
						"qValue": ".999283527063851",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".583709122599236",
						"qValue": "1",
						"foldChange": "1.8187"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".742996310078454",
						"qValue": "1",
						"foldChange": "1.1632"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".825317715423236",
						"qValue": "1",
						"foldChange": "1.7411"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".407255102413023",
						"qValue": ".981796295052358",
						"foldChange": "1.7431"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".445894276185008",
						"qValue": ".900630141528144",
						"foldChange": "1.3604"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".946485608763592",
						"qValue": "1",
						"foldChange": "1.4803"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".55040121123938",
						"qValue": "1",
						"foldChange": "1.3174"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".307580192552604",
						"qValue": "1",
						"foldChange": "1.8817"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".669212588371239",
						"qValue": "1",
						"foldChange": "1.2557"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".586515132330797",
						"qValue": "1",
						"foldChange": "0.8538"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".393258666307233",
						"qValue": "1",
						"foldChange": "0.6238"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".726679141962896",
						"qValue": "1",
						"foldChange": "0.7306"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".734158212191021",
						"qValue": "1",
						"foldChange": "0.8160"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".432344684283768",
						"qValue": "1",
						"foldChange": "0.6206"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".63346847299218",
						"qValue": "1",
						"foldChange": "0.7604"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".836952499848383",
						"qValue": "1",
						"foldChange": "0.8780"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".807621665307302",
						"qValue": "1",
						"foldChange": "0.7012"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".960026132138697",
						"qValue": "1",
						"foldChange": "0.7986"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".08418140102841",
						"qValue": ".072206398395873",
						"foldChange": "0.5869"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".102100241204269",
						"qValue": ".062162202853528",
						"foldChange": "0.7162"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".501082210708673",
						"qValue": ".111273416912358",
						"foldChange": "0.8427"
					}
				]
			},
			{
				"metaboliteId": "0078db71-0000-0000-0000-000000000000",
				"chemicalId": "05f603d8-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/17:0, d17:1/18:0, d19:1/16:0)",
				"plotName": null,
				"compoundId": "0000cd87-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 716.583225,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2806,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".103416954095448",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".259578082984012",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".023403609380784",
						"qValue": ".622389736970234",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".582934881265446",
						"qValue": "1",
						"foldChange": "1.0534"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".275702404681691",
						"qValue": "1",
						"foldChange": "1.0900"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".585042787940936",
						"qValue": "1",
						"foldChange": "1.0427"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".551524705526903",
						"qValue": "1",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".622405014844695",
						"qValue": ".928868848501546",
						"foldChange": "1.0406"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".278828883934644",
						"qValue": ".957839854315834",
						"foldChange": "1.0819"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".009356258157906",
						"qValue": ".678448626775002",
						"foldChange": "1.1881"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".595990906486213",
						"qValue": "1",
						"foldChange": "0.9759"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".002163949603195",
						"qValue": ".306920185386474",
						"foldChange": "0.8243"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".485500155075313",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".455381589150761",
						"qValue": "1",
						"foldChange": "1.0440"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".153731115115846",
						"qValue": "1",
						"foldChange": "1.1304"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".169757008235025",
						"qValue": "1",
						"foldChange": "0.8740"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".04109249256738",
						"qValue": "1",
						"foldChange": "1.2316"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".001106687283839",
						"qValue": ".605991101987089",
						"foldChange": "1.4091"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".27990895717297",
						"qValue": "1",
						"foldChange": "0.9017"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".824880446573677",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".409858992276174",
						"qValue": ".985830071188835",
						"foldChange": "1.0921"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".876499967659257",
						"qValue": ".366245640678326",
						"foldChange": "0.9959"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".724576993899621",
						"qValue": ".271566822761492",
						"foldChange": "1.0309"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".082982617000096",
						"qValue": ".027969825287467",
						"foldChange": "1.1975"
					}
				]
			},
			{
				"metaboliteId": "0078db72-0000-0000-0000-000000000000",
				"chemicalId": "0000031e-0000-0000-0000-000000000000",
				"chemicalName": "adenosine",
				"plotName": null,
				"compoundId": "0000022b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00050",
				"inChiKey": "OIRDTQYFTABQOQ-KQYNXXCUBX",
				"kegg": "C00212",
				"mass": 267.096755,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3768,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".447289085256133",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".156694866133557",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".31553662156459",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".52579042624353",
						"qValue": "1",
						"foldChange": "6.1270"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".308068001335793",
						"qValue": "1",
						"foldChange": "0.7652"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".101561635973492",
						"qValue": "1",
						"foldChange": "0.8659"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".080080578931989",
						"qValue": ".613951105145249",
						"foldChange": "4.3554"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".253565616538991",
						"qValue": ".825038702902081",
						"foldChange": "2.8270"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".530090444647065",
						"qValue": ".957839854315834",
						"foldChange": "1.9522"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".3152925980137",
						"qValue": "1",
						"foldChange": "2.5699"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".104534387744775",
						"qValue": "1",
						"foldChange": "2.2885"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".525350799428971",
						"qValue": "1",
						"foldChange": "1.4917"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".731843990051335",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".248175705226355",
						"qValue": "1",
						"foldChange": "0.4945"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".392809226037424",
						"qValue": "1",
						"foldChange": "0.5108"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".538011430666779",
						"qValue": "1",
						"foldChange": "3.6110"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".433283177067888",
						"qValue": "1",
						"foldChange": "0.3690"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".164033447074207",
						"qValue": "1",
						"foldChange": "0.1022"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".115866601989784",
						"qValue": "1",
						"foldChange": "2.9262"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".221451937255317",
						"qValue": "1",
						"foldChange": "1.8251"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".781195970152447",
						"qValue": ".997573179629986",
						"foldChange": "0.6237"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032950204158974",
						"qValue": ".036758311294647",
						"foldChange": "0.2386"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005617681858936",
						"qValue": ".006900751516465",
						"foldChange": "0.0161"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001730943283271",
						"qValue": ".001537535913228",
						"foldChange": "0.0120"
					}
				]
			},
			{
				"metaboliteId": "0078db73-0000-0000-0000-000000000000",
				"chemicalId": "00000219-0000-0000-0000-000000000000",
				"chemicalName": "trans-urocanate",
				"plotName": null,
				"compoundId": "0000025f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00301",
				"inChiKey": "LOIYMIARKYCTBW-OWOJBTEDBA",
				"kegg": "C00785",
				"mass": 138.042928,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 74,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".41384717916379",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".300286833518595",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".490861946998647",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".698986060191298",
						"qValue": "1",
						"foldChange": "1.0038"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".109094386652331",
						"qValue": "1",
						"foldChange": "0.9211"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".219687021816512",
						"qValue": "1",
						"foldChange": "0.8829"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".935348998095811",
						"qValue": "1",
						"foldChange": "1.0496"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".93322140263655",
						"qValue": "1",
						"foldChange": "1.0848"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".997864932915844",
						"qValue": "1",
						"foldChange": "1.0226"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".142050389472472",
						"qValue": "1",
						"foldChange": "1.5685"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".916516446777024",
						"qValue": "1",
						"foldChange": "1.1390"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".171739953326969",
						"qValue": ".980877183095642",
						"foldChange": "0.8142"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".335283441798175",
						"qValue": "1",
						"foldChange": "0.8562"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".816067028976989",
						"qValue": "1",
						"foldChange": "0.9302"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".485256408687842",
						"qValue": "1",
						"foldChange": "1.0864"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".45392273381284",
						"qValue": "1",
						"foldChange": "0.8843"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".288774108184926",
						"qValue": "1",
						"foldChange": "1.3431"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".073590019837374",
						"qValue": ".822195137696456",
						"foldChange": "1.5188"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".909291280077448",
						"qValue": "1",
						"foldChange": "0.9334"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".361256694735174",
						"qValue": "1",
						"foldChange": "0.9721"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".408764872890421",
						"qValue": ".985830071188835",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".154369773726598",
						"qValue": ".111838904919068",
						"foldChange": "1.3351"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".257539522902262",
						"qValue": ".12434741170742",
						"foldChange": "0.7965"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".192072920466917",
						"qValue": ".054273110107643",
						"foldChange": "0.7767"
					}
				]
			},
			{
				"metaboliteId": "0078db74-0000-0000-0000-000000000000",
				"chemicalId": "05f5e510-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoylglycerol (18:2)",
				"plotName": null,
				"compoundId": "00006b37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WECGLUPZRHILCT-HZJYTTRNBQ",
				"kegg": null,
				"mass": 354.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2558,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".561273025318402",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".872436433922914",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".99261374829778",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".640140863308554",
						"qValue": "1",
						"foldChange": "2.9125"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".819037619692451",
						"qValue": "1",
						"foldChange": "1.1407"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".811011415616079",
						"qValue": "1",
						"foldChange": "2.3133"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".678966694396475",
						"qValue": "1",
						"foldChange": "1.6244"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".936036560991903",
						"qValue": "1",
						"foldChange": "1.3338"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".738533682974163",
						"qValue": ".976023509428735",
						"foldChange": "1.4534"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".989047879938498",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".754617207738398",
						"qValue": "1",
						"foldChange": "1.6546"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".765054214904475",
						"qValue": "1",
						"foldChange": "1.4356"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".586094918409308",
						"qValue": "1",
						"foldChange": "0.8003"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".544460129503725",
						"qValue": "1",
						"foldChange": "0.5551"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".925334676327362",
						"qValue": "1",
						"foldChange": "0.6936"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".541238147738442",
						"qValue": "1",
						"foldChange": "0.5067"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".324974454854449",
						"qValue": "1",
						"foldChange": "0.4003"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".675062894670558",
						"qValue": "1",
						"foldChange": "0.7900"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".496744169825603",
						"qValue": "1",
						"foldChange": "0.7992"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".603077119525032",
						"qValue": "1",
						"foldChange": "0.5238"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".899518176469422",
						"qValue": ".997573179629986",
						"foldChange": "0.6555"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000047228876616",
						"qValue": ".000325158627929",
						"foldChange": "0.1931"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000169492526065",
						"qValue": ".000805494946676",
						"foldChange": "0.2715"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00405972083664",
						"qValue": ".002852241898975",
						"foldChange": "0.4574"
					}
				]
			},
			{
				"metaboliteId": "0078db75-0000-0000-0000-000000000000",
				"chemicalId": "05f6057f-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-linolenoyl-GPC (18:2/18:3)*",
				"plotName": null,
				"compoundId": "0000cfb8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08141",
				"inChiKey": null,
				"kegg": null,
				"mass": 779.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2097,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".877418341325632",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".961832441807103",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".029570172152949",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".549769187998236",
						"qValue": "1",
						"foldChange": "1.2351"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".47523582762266",
						"qValue": "1",
						"foldChange": "1.2265"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".907127700499993",
						"qValue": "1",
						"foldChange": "1.0938"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".28922033357591",
						"qValue": ".911265664999995",
						"foldChange": "1.3835"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".075105123622322",
						"qValue": ".687896186587066",
						"foldChange": "1.4739"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".458336065468133",
						"qValue": ".957839854315834",
						"foldChange": "1.2461"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".067793506861122",
						"qValue": ".824175347697354",
						"foldChange": "0.8110"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".008599906505399",
						"qValue": ".522751459721039",
						"foldChange": "0.6970"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".387831953868892",
						"qValue": "1",
						"foldChange": "0.8414"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".891917633692366",
						"qValue": "1",
						"foldChange": "1.0552"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".134045659120244",
						"qValue": "1",
						"foldChange": "1.4308"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".159281239337297",
						"qValue": "1",
						"foldChange": "1.3560"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".69584099930817",
						"qValue": "1",
						"foldChange": "1.2019"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".989271177760711",
						"qValue": "1",
						"foldChange": "1.3562"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".715136605932903",
						"qValue": "1",
						"foldChange": "1.1284"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".451123968548982",
						"qValue": "1",
						"foldChange": "1.2737"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".553565907027435",
						"qValue": "1",
						"foldChange": "0.7435"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".184880350785567",
						"qValue": ".985830071188835",
						"foldChange": "0.5837"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".700639736056069",
						"qValue": ".320657800180001",
						"foldChange": "0.7459"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".468886401454024",
						"qValue": ".193276678684815",
						"foldChange": "1.3732"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".146727963664488",
						"qValue": ".043629320774403",
						"foldChange": "1.5441"
					}
				]
			},
			{
				"metaboliteId": "0078db76-0000-0000-0000-000000000000",
				"chemicalId": "05f5e569-0000-0000-0000-000000000000",
				"chemicalName": "O-acetylhomoserine",
				"plotName": null,
				"compoundId": "00007b33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FCXZBWSIAGGPCB-YFKPBYRVBL",
				"kegg": "C01077",
				"mass": 161.068808,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 22,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".433099783908124",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".709632620583716",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".431794964323418",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".507825435712674",
						"qValue": "1",
						"foldChange": "1.3120"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".678552517945167",
						"qValue": "1",
						"foldChange": "2.0001"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".283691405571234",
						"qValue": "1",
						"foldChange": "2.1552"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".487334177292954",
						"qValue": "1",
						"foldChange": "1.3398"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".536937169815024",
						"qValue": ".910963185640849",
						"foldChange": "1.5958"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".192475037540355",
						"qValue": ".957839854315834",
						"foldChange": "2.2033"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".209541126866627",
						"qValue": "1",
						"foldChange": "7.9051"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".811874919746945",
						"qValue": "1",
						"foldChange": "2.3471"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".306982180505122",
						"qValue": "1",
						"foldChange": "1.3710"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".589301713038352",
						"qValue": "1",
						"foldChange": "0.2657"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".856312106415219",
						"qValue": "1",
						"foldChange": "0.3522"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".735495061583809",
						"qValue": "1",
						"foldChange": "1.3256"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".59137903844117",
						"qValue": "1",
						"foldChange": "0.8185"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".107399778991275",
						"qValue": "1",
						"foldChange": "3.9822"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".031641160888084",
						"qValue": ".822195137696456",
						"foldChange": "4.8652"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".706700227195479",
						"qValue": "1",
						"foldChange": "0.9461"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".7435759343005",
						"qValue": "1",
						"foldChange": "0.8478"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".976506988831247",
						"qValue": "1",
						"foldChange": "0.8961"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008464481606249",
						"qValue": ".013157324947472",
						"foldChange": "2.8485"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000174856076431",
						"qValue": ".000805494946676",
						"foldChange": "3.9487"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002887041971336",
						"qValue": ".002199842803094",
						"foldChange": "2.2052"
					}
				]
			},
			{
				"metaboliteId": "0078db77-0000-0000-0000-000000000000",
				"chemicalId": "05f60baa-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-arachidonoyl-glycerol (18:2/20:4) [1]*",
				"plotName": null,
				"compoundId": "0000d6ab-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07257",
				"inChiKey": null,
				"kegg": null,
				"mass": 640.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2641,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".22778534742024",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".393696289993543",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".25939711724107",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".714532201633994",
						"qValue": "1",
						"foldChange": "0.9930"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".685366613315359",
						"qValue": "1",
						"foldChange": "0.9714"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".968518142422832",
						"qValue": "1",
						"foldChange": "1.0387"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".229647611338788",
						"qValue": ".852077213869098",
						"foldChange": "1.2907"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".863008450425837",
						"qValue": "1",
						"foldChange": "1.0597"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".170873581419094",
						"qValue": ".957839854315834",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".05414223857044",
						"qValue": ".821720595261736",
						"foldChange": "0.8263"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".092195790444813",
						"qValue": "1",
						"foldChange": "0.8241"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".799174765920156",
						"qValue": "1",
						"foldChange": "0.9785"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".168200161963411",
						"qValue": "1",
						"foldChange": "0.7221"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".330531320387459",
						"qValue": "1",
						"foldChange": "1.3781"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".023320720910494",
						"qValue": "1",
						"foldChange": "1.9084"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".538084140446928",
						"qValue": "1",
						"foldChange": "0.8420"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".885941896022125",
						"qValue": "1",
						"foldChange": "1.0964"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".458083444694084",
						"qValue": "1",
						"foldChange": "1.3022"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".208331472084818",
						"qValue": "1",
						"foldChange": "0.8180"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".768746644973126",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".131378572185219",
						"qValue": ".985830071188835",
						"foldChange": "1.2548"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".183678070065063",
						"qValue": ".125389857094997",
						"foldChange": "1.3902"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".336487915260804",
						"qValue": ".152009550549949",
						"foldChange": "1.4708"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".150430478430544",
						"qValue": ".044432022793897",
						"foldChange": "1.4436"
					}
				]
			},
			{
				"metaboliteId": "0078db78-0000-0000-0000-000000000000",
				"chemicalId": "05f60bc0-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-linolenoyl-glycerol (18:1/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6ba-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07220",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2629,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".88523278962606",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".350681747837555",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".554279035004392",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".910061196453105",
						"qValue": "1",
						"foldChange": "1.7704"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".368179484182866",
						"qValue": "1",
						"foldChange": "1.3269"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".311900372675055",
						"qValue": "1",
						"foldChange": "1.2638"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".238128405401807",
						"qValue": ".852077213869098",
						"foldChange": "3.0511"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".149893215891933",
						"qValue": ".782571329595308",
						"foldChange": "2.1979"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".789253282869589",
						"qValue": ".993571810239675",
						"foldChange": "1.3324"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".238665398446235",
						"qValue": "1",
						"foldChange": "2.1426"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".69294656978651",
						"qValue": "1",
						"foldChange": "1.4265"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".429992694373078",
						"qValue": "1",
						"foldChange": "0.8150"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".275923180304916",
						"qValue": "1",
						"foldChange": "0.6696"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".637460764951626",
						"qValue": "1",
						"foldChange": "0.8621"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".56443924022472",
						"qValue": "1",
						"foldChange": "1.2876"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".866119139482789",
						"qValue": "1",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".605245279618772",
						"qValue": "1",
						"foldChange": "1.7101"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".488888518478293",
						"qValue": "1",
						"foldChange": "1.7330"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".323550883927266",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".503554157023145",
						"qValue": "1",
						"foldChange": "1.0040"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".784608272247009",
						"qValue": ".997573179629986",
						"foldChange": "0.9005"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".274923398682551",
						"qValue": ".167941974961316",
						"foldChange": "0.7830"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".390071516911056",
						"qValue": ".167596385685131",
						"foldChange": "1.1733"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".888534536951672",
						"qValue": ".17636414614677",
						"foldChange": "1.2430"
					}
				]
			},
			{
				"metaboliteId": "0078db79-0000-0000-0000-000000000000",
				"chemicalId": "05f61e41-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoyl-arachidonoyl-glycerol (16:1/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000e03e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 614.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2616,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".532439999445733",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".435322402238193",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".785826930681585",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".568732388924102",
						"qValue": "1",
						"foldChange": "0.9853"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".337330889340992",
						"qValue": "1",
						"foldChange": "0.9064"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".694394003707716",
						"qValue": "1",
						"foldChange": "0.9575"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".878138629379606",
						"qValue": "1",
						"foldChange": "1.4521"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".866570247635914",
						"qValue": "1",
						"foldChange": "1.2037"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".988276887099032",
						"qValue": "1",
						"foldChange": "2.1543"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".175907554096562",
						"qValue": "1",
						"foldChange": "0.7558"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".256600082910622",
						"qValue": "1",
						"foldChange": "0.9640"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".822479446044367",
						"qValue": "1",
						"foldChange": "1.1278"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".207877833354297",
						"qValue": "1",
						"foldChange": "0.5930"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".557989756156803",
						"qValue": "1",
						"foldChange": "0.9451"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".52906852530328",
						"qValue": "1",
						"foldChange": "1.5938"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".405718699929062",
						"qValue": "1",
						"foldChange": "0.9934"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".277530291023043",
						"qValue": "1",
						"foldChange": "1.2548"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".755273972306336",
						"qValue": "1",
						"foldChange": "1.2632"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".558297462776551",
						"qValue": "1",
						"foldChange": "0.8325"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".466986239077283",
						"qValue": "1",
						"foldChange": "0.8456"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".857645893524084",
						"qValue": ".997573179629986",
						"foldChange": "1.0158"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".393670856255544",
						"qValue": ".212359369717559",
						"foldChange": "1.6481"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".214422159131007",
						"qValue": ".109306866528581",
						"foldChange": "1.6622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".165493080837106",
						"qValue": ".048063078602301",
						"foldChange": "1.7531"
					}
				]
			},
			{
				"metaboliteId": "0078db7a-0000-0000-0000-000000000000",
				"chemicalId": "05f61eab-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/19:0, d19:1/18:0)*",
				"plotName": null,
				"compoundId": "0000e083-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 744.614502,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2808,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".176346559922471",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".548180500816541",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".080073491299084",
						"qValue": ".767448234592668",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".568080724433212",
						"qValue": "1",
						"foldChange": "1.0694"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".125075425547373",
						"qValue": "1",
						"foldChange": "1.1585"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".328965131090168",
						"qValue": "1",
						"foldChange": "1.0889"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".580333976435651",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".454445793902315",
						"qValue": ".902562957068497",
						"foldChange": "1.0839"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".196154582670275",
						"qValue": ".957839854315834",
						"foldChange": "1.1178"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".108756559658386",
						"qValue": "1",
						"foldChange": "1.1432"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".473985132595795",
						"qValue": "1",
						"foldChange": "0.9713"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".022609662766188",
						"qValue": ".614826369149758",
						"foldChange": "0.8238"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".470312424938823",
						"qValue": "1",
						"foldChange": "0.9082"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".407138885561846",
						"qValue": "1",
						"foldChange": "1.0660"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".126139683704635",
						"qValue": "1",
						"foldChange": "1.1737"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".190383822740556",
						"qValue": "1",
						"foldChange": "0.8594"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".158632373216896",
						"qValue": "1",
						"foldChange": "1.2496"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".008888638393991",
						"qValue": ".653136865625524",
						"foldChange": "1.4541"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".240483532192866",
						"qValue": "1",
						"foldChange": "0.8738"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".714981336410151",
						"qValue": "1",
						"foldChange": "0.9416"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".441011489550487",
						"qValue": ".985830071188835",
						"foldChange": "1.0776"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".335824963527456",
						"qValue": ".192863611674612",
						"foldChange": "1.1021"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".1604498649466",
						"qValue": ".088420699275986",
						"foldChange": "1.2303"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005030575359626",
						"qValue": ".003235181543249",
						"foldChange": "1.4494"
					}
				]
			},
			{
				"metaboliteId": "0078db7b-0000-0000-0000-000000000000",
				"chemicalId": "05f5f998-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/22:2, d18:2/22:1, d16:1/24:2)*",
				"plotName": null,
				"compoundId": "0000e085-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 782.630175,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2815,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".930228667690086",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".414193064935372",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".019159430469697",
						"qValue": ".620468227380083",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".553935453159559",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".340495691340397",
						"qValue": "1",
						"foldChange": "1.1196"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".715458303089669",
						"qValue": "1",
						"foldChange": "1.0623"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".528529255404153",
						"qValue": "1",
						"foldChange": "0.9806"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".431893012252429",
						"qValue": ".894260227315856",
						"foldChange": "1.1184"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".15990978103973",
						"qValue": ".957839854315834",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".044780111809849",
						"qValue": ".821720595261736",
						"foldChange": "1.2445"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".212497007227111",
						"qValue": "1",
						"foldChange": "0.9198"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".001704205423926",
						"qValue": ".306920185386474",
						"foldChange": "0.7322"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".910054570100743",
						"qValue": "1",
						"foldChange": "1.0525"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".830277293958451",
						"qValue": "1",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".742282048065353",
						"qValue": "1",
						"foldChange": "0.9310"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".58474082185824",
						"qValue": "1",
						"foldChange": "0.9496"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".540179520115404",
						"qValue": "1",
						"foldChange": "1.1232"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".250615740977375",
						"qValue": "1",
						"foldChange": "1.1828"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".99742665049656",
						"qValue": "1",
						"foldChange": "1.0417"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".162856169617095",
						"qValue": "1",
						"foldChange": "0.7965"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".153974670902094",
						"qValue": ".985830071188835",
						"foldChange": "0.7646"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".045514673189067",
						"qValue": ".046654736920714",
						"foldChange": "0.7154"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".634136137203828",
						"qValue": ".246199934190585",
						"foldChange": "1.1343"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".752527777679871",
						"qValue": ".152558761137927",
						"foldChange": "0.9641"
					}
				]
			},
			{
				"metaboliteId": "0078db7c-0000-0000-0000-000000000000",
				"chemicalId": "05f61eaf-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/23:1)*",
				"plotName": null,
				"compoundId": "0000e08a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 796.6458,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2798,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".749241253038461",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".14741246896738",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".007463791383075",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".274575952117076",
						"qValue": "1",
						"foldChange": "1.1212"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".122247744781658",
						"qValue": "1",
						"foldChange": "1.1562"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".641931674039646",
						"qValue": "1",
						"foldChange": "1.0528"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".642259444409861",
						"qValue": "1",
						"foldChange": "0.9920"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".300255882653859",
						"qValue": ".825038702902081",
						"foldChange": "1.1053"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".136408966376991",
						"qValue": ".957839854315834",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".011080606703315",
						"qValue": ".678448626775002",
						"foldChange": "1.2584"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".332947736399764",
						"qValue": "1",
						"foldChange": "0.9487"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".000706658306306",
						"qValue": ".306920185386474",
						"foldChange": "0.7458"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".98736445472459",
						"qValue": "1",
						"foldChange": "1.0149"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".556612416177734",
						"qValue": "1",
						"foldChange": "0.8596"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".537015237348754",
						"qValue": "1",
						"foldChange": "0.8470"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".530408675463704",
						"qValue": "1",
						"foldChange": "0.9259"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".936861615331213",
						"qValue": "1",
						"foldChange": "1.0381"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".491370483777824",
						"qValue": "1",
						"foldChange": "1.1212"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".821500152133516",
						"qValue": "1",
						"foldChange": "1.0015"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".113159463362388",
						"qValue": "1",
						"foldChange": "0.7295"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".157114055457376",
						"qValue": ".985830071188835",
						"foldChange": "0.7284"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".74237526408315",
						"qValue": ".330326907870435",
						"foldChange": "0.9363"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".060259486369246",
						"qValue": ".042147433578833",
						"foldChange": "1.5020"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".050937242021241",
						"qValue": ".019360477520447",
						"foldChange": "1.3741"
					}
				]
			},
			{
				"metaboliteId": "0078db7d-0000-0000-0000-000000000000",
				"chemicalId": "05f60443-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:0/18:0, d19:0/17:0)*",
				"plotName": null,
				"compoundId": "0000e081-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12087",
				"inChiKey": null,
				"kegg": null,
				"mass": 732.614525,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2789,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".364871079134292",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".464597915065443",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".458331408213654",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".659748825358466",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".448704787941177",
						"qValue": "1",
						"foldChange": "1.1365"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".233406454834584",
						"qValue": "1",
						"foldChange": "1.1631"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".360874001799178",
						"qValue": ".951252030575746",
						"foldChange": "1.1409"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".07543785200049",
						"qValue": ".687896186587066",
						"foldChange": "1.2288"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".375439802265626",
						"qValue": ".957839854315834",
						"foldChange": "1.1298"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".557108292401193",
						"qValue": "1",
						"foldChange": "1.1089"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".766271518761379",
						"qValue": "1",
						"foldChange": "1.0085"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".377785582971024",
						"qValue": "1",
						"foldChange": "0.9226"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".848408278356955",
						"qValue": "1",
						"foldChange": "0.8969"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".222840192242138",
						"qValue": "1",
						"foldChange": "1.2025"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".154111798079251",
						"qValue": "1",
						"foldChange": "1.3407"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".710411467725228",
						"qValue": "1",
						"foldChange": "1.0751"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".10315356581637",
						"qValue": "1",
						"foldChange": "1.3754"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".184417646861832",
						"qValue": "1",
						"foldChange": "1.2793"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".826875178738632",
						"qValue": "1",
						"foldChange": "1.1350"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".427460493734664",
						"qValue": "1",
						"foldChange": "1.1122"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".546240178591507",
						"qValue": ".985830071188835",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".150524714980374",
						"qValue": ".110016151825569",
						"foldChange": "1.2898"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".082419416191263",
						"qValue": ".053323343136614",
						"foldChange": "1.6969"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".193427547661938",
						"qValue": ".054273110107643",
						"foldChange": "1.2925"
					}
				]
			},
			{
				"metaboliteId": "0078db7e-0000-0000-0000-000000000000",
				"chemicalId": "05f60418-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-palmitoleoyl-GPC (16:0/16:1)*",
				"plotName": null,
				"compoundId": "0000ccf6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07969",
				"inChiKey": null,
				"kegg": null,
				"mass": 731.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2020,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".18567984925455",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".224861855497403",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".783485417869466",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".101220282919115",
						"qValue": "1",
						"foldChange": "1.1526"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".350040911688222",
						"qValue": "1",
						"foldChange": "1.0864"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".470554397851741",
						"qValue": "1",
						"foldChange": "0.9769"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".160537758731331",
						"qValue": ".742487134132407",
						"foldChange": "1.1350"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".295841590566249",
						"qValue": ".825038702902081",
						"foldChange": "1.0905"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".714196140378421",
						"qValue": ".976023509428735",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".979295206457597",
						"qValue": "1",
						"foldChange": "1.0197"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".743503238733862",
						"qValue": "1",
						"foldChange": "0.9943"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".723996229826736",
						"qValue": "1",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".085922327066837",
						"qValue": "1",
						"foldChange": "1.4034"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".428526988524637",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".37580557492746",
						"qValue": "1",
						"foldChange": "0.7813"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".108614411748854",
						"qValue": "1",
						"foldChange": "1.2752"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".837653069811122",
						"qValue": "1",
						"foldChange": "0.9409"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".174582555869773",
						"qValue": "1",
						"foldChange": "0.7378"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".082200867163675",
						"qValue": "1",
						"foldChange": "1.3405"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".740828721113564",
						"qValue": "1",
						"foldChange": "0.9356"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".171565394803522",
						"qValue": ".985830071188835",
						"foldChange": "0.6979"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".930465036921469",
						"qValue": ".379647432350138",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".033621817920648",
						"qValue": ".027096312931568",
						"foldChange": "1.4570"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".398360051247256",
						"qValue": ".093091139631097",
						"foldChange": "1.2650"
					}
				]
			},
			{
				"metaboliteId": "0078db7f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f92e-0000-0000-0000-000000000000",
				"chemicalName": "2-acetamidophenol sulfate",
				"plotName": null,
				"compoundId": "0000bdc4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JCVGTUVGDOWZJE-UHFFFAOYAS",
				"kegg": null,
				"mass": 231.020143,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4785,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".284121257880402",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".112038773825311",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".462972122213127",
						"qValue": ".984973190008428",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".168490076705443",
						"qValue": "1",
						"foldChange": "5.1986"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".489258796802277",
						"qValue": "1",
						"foldChange": "1.6801"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".486490184422364",
						"qValue": "1",
						"foldChange": "1.5734"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".29619301504354",
						"qValue": ".911265664999995",
						"foldChange": "2.8101"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".014700956651967",
						"qValue": ".567215491640796",
						"foldChange": "5.8027"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".146746950062698",
						"qValue": ".957839854315834",
						"foldChange": "2.9900"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".570513504754592",
						"qValue": "1",
						"foldChange": "1.4690"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".772674272537841",
						"qValue": "1",
						"foldChange": "1.4310"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".780140236006177",
						"qValue": "1",
						"foldChange": "1.2006"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".855721563054114",
						"qValue": "1",
						"foldChange": "0.5408"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".553623614668114",
						"qValue": "1",
						"foldChange": "0.3320"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".666332419068258",
						"qValue": "1",
						"foldChange": "0.6139"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".601817845023127",
						"qValue": "1",
						"foldChange": "0.5323"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".218925143904917",
						"qValue": "1",
						"foldChange": "0.3998"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".447597325928413",
						"qValue": "1",
						"foldChange": "0.7512"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".202525814826759",
						"qValue": "1",
						"foldChange": "1.9064"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".36492598443956",
						"qValue": "1",
						"foldChange": "0.5938"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".032880208638653",
						"qValue": ".985830071188835",
						"foldChange": "0.3115"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".644548754847659",
						"qValue": ".304427999840678",
						"foldChange": "0.7302"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052763277949885",
						"qValue": ".038471747600022",
						"foldChange": "3.4590"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".534685931641913",
						"qValue": ".116084243862522",
						"foldChange": "1.4329"
					}
				]
			},
			{
				"metaboliteId": "0078db80-0000-0000-0000-000000000000",
				"chemicalId": "05f61dfa-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-myristoyl-glycerol (16:0/14:0) [2]",
				"plotName": null,
				"compoundId": "0000e014-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07095",
				"inChiKey": null,
				"kegg": null,
				"mass": 540.475375,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2598,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".983198119560468",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".161738335799851",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".551925435788748",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".158550657541692",
						"qValue": "1",
						"foldChange": "1.5768"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".273119435147731",
						"qValue": "1",
						"foldChange": "1.4206"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".747616378963338",
						"qValue": "1",
						"foldChange": "1.0386"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".440655663375037",
						"qValue": "1",
						"foldChange": "1.6702"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".110871501882394",
						"qValue": ".697458782960496",
						"foldChange": "2.2240"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".401357276296751",
						"qValue": ".957839854315834",
						"foldChange": "1.8136"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".427131930476298",
						"qValue": "1",
						"foldChange": "0.9007"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".507088816721939",
						"qValue": "1",
						"foldChange": "1.2881"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".148277311864603",
						"qValue": ".960252848686774",
						"foldChange": "1.4126"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".902033872220277",
						"qValue": "1",
						"foldChange": "1.5503"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".775541530359037",
						"qValue": "1",
						"foldChange": "1.3646"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".862656381128595",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".85512504573898",
						"qValue": "1",
						"foldChange": "0.9278"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".50799129232184",
						"qValue": "1",
						"foldChange": "0.4697"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".615241152011873",
						"qValue": "1",
						"foldChange": "0.5063"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".754182194891117",
						"qValue": "1",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".905307716108812",
						"qValue": "1",
						"foldChange": "0.7674"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".85587290852494",
						"qValue": ".997573179629986",
						"foldChange": "0.7791"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".734061420265839",
						"qValue": ".328877982604021",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".452512162494005",
						"qValue": ".188049785570147",
						"foldChange": "1.3717"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".736638714792798",
						"qValue": ".150679093764813",
						"foldChange": "1.7976"
					}
				]
			},
			{
				"metaboliteId": "0078db81-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e8-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylphenylalanine",
				"plotName": null,
				"compoundId": "0000849e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00512",
				"inChiKey": "CBQJSKKFNMDLON-JTQLQIEIBY",
				"kegg": "C03519",
				"mass": 207.089543,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".796866833777279",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".975174809057764",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".726011060745586",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".695078236704172",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".810931900374116",
						"qValue": "1",
						"foldChange": "0.9913"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".878418175554238",
						"qValue": "1",
						"foldChange": "1.0457"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".197743999453027",
						"qValue": ".797536225282113",
						"foldChange": "1.2005"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".472130075951237",
						"qValue": ".902562957068497",
						"foldChange": "1.1931"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".563719421581453",
						"qValue": ".957839854315834",
						"foldChange": "1.0044"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".619366921715422",
						"qValue": "1",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".932852244057751",
						"qValue": "1",
						"foldChange": "1.0059"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".679780438277697",
						"qValue": "1",
						"foldChange": "1.0033"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".92085948010669",
						"qValue": "1",
						"foldChange": "0.9185"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".533063257743854",
						"qValue": "1",
						"foldChange": "1.1760"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".463206175109694",
						"qValue": "1",
						"foldChange": "1.2803"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".539446950860955",
						"qValue": "1",
						"foldChange": "1.1033"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".570341652670671",
						"qValue": "1",
						"foldChange": "1.1611"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".989375761656846",
						"qValue": "1",
						"foldChange": "1.0524"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".759140062897705",
						"qValue": "1",
						"foldChange": "1.1651"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".493309027118985",
						"qValue": "1",
						"foldChange": "1.2261"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".684230646252975",
						"qValue": ".990272584968167",
						"foldChange": "1.0523"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011431812008216",
						"qValue": ".016972938964083",
						"foldChange": "2.6034"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00295383996799",
						"qValue": ".004428321306399",
						"foldChange": "2.2984"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006132045878277",
						"qValue": ".003748613394408",
						"foldChange": "1.8279"
					}
				]
			},
			{
				"metaboliteId": "0078db82-0000-0000-0000-000000000000",
				"chemicalId": "05f5f91d-0000-0000-0000-000000000000",
				"chemicalName": "pregnanolone/allopregnanolone sulfate",
				"plotName": null,
				"compoundId": "0000bd0a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 398.212695,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2972,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".382853426703021",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".121877240370116",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".354039118218681",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".244064239449017",
						"qValue": "1",
						"foldChange": "1.8128"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".180709416565173",
						"qValue": "1",
						"foldChange": "0.8087"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".014460189194281",
						"qValue": "1",
						"foldChange": "0.8527"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".317210714458568",
						"qValue": ".940254050726303",
						"foldChange": "1.4417"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".937186092469076",
						"qValue": "1",
						"foldChange": "1.1062"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".281173604337356",
						"qValue": ".957839854315834",
						"foldChange": "0.8405"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".342556523058954",
						"qValue": "1",
						"foldChange": "0.8476"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".344730978354771",
						"qValue": "1",
						"foldChange": "0.8606"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".996546808011289",
						"qValue": "1",
						"foldChange": "1.0281"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".166226173983233",
						"qValue": "1",
						"foldChange": "0.1823"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".496437449335305",
						"qValue": "1",
						"foldChange": "0.2476"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".509246494879113",
						"qValue": "1",
						"foldChange": "1.3580"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".138057088528633",
						"qValue": "1",
						"foldChange": "0.0414"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".104508923080066",
						"qValue": "1",
						"foldChange": "0.0345"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".81328996124375",
						"qValue": "1",
						"foldChange": "0.8326"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".424261688251409",
						"qValue": "1",
						"foldChange": "0.3735"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".597687026281532",
						"qValue": "1",
						"foldChange": "0.4084"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".813425567900178",
						"qValue": ".997573179629986",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".393815640563441",
						"qValue": ".212359369717559",
						"foldChange": "1.1851"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".61782655157318",
						"qValue": ".24094927999131",
						"foldChange": "0.9051"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".521246578729548",
						"qValue": ".114535174484343",
						"foldChange": "2.3550"
					}
				]
			},
			{
				"metaboliteId": "0078db83-0000-0000-0000-000000000000",
				"chemicalId": "05f61df9-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyl-sphingadienine (d18:2/16:0)*",
				"plotName": null,
				"compoundId": "0000e048-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 535.496445,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2720,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".322669602349727",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".398807550552388",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".603696279989764",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".684482329483607",
						"qValue": "1",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".467755253280882",
						"qValue": "1",
						"foldChange": "1.0712"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".748004995549338",
						"qValue": "1",
						"foldChange": "1.0368"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".51427229452106",
						"qValue": "1",
						"foldChange": "1.0748"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".973099428654724",
						"qValue": "1",
						"foldChange": "1.0076"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".492912227654052",
						"qValue": ".957839854315834",
						"foldChange": "0.9775"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".476537921607631",
						"qValue": "1",
						"foldChange": "1.0557"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".279643037621479",
						"qValue": "1",
						"foldChange": "0.9480"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".076404116421056",
						"qValue": ".770965175880612",
						"foldChange": "0.8583"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".919858787912415",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".150203379803322",
						"qValue": "1",
						"foldChange": "1.2081"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".167682460791728",
						"qValue": "1",
						"foldChange": "1.1732"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".8340277348935",
						"qValue": "1",
						"foldChange": "1.0701"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".109714390062086",
						"qValue": "1",
						"foldChange": "1.2794"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".148603473261063",
						"qValue": "1",
						"foldChange": "1.1955"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".773799191671592",
						"qValue": "1",
						"foldChange": "0.9898"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".581406092713014",
						"qValue": "1",
						"foldChange": "1.0789"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".400807270256119",
						"qValue": ".985830071188835",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".12412037667125",
						"qValue": ".096241425791157",
						"foldChange": "1.2782"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052335751216962",
						"qValue": ".038471747600022",
						"foldChange": "1.3178"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001143710923248",
						"qValue": ".001142136931488",
						"foldChange": "1.5369"
					}
				]
			},
			{
				"metaboliteId": "0078db84-0000-0000-0000-000000000000",
				"chemicalId": "05f61e09-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl-N-behenoyl-sphingadienine (d18:2/22:0)*",
				"plotName": null,
				"compoundId": "0000e04d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 781.643169,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2756,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".976838452991352",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".919975845170393",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".435495271136415",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".903204561700151",
						"qValue": "1",
						"foldChange": "1.0233"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".495307813668867",
						"qValue": "1",
						"foldChange": "1.0656"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".574792306489194",
						"qValue": "1",
						"foldChange": "1.0703"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".84027854749881",
						"qValue": "1",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".59257888562764",
						"qValue": ".928868848501546",
						"foldChange": "1.0861"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".738279717744157",
						"qValue": ".976023509428735",
						"foldChange": "1.0359"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".421909136772109",
						"qValue": "1",
						"foldChange": "0.9445"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".084859022712885",
						"qValue": "1",
						"foldChange": "0.8927"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".347130322695902",
						"qValue": "1",
						"foldChange": "0.8920"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".832878654623812",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".51044564784516",
						"qValue": "1",
						"foldChange": "1.1563"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".637492740465854",
						"qValue": "1",
						"foldChange": "1.1190"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".807168001467116",
						"qValue": "1",
						"foldChange": "1.0320"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".824951173425325",
						"qValue": "1",
						"foldChange": "1.1372"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".992121491243161",
						"qValue": "1",
						"foldChange": "1.1020"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".898340145779621",
						"qValue": "1",
						"foldChange": "0.9775"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".630387297253686",
						"qValue": "1",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".538060085443899",
						"qValue": ".985830071188835",
						"foldChange": "0.9386"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".922068937523145",
						"qValue": ".378764696310446",
						"foldChange": "1.1135"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".276481786779264",
						"qValue": ".132158837497075",
						"foldChange": "1.1234"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".05663709416122",
						"qValue": ".020961544936936",
						"foldChange": "1.4355"
					}
				]
			},
			{
				"metaboliteId": "0078db85-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9f1-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxymetoprolol",
				"plotName": null,
				"compoundId": "0000d5d2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60994",
				"inChiKey": "OFRYBPCSEMMZHR-UHFFFAOYAO",
				"kegg": null,
				"mass": 283.178358,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4963,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".565715606942376",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".322076824615878",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".710666655453048",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".788198269920305",
						"qValue": "1",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".739220570517389",
						"qValue": ".957498790731047",
						"foldChange": "1.0114"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".548045177095667",
						"qValue": ".957839854315834",
						"foldChange": "1.0215"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".440402529369028",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".249599798965963",
						"qValue": "1",
						"foldChange": "1.0592"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".057842316766348",
						"qValue": ".727024127920748",
						"foldChange": "1.0732"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".386665173236484",
						"qValue": "1",
						"foldChange": "1.7805"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".351074504840966",
						"qValue": "1",
						"foldChange": "1.5044"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".905422211222748",
						"qValue": "1",
						"foldChange": "0.8450"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".40566114023895",
						"qValue": "1",
						"foldChange": "1.7071"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".407223470897996",
						"qValue": "1",
						"foldChange": "1.5352"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".965064477322598",
						"qValue": "1",
						"foldChange": "0.8993"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".36393617311349",
						"qValue": "1",
						"foldChange": "1.8807"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".277166300164148",
						"qValue": "1",
						"foldChange": "1.7155"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".810457913402009",
						"qValue": ".997573179629986",
						"foldChange": "0.9122"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".264190215905747",
						"qValue": ".163803173417331",
						"foldChange": "1.7155"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.8807"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078db86-0000-0000-0000-000000000000",
				"chemicalId": "05f605ad-0000-0000-0000-000000000000",
				"chemicalName": "lisinopril",
				"plotName": null,
				"compoundId": "0000d603-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RLAWWYSOJDYHDC-UHFFFAOYAB",
				"kegg": null,
				"mass": 405.226371,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4991,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".146123873032011",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".318873899417101",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".331364565210256",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".011460663535114",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".342494476016551",
						"qValue": "1",
						"foldChange": "0.9968"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".101314322158149",
						"qValue": "1",
						"foldChange": "1.0060"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".022117443479187",
						"qValue": "1",
						"foldChange": "0.9916"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".030128531763851",
						"qValue": "1",
						"foldChange": "0.9916"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".141108739901973",
						"qValue": "1",
						"foldChange": "0.9940"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".163674279685184",
						"qValue": "1",
						"foldChange": "0.9940"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.0060"
					}
				]
			},
			{
				"metaboliteId": "0078db87-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-oleoyl-glycerol (16:0/18:1) [1]*",
				"plotName": null,
				"compoundId": "0000d69f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07102",
				"inChiKey": null,
				"kegg": "C13861",
				"mass": 594.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2605,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".895504537531683",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".309460699738479",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".025115064040946",
						"qValue": ".647664227237728",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".681280727503719",
						"qValue": "1",
						"foldChange": "1.0706"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".348179512626354",
						"qValue": "1",
						"foldChange": "0.9627"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".179986188449311",
						"qValue": "1",
						"foldChange": "0.8967"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".002412236471502",
						"qValue": ".138740634931099",
						"foldChange": "1.4459"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".093359674711645",
						"qValue": ".697458782960496",
						"foldChange": "1.2369"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".142336484381738",
						"qValue": ".957839854315834",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".451819230332314",
						"qValue": "1",
						"foldChange": "0.9662"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".309267173817137",
						"qValue": "1",
						"foldChange": "1.1512"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".080381681420987",
						"qValue": ".770965175880612",
						"foldChange": "1.1612"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".515321353371022",
						"qValue": "1",
						"foldChange": "0.9054"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".608950794370767",
						"qValue": "1",
						"foldChange": "0.9372"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".914539484586194",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".84782334758677",
						"qValue": "1",
						"foldChange": "0.9542"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".380417072641477",
						"qValue": "1",
						"foldChange": "0.6699"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".280507978713147",
						"qValue": "1",
						"foldChange": "0.7021"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".861563578194361",
						"qValue": "1",
						"foldChange": "0.8995"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".914659001178772",
						"qValue": "1",
						"foldChange": "0.9088"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".952815035877758",
						"qValue": ".999206196652123",
						"foldChange": "1.0102"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".756483207658599",
						"qValue": ".334795447198846",
						"foldChange": "1.3122"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".08395093931633",
						"qValue": ".053814760149294",
						"foldChange": "1.5513"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".349706637753966",
						"qValue": ".083791962309499",
						"foldChange": "1.7560"
					}
				]
			},
			{
				"metaboliteId": "0078db88-0000-0000-0000-000000000000",
				"chemicalId": "05f6044e-0000-0000-0000-000000000000",
				"chemicalName": "myristoyl dihydrosphingomyelin (d18:0/14:0)*",
				"plotName": null,
				"compoundId": "0000e015-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12085",
				"inChiKey": null,
				"kegg": null,
				"mass": 676.551925,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2786,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".226742380627111",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".493304093504433",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".144678889485852",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".477313856297032",
						"qValue": "1",
						"foldChange": "1.1076"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".157814044628178",
						"qValue": "1",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".475865171493206",
						"qValue": "1",
						"foldChange": "1.1118"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".361767742810926",
						"qValue": ".951252030575746",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".761757175141721",
						"qValue": ".970414150176159",
						"foldChange": "1.0526"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".226295690842442",
						"qValue": ".957839854315834",
						"foldChange": "1.1545"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".063821706897068",
						"qValue": ".821720595261736",
						"foldChange": "1.2267"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".98214100720106",
						"qValue": "1",
						"foldChange": "1.0513"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".066921216928916",
						"qValue": ".727024127920748",
						"foldChange": "0.8122"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".160402464269478",
						"qValue": "1",
						"foldChange": "1.2190"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".128787156309067",
						"qValue": "1",
						"foldChange": "1.2341"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".840013115938646",
						"qValue": "1",
						"foldChange": "1.0124"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".681692659769866",
						"qValue": "1",
						"foldChange": "1.0769"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".02457788446439",
						"qValue": "1",
						"foldChange": "1.4034"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".053214194733148",
						"qValue": ".822195137696456",
						"foldChange": "1.3031"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".489678155104601",
						"qValue": "1",
						"foldChange": "1.1699"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".489715915406171",
						"qValue": "1",
						"foldChange": "1.0939"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".969238373829231",
						"qValue": "1",
						"foldChange": "0.9351"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".698630633610232",
						"qValue": ".320657800180001",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".603515094036101",
						"qValue": ".237010564156036",
						"foldChange": "1.1176"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".475455266000758",
						"qValue": ".107362019958917",
						"foldChange": "1.0522"
					}
				]
			},
			{
				"metaboliteId": "0078db89-0000-0000-0000-000000000000",
				"chemicalId": "05f5e913-0000-0000-0000-000000000000",
				"chemicalName": "pregnenediol sulfate (C21H34O5S)*",
				"plotName": null,
				"compoundId": "00007f6b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 398.212695,
				"pathwayType": "ANIMAL",
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2943,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".435158791811516",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".523200487081986",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".544042486084331",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".824370398630442",
						"qValue": "1",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".895401496888384",
						"qValue": "1",
						"foldChange": "0.9911"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".927892714245718",
						"qValue": "1",
						"foldChange": "1.0544"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".03948900570474",
						"qValue": ".436430439671866",
						"foldChange": "3.8354"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".190001141244303",
						"qValue": ".821388069834477",
						"foldChange": "2.9348"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".4349321114647",
						"qValue": ".957839854315834",
						"foldChange": "0.8980"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".8453655457684",
						"qValue": "1",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".915332316414098",
						"qValue": "1",
						"foldChange": "0.9875"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".763222527351241",
						"qValue": "1",
						"foldChange": "0.9175"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".339194994881664",
						"qValue": "1",
						"foldChange": "1.1005"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".462147022837816",
						"qValue": "1",
						"foldChange": "1.6232"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".098177261053102",
						"qValue": "1",
						"foldChange": "1.4750"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".878283585380145",
						"qValue": "1",
						"foldChange": "1.3647"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".349315147103624",
						"qValue": "1",
						"foldChange": "1.5372"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".416395595222881",
						"qValue": "1",
						"foldChange": "1.1263"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".790523975931588",
						"qValue": "1",
						"foldChange": "1.1858"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".456816256391353",
						"qValue": "1",
						"foldChange": "1.4170"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".310137893416414",
						"qValue": ".985830071188835",
						"foldChange": "1.1950"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".945999181657498",
						"qValue": ".384780623741842",
						"foldChange": "1.1894"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".637701792164583",
						"qValue": ".24697343791802",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".845572504126061",
						"qValue": ".168460592304926",
						"foldChange": "0.8697"
					}
				]
			},
			{
				"metaboliteId": "0078db8a-0000-0000-0000-000000000000",
				"chemicalId": "0000006f-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyisobutyrate",
				"plotName": null,
				"compoundId": "0000060d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00336",
				"inChiKey": "DBXBTMSZEOQQDU-REWHXWOFAW",
				"kegg": "C06001",
				"mass": 104.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 365,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".628397389944534",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".919107285142104",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".505470414192137",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".807011731627352",
						"qValue": "1",
						"foldChange": "1.0730"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".84898543265716",
						"qValue": "1",
						"foldChange": "1.0129"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".664014021966089",
						"qValue": "1",
						"foldChange": "1.0199"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".482201163594679",
						"qValue": "1",
						"foldChange": "1.0303"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".171625455966587",
						"qValue": ".789529107655781",
						"foldChange": "0.8777"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".500106688596747",
						"qValue": ".957839854315834",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".811505233264905",
						"qValue": "1",
						"foldChange": "0.9999"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".361109582521501",
						"qValue": "1",
						"foldChange": "1.1845"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".251037100125857",
						"qValue": "1",
						"foldChange": "1.1815"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".703142343285152",
						"qValue": "1",
						"foldChange": "0.8748"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".734012352100883",
						"qValue": "1",
						"foldChange": "0.8880"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".983138139755943",
						"qValue": "1",
						"foldChange": "1.0151"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".342257869316505",
						"qValue": "1",
						"foldChange": "0.8694"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".52606759057296",
						"qValue": "1",
						"foldChange": "0.8038"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".784169694263136",
						"qValue": "1",
						"foldChange": "0.9245"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".281488671884143",
						"qValue": "1",
						"foldChange": "0.7145"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".725566994545256",
						"qValue": "1",
						"foldChange": "0.9024"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".163434069998618",
						"qValue": ".985830071188835",
						"foldChange": "1.2629"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".1979712406205",
						"qValue": ".1333857097504",
						"foldChange": "0.7849"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".704912271349717",
						"qValue": ".266432367171315",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".550657746800167",
						"qValue": ".118676281918129",
						"foldChange": "0.8748"
					}
				]
			},
			{
				"metaboliteId": "0078db8b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c3-0000-0000-0000-000000000000",
				"chemicalName": "maleate",
				"plotName": null,
				"compoundId": "000050c4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00176",
				"inChiKey": "VZCYOOQTPOCHFL-UPHRSURJBG",
				"kegg": "C01384",
				"mass": 116.010959,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1495,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".191980553387163",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".636529165763176",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".955116245312272",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".847993849385887",
						"qValue": "1",
						"foldChange": "1.3701"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".829031136875729",
						"qValue": "1",
						"foldChange": "1.0806"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".980628135430574",
						"qValue": "1",
						"foldChange": "1.3004"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".623402300207816",
						"qValue": "1",
						"foldChange": "1.2949"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".668700071107213",
						"qValue": ".93517659928016",
						"foldChange": "1.5541"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".359693314601703",
						"qValue": ".957839854315834",
						"foldChange": "1.8510"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".9099604170172",
						"qValue": "1",
						"foldChange": "1.0383"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".520544713359274",
						"qValue": "1",
						"foldChange": "1.3276"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".450408741840304",
						"qValue": "1",
						"foldChange": "1.2909"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".358174132661944",
						"qValue": "1",
						"foldChange": "1.3507"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".571255832402747",
						"qValue": "1",
						"foldChange": "0.7651"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".144083879844487",
						"qValue": "1",
						"foldChange": "0.5664"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".491035589382884",
						"qValue": "1",
						"foldChange": "1.1714"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".610692315949991",
						"qValue": "1",
						"foldChange": "0.7272"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".236633490019219",
						"qValue": "1",
						"foldChange": "0.6208"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".15118526617964",
						"qValue": "1",
						"foldChange": "1.7917"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".87983472453473",
						"qValue": "1",
						"foldChange": "1.0850"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".215134062249817",
						"qValue": ".985830071188835",
						"foldChange": "0.6055"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000631571129746",
						"qValue": ".001834274195873",
						"foldChange": "0.3852"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005499328670615",
						"qValue": ".006876539142905",
						"foldChange": "0.5240"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000029544232945",
						"qValue": ".000118750031432",
						"foldChange": "0.3174"
					}
				]
			},
			{
				"metaboliteId": "0078db8c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e10a-0000-0000-0000-000000000000",
				"chemicalName": "3-phenylpropionate (hydrocinnamate)",
				"plotName": null,
				"compoundId": "00003d85-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00764",
				"inChiKey": "XMIIGOLPHOKFCH-UHFFFAOYAR",
				"kegg": "C05629",
				"mass": 150.06808,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4153,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".187975514367865",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".160607248405564",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".565865074812276",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".919748656731094",
						"qValue": "1",
						"foldChange": "1.1266"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".312014801020916",
						"qValue": "1",
						"foldChange": "1.4637"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".361923921680259",
						"qValue": "1",
						"foldChange": "1.2884"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".226365422988673",
						"qValue": ".852077213869098",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".291120798806463",
						"qValue": ".825038702902081",
						"foldChange": "1.3460"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".02627607728584",
						"qValue": ".587380998803463",
						"foldChange": "1.6891"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".400618447367974",
						"qValue": "1",
						"foldChange": "1.1294"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".562078351267462",
						"qValue": "1",
						"foldChange": "1.3113"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".792728904803174",
						"qValue": "1",
						"foldChange": "1.1438"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".133287957724169",
						"qValue": "1",
						"foldChange": "0.4244"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".976944561782417",
						"qValue": "1",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".13783625800867",
						"qValue": "1",
						"foldChange": "2.2585"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".057528829980822",
						"qValue": "1",
						"foldChange": "0.4147"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".769265577471689",
						"qValue": "1",
						"foldChange": "0.9860"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".117482284754893",
						"qValue": ".908885675694672",
						"foldChange": "2.3776"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".132534015442692",
						"qValue": "1",
						"foldChange": "0.4637"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".617973716878067",
						"qValue": "1",
						"foldChange": "0.8182"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".33347093811045",
						"qValue": ".985830071188835",
						"foldChange": "1.7645"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".913225785417005",
						"qValue": ".375603061627182",
						"foldChange": "1.4963"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".081710848722904",
						"qValue": ".052987859011555",
						"foldChange": "0.5634"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".347053237656447",
						"qValue": ".083280304277061",
						"foldChange": "1.9373"
					}
				]
			},
			{
				"metaboliteId": "0078db8d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3e7-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyoctanoate",
				"plotName": null,
				"compoundId": "00005614-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02264",
				"inChiKey": "JKRDADVRIYVCCY-UHFFFAOYAG",
				"kegg": null,
				"mass": 160.109944,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1731,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".280715881305783",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".967346005133386",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".037458264279364",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".12037809873866",
						"qValue": "1",
						"foldChange": "1.2180"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".889782412637953",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".155801575785044",
						"qValue": "1",
						"foldChange": "0.9080"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007827527075841",
						"qValue": ".237900912197889",
						"foldChange": "0.7927"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".275868856449167",
						"qValue": ".825038702902081",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".10072094407649",
						"qValue": ".861821748215854",
						"foldChange": "1.2414"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".551334248900346",
						"qValue": "1",
						"foldChange": "1.1094"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".604509409567421",
						"qValue": "1",
						"foldChange": "1.1314"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".937657323159663",
						"qValue": "1",
						"foldChange": "0.9560"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".776621027181529",
						"qValue": "1",
						"foldChange": "0.9262"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".98782440715323",
						"qValue": "1",
						"foldChange": "1.0016"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".795459165589672",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".010458676960952",
						"qValue": "1",
						"foldChange": "0.5427"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".601561380495874",
						"qValue": "1",
						"foldChange": "0.7711"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".045142080995987",
						"qValue": ".822195137696456",
						"foldChange": "1.4207"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".341347647573434",
						"qValue": "1",
						"foldChange": "0.7827"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".835993074448062",
						"qValue": "1",
						"foldChange": "0.8778"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".25864148858021",
						"qValue": ".985830071188835",
						"foldChange": "1.1215"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".042371989606409",
						"qValue": ".044536403371619",
						"foldChange": "0.6878"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00004732675964",
						"qValue": ".000377054242849",
						"foldChange": "0.4738"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".028815632223245",
						"qValue": ".012453924207943",
						"foldChange": "0.6646"
					}
				]
			},
			{
				"metaboliteId": "0078db8e-0000-0000-0000-000000000000",
				"chemicalId": "00000475-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyphenylpyruvate",
				"plotName": null,
				"compoundId": "00000685-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00707",
				"inChiKey": "KKADPXVIOXHVKN-UHFFFAOYAH",
				"kegg": "C01179",
				"mass": 180.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".679081307180457",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".134662937724623",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".377696032962855",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".308470727382058",
						"qValue": "1",
						"foldChange": "0.9025"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".79207101244517",
						"qValue": "1",
						"foldChange": "1.1092"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".201701772220059",
						"qValue": "1",
						"foldChange": "1.2968"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".120760752191126",
						"qValue": ".703886302155126",
						"foldChange": "0.8307"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".070115730430646",
						"qValue": ".687896186587066",
						"foldChange": "0.9222"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".786159159486686",
						"qValue": ".991621675660644",
						"foldChange": "1.0963"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".695872278896641",
						"qValue": "1",
						"foldChange": "0.9608"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".113987848796705",
						"qValue": "1",
						"foldChange": "0.8334"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".22970596574694",
						"qValue": "1",
						"foldChange": "0.8105"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".298859906202204",
						"qValue": "1",
						"foldChange": "1.2097"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".312223315853542",
						"qValue": "1",
						"foldChange": "1.1601"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".977033141541485",
						"qValue": "1",
						"foldChange": "0.9589"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".515702985570364",
						"qValue": "1",
						"foldChange": "1.0889"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".137483214724643",
						"qValue": "1",
						"foldChange": "1.1916"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".367063779840651",
						"qValue": "1",
						"foldChange": "1.0943"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".517570052877822",
						"qValue": "1",
						"foldChange": "0.9012"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".585944562129743",
						"qValue": "1",
						"foldChange": "0.8085"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".944824686666184",
						"qValue": ".997575444606604",
						"foldChange": "0.8972"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".85222839844078",
						"qValue": ".359445609851214",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".121490673667147",
						"qValue": ".071021471683003",
						"foldChange": "1.4042"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".203711272523969",
						"qValue": ".05617816025054",
						"foldChange": "1.3371"
					}
				]
			},
			{
				"metaboliteId": "0078db8f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fd-0000-0000-0000-000000000000",
				"chemicalName": "10-nonadecenoate (19:1n9)",
				"plotName": null,
				"compoundId": "000084b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13622",
				"inChiKey": "BBOWBNGUEWHNQZ-KTKRTIGZBX",
				"kegg": null,
				"mass": 296.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1367,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".914180635393207",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".19341139413116",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".228301882662368",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".737178302295828",
						"qValue": "1",
						"foldChange": "1.1195"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".699958511814185",
						"qValue": "1",
						"foldChange": "1.1220"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".960213469608597",
						"qValue": "1",
						"foldChange": "1.0959"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".071526727313756",
						"qValue": ".593497652236823",
						"foldChange": "1.4812"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".217776878195633",
						"qValue": ".825038702902081",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".003310124167599",
						"qValue": ".156495314812598",
						"foldChange": "0.7192"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".770478763739973",
						"qValue": "1",
						"foldChange": "1.1435"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".888183647817497",
						"qValue": "1",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".879783615425798",
						"qValue": "1",
						"foldChange": "1.0811"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".835198076043041",
						"qValue": "1",
						"foldChange": "0.9953"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".86290475598581",
						"qValue": "1",
						"foldChange": "1.0002"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".705320575061237",
						"qValue": "1",
						"foldChange": "1.0049"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".518228102974501",
						"qValue": "1",
						"foldChange": "1.2548"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".874793298341861",
						"qValue": "1",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".641635183540244",
						"qValue": "1",
						"foldChange": "0.7520"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".249240729541742",
						"qValue": "1",
						"foldChange": "0.8167"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".971036660664899",
						"qValue": "1",
						"foldChange": "0.9139"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".24893843135589",
						"qValue": ".985830071188835",
						"foldChange": "1.1189"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".286834193383769",
						"qValue": ".173616275101863",
						"foldChange": "1.2405"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".639022479223613",
						"qValue": ".247141670162749",
						"foldChange": "1.3806"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".301447536208443",
						"qValue": ".075859325332038",
						"foldChange": "1.3526"
					}
				]
			},
			{
				"metaboliteId": "0078db90-0000-0000-0000-000000000000",
				"chemicalId": "00000047-0000-0000-0000-000000000000",
				"chemicalName": "5-hydroxyindoleacetate",
				"plotName": null,
				"compoundId": "000001b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00763",
				"inChiKey": "DUUGKQCEGZLZNO-UHFFFAOYAY",
				"kegg": "C05635",
				"mass": 191.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 262,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".740572211960537",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".438985796659067",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".576736514505992",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".548343818127998",
						"qValue": "1",
						"foldChange": "1.0167"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".944354737446099",
						"qValue": "1",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".595560409222941",
						"qValue": "1",
						"foldChange": "1.1734"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".846038649053092",
						"qValue": "1",
						"foldChange": "1.0997"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".105278103591378",
						"qValue": ".697458782960496",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".071038080890871",
						"qValue": ".785109179715988",
						"foldChange": "0.8402"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".891782227204575",
						"qValue": "1",
						"foldChange": "0.9915"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".617589814470655",
						"qValue": "1",
						"foldChange": "0.9836"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".716150518082332",
						"qValue": "1",
						"foldChange": "0.9141"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".697437839236821",
						"qValue": "1",
						"foldChange": "0.8529"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".797977263836137",
						"qValue": "1",
						"foldChange": "0.8401"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".908803713153281",
						"qValue": "1",
						"foldChange": "0.9849"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".793579752388075",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".918784874249008",
						"qValue": "1",
						"foldChange": "0.9843"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".881502453036742",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".108767531349577",
						"qValue": "1",
						"foldChange": "0.7715"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".538105327314353",
						"qValue": "1",
						"foldChange": "0.8374"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".349427680521142",
						"qValue": ".985830071188835",
						"foldChange": "1.0854"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000442309986528",
						"qValue": ".001435732104421",
						"foldChange": "3.0720"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000011355202892",
						"qValue": ".000175908773092",
						"foldChange": "2.8188"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00008697207347",
						"qValue": ".0002026523942",
						"foldChange": "2.4543"
					}
				]
			},
			{
				"metaboliteId": "0078db91-0000-0000-0000-000000000000",
				"chemicalId": "000003fd-0000-0000-0000-000000000000",
				"chemicalName": "5-oxoproline",
				"plotName": null,
				"compoundId": "000005d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00267",
				"inChiKey": "ODHCTXKNWHHXJC-VKHMYHEABY",
				"kegg": "C01879",
				"mass": 129.042594,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 499,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".813275645556263",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".361107922773667",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".814832362610137",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".808862966065378",
						"qValue": "1",
						"foldChange": "1.0664"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".962098002031311",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".772304572449362",
						"qValue": "1",
						"foldChange": "1.0128"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".527085785433459",
						"qValue": "1",
						"foldChange": "0.9751"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".394425584838712",
						"qValue": ".880987329915339",
						"foldChange": "0.9650"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".82507285049495",
						"qValue": "1",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".95406085649859",
						"qValue": "1",
						"foldChange": "1.0125"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".179693038170615",
						"qValue": "1",
						"foldChange": "0.9122"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".162223296216013",
						"qValue": ".969566045102463",
						"foldChange": "0.8660"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".980431761487293",
						"qValue": "1",
						"foldChange": "0.9720"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".62629342554369",
						"qValue": "1",
						"foldChange": "1.0059"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".634697315654957",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".543388272064931",
						"qValue": "1",
						"foldChange": "0.9205"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".71971121964737",
						"qValue": "1",
						"foldChange": "0.9757"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".340482241143148",
						"qValue": "1",
						"foldChange": "1.0599"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".583626172444713",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".618174818554976",
						"qValue": "1",
						"foldChange": "0.8960"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".983663667151255",
						"qValue": "1",
						"foldChange": "0.9276"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013783502848077",
						"qValue": ".019166391983209",
						"foldChange": "1.4051"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000125020408046",
						"qValue": ".000711459099243",
						"foldChange": "1.6391"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000029494686039",
						"qValue": ".000118750031432",
						"foldChange": "1.5916"
					}
				]
			},
			{
				"metaboliteId": "0078db92-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2bf-0000-0000-0000-000000000000",
				"chemicalName": "gentisate",
				"plotName": null,
				"compoundId": "00004768-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00152",
				"inChiKey": "WXTMDXOMEHJXQO-UHFFFAOYAO",
				"kegg": "C00628",
				"mass": 154.026609,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 201,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".486074034016986",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".003952850905274",
						"qValue": ".369620097659866",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".327129177753952",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".007401879255144",
						"qValue": "1",
						"foldChange": "5.8926"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".031173267199484",
						"qValue": "1",
						"foldChange": "4.6761"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".566736094010803",
						"qValue": "1",
						"foldChange": "1.1464"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".156805108831008",
						"qValue": ".742487134132407",
						"foldChange": "1.7393"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".007923120202909",
						"qValue": ".567215491640796",
						"foldChange": "4.0168"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".18890158920813",
						"qValue": ".957839854315834",
						"foldChange": "2.0224"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".238888633053105",
						"qValue": "1",
						"foldChange": "1.7127"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".746574694743085",
						"qValue": "1",
						"foldChange": "1.4663"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".389944101225627",
						"qValue": "1",
						"foldChange": "1.0240"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".944599176480234",
						"qValue": "1",
						"foldChange": "0.9105"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".163172979475749",
						"qValue": "1",
						"foldChange": "1.6259"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".135590837308904",
						"qValue": "1",
						"foldChange": "1.7857"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".328101630100731",
						"qValue": "1",
						"foldChange": "0.7050"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".627032324202486",
						"qValue": "1",
						"foldChange": "1.2459"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".151811246714067",
						"qValue": "1",
						"foldChange": "1.7673"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".848151672693898",
						"qValue": "1",
						"foldChange": "1.2516"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".780051994277418",
						"qValue": "1",
						"foldChange": "1.0208"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".919805779796496",
						"qValue": ".997573179629986",
						"foldChange": "0.8156"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".407329435168599",
						"qValue": ".216501028847437",
						"foldChange": "1.4658"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".705144688654749",
						"qValue": ".266432367171315",
						"foldChange": "1.3537"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".145392818640203",
						"qValue": ".043368579407762",
						"foldChange": "2.1237"
					}
				]
			},
			{
				"metaboliteId": "0078db93-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60c-0000-0000-0000-000000000000",
				"chemicalName": "bradykinin, des-arg(9)",
				"plotName": null,
				"compoundId": "00008674-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04246",
				"inChiKey": "VCEHWDBVPZFHAG-POFDKVPJBR",
				"kegg": "C00306",
				"mass": 903.460287,
				"pathwayType": "ANIMAL",
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 969,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".307908164845847",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".673107655677405",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".248996301558641",
						"qValue": ".962619864505407",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".446653012831617",
						"qValue": "1",
						"foldChange": "0.8735"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".889871588766632",
						"qValue": "1",
						"foldChange": "1.2256"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".532909104681105",
						"qValue": "1",
						"foldChange": "1.3521"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".018247562973367",
						"qValue": ".330397363624153",
						"foldChange": "6.6561"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".300509493598731",
						"qValue": ".825038702902081",
						"foldChange": "3.7081"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".168710358412288",
						"qValue": ".957839854315834",
						"foldChange": "0.8693"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".74595356662214",
						"qValue": "1",
						"foldChange": "0.7366"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".558034039400708",
						"qValue": "1",
						"foldChange": "0.6393"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".774187830596368",
						"qValue": "1",
						"foldChange": "0.8678"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".033395520155993",
						"qValue": "1",
						"foldChange": "7.6820"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "4.1578"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".039102578846667",
						"qValue": ".822195137696456",
						"foldChange": "0.5412"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".554951590208844",
						"qValue": "1",
						"foldChange": "2.7872"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".631412883523221",
						"qValue": "1",
						"foldChange": "1.8254"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".289442074156851",
						"qValue": ".985830071188835",
						"foldChange": "0.6549"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "2.4681"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".331632575862695",
						"qValue": ".152009550549949",
						"foldChange": "3.7684"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.3521"
					}
				]
			},
			{
				"metaboliteId": "0078db94-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6ac-0000-0000-0000-000000000000",
				"chemicalName": "isovalerylglycine",
				"plotName": null,
				"compoundId": "00008923-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00678",
				"inChiKey": "ZRQXMKMBBMNNQC-UHFFFAOYAA",
				"kegg": null,
				"mass": 159.089543,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".612976857856287",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".14041475745899",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".426013390457382",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".824312725921505",
						"qValue": "1",
						"foldChange": "1.2016"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".305022497202364",
						"qValue": "1",
						"foldChange": "1.4000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".419900293910888",
						"qValue": "1",
						"foldChange": "1.4952"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".085902234825383",
						"qValue": ".623082837721659",
						"foldChange": "0.9607"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".236573876282589",
						"qValue": ".825038702902081",
						"foldChange": "0.8879"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".581537121139109",
						"qValue": ".957839854315834",
						"foldChange": "1.3979"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".065782532481616",
						"qValue": ".821720595261736",
						"foldChange": "0.7192"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".365028997987047",
						"qValue": "1",
						"foldChange": "0.8688"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".337698412521488",
						"qValue": "1",
						"foldChange": "1.1597"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".953650581793182",
						"qValue": "1",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".605122055079397",
						"qValue": "1",
						"foldChange": "1.1928"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".635808685920792",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".236439405959482",
						"qValue": "1",
						"foldChange": "0.9186"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".383616535986531",
						"qValue": "1",
						"foldChange": "0.7241"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".795108253282478",
						"qValue": "1",
						"foldChange": "0.7882"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".170970425626795",
						"qValue": "1",
						"foldChange": "0.6775"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".460037638635566",
						"qValue": "1",
						"foldChange": "0.7414"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".561615920389626",
						"qValue": ".985830071188835",
						"foldChange": "1.0943"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000013806739753",
						"qValue": ".000169306726614",
						"foldChange": "6.2606"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001978785372805",
						"qValue": ".003327413202008",
						"foldChange": "2.8782"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002975951331495",
						"qValue": ".002236983909578",
						"foldChange": "2.9088"
					}
				]
			},
			{
				"metaboliteId": "0078db95-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e5-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglutamine",
				"plotName": null,
				"compoundId": "00008497-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06029",
				"inChiKey": "KSMRODHGGIIXDV-YFKPBYRVBV",
				"kegg": "C02716",
				"mass": 188.079707,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 46,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".635880028322116",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".665796273496726",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".364893856321298",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".681584103361206",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".19827515341532",
						"qValue": "1",
						"foldChange": "1.1664"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".092364534196486",
						"qValue": "1",
						"foldChange": "1.2705"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".755798754671551",
						"qValue": "1",
						"foldChange": "1.0802"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".537098312064899",
						"qValue": ".910963185640849",
						"foldChange": "1.0028"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".758743432760975",
						"qValue": ".98622264532067",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".4253416521877",
						"qValue": "1",
						"foldChange": "0.9361"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".219159780062814",
						"qValue": "1",
						"foldChange": "0.9227"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".661185606217508",
						"qValue": "1",
						"foldChange": "0.8963"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".593543989725097",
						"qValue": "1",
						"foldChange": "1.1796"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".194129126556169",
						"qValue": "1",
						"foldChange": "1.3250"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".409164252914677",
						"qValue": "1",
						"foldChange": "1.1232"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".556428047882971",
						"qValue": "1",
						"foldChange": "1.0719"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".267668981414126",
						"qValue": "1",
						"foldChange": "1.2789"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".566121645411823",
						"qValue": "1",
						"foldChange": "1.1932"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".718920017963561",
						"qValue": "1",
						"foldChange": "0.9489"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".886448346524077",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".621073599484661",
						"qValue": ".985830071188835",
						"foldChange": "1.0745"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002337975508434",
						"qValue": ".005126368819909",
						"foldChange": "2.6230"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".015184159005729",
						"qValue": ".014450678162379",
						"foldChange": "1.8447"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00036278404924",
						"qValue": ".000507190778988",
						"foldChange": "2.7147"
					}
				]
			},
			{
				"metaboliteId": "0078db96-0000-0000-0000-000000000000",
				"chemicalId": "05f5e201-0000-0000-0000-000000000000",
				"chemicalName": "glucuronate",
				"plotName": null,
				"compoundId": "00003c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00127",
				"inChiKey": "IAJILQKETJEXLJ-QTBDOELSBX",
				"kegg": "C00191",
				"mass": 194.042653,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1251,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".221342103732187",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".580160117169075",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".766850758502838",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".193436000460864",
						"qValue": "1",
						"foldChange": "0.8234"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".69213784405081",
						"qValue": "1",
						"foldChange": "0.9270"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".361851446881742",
						"qValue": "1",
						"foldChange": "1.7713"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".807620490814744",
						"qValue": "1",
						"foldChange": "1.5455"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".733660928428184",
						"qValue": ".957498790731047",
						"foldChange": "1.3549"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".559895314139383",
						"qValue": ".957839854315834",
						"foldChange": "1.5338"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".646119787614322",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".420621173364799",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".727685696399109",
						"qValue": "1",
						"foldChange": "1.0517"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".225628738418131",
						"qValue": "1",
						"foldChange": "0.6587"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".629939671454797",
						"qValue": "1",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".097900237069959",
						"qValue": "1",
						"foldChange": "1.5330"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".968009375834374",
						"qValue": "1",
						"foldChange": "0.8680"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".265860854140819",
						"qValue": "1",
						"foldChange": "1.2151"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".270776444503289",
						"qValue": "1",
						"foldChange": "1.3999"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".248920848496437",
						"qValue": "1",
						"foldChange": "0.7891"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".899270593752199",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".213309454893833",
						"qValue": ".985830071188835",
						"foldChange": "1.3115"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000003160247884",
						"qValue": ".000074737738445",
						"foldChange": "9.8467"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000280622329983",
						"qValue": ".001029612342646",
						"foldChange": "7.2716"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00001201325498",
						"qValue": ".0000703618314",
						"foldChange": "9.0282"
					}
				]
			},
			{
				"metaboliteId": "0078db97-0000-0000-0000-000000000000",
				"chemicalId": "000003d4-0000-0000-0000-000000000000",
				"chemicalName": "pentadecanoate (15:0)",
				"plotName": null,
				"compoundId": "00000551-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00826",
				"inChiKey": "WQEPLUUGTLDZJY-UHFFFAOYAK",
				"kegg": "C16537",
				"mass": 242.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1342,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".87085691280727",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".09145109553987",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".05106876349477",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".624500763445099",
						"qValue": "1",
						"foldChange": "1.0486"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".212727342299707",
						"qValue": "1",
						"foldChange": "1.1109"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".444933219498391",
						"qValue": "1",
						"foldChange": "1.0761"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".01476760557219",
						"qValue": ".303094620658292",
						"foldChange": "1.2273"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".268368280186419",
						"qValue": ".825038702902081",
						"foldChange": "0.9662"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000647888118919",
						"qValue": ".061261421022253",
						"foldChange": "0.7930"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".689445741872071",
						"qValue": "1",
						"foldChange": "1.0555"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".766390211540669",
						"qValue": "1",
						"foldChange": "0.9953"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".486798348125555",
						"qValue": "1",
						"foldChange": "0.9487"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".715805035630136",
						"qValue": "1",
						"foldChange": "1.0508"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".875707591163857",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".847236101054786",
						"qValue": "1",
						"foldChange": "0.9430"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".120905531966363",
						"qValue": "1",
						"foldChange": "1.2462"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".907107300663641",
						"qValue": "1",
						"foldChange": "1.0048"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".164933391312175",
						"qValue": "1",
						"foldChange": "0.8063"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".270197669756646",
						"qValue": "1",
						"foldChange": "0.9030"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".430511752932562",
						"qValue": "1",
						"foldChange": "0.8644"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".790773286207024",
						"qValue": ".997573179629986",
						"foldChange": "0.9572"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".169187899902128",
						"qValue": ".119471373382437",
						"foldChange": "1.2217"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".056228815981836",
						"qValue": ".040306440180514",
						"foldChange": "1.4044"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".052144390094145",
						"qValue": ".019726029021561",
						"foldChange": "1.3002"
					}
				]
			},
			{
				"metaboliteId": "0078db98-0000-0000-0000-000000000000",
				"chemicalId": "05f5e550-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltyrosine",
				"plotName": null,
				"compoundId": "00007e86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00866",
				"inChiKey": "CAHKINHBCWCHCF-JTQLQIEIBT",
				"kegg": null,
				"mass": 223.084458,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 153,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".93571627850597",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".530310875386452",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".834820529227246",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".651376166578095",
						"qValue": "1",
						"foldChange": "1.0600"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".896943319494213",
						"qValue": "1",
						"foldChange": "1.0515"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".561348054455013",
						"qValue": "1",
						"foldChange": "1.1844"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".61146637200891",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".375892821787686",
						"qValue": ".875723678608451",
						"foldChange": "1.0477"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".703804657332263",
						"qValue": ".976023509428735",
						"foldChange": "1.0701"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".807903990091404",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".249047791572461",
						"qValue": "1",
						"foldChange": "0.8824"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".360962998704225",
						"qValue": "1",
						"foldChange": "0.8300"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".729491747569739",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".595557003157917",
						"qValue": "1",
						"foldChange": "1.0740"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".834051921564327",
						"qValue": "1",
						"foldChange": "1.0754"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".742383286518079",
						"qValue": "1",
						"foldChange": "0.9567"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".5258876974918",
						"qValue": "1",
						"foldChange": "1.1033"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".739367629539127",
						"qValue": "1",
						"foldChange": "1.1532"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".837072766462604",
						"qValue": "1",
						"foldChange": "0.9362"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".843256791236472",
						"qValue": "1",
						"foldChange": "0.8348"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".997320637648854",
						"qValue": "1",
						"foldChange": "0.8917"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008181323751423",
						"qValue": ".01308579377988",
						"foldChange": "2.4789"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002394827360411",
						"qValue": ".003782101557081",
						"foldChange": "2.8420"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005923276032629",
						"qValue": ".003650229243504",
						"foldChange": "2.9303"
					}
				]
			},
			{
				"metaboliteId": "0078db99-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ae-0000-0000-0000-000000000000",
				"chemicalName": "myristoleate (14:1n5)",
				"plotName": null,
				"compoundId": "00007ea2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02000",
				"inChiKey": "YWWVWXASSLXJHU-WAYWQWQTBZ",
				"kegg": "C08322",
				"mass": 226.19328,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".955351429044487",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".499405324837761",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".71107395638907",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".705972318468259",
						"qValue": "1",
						"foldChange": "1.2262"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".610186102162219",
						"qValue": "1",
						"foldChange": "1.2211"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".894241441154403",
						"qValue": "1",
						"foldChange": "1.3449"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".913399888587224",
						"qValue": "1",
						"foldChange": "1.4381"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".182219346701289",
						"qValue": ".807649291889568",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".150018546343187",
						"qValue": ".957839854315834",
						"foldChange": "0.8586"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".494290186480995",
						"qValue": "1",
						"foldChange": "1.3342"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".948824283159611",
						"qValue": "1",
						"foldChange": "1.1086"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".454960866703962",
						"qValue": "1",
						"foldChange": "0.8558"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".489950204305487",
						"qValue": "1",
						"foldChange": "1.3122"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".792928659599371",
						"qValue": "1",
						"foldChange": "1.0603"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".688293923760474",
						"qValue": "1",
						"foldChange": "0.8080"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".613927878381221",
						"qValue": "1",
						"foldChange": "1.4698"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".626213659869342",
						"qValue": "1",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".99181885562276",
						"qValue": "1",
						"foldChange": "0.7104"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".598999114003681",
						"qValue": "1",
						"foldChange": "1.0726"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".912118907421534",
						"qValue": "1",
						"foldChange": "0.8245"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".691564664777294",
						"qValue": ".992447773567414",
						"foldChange": "0.7687"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".742024548015956",
						"qValue": ".330326907870435",
						"foldChange": "0.8556"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".98562279373084",
						"qValue": ".332055863302994",
						"foldChange": "1.3187"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".724669851052987",
						"qValue": ".1489890826214",
						"foldChange": "0.9863"
					}
				]
			},
			{
				"metaboliteId": "0078db9a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5a9-0000-0000-0000-000000000000",
				"chemicalName": "adrenate (22:4n6)",
				"plotName": null,
				"compoundId": "000080d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02226",
				"inChiKey": "TWSWSIQAPQLDBP-CGRWFSSPBH",
				"kegg": "C16527",
				"mass": 332.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1402,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".950965137022545",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".807428773230521",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".830867254688603",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".906719111056911",
						"qValue": "1",
						"foldChange": "1.2085"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".621550167116924",
						"qValue": "1",
						"foldChange": "1.2648"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".706307632725868",
						"qValue": "1",
						"foldChange": "1.2102"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".896149438889432",
						"qValue": "1",
						"foldChange": "1.2719"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".2867168837138",
						"qValue": ".825038702902081",
						"foldChange": "1.0213"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".348830489541532",
						"qValue": ".957839854315834",
						"foldChange": "1.0277"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".780191581168416",
						"qValue": "1",
						"foldChange": "1.1355"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".587965883848934",
						"qValue": "1",
						"foldChange": "0.9552"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".792286167603705",
						"qValue": "1",
						"foldChange": "1.2470"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".468390563742798",
						"qValue": "1",
						"foldChange": "1.3123"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".605473446406887",
						"qValue": "1",
						"foldChange": "1.1043"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".861735216447203",
						"qValue": "1",
						"foldChange": "0.8415"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".600981827394153",
						"qValue": "1",
						"foldChange": "1.1659"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".851075012006287",
						"qValue": "1",
						"foldChange": "0.9226"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".752996308049938",
						"qValue": "1",
						"foldChange": "0.7913"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".585066499909781",
						"qValue": "1",
						"foldChange": "0.8430"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".73744808486485",
						"qValue": "1",
						"foldChange": "0.8493"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".85248774100833",
						"qValue": ".997573179629986",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".951377428874306",
						"qValue": ".386375057388733",
						"foldChange": "0.9069"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".282604505836155",
						"qValue": ".134247462902032",
						"foldChange": "1.2755"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".3419312839527",
						"qValue": ".083280304277061",
						"foldChange": "1.2757"
					}
				]
			},
			{
				"metaboliteId": "0078db9b-0000-0000-0000-000000000000",
				"chemicalId": "0000014a-0000-0000-0000-000000000000",
				"chemicalName": "fumarate",
				"plotName": null,
				"compoundId": "0000066b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00134",
				"inChiKey": "VZCYOOQTPOCHFL-OWOJBTEDBF",
				"kegg": "C00122",
				"mass": 116.01096,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1293,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".272279693135916",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".097890565488997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".867619212020152",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".061997675904556",
						"qValue": "1",
						"foldChange": "2.0532"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".74312950546425",
						"qValue": "1",
						"foldChange": "1.3725"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".120375806411631",
						"qValue": "1",
						"foldChange": "0.8164"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".67012117271189",
						"qValue": "1",
						"foldChange": "1.5542"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".612743320102454",
						"qValue": ".928868848501546",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".352887284209347",
						"qValue": ".957839854315834",
						"foldChange": "1.1464"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".447113531575147",
						"qValue": "1",
						"foldChange": "1.4028"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".843758426057236",
						"qValue": "1",
						"foldChange": "0.9652"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".339535505963842",
						"qValue": "1",
						"foldChange": "0.8924"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".381011263792466",
						"qValue": "1",
						"foldChange": "1.6305"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".649545150134601",
						"qValue": "1",
						"foldChange": "0.8701"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".190853231567544",
						"qValue": "1",
						"foldChange": "0.5337"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".660615377374233",
						"qValue": "1",
						"foldChange": "0.8606"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".175589687533674",
						"qValue": "1",
						"foldChange": "0.5643"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".332818612893374",
						"qValue": "1",
						"foldChange": "0.6557"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".867872846613565",
						"qValue": "1",
						"foldChange": "0.9544"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".368596444601233",
						"qValue": "1",
						"foldChange": "0.6024"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".280082441633246",
						"qValue": ".985830071188835",
						"foldChange": "0.6312"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".13049125776987",
						"qValue": ".099925558622948",
						"foldChange": "0.4264"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00679340150397",
						"qValue": ".007763589480004",
						"foldChange": "0.3840"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".014763166463664",
						"qValue": ".007371309573753",
						"foldChange": "0.3905"
					}
				]
			},
			{
				"metaboliteId": "0078db9c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6a5-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPA (16:0)",
				"plotName": null,
				"compoundId": "0000867c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00327",
				"inChiKey": "YNDYKPRNFWPPFU-GOSISDBHBW",
				"kegg": "C04036",
				"mass": 410.24334,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2302,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".370070316654775",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".677138227218183",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".437508060626724",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".732794597606599",
						"qValue": "1",
						"foldChange": "4.9847"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".444383879329503",
						"qValue": "1",
						"foldChange": "0.7705"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".270581497420427",
						"qValue": "1",
						"foldChange": "2.8222"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".986576599605964",
						"qValue": "1",
						"foldChange": "1.6809"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".180889342413808",
						"qValue": ".805951991592414",
						"foldChange": "5.4157"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".18628166119116",
						"qValue": ".957839854315834",
						"foldChange": "5.0946"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".664541798176527",
						"qValue": "1",
						"foldChange": "1.3371"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".339156625576848",
						"qValue": "1",
						"foldChange": "3.7636"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".599169284450035",
						"qValue": "1",
						"foldChange": "3.2085"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".414338229175323",
						"qValue": "1",
						"foldChange": "0.5698"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".155441262286053",
						"qValue": "1",
						"foldChange": "0.2204"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".501612836914391",
						"qValue": "1",
						"foldChange": "0.3869"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".252429482602499",
						"qValue": "1",
						"foldChange": "0.2331"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".190728550001373",
						"qValue": "1",
						"foldChange": "0.2135"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".814455435631923",
						"qValue": "1",
						"foldChange": "0.9162"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".207547623069311",
						"qValue": "1",
						"foldChange": "1.7898"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".773010937617031",
						"qValue": "1",
						"foldChange": "1.1167"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".352756900095339",
						"qValue": ".985830071188835",
						"foldChange": "0.6239"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000084740181995",
						"qValue": ".000445344077794",
						"foldChange": "0.2157"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00100761005037",
						"qValue": ".00216872027018",
						"foldChange": "0.3361"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000037397624787",
						"qValue": ".000127928458776",
						"foldChange": "0.1766"
					}
				]
			},
			{
				"metaboliteId": "0078db9d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e72b-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxypalmitate",
				"plotName": null,
				"compoundId": "00008b5b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB31057",
				"inChiKey": "JGHSBPIZNUXPLA-UHFFFAOYAI",
				"kegg": null,
				"mass": 272.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1735,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".918662922444291",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".028613110984925",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".095800977333735",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".699357641229603",
						"qValue": "1",
						"foldChange": "0.9763"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".728245125077555",
						"qValue": "1",
						"foldChange": "0.9835"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".969042781236056",
						"qValue": "1",
						"foldChange": "1.0214"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".042970159208603",
						"qValue": ".457095068581515",
						"foldChange": "1.2058"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".095604700839683",
						"qValue": ".697458782960496",
						"foldChange": "0.9433"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000434927691831",
						"qValue": ".058701893386367",
						"foldChange": "0.7702"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".600201558941241",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".13818666092592",
						"qValue": "1",
						"foldChange": "0.8875"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".331957681469832",
						"qValue": "1",
						"foldChange": "0.8855"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".583450223292617",
						"qValue": "1",
						"foldChange": "0.9666"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".98988307166321",
						"qValue": "1",
						"foldChange": "0.9946"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".604250798585112",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".359888972479268",
						"qValue": "1",
						"foldChange": "1.1629"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".909718492358081",
						"qValue": "1",
						"foldChange": "0.9608"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".31663822858964",
						"qValue": "1",
						"foldChange": "0.8262"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".186425982570056",
						"qValue": "1",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".455850754045475",
						"qValue": "1",
						"foldChange": "0.8455"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".598570613686341",
						"qValue": ".985830071188835",
						"foldChange": "0.9553"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".082762852050672",
						"qValue": ".071545695984405",
						"foldChange": "1.4692"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".051327056662138",
						"qValue": ".037963838430328",
						"foldChange": "1.4842"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001071693996651",
						"qValue": ".00110450377675",
						"foldChange": "1.6293"
					}
				]
			},
			{
				"metaboliteId": "0078db9e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8e2-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol monosulfate (1)",
				"plotName": null,
				"compoundId": "00009142-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 372.197045,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3045,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".45336172357004",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".918066952457836",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".172054534252347",
						"qValue": ".926698788916122",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".289641033113254",
						"qValue": "1",
						"foldChange": "0.8428"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".350753656536842",
						"qValue": "1",
						"foldChange": "0.8460"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".898343905913525",
						"qValue": "1",
						"foldChange": "1.0633"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".039103568675939",
						"qValue": ".436430439671866",
						"foldChange": "3.7766"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".074294533455317",
						"qValue": ".687896186587066",
						"foldChange": "2.6346"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".768086360443247",
						"qValue": ".98622264532067",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815169523088749",
						"qValue": "1",
						"foldChange": "0.9745"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".565747920401307",
						"qValue": "1",
						"foldChange": "0.9072"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".732890328830212",
						"qValue": "1",
						"foldChange": "0.9306"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".058235280176829",
						"qValue": "1",
						"foldChange": "0.4865"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".696212343302617",
						"qValue": "1",
						"foldChange": "0.7415"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".143302681786409",
						"qValue": "1",
						"foldChange": "1.5240"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".528988688761352",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".953454480321787",
						"qValue": "1",
						"foldChange": "0.7594"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".581882129330034",
						"qValue": "1",
						"foldChange": "0.8628"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".422111930829065",
						"qValue": "1",
						"foldChange": "0.7071"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".796690198393023",
						"qValue": "1",
						"foldChange": "0.6791"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".605466510198191",
						"qValue": ".985830071188835",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".363954334963657",
						"qValue": ".200169195515956",
						"foldChange": "1.4932"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".882941079469335",
						"qValue": ".309160458567174",
						"foldChange": "1.1405"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".100658430198878",
						"qValue": ".032893194727232",
						"foldChange": "2.1066"
					}
				]
			},
			{
				"metaboliteId": "0078db9f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f875-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol monosulfate (2)",
				"plotName": null,
				"compoundId": "00009141-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 372.197045,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3046,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".881396494072861",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".330578869886117",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".200451076230203",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".724557226711291",
						"qValue": "1",
						"foldChange": "0.9771"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".066217516488494",
						"qValue": "1",
						"foldChange": "1.2724"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".030162003497236",
						"qValue": "1",
						"foldChange": "1.2960"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".101372113727404",
						"qValue": ".668741618465276",
						"foldChange": "1.3470"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".302514003321684",
						"qValue": ".825038702902081",
						"foldChange": "1.1709"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".532961574704651",
						"qValue": ".957839854315834",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".870049017560334",
						"qValue": "1",
						"foldChange": "1.0169"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".812503050045563",
						"qValue": "1",
						"foldChange": "0.9782"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".688751700529683",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".827664834080307",
						"qValue": "1",
						"foldChange": "0.7021"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".701362410275608",
						"qValue": "1",
						"foldChange": "2.3255"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".547079753137871",
						"qValue": "1",
						"foldChange": "3.3124"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".971077997580589",
						"qValue": "1",
						"foldChange": "0.7594"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".653678864031265",
						"qValue": "1",
						"foldChange": "2.1442"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".671093687128479",
						"qValue": "1",
						"foldChange": "2.8234"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".738258683241343",
						"qValue": "1",
						"foldChange": "0.6135"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".904630753743706",
						"qValue": "1",
						"foldChange": "1.8528"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".655675464503972",
						"qValue": ".985830071188835",
						"foldChange": "3.0203"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".101600431886662",
						"qValue": ".083059128381921",
						"foldChange": "16.7378"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".107184769945197",
						"qValue": ".064275465917365",
						"foldChange": "4.2073"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".042829441862457",
						"qValue": ".016632712450754",
						"foldChange": "9.0336"
					}
				]
			},
			{
				"metaboliteId": "0078dba0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8d9-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-pregnan-3beta,20beta-diol monosulfate (1)",
				"plotName": null,
				"compoundId": "0000914c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 400.228345,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2955,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".241116786941907",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".260334487423272",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".959204249157489",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".571919538921287",
						"qValue": "1",
						"foldChange": "1.4427"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".680116515552163",
						"qValue": "1",
						"foldChange": "0.9817"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".330068847622291",
						"qValue": "1",
						"foldChange": "1.0483"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".519536639037451",
						"qValue": "1",
						"foldChange": "2.9355"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".516928541271682",
						"qValue": ".908244770314396",
						"foldChange": "2.0775"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".199215561733782",
						"qValue": ".957839854315834",
						"foldChange": "0.7972"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".725430505349436",
						"qValue": "1",
						"foldChange": "0.9615"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".366582787649563",
						"qValue": "1",
						"foldChange": "0.8279"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".579278561795352",
						"qValue": "1",
						"foldChange": "0.8067"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".11925921714763",
						"qValue": "1",
						"foldChange": "0.1852"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".530397618813477",
						"qValue": "1",
						"foldChange": "0.2059"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".37404537098365",
						"qValue": "1",
						"foldChange": "1.1120"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".123893905454147",
						"qValue": "1",
						"foldChange": "0.0443"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".310790997788339",
						"qValue": "1",
						"foldChange": "0.0467"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".637095412650788",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".100497851033284",
						"qValue": "1",
						"foldChange": "0.2645"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".393120420034849",
						"qValue": "1",
						"foldChange": "0.3127"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".457327266156859",
						"qValue": ".985830071188835",
						"foldChange": "1.1820"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".518966805381643",
						"qValue": ".257995534648233",
						"foldChange": "0.9462"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".750407475040736",
						"qValue": ".277385958580678",
						"foldChange": "1.1826"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".212894426715708",
						"qValue": ".057675178595684",
						"foldChange": "3.1334"
					}
				]
			},
			{
				"metaboliteId": "0078dba1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7dd-0000-0000-0000-000000000000",
				"chemicalName": "thymol sulfate",
				"plotName": null,
				"compoundId": "00008cff-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01878",
				"inChiKey": "NODSEPOUFZPJEQ-UHFFFAOYAU",
				"kegg": "C09908",
				"mass": 230.06128,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4564,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".608211416527074",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".306716124010722",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".531282553825327",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".17636545113898",
						"qValue": "1",
						"foldChange": "6.1448"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".219462161446509",
						"qValue": "1",
						"foldChange": "5.5273"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".89865790836973",
						"qValue": "1",
						"foldChange": "8.9937"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".073972002735706",
						"qValue": ".593869569132882",
						"foldChange": "6.5275"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".658873433026918",
						"qValue": ".934949271461149",
						"foldChange": "3.4325"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".173236350897884",
						"qValue": ".957839854315834",
						"foldChange": "1.5137"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".703397437656168",
						"qValue": "1",
						"foldChange": "3.3192"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".900932520329903",
						"qValue": "1",
						"foldChange": "2.1217"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".797606268374651",
						"qValue": "1",
						"foldChange": "2.5342"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".77570380644703",
						"qValue": "1",
						"foldChange": "1.3704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".785457356622747",
						"qValue": "1",
						"foldChange": "0.8549"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".997718944934279",
						"qValue": "1",
						"foldChange": "0.6238"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".558675145710193",
						"qValue": "1",
						"foldChange": "0.9879"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".261267522359903",
						"qValue": "1",
						"foldChange": "0.3858"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".088568384878802",
						"qValue": ".834952786556829",
						"foldChange": "0.3905"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".688761770180608",
						"qValue": "1",
						"foldChange": "0.7202"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".417531625935936",
						"qValue": "1",
						"foldChange": "0.3409"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".656891058832911",
						"qValue": ".985915857260684",
						"foldChange": "0.4734"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".408689183223512",
						"qValue": ".216501028847437",
						"foldChange": "1.6200"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".065628233291746",
						"qValue": ".045074382503879",
						"foldChange": "2.5746"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".184151988841001",
						"qValue": ".05249504535623",
						"foldChange": "1.8535"
					}
				]
			},
			{
				"metaboliteId": "0078dba2-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed1d-0000-0000-0000-000000000000",
				"chemicalName": "alpha-CEHC glucuronide*",
				"plotName": null,
				"compoundId": "000099b2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 454.183897,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3978,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".427610850507665",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".072597177739139",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".237478125581211",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".245956209173118",
						"qValue": "1",
						"foldChange": "0.9052"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".343456834222198",
						"qValue": "1",
						"foldChange": "1.3203"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".038204360871491",
						"qValue": "1",
						"foldChange": "1.7465"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".276620147859534",
						"qValue": ".911265664999995",
						"foldChange": "1.0202"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".336901792905671",
						"qValue": ".840772509568112",
						"foldChange": "1.6421"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".043809763185865",
						"qValue": ".679210111549633",
						"foldChange": "1.8634"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".23850019471798",
						"qValue": "1",
						"foldChange": "0.8079"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".104179597639953",
						"qValue": "1",
						"foldChange": "0.7848"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".64565237914778",
						"qValue": "1",
						"foldChange": "0.9642"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".237386439224454",
						"qValue": "1",
						"foldChange": "0.5806"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".918997625121151",
						"qValue": "1",
						"foldChange": "1.8328"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".212870588072836",
						"qValue": "1",
						"foldChange": "3.1568"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".257069100766231",
						"qValue": "1",
						"foldChange": "0.4260"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".945259205499826",
						"qValue": "1",
						"foldChange": "1.3902"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".243380704043269",
						"qValue": "1",
						"foldChange": "3.2634"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".232080004621104",
						"qValue": "1",
						"foldChange": "0.4683"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".382554305069654",
						"qValue": "1",
						"foldChange": "1.0629"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".78604148526292",
						"qValue": ".997573179629986",
						"foldChange": "2.2699"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".130682562770308",
						"qValue": ".099925558622948",
						"foldChange": "6.0959"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004800105503451",
						"qValue": ".006328307675416",
						"foldChange": "3.4167"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002197558344491",
						"qValue": ".001849813960424",
						"foldChange": "6.8482"
					}
				]
			},
			{
				"metaboliteId": "0078dba3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e639-0000-0000-0000-000000000000",
				"chemicalName": "linolenate [alpha or gamma; (18:3n3 or 6)]",
				"plotName": null,
				"compoundId": "000084f3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03073",
				"inChiKey": "DTOSIQBPPRVQHS-PDBXOOCHBH",
				"kegg": "C06426",
				"mass": 278.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1396,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".892857612669995",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".154365313132127",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".617981386254343",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".780512311090189",
						"qValue": "1",
						"foldChange": "1.1700"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".780507196344082",
						"qValue": "1",
						"foldChange": "1.0966"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999994676261601",
						"qValue": "1",
						"foldChange": "1.1406"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".500157030273698",
						"qValue": "1",
						"foldChange": "1.3402"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".165983496539026",
						"qValue": ".787973217949622",
						"foldChange": "1.0957"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".042317116248217",
						"qValue": ".679210111549633",
						"foldChange": "0.8001"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".954460643285188",
						"qValue": "1",
						"foldChange": "1.1807"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".227383902532904",
						"qValue": "1",
						"foldChange": "0.8448"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".20663339915176",
						"qValue": "1",
						"foldChange": "0.8222"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".997591566750842",
						"qValue": "1",
						"foldChange": "1.1205"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".6280723849417",
						"qValue": "1",
						"foldChange": "1.1804"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".617682551957138",
						"qValue": "1",
						"foldChange": "1.0535"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".809329397104788",
						"qValue": "1",
						"foldChange": "1.2205"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".729547451020168",
						"qValue": "1",
						"foldChange": "1.0230"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".904014678325387",
						"qValue": "1",
						"foldChange": "0.8382"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".280618248492867",
						"qValue": "1",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".606844224620753",
						"qValue": "1",
						"foldChange": "0.7750"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".600707700195319",
						"qValue": ".985830071188835",
						"foldChange": "0.7859"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".820006050815559",
						"qValue": ".350770397453081",
						"foldChange": "1.0230"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".962123293993859",
						"qValue": ".327976352737671",
						"foldChange": "1.4350"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".128159350282712",
						"qValue": ".039322399916596",
						"foldChange": "1.4349"
					}
				]
			},
			{
				"metaboliteId": "0078dba4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7fc-0000-0000-0000-000000000000",
				"chemicalName": "desmethylnaproxen sulfate",
				"plotName": null,
				"compoundId": "0000b41a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BNKMSCMOWGCUOF-QMMMGPOBBD",
				"kegg": null,
				"mass": 296.035459,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4790,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".91147364186864",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".557324515341074",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".299256445518005",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".669688089530031",
						"qValue": "1",
						"foldChange": "0.9365"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".073265680796774",
						"qValue": "1",
						"foldChange": "0.8625"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".167322901690582",
						"qValue": "1",
						"foldChange": "0.9011"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".122610904590867",
						"qValue": ".704689005419363",
						"foldChange": "2.2558"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".271470014659773",
						"qValue": ".825038702902081",
						"foldChange": "1.5331"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".648219503365301",
						"qValue": ".973034272612281",
						"foldChange": "0.9467"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".846128167369842",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".855272539866771",
						"qValue": "1",
						"foldChange": "1.0391"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".990687159079518",
						"qValue": "1",
						"foldChange": "0.9981"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".227638921093353",
						"qValue": "1",
						"foldChange": "0.2892"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".602692335866319",
						"qValue": "1",
						"foldChange": "0.4624"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".51904181160698",
						"qValue": "1",
						"foldChange": "1.5988"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".843012640367646",
						"qValue": "1",
						"foldChange": "0.7583"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".840240365702517",
						"qValue": "1",
						"foldChange": "0.5877"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".988289032204264",
						"qValue": "1",
						"foldChange": "0.7750"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".752237979233929",
						"qValue": "1",
						"foldChange": "1.3706"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".619693722099509",
						"qValue": "1",
						"foldChange": "1.5498"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".838958894063233",
						"qValue": ".997573179629986",
						"foldChange": "1.1307"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.7335"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.5331"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087335",
						"qValue": ".083280304277061",
						"foldChange": "1.1185"
					}
				]
			},
			{
				"metaboliteId": "0078dba5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7fb-0000-0000-0000-000000000000",
				"chemicalName": "desmethylnaproxen",
				"plotName": null,
				"compoundId": "0000b4fb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13989",
				"inChiKey": "XWJUDDGELKXYNO-QMMMGPOBBV",
				"kegg": null,
				"mass": 216.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4789,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".9295006297207",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".459473757541733",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".412035134021564",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".86474406614273",
						"qValue": "1",
						"foldChange": "0.9718"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".092512883476086",
						"qValue": "1",
						"foldChange": "0.8948"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".128847793885696",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".157301732107745",
						"qValue": ".742487134132407",
						"foldChange": "2.2382"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".394361235720558",
						"qValue": ".880987329915339",
						"foldChange": "1.3720"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".566500844866758",
						"qValue": ".957839854315834",
						"foldChange": "0.9353"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".790783267287327",
						"qValue": "1",
						"foldChange": "1.0673"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".821888793988525",
						"qValue": "1",
						"foldChange": "1.0552"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".967921591412215",
						"qValue": "1",
						"foldChange": "0.9930"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".256261182095314",
						"qValue": "1",
						"foldChange": "0.2955"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".591610227302442",
						"qValue": "1",
						"foldChange": "0.4141"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".577464603138674",
						"qValue": "1",
						"foldChange": "1.4013"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".835045941055949",
						"qValue": "1",
						"foldChange": "0.7595"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".78315200332263",
						"qValue": "1",
						"foldChange": "0.5458"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".935998955135348",
						"qValue": "1",
						"foldChange": "0.7187"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".688972738267466",
						"qValue": "1",
						"foldChange": "1.3259"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".554901420064564",
						"qValue": "1",
						"foldChange": "1.5106"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".827411652599408",
						"qValue": ".997573179629986",
						"foldChange": "1.1393"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.5631"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.3720"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087332",
						"qValue": ".083280304277061",
						"foldChange": "1.0348"
					}
				]
			},
			{
				"metaboliteId": "0078dba6-0000-0000-0000-000000000000",
				"chemicalId": "000000fe-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxybutyrate (BHBA)",
				"plotName": null,
				"compoundId": "0000021e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00357",
				"inChiKey": "WHBMMWSBFZVSSR-UHFFFAOYAO",
				"kegg": "C01089",
				"mass": 104.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Ketone Bodies",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1710,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".655542050400923",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".444205391365656",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".779720844861635",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".963178193196766",
						"qValue": "1",
						"foldChange": "1.1982"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".858150026265884",
						"qValue": "1",
						"foldChange": "1.2582"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".894524638464215",
						"qValue": "1",
						"foldChange": "1.1880"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".530557577956118",
						"qValue": "1",
						"foldChange": "1.5642"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".737109878656451",
						"qValue": ".957498790731047",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".337219772966376",
						"qValue": ".957839854315834",
						"foldChange": "1.1218"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".143938821984387",
						"qValue": "1",
						"foldChange": "1.8057"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".331140782548812",
						"qValue": "1",
						"foldChange": "1.6911"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".616718640130619",
						"qValue": "1",
						"foldChange": "1.3442"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".455993642238847",
						"qValue": "1",
						"foldChange": "0.3831"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".546403535038292",
						"qValue": "1",
						"foldChange": "0.3991"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".916154875007698",
						"qValue": "1",
						"foldChange": "1.0419"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".695273262507087",
						"qValue": "1",
						"foldChange": "1.3635"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".744358942865991",
						"qValue": "1",
						"foldChange": "1.2972"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".476558710864585",
						"qValue": "1",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".287998785082365",
						"qValue": "1",
						"foldChange": "0.5865"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".936193476500025",
						"qValue": "1",
						"foldChange": "1.0304"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".342586167851589",
						"qValue": ".985830071188835",
						"foldChange": "1.7570"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".991449311658871",
						"qValue": ".395017494716148",
						"foldChange": "0.9360"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".816044188368269",
						"qValue": ".292482028020533",
						"foldChange": "0.7551"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".197969776865283",
						"qValue": ".05497197439469",
						"foldChange": "1.4688"
					}
				]
			},
			{
				"metaboliteId": "0078dba7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e94a-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyhippurate",
				"plotName": null,
				"compoundId": "00009ab0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06116",
				"inChiKey": "XDOFWFNMYJRHEW-UHFFFAOYAF",
				"kegg": null,
				"mass": 195.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4061,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".740436036919347",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".512586526360048",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".570991449610207",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".801647766201931",
						"qValue": "1",
						"foldChange": "2.1529"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".506252453528124",
						"qValue": "1",
						"foldChange": "1.8247"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".678466234436492",
						"qValue": "1",
						"foldChange": "1.6115"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".056481563485301",
						"qValue": ".533236366427116",
						"foldChange": "2.3400"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".28103244060014",
						"qValue": ".825038702902081",
						"foldChange": "1.7921"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".391770857679214",
						"qValue": ".957839854315834",
						"foldChange": "1.0010"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".93506609672831",
						"qValue": "1",
						"foldChange": "1.3104"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".592063472893724",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".649333884731863",
						"qValue": "1",
						"foldChange": "0.9672"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".886990955223964",
						"qValue": "1",
						"foldChange": "1.3781"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".351220834566732",
						"qValue": "1",
						"foldChange": "1.4595"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".412661914350658",
						"qValue": "1",
						"foldChange": "1.0591"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".295856075076233",
						"qValue": "1",
						"foldChange": "2.3651"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".45189362777836",
						"qValue": "1",
						"foldChange": "2.8823"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".805301993810863",
						"qValue": "1",
						"foldChange": "1.2187"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".72689159204739",
						"qValue": "1",
						"foldChange": "2.1349"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".7874434028539",
						"qValue": "1",
						"foldChange": "1.3499"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".950366911804595",
						"qValue": ".99847190363668",
						"foldChange": "0.6323"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000850262211624",
						"qValue": ".002326560477512",
						"foldChange": "6.9244"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004180129282386",
						"qValue": ".00571379497161",
						"foldChange": "13.4933"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000513661986296",
						"qValue": ".000640189758517",
						"foldChange": "5.4052"
					}
				]
			},
			{
				"metaboliteId": "0078dba8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e448-0000-0000-0000-000000000000",
				"chemicalName": "tartronate (hydroxymalonate)",
				"plotName": null,
				"compoundId": "000050d5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB35227",
				"inChiKey": "ROBFUDYVXSDBQM-UHFFFAOYAD",
				"kegg": "C02287",
				"mass": 120.005873,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4687,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".938323625446298",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".773059142102715",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".848338333760834",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".425095062303965",
						"qValue": "1",
						"foldChange": "1.8206"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".972147007996315",
						"qValue": "1",
						"foldChange": "1.1381"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".405315530953934",
						"qValue": "1",
						"foldChange": "7.5068"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".754331217932295",
						"qValue": "1",
						"foldChange": "1.3103"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".406694888878267",
						"qValue": ".882901404171953",
						"foldChange": "1.1363"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".603827338145749",
						"qValue": ".967408831530889",
						"foldChange": "2.2052"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".885545225964007",
						"qValue": "1",
						"foldChange": "1.2275"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".692881298661642",
						"qValue": "1",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".590294155844653",
						"qValue": "1",
						"foldChange": "1.0488"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".989643920780641",
						"qValue": "1",
						"foldChange": "0.9171"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".994234075482455",
						"qValue": "1",
						"foldChange": "0.8427"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".995875004673141",
						"qValue": "1",
						"foldChange": "0.9189"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".632603878034488",
						"qValue": "1",
						"foldChange": "1.1850"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".401744357310477",
						"qValue": "1",
						"foldChange": "1.4116"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".690980958090581",
						"qValue": "1",
						"foldChange": "1.1913"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".454180973328453",
						"qValue": "1",
						"foldChange": "0.6760"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".696703273259207",
						"qValue": "1",
						"foldChange": "0.6793"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".743760760686808",
						"qValue": ".997573179629986",
						"foldChange": "1.0049"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000223926774054",
						"qValue": ".00091530923289",
						"foldChange": "9.5349"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000469129212327",
						"qValue": ".001406613025022",
						"foldChange": "8.7984"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000232076266137",
						"qValue": ".00037312275394",
						"foldChange": "14.0369"
					}
				]
			},
			{
				"metaboliteId": "0078dba9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e449-0000-0000-0000-000000000000",
				"chemicalName": "oxalate (ethanedioate)",
				"plotName": null,
				"compoundId": "000050d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02329",
				"inChiKey": "MUBZPKHOEPUJKR-UHFFFAOYAQ",
				"kegg": "C00209",
				"mass": 89.995309,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3964,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".686518654463804",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".525620984734963",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".690883633295891",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".305030457381121",
						"qValue": "1",
						"foldChange": "1.1469"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".841949466970481",
						"qValue": "1",
						"foldChange": "0.9467"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".407128729225102",
						"qValue": "1",
						"foldChange": "2.1795"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".776643671289279",
						"qValue": "1",
						"foldChange": "1.4899"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".345920007027319",
						"qValue": ".847814688346659",
						"foldChange": "1.1666"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".222034810377338",
						"qValue": ".957839854315834",
						"foldChange": "1.1582"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".690574923566908",
						"qValue": "1",
						"foldChange": "0.9103"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".417083871434319",
						"qValue": "1",
						"foldChange": "0.9166"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".677759423222152",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".486718575322377",
						"qValue": "1",
						"foldChange": "0.8719"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".985883157757326",
						"qValue": "1",
						"foldChange": "0.9708"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".511977556951334",
						"qValue": "1",
						"foldChange": "1.1135"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".580339777585854",
						"qValue": "1",
						"foldChange": "1.0388"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".592554667873868",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".989934782371243",
						"qValue": "1",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".173995123272844",
						"qValue": "1",
						"foldChange": "0.8252"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".53486217434248",
						"qValue": "1",
						"foldChange": "0.8739"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".491535021348284",
						"qValue": ".985830071188835",
						"foldChange": "1.0590"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000601928352026",
						"qValue": ".001779400120967",
						"foldChange": "4.2448"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".014553819930012",
						"qValue": ".01394598245208",
						"foldChange": "3.6272"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000001692609953",
						"qValue": ".000020933147984",
						"foldChange": "4.8348"
					}
				]
			},
			{
				"metaboliteId": "0078dbaa-0000-0000-0000-000000000000",
				"chemicalId": "05f5e94e-0000-0000-0000-000000000000",
				"chemicalName": "16a-hydroxy DHEA 3-sulfate",
				"plotName": null,
				"compoundId": "00009518-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 384.160659,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3011,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".930281768515343",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".524457953759573",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".229881313663006",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".313566153088242",
						"qValue": "1",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".999518601845059",
						"qValue": "1",
						"foldChange": "1.0045"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".313851358283674",
						"qValue": "1",
						"foldChange": "1.2310"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".030096150137242",
						"qValue": ".382266026370041",
						"foldChange": "1.5766"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".049389022884594",
						"qValue": ".678234398444917",
						"foldChange": "1.4401"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".827656012338297",
						"qValue": "1",
						"foldChange": "1.0262"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".954836343524084",
						"qValue": "1",
						"foldChange": "1.0505"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".9497638910961",
						"qValue": "1",
						"foldChange": "1.0210"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".994918204089713",
						"qValue": "1",
						"foldChange": "0.9702"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".872610129652663",
						"qValue": "1",
						"foldChange": "0.9126"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".847903402157478",
						"qValue": "1",
						"foldChange": "1.0702"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".967146431352927",
						"qValue": "1",
						"foldChange": "1.1727"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".538222842455085",
						"qValue": "1",
						"foldChange": "1.2660"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".951329645006736",
						"qValue": "1",
						"foldChange": "1.0876"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".593069166411791",
						"qValue": "1",
						"foldChange": "0.8591"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".753775414428874",
						"qValue": "1",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".860356382383115",
						"qValue": "1",
						"foldChange": "0.9002"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".628894296760294",
						"qValue": ".985830071188835",
						"foldChange": "0.8915"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".289806337737725",
						"qValue": ".174458457291099",
						"foldChange": "0.7967"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".634821631261518",
						"qValue": ".246199934190585",
						"foldChange": "1.1057"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".75163965556793",
						"qValue": ".152558761137927",
						"foldChange": "1.3687"
					}
				]
			},
			{
				"metaboliteId": "0078dbab-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6bc-0000-0000-0000-000000000000",
				"chemicalName": "N1-Methyl-2-pyridone-5-carboxamide",
				"plotName": null,
				"compoundId": "00009e15-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04193",
				"inChiKey": "JLQSXXWTCJPCBC-UHFFFAOYAD",
				"kegg": "C05842",
				"mass": 152.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3935,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".537769428513133",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".423428630787435",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".708606989356939",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".631716860427297",
						"qValue": "1",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".656707746286125",
						"qValue": "1",
						"foldChange": "1.1942"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".357206970585884",
						"qValue": "1",
						"foldChange": "1.4097"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".384623536737055",
						"qValue": ".961000408465767",
						"foldChange": "0.8904"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".136004168115964",
						"qValue": ".756467627886835",
						"foldChange": "0.9315"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".525950028773138",
						"qValue": ".957839854315834",
						"foldChange": "1.1078"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".402115391368746",
						"qValue": "1",
						"foldChange": "0.8889"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".534101523653861",
						"qValue": "1",
						"foldChange": "0.9154"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".827528651883855",
						"qValue": "1",
						"foldChange": "1.0573"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".595735857950606",
						"qValue": "1",
						"foldChange": "0.3591"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".519088979687032",
						"qValue": "1",
						"foldChange": "0.3338"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".882702194432123",
						"qValue": "1",
						"foldChange": "0.9295"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".483257157279524",
						"qValue": "1",
						"foldChange": "0.3510"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".403803514096895",
						"qValue": "1",
						"foldChange": "0.2997"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".858645808264981",
						"qValue": "1",
						"foldChange": "0.8539"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".151117065424676",
						"qValue": "1",
						"foldChange": "0.3289"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".249482948735945",
						"qValue": "1",
						"foldChange": "0.2675"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".825131477978227",
						"qValue": ".997573179629986",
						"foldChange": "0.8133"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006867047128142",
						"qValue": ".011547347761294",
						"foldChange": "2.3710"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002656290845711",
						"qValue": ".004092249995339",
						"foldChange": "3.9547"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".012668438865353",
						"qValue": ".006570257820051",
						"foldChange": "7.6030"
					}
				]
			},
			{
				"metaboliteId": "0078dbac-0000-0000-0000-000000000000",
				"chemicalId": "05f5e989-0000-0000-0000-000000000000",
				"chemicalName": "indole-3-carboxylate",
				"plotName": null,
				"compoundId": "000094e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03320",
				"inChiKey": null,
				"kegg": "C19837",
				"mass": 161.047678,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 275,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".247311854894791",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".251936217218493",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".445282395134279",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".131457687428132",
						"qValue": "1",
						"foldChange": "0.8887"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".935075600293186",
						"qValue": "1",
						"foldChange": "1.0426"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".152821435351308",
						"qValue": "1",
						"foldChange": "1.6383"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".97374227979179",
						"qValue": "1",
						"foldChange": "1.0803"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".208508219601034",
						"qValue": ".825038702902081",
						"foldChange": "0.9717"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".220367249940437",
						"qValue": ".957839854315834",
						"foldChange": "0.9523"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".284238017879665",
						"qValue": "1",
						"foldChange": "0.8510"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".340653703059019",
						"qValue": "1",
						"foldChange": "0.8984"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".904574854711434",
						"qValue": "1",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".357063357104651",
						"qValue": "1",
						"foldChange": "0.7231"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".515718355118804",
						"qValue": "1",
						"foldChange": "0.9983"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".122371756457851",
						"qValue": "1",
						"foldChange": "1.3806"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".82275933925958",
						"qValue": "1",
						"foldChange": "1.0096"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".354283285971521",
						"qValue": "1",
						"foldChange": "1.1326"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".462994133958063",
						"qValue": "1",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".078454246509756",
						"qValue": "1",
						"foldChange": "0.6519"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".978260037470289",
						"qValue": "1",
						"foldChange": "0.8217"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".093088628216649",
						"qValue": ".985830071188835",
						"foldChange": "1.2605"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000093280243066",
						"qValue": ".000474342643377",
						"foldChange": "3.4056"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001813041443807",
						"qValue": ".003199747812528",
						"foldChange": "2.5009"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000059424271522",
						"qValue": ".000167613894286",
						"foldChange": "3.8051"
					}
				]
			},
			{
				"metaboliteId": "0078dbad-0000-0000-0000-000000000000",
				"chemicalId": "000005d1-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl ethanolamide",
				"plotName": null,
				"compoundId": "00009515-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02100",
				"inChiKey": "HXYVTAGFYLMHSO-UHFFFAOYAE",
				"kegg": "C16512",
				"mass": 299.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1908,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".362053555666102",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".014373126067117",
						"qValue": ".614455251574347",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".05376095224028",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".815333155022245",
						"qValue": "1",
						"foldChange": "1.0409"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".901036277526865",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".720537670727418",
						"qValue": "1",
						"foldChange": "0.9750"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007555122166797",
						"qValue": ".237900912197889",
						"foldChange": "1.3766"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".116381954593683",
						"qValue": ".707436023994459",
						"foldChange": "0.9229"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000062425728067",
						"qValue": ".012405015081279",
						"foldChange": "0.6701"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".855163667943266",
						"qValue": "1",
						"foldChange": "1.0018"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".364907522077167",
						"qValue": "1",
						"foldChange": "0.9442"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".468207080613974",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".601121383439859",
						"qValue": "1",
						"foldChange": "0.9651"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".256043946809031",
						"qValue": "1",
						"foldChange": "1.1781"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".097758946278522",
						"qValue": "1",
						"foldChange": "1.2206"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".184572279794148",
						"qValue": "1",
						"foldChange": "1.2238"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".408469558911253",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".655488409538042",
						"qValue": "1",
						"foldChange": "0.8515"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".114317863481021",
						"qValue": "1",
						"foldChange": "0.8343"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".599495879506789",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".040851398816047",
						"qValue": ".985830071188835",
						"foldChange": "1.2318"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".047700042242737",
						"qValue": ".048296743596225",
						"foldChange": "1.6256"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".24075654145521",
						"qValue": ".119455702462296",
						"foldChange": "1.2936"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037709414534284",
						"qValue": ".01516382095168",
						"foldChange": "1.4191"
					}
				]
			},
			{
				"metaboliteId": "0078dbae-0000-0000-0000-000000000000",
				"chemicalId": "05f5e951-0000-0000-0000-000000000000",
				"chemicalName": "pregnenolone sulfate",
				"plotName": null,
				"compoundId": "0000951a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00774",
				"inChiKey": "DIJBBUIOWGGQOP-QGVNFLHTBX",
				"kegg": null,
				"mass": 396.197045,
				"pathwayType": "ANIMAL",
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2927,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".672230981204882",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".66551373578935",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".352922535583374",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".553744796367021",
						"qValue": "1",
						"foldChange": "0.9375"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".995049648114531",
						"qValue": "1",
						"foldChange": "1.0189"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".549621206852262",
						"qValue": "1",
						"foldChange": "1.1825"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".029074985645563",
						"qValue": ".374891102793553",
						"foldChange": "3.1892"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".228744064014015",
						"qValue": ".825038702902081",
						"foldChange": "2.7612"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".307814456210092",
						"qValue": ".957839854315834",
						"foldChange": "0.8840"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".97894948755101",
						"qValue": "1",
						"foldChange": "1.0241"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".83203019159831",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".811521668446056",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".714367920366157",
						"qValue": "1",
						"foldChange": "1.2260"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".456888789573298",
						"qValue": "1",
						"foldChange": "1.8874"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".267407684015073",
						"qValue": "1",
						"foldChange": "1.5394"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".442193270468705",
						"qValue": "1",
						"foldChange": "1.7557"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".324334033117606",
						"qValue": "1",
						"foldChange": "1.9879"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".787426896493524",
						"qValue": "1",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".909818027014924",
						"qValue": "1",
						"foldChange": "1.2422"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".513463698033332",
						"qValue": "1",
						"foldChange": "1.4874"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".575076847367314",
						"qValue": ".985830071188835",
						"foldChange": "1.1973"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".602133116705973",
						"qValue": ".289768327335659",
						"foldChange": "1.1024"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".424224726200291",
						"qValue": ".178152944542161",
						"foldChange": "0.8532"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".602713974876541",
						"qValue": ".127502492520391",
						"foldChange": "0.6279"
					}
				]
			},
			{
				"metaboliteId": "0078dbaf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c9-0000-0000-0000-000000000000",
				"chemicalName": "pregnenediol disulfate (C21H34O8S2)*",
				"plotName": null,
				"compoundId": "00007f32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 478.169509,
				"pathwayType": "ANIMAL",
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2944,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".801739495228144",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".792642261883371",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".378724458824175",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".232583733004258",
						"qValue": "1",
						"foldChange": "0.8803"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".77773237260621",
						"qValue": "1",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".359524048137005",
						"qValue": "1",
						"foldChange": "1.2132"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".140485257895237",
						"qValue": ".742487134132407",
						"foldChange": "1.6327"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".209757769772262",
						"qValue": ".825038702902081",
						"foldChange": "1.4885"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".821013412144568",
						"qValue": "1",
						"foldChange": "1.0078"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".454339515164134",
						"qValue": "1",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".908270152763432",
						"qValue": "1",
						"foldChange": "1.0312"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".526187247931733",
						"qValue": "1",
						"foldChange": "0.8733"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".937454619149648",
						"qValue": "1",
						"foldChange": "1.0859"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".709730985253099",
						"qValue": "1",
						"foldChange": "1.3773"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".647726618683835",
						"qValue": "1",
						"foldChange": "1.2683"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".418109418583192",
						"qValue": "1",
						"foldChange": "1.5051"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".313377348883602",
						"qValue": "1",
						"foldChange": "1.4461"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".800108589136408",
						"qValue": "1",
						"foldChange": "0.9608"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".669699786971426",
						"qValue": "1",
						"foldChange": "1.2214"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".615610363017237",
						"qValue": "1",
						"foldChange": "1.1288"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".919049655572377",
						"qValue": ".997573179629986",
						"foldChange": "0.9242"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".729205468774586",
						"qValue": ".328480366391012",
						"foldChange": "1.0810"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".890503218374186",
						"qValue": ".310392066666727",
						"foldChange": "1.1729"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".605762777127052",
						"qValue": ".127811113260879",
						"foldChange": "1.2032"
					}
				]
			},
			{
				"metaboliteId": "0078dbb0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e372-0000-0000-0000-000000000000",
				"chemicalName": "sphingosine 1-phosphate",
				"plotName": null,
				"compoundId": "0000868d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00277",
				"inChiKey": "DUYSYHSSBDVJSM-KRWOKUGFBM",
				"kegg": "C06124",
				"mass": 379.24876,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingosines",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2849,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".066435577365217",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".597759894503128",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".633945623781265",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".273677490263714",
						"qValue": "1",
						"foldChange": "0.9519"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".113404846176565",
						"qValue": "1",
						"foldChange": "0.8450"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".615723078555357",
						"qValue": "1",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".670346882574111",
						"qValue": "1",
						"foldChange": "0.9750"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".663770094139643",
						"qValue": ".93517659928016",
						"foldChange": "1.0071"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".992764606400448",
						"qValue": "1",
						"foldChange": "1.1023"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".876635032981874",
						"qValue": "1",
						"foldChange": "1.0163"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".516889114191033",
						"qValue": "1",
						"foldChange": "1.1247"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".422484513743128",
						"qValue": "1",
						"foldChange": "1.1853"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".028608713988594",
						"qValue": "1",
						"foldChange": "0.7354"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".054179226949607",
						"qValue": "1",
						"foldChange": "0.7417"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".873921006527918",
						"qValue": "1",
						"foldChange": "1.0087"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".11727639851228",
						"qValue": "1",
						"foldChange": "0.8133"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".2609776749995",
						"qValue": "1",
						"foldChange": "0.8262"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".708381116507136",
						"qValue": "1",
						"foldChange": "1.0158"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".264136438132071",
						"qValue": "1",
						"foldChange": "0.8800"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".950674882614596",
						"qValue": "1",
						"foldChange": "0.9835"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".25243305028081",
						"qValue": ".985830071188835",
						"foldChange": "1.1175"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".016954460138072",
						"qValue": ".022100244959721",
						"foldChange": "1.5331"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".929306060265695",
						"qValue": ".32110741970734",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".088582486085684",
						"qValue": ".029670689106157",
						"foldChange": "1.2160"
					}
				]
			},
			{
				"metaboliteId": "0078dbb1-0000-0000-0000-000000000000",
				"chemicalId": "00000803-0000-0000-0000-000000000000",
				"chemicalName": "methylsuccinate",
				"plotName": null,
				"compoundId": "00003d81-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01844",
				"inChiKey": "WXUAQHNMJWJLTG-UHFFFAOYAC",
				"kegg": null,
				"mass": 132.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 348,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".874120382788647",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".740825240090477",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".510289416705646",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".752101640398624",
						"qValue": "1",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".360054187603745",
						"qValue": "1",
						"foldChange": "1.0090"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".547093630102438",
						"qValue": "1",
						"foldChange": "1.1324"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".18612821127325",
						"qValue": ".797133099236872",
						"foldChange": "0.8648"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".743234596809446",
						"qValue": ".959776391327525",
						"foldChange": "1.2160"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".101271650789172",
						"qValue": ".861821748215854",
						"foldChange": "1.9755"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".822791012144929",
						"qValue": "1",
						"foldChange": "1.1180"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".942009940793766",
						"qValue": "1",
						"foldChange": "1.0338"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".766753259099053",
						"qValue": "1",
						"foldChange": "0.9418"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".800683245315575",
						"qValue": "1",
						"foldChange": "0.9536"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".888406794718688",
						"qValue": "1",
						"foldChange": "0.9138"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".919665057720142",
						"qValue": "1",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".306836282803987",
						"qValue": "1",
						"foldChange": "0.9012"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".774708453917362",
						"qValue": "1",
						"foldChange": "1.1440"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".201105161930337",
						"qValue": "1",
						"foldChange": "1.2694"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".454315495792205",
						"qValue": "1",
						"foldChange": "0.9916"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".611399518104734",
						"qValue": "1",
						"foldChange": "0.9262"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".837196073168934",
						"qValue": ".997573179629986",
						"foldChange": "0.9340"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".789812211028028",
						"qValue": ".344316995840098",
						"foldChange": "1.1023"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".188478832869032",
						"qValue": ".099706293985613",
						"foldChange": "1.1639"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".824676951322569",
						"qValue": ".164910691848977",
						"foldChange": "1.3203"
					}
				]
			},
			{
				"metaboliteId": "0078dbb2-0000-0000-0000-000000000000",
				"chemicalId": "000001c0-0000-0000-0000-000000000000",
				"chemicalName": "glutathione, oxidized (GSSG)",
				"plotName": null,
				"compoundId": "00006c4f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03337",
				"inChiKey": "YPZRWBKMTBYPTK-BJDJZHNGBD",
				"kegg": "C00127",
				"mass": 612.151968,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 490,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".382696706677089",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".378404075121555",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".416838497242706",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".039085494329054",
						"qValue": "1",
						"foldChange": "12.8403"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".039085494329054",
						"qValue": "1",
						"foldChange": "0.8899"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".032804345008896",
						"qValue": "1",
						"foldChange": "0.0779"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".043212678934555",
						"qValue": "1",
						"foldChange": "0.0779"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "0.8698"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "0.9612"
					}
				]
			},
			{
				"metaboliteId": "0078dbb3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e70c-0000-0000-0000-000000000000",
				"chemicalName": "testosterone sulfate",
				"plotName": null,
				"compoundId": "00008b54-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02833",
				"inChiKey": "WAQBISPOEAOCOG-YLXDANMGBE",
				"kegg": null,
				"mass": 368.165745,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3038,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".932084998794466",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".462894310001419",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".648793310938039",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".393519657551681",
						"qValue": "1",
						"foldChange": "1.0614"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".307335875916392",
						"qValue": "1",
						"foldChange": "1.0636"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".865095970097857",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".683124737468212",
						"qValue": "1",
						"foldChange": "1.0433"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".356946097849104",
						"qValue": ".862957753606784",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".18628116304403",
						"qValue": ".957839854315834",
						"foldChange": "0.9587"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".389605033493064",
						"qValue": "1",
						"foldChange": "1.0611"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".447156022474881",
						"qValue": "1",
						"foldChange": "1.0527"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".919386700513917",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".619488679923364",
						"qValue": "1",
						"foldChange": "1.3715"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".872689897040767",
						"qValue": "1",
						"foldChange": "1.2451"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".750714692641368",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".674886111578",
						"qValue": "1",
						"foldChange": "1.2289"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".865358735547756",
						"qValue": "1",
						"foldChange": "1.3837"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".815638875472236",
						"qValue": "1",
						"foldChange": "1.1260"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".855150417946485",
						"qValue": "1",
						"foldChange": "1.1139"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".899583509983056",
						"qValue": "1",
						"foldChange": "1.3227"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".962035593484013",
						"qValue": "1",
						"foldChange": "1.1875"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "2.1298"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".169270218891331",
						"qValue": ".091829568368456",
						"foldChange": "1.7935"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".218002511789049",
						"qValue": ".058709471656433",
						"foldChange": "1.6101"
					}
				]
			},
			{
				"metaboliteId": "0078dbb4-0000-0000-0000-000000000000",
				"chemicalId": "0000016a-0000-0000-0000-000000000000",
				"chemicalName": "inosine 5'-monophosphate (IMP)",
				"plotName": null,
				"compoundId": "00000855-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00175",
				"inChiKey": "GRSZFWQUAKGDAV-RWLQFSFNBK",
				"kegg": "C00130",
				"mass": 348.047104,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3742,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".457890597263844",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".687969266403583",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".752700488321387",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".877332353322449",
						"qValue": "1",
						"foldChange": "5.5391"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".561405360266471",
						"qValue": "1",
						"foldChange": "1.0085"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".669375375654096",
						"qValue": "1",
						"foldChange": "3.9641"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".917204274485739",
						"qValue": "1",
						"foldChange": "2.1869"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".61920557218037",
						"qValue": ".928868848501546",
						"foldChange": "3.9936"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".548209758576252",
						"qValue": ".957839854315834",
						"foldChange": "3.6582"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".79321998414375",
						"qValue": "1",
						"foldChange": "1.5474"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".319620235446916",
						"qValue": "1",
						"foldChange": "3.7337"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".210468557049105",
						"qValue": "1",
						"foldChange": "4.4184"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".365541265390127",
						"qValue": "1",
						"foldChange": "0.6747"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".535636655332558",
						"qValue": "1",
						"foldChange": "0.6305"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".807420243732303",
						"qValue": "1",
						"foldChange": "0.9344"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".400054854195719",
						"qValue": "1",
						"foldChange": "0.4306"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".451547360387371",
						"qValue": "1",
						"foldChange": "0.4357"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".964518120280523",
						"qValue": "1",
						"foldChange": "1.0117"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".763985335893112",
						"qValue": "1",
						"foldChange": "1.1727"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".248305364881189",
						"qValue": "1",
						"foldChange": "1.4814"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".370743956338414",
						"qValue": ".985830071188835",
						"foldChange": "1.2633"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000971293178121",
						"qValue": ".002572688255621",
						"foldChange": "9.5702"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".031264835329496",
						"qValue": ".025717073943292",
						"foldChange": "1.9447"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00155699976154",
						"qValue": ".001398480840298",
						"foldChange": "6.4604"
					}
				]
			},
			{
				"metaboliteId": "0078dbb5-0000-0000-0000-000000000000",
				"chemicalId": "00000233-0000-0000-0000-000000000000",
				"chemicalName": "glutamine",
				"plotName": null,
				"compoundId": "00000035-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00641",
				"inChiKey": "ZDXPYRJPNDTMRX-VKHMYHEABV",
				"kegg": "C00064",
				"mass": 146.069142,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 43,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".301649234214899",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".517662850779664",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".271864700149239",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".190173775823328",
						"qValue": "1",
						"foldChange": "0.9540"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".737521384112251",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".102236212949885",
						"qValue": "1",
						"foldChange": "1.0731"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".881173083919155",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".400600657739192",
						"qValue": ".882161593933088",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".323109251375539",
						"qValue": ".957839854315834",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".85183065651525",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".167657304772489",
						"qValue": "1",
						"foldChange": "0.9444"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".231001232452063",
						"qValue": "1",
						"foldChange": "0.8936"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".413058179922713",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".152834500005039",
						"qValue": "1",
						"foldChange": "1.0700"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".493923281574594",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".07548561119516",
						"qValue": "1",
						"foldChange": "1.1129"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".034934295326959",
						"qValue": "1",
						"foldChange": "1.1266"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".643405613119256",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".95641979304756",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".77316062823468",
						"qValue": "1",
						"foldChange": "0.9521"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".808470671218692",
						"qValue": ".997573179629986",
						"foldChange": "0.9385"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".172829179891371",
						"qValue": ".12021452780783",
						"foldChange": "1.2036"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004833949212314",
						"qValue": ".006328307675416",
						"foldChange": "1.2718"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000067269840875",
						"qValue": ".000172721742087",
						"foldChange": "1.2856"
					}
				]
			},
			{
				"metaboliteId": "0078dbb6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9d9-0000-0000-0000-000000000000",
				"chemicalName": "dopamine 3-O-sulfate",
				"plotName": null,
				"compoundId": "0000bd17-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06275",
				"inChiKey": "NZKRYJGNYPYXJZ-UHFFFAOYAU",
				"kegg": "C13690",
				"mass": 0.0,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 206,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".796625908080208",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".339816503237847",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".133261568647124",
						"qValue": ".877184673664369",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".874782716225496",
						"qValue": "1",
						"foldChange": "1.1840"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".391247447289073",
						"qValue": "1",
						"foldChange": "1.4068"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".311154135875827",
						"qValue": "1",
						"foldChange": "1.4241"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".015315004334085",
						"qValue": ".303094620658292",
						"foldChange": "0.8730"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".011416295155316",
						"qValue": ".567215491640796",
						"foldChange": "0.8662"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".908089730592408",
						"qValue": "1",
						"foldChange": "1.0364"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".931212723525024",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".776859735479062",
						"qValue": "1",
						"foldChange": "0.9958"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".711687659872741",
						"qValue": "1",
						"foldChange": "0.9474"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".311667444349134",
						"qValue": "1",
						"foldChange": "2.2001"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".800862912746108",
						"qValue": "1",
						"foldChange": "0.4952"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".4687719163448",
						"qValue": "1",
						"foldChange": "0.2251"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".467635062222216",
						"qValue": "1",
						"foldChange": "0.6985"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".661794468825042",
						"qValue": "1",
						"foldChange": "1.0237"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".25150174085431",
						"qValue": "1",
						"foldChange": "1.4656"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".110278688921377",
						"qValue": "1",
						"foldChange": "0.6131"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".540944701668737",
						"qValue": "1",
						"foldChange": "0.7337"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".350147583033664",
						"qValue": ".985830071188835",
						"foldChange": "1.1967"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000049104178312",
						"qValue": ".000325158627929",
						"foldChange": "3.4354"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000167235449891",
						"qValue": ".000805494946676",
						"foldChange": "3.4084"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000001430768613",
						"qValue": ".000019169428436",
						"foldChange": "4.6877"
					}
				]
			},
			{
				"metaboliteId": "0078dbb7-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8a8-0000-0000-0000-000000000000",
				"chemicalName": "N-formylphenylalanine",
				"plotName": null,
				"compoundId": "0000bd31-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NSTPXGARCQOSAU-VIFPVBQEBQ",
				"kegg": null,
				"mass": 193.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 209,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".905642935918641",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".79509761170035",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".825370191011727",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".744104933448554",
						"qValue": "1",
						"foldChange": "2.4322"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".46544793185931",
						"qValue": "1",
						"foldChange": "2.4385"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".685478261321677",
						"qValue": "1",
						"foldChange": "2.6599"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".943998024882083",
						"qValue": "1",
						"foldChange": "1.1004"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".428618489487879",
						"qValue": ".894260227315856",
						"foldChange": "1.3145"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".389208578156259",
						"qValue": ".957839854315834",
						"foldChange": "1.2352"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".724240438945719",
						"qValue": "1",
						"foldChange": "3.2770"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".731293424914074",
						"qValue": "1",
						"foldChange": "4.5656"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".487130523688147",
						"qValue": "1",
						"foldChange": "1.4381"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".414438136476035",
						"qValue": "1",
						"foldChange": "1.1671"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".578638594663586",
						"qValue": "1",
						"foldChange": "1.0708"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".823678670380671",
						"qValue": "1",
						"foldChange": "0.9175"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".571793863988749",
						"qValue": "1",
						"foldChange": "1.0335"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".549128611068428",
						"qValue": "1",
						"foldChange": "1.0961"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".947252014431142",
						"qValue": "1",
						"foldChange": "1.0606"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".534632881240185",
						"qValue": "1",
						"foldChange": "0.7854"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".653667203240559",
						"qValue": "1",
						"foldChange": "1.0703"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".886803654029068",
						"qValue": ".997573179629986",
						"foldChange": "1.3627"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032236271571896",
						"qValue": ".036670497319171",
						"foldChange": "3.9666"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00826465136745",
						"qValue": ".008770671777843",
						"foldChange": "2.8589"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000437633242434",
						"qValue": ".000579224335096",
						"foldChange": "4.5624"
					}
				]
			},
			{
				"metaboliteId": "0078dbb8-0000-0000-0000-000000000000",
				"chemicalId": "05f5f98a-0000-0000-0000-000000000000",
				"chemicalName": "umbelliferone sulfate",
				"plotName": null,
				"compoundId": "0000be22-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LJOOSFYJELZGMR-UHFFFAOYAS",
				"kegg": null,
				"mass": 241.988509,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4607,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".712999250259637",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".475671859354591",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".623446405795664",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".148312813615191",
						"qValue": "1",
						"foldChange": "5.1164"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".787924889258949",
						"qValue": "1",
						"foldChange": "1.3548"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".088325255099613",
						"qValue": "1",
						"foldChange": "0.7780"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".677977402986877",
						"qValue": "1",
						"foldChange": "2.6941"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".948252495518724",
						"qValue": "1",
						"foldChange": "4.3229"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".725984516980069",
						"qValue": ".976023509428735",
						"foldChange": "5.1028"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".856796014131464",
						"qValue": "1",
						"foldChange": "5.0775"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".488937129035318",
						"qValue": "1",
						"foldChange": "2.0678"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".608265022586812",
						"qValue": "1",
						"foldChange": "2.9618"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".716272857757321",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".942138535198882",
						"qValue": "1",
						"foldChange": "0.8304"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".781168621975408",
						"qValue": "1",
						"foldChange": "0.7448"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".201053991577408",
						"qValue": "1",
						"foldChange": "0.6854"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".268623794762285",
						"qValue": "1",
						"foldChange": "0.8377"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".914103452521631",
						"qValue": "1",
						"foldChange": "1.2221"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".938225703287265",
						"qValue": "1",
						"foldChange": "1.0285"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".454488658528944",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".400576425372617",
						"qValue": ".985830071188835",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001026404240098",
						"qValue": ".002675848708732",
						"foldChange": "8.4635"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".011939337069847",
						"qValue": ".011764104778738",
						"foldChange": "8.8318"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".290215606163093",
						"qValue": ".073945612240528",
						"foldChange": "1.2732"
					}
				]
			},
			{
				"metaboliteId": "0078dbb9-0000-0000-0000-000000000000",
				"chemicalId": "00000113-0000-0000-0000-000000000000",
				"chemicalName": "creatinine",
				"plotName": null,
				"compoundId": "00000201-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00562",
				"inChiKey": "DDRJAANPRJIHGJ-UHFFFAOYAV",
				"kegg": "C00791",
				"mass": 113.058912,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 457,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".906456260267614",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".092734928442544",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".397690291330534",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".147743168424014",
						"qValue": "1",
						"foldChange": "0.9746"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".21173786810316",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".837975462269591",
						"qValue": "1",
						"foldChange": "1.0046"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".507907548232799",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".701072680954083",
						"qValue": ".950986052805043",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".297491301250573",
						"qValue": ".957839854315834",
						"foldChange": "0.9843"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".941133120928457",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".053522865186356",
						"qValue": "1",
						"foldChange": "0.9642"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".062759850732528",
						"qValue": ".727024127920748",
						"foldChange": "0.9306"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".78060616162871",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".742887239724533",
						"qValue": "1",
						"foldChange": "0.9862"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".947090239782977",
						"qValue": "1",
						"foldChange": "0.9952"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".523234718292647",
						"qValue": "1",
						"foldChange": "1.0236"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".805068984162318",
						"qValue": "1",
						"foldChange": "1.0031"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".713560538774351",
						"qValue": "1",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".909112393023998",
						"qValue": "1",
						"foldChange": "1.0057"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".502686282918576",
						"qValue": "1",
						"foldChange": "0.9332"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".426264960186374",
						"qValue": ".985830071188835",
						"foldChange": "0.9279"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001407440035996",
						"qValue": ".003352447141714",
						"foldChange": "1.3187"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000000156066645",
						"qValue": ".000021759314313",
						"foldChange": "1.4029"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000284133112",
						"qValue": ".000009136352553",
						"foldChange": "1.3779"
					}
				]
			},
			{
				"metaboliteId": "0078dbba-0000-0000-0000-000000000000",
				"chemicalId": "00000370-0000-0000-0000-000000000000",
				"chemicalName": "adenine",
				"plotName": null,
				"compoundId": "0000022a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00034",
				"inChiKey": "GFFGJBXGBJISGV-UHFFFAOYAT",
				"kegg": "C00147",
				"mass": 135.054495,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3769,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".517613969138848",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".75851338277942",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".59410856026254",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".202867880530572",
						"qValue": "1",
						"foldChange": "1.3202"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".857467734792348",
						"qValue": "1",
						"foldChange": "0.9985"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".147649242345884",
						"qValue": "1",
						"foldChange": "0.9337"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".989650021179081",
						"qValue": "1",
						"foldChange": "1.0312"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".574517927081295",
						"qValue": ".928868848501546",
						"foldChange": "0.9818"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".583348037168495",
						"qValue": ".957839854315834",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".583712550412686",
						"qValue": "1",
						"foldChange": "0.9782"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".914439197898546",
						"qValue": "1",
						"foldChange": "1.0503"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".512593217302728",
						"qValue": "1",
						"foldChange": "1.0528"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".832944695599717",
						"qValue": "1",
						"foldChange": "0.9446"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".996625444163998",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".834725196626791",
						"qValue": "1",
						"foldChange": "1.0452"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".148295157774927",
						"qValue": "1",
						"foldChange": "0.8132"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".094112690688411",
						"qValue": "1",
						"foldChange": "0.7602"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".747897319611214",
						"qValue": "1",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".582131354243696",
						"qValue": "1",
						"foldChange": "0.9347"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".78651587318691",
						"qValue": "1",
						"foldChange": "0.9654"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".418396460998154",
						"qValue": ".985830071188835",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".015301892846684",
						"qValue": ".02034663750731",
						"foldChange": "1.4764"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".751045621441451",
						"qValue": ".277385958580678",
						"foldChange": "0.7800"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".064809282764494",
						"qValue": ".023103707573419",
						"foldChange": "1.2475"
					}
				]
			},
			{
				"metaboliteId": "0078dbbb-0000-0000-0000-000000000000",
				"chemicalId": "00000351-0000-0000-0000-000000000000",
				"chemicalName": "caffeine",
				"plotName": null,
				"compoundId": "00000239-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01847",
				"inChiKey": "RYYVLZVUVIJVGH-UHFFFAOYAW",
				"kegg": "C07481",
				"mass": 194.080376,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4155,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".235314889795206",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".459708303004118",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".903526523234866",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".884498850359797",
						"qValue": "1",
						"foldChange": "5.5513"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".614116084957843",
						"qValue": "1",
						"foldChange": "5.2192"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".516513804046274",
						"qValue": "1",
						"foldChange": "1.0323"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".418427928308907",
						"qValue": ".989197938984887",
						"foldChange": "1.1273"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".659761144964088",
						"qValue": ".934949271461149",
						"foldChange": "1.3328"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".710650317624072",
						"qValue": ".976023509428735",
						"foldChange": "1.6314"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".362059940510549",
						"qValue": "1",
						"foldChange": "1.1903"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".279415153085172",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".8627694156449",
						"qValue": "1",
						"foldChange": "1.2152"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".555106157002078",
						"qValue": "1",
						"foldChange": "2.4311"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".073889549205598",
						"qValue": "1",
						"foldChange": "2.3231"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".198333036339712",
						"qValue": "1",
						"foldChange": "0.9556"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".901548503818911",
						"qValue": "1",
						"foldChange": "1.1239"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".202064060807096",
						"qValue": "1",
						"foldChange": "1.7976"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".23392315992521",
						"qValue": "1",
						"foldChange": "1.5994"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".526002154250725",
						"qValue": "1",
						"foldChange": "2.4176"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".135750798198413",
						"qValue": "1",
						"foldChange": "2.5300"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".35143573199191",
						"qValue": ".985830071188835",
						"foldChange": "1.0465"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000028033450901",
						"qValue": ".000272988582771",
						"foldChange": "28.0091"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004169548074275",
						"qValue": ".00571379497161",
						"foldChange": "15.6185"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".017762820481084",
						"qValue": ".008524877073459",
						"foldChange": "4.4611"
					}
				]
			},
			{
				"metaboliteId": "0078dbbc-0000-0000-0000-000000000000",
				"chemicalId": "00000450-0000-0000-0000-000000000000",
				"chemicalName": "methyl indole-3-acetate",
				"plotName": null,
				"compoundId": "00000630-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29738",
				"inChiKey": "KTHADMDGDNYQRX-UHFFFAOYAX",
				"kegg": null,
				"mass": 189.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4467,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".927738125087352",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".539676947627152",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".216934671365306",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".107518517001861",
						"qValue": "1",
						"foldChange": "1.3515"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".923303011489497",
						"qValue": "1",
						"foldChange": "1.0806"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".129316699205283",
						"qValue": "1",
						"foldChange": "0.8305"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".166925235641156",
						"qValue": ".759643719415099",
						"foldChange": "1.3982"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".164955635092337",
						"qValue": ".787973217949622",
						"foldChange": "1.3019"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".994700216590245",
						"qValue": "1",
						"foldChange": "1.0808"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".28247500921535",
						"qValue": "1",
						"foldChange": "0.9798"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".296047341941435",
						"qValue": "1",
						"foldChange": "1.0277"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".975779068110022",
						"qValue": "1",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".924784758536853",
						"qValue": "1",
						"foldChange": "0.9292"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".328340688908974",
						"qValue": "1",
						"foldChange": "1.7974"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".275614543428497",
						"qValue": "1",
						"foldChange": "1.9344"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".799684767901278",
						"qValue": "1",
						"foldChange": "0.8827"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".704395500432346",
						"qValue": "1",
						"foldChange": "0.8167"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".886191140021205",
						"qValue": "1",
						"foldChange": "0.9253"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".585482635330203",
						"qValue": "1",
						"foldChange": "1.1133"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".696987282530905",
						"qValue": "1",
						"foldChange": "1.1335"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".896484594755676",
						"qValue": ".997573179629986",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".133449766682356",
						"qValue": ".101572326577429",
						"foldChange": "2.3289"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001162357595397",
						"qValue": ".002346481515406",
						"foldChange": "2.6230"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".073974860183211",
						"qValue": ".025632276433536",
						"foldChange": "1.7098"
					}
				]
			},
			{
				"metaboliteId": "0078dbbd-0000-0000-0000-000000000000",
				"chemicalId": "05f5fa1e-0000-0000-0000-000000000000",
				"chemicalName": "arabitol/xylitol",
				"plotName": null,
				"compoundId": "0000bef5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 152.068475,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1127,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".994107390904829",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".280730457776738",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".386556093772551",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".285719409048054",
						"qValue": "1",
						"foldChange": "1.1777"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".544219237824824",
						"qValue": "1",
						"foldChange": "0.9555"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".097439886510915",
						"qValue": "1",
						"foldChange": "0.9003"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".488187818289594",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".189285541627777",
						"qValue": ".821388069834477",
						"foldChange": "1.1429"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".529731746327726",
						"qValue": ".957839854315834",
						"foldChange": "1.0870"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".411983726383974",
						"qValue": "1",
						"foldChange": "1.1029"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".154205349981487",
						"qValue": "1",
						"foldChange": "1.2006"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".538144832673274",
						"qValue": "1",
						"foldChange": "1.0624"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".740087095184795",
						"qValue": "1",
						"foldChange": "0.9401"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".731635101495505",
						"qValue": "1",
						"foldChange": "0.9214"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".976055785110296",
						"qValue": "1",
						"foldChange": "0.9801"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".536390518908653",
						"qValue": "1",
						"foldChange": "0.8963"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".635330967570917",
						"qValue": "1",
						"foldChange": "0.9127"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".909437449864441",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".338892351534551",
						"qValue": "1",
						"foldChange": "1.1194"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".293358090309935",
						"qValue": "1",
						"foldChange": "1.0583"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".879233033768099",
						"qValue": ".997573179629986",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032973508658127",
						"qValue": ".036758311294647",
						"foldChange": "1.4447"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000638342786155",
						"qValue": ".001745094256805",
						"foldChange": "1.5792"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01466080899909",
						"qValue": ".007343006439647",
						"foldChange": "1.4502"
					}
				]
			},
			{
				"metaboliteId": "0078dbbe-0000-0000-0000-000000000000",
				"chemicalId": "00000337-0000-0000-0000-000000000000",
				"chemicalName": "pyruvate",
				"plotName": null,
				"compoundId": "0000bf5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00243",
				"inChiKey": "LCTONWCANYUPML-REWHXWOFAD",
				"kegg": "C00022",
				"mass": 88.016045,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".983140487287886",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".714472329842055",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".894130712046013",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".898841769116085",
						"qValue": "1",
						"foldChange": "1.0460"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".894903825329769",
						"qValue": "1",
						"foldChange": "1.0370"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".795513501987262",
						"qValue": "1",
						"foldChange": "1.1025"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".516151675401778",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".197222594167466",
						"qValue": ".825038702902081",
						"foldChange": "0.9621"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".516541454260677",
						"qValue": ".957839854315834",
						"foldChange": "1.0391"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".78117154045819",
						"qValue": "1",
						"foldChange": "1.0233"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".806074338154609",
						"qValue": "1",
						"foldChange": "1.0378"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".97420579789875",
						"qValue": "1",
						"foldChange": "0.9870"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".657886752828975",
						"qValue": "1",
						"foldChange": "1.0729"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".997258942581623",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".665390927151057",
						"qValue": "1",
						"foldChange": "0.9240"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".946055538218144",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".900624050879064",
						"qValue": "1",
						"foldChange": "0.9842"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".846618712570149",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".546859818312957",
						"qValue": "1",
						"foldChange": "0.8873"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".773540684093692",
						"qValue": "1",
						"foldChange": "0.8678"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".772113375068147",
						"qValue": ".997573179629986",
						"foldChange": "0.9780"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".04761568902171",
						"qValue": ".048296743596225",
						"foldChange": "1.4740"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".206717354321389",
						"qValue": ".106547871700041",
						"foldChange": "1.2447"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".104009633247428",
						"qValue": ".033646367327685",
						"foldChange": "1.4293"
					}
				]
			},
			{
				"metaboliteId": "0078dbbf-0000-0000-0000-000000000000",
				"chemicalId": "00000566-0000-0000-0000-000000000000",
				"chemicalName": "fluoxetine",
				"plotName": null,
				"compoundId": "0000ae4b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RTHCYVBBDHJXIQ-UHFFFAOYAV",
				"kegg": "D00326",
				"mass": 309.134048,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "31.7067"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "31.7067"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "10.5087"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "10.5087"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "10.5087"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dbc0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e723-0000-0000-0000-000000000000",
				"chemicalName": "1-arachidonoyl-GPE (20:4n6)*",
				"plotName": null,
				"compoundId": "00008972-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11517",
				"inChiKey": "ROPRRXYVXLDXQO-XSQXPFHXBE",
				"kegg": null,
				"mass": 501.285539,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2394,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".682070428823707",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".794031294500234",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".794673939220106",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".450095517133512",
						"qValue": "1",
						"foldChange": "1.0593"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".866042409067504",
						"qValue": "1",
						"foldChange": "1.0217"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".556704338169831",
						"qValue": "1",
						"foldChange": "0.9774"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".431012680262786",
						"qValue": ".994846434063709",
						"foldChange": "0.9817"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".375285822693892",
						"qValue": ".875723678608451",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".920143970767012",
						"qValue": "1",
						"foldChange": "1.0082"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".458182488225322",
						"qValue": "1",
						"foldChange": "0.9552"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".652413906708956",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".769545812051964",
						"qValue": "1",
						"foldChange": "0.9786"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".200754510782162",
						"qValue": "1",
						"foldChange": "1.0930"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".299998863702135",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".855438975857148",
						"qValue": "1",
						"foldChange": "0.9759"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".853200026561817",
						"qValue": "1",
						"foldChange": "1.0103"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".975777711624644",
						"qValue": "1",
						"foldChange": "1.0180"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".833416195801487",
						"qValue": "1",
						"foldChange": "1.0076"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".584855143220852",
						"qValue": "1",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".553523127044946",
						"qValue": "1",
						"foldChange": "1.0271"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".937789123036414",
						"qValue": ".997573179629986",
						"foldChange": "0.9650"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".078130084635161",
						"qValue": ".068798233676837",
						"foldChange": "1.3029"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".086867692210572",
						"qValue": ".055051697339553",
						"foldChange": "1.1911"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003362116718867",
						"qValue": ".002445915888942",
						"foldChange": "1.3673"
					}
				]
			},
			{
				"metaboliteId": "0078dbc1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e721-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPE (18:1)",
				"plotName": null,
				"compoundId": "00008b2c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11506",
				"inChiKey": "PYVRVRFVLRNJLY-MZMPXXGTBD",
				"kegg": null,
				"mass": 479.301189,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2380,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".870631131001362",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".927844422419273",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".542458754115191",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".220121694747894",
						"qValue": "1",
						"foldChange": "1.1842"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".278958919053783",
						"qValue": "1",
						"foldChange": "1.1490"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".883466960643416",
						"qValue": "1",
						"foldChange": "1.0388"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".332687358622352",
						"qValue": ".951252030575746",
						"foldChange": "0.9522"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".897314914474245",
						"qValue": "1",
						"foldChange": "1.0522"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".400175890184042",
						"qValue": ".957839854315834",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".609710379523262",
						"qValue": "1",
						"foldChange": "1.0063"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".613369297690066",
						"qValue": "1",
						"foldChange": "0.9725"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".99582047060438",
						"qValue": "1",
						"foldChange": "0.9435"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".205166349921155",
						"qValue": "1",
						"foldChange": "1.1449"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".290969068559146",
						"qValue": "1",
						"foldChange": "1.1515"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".881383201746368",
						"qValue": "1",
						"foldChange": "1.0058"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".703785053434094",
						"qValue": "1",
						"foldChange": "0.9300"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".819150977468474",
						"qValue": "1",
						"foldChange": "0.9940"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".893191232446891",
						"qValue": "1",
						"foldChange": "1.0688"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".728841101528339",
						"qValue": "1",
						"foldChange": "1.0828"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".905853890829822",
						"qValue": "1",
						"foldChange": "0.9451"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".647891392978769",
						"qValue": ".985830071188835",
						"foldChange": "0.8728"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".174366981922821",
						"qValue": ".120524565802269",
						"foldChange": "1.3489"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".277971226827003",
						"qValue": ".132497927112203",
						"foldChange": "1.1737"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003762135605334",
						"qValue": ".002688270294588",
						"foldChange": "1.7046"
					}
				]
			},
			{
				"metaboliteId": "0078dbc2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e202-0000-0000-0000-000000000000",
				"chemicalName": "glycerol 3-phosphate",
				"plotName": null,
				"compoundId": "0000ab47-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00126",
				"inChiKey": "AWUCVROLDVIAJX-UHFFFAOYAM",
				"kegg": "C00093",
				"mass": 172.013675,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2544,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".231425486403828",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".786228178678651",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".928419338435927",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".436882482919885",
						"qValue": "1",
						"foldChange": "4.5677"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".43262640084068",
						"qValue": "1",
						"foldChange": "2.1571"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".994170092000448",
						"qValue": "1",
						"foldChange": "3.1210"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".794731748419905",
						"qValue": "1",
						"foldChange": "1.3397"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".667208801169829",
						"qValue": ".93517659928016",
						"foldChange": "2.8476"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".864923377093213",
						"qValue": "1",
						"foldChange": "2.8343"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".878019590593206",
						"qValue": "1",
						"foldChange": "1.5449"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".674439919904726",
						"qValue": "1",
						"foldChange": "2.5389"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".566670661151166",
						"qValue": "1",
						"foldChange": "2.3167"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".44914458188532",
						"qValue": "1",
						"foldChange": "1.0220"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".983598529925907",
						"qValue": "1",
						"foldChange": "0.7599"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".476305870825983",
						"qValue": "1",
						"foldChange": "0.7435"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".831429917976187",
						"qValue": "1",
						"foldChange": "0.6472"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".564669153598353",
						"qValue": "1",
						"foldChange": "0.3933"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".426543612554735",
						"qValue": "1",
						"foldChange": "0.6076"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".71107437724385",
						"qValue": "1",
						"foldChange": "1.1560"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".243156487141189",
						"qValue": "1",
						"foldChange": "0.6155"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".12180105486535",
						"qValue": ".985830071188835",
						"foldChange": "0.5324"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000041019667154",
						"qValue": ".000320994653109",
						"foldChange": "0.1850"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002829196151695",
						"qValue": ".004287561295635",
						"foldChange": "0.4169"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002759659750057",
						"qValue": ".002133109795806",
						"foldChange": "0.3980"
					}
				]
			},
			{
				"metaboliteId": "0078dbc3-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebaf-0000-0000-0000-000000000000",
				"chemicalName": "ranitidine",
				"plotName": null,
				"compoundId": "000096c3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01930",
				"inChiKey": "VMXUWOKSQNHOCA-UKTHLTGXBF",
				"kegg": "D00422",
				"mass": 314.141261,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Gastrointestinal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5006,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dbc4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8dc-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetylcarnitine",
				"plotName": null,
				"compoundId": "0000bd29-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 279.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1034,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".796537792737893",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".695697609313361",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".237116534361623",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".10217592416077",
						"qValue": "1",
						"foldChange": "2.1205"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".092215544332798",
						"qValue": "1",
						"foldChange": "2.1170"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".958833814111796",
						"qValue": "1",
						"foldChange": "1.1088"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".959514408335543",
						"qValue": "1",
						"foldChange": "1.3287"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".641558160212211",
						"qValue": ".928868848501546",
						"foldChange": "1.5384"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".67820584332569",
						"qValue": ".976023509428735",
						"foldChange": "1.2337"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".123049779475902",
						"qValue": "1",
						"foldChange": "0.7589"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".426606322927725",
						"qValue": "1",
						"foldChange": "0.9568"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".446310895712013",
						"qValue": "1",
						"foldChange": "1.2753"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".590334773183115",
						"qValue": "1",
						"foldChange": "1.2448"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".530915583595172",
						"qValue": "1",
						"foldChange": "0.9001"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".903655919559887",
						"qValue": "1",
						"foldChange": "0.7231"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".637437937461953",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".184816782014398",
						"qValue": "1",
						"foldChange": "0.6242"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".361992392070111",
						"qValue": "1",
						"foldChange": "0.6621"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".797397042361836",
						"qValue": "1",
						"foldChange": "1.0809"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".375792580302787",
						"qValue": "1",
						"foldChange": "0.6731"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".508508144476491",
						"qValue": ".985830071188835",
						"foldChange": "0.6227"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".237714147780884",
						"qValue": ".150775704164066",
						"foldChange": "1.4121"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".055045204346163",
						"qValue": ".039661910433677",
						"foldChange": "2.3069"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".248289541015827",
						"qValue": ".065170446978678",
						"foldChange": "1.4445"
					}
				]
			},
			{
				"metaboliteId": "0078dbc5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ee-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyl-epsilon-lysine",
				"plotName": null,
				"compoundId": "0000848e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03869",
				"inChiKey": "JPKNLFVGUZRHOB-YUMQZZPRBM",
				"kegg": null,
				"mass": 275.148121,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 557,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844580519489401",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".37996459641149",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".952646616580316",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".517263266418866",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".412116541579668",
						"qValue": "1",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".861822234633861",
						"qValue": "1",
						"foldChange": "1.0328"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".731961885002427",
						"qValue": "1",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".666744805312232",
						"qValue": ".93517659928016",
						"foldChange": "1.0437"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".440499207420538",
						"qValue": ".957839854315834",
						"foldChange": "0.9632"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".774007795735892",
						"qValue": "1",
						"foldChange": "0.9757"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".29531410848989",
						"qValue": "1",
						"foldChange": "0.9360"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".44547221284834",
						"qValue": "1",
						"foldChange": "0.9000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999106155409627",
						"qValue": "1",
						"foldChange": "0.9893"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".959228099755764",
						"qValue": "1",
						"foldChange": "0.9346"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".959099063335603",
						"qValue": "1",
						"foldChange": "0.9447"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".438468962755585",
						"qValue": "1",
						"foldChange": "1.1177"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".834549555653756",
						"qValue": "1",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".590689911823208",
						"qValue": "1",
						"foldChange": "0.9184"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".741774460030554",
						"qValue": "1",
						"foldChange": "1.0203"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".785176720745922",
						"qValue": "1",
						"foldChange": "0.8911"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".550057348085564",
						"qValue": ".985830071188835",
						"foldChange": "0.8734"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".103149190653091",
						"qValue": ".08411755283077",
						"foldChange": "1.3531"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00397499969967",
						"qValue": ".005514500099902",
						"foldChange": "1.6069"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003974397906354",
						"qValue": ".0028273782442",
						"foldChange": "1.9714"
					}
				]
			},
			{
				"metaboliteId": "0078dbc6-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee45-0000-0000-0000-000000000000",
				"chemicalName": "trimethylamine N-oxide",
				"plotName": null,
				"compoundId": "00009dd6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00925",
				"inChiKey": "UYPYRKYUKCHHIB-UHFFFAOYAU",
				"kegg": "C01104",
				"mass": 75.068414,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1985,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".656106476707597",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".283300504547964",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".63772543927666",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".100307799457149",
						"qValue": "1",
						"foldChange": "1.8577"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".27698006305673",
						"qValue": "1",
						"foldChange": "1.5656"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".567282287834096",
						"qValue": "1",
						"foldChange": "1.0453"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".799008455559021",
						"qValue": "1",
						"foldChange": "1.0509"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".315837536814158",
						"qValue": ".825038702902081",
						"foldChange": "1.5841"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".210317711419225",
						"qValue": ".957839854315834",
						"foldChange": "1.3965"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".547115148354881",
						"qValue": "1",
						"foldChange": "1.2099"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".572521200327026",
						"qValue": "1",
						"foldChange": "1.1923"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".969728373205",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".969297712188559",
						"qValue": "1",
						"foldChange": "1.0161"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".703166214237392",
						"qValue": "1",
						"foldChange": "0.9173"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".669002251367844",
						"qValue": "1",
						"foldChange": "0.9028"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".173725263231681",
						"qValue": "1",
						"foldChange": "0.5613"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".274376344103636",
						"qValue": "1",
						"foldChange": "0.6451"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".838645518516738",
						"qValue": "1",
						"foldChange": "1.1493"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".949718169029621",
						"qValue": "1",
						"foldChange": "0.8167"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".468366228710649",
						"qValue": "1",
						"foldChange": "0.6718"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".495162994817214",
						"qValue": ".985830071188835",
						"foldChange": "0.8226"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".438279696573315",
						"qValue": ".22888057345372",
						"foldChange": "1.4039"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".256201925860955",
						"qValue": ".12434741170742",
						"foldChange": "1.3378"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".266918980100436",
						"qValue": ".069104903085383",
						"foldChange": "1.8262"
					}
				]
			},
			{
				"metaboliteId": "0078dbc7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e671-0000-0000-0000-000000000000",
				"chemicalName": "isovalerylcarnitine (C5)",
				"plotName": null,
				"compoundId": "00008667-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00688",
				"inChiKey": "IGQBPDJNUXPEMT-UHFFFAOYAB",
				"kegg": null,
				"mass": 245.162708,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 306,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".953646717426709",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".516954005372975",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".846611644269925",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".857320235918521",
						"qValue": "1",
						"foldChange": "1.0649"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".918982085171898",
						"qValue": "1",
						"foldChange": "1.0647"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".77838721944462",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".455604968779267",
						"qValue": "1",
						"foldChange": "0.9758"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".451409563132218",
						"qValue": ".901759479402622",
						"foldChange": "0.9962"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".994394354398486",
						"qValue": "1",
						"foldChange": "1.0290"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".187741910931151",
						"qValue": "1",
						"foldChange": "0.8961"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".586937348691223",
						"qValue": "1",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".433780011489831",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".963802228604291",
						"qValue": "1",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".726719842650466",
						"qValue": "1",
						"foldChange": "1.0308"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".753412979920885",
						"qValue": "1",
						"foldChange": "1.0453"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".633482440676959",
						"qValue": "1",
						"foldChange": "0.8857"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".594163335096289",
						"qValue": "1",
						"foldChange": "0.8606"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".932849622546723",
						"qValue": "1",
						"foldChange": "0.9717"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".752166288221038",
						"qValue": "1",
						"foldChange": "0.8872"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".931822493858345",
						"qValue": "1",
						"foldChange": "0.9006"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".694080780651842",
						"qValue": ".992710494680197",
						"foldChange": "1.0150"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".800137500107509",
						"qValue": ".344316995840098",
						"foldChange": "1.0141"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".594131687251161",
						"qValue": ".234662190655155",
						"foldChange": "0.9367"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".077515691525269",
						"qValue": ".026516290818571",
						"foldChange": "0.7028"
					}
				]
			},
			{
				"metaboliteId": "0078dbc8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e684-0000-0000-0000-000000000000",
				"chemicalName": "N2,N2-dimethylguanosine",
				"plotName": null,
				"compoundId": "00008941-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04824",
				"inChiKey": "RSPURTUNRHNVGF-IOSLPCCCBE",
				"kegg": null,
				"mass": 311.122969,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".948861845289081",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".065370482818124",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".375755858503229",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".350428475274752",
						"qValue": "1",
						"foldChange": "0.9650"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".506580533854276",
						"qValue": "1",
						"foldChange": "0.9740"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".785443382118673",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".572936843035921",
						"qValue": "1",
						"foldChange": "0.9912"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".914732405868156",
						"qValue": "1",
						"foldChange": "1.0101"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".647603419249811",
						"qValue": ".973034272612281",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".061216860799479",
						"qValue": ".821720595261736",
						"foldChange": "0.9216"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".007783107408121",
						"qValue": ".522751459721039",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".393491857131095",
						"qValue": "1",
						"foldChange": "0.8974"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".911710602126288",
						"qValue": "1",
						"foldChange": "0.9715"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".726292813339056",
						"qValue": "1",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".801481972148764",
						"qValue": "1",
						"foldChange": "1.0258"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".791060282386539",
						"qValue": "1",
						"foldChange": "1.0227"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".966024093107531",
						"qValue": "1",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".764037572097189",
						"qValue": "1",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".743824918698094",
						"qValue": "1",
						"foldChange": "1.0325"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".638617105640779",
						"qValue": "1",
						"foldChange": "0.8789"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".426926659940298",
						"qValue": ".985830071188835",
						"foldChange": "0.8512"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".552361356348873",
						"qValue": ".272145309043163",
						"foldChange": "1.1781"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006428410529066",
						"qValue": ".007503153045567",
						"foldChange": "1.3333"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".141699151886639",
						"qValue": ".042662568590251",
						"foldChange": "1.1773"
					}
				]
			},
			{
				"metaboliteId": "0078dbc9-0000-0000-0000-000000000000",
				"chemicalId": "000000fa-0000-0000-0000-000000000000",
				"chemicalName": "biliverdin",
				"plotName": null,
				"compoundId": "00000859-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01008",
				"inChiKey": "QBUVFDKTZJNUPP-BBROENKCBK",
				"kegg": "C00500",
				"mass": 582.247836,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4016,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".241217432378836",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".212038839404106",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".954310546251512",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".573263118635649",
						"qValue": "1",
						"foldChange": "1.4663"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".658075373861833",
						"qValue": "1",
						"foldChange": "0.9666"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".316385591111872",
						"qValue": "1",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".992625947360536",
						"qValue": "1",
						"foldChange": "1.0964"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".279315273712297",
						"qValue": ".825038702902081",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".275303364531949",
						"qValue": ".957839854315834",
						"foldChange": "0.9598"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".34785852388929",
						"qValue": "1",
						"foldChange": "1.2768"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".963824395855346",
						"qValue": "1",
						"foldChange": "1.1306"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".325385373549397",
						"qValue": "1",
						"foldChange": "0.8843"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".989688607442609",
						"qValue": "1",
						"foldChange": "0.9704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".123584561231014",
						"qValue": "1",
						"foldChange": "0.6051"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".117723471858535",
						"qValue": "1",
						"foldChange": "0.6235"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".709033506663917",
						"qValue": "1",
						"foldChange": "0.7029"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".199957110183002",
						"qValue": "1",
						"foldChange": "0.5076"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".337586666612219",
						"qValue": "1",
						"foldChange": "0.7222"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".692609271976437",
						"qValue": "1",
						"foldChange": "0.8534"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".191479212188117",
						"qValue": "1",
						"foldChange": "0.6486"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".335417333346737",
						"qValue": ".985830071188835",
						"foldChange": "0.7599"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000439138131272",
						"qValue": ".001435732104421",
						"foldChange": "2.8478"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000145467003934",
						"qValue": ".00075116585854",
						"foldChange": "2.6842"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000094370286037",
						"qValue": ".000204229996389",
						"foldChange": "4.2747"
					}
				]
			},
			{
				"metaboliteId": "0078dbca-0000-0000-0000-000000000000",
				"chemicalId": "0000014b-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglutamate",
				"plotName": null,
				"compoundId": "00008f82-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11737",
				"inChiKey": "OWQDWQKWSLFFFR-WDSKDSINBR",
				"kegg": "C05282",
				"mass": 276.095753,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".695340515916734",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".212408962825809",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".601700282688393",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".249728803905426",
						"qValue": "1",
						"foldChange": "1.2571"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".481737278743132",
						"qValue": "1",
						"foldChange": "0.9416"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".067003797326779",
						"qValue": "1",
						"foldChange": "0.8660"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".434321675677368",
						"qValue": ".994846434063709",
						"foldChange": "1.1495"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".423457359973664",
						"qValue": ".891985676578188",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".984997288822922",
						"qValue": "1",
						"foldChange": "1.1040"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".279283075481977",
						"qValue": "1",
						"foldChange": "1.1917"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".322121118394147",
						"qValue": "1",
						"foldChange": "1.1913"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".925597599149131",
						"qValue": "1",
						"foldChange": "0.9985"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".443119227147593",
						"qValue": "1",
						"foldChange": "0.8366"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".821874403569133",
						"qValue": "1",
						"foldChange": "0.9554"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".607321083263528",
						"qValue": "1",
						"foldChange": "1.1421"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".294728285982913",
						"qValue": "1",
						"foldChange": "0.8238"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".819649850155834",
						"qValue": "1",
						"foldChange": "0.8338"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".432507702696406",
						"qValue": "1",
						"foldChange": "1.0122"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".812222223536503",
						"qValue": "1",
						"foldChange": "1.0170"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".361028908163337",
						"qValue": "1",
						"foldChange": "1.1302"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".47899610120107",
						"qValue": ".985830071188835",
						"foldChange": "1.1113"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".207030189649492",
						"qValue": ".137091495510299",
						"foldChange": "1.4149"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".027787037213473",
						"qValue": ".02347974678443",
						"foldChange": "1.4332"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".178427911535493",
						"qValue": ".051135315117907",
						"foldChange": "1.2752"
					}
				]
			},
			{
				"metaboliteId": "0078dbcb-0000-0000-0000-000000000000",
				"chemicalId": "05f5f080-0000-0000-0000-000000000000",
				"chemicalName": "triamterene",
				"plotName": null,
				"compoundId": "0000a661-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01940",
				"inChiKey": "FNYLWPVRPXGIIP-UHFFFAOYAQ",
				"kegg": "D00386",
				"mass": 253.107593,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4983,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444216931193124",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339932",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".025345881135787",
						"qValue": ".359575885726771",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".282255835864009",
						"qValue": ".825038702902081",
						"foldChange": "0.9628"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".228184142482206",
						"qValue": ".957839854315834",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".091042103409514",
						"qValue": "1",
						"foldChange": "1.1683"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".101365353145356",
						"qValue": "1",
						"foldChange": "0.8559"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999991",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".366861540271161",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".3819665457222",
						"qValue": ".985830071188835",
						"foldChange": "0.9359"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dbcc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e833-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylalanine",
				"plotName": null,
				"compoundId": "000090c7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29142",
				"inChiKey": "WQXXXVRAFAKQJM-WHFBIAKZBJ",
				"kegg": null,
				"mass": 218.090272,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 548,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".760158832062599",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".501308344299836",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".140851370571727",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".431410165899232",
						"qValue": "1",
						"foldChange": "1.0183"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".984638069174216",
						"qValue": "1",
						"foldChange": "1.0369"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".442660267018646",
						"qValue": "1",
						"foldChange": "1.1952"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".643357471930798",
						"qValue": "1",
						"foldChange": "1.0736"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".638499701947619",
						"qValue": ".928868848501546",
						"foldChange": "1.0944"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".99456550139117",
						"qValue": "1",
						"foldChange": "1.0480"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".205795579808127",
						"qValue": "1",
						"foldChange": "1.1857"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".169663009475436",
						"qValue": "1",
						"foldChange": "0.8661"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".010140014054084",
						"qValue": ".566846104084484",
						"foldChange": "0.7182"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".418131498703588",
						"qValue": "1",
						"foldChange": "0.8684"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".983482900987688",
						"qValue": "1",
						"foldChange": "1.0263"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".42056975602483",
						"qValue": "1",
						"foldChange": "1.1818"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".94908289877603",
						"qValue": "1",
						"foldChange": "0.9061"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".213763317951857",
						"qValue": "1",
						"foldChange": "1.2327"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".182578035865282",
						"qValue": "1",
						"foldChange": "1.3605"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".597216772364556",
						"qValue": "1",
						"foldChange": "0.8965"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".416382619435928",
						"qValue": "1",
						"foldChange": "0.8248"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".746608724653797",
						"qValue": ".997573179629986",
						"foldChange": "0.9200"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".861677285984427",
						"qValue": ".361182939797515",
						"foldChange": "1.0670"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".372166695030442",
						"qValue": ".16139558078953",
						"foldChange": "1.1554"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".072131913512711",
						"qValue": ".025101893543608",
						"foldChange": "1.4135"
					}
				]
			},
			{
				"metaboliteId": "0078dbcd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e80d-0000-0000-0000-000000000000",
				"chemicalName": "methylphosphate",
				"plotName": null,
				"compoundId": "000090ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61711",
				"inChiKey": "CAAULPUQFIIOTL-UHFFFAOYAW",
				"kegg": null,
				"mass": 111.992545,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine and Pyrimidine Metabolism",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3896,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".314253660372821",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".636095373597732",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".851309363776449",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".431767398407277",
						"qValue": "1",
						"foldChange": "1.2173"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".566148922724145",
						"qValue": "1",
						"foldChange": "1.0758"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".83058363933631",
						"qValue": "1",
						"foldChange": "1.1173"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".258888265849748",
						"qValue": ".825038702902081",
						"foldChange": "1.1957"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".258888265849748",
						"qValue": ".957839854315834",
						"foldChange": "1.1957"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".588982310409829",
						"qValue": "1",
						"foldChange": "0.9400"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".609377558383485",
						"qValue": "1",
						"foldChange": "0.9400"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".188619541178366",
						"qValue": "1",
						"foldChange": "0.7933"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".213527738893898",
						"qValue": "1",
						"foldChange": "0.7933"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".966751483923332",
						"qValue": "1",
						"foldChange": "1.0005"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".295516288155627",
						"qValue": "1",
						"foldChange": "0.8367"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".302303907908265",
						"qValue": ".985830071188835",
						"foldChange": "0.8363"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003248277056311",
						"qValue": ".006429398770965",
						"foldChange": "0.2910"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000006812069393",
						"qValue": ".000146117023651",
						"foldChange": "0.2793"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000524893135937",
						"qValue": ".000649155209803",
						"foldChange": "0.2777"
					}
				]
			},
			{
				"metaboliteId": "0078dbce-0000-0000-0000-000000000000",
				"chemicalId": "05f5f29d-0000-0000-0000-000000000000",
				"chemicalName": "S-allylcysteine",
				"plotName": null,
				"compoundId": "0000a8e7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34323",
				"inChiKey": "ZFAHNWWNDFHPOH-UHFFFAOYAF",
				"kegg": null,
				"mass": 161.051049,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4534,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".037610219902645",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".220560708277173",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".531766806890569",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".564219255696678",
						"qValue": "1",
						"foldChange": "4.1016"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".976883634355857",
						"qValue": "1",
						"foldChange": "2.1695"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".544911234412263",
						"qValue": "1",
						"foldChange": "5.0954"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".159615411537448",
						"qValue": ".742487134132407",
						"foldChange": "5.3469"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".036289048047539",
						"qValue": ".678234398444917",
						"foldChange": "7.4418"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".47167597463827",
						"qValue": ".957839854315834",
						"foldChange": "2.0627"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".701296502729698",
						"qValue": "1",
						"foldChange": "4.0049"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".481817782086267",
						"qValue": "1",
						"foldChange": "3.5117"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".279081284684545",
						"qValue": "1",
						"foldChange": "4.5574"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".512454415379578",
						"qValue": "1",
						"foldChange": "1.6322"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".945040891887598",
						"qValue": "1",
						"foldChange": "1.2744"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".480944706458827",
						"qValue": "1",
						"foldChange": "0.7808"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".02250883766862",
						"qValue": "1",
						"foldChange": "3.5467"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".943236232340792",
						"qValue": "1",
						"foldChange": "1.8434"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".032026482838615",
						"qValue": ".822195137696456",
						"foldChange": "0.5198"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".019010860237751",
						"qValue": "1",
						"foldChange": "4.0758"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".601892709053526",
						"qValue": "1",
						"foldChange": "1.3991"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".07742017401823",
						"qValue": ".985830071188835",
						"foldChange": "0.3433"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".33727391794613",
						"qValue": ".192863611674612",
						"foldChange": "0.7832"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005617634548322",
						"qValue": ".006900751516465",
						"foldChange": "3.3006"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".147452895251766",
						"qValue": ".04373962179892",
						"foldChange": "0.3805"
					}
				]
			},
			{
				"metaboliteId": "0078dbcf-0000-0000-0000-000000000000",
				"chemicalId": "05f5f14d-0000-0000-0000-000000000000",
				"chemicalName": "O-desmethylvenlafaxine",
				"plotName": null,
				"compoundId": "0000a946-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60532",
				"inChiKey": null,
				"kegg": "D07793",
				"mass": 263.188529,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5094,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444216931193129",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339932",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".957869479255482",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".048339831649422",
						"qValue": ".678234398444917",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".054278742146592",
						"qValue": ".721737649480466",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999995",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".959462032298317",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".96073206706059",
						"qValue": "1",
						"foldChange": "0.9997"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".055564847773412",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".063475044737294",
						"qValue": ".985830071188835",
						"foldChange": "0.9865"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dbd0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5bb-0000-0000-0000-000000000000",
				"chemicalName": "sebacate (C10-DC)",
				"plotName": null,
				"compoundId": "00007e8e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00792",
				"inChiKey": "CXMXRPHRNRROMY-UHFFFAOYAE",
				"kegg": "C08277",
				"mass": 202.120509,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".598926759912131",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".739497316726714",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".671506886685217",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".590743914447867",
						"qValue": "1",
						"foldChange": "2.4690"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".474864456770917",
						"qValue": "1",
						"foldChange": "0.8589"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".213175860853712",
						"qValue": "1",
						"foldChange": "0.8737"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".902343823639838",
						"qValue": "1",
						"foldChange": "2.2943"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".973785097550838",
						"qValue": "1",
						"foldChange": "1.7346"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".928410110703506",
						"qValue": "1",
						"foldChange": "2.9293"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".403276538300773",
						"qValue": "1",
						"foldChange": "1.6159"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".28801142172403",
						"qValue": "1",
						"foldChange": "2.5011"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".818167533794775",
						"qValue": "1",
						"foldChange": "1.4747"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".848920947548667",
						"qValue": "1",
						"foldChange": "1.0499"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".26588649004262",
						"qValue": "1",
						"foldChange": "0.4578"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".186560759335938",
						"qValue": "1",
						"foldChange": "0.4361"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".686501218447729",
						"qValue": "1",
						"foldChange": "0.9594"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".415371488443147",
						"qValue": "1",
						"foldChange": "0.4916"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".656365305236482",
						"qValue": "1",
						"foldChange": "0.5124"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".417713301303452",
						"qValue": "1",
						"foldChange": "1.5596"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".638844850510551",
						"qValue": "1",
						"foldChange": "0.9464"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".759844107178391",
						"qValue": ".997573179629986",
						"foldChange": "0.6069"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000041279218295",
						"qValue": ".000320994653109",
						"foldChange": "0.1749"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000132250682893",
						"qValue": ".000714140423892",
						"foldChange": "0.1990"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000022652248765",
						"qValue": ".000102368819781",
						"foldChange": "0.1493"
					}
				]
			},
			{
				"metaboliteId": "0078dbd1-0000-0000-0000-000000000000",
				"chemicalId": "05f603fa-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl dihydrosphingomyelin (d18:0/16:0)*",
				"plotName": null,
				"compoundId": "0000ccd2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QHZIGNLCLJPLCU-QPPIDDCLBH",
				"kegg": null,
				"mass": 704.58328,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2787,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".264640387556294",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".80845656703347",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".02129189954253",
						"qValue": ".622389736970234",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".916948412094226",
						"qValue": "1",
						"foldChange": "1.0115"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".774456843957593",
						"qValue": "1",
						"foldChange": "1.0293"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".696059877629424",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".019490315972406",
						"qValue": ".345547060260772",
						"foldChange": "0.8900"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".617144134332149",
						"qValue": ".928868848501546",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".061593212708269",
						"qValue": ".750620749550044",
						"foldChange": "1.1436"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".107239525305252",
						"qValue": "1",
						"foldChange": "1.1152"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".378568344785664",
						"qValue": "1",
						"foldChange": "0.9599"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".014734448626439",
						"qValue": ".573029207973764",
						"foldChange": "0.8454"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".500526221093815",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".243823313686291",
						"qValue": "1",
						"foldChange": "1.1192"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".583111882798467",
						"qValue": "1",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".50789468860221",
						"qValue": "1",
						"foldChange": "0.9431"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".029505823309534",
						"qValue": "1",
						"foldChange": "1.2447"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".005164986136168",
						"qValue": ".605991101987089",
						"foldChange": "1.3197"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".836059080615045",
						"qValue": "1",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".64368120878568",
						"qValue": "1",
						"foldChange": "1.0390"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".784196158444296",
						"qValue": ".997573179629986",
						"foldChange": "0.9951"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".257064247269037",
						"qValue": ".160285411375899",
						"foldChange": "1.0918"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".147728801243036",
						"qValue": ".082718173677614",
						"foldChange": "1.1757"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013792451197963",
						"qValue": ".006989178209325",
						"foldChange": "1.2049"
					}
				]
			},
			{
				"metaboliteId": "0078dbd2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e84d-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-arachidonoyl-GPC (18:0/20:4)",
				"plotName": null,
				"compoundId": "0000a5d2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08048",
				"inChiKey": "PSVRFUPOQYJOOZ-QNPWAGBNBV",
				"kegg": null,
				"mass": 809.593455,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2071,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".255353794888289",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".55383976747846",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".611601702354367",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".982001146247203",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".437736379158887",
						"qValue": "1",
						"foldChange": "1.0324"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".424660247308777",
						"qValue": "1",
						"foldChange": "1.0330"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".792216908918986",
						"qValue": "1",
						"foldChange": "0.9976"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".458225794441861",
						"qValue": ".902562957068497",
						"foldChange": "0.9814"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".631361816053394",
						"qValue": ".973034272612281",
						"foldChange": "0.9885"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".135776365815764",
						"qValue": "1",
						"foldChange": "0.9475"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".464970322587576",
						"qValue": "1",
						"foldChange": "0.9793"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".438854162034905",
						"qValue": "1",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".932627200459749",
						"qValue": "1",
						"foldChange": "0.9704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".08966950688749",
						"qValue": "1",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".096970022946086",
						"qValue": "1",
						"foldChange": "1.1188"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".964545956848201",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".378725492912176",
						"qValue": "1",
						"foldChange": "1.0717"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".345277085747999",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".45069213662198",
						"qValue": "1",
						"foldChange": "0.9573"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".372290288773947",
						"qValue": "1",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".104675820198027",
						"qValue": ".985830071188835",
						"foldChange": "1.0953"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".048909861378937",
						"qValue": ".049083860920383",
						"foldChange": "1.1644"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".317611700418653",
						"qValue": ".146873784991233",
						"foldChange": "1.0744"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".075163935452564",
						"qValue": ".02593251273936",
						"foldChange": "1.1129"
					}
				]
			},
			{
				"metaboliteId": "0078dbd3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9b3-0000-0000-0000-000000000000",
				"chemicalName": "4-cholesten-3-one",
				"plotName": null,
				"compoundId": "000094ed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00921",
				"inChiKey": "NYOXRYYXRWJDKP-UHFFFAOYAV",
				"kegg": "C00599",
				"mass": 384.339216,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2904,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".564742020335581",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".660746322696186",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".001249800354804",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".190245641508279",
						"qValue": "1",
						"foldChange": "0.9427"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".352870014169241",
						"qValue": "1",
						"foldChange": "0.9493"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".697886176947884",
						"qValue": "1",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007093064833835",
						"qValue": ".237900912197889",
						"foldChange": "1.2219"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".392853968882162",
						"qValue": ".880987329915339",
						"foldChange": "0.9649"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000598529613183",
						"qValue": ".061261421022253",
						"foldChange": "0.7929"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".825273307061563",
						"qValue": "1",
						"foldChange": "1.0472"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".031087751507098",
						"qValue": ".933767468184815",
						"foldChange": "1.2020"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".051260933415627",
						"qValue": ".727024127920748",
						"foldChange": "1.1069"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".216034294963932",
						"qValue": "1",
						"foldChange": "0.8431"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".734897310870148",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".389404340842153",
						"qValue": "1",
						"foldChange": "1.1923"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".531925375118965",
						"qValue": "1",
						"foldChange": "1.1000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".721823652907685",
						"qValue": "1",
						"foldChange": "1.0649"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".80866114071378",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".236980922791622",
						"qValue": "1",
						"foldChange": "0.8597"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".265086316004074",
						"qValue": "1",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".026289740041902",
						"qValue": ".985830071188835",
						"foldChange": "1.3477"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001457722276026",
						"qValue": ".003447415208889",
						"foldChange": "2.0107"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".152655084938283",
						"qValue": ".084964724886792",
						"foldChange": "1.2629"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004080310243761",
						"qValue": ".002852241898975",
						"foldChange": "1.4500"
					}
				]
			},
			{
				"metaboliteId": "0078dbd4-0000-0000-0000-000000000000",
				"chemicalId": "05f60427-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-stearoyl)-2-arachidonoyl-GPE (P-18:0/20:4)*",
				"plotName": null,
				"compoundId": "0000ccfb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05779",
				"inChiKey": "URPXXNCTXCOATD-FXMFQVEGBH",
				"kegg": null,
				"mass": 751.55159,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2520,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".550289505391739",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".369682533737263",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".891970781605243",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".90345057835652",
						"qValue": "1",
						"foldChange": "1.0698"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".770513882543809",
						"qValue": "1",
						"foldChange": "0.9875"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".86462147568465",
						"qValue": "1",
						"foldChange": "1.1013"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".362093215597278",
						"qValue": ".951252030575746",
						"foldChange": "0.9803"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".346697428372077",
						"qValue": ".847814688346659",
						"foldChange": "0.9403"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".97604011336968",
						"qValue": "1",
						"foldChange": "1.0623"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".187436145968353",
						"qValue": "1",
						"foldChange": "0.8773"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".63684879426105",
						"qValue": "1",
						"foldChange": "1.0033"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".392895725297266",
						"qValue": "1",
						"foldChange": "1.1058"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".946535366265886",
						"qValue": "1",
						"foldChange": "0.9383"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".411316006583965",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".365083056165012",
						"qValue": "1",
						"foldChange": "1.1236"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".523280880069454",
						"qValue": "1",
						"foldChange": "0.8652"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".912215375310836",
						"qValue": "1",
						"foldChange": "0.8926"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".612899533440517",
						"qValue": "1",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".596111174728943",
						"qValue": "1",
						"foldChange": "0.9112"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".50221578926375",
						"qValue": "1",
						"foldChange": "1.1049"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".232235900861852",
						"qValue": ".985830071188835",
						"foldChange": "1.2127"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".450568642299795",
						"qValue": ".233092246668155",
						"foldChange": "1.1950"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".421272218481525",
						"qValue": ".177447521005981",
						"foldChange": "0.8866"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".077731118862658",
						"qValue": ".026533529115226",
						"foldChange": "1.2523"
					}
				]
			},
			{
				"metaboliteId": "0078dbd5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e21a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglutamate",
				"plotName": null,
				"compoundId": "00003d68-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01138",
				"inChiKey": "RFMMMVDNIPUKGG-QUCRTXIXBK",
				"kegg": "C00624",
				"mass": 189.06348,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 45,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".371833803100754",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".295289883643683",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".661405144194557",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".112260742697912",
						"qValue": "1",
						"foldChange": "0.7514"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".873778109742326",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".151330616228464",
						"qValue": "1",
						"foldChange": "2.8008"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".923646822930177",
						"qValue": "1",
						"foldChange": "2.1188"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".550895618764289",
						"qValue": ".918025358340401",
						"foldChange": "1.3715"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".616351582045699",
						"qValue": ".967408831530889",
						"foldChange": "2.9626"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".343229244309012",
						"qValue": "1",
						"foldChange": "0.8009"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".367326414997223",
						"qValue": "1",
						"foldChange": "0.8566"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".962561969343773",
						"qValue": "1",
						"foldChange": "1.3834"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".703563918445175",
						"qValue": "1",
						"foldChange": "0.9362"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".366098522738391",
						"qValue": "1",
						"foldChange": "1.2562"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".197341105934563",
						"qValue": "1",
						"foldChange": "1.3417"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".333079259478092",
						"qValue": "1",
						"foldChange": "1.3148"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".161355220499444",
						"qValue": "1",
						"foldChange": "1.5111"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".614071601761164",
						"qValue": "1",
						"foldChange": "1.1493"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".457817236462765",
						"qValue": "1",
						"foldChange": "0.9281"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".816661664809476",
						"qValue": "1",
						"foldChange": "1.0628"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".339657032334123",
						"qValue": ".985830071188835",
						"foldChange": "1.1451"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000007849388495",
						"qValue": ".000112365462478",
						"foldChange": "9.4735"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000339881237051",
						"qValue": ".001151023864247",
						"foldChange": "8.1823"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000018712385121",
						"qValue": ".000094015654658",
						"foldChange": "8.3211"
					}
				]
			},
			{
				"metaboliteId": "0078dbd6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f861-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/14:0, d18:1/14:1)*",
				"plotName": null,
				"compoundId": "0000b832-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 672.520625,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2802,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".695109538912643",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".265830690784498",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".416053500483906",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".125342514209507",
						"qValue": "1",
						"foldChange": "1.1761"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".098011829100305",
						"qValue": "1",
						"foldChange": "1.2033"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".899098509608494",
						"qValue": "1",
						"foldChange": "1.0434"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".515578749558257",
						"qValue": "1",
						"foldChange": "1.1020"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".196183319085955",
						"qValue": ".825038702902081",
						"foldChange": "1.1489"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".515139783392645",
						"qValue": ".957839854315834",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".602330266571677",
						"qValue": "1",
						"foldChange": "1.0713"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".446553901412976",
						"qValue": "1",
						"foldChange": "0.9957"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".202542130033369",
						"qValue": "1",
						"foldChange": "0.8537"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".33101365867199",
						"qValue": "1",
						"foldChange": "1.3207"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".360013346720893",
						"qValue": "1",
						"foldChange": "1.1601"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".996136773242505",
						"qValue": "1",
						"foldChange": "0.8784"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".537659443309035",
						"qValue": "1",
						"foldChange": "1.2873"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".579021955901836",
						"qValue": "1",
						"foldChange": "1.1623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".976757432246157",
						"qValue": "1",
						"foldChange": "0.9029"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".421126744290929",
						"qValue": "1",
						"foldChange": "1.3198"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".991668221269929",
						"qValue": "1",
						"foldChange": "0.9587"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".441904943731174",
						"qValue": ".985830071188835",
						"foldChange": "0.7264"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".82554960518101",
						"qValue": ".352045466002712",
						"foldChange": "1.0746"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".060881645943547",
						"qValue": ".042441575766544",
						"foldChange": "1.7556"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".125650056832553",
						"qValue": ".038732159388337",
						"foldChange": "1.3706"
					}
				]
			},
			{
				"metaboliteId": "0078dbd7-0000-0000-0000-000000000000",
				"chemicalId": "05f603fb-0000-0000-0000-000000000000",
				"chemicalName": "tricosanoyl sphingomyelin (d18:1/23:0)*",
				"plotName": null,
				"compoundId": "0000ccd4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12105",
				"inChiKey": null,
				"kegg": null,
				"mass": 800.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".269170166040448",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".621313873492654",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".080567974237797",
						"qValue": ".767448234592668",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".95276205388393",
						"qValue": "1",
						"foldChange": "1.0313"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".171187708719151",
						"qValue": "1",
						"foldChange": "1.1601"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".189922553250401",
						"qValue": "1",
						"foldChange": "1.1423"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".421795694106217",
						"qValue": ".989197938984887",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".268996716891343",
						"qValue": ".825038702902081",
						"foldChange": "1.1320"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".059709939573151",
						"qValue": ".750620749550044",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".215790402757003",
						"qValue": "1",
						"foldChange": "1.1188"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".47475130628446",
						"qValue": "1",
						"foldChange": "0.9692"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".054060429736312",
						"qValue": ".727024127920748",
						"foldChange": "0.8149"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".447530826971661",
						"qValue": "1",
						"foldChange": "1.1422"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".108598103220547",
						"qValue": "1",
						"foldChange": "1.2236"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".352002603927153",
						"qValue": "1",
						"foldChange": "1.0713"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".735473701407624",
						"qValue": "1",
						"foldChange": "1.1073"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".029755444593729",
						"qValue": "1",
						"foldChange": "1.4470"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".054224321970711",
						"qValue": ".822195137696456",
						"foldChange": "1.3067"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".553220102181086",
						"qValue": "1",
						"foldChange": "1.1528"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".55507536070271",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".975841332627855",
						"qValue": "1",
						"foldChange": "0.9184"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".800759956201396",
						"qValue": ".344316995840098",
						"foldChange": "1.0412"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".118264454376261",
						"qValue": ".069836543035658",
						"foldChange": "1.3416"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".218691187892394",
						"qValue": ".058796450022689",
						"foldChange": "1.1634"
					}
				]
			},
			{
				"metaboliteId": "0078dbd8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e84e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPE (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000a5d1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05322",
				"inChiKey": "HBZNVZIRJWODIB-NHCUFCNUBC",
				"kegg": null,
				"mass": 715.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2145,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".217125518958131",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".513009411015445",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".384371674380979",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".325552382774226",
						"qValue": "1",
						"foldChange": "1.1604"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".63899231817699",
						"qValue": "1",
						"foldChange": "1.0725"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".604636961094431",
						"qValue": "1",
						"foldChange": "0.9923"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".084278073014609",
						"qValue": ".623082837721659",
						"foldChange": "1.3247"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".077600509885263",
						"qValue": ".687896186587066",
						"foldChange": "1.2749"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".967869122440657",
						"qValue": "1",
						"foldChange": "1.0591"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".369409627610856",
						"qValue": "1",
						"foldChange": "0.9100"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".717258350931831",
						"qValue": "1",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".590440892781195",
						"qValue": "1",
						"foldChange": "1.0153"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".313940238167256",
						"qValue": "1",
						"foldChange": "1.3504"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".678126755618629",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".578494933642897",
						"qValue": "1",
						"foldChange": "0.7638"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".171795624523522",
						"qValue": "1",
						"foldChange": "1.1884"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".55449952691456",
						"qValue": "1",
						"foldChange": "0.7945"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".057602608734642",
						"qValue": ".822195137696456",
						"foldChange": "0.6686"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".097479283935239",
						"qValue": "1",
						"foldChange": "1.3388"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".978254435958121",
						"qValue": "1",
						"foldChange": "0.8790"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".102357618606212",
						"qValue": ".985830071188835",
						"foldChange": "0.6565"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".607428112838897",
						"qValue": ".290834600193072",
						"foldChange": "1.0733"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001829194082922",
						"qValue": ".003207951322721",
						"foldChange": "1.9629"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".052122461315136",
						"qValue": ".019726029021561",
						"foldChange": "1.6059"
					}
				]
			},
			{
				"metaboliteId": "0078dbd9-0000-0000-0000-000000000000",
				"chemicalId": "05f60581-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoyl-2-linolenoyl-GPC (16:1/18:3)*",
				"plotName": null,
				"compoundId": "0000cfbc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08008",
				"inChiKey": null,
				"kegg": null,
				"mass": 753.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2050,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".507583135207333",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".560751244022339",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".00658340422401",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".375164844826753",
						"qValue": "1",
						"foldChange": "1.1423"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".057560044056492",
						"qValue": "1",
						"foldChange": "1.2993"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".298736803949225",
						"qValue": "1",
						"foldChange": "1.1995"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".080943923481339",
						"qValue": ".615029275737672",
						"foldChange": "1.2637"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".011137528935798",
						"qValue": ".567215491640796",
						"foldChange": "1.3982"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".395340898385027",
						"qValue": ".957839854315834",
						"foldChange": "1.1708"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".353990509860692",
						"qValue": "1",
						"foldChange": "0.9193"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".011516902759373",
						"qValue": ".606329888436129",
						"foldChange": "0.7721"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".097231063831975",
						"qValue": ".85302716825784",
						"foldChange": "0.7805"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".399831955282869",
						"qValue": "1",
						"foldChange": "1.2492"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".16204504152326",
						"qValue": "1",
						"foldChange": "1.3669"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".52726960921166",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".221790119601264",
						"qValue": "1",
						"foldChange": "1.3596"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".585671465940175",
						"qValue": "1",
						"foldChange": "1.1890"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".525104408610124",
						"qValue": "1",
						"foldChange": "0.8745"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".267217131204772",
						"qValue": "1",
						"foldChange": "1.3327"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".443822348810694",
						"qValue": "1",
						"foldChange": "0.7437"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".067966531489",
						"qValue": ".985830071188835",
						"foldChange": "0.5580"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".733413085484345",
						"qValue": ".328877982604021",
						"foldChange": "1.1995"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".025536744728962",
						"qValue": ".021977871567485",
						"foldChange": "1.8139"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".016185273188266",
						"qValue": ".007885461214948",
						"foldChange": "1.8401"
					}
				]
			},
			{
				"metaboliteId": "0078dbda-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecae-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-linoleoyl-glycerol (18:1/18:2) [2]",
				"plotName": null,
				"compoundId": "0000b6cf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07219",
				"inChiKey": null,
				"kegg": null,
				"mass": 618.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2627,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".787501716675545",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".341203975086233",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".051786419230432",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".890686501233013",
						"qValue": "1",
						"foldChange": "1.0118"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".231085834801248",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".287846340855211",
						"qValue": "1",
						"foldChange": "0.9115"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".003723168663477",
						"qValue": ".176023140701035",
						"foldChange": "1.4856"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".018576498771918",
						"qValue": ".608023094419306",
						"foldChange": "1.3785"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".542885109004373",
						"qValue": ".957839854315834",
						"foldChange": "0.9880"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815111976955317",
						"qValue": "1",
						"foldChange": "1.0558"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".521669797247524",
						"qValue": "1",
						"foldChange": "1.1553"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".382902903658751",
						"qValue": "1",
						"foldChange": "1.0695"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".129764806945281",
						"qValue": "1",
						"foldChange": "0.6967"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".774052286287722",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".235089038743841",
						"qValue": "1",
						"foldChange": "1.5207"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".839647599325015",
						"qValue": "1",
						"foldChange": "0.8772"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".738751322359736",
						"qValue": "1",
						"foldChange": "0.9992"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".884029980661651",
						"qValue": "1",
						"foldChange": "1.1390"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".987292169571772",
						"qValue": "1",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".613067092922893",
						"qValue": "1",
						"foldChange": "1.0691"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".615208426151844",
						"qValue": ".985830071188835",
						"foldChange": "1.1873"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".390243956598806",
						"qValue": ".211466615472062",
						"foldChange": "1.3323"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".037328662339466",
						"qValue": ".029403854939673",
						"foldChange": "1.4562"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".2334113995674",
						"qValue": ".061721928783907",
						"foldChange": "1.5681"
					}
				]
			},
			{
				"metaboliteId": "0078dbdb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e994-0000-0000-0000-000000000000",
				"chemicalName": "13-HODE + 9-HODE",
				"plotName": null,
				"compoundId": "00009378-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 296.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1769,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".635007365228702",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".520184779927362",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".305531548304471",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".061839807051841",
						"qValue": "1",
						"foldChange": "2.0163"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".029692456460857",
						"qValue": "1",
						"foldChange": "2.0729"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".743723578499047",
						"qValue": "1",
						"foldChange": "1.1278"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".778962220589818",
						"qValue": "1",
						"foldChange": "1.0804"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".689842453107495",
						"qValue": ".948394067196249",
						"foldChange": "1.2146"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".905587242434285",
						"qValue": "1",
						"foldChange": "1.0853"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".734071996569295",
						"qValue": "1",
						"foldChange": "1.6873"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".915773683168627",
						"qValue": "1",
						"foldChange": "1.1337"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".656160060487981",
						"qValue": "1",
						"foldChange": "1.4624"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".605091497757781",
						"qValue": "1",
						"foldChange": "1.2510"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".480806366419732",
						"qValue": "1",
						"foldChange": "1.2132"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".824063228245004",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".233236850273499",
						"qValue": "1",
						"foldChange": "0.8484"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".325273560544269",
						"qValue": "1",
						"foldChange": "0.7590"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".880676563442783",
						"qValue": "1",
						"foldChange": "0.8947"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".125676402695619",
						"qValue": "1",
						"foldChange": "0.7441"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".381070815176745",
						"qValue": "1",
						"foldChange": "0.7183"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".549727078470354",
						"qValue": ".985830071188835",
						"foldChange": "0.9654"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".597829293455367",
						"qValue": ".28895715886893",
						"foldChange": "1.1107"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".239143604034112",
						"qValue": ".118866921433219",
						"foldChange": "1.5710"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".081187773606261",
						"qValue": ".027538057094324",
						"foldChange": "1.4621"
					}
				]
			},
			{
				"metaboliteId": "0078dbdc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6f6-0000-0000-0000-000000000000",
				"chemicalName": "malonylcarnitine",
				"plotName": null,
				"compoundId": "000090c3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02095",
				"inChiKey": "ZGNBLKBZJBJFDG-UHFFFAOYAY",
				"kegg": null,
				"mass": 247.105587,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1312,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".745752838601593",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".779415765455342",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".507611117625906",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".998028092515912",
						"qValue": "1",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".506405818698176",
						"qValue": "1",
						"foldChange": "0.9981"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".504836783718725",
						"qValue": "1",
						"foldChange": "1.0160"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".105097291120473",
						"qValue": ".676892563582731",
						"foldChange": "1.4251"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".148598541259625",
						"qValue": ".782571329595308",
						"foldChange": "1.4336"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".855003774729232",
						"qValue": "1",
						"foldChange": "1.0362"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".7396533819886",
						"qValue": "1",
						"foldChange": "0.9659"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".95822229629309",
						"qValue": "1",
						"foldChange": "1.0230"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".700540072490607",
						"qValue": "1",
						"foldChange": "1.0267"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".339772932104035",
						"qValue": "1",
						"foldChange": "0.8456"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".481146600969524",
						"qValue": "1",
						"foldChange": "0.7904"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".836887805535814",
						"qValue": "1",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".948435304126294",
						"qValue": "1",
						"foldChange": "1.0031"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".356061656837133",
						"qValue": "1",
						"foldChange": "0.8157"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".314046434887167",
						"qValue": "1",
						"foldChange": "0.8132"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".696403731383586",
						"qValue": "1",
						"foldChange": "0.9896"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".796906911649201",
						"qValue": "1",
						"foldChange": "0.7815"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".521874061847249",
						"qValue": ".985830071188835",
						"foldChange": "0.7897"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".0076070875905",
						"qValue": ".012526994606525",
						"foldChange": "1.7869"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003293519524952",
						"qValue": ".00485918623599",
						"foldChange": "2.0212"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".025365450206099",
						"qValue": ".011391490543222",
						"foldChange": "2.2211"
					}
				]
			},
			{
				"metaboliteId": "0078dbdd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e699-0000-0000-0000-000000000000",
				"chemicalName": "1-arachidonylglycerol (20:4)",
				"plotName": null,
				"compoundId": "0000865d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11549",
				"inChiKey": "DCPCOKIYJYGMDN-DOFZRALJBT",
				"kegg": "C13857",
				"mass": 378.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2564,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".725452533788945",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".993472915304658",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".964836948712204",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".676967295908194",
						"qValue": "1",
						"foldChange": "3.1350"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".833567570780651",
						"qValue": "1",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".531398877620409",
						"qValue": "1",
						"foldChange": "5.6279"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".933231324538705",
						"qValue": "1",
						"foldChange": "1.3750"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".894853791645222",
						"qValue": "1",
						"foldChange": "1.4619"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".961402716537861",
						"qValue": "1",
						"foldChange": "1.4930"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".750549240226525",
						"qValue": "1",
						"foldChange": "0.9631"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".953228824344116",
						"qValue": "1",
						"foldChange": "1.3042"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".706576957252738",
						"qValue": "1",
						"foldChange": "1.3580"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".91158416188868",
						"qValue": "1",
						"foldChange": "0.7224"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".756326260845224",
						"qValue": "1",
						"foldChange": "0.4441"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".670652170084205",
						"qValue": "1",
						"foldChange": "0.6148"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".856496025750756",
						"qValue": "1",
						"foldChange": "0.4560"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".355413068069946",
						"qValue": "1",
						"foldChange": "0.2487"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".439571064966464",
						"qValue": "1",
						"foldChange": "0.5454"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".688405715286872",
						"qValue": "1",
						"foldChange": "0.7104"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".930147560770239",
						"qValue": "1",
						"foldChange": "0.4261"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".632611638098431",
						"qValue": ".985830071188835",
						"foldChange": "0.5999"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000006858200633",
						"qValue": ".00010321298626",
						"foldChange": "0.1610"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000133650071918",
						"qValue": ".000714140423892",
						"foldChange": "0.2415"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002398661003201",
						"qValue": ".001957598637457",
						"foldChange": "0.4057"
					}
				]
			},
			{
				"metaboliteId": "0078dbde-0000-0000-0000-000000000000",
				"chemicalId": "00000236-0000-0000-0000-000000000000",
				"chemicalName": "valine",
				"plotName": null,
				"compoundId": "00000671-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00883",
				"inChiKey": "KZSNJWFQEVHDMF-BYPYZUCNBW",
				"kegg": "C00183",
				"mass": 117.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".655122109657633",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".154361830115322",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".433893213860644",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".688072663218483",
						"qValue": "1",
						"foldChange": "0.9897"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".467949946724948",
						"qValue": "1",
						"foldChange": "1.0421"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".261786264953186",
						"qValue": "1",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".18380372941997",
						"qValue": ".797133099236872",
						"foldChange": "1.0770"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".169978292367094",
						"qValue": ".789529107655781",
						"foldChange": "1.0848"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".96458863031433",
						"qValue": "1",
						"foldChange": "1.0101"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".208189748114898",
						"qValue": "1",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".543998855589631",
						"qValue": "1",
						"foldChange": "1.0341"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".065235417581074",
						"qValue": ".727024127920748",
						"foldChange": "1.0344"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".382091315525069",
						"qValue": "1",
						"foldChange": "0.9234"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".590272536726456",
						"qValue": "1",
						"foldChange": "1.0422"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".166085455466334",
						"qValue": "1",
						"foldChange": "1.1286"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".970350309774004",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".959218311710989",
						"qValue": "1",
						"foldChange": "0.9648"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".986932012367918",
						"qValue": "1",
						"foldChange": "0.9637"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".577876183250036",
						"qValue": "1",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".620068114109215",
						"qValue": "1",
						"foldChange": "0.9720"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".297920970117566",
						"qValue": ".985830071188835",
						"foldChange": "1.0300"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".691268166785467",
						"qValue": ".319208348085773",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".607318471739196",
						"qValue": ".237661566132019",
						"foldChange": "1.0257"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".562739559229817",
						"qValue": ".120794353577769",
						"foldChange": "0.9604"
					}
				]
			},
			{
				"metaboliteId": "0078dbdf-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb9-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-arachidonoyl-glycerol (18:1/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000d6b1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07228",
				"inChiKey": null,
				"kegg": null,
				"mass": 642.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2640,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".462508503989877",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".957455489191152",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".026956573045281",
						"qValue": ".674707166515718",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".462665157404862",
						"qValue": "1",
						"foldChange": "0.9548"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".251030480654337",
						"qValue": "1",
						"foldChange": "0.9228"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".675286982207118",
						"qValue": "1",
						"foldChange": "0.9961"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".036246121342231",
						"qValue": ".428409017531088",
						"foldChange": "1.2897"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".738474747692069",
						"qValue": ".957498790731047",
						"foldChange": "1.0844"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".075196178257102",
						"qValue": ".794743555017874",
						"foldChange": "0.8878"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".09424867544818",
						"qValue": ".966332804896402",
						"foldChange": "0.8740"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".624595538064041",
						"qValue": "1",
						"foldChange": "1.1124"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".032675496226958",
						"qValue": ".614826369149758",
						"foldChange": "1.2327"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".17691743188497",
						"qValue": "1",
						"foldChange": "0.7686"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".697028600059294",
						"qValue": "1",
						"foldChange": "1.1452"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".091333728440031",
						"qValue": "1",
						"foldChange": "1.4900"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".984282480121208",
						"qValue": "1",
						"foldChange": "1.0250"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".908401505667196",
						"qValue": "1",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".891080887337811",
						"qValue": "1",
						"foldChange": "0.9182"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".529467941418963",
						"qValue": "1",
						"foldChange": "0.9017"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".24123800844296",
						"qValue": "1",
						"foldChange": "1.2336"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".075012326326455",
						"qValue": ".985830071188835",
						"foldChange": "1.3681"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006958248315634",
						"qValue": ".011584932797588",
						"foldChange": "1.8636"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".047442027049554",
						"qValue": ".036046431626031",
						"foldChange": "1.6209"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".025937582222333",
						"qValue": ".011551630654369",
						"foldChange": "1.6324"
					}
				]
			},
			{
				"metaboliteId": "0078dbe0-0000-0000-0000-000000000000",
				"chemicalId": "05f60bce-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (12:0/18:1, 14:0/16:1, 16:0/14:1) [1]*",
				"plotName": null,
				"compoundId": "0000d6da-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 538.459725,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2586,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".962745650949047",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".398841530842324",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".423837187565759",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".158885936013391",
						"qValue": "1",
						"foldChange": "1.6818"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".323198718563759",
						"qValue": "1",
						"foldChange": "2.1789"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".667024549732849",
						"qValue": "1",
						"foldChange": "1.1038"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".852656807790899",
						"qValue": "1",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".328007125951204",
						"qValue": ".831655911352372",
						"foldChange": "1.7001"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".426807803919809",
						"qValue": ".957839854315834",
						"foldChange": "1.8994"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".211116423863587",
						"qValue": "1",
						"foldChange": "0.9044"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".817903023825783",
						"qValue": "1",
						"foldChange": "1.3734"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".140438205998483",
						"qValue": ".960252848686774",
						"foldChange": "1.9525"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".742864899677541",
						"qValue": "1",
						"foldChange": "1.4141"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".640370298711713",
						"qValue": "1",
						"foldChange": "2.2926"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".871890162100841",
						"qValue": "1",
						"foldChange": "1.6213"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".704791460999834",
						"qValue": "1",
						"foldChange": "0.9158"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".314475326786973",
						"qValue": "1",
						"foldChange": "0.4354"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".50398585907428",
						"qValue": "1",
						"foldChange": "0.4754"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".768842521900569",
						"qValue": "1",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".94712381278254",
						"qValue": "1",
						"foldChange": "1.0837"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".828337360841819",
						"qValue": ".997573179629986",
						"foldChange": "1.1997"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".743281607447612",
						"qValue": ".330326907870435",
						"foldChange": "1.8902"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".704658840845625",
						"qValue": ".266432367171315",
						"foldChange": "0.7299"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".405228044642504",
						"qValue": ".094168981073947",
						"foldChange": "1.9508"
					}
				]
			},
			{
				"metaboliteId": "0078dbe1-0000-0000-0000-000000000000",
				"chemicalId": "05f5f996-0000-0000-0000-000000000000",
				"chemicalName": "behenoyl sphingomyelin (d18:1/22:0)*",
				"plotName": null,
				"compoundId": "0000bd6c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12103",
				"inChiKey": null,
				"kegg": null,
				"mass": 786.661475,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2794,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".048654448444492",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".620468013601849",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".88127206930494",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".741813168054077",
						"qValue": "1",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".844339958616852",
						"qValue": "1",
						"foldChange": "1.0218"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".599399770249779",
						"qValue": "1",
						"foldChange": "1.0447"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".657359417804449",
						"qValue": "1",
						"foldChange": "0.9926"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".952641469950859",
						"qValue": "1",
						"foldChange": "1.0088"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".700733029485439",
						"qValue": ".976023509428735",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".39113634467459",
						"qValue": "1",
						"foldChange": "0.9555"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".235085447374435",
						"qValue": "1",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".737527647255641",
						"qValue": "1",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".581737813751758",
						"qValue": "1",
						"foldChange": "1.0655"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".013693924740118",
						"qValue": "1",
						"foldChange": "1.2629"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".041612333168969",
						"qValue": "1",
						"foldChange": "1.1852"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".620183681845392",
						"qValue": "1",
						"foldChange": "1.0383"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".030498633832135",
						"qValue": "1",
						"foldChange": "1.2279"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".077060035537109",
						"qValue": ".822195137696456",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".690453447134829",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".090414419820996",
						"qValue": "1",
						"foldChange": "1.1373"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".172425838783204",
						"qValue": ".985830071188835",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".267857023844877",
						"qValue": ".164841896302784",
						"foldChange": "1.1369"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".124581143380665",
						"qValue": ".072372934321015",
						"foldChange": "1.0904"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".289304352112421",
						"qValue": ".073830434308976",
						"foldChange": "1.0865"
					}
				]
			},
			{
				"metaboliteId": "0078dbe2-0000-0000-0000-000000000000",
				"chemicalId": "05f61eac-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/18:1)*",
				"plotName": null,
				"compoundId": "0000e082-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 726.567575,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2797,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".883732602296615",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".66603183564467",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".690733948006983",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".798147268012444",
						"qValue": "1",
						"foldChange": "0.9954"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".493058362410792",
						"qValue": "1",
						"foldChange": "1.0614"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".347925671127083",
						"qValue": "1",
						"foldChange": "1.0836"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".349316591601159",
						"qValue": ".951252030575746",
						"foldChange": "1.1288"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".947132279829104",
						"qValue": "1",
						"foldChange": "1.0303"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".384053007196628",
						"qValue": ".957839854315834",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".382300593081607",
						"qValue": "1",
						"foldChange": "1.0849"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".973792772842058",
						"qValue": "1",
						"foldChange": "1.0291"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".400240612704958",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".733276747810729",
						"qValue": "1",
						"foldChange": "0.9962"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".931152940722815",
						"qValue": "1",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".808977209708115",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".325192269195272",
						"qValue": "1",
						"foldChange": "1.1675"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".47248331597576",
						"qValue": "1",
						"foldChange": "1.1802"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".825307484109799",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".990315754990825",
						"qValue": "1",
						"foldChange": "1.0014"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".792974876927377",
						"qValue": "1",
						"foldChange": "1.0141"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".79709764527791",
						"qValue": ".997573179629986",
						"foldChange": "1.0127"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".107139939982897",
						"qValue": ".086099577673984",
						"foldChange": "0.7929"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".431310660513895",
						"qValue": ".180314007109476",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".371453389848472",
						"qValue": ".087953992346103",
						"foldChange": "0.9002"
					}
				]
			},
			{
				"metaboliteId": "0078dbe3-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0d6-0000-0000-0000-000000000000",
				"chemicalName": "margaroylcarnitine (C17)*",
				"plotName": null,
				"compoundId": "0000e0a8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06210",
				"inChiKey": null,
				"kegg": null,
				"mass": 413.350509,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1649,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".348748651786959",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".269787229099008",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".732363209300372",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".202450809311887",
						"qValue": "1",
						"foldChange": "1.1988"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".248454875657",
						"qValue": "1",
						"foldChange": "1.1502"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".901859320031105",
						"qValue": "1",
						"foldChange": "1.0421"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".934370297803364",
						"qValue": "1",
						"foldChange": "1.0237"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".818122045101596",
						"qValue": ".991769031882419",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".882613085051633",
						"qValue": "1",
						"foldChange": "1.0054"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".21434312507224",
						"qValue": "1",
						"foldChange": "1.1649"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".181405953695919",
						"qValue": "1",
						"foldChange": "1.2049"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".922539863461368",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".646643141129012",
						"qValue": "1",
						"foldChange": "0.9107"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".981118239132065",
						"qValue": "1",
						"foldChange": "0.9438"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".639610169134726",
						"qValue": "1",
						"foldChange": "1.0364"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".184569878693177",
						"qValue": "1",
						"foldChange": "0.8231"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".960530888378844",
						"qValue": "1",
						"foldChange": "0.9673"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".181815161610394",
						"qValue": "1",
						"foldChange": "1.1752"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".181117847401205",
						"qValue": "1",
						"foldChange": "0.8401"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".850911578775353",
						"qValue": "1",
						"foldChange": "1.0018"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".138288404490289",
						"qValue": ".985830071188835",
						"foldChange": "1.1925"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".056574390048078",
						"qValue": ".054293473077349",
						"foldChange": "1.5214"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".022877247544717",
						"qValue": ".020316047970125",
						"foldChange": "1.4343"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000057357662427",
						"qValue": ".00016467376775",
						"foldChange": "1.9443"
					}
				]
			},
			{
				"metaboliteId": "0078dbe4-0000-0000-0000-000000000000",
				"chemicalId": "05f6044a-0000-0000-0000-000000000000",
				"chemicalName": "cetirizine",
				"plotName": null,
				"compoundId": "0000d5d9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05032",
				"inChiKey": "ZKLPARSLTMPFCP-UHFFFAOYAC",
				"kegg": "C07778",
				"mass": 388.15537,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Respiratory",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5135,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".626976148266046",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".720645202432493",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".146465049873857",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".278616019224538",
						"qValue": "1",
						"foldChange": "1.2663"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".522052545809821",
						"qValue": "1",
						"foldChange": "1.1171"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".654073349292569",
						"qValue": "1",
						"foldChange": "0.9561"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".014412351842549",
						"qValue": ".303094620658292",
						"foldChange": "0.8496"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".07079637310594",
						"qValue": ".687896186587066",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".492766860752737",
						"qValue": ".957839854315834",
						"foldChange": "1.1255"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".639547052886822",
						"qValue": "1",
						"foldChange": "7.8272"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".512420695843621",
						"qValue": "1",
						"foldChange": "0.0822"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".265119421294316",
						"qValue": "1",
						"foldChange": "0.0105"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".915124120108954",
						"qValue": "1",
						"foldChange": "0.7846"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".410772879143792",
						"qValue": "1",
						"foldChange": "0.0230"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".458672852827575",
						"qValue": "1",
						"foldChange": "0.0294"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".939987901762769",
						"qValue": "1",
						"foldChange": "2.8450"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".450980942084517",
						"qValue": "1",
						"foldChange": "0.0376"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".398986774115465",
						"qValue": ".985830071188835",
						"foldChange": "0.0132"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "75.6906"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "26.6048"
					}
				]
			},
			{
				"metaboliteId": "0078dbe5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e856-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl-N-nervonoyl-sphingosine (d18:1/24:1)*",
				"plotName": null,
				"compoundId": "0000e019-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 809.674469,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2751,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".516537240217128",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".907111792383468",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".554369605908463",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".819423671385195",
						"qValue": "1",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".435132677826608",
						"qValue": "1",
						"foldChange": "1.0874"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".579783583717426",
						"qValue": "1",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".282784602824187",
						"qValue": ".911265664999995",
						"foldChange": "1.1401"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".475419215950006",
						"qValue": ".902562957068497",
						"foldChange": "1.0882"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".715283748243955",
						"qValue": ".976023509428735",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".627491422530542",
						"qValue": "1",
						"foldChange": "0.9990"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".236145602679457",
						"qValue": "1",
						"foldChange": "0.9333"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".480342288370053",
						"qValue": "1",
						"foldChange": "0.9241"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".5675793135644",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".943734638776634",
						"qValue": "1",
						"foldChange": "1.0149"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".629858305768555",
						"qValue": "1",
						"foldChange": "0.9319"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".337767134031208",
						"qValue": "1",
						"foldChange": "1.1939"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".782936286242607",
						"qValue": "1",
						"foldChange": "0.9941"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".228597255488402",
						"qValue": "1",
						"foldChange": "0.8327"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".602551518312416",
						"qValue": "1",
						"foldChange": "1.0539"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".37991224787308",
						"qValue": "1",
						"foldChange": "0.8432"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".164696082938561",
						"qValue": ".985830071188835",
						"foldChange": "0.8001"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".479017987494682",
						"qValue": ".242505119830715",
						"foldChange": "0.9501"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".31414528451237",
						"qValue": ".145512117293154",
						"foldChange": "1.1554"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".202137668843307",
						"qValue": ".055936092676876",
						"foldChange": "1.3331"
					}
				]
			},
			{
				"metaboliteId": "0078dbe6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e916-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyglutarate",
				"plotName": null,
				"compoundId": "00009185-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB0059655",
				"inChiKey": "HWXBTNAVRSUOJR-UHFFFAOYAI",
				"kegg": "C02630",
				"mass": 148.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".86576623655039",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".471246932695314",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".931139059683268",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".1813071788575",
						"qValue": "1",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".631690011862423",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".386470483105204",
						"qValue": "1",
						"foldChange": "2.3061"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".766499982670025",
						"qValue": "1",
						"foldChange": "1.1156"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".636762875580007",
						"qValue": ".928868848501546",
						"foldChange": "1.1591"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".860560136853906",
						"qValue": "1",
						"foldChange": "1.4157"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".633862398524291",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".833578809921768",
						"qValue": "1",
						"foldChange": "1.0580"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".789774373278382",
						"qValue": "1",
						"foldChange": "1.1545"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".951430728501934",
						"qValue": "1",
						"foldChange": "0.9415"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".976117602767104",
						"qValue": "1",
						"foldChange": "0.8721"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".928553111673884",
						"qValue": "1",
						"foldChange": "0.9264"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".360055328070277",
						"qValue": "1",
						"foldChange": "1.0571"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".447338642141745",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".912984919201358",
						"qValue": "1",
						"foldChange": "0.9852"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".97438712939573",
						"qValue": "1",
						"foldChange": "1.0859"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".800448183774872",
						"qValue": "1",
						"foldChange": "1.0152"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".771813741610277",
						"qValue": ".997573179629986",
						"foldChange": "0.9349"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000360857375235",
						"qValue": ".001231716303866",
						"foldChange": "2.1928"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".118461920159485",
						"qValue": ".069836543035658",
						"foldChange": "1.8112"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005558770040694",
						"qValue": ".003518568393878",
						"foldChange": "2.2025"
					}
				]
			},
			{
				"metaboliteId": "0078dbe7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e40d-0000-0000-0000-000000000000",
				"chemicalName": "hexanoylcarnitine (C6)",
				"plotName": null,
				"compoundId": "00007e48-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00705",
				"inChiKey": "VVPRQWTYSNDTEA-UHFFFAOYAN",
				"kegg": null,
				"mass": 259.178358,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1638,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".677147750254486",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".434342155608475",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".440379355101216",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".955804933687944",
						"qValue": "1",
						"foldChange": "1.0459"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".528204583893578",
						"qValue": "1",
						"foldChange": "1.0854"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".492844700692935",
						"qValue": "1",
						"foldChange": "1.1387"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".823372486746501",
						"qValue": "1",
						"foldChange": "1.0774"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".493464489887693",
						"qValue": ".902562957068497",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".365016079324859",
						"qValue": ".957839854315834",
						"foldChange": "0.9781"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".153663354626107",
						"qValue": "1",
						"foldChange": "1.2528"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".683057192567502",
						"qValue": "1",
						"foldChange": "0.9736"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".068958456098111",
						"qValue": ".727024127920748",
						"foldChange": "0.8159"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".66650067126094",
						"qValue": "1",
						"foldChange": "1.0664"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".473324832038622",
						"qValue": "1",
						"foldChange": "1.2673"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".749570939896896",
						"qValue": "1",
						"foldChange": "1.1883"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".57517406997719",
						"qValue": "1",
						"foldChange": "1.1338"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".153409539997543",
						"qValue": "1",
						"foldChange": "1.5295"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".349691231678367",
						"qValue": "1",
						"foldChange": "1.3491"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".846324935692731",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".81900593632688",
						"qValue": "1",
						"foldChange": "1.2246"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".673238385190973",
						"qValue": ".990272584968167",
						"foldChange": "1.2330"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".605918702644793",
						"qValue": ".290744900131941",
						"foldChange": "1.4547"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".106057501201053",
						"qValue": ".063874204695417",
						"foldChange": "1.4099"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".399707429113736",
						"qValue": ".093270435389551",
						"foldChange": "1.1735"
					}
				]
			},
			{
				"metaboliteId": "0078dbe8-0000-0000-0000-000000000000",
				"chemicalId": "0000017d-0000-0000-0000-000000000000",
				"chemicalName": "2-aminoadipate",
				"plotName": null,
				"compoundId": "00001802-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00510",
				"inChiKey": "OYIFNHCXNCRBQI-UHFFFAOYAC",
				"kegg": "C00956",
				"mass": 161.068809,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 115,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".619566791307455",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".792921329775561",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".550449214411261",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".382641424206319",
						"qValue": "1",
						"foldChange": "0.9386"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".888652838855987",
						"qValue": "1",
						"foldChange": "1.0583"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".311961065238446",
						"qValue": "1",
						"foldChange": "1.2552"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".239302531274635",
						"qValue": ".852077213869098",
						"foldChange": "1.2373"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".85098656059911",
						"qValue": "1",
						"foldChange": "1.0831"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".321053954486713",
						"qValue": ".957839854315834",
						"foldChange": "0.9716"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".612628487657301",
						"qValue": "1",
						"foldChange": "1.3165"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".430573873857823",
						"qValue": "1",
						"foldChange": "1.4219"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".776669432024802",
						"qValue": "1",
						"foldChange": "1.0115"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".2824915378807",
						"qValue": "1",
						"foldChange": "0.7332"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".233432974326636",
						"qValue": "1",
						"foldChange": "0.8261"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".854765855475562",
						"qValue": "1",
						"foldChange": "1.1267"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".901519178635207",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".692215751778086",
						"qValue": "1",
						"foldChange": "0.8315"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".599686058822779",
						"qValue": "1",
						"foldChange": "0.8128"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".292712177095009",
						"qValue": "1",
						"foldChange": "0.7106"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".423188544659196",
						"qValue": "1",
						"foldChange": "0.7349"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".839403387932353",
						"qValue": ".997573179629986",
						"foldChange": "1.0343"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".037359501985948",
						"qValue": ".040555342870879",
						"foldChange": "2.1594"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000987593440175",
						"qValue": ".00216840091464",
						"foldChange": "2.1625"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007643600135938",
						"qValue": ".004452561052204",
						"foldChange": "2.2710"
					}
				]
			},
			{
				"metaboliteId": "0078dbe9-0000-0000-0000-000000000000",
				"chemicalId": "05f5eae0-0000-0000-0000-000000000000",
				"chemicalName": "sulfate*",
				"plotName": null,
				"compoundId": "0000b770-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01448",
				"inChiKey": "QAOWNCQODCNURD-UHFFFAOYAC",
				"kegg": "C00059",
				"mass": 97.967379,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5227,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".196917800941403",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".431756612156515",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".523467606036973",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".965931946861844",
						"qValue": "1",
						"foldChange": "1.0108"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".898316948164588",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".864629486480075",
						"qValue": "1",
						"foldChange": "0.9955"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".159416370997553",
						"qValue": ".742487134132407",
						"foldChange": "1.1503"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".400133662514934",
						"qValue": ".882161593933088",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".027209110947919",
						"qValue": ".587380998803463",
						"foldChange": "0.8911"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".92055340153352",
						"qValue": "1",
						"foldChange": "1.0230"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".843780775923004",
						"qValue": "1",
						"foldChange": "1.0209"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".922445866772212",
						"qValue": "1",
						"foldChange": "0.9571"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".87841732434305",
						"qValue": "1",
						"foldChange": "0.9871"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".187003765472926",
						"qValue": "1",
						"foldChange": "0.8402"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".135368047282545",
						"qValue": "1",
						"foldChange": "0.8511"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".358830340981515",
						"qValue": "1",
						"foldChange": "1.1154"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".198613932889992",
						"qValue": "1",
						"foldChange": "0.8457"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".031021021582557",
						"qValue": ".822195137696456",
						"foldChange": "0.7582"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".807415741946916",
						"qValue": "1",
						"foldChange": "0.9485"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".255392445215171",
						"qValue": "1",
						"foldChange": "0.8074"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".350539927456985",
						"qValue": ".985830071188835",
						"foldChange": "0.8512"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003363131977287",
						"qValue": ".006530423897099",
						"foldChange": "0.6336"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000605007598125",
						"qValue": ".00170408298631",
						"foldChange": "0.5016"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037267614371216",
						"qValue": ".01516382095168",
						"foldChange": "0.6612"
					}
				]
			},
			{
				"metaboliteId": "0078dbea-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c7-0000-0000-0000-000000000000",
				"chemicalName": "4-acetylphenol sulfate",
				"plotName": null,
				"compoundId": "0000ae4c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HOFGLAYWQRGTKC-UHFFFAOYAS",
				"kegg": null,
				"mass": 216.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4104,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".889216738908055",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".158621342976887",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".620505764844616",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".588204703572223",
						"qValue": "1",
						"foldChange": "1.7555"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".559309520848599",
						"qValue": "1",
						"foldChange": "1.5782"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".965988657828473",
						"qValue": "1",
						"foldChange": "1.3162"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".744813004983873",
						"qValue": "1",
						"foldChange": "1.2767"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".154176080318733",
						"qValue": ".784201030789377",
						"foldChange": "2.5492"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".082212528468792",
						"qValue": ".796492767737576",
						"foldChange": "2.4358"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".172074216841991",
						"qValue": "1",
						"foldChange": "2.5422"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".180578344334735",
						"qValue": "1",
						"foldChange": "2.4695"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".978165101765311",
						"qValue": "1",
						"foldChange": "1.0622"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".832278335921366",
						"qValue": "1",
						"foldChange": "0.6941"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".972469874668647",
						"qValue": "1",
						"foldChange": "0.6393"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".8651579584258",
						"qValue": "1",
						"foldChange": "0.9211"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".719930625359343",
						"qValue": "1",
						"foldChange": "0.7974"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".537487582342948",
						"qValue": "1",
						"foldChange": "1.5587"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".329453615663721",
						"qValue": "1",
						"foldChange": "1.9547"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".463307053606747",
						"qValue": "1",
						"foldChange": "2.2649"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".56768574131149",
						"qValue": "1",
						"foldChange": "1.1409"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".899642570170182",
						"qValue": ".997573179629986",
						"foldChange": "0.5037"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".040618016983972",
						"qValue": ".043458181914969",
						"foldChange": "2.6150"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".400220928318278",
						"qValue": ".170381956581637",
						"foldChange": "2.2499"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".095309057125859",
						"qValue": ".03165992029763",
						"foldChange": "1.9611"
					}
				]
			},
			{
				"metaboliteId": "0078dbeb-0000-0000-0000-000000000000",
				"chemicalId": "0000046f-0000-0000-0000-000000000000",
				"chemicalName": "ursodeoxycholate",
				"plotName": null,
				"compoundId": "00000645-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00946",
				"inChiKey": "RUDATBOHQWOJDD-UZVSRGJWBC",
				"kegg": "C07880",
				"mass": 392.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3133,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".708944674445098",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".008053694787285",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".5578468678829",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".187851678518428",
						"qValue": "1",
						"foldChange": "1.3927"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".821657508408685",
						"qValue": "1",
						"foldChange": "1.7933"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".272810249126664",
						"qValue": "1",
						"foldChange": "2.5426"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".243103967085337",
						"qValue": ".861146427249076",
						"foldChange": "0.9270"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".795036372916583",
						"qValue": ".985267275880213",
						"foldChange": "1.2571"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".155505820002107",
						"qValue": ".957839854315834",
						"foldChange": "2.0976"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".005111012151276",
						"qValue": ".543683917591992",
						"foldChange": "0.6071"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".0919211074963",
						"qValue": "1",
						"foldChange": "0.7059"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".230378362709276",
						"qValue": "1",
						"foldChange": "1.9969"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".669514065704241",
						"qValue": "1",
						"foldChange": "1.4092"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".770000763564139",
						"qValue": "1",
						"foldChange": "1.5991"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".908995977617802",
						"qValue": "1",
						"foldChange": "1.1347"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".597708720122096",
						"qValue": "1",
						"foldChange": "0.4763"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".617372297728352",
						"qValue": "1",
						"foldChange": "0.1495"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".309280622458251",
						"qValue": "1",
						"foldChange": "0.3139"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".51615547272188",
						"qValue": "1",
						"foldChange": "2.2799"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".674492839858757",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".292515320086916",
						"qValue": ".985830071188835",
						"foldChange": "0.4606"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".397197936801106",
						"qValue": ".213141814368499",
						"foldChange": "0.7063"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".975399771049586",
						"qValue": ".330485206882319",
						"foldChange": "1.2108"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".781906399484164",
						"qValue": ".157336322139938",
						"foldChange": "1.1352"
					}
				]
			},
			{
				"metaboliteId": "0078dbec-0000-0000-0000-000000000000",
				"chemicalId": "00000567-0000-0000-0000-000000000000",
				"chemicalName": "4-acetamidophenol",
				"plotName": null,
				"compoundId": "00002f00-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01859",
				"inChiKey": "RZVAJINKPMORJF-UHFFFAOYAA",
				"kegg": "C06804",
				"mass": 151.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4772,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".172881365562469",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".741423409885298",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".689533775415472",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".324415079588177",
						"qValue": "1",
						"foldChange": "5.1481"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".821577220984885",
						"qValue": "1",
						"foldChange": "3.9392"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".227524668980163",
						"qValue": "1",
						"foldChange": "3.8315"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".698923713312831",
						"qValue": "1",
						"foldChange": "6.8883"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".712032698695541",
						"qValue": ".950986052805043",
						"foldChange": "1.0446"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".450722966533549",
						"qValue": ".957839854315834",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".409791812923353",
						"qValue": "1",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".799424984149722",
						"qValue": "1",
						"foldChange": "0.9186"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".567289163626057",
						"qValue": "1",
						"foldChange": "10.8152"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".130260756828211",
						"qValue": "1",
						"foldChange": "0.0318"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".38842802778068",
						"qValue": "1",
						"foldChange": "1.1024"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".548662083629938",
						"qValue": "1",
						"foldChange": "34.7136"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".065674610099928",
						"qValue": "1",
						"foldChange": "0.0385"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".070376889269229",
						"qValue": "1",
						"foldChange": "0.0170"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".943503543574787",
						"qValue": "1",
						"foldChange": "0.4411"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".114269846001908",
						"qValue": "1",
						"foldChange": "0.0224"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".375989776860913",
						"qValue": "1",
						"foldChange": "0.6539"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".520177709669969",
						"qValue": ".985830071188835",
						"foldChange": "29.1622"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".171465953354031",
						"qValue": ".120078283522674",
						"foldChange": "33.1563"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".647214933510292",
						"qValue": ".249963408462593",
						"foldChange": "0.8416"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".069347250520709",
						"qValue": ".02434359838509",
						"foldChange": "50.7071"
					}
				]
			},
			{
				"metaboliteId": "0078dbed-0000-0000-0000-000000000000",
				"chemicalId": "00000075-0000-0000-0000-000000000000",
				"chemicalName": "homovanillate (HVA)",
				"plotName": null,
				"compoundId": "0000044d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00118",
				"inChiKey": "QRMZSPFSDQBLIX-REWHXWOFAT",
				"kegg": "C05582",
				"mass": 182.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 192,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".891573465654265",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".772970941074458",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".385522943016323",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".249194875293658",
						"qValue": "1",
						"foldChange": "1.5624"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".846832427352717",
						"qValue": "1",
						"foldChange": "1.3070"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".335803785775591",
						"qValue": "1",
						"foldChange": "0.8266"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".08871480618328",
						"qValue": ".629135833849759",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".217024939064651",
						"qValue": ".825038702902081",
						"foldChange": "1.0780"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".628784820296379",
						"qValue": ".972901603767669",
						"foldChange": "1.2914"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".993687491358809",
						"qValue": "1",
						"foldChange": "1.2232"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".81647402294057",
						"qValue": "1",
						"foldChange": "0.9673"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".810340267858544",
						"qValue": "1",
						"foldChange": "1.3270"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".12327466872756",
						"qValue": "1",
						"foldChange": "4.1444"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".620438013932012",
						"qValue": "1",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".319657302898547",
						"qValue": "1",
						"foldChange": "0.2312"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".34630816447737",
						"qValue": "1",
						"foldChange": "0.7162"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".633598439581516",
						"qValue": "1",
						"foldChange": "0.9200"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".669827316769274",
						"qValue": "1",
						"foldChange": "1.2845"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".74243281377495",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".900820509574617",
						"qValue": "1",
						"foldChange": "0.8574"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".848710917073203",
						"qValue": ".997573179629986",
						"foldChange": "0.8333"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".075017334717956",
						"qValue": ".066588562075421",
						"foldChange": "0.7345"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".529345213652997",
						"qValue": ".212077627874824",
						"foldChange": "1.1892"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".53809789911",
						"qValue": ".116352240974139",
						"foldChange": "0.8682"
					}
				]
			},
			{
				"metaboliteId": "0078dbee-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fe-0000-0000-0000-000000000000",
				"chemicalName": "10-heptadecenoate (17:1n7)",
				"plotName": null,
				"compoundId": "000084b3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60038",
				"inChiKey": "GDTXICBNEOEPAZ-FPLPWBNLBM",
				"kegg": null,
				"mass": 268.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1359,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".975349319159943",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".158096240177422",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".537573799218652",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".529835383157817",
						"qValue": "1",
						"foldChange": "1.2075"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".719174265601932",
						"qValue": "1",
						"foldChange": "1.1261"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".787427707601443",
						"qValue": "1",
						"foldChange": "1.0476"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".279586340408377",
						"qValue": ".911265664999995",
						"foldChange": "1.5374"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".146391455611703",
						"qValue": ".782571329595308",
						"foldChange": "1.0760"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".013332294512186",
						"qValue": ".436376254995018",
						"foldChange": "0.7631"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".791128227396829",
						"qValue": "1",
						"foldChange": "1.1721"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".706877196080594",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".522239296231109",
						"qValue": "1",
						"foldChange": "1.0186"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".635529753146766",
						"qValue": "1",
						"foldChange": "1.1420"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".701830704244278",
						"qValue": "1",
						"foldChange": "0.9930"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".946445247124802",
						"qValue": "1",
						"foldChange": "0.8695"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".458354298045525",
						"qValue": "1",
						"foldChange": "1.3721"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".870825042626545",
						"qValue": "1",
						"foldChange": "0.9134"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".580247751663295",
						"qValue": "1",
						"foldChange": "0.6657"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".486086372119995",
						"qValue": "1",
						"foldChange": "0.9529"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".922686174431259",
						"qValue": "1",
						"foldChange": "0.8417"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".564562862790688",
						"qValue": ".985830071188835",
						"foldChange": "0.8833"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".32770041039047",
						"qValue": ".190014925400157",
						"foldChange": "1.2701"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".468856421033929",
						"qValue": ".193276678684815",
						"foldChange": "1.7166"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".165615257596551",
						"qValue": ".048063078602301",
						"foldChange": "1.6203"
					}
				]
			},
			{
				"metaboliteId": "0078dbef-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5cd-0000-0000-0000-000000000000",
				"chemicalName": "stearidonate (18:4n3)",
				"plotName": null,
				"compoundId": "000084b1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06547",
				"inChiKey": "JIWBIWFOSCKQMA-LTKCOYKYBT",
				"kegg": "C16300",
				"mass": 276.20893,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1384,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".570490936985856",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".636205192507601",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".94479108619322",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".840914949992068",
						"qValue": "1",
						"foldChange": "1.2198"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".875491232918591",
						"qValue": "1",
						"foldChange": "1.1562"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".964858379414212",
						"qValue": "1",
						"foldChange": "1.8023"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".958387933742837",
						"qValue": "1",
						"foldChange": "1.1775"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".433460027971595",
						"qValue": ".89532641699958",
						"foldChange": "1.1430"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".464426885484937",
						"qValue": ".957839854315834",
						"foldChange": "1.1223"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".695405316966035",
						"qValue": "1",
						"foldChange": "1.0458"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".369070608955047",
						"qValue": "1",
						"foldChange": "0.9239"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".610405441695447",
						"qValue": "1",
						"foldChange": "0.8971"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".556996594683185",
						"qValue": "1",
						"foldChange": "0.9392"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".829476348623581",
						"qValue": "1",
						"foldChange": "1.1390"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".430772269785544",
						"qValue": "1",
						"foldChange": "1.2127"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".451100201410041",
						"qValue": "1",
						"foldChange": "0.7880"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".861213759181137",
						"qValue": "1",
						"foldChange": "0.7568"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".580768527302136",
						"qValue": "1",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".236828454496579",
						"qValue": "1",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".624890551063203",
						"qValue": "1",
						"foldChange": "0.6260"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".514354614916359",
						"qValue": ".985830071188835",
						"foldChange": "0.6419"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".442608074252005",
						"qValue": ".230052384131036",
						"foldChange": "1.2254"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".985998794238638",
						"qValue": ".332055863302994",
						"foldChange": "1.7631"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".086605522040754",
						"qValue": ".029069065879246",
						"foldChange": "2.0034"
					}
				]
			},
			{
				"metaboliteId": "0078dbf0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e675-0000-0000-0000-000000000000",
				"chemicalName": "1,3,7-trimethylurate",
				"plotName": null,
				"compoundId": "00008664-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02123",
				"inChiKey": "BYXCFUMGEBZDDI-UHFFFAOYAE",
				"kegg": "C16361",
				"mass": 210.07529,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4164,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".074857977138347",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".252501602845534",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".872577961143113",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".947091114774034",
						"qValue": "1",
						"foldChange": "2.0162"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".288065856135181",
						"qValue": "1",
						"foldChange": "1.4711"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".259533597736616",
						"qValue": "1",
						"foldChange": "0.8365"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".232817067254547",
						"qValue": ".852077213869098",
						"foldChange": "1.3430"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".350138101844061",
						"qValue": ".853775142318898",
						"foldChange": "1.8508"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".792247093036856",
						"qValue": ".99456541772943",
						"foldChange": "1.4508"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".522476974961899",
						"qValue": "1",
						"foldChange": "1.4166"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".38186972747737",
						"qValue": "1",
						"foldChange": "0.9122"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".812654708501222",
						"qValue": "1",
						"foldChange": "1.2447"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".35264718304841",
						"qValue": "1",
						"foldChange": "1.5122"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".034301412933444",
						"qValue": "1",
						"foldChange": "1.5469"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".189413022876885",
						"qValue": "1",
						"foldChange": "1.0229"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".840359156930055",
						"qValue": "1",
						"foldChange": "0.9284"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".085913362134242",
						"qValue": "1",
						"foldChange": "2.1984"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".115793819960035",
						"qValue": ".908885675694672",
						"foldChange": "2.3680"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".300316508690532",
						"qValue": "1",
						"foldChange": "2.0334"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".028617112111748",
						"qValue": "1",
						"foldChange": "2.7241"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".197367051386289",
						"qValue": ".985830071188835",
						"foldChange": "1.3397"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000020749299858",
						"qValue": ".000236893032539",
						"foldChange": "31.4377"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005542722835156",
						"qValue": ".006889764276629",
						"foldChange": "12.5015"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".063631993212732",
						"qValue": ".022887007391116",
						"foldChange": "5.1249"
					}
				]
			},
			{
				"metaboliteId": "0078dbf1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4ee-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglycine",
				"plotName": null,
				"compoundId": "00006c3e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00532",
				"inChiKey": "OKJIRPAQVSHGFK-UHFFFAOYAB",
				"kegg": null,
				"mass": 117.042593,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 2,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".978893186233718",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".339524359933607",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".148219868833636",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".977528598055076",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".560795110398014",
						"qValue": "1",
						"foldChange": "1.1060"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".542079506649966",
						"qValue": "1",
						"foldChange": "1.1112"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".474506981121167",
						"qValue": "1",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".230266070426079",
						"qValue": ".825038702902081",
						"foldChange": "0.9458"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".058775037120578",
						"qValue": ".750620749550044",
						"foldChange": "0.8546"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".077279032210396",
						"qValue": ".886468253895902",
						"foldChange": "1.2905"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".054256159640752",
						"qValue": "1",
						"foldChange": "1.3018"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".86754891822176",
						"qValue": "1",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".986039666645647",
						"qValue": "1",
						"foldChange": "1.1554"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".54679259397315",
						"qValue": "1",
						"foldChange": "0.9263"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".548251368232244",
						"qValue": "1",
						"foldChange": "0.8017"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".738818402415178",
						"qValue": "1",
						"foldChange": "1.2158"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".762873185940135",
						"qValue": "1",
						"foldChange": "1.1143"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".988867107801555",
						"qValue": "1",
						"foldChange": "0.9166"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".384418610357566",
						"qValue": "1",
						"foldChange": "0.8605"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".92153404209646",
						"qValue": "1",
						"foldChange": "0.9882"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".346245680862216",
						"qValue": ".985830071188835",
						"foldChange": "1.1484"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000548709996216",
						"qValue": ".001697875856955",
						"foldChange": "2.0705"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007728970129596",
						"qValue": ".008451748293679",
						"foldChange": "2.0098"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000142163508753",
						"qValue": ".00027537914494",
						"foldChange": "2.8166"
					}
				]
			},
			{
				"metaboliteId": "0078dbf2-0000-0000-0000-000000000000",
				"chemicalId": "0000012e-0000-0000-0000-000000000000",
				"chemicalName": "deoxycholate",
				"plotName": null,
				"compoundId": "0000045a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00626",
				"inChiKey": "KXGVEGMKQFWNSR-LLQZFEROBK",
				"kegg": "C04483",
				"mass": 392.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3112,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".791483226453783",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".001459698386224",
						"qValue": ".207033887779446",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".315602926729859",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".478611408553463",
						"qValue": "1",
						"foldChange": "1.0657"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".290356257034368",
						"qValue": "1",
						"foldChange": "1.3465"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".080868569898726",
						"qValue": "1",
						"foldChange": "1.4779"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".024985350918423",
						"qValue": ".359575885726771",
						"foldChange": "0.7700"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".377062740712437",
						"qValue": ".875723678608451",
						"foldChange": "1.0143"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".161330413984752",
						"qValue": ".957839854315834",
						"foldChange": "1.4945"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".001987712680148",
						"qValue": ".543683917591992",
						"foldChange": "0.6088"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".154699427180689",
						"qValue": "1",
						"foldChange": "0.8169"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".074207006063031",
						"qValue": ".765366481662566",
						"foldChange": "1.4624"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".972998911833779",
						"qValue": "1",
						"foldChange": "1.0820"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".437820700420547",
						"qValue": "1",
						"foldChange": "1.3526"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".408304747473469",
						"qValue": "1",
						"foldChange": "1.2500"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".489571942443025",
						"qValue": "1",
						"foldChange": "0.9903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".703622061967959",
						"qValue": "1",
						"foldChange": "0.8884"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".778272857140843",
						"qValue": "1",
						"foldChange": "0.8971"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".378166243117023",
						"qValue": "1",
						"foldChange": "0.9609"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".751733879497829",
						"qValue": "1",
						"foldChange": "0.7468"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".593695826588229",
						"qValue": ".985830071188835",
						"foldChange": "0.7772"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".094933005460504",
						"qValue": ".078578562104286",
						"foldChange": "1.7873"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".737107831197647",
						"qValue": ".274053194027384",
						"foldChange": "1.4394"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007889265790091",
						"qValue": ".004563117531926",
						"foldChange": "2.9968"
					}
				]
			},
			{
				"metaboliteId": "0078dbf3-0000-0000-0000-000000000000",
				"chemicalId": "0000037c-0000-0000-0000-000000000000",
				"chemicalName": "nonadecanoate (19:0)",
				"plotName": null,
				"compoundId": "0000054c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00772",
				"inChiKey": "ISYWECDDZWTKFF-UHFFFAOYAR",
				"kegg": "C16535",
				"mass": 298.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1346,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".728510946106345",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".148906433790012",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".003691250502522",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".71748788891253",
						"qValue": "1",
						"foldChange": "1.0493"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".34844124100143",
						"qValue": "1",
						"foldChange": "1.0995"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".562672558220564",
						"qValue": "1",
						"foldChange": "1.0654"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".004416073646595",
						"qValue": ".189570241250002",
						"foldChange": "1.2921"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".110143475965533",
						"qValue": ".697458782960496",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000029446664273",
						"qValue": ".012405015081279",
						"foldChange": "0.7151"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".782520545277273",
						"qValue": "1",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".696697434836686",
						"qValue": "1",
						"foldChange": "1.0777"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".506246047988275",
						"qValue": "1",
						"foldChange": "1.0615"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".981834973654826",
						"qValue": "1",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".402662416577886",
						"qValue": "1",
						"foldChange": "1.1207"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".403522183111023",
						"qValue": "1",
						"foldChange": "1.0683"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".12857520526747",
						"qValue": "1",
						"foldChange": "1.2887"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".647591447818721",
						"qValue": "1",
						"foldChange": "1.0507"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".308527450684821",
						"qValue": "1",
						"foldChange": "0.8153"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".136716636713456",
						"qValue": "1",
						"foldChange": "0.8477"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".595509587930331",
						"qValue": "1",
						"foldChange": "1.0292"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".050215249595579",
						"qValue": ".985830071188835",
						"foldChange": "1.2140"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".091275124393027",
						"qValue": ".077092690915509",
						"foldChange": "1.3853"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".190241702612207",
						"qValue": ".09971470252619",
						"foldChange": "1.2855"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".041470290509505",
						"qValue": ".016222443173424",
						"foldChange": "1.3327"
					}
				]
			},
			{
				"metaboliteId": "0078dbf4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2c5-0000-0000-0000-000000000000",
				"chemicalName": "paraxanthine",
				"plotName": null,
				"compoundId": "0000474e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01860",
				"inChiKey": "QUNWUDVFRNGTCO-UHFFFAOYAS",
				"kegg": "C13747",
				"mass": 180.064726,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4156,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".212711775101589",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".183076998565096",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".936000587033384",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".732184404644091",
						"qValue": "1",
						"foldChange": "1.3470"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".253839551010386",
						"qValue": "1",
						"foldChange": "1.1398"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".421538198224475",
						"qValue": "1",
						"foldChange": "1.0345"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".191175378431599",
						"qValue": ".797133099236872",
						"foldChange": "0.7911"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".344889792307623",
						"qValue": ".847814688346659",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".711626376921344",
						"qValue": ".976023509428735",
						"foldChange": "1.4918"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".501858373565155",
						"qValue": "1",
						"foldChange": "1.1719"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".310711392996283",
						"qValue": "1",
						"foldChange": "0.8299"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".729457174526891",
						"qValue": "1",
						"foldChange": "1.0590"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".800535478929577",
						"qValue": "1",
						"foldChange": "1.2764"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".138557086572429",
						"qValue": "1",
						"foldChange": "1.7404"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".200215354598153",
						"qValue": "1",
						"foldChange": "1.3635"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".785192514237368",
						"qValue": "1",
						"foldChange": "0.7492"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".198514414464733",
						"qValue": "1",
						"foldChange": "1.4006"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".116499903963878",
						"qValue": ".908885675694672",
						"foldChange": "1.8694"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".692034880370693",
						"qValue": "1",
						"foldChange": "1.6468"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".129358225321065",
						"qValue": "1",
						"foldChange": "1.6489"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".236898671241058",
						"qValue": ".985830071188835",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000096324690073",
						"qValue": ".000476002963016",
						"foldChange": "6.8887"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003563410278749",
						"qValue": ".005148415931684",
						"foldChange": "7.9224"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".048128462545523",
						"qValue": ".018423566391658",
						"foldChange": "3.3472"
					}
				]
			},
			{
				"metaboliteId": "0078dbf5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2b5-0000-0000-0000-000000000000",
				"chemicalName": "theophylline",
				"plotName": null,
				"compoundId": "000047da-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01889",
				"inChiKey": "ZFXYFBGIUFBOJW-UHFFFAOYAT",
				"kegg": "C07130",
				"mass": 180.064726,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4158,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".191374738554257",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".089515364172831",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".979397946459247",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".22349143349378",
						"qValue": "1",
						"foldChange": "1.0863"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".111190726857286",
						"qValue": "1",
						"foldChange": "0.8467"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".698612992462291",
						"qValue": "1",
						"foldChange": "1.3443"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".23840720734077",
						"qValue": ".852077213869098",
						"foldChange": "0.9051"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".173492295101799",
						"qValue": ".789529107655781",
						"foldChange": "0.9340"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".851857893190005",
						"qValue": "1",
						"foldChange": "1.0831"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".655036137439597",
						"qValue": "1",
						"foldChange": "1.1136"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".440810607770804",
						"qValue": "1",
						"foldChange": "0.9153"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".744568192841356",
						"qValue": "1",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".738356290435487",
						"qValue": "1",
						"foldChange": "1.3967"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".164166637104119",
						"qValue": "1",
						"foldChange": "1.6996"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".266084943836324",
						"qValue": "1",
						"foldChange": "1.2168"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".701794949804888",
						"qValue": "1",
						"foldChange": "1.0913"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".085348557599756",
						"qValue": "1",
						"foldChange": "1.6353"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".158483925297346",
						"qValue": "1",
						"foldChange": "1.4984"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".62740711403172",
						"qValue": "1",
						"foldChange": "1.6079"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".082124614434719",
						"qValue": "1",
						"foldChange": "1.9144"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".182313036986279",
						"qValue": ".985830071188835",
						"foldChange": "1.1906"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000004470024238",
						"qValue": ".000082775668234",
						"foldChange": "12.7938"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002100662748655",
						"qValue": ".003414803198587",
						"foldChange": "10.0447"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".009217470250612",
						"qValue": ".005092602293408",
						"foldChange": "4.6795"
					}
				]
			},
			{
				"metaboliteId": "0078dbf6-0000-0000-0000-000000000000",
				"chemicalId": "000003a4-0000-0000-0000-000000000000",
				"chemicalName": "caprylate (8:0)",
				"plotName": null,
				"compoundId": "00007eec-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00482",
				"inChiKey": "WWZKQHOCKIZLMA-UHFFFAOYAH",
				"kegg": "C06423",
				"mass": 144.11503,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1330,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844637359153382",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".963664968798533",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".241701704448177",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".867821337224733",
						"qValue": "1",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".428521280213699",
						"qValue": "1",
						"foldChange": "1.1546"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".530925658838379",
						"qValue": "1",
						"foldChange": "1.2073"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".123216396596131",
						"qValue": ".704689005419363",
						"foldChange": "0.8695"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".068606326187712",
						"qValue": ".687896186587066",
						"foldChange": "0.8589"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".770128689342839",
						"qValue": ".98622264532067",
						"foldChange": "1.0475"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".292380560640918",
						"qValue": "1",
						"foldChange": "1.2042"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".640785473103551",
						"qValue": "1",
						"foldChange": "1.0925"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".554617205347731",
						"qValue": "1",
						"foldChange": "0.9287"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".345203321831017",
						"qValue": "1",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".747321341917679",
						"qValue": "1",
						"foldChange": "1.0489"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".556634119299343",
						"qValue": "1",
						"foldChange": "0.9624"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".914448313890166",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".369038797211904",
						"qValue": "1",
						"foldChange": "1.2366"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".306662028762768",
						"qValue": "1",
						"foldChange": "1.2244"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".492664409802032",
						"qValue": "1",
						"foldChange": "0.9234"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".887792894769648",
						"qValue": "1",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".419201553244283",
						"qValue": ".985830071188835",
						"foldChange": "1.1118"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006573828735191",
						"qValue": ".011219241433947",
						"foldChange": "0.5930"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004327930904609",
						"qValue": ".005858388983354",
						"foldChange": "0.5680"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002708822884111",
						"qValue": ".002124456066305",
						"foldChange": "0.6464"
					}
				]
			},
			{
				"metaboliteId": "0078dbf7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ad-0000-0000-0000-000000000000",
				"chemicalName": "10-undecenoate (11:1n1)",
				"plotName": null,
				"compoundId": "00007ef1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33724",
				"inChiKey": "FRPZMMHWLSIFAZ-UHFFFAOYAJ",
				"kegg": "C13910",
				"mass": 184.14633,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".423629883167158",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".190521395322847",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".797156241643528",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".602826538049105",
						"qValue": "1",
						"foldChange": "1.1413"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".489472355183966",
						"qValue": "1",
						"foldChange": "1.1556"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".863611566747862",
						"qValue": "1",
						"foldChange": "1.1187"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".47868444915419",
						"qValue": "1",
						"foldChange": "1.3302"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".909326353713873",
						"qValue": "1",
						"foldChange": "1.1159"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".55165235081503",
						"qValue": ".957839854315834",
						"foldChange": "1.0675"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".062677780972453",
						"qValue": ".821720595261736",
						"foldChange": "1.4445"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".463527762196002",
						"qValue": "1",
						"foldChange": "1.2025"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".24905074925844",
						"qValue": "1",
						"foldChange": "0.8416"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".327813050639457",
						"qValue": "1",
						"foldChange": "0.8404"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".475493398550586",
						"qValue": "1",
						"foldChange": "0.8101"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".826044274686308",
						"qValue": "1",
						"foldChange": "0.9640"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".382568930404698",
						"qValue": "1",
						"foldChange": "0.9560"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".787354755845086",
						"qValue": "1",
						"foldChange": "1.0541"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".26354428063561",
						"qValue": "1",
						"foldChange": "1.1026"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".168120480733386",
						"qValue": "1",
						"foldChange": "0.7994"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".51145176646034",
						"qValue": "1",
						"foldChange": "0.8110"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".500779029645541",
						"qValue": ".985830071188835",
						"foldChange": "1.0145"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".674444874141345",
						"qValue": ".314068005176884",
						"foldChange": "1.0243"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".394031654478796",
						"qValue": ".169037422426358",
						"foldChange": "1.3078"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".060067195829209",
						"qValue": ".021849227877547",
						"foldChange": "1.4579"
					}
				]
			},
			{
				"metaboliteId": "0078dbf8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e89e-0000-0000-0000-000000000000",
				"chemicalName": "bilirubin (E,E)*",
				"plotName": null,
				"compoundId": "00007f4a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BPYKTIZUTYGOLE-BMNRKXREBL",
				"kegg": null,
				"mass": 584.263485,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4014,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".146864292677902",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".014440781470608",
						"qValue": ".614455251574347",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".213112169916117",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".002022815390977",
						"qValue": ".860707948860683",
						"foldChange": "0.7190"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".01749375050445",
						"qValue": "1",
						"foldChange": "0.7303"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".425488874814081",
						"qValue": "1",
						"foldChange": "1.2850"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".867823632889883",
						"qValue": "1",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".126685691034874",
						"qValue": ".728442723450525",
						"foldChange": "0.8587"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".171858674899636",
						"qValue": ".957839854315834",
						"foldChange": "0.8858"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".546163996256317",
						"qValue": "1",
						"foldChange": "0.9889"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".334561967138556",
						"qValue": "1",
						"foldChange": "0.9300"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".715278990266213",
						"qValue": "1",
						"foldChange": "0.9800"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".618572993681952",
						"qValue": "1",
						"foldChange": "0.9222"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".040749565408587",
						"qValue": "1",
						"foldChange": "0.6227"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".101061869934962",
						"qValue": "1",
						"foldChange": "0.6752"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".13138861077605",
						"qValue": "1",
						"foldChange": "1.3229"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".631303703939552",
						"qValue": "1",
						"foldChange": "0.8623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".053600026313298",
						"qValue": ".822195137696456",
						"foldChange": "0.6518"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".884295874129288",
						"qValue": "1",
						"foldChange": "1.0568"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".227634305793585",
						"qValue": "1",
						"foldChange": "0.7419"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".170016251672893",
						"qValue": ".985830071188835",
						"foldChange": "0.7020"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".153580939822502",
						"qValue": ".111511412422921",
						"foldChange": "1.3685"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".022727588004556",
						"qValue": ".020247626301763",
						"foldChange": "1.5230"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00044368682175",
						"qValue": ".000579952498639",
						"foldChange": "1.8788"
					}
				]
			},
			{
				"metaboliteId": "0078dbf9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e777-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPI (16:0)",
				"plotName": null,
				"compoundId": "000089e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61695",
				"inChiKey": null,
				"kegg": null,
				"mass": 572.296164,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2422,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".291998386205853",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".933968435457427",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".236930361270786",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".19951350884379",
						"qValue": "1",
						"foldChange": "0.9058"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".756185318866143",
						"qValue": "1",
						"foldChange": "1.1730"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".327817977964383",
						"qValue": "1",
						"foldChange": "1.7153"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".371647618641473",
						"qValue": ".957150284015863",
						"foldChange": "1.7772"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".258052894600459",
						"qValue": ".825038702902081",
						"foldChange": "1.7004"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".809226528596532",
						"qValue": "1",
						"foldChange": "1.3879"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".94874307594394",
						"qValue": "1",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".188913697001275",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".168734778281177",
						"qValue": ".976825145015523",
						"foldChange": "0.7402"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".996264141788158",
						"qValue": "1",
						"foldChange": "1.1591"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".628184851205627",
						"qValue": "1",
						"foldChange": "0.7359"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".616663601679418",
						"qValue": "1",
						"foldChange": "0.6349"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".122540421335566",
						"qValue": "1",
						"foldChange": "1.9605"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".652136699959318",
						"qValue": "1",
						"foldChange": "1.2426"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".29539914488",
						"qValue": "1",
						"foldChange": "0.6338"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".312469075759026",
						"qValue": "1",
						"foldChange": "1.8596"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".219455954278619",
						"qValue": "1",
						"foldChange": "0.4917"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".028165883577735",
						"qValue": ".985830071188835",
						"foldChange": "0.2644"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".8604179079037",
						"qValue": ".361182939797515",
						"foldChange": "1.0970"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004611616614269",
						"qValue": ".006123489910156",
						"foldChange": "5.1568"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".104545541035837",
						"qValue": ".033751818534192",
						"foldChange": "1.8053"
					}
				]
			},
			{
				"metaboliteId": "0078dbfa-0000-0000-0000-000000000000",
				"chemicalId": "00000400-0000-0000-0000-000000000000",
				"chemicalName": "pantothenate",
				"plotName": null,
				"compoundId": "000005e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00210",
				"inChiKey": "GHOKWGTUZJEAQD-ZETCQYMHBS",
				"kegg": "C00864",
				"mass": 219.110673,
				"pathwayType": "ANIMAL",
				"subPathway": "Pantothenate and CoA Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3945,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".027961378971548",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".952358025547545",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".669643455587873",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".386808531555378",
						"qValue": "1",
						"foldChange": "0.9376"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".913497615655253",
						"qValue": "1",
						"foldChange": "1.0250"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".33074378902614",
						"qValue": "1",
						"foldChange": "1.2317"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".421581295490551",
						"qValue": ".989197938984887",
						"foldChange": "1.2161"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".96476185025754",
						"qValue": "1",
						"foldChange": "1.1458"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".396778745381893",
						"qValue": ".957839854315834",
						"foldChange": "0.9595"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".544166051738195",
						"qValue": "1",
						"foldChange": "1.0905"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".840142823976566",
						"qValue": "1",
						"foldChange": "1.0535"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".685040503418507",
						"qValue": "1",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".006863292621924",
						"qValue": "1",
						"foldChange": "0.4909"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".076968448657365",
						"qValue": "1",
						"foldChange": "0.6441"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".362242628941903",
						"qValue": "1",
						"foldChange": "1.3121"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".045451437810222",
						"qValue": "1",
						"foldChange": "0.5799"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".270619383750348",
						"qValue": "1",
						"foldChange": "0.6931"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".392530690245595",
						"qValue": "1",
						"foldChange": "1.1952"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".005684726995397",
						"qValue": "1",
						"foldChange": "0.4457"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".084865613427636",
						"qValue": "1",
						"foldChange": "0.5093"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".303130628935836",
						"qValue": ".985830071188835",
						"foldChange": "1.1428"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005083065034972",
						"qValue": ".009146494588545",
						"foldChange": "2.3497"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001026626247828",
						"qValue": ".00216872027018",
						"foldChange": "2.4579"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000094160976052",
						"qValue": ".000204229996389",
						"foldChange": "5.2972"
					}
				]
			},
			{
				"metaboliteId": "0078dbfb-0000-0000-0000-000000000000",
				"chemicalId": "00000164-0000-0000-0000-000000000000",
				"chemicalName": "cortisol",
				"plotName": null,
				"compoundId": "000006b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00063",
				"inChiKey": "JYGXADMDTFJGBT-VWUMJDOOBO",
				"kegg": "C00735",
				"mass": 362.209324,
				"pathwayType": "ANIMAL",
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2987,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".059093771155705",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".753817425121665",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".532744213085009",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".107770270913158",
						"qValue": "1",
						"foldChange": "0.8832"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".537824994218696",
						"qValue": "1",
						"foldChange": "0.9751"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".313615158986126",
						"qValue": "1",
						"foldChange": "1.1772"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".64846467068405",
						"qValue": "1",
						"foldChange": "1.1355"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".685680523317742",
						"qValue": ".946387029686722",
						"foldChange": "1.0547"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".39081729962137",
						"qValue": ".957839854315834",
						"foldChange": "0.9350"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".782349766464677",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".802835674826401",
						"qValue": "1",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".599328118603188",
						"qValue": "1",
						"foldChange": "0.8987"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".481038066142562",
						"qValue": "1",
						"foldChange": "1.1366"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".101516378000111",
						"qValue": "1",
						"foldChange": "1.2248"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".310661373491226",
						"qValue": "1",
						"foldChange": "1.0776"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".044361546156006",
						"qValue": "1",
						"foldChange": "1.4690"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".006312393091058",
						"qValue": "1",
						"foldChange": "1.5558"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".354199371529301",
						"qValue": "1",
						"foldChange": "1.0592"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".39209269336362",
						"qValue": "1",
						"foldChange": "1.1505"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".065095381097307",
						"qValue": "1",
						"foldChange": "1.2162"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".276509670080365",
						"qValue": ".985830071188835",
						"foldChange": "1.0571"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".199846226326991",
						"qValue": ".134213397760911",
						"foldChange": "1.3617"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".112190110941511",
						"qValue": ".066988893309336",
						"foldChange": "1.3281"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".802508110753671",
						"qValue": ".160877781159725",
						"foldChange": "0.9882"
					}
				]
			},
			{
				"metaboliteId": "0078dbfc-0000-0000-0000-000000000000",
				"chemicalId": "00000684-0000-0000-0000-000000000000",
				"chemicalName": "taurodeoxycholate",
				"plotName": null,
				"compoundId": "00002fe5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00896",
				"inChiKey": "AWDRATDZQPNJFN-VAYUFCLWBR",
				"kegg": "C05463",
				"mass": 499.296759,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".986639845376447",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".993094027996601",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".409714412828441",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".32900020518356",
						"qValue": "1",
						"foldChange": "1.9906"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".333885025512266",
						"qValue": "1",
						"foldChange": "2.0916"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".992054448980022",
						"qValue": "1",
						"foldChange": "1.5427"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".198880626543638",
						"qValue": ".798336854663379",
						"foldChange": "1.1426"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".161578221249558",
						"qValue": ".787973217949622",
						"foldChange": "5.3615"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".905798860448929",
						"qValue": "1",
						"foldChange": "2.5660"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".677455128399559",
						"qValue": "1",
						"foldChange": "1.7854"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".622185917777229",
						"qValue": "1",
						"foldChange": "1.7658"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".93857193942236",
						"qValue": "1",
						"foldChange": "1.2645"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".401265363425772",
						"qValue": "1",
						"foldChange": "3.1249"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".934131846680339",
						"qValue": "1",
						"foldChange": "0.7754"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".465690218817884",
						"qValue": "1",
						"foldChange": "0.2481"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".565269404148576",
						"qValue": "1",
						"foldChange": "0.3942"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".806644954796078",
						"qValue": "1",
						"foldChange": "0.2909"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".758743372621994",
						"qValue": "1",
						"foldChange": "0.7380"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".521991555915738",
						"qValue": "1",
						"foldChange": "0.5324"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".849986669169743",
						"qValue": "1",
						"foldChange": "0.4556"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".669024270995864",
						"qValue": ".990272584968167",
						"foldChange": "0.8557"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".440191773668757",
						"qValue": ".229517093392737",
						"foldChange": "1.1373"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".710456877573224",
						"qValue": ".268076281390264",
						"foldChange": "1.5253"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".201236501444245",
						"qValue": ".055782730735944",
						"foldChange": "3.0855"
					}
				]
			},
			{
				"metaboliteId": "0078dbfd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6a6-0000-0000-0000-000000000000",
				"chemicalName": "5-methyluridine (ribothymidine)",
				"plotName": null,
				"compoundId": "00008940-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00884",
				"inChiKey": "DWRXFEITVBNRMK-JXOAFFINBW",
				"kegg": null,
				"mass": 258.085186,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3842,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".555700223034867",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".63855764139918",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".675596487660309",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".319284750435329",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".817756759451515",
						"qValue": "1",
						"foldChange": "1.1520"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".221712463148873",
						"qValue": "1",
						"foldChange": "3.3699"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".951195176302503",
						"qValue": "1",
						"foldChange": "2.2805"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".369139409525678",
						"qValue": ".875723678608451",
						"foldChange": "1.2924"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".402254492293039",
						"qValue": ".957839854315834",
						"foldChange": "2.3851"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".679633819768715",
						"qValue": "1",
						"foldChange": "1.0141"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".500595843308108",
						"qValue": "1",
						"foldChange": "0.8812"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".793526743492095",
						"qValue": "1",
						"foldChange": "1.5227"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".861649921712088",
						"qValue": "1",
						"foldChange": "1.0512"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".398347232310082",
						"qValue": "1",
						"foldChange": "1.2506"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".485502100919903",
						"qValue": "1",
						"foldChange": "1.1897"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".32224970930766",
						"qValue": "1",
						"foldChange": "1.2689"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".193452524749749",
						"qValue": "1",
						"foldChange": "1.3770"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".705058587407782",
						"qValue": "1",
						"foldChange": "1.0852"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".442686565492375",
						"qValue": "1",
						"foldChange": "0.8629"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".944815111758342",
						"qValue": "1",
						"foldChange": "0.9328"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".415593379438301",
						"qValue": ".985830071188835",
						"foldChange": "1.0810"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000023820209617",
						"qValue": ".000262888242316",
						"foldChange": "9.3446"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000798591261303",
						"qValue": ".002007776002987",
						"foldChange": "10.0099"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000097811349109",
						"qValue": ".000204229996389",
						"foldChange": "10.7347"
					}
				]
			},
			{
				"metaboliteId": "0078dbfe-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74e-0000-0000-0000-000000000000",
				"chemicalName": "hexadecanedioate (C16-DC)",
				"plotName": null,
				"compoundId": "00008b5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00672",
				"inChiKey": "QQHJDPROMQRDLA-UHFFFAOYAF",
				"kegg": "C19615",
				"mass": 286.214409,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1519,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".565827474291854",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".419788718680539",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".311550975759804",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".451938101290752",
						"qValue": "1",
						"foldChange": "0.9652"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".826922148106093",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".592706254932136",
						"qValue": "1",
						"foldChange": "1.1446"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".604476541722261",
						"qValue": "1",
						"foldChange": "1.1681"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".3266105491522",
						"qValue": ".831655911352372",
						"foldChange": "1.0023"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".136962036108692",
						"qValue": ".957839854315834",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".065321288831353",
						"qValue": ".821720595261736",
						"foldChange": "1.3965"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".508565118800544",
						"qValue": "1",
						"foldChange": "1.1469"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".228357452958683",
						"qValue": "1",
						"foldChange": "0.8570"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".882273686484768",
						"qValue": "1",
						"foldChange": "1.0509"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".881947851913745",
						"qValue": "1",
						"foldChange": "1.0253"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".767662160988432",
						"qValue": "1",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".576429309443255",
						"qValue": "1",
						"foldChange": "1.6420"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".110898735154507",
						"qValue": "1",
						"foldChange": "1.4904"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".266874206242032",
						"qValue": "1",
						"foldChange": "0.9077"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".579624607005472",
						"qValue": "1",
						"foldChange": "0.8915"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".521162936438246",
						"qValue": "1",
						"foldChange": "1.1196"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".235930612463317",
						"qValue": ".985830071188835",
						"foldChange": "1.2558"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".160572979896056",
						"qValue": ".11529914801257",
						"foldChange": "1.7037"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".18145423852142",
						"qValue": ".096930782863831",
						"foldChange": "1.4152"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".011423129452353",
						"qValue": ".006001843649041",
						"foldChange": "1.8756"
					}
				]
			},
			{
				"metaboliteId": "0078dbff-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8ec-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3beta,17beta) monosulfate (1)",
				"plotName": null,
				"compoundId": "0000915b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03818",
				"inChiKey": null,
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3028,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".638012453804366",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".779540268004441",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".139013674331768",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".377339679124395",
						"qValue": "1",
						"foldChange": "0.8780"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".795927069878073",
						"qValue": "1",
						"foldChange": "0.9767"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".531033464134359",
						"qValue": "1",
						"foldChange": "1.1543"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".015146132483805",
						"qValue": ".303094620658292",
						"foldChange": "3.2206"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".055872509482216",
						"qValue": ".687896186587066",
						"foldChange": "2.5943"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".578299130489586",
						"qValue": ".957839854315834",
						"foldChange": "0.9471"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".687143950411801",
						"qValue": "1",
						"foldChange": "0.9463"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".615354981613742",
						"qValue": "1",
						"foldChange": "0.9266"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".920321425112531",
						"qValue": "1",
						"foldChange": "0.9653"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".30466012616558",
						"qValue": "1",
						"foldChange": "0.7819"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".522466533369479",
						"qValue": "1",
						"foldChange": "1.3169"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".103932333248142",
						"qValue": "1",
						"foldChange": "1.6842"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".566858876858093",
						"qValue": "1",
						"foldChange": "1.4949"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".394922486270268",
						"qValue": "1",
						"foldChange": "1.2567"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".749000181981366",
						"qValue": "1",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".996558741682841",
						"qValue": "1",
						"foldChange": "1.0331"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".608385002475853",
						"qValue": "1",
						"foldChange": "1.1275"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".60259289006619",
						"qValue": ".985830071188835",
						"foldChange": "1.0913"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".015653971501416",
						"qValue": ".020648934936414",
						"foldChange": "3.6395"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".018853077381147",
						"qValue": ".017236437815304",
						"foldChange": "2.4479"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003632708389641",
						"qValue": ".002616013038889",
						"foldChange": "3.1998"
					}
				]
			},
			{
				"metaboliteId": "0078dc00-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8ca-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3beta,17beta) disulfate (2)",
				"plotName": null,
				"compoundId": "00009153-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03818",
				"inChiKey": null,
				"kegg": "C04295",
				"mass": 450.138209,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3032,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".95711524170508",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".927907608386369",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".195194266926691",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".077602781180954",
						"qValue": "1",
						"foldChange": "0.8004"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".301073589206602",
						"qValue": "1",
						"foldChange": "0.8819"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".45215330240508",
						"qValue": "1",
						"foldChange": "1.1565"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".123382681324894",
						"qValue": ".704689005419363",
						"foldChange": "1.6807"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".421416867851742",
						"qValue": ".891822313747101",
						"foldChange": "1.4068"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".45252692951414",
						"qValue": ".957839854315834",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".404939496942422",
						"qValue": "1",
						"foldChange": "1.1934"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".806421191032268",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".555409009875922",
						"qValue": "1",
						"foldChange": "0.8901"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".73970175634085",
						"qValue": "1",
						"foldChange": "1.0085"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".822220616834976",
						"qValue": "1",
						"foldChange": "1.2500"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".926802985418569",
						"qValue": "1",
						"foldChange": "1.2394"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".414518709629067",
						"qValue": "1",
						"foldChange": "1.4175"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".505716310935374",
						"qValue": "1",
						"foldChange": "1.3077"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".911897787082911",
						"qValue": "1",
						"foldChange": "0.9226"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".761766981916131",
						"qValue": "1",
						"foldChange": "1.1425"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".835092995507661",
						"qValue": "1",
						"foldChange": "1.1882"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".935793330363295",
						"qValue": ".997573179629986",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".592091828430172",
						"qValue": ".287022010456366",
						"foldChange": "1.8267"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".305054758660343",
						"qValue": ".142723882261536",
						"foldChange": "1.3368"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".195878330793097",
						"qValue": ".054674512163849",
						"foldChange": "1.4701"
					}
				]
			},
			{
				"metaboliteId": "0078dc01-0000-0000-0000-000000000000",
				"chemicalId": "05f5e634-0000-0000-0000-000000000000",
				"chemicalName": "salicyluric glucuronide*",
				"plotName": null,
				"compoundId": "00008268-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "OEPADIDIUZTYOX-UHFFFAOYAT",
				"kegg": null,
				"mass": 371.085246,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4800,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".378219342144336",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".786538915919704",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".943523346360885",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".459632929356575",
						"qValue": "1",
						"foldChange": "19.8552"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".695416360251522",
						"qValue": "1",
						"foldChange": "3.8307"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".726694916866792",
						"qValue": "1",
						"foldChange": "3.0552"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".760760034616005",
						"qValue": "1",
						"foldChange": "1.5161"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".717684171526107",
						"qValue": ".950986052805043",
						"foldChange": "1.6162"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".954408347116099",
						"qValue": "1",
						"foldChange": "1.2876"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".518756791387124",
						"qValue": "1",
						"foldChange": "2.0531"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".860297709530521",
						"qValue": "1",
						"foldChange": "1.5614"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".63827871653788",
						"qValue": "1",
						"foldChange": "0.8995"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".321404608476443",
						"qValue": "1",
						"foldChange": "0.5498"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".47103367622135",
						"qValue": "1",
						"foldChange": "0.3328"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".820938865361628",
						"qValue": "1",
						"foldChange": "0.6053"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".137928835498193",
						"qValue": "1",
						"foldChange": "0.3234"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".455352792274786",
						"qValue": "1",
						"foldChange": "0.3581"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".489550184761487",
						"qValue": "1",
						"foldChange": "1.1074"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".178976041087413",
						"qValue": "1",
						"foldChange": "1.3614"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".414811714717641",
						"qValue": "1",
						"foldChange": "0.2363"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".631579735672407",
						"qValue": ".985830071188835",
						"foldChange": "0.1736"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00478832758575",
						"qValue": ".008758951426348",
						"foldChange": "24.8485"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".044567562732403",
						"qValue": ".034235554439445",
						"foldChange": "161.2831"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000540140187007",
						"qValue": ".000662912488169",
						"foldChange": "95.5338"
					}
				]
			},
			{
				"metaboliteId": "0078dc02-0000-0000-0000-000000000000",
				"chemicalId": "05f5e80e-0000-0000-0000-000000000000",
				"chemicalName": "o-cresol sulfate",
				"plotName": null,
				"compoundId": "00008fed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CYGSXDXRHXMAOV-UHFFFAOYAI",
				"kegg": null,
				"mass": 188.014329,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4139,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".635633624595415",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".445037337117919",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".120582724093534",
						"qValue": ".834275595151197",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".249143446671725",
						"qValue": "1",
						"foldChange": "1.8300"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".196382705523875",
						"qValue": "1",
						"foldChange": "1.7375"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".886446338529138",
						"qValue": "1",
						"foldChange": "1.2103"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".022335080177425",
						"qValue": ".359575885726771",
						"foldChange": "1.0919"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".977780148026356",
						"qValue": "1",
						"foldChange": "2.3437"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".023887250414396",
						"qValue": ".580207043795744",
						"foldChange": "4.6421"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".586706953466014",
						"qValue": "1",
						"foldChange": "1.3919"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".739112911141054",
						"qValue": "1",
						"foldChange": "1.2402"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".832741064368029",
						"qValue": "1",
						"foldChange": "0.9011"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".763546850893615",
						"qValue": "1",
						"foldChange": "0.8638"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".871142531006979",
						"qValue": "1",
						"foldChange": "0.5777"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".900250978586405",
						"qValue": "1",
						"foldChange": "0.6687"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".050865073830503",
						"qValue": "1",
						"foldChange": "0.2982"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".83256471649187",
						"qValue": "1",
						"foldChange": "0.6332"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".091246309224189",
						"qValue": ".834952786556829",
						"foldChange": "2.1235"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".549068282125764",
						"qValue": "1",
						"foldChange": "0.7368"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655521361895572",
						"qValue": "1",
						"foldChange": "0.4997"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".90127913682446",
						"qValue": ".997573179629986",
						"foldChange": "0.6782"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".01886883929548",
						"qValue": ".024037800988778",
						"foldChange": "3.4873"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".13615896827334",
						"qValue": ".077643034238057",
						"foldChange": "3.0255"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".055901079580602",
						"qValue": ".020879844219214",
						"foldChange": "4.2920"
					}
				]
			},
			{
				"metaboliteId": "0078dc03-0000-0000-0000-000000000000",
				"chemicalId": "05f5e977-0000-0000-0000-000000000000",
				"chemicalName": "12-HETE",
				"plotName": null,
				"compoundId": "000092a0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06111",
				"inChiKey": "ZNHVWPKMFKADKW-VXBMJZGYBY",
				"kegg": null,
				"mass": 320.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1890,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".252403898626227",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".768760553148522",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".290501453215868",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".712490411789429",
						"qValue": "1",
						"foldChange": "1.4500"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".49819769817168",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".297517184986253",
						"qValue": "1",
						"foldChange": "0.8447"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".778403171043176",
						"qValue": "1",
						"foldChange": "1.1449"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".430487675039639",
						"qValue": ".894260227315856",
						"foldChange": "1.3590"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".610942640860459",
						"qValue": ".967408831530889",
						"foldChange": "1.7869"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".206637117315469",
						"qValue": "1",
						"foldChange": "0.8520"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".52635580430776",
						"qValue": "1",
						"foldChange": "1.5167"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".061018188920894",
						"qValue": ".727024127920748",
						"foldChange": "2.7205"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".658284983396044",
						"qValue": "1",
						"foldChange": "0.8316"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".588503008456285",
						"qValue": "1",
						"foldChange": "1.0099"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".327039484330147",
						"qValue": "1",
						"foldChange": "1.2144"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".295064139608747",
						"qValue": "1",
						"foldChange": "0.8301"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".311977626131246",
						"qValue": "1",
						"foldChange": "0.7529"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".983001101867261",
						"qValue": "1",
						"foldChange": "0.9070"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".609738918018328",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".080769595430829",
						"qValue": "1",
						"foldChange": "1.8060"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".023747114321516",
						"qValue": ".985830071188835",
						"foldChange": "1.7451"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".641981449427651",
						"qValue": ".303648596029503",
						"foldChange": "1.2538"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".071999074597697",
						"qValue": ".048535883584844",
						"foldChange": "0.2461"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056143873256917",
						"qValue": ".020879844219214",
						"foldChange": "0.4316"
					}
				]
			},
			{
				"metaboliteId": "0078dc04-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea28-0000-0000-0000-000000000000",
				"chemicalName": "(12 or 13)-methylmyristate (a15:0 or i15:0)",
				"plotName": null,
				"compoundId": "00009595-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZOCYQVNGROEVLU-UHFFFAOYAB",
				"kegg": null,
				"mass": 242.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1459,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".973907010132757",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".286092276167696",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".330159819612479",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".243442928668908",
						"qValue": "1",
						"foldChange": "1.2379"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".611956112870772",
						"qValue": "1",
						"foldChange": "1.1405"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".505863992071753",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".980477439083756",
						"qValue": "1",
						"foldChange": "1.0396"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".037131718404527",
						"qValue": ".678234398444917",
						"foldChange": "0.8226"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".03510450744748",
						"qValue": ".635615656123514",
						"foldChange": "0.8465"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".733999241674663",
						"qValue": "1",
						"foldChange": "1.2082"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".771536994946307",
						"qValue": "1",
						"foldChange": "1.1249"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".960502973410291",
						"qValue": "1",
						"foldChange": "1.0318"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".538535205193764",
						"qValue": "1",
						"foldChange": "1.1969"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".810951072834623",
						"qValue": "1",
						"foldChange": "0.9982"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".725011155307036",
						"qValue": "1",
						"foldChange": "0.8339"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".919347193869632",
						"qValue": "1",
						"foldChange": "1.1541"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".809973287946523",
						"qValue": "1",
						"foldChange": "0.9334"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".882099730556956",
						"qValue": "1",
						"foldChange": "0.8088"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".339230522781436",
						"qValue": "1",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".903303667665056",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".294528030998902",
						"qValue": ".985830071188835",
						"foldChange": "1.0325"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".808833150232468",
						"qValue": ".347337283237798",
						"foldChange": "1.0220"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".505002608452336",
						"qValue": ".204325434076588",
						"foldChange": "1.4358"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".481567856240972",
						"qValue": ".10828603571378",
						"foldChange": "1.1851"
					}
				]
			},
			{
				"metaboliteId": "0078dc05-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9ca-0000-0000-0000-000000000000",
				"chemicalName": "homovanillate sulfate",
				"plotName": null,
				"compoundId": "000095cd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11719",
				"inChiKey": "IACOAKYXFIWAQN-UHFFFAOYAW",
				"kegg": null,
				"mass": 262.014723,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 203,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".994221972416132",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".942167584900195",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".073815600130969",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".875240375872627",
						"qValue": "1",
						"foldChange": "1.3563"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".394011046063226",
						"qValue": "1",
						"foldChange": "1.7483"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".485914017090596",
						"qValue": "1",
						"foldChange": "1.6873"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".107380136408541",
						"qValue": ".676892563582731",
						"foldChange": "0.8298"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".014075342157747",
						"qValue": ".567215491640796",
						"foldChange": "0.8843"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".368601647160741",
						"qValue": ".957839854315834",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".1898860229939",
						"qValue": "1",
						"foldChange": "1.6207"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".330250791838806",
						"qValue": "1",
						"foldChange": "1.4594"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".730734010285626",
						"qValue": "1",
						"foldChange": "1.0837"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".20875696628033",
						"qValue": "1",
						"foldChange": "4.2388"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".805569405783064",
						"qValue": "1",
						"foldChange": "0.6288"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".143378703321003",
						"qValue": "1",
						"foldChange": "0.1484"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".970914031002193",
						"qValue": "1",
						"foldChange": "1.1033"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".546434171933541",
						"qValue": "1",
						"foldChange": "1.2324"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".5601301992567",
						"qValue": "1",
						"foldChange": "1.1171"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".28038898127582",
						"qValue": "1",
						"foldChange": "0.6626"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".903015789788791",
						"qValue": "1",
						"foldChange": "0.8204"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".355972961374857",
						"qValue": ".985830071188835",
						"foldChange": "1.2382"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011493660100606",
						"qValue": ".016988583528684",
						"foldChange": "2.4135"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".091137525613083",
						"qValue": ".057237330356466",
						"foldChange": "1.9493"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01584274299591",
						"qValue": ".00781328709767",
						"foldChange": "2.6153"
					}
				]
			},
			{
				"metaboliteId": "0078dc06-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed61-0000-0000-0000-000000000000",
				"chemicalName": "isoleucylglycine",
				"plotName": null,
				"compoundId": "00009c48-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28907",
				"inChiKey": "UCGDDTHMMVWVMV-UHFFFAOYAU",
				"kegg": null,
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".00440728790742",
						"qValue": ".750120401842876",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".989819152956059",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".913463388694962",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".518375553869128",
						"qValue": "1",
						"foldChange": "1.1456"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".437772152840532",
						"qValue": "1",
						"foldChange": "0.8997"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".89566408957748",
						"qValue": "1",
						"foldChange": "1.1673"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".837756120033457",
						"qValue": "1",
						"foldChange": "1.1873"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".805078002770873",
						"qValue": ".990059798205221",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".966459159335109",
						"qValue": "1",
						"foldChange": "1.1975"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".656748006375377",
						"qValue": "1",
						"foldChange": "1.1869"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".748741246398983",
						"qValue": "1",
						"foldChange": "1.1800"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".901009190485553",
						"qValue": "1",
						"foldChange": "0.9715"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".090318645046152",
						"qValue": "1",
						"foldChange": "0.6673"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".345946723378556",
						"qValue": "1",
						"foldChange": "1.2269"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".010546854337406",
						"qValue": "1",
						"foldChange": "1.8387"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".317225080430753",
						"qValue": "1",
						"foldChange": "0.8431"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".069775487178514",
						"qValue": "1",
						"foldChange": "1.3909"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".00569674361445",
						"qValue": ".605991101987089",
						"foldChange": "1.6498"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".391205869812127",
						"qValue": "1",
						"foldChange": "0.8683"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".069868740761419",
						"qValue": "1",
						"foldChange": "1.4440"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".008371797184057",
						"qValue": ".893076453757469",
						"foldChange": "1.6629"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006119805584313",
						"qValue": ".01066426339491",
						"foldChange": "0.4880"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000000612961508",
						"qValue": ".000042730533812",
						"foldChange": "0.2197"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000889972059",
						"qValue": ".000014308607724",
						"foldChange": "0.2512"
					}
				]
			},
			{
				"metaboliteId": "0078dc07-0000-0000-0000-000000000000",
				"chemicalId": "000005e2-0000-0000-0000-000000000000",
				"chemicalName": "N-linoleoylglycine",
				"plotName": null,
				"compoundId": "00007ece-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "YCRHZEHWEYAHCO-HZJYTTRNBW",
				"kegg": null,
				"mass": 337.261694,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1624,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".646146626600736",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".477881357547664",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".693217697685655",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".51778617005924",
						"qValue": "1",
						"foldChange": "1.2074"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".710838251515474",
						"qValue": "1",
						"foldChange": "1.1342"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".781722152349831",
						"qValue": "1",
						"foldChange": "1.4688"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".285613208096279",
						"qValue": ".911265664999995",
						"foldChange": "1.9083"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".812232117973089",
						"qValue": ".991417013240296",
						"foldChange": "1.5138"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".193529984649208",
						"qValue": ".957839854315834",
						"foldChange": "0.9148"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".895978025204745",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".297462157457526",
						"qValue": "1",
						"foldChange": "0.7811"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".361194290843055",
						"qValue": "1",
						"foldChange": "0.8345"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".817024680646798",
						"qValue": "1",
						"foldChange": "1.0657"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".43855081564426",
						"qValue": "1",
						"foldChange": "1.2635"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".310088032493073",
						"qValue": "1",
						"foldChange": "1.1857"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".281140057744072",
						"qValue": "1",
						"foldChange": "1.4378"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".250276274711043",
						"qValue": "1",
						"foldChange": "1.3046"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".89232411784803",
						"qValue": "1",
						"foldChange": "0.9074"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".907175332451836",
						"qValue": "1",
						"foldChange": "1.1468"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".822338227435929",
						"qValue": "1",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".731722635754551",
						"qValue": ".997573179629986",
						"foldChange": "0.8485"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".108990763696181",
						"qValue": ".087163807531252",
						"foldChange": "1.6528"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".753575904706671",
						"qValue": ".277952327000241",
						"foldChange": "1.3187"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013823952361254",
						"qValue": ".006989178209325",
						"foldChange": "2.3543"
					}
				]
			},
			{
				"metaboliteId": "0078dc08-0000-0000-0000-000000000000",
				"chemicalId": "0000005d-0000-0000-0000-000000000000",
				"chemicalName": "alpha-ketoglutarate",
				"plotName": null,
				"compoundId": "00000210-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00208",
				"inChiKey": "KPGXRSRHYNQIFN-UHFFFAOYAN",
				"kegg": "C00026",
				"mass": 146.021525,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".792574975649393",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".30869226670261",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".047238471238803",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".072250772044752",
						"qValue": "1",
						"foldChange": "0.8749"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".89347984645914",
						"qValue": "1",
						"foldChange": "1.0285"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".054311008368548",
						"qValue": "1",
						"foldChange": "1.2350"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".600045513543172",
						"qValue": "1",
						"foldChange": "1.0675"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".043646719744997",
						"qValue": ".678234398444917",
						"foldChange": "0.8859"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".012352596168363",
						"qValue": ".420482373571078",
						"foldChange": "0.8517"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".746202293716427",
						"qValue": "1",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".43312378655889",
						"qValue": "1",
						"foldChange": "0.9420"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".644047882858172",
						"qValue": "1",
						"foldChange": "0.9269"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".818223514820243",
						"qValue": "1",
						"foldChange": "1.0065"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".606175796926548",
						"qValue": "1",
						"foldChange": "1.0180"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".759461231143152",
						"qValue": "1",
						"foldChange": "1.0113"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".131012514246435",
						"qValue": "1",
						"foldChange": "1.2284"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".19748318781363",
						"qValue": "1",
						"foldChange": "1.1674"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".880237719704492",
						"qValue": "1",
						"foldChange": "0.9503"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".349328064169973",
						"qValue": "1",
						"foldChange": "0.8159"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".99737433698427",
						"qValue": "1",
						"foldChange": "0.8678"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".362717153721591",
						"qValue": ".985830071188835",
						"foldChange": "1.0636"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002953456540676",
						"qValue": ".006073674970501",
						"foldChange": "1.6044"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".032202579050759",
						"qValue": ".026333063244051",
						"foldChange": "1.3988"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".031667003487258",
						"qValue": ".013439424573254",
						"foldChange": "1.6778"
					}
				]
			},
			{
				"metaboliteId": "0078dc09-0000-0000-0000-000000000000",
				"chemicalId": "05f5e209-0000-0000-0000-000000000000",
				"chemicalName": "kynurenine",
				"plotName": null,
				"compoundId": "00003b24-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00684",
				"inChiKey": "YGPSJZOEDVAXAB-QMMMGPOBBJ",
				"kegg": "C00328",
				"mass": 208.084792,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 240,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".914243931704743",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".39673550437817",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".742568537208266",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".677362880196247",
						"qValue": "1",
						"foldChange": "0.9852"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".691168465580533",
						"qValue": "1",
						"foldChange": "1.0250"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".417233739787501",
						"qValue": "1",
						"foldChange": "1.0443"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".942192810955851",
						"qValue": "1",
						"foldChange": "1.0120"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".489795081062057",
						"qValue": ".902562957068497",
						"foldChange": "1.0406"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".536175151100013",
						"qValue": ".957839854315834",
						"foldChange": "1.0366"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".125248679957188",
						"qValue": "1",
						"foldChange": "0.9363"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".380811239310273",
						"qValue": "1",
						"foldChange": "0.9687"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".502412792866635",
						"qValue": "1",
						"foldChange": "0.9777"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".776627906684054",
						"qValue": "1",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".676675561640357",
						"qValue": "1",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".878576787712547",
						"qValue": "1",
						"foldChange": "1.0181"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".633400938682238",
						"qValue": "1",
						"foldChange": "1.0532"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".962299586673189",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".609929321756586",
						"qValue": "1",
						"foldChange": "0.9351"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".698726009365116",
						"qValue": "1",
						"foldChange": "1.0465"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".931919519721178",
						"qValue": "1",
						"foldChange": "0.9366"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".644038916633519",
						"qValue": ".985830071188835",
						"foldChange": "0.8950"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".924344826893579",
						"qValue": ".378764696310446",
						"foldChange": "1.0385"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".683508208663498",
						"qValue": ".260730275686005",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".549730823012371",
						"qValue": ".118635542664953",
						"foldChange": "0.9556"
					}
				]
			},
			{
				"metaboliteId": "0078dc0a-0000-0000-0000-000000000000",
				"chemicalId": "000007ec-0000-0000-0000-000000000000",
				"chemicalName": "metoprolol",
				"plotName": null,
				"compoundId": "00004675-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01932",
				"inChiKey": "IUBSYMUCCVWXPE-UHFFFAOYAN",
				"kegg": "D02358",
				"mass": 267.183444,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4961,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".455297140085109",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".147925720594128",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".489190200250262",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".506626975274154",
						"qValue": "1",
						"foldChange": "1.0780"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".285420403823402",
						"qValue": ".825038702902081",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".682790350106232",
						"qValue": ".976023509428735",
						"foldChange": "1.0425"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".930263604661374",
						"qValue": "1",
						"foldChange": "1.0673"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".036922624797484",
						"qValue": "1",
						"foldChange": "1.5138"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".044997813223493",
						"qValue": ".696238891876229",
						"foldChange": "1.2738"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".641135993339908",
						"qValue": "1",
						"foldChange": "1.2286"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".349722291012637",
						"qValue": "1",
						"foldChange": "1.4929"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".608718896999587",
						"qValue": "1",
						"foldChange": "1.2152"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".489866775011413",
						"qValue": "1",
						"foldChange": "1.4850"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".334118363518469",
						"qValue": "1",
						"foldChange": "1.7954"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".744663698886314",
						"qValue": "1",
						"foldChange": "1.2090"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".4079347140908",
						"qValue": "1",
						"foldChange": "1.7339"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".095296976272349",
						"qValue": "1",
						"foldChange": "3.2899"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".34811995443776",
						"qValue": ".985830071188835",
						"foldChange": "1.8974"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".256729530775424",
						"qValue": ".160285411375899",
						"foldChange": "3.2899"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.7339"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc0b-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebf8-0000-0000-0000-000000000000",
				"chemicalName": "pantoprazole",
				"plotName": null,
				"compoundId": "000096d1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05017",
				"inChiKey": "IQPSEEYGBUAQFF-UHFFFAOYAJ",
				"kegg": "C11806",
				"mass": 383.075133,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Gastrointestinal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5011,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".680426316496918",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".335479972019538",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".557788209372013",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".230085442842497",
						"qValue": "1",
						"foldChange": "1.9976"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".457567452607515",
						"qValue": "1",
						"foldChange": "1.3473"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".642836924316467",
						"qValue": "1",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".149963628793463",
						"qValue": ".742487134132407",
						"foldChange": "2.7513"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".787646476856981",
						"qValue": ".978751596872832",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".089345043552409",
						"qValue": ".817555183474197",
						"foldChange": "0.9031"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".604457678617292",
						"qValue": "1",
						"foldChange": "0.9243"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".426222569472111",
						"qValue": "1",
						"foldChange": "0.9049"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".779876973504479",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".544144581269334",
						"qValue": "1",
						"foldChange": "0.0775"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".697212554563639",
						"qValue": "1",
						"foldChange": "9.5370"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".327265488878966",
						"qValue": "1",
						"foldChange": "123.0302"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".574379031154876",
						"qValue": "1",
						"foldChange": "0.0283"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".959606352323885",
						"qValue": "1",
						"foldChange": "0.3246"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".622277724638598",
						"qValue": "1",
						"foldChange": "11.4780"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".385830501573226",
						"qValue": "1",
						"foldChange": "0.0166"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".993073848780885",
						"qValue": "1",
						"foldChange": "0.4772"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".405594587181411",
						"qValue": ".985830071188835",
						"foldChange": "28.6993"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "28.6993"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087335",
						"qValue": ".083280304277061",
						"foldChange": "60.1366"
					}
				]
			},
			{
				"metaboliteId": "0078dc0c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e968-0000-0000-0000-000000000000",
				"chemicalName": "andro steroid monosulfate C19H28O6S (1)*",
				"plotName": null,
				"compoundId": "0000803b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02759",
				"inChiKey": null,
				"kegg": "C04555",
				"mass": 384.160659,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3060,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".920961133078551",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".950473201193043",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".147622546235602",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".112404255341738",
						"qValue": "1",
						"foldChange": "0.8527"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".529901141597912",
						"qValue": "1",
						"foldChange": "0.9162"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".32986407334176",
						"qValue": "1",
						"foldChange": "1.3534"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".052782501638991",
						"qValue": ".510430782895238",
						"foldChange": "1.7822"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".105221742092223",
						"qValue": ".697458782960496",
						"foldChange": "1.5775"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".739396229334717",
						"qValue": ".976023509428735",
						"foldChange": "1.0666"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".954337410106098",
						"qValue": "1",
						"foldChange": "1.0725"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".718352941574012",
						"qValue": "1",
						"foldChange": "0.9548"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".761509786735439",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".512560170037613",
						"qValue": "1",
						"foldChange": "0.8177"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".608686607578672",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".911571785274129",
						"qValue": "1",
						"foldChange": "1.2915"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".505937721473157",
						"qValue": "1",
						"foldChange": "1.3257"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".965086894778479",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".548448601719644",
						"qValue": "1",
						"foldChange": "0.7808"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".861075558642376",
						"qValue": "1",
						"foldChange": "1.0680"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".671768853192231",
						"qValue": "1",
						"foldChange": "0.8649"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".546917272930322",
						"qValue": ".985830071188835",
						"foldChange": "0.8099"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".973817982938625",
						"qValue": ".389869372516017",
						"foldChange": "1.3038"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".2375664412017",
						"qValue": ".118293851216076",
						"foldChange": "1.3897"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".302946467982357",
						"qValue": ".075985175282193",
						"foldChange": "1.4568"
					}
				]
			},
			{
				"metaboliteId": "0078dc0d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e410-0000-0000-0000-000000000000",
				"chemicalName": "theanine",
				"plotName": null,
				"compoundId": "000056be-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34365",
				"inChiKey": "DATAGRPVKZEWHA-YFKPBYRVBS",
				"kegg": "C01047",
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4561,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".2298355824983",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".115661672146524",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".607976015298787",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".013966599050304",
						"qValue": "1",
						"foldChange": "43.9108"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".335752022013549",
						"qValue": "1",
						"foldChange": "6.5293"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".120868433511906",
						"qValue": "1",
						"foldChange": "0.7824"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".52957915307763",
						"qValue": "1",
						"foldChange": "7.2524"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".780231896768086",
						"qValue": ".976437270808295",
						"foldChange": "3.0966"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".725889569060858",
						"qValue": ".976023509428735",
						"foldChange": "3.6694"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".617586462247067",
						"qValue": "1",
						"foldChange": "7.8138"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".729278755028022",
						"qValue": "1",
						"foldChange": "5.3941"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".877920100235479",
						"qValue": "1",
						"foldChange": "1.1580"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".573985778416906",
						"qValue": "1",
						"foldChange": "0.7833"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".503352367288827",
						"qValue": "1",
						"foldChange": "0.2709"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".887335645527127",
						"qValue": "1",
						"foldChange": "0.3459"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".052306411322118",
						"qValue": "1",
						"foldChange": "0.0955"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".04410057513886",
						"qValue": "1",
						"foldChange": "0.0899"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".849871777481463",
						"qValue": "1",
						"foldChange": "0.9415"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".284903568173592",
						"qValue": "1",
						"foldChange": "0.6698"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".280923948174497",
						"qValue": "1",
						"foldChange": "0.2311"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".944805297162974",
						"qValue": ".997575444606604",
						"foldChange": "0.3450"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".050769636752892",
						"qValue": ".050027824449754",
						"foldChange": "6.2786"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".09589167876092",
						"qValue": ".059220685240012",
						"foldChange": "18.1995"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01757777610022",
						"qValue": ".008461326763109",
						"foldChange": "27.1722"
					}
				]
			},
			{
				"metaboliteId": "0078dc0e-0000-0000-0000-000000000000",
				"chemicalId": "00000037-0000-0000-0000-000000000000",
				"chemicalName": "1-methylnicotinamide",
				"plotName": null,
				"compoundId": "00006c11-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00699",
				"inChiKey": "LDHMAVIPBRSVRG-UHFFFAOYAE",
				"kegg": "C02918",
				"mass": 136.063663,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3928,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".437578365525572",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".728770558750672",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".765655454554971",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".542926997397049",
						"qValue": "1",
						"foldChange": "1.1858"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".425372259359455",
						"qValue": "1",
						"foldChange": "1.2076"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".84916009827967",
						"qValue": "1",
						"foldChange": "1.1005"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".374943526367495",
						"qValue": ".957150284015863",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".904472053027926",
						"qValue": "1",
						"foldChange": "1.1588"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".314612479724411",
						"qValue": ".957839854315834",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".462616058954803",
						"qValue": "1",
						"foldChange": "0.9995"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".563914438268065",
						"qValue": "1",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".874012251897358",
						"qValue": "1",
						"foldChange": "1.1514"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".379206749158938",
						"qValue": "1",
						"foldChange": "0.2645"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".563908209545245",
						"qValue": "1",
						"foldChange": "0.2662"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".791377887343911",
						"qValue": "1",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".152506212119374",
						"qValue": "1",
						"foldChange": "0.2698"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".278382986088944",
						"qValue": "1",
						"foldChange": "0.2689"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".772755277008969",
						"qValue": "1",
						"foldChange": "0.9968"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".253624310111894",
						"qValue": "1",
						"foldChange": "0.3416"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".27488262547729",
						"qValue": "1",
						"foldChange": "0.2860"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".990601158026384",
						"qValue": "1",
						"foldChange": "0.8372"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".165996135393574",
						"qValue": ".11793939591935",
						"foldChange": "1.4121"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007800250054829",
						"qValue": ".008496374876443",
						"foldChange": "2.3023"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".180288960613811",
						"qValue": ".051576732491897",
						"foldChange": "3.8843"
					}
				]
			},
			{
				"metaboliteId": "0078dc0f-0000-0000-0000-000000000000",
				"chemicalId": "05f5ead2-0000-0000-0000-000000000000",
				"chemicalName": "hydantoin-5-propionate",
				"plotName": null,
				"compoundId": "00009e19-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01212",
				"inChiKey": "VWFWNXQAMGDPGG-UHFFFAOYAX",
				"kegg": "C05565",
				"mass": 172.048407,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 73,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".876233747511133",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".89052327841248",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".660816143189633",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".730291108113217",
						"qValue": "1",
						"foldChange": "1.1785"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".449404034254671",
						"qValue": "1",
						"foldChange": "1.5339"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".679340445340751",
						"qValue": "1",
						"foldChange": "1.1660"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".357481539768648",
						"qValue": ".951252030575746",
						"foldChange": "0.9779"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".157577410417137",
						"qValue": ".784201030789377",
						"foldChange": "0.9462"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".614772508279534",
						"qValue": ".967408831530889",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".896296616587479",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".87339092659837",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".97684763383237",
						"qValue": "1",
						"foldChange": "1.0113"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".389658890990839",
						"qValue": "1",
						"foldChange": "1.2380"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".487376542936365",
						"qValue": "1",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".901457247929399",
						"qValue": "1",
						"foldChange": "0.7758"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".829177287402271",
						"qValue": "1",
						"foldChange": "1.1458"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".650170741541717",
						"qValue": "1",
						"foldChange": "1.0374"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".797874311509467",
						"qValue": "1",
						"foldChange": "0.9054"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".796560477534811",
						"qValue": "1",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".817448970755068",
						"qValue": "1",
						"foldChange": "0.9048"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".627282975171184",
						"qValue": ".985830071188835",
						"foldChange": "0.9602"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".114407436232673",
						"qValue": ".090481676739354",
						"foldChange": "1.6004"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".040201062400862",
						"qValue": ".031312634590805",
						"foldChange": "2.3944"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".387391141599002",
						"qValue": ".091057262451545",
						"foldChange": "1.4764"
					}
				]
			},
			{
				"metaboliteId": "0078dc10-0000-0000-0000-000000000000",
				"chemicalId": "05f5e68f-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyhippurate",
				"plotName": null,
				"compoundId": "00008ac7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13678",
				"inChiKey": "ZMHLUFWWWPBTIU-UHFFFAOYAI",
				"kegg": null,
				"mass": 195.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4063,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".217408559404633",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".281384206163565",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".778847461163713",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".439729188811807",
						"qValue": "1",
						"foldChange": "1.4680"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".18989656975826",
						"qValue": "1",
						"foldChange": "1.5184"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".584469127612052",
						"qValue": "1",
						"foldChange": "1.2536"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".112075328038165",
						"qValue": ".678406740976754",
						"foldChange": "2.4552"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".23612458621993",
						"qValue": ".825038702902081",
						"foldChange": "1.6149"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".677157491077333",
						"qValue": ".976023509428735",
						"foldChange": "1.0703"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".892534402902172",
						"qValue": "1",
						"foldChange": "1.0006"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".881909197750718",
						"qValue": "1",
						"foldChange": "1.2778"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".776749320575946",
						"qValue": "1",
						"foldChange": "1.1747"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".151348875599713",
						"qValue": "1",
						"foldChange": "2.0484"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".144003382897991",
						"qValue": "1",
						"foldChange": "2.1070"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".912983927659454",
						"qValue": "1",
						"foldChange": "1.0286"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".067720142973307",
						"qValue": "1",
						"foldChange": "5.0412"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".318502245497999",
						"qValue": "1",
						"foldChange": "1.7910"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".435605047189859",
						"qValue": "1",
						"foldChange": "0.3553"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".182232176702618",
						"qValue": "1",
						"foldChange": "2.7279"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".383632661222934",
						"qValue": "1",
						"foldChange": "1.5468"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".68128096384988",
						"qValue": ".990272584968167",
						"foldChange": "0.5671"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000335988011783",
						"qValue": ".001183430523861",
						"foldChange": "4.2298"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001403103450316",
						"qValue": ".002698278646729",
						"foldChange": "8.5145"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002867725610908",
						"qValue": ".002195529646751",
						"foldChange": "2.5733"
					}
				]
			},
			{
				"metaboliteId": "0078dc11-0000-0000-0000-000000000000",
				"chemicalId": "05f5e484-0000-0000-0000-000000000000",
				"chemicalName": "iminodiacetate (IDA)",
				"plotName": null,
				"compoundId": "00005221-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11753",
				"inChiKey": "NBZBKCUXIYYUSX-UHFFFAOYAF",
				"kegg": "C19911",
				"mass": 133.037508,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5306,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".445162144949726",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".681227963420806",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".859778851073961",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".427837568724253",
						"qValue": "1",
						"foldChange": "1.2891"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".956314037099484",
						"qValue": "1",
						"foldChange": "1.0102"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".460137193999622",
						"qValue": "1",
						"foldChange": "3.1192"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".844592509560461",
						"qValue": "1",
						"foldChange": "2.1502"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".624262484284451",
						"qValue": ".928868848501546",
						"foldChange": "1.8791"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".493465201825498",
						"qValue": ".957839854315834",
						"foldChange": "1.9356"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".531383407533967",
						"qValue": "1",
						"foldChange": "0.8974"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".431196705921585",
						"qValue": "1",
						"foldChange": "0.9297"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".870907264446087",
						"qValue": "1",
						"foldChange": "1.1331"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".946346925300948",
						"qValue": "1",
						"foldChange": "1.0871"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".277633459533481",
						"qValue": "1",
						"foldChange": "1.5078"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".294808052586378",
						"qValue": "1",
						"foldChange": "1.3870"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".371342144601397",
						"qValue": "1",
						"foldChange": "1.1963"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".234793053395308",
						"qValue": "1",
						"foldChange": "1.2977"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".722345557736132",
						"qValue": "1",
						"foldChange": "1.0847"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".77978346262971",
						"qValue": "1",
						"foldChange": "1.0418"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".64198451847081",
						"qValue": "1",
						"foldChange": "1.4101"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".455746068179232",
						"qValue": ".985830071188835",
						"foldChange": "1.3535"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000178068382396",
						"qValue": ".000746288175798",
						"foldChange": "19.5551"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000338976548681",
						"qValue": ".001151023864247",
						"foldChange": "14.4482"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000092065763131",
						"qValue": ".000204229996389",
						"foldChange": "13.8681"
					}
				]
			},
			{
				"metaboliteId": "0078dc12-0000-0000-0000-000000000000",
				"chemicalId": "000001f0-0000-0000-0000-000000000000",
				"chemicalName": "glutathione, reduced (GSH)",
				"plotName": null,
				"compoundId": "0000084f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00125",
				"inChiKey": "RWSXRVCMGQZWBV-WDSKDSINBK",
				"kegg": "C00051",
				"mass": 307.083809,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc13-0000-0000-0000-000000000000",
				"chemicalId": "0000053e-0000-0000-0000-000000000000",
				"chemicalName": "3-methoxytyrosine",
				"plotName": null,
				"compoundId": "00002ef1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01434",
				"inChiKey": "PFDUUKDQEHURQC-ZETCQYMHBZ",
				"kegg": null,
				"mass": 211.084459,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 186,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".371996832158177",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".571647787408843",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".486644472025629",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".89984305400578",
						"qValue": "1",
						"foldChange": "0.9790"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".951461033506658",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".851888629780788",
						"qValue": "1",
						"foldChange": "1.0554"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".082091697278843",
						"qValue": ".618230392781378",
						"foldChange": "0.9598"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".052749841114131",
						"qValue": ".68015325436554",
						"foldChange": "0.9138"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".834394038512442",
						"qValue": "1",
						"foldChange": "0.9685"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".69896869412423",
						"qValue": "1",
						"foldChange": "1.1083"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".94409129848684",
						"qValue": "1",
						"foldChange": "1.0241"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".751456360772094",
						"qValue": "1",
						"foldChange": "0.8607"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".143104760914427",
						"qValue": "1",
						"foldChange": "1.9697"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".468719868660403",
						"qValue": "1",
						"foldChange": "0.2138"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".03256180327216",
						"qValue": "1",
						"foldChange": "0.1086"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".986999974751382",
						"qValue": "1",
						"foldChange": "0.9978"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".812087584496654",
						"qValue": "1",
						"foldChange": "0.9420"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".81983509147635",
						"qValue": "1",
						"foldChange": "0.9441"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".698925695680971",
						"qValue": "1",
						"foldChange": "0.8917"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".476257325682479",
						"qValue": "1",
						"foldChange": "0.7618"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".721811941780008",
						"qValue": ".997573179629986",
						"foldChange": "0.8543"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".311941712810662",
						"qValue": ".183122279991681",
						"foldChange": "0.9037"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".728454279754974",
						"qValue": ".272320393121461",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".844143129650846",
						"qValue": ".168384477658304",
						"foldChange": "1.0398"
					}
				]
			},
			{
				"metaboliteId": "0078dc14-0000-0000-0000-000000000000",
				"chemicalId": "05f5e528-0000-0000-0000-000000000000",
				"chemicalName": "glycolithocholate",
				"plotName": null,
				"compoundId": "00007ca8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00698",
				"inChiKey": "XBSQTYHEGZTYJE-OETIFKLTBJ",
				"kegg": "C15557",
				"mass": 433.31958,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3128,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".497341093758733",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".829567337764226",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".831971798465849",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".332992235725069",
						"qValue": "1",
						"foldChange": "1.7079"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".469101126456893",
						"qValue": "1",
						"foldChange": "1.5498"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".805007699003217",
						"qValue": "1",
						"foldChange": "1.0802"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".73357719610751",
						"qValue": "1",
						"foldChange": "1.8072"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".579185161757524",
						"qValue": ".928868848501546",
						"foldChange": "7.0337"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".372229306061446",
						"qValue": ".957839854315834",
						"foldChange": "3.4857"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".96033059303841",
						"qValue": "1",
						"foldChange": "1.3392"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".853337521272781",
						"qValue": "1",
						"foldChange": "1.2370"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".892499177403671",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".869040374829392",
						"qValue": "1",
						"foldChange": "1.9093"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".584184138488577",
						"qValue": "1",
						"foldChange": "0.7441"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".472049145878821",
						"qValue": "1",
						"foldChange": "0.3898"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".494216304173772",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".240017606411878",
						"qValue": "1",
						"foldChange": "0.3094"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".583316293149297",
						"qValue": "1",
						"foldChange": "0.3236"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".972607297586763",
						"qValue": "1",
						"foldChange": "1.5874"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".266075884460892",
						"qValue": "1",
						"foldChange": "0.4397"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".241460427435502",
						"qValue": ".985830071188835",
						"foldChange": "0.2770"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".454974045003326",
						"qValue": ".233643038757568",
						"foldChange": "1.8591"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".073739038274848",
						"qValue": ".04930903693404",
						"foldChange": "6.0869"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".125753826162203",
						"qValue": ".038732159388337",
						"foldChange": "2.6202"
					}
				]
			},
			{
				"metaboliteId": "0078dc15-0000-0000-0000-000000000000",
				"chemicalId": "05f5f61b-0000-0000-0000-000000000000",
				"chemicalName": "etiocholanolone glucuronide",
				"plotName": null,
				"compoundId": "0000b808-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04484",
				"inChiKey": null,
				"kegg": null,
				"mass": 466.256668,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3025,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".584846127848818",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".587815717746277",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".159471465022535",
						"qValue": ".892830373251164",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".173091856223018",
						"qValue": "1",
						"foldChange": "0.8001"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".453223718605851",
						"qValue": "1",
						"foldChange": "0.9335"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".533819206949821",
						"qValue": "1",
						"foldChange": "1.1873"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".034818666248457",
						"qValue": ".422966199115749",
						"foldChange": "7.4279"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".046504797605675",
						"qValue": ".678234398444917",
						"foldChange": "6.2336"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".898268382339308",
						"qValue": "1",
						"foldChange": "0.9893"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".691754476169897",
						"qValue": "1",
						"foldChange": "1.1383"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".571424783338875",
						"qValue": "1",
						"foldChange": "1.1963"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".864947721595013",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".176858607591742",
						"qValue": "1",
						"foldChange": "0.8093"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".914151575898164",
						"qValue": "1",
						"foldChange": "1.1976"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".156838541394545",
						"qValue": "1",
						"foldChange": "1.4797"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".407650108524255",
						"qValue": "1",
						"foldChange": "1.2175"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".235175280581084",
						"qValue": "1",
						"foldChange": "1.3429"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".673950157185126",
						"qValue": "1",
						"foldChange": "1.1030"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".723662833854571",
						"qValue": "1",
						"foldChange": "0.9614"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".355541610621544",
						"qValue": "1",
						"foldChange": "1.0973"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".543670598793476",
						"qValue": ".985830071188835",
						"foldChange": "1.1414"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".021167402804321",
						"qValue": ".026004026626802",
						"foldChange": "2.8045"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".023269225820217",
						"qValue": ".020533356992961",
						"foldChange": "1.9833"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".059187369562476",
						"qValue": ".021676318550479",
						"foldChange": "2.6910"
					}
				]
			},
			{
				"metaboliteId": "0078dc16-0000-0000-0000-000000000000",
				"chemicalId": "05f5f60d-0000-0000-0000-000000000000",
				"chemicalName": "ferulic acid 4-sulfate",
				"plotName": null,
				"compoundId": "0000b80a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29200",
				"inChiKey": "PZPATWACAAOHTJ-HWKANZROBD",
				"kegg": null,
				"mass": 274.014723,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4409,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".229341897808127",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".149806962589893",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".677162313763668",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".061007333782114",
						"qValue": "1",
						"foldChange": "4.5299"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".051716958433676",
						"qValue": "1",
						"foldChange": "4.9280"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".939188684388259",
						"qValue": "1",
						"foldChange": "1.3367"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".990116467826995",
						"qValue": "1",
						"foldChange": "3.9466"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".709977356396083",
						"qValue": ".950986052805043",
						"foldChange": "6.6853"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".719202313598663",
						"qValue": ".976023509428735",
						"foldChange": "8.4759"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".411312417052899",
						"qValue": "1",
						"foldChange": "1.8555"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".454706742459038",
						"qValue": "1",
						"foldChange": "1.6013"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".940441883363848",
						"qValue": "1",
						"foldChange": "1.1495"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".065280882027254",
						"qValue": "1",
						"foldChange": "3.3382"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".063125389396223",
						"qValue": "1",
						"foldChange": "2.1225"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".904003313412852",
						"qValue": "1",
						"foldChange": "0.6358"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".675477905272596",
						"qValue": "1",
						"foldChange": "1.7816"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".257326228037618",
						"qValue": "1",
						"foldChange": "4.4440"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".447466415530461",
						"qValue": "1",
						"foldChange": "2.4944"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".534499060481963",
						"qValue": "1",
						"foldChange": "2.5242"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".306880006196496",
						"qValue": "1",
						"foldChange": "3.7966"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".653571373972917",
						"qValue": ".985830071188835",
						"foldChange": "1.5041"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".031631064244407",
						"qValue": ".036255122858497",
						"foldChange": "15.4376"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".13855715647827",
						"qValue": ".078849326507585",
						"foldChange": "2.7881"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".030878869659105",
						"qValue": ".013203666766016",
						"foldChange": "4.2417"
					}
				]
			},
			{
				"metaboliteId": "0078dc17-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e7-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoyl-GPC (16:1)*",
				"plotName": null,
				"compoundId": "000081ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10383",
				"inChiKey": "LFUDDCMNKWEORN-KHPPLWFEBO",
				"kegg": null,
				"mass": 493.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2319,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".702984195970243",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".64976175245261",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".009694107000863",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".017644638701343",
						"qValue": "1",
						"foldChange": "1.2023"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".001169773420345",
						"qValue": ".497738590356736",
						"foldChange": "1.2905"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".324909083539081",
						"qValue": "1",
						"foldChange": "1.0827"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".572467882817171",
						"qValue": "1",
						"foldChange": "1.0045"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".9903404130557",
						"qValue": "1",
						"foldChange": "1.0128"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".564300557490445",
						"qValue": ".957839854315834",
						"foldChange": "1.0680"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".395949518103798",
						"qValue": "1",
						"foldChange": "0.9499"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".085981177204592",
						"qValue": "1",
						"foldChange": "0.8965"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".374469251411765",
						"qValue": "1",
						"foldChange": "0.8800"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".05036562549454",
						"qValue": "1",
						"foldChange": "1.2269"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".053598756861806",
						"qValue": "1",
						"foldChange": "1.2124"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".934369264016748",
						"qValue": "1",
						"foldChange": "0.9882"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".812131147505951",
						"qValue": "1",
						"foldChange": "1.0227"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".921156549290655",
						"qValue": "1",
						"foldChange": "0.9954"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".897437647959145",
						"qValue": "1",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".98055838272213",
						"qValue": "1",
						"foldChange": "1.0063"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".326575882028276",
						"qValue": "1",
						"foldChange": "0.8152"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".326344025055819",
						"qValue": ".985830071188835",
						"foldChange": "0.8101"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".210075760413686",
						"qValue": ".13855399915949",
						"foldChange": "1.3345"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002404161903777",
						"qValue": ".003782101557081",
						"foldChange": "1.4828"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000140751572108",
						"qValue": ".00027537914494",
						"foldChange": "1.9852"
					}
				]
			},
			{
				"metaboliteId": "0078dc18-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ef-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPC (16:0)",
				"plotName": null,
				"compoundId": "000084a3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10382",
				"inChiKey": "ASWBNKHCZGQVJV-UHFFFAOYAM",
				"kegg": null,
				"mass": 495.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2317,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".568512635623686",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".485267904437501",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".05984644468896",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".285132624971844",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".03195971513079",
						"qValue": "1",
						"foldChange": "1.1147"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".264773333649708",
						"qValue": "1",
						"foldChange": "1.0579"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".506891706108926",
						"qValue": "1",
						"foldChange": "1.0419"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".173202800438687",
						"qValue": ".789529107655781",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".478777059122822",
						"qValue": ".957839854315834",
						"foldChange": "1.0402"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".055639304674503",
						"qValue": ".821720595261736",
						"foldChange": "0.9126"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".078626579217413",
						"qValue": "1",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".870068279515101",
						"qValue": "1",
						"foldChange": "0.9615"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".779711188684912",
						"qValue": "1",
						"foldChange": "0.9657"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".078984321688217",
						"qValue": "1",
						"foldChange": "1.1401"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".040301605630971",
						"qValue": "1",
						"foldChange": "1.1807"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".577608899060869",
						"qValue": "1",
						"foldChange": "0.9624"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".943553784218298",
						"qValue": "1",
						"foldChange": "1.0108"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".640288382967351",
						"qValue": "1",
						"foldChange": "1.0504"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".403547285780996",
						"qValue": "1",
						"foldChange": "0.9323"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".527998289357344",
						"qValue": "1",
						"foldChange": "0.9051"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".869291835923214",
						"qValue": ".997573179629986",
						"foldChange": "0.9708"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".099349287284498",
						"qValue": ".081621871461309",
						"foldChange": "1.2121"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004312780325552",
						"qValue": ".005858388983354",
						"foldChange": "1.1822"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000178715028692",
						"qValue": ".000309461356268",
						"foldChange": "1.5324"
					}
				]
			},
			{
				"metaboliteId": "0078dc19-0000-0000-0000-000000000000",
				"chemicalId": "05f5e673-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPC (18:2)",
				"plotName": null,
				"compoundId": "00008673-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10386",
				"inChiKey": "SPJFYYJXNPEZDW-UTJQPWESBU",
				"kegg": "C04100",
				"mass": 519.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2331,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".559235616810093",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".610572767266006",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".069782787967591",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".241592023633028",
						"qValue": "1",
						"foldChange": "1.1578"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".01673346876246",
						"qValue": "1",
						"foldChange": "1.2743"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".202026121346142",
						"qValue": "1",
						"foldChange": "1.1365"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".298277610051764",
						"qValue": ".911265664999995",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".833886146416203",
						"qValue": ".998083137271714",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".404699934822814",
						"qValue": ".957839854315834",
						"foldChange": "1.1049"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".203531527823484",
						"qValue": "1",
						"foldChange": "0.9309"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".10266569242186",
						"qValue": "1",
						"foldChange": "0.8773"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".709544062703592",
						"qValue": "1",
						"foldChange": "0.9110"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".353432015132966",
						"qValue": "1",
						"foldChange": "1.0860"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".032974818914293",
						"qValue": "1",
						"foldChange": "1.2729"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".186893948476535",
						"qValue": "1",
						"foldChange": "1.1721"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".465999685860675",
						"qValue": "1",
						"foldChange": "0.9114"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".732482167194828",
						"qValue": "1",
						"foldChange": "1.0387"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".292511499416696",
						"qValue": "1",
						"foldChange": "1.1397"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".270430356127638",
						"qValue": "1",
						"foldChange": "0.8785"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".38295924353757",
						"qValue": "1",
						"foldChange": "0.8456"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".859683961131134",
						"qValue": ".997573179629986",
						"foldChange": "0.9625"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".671207808652977",
						"qValue": ".313318826464865",
						"foldChange": "1.1110"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".679180500393308",
						"qValue": ".25961235604587",
						"foldChange": "0.9414"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".0014580044039",
						"qValue": ".001347195405918",
						"foldChange": "1.5541"
					}
				]
			},
			{
				"metaboliteId": "0078dc1a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e67d-0000-0000-0000-000000000000",
				"chemicalName": "1-methylxanthine",
				"plotName": null,
				"compoundId": "00008655-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10738",
				"inChiKey": "MVOYJPOZRLFTCP-UHFFFAOYAI",
				"kegg": "C16358",
				"mass": 166.049075,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4165,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".128595002360279",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".539407611031888",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".913332470271014",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".925033167714895",
						"qValue": "1",
						"foldChange": "1.9359"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".393991069178784",
						"qValue": "1",
						"foldChange": "1.1455"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".447696606793858",
						"qValue": "1",
						"foldChange": "1.6874"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".665919648918817",
						"qValue": "1",
						"foldChange": "1.0523"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".962836224275017",
						"qValue": "1",
						"foldChange": "1.2432"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".700028476499849",
						"qValue": ".976023509428735",
						"foldChange": "1.3658"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".684835692010895",
						"qValue": "1",
						"foldChange": "1.1426"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".331715179454771",
						"qValue": "1",
						"foldChange": "0.7853"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".569831958905605",
						"qValue": "1",
						"foldChange": "0.9555"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".832022831858176",
						"qValue": "1",
						"foldChange": "0.8429"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".106375946143672",
						"qValue": "1",
						"foldChange": "1.5916"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".064873922227267",
						"qValue": "1",
						"foldChange": "1.8882"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".689933877306904",
						"qValue": "1",
						"foldChange": "0.6450"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".151427363540435",
						"qValue": "1",
						"foldChange": "1.4924"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".066516657752203",
						"qValue": ".822195137696456",
						"foldChange": "2.3139"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".788274069040658",
						"qValue": "1",
						"foldChange": "1.4228"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".128720174216491",
						"qValue": "1",
						"foldChange": "1.4563"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".191827256723945",
						"qValue": ".985830071188835",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000179461085",
						"qValue": ".000015537435463",
						"foldChange": "11.6743"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001483055147297",
						"qValue": ".002742250837077",
						"foldChange": "10.4379"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002744121069191",
						"qValue": ".002133109795806",
						"foldChange": "7.5194"
					}
				]
			},
			{
				"metaboliteId": "0078dc1b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e941-0000-0000-0000-000000000000",
				"chemicalName": "cysteine sulfinic acid",
				"plotName": null,
				"compoundId": "00009243-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00996",
				"inChiKey": "ADVPTQAUNPRNPO-UHFFFAOYAU",
				"kegg": "C00606",
				"mass": 153.009578,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 403,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".992935729995729",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".299704269836959",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".577550262358429",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".683631352305749",
						"qValue": "1",
						"foldChange": "1.3353"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".584193089004675",
						"qValue": "1",
						"foldChange": "1.2135"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".88846298945734",
						"qValue": "1",
						"foldChange": "1.4229"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".837901212170077",
						"qValue": "1",
						"foldChange": "1.1616"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".250645758962587",
						"qValue": ".825038702902081",
						"foldChange": "0.8641"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".177519106317854",
						"qValue": ".957839854315834",
						"foldChange": "0.9719"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".118591731144775",
						"qValue": "1",
						"foldChange": "1.5227"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".704553709462138",
						"qValue": "1",
						"foldChange": "1.4377"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".233078153579036",
						"qValue": "1",
						"foldChange": "0.8365"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".608826974601395",
						"qValue": "1",
						"foldChange": "1.0728"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".810469547784577",
						"qValue": "1",
						"foldChange": "1.0539"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".459364702248918",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".714631045051338",
						"qValue": "1",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".560662722149298",
						"qValue": "1",
						"foldChange": "1.1072"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".808264384991426",
						"qValue": "1",
						"foldChange": "1.0731"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".53874908529207",
						"qValue": "1",
						"foldChange": "0.9267"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".738276968700133",
						"qValue": "1",
						"foldChange": "0.9375"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".79941173160768",
						"qValue": ".997573179629986",
						"foldChange": "1.0116"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".022558453421016",
						"qValue": ".027358577528654",
						"foldChange": "2.6517"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".023963766584986",
						"qValue": ".020947370000971",
						"foldChange": "2.1575"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00116089863746",
						"qValue": ".001142136931488",
						"foldChange": "2.4516"
					}
				]
			},
			{
				"metaboliteId": "0078dc1c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e739-0000-0000-0000-000000000000",
				"chemicalName": "glutarylcarnitine (C5-DC)",
				"plotName": null,
				"compoundId": "0000ae78-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13130",
				"inChiKey": "NXJAXUYOQLTISD-UHFFFAOYAA",
				"kegg": null,
				"mass": 275.136887,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".614818565450899",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".977600666801362",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".949123034221145",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".476912934335156",
						"qValue": "1",
						"foldChange": "0.9606"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".97284467649311",
						"qValue": "1",
						"foldChange": "1.0086"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".49809307074055",
						"qValue": "1",
						"foldChange": "1.0907"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".840891480763381",
						"qValue": "1",
						"foldChange": "1.0504"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".98390438471742",
						"qValue": "1",
						"foldChange": "1.0434"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".825159836742347",
						"qValue": "1",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".852535206005121",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".970037278884685",
						"qValue": "1",
						"foldChange": "1.0344"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".882074879047879",
						"qValue": "1",
						"foldChange": "0.9569"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".296160752794058",
						"qValue": "1",
						"foldChange": "0.8949"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".56524037675056",
						"qValue": "1",
						"foldChange": "0.9550"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".668400751469874",
						"qValue": "1",
						"foldChange": "1.0672"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".608719022750599",
						"qValue": "1",
						"foldChange": "0.9579"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".950209129768931",
						"qValue": "1",
						"foldChange": "1.3697"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".665591176219526",
						"qValue": "1",
						"foldChange": "1.4300"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".300205087542791",
						"qValue": "1",
						"foldChange": "0.8665"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".593602386570167",
						"qValue": "1",
						"foldChange": "1.0784"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".643855170394444",
						"qValue": ".985830071188835",
						"foldChange": "1.2445"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002133348031227",
						"qValue": ".004772509861707",
						"foldChange": "2.3063"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".0000209957099",
						"qValue": ".000266117226655",
						"foldChange": "2.1047"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000040618653595",
						"qValue": ".000133275539494",
						"foldChange": "2.0568"
					}
				]
			},
			{
				"metaboliteId": "0078dc1d-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea65-0000-0000-0000-000000000000",
				"chemicalName": "metformin",
				"plotName": null,
				"compoundId": "000095a2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01921",
				"inChiKey": "XZWYZXLIPXDOLR-UHFFFAOYAB",
				"kegg": "C07151",
				"mass": 129.101445,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Metabolic",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5017,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".412037755816829",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".423454033054126",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".389261543814083",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".039694386682053",
						"qValue": "1",
						"foldChange": "23.4910"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".039694386682053",
						"qValue": "1",
						"foldChange": "23.4910"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".875113627964864",
						"qValue": "1",
						"foldChange": "0.9610"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".843054717706829",
						"qValue": "1",
						"foldChange": "0.9530"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".96742250692469",
						"qValue": "1",
						"foldChange": "0.9897"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".217524998159926",
						"qValue": "1",
						"foldChange": "78.2257"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".206778740144467",
						"qValue": "1",
						"foldChange": "78.2257"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".24488272358174",
						"qValue": "1",
						"foldChange": "48.1690"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".233806608306369",
						"qValue": "1",
						"foldChange": "48.1690"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".335472103711694",
						"qValue": "1",
						"foldChange": "0.0426"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".811095953669028",
						"qValue": "1",
						"foldChange": "1.8639"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".241241408479561",
						"qValue": ".985830071188835",
						"foldChange": "43.7854"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "43.7854"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "23.4910"
					}
				]
			},
			{
				"metaboliteId": "0078dc1e-0000-0000-0000-000000000000",
				"chemicalId": "0000048a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylneuraminate",
				"plotName": null,
				"compoundId": "00007e79-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00230",
				"inChiKey": "KBGAYAKRZNYFFG-BOHATCBPBL",
				"kegg": "C00270",
				"mass": 309.105981,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1263,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".423086322493455",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".84219861264956",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".889097315192464",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".817007061807044",
						"qValue": "1",
						"foldChange": "1.1674"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".578700472448905",
						"qValue": "1",
						"foldChange": "1.0117"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".432396859786698",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".561978622274841",
						"qValue": "1",
						"foldChange": "1.1698"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".771761874178507",
						"qValue": ".974435244697195",
						"foldChange": "1.3442"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".771355096622756",
						"qValue": ".98622264532067",
						"foldChange": "1.0998"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".827692262331477",
						"qValue": "1",
						"foldChange": "1.0604"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".493599222134973",
						"qValue": "1",
						"foldChange": "1.2132"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".639763871745171",
						"qValue": "1",
						"foldChange": "1.1141"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".781869027344306",
						"qValue": "1",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".508389366836456",
						"qValue": "1",
						"foldChange": "0.8270"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".345359798506982",
						"qValue": "1",
						"foldChange": "0.8190"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".574257041290056",
						"qValue": "1",
						"foldChange": "1.1522"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".508099526695012",
						"qValue": "1",
						"foldChange": "0.8988"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".223651801911358",
						"qValue": "1",
						"foldChange": "0.7801"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".309844482141332",
						"qValue": "1",
						"foldChange": "1.4587"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".673194662404539",
						"qValue": "1",
						"foldChange": "1.0611"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".579126248989551",
						"qValue": ".985830071188835",
						"foldChange": "0.7274"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002393012866454",
						"qValue": ".005212526599843",
						"foldChange": "1.8290"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".165880899724767",
						"qValue": ".090740323989308",
						"foldChange": "1.7004"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".114749956219941",
						"qValue": ".036245638267018",
						"foldChange": "1.4088"
					}
				]
			},
			{
				"metaboliteId": "0078dc1f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e544-0000-0000-0000-000000000000",
				"chemicalName": "trigonelline (N'-methylnicotinate)",
				"plotName": null,
				"compoundId": "00007e91-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00875",
				"inChiKey": "WWNNZCOKKKDOPX-UHFFFAOYAV",
				"kegg": "C01004",
				"mass": 137.047678,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3933,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".440312734811846",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".779583260866251",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".549119256664838",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".621455623205347",
						"qValue": "1",
						"foldChange": "1.4569"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".762187936657945",
						"qValue": "1",
						"foldChange": "1.2530"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".848031961260142",
						"qValue": "1",
						"foldChange": "1.3204"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".214726353348362",
						"qValue": ".830600575906619",
						"foldChange": "1.6947"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".077080361543629",
						"qValue": ".687896186587066",
						"foldChange": "2.1559"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".585284047290521",
						"qValue": ".957839854315834",
						"foldChange": "1.3443"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".908339857296556",
						"qValue": "1",
						"foldChange": "1.2809"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".847819868026654",
						"qValue": "1",
						"foldChange": "1.0171"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".758899053648751",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".273021123137698",
						"qValue": "1",
						"foldChange": "0.6051"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".535453396701951",
						"qValue": "1",
						"foldChange": "1.2080"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".093707265444899",
						"qValue": "1",
						"foldChange": "1.9965"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".956369831685058",
						"qValue": "1",
						"foldChange": "0.7789"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".327414716473244",
						"qValue": "1",
						"foldChange": "1.6491"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".2914815361162",
						"qValue": "1",
						"foldChange": "2.1174"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".881268410618793",
						"qValue": "1",
						"foldChange": "0.9697"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".497897924315486",
						"qValue": "1",
						"foldChange": "1.5259"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".581911194775779",
						"qValue": ".985830071188835",
						"foldChange": "1.5736"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000986238338189",
						"qValue": ".002591541538297",
						"foldChange": "9.0951"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007874688778627",
						"qValue": ".00851096488988",
						"foldChange": "1.9649"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000030707245119",
						"qValue": ".000120414283184",
						"foldChange": "9.0031"
					}
				]
			},
			{
				"metaboliteId": "0078dc20-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60f-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyltryptophan",
				"plotName": null,
				"compoundId": "0000849b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29160",
				"inChiKey": "CATMPQFFVNKDEY-AAEUAGOBBU",
				"kegg": null,
				"mass": 333.132471,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 561,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".803278811160082",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".235180710330135",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".975204103589415",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".998241711490373",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".587106070481372",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".588617211855247",
						"qValue": "1",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".412279905179455",
						"qValue": ".988310420585117",
						"foldChange": "1.0932"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".600448833425362",
						"qValue": ".928868848501546",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".181970120587782",
						"qValue": ".957839854315834",
						"foldChange": "0.9574"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".778463513610684",
						"qValue": "1",
						"foldChange": "1.0479"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".432858234721994",
						"qValue": "1",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".288250552111562",
						"qValue": "1",
						"foldChange": "0.8487"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".739179270449779",
						"qValue": "1",
						"foldChange": "0.9085"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".577365729158745",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".803268722940576",
						"qValue": "1",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".874708573019582",
						"qValue": "1",
						"foldChange": "0.9996"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".704195662928348",
						"qValue": "1",
						"foldChange": "0.9206"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".588477830218662",
						"qValue": "1",
						"foldChange": "0.9210"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".761149119853479",
						"qValue": "1",
						"foldChange": "0.9058"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".466443514202105",
						"qValue": "1",
						"foldChange": "0.7850"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".65031062268897",
						"qValue": ".985830071188835",
						"foldChange": "0.8666"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".470658462813027",
						"qValue": ".239003969947275",
						"foldChange": "1.2058"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002482880530339",
						"qValue": ".003867834636006",
						"foldChange": "1.4325"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".060460796835968",
						"qValue": ".021942754596347",
						"foldChange": "1.4744"
					}
				]
			},
			{
				"metaboliteId": "0078dc21-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9ea-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylkynurenine (2)",
				"plotName": null,
				"compoundId": "0000be75-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 250.095357,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 242,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".441862501750639",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".109953508925621",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".472001861053206",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".068263783496665",
						"qValue": "1",
						"foldChange": "0.7309"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".591187186454256",
						"qValue": "1",
						"foldChange": "1.3498"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".020028312114368",
						"qValue": "1",
						"foldChange": "3.1156"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".661590374376829",
						"qValue": "1",
						"foldChange": "1.4492"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".636389159810972",
						"qValue": ".928868848501546",
						"foldChange": "1.2362"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".971960682684191",
						"qValue": "1",
						"foldChange": "2.3706"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".249216911190057",
						"qValue": "1",
						"foldChange": "0.8846"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".480164380268598",
						"qValue": "1",
						"foldChange": "1.0656"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".651297247146627",
						"qValue": "1",
						"foldChange": "1.2079"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".513526164810659",
						"qValue": "1",
						"foldChange": "0.6351"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".493694338697243",
						"qValue": "1",
						"foldChange": "1.2532"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".185626218273421",
						"qValue": "1",
						"foldChange": "1.9734"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".774022268123582",
						"qValue": "1",
						"foldChange": "0.9230"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".286536641020358",
						"qValue": "1",
						"foldChange": "1.0977"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".413548003073416",
						"qValue": "1",
						"foldChange": "1.1893"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".197733261703202",
						"qValue": "1",
						"foldChange": "0.6136"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".91152807224373",
						"qValue": "1",
						"foldChange": "0.9578"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".25535601995647",
						"qValue": ".985830071188835",
						"foldChange": "1.5609"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011372488616732",
						"qValue": ".016960918794009",
						"foldChange": "6.3321"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".029939340736635",
						"qValue": ".024846662549727",
						"foldChange": "4.0567"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002392940246494",
						"qValue": ".001957598637457",
						"foldChange": "6.6109"
					}
				]
			},
			{
				"metaboliteId": "0078dc22-0000-0000-0000-000000000000",
				"chemicalId": "05f5e669-0000-0000-0000-000000000000",
				"chemicalName": "metoprolol acid metabolite*",
				"plotName": null,
				"compoundId": "0000853d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "PUQIRTNPJRFRCZ-UHFFFAOYAZ",
				"kegg": null,
				"mass": 267.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4962,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".563626805962097",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".447595244201073",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".610528518745769",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".770301144580002",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".952797996099757",
						"qValue": "1",
						"foldChange": "0.9969"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".81587717824931",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".996517325984351",
						"qValue": "1",
						"foldChange": "1.0492"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".091740068244046",
						"qValue": "1",
						"foldChange": "1.1978"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".090939435488785",
						"qValue": ".823292123414428",
						"foldChange": "1.1130"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".370671032058007",
						"qValue": "1",
						"foldChange": "2.0169"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".411197561825794",
						"qValue": "1",
						"foldChange": "1.4193"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".978786329882339",
						"qValue": "1",
						"foldChange": "0.7037"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".402014602663062",
						"qValue": "1",
						"foldChange": "1.8552"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".411713191469362",
						"qValue": "1",
						"foldChange": "1.6096"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".976769565495423",
						"qValue": "1",
						"foldChange": "0.8676"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".376889474048311",
						"qValue": "1",
						"foldChange": "1.9820"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".24063908759768",
						"qValue": "1",
						"foldChange": "2.0193"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".722109539057302",
						"qValue": ".997573179629986",
						"foldChange": "1.0188"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".266440537636296",
						"qValue": ".164582005514135",
						"foldChange": "2.0193"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.9820"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc23-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee6a-0000-0000-0000-000000000000",
				"chemicalName": "imidazole propionate",
				"plotName": null,
				"compoundId": "00009f1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02271",
				"inChiKey": "ZCKYOWGFRHAZIQ-UHFFFAOYAI",
				"kegg": null,
				"mass": 140.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 76,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".35852838661475",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".989009937833756",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".510221943170621",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".544443769682019",
						"qValue": "1",
						"foldChange": "0.9854"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".440006465313427",
						"qValue": "1",
						"foldChange": "0.9341"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".867130588628718",
						"qValue": "1",
						"foldChange": "1.0601"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".184244001083514",
						"qValue": ".797133099236872",
						"foldChange": "1.4581"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".288794146567546",
						"qValue": ".825038702902081",
						"foldChange": "1.3540"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".784841602660361",
						"qValue": ".991621675660644",
						"foldChange": "1.0390"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".457790817026553",
						"qValue": "1",
						"foldChange": "0.9090"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".920287830712272",
						"qValue": "1",
						"foldChange": "1.0030"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".520114825947955",
						"qValue": "1",
						"foldChange": "1.0376"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".994211282313799",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".391222192764048",
						"qValue": "1",
						"foldChange": "0.6717"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".376178787699954",
						"qValue": "1",
						"foldChange": "0.6565"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".396240854855716",
						"qValue": "1",
						"foldChange": "1.4864"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".350492028517733",
						"qValue": "1",
						"foldChange": "0.6783"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".08084521584881",
						"qValue": ".834952786556829",
						"foldChange": "0.4563"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".419260730942022",
						"qValue": "1",
						"foldChange": "1.8128"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".564397878828553",
						"qValue": "1",
						"foldChange": "0.7471"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".174470775747696",
						"qValue": ".985830071188835",
						"foldChange": "0.4121"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".144081370464243",
						"qValue": ".106720326702336",
						"foldChange": "1.5809"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002016639798919",
						"qValue": ".003327413202008",
						"foldChange": "4.7738"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000428563939967",
						"qValue": ".000574189684068",
						"foldChange": "2.8333"
					}
				]
			},
			{
				"metaboliteId": "0078dc24-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee91-0000-0000-0000-000000000000",
				"chemicalName": "alliin",
				"plotName": null,
				"compoundId": "0000a216-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33592",
				"inChiKey": "XUHLIQGRKRUKPH-ITZCMCNPBT",
				"kegg": "C08265",
				"mass": 177.045964,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".060115048260598",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".010839163180628",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".896360525344068",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".414458600367384",
						"qValue": "1",
						"foldChange": "7.8305"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".306077008559011",
						"qValue": "1",
						"foldChange": "4.4769"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".833541392410349",
						"qValue": "1",
						"foldChange": "1.9705"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".042363280969963",
						"qValue": ".456343697537199",
						"foldChange": "4.8122"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".017187304317083",
						"qValue": ".585055838953495",
						"foldChange": "6.4063"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".703525832936179",
						"qValue": ".976023509428735",
						"foldChange": "1.4942"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".179681967806046",
						"qValue": "1",
						"foldChange": "3.0405"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".103799746607888",
						"qValue": "1",
						"foldChange": "3.0476"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".768052681742105",
						"qValue": "1",
						"foldChange": "2.5802"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".277210931003077",
						"qValue": "1",
						"foldChange": "1.2949"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".800152299183439",
						"qValue": "1",
						"foldChange": "0.7742"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".425924602934905",
						"qValue": "1",
						"foldChange": "0.5979"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".040589890035939",
						"qValue": "1",
						"foldChange": "2.4159"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".450005630202502",
						"qValue": "1",
						"foldChange": "2.1804"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".216946333749609",
						"qValue": "1",
						"foldChange": "0.9025"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".029921971441426",
						"qValue": "1",
						"foldChange": "3.2234"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".401709639011228",
						"qValue": "1",
						"foldChange": "1.2277"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".202548614467011",
						"qValue": ".985830071188835",
						"foldChange": "0.3809"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".220353684721329",
						"qValue": ".143177969939591",
						"foldChange": "1.8891"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000020785684087",
						"qValue": ".000266117226655",
						"foldChange": "11.4883"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".287354748165503",
						"qValue": ".073566439880271",
						"foldChange": "1.0376"
					}
				]
			},
			{
				"metaboliteId": "0078dc25-0000-0000-0000-000000000000",
				"chemicalId": "00000468-0000-0000-0000-000000000000",
				"chemicalName": "2-aminobutyrate",
				"plotName": null,
				"compoundId": "0000a586-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00650",
				"inChiKey": "QWCKQJZIFLGMSD-GSVOUGTGBL",
				"kegg": "C02261",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 500,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".305393332554902",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".794666082976606",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".337680380157399",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".05895939657294",
						"qValue": "1",
						"foldChange": "0.8942"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".333160956364415",
						"qValue": "1",
						"foldChange": "0.9535"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".34343283136588",
						"qValue": "1",
						"foldChange": "1.0968"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".913773125591738",
						"qValue": "1",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".487267794450139",
						"qValue": ".902562957068497",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".422555648251391",
						"qValue": ".957839854315834",
						"foldChange": "0.9671"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".401776587583819",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".608191938665553",
						"qValue": "1",
						"foldChange": "1.0455"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".742905198252216",
						"qValue": "1",
						"foldChange": "0.9523"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".110925057339036",
						"qValue": "1",
						"foldChange": "0.8580"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".436079718433762",
						"qValue": "1",
						"foldChange": "0.9040"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".444438932408965",
						"qValue": "1",
						"foldChange": "1.0537"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".75386808810482",
						"qValue": "1",
						"foldChange": "0.9734"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".353129409434649",
						"qValue": "1",
						"foldChange": "1.0513"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".2119677191437",
						"qValue": "1",
						"foldChange": "1.0801"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".161126010801785",
						"qValue": "1",
						"foldChange": "0.8643"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".88714523900823",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".134021201861495",
						"qValue": ".985830071188835",
						"foldChange": "1.0896"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".394838517024459",
						"qValue": ".212564744354685",
						"foldChange": "0.9065"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".248557027133438",
						"qValue": ".122238522848194",
						"foldChange": "0.8801"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".377845536289107",
						"qValue": ".089038698811059",
						"foldChange": "0.8974"
					}
				]
			},
			{
				"metaboliteId": "0078dc26-0000-0000-0000-000000000000",
				"chemicalId": "000005f6-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPE (16:0/18:1)",
				"plotName": null,
				"compoundId": "00004b3f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05320",
				"inChiKey": "FHQVHHIBKUMWTI-OTMQOFQLBI",
				"kegg": null,
				"mass": 717.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2143,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".668409394399592",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".817216994478889",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".556712485506214",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".348194327357829",
						"qValue": "1",
						"foldChange": "1.3040"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".611906336193057",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".151334701510653",
						"qValue": "1",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".584840721322774",
						"qValue": "1",
						"foldChange": "1.1286"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".282030870915089",
						"qValue": ".825038702902081",
						"foldChange": "1.2161"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".593096605460109",
						"qValue": ".962532817488692",
						"foldChange": "1.1330"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".733445643090396",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".985874629707577",
						"qValue": "1",
						"foldChange": "1.0259"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".746792287057098",
						"qValue": "1",
						"foldChange": "1.0130"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".728147164609933",
						"qValue": "1",
						"foldChange": "1.1326"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".954629777226832",
						"qValue": "1",
						"foldChange": "0.9120"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".692964795277786",
						"qValue": "1",
						"foldChange": "0.8053"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".919619980263254",
						"qValue": "1",
						"foldChange": "0.8213"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".439528152033879",
						"qValue": "1",
						"foldChange": "0.6467"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".374505940079678",
						"qValue": "1",
						"foldChange": "0.7874"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".218479088989199",
						"qValue": "1",
						"foldChange": "1.3026"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".831394090914343",
						"qValue": "1",
						"foldChange": "0.9289"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".327233998685729",
						"qValue": ".985830071188835",
						"foldChange": "0.7131"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".041415708792303",
						"qValue": ".043809429656789",
						"foldChange": "1.5607"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00114988921993",
						"qValue": ".002340456326773",
						"foldChange": "2.3669"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004834164001515",
						"qValue": ".003159419385561",
						"foldChange": "2.1177"
					}
				]
			},
			{
				"metaboliteId": "0078dc27-0000-0000-0000-000000000000",
				"chemicalId": "05f5e391-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dipalmitoyl-GPC (16:0/16:0)",
				"plotName": null,
				"compoundId": "00004aba-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00564",
				"inChiKey": "KILNVBDSWZSGLL-KXQOOQHDBV",
				"kegg": null,
				"mass": 733.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2019,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".682608335677885",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".858322555616041",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".606757789778997",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".678837389052639",
						"qValue": "1",
						"foldChange": "0.9992"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".45553354156098",
						"qValue": "1",
						"foldChange": "0.9661"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".738537137034228",
						"qValue": "1",
						"foldChange": "1.0022"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".870437085463468",
						"qValue": "1",
						"foldChange": "1.0202"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".320732741169215",
						"qValue": ".829615692203654",
						"foldChange": "1.0639"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".405432510235202",
						"qValue": ".957839854315834",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".718875061369284",
						"qValue": "1",
						"foldChange": "0.9818"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".287333347297248",
						"qValue": "1",
						"foldChange": "0.9567"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".478777194168004",
						"qValue": "1",
						"foldChange": "0.9480"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".498192781856423",
						"qValue": "1",
						"foldChange": "0.9367"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".529702718342458",
						"qValue": "1",
						"foldChange": "1.0325"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".196436894482036",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".800713291471064",
						"qValue": "1",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".514888064153282",
						"qValue": "1",
						"foldChange": "1.0375"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".363165324167418",
						"qValue": "1",
						"foldChange": "1.0641"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".555127423631452",
						"qValue": "1",
						"foldChange": "1.0432"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".722261677851914",
						"qValue": "1",
						"foldChange": "1.0138"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".835065999323885",
						"qValue": ".997573179629986",
						"foldChange": "0.9718"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013849940696587",
						"qValue": ".019166391983209",
						"foldChange": "1.3124"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".016343178838433",
						"qValue": ".015396071814943",
						"foldChange": "1.2115"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00351334256348",
						"qValue": ".002544418404861",
						"foldChange": "1.2302"
					}
				]
			},
			{
				"metaboliteId": "0078dc28-0000-0000-0000-000000000000",
				"chemicalId": "05f6042a-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-arachidonoyl-GPE (P-16:0/20:4)*",
				"plotName": null,
				"compoundId": "0000cdc1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11352",
				"inChiKey": "UUYSKERSKRMYME-MAIXUNBTBG",
				"kegg": null,
				"mass": 723.52029,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2507,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".98361186374401",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".367067379195441",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".794683833889474",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".677198941964901",
						"qValue": "1",
						"foldChange": "1.0554"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".457574504404671",
						"qValue": "1",
						"foldChange": "0.9558"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".742796757677453",
						"qValue": "1",
						"foldChange": "1.0521"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".537096103298484",
						"qValue": "1",
						"foldChange": "1.0106"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".528587637526594",
						"qValue": ".910963185640849",
						"foldChange": "0.9671"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".989601527915129",
						"qValue": "1",
						"foldChange": "1.0892"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".171101305339222",
						"qValue": "1",
						"foldChange": "0.8512"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".970825387674504",
						"qValue": "1",
						"foldChange": "1.0320"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".182487109737034",
						"qValue": ".989315025499063",
						"foldChange": "1.1795"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".949516728158701",
						"qValue": "1",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".995822112000344",
						"qValue": "1",
						"foldChange": "0.9035"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".946826798033801",
						"qValue": "1",
						"foldChange": "0.9409"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".948228392287549",
						"qValue": "1",
						"foldChange": "0.9906"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".452330981068835",
						"qValue": "1",
						"foldChange": "0.8057"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".479333667538596",
						"qValue": "1",
						"foldChange": "0.8133"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".862375486311583",
						"qValue": "1",
						"foldChange": "1.0466"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".618629130749786",
						"qValue": "1",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".732029834125991",
						"qValue": ".997573179629986",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".387080911866227",
						"qValue": ".210096471647455",
						"foldChange": "1.1506"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".909185573151767",
						"qValue": ".315720001838867",
						"foldChange": "0.9622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".451556985068817",
						"qValue": ".102252802939944",
						"foldChange": "1.0944"
					}
				]
			},
			{
				"metaboliteId": "0078dc29-0000-0000-0000-000000000000",
				"chemicalId": "05f5f994-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/20:1, d18:2/20:0)*",
				"plotName": null,
				"compoundId": "0000bd6b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 756.614525,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2810,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".35168256777853",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".829866062378664",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".161639884797581",
						"qValue": ".894199496328765",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".860258835330406",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".181405782218969",
						"qValue": "1",
						"foldChange": "1.0644"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".244158454990041",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".398388141995247",
						"qValue": ".974219278269986",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".831321056140356",
						"qValue": ".997819772602882",
						"foldChange": "1.0225"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".291633560046202",
						"qValue": ".957839854315834",
						"foldChange": "1.0516"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".1787165505974",
						"qValue": "1",
						"foldChange": "1.0693"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".600179836473549",
						"qValue": "1",
						"foldChange": "0.9889"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".064487701413091",
						"qValue": ".727024127920748",
						"foldChange": "0.9085"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".492390022163829",
						"qValue": "1",
						"foldChange": "0.9065"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".676998229442372",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".278238556232206",
						"qValue": "1",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".271136470938411",
						"qValue": "1",
						"foldChange": "0.9062"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".354702302927624",
						"qValue": "1",
						"foldChange": "1.1604"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".049200895120934",
						"qValue": ".822195137696456",
						"foldChange": "1.2806"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".24199399663802",
						"qValue": "1",
						"foldChange": "0.8922"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".719627237819852",
						"qValue": "1",
						"foldChange": "0.9783"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".438732811753473",
						"qValue": ".985830071188835",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".608132939560981",
						"qValue": ".290834600193072",
						"foldChange": "1.0431"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".788151305767717",
						"qValue": ".286536097459722",
						"foldChange": "0.9904"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".394929515792701",
						"qValue": ".092612748734229",
						"foldChange": "1.0906"
					}
				]
			},
			{
				"metaboliteId": "0078dc2a-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9aa-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d17:1/16:0, d18:1/15:0, d16:1/17:0)*",
				"plotName": null,
				"compoundId": "0000ccd1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LQINJRUGTUOHGS-YPDYIYJKBT",
				"kegg": null,
				"mass": 688.551925,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2803,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".443828476466999",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".770101318905877",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".190496507180046",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".69307500883549",
						"qValue": "1",
						"foldChange": "1.0382"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".376142355435784",
						"qValue": "1",
						"foldChange": "1.0652"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".621983051929452",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".336567315083189",
						"qValue": ".951252030575746",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".762875518763631",
						"qValue": ".970414150176159",
						"foldChange": "0.9928"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".507797742423467",
						"qValue": ".957839854315834",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".163444857354238",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".45083291733432",
						"qValue": "1",
						"foldChange": "0.9712"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".034548084655464",
						"qValue": ".614826369149758",
						"foldChange": "0.8584"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".315284862340234",
						"qValue": "1",
						"foldChange": "1.1464"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".211961349664451",
						"qValue": "1",
						"foldChange": "1.1574"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".754246800781784",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".762951038444157",
						"qValue": "1",
						"foldChange": "1.0405"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".074582666626873",
						"qValue": "1",
						"foldChange": "1.2414"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".121254154925751",
						"qValue": ".929212408184793",
						"foldChange": "1.1931"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".707604073988854",
						"qValue": "1",
						"foldChange": "1.0416"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".691826887292601",
						"qValue": "1",
						"foldChange": "1.0350"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".96584375550653",
						"qValue": "1",
						"foldChange": "0.9937"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".201564148960863",
						"qValue": ".134548373216769",
						"foldChange": "0.8845"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".95285082034406",
						"qValue": ".326411621942158",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".425986135383916",
						"qValue": ".097700874725452",
						"foldChange": "1.0932"
					}
				]
			},
			{
				"metaboliteId": "0078dc2b-0000-0000-0000-000000000000",
				"chemicalId": "05f60530-0000-0000-0000-000000000000",
				"chemicalName": "glycochenodeoxycholate glucuronide (1)",
				"plotName": null,
				"compoundId": "0000cef7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 625.346211,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3106,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".900807578906619",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".175021471172198",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".483333377543168",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".409383466683793",
						"qValue": "1",
						"foldChange": "0.8878"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".521967905762114",
						"qValue": "1",
						"foldChange": "1.3196"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".146124750026227",
						"qValue": "1",
						"foldChange": "1.5285"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".058244887305974",
						"qValue": ".533804687143822",
						"foldChange": "0.8527"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".156295839681991",
						"qValue": ".784201030789377",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".619388905634251",
						"qValue": ".967408831530889",
						"foldChange": "1.2583"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".927124926889826",
						"qValue": "1",
						"foldChange": "1.4974"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".40032745319539",
						"qValue": "1",
						"foldChange": "1.8631"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".351658252513582",
						"qValue": "1",
						"foldChange": "1.3188"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".612681460876001",
						"qValue": "1",
						"foldChange": "1.5596"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".778168475299057",
						"qValue": "1",
						"foldChange": "4.2094"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".83944931433549",
						"qValue": "1",
						"foldChange": "2.6991"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".925420615110665",
						"qValue": "1",
						"foldChange": "1.4443"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".571784901856798",
						"qValue": "1",
						"foldChange": "3.3213"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".624759209778417",
						"qValue": "1",
						"foldChange": "2.2995"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".750748019176329",
						"qValue": "1",
						"foldChange": "1.1669"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".703673273044321",
						"qValue": "1",
						"foldChange": "2.4426"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".48691340948096",
						"qValue": ".985830071188835",
						"foldChange": "2.0933"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".11906040867635",
						"qValue": ".093633684135794",
						"foldChange": "6.2250"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".454050308997372",
						"qValue": ".188408203565623",
						"foldChange": "1.2638"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000969988092124",
						"qValue": ".001040462564054",
						"foldChange": "3.7714"
					}
				]
			},
			{
				"metaboliteId": "0078dc2c-0000-0000-0000-000000000000",
				"chemicalId": "00000601-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPC (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000a5ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07973",
				"inChiKey": "JLPULHDHAOZNQI-ZTIMHPMXBU",
				"kegg": null,
				"mass": 757.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2026,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".785279317911534",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".655370102243459",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".204309674487233",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".969354107090698",
						"qValue": "1",
						"foldChange": "1.0084"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".200843297850458",
						"qValue": "1",
						"foldChange": "1.0671"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".214353479849122",
						"qValue": "1",
						"foldChange": "1.0660"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".085187087165109",
						"qValue": ".623082837721659",
						"foldChange": "1.0984"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".192651442065989",
						"qValue": ".82375925014267",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".664586019311056",
						"qValue": ".976023509428735",
						"foldChange": "0.9883"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".21266617288898",
						"qValue": "1",
						"foldChange": "0.9489"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".344239562277894",
						"qValue": "1",
						"foldChange": "0.9678"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".75955033649965",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".963859119397725",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".163182143972146",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".141493802329756",
						"qValue": "1",
						"foldChange": "1.1010"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".255872739786501",
						"qValue": "1",
						"foldChange": "1.0753"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".647952880184931",
						"qValue": "1",
						"foldChange": "1.0368"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".523236077531126",
						"qValue": "1",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".938610906127734",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".852322557794586",
						"qValue": "1",
						"foldChange": "0.9704"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".907557680217662",
						"qValue": ".997573179629986",
						"foldChange": "0.9777"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".273399053174797",
						"qValue": ".16742077809825",
						"foldChange": "1.0858"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".07206065000017",
						"qValue": ".048535883584844",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00738836450854",
						"qValue": ".004319531299569",
						"foldChange": "1.1753"
					}
				]
			},
			{
				"metaboliteId": "0078dc2d-0000-0000-0000-000000000000",
				"chemicalId": "05f60441-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/21:0, d17:1/22:0, d16:1/23:0)*",
				"plotName": null,
				"compoundId": "0000cd0f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 772.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".241049344371782",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".587359820522647",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".190283601727766",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".59250080386195",
						"qValue": "1",
						"foldChange": "1.0620"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".070809106447276",
						"qValue": "1",
						"foldChange": "1.1606"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".196798429355971",
						"qValue": "1",
						"foldChange": "1.1205"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".723038082181402",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".266667324426171",
						"qValue": ".825038702902081",
						"foldChange": "1.1028"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".145318489858667",
						"qValue": ".957839854315834",
						"foldChange": "1.1213"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".991743858667533",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".242049409297786",
						"qValue": "1",
						"foldChange": "0.9447"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".237998290974472",
						"qValue": "1",
						"foldChange": "0.8898"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".767553308034983",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".058800598613827",
						"qValue": "1",
						"foldChange": "1.2987"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".095385681103569",
						"qValue": "1",
						"foldChange": "1.2119"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".903033253227546",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".095117339391288",
						"qValue": "1",
						"foldChange": "1.3225"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".069111661174018",
						"qValue": ".822195137696456",
						"foldChange": "1.2899"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".939114363996604",
						"qValue": "1",
						"foldChange": "1.0112"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".585973822651354",
						"qValue": "1",
						"foldChange": "1.0459"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".527855303468208",
						"qValue": ".985830071188835",
						"foldChange": "1.0343"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".305990162201229",
						"qValue": ".181559970446726",
						"foldChange": "1.1464"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".174449474559957",
						"qValue": ".094090164705456",
						"foldChange": "1.2605"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".050116207359853",
						"qValue": ".019138905908362",
						"foldChange": "1.3380"
					}
				]
			},
			{
				"metaboliteId": "0078dc2e-0000-0000-0000-000000000000",
				"chemicalId": "000001f2-0000-0000-0000-000000000000",
				"chemicalName": "retinol (Vitamin A)",
				"plotName": null,
				"compoundId": "0000070e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00305",
				"inChiKey": "FPIPGXGPPPQFEQ-OVSJKPMPBW",
				"kegg": "C00473",
				"mass": 286.229665,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4033,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".64930785342634",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".287247658125418",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".743937801194505",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".985667346546767",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".382782427957948",
						"qValue": "1",
						"foldChange": "1.0568"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".39253635150115",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".640749515094669",
						"qValue": "1",
						"foldChange": "1.0466"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".534566531100443",
						"qValue": ".910963185640849",
						"foldChange": "1.0461"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".876737920546884",
						"qValue": "1",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".221176110999293",
						"qValue": "1",
						"foldChange": "0.9382"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".711231719012315",
						"qValue": "1",
						"foldChange": "1.0313"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".113533504728386",
						"qValue": ".934715307502823",
						"foldChange": "1.0214"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".435805264062485",
						"qValue": "1",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".805946876279633",
						"qValue": "1",
						"foldChange": "1.0484"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".316093693409486",
						"qValue": "1",
						"foldChange": "1.1160"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".574341029015069",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".687791485715054",
						"qValue": "1",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".894062231102478",
						"qValue": "1",
						"foldChange": "1.0243"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".355108595866971",
						"qValue": "1",
						"foldChange": "0.9208"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".992420217438698",
						"qValue": "1",
						"foldChange": "0.9349"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".365128584969663",
						"qValue": ".985830071188835",
						"foldChange": "1.0153"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005931340828091",
						"qValue": ".01044580392338",
						"foldChange": "1.6260"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000855404719834",
						"qValue": ".002039209562601",
						"foldChange": "1.5464"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000005364649274",
						"qValue": ".000045998356586",
						"foldChange": "1.8287"
					}
				]
			},
			{
				"metaboliteId": "0078dc2f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e67b-0000-0000-0000-000000000000",
				"chemicalName": "5-acetylamino-6-amino-3-methyluracil",
				"plotName": null,
				"compoundId": "00008678-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04400",
				"inChiKey": "POQOTWQIYYNXAT-UHFFFAOYAS",
				"kegg": "C16366",
				"mass": 198.07529,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4168,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".166053813014671",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".186213644421913",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".711567677610997",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".555759067017939",
						"qValue": "1",
						"foldChange": "1.2467"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".233419342881001",
						"qValue": "1",
						"foldChange": "0.8512"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".542442558083475",
						"qValue": "1",
						"foldChange": "1.2427"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".048054094422151",
						"qValue": ".481106286508833",
						"foldChange": "0.7282"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".142098449818683",
						"qValue": ".775165261510891",
						"foldChange": "0.9692"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".594320608149751",
						"qValue": ".962532817488692",
						"foldChange": "1.2811"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".966497479873901",
						"qValue": "1",
						"foldChange": "1.2147"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".719383687990886",
						"qValue": "1",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".750973125838935",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".920818066274182",
						"qValue": "1",
						"foldChange": "1.3206"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".174964401272977",
						"qValue": "1",
						"foldChange": "1.9620"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".194796990470328",
						"qValue": "1",
						"foldChange": "1.4857"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".668232581757698",
						"qValue": "1",
						"foldChange": "0.7902"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".120785109372571",
						"qValue": "1",
						"foldChange": "1.8695"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".048727733113659",
						"qValue": ".822195137696456",
						"foldChange": "2.3657"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".986949051316099",
						"qValue": "1",
						"foldChange": "1.3135"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".099319672309267",
						"qValue": "1",
						"foldChange": "2.0056"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".094574108006313",
						"qValue": ".985830071188835",
						"foldChange": "1.5269"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000041795103774",
						"qValue": ".000320994653109",
						"foldChange": "13.4969"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003939584509547",
						"qValue": ".005514500099902",
						"foldChange": "9.9764"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".017343858928116",
						"qValue": ".008373798468866",
						"foldChange": "5.6881"
					}
				]
			},
			{
				"metaboliteId": "0078dc30-0000-0000-0000-000000000000",
				"chemicalId": "05f6045d-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoyl-oleoyl-glycerol (16:1/18:1) [1]*",
				"plotName": null,
				"compoundId": "0000cd98-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07131",
				"inChiKey": "FWWLDEIJAPIBES-DATDAJMQBY",
				"kegg": null,
				"mass": 592.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2610,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".564970718118211",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".853219782581358",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".408952239812051",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".462052730313155",
						"qValue": "1",
						"foldChange": "1.0298"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".417275918252597",
						"qValue": "1",
						"foldChange": "1.0591"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".939100819390351",
						"qValue": "1",
						"foldChange": "3.3110"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".282348010316455",
						"qValue": ".911265664999995",
						"foldChange": "1.5824"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".61062750601838",
						"qValue": ".928868848501546",
						"foldChange": "1.2868"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".568155653659547",
						"qValue": ".957839854315834",
						"foldChange": "0.9197"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".355465345297846",
						"qValue": "1",
						"foldChange": "1.1053"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".58799048813352",
						"qValue": "1",
						"foldChange": "1.2790"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".145815407302858",
						"qValue": ".960252848686774",
						"foldChange": "8.1777"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".850984064845159",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".558827078725161",
						"qValue": "1",
						"foldChange": "0.6052"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".676284697677769",
						"qValue": "1",
						"foldChange": "0.6701"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".281525023936408",
						"qValue": "1",
						"foldChange": "1.2382"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".452219104799029",
						"qValue": "1",
						"foldChange": "0.6164"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".07328216538542",
						"qValue": ".822195137696456",
						"foldChange": "0.4978"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".456027214547948",
						"qValue": "1",
						"foldChange": "0.8760"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".717898750677055",
						"qValue": "1",
						"foldChange": "0.7059"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".723645547355648",
						"qValue": ".997573179629986",
						"foldChange": "0.8059"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".558014996656051",
						"qValue": ".27452231023051",
						"foldChange": "1.3494"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".027146870100939",
						"qValue": ".023020618576832",
						"foldChange": "2.1999"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".664952496816833",
						"qValue": ".138303169459679",
						"foldChange": "2.4967"
					}
				]
			},
			{
				"metaboliteId": "0078dc31-0000-0000-0000-000000000000",
				"chemicalId": "05f603d9-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-stearoyl-GPC (16:0/18:0)",
				"plotName": null,
				"compoundId": "0000cd88-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07970",
				"inChiKey": "PZNPLUBHRSSFHT-RRHRGVEJBV",
				"kegg": null,
				"mass": 761.593455,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2021,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".345156863272738",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".735377158280911",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".535189278344643",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".832390241422908",
						"qValue": "1",
						"foldChange": "1.0385"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".336144266753975",
						"qValue": "1",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".451706356705767",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".825195106197382",
						"qValue": "1",
						"foldChange": "1.0459"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".344112822280114",
						"qValue": ".847814688346659",
						"foldChange": "1.1101"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".466868732684683",
						"qValue": ".957839854315834",
						"foldChange": "1.0954"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".275289149225108",
						"qValue": "1",
						"foldChange": "0.9112"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".291413240789006",
						"qValue": "1",
						"foldChange": "0.9399"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".970808722031288",
						"qValue": "1",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".161791547831202",
						"qValue": "1",
						"foldChange": "0.8497"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".549290385944044",
						"qValue": "1",
						"foldChange": "1.0504"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".05129103631951",
						"qValue": "1",
						"foldChange": "1.2362"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".293198505346268",
						"qValue": "1",
						"foldChange": "0.8764"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".873855801379166",
						"qValue": "1",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".391096895313264",
						"qValue": "1",
						"foldChange": "1.0884"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".871127820171844",
						"qValue": "1",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".636481313064395",
						"qValue": "1",
						"foldChange": "1.0481"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".74343556424163",
						"qValue": ".997573179629986",
						"foldChange": "1.0341"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013864517835721",
						"qValue": ".019166391983209",
						"foldChange": "1.4604"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".079480374000623",
						"qValue": ".052025397738129",
						"foldChange": "1.1874"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000473451234623",
						"qValue": ".000599366600162",
						"foldChange": "1.4549"
					}
				]
			},
			{
				"metaboliteId": "0078dc32-0000-0000-0000-000000000000",
				"chemicalId": "05f5e390-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPI (18:0)",
				"plotName": null,
				"compoundId": "00004b7c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61696",
				"inChiKey": "LXUGKKVCSTYZFK-DYLKWFMLBW",
				"kegg": null,
				"mass": 600.327464,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2425,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".924322448779238",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".82473973419487",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".115160748357053",
						"qValue": ".830523702134338",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".519963472251405",
						"qValue": "1",
						"foldChange": "1.1335"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".036220234698386",
						"qValue": "1",
						"foldChange": "1.4109"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".138447359680327",
						"qValue": "1",
						"foldChange": "1.3053"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".379780338857252",
						"qValue": ".958380391854625",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".918919871587979",
						"qValue": "1",
						"foldChange": "1.0854"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".327715144618294",
						"qValue": ".957839854315834",
						"foldChange": "1.4392"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".90837379188401",
						"qValue": "1",
						"foldChange": "1.0781"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".208554932976468",
						"qValue": "1",
						"foldChange": "0.8610"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".170824186534323",
						"qValue": ".980877183095642",
						"foldChange": "0.7723"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".396846782345961",
						"qValue": "1",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".260945826986512",
						"qValue": "1",
						"foldChange": "1.1624"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".736818533623977",
						"qValue": "1",
						"foldChange": "1.0102"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".93278150869542",
						"qValue": "1",
						"foldChange": "0.9632"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".413622959109766",
						"qValue": "1",
						"foldChange": "1.1304"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".358698693705993",
						"qValue": "1",
						"foldChange": "1.1736"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".659223627532261",
						"qValue": "1",
						"foldChange": "1.0636"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".34484329547659",
						"qValue": "1",
						"foldChange": "0.7027"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".586172301156122",
						"qValue": ".985830071188835",
						"foldChange": "0.6607"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".506557301656069",
						"qValue": ".253347970028165",
						"foldChange": "1.1945"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006740188041039",
						"qValue": ".007734475096123",
						"foldChange": "2.7455"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000206251312593",
						"qValue": ".000345419215699",
						"foldChange": "2.6574"
					}
				]
			},
			{
				"metaboliteId": "0078dc33-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cc-0000-0000-0000-000000000000",
				"chemicalName": "mannitol/sorbitol",
				"plotName": null,
				"compoundId": "0000b43e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00247",
				"inChiKey": "FBPFZTCFMRRESA-UHFFFAOYAI",
				"kegg": "C00794",
				"mass": 182.079038,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1198,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".677238245710243",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".185306837543825",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".784015959437054",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".378410235897338",
						"qValue": "1",
						"foldChange": "1.4762"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".257779694843442",
						"qValue": "1",
						"foldChange": "1.6712"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".798883463830878",
						"qValue": "1",
						"foldChange": "1.3331"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".658777996655966",
						"qValue": "1",
						"foldChange": "1.9495"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".207195626572088",
						"qValue": ".825038702902081",
						"foldChange": "1.8676"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".408295430770939",
						"qValue": ".957839854315834",
						"foldChange": "1.5161"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".395083369283312",
						"qValue": "1",
						"foldChange": "1.0850"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".551871153181189",
						"qValue": "1",
						"foldChange": "1.4443"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".151541554684152",
						"qValue": ".960252848686774",
						"foldChange": "2.0974"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".49769115064593",
						"qValue": "1",
						"foldChange": "1.1613"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".241378369352041",
						"qValue": "1",
						"foldChange": "3.9800"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".58234782820139",
						"qValue": "1",
						"foldChange": "3.4271"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".718811738280939",
						"qValue": "1",
						"foldChange": "1.3311"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".985924412114985",
						"qValue": "1",
						"foldChange": "0.8759"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".740843711276511",
						"qValue": "1",
						"foldChange": "0.6581"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".469051297833649",
						"qValue": "1",
						"foldChange": "1.3975"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".396588098908555",
						"qValue": "1",
						"foldChange": "1.9895"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".866179689428378",
						"qValue": ".997573179629986",
						"foldChange": "1.4236"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003392524409696",
						"qValue": ".006530423897099",
						"foldChange": "9.6091"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000041238443883",
						"qValue": ".000377054242849",
						"foldChange": "6.4263"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006570079747614",
						"qValue": ".003971092561775",
						"foldChange": "2.4063"
					}
				]
			},
			{
				"metaboliteId": "0078dc34-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecb9-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-stearoyl)-GPE (P-18:0)*",
				"plotName": null,
				"compoundId": "00009967-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 465.321925,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2540,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".315322710031629",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".64829916830176",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".730106431346281",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".966326905453298",
						"qValue": "1",
						"foldChange": "1.1990"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".464333689977317",
						"qValue": "1",
						"foldChange": "0.9637"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".439185009500926",
						"qValue": "1",
						"foldChange": "1.1113"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".386575295509895",
						"qValue": ".961916890289242",
						"foldChange": "0.9354"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".852132557543624",
						"qValue": "1",
						"foldChange": "1.0997"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495763818424994",
						"qValue": ".957839854315834",
						"foldChange": "1.2358"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".445811842571151",
						"qValue": "1",
						"foldChange": "0.9268"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".736399377314798",
						"qValue": "1",
						"foldChange": "1.1483"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".273670591566364",
						"qValue": "1",
						"foldChange": "1.2447"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".437763178055121",
						"qValue": "1",
						"foldChange": "0.8020"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".920949637188342",
						"qValue": "1",
						"foldChange": "0.9111"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".393860832440757",
						"qValue": "1",
						"foldChange": "1.1360"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".127021595613482",
						"qValue": "1",
						"foldChange": "0.6952"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".542175471505227",
						"qValue": "1",
						"foldChange": "0.8087"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".387559359795886",
						"qValue": "1",
						"foldChange": "1.1632"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".773394302226123",
						"qValue": "1",
						"foldChange": "1.0428"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".316077801865572",
						"qValue": "1",
						"foldChange": "1.1878"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".193157620353739",
						"qValue": ".985830071188835",
						"foldChange": "1.1390"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000210410743642",
						"qValue": ".000870812717164",
						"foldChange": "2.2228"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".396355269028121",
						"qValue": ".169773051036813",
						"foldChange": "1.2820"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000867907046372",
						"qValue": ".000969017740183",
						"foldChange": "1.9638"
					}
				]
			},
			{
				"metaboliteId": "0078dc35-0000-0000-0000-000000000000",
				"chemicalId": "05f5f93b-0000-0000-0000-000000000000",
				"chemicalName": "acesulfame",
				"plotName": null,
				"compoundId": "0000bdb9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33585",
				"inChiKey": null,
				"kegg": null,
				"mass": 162.993928,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4533,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".611268208543924",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".289744059195661",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".556780233671046",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".418937758466937",
						"qValue": "1",
						"foldChange": "50.3019"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".401446611335873",
						"qValue": "1",
						"foldChange": "87.6699"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".97518539237158",
						"qValue": "1",
						"foldChange": "2.0475"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".62513256967131",
						"qValue": "1",
						"foldChange": "14.1055"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".524559879501129",
						"qValue": ".910963185640849",
						"foldChange": "3.1733"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".262935533505943",
						"qValue": ".957839854315834",
						"foldChange": "2.7453"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".201714184475695",
						"qValue": "1",
						"foldChange": "29.0999"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".099121875837786",
						"qValue": "1",
						"foldChange": "20.2701"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".700254440043723",
						"qValue": "1",
						"foldChange": "14.7187"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".588110279736816",
						"qValue": "1",
						"foldChange": "2.0786"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".654096333526208",
						"qValue": "1",
						"foldChange": "3.6783"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".947435722639901",
						"qValue": "1",
						"foldChange": "1.7696"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".763431645099714",
						"qValue": "1",
						"foldChange": "0.1416"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".417657281926407",
						"qValue": "1",
						"foldChange": "0.5282"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".588691306505588",
						"qValue": "1",
						"foldChange": "3.7307"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".642955413886978",
						"qValue": "1",
						"foldChange": "0.2224"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".291324387726226",
						"qValue": "1",
						"foldChange": "0.1791"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".129104368413643",
						"qValue": ".985830071188835",
						"foldChange": "0.8050"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".03567652258725",
						"qValue": ".039243059576745",
						"foldChange": "2.2764"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".939878821639554",
						"qValue": ".323818004026464",
						"foldChange": "2.4270"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".492448281858592",
						"qValue": ".109811131738121",
						"foldChange": "8.5958"
					}
				]
			},
			{
				"metaboliteId": "0078dc36-0000-0000-0000-000000000000",
				"chemicalId": "05f60579-0000-0000-0000-000000000000",
				"chemicalName": "caffeic acid sulfate",
				"plotName": null,
				"compoundId": "0000d006-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB41708",
				"inChiKey": "SNVAIAITQIIEMQ-DUXPYHPUBQ",
				"kegg": null,
				"mass": 259.999073,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4684,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".650672417206488",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".330474876894035",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".379632088482726",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".092558676551141",
						"qValue": "1",
						"foldChange": "9.5448"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".068825132543736",
						"qValue": "1",
						"foldChange": "8.5884"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".885267185872119",
						"qValue": "1",
						"foldChange": "1.4860"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".57062241920965",
						"qValue": "1",
						"foldChange": "3.5651"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".32863426005312",
						"qValue": ".831655911352372",
						"foldChange": "6.5069"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".12580904000122",
						"qValue": ".932820956170539",
						"foldChange": "8.6099"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".801421555321295",
						"qValue": "1",
						"foldChange": "1.1920"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".838684164643792",
						"qValue": "1",
						"foldChange": "1.2214"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".961740480961638",
						"qValue": "1",
						"foldChange": "1.8792"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".197729973519188",
						"qValue": "1",
						"foldChange": "4.9076"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".097635590543166",
						"qValue": "1",
						"foldChange": "3.5595"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".644115223082398",
						"qValue": "1",
						"foldChange": "0.7253"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".706369998950585",
						"qValue": "1",
						"foldChange": "0.7819"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".774277078958254",
						"qValue": "1",
						"foldChange": "1.6751"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".510765150711361",
						"qValue": "1",
						"foldChange": "2.1424"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".541086159019018",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".8251424980987",
						"qValue": "1",
						"foldChange": "2.1652"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".713993859850486",
						"qValue": ".997573179629986",
						"foldChange": "2.1646"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".074040997300184",
						"qValue": ".066363247774734",
						"foldChange": "30.0518"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".129019772389201",
						"qValue": ".074503140058917",
						"foldChange": "0.5204"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".035825549035488",
						"qValue": ".014768937527881",
						"foldChange": "13.8794"
					}
				]
			},
			{
				"metaboliteId": "0078dc37-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4db-0000-0000-0000-000000000000",
				"chemicalName": "2-linoleoylglycerol (18:2)",
				"plotName": null,
				"compoundId": "00007efa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11538",
				"inChiKey": "IEPGNWMPIFDNSD-HZJYTTRNBY",
				"kegg": null,
				"mass": 354.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2573,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".690010121736181",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".683263010640734",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".940296375207121",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".801722688632369",
						"qValue": "1",
						"foldChange": "4.2083"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".393051307352227",
						"qValue": "1",
						"foldChange": "1.6586"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".545169823036906",
						"qValue": "1",
						"foldChange": "3.8139"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".913633382461312",
						"qValue": "1",
						"foldChange": "1.4151"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".995810567742503",
						"qValue": "1",
						"foldChange": "1.3189"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".909470512378092",
						"qValue": "1",
						"foldChange": "1.5906"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".42322315304506",
						"qValue": "1",
						"foldChange": "1.5967"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".607179580901604",
						"qValue": "1",
						"foldChange": "1.6612"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".77288497724081",
						"qValue": "1",
						"foldChange": "0.8957"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".832633443990182",
						"qValue": "1",
						"foldChange": "0.9333"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".503747439707787",
						"qValue": "1",
						"foldChange": "0.4485"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".374573577653619",
						"qValue": "1",
						"foldChange": "0.4805"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".929687559581108",
						"qValue": "1",
						"foldChange": "0.5781"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".843052850536955",
						"qValue": "1",
						"foldChange": "0.4887"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".773174923132299",
						"qValue": "1",
						"foldChange": "0.8453"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".61424017081028",
						"qValue": "1",
						"foldChange": "0.7656"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".362282832956556",
						"qValue": "1",
						"foldChange": "0.4542"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".654540828004573",
						"qValue": ".985830071188835",
						"foldChange": "0.5932"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000054425232874",
						"qValue": ".000333697823959",
						"foldChange": "0.1839"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000462486505031",
						"qValue": ".00140176865258",
						"foldChange": "0.2788"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01800043768752",
						"qValue": ".008599121402235",
						"foldChange": "0.5559"
					}
				]
			},
			{
				"metaboliteId": "0078dc38-0000-0000-0000-000000000000",
				"chemicalId": "05f60bcb-0000-0000-0000-000000000000",
				"chemicalName": "perfluorooctanesulfonate (PFOS)",
				"plotName": null,
				"compoundId": "0000e0dc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB59586",
				"inChiKey": "YFSUTJLHUFNCNZ-UHFFFAOYAS",
				"kegg": "C18142",
				"mass": 499.937494,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".801423460152953",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".251688466865389",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".56772376784232",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".641159795038674",
						"qValue": "1",
						"foldChange": "0.9854"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".794767639095076",
						"qValue": "1",
						"foldChange": "1.0258"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".468561116881067",
						"qValue": "1",
						"foldChange": "1.0798"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".038517640768858",
						"qValue": ".436430439671866",
						"foldChange": "0.8949"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".06428169981917",
						"qValue": ".687896186587066",
						"foldChange": "0.9074"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".816017094636377",
						"qValue": "1",
						"foldChange": "1.0442"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".67990626618922",
						"qValue": "1",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".966137712215287",
						"qValue": "1",
						"foldChange": "1.0076"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".711203463258138",
						"qValue": "1",
						"foldChange": "0.9710"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".528474346260138",
						"qValue": "1",
						"foldChange": "1.2668"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".793147217453533",
						"qValue": "1",
						"foldChange": "0.7618"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".381524165214195",
						"qValue": "1",
						"foldChange": "0.6013"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".769256276022454",
						"qValue": "1",
						"foldChange": "1.1399"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".797729591786325",
						"qValue": "1",
						"foldChange": "0.8009"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".585420270063497",
						"qValue": "1",
						"foldChange": "0.7027"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".855444106137149",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".744741286602397",
						"qValue": "1",
						"foldChange": "0.6531"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".610694241338271",
						"qValue": ".985830071188835",
						"foldChange": "0.6424"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".051961990288989",
						"qValue": ".050899781217581",
						"foldChange": "1.4059"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003721464105514",
						"qValue": ".005325770447309",
						"foldChange": "3.2892"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".2565455099621",
						"qValue": ".066958345133439",
						"foldChange": "1.7148"
					}
				]
			},
			{
				"metaboliteId": "0078dc39-0000-0000-0000-000000000000",
				"chemicalId": "05f60b62-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyphenylacetylglutamine",
				"plotName": null,
				"compoundId": "0000d6e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 280.105922,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1039,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".451683625749138",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".870378218388359",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".321285282750208",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".785926733784213",
						"qValue": "1",
						"foldChange": "2.1627"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".106833195787076",
						"qValue": "1",
						"foldChange": "2.4266"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".176973387299156",
						"qValue": "1",
						"foldChange": "2.9662"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".886959042760465",
						"qValue": "1",
						"foldChange": "1.4297"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".369217967276992",
						"qValue": ".875723678608451",
						"foldChange": "1.1926"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".448869804520662",
						"qValue": ".957839854315834",
						"foldChange": "1.1970"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".368475084786409",
						"qValue": "1",
						"foldChange": "0.7622"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".427599039044354",
						"qValue": "1",
						"foldChange": "1.0343"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".914610601492158",
						"qValue": "1",
						"foldChange": "1.2061"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".581688989503554",
						"qValue": "1",
						"foldChange": "1.1229"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".10595782450299",
						"qValue": "1",
						"foldChange": "1.6084"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".256976620313393",
						"qValue": "1",
						"foldChange": "1.4324"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".842653040078849",
						"qValue": "1",
						"foldChange": "1.0540"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".533106220172837",
						"qValue": "1",
						"foldChange": "0.9218"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".655009180142881",
						"qValue": "1",
						"foldChange": "0.8746"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".110856219376627",
						"qValue": "1",
						"foldChange": "0.7648"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".688117062423429",
						"qValue": "1",
						"foldChange": "0.8656"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".253083840058561",
						"qValue": ".985830071188835",
						"foldChange": "1.1318"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000003547634193",
						"qValue": ".00007612777433",
						"foldChange": "13.3581"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000186149068476",
						"qValue": ".00082392071873",
						"foldChange": "11.5087"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000829457219",
						"qValue": ".000014308607724",
						"foldChange": "8.2248"
					}
				]
			},
			{
				"metaboliteId": "0078dc3a-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea9e-0000-0000-0000-000000000000",
				"chemicalName": "5-(galactosylhydroxy)-L-lysine",
				"plotName": null,
				"compoundId": "0000aa3e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 324.153266,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 112,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".951813811973349",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".542463921951373",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".378136574805993",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".053204218249785",
						"qValue": "1",
						"foldChange": "0.7968"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".141036926773736",
						"qValue": "1",
						"foldChange": "0.8476"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".629520557204365",
						"qValue": "1",
						"foldChange": "1.1245"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".984798260008365",
						"qValue": "1",
						"foldChange": "1.1223"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".933804760724699",
						"qValue": "1",
						"foldChange": "1.1185"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".918669369347304",
						"qValue": "1",
						"foldChange": "1.0710"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".512578531235956",
						"qValue": "1",
						"foldChange": "1.1083"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".656435907966216",
						"qValue": "1",
						"foldChange": "1.0271"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".273656765476613",
						"qValue": "1",
						"foldChange": "0.8361"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".574475366468424",
						"qValue": "1",
						"foldChange": "0.9311"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".440651644252026",
						"qValue": "1",
						"foldChange": "0.8034"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".804399745698371",
						"qValue": "1",
						"foldChange": "0.8628"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".445203284476391",
						"qValue": "1",
						"foldChange": "1.1393"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".348796347953794",
						"qValue": "1",
						"foldChange": "1.1886"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".823860594009625",
						"qValue": "1",
						"foldChange": "1.0433"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".615163706514059",
						"qValue": "1",
						"foldChange": "1.0294"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".904382777710592",
						"qValue": "1",
						"foldChange": "0.8927"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".54223966875686",
						"qValue": ".985830071188835",
						"foldChange": "0.8672"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".074492164187682",
						"qValue": ".066478853458539",
						"foldChange": "0.7270"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".053921222009644",
						"qValue": ".039053872372225",
						"foldChange": "0.7861"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037820913540752",
						"qValue": ".01516382095168",
						"foldChange": "0.7199"
					}
				]
			},
			{
				"metaboliteId": "0078dc3b-0000-0000-0000-000000000000",
				"chemicalId": "05f60b8f-0000-0000-0000-000000000000",
				"chemicalName": "2'-O-methylcytidine",
				"plotName": null,
				"compoundId": "0000e0d2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RFCQJGFZUQFYRF-ZOQUXTDFBZ",
				"kegg": null,
				"mass": 257.101171,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3879,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".404553505175362",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".537901325005249",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".864379600755869",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".271134074443895",
						"qValue": "1",
						"foldChange": "1.0883"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".824251657681054",
						"qValue": "1",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".3779164359961",
						"qValue": "1",
						"foldChange": "2.4668"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".925585618421457",
						"qValue": "1",
						"foldChange": "2.9762"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".476971718587718",
						"qValue": ".902562957068497",
						"foldChange": "1.6330"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".536294333913239",
						"qValue": ".957839854315834",
						"foldChange": "1.6640"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".610472549040795",
						"qValue": "1",
						"foldChange": "0.8638"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".500303395244353",
						"qValue": "1",
						"foldChange": "0.8795"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".868545554029217",
						"qValue": "1",
						"foldChange": "1.0023"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".847148368678121",
						"qValue": "1",
						"foldChange": "1.0064"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".43015954943019",
						"qValue": "1",
						"foldChange": "1.1580"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".320678062719822",
						"qValue": "1",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".466478174813439",
						"qValue": "1",
						"foldChange": "1.2299"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".204836125265537",
						"qValue": "1",
						"foldChange": "1.3405"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".549018016117675",
						"qValue": "1",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".546306356846004",
						"qValue": "1",
						"foldChange": "0.8434"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".71340206986128",
						"qValue": "1",
						"foldChange": "0.9520"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".337931931221317",
						"qValue": ".985830071188835",
						"foldChange": "1.1288"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000033251179591",
						"qValue": ".000282285966353",
						"foldChange": "6.1600"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000046212863636",
						"qValue": ".000377054242849",
						"foldChange": "8.5400"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000101922750172",
						"qValue": ".000210086205304",
						"foldChange": "9.2586"
					}
				]
			},
			{
				"metaboliteId": "0078dc3c-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb1-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-docosahexaenoyl-glycerol (18:2/22:6) [2]*",
				"plotName": null,
				"compoundId": "0000d6a6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07266",
				"inChiKey": null,
				"kegg": null,
				"mass": 664.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2646,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".153107205022992",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".117984960650754",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".449145645416418",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".740774880838838",
						"qValue": "1",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".786588781626664",
						"qValue": "1",
						"foldChange": "1.2290"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".548023705423413",
						"qValue": "1",
						"foldChange": "1.3030"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".018008883237023",
						"qValue": ".330397363624153",
						"foldChange": "1.8541"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".063916139560656",
						"qValue": ".687896186587066",
						"foldChange": "1.6148"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".582907707249046",
						"qValue": ".957839854315834",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".316924735727568",
						"qValue": "1",
						"foldChange": "1.6979"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".25503388061496",
						"qValue": "1",
						"foldChange": "2.2471"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".888741305556269",
						"qValue": "1",
						"foldChange": "1.1393"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".016371644248721",
						"qValue": "1",
						"foldChange": "0.4942"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".406795892075168",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".123225060351951",
						"qValue": "1",
						"foldChange": "2.0746"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".319236237770104",
						"qValue": "1",
						"foldChange": "0.6956"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".932305957480215",
						"qValue": "1",
						"foldChange": "0.9455"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".379021587249368",
						"qValue": "1",
						"foldChange": "1.3591"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".107422362233166",
						"qValue": "1",
						"foldChange": "0.5967"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".743298505875135",
						"qValue": "1",
						"foldChange": "1.1414"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".215436561689488",
						"qValue": ".985830071188835",
						"foldChange": "1.9128"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011646004725749",
						"qValue": ".017061427355454",
						"foldChange": "3.7435"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000958558871048",
						"qValue": ".002121444126035",
						"foldChange": "3.7464"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000684774804757",
						"qValue": ".000809524263269",
						"foldChange": "3.6466"
					}
				]
			},
			{
				"metaboliteId": "0078dc3d-0000-0000-0000-000000000000",
				"chemicalId": "05f61eaa-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:0/20:0, d16:0/22:0)*",
				"plotName": null,
				"compoundId": "0000e084-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 760.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2790,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".169599515093459",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".758659440249141",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".608625934673484",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".6312651771721",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".890673162271974",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".53739319323396",
						"qValue": "1",
						"foldChange": "1.1700"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".744608646939048",
						"qValue": "1",
						"foldChange": "1.0047"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".241366614724004",
						"qValue": ".825038702902081",
						"foldChange": "1.1746"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".136613984757809",
						"qValue": ".957839854315834",
						"foldChange": "1.2173"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".852466476598834",
						"qValue": "1",
						"foldChange": "1.0469"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".597307837222705",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".475754289626083",
						"qValue": "1",
						"foldChange": "0.9238"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".572018681969023",
						"qValue": "1",
						"foldChange": "1.0641"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".081999674718383",
						"qValue": "1",
						"foldChange": "1.3550"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".207856721640018",
						"qValue": "1",
						"foldChange": "1.2733"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".516195727118561",
						"qValue": "1",
						"foldChange": "1.1294"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".042170299181906",
						"qValue": "1",
						"foldChange": "1.4440"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".135656518127299",
						"qValue": ".946259810871566",
						"foldChange": "1.2785"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".296427322909018",
						"qValue": "1",
						"foldChange": "1.3337"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".152622775143909",
						"qValue": "1",
						"foldChange": "1.2720"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".639948873393473",
						"qValue": ".985830071188835",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".073736510817311",
						"qValue": ".066340937262125",
						"foldChange": "1.4909"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".129113140802924",
						"qValue": ".074503140058917",
						"foldChange": "1.5565"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".261884760358943",
						"qValue": ".068156467360188",
						"foldChange": "1.2968"
					}
				]
			},
			{
				"metaboliteId": "0078dc3e-0000-0000-0000-000000000000",
				"chemicalId": "05f61ed8-0000-0000-0000-000000000000",
				"chemicalName": "behenoylcarnitine (C22)*",
				"plotName": null,
				"compoundId": "0000e0aa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "IUMXSSOVGPXXJL-UHFFFAOYAY",
				"kegg": null,
				"mass": 483.428759,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1652,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".114565099846039",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".260750610825119",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".672282192996102",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".422954287647956",
						"qValue": "1",
						"foldChange": "0.9444"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".922389734001408",
						"qValue": "1",
						"foldChange": "1.0522"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".369381404415227",
						"qValue": "1",
						"foldChange": "1.1798"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".129131703797497",
						"qValue": ".722967631129409",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".077572190747663",
						"qValue": ".687896186587066",
						"foldChange": "0.8222"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".796361138712657",
						"qValue": ".995159073486742",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".562585179488007",
						"qValue": "1",
						"foldChange": "0.9576"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".913907209547634",
						"qValue": "1",
						"foldChange": "1.2247"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".492434066041723",
						"qValue": "1",
						"foldChange": "1.2996"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".777737969062628",
						"qValue": "1",
						"foldChange": "1.0853"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".147681062698073",
						"qValue": "1",
						"foldChange": "1.4214"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".223269910366372",
						"qValue": "1",
						"foldChange": "1.3097"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".901515002632812",
						"qValue": "1",
						"foldChange": "0.9317"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".120149146808579",
						"qValue": "1",
						"foldChange": "1.3680"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".088237656479851",
						"qValue": ".834952786556829",
						"foldChange": "1.4683"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".404914394131502",
						"qValue": "1",
						"foldChange": "0.8762"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".144974181107081",
						"qValue": "1",
						"foldChange": "1.5729"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".024461152910334",
						"qValue": ".985830071188835",
						"foldChange": "1.7950"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".090839097702644",
						"qValue": ".076920640676367",
						"foldChange": "1.9451"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".224756139218121",
						"qValue": ".113269938775178",
						"foldChange": "0.8412"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".085640449235029",
						"qValue": ".028805276332925",
						"foldChange": "1.3830"
					}
				]
			},
			{
				"metaboliteId": "0078dc3f-0000-0000-0000-000000000000",
				"chemicalId": "00000117-0000-0000-0000-000000000000",
				"chemicalName": "cystine",
				"plotName": null,
				"compoundId": "00000038-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00192",
				"inChiKey": "LEVWYRKDKASIDU-UHFFFAOYAA",
				"kegg": "C00491",
				"mass": 240.023848,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 397,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".883922101894411",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".323793713457057",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".808880260268646",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".135537031132906",
						"qValue": "1",
						"foldChange": "0.7947"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".3928486097361",
						"qValue": "1",
						"foldChange": "0.9950"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".515009640473314",
						"qValue": "1",
						"foldChange": "1.3398"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".902273557883267",
						"qValue": "1",
						"foldChange": "1.3813"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".918590867116402",
						"qValue": "1",
						"foldChange": "1.2614"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".822014899990131",
						"qValue": "1",
						"foldChange": "1.3625"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".263900015306821",
						"qValue": "1",
						"foldChange": "0.8905"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".482421344514407",
						"qValue": "1",
						"foldChange": "0.9320"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".674515169637873",
						"qValue": "1",
						"foldChange": "1.5072"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".528641016842724",
						"qValue": "1",
						"foldChange": "0.8609"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".820837964164951",
						"qValue": "1",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".40049030743104",
						"qValue": "1",
						"foldChange": "1.1529"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".506645109361915",
						"qValue": "1",
						"foldChange": "1.0998"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".64169984591546",
						"qValue": "1",
						"foldChange": "1.0804"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".867067524867528",
						"qValue": "1",
						"foldChange": "0.9824"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".980189410584617",
						"qValue": "1",
						"foldChange": "0.8710"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".756645445991428",
						"qValue": "1",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".732758654736427",
						"qValue": ".997573179629986",
						"foldChange": "1.0806"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000006751705242",
						"qValue": ".00010321298626",
						"foldChange": "3.5333"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000053545676327",
						"qValue": ".000403541115839",
						"foldChange": "3.3621"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005415922100734",
						"qValue": ".003455356599038",
						"foldChange": "3.8742"
					}
				]
			},
			{
				"metaboliteId": "0078dc40-0000-0000-0000-000000000000",
				"chemicalId": "05f61e77-0000-0000-0000-000000000000",
				"chemicalName": "ceramide (d18:1/14:0, d16:1/16:0)*",
				"plotName": null,
				"compoundId": "0000e058-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 509.480804,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2736,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".058985224208572",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".406472619146568",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".396604321078564",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".427622711383823",
						"qValue": "1",
						"foldChange": "1.0929"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".17897641396926",
						"qValue": "1",
						"foldChange": "1.1478"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".575515785893356",
						"qValue": "1",
						"foldChange": "1.0741"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".989053275178986",
						"qValue": "1",
						"foldChange": "1.0378"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".833677590242581",
						"qValue": ".998083137271714",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".844394076780621",
						"qValue": "1",
						"foldChange": "1.0500"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".152463623940693",
						"qValue": "1",
						"foldChange": "1.1411"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".687434756933514",
						"qValue": "1",
						"foldChange": "1.0124"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".069199711353209",
						"qValue": ".727024127920748",
						"foldChange": "0.8250"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".923271261880166",
						"qValue": "1",
						"foldChange": "1.0429"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".051427086959872",
						"qValue": "1",
						"foldChange": "1.4017"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".056542456897929",
						"qValue": "1",
						"foldChange": "1.3441"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".742994529624633",
						"qValue": "1",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".021926769473881",
						"qValue": "1",
						"foldChange": "1.4907"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".008740941764969",
						"qValue": ".653136865625524",
						"foldChange": "1.4949"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".595979020188426",
						"qValue": "1",
						"foldChange": "0.9369"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".280857785379672",
						"qValue": "1",
						"foldChange": "1.1874"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".109849333900126",
						"qValue": ".985830071188835",
						"foldChange": "1.2673"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".281653852913552",
						"qValue": ".171705463132094",
						"foldChange": "1.3031"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".129316765283096",
						"qValue": ".074503140058917",
						"foldChange": "1.2511"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007907100423181",
						"qValue": ".004563117531926",
						"foldChange": "1.4992"
					}
				]
			},
			{
				"metaboliteId": "0078dc41-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c8-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3beta,17beta) disulfate (1)",
				"plotName": null,
				"compoundId": "00009152-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03818",
				"inChiKey": null,
				"kegg": "C04295",
				"mass": 450.138209,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3031,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".76080962452057",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".769652828291988",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".299709447417668",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".430123316320285",
						"qValue": "1",
						"foldChange": "0.9137"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".923791937334693",
						"qValue": "1",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".487591826874406",
						"qValue": "1",
						"foldChange": "1.1179"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".049830792529652",
						"qValue": ".493093074915512",
						"foldChange": "1.8443"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".091313493936395",
						"qValue": ".697458782960496",
						"foldChange": "1.6627"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".773739053920909",
						"qValue": ".987060268283587",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".981292961856176",
						"qValue": "1",
						"foldChange": "1.0178"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".641275965822087",
						"qValue": "1",
						"foldChange": "0.9563"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".624643911065618",
						"qValue": "1",
						"foldChange": "0.8865"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".92908570961851",
						"qValue": "1",
						"foldChange": "1.0011"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".622892564209021",
						"qValue": "1",
						"foldChange": "1.0695"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".675971800623091",
						"qValue": "1",
						"foldChange": "1.0683"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".265761947775886",
						"qValue": "1",
						"foldChange": "1.4386"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".431983066895988",
						"qValue": "1",
						"foldChange": "1.0502"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".77932279152895",
						"qValue": "1",
						"foldChange": "0.7300"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".454272842200561",
						"qValue": "1",
						"foldChange": "1.3140"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".72849518876064",
						"qValue": "1",
						"foldChange": "0.9685"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".709908936924814",
						"qValue": ".996918325615539",
						"foldChange": "0.7370"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".130225179408168",
						"qValue": ".099925558622948",
						"foldChange": "2.0869"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".050854069223132",
						"qValue": ".037814603264342",
						"foldChange": "2.0232"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".025718887477956",
						"qValue": ".011487957398081",
						"foldChange": "2.4443"
					}
				]
			},
			{
				"metaboliteId": "0078dc42-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4ef-0000-0000-0000-000000000000",
				"chemicalName": "ribonate",
				"plotName": null,
				"compoundId": "00006c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00867",
				"inChiKey": "QXKAIJAYHKCRRA-BXXZVTAOBP",
				"kegg": "C01685",
				"mass": 166.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1113,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".520173710474733",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".517228683424992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".711146421387343",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".639496914228473",
						"qValue": "1",
						"foldChange": "0.9721"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".711132572768572",
						"qValue": "1",
						"foldChange": "0.9755"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".921666044301918",
						"qValue": "1",
						"foldChange": "1.0368"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".340167368740124",
						"qValue": ".951252030575746",
						"foldChange": "0.9456"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".098283473954675",
						"qValue": ".697458782960496",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".473432328715996",
						"qValue": ".957839854315834",
						"foldChange": "0.9818"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".508136337166896",
						"qValue": "1",
						"foldChange": "1.1056"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".995091215111663",
						"qValue": "1",
						"foldChange": "1.0395"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".504228083577574",
						"qValue": "1",
						"foldChange": "0.8870"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".658948469145472",
						"qValue": "1",
						"foldChange": "1.0344"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".748588608844823",
						"qValue": "1",
						"foldChange": "1.0059"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".920810568321587",
						"qValue": "1",
						"foldChange": "0.9724"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".944060255743126",
						"qValue": "1",
						"foldChange": "1.0010"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".212048681834125",
						"qValue": "1",
						"foldChange": "1.1698"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".225766356689147",
						"qValue": "1",
						"foldChange": "1.1686"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".556888633592864",
						"qValue": "1",
						"foldChange": "0.9476"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".541727389905008",
						"qValue": "1",
						"foldChange": "0.9938"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".234515766711669",
						"qValue": ".985830071188835",
						"foldChange": "1.0488"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".004706092737961",
						"qValue": ".008656350132498",
						"foldChange": "1.5515"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".026162867843258",
						"qValue": ".022310160825836",
						"foldChange": "1.3787"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002427070032911",
						"qValue": ".001970779896418",
						"foldChange": "1.5385"
					}
				]
			},
			{
				"metaboliteId": "0078dc43-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebe0-0000-0000-0000-000000000000",
				"chemicalName": "2-oxoarginine*",
				"plotName": null,
				"compoundId": "0000d720-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04225",
				"inChiKey": "ARBHXJXXVVHMET-UHFFFAOYAC",
				"kegg": "C03771",
				"mass": 173.080041,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 422,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".899231662655512",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".127637968749985",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".371577412278672",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".655910101887901",
						"qValue": "1",
						"foldChange": "1.3667"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".409173445780504",
						"qValue": "1",
						"foldChange": "1.3446"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".702471350644981",
						"qValue": "1",
						"foldChange": "1.3164"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".077504506678351",
						"qValue": ".609442865135016",
						"foldChange": "0.8208"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".578618779553348",
						"qValue": ".928868848501546",
						"foldChange": "1.0439"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".2192432709091",
						"qValue": ".957839854315834",
						"foldChange": "1.6341"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".040031292160433",
						"qValue": ".821720595261736",
						"foldChange": "0.6992"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".223668460086458",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".384336509767545",
						"qValue": "1",
						"foldChange": "1.3960"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".323380063412314",
						"qValue": "1",
						"foldChange": "1.1001"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".129465695926087",
						"qValue": "1",
						"foldChange": "1.4013"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".543150313715542",
						"qValue": "1",
						"foldChange": "1.2738"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".484508187739519",
						"qValue": "1",
						"foldChange": "0.7787"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".646756783114214",
						"qValue": "1",
						"foldChange": "0.6970"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".834646132982201",
						"qValue": "1",
						"foldChange": "0.8950"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".939184787908846",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".957424196540976",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".984669595992245",
						"qValue": "1",
						"foldChange": "1.0770"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".369302322584483",
						"qValue": ".202437953775333",
						"foldChange": "1.5696"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".024537761970499",
						"qValue": ".021249278263457",
						"foldChange": "1.6596"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".432459945969133",
						"qValue": ".099044367579403",
						"foldChange": "1.2653"
					}
				]
			},
			{
				"metaboliteId": "0078dc44-0000-0000-0000-000000000000",
				"chemicalId": "05f5e43b-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoylglycerol (16:0)",
				"plotName": null,
				"compoundId": "00005287-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB31074",
				"inChiKey": "QHZLMUACJMDIAE-UHFFFAOYAU",
				"kegg": null,
				"mass": 330.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2553,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".694016431244205",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".794651556545385",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".688055111864338",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".929769787857486",
						"qValue": "1",
						"foldChange": "3.2582"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".830427494151377",
						"qValue": "1",
						"foldChange": "1.0391"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".762501153831796",
						"qValue": "1",
						"foldChange": "3.5427"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".501264265882969",
						"qValue": "1",
						"foldChange": "1.0848"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".166737917340178",
						"qValue": ".787973217949622",
						"foldChange": "0.9534"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".471097492966975",
						"qValue": ".957839854315834",
						"foldChange": "1.3166"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".415142833324787",
						"qValue": "1",
						"foldChange": "1.9837"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".500461509124065",
						"qValue": "1",
						"foldChange": "1.6131"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".886812402506107",
						"qValue": "1",
						"foldChange": "1.3409"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".466319662556884",
						"qValue": "1",
						"foldChange": "1.7143"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".390084621274555",
						"qValue": "1",
						"foldChange": "0.3732"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".115605369559228",
						"qValue": "1",
						"foldChange": "0.2177"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".90003243926774",
						"qValue": "1",
						"foldChange": "0.7664"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".802563152199887",
						"qValue": "1",
						"foldChange": "0.6135"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".705686010756435",
						"qValue": "1",
						"foldChange": "0.8005"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".8471677394025",
						"qValue": "1",
						"foldChange": "0.7113"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".895626640426844",
						"qValue": "1",
						"foldChange": "0.4643"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".958272588004744",
						"qValue": "1",
						"foldChange": "0.6528"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000262896271956",
						"qValue": ".000998262894081",
						"foldChange": "0.2283"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001169679373426",
						"qValue": ".002346481515406",
						"foldChange": "0.3521"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".012732429305415",
						"qValue": ".006582212367015",
						"foldChange": "0.6131"
					}
				]
			},
			{
				"metaboliteId": "0078dc45-0000-0000-0000-000000000000",
				"chemicalId": "00000062-0000-0000-0000-000000000000",
				"chemicalName": "kynurenate",
				"plotName": null,
				"compoundId": "00000589-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00715",
				"inChiKey": "HCZHHEIFKROPDY-UHFFFAOYAN",
				"kegg": "C01717",
				"mass": 189.042593,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 244,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".960410733716474",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".54848501853911",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".476466869651815",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".917981627345205",
						"qValue": "1",
						"foldChange": "1.0660"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".779479376054173",
						"qValue": "1",
						"foldChange": "1.0576"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".701867861589101",
						"qValue": "1",
						"foldChange": "1.1506"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".192131463098032",
						"qValue": ".797133099236872",
						"foldChange": "1.2370"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".493265993100281",
						"qValue": ".902562957068497",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".049706990013452",
						"qValue": ".693453254122091",
						"foldChange": "0.8318"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".648436117210262",
						"qValue": "1",
						"foldChange": "0.9583"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".425563276241029",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".731729444038433",
						"qValue": "1",
						"foldChange": "0.9481"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".878701823482582",
						"qValue": "1",
						"foldChange": "0.9257"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".71056062312519",
						"qValue": "1",
						"foldChange": "0.9376"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".816106993418407",
						"qValue": "1",
						"foldChange": "1.0128"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".322511272450291",
						"qValue": "1",
						"foldChange": "1.1101"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".8834004664315",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".417595040018265",
						"qValue": "1",
						"foldChange": "0.8136"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".668744313958094",
						"qValue": "1",
						"foldChange": "0.7924"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".76905771716946",
						"qValue": "1",
						"foldChange": "0.8136"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".909244792937618",
						"qValue": ".997573179629986",
						"foldChange": "1.0267"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".103962031576113",
						"qValue": ".084364829486589",
						"foldChange": "1.6744"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000008190750462",
						"qValue": ".000152264107822",
						"foldChange": "2.0024"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02609854846846",
						"qValue": ".011590600812547",
						"foldChange": "2.1252"
					}
				]
			},
			{
				"metaboliteId": "0078dc46-0000-0000-0000-000000000000",
				"chemicalId": "00000200-0000-0000-0000-000000000000",
				"chemicalName": "taurine",
				"plotName": null,
				"compoundId": "0000084d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00251",
				"inChiKey": "XOAAWQZATWQOTB-UHFFFAOYAA",
				"kegg": "C00245",
				"mass": 125.014666,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 406,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".398084319862396",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".854935304383329",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".727064926852875",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".806010673473428",
						"qValue": "1",
						"foldChange": "1.3467"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".285297004762617",
						"qValue": "1",
						"foldChange": "0.8755"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".408628257303298",
						"qValue": "1",
						"foldChange": "1.2007"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".914726408626294",
						"qValue": "1",
						"foldChange": "1.0616"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".79639342241238",
						"qValue": ".985267275880213",
						"foldChange": "1.1504"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".879972302624934",
						"qValue": "1",
						"foldChange": "1.2207"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".612961811419551",
						"qValue": "1",
						"foldChange": "0.9634"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".616368916310479",
						"qValue": "1",
						"foldChange": "1.2320"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".315922080550222",
						"qValue": "1",
						"foldChange": "1.3761"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".302210491168688",
						"qValue": "1",
						"foldChange": "0.7112"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".580093486433362",
						"qValue": "1",
						"foldChange": "0.7565"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".663222251998112",
						"qValue": "1",
						"foldChange": "1.0637"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".378768621310816",
						"qValue": "1",
						"foldChange": "0.7562"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".398346797361097",
						"qValue": "1",
						"foldChange": "0.7293"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".9900607232629",
						"qValue": "1",
						"foldChange": "0.9645"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".87765317164549",
						"qValue": "1",
						"foldChange": "1.0336"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".290993159401823",
						"qValue": "1",
						"foldChange": "1.1920"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".219246307964608",
						"qValue": ".985830071188835",
						"foldChange": "1.1533"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000002067351939",
						"qValue": ".000069678697434",
						"foldChange": "2.9262"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".205361269934441",
						"qValue": ".106241668431235",
						"foldChange": "1.4053"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".023967123031839",
						"qValue": ".010854468381387",
						"foldChange": "1.7071"
					}
				]
			},
			{
				"metaboliteId": "0078dc47-0000-0000-0000-000000000000",
				"chemicalId": "00000568-0000-0000-0000-000000000000",
				"chemicalName": "naproxen",
				"plotName": null,
				"compoundId": "00002f5a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01923",
				"inChiKey": "CMWTZPSULFXXJA-VIFPVBQEBN",
				"kegg": "C01517",
				"mass": 230.094295,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4788,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".884010458270531",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".529793334105076",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".250554494447583",
						"qValue": ".962619864505407",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".589560372014664",
						"qValue": "1",
						"foldChange": "0.9329"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".044731539916701",
						"qValue": "1",
						"foldChange": "0.8307"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".135574904152601",
						"qValue": "1",
						"foldChange": "0.8974"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".142096309678676",
						"qValue": ".742487134132407",
						"foldChange": "2.3361"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".312506357444427",
						"qValue": ".825038702902081",
						"foldChange": "1.5185"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".639175197394074",
						"qValue": ".973034272612281",
						"foldChange": "0.9431"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".833789006676136",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".836443345151949",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".99728568700816",
						"qValue": "1",
						"foldChange": "0.9994"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".207479557238972",
						"qValue": "1",
						"foldChange": "0.3145"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".621608303506417",
						"qValue": "1",
						"foldChange": "0.5912"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".468014681445051",
						"qValue": "1",
						"foldChange": "1.8796"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".833195520833453",
						"qValue": "1",
						"foldChange": "0.7196"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".919957513746122",
						"qValue": "1",
						"foldChange": "0.6591"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".919461222057011",
						"qValue": "1",
						"foldChange": "0.9159"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".711069808073717",
						"qValue": "1",
						"foldChange": "1.4203"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".499555410192744",
						"qValue": "1",
						"foldChange": "1.9947"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".738012256903456",
						"qValue": ".997573179629986",
						"foldChange": "1.4045"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "2.1327"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137914",
						"qValue": ".152009550549949",
						"foldChange": "1.5185"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087332",
						"qValue": ".083280304277061",
						"foldChange": "1.0692"
					}
				]
			},
			{
				"metaboliteId": "0078dc48-0000-0000-0000-000000000000",
				"chemicalId": "05f5e406-0000-0000-0000-000000000000",
				"chemicalName": "phenyllactate (PLA)",
				"plotName": null,
				"compoundId": "00005672-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00779",
				"inChiKey": "VOXXWSYKYCBWHO-UHFFFAOYAI",
				"kegg": "C05607",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 141,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".805329642494302",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".812016581374235",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".64626172101164",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".868625376477464",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".240801392178165",
						"qValue": "1",
						"foldChange": "1.1245"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".312188696481396",
						"qValue": "1",
						"foldChange": "1.1007"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".820859877308441",
						"qValue": "1",
						"foldChange": "1.0016"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".986383681330811",
						"qValue": "1",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".834147272143869",
						"qValue": "1",
						"foldChange": "1.0463"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".407917105150058",
						"qValue": "1",
						"foldChange": "0.9649"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".305864773069567",
						"qValue": "1",
						"foldChange": "0.9355"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".842254216976651",
						"qValue": "1",
						"foldChange": "0.9260"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".463504446188824",
						"qValue": "1",
						"foldChange": "1.0645"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".685086141996995",
						"qValue": "1",
						"foldChange": "1.0310"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".766421660850775",
						"qValue": "1",
						"foldChange": "0.9685"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".568678113244227",
						"qValue": "1",
						"foldChange": "1.0608"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".981871610444684",
						"qValue": "1",
						"foldChange": "0.9820"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".56508022070259",
						"qValue": "1",
						"foldChange": "0.9256"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".828059375723623",
						"qValue": "1",
						"foldChange": "1.0066"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".599965160689682",
						"qValue": "1",
						"foldChange": "0.8419"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".455972617107128",
						"qValue": ".985830071188835",
						"foldChange": "0.8364"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".551002401888957",
						"qValue": ".271880343729249",
						"foldChange": "1.1641"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".027953359468849",
						"qValue": ".023548926911988",
						"foldChange": "1.3888"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".611123047409442",
						"qValue": ".128773093965146",
						"foldChange": "1.1528"
					}
				]
			},
			{
				"metaboliteId": "0078dc49-0000-0000-0000-000000000000",
				"chemicalId": "05f5e843-0000-0000-0000-000000000000",
				"chemicalName": "chiro-inositol",
				"plotName": null,
				"compoundId": "000090f8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34220",
				"inChiKey": "CDAISMWEOUEBRE-LKPKBOIGBG",
				"kegg": "C19891",
				"mass": 180.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1946,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".470893756031038",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".528092431703162",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".570034250395868",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".912685208413936",
						"qValue": "1",
						"foldChange": "6.0956"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".826032743623144",
						"qValue": "1",
						"foldChange": "19.0859"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".912264910973896",
						"qValue": "1",
						"foldChange": "1.9702"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".601086020840613",
						"qValue": "1",
						"foldChange": "2.2077"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".64644413332357",
						"qValue": ".930662761919878",
						"foldChange": "1.9532"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".328137039420046",
						"qValue": ".957839854315834",
						"foldChange": "1.5717"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".186415197279839",
						"qValue": "1",
						"foldChange": "0.9958"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".105176217389148",
						"qValue": "1",
						"foldChange": "2.1220"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".757137828869181",
						"qValue": "1",
						"foldChange": "1.3192"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".421589787403876",
						"qValue": "1",
						"foldChange": "0.2621"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".74609441410257",
						"qValue": "1",
						"foldChange": "0.4971"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".268396150909576",
						"qValue": "1",
						"foldChange": "1.8967"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".636334889859843",
						"qValue": "1",
						"foldChange": "0.9782"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".362271910386501",
						"qValue": "1",
						"foldChange": "0.4082"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".632936503300109",
						"qValue": "1",
						"foldChange": "0.4173"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".174281742123058",
						"qValue": "1",
						"foldChange": "0.1748"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".206965685396266",
						"qValue": "1",
						"foldChange": "0.1397"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".980228573287839",
						"qValue": "1",
						"foldChange": "0.7993"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".299268372528518",
						"qValue": ".179501706878449",
						"foldChange": "1.5593"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".286518260893444",
						"qValue": ".135185443981391",
						"foldChange": "1.1999"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".51572900528835",
						"qValue": ".113584665384229",
						"foldChange": "3.3473"
					}
				]
			},
			{
				"metaboliteId": "0078dc4a-0000-0000-0000-000000000000",
				"chemicalId": "000003ec-0000-0000-0000-000000000000",
				"chemicalName": "xanthine",
				"plotName": null,
				"compoundId": "00000c4b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00292",
				"inChiKey": "LRFVTYWOQMYALW-UHFFFAOYAT",
				"kegg": "C00385",
				"mass": 152.033426,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".856745443202242",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".572582252507551",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".356396798994093",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".199285638703114",
						"qValue": "1",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".563897126745958",
						"qValue": "1",
						"foldChange": "0.9523"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".474742615689926",
						"qValue": "1",
						"foldChange": "1.1665"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".231122280324596",
						"qValue": ".852077213869098",
						"foldChange": "1.1628"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".641438240287957",
						"qValue": ".928868848501546",
						"foldChange": "0.9858"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".099161091145272",
						"qValue": ".861821748215854",
						"foldChange": "0.8475"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".405798493080086",
						"qValue": "1",
						"foldChange": "0.9410"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".445146078587863",
						"qValue": "1",
						"foldChange": "0.9929"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".945420224836769",
						"qValue": "1",
						"foldChange": "1.0232"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".430693960380514",
						"qValue": "1",
						"foldChange": "0.6313"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".603237585059898",
						"qValue": "1",
						"foldChange": "0.6336"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".815680051144914",
						"qValue": "1",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".574336391999774",
						"qValue": "1",
						"foldChange": "1.0561"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".760076722130222",
						"qValue": "1",
						"foldChange": "0.8610"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".393150138006292",
						"qValue": "1",
						"foldChange": "0.8152"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".475530716026202",
						"qValue": "1",
						"foldChange": "0.6621"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".52096299631332",
						"qValue": "1",
						"foldChange": "0.6771"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".972727305123141",
						"qValue": "1",
						"foldChange": "1.0226"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".052458878554572",
						"qValue": ".051234929441998",
						"foldChange": "1.6763"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".095994589753179",
						"qValue": ".059220685240012",
						"foldChange": "1.2612"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".238287533227457",
						"qValue": ".062907877799611",
						"foldChange": "1.7564"
					}
				]
			},
			{
				"metaboliteId": "0078dc4b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4e5-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxydecanoate",
				"plotName": null,
				"compoundId": "00005625-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02203",
				"inChiKey": "FYSSBMZUBSBFJL-UHFFFAOYAJ",
				"kegg": null,
				"mass": 188.141245,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1747,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".639005751247718",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".253968394892471",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".481617071001876",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".593754854553645",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".901255208530024",
						"qValue": "1",
						"foldChange": "1.0651"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".511312369075738",
						"qValue": "1",
						"foldChange": "1.2480"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".658237194896038",
						"qValue": "1",
						"foldChange": "0.9915"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".071983082599444",
						"qValue": ".687896186587066",
						"foldChange": "0.8592"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".169526801794789",
						"qValue": ".957839854315834",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".706999688702958",
						"qValue": "1",
						"foldChange": "1.1079"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".287167774854039",
						"qValue": "1",
						"foldChange": "0.9011"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".152331532987914",
						"qValue": ".960252848686774",
						"foldChange": "0.8552"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".816107670707189",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".742803265550536",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".57512075669592",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".866520247049714",
						"qValue": "1",
						"foldChange": "1.0134"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".410247759725168",
						"qValue": "1",
						"foldChange": "1.0744"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".315698832323349",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".203976903324246",
						"qValue": "1",
						"foldChange": "0.7993"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".730187368629574",
						"qValue": "1",
						"foldChange": "0.8586"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".375666102075126",
						"qValue": ".985830071188835",
						"foldChange": "1.0743"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".356689611049174",
						"qValue": ".197485909647979",
						"foldChange": "0.7217"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".790400080272899",
						"qValue": ".286615249780362",
						"foldChange": "1.0473"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".766298566285634",
						"qValue": ".154776831199697",
						"foldChange": "1.0882"
					}
				]
			},
			{
				"metaboliteId": "0078dc4c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e676-0000-0000-0000-000000000000",
				"chemicalName": "3,7-dimethylurate",
				"plotName": null,
				"compoundId": "0000865f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01982",
				"inChiKey": "HMLZLHKHNBLLJD-UHFFFAOYAN",
				"kegg": "C16360",
				"mass": 196.05964,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".330580191401961",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".827837896075291",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".522091159805259",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".16355043706154",
						"qValue": "1",
						"foldChange": "1.2840"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".484080693477979",
						"qValue": "1",
						"foldChange": "1.2194"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".481332087237184",
						"qValue": "1",
						"foldChange": "2.6025"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".548243435386778",
						"qValue": "1",
						"foldChange": "2.6701"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".953369101150528",
						"qValue": "1",
						"foldChange": "1.8674"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".510211953896559",
						"qValue": ".957839854315834",
						"foldChange": "1.3730"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".996604233478256",
						"qValue": "1",
						"foldChange": "1.2104"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".389055991318427",
						"qValue": "1",
						"foldChange": "2.8202"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".391376452788317",
						"qValue": "1",
						"foldChange": "1.9588"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".245203423328158",
						"qValue": "1",
						"foldChange": "0.7915"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".96985052905725",
						"qValue": "1",
						"foldChange": "1.0219"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".276571504916801",
						"qValue": "1",
						"foldChange": "1.2912"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".72227795165",
						"qValue": "1",
						"foldChange": "1.1955"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".323602648484951",
						"qValue": "1",
						"foldChange": "1.3441"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".502858139639164",
						"qValue": "1",
						"foldChange": "1.1243"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".502810706882802",
						"qValue": "1",
						"foldChange": "0.8139"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".256249567118502",
						"qValue": "1",
						"foldChange": "2.3042"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".073042042567968",
						"qValue": ".985830071188835",
						"foldChange": "2.8310"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".007642781107809",
						"qValue": ".012526994606525",
						"foldChange": "7.6563"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".086218979009656",
						"qValue": ".054890080963378",
						"foldChange": "1.9402"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002997238456546",
						"qValue": ".002239647970864",
						"foldChange": "3.1651"
					}
				]
			},
			{
				"metaboliteId": "0078dc4d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e108-0000-0000-0000-000000000000",
				"chemicalName": "benzoate",
				"plotName": null,
				"compoundId": "00003da2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01870",
				"inChiKey": "WPYMKLBDIGXBTP-UHFFFAOYAD",
				"kegg": "C00180",
				"mass": 122.03678,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4069,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".153107691224341",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".925353666896126",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".261419452873479",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".622360190869167",
						"qValue": "1",
						"foldChange": "1.1798"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".208405813129764",
						"qValue": "1",
						"foldChange": "1.2707"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".439567691787793",
						"qValue": "1",
						"foldChange": "1.1836"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".298492751033337",
						"qValue": ".911265664999995",
						"foldChange": "0.9578"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".11083012690322",
						"qValue": ".697458782960496",
						"foldChange": "0.9838"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".569061978844003",
						"qValue": ".957839854315834",
						"foldChange": "0.9942"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".298116178359408",
						"qValue": "1",
						"foldChange": "1.2626"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".771812388626087",
						"qValue": "1",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".450827366305164",
						"qValue": "1",
						"foldChange": "0.9186"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".646247085207927",
						"qValue": "1",
						"foldChange": "1.1216"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".250382367846339",
						"qValue": "1",
						"foldChange": "0.8243"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".106795605156247",
						"qValue": "1",
						"foldChange": "0.7350"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".336303682860688",
						"qValue": "1",
						"foldChange": "0.8423"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".551888591471331",
						"qValue": "1",
						"foldChange": "0.8799"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".745850980198391",
						"qValue": "1",
						"foldChange": "1.0446"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".028619134709443",
						"qValue": "1",
						"foldChange": "0.7655"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".045829167336007",
						"qValue": "1",
						"foldChange": "0.6936"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".934824848460822",
						"qValue": ".997573179629986",
						"foldChange": "0.9060"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".407432955510945",
						"qValue": ".216501028847437",
						"foldChange": "0.7306"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".035365420766159",
						"qValue": ".02831447405639",
						"foldChange": "0.6758"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".616875281439099",
						"qValue": ".129815041123595",
						"foldChange": "1.0358"
					}
				]
			},
			{
				"metaboliteId": "0078dc4e-0000-0000-0000-000000000000",
				"chemicalId": "000000c5-0000-0000-0000-000000000000",
				"chemicalName": "S-adenosylhomocysteine (SAH)",
				"plotName": null,
				"compoundId": "0000a58e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00939",
				"inChiKey": null,
				"kegg": "C00021",
				"mass": 384.121588,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 382,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".662263307812853",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".789935563614973",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".905596583394379",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".686206131979213",
						"qValue": "1",
						"foldChange": "1.3643"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".996608578792001",
						"qValue": "1",
						"foldChange": "1.0241"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".689326493067522",
						"qValue": "1",
						"foldChange": "1.5258"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".730499282907811",
						"qValue": "1",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".305883245104747",
						"qValue": ".825038702902081",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".494475038849816",
						"qValue": ".957839854315834",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".768348211662992",
						"qValue": "1",
						"foldChange": "1.0948"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".854820723874892",
						"qValue": "1",
						"foldChange": "1.0357"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".633289055545825",
						"qValue": "1",
						"foldChange": "0.9181"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".911151996000705",
						"qValue": "1",
						"foldChange": "0.9772"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".752394454818982",
						"qValue": "1",
						"foldChange": "0.9550"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".829631330176159",
						"qValue": "1",
						"foldChange": "0.9772"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".856642633229978",
						"qValue": "1",
						"foldChange": "0.8676"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".358691069324688",
						"qValue": "1",
						"foldChange": "1.0130"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".443327289432249",
						"qValue": "1",
						"foldChange": "1.1676"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".450215320394005",
						"qValue": "1",
						"foldChange": "0.8166"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".876588183867786",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".373812460703083",
						"qValue": ".985830071188835",
						"foldChange": "1.1779"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000255847609602",
						"qValue": ".000984985304151",
						"foldChange": "4.8183"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000032692418533",
						"qValue": ".000350605657304",
						"foldChange": "3.5232"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000080754628591",
						"qValue": ".000196718178393",
						"foldChange": "4.7165"
					}
				]
			},
			{
				"metaboliteId": "0078dc4f-0000-0000-0000-000000000000",
				"chemicalId": "05f5eab9-0000-0000-0000-000000000000",
				"chemicalName": "oxypurinol",
				"plotName": null,
				"compoundId": "0000a2fe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00786",
				"inChiKey": "HXNFUBHNUDHIGC-UHFFFAOYAQ",
				"kegg": "D02365",
				"mass": 152.033425,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Metabolic",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5042,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".382696706677089",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".378404075121554",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".416838497242706",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".029866843219447",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".797689737519243",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".053375805470375",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".109625187142754",
						"qValue": "1",
						"foldChange": "0.9764"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".129791888351371",
						"qValue": "1",
						"foldChange": "0.9764"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".155692196385309",
						"qValue": "1",
						"foldChange": "0.9771"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".178972152456317",
						"qValue": "1",
						"foldChange": "0.9771"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.0234"
					}
				]
			},
			{
				"metaboliteId": "0078dc50-0000-0000-0000-000000000000",
				"chemicalId": "000000fc-0000-0000-0000-000000000000",
				"chemicalName": "succinate",
				"plotName": null,
				"compoundId": "0000059d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00254",
				"inChiKey": "KDYFGRWQOYBRFD-UHFFFAOYAC",
				"kegg": "C00042",
				"mass": 118.02661,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1292,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".986571821400187",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".816200342211471",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".688839405504479",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".163742600648326",
						"qValue": "1",
						"foldChange": "0.8953"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".659677923042843",
						"qValue": "1",
						"foldChange": "0.9528"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".336346608783528",
						"qValue": "1",
						"foldChange": "1.3050"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".572993071062562",
						"qValue": "1",
						"foldChange": "1.2690"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".971637250198645",
						"qValue": "1",
						"foldChange": "1.1081"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".549162743006399",
						"qValue": ".957839854315834",
						"foldChange": "1.0995"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".841185541066565",
						"qValue": "1",
						"foldChange": "0.9843"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".810840465616497",
						"qValue": "1",
						"foldChange": "0.9894"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".968895079915484",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".463504126301926",
						"qValue": "1",
						"foldChange": "0.8552"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".619232939048282",
						"qValue": "1",
						"foldChange": "0.8138"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".839881023620631",
						"qValue": "1",
						"foldChange": "0.9516"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".339248972605961",
						"qValue": "1",
						"foldChange": "1.1799"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".626350584271324",
						"qValue": "1",
						"foldChange": "1.0320"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".66751078930513",
						"qValue": "1",
						"foldChange": "0.8746"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".704321135627163",
						"qValue": "1",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".731456483392144",
						"qValue": "1",
						"foldChange": "0.9179"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".98716994957315",
						"qValue": "1",
						"foldChange": "0.9167"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002809561878222",
						"qValue": ".005887465199241",
						"foldChange": "1.5584"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".059351290778696",
						"qValue": ".041898471430646",
						"foldChange": "1.5348"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000387166171484",
						"qValue": ".000532025621705",
						"foldChange": "1.6682"
					}
				]
			},
			{
				"metaboliteId": "0078dc51-0000-0000-0000-000000000000",
				"chemicalId": "0000033c-0000-0000-0000-000000000000",
				"chemicalName": "arabinose",
				"plotName": null,
				"compoundId": "0000023f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00646",
				"inChiKey": "PYMYPHUHKUWMLA-WDCZJNDABW",
				"kegg": "C00216",
				"mass": 150.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".468690434070284",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".126558145258702",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".28940968511156",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".21327249579862",
						"qValue": "1",
						"foldChange": "1.0313"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".463018471240932",
						"qValue": "1",
						"foldChange": "1.3897"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".051044617777723",
						"qValue": "1",
						"foldChange": "2.6027"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".770516117980403",
						"qValue": "1",
						"foldChange": "1.5511"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".713504303408842",
						"qValue": ".950986052805043",
						"foldChange": "1.7890"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".939752140244053",
						"qValue": "1",
						"foldChange": "1.6250"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".036300619338452",
						"qValue": ".821720595261736",
						"foldChange": "0.5855"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".197163380353395",
						"qValue": "1",
						"foldChange": "0.9977"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".401721077864375",
						"qValue": "1",
						"foldChange": "1.6417"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".251578631405257",
						"qValue": "1",
						"foldChange": "0.6553"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".804935678382731",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".173959981905342",
						"qValue": "1",
						"foldChange": "1.6113"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".803994502321782",
						"qValue": "1",
						"foldChange": "1.3267"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".527351786852367",
						"qValue": "1",
						"foldChange": "0.7529"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".375476940729085",
						"qValue": "1",
						"foldChange": "0.5675"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".135238818638687",
						"qValue": "1",
						"foldChange": "0.6436"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".116958578902336",
						"qValue": "1",
						"foldChange": "0.6271"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".871518649988471",
						"qValue": ".997573179629986",
						"foldChange": "0.9743"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".468265116951665",
						"qValue": ".238520267863026",
						"foldChange": "1.5240"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".106460783304802",
						"qValue": ".063978902054771",
						"foldChange": "1.8890"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".021528720799749",
						"qValue": ".010003756968623",
						"foldChange": "2.4054"
					}
				]
			},
			{
				"metaboliteId": "0078dc52-0000-0000-0000-000000000000",
				"chemicalId": "000003ea-0000-0000-0000-000000000000",
				"chemicalName": "allantoin",
				"plotName": null,
				"compoundId": "00000453-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00462",
				"inChiKey": "POJWUDADGALRAB-UHFFFAOYAE",
				"kegg": "C02350",
				"mass": 158.043991,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3755,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".742917548970951",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".433451247708718",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".985702820518361",
						"qValue": ".999283527063851",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".969766688026985",
						"qValue": "1",
						"foldChange": "1.2155"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".421350364639988",
						"qValue": "1",
						"foldChange": "0.8984"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".443351453795283",
						"qValue": "1",
						"foldChange": "1.0901"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".942072296392062",
						"qValue": "1",
						"foldChange": "1.0911"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".43181658017961",
						"qValue": ".894260227315856",
						"foldChange": "1.0314"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".39090107153546",
						"qValue": ".957839854315834",
						"foldChange": "0.9240"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".628558939201595",
						"qValue": "1",
						"foldChange": "0.9276"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".575044879031801",
						"qValue": "1",
						"foldChange": "0.9525"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".938456227310333",
						"qValue": "1",
						"foldChange": "0.9948"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".612402462933821",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".770659825890029",
						"qValue": "1",
						"foldChange": "0.9730"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".847336471093624",
						"qValue": "1",
						"foldChange": "0.9405"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".54911389532335",
						"qValue": "1",
						"foldChange": "1.0245"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".921027223581539",
						"qValue": "1",
						"foldChange": "0.9086"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".495602346342474",
						"qValue": "1",
						"foldChange": "0.8869"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".577252290690187",
						"qValue": "1",
						"foldChange": "1.0366"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".642191927766792",
						"qValue": "1",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".94879675179716",
						"qValue": ".99847190363668",
						"foldChange": "0.9389"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000758386055",
						"qValue": ".000036377288921",
						"foldChange": "2.5477"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00002564208684",
						"qValue": ".000297925194193",
						"foldChange": "2.6704"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008001983464184",
						"qValue": ".004563117531926",
						"foldChange": "2.3486"
					}
				]
			},
			{
				"metaboliteId": "0078dc53-0000-0000-0000-000000000000",
				"chemicalId": "05f5e44e-0000-0000-0000-000000000000",
				"chemicalName": "erythritol",
				"plotName": null,
				"compoundId": "000050db-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02994",
				"inChiKey": "UNXHWFMMPAWVPI-UHFFFAOYAU",
				"kegg": "C00503",
				"mass": 122.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4403,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".993928059010278",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".670653205346969",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".785839727188818",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".652994208235535",
						"qValue": "1",
						"foldChange": "1.1626"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".658969391822581",
						"qValue": "1",
						"foldChange": "1.0959"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".993380928572016",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".652922836674281",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".698949839560113",
						"qValue": ".950986052805043",
						"foldChange": "1.1522"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".404186601637715",
						"qValue": ".957839854315834",
						"foldChange": "1.3128"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".234358417405567",
						"qValue": "1",
						"foldChange": "1.6402"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".583826725559911",
						"qValue": "1",
						"foldChange": "1.1707"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".516987363317399",
						"qValue": "1",
						"foldChange": "1.2145"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".771674828073303",
						"qValue": "1",
						"foldChange": "1.0352"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".840311163318168",
						"qValue": "1",
						"foldChange": "0.9060"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".626137380705184",
						"qValue": "1",
						"foldChange": "0.8752"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".620564820957868",
						"qValue": "1",
						"foldChange": "0.8738"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".621869897695247",
						"qValue": "1",
						"foldChange": "1.1879"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".326101388969958",
						"qValue": "1",
						"foldChange": "1.3595"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".823224339898347",
						"qValue": "1",
						"foldChange": "1.0518"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".934014384422043",
						"qValue": "1",
						"foldChange": "0.9223"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".763301827687768",
						"qValue": ".997573179629986",
						"foldChange": "0.8769"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".45516171191386",
						"qValue": ".233643038757568",
						"foldChange": "1.2256"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".015471353430848",
						"qValue": ".014673917839994",
						"foldChange": "1.5164"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".209611279449944",
						"qValue": ".057313684477154",
						"foldChange": "1.2262"
					}
				]
			},
			{
				"metaboliteId": "0078dc54-0000-0000-0000-000000000000",
				"chemicalId": "05f5e53e-0000-0000-0000-000000000000",
				"chemicalName": "N-(2-furoyl)glycine",
				"plotName": null,
				"compoundId": "00007b30-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00439",
				"inChiKey": "KSPQDMRTZZYQLM-UHFFFAOYAM",
				"kegg": null,
				"mass": 169.037508,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4470,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".774064725360686",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".618272920121034",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".706390757689642",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".723967265113306",
						"qValue": "1",
						"foldChange": "4.7308"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".444384262909632",
						"qValue": "1",
						"foldChange": "6.0137"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".679311816735254",
						"qValue": "1",
						"foldChange": "1.2358"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".30273737859141",
						"qValue": ".919428356526374",
						"foldChange": "1.1027"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".598654802137885",
						"qValue": ".928868848501546",
						"foldChange": "5.9051"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".122606817541237",
						"qValue": ".932820956170539",
						"foldChange": "7.4022"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".680415413817454",
						"qValue": "1",
						"foldChange": "1.3681"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".921989217394386",
						"qValue": "1",
						"foldChange": "1.2998"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".753393821055092",
						"qValue": "1",
						"foldChange": "0.8191"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".560617975056991",
						"qValue": "1",
						"foldChange": "2.5083"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".475980568464191",
						"qValue": "1",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".867004643493258",
						"qValue": "1",
						"foldChange": "0.3352"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".726530697954531",
						"qValue": "1",
						"foldChange": "0.2775"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".442697062553477",
						"qValue": "1",
						"foldChange": "2.1090"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".262655041008838",
						"qValue": "1",
						"foldChange": "7.5999"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".691949195600871",
						"qValue": "1",
						"foldChange": "1.8175"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".781891541692028",
						"qValue": "1",
						"foldChange": "2.0096"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".920037385951715",
						"qValue": ".997573179629986",
						"foldChange": "1.1057"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".010806968246581",
						"qValue": ".01626402499519",
						"foldChange": "49.5826"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".028808662920514",
						"qValue": ".024051476441686",
						"foldChange": "2.8728"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004908257845224",
						"qValue": ".003194856982991",
						"foldChange": "25.9308"
					}
				]
			},
			{
				"metaboliteId": "0078dc55-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6f7-0000-0000-0000-000000000000",
				"chemicalName": "hexanoylglycine",
				"plotName": null,
				"compoundId": "00008a6c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00701",
				"inChiKey": "UPCKIPHSXMXJOX-UHFFFAOYAU",
				"kegg": null,
				"mass": 173.105193,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1613,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".968165430025103",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".252231203897256",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".407827677682468",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".568506254265793",
						"qValue": "1",
						"foldChange": "1.2084"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".655543449075238",
						"qValue": "1",
						"foldChange": "1.1133"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".900779245465729",
						"qValue": "1",
						"foldChange": "1.0865"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".740340090787569",
						"qValue": "1",
						"foldChange": "1.1855"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".20004728793256",
						"qValue": ".825038702902081",
						"foldChange": "0.9244"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".109091598410077",
						"qValue": ".893591860856987",
						"foldChange": "0.9020"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".080183431770403",
						"qValue": ".886468253895902",
						"foldChange": "1.9336"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".194472332470594",
						"qValue": "1",
						"foldChange": "1.6389"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".639371283177484",
						"qValue": "1",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".660082393772234",
						"qValue": "1",
						"foldChange": "1.4515"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".513707025580849",
						"qValue": "1",
						"foldChange": "0.9535"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".275901243039334",
						"qValue": "1",
						"foldChange": "0.6569"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".787970571841453",
						"qValue": "1",
						"foldChange": "1.4263"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".861287947096301",
						"qValue": "1",
						"foldChange": "1.0998"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".934971617315116",
						"qValue": "1",
						"foldChange": "0.7711"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".500329070104735",
						"qValue": "1",
						"foldChange": "1.0038"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".951771249105832",
						"qValue": "1",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".554249019211815",
						"qValue": ".985830071188835",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".010281609149999",
						"qValue": ".015615339657787",
						"foldChange": "1.9865"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".060181499973831",
						"qValue": ".042147433578833",
						"foldChange": "2.2616"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000325636463754",
						"qValue": ".000467450913571",
						"foldChange": "2.5784"
					}
				]
			},
			{
				"metaboliteId": "0078dc56-0000-0000-0000-000000000000",
				"chemicalId": "05f5e752-0000-0000-0000-000000000000",
				"chemicalName": "1-myristoylglycerol (14:0)",
				"plotName": null,
				"compoundId": "00008b29-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11561",
				"inChiKey": "DCBSHORRWZKAKO-UHFFFAOYAJ",
				"kegg": "C01885",
				"mass": 302.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".713988940690556",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".482631287965271",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".617898752248942",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".186986133503016",
						"qValue": "1",
						"foldChange": "2.0127"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".312530506867333",
						"qValue": "1",
						"foldChange": "1.4060"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".751864289323877",
						"qValue": "1",
						"foldChange": "1.3013"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".852001625785978",
						"qValue": "1",
						"foldChange": "1.6220"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".245888288186438",
						"qValue": ".825038702902081",
						"foldChange": "1.0699"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".32850457091411",
						"qValue": ".957839854315834",
						"foldChange": "1.3828"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".80606589619332",
						"qValue": "1",
						"foldChange": "1.3055"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".605916075340781",
						"qValue": "1",
						"foldChange": "0.9019"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".447311400008431",
						"qValue": "1",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".330105672632733",
						"qValue": "1",
						"foldChange": "2.6018"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".94754627084094",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".380385729572958",
						"qValue": "1",
						"foldChange": "0.3764"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".93791796371139",
						"qValue": "1",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".520661881679658",
						"qValue": "1",
						"foldChange": "0.7803"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".559925912119675",
						"qValue": "1",
						"foldChange": "0.7555"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".611312881464103",
						"qValue": "1",
						"foldChange": "0.9728"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".33464897915731",
						"qValue": "1",
						"foldChange": "0.5890"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".617247534368946",
						"qValue": ".985830071188835",
						"foldChange": "0.6055"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006870710234065",
						"qValue": ".011547347761294",
						"foldChange": "0.3310"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052351476584145",
						"qValue": ".038471747600022",
						"foldChange": "0.5484"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".211006560710209",
						"qValue": ".057402325045719",
						"foldChange": "0.5720"
					}
				]
			},
			{
				"metaboliteId": "0078dc57-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8ed-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3beta,17beta) monosulfate (2)",
				"plotName": null,
				"compoundId": "0000915a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3029,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".739305544793705",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".964971864158484",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".1934602094594",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".247513346131822",
						"qValue": "1",
						"foldChange": "0.8808"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".34973434946561",
						"qValue": "1",
						"foldChange": "0.8872"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".821897096075551",
						"qValue": "1",
						"foldChange": "1.0783"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".044990002989054",
						"qValue": ".466908445654697",
						"foldChange": "2.0118"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".190145064344762",
						"qValue": ".821388069834477",
						"foldChange": "1.6002"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".469201509048073",
						"qValue": ".957839854315834",
						"foldChange": "0.9239"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".520716321397885",
						"qValue": "1",
						"foldChange": "0.9269"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".535398627994206",
						"qValue": "1",
						"foldChange": "0.9259"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".981971892809012",
						"qValue": "1",
						"foldChange": "0.9569"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".993130103057382",
						"qValue": "1",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".767178287329534",
						"qValue": "1",
						"foldChange": "1.0454"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".75534495611529",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".202991800721979",
						"qValue": "1",
						"foldChange": "1.4462"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".627075804097936",
						"qValue": "1",
						"foldChange": "0.9803"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".454504281506222",
						"qValue": "1",
						"foldChange": "0.6778"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".366860106639929",
						"qValue": "1",
						"foldChange": "1.1952"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".684228160400753",
						"qValue": "1",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".644037974601023",
						"qValue": ".985830071188835",
						"foldChange": "0.8208"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".193462392714477",
						"qValue": ".130988911769559",
						"foldChange": "1.8545"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".071084774482487",
						"qValue": ".048463903151471",
						"foldChange": "1.6061"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".361076206712448",
						"qValue": ".085876271639878",
						"foldChange": "1.3575"
					}
				]
			},
			{
				"metaboliteId": "0078dc58-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8e1-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol disulfate",
				"plotName": null,
				"compoundId": "00009140-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 452.153859,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3047,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".758357665147784",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".702304248680726",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".976990759098027",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".592675995772581",
						"qValue": "1",
						"foldChange": "6.1354"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".753775210970885",
						"qValue": "1",
						"foldChange": "3.8935"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".397329895829253",
						"qValue": "1",
						"foldChange": "0.9520"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".955942882053033",
						"qValue": "1",
						"foldChange": "6.8033"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".822904611612847",
						"qValue": ".99473270523087",
						"foldChange": "3.8080"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".780243407074161",
						"qValue": ".991025581224046",
						"foldChange": "1.2619"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".57959114586145",
						"qValue": "1",
						"foldChange": "3.2741"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".730102567178557",
						"qValue": "1",
						"foldChange": "1.3664"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".833961850930426",
						"qValue": "1",
						"foldChange": "2.2632"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".751577896809389",
						"qValue": "1",
						"foldChange": "1.4736"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".589779839227908",
						"qValue": "1",
						"foldChange": "2.8465"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".805293091220358",
						"qValue": "1",
						"foldChange": "1.9317"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".954515285941326",
						"qValue": "1",
						"foldChange": "1.2854"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".571534968324908",
						"qValue": "1",
						"foldChange": "2.2066"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".599832838265761",
						"qValue": "1",
						"foldChange": "1.7166"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".710685191064923",
						"qValue": "1",
						"foldChange": "0.8938"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".378392549137808",
						"qValue": "1",
						"foldChange": "2.2631"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".584669679191554",
						"qValue": ".985830071188835",
						"foldChange": "2.5320"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032627424180738",
						"qValue": ".03674365013778",
						"foldChange": "29.2317"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".064928213394791",
						"qValue": ".044703706764271",
						"foldChange": "5.0539"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".059814647839664",
						"qValue": ".021806700875963",
						"foldChange": "18.5792"
					}
				]
			},
			{
				"metaboliteId": "0078dc59-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8e8-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3beta,17beta-diol monosulfate (2)",
				"plotName": null,
				"compoundId": "00009148-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 372.197045,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3052,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".257616959968274",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".99204563881024",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".222711801096938",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".32002476950421",
						"qValue": "1",
						"foldChange": "0.8687"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".272800768719854",
						"qValue": "1",
						"foldChange": "0.8641"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".917169840117968",
						"qValue": "1",
						"foldChange": "1.0567"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".072934649763235",
						"qValue": ".593497652236823",
						"foldChange": "3.5195"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".131024316865812",
						"qValue": ".746945121629492",
						"foldChange": "2.3535"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".767625890947767",
						"qValue": ".98622264532067",
						"foldChange": "1.0291"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".466247801768175",
						"qValue": "1",
						"foldChange": "0.8813"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".602041033464059",
						"qValue": "1",
						"foldChange": "0.9188"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".834937475536945",
						"qValue": "1",
						"foldChange": "1.0688"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".035235093172827",
						"qValue": "1",
						"foldChange": "0.3723"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".752244213373086",
						"qValue": "1",
						"foldChange": "0.8063"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".080303150729851",
						"qValue": "1",
						"foldChange": "2.1657"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".28403062541499",
						"qValue": "1",
						"foldChange": "0.7335"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".818250517829864",
						"qValue": "1",
						"foldChange": "0.7001"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".419130806040177",
						"qValue": "1",
						"foldChange": "0.9544"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".254041933134775",
						"qValue": "1",
						"foldChange": "0.5676"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".916083546551079",
						"qValue": "1",
						"foldChange": "0.6366"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".31700139223709",
						"qValue": ".985830071188835",
						"foldChange": "1.1217"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".063158332111207",
						"qValue": ".058574592374204",
						"foldChange": "2.5123"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".518338906797121",
						"qValue": ".207966848206051",
						"foldChange": "1.6046"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".022141120914374",
						"qValue": ".010199882818042",
						"foldChange": "4.4247"
					}
				]
			},
			{
				"metaboliteId": "0078dc5a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7db-0000-0000-0000-000000000000",
				"chemicalName": "4-vinylphenol sulfate",
				"plotName": null,
				"compoundId": "00008d02-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB62775",
				"inChiKey": "IETVQHUKTKKBFF-UHFFFAOYAU",
				"kegg": "C05627",
				"mass": 200.014329,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4110,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".101985686178576",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".564347828118099",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".907778302682394",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".995197045160013",
						"qValue": "1",
						"foldChange": "1.2948"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".678257986711925",
						"qValue": "1",
						"foldChange": "1.8671"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".682658659107395",
						"qValue": "1",
						"foldChange": "1.9970"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".221728819814085",
						"qValue": ".842371543132976",
						"foldChange": "1.3080"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".414133456111713",
						"qValue": ".88881677553957",
						"foldChange": "1.2232"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".680548746054757",
						"qValue": ".976023509428735",
						"foldChange": "1.7091"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".521456108112654",
						"qValue": "1",
						"foldChange": "1.0296"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".751491591754992",
						"qValue": "1",
						"foldChange": "1.7934"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".744826910460704",
						"qValue": "1",
						"foldChange": "1.8017"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".463624617111271",
						"qValue": "1",
						"foldChange": "1.4110"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".053760396461892",
						"qValue": "1",
						"foldChange": "2.8594"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".196743163352068",
						"qValue": "1",
						"foldChange": "2.0266"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".889852982067066",
						"qValue": "1",
						"foldChange": "0.9286"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".142598545650947",
						"qValue": "1",
						"foldChange": "1.8991"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".103017850324427",
						"qValue": ".876681906260878",
						"foldChange": "2.0452"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".879133702919642",
						"qValue": "1",
						"foldChange": "1.7315"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".154816210929968",
						"qValue": "1",
						"foldChange": "2.1694"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".109849062649199",
						"qValue": ".985830071188835",
						"foldChange": "1.2529"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00131568860878",
						"qValue": ".003179650499826",
						"foldChange": "6.2411"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".533435698150213",
						"qValue": ".213409822160356",
						"foldChange": "1.2722"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".038348458792076",
						"qValue": ".015322205879831",
						"foldChange": "1.6294"
					}
				]
			},
			{
				"metaboliteId": "0078dc5b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e6-0000-0000-0000-000000000000",
				"chemicalName": "phenol sulfate",
				"plotName": null,
				"compoundId": "00007f29-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60015",
				"inChiKey": "CTYRPMDGLDAWRQ-UHFFFAOYAC",
				"kegg": "C02180",
				"mass": 173.998679,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".331948972872626",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".066710985485284",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".360302741600714",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".307387742536279",
						"qValue": "1",
						"foldChange": "1.2565"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".059555652904749",
						"qValue": "1",
						"foldChange": "1.7229"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".37362090068839",
						"qValue": "1",
						"foldChange": "1.2682"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".433572403591463",
						"qValue": ".994846434063709",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".937620959338193",
						"qValue": "1",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".389498552768952",
						"qValue": ".957839854315834",
						"foldChange": "1.3697"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".077750956645598",
						"qValue": ".886468253895902",
						"foldChange": "1.5629"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".047478837845519",
						"qValue": "1",
						"foldChange": "1.5888"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".817750171886281",
						"qValue": "1",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".139598111522685",
						"qValue": "1",
						"foldChange": "2.1939"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".241885221158633",
						"qValue": "1",
						"foldChange": "1.7635"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".807688310515132",
						"qValue": "1",
						"foldChange": "0.8038"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".544798064827053",
						"qValue": "1",
						"foldChange": "1.5913"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".116209240464444",
						"qValue": "1",
						"foldChange": "1.8277"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".297042484365422",
						"qValue": "1",
						"foldChange": "1.1486"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".569655877037355",
						"qValue": "1",
						"foldChange": "1.4238"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".202342543163574",
						"qValue": "1",
						"foldChange": "1.2111"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".443265910393328",
						"qValue": ".985830071188835",
						"foldChange": "0.8507"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000935381839514",
						"qValue": ".002497549400367",
						"foldChange": "2.1982"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".075794261479862",
						"qValue": ".050321333519318",
						"foldChange": "2.9163"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".29174684129333",
						"qValue": ".074218144085227",
						"foldChange": "1.6951"
					}
				]
			},
			{
				"metaboliteId": "0078dc5c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8cf-0000-0000-0000-000000000000",
				"chemicalName": "21-hydroxypregnenolone disulfate",
				"plotName": null,
				"compoundId": "0000b423-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CBOVWLYQUCVTFA-WPWXJNKXBZ",
				"kegg": null,
				"mass": 492.148774,
				"pathwayType": "ANIMAL",
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2937,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".69816753955932",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".768903284349447",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".715361009882795",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".261984422253517",
						"qValue": "1",
						"foldChange": "0.8568"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".820986357249902",
						"qValue": "1",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".368652190662626",
						"qValue": "1",
						"foldChange": "1.2179"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".815862501636779",
						"qValue": "1",
						"foldChange": "2.1779"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".366599731762655",
						"qValue": ".875723678608451",
						"foldChange": "2.0965"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".501452472844217",
						"qValue": ".957839854315834",
						"foldChange": "1.2762"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".873069192480335",
						"qValue": "1",
						"foldChange": "1.0366"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".56526047688193",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".677494003605223",
						"qValue": "1",
						"foldChange": "0.9202"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".71555527229859",
						"qValue": "1",
						"foldChange": "1.0807"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".546642015036972",
						"qValue": "1",
						"foldChange": "1.6171"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".334310087699946",
						"qValue": "1",
						"foldChange": "1.4963"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".789153088928765",
						"qValue": "1",
						"foldChange": "1.2534"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".307221000344161",
						"qValue": "1",
						"foldChange": "1.5877"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".428620390224405",
						"qValue": "1",
						"foldChange": "1.2667"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".885438910273918",
						"qValue": "1",
						"foldChange": "1.1645"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".664620919397136",
						"qValue": "1",
						"foldChange": "1.2975"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".760387806894383",
						"qValue": ".997573179629986",
						"foldChange": "1.1142"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".90031513335573",
						"qValue": ".371321601579918",
						"foldChange": "1.2560"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".778614685589627",
						"qValue": ".284180542086041",
						"foldChange": "1.0339"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".660270120985937",
						"qValue": ".137685521019958",
						"foldChange": "1.0900"
					}
				]
			},
			{
				"metaboliteId": "0078dc5d-0000-0000-0000-000000000000",
				"chemicalId": "0000016b-0000-0000-0000-000000000000",
				"chemicalName": "myo-inositol",
				"plotName": null,
				"compoundId": "00000464-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00211",
				"inChiKey": "CDAISMWEOUEBRE-GPIVLXJGBG",
				"kegg": "C00137",
				"mass": 180.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1945,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".846900933718044",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".993493289813221",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".957266131999391",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".714948326921495",
						"qValue": "1",
						"foldChange": "0.9820"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".987959616170993",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".703740223278223",
						"qValue": "1",
						"foldChange": "1.0962"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".750898810147713",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".736958478406082",
						"qValue": ".957498790731047",
						"foldChange": "1.0335"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".51424307790257",
						"qValue": ".957839854315834",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".870050517001364",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".838802464555103",
						"qValue": "1",
						"foldChange": "1.0532"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".968211051510132",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".616505644806981",
						"qValue": "1",
						"foldChange": "0.9371"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".828515203342158",
						"qValue": "1",
						"foldChange": "0.9600"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".792028206582869",
						"qValue": "1",
						"foldChange": "1.0244"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".998068481460164",
						"qValue": "1",
						"foldChange": "0.9941"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".866227378986757",
						"qValue": "1",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".86483984457014",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".452264455959754",
						"qValue": "1",
						"foldChange": "0.9241"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".941617142631964",
						"qValue": "1",
						"foldChange": "0.9056"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".513299382458675",
						"qValue": ".985830071188835",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00055707713064",
						"qValue": ".001707805520111",
						"foldChange": "1.8857"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005559321307784",
						"qValue": ".006889764276629",
						"foldChange": "1.6972"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000117694735381",
						"qValue": ".00023653099324",
						"foldChange": "2.0365"
					}
				]
			},
			{
				"metaboliteId": "0078dc5e-0000-0000-0000-000000000000",
				"chemicalId": "00000199-0000-0000-0000-000000000000",
				"chemicalName": "malate",
				"plotName": null,
				"compoundId": "00000517-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00156",
				"inChiKey": "BJEPYKJPYRNKOW-UHFFFAOYAM",
				"kegg": "C00149",
				"mass": 134.021525,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1295,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".711012336587725",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".995741147802078",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".310955400292279",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".528318454152932",
						"qValue": "1",
						"foldChange": "1.0744"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".397928219838417",
						"qValue": "1",
						"foldChange": "0.9134"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".82854922744129",
						"qValue": "1",
						"foldChange": "1.0428"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".254082410633507",
						"qValue": ".886796242976172",
						"foldChange": "1.2044"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".9107615130083",
						"qValue": "1",
						"foldChange": "1.0788"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".211270462960468",
						"qValue": ".957839854315834",
						"foldChange": "1.0097"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".714097487692487",
						"qValue": "1",
						"foldChange": "0.9794"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".296272041372479",
						"qValue": "1",
						"foldChange": "1.2096"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".16077340003404",
						"qValue": ".969566045102463",
						"foldChange": "1.2882"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".190571701644595",
						"qValue": "1",
						"foldChange": "0.7714"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".36907200989299",
						"qValue": "1",
						"foldChange": "0.8202"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".723979270069894",
						"qValue": "1",
						"foldChange": "1.0632"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".819627767321593",
						"qValue": "1",
						"foldChange": "0.9649"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".488078725879124",
						"qValue": "1",
						"foldChange": "0.8188"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".352680850261358",
						"qValue": "1",
						"foldChange": "0.8486"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".515623934231571",
						"qValue": "1",
						"foldChange": "0.9239"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".444975310641542",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".160270515888577",
						"qValue": ".985830071188835",
						"foldChange": "1.1605"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000004127067",
						"qValue": ".000001366433028",
						"foldChange": "2.8104"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000406220475888",
						"qValue": ".00128719470511",
						"foldChange": "1.9666"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000032967745163",
						"qValue": ".000123265571784",
						"foldChange": "2.2786"
					}
				]
			},
			{
				"metaboliteId": "0078dc5f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9cd-0000-0000-0000-000000000000",
				"chemicalName": "cinnamoylglycine",
				"plotName": null,
				"compoundId": "000096ed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11621",
				"inChiKey": "YAADMLWHGMUGQL-VOTSOKGWBK",
				"kegg": null,
				"mass": 205.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".096322645718661",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".908256697655664",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".999903082262637",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".930193379472572",
						"qValue": "1",
						"foldChange": "1.1265"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".800707658396818",
						"qValue": "1",
						"foldChange": "1.2832"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".869032711307592",
						"qValue": "1",
						"foldChange": "1.2195"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".986500995788803",
						"qValue": "1",
						"foldChange": "1.4020"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".891520754938511",
						"qValue": "1",
						"foldChange": "1.2194"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".904906803504756",
						"qValue": "1",
						"foldChange": "1.1999"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".897823171969732",
						"qValue": "1",
						"foldChange": "1.3758"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".73276456266534",
						"qValue": "1",
						"foldChange": "1.3647"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".831181981892253",
						"qValue": "1",
						"foldChange": "1.1844"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".057439408600378",
						"qValue": "1",
						"foldChange": "0.3776"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".81806849847692",
						"qValue": "1",
						"foldChange": "0.9319"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".103704927867299",
						"qValue": "1",
						"foldChange": "2.4677"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".054770231562467",
						"qValue": "1",
						"foldChange": "0.5123"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".829524527684532",
						"qValue": "1",
						"foldChange": "1.0923"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".096556258013634",
						"qValue": ".855930995516691",
						"foldChange": "2.1322"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".052914863275684",
						"qValue": "1",
						"foldChange": "0.3475"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".844017024349748",
						"qValue": "1",
						"foldChange": "0.7120"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".09022699252438",
						"qValue": ".985830071188835",
						"foldChange": "2.0492"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".106207984331914",
						"qValue": ".085766985987367",
						"foldChange": "3.7768"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".740507653329415",
						"qValue": ".274385598597047",
						"foldChange": "1.5399"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007387306192929",
						"qValue": ".004319531299569",
						"foldChange": "10.4782"
					}
				]
			},
			{
				"metaboliteId": "0078dc60-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec21-0000-0000-0000-000000000000",
				"chemicalName": "ethyl glucuronide",
				"plotName": null,
				"compoundId": "00009ab3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10325",
				"inChiKey": "IWJBVMJWSPZNJH-XWBUKDKVBJ",
				"kegg": null,
				"mass": 222.073953,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5235,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".393595950093141",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".859141426037985",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".901481670842229",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".89884981086616",
						"qValue": "1",
						"foldChange": "2.5669"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".875899996353309",
						"qValue": "1",
						"foldChange": "1.7547"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".976814024528873",
						"qValue": "1",
						"foldChange": "2.3996"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".285079075612764",
						"qValue": ".911265664999995",
						"foldChange": "3.4950"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".459243924955578",
						"qValue": ".902562957068497",
						"foldChange": "4.3423"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".739128642346936",
						"qValue": ".976023509428735",
						"foldChange": "1.1793"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".853354109366845",
						"qValue": "1",
						"foldChange": "1.3495"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".666934366777316",
						"qValue": "1",
						"foldChange": "1.6371"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".805816540671545",
						"qValue": "1",
						"foldChange": "2.0551"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".664124109782141",
						"qValue": "1",
						"foldChange": "0.8637"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".679411495692182",
						"qValue": "1",
						"foldChange": "1.7782"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".997836106189488",
						"qValue": "1",
						"foldChange": "2.0587"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".647305724007212",
						"qValue": "1",
						"foldChange": "1.6111"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".473017070385733",
						"qValue": "1",
						"foldChange": "0.7076"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".240317701314892",
						"qValue": "1",
						"foldChange": "0.4392"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".910057379423311",
						"qValue": "1",
						"foldChange": "2.0561"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".322495313418976",
						"qValue": "1",
						"foldChange": "0.6787"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".262105785407452",
						"qValue": ".985830071188835",
						"foldChange": "0.3301"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".017784046980825",
						"qValue": ".022911014075714",
						"foldChange": "3.7237"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004816833466932",
						"qValue": ".006328307675416",
						"foldChange": "11.2812"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00009682644891",
						"qValue": ".000204229996389",
						"foldChange": "5.4867"
					}
				]
			},
			{
				"metaboliteId": "0078dc61-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebae-0000-0000-0000-000000000000",
				"chemicalName": "hydrochlorothiazide",
				"plotName": null,
				"compoundId": "00009ac9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01928",
				"inChiKey": "JZUFKLXOESDKRF-UHFFFAOYAN",
				"kegg": "C07041",
				"mass": 296.964475,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4982,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".208851616424383",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".588439705357937",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".920697057365332",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".668379008842036",
						"qValue": "1",
						"foldChange": "0.9728"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".254257389022842",
						"qValue": "1",
						"foldChange": "0.9287"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".473269611412643",
						"qValue": "1",
						"foldChange": "0.9620"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".987275819473009",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".49537664554385",
						"qValue": ".902562957068497",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".485420338223001",
						"qValue": ".957839854315834",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".748066088512619",
						"qValue": "1",
						"foldChange": "1.0293"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".843470946338808",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".901427353151367",
						"qValue": "1",
						"foldChange": "0.9913"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".662298152344994",
						"qValue": "1",
						"foldChange": "1.1717"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".174541876348014",
						"qValue": "1",
						"foldChange": "0.4263"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".074558389441652",
						"qValue": "1",
						"foldChange": "0.3639"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".586472888870426",
						"qValue": "1",
						"foldChange": "0.9767"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".23444700223469",
						"qValue": "1",
						"foldChange": "0.4131"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".086711686271291",
						"qValue": ".834952786556829",
						"foldChange": "0.4229"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".577282162814859",
						"qValue": "1",
						"foldChange": "1.2414"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".289720462560034",
						"qValue": "1",
						"foldChange": "0.5306"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".110195454283143",
						"qValue": ".985830071188835",
						"foldChange": "0.4274"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087335",
						"qValue": ".192863611674612",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052930716199092",
						"qValue": ".038471747600022",
						"foldChange": "2.3755"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".1427911802",
						"qValue": ".042910997167964",
						"foldChange": "1.9136"
					}
				]
			},
			{
				"metaboliteId": "0078dc62-0000-0000-0000-000000000000",
				"chemicalId": "05f5f755-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPG (16:0)*",
				"plotName": null,
				"compoundId": "0000b392-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BVJSKAUUFXBDOB-UHFFFAOYAJ",
				"kegg": null,
				"mass": 484.28012,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2415,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".704713054944106",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".231349028701794",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".486935990766643",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".760646460797308",
						"qValue": "1",
						"foldChange": "1.2304"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".883833006069293",
						"qValue": "1",
						"foldChange": "1.3153"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".873959415461009",
						"qValue": "1",
						"foldChange": "1.1543"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".732113227102698",
						"qValue": "1",
						"foldChange": "1.3364"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".674650294939734",
						"qValue": ".937755719066778",
						"foldChange": "1.2747"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".447030781563777",
						"qValue": ".957839854315834",
						"foldChange": "0.9728"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".211566946942059",
						"qValue": "1",
						"foldChange": "0.8046"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".024305642819126",
						"qValue": ".884352967171025",
						"foldChange": "0.6456"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".294648013827129",
						"qValue": "1",
						"foldChange": "0.9172"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".662008703285394",
						"qValue": "1",
						"foldChange": "1.0568"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".308284058017728",
						"qValue": "1",
						"foldChange": "1.0855"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".533128415014833",
						"qValue": "1",
						"foldChange": "1.0272"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".319424731619991",
						"qValue": "1",
						"foldChange": "1.4073"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".878649054526579",
						"qValue": "1",
						"foldChange": "1.0121"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".418127936876404",
						"qValue": "1",
						"foldChange": "0.7192"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".832034525716621",
						"qValue": "1",
						"foldChange": "1.0843"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".356707598756623",
						"qValue": "1",
						"foldChange": "0.6606"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".251449300751631",
						"qValue": ".985830071188835",
						"foldChange": "0.6092"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".833838909827817",
						"qValue": ".354412000063486",
						"foldChange": "1.2300"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".05811105935338",
						"qValue": ".041178340734901",
						"foldChange": "2.0055"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".052555836292547",
						"qValue": ".01983500698495",
						"foldChange": "2.0224"
					}
				]
			},
			{
				"metaboliteId": "0078dc63-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed71-0000-0000-0000-000000000000",
				"chemicalName": "leucylglycine",
				"plotName": null,
				"compoundId": "00009c6d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28929",
				"inChiKey": "LESXFEZIFXFIQR-UHFFFAOYAD",
				"kegg": null,
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 782,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".146460848066828",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".92602286479011",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".894232838232735",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".94420161544463",
						"qValue": "1",
						"foldChange": "2.5167"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".990081061963972",
						"qValue": "1",
						"foldChange": "1.1386"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".95409996591177",
						"qValue": "1",
						"foldChange": "2.2505"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".987727741324864",
						"qValue": "1",
						"foldChange": "1.5384"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".705230449440008",
						"qValue": ".950986052805043",
						"foldChange": "1.6361"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".716663843325915",
						"qValue": ".976023509428735",
						"foldChange": "1.9327"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".978028860971418",
						"qValue": "1",
						"foldChange": "1.1209"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".40089888345242",
						"qValue": "1",
						"foldChange": "2.1020"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".385808932038435",
						"qValue": "1",
						"foldChange": "2.0301"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".644083038426237",
						"qValue": "1",
						"foldChange": "1.1096"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".760826677469695",
						"qValue": "1",
						"foldChange": "1.1413"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".448306250883044",
						"qValue": "1",
						"foldChange": "1.0286"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".685399656605407",
						"qValue": "1",
						"foldChange": "0.8644"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".730823744668771",
						"qValue": "1",
						"foldChange": "1.0483"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".457023353304048",
						"qValue": "1",
						"foldChange": "1.2127"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".413831465911659",
						"qValue": "1",
						"foldChange": "0.7122"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".237633648901546",
						"qValue": "1",
						"foldChange": "1.4884"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".047528988525629",
						"qValue": ".985830071188835",
						"foldChange": "2.0898"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001277383515293",
						"qValue": ".003134085450157",
						"foldChange": "6.1846"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001077213636765",
						"qValue": ".002241620813726",
						"foldChange": "2.9594"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001046123637055",
						"qValue": ".001085106393953",
						"foldChange": "4.1551"
					}
				]
			},
			{
				"metaboliteId": "0078dc64-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8c5-0000-0000-0000-000000000000",
				"chemicalName": "glycocholenate sulfate*",
				"plotName": null,
				"compoundId": "00007f57-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 511.260373,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3162,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".227246627911433",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".002201985935155",
						"qValue": ".267698575830956",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".582289965390957",
						"qValue": ".996704603416188",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".023312810013622",
						"qValue": "1",
						"foldChange": "0.8062"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".240188250864223",
						"qValue": "1",
						"foldChange": "0.9028"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".254256634269186",
						"qValue": "1",
						"foldChange": "1.1750"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007554519521121",
						"qValue": ".237900912197889",
						"foldChange": "0.8192"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".009792851356315",
						"qValue": ".567215491640796",
						"foldChange": "0.8228"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".921616986778221",
						"qValue": "1",
						"foldChange": "1.0378"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".402627169555241",
						"qValue": "1",
						"foldChange": "0.9322"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".143462968573446",
						"qValue": "1",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".52332630066294",
						"qValue": "1",
						"foldChange": "0.9170"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".06537717428392",
						"qValue": "1",
						"foldChange": "1.2821"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".550436580152462",
						"qValue": "1",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".229294983974373",
						"qValue": "1",
						"foldChange": "0.7439"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".092549027120079",
						"qValue": "1",
						"foldChange": "1.3272"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".175482584477577",
						"qValue": "1",
						"foldChange": "1.2235"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".79801025605061",
						"qValue": "1",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".268328931351887",
						"qValue": "1",
						"foldChange": "1.1237"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".696048828094964",
						"qValue": "1",
						"foldChange": "0.9440"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".497424828046974",
						"qValue": ".985830071188835",
						"foldChange": "0.8401"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".288403906301329",
						"qValue": ".174247846090522",
						"foldChange": "0.8426"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".7401119582205",
						"qValue": ".274385598597047",
						"foldChange": "0.9128"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".373503163345029",
						"qValue": ".088309286800635",
						"foldChange": "0.8473"
					}
				]
			},
			{
				"metaboliteId": "0078dc65-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef66-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoylglycine",
				"plotName": null,
				"compoundId": "0000a46c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13034",
				"inChiKey": "KVTFEOAKFFQCCX-UHFFFAOYAT",
				"kegg": null,
				"mass": 313.261694,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1620,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".491050048004811",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".023646640328003",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".552069381285767",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".179511949179977",
						"qValue": "1",
						"foldChange": "0.8403"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".06349221751185",
						"qValue": "1",
						"foldChange": "0.7954"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".593377655185004",
						"qValue": "1",
						"foldChange": "0.9918"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".356114236223384",
						"qValue": ".951252030575746",
						"foldChange": "1.2237"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".445867077220746",
						"qValue": ".900630141528144",
						"foldChange": "1.0734"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".095521985318819",
						"qValue": ".855675889540161",
						"foldChange": "0.8885"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".998868424453357",
						"qValue": "1",
						"foldChange": "1.0518"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".082833404294425",
						"qValue": "1",
						"foldChange": "0.8099"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".08307350088671",
						"qValue": ".783582084395718",
						"foldChange": "0.8339"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".978179165295209",
						"qValue": "1",
						"foldChange": "1.0731"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".73205147253068",
						"qValue": "1",
						"foldChange": "1.0253"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".745718856305906",
						"qValue": "1",
						"foldChange": "0.9554"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".081677478675264",
						"qValue": "1",
						"foldChange": "1.4877"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".184327605161061",
						"qValue": "1",
						"foldChange": "1.2431"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".734251362312009",
						"qValue": "1",
						"foldChange": "0.8356"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".352477519662757",
						"qValue": "1",
						"foldChange": "1.3391"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".720660295577154",
						"qValue": "1",
						"foldChange": "1.0521"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".591197974423347",
						"qValue": ".985830071188835",
						"foldChange": "0.7857"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".249075865878988",
						"qValue": ".156590392054259",
						"foldChange": "1.3896"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".127733756544292",
						"qValue": ".074050111092078",
						"foldChange": "1.5954"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".099228394812306",
						"qValue": ".032624819120009",
						"foldChange": "1.3652"
					}
				]
			},
			{
				"metaboliteId": "0078dc66-0000-0000-0000-000000000000",
				"chemicalId": "05f5e96a-0000-0000-0000-000000000000",
				"chemicalName": "ergothioneine",
				"plotName": null,
				"compoundId": "00009253-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03045",
				"inChiKey": "SSISHJJTAXXQAX-UHFFFAOYAO",
				"kegg": "C05570",
				"mass": 229.088497,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".796398096696391",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".333609411006608",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".993903456178337",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".436226960972457",
						"qValue": "1",
						"foldChange": "1.9017"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".922785688261199",
						"qValue": "1",
						"foldChange": "1.0064"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".381861445142645",
						"qValue": "1",
						"foldChange": "1.1062"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".323550877752065",
						"qValue": ".942586781423443",
						"foldChange": "1.3611"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".852833089857052",
						"qValue": "1",
						"foldChange": "1.3264"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".421404676527605",
						"qValue": ".957839854315834",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".7339682331305",
						"qValue": "1",
						"foldChange": "1.0751"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".723637664314164",
						"qValue": "1",
						"foldChange": "0.9553"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".488845599910234",
						"qValue": "1",
						"foldChange": "0.8506"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".739275853027688",
						"qValue": "1",
						"foldChange": "1.0004"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".677213880956112",
						"qValue": "1",
						"foldChange": "0.9251"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".916964106939019",
						"qValue": "1",
						"foldChange": "0.9248"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".822303022139086",
						"qValue": "1",
						"foldChange": "0.4796"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".494212072931572",
						"qValue": "1",
						"foldChange": "0.3916"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".628436396825945",
						"qValue": "1",
						"foldChange": "0.8164"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".881991458432959",
						"qValue": "1",
						"foldChange": "1.0212"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".552872274761091",
						"qValue": "1",
						"foldChange": "0.7512"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".642085880094408",
						"qValue": ".985830071188835",
						"foldChange": "0.7357"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003524397252522",
						"qValue": ".006706291768457",
						"foldChange": "2.5611"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000218720173758",
						"qValue": ".000898002474794",
						"foldChange": "3.7859"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001436795632425",
						"qValue": ".001335272490554",
						"foldChange": "3.2169"
					}
				]
			},
			{
				"metaboliteId": "0078dc67-0000-0000-0000-000000000000",
				"chemicalId": "00000471-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl ethanolamide",
				"plotName": null,
				"compoundId": "000094d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02088",
				"inChiKey": "BOWVQLFMWHZBEF-KTKRTIGZBW",
				"kegg": null,
				"mass": 325.298079,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1905,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".907560545130591",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".003717366250834",
						"qValue": ".369620097659866",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".1340000089029",
						"qValue": ".877184673664369",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".894643716283792",
						"qValue": "1",
						"foldChange": "1.0539"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".444563076655861",
						"qValue": "1",
						"foldChange": "0.9250"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".370508863388123",
						"qValue": "1",
						"foldChange": "0.9039"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".009079987282439",
						"qValue": ".266450661288133",
						"foldChange": "1.5033"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".111462273187576",
						"qValue": ".697458782960496",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000072884929972",
						"qValue": ".012405015081279",
						"foldChange": "0.6302"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".8236182231769",
						"qValue": "1",
						"foldChange": "1.0563"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".393991277225605",
						"qValue": "1",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".283670228852192",
						"qValue": "1",
						"foldChange": "0.9102"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".815274945940031",
						"qValue": "1",
						"foldChange": "0.9623"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".72754128630818",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".560207720142927",
						"qValue": "1",
						"foldChange": "1.0506"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".096044075860758",
						"qValue": "1",
						"foldChange": "1.3255"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".670233137137845",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".234529549503779",
						"qValue": "1",
						"foldChange": "0.7351"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".404757127783011",
						"qValue": "1",
						"foldChange": "0.8967"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".807554462835093",
						"qValue": "1",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".291993947398252",
						"qValue": ".985830071188835",
						"foldChange": "1.0877"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".082063379669495",
						"qValue": ".071313419522996",
						"foldChange": "1.6810"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".076028941445098",
						"qValue": ".050357244064712",
						"foldChange": "1.5072"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010110174047056",
						"qValue": ".005491461291536",
						"foldChange": "1.7217"
					}
				]
			},
			{
				"metaboliteId": "0078dc68-0000-0000-0000-000000000000",
				"chemicalId": "05f5e422-0000-0000-0000-000000000000",
				"chemicalName": "acetylcarnitine (C2)",
				"plotName": null,
				"compoundId": "00007dc6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00201",
				"inChiKey": "RDHQFKQIGNGIED-MRVPVSSYBU",
				"kegg": "C02571",
				"mass": 203.115758,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Short Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1634,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".563053818102892",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".697035986265066",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".664853150077443",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".888438395246046",
						"qValue": "1",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".785151453831849",
						"qValue": "1",
						"foldChange": "1.0284"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".679867402978457",
						"qValue": "1",
						"foldChange": "1.0426"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".990302239702584",
						"qValue": "1",
						"foldChange": "1.0198"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".538706845492391",
						"qValue": ".910963185640849",
						"foldChange": "0.9945"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".546717742401885",
						"qValue": ".957839854315834",
						"foldChange": "0.9821"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".135703095253391",
						"qValue": "1",
						"foldChange": "1.1434"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".453967240501058",
						"qValue": "1",
						"foldChange": "1.0860"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".449471225676793",
						"qValue": "1",
						"foldChange": "0.9368"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".64306580516799",
						"qValue": "1",
						"foldChange": "0.9869"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".347710648456588",
						"qValue": "1",
						"foldChange": "0.7488"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".162767090372688",
						"qValue": "1",
						"foldChange": "0.7588"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".604290396389514",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".8099411076549",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".455585222404571",
						"qValue": "1",
						"foldChange": "0.8485"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".922639981979353",
						"qValue": "1",
						"foldChange": "0.9435"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".465066978404255",
						"qValue": "1",
						"foldChange": "0.7867"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".400466315841943",
						"qValue": ".985830071188835",
						"foldChange": "0.8338"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".81445094226252",
						"qValue": ".349296688416094",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".01806345600075",
						"qValue": ".016678577555753",
						"foldChange": "1.3343"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".397531600873677",
						"qValue": ".093032764475269",
						"foldChange": "1.2567"
					}
				]
			},
			{
				"metaboliteId": "0078dc69-0000-0000-0000-000000000000",
				"chemicalId": "05f5e428-0000-0000-0000-000000000000",
				"chemicalName": "cysteine s-sulfate",
				"plotName": null,
				"compoundId": "000056a0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00731",
				"inChiKey": "NOKPBJYHPHHWAN-REOHCLBHBK",
				"kegg": "C05824",
				"mass": 200.976564,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 396,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".824704360509604",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".125361104860449",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".775272270541432",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".107215986540204",
						"qValue": "1",
						"foldChange": "0.7932"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".233721310197542",
						"qValue": "1",
						"foldChange": "1.0181"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".66511459591799",
						"qValue": "1",
						"foldChange": "1.3915"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".856938339088708",
						"qValue": "1",
						"foldChange": "1.0835"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".796938901956075",
						"qValue": ".985267275880213",
						"foldChange": "1.0334"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".938548615130136",
						"qValue": "1",
						"foldChange": "1.1096"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".10182364737209",
						"qValue": "1",
						"foldChange": "0.7849"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".452318393450751",
						"qValue": "1",
						"foldChange": "0.9971"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".367217159312262",
						"qValue": "1",
						"foldChange": "1.3631"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".460903476948905",
						"qValue": "1",
						"foldChange": "0.8283"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".624834778800912",
						"qValue": "1",
						"foldChange": "0.8825"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".830255528819281",
						"qValue": "1",
						"foldChange": "1.0654"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".607170536360143",
						"qValue": "1",
						"foldChange": "1.1583"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".554125282103537",
						"qValue": "1",
						"foldChange": "0.8779"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".271370576583011",
						"qValue": "1",
						"foldChange": "0.7580"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".935919759301902",
						"qValue": "1",
						"foldChange": "0.8704"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".86899265959647",
						"qValue": "1",
						"foldChange": "0.8199"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".805063982581333",
						"qValue": ".997573179629986",
						"foldChange": "0.9420"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".668223132245481",
						"qValue": ".313175797909121",
						"foldChange": "1.0030"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".349899018647143",
						"qValue": ".154136012850042",
						"foldChange": "0.8895"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".630636437030746",
						"qValue": ".132191856428335",
						"foldChange": "0.9862"
					}
				]
			},
			{
				"metaboliteId": "0078dc6a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e160-0000-0000-0000-000000000000",
				"chemicalName": "4-guanidinobutanoate",
				"plotName": null,
				"compoundId": "00003d41-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03464",
				"inChiKey": "TUHVEAJXIMEOSA-UHFFFAOYAM",
				"kegg": "C01035",
				"mass": 145.085127,
				"pathwayType": "ANIMAL",
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 486,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".904862639200632",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".190595080529705",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".89075945046009",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".937188103427849",
						"qValue": "1",
						"foldChange": "1.0411"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".125722106244616",
						"qValue": "1",
						"foldChange": "1.3285"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".145640923828166",
						"qValue": "1",
						"foldChange": "1.3876"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".687767943439747",
						"qValue": "1",
						"foldChange": "1.0730"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".404284667732811",
						"qValue": ".882901404171953",
						"foldChange": "1.2968"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".218903297443213",
						"qValue": ".957839854315834",
						"foldChange": "1.3513"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".997049912322295",
						"qValue": "1",
						"foldChange": "1.0888"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".80231031106608",
						"qValue": "1",
						"foldChange": "1.1583"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".799455429059865",
						"qValue": "1",
						"foldChange": "1.0028"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".924371587964212",
						"qValue": "1",
						"foldChange": "1.1085"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".618534011646491",
						"qValue": "1",
						"foldChange": "1.3219"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".675605031589226",
						"qValue": "1",
						"foldChange": "1.1925"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".856995354751317",
						"qValue": "1",
						"foldChange": "1.1069"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".651714976934944",
						"qValue": "1",
						"foldChange": "1.1359"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".524837509923676",
						"qValue": "1",
						"foldChange": "1.0262"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".721307576429572",
						"qValue": "1",
						"foldChange": "1.1423"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".82026934180092",
						"qValue": "1",
						"foldChange": "0.8076"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".910041266439027",
						"qValue": ".997573179629986",
						"foldChange": "0.7070"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000541869132375",
						"qValue": ".001697875856955",
						"foldChange": "3.4358"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002118588821802",
						"qValue": ".003414803198587",
						"foldChange": "3.9766"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000489053032942",
						"qValue": ".00061428087275",
						"foldChange": "3.0204"
					}
				]
			},
			{
				"metaboliteId": "0078dc6b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5bc-0000-0000-0000-000000000000",
				"chemicalName": "guanidinosuccinate",
				"plotName": null,
				"compoundId": "00007ebe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03157",
				"inChiKey": "VVHOUVWJCQOYGG-UHFFFAOYAC",
				"kegg": "C03139",
				"mass": 175.059306,
				"pathwayType": "ANIMAL",
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".196667430856024",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".263250953472019",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".719364695930312",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".634134509394419",
						"qValue": "1",
						"foldChange": "0.9801"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".199502803146201",
						"qValue": "1",
						"foldChange": "0.8662"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".415180339529411",
						"qValue": "1",
						"foldChange": "0.9728"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".856023570455053",
						"qValue": "1",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".138682474256877",
						"qValue": ".761411519952272",
						"foldChange": "0.9465"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".191991443497801",
						"qValue": ".957839854315834",
						"foldChange": "0.9262"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".433485642830896",
						"qValue": "1",
						"foldChange": "0.9253"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".886941537068658",
						"qValue": "1",
						"foldChange": "1.0574"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".520751291913402",
						"qValue": "1",
						"foldChange": "1.0872"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".487272871406553",
						"qValue": "1",
						"foldChange": "0.9421"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".08228315593613",
						"qValue": "1",
						"foldChange": "0.6616"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".257841885682556",
						"qValue": "1",
						"foldChange": "0.7023"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".605884349508359",
						"qValue": "1",
						"foldChange": "0.9735"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".054306802300252",
						"qValue": "1",
						"foldChange": "0.7268"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".134256690833272",
						"qValue": ".944235073546399",
						"foldChange": "0.7466"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".441933003013267",
						"qValue": "1",
						"foldChange": "0.8352"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".261016411657",
						"qValue": "1",
						"foldChange": "0.7610"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".679676945425933",
						"qValue": ".990272584968167",
						"foldChange": "0.9112"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003261834860671",
						"qValue": ".006429398770965",
						"foldChange": "2.4455"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002065426770851",
						"qValue": ".003387864144943",
						"foldChange": "2.2339"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000824464919717",
						"qValue": ".000933479624436",
						"foldChange": "2.8121"
					}
				]
			},
			{
				"metaboliteId": "0078dc6c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea71-0000-0000-0000-000000000000",
				"chemicalName": "2,3-dihydroxyisovalerate",
				"plotName": null,
				"compoundId": "00009584-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12141",
				"inChiKey": "JTEYKUFKXGDTEU-UHFFFAOYAT",
				"kegg": "C04039",
				"mass": 134.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4281,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".460961542805546",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".667863768588429",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".448822894572934",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".578911412823439",
						"qValue": "1",
						"foldChange": "1.8567"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".833569190910352",
						"qValue": "1",
						"foldChange": "2.3088"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".729731258342764",
						"qValue": "1",
						"foldChange": "2.3360"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".474953709273145",
						"qValue": "1",
						"foldChange": "3.8430"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".276149083510725",
						"qValue": ".825038702902081",
						"foldChange": "4.6159"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".70455256288191",
						"qValue": ".976023509428735",
						"foldChange": "1.4414"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".184044320072563",
						"qValue": "1",
						"foldChange": "5.2801"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".817046125791338",
						"qValue": "1",
						"foldChange": "2.3824"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".120574916504399",
						"qValue": ".952068279015587",
						"foldChange": "0.5294"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".155264469637131",
						"qValue": "1",
						"foldChange": "0.2603"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".169863324609534",
						"qValue": "1",
						"foldChange": "0.6736"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".97691526878743",
						"qValue": "1",
						"foldChange": "2.5879"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".688270454708559",
						"qValue": "1",
						"foldChange": "1.3182"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".858199829700862",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".567904522725791",
						"qValue": "1",
						"foldChange": "0.7476"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".697065752202046",
						"qValue": "1",
						"foldChange": "1.6028"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".161565476955422",
						"qValue": "1",
						"foldChange": "0.2503"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".287502215691805",
						"qValue": ".985830071188835",
						"foldChange": "0.1562"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".020804993897698",
						"qValue": ".025871048101588",
						"foldChange": "1.8895"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".060052387510997",
						"qValue": ".042147433578833",
						"foldChange": "7.8148"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004638797170686",
						"qValue": ".003081846639336",
						"foldChange": "6.6477"
					}
				]
			},
			{
				"metaboliteId": "0078dc6d-0000-0000-0000-000000000000",
				"chemicalId": "05f61e04-0000-0000-0000-000000000000",
				"chemicalName": "lactosyl-N-nervonoyl-sphingosine (d18:1/24:1)*",
				"plotName": null,
				"compoundId": "0000e01a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "MKOKWBRPIBQYJJ-VMHDMWLQBO",
				"kegg": null,
				"mass": 971.727292,
				"pathwayType": "ANIMAL",
				"subPathway": "Lactosylceramides (LCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2779,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".682885197365252",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".72528429283817",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".449487659315389",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".710762843162099",
						"qValue": "1",
						"foldChange": "1.1373"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".747515274718874",
						"qValue": "1",
						"foldChange": "0.9936"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".489281437829442",
						"qValue": "1",
						"foldChange": "1.0050"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".812191213886624",
						"qValue": "1",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".425234278896391",
						"qValue": ".893516966273651",
						"foldChange": "1.2026"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".302111940151802",
						"qValue": ".957839854315834",
						"foldChange": "1.1692"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".484606163775597",
						"qValue": "1",
						"foldChange": "1.0990"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".387826041060168",
						"qValue": "1",
						"foldChange": "0.9301"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".121627370361238",
						"qValue": ".952068279015587",
						"foldChange": "0.8206"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".511550757770052",
						"qValue": "1",
						"foldChange": "0.9110"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".490717131218266",
						"qValue": "1",
						"foldChange": "0.8757"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".943765696459274",
						"qValue": "1",
						"foldChange": "0.9613"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".312518057818626",
						"qValue": "1",
						"foldChange": "0.7911"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".631810434171931",
						"qValue": "1",
						"foldChange": "0.8669"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".622400243785113",
						"qValue": "1",
						"foldChange": "1.0958"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".993037599521602",
						"qValue": "1",
						"foldChange": "0.9959"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".305976096692912",
						"qValue": "1",
						"foldChange": "0.7882"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".298317201985891",
						"qValue": ".985830071188835",
						"foldChange": "0.7914"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".745352680533885",
						"qValue": ".330803296480837",
						"foldChange": "0.9720"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".295345740121326",
						"qValue": ".138646647408398",
						"foldChange": "1.2351"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".105259648858287",
						"qValue": ".033914262470467",
						"foldChange": "1.4104"
					}
				]
			},
			{
				"metaboliteId": "0078dc6e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e472-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxymyristate",
				"plotName": null,
				"compoundId": "000052a6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ATRNZOYKSNPPBF-UHFFFAOYAM",
				"kegg": null,
				"mass": 244.203845,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1751,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".716131762108713",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".159527200176119",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".544427508086995",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".60338848064376",
						"qValue": "1",
						"foldChange": "1.1226"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".624376302721074",
						"qValue": "1",
						"foldChange": "1.0787"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".976069893207155",
						"qValue": "1",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".926732331344015",
						"qValue": "1",
						"foldChange": "1.0444"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".093353425030669",
						"qValue": ".697458782960496",
						"foldChange": "0.9290"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".077440041442342",
						"qValue": ".794743555017874",
						"foldChange": "0.8717"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".95573695982307",
						"qValue": "1",
						"foldChange": "1.0160"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".164871229831842",
						"qValue": "1",
						"foldChange": "0.8725"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".1818874197905",
						"qValue": ".989315025499063",
						"foldChange": "0.8984"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".908268984175011",
						"qValue": "1",
						"foldChange": "1.0852"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".456091590789701",
						"qValue": "1",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".513969066940512",
						"qValue": "1",
						"foldChange": "1.0433"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".851427099723337",
						"qValue": "1",
						"foldChange": "1.0026"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".715346097964521",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".579322379727781",
						"qValue": "1",
						"foldChange": "0.9611"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".179343707646082",
						"qValue": "1",
						"foldChange": "0.8712"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".57643870999603",
						"qValue": "1",
						"foldChange": "0.8680"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".460339459958252",
						"qValue": ".985830071188835",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".470188283087041",
						"qValue": ".239003969947275",
						"foldChange": "0.8386"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".887414903164208",
						"qValue": ".309702737957111",
						"foldChange": "1.0574"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".188033334221074",
						"qValue": ".053317870297183",
						"foldChange": "1.1724"
					}
				]
			},
			{
				"metaboliteId": "0078dc6f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e780-0000-0000-0000-000000000000",
				"chemicalName": "N6-succinyladenosine",
				"plotName": null,
				"compoundId": "0000bc02-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00912",
				"inChiKey": null,
				"kegg": null,
				"mass": 383.107713,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".811824348446035",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".703621790321458",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".596575544901985",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".267550292983614",
						"qValue": "1",
						"foldChange": "0.9611"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".68177592840929",
						"qValue": "1",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".481630521399812",
						"qValue": "1",
						"foldChange": "1.3222"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".381621523244785",
						"qValue": ".958380391854625",
						"foldChange": "1.3546"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".728050649906736",
						"qValue": ".95612824547937",
						"foldChange": "1.2319"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".223659089191624",
						"qValue": ".957839854315834",
						"foldChange": "0.8908"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".756524990912973",
						"qValue": "1",
						"foldChange": "1.0631"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".640011939281264",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".437770888940333",
						"qValue": "1",
						"foldChange": "0.8745"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".917118520790733",
						"qValue": "1",
						"foldChange": "0.9954"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".920070322214698",
						"qValue": "1",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".838693793294676",
						"qValue": "1",
						"foldChange": "0.9498"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".154941168539942",
						"qValue": "1",
						"foldChange": "1.3230"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".241138517883997",
						"qValue": "1",
						"foldChange": "1.2892"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".855352673922924",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".967598842853267",
						"qValue": "1",
						"foldChange": "0.9537"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".971325738507044",
						"qValue": "1",
						"foldChange": "0.9114"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".939286049432352",
						"qValue": ".997575444606604",
						"foldChange": "0.9557"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000094556038001",
						"qValue": ".000474342643377",
						"foldChange": "3.7215"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000035205607652",
						"qValue": ".000350605657304",
						"foldChange": "2.9301"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001386624718594",
						"qValue": ".001319147136066",
						"foldChange": "2.9586"
					}
				]
			},
			{
				"metaboliteId": "0078dc70-0000-0000-0000-000000000000",
				"chemicalId": "05f5e579-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxysebacate",
				"plotName": null,
				"compoundId": "00007cc7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00350",
				"inChiKey": "OQYZCCKCJQWHIE-UHFFFAOYAK",
				"kegg": null,
				"mass": 218.115424,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1749,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".842314519090216",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".279378923701436",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".217963471083518",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".164785576402328",
						"qValue": "1",
						"foldChange": "0.9307"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".743786183607809",
						"qValue": "1",
						"foldChange": "1.1081"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".284679990319235",
						"qValue": "1",
						"foldChange": "1.3389"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".239130904824929",
						"qValue": ".852077213869098",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".035517082291583",
						"qValue": ".678234398444917",
						"foldChange": "0.7570"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".33619285047284",
						"qValue": ".957839854315834",
						"foldChange": "1.0889"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".319025718010705",
						"qValue": "1",
						"foldChange": "1.3500"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".685135590761045",
						"qValue": "1",
						"foldChange": "1.0273"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".163568033754371",
						"qValue": ".969566045102463",
						"foldChange": "0.8829"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".993595433638516",
						"qValue": "1",
						"foldChange": "0.7438"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".858304010405109",
						"qValue": "1",
						"foldChange": "0.9795"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".848794735500091",
						"qValue": "1",
						"foldChange": "1.3168"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".866013996486465",
						"qValue": "1",
						"foldChange": "2.0068"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".247337291717382",
						"qValue": "1",
						"foldChange": "1.7498"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".305323241109574",
						"qValue": "1",
						"foldChange": "0.8719"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".329303553699281",
						"qValue": "1",
						"foldChange": "0.7785"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".81426354280141",
						"qValue": "1",
						"foldChange": "0.9779"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".47900873625153",
						"qValue": ".985830071188835",
						"foldChange": "1.2561"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".284241470408987",
						"qValue": ".172678305137313",
						"foldChange": "1.7271"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".678827444285557",
						"qValue": ".25961235604587",
						"foldChange": "1.2802"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".028211676917397",
						"qValue": ".012292028007884",
						"foldChange": "1.8387"
					}
				]
			},
			{
				"metaboliteId": "0078dc71-0000-0000-0000-000000000000",
				"chemicalId": "00000215-0000-0000-0000-000000000000",
				"chemicalName": "urea",
				"plotName": null,
				"compoundId": "00000686-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00294",
				"inChiKey": "XSQUKJJJFZCRTK-UHFFFAOYAF",
				"kegg": "C00086",
				"mass": 60.032363,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 416,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".578392450720649",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".487509451203486",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".369779992017722",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".28747314933484",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".191362876504152",
						"qValue": "1",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".80394247704492",
						"qValue": "1",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".188232400451496",
						"qValue": ".797133099236872",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".438344012304273",
						"qValue": ".898869287881775",
						"foldChange": "0.9731"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".582615433511154",
						"qValue": ".957839854315834",
						"foldChange": "1.0532"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".211801687970412",
						"qValue": "1",
						"foldChange": "0.9260"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".920331226389533",
						"qValue": "1",
						"foldChange": "1.0091"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".249794900296313",
						"qValue": "1",
						"foldChange": "1.0427"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".564856657304136",
						"qValue": "1",
						"foldChange": "1.0695"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".913819376344567",
						"qValue": "1",
						"foldChange": "0.9734"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".504271640425831",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".34830726685587",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".116798544073574",
						"qValue": "1",
						"foldChange": "0.8088"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".474543094250665",
						"qValue": "1",
						"foldChange": "0.8909"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".446325491583624",
						"qValue": "1",
						"foldChange": "0.8886"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".326544091423105",
						"qValue": "1",
						"foldChange": "0.8066"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".786783313880369",
						"qValue": ".997573179629986",
						"foldChange": "0.9077"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".302814835656039",
						"qValue": ".180647104086847",
						"foldChange": "1.1722"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005201678519303",
						"qValue": ".006623148600218",
						"foldChange": "1.3557"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".121150838035256",
						"qValue": ".037602582135086",
						"foldChange": "1.2751"
					}
				]
			},
			{
				"metaboliteId": "0078dc72-0000-0000-0000-000000000000",
				"chemicalId": "05f5e901-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxycoumarin",
				"plotName": null,
				"compoundId": "00009245-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "VXIXUWQIVKSKSA-UHFFFAOYAD",
				"kegg": "C20414",
				"mass": 162.031694,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4973,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".171124281958697",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".000241647662718",
						"qValue": ".04604885748617",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".834123217643855",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".03063741409669",
						"qValue": "1",
						"foldChange": "6.8234"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".225108047278328",
						"qValue": "1",
						"foldChange": "2.2209"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".322989220270178",
						"qValue": "1",
						"foldChange": "1.3876"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001329198853278",
						"qValue": ".1236170295117",
						"foldChange": "8.0420"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".103695403804951",
						"qValue": ".697458782960496",
						"foldChange": "5.6788"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".086443928618845",
						"qValue": ".799606339724314",
						"foldChange": "1.0326"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".042696877936853",
						"qValue": ".821720595261736",
						"foldChange": "8.3689"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".058162892963028",
						"qValue": "1",
						"foldChange": "3.8537"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".888327850600042",
						"qValue": "1",
						"foldChange": "1.5293"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".151453634726305",
						"qValue": "1",
						"foldChange": "0.5553"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".993529257688326",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".162041457407906",
						"qValue": "1",
						"foldChange": "1.7393"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".585853220117171",
						"qValue": "1",
						"foldChange": "0.5482"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".993517681591363",
						"qValue": "1",
						"foldChange": "0.8646"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".603403769439166",
						"qValue": "1",
						"foldChange": "1.5771"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".257659349867745",
						"qValue": "1",
						"foldChange": "0.8193"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".478634077820113",
						"qValue": "1",
						"foldChange": "1.4592"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".07091296539935",
						"qValue": ".985830071188835",
						"foldChange": "1.7811"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".031880583131692",
						"qValue": ".036397796884562",
						"foldChange": "5.0599"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".469248633405131",
						"qValue": ".193276678684815",
						"foldChange": "2.2525"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".204969173292962",
						"qValue": ".056428266405014",
						"foldChange": "1.2654"
					}
				]
			},
			{
				"metaboliteId": "0078dc73-0000-0000-0000-000000000000",
				"chemicalId": "000000ab-0000-0000-0000-000000000000",
				"chemicalName": "hypoxanthine",
				"plotName": null,
				"compoundId": "00000c37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00157",
				"inChiKey": "FDGQSTZJBFJUBT-UHFFFAOYAJ",
				"kegg": "C00262",
				"mass": 136.038511,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3744,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".772695318543549",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".532945408121223",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".831275875231408",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".59544247666592",
						"qValue": "1",
						"foldChange": "1.0206"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".825962056837642",
						"qValue": "1",
						"foldChange": "1.0444"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".755302763008721",
						"qValue": "1",
						"foldChange": "1.1721"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".736837568165178",
						"qValue": "1",
						"foldChange": "1.1263"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".481984356773507",
						"qValue": ".902562957068497",
						"foldChange": "1.3090"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".300514576300144",
						"qValue": ".957839854315834",
						"foldChange": "1.1453"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".350620196225635",
						"qValue": "1",
						"foldChange": "0.8488"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".326755481552753",
						"qValue": "1",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".961740359377033",
						"qValue": "1",
						"foldChange": "1.1082"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".59665937355686",
						"qValue": "1",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".997310942281361",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".610608044969769",
						"qValue": "1",
						"foldChange": "1.1102"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".769301775193236",
						"qValue": "1",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".669744141120754",
						"qValue": "1",
						"foldChange": "0.8017"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".471507706215477",
						"qValue": "1",
						"foldChange": "0.7959"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".336646140345646",
						"qValue": "1",
						"foldChange": "0.8986"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".449025285839445",
						"qValue": "1",
						"foldChange": "0.7308"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".875516076066961",
						"qValue": ".997573179629986",
						"foldChange": "0.8132"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".251844906191374",
						"qValue": ".157923255153137",
						"foldChange": "1.3700"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".206660847864403",
						"qValue": ".106547871700041",
						"foldChange": "1.7585"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".402508113266856",
						"qValue": ".093787844285664",
						"foldChange": "1.2453"
					}
				]
			},
			{
				"metaboliteId": "0078dc74-0000-0000-0000-000000000000",
				"chemicalId": "000001cc-0000-0000-0000-000000000000",
				"chemicalName": "phenylalanine",
				"plotName": null,
				"compoundId": "00000040-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00159",
				"inChiKey": "COLNVLDHVKWLRT-QMMMGPOBBC",
				"kegg": "C00079",
				"mass": 165.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 130,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".831809924846128",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".999483987678915",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".95310776465673",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".734968398724361",
						"qValue": "1",
						"foldChange": "1.0140"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".651310673224938",
						"qValue": "1",
						"foldChange": "1.0172"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".9094819837629",
						"qValue": "1",
						"foldChange": "1.0060"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".814137386901772",
						"qValue": "1",
						"foldChange": "0.9981"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".931209554832757",
						"qValue": "1",
						"foldChange": "1.0094"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".747973697570742",
						"qValue": ".981344309103764",
						"foldChange": "1.0152"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".895976164386749",
						"qValue": "1",
						"foldChange": "0.9983"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".578869683785177",
						"qValue": "1",
						"foldChange": "0.9871"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".671000547328208",
						"qValue": "1",
						"foldChange": "0.9383"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".827352307291317",
						"qValue": "1",
						"foldChange": "1.0053"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".456580435750151",
						"qValue": "1",
						"foldChange": "1.0455"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".580085827434927",
						"qValue": "1",
						"foldChange": "1.0400"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".952269764218715",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".600869728205139",
						"qValue": "1",
						"foldChange": "1.0148"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".552903167289821",
						"qValue": "1",
						"foldChange": "1.0236"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".972233238008195",
						"qValue": "1",
						"foldChange": "0.9979"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".794960676073307",
						"qValue": "1",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".816119670551407",
						"qValue": ".997573179629986",
						"foldChange": "0.9714"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".893114903520279",
						"qValue": ".369627426057473",
						"foldChange": "1.0620"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000003164549647",
						"qValue": ".000119001103967",
						"foldChange": "1.2668"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".148095960498749",
						"qValue": ".043849474074144",
						"foldChange": "1.0924"
					}
				]
			},
			{
				"metaboliteId": "0078dc75-0000-0000-0000-000000000000",
				"chemicalId": "000001b0-0000-0000-0000-000000000000",
				"chemicalName": "nicotinamide",
				"plotName": null,
				"compoundId": "00000252-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01406",
				"inChiKey": "DFPAKSUCGFBDDF-UHFFFAOYAZ",
				"kegg": "C00153",
				"mass": 122.048013,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3916,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".385598735039065",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".971855480241526",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".845085977607251",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".655019327877706",
						"qValue": "1",
						"foldChange": "2.6391"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".579834968842669",
						"qValue": "1",
						"foldChange": "0.9889"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".31897552478179",
						"qValue": "1",
						"foldChange": "1.5800"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".694068005520542",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".956217605656735",
						"qValue": "1",
						"foldChange": "1.3550"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".73494503320758",
						"qValue": ".976023509428735",
						"foldChange": "1.5586"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".829810592783041",
						"qValue": "1",
						"foldChange": "1.2412"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".629242947786371",
						"qValue": "1",
						"foldChange": "1.4089"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".78847892590503",
						"qValue": "1",
						"foldChange": "1.2587"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".442892627803754",
						"qValue": "1",
						"foldChange": "0.5557"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".366914226506074",
						"qValue": "1",
						"foldChange": "0.5328"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".855475825373909",
						"qValue": "1",
						"foldChange": "0.9587"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".122386119463237",
						"qValue": "1",
						"foldChange": "0.3959"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".271563979455656",
						"qValue": "1",
						"foldChange": "0.4282"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".705471844199091",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".77511246671926",
						"qValue": "1",
						"foldChange": "0.8296"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".947752189220525",
						"qValue": "1",
						"foldChange": "0.8042"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".730996545910259",
						"qValue": ".997573179629986",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000249940905017",
						"qValue": ".000979115718405",
						"foldChange": "3.4690"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052979521682557",
						"qValue": ".038471747600022",
						"foldChange": "1.9037"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037682861226765",
						"qValue": ".01516382095168",
						"foldChange": "2.8517"
					}
				]
			},
			{
				"metaboliteId": "0078dc76-0000-0000-0000-000000000000",
				"chemicalId": "00000178-0000-0000-0000-000000000000",
				"chemicalName": "isoleucine",
				"plotName": null,
				"compoundId": "00000465-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00172",
				"inChiKey": "AGPKZVBTJJNPAG-UHFFFAOYAW",
				"kegg": "C00407",
				"mass": 131.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 329,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".690804816921313",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".580551119939218",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".101471208523885",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".049714737564133",
						"qValue": "1",
						"foldChange": "0.9483"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".869566421400436",
						"qValue": "1",
						"foldChange": "0.9971"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".070745621708976",
						"qValue": "1",
						"foldChange": "1.0587"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".066960029730968",
						"qValue": ".581459033684225",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".370056272175195",
						"qValue": ".875723678608451",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".33711072006699",
						"qValue": ".957839854315834",
						"foldChange": "0.9758"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".830994216964609",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".572047676199859",
						"qValue": "1",
						"foldChange": "1.0256"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".437162183306834",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".910710624793806",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".640317907608992",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".557565405778174",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".160040378769017",
						"qValue": "1",
						"foldChange": "1.0961"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".253618434937892",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".840360098757792",
						"qValue": "1",
						"foldChange": "0.9748"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".761436132020084",
						"qValue": "1",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".447409381588168",
						"qValue": "1",
						"foldChange": "0.9990"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".626387633904919",
						"qValue": ".985830071188835",
						"foldChange": "0.9899"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".788540015220899",
						"qValue": ".344316995840098",
						"foldChange": "1.0027"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".192659828922541",
						"qValue": ".100792697298266",
						"foldChange": "1.0615"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".514858658646807",
						"qValue": ".113584665384229",
						"foldChange": "0.9634"
					}
				]
			},
			{
				"metaboliteId": "0078dc77-0000-0000-0000-000000000000",
				"chemicalId": "000000bd-0000-0000-0000-000000000000",
				"chemicalName": "N6,N6,N6-trimethyllysine",
				"plotName": null,
				"compoundId": "000005da-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01325",
				"inChiKey": "MXNRLFUSFKVQSK-UHFFFAOYAO",
				"kegg": "C03793",
				"mass": 188.152478,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 109,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".772759949348328",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".903870121983566",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".091550387784884",
						"qValue": ".803189484586976",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".761090026669694",
						"qValue": "1",
						"foldChange": "1.0045"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".332863163372389",
						"qValue": "1",
						"foldChange": "0.9335"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".504522467372521",
						"qValue": "1",
						"foldChange": "0.9659"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".345299273186745",
						"qValue": ".951252030575746",
						"foldChange": "1.1268"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".30861791987707",
						"qValue": ".825038702902081",
						"foldChange": "0.9392"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".053200869998353",
						"qValue": ".718633974104734",
						"foldChange": "0.8887"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".379009089173114",
						"qValue": "1",
						"foldChange": "0.9346"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".274045331510447",
						"qValue": "1",
						"foldChange": "1.1569"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".051807283082489",
						"qValue": ".727024127920748",
						"foldChange": "1.1666"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".85144436011799",
						"qValue": "1",
						"foldChange": "0.9025"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".426021947399181",
						"qValue": "1",
						"foldChange": "0.8258"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".525104775045897",
						"qValue": "1",
						"foldChange": "0.9150"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".532183464732807",
						"qValue": "1",
						"foldChange": "1.1199"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".232730034430778",
						"qValue": "1",
						"foldChange": "0.8285"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".071126448196662",
						"qValue": ".822195137696456",
						"foldChange": "0.7398"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".85249800437929",
						"qValue": "1",
						"foldChange": "0.9414"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".570175596021234",
						"qValue": "1",
						"foldChange": "0.9944"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".447059111148002",
						"qValue": ".985830071188835",
						"foldChange": "1.0564"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001859414587934",
						"qValue": ".00424575637254",
						"foldChange": "2.1108"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001845225460496",
						"qValue": ".00321284434642",
						"foldChange": "1.4853"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002921485260309",
						"qValue": ".002215587183747",
						"foldChange": "1.7076"
					}
				]
			},
			{
				"metaboliteId": "0078dc78-0000-0000-0000-000000000000",
				"chemicalId": "000003ff-0000-0000-0000-000000000000",
				"chemicalName": "sarcosine",
				"plotName": null,
				"compoundId": "000005ec-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00271",
				"inChiKey": "FSYKKLYZXJSNPZ-UHFFFAOYAL",
				"kegg": "C00213",
				"mass": 89.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 4,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".357695427638538",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".516408341147433",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".911474405151071",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".249240201420125",
						"qValue": "1",
						"foldChange": "0.9798"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".546465721701289",
						"qValue": "1",
						"foldChange": "0.9028"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".578784684316093",
						"qValue": "1",
						"foldChange": "1.5302"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".838125107309691",
						"qValue": "1",
						"foldChange": "1.2051"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".376800229514405",
						"qValue": ".875723678608451",
						"foldChange": "1.3227"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495590780705108",
						"qValue": ".957839854315834",
						"foldChange": "1.1712"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".801740215386045",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".717826454363267",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".912070412911176",
						"qValue": "1",
						"foldChange": "1.0387"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".941096830956247",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".439178356759672",
						"qValue": "1",
						"foldChange": "1.3350"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".470717003584961",
						"qValue": "1",
						"foldChange": "1.1978"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".387014162700443",
						"qValue": "1",
						"foldChange": "1.2551"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".145298065021831",
						"qValue": "1",
						"foldChange": "1.5299"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".5067356911695",
						"qValue": "1",
						"foldChange": "1.2189"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".90338943864039",
						"qValue": "1",
						"foldChange": "1.1607"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".345502101264553",
						"qValue": "1",
						"foldChange": "1.4636"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".279190688560574",
						"qValue": ".985830071188835",
						"foldChange": "1.2610"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001713276091258",
						"qValue": ".003994715442605",
						"foldChange": "5.4467"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007195183323922",
						"qValue": ".008025405055036",
						"foldChange": "3.5717"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000089044189341",
						"qValue": ".000204229996389",
						"foldChange": "3.7251"
					}
				]
			},
			{
				"metaboliteId": "0078dc79-0000-0000-0000-000000000000",
				"chemicalId": "000001f7-0000-0000-0000-000000000000",
				"chemicalName": "serine",
				"plotName": null,
				"compoundId": "00000670-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00187",
				"inChiKey": "MTCFGRXMJLQNBG-REOHCLBHBX",
				"kegg": "C00065",
				"mass": 105.042594,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 9,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".995469127597563",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".679849567356198",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".158473496093622",
						"qValue": ".892830373251164",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".322471017830308",
						"qValue": "1",
						"foldChange": "0.9495"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".91517615752594",
						"qValue": "1",
						"foldChange": "1.0114"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".273909254026225",
						"qValue": "1",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".663863523920813",
						"qValue": "1",
						"foldChange": "0.9885"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".03352698634192",
						"qValue": ".678234398444917",
						"foldChange": "0.9084"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".086287912114902",
						"qValue": ".799606339724314",
						"foldChange": "0.9308"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".289268517857678",
						"qValue": "1",
						"foldChange": "1.0772"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".62379463871034",
						"qValue": "1",
						"foldChange": "1.0393"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".566024538731042",
						"qValue": "1",
						"foldChange": "0.9202"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".652654799408567",
						"qValue": "1",
						"foldChange": "1.0356"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".610475378964406",
						"qValue": "1",
						"foldChange": "0.9477"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".339943574312311",
						"qValue": "1",
						"foldChange": "0.9151"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".389840624897192",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".362027809080299",
						"qValue": "1",
						"foldChange": "1.0625"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".919023676730337",
						"qValue": "1",
						"foldChange": "0.9934"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".278584291958388",
						"qValue": "1",
						"foldChange": "0.9345"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".816498370907751",
						"qValue": "1",
						"foldChange": "0.9281"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".414781485145056",
						"qValue": ".985830071188835",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".236243265024343",
						"qValue": ".150130368777605",
						"foldChange": "1.1519"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".054458298959441",
						"qValue": ".039340680982942",
						"foldChange": "1.3644"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02713139104897",
						"qValue": ".011912567781405",
						"foldChange": "1.2472"
					}
				]
			},
			{
				"metaboliteId": "0078dc7a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e950-0000-0000-0000-000000000000",
				"chemicalName": "17alpha-hydroxypregnenolone 3-sulfate",
				"plotName": null,
				"compoundId": "0000926a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00416",
				"inChiKey": "OMOKWYAQVYBHMG-QUPIPBJSBX",
				"kegg": null,
				"mass": 412.191959,
				"pathwayType": "ANIMAL",
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2931,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".203840373943717",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".626131078716305",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".406096017150464",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".146906517246502",
						"qValue": "1",
						"foldChange": "0.7964"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".822962292480701",
						"qValue": "1",
						"foldChange": "1.0855"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".217568884662407",
						"qValue": "1",
						"foldChange": "1.5153"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".254263552627716",
						"qValue": ".886796242976172",
						"foldChange": "1.6032"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".604095180958398",
						"qValue": ".928868848501546",
						"foldChange": "1.6083"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".530442573922506",
						"qValue": ".957839854315834",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".766199513209105",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".502948484514291",
						"qValue": "1",
						"foldChange": "1.2592"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".334946003125123",
						"qValue": "1",
						"foldChange": "1.3221"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".739203483844353",
						"qValue": "1",
						"foldChange": "1.6213"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".197727943896403",
						"qValue": "1",
						"foldChange": "1.9809"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".314722399132943",
						"qValue": "1",
						"foldChange": "1.2218"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".096671332925599",
						"qValue": "1",
						"foldChange": "2.2059"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".06609072668943",
						"qValue": "1",
						"foldChange": "2.8617"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".773605044050109",
						"qValue": "1",
						"foldChange": "1.2973"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".476285495095018",
						"qValue": "1",
						"foldChange": "1.3231"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".082453499488973",
						"qValue": "1",
						"foldChange": "1.9378"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".264443496856154",
						"qValue": ".985830071188835",
						"foldChange": "1.4646"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".564282256851913",
						"qValue": ".277193687929651",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".397295055790836",
						"qValue": ".169914589600074",
						"foldChange": "0.6927"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".249170286312491",
						"qValue": ".065245247929173",
						"foldChange": "0.3810"
					}
				]
			},
			{
				"metaboliteId": "0078dc7b-0000-0000-0000-000000000000",
				"chemicalId": "05f5efb9-0000-0000-0000-000000000000",
				"chemicalName": "norfluoxetine",
				"plotName": null,
				"compoundId": "00009184-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60551",
				"inChiKey": "WIQRCHMSJFFONW-UHFFFAOYAX",
				"kegg": null,
				"mass": 295.118399,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": "0",
						"qValue": "0",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".999999999999985",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".999999999999992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".938748643465118",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".928868905790748",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".868266293513527",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".938748643465111",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".928868905790728",
						"qValue": "1",
						"foldChange": "1.1776"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".8682662935135",
						"qValue": "1",
						"foldChange": "1.1776"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".93874864346511",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".928868905790741",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".868266293513512",
						"qValue": ".997573179629986",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc7c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0af-0000-0000-0000-000000000000",
				"chemicalName": "diltiazem",
				"plotName": null,
				"compoundId": "0000a7e5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB14487",
				"inChiKey": "HSUGRBWQSSZJOP-RTWAWAEBBV",
				"kegg": "C06958",
				"mass": 414.161328,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Cardiovascular",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4968,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".31679741598121",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".288699042107211",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320381924234875",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".225288026504031",
						"qValue": "1",
						"foldChange": "0.9877"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".171368876532209",
						"qValue": "1",
						"foldChange": "1.0156"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".011828300115246",
						"qValue": ".566846104084484",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".325718148254748",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".314159529050728",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".039160849223386",
						"qValue": "1",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".034785419145654",
						"qValue": ".985830071188835",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc7d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f83a-0000-0000-0000-000000000000",
				"chemicalName": "2-keto-3-deoxy-gluconate",
				"plotName": null,
				"compoundId": "0000bc0d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01353",
				"inChiKey": "WPAMZTWLKIDIOP-WVZVXSGGBW",
				"kegg": "C00204",
				"mass": 178.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4614,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".782683184823433",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".580175809198368",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".966693957671448",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".475529533445066",
						"qValue": "1",
						"foldChange": "1.3921"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".801348773127437",
						"qValue": "1",
						"foldChange": "1.0181"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".335845081739745",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".963014425720318",
						"qValue": "1",
						"foldChange": "1.0802"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".989152007809178",
						"qValue": "1",
						"foldChange": "1.0882"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".952181980247731",
						"qValue": "1",
						"foldChange": "1.0840"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".764664395416714",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".662909301624689",
						"qValue": "1",
						"foldChange": "0.9701"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".463096610012278",
						"qValue": "1",
						"foldChange": "0.8501"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".843465479143653",
						"qValue": "1",
						"foldChange": "1.0350"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".76413232527518",
						"qValue": "1",
						"foldChange": "0.8711"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".61834977132007",
						"qValue": "1",
						"foldChange": "0.8416"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".76817075509073",
						"qValue": "1",
						"foldChange": "0.8005"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".565266920124318",
						"qValue": "1",
						"foldChange": "0.7843"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".760919786334356",
						"qValue": "1",
						"foldChange": "0.9798"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".709630186714213",
						"qValue": "1",
						"foldChange": "1.1401"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655496926677126",
						"qValue": "1",
						"foldChange": "0.8664"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".41472182562015",
						"qValue": ".985830071188835",
						"foldChange": "0.7600"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003262366930119",
						"qValue": ".006429398770965",
						"foldChange": "2.3754"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00030831715416",
						"qValue": ".00110221975901",
						"foldChange": "2.8885"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00014836331443",
						"qValue": ".000281817747393",
						"foldChange": "2.5734"
					}
				]
			},
			{
				"metaboliteId": "0078dc7e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e753-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoglycerol",
				"plotName": null,
				"compoundId": "0000bed9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LLCSXHMJULHSJN-UHFFFAOYAC",
				"kegg": "C03274",
				"mass": 246.050454,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2549,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".715408382715675",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".666229209231733",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".400292550481769",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".702103404528337",
						"qValue": "1",
						"foldChange": "2.1529"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".208414726605216",
						"qValue": "1",
						"foldChange": "0.7442"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".377737782569519",
						"qValue": "1",
						"foldChange": "1.4498"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".659611452949733",
						"qValue": "1",
						"foldChange": "1.5578"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".87709198017877",
						"qValue": "1",
						"foldChange": "1.4560"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".77480171470439",
						"qValue": ".987060268283587",
						"foldChange": "1.5411"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".149631594708245",
						"qValue": "1",
						"foldChange": "0.7497"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".995319843398203",
						"qValue": "1",
						"foldChange": "1.1730"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".148034099580416",
						"qValue": ".960252848686774",
						"foldChange": "1.8290"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".622521679466546",
						"qValue": "1",
						"foldChange": "0.7601"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".628780080009605",
						"qValue": "1",
						"foldChange": "1.0900"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".332221638682219",
						"qValue": "1",
						"foldChange": "1.4340"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".773337334145198",
						"qValue": "1",
						"foldChange": "0.7760"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".557420418250756",
						"qValue": "1",
						"foldChange": "0.5530"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".379742899461633",
						"qValue": "1",
						"foldChange": "0.7126"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".37655614462513",
						"qValue": "1",
						"foldChange": "1.5150"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".100429626962042",
						"qValue": "1",
						"foldChange": "1.6715"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".401955810004197",
						"qValue": ".985830071188835",
						"foldChange": "1.1033"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".570535750179097",
						"qValue": ".279436417845201",
						"foldChange": "1.5638"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".850260260561796",
						"qValue": ".302028086322886",
						"foldChange": "1.0502"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".301972882245384",
						"qValue": ".075859325332038",
						"foldChange": "0.7782"
					}
				]
			},
			{
				"metaboliteId": "0078dc7f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e408-0000-0000-0000-000000000000",
				"chemicalName": "palmitoylcarnitine (C16)",
				"plotName": null,
				"compoundId": "0000ae89-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00222",
				"inChiKey": "XOMRRQXKHMYMOC-UHFFFAOYAU",
				"kegg": "C02990",
				"mass": 399.334859,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1648,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".650401525750462",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".444048603440367",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".95838068263676",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".497487966131468",
						"qValue": "1",
						"foldChange": "1.1024"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".686955034660229",
						"qValue": "1",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".78205240669825",
						"qValue": "1",
						"foldChange": "1.0171"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".756356408250799",
						"qValue": "1",
						"foldChange": "1.0348"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".80230834186981",
						"qValue": ".988780551738153",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".575516525920365",
						"qValue": ".957839854315834",
						"foldChange": "0.9727"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".433294768588871",
						"qValue": "1",
						"foldChange": "1.0882"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".718986571059457",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".254968232775211",
						"qValue": "1",
						"foldChange": "0.9155"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".539452483056559",
						"qValue": "1",
						"foldChange": "0.8922"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".851371957467813",
						"qValue": "1",
						"foldChange": "0.9420"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".686594299821757",
						"qValue": "1",
						"foldChange": "1.0558"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".403080429168792",
						"qValue": "1",
						"foldChange": "0.8373"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".918241308177332",
						"qValue": "1",
						"foldChange": "0.8926"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".480063524016427",
						"qValue": "1",
						"foldChange": "1.0661"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".325816526784852",
						"qValue": "1",
						"foldChange": "0.8668"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".536021048591457",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".747318811389367",
						"qValue": ".997573179629986",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".132057278608019",
						"qValue": ".100744060742624",
						"foldChange": "1.3481"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005229273664008",
						"qValue": ".006628019778834",
						"foldChange": "1.3735"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000373951113032",
						"qValue": ".000518295987481",
						"foldChange": "1.6849"
					}
				]
			},
			{
				"metaboliteId": "0078dc80-0000-0000-0000-000000000000",
				"chemicalId": "000005d0-0000-0000-0000-000000000000",
				"chemicalName": "arachidonoyl ethanolamide",
				"plotName": null,
				"compoundId": "00007ecf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04080",
				"inChiKey": "LGEQQWMQCRIYKG-DOFZRALJBA",
				"kegg": "C11695",
				"mass": 347.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1913,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".886394406403804",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".143003539302518",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".730225531988401",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".95702145555454",
						"qValue": "1",
						"foldChange": "1.0032"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".870501830083932",
						"qValue": "1",
						"foldChange": "1.0106"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".913087874255902",
						"qValue": "1",
						"foldChange": "1.0319"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".784577413553304",
						"qValue": "1",
						"foldChange": "1.1157"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".121324753939855",
						"qValue": ".716995594464005",
						"foldChange": "0.8680"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".070171930420309",
						"qValue": ".785109179715988",
						"foldChange": "0.8896"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".563357676803214",
						"qValue": "1",
						"foldChange": "0.9767"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".1488624768093",
						"qValue": "1",
						"foldChange": "0.8738"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".380445077332949",
						"qValue": "1",
						"foldChange": "0.9218"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".971618694095288",
						"qValue": "1",
						"foldChange": "0.9489"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".622323384409469",
						"qValue": "1",
						"foldChange": "1.0209"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".589957309560348",
						"qValue": "1",
						"foldChange": "1.0759"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".814949961483638",
						"qValue": "1",
						"foldChange": "1.0562"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".970313161834768",
						"qValue": "1",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".850401337449673",
						"qValue": "1",
						"foldChange": "0.9129"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".24210711493834",
						"qValue": "1",
						"foldChange": "0.8307"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".526678420793419",
						"qValue": "1",
						"foldChange": "0.8472"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".62456738177866",
						"qValue": ".985830071188835",
						"foldChange": "1.0199"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".67759519836247",
						"qValue": ".315091846049996",
						"foldChange": "1.0991"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007074062251558",
						"qValue": ".007981958638321",
						"foldChange": "1.7163"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".009818631391225",
						"qValue": ".00538771161549",
						"foldChange": "1.7577"
					}
				]
			},
			{
				"metaboliteId": "0078dc81-0000-0000-0000-000000000000",
				"chemicalId": "05f5eb08-0000-0000-0000-000000000000",
				"chemicalName": "L-urobilin",
				"plotName": null,
				"compoundId": "00009ced-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04159",
				"inChiKey": "TYOWQSLRVAUSMI-WKULSOCRBC",
				"kegg": "C05793",
				"mass": 594.341735,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4019,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".875615627782088",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".81160175968865",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".595981310435727",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".86537053494896",
						"qValue": "1",
						"foldChange": "1.3508"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".513120429550641",
						"qValue": "1",
						"foldChange": "1.5510"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".627470334162104",
						"qValue": "1",
						"foldChange": "1.5189"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".107330432578039",
						"qValue": ".676892563582731",
						"foldChange": "0.8274"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".369092139594346",
						"qValue": ".875723678608451",
						"foldChange": "0.8867"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".466328436499564",
						"qValue": ".957839854315834",
						"foldChange": "2.0238"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".799973855185124",
						"qValue": "1",
						"foldChange": "1.0683"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".831996868581517",
						"qValue": "1",
						"foldChange": "0.9649"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".641839334614263",
						"qValue": "1",
						"foldChange": "0.9255"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".867441037271443",
						"qValue": "1",
						"foldChange": "2.0481"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".68253507748186",
						"qValue": "1",
						"foldChange": "0.8035"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".562219108575827",
						"qValue": "1",
						"foldChange": "0.3923"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".696150860873516",
						"qValue": "1",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".704946683377628",
						"qValue": "1",
						"foldChange": "0.7067"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".992346397018326",
						"qValue": "1",
						"foldChange": "0.7285"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".746320610726131",
						"qValue": "1",
						"foldChange": "1.1850"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".498306731387308",
						"qValue": "1",
						"foldChange": "0.8592"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".702128843338785",
						"qValue": ".995398138635986",
						"foldChange": "0.7251"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".859282201118888",
						"qValue": ".361182939797515",
						"foldChange": "0.8776"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".081429237574262",
						"qValue": ".052928328923925",
						"foldChange": "6.1580"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".099762460129095",
						"qValue": ".032733472230166",
						"foldChange": "2.6870"
					}
				]
			},
			{
				"metaboliteId": "0078dc82-0000-0000-0000-000000000000",
				"chemicalId": "05f5e66a-0000-0000-0000-000000000000",
				"chemicalName": "heme",
				"plotName": null,
				"compoundId": "0000a31a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03178",
				"inChiKey": null,
				"kegg": "C00032",
				"mass": 615.168924,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4012,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".792952424428668",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".54699588475803",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".083275993048866",
						"qValue": ".769244960704375",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".710865891362061",
						"qValue": "1",
						"foldChange": "2.4943"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".724204002940821",
						"qValue": "1",
						"foldChange": "1.1313"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".470260231653323",
						"qValue": "1",
						"foldChange": "1.4506"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".998552500938293",
						"qValue": "1",
						"foldChange": "1.3702"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".243783625945868",
						"qValue": ".825038702902081",
						"foldChange": "2.0716"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".243066077738374",
						"qValue": ".957839854315834",
						"foldChange": "1.3015"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".173205164584047",
						"qValue": "1",
						"foldChange": "2.3176"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".009294919639655",
						"qValue": ".5273317742231",
						"foldChange": "4.0643"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".190528838459341",
						"qValue": ".994724181158891",
						"foldChange": "2.3199"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".818401077199401",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".122318498219516",
						"qValue": "1",
						"foldChange": "0.5203"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".072536710847938",
						"qValue": "1",
						"foldChange": "0.5124"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".950053699357518",
						"qValue": "1",
						"foldChange": "0.2622"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".455543645194522",
						"qValue": "1",
						"foldChange": "0.2636"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".481377608223621",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".709718660182521",
						"qValue": "1",
						"foldChange": "1.5715"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".401028134567604",
						"qValue": "1",
						"foldChange": "1.4455"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".224176059768415",
						"qValue": ".985830071188835",
						"foldChange": "0.9198"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000004500164217",
						"qValue": ".000082775668234",
						"foldChange": "6.3784"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".071879883745699",
						"qValue": ".048535883584844",
						"foldChange": "4.9140"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".033154917368866",
						"qValue": ".013845486676469",
						"foldChange": "2.4622"
					}
				]
			},
			{
				"metaboliteId": "0078dc83-0000-0000-0000-000000000000",
				"chemicalId": "05f5e722-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPE (18:2)*",
				"plotName": null,
				"compoundId": "00008ef8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11507",
				"inChiKey": "DBHKHNGBVGWQJE-HZJYTTRNBF",
				"kegg": null,
				"mass": 477.285539,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2382,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".19727020339709",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".868302825700103",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".137894944918087",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".095488765872596",
						"qValue": "1",
						"foldChange": "1.2323"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".033531125075345",
						"qValue": "1",
						"foldChange": "1.2927"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".627842238338957",
						"qValue": "1",
						"foldChange": "1.0766"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".75811959053259",
						"qValue": "1",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".844627171826212",
						"qValue": "1",
						"foldChange": "1.0431"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".614647073608471",
						"qValue": ".967408831530889",
						"foldChange": "1.0954"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".268859257788032",
						"qValue": "1",
						"foldChange": "0.9464"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".167455487637652",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".778395018561873",
						"qValue": "1",
						"foldChange": "0.9152"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".015344686960296",
						"qValue": "1",
						"foldChange": "1.3692"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".01818022990226",
						"qValue": "1",
						"foldChange": "1.3874"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".944241819458909",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".370896827908467",
						"qValue": "1",
						"foldChange": "1.1378"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".807670130630369",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".535661693609091",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".373923766064607",
						"qValue": "1",
						"foldChange": "1.1245"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".725370822049305",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".223909347960267",
						"qValue": ".985830071188835",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".958484315967551",
						"qValue": ".387478801242755",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".57008935338385",
						"qValue": ".226449269750937",
						"foldChange": "1.0266"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056786042185127",
						"qValue": ".020961544936936",
						"foldChange": "1.2737"
					}
				]
			},
			{
				"metaboliteId": "0078dc84-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6b5-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPE (18:0)",
				"plotName": null,
				"compoundId": "0000a59e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11130",
				"inChiKey": "BBYWOYAFBUOUFP-JOCHJYFZBR",
				"kegg": null,
				"mass": 481.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2378,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".885118654900826",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".907858437216292",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".735635525133844",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".479951508395283",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".891819036906082",
						"qValue": "1",
						"foldChange": "1.0535"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".567837257885517",
						"qValue": "1",
						"foldChange": "0.9781"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".634710350990192",
						"qValue": "1",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".586504812314895",
						"qValue": ".928868848501546",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".310149221826963",
						"qValue": ".957839854315834",
						"foldChange": "1.1174"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".445657087624114",
						"qValue": "1",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".652626141384311",
						"qValue": "1",
						"foldChange": "0.9912"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".753329430060582",
						"qValue": "1",
						"foldChange": "0.9943"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".894418800682331",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".408375288009715",
						"qValue": "1",
						"foldChange": "1.0751"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".471975047064508",
						"qValue": "1",
						"foldChange": "1.0751"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".411649748619404",
						"qValue": "1",
						"foldChange": "0.9125"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".722208416955065",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".665112073660143",
						"qValue": "1",
						"foldChange": "1.1045"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".655352976045547",
						"qValue": "1",
						"foldChange": "1.0691"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".726092902503368",
						"qValue": "1",
						"foldChange": "1.0203"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".941287064400245",
						"qValue": ".997575444606604",
						"foldChange": "0.9544"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001304932217438",
						"qValue": ".003176843959666",
						"foldChange": "1.7712"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002006564020879",
						"qValue": ".003327413202008",
						"foldChange": "1.4750"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000007585750775",
						"qValue": ".000058076479343",
						"foldChange": "1.9683"
					}
				]
			},
			{
				"metaboliteId": "0078dc85-0000-0000-0000-000000000000",
				"chemicalId": "00000129-0000-0000-0000-000000000000",
				"chemicalName": "sphingosine",
				"plotName": null,
				"compoundId": "00004553-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00252",
				"inChiKey": "WWUZIQQURGPMPG-CCEZHUSRBL",
				"kegg": "C00319",
				"mass": 299.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingosines",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2847,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".437980001499467",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".884935556224275",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".668350551048256",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".76320762152297",
						"qValue": "1",
						"foldChange": "1.6292"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".265882658254836",
						"qValue": "1",
						"foldChange": "0.8806"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".414731447960874",
						"qValue": "1",
						"foldChange": "1.1632"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".869603687525414",
						"qValue": "1",
						"foldChange": "1.1012"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".57904286823496",
						"qValue": ".928868848501546",
						"foldChange": "1.5175"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".47280757813188",
						"qValue": ".957839854315834",
						"foldChange": "1.5533"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".775598375661558",
						"qValue": "1",
						"foldChange": "1.0483"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".600874828624236",
						"qValue": "1",
						"foldChange": "1.4235"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".420060939824935",
						"qValue": "1",
						"foldChange": "1.6667"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".174602432193693",
						"qValue": "1",
						"foldChange": "0.6068"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".484673484474385",
						"qValue": "1",
						"foldChange": "0.6514"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".544678497960009",
						"qValue": "1",
						"foldChange": "1.0733"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".223580711535134",
						"qValue": "1",
						"foldChange": "0.6500"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".484058589210584",
						"qValue": "1",
						"foldChange": "0.6964"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".640749184726546",
						"qValue": "1",
						"foldChange": "1.0715"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".802216152144419",
						"qValue": "1",
						"foldChange": "1.2477"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".393515536756985",
						"qValue": "1",
						"foldChange": "1.3371"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".526893298301447",
						"qValue": ".985830071188835",
						"foldChange": "1.0717"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013300171527069",
						"qValue": ".0186591595147",
						"foldChange": "2.5210"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".662308720077609",
						"qValue": ".255086228699909",
						"foldChange": "1.0193"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".32416014467031",
						"qValue": ".08030376850516",
						"foldChange": "1.2588"
					}
				]
			},
			{
				"metaboliteId": "0078dc86-0000-0000-0000-000000000000",
				"chemicalId": "00000139-0000-0000-0000-000000000000",
				"chemicalName": "sphinganine",
				"plotName": null,
				"compoundId": "00004569-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00269",
				"inChiKey": "OTKJDMGTUTTYMP-UHFFFAOYAS",
				"kegg": "C00836",
				"mass": 301.298079,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2696,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".586444523005127",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".696361282988858",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".48734123689001",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".548208915310557",
						"qValue": "1",
						"foldChange": "1.2317"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".164026932978852",
						"qValue": "1",
						"foldChange": "0.8325"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".42276369348733",
						"qValue": "1",
						"foldChange": "1.0764"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".777812737148115",
						"qValue": "1",
						"foldChange": "1.0274"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".48706183644439",
						"qValue": ".902562957068497",
						"foldChange": "1.3538"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".329972939706552",
						"qValue": ".957839854315834",
						"foldChange": "1.4208"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".682033285031415",
						"qValue": "1",
						"foldChange": "0.9862"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".51387362336824",
						"qValue": "1",
						"foldChange": "1.3330"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".290018866880492",
						"qValue": "1",
						"foldChange": "1.5175"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".184018933504615",
						"qValue": "1",
						"foldChange": "0.6272"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".532665625124659",
						"qValue": "1",
						"foldChange": "0.6969"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".512901444905066",
						"qValue": "1",
						"foldChange": "1.1111"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".303850227982113",
						"qValue": "1",
						"foldChange": "0.7326"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".630939599553561",
						"qValue": "1",
						"foldChange": "0.7798"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".612201446054152",
						"qValue": "1",
						"foldChange": "1.0645"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".581538506973607",
						"qValue": "1",
						"foldChange": "1.2770"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".236532205535683",
						"qValue": "1",
						"foldChange": "1.3637"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".494374349399669",
						"qValue": ".985830071188835",
						"foldChange": "1.0679"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001881016124434",
						"qValue": ".004265662599281",
						"foldChange": "2.5731"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".133565208821678",
						"qValue": ".076495996979391",
						"foldChange": "1.5413"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".031680999782605",
						"qValue": ".013439424573254",
						"foldChange": "1.6448"
					}
				]
			},
			{
				"metaboliteId": "0078dc87-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2be-0000-0000-0000-000000000000",
				"chemicalName": "2-aminoheptanoate",
				"plotName": null,
				"compoundId": "0000aaf1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RDFMDVXONNIGBC-UHFFFAOYAG",
				"kegg": null,
				"mass": 145.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Amino",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1574,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".091486284656529",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".338083547675266",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".065967209206498",
						"qValue": ".738659145193813",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".740649630949975",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".456921636805556",
						"qValue": "1",
						"foldChange": "1.1782"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".284261773315784",
						"qValue": "1",
						"foldChange": "1.1666"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".759171947480559",
						"qValue": "1",
						"foldChange": "1.0548"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".415686341556697",
						"qValue": ".88881677553957",
						"foldChange": "0.9526"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".610504150284535",
						"qValue": ".967408831530889",
						"foldChange": "1.0067"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".004000851800815",
						"qValue": ".543683917591992",
						"foldChange": "1.4990"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".162085947994347",
						"qValue": "1",
						"foldChange": "1.2248"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".115329150749467",
						"qValue": ".934715307502823",
						"foldChange": "0.7781"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".649054415899889",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".95695533550047",
						"qValue": "1",
						"foldChange": "0.9992"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".619936847774125",
						"qValue": "1",
						"foldChange": "1.1063"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".670331821052797",
						"qValue": "1",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".015528855664214",
						"qValue": "1",
						"foldChange": "1.3994"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".004439531823613",
						"qValue": ".605991101987089",
						"foldChange": "1.5653"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".120902316286333",
						"qValue": "1",
						"foldChange": "0.7833"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".565714575125529",
						"qValue": "1",
						"foldChange": "0.9899"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".038993147526251",
						"qValue": ".985830071188835",
						"foldChange": "1.2638"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".070570969404116",
						"qValue": ".064014750511013",
						"foldChange": "1.2731"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".863199408799162",
						"qValue": ".30429846169633",
						"foldChange": "1.0157"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".036662463405043",
						"qValue": ".015042932225211",
						"foldChange": "1.4216"
					}
				]
			},
			{
				"metaboliteId": "0078dc88-0000-0000-0000-000000000000",
				"chemicalId": "05f5e987-0000-0000-0000-000000000000",
				"chemicalName": "S-methylmethionine",
				"plotName": null,
				"compoundId": "000094ef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB38670",
				"inChiKey": "YDBYJHTYSHBBAU-UHFFFAOYAF",
				"kegg": "C05319",
				"mass": 163.066699,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 374,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".643540955577208",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".336867910887497",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".987541064936192",
						"qValue": ".999283527063851",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".523557265102108",
						"qValue": "1",
						"foldChange": "2.7319"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".552579237405176",
						"qValue": "1",
						"foldChange": "1.8062"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".964716905279267",
						"qValue": "1",
						"foldChange": "2.1045"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".25954871681533",
						"qValue": ".897869748007503",
						"foldChange": "3.0727"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".621279642735666",
						"qValue": ".928868848501546",
						"foldChange": "2.2680"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".522832738901147",
						"qValue": ".957839854315834",
						"foldChange": "0.9017"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".41777378585234",
						"qValue": "1",
						"foldChange": "4.8353"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".913826614478881",
						"qValue": "1",
						"foldChange": "4.1732"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".48202882399794",
						"qValue": "1",
						"foldChange": "1.4775"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".406750235521665",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".643881252827647",
						"qValue": "1",
						"foldChange": "1.7271"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".739663202772703",
						"qValue": "1",
						"foldChange": "1.6347"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".60291563492719",
						"qValue": "1",
						"foldChange": "0.6562"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".754817042386279",
						"qValue": "1",
						"foldChange": "0.6733"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".853604490065077",
						"qValue": "1",
						"foldChange": "1.0261"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".35856851499964",
						"qValue": "1",
						"foldChange": "0.3728"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".433196861355806",
						"qValue": "1",
						"foldChange": "0.4364"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".930002575499955",
						"qValue": ".997573179629986",
						"foldChange": "1.1706"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".046705577230439",
						"qValue": ".047580852762311",
						"foldChange": "4.6703"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001096894152947",
						"qValue": ".002265666895835",
						"foldChange": "5.9671"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01880933756869",
						"qValue": ".008894378141311",
						"foldChange": "6.9935"
					}
				]
			},
			{
				"metaboliteId": "0078dc89-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5b7-0000-0000-0000-000000000000",
				"chemicalName": "4-imidazoleacetate",
				"plotName": null,
				"compoundId": "00007e5d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02024",
				"inChiKey": "PRJKNHOMHKJCEJ-UHFFFAOYAF",
				"kegg": "C02835",
				"mass": 126.042927,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 90,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".996775701775539",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".916290703004992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".661931885967159",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".496713879550938",
						"qValue": "1",
						"foldChange": "1.2496"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".223272400441274",
						"qValue": "1",
						"foldChange": "1.2847"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".585581975185246",
						"qValue": "1",
						"foldChange": "1.1275"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".968974114593288",
						"qValue": "1",
						"foldChange": "1.1758"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".957399642336503",
						"qValue": "1",
						"foldChange": "1.1436"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".926452549314808",
						"qValue": "1",
						"foldChange": "1.0694"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".940364165569082",
						"qValue": "1",
						"foldChange": "1.1717"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".399799191427971",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".359725238346833",
						"qValue": "1",
						"foldChange": "0.8954"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".700201879045961",
						"qValue": "1",
						"foldChange": "1.0017"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".588839558403595",
						"qValue": "1",
						"foldChange": "1.0084"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".856018629182493",
						"qValue": "1",
						"foldChange": "1.0067"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".994061618082678",
						"qValue": "1",
						"foldChange": "0.9311"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".817956123127119",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".808016561384364",
						"qValue": "1",
						"foldChange": "1.0881"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".796288761334453",
						"qValue": "1",
						"foldChange": "0.9096"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".578400506399973",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".748877669306406",
						"qValue": ".997573179629986",
						"foldChange": "0.8971"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".671889727861667",
						"qValue": ".313318826464865",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".142613435246654",
						"qValue": ".080543613845744",
						"foldChange": "1.5435"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".182326171204762",
						"qValue": ".052066888222303",
						"foldChange": "1.5804"
					}
				]
			},
			{
				"metaboliteId": "0078dc8a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e51e-0000-0000-0000-000000000000",
				"chemicalName": "butyrylcarnitine (C4)",
				"plotName": null,
				"compoundId": "00007e9c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02013",
				"inChiKey": "QWYFHHGCZUCMBN-SECBINFHBA",
				"kegg": "C02862",
				"mass": 231.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1592,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".314114163720009",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".793563248162544",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".768052654075896",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".344405107899723",
						"qValue": "1",
						"foldChange": "0.9314"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".868743128053791",
						"qValue": "1",
						"foldChange": "1.0002"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".43412309084692",
						"qValue": "1",
						"foldChange": "1.0984"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".85300517303442",
						"qValue": "1",
						"foldChange": "1.0698"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".393574061049184",
						"qValue": ".880987329915339",
						"foldChange": "0.9780"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".300258920402997",
						"qValue": ".957839854315834",
						"foldChange": "0.9478"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".975961174242696",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".859943922198008",
						"qValue": "1",
						"foldChange": "1.0096"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".836352716283023",
						"qValue": "1",
						"foldChange": "0.9461"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".685668118229744",
						"qValue": "1",
						"foldChange": "1.1040"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".277288029519955",
						"qValue": "1",
						"foldChange": "0.7885"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".13686993613612",
						"qValue": "1",
						"foldChange": "0.7143"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".418807013354721",
						"qValue": "1",
						"foldChange": "1.2574"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".448657016444833",
						"qValue": "1",
						"foldChange": "0.8374"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".124771375067073",
						"qValue": ".931407370018239",
						"foldChange": "0.6660"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".864387628834412",
						"qValue": "1",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".274086277584286",
						"qValue": "1",
						"foldChange": "0.7480"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".200884555540236",
						"qValue": ".985830071188835",
						"foldChange": "0.6909"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".602020379956543",
						"qValue": ".289768327335659",
						"foldChange": "0.6881"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".03714732970513",
						"qValue": ".029343911299954",
						"foldChange": "1.7640"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".75436803265688",
						"qValue": ".152558761137927",
						"foldChange": "0.8754"
					}
				]
			},
			{
				"metaboliteId": "0078dc8b-0000-0000-0000-000000000000",
				"chemicalId": "000004bf-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglucosaminylasparagine",
				"plotName": null,
				"compoundId": "0000bc15-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00489",
				"inChiKey": "YTTRPBWEMMPYSW-VFFCQLMOBE",
				"kegg": "C04540",
				"mass": 335.132867,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1274,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".738689546646057",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".417404831931633",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".794436691534243",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".161430221456331",
						"qValue": "1",
						"foldChange": "1.3598"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".394920478023898",
						"qValue": "1",
						"foldChange": "0.7937"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".575527257208508",
						"qValue": "1",
						"foldChange": "2.3571"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".919032358256524",
						"qValue": "1",
						"foldChange": "1.4714"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".417786266307384",
						"qValue": ".88884028156896",
						"foldChange": "1.2885"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".477994609948525",
						"qValue": ".957839854315834",
						"foldChange": "1.1425"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".908149692928816",
						"qValue": "1",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".531132633496527",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".458808370311525",
						"qValue": "1",
						"foldChange": "0.9233"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".446683384622772",
						"qValue": "1",
						"foldChange": "0.7763"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".721061076862758",
						"qValue": "1",
						"foldChange": "0.8661"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".709513701175665",
						"qValue": "1",
						"foldChange": "1.1156"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".591474649309563",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".28396643953301",
						"qValue": "1",
						"foldChange": "1.0281"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".561264537052437",
						"qValue": "1",
						"foldChange": "1.1324"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".496539718307716",
						"qValue": "1",
						"foldChange": "0.8718"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".855887299410187",
						"qValue": "1",
						"foldChange": "0.8699"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".636025120795249",
						"qValue": ".985830071188835",
						"foldChange": "0.9978"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000316687943465",
						"qValue": ".001152224156326",
						"foldChange": "5.2634"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000450618967302",
						"qValue": ".001396149948619",
						"foldChange": "5.0859"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000070761380909",
						"qValue": ".000177761346496",
						"foldChange": "6.0978"
					}
				]
			},
			{
				"metaboliteId": "0078dc8c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec98-0000-0000-0000-000000000000",
				"chemicalName": "quinine",
				"plotName": null,
				"compoundId": "00009b57-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LOUPRKONTZGTKE-WGFDLZGGBH",
				"kegg": null,
				"mass": 324.183778,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Antibiotic",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4889,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".382696706677088",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".378404075121555",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".416838497242705",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".127386053144775",
						"qValue": "1",
						"foldChange": "1.1474"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".019483311277868",
						"qValue": "1",
						"foldChange": "1.3030"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".39081914241046",
						"qValue": "1",
						"foldChange": "1.0669"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".387431659774447",
						"qValue": "1",
						"foldChange": "0.6617"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".413432082728803",
						"qValue": "1",
						"foldChange": "0.6617"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".208701564338601",
						"qValue": "1",
						"foldChange": "0.4051"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".233802749993955",
						"qValue": "1",
						"foldChange": "0.4051"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".141055946622864",
						"qValue": "1",
						"foldChange": "0.2924"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".163080238369949",
						"qValue": "1",
						"foldChange": "0.2924"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "3.4198"
					}
				]
			},
			{
				"metaboliteId": "0078dc8d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e9-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylasparagine",
				"plotName": null,
				"compoundId": "00008496-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06028",
				"inChiKey": "HXFOXFJUNFFYMO-BYPYZUCNBZ",
				"kegg": null,
				"mass": 174.064057,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 40,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".376064184225156",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".64323371722026",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".490169555443134",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".214652919815151",
						"qValue": "1",
						"foldChange": "2.5173"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".914178789536784",
						"qValue": "1",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".256179393512974",
						"qValue": "1",
						"foldChange": "1.2781"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".596225032004272",
						"qValue": "1",
						"foldChange": "0.9317"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".542724248084501",
						"qValue": ".910963185640849",
						"foldChange": "1.4239"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".257288076279745",
						"qValue": ".957839854315834",
						"foldChange": "1.4922"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".375113419893285",
						"qValue": "1",
						"foldChange": "1.5762"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".375113419893285",
						"qValue": "1",
						"foldChange": "1.5122"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".845819585636467",
						"qValue": "1",
						"foldChange": "0.9230"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".50559513273307",
						"qValue": "1",
						"foldChange": "0.7594"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".621161732048557",
						"qValue": "1",
						"foldChange": "0.8228"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".051418037537624",
						"qValue": "1",
						"foldChange": "0.3844"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".06510857659862",
						"qValue": "1",
						"foldChange": "0.3844"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".777152389622076",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".891852678813148",
						"qValue": "1",
						"foldChange": "1.0309"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".892659668114708",
						"qValue": ".997573179629986",
						"foldChange": "1.0134"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000168244429499",
						"qValue": ".000728359333549",
						"foldChange": "0.3700"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000103126825864",
						"qValue": ".000617602035247",
						"foldChange": "0.3665"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000425376743565",
						"qValue": ".000574189684068",
						"foldChange": "0.4182"
					}
				]
			},
			{
				"metaboliteId": "0078dc8e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60e-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglycine",
				"plotName": null,
				"compoundId": "0000849d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11667",
				"inChiKey": "ACIJGUBIMXQCMF-BYPYZUCNBW",
				"kegg": null,
				"mass": 204.074622,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".900598278393581",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".116583223699782",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".182664503035298",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".937297773704658",
						"qValue": "1",
						"foldChange": "1.0374"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".847587694093016",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".786545315764455",
						"qValue": "1",
						"foldChange": "1.0582"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".790406107957375",
						"qValue": "1",
						"foldChange": "1.0170"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".1935979436293",
						"qValue": ".82375925014267",
						"foldChange": "0.9240"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".298423344456061",
						"qValue": ".957839854315834",
						"foldChange": "0.9355"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".038957210005135",
						"qValue": ".821720595261736",
						"foldChange": "1.3472"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".506088833650975",
						"qValue": "1",
						"foldChange": "0.9445"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".007483670334491",
						"qValue": ".566846104084484",
						"foldChange": "0.7321"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".822232782363136",
						"qValue": "1",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".934022826556066",
						"qValue": "1",
						"foldChange": "0.9728"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".894285465632921",
						"qValue": "1",
						"foldChange": "0.9467"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".895063721793441",
						"qValue": "1",
						"foldChange": "0.9479"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".214426685361129",
						"qValue": "1",
						"foldChange": "1.2262"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".251262554151154",
						"qValue": "1",
						"foldChange": "1.2937"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".58748120038533",
						"qValue": "1",
						"foldChange": "0.9061"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".710181412554066",
						"qValue": "1",
						"foldChange": "0.8252"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".884300861528306",
						"qValue": ".997573179629986",
						"foldChange": "0.9108"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".009140592230531",
						"qValue": ".014076111998558",
						"foldChange": "1.6201"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".057645940148288",
						"qValue": ".041156355001095",
						"foldChange": "1.5055"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005908156958437",
						"qValue": ".003650229243504",
						"foldChange": "1.8699"
					}
				]
			},
			{
				"metaboliteId": "0078dc8f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8eb-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylhistamine",
				"plotName": null,
				"compoundId": "0000be27-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13253",
				"inChiKey": "XJWPISBUKWZALE-UHFFFAOYAX",
				"kegg": "C05135",
				"mass": 153.090212,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 94,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".556941398333262",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".303264522317737",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".308236756678981",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".875792536940779",
						"qValue": "1",
						"foldChange": "1.0129"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".719156570841784",
						"qValue": ".950986052805043",
						"foldChange": "1.0304"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".838774544213785",
						"qValue": "1",
						"foldChange": "1.0164"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".015908844645268",
						"qValue": ".75213482184018",
						"foldChange": "0.8972"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".531060725446441",
						"qValue": "1",
						"foldChange": "0.9558"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".067793838504993",
						"qValue": ".727024127920748",
						"foldChange": "1.2931"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".319466826369028",
						"qValue": "1",
						"foldChange": "3.1148"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".509423398378215",
						"qValue": "1",
						"foldChange": "1.5783"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".768210542416891",
						"qValue": "1",
						"foldChange": "0.5067"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".301231659265706",
						"qValue": "1",
						"foldChange": "3.4007"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999991",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".316338491827804",
						"qValue": "1",
						"foldChange": "0.2941"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".278609653408244",
						"qValue": "1",
						"foldChange": "3.6166"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".621228122979005",
						"qValue": "1",
						"foldChange": "1.2931"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".580608525412323",
						"qValue": ".985830071188835",
						"foldChange": "0.3575"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087335",
						"qValue": ".192863611674612",
						"foldChange": "1.2931"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".875543954715496",
						"qValue": ".307484025061677",
						"foldChange": "1.5140"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dc90-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9eb-0000-0000-0000-000000000000",
				"chemicalName": "C-glycosyltryptophan",
				"plotName": null,
				"compoundId": "0000be8e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CPXSBHKDEPPWIX-RAYCSJGIBM",
				"kegg": null,
				"mass": 366.142701,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 236,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".779598930307883",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".526234537007976",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".404834434016694",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".786069924216448",
						"qValue": "1",
						"foldChange": "0.9997"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".802469800695864",
						"qValue": "1",
						"foldChange": "0.9938"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".983015685696568",
						"qValue": "1",
						"foldChange": "1.0813"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".095884632855227",
						"qValue": ".661091185233501",
						"foldChange": "1.2643"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".029101503869112",
						"qValue": ".678234398444917",
						"foldChange": "1.3144"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".584177796780369",
						"qValue": ".957839854315834",
						"foldChange": "1.1230"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".791340554020458",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".925196669263683",
						"qValue": "1",
						"foldChange": "1.0547"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".720103923641712",
						"qValue": "1",
						"foldChange": "1.0632"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".167452972393232",
						"qValue": "1",
						"foldChange": "0.7967"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".545250152260363",
						"qValue": "1",
						"foldChange": "0.8663"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".468028779298346",
						"qValue": "1",
						"foldChange": "1.0874"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".839663902287901",
						"qValue": "1",
						"foldChange": "1.0380"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".540368826734013",
						"qValue": "1",
						"foldChange": "0.8958"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".411157120642133",
						"qValue": "1",
						"foldChange": "0.8630"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".531704699014211",
						"qValue": "1",
						"foldChange": "1.0928"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".745625891478374",
						"qValue": "1",
						"foldChange": "0.8871"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".349366376407612",
						"qValue": ".985830071188835",
						"foldChange": "0.8118"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".036316597482865",
						"qValue": ".039683444580175",
						"foldChange": "1.3856"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00595769828885",
						"qValue": ".007191701150101",
						"foldChange": "1.4194"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".136511559720579",
						"qValue": ".041513848244746",
						"foldChange": "1.2099"
					}
				]
			},
			{
				"metaboliteId": "0078dc91-0000-0000-0000-000000000000",
				"chemicalId": "05f5e685-0000-0000-0000-000000000000",
				"chemicalName": "N4-acetylcytidine",
				"plotName": null,
				"compoundId": "0000893a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05923",
				"inChiKey": "NIDVTARKFBZMOT-PEBGCTIMBU",
				"kegg": null,
				"mass": 285.096085,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3872,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".173047800117048",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".870850732679756",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".564459502439166",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".44682963909902",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".450372195852762",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".995236491015845",
						"qValue": "1",
						"foldChange": "1.0735"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".306213709605342",
						"qValue": ".921611482331303",
						"foldChange": "1.1185"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".491655987578508",
						"qValue": ".902562957068497",
						"foldChange": "1.0779"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".734415282733782",
						"qValue": ".976023509428735",
						"foldChange": "1.0037"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".29907712857618",
						"qValue": "1",
						"foldChange": "0.9088"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".854597195967263",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".39105208754372",
						"qValue": "1",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".229166530304879",
						"qValue": "1",
						"foldChange": "1.1917"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".523466378546299",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".602420013056925",
						"qValue": "1",
						"foldChange": "0.8633"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".041902251960865",
						"qValue": "1",
						"foldChange": "1.4515"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".635805508901273",
						"qValue": "1",
						"foldChange": "1.0027"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".128306438024108",
						"qValue": ".931625125271228",
						"foldChange": "0.6908"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".059894895633519",
						"qValue": "1",
						"foldChange": "1.4183"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".36561197782853",
						"qValue": "1",
						"foldChange": "1.0199"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".350591382899814",
						"qValue": ".985830071188835",
						"foldChange": "0.7191"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".573775955518631",
						"qValue": ".280608301274392",
						"foldChange": "1.1420"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".091516489310197",
						"qValue": ".057346173834967",
						"foldChange": "1.4667"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".119295419253388",
						"qValue": ".03725330806537",
						"foldChange": "0.7823"
					}
				]
			},
			{
				"metaboliteId": "0078dc92-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74c-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-aspartyl-glutamate (NAAG)",
				"plotName": null,
				"compoundId": "00008b51-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01067",
				"inChiKey": "OPVPGKGADVGKTG-UHFFFAOYAF",
				"kegg": "C12270",
				"mass": 304.090666,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 53,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".587026497437673",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".905698229108905",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".552750984038717",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".34870852119793",
						"qValue": "1",
						"foldChange": "0.9411"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".432560608659692",
						"qValue": "1",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".87756709359914",
						"qValue": "1",
						"foldChange": "1.2067"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".333494610352895",
						"qValue": ".951252030575746",
						"foldChange": "0.9179"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".44899970951468",
						"qValue": ".901759479402622",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".831755437830427",
						"qValue": "1",
						"foldChange": "1.1098"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".275102883139425",
						"qValue": "1",
						"foldChange": "1.2767"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".410442458188363",
						"qValue": "1",
						"foldChange": "1.2309"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".785551238497772",
						"qValue": "1",
						"foldChange": "0.9550"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".470302259494738",
						"qValue": "1",
						"foldChange": "0.9063"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".45109329213959",
						"qValue": "1",
						"foldChange": "0.9541"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".941780956263318",
						"qValue": "1",
						"foldChange": "1.0527"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".478672128610979",
						"qValue": "1",
						"foldChange": "0.8727"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".520691081344264",
						"qValue": "1",
						"foldChange": "1.1546"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".182061584431274",
						"qValue": "1",
						"foldChange": "1.3230"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".499482548283977",
						"qValue": "1",
						"foldChange": "0.8885"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".726890964149324",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".313381439727202",
						"qValue": ".985830071188835",
						"foldChange": "1.2420"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".06132623572922",
						"qValue": ".057519942222417",
						"foldChange": "0.6389"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000078410071003",
						"qValue": ".000526311519666",
						"foldChange": "0.4407"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000713479576141",
						"qValue": ".00083730172158",
						"foldChange": "0.4941"
					}
				]
			},
			{
				"metaboliteId": "0078dc93-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7e8-0000-0000-0000-000000000000",
				"chemicalName": "N6-carboxymethyllysine",
				"plotName": null,
				"compoundId": "00008f69-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NUXSIDPKKIEIMI-UHFFFAOYAH",
				"kegg": null,
				"mass": 204.111007,
				"pathwayType": "ANIMAL",
				"subPathway": "Advanced Glycation End-product",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1279,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".384985060674364",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".619605014757932",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".892361648589546",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".517421417321133",
						"qValue": "1",
						"foldChange": "1.6837"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".712368258501034",
						"qValue": "1",
						"foldChange": "1.1557"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".779709797136739",
						"qValue": "1",
						"foldChange": "1.2041"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".974300582246518",
						"qValue": "1",
						"foldChange": "1.3530"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".654759589900229",
						"qValue": ".934949271461149",
						"foldChange": "1.2317"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".678113355153291",
						"qValue": ".976023509428735",
						"foldChange": "0.9918"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".913951733807557",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".302432243540095",
						"qValue": "1",
						"foldChange": "0.9046"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".35507667776696",
						"qValue": "1",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".916717758023594",
						"qValue": "1",
						"foldChange": "1.0323"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".199606104014176",
						"qValue": "1",
						"foldChange": "1.2497"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".224666837351578",
						"qValue": "1",
						"foldChange": "1.2107"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".631443330370437",
						"qValue": "1",
						"foldChange": "0.8423"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".503585223461468",
						"qValue": "1",
						"foldChange": "1.1570"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".252075315810144",
						"qValue": "1",
						"foldChange": "1.3736"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".56344779617328",
						"qValue": "1",
						"foldChange": "0.8439"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".923618270808444",
						"qValue": "1",
						"foldChange": "0.9295"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".510903974567102",
						"qValue": ".985830071188835",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".019323698764528",
						"qValue": ".024504073007039",
						"foldChange": "2.6076"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000651511267148",
						"qValue": ".001763801924806",
						"foldChange": "2.5817"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".055948821874585",
						"qValue": ".020879844219214",
						"foldChange": "1.7429"
					}
				]
			},
			{
				"metaboliteId": "0078dc94-0000-0000-0000-000000000000",
				"chemicalId": "05f5f034-0000-0000-0000-000000000000",
				"chemicalName": "lanthionine",
				"plotName": null,
				"compoundId": "0000a412-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DWPCPZJAHOETAG-IMJSIDKUBX",
				"kegg": null,
				"mass": 208.051778,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".910274575727931",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".832133035018297",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".763311555292383",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".775636431769976",
						"qValue": "1",
						"foldChange": "1.1403"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".37030612770668",
						"qValue": "1",
						"foldChange": "1.5566"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".539648096523513",
						"qValue": "1",
						"foldChange": "1.4656"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".885353659581834",
						"qValue": "1",
						"foldChange": "1.3026"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".887397703004527",
						"qValue": "1",
						"foldChange": "1.2702"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".997934319129469",
						"qValue": "1",
						"foldChange": "1.2173"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".270288844771321",
						"qValue": "1",
						"foldChange": "1.0372"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".464392631536738",
						"qValue": "1",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".707288699792537",
						"qValue": "1",
						"foldChange": "1.5126"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".801603664712794",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".282583459998428",
						"qValue": "1",
						"foldChange": "1.3862"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".389654778015727",
						"qValue": "1",
						"foldChange": "1.3983"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".893569751373562",
						"qValue": "1",
						"foldChange": "0.9551"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".987920052878301",
						"qValue": "1",
						"foldChange": "0.8968"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".884631896848459",
						"qValue": "1",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".722623307144737",
						"qValue": "1",
						"foldChange": "1.0835"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".859538823943685",
						"qValue": "1",
						"foldChange": "0.8221"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".870753140242103",
						"qValue": ".997573179629986",
						"foldChange": "0.7588"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000755007239584",
						"qValue": ".002136545237883",
						"foldChange": "3.1468"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".038871896219584",
						"qValue": ".030447443606956",
						"foldChange": "2.1691"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002566409326186",
						"qValue": ".002042657538003",
						"foldChange": "2.3548"
					}
				]
			},
			{
				"metaboliteId": "0078dc95-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7c3-0000-0000-0000-000000000000",
				"chemicalName": "indoleacetylglutamine",
				"plotName": null,
				"compoundId": "0000a467-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13240",
				"inChiKey": null,
				"kegg": null,
				"mass": 303.121906,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 272,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".785904698218091",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".655018271551249",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".118429753480963",
						"qValue": ".834275595151197",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".143724350885557",
						"qValue": "1",
						"foldChange": "1.7091"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".809774461031866",
						"qValue": "1",
						"foldChange": "1.2778"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".219427973524776",
						"qValue": "1",
						"foldChange": "0.9392"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".645299680756241",
						"qValue": "1",
						"foldChange": "1.0388"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".591298017606764",
						"qValue": ".928868848501546",
						"foldChange": "1.2490"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".32050913825965",
						"qValue": ".957839854315834",
						"foldChange": "1.3057"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".043624569937454",
						"qValue": ".821720595261736",
						"foldChange": "0.7448"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".736168931606475",
						"qValue": "1",
						"foldChange": "1.1432"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".08942989093905",
						"qValue": ".81833158267883",
						"foldChange": "1.3693"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".696942041508785",
						"qValue": "1",
						"foldChange": "0.8148"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".855014772443541",
						"qValue": "1",
						"foldChange": "1.1677"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".573107312020913",
						"qValue": "1",
						"foldChange": "1.4330"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".217939753613551",
						"qValue": "1",
						"foldChange": "0.6607"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".177579787180144",
						"qValue": "1",
						"foldChange": "0.5829"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".846600090475949",
						"qValue": "1",
						"foldChange": "0.8822"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".787769457947609",
						"qValue": "1",
						"foldChange": "0.7905"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".943830204569861",
						"qValue": "1",
						"foldChange": "0.8326"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".850337600204941",
						"qValue": ".997573179629986",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".143679924292891",
						"qValue": ".106661594138997",
						"foldChange": "2.2385"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052561432238462",
						"qValue": ".038471747600022",
						"foldChange": "2.0469"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".024286158342837",
						"qValue": ".010942710814158",
						"foldChange": "3.0548"
					}
				]
			},
			{
				"metaboliteId": "0078dc96-0000-0000-0000-000000000000",
				"chemicalId": "05f5e77e-0000-0000-0000-000000000000",
				"chemicalName": "deoxycarnitine",
				"plotName": null,
				"compoundId": "00008f8b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01161",
				"inChiKey": "JHPNVNIEXXLNTR-UHFFFAOYAL",
				"kegg": "C01181",
				"mass": 145.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1703,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".957121442317067",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".66634829171929",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".949236923420123",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".835970803099042",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".735925395036906",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".586643235795989",
						"qValue": "1",
						"foldChange": "0.9953"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".634662952805725",
						"qValue": "1",
						"foldChange": "0.9849"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".695640531533056",
						"qValue": ".950224867310804",
						"foldChange": "0.9922"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".932890810542989",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".372489270155491",
						"qValue": "1",
						"foldChange": "0.9479"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".471924639680328",
						"qValue": "1",
						"foldChange": "0.9629"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".861691951309248",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".791156500041824",
						"qValue": "1",
						"foldChange": "1.0273"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".84621990183707",
						"qValue": "1",
						"foldChange": "1.0128"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".953835120206038",
						"qValue": "1",
						"foldChange": "0.9859"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".862215547392187",
						"qValue": "1",
						"foldChange": "0.9601"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".593529855894191",
						"qValue": "1",
						"foldChange": "0.9008"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".704540547480671",
						"qValue": "1",
						"foldChange": "0.9382"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".80862148674609",
						"qValue": "1",
						"foldChange": "1.0033"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".939978297480688",
						"qValue": "1",
						"foldChange": "0.9063"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".755306429386088",
						"qValue": ".997573179629986",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".014216245433951",
						"qValue": ".019211694270599",
						"foldChange": "1.6237"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000005254315629",
						"qValue": ".000146117023651",
						"foldChange": "1.6275"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002091975023419",
						"qValue": ".001789038355159",
						"foldChange": "1.5514"
					}
				]
			},
			{
				"metaboliteId": "0078dc97-0000-0000-0000-000000000000",
				"chemicalId": "05f5e812-0000-0000-0000-000000000000",
				"chemicalName": "dimethylarginine (SDMA + ADMA)",
				"plotName": null,
				"compoundId": "00008fc8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01539",
				"inChiKey": "YDGMGEXADBMOMJ-LURJTMIEBT",
				"kegg": "C03626",
				"mass": 202.142976,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".858263193995224",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".293503198791358",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".732145359093784",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".165051342687434",
						"qValue": "1",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".591655565522125",
						"qValue": "1",
						"foldChange": "0.9758"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".38877937805109",
						"qValue": "1",
						"foldChange": "1.0473"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".594576068022763",
						"qValue": "1",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".243379291477399",
						"qValue": ".825038702902081",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".521795956740546",
						"qValue": ".957839854315834",
						"foldChange": "0.9814"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".985483377147362",
						"qValue": "1",
						"foldChange": "1.0019"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".346395541599409",
						"qValue": "1",
						"foldChange": "0.9616"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".355665214149613",
						"qValue": "1",
						"foldChange": "0.8964"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".99863861888595",
						"qValue": "1",
						"foldChange": "0.9723"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".576707544082369",
						"qValue": "1",
						"foldChange": "0.9214"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".568595821444637",
						"qValue": "1",
						"foldChange": "0.9477"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".565941671145035",
						"qValue": "1",
						"foldChange": "1.0367"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".777985645353502",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".788640683789798",
						"qValue": "1",
						"foldChange": "0.9980"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".709267542601472",
						"qValue": "1",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".401360615986409",
						"qValue": "1",
						"foldChange": "0.8770"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".616272468405524",
						"qValue": ".985830071188835",
						"foldChange": "0.9074"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".128750710578763",
						"qValue": ".09913522976921",
						"foldChange": "1.1841"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001316940022472",
						"qValue": ".002578464669276",
						"foldChange": "1.2541"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00364474305381",
						"qValue": ".002616013038889",
						"foldChange": "1.3142"
					}
				]
			},
			{
				"metaboliteId": "0078dc98-0000-0000-0000-000000000000",
				"chemicalId": "05f5e89c-0000-0000-0000-000000000000",
				"chemicalName": "succinylcarnitine (C4-DC)",
				"plotName": null,
				"compoundId": "000090c2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61717",
				"inChiKey": null,
				"kegg": null,
				"mass": 261.121237,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1291,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".560776266875177",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".37233353890678",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".446489150499823",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".039318619790744",
						"qValue": "1",
						"foldChange": "1.5101"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".64578211293882",
						"qValue": "1",
						"foldChange": "1.0520"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".104216249670616",
						"qValue": "1",
						"foldChange": "0.9376"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".818239616251321",
						"qValue": "1",
						"foldChange": "0.9894"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".989545561779546",
						"qValue": "1",
						"foldChange": "1.0090"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".808081868428488",
						"qValue": "1",
						"foldChange": "1.0332"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".883798826683608",
						"qValue": "1",
						"foldChange": "1.0244"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".55279299889921",
						"qValue": "1",
						"foldChange": "0.9561"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".460071458525979",
						"qValue": "1",
						"foldChange": "0.8620"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".99268278427752",
						"qValue": "1",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".887300853103935",
						"qValue": "1",
						"foldChange": "0.9516"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".891587125240586",
						"qValue": "1",
						"foldChange": "0.9660"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".096378497929615",
						"qValue": "1",
						"foldChange": "0.6974"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".144998203570333",
						"qValue": "1",
						"foldChange": "0.7019"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".900954838065202",
						"qValue": "1",
						"foldChange": "1.0065"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".739877258098204",
						"qValue": "1",
						"foldChange": "0.9629"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".377576324570155",
						"qValue": "1",
						"foldChange": "0.8267"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".558696105293818",
						"qValue": ".985830071188835",
						"foldChange": "0.8585"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".353034379890977",
						"qValue": ".196118055917256",
						"foldChange": "1.2689"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".016630298644766",
						"qValue": ".015561408031615",
						"foldChange": "1.3244"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".015595174221425",
						"qValue": ".007738668197996",
						"foldChange": "1.3740"
					}
				]
			},
			{
				"metaboliteId": "0078dc99-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8a4-0000-0000-0000-000000000000",
				"chemicalName": "N-methylproline",
				"plotName": null,
				"compoundId": "00009237-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CWLQUGTUXBXTLF-UHFFFAOYAJ",
				"kegg": null,
				"mass": 129.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 446,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".514561672325885",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".68922606312659",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".950875369690661",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".913793027143265",
						"qValue": "1",
						"foldChange": "2.5498"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".992181078192328",
						"qValue": "1",
						"foldChange": "1.7450"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".906025472871847",
						"qValue": "1",
						"foldChange": "1.1677"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".650143152350333",
						"qValue": "1",
						"foldChange": "1.3711"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".594752606915006",
						"qValue": ".928868848501546",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".32619138739601",
						"qValue": ".957839854315834",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".679426901405689",
						"qValue": "1",
						"foldChange": "1.2286"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".492642759047968",
						"qValue": "1",
						"foldChange": "1.2959"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".784017479710899",
						"qValue": "1",
						"foldChange": "1.3231"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".817348151191722",
						"qValue": "1",
						"foldChange": "0.9000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".484525038190032",
						"qValue": "1",
						"foldChange": "0.4842"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".621528424705188",
						"qValue": "1",
						"foldChange": "0.5380"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".983879505455167",
						"qValue": "1",
						"foldChange": "1.1368"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".30179588146812",
						"qValue": "1",
						"foldChange": "0.4255"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".299149868049722",
						"qValue": "1",
						"foldChange": "0.3743"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".579568097566785",
						"qValue": "1",
						"foldChange": "0.7914"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".255472439740709",
						"qValue": "1",
						"foldChange": "0.3709"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".525561649019286",
						"qValue": ".985830071188835",
						"foldChange": "0.4686"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".580124140404472",
						"qValue": ".282877237475127",
						"foldChange": "0.9250"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".4683573255027",
						"qValue": ".193276678684815",
						"foldChange": "1.0220"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".280023974144803",
						"qValue": ".072033779681829",
						"foldChange": "1.3809"
					}
				]
			},
			{
				"metaboliteId": "0078dc9a-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1de-0000-0000-0000-000000000000",
				"chemicalName": "indolin-2-one",
				"plotName": null,
				"compoundId": "0000a96e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JYGFTBXVXVMTGB-UHFFFAOYAF",
				"kegg": "C12312",
				"mass": 133.052764,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4444,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".948923382836245",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".935940109217135",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".720979799191692",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".693622818855958",
						"qValue": "1",
						"foldChange": "1.2346"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".556931097138353",
						"qValue": "1",
						"foldChange": "1.3935"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".846043484237125",
						"qValue": "1",
						"foldChange": "1.0922"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".914096651075651",
						"qValue": "1",
						"foldChange": "1.4820"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".524551789687926",
						"qValue": ".910963185640849",
						"foldChange": "1.8336"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".596775875747511",
						"qValue": ".962532817488692",
						"foldChange": "1.3019"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".343334487168721",
						"qValue": "1",
						"foldChange": "0.9370"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".288033317835068",
						"qValue": "1",
						"foldChange": "1.0392"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".907110377296634",
						"qValue": "1",
						"foldChange": "0.9591"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".844034417998905",
						"qValue": "1",
						"foldChange": "0.8757"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".636035779084295",
						"qValue": "1",
						"foldChange": "1.1325"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".500139124591134",
						"qValue": "1",
						"foldChange": "1.2933"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".692708074731228",
						"qValue": "1",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".66744637622207",
						"qValue": "1",
						"foldChange": "0.8911"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".954173784268444",
						"qValue": "1",
						"foldChange": "0.9485"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".852832144186799",
						"qValue": "1",
						"foldChange": "1.1914"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".526490968063696",
						"qValue": "1",
						"foldChange": "0.8655"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".63836141213008",
						"qValue": ".985830071188835",
						"foldChange": "0.7265"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".232789198852789",
						"qValue": ".148505420663879",
						"foldChange": "2.0106"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".009265280241167",
						"qValue": ".009533543992994",
						"foldChange": "3.3301"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00326018684772",
						"qValue": ".002382543375278",
						"foldChange": "2.5296"
					}
				]
			},
			{
				"metaboliteId": "0078dc9b-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1bc-0000-0000-0000-000000000000",
				"chemicalName": "dimethyl sulfone",
				"plotName": null,
				"compoundId": "0000a9a0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04983",
				"inChiKey": "HHVIBTZHLRERCL-UHFFFAOYAG",
				"kegg": "C11142",
				"mass": 94.00885,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5294,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".031853348935334",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".684249530853875",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".022543015596294",
						"qValue": ".622389736970234",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".660147190213958",
						"qValue": "1",
						"foldChange": "2.2598"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".114470456850555",
						"qValue": "1",
						"foldChange": "3.1753"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".045837475756674",
						"qValue": "1",
						"foldChange": "2.3688"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".647173095464501",
						"qValue": "1",
						"foldChange": "1.4336"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".064640241771058",
						"qValue": ".687896186587066",
						"foldChange": "1.1186"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".022834313244261",
						"qValue": ".57754088580993",
						"foldChange": "0.8124"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".178743879813469",
						"qValue": "1",
						"foldChange": "1.6802"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".216925396290513",
						"qValue": "1",
						"foldChange": "1.8876"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".910190872430505",
						"qValue": "1",
						"foldChange": "1.0523"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".245660403034359",
						"qValue": "1",
						"foldChange": "2.0948"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".038142240246186",
						"qValue": "1",
						"foldChange": "7.6286"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".296054984512827",
						"qValue": "1",
						"foldChange": "3.6417"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".100459612735641",
						"qValue": "1",
						"foldChange": "3.0697"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".003002868216147",
						"qValue": ".851813617313741",
						"foldChange": "11.6560"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".113293378180934",
						"qValue": ".908885675694672",
						"foldChange": "3.7971"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".443888790563233",
						"qValue": "1",
						"foldChange": "0.8456"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".053124489936616",
						"qValue": "1",
						"foldChange": "6.6683"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".008149632138081",
						"qValue": ".893076453757469",
						"foldChange": "7.8863"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".048922181920807",
						"qValue": ".049083860920383",
						"foldChange": "10.7077"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".355985655530013",
						"qValue": ".156077567873942",
						"foldChange": "1.6196"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01556171300071",
						"qValue": ".007738668197996",
						"foldChange": "2.2145"
					}
				]
			},
			{
				"metaboliteId": "0078dc9c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e69d-0000-0000-0000-000000000000",
				"chemicalName": "cysteine-glutathione disulfide",
				"plotName": null,
				"compoundId": "00008957-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00656",
				"inChiKey": "BNRXZEPOHPEEAS-UHFFFAOYAL",
				"kegg": null,
				"mass": 426.087905,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 493,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".16690324628257",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".870192785515689",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".16363945987851",
						"qValue": ".898433421655562",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".400681277392175",
						"qValue": "1",
						"foldChange": "3.3981"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".339493928862064",
						"qValue": "1",
						"foldChange": "0.9095"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".076132804004633",
						"qValue": "1",
						"foldChange": "0.8591"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".235516910660731",
						"qValue": ".852077213869098",
						"foldChange": "0.8975"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".531797556936691",
						"qValue": ".910963185640849",
						"foldChange": "1.3326"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".07328823754372",
						"qValue": ".794743555017874",
						"foldChange": "1.6763"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".55741433130052",
						"qValue": "1",
						"foldChange": "1.0188"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".922793068550222",
						"qValue": "1",
						"foldChange": "1.0411"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".623986314959319",
						"qValue": "1",
						"foldChange": "1.1326"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".189779520559693",
						"qValue": "1",
						"foldChange": "0.7521"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".152607131067223",
						"qValue": "1",
						"foldChange": "0.5845"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".840995234742977",
						"qValue": "1",
						"foldChange": "0.7772"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".016621861829372",
						"qValue": "1",
						"foldChange": "0.2976"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".028252410098553",
						"qValue": "1",
						"foldChange": "0.2441"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".928193851130686",
						"qValue": "1",
						"foldChange": "0.8203"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".680535648789156",
						"qValue": "1",
						"foldChange": "0.8998"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".333349378663305",
						"qValue": "1",
						"foldChange": "0.5890"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".550833943239377",
						"qValue": ".985830071188835",
						"foldChange": "0.6546"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003148136591041",
						"qValue": ".006317081230889",
						"foldChange": "0.3749"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00403725764889",
						"qValue": ".005573143206225",
						"foldChange": "0.4603"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010443525355708",
						"qValue": ".005615610053202",
						"foldChange": "0.4875"
					}
				]
			},
			{
				"metaboliteId": "0078dc9d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e840-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPE (18:0/18:1)",
				"plotName": null,
				"compoundId": "0000a5d0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08993",
				"inChiKey": null,
				"kegg": null,
				"mass": 745.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2157,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".955480693036562",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".995584440360322",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".454258562412193",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".549182649246612",
						"qValue": "1",
						"foldChange": "1.2225"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".458109328949494",
						"qValue": "1",
						"foldChange": "0.9323"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".182950166596901",
						"qValue": "1",
						"foldChange": "0.9570"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".892619343126259",
						"qValue": "1",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".277721952071365",
						"qValue": ".825038702902081",
						"foldChange": "1.3108"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".34077202333192",
						"qValue": ".957839854315834",
						"foldChange": "1.2271"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".514705548109137",
						"qValue": "1",
						"foldChange": "0.9318"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".897474517601481",
						"qValue": "1",
						"foldChange": "1.0380"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".600719345807569",
						"qValue": "1",
						"foldChange": "1.0435"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".975523261304352",
						"qValue": "1",
						"foldChange": "1.0941"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".771959197249977",
						"qValue": "1",
						"foldChange": "1.0444"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".744012970668033",
						"qValue": "1",
						"foldChange": "0.9546"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".734097364632794",
						"qValue": "1",
						"foldChange": "0.8466"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".610043119704468",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".846203242139013",
						"qValue": "1",
						"foldChange": "0.9640"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".25975144254727",
						"qValue": "1",
						"foldChange": "1.3107"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".50638497866558",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".676645424224866",
						"qValue": ".990272584968167",
						"foldChange": "0.8244"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".221740334528083",
						"qValue": ".14353959648982",
						"foldChange": "1.3917"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".039299374687188",
						"qValue": ".030696052586732",
						"foldChange": "1.6910"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037522841546881",
						"qValue": ".01516382095168",
						"foldChange": "1.6313"
					}
				]
			},
			{
				"metaboliteId": "0078dc9e-0000-0000-0000-000000000000",
				"chemicalId": "0000013a-0000-0000-0000-000000000000",
				"chemicalName": "behenate (22:0)*",
				"plotName": null,
				"compoundId": "00002f5d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00944",
				"inChiKey": "UKMSUNONTOPOIO-UHFFFAOYAN",
				"kegg": "C08281",
				"mass": 340.33413,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1349,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".856381923325969",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".984867085619827",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".638404015039398",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".231809174286225",
						"qValue": "1",
						"foldChange": "5.5568"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".443935937471952",
						"qValue": "1",
						"foldChange": "1.4883"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".662557329675142",
						"qValue": "1",
						"foldChange": "1.7499"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".400858298077921",
						"qValue": ".977451036287423",
						"foldChange": "0.9608"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".583967421799545",
						"qValue": ".928868848501546",
						"foldChange": "0.9212"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".768286621462683",
						"qValue": ".98622264532067",
						"foldChange": "2.0383"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".630809060316907",
						"qValue": "1",
						"foldChange": "0.9001"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".607460490530127",
						"qValue": "1",
						"foldChange": "1.5855"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".973481223965969",
						"qValue": "1",
						"foldChange": "1.2285"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".621701061354032",
						"qValue": "1",
						"foldChange": "1.3399"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".378771716425317",
						"qValue": "1",
						"foldChange": "2.0268"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".668932858684851",
						"qValue": "1",
						"foldChange": "1.5127"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".639207813954628",
						"qValue": "1",
						"foldChange": "1.1951"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".915871635593849",
						"qValue": "1",
						"foldChange": "1.3228"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".574304246988596",
						"qValue": "1",
						"foldChange": "1.1069"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".900460490233274",
						"qValue": "1",
						"foldChange": "0.8935"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".773310349939899",
						"qValue": "1",
						"foldChange": "1.4389"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".677500336554757",
						"qValue": ".990272584968167",
						"foldChange": "1.6103"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".591806602673614",
						"qValue": ".287022010456366",
						"foldChange": "1.5253"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".703802494374031",
						"qValue": ".266432367171315",
						"foldChange": "0.9773"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".797257392521644",
						"qValue": ".160024708403571",
						"foldChange": "1.5394"
					}
				]
			},
			{
				"metaboliteId": "0078dc9f-0000-0000-0000-000000000000",
				"chemicalId": "05f603fd-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/24:1, d18:1/24:2)*",
				"plotName": null,
				"compoundId": "0000ccd5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 810.6614,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2818,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".686516206099397",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".700634089087435",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".1054945343754",
						"qValue": ".816144079576958",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".75186796554448",
						"qValue": "1",
						"foldChange": "1.0202"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".262372493751206",
						"qValue": "1",
						"foldChange": "1.0550"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".418454954651055",
						"qValue": "1",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".593343897709994",
						"qValue": "1",
						"foldChange": "1.0313"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".286791060558304",
						"qValue": ".825038702902081",
						"foldChange": "1.0523"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".591985363324391",
						"qValue": ".962532817488692",
						"foldChange": "1.0295"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".621622952263648",
						"qValue": "1",
						"foldChange": "1.0270"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".068306442588384",
						"qValue": "1",
						"foldChange": "0.9297"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".022295164183417",
						"qValue": ".614826369149758",
						"foldChange": "0.8973"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999177010732448",
						"qValue": "1",
						"foldChange": "0.9739"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".726181337306211",
						"qValue": "1",
						"foldChange": "0.9420"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".719049292465062",
						"qValue": "1",
						"foldChange": "0.9672"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".939382724524165",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".78467829974116",
						"qValue": "1",
						"foldChange": "1.0044"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".723821098820853",
						"qValue": "1",
						"foldChange": "0.9797"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".96584386599341",
						"qValue": "1",
						"foldChange": "0.9889"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".140926103572939",
						"qValue": "1",
						"foldChange": "0.8560"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".142175810873888",
						"qValue": ".985830071188835",
						"foldChange": "0.8655"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".967268597446008",
						"qValue": ".388186098375975",
						"foldChange": "1.0170"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".093735863849809",
						"qValue": ".058343553680028",
						"foldChange": "1.1686"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".227375681414161",
						"qValue": ".060524063835401",
						"foldChange": "1.1489"
					}
				]
			},
			{
				"metaboliteId": "0078dca0-0000-0000-0000-000000000000",
				"chemicalId": "05f5faf2-0000-0000-0000-000000000000",
				"chemicalName": "glycodeoxycholate 3-sulfate",
				"plotName": null,
				"compoundId": "0000ceef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 529.270938,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3167,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".840574767162164",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".485672569702122",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".441584260832954",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".932037655987221",
						"qValue": "1",
						"foldChange": "1.1467"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".212937219125011",
						"qValue": "1",
						"foldChange": "3.3468"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".183974165640996",
						"qValue": "1",
						"foldChange": "3.2411"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".542680926572558",
						"qValue": "1",
						"foldChange": "1.3707"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".296339510005877",
						"qValue": ".825038702902081",
						"foldChange": "1.2954"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".659988774617169",
						"qValue": ".975068512044627",
						"foldChange": "1.1935"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".921463487430609",
						"qValue": "1",
						"foldChange": "3.6629"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".257306418918635",
						"qValue": "1",
						"foldChange": "4.3347"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".300204227307215",
						"qValue": "1",
						"foldChange": "1.7185"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".279310176569577",
						"qValue": "1",
						"foldChange": "2.5486"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".809272390358094",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".420836755080221",
						"qValue": "1",
						"foldChange": "0.4021"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".440025881084254",
						"qValue": "1",
						"foldChange": "2.6111"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".722071075612104",
						"qValue": "1",
						"foldChange": "0.8547"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".699733371157047",
						"qValue": "1",
						"foldChange": "0.3273"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".728839141697441",
						"qValue": "1",
						"foldChange": "1.5467"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".830874720611222",
						"qValue": "1",
						"foldChange": "1.0729"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".579678672295793",
						"qValue": ".985830071188835",
						"foldChange": "0.6937"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".923995325640762",
						"qValue": ".378764696310446",
						"foldChange": "0.8640"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".31358061309841",
						"qValue": ".145492242922197",
						"foldChange": "0.9549"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000559264186525",
						"qValue": ".000676061763781",
						"foldChange": "3.5207"
					}
				]
			},
			{
				"metaboliteId": "0078dca1-0000-0000-0000-000000000000",
				"chemicalId": "05f5fafb-0000-0000-0000-000000000000",
				"chemicalName": "3,4-methyleneheptanoate",
				"plotName": null,
				"compoundId": "0000cf22-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 142.09938,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4636,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".886951227206155",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".220390870132025",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".861799513886823",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".938177741674398",
						"qValue": "1",
						"foldChange": "1.2190"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".729027831819751",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".671711539620863",
						"qValue": "1",
						"foldChange": "1.1823"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".457106050530699",
						"qValue": "1",
						"foldChange": "0.9933"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".264380079202509",
						"qValue": ".825038702902081",
						"foldChange": "0.9337"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".70589596704968",
						"qValue": ".976023509428735",
						"foldChange": "1.2385"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".167240677079464",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".146176336558902",
						"qValue": "1",
						"foldChange": "0.9494"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".940724939318322",
						"qValue": "1",
						"foldChange": "1.6709"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".442925030987664",
						"qValue": "1",
						"foldChange": "1.4319"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".322183639082431",
						"qValue": "1",
						"foldChange": "1.5684"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".784669975795173",
						"qValue": "1",
						"foldChange": "1.0953"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".909864521345351",
						"qValue": "1",
						"foldChange": "1.1175"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".813291010162254",
						"qValue": "1",
						"foldChange": "1.0422"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".725227502696398",
						"qValue": "1",
						"foldChange": "0.9327"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".866363033978437",
						"qValue": "1",
						"foldChange": "0.9587"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".970799470271762",
						"qValue": "1",
						"foldChange": "0.7639"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".900115079736425",
						"qValue": ".997573179629986",
						"foldChange": "0.7968"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".014473173616486",
						"qValue": ".019479396747903",
						"foldChange": "0.4916"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".032459588575248",
						"qValue": ".026388457018901",
						"foldChange": "0.6231"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".262244223155946",
						"qValue": ".068156467360188",
						"foldChange": "0.6887"
					}
				]
			},
			{
				"metaboliteId": "0078dca2-0000-0000-0000-000000000000",
				"chemicalId": "05f60537-0000-0000-0000-000000000000",
				"chemicalName": "(S)-3-hydroxybutyrylcarnitine",
				"plotName": null,
				"compoundId": "0000cef8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13127",
				"inChiKey": null,
				"kegg": null,
				"mass": 247.141973,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1696,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".622582864263654",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".213112947816976",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".352924466677587",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".262142151485063",
						"qValue": "1",
						"foldChange": "1.0149"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".045784601995317",
						"qValue": "1",
						"foldChange": "0.8364"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".364275812891592",
						"qValue": "1",
						"foldChange": "0.9353"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".746692434464514",
						"qValue": "1",
						"foldChange": "0.9982"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".153704726220564",
						"qValue": ".784201030789377",
						"foldChange": "0.8718"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".26634404297818",
						"qValue": ".957839854315834",
						"foldChange": "0.9074"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".215996976638137",
						"qValue": "1",
						"foldChange": "1.2610"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".524966730085381",
						"qValue": "1",
						"foldChange": "1.1862"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".542517232724433",
						"qValue": "1",
						"foldChange": "0.9982"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".263493996366816",
						"qValue": "1",
						"foldChange": "0.4522"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".264473477475352",
						"qValue": "1",
						"foldChange": "0.4391"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".951705060976061",
						"qValue": "1",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".440693081550165",
						"qValue": "1",
						"foldChange": "0.6889"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".899358983228373",
						"qValue": "1",
						"foldChange": "0.7880"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".535919691258327",
						"qValue": "1",
						"foldChange": "1.1438"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".40380898156159",
						"qValue": "1",
						"foldChange": "0.5732"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".985069692948775",
						"qValue": "1",
						"foldChange": "0.7418"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".42948201266744",
						"qValue": ".985830071188835",
						"foldChange": "1.2940"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".725169061331246",
						"qValue": ".327107156366799",
						"foldChange": "0.8644"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".256954395228054",
						"qValue": ".12434741170742",
						"foldChange": "0.5918"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".640712593617862",
						"qValue": ".134129110982267",
						"foldChange": "1.0319"
					}
				]
			},
			{
				"metaboliteId": "0078dca3-0000-0000-0000-000000000000",
				"chemicalId": "05f5fae3-0000-0000-0000-000000000000",
				"chemicalName": "suberoylcarnitine (C8-DC)",
				"plotName": null,
				"compoundId": "0000cefe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 317.183838,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1691,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".786457474204957",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".214050135414345",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".231750869127589",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".735999034393734",
						"qValue": "1",
						"foldChange": "1.0411"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".627273847187082",
						"qValue": "1",
						"foldChange": "0.9547"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".881715822250429",
						"qValue": "1",
						"foldChange": "1.0454"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".542781156655203",
						"qValue": "1",
						"foldChange": "1.3034"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".315627406319219",
						"qValue": ".825038702902081",
						"foldChange": "1.2649"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".110270655494847",
						"qValue": ".893591860856987",
						"foldChange": "1.2537"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".020758291666256",
						"qValue": ".821720595261736",
						"foldChange": "2.0261"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".127958219098861",
						"qValue": "1",
						"foldChange": "1.5216"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".403795338786931",
						"qValue": "1",
						"foldChange": "0.8751"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".91175161547169",
						"qValue": "1",
						"foldChange": "0.9422"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".772570014093632",
						"qValue": "1",
						"foldChange": "0.8652"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".686839435473745",
						"qValue": "1",
						"foldChange": "0.9183"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".528416877325556",
						"qValue": "1",
						"foldChange": "1.6559"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".215979157603661",
						"qValue": "1",
						"foldChange": "1.5132"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".505060354711302",
						"qValue": "1",
						"foldChange": "0.9139"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".875120113039944",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".396464425710506",
						"qValue": "1",
						"foldChange": "1.4123"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".308718680960541",
						"qValue": ".985830071188835",
						"foldChange": "1.4435"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".489720882379993",
						"qValue": ".24679144011809",
						"foldChange": "1.1967"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".055428823192467",
						"qValue": ".039835386824503",
						"foldChange": "1.3677"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".016761984800695",
						"qValue": ".008117239582777",
						"foldChange": "2.3324"
					}
				]
			},
			{
				"metaboliteId": "0078dca4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9ef-0000-0000-0000-000000000000",
				"chemicalName": "O-desmethyltramadol glucuronide",
				"plotName": null,
				"compoundId": "0000cc85-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DSBGQRZOJXSECT-VFUSOVNCBT",
				"kegg": null,
				"mass": 425.204967,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4826,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".316797415981209",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".28869904210721",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".320381924234875",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".544982145691468",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".063360726180676",
						"qValue": "1",
						"foldChange": "0.9793"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".015487275891183",
						"qValue": ".573029207973764",
						"foldChange": "0.9763"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".165236436858942",
						"qValue": "1",
						"foldChange": "1.0248"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".155362844121556",
						"qValue": "1",
						"foldChange": "1.0248"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".06880704150544",
						"qValue": "1",
						"foldChange": "1.0301"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".062525673907171",
						"qValue": ".822195137696456",
						"foldChange": "1.0301"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dca5-0000-0000-0000-000000000000",
				"chemicalId": "05f5f871-0000-0000-0000-000000000000",
				"chemicalName": "N,O-didesmethylvenlafaxine glucuronide",
				"plotName": null,
				"compoundId": "0000cc86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61167",
				"inChiKey": null,
				"kegg": null,
				"mass": 425.204967,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5096,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444216931193125",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339933",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".05833588237882",
						"qValue": ".533804687143822",
						"foldChange": "0.9790"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".908101095258923",
						"qValue": "1",
						"foldChange": "1.0014"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".045293162694265",
						"qValue": ".679210111549633",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".187859391493731",
						"qValue": "1",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".201830664643189",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999994",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999993",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".163259461542427",
						"qValue": "1",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".17659406096664",
						"qValue": ".985830071188835",
						"foldChange": "0.9725"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dca6-0000-0000-0000-000000000000",
				"chemicalId": "00000446-0000-0000-0000-000000000000",
				"chemicalName": "thyroxine",
				"plotName": null,
				"compoundId": "00000ac9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01918",
				"inChiKey": "XUIIKFGFIJCVMT-LBPRGKRZBS",
				"kegg": "C01829",
				"mass": 776.686717,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".625439546197201",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".061358239575094",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".060542629375293",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".263779912439469",
						"qValue": "1",
						"foldChange": "0.9533"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".949519699096865",
						"qValue": "1",
						"foldChange": "1.0066"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".238194778370654",
						"qValue": "1",
						"foldChange": "1.0621"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".321382030699151",
						"qValue": ".942586781423443",
						"foldChange": "1.0520"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".068835478034163",
						"qValue": ".687896186587066",
						"foldChange": "0.9349"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".00619941647122",
						"qValue": ".229378409435141",
						"foldChange": "0.8897"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".367260582245719",
						"qValue": "1",
						"foldChange": "1.0513"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".162019022415841",
						"qValue": "1",
						"foldChange": "0.9451"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".024036125945948",
						"qValue": ".614826369149758",
						"foldChange": "0.8662"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".447085412140847",
						"qValue": "1",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".492900149680013",
						"qValue": "1",
						"foldChange": "1.0453"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".972249125818889",
						"qValue": "1",
						"foldChange": "0.9965"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".096088816056242",
						"qValue": "1",
						"foldChange": "1.1883"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".120866657135055",
						"qValue": "1",
						"foldChange": "1.1497"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".97742628245179",
						"qValue": "1",
						"foldChange": "0.9675"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".951550992706214",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".984711967258175",
						"qValue": "1",
						"foldChange": "0.9638"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".937445236683827",
						"qValue": ".997573179629986",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".323866266688187",
						"qValue": ".188783580107047",
						"foldChange": "1.1847"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".01142944142876",
						"qValue": ".011341847215214",
						"foldChange": "1.3484"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037753548087868",
						"qValue": ".01516382095168",
						"foldChange": "1.1731"
					}
				]
			},
			{
				"metaboliteId": "0078dca7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecb8-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-GPE (P-16:0)*",
				"plotName": null,
				"compoundId": "00009966-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QYTPGOPLNFESQC-RLHAVTDZBD",
				"kegg": null,
				"mass": 437.28983,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2536,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".779932125158368",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".789036991195079",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".667400635377207",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".935477230183841",
						"qValue": "1",
						"foldChange": "1.2667"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".282680752065679",
						"qValue": "1",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".31987624222921",
						"qValue": "1",
						"foldChange": "1.1054"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".532407344214883",
						"qValue": "1",
						"foldChange": "0.9895"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".960033476745683",
						"qValue": "1",
						"foldChange": "1.1644"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".565625276260843",
						"qValue": ".957839854315834",
						"foldChange": "1.2533"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".625241891490792",
						"qValue": "1",
						"foldChange": "0.9754"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".642296686654587",
						"qValue": "1",
						"foldChange": "1.2166"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".342341471475024",
						"qValue": "1",
						"foldChange": "1.2960"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".539665028362979",
						"qValue": "1",
						"foldChange": "0.8102"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".676305411785277",
						"qValue": "1",
						"foldChange": "0.7773"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".867418805296047",
						"qValue": "1",
						"foldChange": "0.9595"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".279611581332738",
						"qValue": "1",
						"foldChange": "0.7367"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".425521587738852",
						"qValue": "1",
						"foldChange": "0.7388"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".815297918811069",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".736274548556",
						"qValue": "1",
						"foldChange": "1.2134"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".342615489575047",
						"qValue": "1",
						"foldChange": "1.1819"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".516939290959543",
						"qValue": ".985830071188835",
						"foldChange": "0.9740"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000041093138109",
						"qValue": ".000320994653109",
						"foldChange": "2.5575"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".221281971357186",
						"qValue": ".112188527668729",
						"foldChange": "1.4374"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004991072494194",
						"qValue": ".00322266777609",
						"foldChange": "1.8661"
					}
				]
			},
			{
				"metaboliteId": "0078dca8-0000-0000-0000-000000000000",
				"chemicalId": "05f60410-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-linoleoyl-GPE (18:0/18:2)*",
				"plotName": null,
				"compoundId": "0000ccde-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08994",
				"inChiKey": "YDTWOEYVDRKKCR-KNERPIHHBL",
				"kegg": null,
				"mass": 743.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2160,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".44083944481033",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".552322293330276",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".425547917930833",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".391792962139646",
						"qValue": "1",
						"foldChange": "1.1060"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".940129237062148",
						"qValue": "1",
						"foldChange": "1.0224"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".434184491815029",
						"qValue": "1",
						"foldChange": "0.9594"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".153720099639238",
						"qValue": ".742487134132407",
						"foldChange": "1.2319"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".049419989587929",
						"qValue": ".678234398444917",
						"foldChange": "1.2741"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".573672918731141",
						"qValue": ".957839854315834",
						"foldChange": "1.1041"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".623655707997906",
						"qValue": "1",
						"foldChange": "0.9612"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".701452606985458",
						"qValue": "1",
						"foldChange": "0.9897"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".914285940011583",
						"qValue": "1",
						"foldChange": "0.9571"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".513382550598549",
						"qValue": "1",
						"foldChange": "1.2512"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".653894581917461",
						"qValue": "1",
						"foldChange": "1.0886"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".861047824866043",
						"qValue": "1",
						"foldChange": "0.8701"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".356182422071063",
						"qValue": "1",
						"foldChange": "1.1596"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".801356044298039",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".251732180386189",
						"qValue": "1",
						"foldChange": "0.8500"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".108265799397092",
						"qValue": "1",
						"foldChange": "1.3062"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".838101072013457",
						"qValue": "1",
						"foldChange": "0.9791"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".174636282009001",
						"qValue": ".985830071188835",
						"foldChange": "0.7496"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".906716612523929",
						"qValue": ".373389724766972",
						"foldChange": "1.0612"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005015808779539",
						"qValue": ".006475187166597",
						"foldChange": "1.6699"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".073019212026343",
						"qValue": ".025355790610666",
						"foldChange": "1.4677"
					}
				]
			},
			{
				"metaboliteId": "0078dca9-0000-0000-0000-000000000000",
				"chemicalId": "05f60414-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-linoleoyl-GPC (18:0/18:2)*",
				"plotName": null,
				"compoundId": "0000cce4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08039",
				"inChiKey": null,
				"kegg": null,
				"mass": 785.593455,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2063,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".58543182326364",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".809156783589391",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".21825267660675",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".922616669759346",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".435233000885837",
						"qValue": "1",
						"foldChange": "1.0463"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".494010940499586",
						"qValue": "1",
						"foldChange": "1.0442"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".156491397995403",
						"qValue": ".742487134132407",
						"foldChange": "1.0990"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".329339649971504",
						"qValue": ".831655911352372",
						"foldChange": "1.0613"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".651827252640032",
						"qValue": ".973034272612281",
						"foldChange": "0.9856"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".069431593810725",
						"qValue": ".832201215956717",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".306654354409926",
						"qValue": "1",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".414085586832304",
						"qValue": "1",
						"foldChange": "1.0041"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".937743813231292",
						"qValue": "1",
						"foldChange": "1.0155"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".091445730040083",
						"qValue": "1",
						"foldChange": "1.1527"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".097804846468629",
						"qValue": "1",
						"foldChange": "1.1350"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".352071091483982",
						"qValue": "1",
						"foldChange": "1.0755"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".693661848878857",
						"qValue": "1",
						"foldChange": "1.0585"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".616160070451446",
						"qValue": "1",
						"foldChange": "0.9842"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".858615748010906",
						"qValue": "1",
						"foldChange": "1.0067"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".60900795638488",
						"qValue": "1",
						"foldChange": "1.0141"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".724898360644348",
						"qValue": ".997573179629986",
						"foldChange": "1.0074"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".700101683249849",
						"qValue": ".320657800180001",
						"foldChange": "1.0437"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".540724003599175",
						"qValue": ".215706672507429",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".369999242617032",
						"qValue": ".08773889224606",
						"foldChange": "1.0716"
					}
				]
			},
			{
				"metaboliteId": "0078dcaa-0000-0000-0000-000000000000",
				"chemicalId": "00000603-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPC (16:0/18:1)",
				"plotName": null,
				"compoundId": "0000cced-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07972",
				"inChiKey": "WTJKGGKOPKCXLL-MRCUWXFGBH",
				"kegg": null,
				"mass": 759.577805,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2023,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".126538444903174",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".112807983924256",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".772062413274999",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".297106898052234",
						"qValue": "1",
						"foldChange": "1.0470"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".597268385762613",
						"qValue": "1",
						"foldChange": "1.0211"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".603901578583331",
						"qValue": "1",
						"foldChange": "0.9872"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".028626263561126",
						"qValue": ".374891102793553",
						"foldChange": "1.0923"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".517624810343692",
						"qValue": ".908244770314396",
						"foldChange": "1.0291"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".115111494858203",
						"qValue": ".915512917049817",
						"foldChange": "0.9510"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".728701427987448",
						"qValue": "1",
						"foldChange": "1.0166"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".792318343668661",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".542338963895955",
						"qValue": "1",
						"foldChange": "0.9476"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".108486288176083",
						"qValue": "1",
						"foldChange": "1.0878"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".219970723865741",
						"qValue": "1",
						"foldChange": "1.0562"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".75479811976962",
						"qValue": "1",
						"foldChange": "0.9710"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".027965345647535",
						"qValue": "1",
						"foldChange": "1.1412"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".392328656577963",
						"qValue": "1",
						"foldChange": "1.0493"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".192341174150791",
						"qValue": "1",
						"foldChange": "0.9194"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".098298392520391",
						"qValue": "1",
						"foldChange": "1.1071"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".432448836765257",
						"qValue": "1",
						"foldChange": "1.0121"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".412368357921105",
						"qValue": ".985830071188835",
						"foldChange": "0.9141"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".07859495694739",
						"qValue": ".068841403997043",
						"foldChange": "1.1641"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000022927237677",
						"qValue": ".00027796428839",
						"foldChange": "1.2685"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001010435327444",
						"qValue": ".001067127706614",
						"foldChange": "1.3349"
					}
				]
			},
			{
				"metaboliteId": "0078dcab-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec39-0000-0000-0000-000000000000",
				"chemicalName": "1-lignoceroyl-GPC (24:0)",
				"plotName": null,
				"compoundId": "0000c1d1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10405",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 607.45769,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".450461852702833",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".870230453107956",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".427531951465743",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".262047421718684",
						"qValue": "1",
						"foldChange": "1.5366"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".61245583515609",
						"qValue": "1",
						"foldChange": "1.4565"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".534924793390197",
						"qValue": "1",
						"foldChange": "0.9727"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".188393733913073",
						"qValue": ".797133099236872",
						"foldChange": "0.8376"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".576460999660077",
						"qValue": ".928868848501546",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".443943576476708",
						"qValue": ".957839854315834",
						"foldChange": "1.1797"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".327837710490467",
						"qValue": "1",
						"foldChange": "1.5372"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".629520312423156",
						"qValue": "1",
						"foldChange": "1.5848"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".617235757844994",
						"qValue": "1",
						"foldChange": "0.9365"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".274703086941901",
						"qValue": "1",
						"foldChange": "1.1762"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".304485791366434",
						"qValue": "1",
						"foldChange": "1.4070"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".99444854148594",
						"qValue": "1",
						"foldChange": "1.1963"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".537166727703508",
						"qValue": "1",
						"foldChange": "0.9035"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".332376822774134",
						"qValue": "1",
						"foldChange": "1.3634"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".115049374287957",
						"qValue": ".908885675694672",
						"foldChange": "1.5091"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".72446280316894",
						"qValue": "1",
						"foldChange": "1.1091"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".302902599554905",
						"qValue": "1",
						"foldChange": "1.3228"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".4729430894346",
						"qValue": ".985830071188835",
						"foldChange": "1.1927"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".04287334196288",
						"qValue": ".044920759841276",
						"foldChange": "2.0282"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".036840452447624",
						"qValue": ".029267319252817",
						"foldChange": "1.7674"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000966913064723",
						"qValue": ".001040462564054",
						"foldChange": "2.8115"
					}
				]
			},
			{
				"metaboliteId": "0078dcac-0000-0000-0000-000000000000",
				"chemicalId": "05f5e681-0000-0000-0000-000000000000",
				"chemicalName": "N1-methylinosine",
				"plotName": null,
				"compoundId": "0000bcdf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02721",
				"inChiKey": "WJNGQIYEQLPJMN-UHFFFAOYAL",
				"kegg": null,
				"mass": 282.09642,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3750,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".820664242759227",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".357795955596464",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".962822033855088",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".210280816838307",
						"qValue": "1",
						"foldChange": "0.9294"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".473205072849943",
						"qValue": "1",
						"foldChange": "0.9615"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".587036956007219",
						"qValue": "1",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".447067185030389",
						"qValue": "1",
						"foldChange": "0.9683"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".551246719285482",
						"qValue": ".918025358340401",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".868473125098349",
						"qValue": "1",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".767071925889705",
						"qValue": "1",
						"foldChange": "1.0039"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".493670361180526",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".696893998656824",
						"qValue": "1",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".685080349152057",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".884694043193445",
						"qValue": "1",
						"foldChange": "0.9599"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".588771980087933",
						"qValue": "1",
						"foldChange": "0.9435"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".531395210453306",
						"qValue": "1",
						"foldChange": "1.0880"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".823004238507734",
						"qValue": "1",
						"foldChange": "1.0670"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".705004500339678",
						"qValue": "1",
						"foldChange": "0.9807"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".639737000604898",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".882063844932119",
						"qValue": "1",
						"foldChange": "0.9121"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".545605211716959",
						"qValue": ".985830071188835",
						"foldChange": "0.8694"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".40432786013923",
						"qValue": ".216266801282784",
						"foldChange": "1.2699"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001071856057285",
						"qValue": ".002241620813726",
						"foldChange": "1.6292"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".210847498131613",
						"qValue": ".057402325045719",
						"foldChange": "1.2724"
					}
				]
			},
			{
				"metaboliteId": "0078dcad-0000-0000-0000-000000000000",
				"chemicalId": "05f60574-0000-0000-0000-000000000000",
				"chemicalName": "arachidonoylcholine",
				"plotName": null,
				"compoundId": "0000d00d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 389.32938,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1723,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".803357824218054",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".167833850336761",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".058191392533435",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".927442472157597",
						"qValue": "1",
						"foldChange": "1.6534"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".227396652392233",
						"qValue": "1",
						"foldChange": "2.0640"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".263591015512391",
						"qValue": "1",
						"foldChange": "5.8417"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".073027701263462",
						"qValue": ".593497652236823",
						"foldChange": "0.7765"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".243140673860648",
						"qValue": ".825038702902081",
						"foldChange": "1.2331"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".518049421498415",
						"qValue": ".957839854315834",
						"foldChange": "2.6225"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".243486980023879",
						"qValue": "1",
						"foldChange": "0.9462"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".006789275902172",
						"qValue": ".522751459721039",
						"foldChange": "0.3630"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".105781805867126",
						"qValue": ".900203167929245",
						"foldChange": "0.5813"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".477364562777817",
						"qValue": "1",
						"foldChange": "1.5727"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".288206382209995",
						"qValue": "1",
						"foldChange": "1.6917"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".68680393479997",
						"qValue": "1",
						"foldChange": "1.0757"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".415477028915224",
						"qValue": "1",
						"foldChange": "0.5005"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".995376887126073",
						"qValue": "1",
						"foldChange": "0.7591"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".426738124597542",
						"qValue": "1",
						"foldChange": "1.5169"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".215973186780237",
						"qValue": "1",
						"foldChange": "1.1479"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".02636252396364",
						"qValue": "1",
						"foldChange": "0.2698"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".266371407605038",
						"qValue": ".985830071188835",
						"foldChange": "0.2350"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032389931097218",
						"qValue": ".036670497319171",
						"foldChange": "2.3851"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000484859879514",
						"qValue": ".001438313317979",
						"foldChange": "15.4016"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000094059958584",
						"qValue": ".000204229996389",
						"foldChange": "8.3939"
					}
				]
			},
			{
				"metaboliteId": "0078dcae-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7c5-0000-0000-0000-000000000000",
				"chemicalName": "hexanoylglutamine",
				"plotName": null,
				"compoundId": "0000d67b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 244.142307,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Glutamine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1600,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".412280158268295",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".083155925158861",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".269960082110042",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".473720167926817",
						"qValue": "1",
						"foldChange": "0.9796"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".542707338825835",
						"qValue": "1",
						"foldChange": "0.9618"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".913632532818258",
						"qValue": "1",
						"foldChange": "1.1604"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".283385866074272",
						"qValue": ".911265664999995",
						"foldChange": "1.3260"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".202075841754243",
						"qValue": ".825038702902081",
						"foldChange": "1.1136"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".021422994344603",
						"qValue": ".57754088580993",
						"foldChange": "0.8624"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".060025228886143",
						"qValue": ".821720595261736",
						"foldChange": "1.6684"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".828140199544938",
						"qValue": "1",
						"foldChange": "1.0868"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".09412944277757",
						"qValue": ".834418289621997",
						"foldChange": "0.9099"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".83567839352568",
						"qValue": "1",
						"foldChange": "0.6291"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".684492523416927",
						"qValue": "1",
						"foldChange": "0.8050"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".537836447159503",
						"qValue": "1",
						"foldChange": "1.2796"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".43942843721746",
						"qValue": "1",
						"foldChange": "1.2962"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".066917393683485",
						"qValue": "1",
						"foldChange": "1.8309"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".246664467670045",
						"qValue": "1",
						"foldChange": "1.4125"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".581501822875461",
						"qValue": "1",
						"foldChange": "0.8058"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".394671722806492",
						"qValue": "1",
						"foldChange": "1.2438"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".163777523298517",
						"qValue": ".985830071188835",
						"foldChange": "1.5436"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002512617830817",
						"qValue": ".005367123364265",
						"foldChange": "3.1606"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005812705544102",
						"qValue": ".007047183716515",
						"foldChange": "2.9267"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004363679230163",
						"qValue": ".002947792212149",
						"foldChange": "3.4052"
					}
				]
			},
			{
				"metaboliteId": "0078dcaf-0000-0000-0000-000000000000",
				"chemicalId": "05f60b6f-0000-0000-0000-000000000000",
				"chemicalName": "indolepropionylglycine",
				"plotName": null,
				"compoundId": "0000d685-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 246.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 271,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".550660375456025",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".846423257457678",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".239089970325054",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".691474408123538",
						"qValue": "1",
						"foldChange": "0.9578"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".469842806439005",
						"qValue": "1",
						"foldChange": "1.3421"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".265025262794485",
						"qValue": "1",
						"foldChange": "1.5780"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".176348665472076",
						"qValue": ".789856391140718",
						"foldChange": "1.9032"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".168660175290428",
						"qValue": ".788625325121726",
						"foldChange": "1.6755"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".979923324069091",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".942425087189133",
						"qValue": "1",
						"foldChange": "1.1709"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".203975050118464",
						"qValue": "1",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".18004412537591",
						"qValue": ".989315025499063",
						"foldChange": "0.7843"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".765426082370865",
						"qValue": "1",
						"foldChange": "1.0948"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".88342683126422",
						"qValue": "1",
						"foldChange": "1.3256"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".88950131931128",
						"qValue": "1",
						"foldChange": "1.2108"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".197036982278923",
						"qValue": "1",
						"foldChange": "1.8117"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".680009082042599",
						"qValue": "1",
						"foldChange": "1.5994"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".402831030825587",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".515744095426246",
						"qValue": "1",
						"foldChange": "1.2493"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".308996100999216",
						"qValue": "1",
						"foldChange": "0.5004"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".098844448431251",
						"qValue": ".985830071188835",
						"foldChange": "0.4005"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".861801967721188",
						"qValue": ".361182939797515",
						"foldChange": "0.8262"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".113980067642043",
						"qValue": ".067724685295739",
						"foldChange": "1.9612"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".064645967116103",
						"qValue": ".023098600182457",
						"foldChange": "2.7522"
					}
				]
			},
			{
				"metaboliteId": "0078dcb0-0000-0000-0000-000000000000",
				"chemicalId": "05f6056e-0000-0000-0000-000000000000",
				"chemicalName": "catechol glucuronide",
				"plotName": null,
				"compoundId": "0000cffb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 286.068867,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".125308593735007",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".258609353906527",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".916229964986392",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".78965924219815",
						"qValue": "1",
						"foldChange": "1.5818"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".34545772322904",
						"qValue": "1",
						"foldChange": "2.0608"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".496908677379863",
						"qValue": "1",
						"foldChange": "4.1615"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".605392489876198",
						"qValue": "1",
						"foldChange": "1.0790"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".384928700612971",
						"qValue": ".880576140380748",
						"foldChange": "8.7448"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".168853500091325",
						"qValue": ".957839854315834",
						"foldChange": "8.4200"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".489960141108189",
						"qValue": "1",
						"foldChange": "1.7835"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".377550673563719",
						"qValue": "1",
						"foldChange": "2.1097"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".846256095874218",
						"qValue": "1",
						"foldChange": "1.3625"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".728100423342852",
						"qValue": "1",
						"foldChange": "1.0897"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".194618007234635",
						"qValue": "1",
						"foldChange": "1.3857"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".318686175029201",
						"qValue": "1",
						"foldChange": "1.2717"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".777097203833685",
						"qValue": "1",
						"foldChange": "0.8977"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".097981343416518",
						"qValue": "1",
						"foldChange": "3.8600"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".050365592901063",
						"qValue": ".822195137696456",
						"foldChange": "4.3000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".804474278106433",
						"qValue": "1",
						"foldChange": "2.1383"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".196768381935254",
						"qValue": "1",
						"foldChange": "1.9996"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".277280951483237",
						"qValue": ".985830071188835",
						"foldChange": "0.9352"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".014026472984159",
						"qValue": ".019190219933152",
						"foldChange": "9.5943"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".080733084432864",
						"qValue": ".05259844224847",
						"foldChange": "10.2594"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".050616516689503",
						"qValue": ".019284163698107",
						"foldChange": "4.7981"
					}
				]
			},
			{
				"metaboliteId": "0078dcb1-0000-0000-0000-000000000000",
				"chemicalId": "05f60b95-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyl-alpha-lysine",
				"plotName": null,
				"compoundId": "0000d6e7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LNLLNTMHVMIMOG-YUMQZZPRBS",
				"kegg": null,
				"mass": 275.148121,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 556,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".617241222796843",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".70000012231867",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".456340751970664",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".824732760526645",
						"qValue": "1",
						"foldChange": "1.0090"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".487732876711143",
						"qValue": "1",
						"foldChange": "1.0550"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".361146773797598",
						"qValue": "1",
						"foldChange": "1.0628"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".235808441697941",
						"qValue": ".852077213869098",
						"foldChange": "0.9444"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".070199260868537",
						"qValue": ".687896186587066",
						"foldChange": "0.9168"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".517955659996277",
						"qValue": ".957839854315834",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".890265683627668",
						"qValue": "1",
						"foldChange": "1.0161"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".797443802781744",
						"qValue": "1",
						"foldChange": "0.9893"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".693282174982353",
						"qValue": "1",
						"foldChange": "0.9277"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".152664429576874",
						"qValue": "1",
						"foldChange": "1.0854"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".389921625677259",
						"qValue": "1",
						"foldChange": "1.0190"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".605156749291362",
						"qValue": "1",
						"foldChange": "0.9388"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".395871993406516",
						"qValue": "1",
						"foldChange": "1.0849"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".278783434826027",
						"qValue": "1",
						"foldChange": "1.0712"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".770893182074738",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".882726507169502",
						"qValue": "1",
						"foldChange": "0.9919"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".790383040933652",
						"qValue": "1",
						"foldChange": "0.9450"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".678221081361208",
						"qValue": ".990272584968167",
						"foldChange": "0.9528"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".017466540803208",
						"qValue": ".02258987230314",
						"foldChange": "1.5180"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000041933582652",
						"qValue": ".000377054242849",
						"foldChange": "1.6813"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000289242533795",
						"qValue": ".000426635180897",
						"foldChange": "1.5513"
					}
				]
			},
			{
				"metaboliteId": "0078dcb2-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec6f-0000-0000-0000-000000000000",
				"chemicalName": "S-methylcysteine sulfoxide",
				"plotName": null,
				"compoundId": "0000a972-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29432",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 151.030314,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 394,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".342496395735597",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".000128077555951",
						"qValue": ".036331333371469",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".665563775285653",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".032800800433454",
						"qValue": "1",
						"foldChange": "1.5344"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".008320615741047",
						"qValue": "1",
						"foldChange": "1.6646"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".581777482358692",
						"qValue": "1",
						"foldChange": "1.2144"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001797472726288",
						"qValue": ".1236170295117",
						"foldChange": "1.8622"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".001574061892135",
						"qValue": ".173657990763309",
						"foldChange": "2.0669"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".963815713597095",
						"qValue": "1",
						"foldChange": "1.1124"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".164363653149005",
						"qValue": "1",
						"foldChange": "1.3160"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".238865712838656",
						"qValue": "1",
						"foldChange": "1.3188"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".827216947379344",
						"qValue": "1",
						"foldChange": "0.9533"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".751214897074604",
						"qValue": "1",
						"foldChange": "1.2788"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".812758911869799",
						"qValue": "1",
						"foldChange": "1.0746"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".583004530067353",
						"qValue": "1",
						"foldChange": "0.8403"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".362958377486075",
						"qValue": "1",
						"foldChange": "1.2656"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".511379650237781",
						"qValue": "1",
						"foldChange": "0.8620"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".124130401880793",
						"qValue": ".931407370018239",
						"foldChange": "0.6811"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".556746474083927",
						"qValue": "1",
						"foldChange": "1.1669"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".261196031270314",
						"qValue": "1",
						"foldChange": "0.7519"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".089154449040244",
						"qValue": ".985830071188835",
						"foldChange": "0.6444"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".064232169752385",
						"qValue": ".059404097811106",
						"foldChange": "1.9277"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006458901455541",
						"qValue": ".007504340354294",
						"foldChange": "2.0291"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005071258866276",
						"qValue": ".003248351848832",
						"foldChange": "2.2002"
					}
				]
			},
			{
				"metaboliteId": "0078dcb3-0000-0000-0000-000000000000",
				"chemicalId": "05f60bad-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-arachidonoyl-glycerol (16:0/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000d6ae-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07112",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2615,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".805029435495896",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".75606748790247",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".022696094375899",
						"qValue": ".622389736970234",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".746130052101254",
						"qValue": "1",
						"foldChange": "1.0107"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".459605456829555",
						"qValue": "1",
						"foldChange": "1.3858"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".289474101596395",
						"qValue": "1",
						"foldChange": "1.4346"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".066251225517388",
						"qValue": ".581234978508222",
						"foldChange": "1.7195"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".719239645168162",
						"qValue": ".950986052805043",
						"foldChange": "1.1900"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".029679650938365",
						"qValue": ".587380998803463",
						"foldChange": "0.8369"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".061741024652186",
						"qValue": ".821720595261736",
						"foldChange": "0.6898"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".75894065782199",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".031067674293287",
						"qValue": ".614826369149758",
						"foldChange": "1.5035"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".869241350411151",
						"qValue": "1",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".370157909689011",
						"qValue": "1",
						"foldChange": "1.3460"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".282794984137374",
						"qValue": "1",
						"foldChange": "1.3549"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".304683216122505",
						"qValue": "1",
						"foldChange": "1.3670"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".98858614356125",
						"qValue": "1",
						"foldChange": "0.8900"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".312949735869773",
						"qValue": "1",
						"foldChange": "0.6510"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".435331951653486",
						"qValue": "1",
						"foldChange": "0.8737"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".500128399312774",
						"qValue": "1",
						"foldChange": "1.2023"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".152321205768648",
						"qValue": ".985830071188835",
						"foldChange": "1.3761"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".197342135272643",
						"qValue": ".133343110428826",
						"foldChange": "1.7831"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".324872691578099",
						"qValue": ".149982771348034",
						"foldChange": "1.6700"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".134009308379247",
						"qValue": ".040883244698591",
						"foldChange": "1.6821"
					}
				]
			},
			{
				"metaboliteId": "0078dcb4-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba6-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-oleoyl-glycerol (18:1/18:1)  [1]*",
				"plotName": null,
				"compoundId": "0000d6a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07218",
				"inChiKey": null,
				"kegg": null,
				"mass": 620.537975,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2623,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".948507395047069",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".097932156744592",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".04264160481933",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".930097295294527",
						"qValue": "1",
						"foldChange": "1.0215"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".054901540402159",
						"qValue": "1",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".045259718172663",
						"qValue": "1",
						"foldChange": "0.8525"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".002905186117692",
						"qValue": ".145430199185623",
						"foldChange": "1.3524"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".227566490169282",
						"qValue": ".825038702902081",
						"foldChange": "1.1417"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".061354405924382",
						"qValue": ".750620749550044",
						"foldChange": "0.8874"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".709375460821743",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".30547950178339",
						"qValue": "1",
						"foldChange": "1.1202"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".51183689781878",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".424493000246024",
						"qValue": "1",
						"foldChange": "0.8385"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".499598155941597",
						"qValue": "1",
						"foldChange": "0.8801"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".933355070165341",
						"qValue": "1",
						"foldChange": "1.0496"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".83055208896357",
						"qValue": "1",
						"foldChange": "1.0222"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".566543454968674",
						"qValue": "1",
						"foldChange": "0.9224"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".428502164635744",
						"qValue": "1",
						"foldChange": "0.9023"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".765879105667497",
						"qValue": "1",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".731764454945684",
						"qValue": "1",
						"foldChange": "1.0209"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".94988818859179",
						"qValue": ".99847190363668",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".458742338253805",
						"qValue": ".235116519641028",
						"foldChange": "1.3972"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".018166506683737",
						"qValue": ".016718368772452",
						"foldChange": "1.6570"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".096477098162552",
						"qValue": ".031916037648784",
						"foldChange": "1.7856"
					}
				]
			},
			{
				"metaboliteId": "0078dcb5-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb6-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (14:0/18:1, 16:0/16:1) [1]*",
				"plotName": null,
				"compoundId": "0000d6a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 566.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2588,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".902154887926302",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".177138280777279",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".101982804907022",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".361169371697549",
						"qValue": "1",
						"foldChange": "1.2724"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".921338421862077",
						"qValue": "1",
						"foldChange": "1.1185"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".312273849536618",
						"qValue": "1",
						"foldChange": "0.9137"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".014885321478477",
						"qValue": ".303094620658292",
						"foldChange": "1.6686"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".067301072086048",
						"qValue": ".687896186587066",
						"foldChange": "1.5458"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".516163629391106",
						"qValue": ".957839854315834",
						"foldChange": "1.0974"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".491990093472592",
						"qValue": "1",
						"foldChange": "0.9536"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".197478337265716",
						"qValue": "1",
						"foldChange": "1.3090"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".05124999450212",
						"qValue": ".727024127920748",
						"foldChange": "1.3371"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".686814126134588",
						"qValue": "1",
						"foldChange": "0.9978"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".721487961718334",
						"qValue": "1",
						"foldChange": "0.9560"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".979635185801041",
						"qValue": "1",
						"foldChange": "0.9581"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".900682179880073",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".362019484121207",
						"qValue": "1",
						"foldChange": "0.5154"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".293177433737342",
						"qValue": "1",
						"foldChange": "0.5370"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".788490323288578",
						"qValue": "1",
						"foldChange": "0.8089"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".883450847457094",
						"qValue": "1",
						"foldChange": "0.7846"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".912556924564394",
						"qValue": ".997573179629986",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".833871320779838",
						"qValue": ".354412000063486",
						"foldChange": "1.4259"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".124540621281317",
						"qValue": ".072372934321015",
						"foldChange": "1.6725"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".499027388088886",
						"qValue": ".11097038429555",
						"foldChange": "2.2780"
					}
				]
			},
			{
				"metaboliteId": "0078dcb6-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbb-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linolenoyl-glycerol (16:0/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07105",
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2609,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".868572772679636",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".282500698249606",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".171438581090705",
						"qValue": ".926698788916122",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".620967904819176",
						"qValue": "1",
						"foldChange": "1.4414"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".152851134432453",
						"qValue": "1",
						"foldChange": "1.0386"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".056997585533081",
						"qValue": "1",
						"foldChange": "0.6179"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".092224657318486",
						"qValue": ".648621350231666",
						"foldChange": "3.8374"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".229110302984124",
						"qValue": ".825038702902081",
						"foldChange": "2.1745"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".619418127990724",
						"qValue": ".967408831530889",
						"foldChange": "1.2055"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".301288403606604",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".176791443879052",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".745887161806737",
						"qValue": "1",
						"foldChange": "1.0890"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".235486261535132",
						"qValue": "1",
						"foldChange": "0.5865"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".945136844161523",
						"qValue": "1",
						"foldChange": "1.0776"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".279819497905711",
						"qValue": "1",
						"foldChange": "1.8375"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".661501216611233",
						"qValue": "1",
						"foldChange": "0.6470"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".281096685370603",
						"qValue": "1",
						"foldChange": "0.7746"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".493241728380383",
						"qValue": "1",
						"foldChange": "1.1973"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".583335173302197",
						"qValue": "1",
						"foldChange": "0.9841"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".944399145154821",
						"qValue": "1",
						"foldChange": "0.9027"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".546891348857848",
						"qValue": ".985830071188835",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".248996773801099",
						"qValue": ".156590392054259",
						"foldChange": "0.9529"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".823515500187022",
						"qValue": ".294403033989532",
						"foldChange": "1.1949"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".992494611836368",
						"qValue": ".192252090541373",
						"foldChange": "1.4914"
					}
				]
			},
			{
				"metaboliteId": "0078dcb7-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbc-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (16:1/18:2 [2], 16:0/18:3 [1])*",
				"plotName": null,
				"compoundId": "0000d6b6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2590,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".974907026781642",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".350535643467629",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".033972497885471",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".961316724949882",
						"qValue": "1",
						"foldChange": "1.0406"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".282918630995813",
						"qValue": "1",
						"foldChange": "0.9169"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".3048354628445",
						"qValue": "1",
						"foldChange": "0.9632"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001761370579048",
						"qValue": ".1236170295117",
						"foldChange": "1.7192"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".021040486291013",
						"qValue": ".639480494059015",
						"foldChange": "1.4374"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".358506073676156",
						"qValue": ".957839854315834",
						"foldChange": "0.9828"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".575032803565149",
						"qValue": "1",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".633694846520588",
						"qValue": "1",
						"foldChange": "1.1378"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".301665676417707",
						"qValue": "1",
						"foldChange": "1.1397"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".27542750479477",
						"qValue": "1",
						"foldChange": "0.7397"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".743032988779029",
						"qValue": "1",
						"foldChange": "0.9381"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".466836012100077",
						"qValue": "1",
						"foldChange": "1.2683"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".799092731733001",
						"qValue": "1",
						"foldChange": "0.9757"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".583859663655842",
						"qValue": "1",
						"foldChange": "0.7374"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".420913000083148",
						"qValue": "1",
						"foldChange": "0.7558"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".747921158750905",
						"qValue": "1",
						"foldChange": "0.9655"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".754775679770681",
						"qValue": "1",
						"foldChange": "0.9080"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".993093951036767",
						"qValue": "1",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".401879301992282",
						"qValue": ".215304944063342",
						"foldChange": "1.3140"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".058183515553966",
						"qValue": ".041178340734901",
						"foldChange": "1.6949"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".699758412346581",
						"qValue": ".144793182898838",
						"foldChange": "1.4859"
					}
				]
			},
			{
				"metaboliteId": "0078dcb8-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbd-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linoleoyl-glycerol (18:2/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000d6b8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07248",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2631,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".423959055737576",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".530658132728278",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".141563672593074",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".497876945015632",
						"qValue": "1",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".238799966627648",
						"qValue": "1",
						"foldChange": "0.8887"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".612523284681023",
						"qValue": "1",
						"foldChange": "0.9860"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".013230379799678",
						"qValue": ".303094620658292",
						"foldChange": "1.4916"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".103243967524036",
						"qValue": ".697458782960496",
						"foldChange": "1.4148"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".366324725897407",
						"qValue": ".957839854315834",
						"foldChange": "0.9529"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".79643700845945",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".473680215567787",
						"qValue": "1",
						"foldChange": "0.9474"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".645603681376827",
						"qValue": "1",
						"foldChange": "0.9232"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".03947204069503",
						"qValue": "1",
						"foldChange": "0.5638"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".703301096824438",
						"qValue": "1",
						"foldChange": "1.0257"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".101768918535171",
						"qValue": "1",
						"foldChange": "1.8194"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".560976266945991",
						"qValue": "1",
						"foldChange": "0.7715"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".848900339951687",
						"qValue": "1",
						"foldChange": "1.0903"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".712120628224284",
						"qValue": "1",
						"foldChange": "1.4132"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".450049623877716",
						"qValue": "1",
						"foldChange": "0.6959"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".855839277463453",
						"qValue": "1",
						"foldChange": "0.8809"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".584078526066851",
						"qValue": ".985830071188835",
						"foldChange": "1.2659"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".963730966040932",
						"qValue": ".387784510668635",
						"foldChange": "0.9911"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".546316184328939",
						"qValue": ".217626176825448",
						"foldChange": "1.1040"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".753678515957936",
						"qValue": ".152558761137927",
						"foldChange": "1.3442"
					}
				]
			},
			{
				"metaboliteId": "0078dcb9-0000-0000-0000-000000000000",
				"chemicalId": "0000060b-0000-0000-0000-000000000000",
				"chemicalName": "N-stearoyl-sphingosine (d18:1/18:0)*",
				"plotName": null,
				"compoundId": "0000d6c3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04950",
				"inChiKey": "VODZWWMEJITOND-NXCSZAMKBA",
				"kegg": null,
				"mass": 565.543394,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2714,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".107070872606143",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".287742891393051",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".969167013877983",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".275900736715654",
						"qValue": "1",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".261300538794613",
						"qValue": "1",
						"foldChange": "1.1788"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".972554089120753",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".268525469252422",
						"qValue": ".901656639749634",
						"foldChange": "1.1367"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".62133288993181",
						"qValue": ".928868848501546",
						"foldChange": "1.0635"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".536718667598721",
						"qValue": ".957839854315834",
						"foldChange": "0.9688"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".598138994560939",
						"qValue": "1",
						"foldChange": "1.0680"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".830029596445491",
						"qValue": "1",
						"foldChange": "1.0434"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".754301842465665",
						"qValue": "1",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".369924309053938",
						"qValue": "1",
						"foldChange": "0.8534"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".239120656556701",
						"qValue": "1",
						"foldChange": "1.1812"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".042169591201342",
						"qValue": "1",
						"foldChange": "1.3841"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".358103419881494",
						"qValue": "1",
						"foldChange": "0.8847"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".371067482344265",
						"qValue": "1",
						"foldChange": "1.1667"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".075491467423275",
						"qValue": ".822195137696456",
						"foldChange": "1.3188"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".206038593598467",
						"qValue": "1",
						"foldChange": "0.8275"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".484188608320467",
						"qValue": "1",
						"foldChange": "1.1343"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".056290187474135",
						"qValue": ".985830071188835",
						"foldChange": "1.3707"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".220034998786478",
						"qValue": ".143177969939591",
						"foldChange": "1.4030"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".278920759376587",
						"qValue": ".132723653810422",
						"foldChange": "1.2090"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002284491641981",
						"qValue": ".001893253852561",
						"foldChange": "1.6479"
					}
				]
			},
			{
				"metaboliteId": "0078dcba-0000-0000-0000-000000000000",
				"chemicalId": "000000e7-0000-0000-0000-000000000000",
				"chemicalName": "arginine",
				"plotName": null,
				"compoundId": "00000666-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00517",
				"inChiKey": "ODKSFYDXXFIFQN-UHFFFAOYAT",
				"kegg": "C00062",
				"mass": 174.111676,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 414,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".414128254749044",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".171668933036358",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".766705180699366",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".057218413562486",
						"qValue": "1",
						"foldChange": "0.9189"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".938298924273175",
						"qValue": "1",
						"foldChange": "0.9990"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".067497092266835",
						"qValue": "1",
						"foldChange": "1.1654"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".530358669742957",
						"qValue": "1",
						"foldChange": "0.9759"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".951852463854221",
						"qValue": "1",
						"foldChange": "1.0127"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".570431770580988",
						"qValue": ".957839854315834",
						"foldChange": "1.0408"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".761668245219319",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".885941170389148",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".655288799400995",
						"qValue": "1",
						"foldChange": "0.9832"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".43070711735311",
						"qValue": "1",
						"foldChange": "1.0545"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".642296219536585",
						"qValue": "1",
						"foldChange": "1.0304"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".772184224205896",
						"qValue": "1",
						"foldChange": "0.9772"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".099542244441679",
						"qValue": "1",
						"foldChange": "1.1185"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".146332996576429",
						"qValue": "1",
						"foldChange": "1.1267"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".908777428464016",
						"qValue": "1",
						"foldChange": "1.0073"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".423085533694514",
						"qValue": "1",
						"foldChange": "1.0803"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".544107920786191",
						"qValue": "1",
						"foldChange": "1.0176"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".87592872961486",
						"qValue": ".997573179629986",
						"foldChange": "0.9419"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".334534691732965",
						"qValue": ".192863611674612",
						"foldChange": "1.0875"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".023216932423278",
						"qValue": ".020533356992961",
						"foldChange": "1.2060"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".217963510475524",
						"qValue": ".058709471656433",
						"foldChange": "1.1056"
					}
				]
			},
			{
				"metaboliteId": "0078dcbb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e73d-0000-0000-0000-000000000000",
				"chemicalName": "tiglylcarnitine (C5:1-DC)",
				"plotName": null,
				"compoundId": "00008a64-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02366",
				"inChiKey": "WURBQCVBQNMUQT-RMKNXTFCBN",
				"kegg": null,
				"mass": 243.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 342,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".292554246818921",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".622090075384151",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".458276956010634",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".479540976357486",
						"qValue": "1",
						"foldChange": "0.9532"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".91001877195987",
						"qValue": "1",
						"foldChange": "1.0331"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".412769537576064",
						"qValue": "1",
						"foldChange": "1.1196"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".628633000880046",
						"qValue": "1",
						"foldChange": "1.0585"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".33428207837835",
						"qValue": ".837314778720603",
						"foldChange": "0.9549"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".627375298741293",
						"qValue": ".972488851054354",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".778649353815449",
						"qValue": "1",
						"foldChange": "1.0924"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".128642051670467",
						"qValue": "1",
						"foldChange": "1.2248"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".212525086973056",
						"qValue": "1",
						"foldChange": "1.1674"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".504335384576561",
						"qValue": "1",
						"foldChange": "0.9648"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".067480187668399",
						"qValue": "1",
						"foldChange": "0.7014"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".209667786286391",
						"qValue": "1",
						"foldChange": "0.7270"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".598429002412944",
						"qValue": "1",
						"foldChange": "0.8763"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".189089668086333",
						"qValue": "1",
						"foldChange": "0.7282"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".397494678112713",
						"qValue": "1",
						"foldChange": "0.8310"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".213189199954495",
						"qValue": "1",
						"foldChange": "0.8024"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".295637776030712",
						"qValue": "1",
						"foldChange": "0.7292"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".88921816807563",
						"qValue": ".997573179629986",
						"foldChange": "0.9087"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".050465210847614",
						"qValue": ".050025618150769",
						"foldChange": "0.7340"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".811941533530611",
						"qValue": ".291761607086293",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".533206880600493",
						"qValue": ".116084243862522",
						"foldChange": "1.1379"
					}
				]
			},
			{
				"metaboliteId": "0078dcbc-0000-0000-0000-000000000000",
				"chemicalId": "05f61eae-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/21:0, d16:2/23:0)*",
				"plotName": null,
				"compoundId": "0000e088-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 770.6302,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2813,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".942017345540944",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".690792748717783",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".030487925968611",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".552929238929061",
						"qValue": "1",
						"foldChange": "1.0527"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".080253305316448",
						"qValue": "1",
						"foldChange": "1.1385"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".240125507938091",
						"qValue": "1",
						"foldChange": "1.0880"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".512393704691629",
						"qValue": "1",
						"foldChange": "0.9805"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".494060559078888",
						"qValue": ".902562957068497",
						"foldChange": "1.0630"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".183618437775511",
						"qValue": ".957839854315834",
						"foldChange": "1.0998"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".169652639715263",
						"qValue": "1",
						"foldChange": "1.1031"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".200087378125709",
						"qValue": "1",
						"foldChange": "0.9416"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".009711944174791",
						"qValue": ".566846104084484",
						"foldChange": "0.8166"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".99845553148023",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".849182980265421",
						"qValue": "1",
						"foldChange": "0.9634"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".844085831157141",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".622286531076008",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".600403573158211",
						"qValue": "1",
						"foldChange": "1.1527"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".313825752228119",
						"qValue": "1",
						"foldChange": "1.1694"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".648060065282794",
						"qValue": "1",
						"foldChange": "0.9656"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".315580787360572",
						"qValue": "1",
						"foldChange": "0.8401"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".553680793801566",
						"qValue": ".985830071188835",
						"foldChange": "0.8700"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".629815181462844",
						"qValue": ".299606149964443",
						"foldChange": "1.0512"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".050224639814007",
						"qValue": ".037647747509134",
						"foldChange": "1.4410"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".024298089789691",
						"qValue": ".010942710814158",
						"foldChange": "1.4121"
					}
				]
			},
			{
				"metaboliteId": "0078dcbd-0000-0000-0000-000000000000",
				"chemicalId": "05f61eb1-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d17:2/16:0, d18:2/15:0)*",
				"plotName": null,
				"compoundId": "0000e08b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 686.536275,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2804,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".742074094432446",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".6164549026328",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".229612801165003",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".521925121296194",
						"qValue": "1",
						"foldChange": "1.0639"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".102873127353342",
						"qValue": "1",
						"foldChange": "1.1677"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".313937344877634",
						"qValue": "1",
						"foldChange": "1.1100"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".900267737111518",
						"qValue": "1",
						"foldChange": "1.0140"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".228837281401014",
						"qValue": ".825038702902081",
						"foldChange": "1.1166"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".184980705748092",
						"qValue": ".957839854315834",
						"foldChange": "1.1253"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".717880409236476",
						"qValue": "1",
						"foldChange": "1.0360"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".304572861507949",
						"qValue": "1",
						"foldChange": "0.9631"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".16758468235889",
						"qValue": ".976812086900105",
						"foldChange": "0.8564"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".873888319195278",
						"qValue": "1",
						"foldChange": "1.0696"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".326424228984754",
						"qValue": "1",
						"foldChange": "1.1517"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".392784108262371",
						"qValue": "1",
						"foldChange": "1.0768"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".903202150861845",
						"qValue": "1",
						"foldChange": "1.0211"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".371647750790519",
						"qValue": "1",
						"foldChange": "1.2924"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".303120649778912",
						"qValue": "1",
						"foldChange": "1.2658"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".978500217090505",
						"qValue": "1",
						"foldChange": "1.0153"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".974832948675939",
						"qValue": "1",
						"foldChange": "0.9237"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".953414138286162",
						"qValue": ".999206196652123",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".030765936282194",
						"qValue": ".035616475569423",
						"foldChange": "1.5284"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".012266114883398",
						"qValue": ".012031575311664",
						"foldChange": "1.9303"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004195736408715",
						"qValue": ".002912775241695",
						"foldChange": "2.0647"
					}
				]
			},
			{
				"metaboliteId": "0078dcbe-0000-0000-0000-000000000000",
				"chemicalId": "05f61edd-0000-0000-0000-000000000000",
				"chemicalName": "arachidonoylcarnitine (C20:4)",
				"plotName": null,
				"compoundId": "0000e0ae-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 447.334869,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1675,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".563473777271294",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".201452200759386",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".099447074501964",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".295614095861759",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".328914434091562",
						"qValue": "1",
						"foldChange": "1.0981"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".943628237289534",
						"qValue": "1",
						"foldChange": "1.0081"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".086396915218749",
						"qValue": ".623082837721659",
						"foldChange": "0.9030"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".07570970793096",
						"qValue": ".687896186587066",
						"foldChange": "0.9244"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".948622145457403",
						"qValue": "1",
						"foldChange": "1.0171"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".752623060986062",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".03133879647358",
						"qValue": ".933767468184815",
						"foldChange": "0.8408"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".063347458610596",
						"qValue": ".727024127920748",
						"foldChange": "0.8166"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".818128358667187",
						"qValue": "1",
						"foldChange": "0.9900"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".829382934165381",
						"qValue": "1",
						"foldChange": "0.8918"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".657765807886589",
						"qValue": "1",
						"foldChange": "0.9008"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".264388466296478",
						"qValue": "1",
						"foldChange": "0.7955"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".385212319586604",
						"qValue": "1",
						"foldChange": "0.8218"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".844072528536027",
						"qValue": "1",
						"foldChange": "1.0331"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".266393944882184",
						"qValue": "1",
						"foldChange": "0.8045"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".080136566013445",
						"qValue": "1",
						"foldChange": "0.6669"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".45620081432044",
						"qValue": ".985830071188835",
						"foldChange": "0.8290"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".603869049590215",
						"qValue": ".290181945211542",
						"foldChange": "1.1113"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".175471593127614",
						"qValue": ".094458743779558",
						"foldChange": "1.2294"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005624947148388",
						"qValue": ".003546494343032",
						"foldChange": "1.8223"
					}
				]
			},
			{
				"metaboliteId": "0078dcbf-0000-0000-0000-000000000000",
				"chemicalId": "05f61ee6-0000-0000-0000-000000000000",
				"chemicalName": "nervonoylcarnitine (C24:1)*",
				"plotName": null,
				"compoundId": "0000e0bb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 509.444397,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1670,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".834925093150949",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".873078647746107",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".21015855747263",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".471693670796995",
						"qValue": "1",
						"foldChange": "0.9688"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".518121609446445",
						"qValue": "1",
						"foldChange": "1.0644"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".17505327592049",
						"qValue": "1",
						"foldChange": "1.1384"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".420877491612338",
						"qValue": ".989197938984887",
						"foldChange": "0.9882"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".699392037862257",
						"qValue": ".950986052805043",
						"foldChange": "0.9832"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".673892791694483",
						"qValue": ".976023509428735",
						"foldChange": "1.0633"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".064064661440773",
						"qValue": ".821720595261736",
						"foldChange": "1.1822"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".56028937559823",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".196786858873688",
						"qValue": "1",
						"foldChange": "0.8801"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".767928571554125",
						"qValue": "1",
						"foldChange": "0.9838"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".386130958543146",
						"qValue": "1",
						"foldChange": "0.8960"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".54539165477828",
						"qValue": "1",
						"foldChange": "0.9107"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".747265625552842",
						"qValue": "1",
						"foldChange": "0.9967"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".48379853672536",
						"qValue": "1",
						"foldChange": "1.1427"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".305387185118465",
						"qValue": "1",
						"foldChange": "1.1465"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".362593114287873",
						"qValue": "1",
						"foldChange": "0.9065"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".377197685113037",
						"qValue": "1",
						"foldChange": "0.8947"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".98200192893151",
						"qValue": "1",
						"foldChange": "0.9870"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".037111261760328",
						"qValue": ".040418386752357",
						"foldChange": "1.4071"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000257218186909",
						"qValue": ".000956325013802",
						"foldChange": "1.5501"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000075690617",
						"qValue": ".000004056409553",
						"foldChange": "2.1469"
					}
				]
			},
			{
				"metaboliteId": "0078dcc0-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec62-0000-0000-0000-000000000000",
				"chemicalName": "3-(4-hydroxyphenyl)propionate",
				"plotName": null,
				"compoundId": "00009aa3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02199",
				"inChiKey": "NMHMNPHRMNGLLB-UHFFFAOYAT",
				"kegg": "C01744",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".068958667221854",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".692658381556581",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".271202187878537",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".47941682086555",
						"qValue": "1",
						"foldChange": "1.5825"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".267822208362341",
						"qValue": "1",
						"foldChange": "2.2517"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".684872340285772",
						"qValue": "1",
						"foldChange": "1.4816"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".131187540491893",
						"qValue": ".72967710430458",
						"foldChange": "2.5875"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".105980244288092",
						"qValue": ".697458782960496",
						"foldChange": "1.9119"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".91139088234916",
						"qValue": "1",
						"foldChange": "1.2414"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".421409974409575",
						"qValue": "1",
						"foldChange": "0.8249"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".192335092011412",
						"qValue": "1",
						"foldChange": "0.9411"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".611435595567816",
						"qValue": "1",
						"foldChange": "1.0243"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".052297033898864",
						"qValue": "1",
						"foldChange": "4.4134"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".101290994907931",
						"qValue": "1",
						"foldChange": "4.3688"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".825375445008602",
						"qValue": "1",
						"foldChange": "0.9899"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".021796157865528",
						"qValue": "1",
						"foldChange": "11.6411"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".374964026522898",
						"qValue": "1",
						"foldChange": "2.4505"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".168547101614135",
						"qValue": "1",
						"foldChange": "0.2105"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".031409286110546",
						"qValue": "1",
						"foldChange": "4.4804"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".673780251082428",
						"qValue": "1",
						"foldChange": "1.7169"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".091155294915449",
						"qValue": ".985830071188835",
						"foldChange": "0.3832"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".635975685797777",
						"qValue": ".301632829345998",
						"foldChange": "1.3023"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007702122214369",
						"qValue": ".008451748293679",
						"foldChange": "5.7572"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".826585180382731",
						"qValue": ".165086948485595",
						"foldChange": "0.8630"
					}
				]
			},
			{
				"metaboliteId": "0078dcc1-0000-0000-0000-000000000000",
				"chemicalId": "05f61e8b-0000-0000-0000-000000000000",
				"chemicalName": "ceramide (d18:1/20:0, d16:1/22:0, d20:1/18:0)*",
				"plotName": null,
				"compoundId": "0000e060-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 593.574707,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2739,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".076277631308829",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".981871909037161",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".646388133624056",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".211592442419466",
						"qValue": "1",
						"foldChange": "1.1687"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".383125907911038",
						"qValue": "1",
						"foldChange": "1.1066"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".701300662553588",
						"qValue": "1",
						"foldChange": "1.0190"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".67031974153369",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".600878994685189",
						"qValue": ".928868848501546",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".922114795211751",
						"qValue": "1",
						"foldChange": "1.0116"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".487354106980993",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".57959497466445",
						"qValue": "1",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".887552873579893",
						"qValue": "1",
						"foldChange": "0.9993"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".625451901400851",
						"qValue": "1",
						"foldChange": "0.9274"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".094927477406923",
						"qValue": "1",
						"foldChange": "1.3141"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".031846902293715",
						"qValue": "1",
						"foldChange": "1.4169"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".170029215334041",
						"qValue": "1",
						"foldChange": "0.8308"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".498073008313802",
						"qValue": "1",
						"foldChange": "1.1462"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".04701775225492",
						"qValue": ".822195137696456",
						"foldChange": "1.3796"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".222500914222753",
						"qValue": "1",
						"foldChange": "0.8163"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".343502698794954",
						"qValue": "1",
						"foldChange": "1.1808"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".035492544869578",
						"qValue": ".985830071188835",
						"foldChange": "1.4466"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".12224400505698",
						"qValue": ".095457169403165",
						"foldChange": "1.5898"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".474630716935851",
						"qValue": ".195204762397292",
						"foldChange": "1.1308"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001117622742994",
						"qValue": ".001137258390251",
						"foldChange": "1.7753"
					}
				]
			},
			{
				"metaboliteId": "0078dcc2-0000-0000-0000-000000000000",
				"chemicalId": "05f61e80-0000-0000-0000-000000000000",
				"chemicalName": "ceramide (d18:2/24:1, d18:1/24:2)*",
				"plotName": null,
				"compoundId": "0000e063-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 645.606018,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2741,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".571375438052762",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".054723525931081",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".218512434516608",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".005628005730524",
						"qValue": "1",
						"foldChange": "1.3137"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".071569235011841",
						"qValue": "1",
						"foldChange": "1.1793"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".296031010219041",
						"qValue": "1",
						"foldChange": "0.9551"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".344640793279542",
						"qValue": ".951252030575746",
						"foldChange": "1.0964"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".779874181551705",
						"qValue": ".976437270808295",
						"foldChange": "1.0350"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".503930738199335",
						"qValue": ".957839854315834",
						"foldChange": "0.9626"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".808921380308864",
						"qValue": "1",
						"foldChange": "1.0304"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".206752349365009",
						"qValue": "1",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".13432126051315",
						"qValue": ".960252848686774",
						"foldChange": "0.8695"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".880919018604547",
						"qValue": "1",
						"foldChange": "0.9280"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".982062000976398",
						"qValue": "1",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".902831158923195",
						"qValue": "1",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".26430872141819",
						"qValue": "1",
						"foldChange": "0.8277"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".217897617771358",
						"qValue": "1",
						"foldChange": "0.8442"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".852990348825807",
						"qValue": "1",
						"foldChange": "1.0200"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".359476465439577",
						"qValue": "1",
						"foldChange": "0.8354"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".138759736135015",
						"qValue": "1",
						"foldChange": "0.7795"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".51768096077439",
						"qValue": ".985830071188835",
						"foldChange": "0.9331"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".890498985589072",
						"qValue": ".369006051355787",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".01465323296071",
						"qValue": ".013993157066355",
						"foldChange": "1.3574"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00230633093225",
						"qValue": ".001901551176412",
						"foldChange": "1.8243"
					}
				]
			},
			{
				"metaboliteId": "0078dcc3-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9e2-0000-0000-0000-000000000000",
				"chemicalName": "3beta-hydroxy-5-cholestenoate",
				"plotName": null,
				"compoundId": "0000d615-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": "C17333",
				"mass": 416.329045,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2894,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".653814882069178",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".077144116659842",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".565070146834451",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".4069937407821",
						"qValue": "1",
						"foldChange": "0.9458"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".468951377564937",
						"qValue": "1",
						"foldChange": "0.9351"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".915576479063486",
						"qValue": "1",
						"foldChange": "1.0317"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".512275906980079",
						"qValue": "1",
						"foldChange": "1.1068"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".098048827936933",
						"qValue": ".697458782960496",
						"foldChange": "0.9302"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".02307448897478",
						"qValue": ".57754088580993",
						"foldChange": "0.8237"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".737956603354473",
						"qValue": "1",
						"foldChange": "0.9832"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".186640666413356",
						"qValue": "1",
						"foldChange": "0.9264"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".320781707132656",
						"qValue": "1",
						"foldChange": "0.8784"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".454628525807177",
						"qValue": "1",
						"foldChange": "1.1826"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".604554941598562",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".844798977273011",
						"qValue": "1",
						"foldChange": "0.8540"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".150372562914021",
						"qValue": "1",
						"foldChange": "1.3254"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".461656212517556",
						"qValue": "1",
						"foldChange": "1.0840"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".511685411750934",
						"qValue": "1",
						"foldChange": "0.8179"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".743058304489214",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".840300154361662",
						"qValue": "1",
						"foldChange": "0.9277"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".911386115258464",
						"qValue": ".997573179629986",
						"foldChange": "0.8784"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".027713470150996",
						"qValue": ".032422859359742",
						"foldChange": "1.5707"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".100200777290641",
						"qValue": ".061273311742075",
						"foldChange": "1.4678"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".168971473794914",
						"qValue": ".048860691178981",
						"foldChange": "1.4676"
					}
				]
			},
			{
				"metaboliteId": "0078dcc4-0000-0000-0000-000000000000",
				"chemicalId": "000004f4-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylleucine",
				"plotName": null,
				"compoundId": "000047c1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11171",
				"inChiKey": "MYFMARDICOWMQP-YUMQZZPRBM",
				"kegg": null,
				"mass": 260.137223,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 555,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".362861949350268",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".914430513094189",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".411275340594228",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".426151519509507",
						"qValue": "1",
						"foldChange": "1.0756"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".617378916671538",
						"qValue": "1",
						"foldChange": "1.0419"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".765637538874205",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".159420450781084",
						"qValue": ".742487134132407",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".222212178324571",
						"qValue": ".825038702902081",
						"foldChange": "0.9407"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".848162830169903",
						"qValue": "1",
						"foldChange": "1.0784"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".298975797520902",
						"qValue": "1",
						"foldChange": "1.1006"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".61496223738694",
						"qValue": "1",
						"foldChange": "1.0672"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".589165550584346",
						"qValue": "1",
						"foldChange": "0.9375"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".543066291298025",
						"qValue": "1",
						"foldChange": "1.0086"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".309534154023676",
						"qValue": "1",
						"foldChange": "1.1007"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".647433197676194",
						"qValue": "1",
						"foldChange": "1.0914"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".573888420509603",
						"qValue": "1",
						"foldChange": "0.9285"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".241169354939731",
						"qValue": "1",
						"foldChange": "1.1163"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".085159547638866",
						"qValue": ".834952786556829",
						"foldChange": "1.2023"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".763289439840024",
						"qValue": "1",
						"foldChange": "0.9221"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".301426099549916",
						"qValue": "1",
						"foldChange": "1.0644"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".179839461023268",
						"qValue": ".985830071188835",
						"foldChange": "1.1543"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".082390228943867",
						"qValue": ".071410025019733",
						"foldChange": "1.5127"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006730269754037",
						"qValue": ".007734475096123",
						"foldChange": "1.3622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".096458237356598",
						"qValue": ".031916037648784",
						"foldChange": "1.3490"
					}
				]
			},
			{
				"metaboliteId": "0078dcc5-0000-0000-0000-000000000000",
				"chemicalId": "05f604dd-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPI (18:0/18:1)*",
				"plotName": null,
				"compoundId": "0000cdf6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 864.572779,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2264,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".911232871902545",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".417186938813328",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".279358403312289",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".135455898867624",
						"qValue": "1",
						"foldChange": "1.2391"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".318365301487671",
						"qValue": "1",
						"foldChange": "1.1266"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".612273013019414",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".085394941097779",
						"qValue": ".623082837721659",
						"foldChange": "1.3355"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".095121476686531",
						"qValue": ".697458782960496",
						"foldChange": "1.3182"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".957258650064375",
						"qValue": "1",
						"foldChange": "1.0280"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".279155033969522",
						"qValue": "1",
						"foldChange": "0.8942"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".556808106521113",
						"qValue": "1",
						"foldChange": "0.9605"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".617356643033332",
						"qValue": "1",
						"foldChange": "0.9900"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".866208258873507",
						"qValue": "1",
						"foldChange": "1.1579"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".297401777697218",
						"qValue": "1",
						"foldChange": "1.3223"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".364213647465406",
						"qValue": "1",
						"foldChange": "1.1419"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".808709856982466",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".831909136949606",
						"qValue": "1",
						"foldChange": "1.0630"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".651693057382321",
						"qValue": "1",
						"foldChange": "1.0272"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".638182316380719",
						"qValue": "1",
						"foldChange": "1.0726"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".779947713740928",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".86512614861547",
						"qValue": ".997573179629986",
						"foldChange": "0.9570"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".549343872010032",
						"qValue": ".271466547769027",
						"foldChange": "1.3266"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".017347845649166",
						"qValue": ".016071046584725",
						"foldChange": "1.4806"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".027046706329532",
						"qValue": ".011912567781405",
						"foldChange": "1.6196"
					}
				]
			},
			{
				"metaboliteId": "0078dcc6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e22b-0000-0000-0000-000000000000",
				"chemicalName": "xanthosine",
				"plotName": null,
				"compoundId": "00003b20-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00299",
				"inChiKey": "UBORTCNDUKBEOP-UUOKFMHZBM",
				"kegg": "C01762",
				"mass": 284.075684,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3747,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".668745491008396",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".369709786395985",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".468950437427741",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".330463142526692",
						"qValue": "1",
						"foldChange": "0.9154"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".351101058053767",
						"qValue": "1",
						"foldChange": "0.9268"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".967047152284622",
						"qValue": "1",
						"foldChange": "1.1265"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".434879992328672",
						"qValue": ".994846434063709",
						"foldChange": "1.2465"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".31508999045445",
						"qValue": ".825038702902081",
						"foldChange": "0.9573"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".077717171856988",
						"qValue": ".794743555017874",
						"foldChange": "0.8545"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".27415025370126",
						"qValue": "1",
						"foldChange": "0.9210"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".595849033833926",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".569809906724581",
						"qValue": "1",
						"foldChange": "1.1468"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".396983566419897",
						"qValue": "1",
						"foldChange": "0.5148"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".418682788437086",
						"qValue": "1",
						"foldChange": "0.4875"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".993885812497636",
						"qValue": "1",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".862671212301946",
						"qValue": "1",
						"foldChange": "0.9040"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".360936732370806",
						"qValue": "1",
						"foldChange": "0.7233"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".271421277375708",
						"qValue": "1",
						"foldChange": "0.8001"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".390028752612372",
						"qValue": "1",
						"foldChange": "0.5693"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".548013559628532",
						"qValue": "1",
						"foldChange": "0.5900"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".826062064446215",
						"qValue": ".997573179629986",
						"foldChange": "1.0365"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002696840014688",
						"qValue": ".005687250656773",
						"foldChange": "2.4113"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".403706066205962",
						"qValue": ".171603658320264",
						"foldChange": "0.9533"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".031586500661702",
						"qValue": ".013439424573254",
						"foldChange": "3.1943"
					}
				]
			},
			{
				"metaboliteId": "0078dcc7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e755-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoinositol*",
				"plotName": null,
				"compoundId": "0000b833-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 334.066498,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1984,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".544423525529699",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".873445728933945",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".614763634748553",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".322676024204316",
						"qValue": "1",
						"foldChange": "2.4155"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".62591298732064",
						"qValue": "1",
						"foldChange": "1.7729"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".142789235010156",
						"qValue": "1",
						"foldChange": "1.2176"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".758124201184312",
						"qValue": "1",
						"foldChange": "1.1008"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".660836593187113",
						"qValue": ".934949271461149",
						"foldChange": "1.5920"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".456197785621573",
						"qValue": ".957839854315834",
						"foldChange": "1.7772"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".927794140267109",
						"qValue": "1",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".847636237390046",
						"qValue": "1",
						"foldChange": "1.8953"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".919119710779749",
						"qValue": "1",
						"foldChange": "1.7849"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".84596201821423",
						"qValue": "1",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".587520831036187",
						"qValue": "1",
						"foldChange": "0.7441"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".712951212532054",
						"qValue": "1",
						"foldChange": "0.6756"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".179044699290928",
						"qValue": "1",
						"foldChange": "0.5821"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".144397041180343",
						"qValue": "1",
						"foldChange": "0.5175"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".843920797766673",
						"qValue": "1",
						"foldChange": "0.8889"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".535361746703805",
						"qValue": "1",
						"foldChange": "1.2649"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".763570171690037",
						"qValue": "1",
						"foldChange": "0.8550"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".364591483514575",
						"qValue": ".985830071188835",
						"foldChange": "0.6759"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".058700485210307",
						"qValue": ".055688190013004",
						"foldChange": "0.6442"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".37714131286473",
						"qValue": ".163045756476216",
						"foldChange": "0.9985"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".354931031452619",
						"qValue": ".084849301447442",
						"foldChange": "0.9325"
					}
				]
			},
			{
				"metaboliteId": "0078dcc8-0000-0000-0000-000000000000",
				"chemicalId": "00000454-0000-0000-0000-000000000000",
				"chemicalName": "N-carbamoylaspartate",
				"plotName": null,
				"compoundId": "0000063a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00828",
				"inChiKey": "HLKXYZVTANABHZ-MRIHDYNCBG",
				"kegg": "C00438",
				"mass": 176.043323,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3823,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".59240963835527",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".68382655405814",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".378064179755311",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".112180625745459",
						"qValue": "1",
						"foldChange": "1.1247"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".787627721836768",
						"qValue": "1",
						"foldChange": "1.0208"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".184221884289759",
						"qValue": "1",
						"foldChange": "2.6143"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".355823708135789",
						"qValue": ".951252030575746",
						"foldChange": "1.5700"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".903415840284944",
						"qValue": "1",
						"foldChange": "1.3674"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".297007052554203",
						"qValue": ".957839854315834",
						"foldChange": "1.2044"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".464066632530694",
						"qValue": "1",
						"foldChange": "0.9040"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".810329485380057",
						"qValue": "1",
						"foldChange": "1.1345"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".621639549169668",
						"qValue": "1",
						"foldChange": "1.7206"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".147030877236311",
						"qValue": "1",
						"foldChange": "0.6849"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".422988211526058",
						"qValue": "1",
						"foldChange": "0.6568"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".552801279937467",
						"qValue": "1",
						"foldChange": "0.9590"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".549047833872143",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".879038034669067",
						"qValue": "1",
						"foldChange": "0.8521"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".462040736458575",
						"qValue": "1",
						"foldChange": "0.8654"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".185063726186239",
						"qValue": "1",
						"foldChange": "0.4915"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".42970414473607",
						"qValue": "1",
						"foldChange": "0.8125"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".629816774077591",
						"qValue": ".985830071188835",
						"foldChange": "1.6532"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005045339165286",
						"qValue": ".009128220348736",
						"foldChange": "4.4835"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".012803950529921",
						"qValue": ".01244019525697",
						"foldChange": "2.7120"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006760423162039",
						"qValue": ".00406747225191",
						"foldChange": "5.5179"
					}
				]
			},
			{
				"metaboliteId": "0078dcc9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e688-0000-0000-0000-000000000000",
				"chemicalName": "orotidine",
				"plotName": null,
				"compoundId": "00008964-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00788",
				"inChiKey": "FKCRAVPPBFWEJD-XVFCMESIBQ",
				"kegg": null,
				"mass": 288.059365,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3827,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".322106971618964",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".900158997781572",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".808320512484142",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".705600406578257",
						"qValue": "1",
						"foldChange": "1.3624"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".452133180111677",
						"qValue": "1",
						"foldChange": "0.9223"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".260740695241812",
						"qValue": "1",
						"foldChange": "0.9754"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".649365290595332",
						"qValue": "1",
						"foldChange": "1.0960"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".729398202262969",
						"qValue": ".95642198786716",
						"foldChange": "1.0735"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".913212232543652",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".90046063518158",
						"qValue": "1",
						"foldChange": "0.9862"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".703145340181838",
						"qValue": "1",
						"foldChange": "1.0650"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".613012349051123",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".302502569073418",
						"qValue": "1",
						"foldChange": "0.2460"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".13534070826072",
						"qValue": "1",
						"foldChange": "0.1950"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".581241152349604",
						"qValue": "1",
						"foldChange": "0.7926"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".310601423549945",
						"qValue": "1",
						"foldChange": "0.3142"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".100714803673571",
						"qValue": "1",
						"foldChange": "0.2532"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".466082296005476",
						"qValue": "1",
						"foldChange": "0.8058"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".494434295909665",
						"qValue": "1",
						"foldChange": "0.2576"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".249714142540246",
						"qValue": "1",
						"foldChange": "0.1985"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".597788942738292",
						"qValue": ".985830071188835",
						"foldChange": "0.7705"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".051534479765241",
						"qValue": ".050630805162502",
						"foldChange": "1.5803"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".010282503518269",
						"qValue": ".010464377784092",
						"foldChange": "1.8561"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".118735251669157",
						"qValue": ".037212026166842",
						"foldChange": "6.7336"
					}
				]
			},
			{
				"metaboliteId": "0078dcca-0000-0000-0000-000000000000",
				"chemicalId": "0000043f-0000-0000-0000-000000000000",
				"chemicalName": "erucate (22:1n9)",
				"plotName": null,
				"compoundId": "00000610-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02068",
				"inChiKey": "DPUOLQHDNGRHBS-UHFFFAOYAW",
				"kegg": "C08316",
				"mass": 338.31848,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1374,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".63567378396843",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".20502141319557",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".238297605639145",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".986887174233406",
						"qValue": "1",
						"foldChange": "1.0130"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".982565201526364",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".995677226617133",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".07322826496459",
						"qValue": ".593497652236823",
						"foldChange": "1.3358"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".229978251094803",
						"qValue": ".825038702902081",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".00374591096204",
						"qValue": ".167777380457677",
						"foldChange": "0.7457"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".62990313701706",
						"qValue": "1",
						"foldChange": "1.1388"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".726282353735916",
						"qValue": "1",
						"foldChange": "1.2355"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".894874449694205",
						"qValue": "1",
						"foldChange": "1.0818"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".903895294912912",
						"qValue": "1",
						"foldChange": "1.1004"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".626669833889023",
						"qValue": "1",
						"foldChange": "1.0796"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".538794637135565",
						"qValue": "1",
						"foldChange": "0.9811"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".262985010008782",
						"qValue": "1",
						"foldChange": "1.2541"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".400390696153196",
						"qValue": "1",
						"foldChange": "1.1617"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".820571858628685",
						"qValue": "1",
						"foldChange": "0.9263"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".356848743193649",
						"qValue": "1",
						"foldChange": "0.9257"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".452807330396114",
						"qValue": "1",
						"foldChange": "1.1231"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".100202451022582",
						"qValue": ".985830071188835",
						"foldChange": "1.2133"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".015012068670505",
						"qValue": ".020122893448057",
						"foldChange": "1.9313"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".104363561762857",
						"qValue": ".062990060151526",
						"foldChange": "1.5804"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008003680758033",
						"qValue": ".004563117531926",
						"foldChange": "1.6711"
					}
				]
			},
			{
				"metaboliteId": "0078dccb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e531-0000-0000-0000-000000000000",
				"chemicalName": "androsterone sulfate",
				"plotName": null,
				"compoundId": "00007b67-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02759",
				"inChiKey": "ZMITXKRGXGRMKS-CZTOYULQBN",
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3022,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".62523315945931",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".900327857487666",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".113925807216815",
						"qValue": ".828639845653926",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".417700327091611",
						"qValue": "1",
						"foldChange": "0.8360"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".421943515705794",
						"qValue": "1",
						"foldChange": "0.8342"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".994064382118534",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".022544122312841",
						"qValue": ".359575885726771",
						"foldChange": "8.3359"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".037074469347412",
						"qValue": ".678234398444917",
						"foldChange": "5.9271"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".832617860855075",
						"qValue": "1",
						"foldChange": "0.9724"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".560837533508642",
						"qValue": "1",
						"foldChange": "0.8848"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".452190400931992",
						"qValue": "1",
						"foldChange": "0.8437"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".863951333659592",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".061770807585752",
						"qValue": "1",
						"foldChange": "0.6182"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".509943167546094",
						"qValue": "1",
						"foldChange": "0.7837"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".243388555060033",
						"qValue": "1",
						"foldChange": "1.2677"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".806187892701858",
						"qValue": "1",
						"foldChange": "0.9980"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".576670393343846",
						"qValue": "1",
						"foldChange": "0.7187"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".737538714288407",
						"qValue": "1",
						"foldChange": "0.7201"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".719272083395434",
						"qValue": "1",
						"foldChange": "0.9057"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".511942057266659",
						"qValue": "1",
						"foldChange": "0.6853"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".745074652393026",
						"qValue": ".997573179629986",
						"foldChange": "0.7566"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".711843749960721",
						"qValue": ".323298720935727",
						"foldChange": "0.8505"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".258310169493364",
						"qValue": ".124402196014933",
						"foldChange": "0.7268"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".648604596545566",
						"qValue": ".135565716903519",
						"foldChange": "1.0756"
					}
				]
			},
			{
				"metaboliteId": "0078dccc-0000-0000-0000-000000000000",
				"chemicalId": "000001c4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleate (16:1n7)",
				"plotName": null,
				"compoundId": "000082a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03229",
				"inChiKey": "SECPZKHBENQXJG-FPLPWBNLBE",
				"kegg": "C08362",
				"mass": 254.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1357,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".995547184608268",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".223315896130757",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".700557260426474",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".708389652006011",
						"qValue": "1",
						"foldChange": "1.2468"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".748576761561317",
						"qValue": "1",
						"foldChange": "1.1292"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".957250428554985",
						"qValue": "1",
						"foldChange": "1.1700"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".422426958998728",
						"qValue": ".989197938984887",
						"foldChange": "1.6275"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".207714875858277",
						"qValue": ".825038702902081",
						"foldChange": "1.2140"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".04229551113649",
						"qValue": ".679210111549633",
						"foldChange": "0.8569"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".523768873663951",
						"qValue": "1",
						"foldChange": "1.3382"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".760899302389743",
						"qValue": "1",
						"foldChange": "1.0153"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".347734731201485",
						"qValue": "1",
						"foldChange": "0.8658"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".728223354913795",
						"qValue": "1",
						"foldChange": "1.1372"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".910056364061298",
						"qValue": "1",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".825074321042401",
						"qValue": "1",
						"foldChange": "0.8246"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".532443350284119",
						"qValue": "1",
						"foldChange": "1.3903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".75083398127862",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".779176262501454",
						"qValue": "1",
						"foldChange": "0.6935"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".4597872655576",
						"qValue": "1",
						"foldChange": "0.9487"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".748061213163664",
						"qValue": "1",
						"foldChange": "0.7066"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".697575835679974",
						"qValue": ".993114870458668",
						"foldChange": "0.7449"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".32565577431605",
						"qValue": ".189493080207603",
						"foldChange": "1.2252"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".420797940522211",
						"qValue": ".177447521005981",
						"foldChange": "1.7517"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".141514762073705",
						"qValue": ".042662568590251",
						"foldChange": "1.8162"
					}
				]
			},
			{
				"metaboliteId": "0078dccd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e614-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxyisovalerate",
				"plotName": null,
				"compoundId": "00008491-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00407",
				"inChiKey": "NGEWQZIDQIYUNV-UHFFFAOYAS",
				"kegg": null,
				"mass": 118.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".781131791785204",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".753569381141984",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".794297219699978",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".292141309735686",
						"qValue": "1",
						"foldChange": "0.9377"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".875202934588562",
						"qValue": "1",
						"foldChange": "1.0272"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".227287756174434",
						"qValue": "1",
						"foldChange": "1.1074"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".954181607976442",
						"qValue": "1",
						"foldChange": "1.0383"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".637883961990048",
						"qValue": ".928868848501546",
						"foldChange": "1.0167"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".597574749257295",
						"qValue": ".962532817488692",
						"foldChange": "0.9990"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".779115619620597",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".78536424192214",
						"qValue": "1",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".993500301837017",
						"qValue": "1",
						"foldChange": "0.9478"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".961593228527295",
						"qValue": "1",
						"foldChange": "0.9107"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".549997941074387",
						"qValue": "1",
						"foldChange": "0.8089"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".571388033972677",
						"qValue": "1",
						"foldChange": "0.8882"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".660347243388314",
						"qValue": "1",
						"foldChange": "1.0709"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".776758634932766",
						"qValue": "1",
						"foldChange": "0.8915"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".475235856853026",
						"qValue": "1",
						"foldChange": "0.8325"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".754535294110584",
						"qValue": "1",
						"foldChange": "0.9322"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".43512569675712",
						"qValue": "1",
						"foldChange": "0.7932"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".617514911123057",
						"qValue": ".985830071188835",
						"foldChange": "0.8509"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".826176686628422",
						"qValue": ".352045466002712",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".985390573530298",
						"qValue": ".332055863302994",
						"foldChange": "1.0156"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".935626075287259",
						"qValue": ".183670505273118",
						"foldChange": "1.0267"
					}
				]
			},
			{
				"metaboliteId": "0078dcce-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fa-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylthreonine",
				"plotName": null,
				"compoundId": "00008493-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB62557",
				"inChiKey": "PEDXUVCGOLSNLQ-UHFFFAOYAC",
				"kegg": null,
				"mass": 161.068808,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 17,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".571077901308792",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".343094102543877",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".638858136078205",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".313319508201383",
						"qValue": "1",
						"foldChange": "1.0040"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".853300305069385",
						"qValue": "1",
						"foldChange": "0.9797"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".408928444885891",
						"qValue": "1",
						"foldChange": "1.4346"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".990249799088438",
						"qValue": "1",
						"foldChange": "1.1365"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".239566916762212",
						"qValue": ".825038702902081",
						"foldChange": "1.0064"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".23482379816374",
						"qValue": ".957839854315834",
						"foldChange": "0.9332"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".870757294810169",
						"qValue": "1",
						"foldChange": "0.9769"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".242856480109107",
						"qValue": "1",
						"foldChange": "0.8671"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".313373479779327",
						"qValue": "1",
						"foldChange": "0.8106"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".436407660871509",
						"qValue": "1",
						"foldChange": "1.1011"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".591785361661073",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".836927761475689",
						"qValue": "1",
						"foldChange": "0.9014"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".109212202864493",
						"qValue": "1",
						"foldChange": "1.3478"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".237602738199147",
						"qValue": "1",
						"foldChange": "1.2486"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".724995092815728",
						"qValue": "1",
						"foldChange": "0.9264"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".990938911364418",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".774895910330254",
						"qValue": "1",
						"foldChange": "0.8395"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".761177289210661",
						"qValue": ".997573179629986",
						"foldChange": "0.8329"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00057932608652",
						"qValue": ".00174372196467",
						"foldChange": "3.1915"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000105928094298",
						"qValue": ".000617602035247",
						"foldChange": "3.4998"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000176337564163",
						"qValue": ".000309461356268",
						"foldChange": "3.4233"
					}
				]
			},
			{
				"metaboliteId": "0078dccf-0000-0000-0000-000000000000",
				"chemicalId": "0000046e-0000-0000-0000-000000000000",
				"chemicalName": "urate",
				"plotName": null,
				"compoundId": "00000644-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00289",
				"inChiKey": "LEHOTFFKMJEONL-UHFFFAOYAN",
				"kegg": "C00366",
				"mass": 168.02834,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3753,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".105375876521496",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".298300226481282",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".605082348473502",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".073175811843626",
						"qValue": "1",
						"foldChange": "0.9259"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".084218705669778",
						"qValue": "1",
						"foldChange": "0.9234"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".945579265804785",
						"qValue": "1",
						"foldChange": "1.0120"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".831415994140951",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".500191190927453",
						"qValue": ".902562957068497",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".376111580986587",
						"qValue": ".957839854315834",
						"foldChange": "0.9736"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".758373635321569",
						"qValue": "1",
						"foldChange": "0.9895"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".828360600089206",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".927565649050419",
						"qValue": "1",
						"foldChange": "0.9618"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".341803454872872",
						"qValue": "1",
						"foldChange": "1.0962"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".311382391918621",
						"qValue": "1",
						"foldChange": "0.8927"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".055577823745867",
						"qValue": "1",
						"foldChange": "0.8144"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".072927645019804",
						"qValue": "1",
						"foldChange": "1.1935"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".687206684330446",
						"qValue": "1",
						"foldChange": "0.9469"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".034293089813388",
						"qValue": ".822195137696456",
						"foldChange": "0.7934"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".155638347259551",
						"qValue": "1",
						"foldChange": "1.1426"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".695750664226022",
						"qValue": "1",
						"foldChange": "0.9005"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".079387620615515",
						"qValue": ".985830071188835",
						"foldChange": "0.7881"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".163136211584384",
						"qValue": ".116658454936222",
						"foldChange": "1.2388"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000813633078298",
						"qValue": ".002007776002987",
						"foldChange": "1.4855"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".319353634327791",
						"qValue": ".079235146039338",
						"foldChange": "1.1531"
					}
				]
			},
			{
				"metaboliteId": "0078dcd0-0000-0000-0000-000000000000",
				"chemicalId": "0000033b-0000-0000-0000-000000000000",
				"chemicalName": "cytidine",
				"plotName": null,
				"compoundId": "00000202-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00089",
				"inChiKey": "UHDGCWIWMRVCDJ-XVFCMESIBD",
				"kegg": "C00475",
				"mass": 243.085522,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3868,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".28761136831382",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".744873587661834",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".97894711292587",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".734143742625448",
						"qValue": "1",
						"foldChange": "1.1307"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".826845912055954",
						"qValue": "1",
						"foldChange": "1.8578"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".577038678289018",
						"qValue": "1",
						"foldChange": "2.1561"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".385077719490983",
						"qValue": ".961000408465767",
						"foldChange": "1.0522"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".757437311017397",
						"qValue": ".966385534746334",
						"foldChange": "1.2292"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".574050933080447",
						"qValue": ".957839854315834",
						"foldChange": "1.2811"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".993251985366338",
						"qValue": "1",
						"foldChange": "1.0770"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".978956701303664",
						"qValue": "1",
						"foldChange": "1.0474"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".972211883721858",
						"qValue": "1",
						"foldChange": "1.0835"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".155248614932874",
						"qValue": "1",
						"foldChange": "1.9989"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".897037657902709",
						"qValue": "1",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".211955468566194",
						"qValue": "1",
						"foldChange": "0.4153"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".291428042086397",
						"qValue": "1",
						"foldChange": "1.4600"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".711709226467746",
						"qValue": "1",
						"foldChange": "1.2167"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".517430019322231",
						"qValue": "1",
						"foldChange": "0.8334"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".299981896591554",
						"qValue": "1",
						"foldChange": "1.1321"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".992631120490646",
						"qValue": "1",
						"foldChange": "0.7168"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".310766160471576",
						"qValue": ".985830071188835",
						"foldChange": "0.6332"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".733409354174055",
						"qValue": ".328877982604021",
						"foldChange": "0.8768"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".079379941820108",
						"qValue": ".052025397738129",
						"foldChange": "0.4079"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".021000694602058",
						"qValue": ".009786684095596",
						"foldChange": "0.4322"
					}
				]
			},
			{
				"metaboliteId": "0078dcd1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7b9-0000-0000-0000-000000000000",
				"chemicalName": "N2-acetyllysine",
				"plotName": null,
				"compoundId": "00008f8f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00446",
				"inChiKey": "VEYYWZRYIYDQJM-ZETCQYMHBC",
				"kegg": "C12989",
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 99,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".878293614959399",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".140316299200942",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".758608937854442",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".539617222290515",
						"qValue": "1",
						"foldChange": "1.0618"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".743909042315691",
						"qValue": "1",
						"foldChange": "1.2238"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".348691460160216",
						"qValue": "1",
						"foldChange": "1.5948"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".187204484762508",
						"qValue": ".797133099236872",
						"foldChange": "0.8760"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".156058213099126",
						"qValue": ".784201030789377",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".918331600820476",
						"qValue": "1",
						"foldChange": "1.0956"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".14241437905852",
						"qValue": "1",
						"foldChange": "0.7979"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".261042999364585",
						"qValue": "1",
						"foldChange": "0.8496"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".724884173500981",
						"qValue": "1",
						"foldChange": "1.0116"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".931056865251303",
						"qValue": "1",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".773910578551952",
						"qValue": "1",
						"foldChange": "1.3437"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".705550227026712",
						"qValue": "1",
						"foldChange": "1.3355"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".70408691772721",
						"qValue": "1",
						"foldChange": "0.8598"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".901719444975774",
						"qValue": "1",
						"foldChange": "1.1385"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".80901481800543",
						"qValue": "1",
						"foldChange": "1.3242"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".397494780553016",
						"qValue": "1",
						"foldChange": "0.8442"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".713999570148522",
						"qValue": "1",
						"foldChange": "0.9502"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".654321870127094",
						"qValue": ".985830071188835",
						"foldChange": "1.1256"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".127733670232527",
						"qValue": ".098581390004428",
						"foldChange": "2.5411"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".233768351519191",
						"qValue": ".117240059153998",
						"foldChange": "1.9826"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010245912543869",
						"qValue": ".005546451259642",
						"foldChange": "3.1513"
					}
				]
			},
			{
				"metaboliteId": "0078dcd2-0000-0000-0000-000000000000",
				"chemicalId": "00000090-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyphenylacetate",
				"plotName": null,
				"compoundId": "0000021d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00020",
				"inChiKey": "XQXPVVBIMDBYFF-REWHXWOFAL",
				"kegg": "C00642",
				"mass": 152.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 149,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".764491932473388",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".117288122341647",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".105227283304433",
						"qValue": ".816144079576958",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".267697234310614",
						"qValue": "1",
						"foldChange": "2.8807"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".116056655359258",
						"qValue": "1",
						"foldChange": "3.1688"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".634063922528433",
						"qValue": "1",
						"foldChange": "1.5556"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".223837941593817",
						"qValue": ".846604836872615",
						"foldChange": "2.6444"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".0745537730125",
						"qValue": ".687896186587066",
						"foldChange": "2.8885"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".55757778546254",
						"qValue": ".957839854315834",
						"foldChange": "1.4088"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".025128286910512",
						"qValue": ".821720595261736",
						"foldChange": "0.5933"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".833415255296832",
						"qValue": "1",
						"foldChange": "1.4695"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".040983749227828",
						"qValue": ".683421910757508",
						"foldChange": "3.4151"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".994815579654956",
						"qValue": "1",
						"foldChange": "0.9298"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".346923305582285",
						"qValue": "1",
						"foldChange": "1.2840"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".332269143647158",
						"qValue": "1",
						"foldChange": "1.3809"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".973869598076986",
						"qValue": "1",
						"foldChange": "0.9117"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".069980618664643",
						"qValue": "1",
						"foldChange": "0.3318"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".059393638179201",
						"qValue": ".822195137696456",
						"foldChange": "0.3639"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".920650613698152",
						"qValue": "1",
						"foldChange": "0.9291"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".633928681841291",
						"qValue": "1",
						"foldChange": "0.7627"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".559267511072707",
						"qValue": ".985830071188835",
						"foldChange": "0.8209"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".022190361131449",
						"qValue": ".02711077406564",
						"foldChange": "3.8941"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".009014375104292",
						"qValue": ".009309727472597",
						"foldChange": "2.9640"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".071296097068038",
						"qValue": ".024864849584814",
						"foldChange": "2.5849"
					}
				]
			},
			{
				"metaboliteId": "0078dcd3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e124-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl-2-oxovalerate",
				"plotName": null,
				"compoundId": "00003d3c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03736",
				"inChiKey": "JVQYSWDUAOAHFM-UHFFFAOYAW",
				"kegg": "C00671",
				"mass": 130.062995,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".67942296648729",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".360617312160361",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".081163738088531",
						"qValue": ".767448234592668",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".02798632274027",
						"qValue": "1",
						"foldChange": "0.9036"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".36820138651711",
						"qValue": "1",
						"foldChange": "0.9592"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".180881348661825",
						"qValue": "1",
						"foldChange": "1.0973"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".413950928128941",
						"qValue": ".989197938984887",
						"foldChange": "1.0568"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".132664657466729",
						"qValue": ".747666380822427",
						"foldChange": "0.9287"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".022725747287234",
						"qValue": ".57754088580993",
						"foldChange": "0.8935"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".694707612649404",
						"qValue": "1",
						"foldChange": "0.9832"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".992385770219675",
						"qValue": "1",
						"foldChange": "1.0117"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".701753900919505",
						"qValue": "1",
						"foldChange": "0.9808"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".847998764256388",
						"qValue": "1",
						"foldChange": "1.0308"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".656260943359743",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".786538522669762",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".146410894427943",
						"qValue": "1",
						"foldChange": "1.2120"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".242134770390289",
						"qValue": "1",
						"foldChange": "1.1339"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".826013935363474",
						"qValue": "1",
						"foldChange": "0.9356"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".97019735404373",
						"qValue": "1",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".423694593374375",
						"qValue": "1",
						"foldChange": "1.0404"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".392612147193806",
						"qValue": ".985830071188835",
						"foldChange": "1.0755"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".762113198532366",
						"qValue": ".335990025268897",
						"foldChange": "1.0211"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".560253849531297",
						"qValue": ".222859903164516",
						"foldChange": "0.9150"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".44904712221483",
						"qValue": ".101827876693684",
						"foldChange": "0.9211"
					}
				]
			},
			{
				"metaboliteId": "0078dcd4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e344-0000-0000-0000-000000000000",
				"chemicalName": "1,5-anhydroglucitol (1,5-AG)",
				"plotName": null,
				"compoundId": "000050c3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02712",
				"inChiKey": "MPCAJMNYNOGXPB-SLPGGIOYBT",
				"kegg": "C07326",
				"mass": 164.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1070,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".048936452190237",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".346016937762208",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".40857387171503",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".608206247150521",
						"qValue": "1",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".710728122224911",
						"qValue": "1",
						"foldChange": "1.0460"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".378378726554145",
						"qValue": "1",
						"foldChange": "1.0821"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".74344991515715",
						"qValue": "1",
						"foldChange": "1.0310"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".250265289561883",
						"qValue": ".825038702902081",
						"foldChange": "0.9507"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".141991281067452",
						"qValue": ".957839854315834",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".66582256728252",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".106597788929435",
						"qValue": "1",
						"foldChange": "0.9103"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".23221282144189",
						"qValue": "1",
						"foldChange": "0.8680"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".134137379033586",
						"qValue": "1",
						"foldChange": "1.3293"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".376732962112351",
						"qValue": "1",
						"foldChange": "0.9371"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".022836659291298",
						"qValue": "1",
						"foldChange": "0.7050"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".090045984710137",
						"qValue": "1",
						"foldChange": "1.5006"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".383823869152007",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".014703374967803",
						"qValue": ".736033652800007",
						"foldChange": "0.6373"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".255088965189352",
						"qValue": "1",
						"foldChange": "1.2998"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".168157365380542",
						"qValue": "1",
						"foldChange": "0.8080"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".015876001284798",
						"qValue": ".985830071188835",
						"foldChange": "0.6217"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".060675624149843",
						"qValue": ".057233983568293",
						"foldChange": "0.7027"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".698687798209899",
						"qValue": ".265431343393153",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".036677317109288",
						"qValue": ".015042932225211",
						"foldChange": "0.7707"
					}
				]
			},
			{
				"metaboliteId": "0078dcd5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2d3-0000-0000-0000-000000000000",
				"chemicalName": "3-indoxyl sulfate",
				"plotName": null,
				"compoundId": "00006c18-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00682",
				"inChiKey": "BXFFHSIDQOFMLE-UHFFFAOYAB",
				"kegg": null,
				"mass": 213.009581,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 281,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".625425377531177",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".239604119840037",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".236255202220489",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".849933663251116",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".696964424316029",
						"qValue": "1",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".563294621421318",
						"qValue": "1",
						"foldChange": "1.1136"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".837459366470874",
						"qValue": "1",
						"foldChange": "1.1105"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".376303188647401",
						"qValue": ".875723678608451",
						"foldChange": "1.2219"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495540689435293",
						"qValue": ".957839854315834",
						"foldChange": "1.1314"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".011493629475754",
						"qValue": ".678448626775002",
						"foldChange": "0.7460"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".116617359594263",
						"qValue": "1",
						"foldChange": "0.8714"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".308057884983231",
						"qValue": "1",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".592649810090331",
						"qValue": "1",
						"foldChange": "1.1320"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".406192392730787",
						"qValue": "1",
						"foldChange": "1.2073"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".737437827142568",
						"qValue": "1",
						"foldChange": "1.0666"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".4432151168243",
						"qValue": "1",
						"foldChange": "1.1752"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".514873929482365",
						"qValue": "1",
						"foldChange": "1.0103"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".162478843713977",
						"qValue": "1",
						"foldChange": "0.8597"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".418874739043346",
						"qValue": "1",
						"foldChange": "1.2095"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".718640753480715",
						"qValue": "1",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".25181983798396",
						"qValue": ".985830071188835",
						"foldChange": "0.8060"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".024546246301592",
						"qValue": ".029552840669334",
						"foldChange": "1.8827"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001253705386077",
						"qValue": ".002479370817295",
						"foldChange": "2.4019"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".102535333052518",
						"qValue": ".033303459778635",
						"foldChange": "1.4165"
					}
				]
			},
			{
				"metaboliteId": "0078dcd6-0000-0000-0000-000000000000",
				"chemicalId": "000000f0-0000-0000-0000-000000000000",
				"chemicalName": "3-(4-hydroxyphenyl)lactate",
				"plotName": null,
				"compoundId": "00007dc5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00755",
				"inChiKey": "JVGVDSSUAVXRDY-UHFFFAOYAV",
				"kegg": "C03672",
				"mass": 182.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 168,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".702784572699984",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".40230057622995",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".378844559936083",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".266755943311174",
						"qValue": "1",
						"foldChange": "0.9301"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".882271944959497",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".209319592918003",
						"qValue": "1",
						"foldChange": "1.2305"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".864858436133487",
						"qValue": "1",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".41371103955226",
						"qValue": ".88881677553957",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".51659506459037",
						"qValue": ".957839854315834",
						"foldChange": "1.0053"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".9262765913621",
						"qValue": "1",
						"foldChange": "1.0074"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".110577715396105",
						"qValue": "1",
						"foldChange": "0.8457"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".131937260733522",
						"qValue": ".960252848686774",
						"foldChange": "0.8023"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".513375708181718",
						"qValue": "1",
						"foldChange": "1.0150"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".849083809403128",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".660396115326496",
						"qValue": "1",
						"foldChange": "0.9258"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".228732183495146",
						"qValue": "1",
						"foldChange": "1.1993"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".452047522029016",
						"qValue": "1",
						"foldChange": "1.1024"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".686526671617617",
						"qValue": "1",
						"foldChange": "0.9192"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".909943214926469",
						"qValue": "1",
						"foldChange": "0.9408"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".402378104646539",
						"qValue": "1",
						"foldChange": "0.7516"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".334359621049947",
						"qValue": ".985830071188835",
						"foldChange": "0.7989"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".108036535492369",
						"qValue": ".086609879754014",
						"foldChange": "1.6337"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000203538876386",
						"qValue": ".000869077862093",
						"foldChange": "1.9791"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".041312875406812",
						"qValue": ".016200281917529",
						"foldChange": "1.8123"
					}
				]
			},
			{
				"metaboliteId": "0078dcd7-0000-0000-0000-000000000000",
				"chemicalId": "00000802-0000-0000-0000-000000000000",
				"chemicalName": "eicosapentaenoate (EPA; 20:5n3)",
				"plotName": null,
				"compoundId": "00004823-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01999",
				"inChiKey": "JAZBEHYOTPTENJ-JLNKQSITBZ",
				"kegg": "C06428",
				"mass": 302.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1387,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".116031576207104",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".515623703574213",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".858725155712238",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".513575551630925",
						"qValue": "1",
						"foldChange": "1.2580"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".63824703671556",
						"qValue": "1",
						"foldChange": "1.1316"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".853999773801254",
						"qValue": "1",
						"foldChange": "1.2695"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".865716104775119",
						"qValue": "1",
						"foldChange": "1.0274"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".419693432849523",
						"qValue": ".890671100635771",
						"foldChange": "1.0172"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".522680295241039",
						"qValue": ".957839854315834",
						"foldChange": "1.0635"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".733919283257477",
						"qValue": "1",
						"foldChange": "1.1759"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".426726661808015",
						"qValue": "1",
						"foldChange": "0.9650"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".258496542961105",
						"qValue": "1",
						"foldChange": "0.8238"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".165351217799373",
						"qValue": "1",
						"foldChange": "0.7696"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".957716017234742",
						"qValue": "1",
						"foldChange": "1.0108"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".196213930850148",
						"qValue": "1",
						"foldChange": "1.3135"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".066177300343377",
						"qValue": "1",
						"foldChange": "0.5911"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".830001911507142",
						"qValue": "1",
						"foldChange": "0.7850"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".115442424141558",
						"qValue": ".908885675694672",
						"foldChange": "1.3279"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".038875292135298",
						"qValue": "1",
						"foldChange": "0.7661"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".438817656698757",
						"qValue": "1",
						"foldChange": "0.6283"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".211280340427373",
						"qValue": ".985830071188835",
						"foldChange": "0.8202"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".015599593520998",
						"qValue": ".020648934936414",
						"foldChange": "2.4239"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".097324387663617",
						"qValue": ".059776561989747",
						"foldChange": "2.9934"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000038647999811",
						"qValue": ".000129451411631",
						"foldChange": "4.6538"
					}
				]
			},
			{
				"metaboliteId": "0078dcd8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e69b-0000-0000-0000-000000000000",
				"chemicalName": "1-linolenoylglycerol (18:3)",
				"plotName": null,
				"compoundId": "00008659-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11569",
				"inChiKey": "XQBHBEXBUZDCRY-QNEBEIHSBO",
				"kegg": null,
				"mass": 352.26136,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".753017689721341",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".519641541070363",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".679416081132527",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".60392580740966",
						"qValue": "1",
						"foldChange": "1.9058"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".628277609124083",
						"qValue": "1",
						"foldChange": "1.2324"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".972280673320753",
						"qValue": "1",
						"foldChange": "1.6746"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".078060249470878",
						"qValue": ".609442865135016",
						"foldChange": "2.3136"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".282639869221762",
						"qValue": ".825038702902081",
						"foldChange": "1.7837"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".478721216156236",
						"qValue": ".957839854315834",
						"foldChange": "1.4419"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".687610074259454",
						"qValue": "1",
						"foldChange": "1.3155"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".893594711567389",
						"qValue": "1",
						"foldChange": "1.5851"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".592370140766668",
						"qValue": "1",
						"foldChange": "1.3631"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".362408325414256",
						"qValue": "1",
						"foldChange": "0.8789"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".881810509545732",
						"qValue": "1",
						"foldChange": "1.1970"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".464612694950966",
						"qValue": "1",
						"foldChange": "1.3619"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".898702144305623",
						"qValue": "1",
						"foldChange": "0.9302"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".349736996617401",
						"qValue": "1",
						"foldChange": "0.6619"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".280344281653182",
						"qValue": "1",
						"foldChange": "0.7115"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".662628380286285",
						"qValue": "1",
						"foldChange": "1.2100"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".663953692922319",
						"qValue": "1",
						"foldChange": "0.8084"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".982076013834379",
						"qValue": "1",
						"foldChange": "0.6682"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008010432796471",
						"qValue": ".013064920813983",
						"foldChange": "0.4299"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".086532033087889",
						"qValue": ".054963894026383",
						"foldChange": "0.7129"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".047692688455011",
						"qValue": ".01830032435278",
						"foldChange": "0.5748"
					}
				]
			},
			{
				"metaboliteId": "0078dcd9-0000-0000-0000-000000000000",
				"chemicalId": "0000041c-0000-0000-0000-000000000000",
				"chemicalName": "glycerate",
				"plotName": null,
				"compoundId": "00000624-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00139",
				"inChiKey": "RBNPOMFGQQGHHO-UHFFFAOYAE",
				"kegg": "C00258",
				"mass": 106.02661,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1093,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".429004875060181",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".650440124102065",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".848655105930108",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".797006658510254",
						"qValue": "1",
						"foldChange": "1.1003"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".890749724498799",
						"qValue": "1",
						"foldChange": "1.0347"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".693315870549017",
						"qValue": "1",
						"foldChange": "1.2105"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".870725250629003",
						"qValue": "1",
						"foldChange": "1.0830"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".311109846851803",
						"qValue": ".825038702902081",
						"foldChange": "1.0413"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".240949010870977",
						"qValue": ".957839854315834",
						"foldChange": "0.9232"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".78285185643798",
						"qValue": "1",
						"foldChange": "0.9812"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".496935939857954",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".685546995938011",
						"qValue": "1",
						"foldChange": "0.9633"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".522406363824159",
						"qValue": "1",
						"foldChange": "0.8919"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".586302853974301",
						"qValue": "1",
						"foldChange": "0.8561"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".950195115394056",
						"qValue": "1",
						"foldChange": "0.9599"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".779744425856422",
						"qValue": "1",
						"foldChange": "0.9334"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".566501243119865",
						"qValue": "1",
						"foldChange": "0.9147"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".751339675364197",
						"qValue": "1",
						"foldChange": "0.9800"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".111430428972548",
						"qValue": "1",
						"foldChange": "0.7788"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".211441783614099",
						"qValue": "1",
						"foldChange": "0.7545"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".787578199254501",
						"qValue": ".997573179629986",
						"foldChange": "0.9689"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000565868464089",
						"qValue": ".001718841504178",
						"foldChange": "2.4109"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001012590867704",
						"qValue": ".00216872027018",
						"foldChange": "2.4175"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000006464335656",
						"qValue": ".000051965475883",
						"foldChange": "3.1277"
					}
				]
			},
			{
				"metaboliteId": "0078dcda-0000-0000-0000-000000000000",
				"chemicalId": "000000b6-0000-0000-0000-000000000000",
				"chemicalName": "quinolinate",
				"plotName": null,
				"compoundId": "0000076b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00232",
				"inChiKey": "GJAWHXHKYYXBSV-UHFFFAOYAW",
				"kegg": "C03722",
				"mass": 167.021859,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3912,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".346542690955251",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".595866689317801",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".684338554613992",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".213122727504263",
						"qValue": "1",
						"foldChange": "0.8625"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".891154156120285",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".266512790619527",
						"qValue": "1",
						"foldChange": "1.7828"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".831398421373952",
						"qValue": "1",
						"foldChange": "1.4126"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".508245661302929",
						"qValue": ".905893288986492",
						"foldChange": "1.1242"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".382906576414415",
						"qValue": ".957839854315834",
						"foldChange": "1.5067"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".502541574534248",
						"qValue": "1",
						"foldChange": "0.8592"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".83445429683689",
						"qValue": "1",
						"foldChange": "1.2089"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".643706789867552",
						"qValue": "1",
						"foldChange": "1.3343"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".705643307692998",
						"qValue": "1",
						"foldChange": "0.8688"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".416283412906218",
						"qValue": "1",
						"foldChange": "1.1696"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".232685801265288",
						"qValue": "1",
						"foldChange": "1.3462"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".437206877949836",
						"qValue": "1",
						"foldChange": "1.2603"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".224650468919846",
						"qValue": "1",
						"foldChange": "1.3399"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".619063502884674",
						"qValue": "1",
						"foldChange": "1.0631"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".443269088881964",
						"qValue": "1",
						"foldChange": "0.9424"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".453429327552007",
						"qValue": "1",
						"foldChange": "1.4063"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".133887705723412",
						"qValue": ".985830071188835",
						"foldChange": "1.4923"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002860119326199",
						"qValue": ".005955714445209",
						"foldChange": "5.8693"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004857171540348",
						"qValue": ".006328995319592",
						"foldChange": "4.1860"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000199576007842",
						"qValue": ".000337758067778",
						"foldChange": "3.8649"
					}
				]
			},
			{
				"metaboliteId": "0078dcdb-0000-0000-0000-000000000000",
				"chemicalId": "0000037b-0000-0000-0000-000000000000",
				"chemicalName": "margarate (17:0)",
				"plotName": null,
				"compoundId": "00000461-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02259",
				"inChiKey": "KEMQGTRYUADPNZ-UHFFFAOYAT",
				"kegg": null,
				"mass": 270.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1344,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".864392562184255",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".191401562457944",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".09779704256799",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".865710839964986",
						"qValue": "1",
						"foldChange": "1.0774"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".728698859480924",
						"qValue": "1",
						"foldChange": "1.0896"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".858791988939057",
						"qValue": "1",
						"foldChange": "1.0616"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".025346210221376",
						"qValue": ".359575885726771",
						"foldChange": "1.4544"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".249315167620768",
						"qValue": ".825038702902081",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".001096074434143",
						"qValue": ".093275934345593",
						"foldChange": "0.6997"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".9984732409258",
						"qValue": "1",
						"foldChange": "1.0604"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".966103907979254",
						"qValue": "1",
						"foldChange": "1.0945"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".967629309687347",
						"qValue": "1",
						"foldChange": "1.0944"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".93462892802397",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".608376388993135",
						"qValue": "1",
						"foldChange": "1.0898"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".545964322067212",
						"qValue": "1",
						"foldChange": "1.0733"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".252648171294849",
						"qValue": "1",
						"foldChange": "1.3296"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".677687582858232",
						"qValue": "1",
						"foldChange": "1.0233"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".490780198647147",
						"qValue": "1",
						"foldChange": "0.7696"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".333794998875673",
						"qValue": "1",
						"foldChange": "0.8911"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".735823047488806",
						"qValue": "1",
						"foldChange": "1.0206"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".202539290346528",
						"qValue": ".985830071188835",
						"foldChange": "1.1454"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".069858774752523",
						"qValue": ".063542810408596",
						"foldChange": "1.5246"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".243247723758379",
						"qValue": ".120477374961749",
						"foldChange": "1.5231"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".110060190321707",
						"qValue": ".034970411950766",
						"foldChange": "1.4008"
					}
				]
			},
			{
				"metaboliteId": "0078dcdc-0000-0000-0000-000000000000",
				"chemicalId": "000001a8-0000-0000-0000-000000000000",
				"chemicalName": "palmitate (16:0)",
				"plotName": null,
				"compoundId": "00000538-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00220",
				"inChiKey": "IPCSVZSSVZVIGE-UHFFFAOYAJ",
				"kegg": "C00249",
				"mass": 256.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1343,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".86484134245279",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".182366964754857",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".125423612373441",
						"qValue": ".8440307234321",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".736239797590463",
						"qValue": "1",
						"foldChange": "1.0521"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".522966393609257",
						"qValue": "1",
						"foldChange": "1.0700"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".761914809589245",
						"qValue": "1",
						"foldChange": "1.0474"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".063758951195003",
						"qValue": ".565196536114033",
						"foldChange": "1.2252"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".152995844992996",
						"qValue": ".784201030789377",
						"foldChange": "0.9497"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".00158183566517",
						"qValue": ".112178512588291",
						"foldChange": "0.7834"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".689996230740193",
						"qValue": "1",
						"foldChange": "1.0699"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".80401501837052",
						"qValue": "1",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".880022180089035",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".96038604483105",
						"qValue": "1",
						"foldChange": "0.9983"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".729168439934543",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".687204145307253",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".376264161378367",
						"qValue": "1",
						"foldChange": "1.1412"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".69002063972862",
						"qValue": "1",
						"foldChange": "1.0296"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".651535139981401",
						"qValue": "1",
						"foldChange": "0.9022"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".182461684914789",
						"qValue": "1",
						"foldChange": "0.8631"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".90732958363589",
						"qValue": "1",
						"foldChange": "0.9481"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".159240638758334",
						"qValue": ".985830071188835",
						"foldChange": "1.0985"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".053090609181276",
						"qValue": ".051547803950466",
						"foldChange": "1.3586"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".293585279479227",
						"qValue": ".138052631324859",
						"foldChange": "1.2675"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".138846657046136",
						"qValue": ".042039923055581",
						"foldChange": "1.2424"
					}
				]
			},
			{
				"metaboliteId": "0078dcdd-0000-0000-0000-000000000000",
				"chemicalId": "00000463-0000-0000-0000-000000000000",
				"chemicalName": "chenodeoxycholate",
				"plotName": null,
				"compoundId": "0000061b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00518",
				"inChiKey": null,
				"kegg": "C02528",
				"mass": 392.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".905014179476393",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".516938362045731",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".561710676395519",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".605399425836198",
						"qValue": "1",
						"foldChange": "1.0368"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".7585934700058",
						"qValue": "1",
						"foldChange": "1.7345"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".411115609544139",
						"qValue": "1",
						"foldChange": "1.8942"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".710515490107129",
						"qValue": "1",
						"foldChange": "1.2590"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".380622596130092",
						"qValue": ".875723678608451",
						"foldChange": "2.2669"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".611327524843699",
						"qValue": ".967408831530889",
						"foldChange": "1.7695"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".125991959002892",
						"qValue": "1",
						"foldChange": "0.8693"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".222683475888679",
						"qValue": "1",
						"foldChange": "0.7702"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".749021571870988",
						"qValue": "1",
						"foldChange": "1.8978"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".536598715858018",
						"qValue": "1",
						"foldChange": "0.7757"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".76284678481298",
						"qValue": "1",
						"foldChange": "0.9741"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".365353112160198",
						"qValue": "1",
						"foldChange": "1.2558"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".978871836708329",
						"qValue": "1",
						"foldChange": "0.6585"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".625758327069027",
						"qValue": "1",
						"foldChange": "0.4061"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".599497558217479",
						"qValue": "1",
						"foldChange": "0.6167"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".820102352324841",
						"qValue": "1",
						"foldChange": "0.9413"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".408748709211934",
						"qValue": "1",
						"foldChange": "0.6178"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".530267822244039",
						"qValue": ".985830071188835",
						"foldChange": "0.6564"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".041318514182766",
						"qValue": ".043809429656789",
						"foldChange": "0.4308"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".209174849506366",
						"qValue": ".107417426107465",
						"foldChange": "0.7225"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".288419273660681",
						"qValue": ".073721580781492",
						"foldChange": "0.7470"
					}
				]
			},
			{
				"metaboliteId": "0078dcde-0000-0000-0000-000000000000",
				"chemicalId": "000000b5-0000-0000-0000-000000000000",
				"chemicalName": "laurate (12:0)",
				"plotName": null,
				"compoundId": "0000066d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00638",
				"inChiKey": "POULHZVOKOAJMA-UHFFFAOYAP",
				"kegg": "C02679",
				"mass": 200.17763,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1337,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".889550176613388",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".930031026241982",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".418223819346733",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".950876453716027",
						"qValue": "1",
						"foldChange": "1.0698"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".300829646464501",
						"qValue": "1",
						"foldChange": "1.2170"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".273441126453281",
						"qValue": "1",
						"foldChange": "1.2693"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".550970214832301",
						"qValue": "1",
						"foldChange": "1.0199"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".202630322915277",
						"qValue": ".825038702902081",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".492766616899872",
						"qValue": ".957839854315834",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".564279520553551",
						"qValue": "1",
						"foldChange": "1.2352"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".706067268949898",
						"qValue": "1",
						"foldChange": "1.0624"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".341908070620562",
						"qValue": "1",
						"foldChange": "0.9045"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".500087050845833",
						"qValue": "1",
						"foldChange": "1.4733"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".576065596947514",
						"qValue": "1",
						"foldChange": "1.2076"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".935263860228185",
						"qValue": "1",
						"foldChange": "0.8197"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".701557547183544",
						"qValue": "1",
						"foldChange": "1.3993"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".355235741420494",
						"qValue": "1",
						"foldChange": "1.1897"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".562158534992111",
						"qValue": "1",
						"foldChange": "0.8502"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".527576743546646",
						"qValue": "1",
						"foldChange": "1.1378"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".818590167062286",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".705317364979436",
						"qValue": ".995398138635986",
						"foldChange": "0.8113"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".838292492564355",
						"qValue": ".355834303110981",
						"foldChange": "0.9338"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".478143699291043",
						"qValue": ".195783654825656",
						"foldChange": "0.8806"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".88100271617986",
						"qValue": ".175085318861155",
						"foldChange": "0.9100"
					}
				]
			},
			{
				"metaboliteId": "0078dcdf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ab-0000-0000-0000-000000000000",
				"chemicalName": "docosatrienoate (22:3n3)",
				"plotName": null,
				"compoundId": "00007ea1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02823",
				"inChiKey": "WBBQTNCISCKUMU-IUQGRGSQBT",
				"kegg": "C16534",
				"mass": 334.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1391,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".612466966781527",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".820714063090545",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".046399123640379",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".676036426258486",
						"qValue": "1",
						"foldChange": "1.4029"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".844042209993149",
						"qValue": "1",
						"foldChange": "1.4206"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".824750816001225",
						"qValue": "1",
						"foldChange": "1.6030"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".212656061958595",
						"qValue": ".826348441674723",
						"foldChange": "1.6914"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".812260276713848",
						"qValue": ".991417013240296",
						"foldChange": "1.1296"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".139668938500556",
						"qValue": ".957839854315834",
						"foldChange": "0.8228"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".027275281460115",
						"qValue": ".821720595261736",
						"foldChange": "0.6773"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".791513446975865",
						"qValue": "1",
						"foldChange": "1.5688"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".014270662590717",
						"qValue": ".573029207973764",
						"foldChange": "2.4799"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".717590699462595",
						"qValue": "1",
						"foldChange": "0.7995"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".840738614262062",
						"qValue": "1",
						"foldChange": "0.8660"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".884965933721268",
						"qValue": "1",
						"foldChange": "1.0831"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".853579302753804",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".0415441389752",
						"qValue": "1",
						"foldChange": "0.4034"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".024376790483442",
						"qValue": ".822195137696456",
						"foldChange": "0.3977"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".510186303037042",
						"qValue": "1",
						"foldChange": "0.6563"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".884660041338481",
						"qValue": "1",
						"foldChange": "0.7841"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".624031187163794",
						"qValue": ".985830071188835",
						"foldChange": "1.1947"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".886371920273298",
						"qValue": ".367756143534571",
						"foldChange": "0.9422"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".310436972065787",
						"qValue": ".144647639353383",
						"foldChange": "0.8822"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".690909836231467",
						"qValue": ".14333118082496",
						"foldChange": "1.3684"
					}
				]
			},
			{
				"metaboliteId": "0078dce0-0000-0000-0000-000000000000",
				"chemicalId": "00000339-0000-0000-0000-000000000000",
				"chemicalName": "uracil",
				"plotName": null,
				"compoundId": "0000025d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00300",
				"inChiKey": "ISAKRJDGNUQOIC-UHFFFAOYAV",
				"kegg": "C00106",
				"mass": 112.027278,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3838,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".895177435349197",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".701766183446364",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".915816677498941",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".671453436214669",
						"qValue": "1",
						"foldChange": "1.1395"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".977697542707893",
						"qValue": "1",
						"foldChange": "1.1352"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".651250696942977",
						"qValue": "1",
						"foldChange": "1.7190"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".902743190564848",
						"qValue": "1",
						"foldChange": "1.1152"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".372227056671689",
						"qValue": ".875723678608451",
						"foldChange": "1.2442"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".440451743503064",
						"qValue": ".957839854315834",
						"foldChange": "1.5667"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".500212631744783",
						"qValue": "1",
						"foldChange": "0.9280"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".645318218427041",
						"qValue": "1",
						"foldChange": "0.9660"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".829823998643461",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".754418111291586",
						"qValue": "1",
						"foldChange": "0.9055"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".585819887215612",
						"qValue": "1",
						"foldChange": "1.0894"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".389991904809004",
						"qValue": "1",
						"foldChange": "1.2030"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".831537021278317",
						"qValue": "1",
						"foldChange": "0.9874"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".782035399271549",
						"qValue": "1",
						"foldChange": "0.9128"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".938306405928496",
						"qValue": "1",
						"foldChange": "0.9245"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".626775278620867",
						"qValue": "1",
						"foldChange": "1.0986"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".943971054387385",
						"qValue": "1",
						"foldChange": "0.9337"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".689746919348526",
						"qValue": ".992105993541858",
						"foldChange": "0.8498"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".160887104316189",
						"qValue": ".11529914801257",
						"foldChange": "1.3708"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008486417029251",
						"qValue": ".008929838442512",
						"foldChange": "1.7762"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".186909708278843",
						"qValue": ".053092898344449",
						"foldChange": "1.4563"
					}
				]
			},
			{
				"metaboliteId": "0078dce1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4bc-0000-0000-0000-000000000000",
				"chemicalName": "8-hydroxyoctanoate",
				"plotName": null,
				"compoundId": "000052f7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61914",
				"inChiKey": "KDMSVYIHKLZKET-UHFFFAOYAJ",
				"kegg": null,
				"mass": 160.109944,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".429341012693512",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".32582919618552",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".869710706178973",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".283138551637951",
						"qValue": "1",
						"foldChange": "4.5914"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".608491268470893",
						"qValue": "1",
						"foldChange": "0.8621"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".115921932449386",
						"qValue": "1",
						"foldChange": "0.7702"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".420049395989868",
						"qValue": ".989197938984887",
						"foldChange": "2.5833"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".599070802812336",
						"qValue": ".928868848501546",
						"foldChange": "3.7961"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".777577287177585",
						"qValue": ".98911550282231",
						"foldChange": "2.4198"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".637408045538621",
						"qValue": "1",
						"foldChange": "1.6471"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".86735570178511",
						"qValue": "1",
						"foldChange": "2.0987"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".760605993263226",
						"qValue": "1",
						"foldChange": "1.1069"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".93282793879927",
						"qValue": "1",
						"foldChange": "1.1373"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".529169975869749",
						"qValue": "1",
						"foldChange": "0.4398"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".467970787790462",
						"qValue": "1",
						"foldChange": "0.3867"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".841459778720035",
						"qValue": "1",
						"foldChange": "0.7040"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".261165791774507",
						"qValue": "1",
						"foldChange": "0.3110"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".337330537836651",
						"qValue": "1",
						"foldChange": "0.4418"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".316154313838533",
						"qValue": "1",
						"foldChange": "1.6344"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".971299609462336",
						"qValue": "1",
						"foldChange": "0.7441"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".313629453811729",
						"qValue": ".985830071188835",
						"foldChange": "0.4553"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000005707350363",
						"qValue": ".000094482500107",
						"foldChange": "0.1127"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000135735791719",
						"qValue": ".000714140423892",
						"foldChange": "0.1973"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000012548310016",
						"qValue": ".0000703618314",
						"foldChange": "0.1359"
					}
				]
			},
			{
				"metaboliteId": "0078dce2-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee68-0000-0000-0000-000000000000",
				"chemicalName": "dihydroferulate",
				"plotName": null,
				"compoundId": "00009e21-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BOLQJTPHPSDZHR-UHFFFAOYAM",
				"kegg": null,
				"mass": 196.073559,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".303300963782984",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".024519087487061",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".434366393616062",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".024822230393589",
						"qValue": "1",
						"foldChange": "3.5627"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".009535246180084",
						"qValue": "1",
						"foldChange": "3.7072"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".702946919771862",
						"qValue": "1",
						"foldChange": "2.0019"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".882002799002377",
						"qValue": "1",
						"foldChange": "1.3203"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".478941658527266",
						"qValue": ".902562957068497",
						"foldChange": "1.8253"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".392535699655271",
						"qValue": ".957839854315834",
						"foldChange": "1.7236"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".203442303352294",
						"qValue": "1",
						"foldChange": "2.1919"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".206786219772703",
						"qValue": "1",
						"foldChange": "2.3213"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".992346112790003",
						"qValue": "1",
						"foldChange": "1.2383"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".050180017959167",
						"qValue": "1",
						"foldChange": "2.8758"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".820991557201192",
						"qValue": "1",
						"foldChange": "1.7994"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".092905890288425",
						"qValue": "1",
						"foldChange": "0.6257"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".71694095881773",
						"qValue": "1",
						"foldChange": "1.0290"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".709773902148555",
						"qValue": "1",
						"foldChange": "0.8246"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".464947105225948",
						"qValue": "1",
						"foldChange": "0.8013"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".514831082791113",
						"qValue": "1",
						"foldChange": "1.3645"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".535577627990358",
						"qValue": "1",
						"foldChange": "0.6555"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".208789979684277",
						"qValue": ".985830071188835",
						"foldChange": "0.4804"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".024839222559682",
						"qValue": ".029687788285768",
						"foldChange": "0.5624"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".254181889155604",
						"qValue": ".124025740207524",
						"foldChange": "0.9576"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".067715247421558",
						"qValue": ".023927431339093",
						"foldChange": "0.7454"
					}
				]
			},
			{
				"metaboliteId": "0078dce3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8de-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-pregnan-3beta,20alpha-diol monosulfate (2)",
				"plotName": null,
				"compoundId": "00009150-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 400.228345,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2958,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".117148973096707",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".197686036499503",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".766520287789718",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".353212606032237",
						"qValue": "1",
						"foldChange": "1.4539"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".482229937817065",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".106360763459538",
						"qValue": "1",
						"foldChange": "0.9058"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".8935678403308",
						"qValue": "1",
						"foldChange": "1.1986"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".159939781863418",
						"qValue": ".786755805582478",
						"foldChange": "1.0190"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".202236875101823",
						"qValue": ".957839854315834",
						"foldChange": "0.8663"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".656312811385323",
						"qValue": "1",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".630518484371777",
						"qValue": "1",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".971195290862549",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".066678873448373",
						"qValue": "1",
						"foldChange": "0.0898"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".360850975047238",
						"qValue": "1",
						"foldChange": "0.1189"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".377077228091174",
						"qValue": "1",
						"foldChange": "1.3237"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".032382858842808",
						"qValue": "1",
						"foldChange": "0.0239"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".184599736120771",
						"qValue": "1",
						"foldChange": "0.0324"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".43682294030904",
						"qValue": "1",
						"foldChange": "1.3526"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".04332913208541",
						"qValue": "1",
						"foldChange": "0.1213"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".391983709286721",
						"qValue": "1",
						"foldChange": "0.1785"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".255619750194185",
						"qValue": ".985830071188835",
						"foldChange": "1.4717"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".601277503600945",
						"qValue": ".289768327335659",
						"foldChange": "1.4178"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".764814216205731",
						"qValue": ".281724864437188",
						"foldChange": "1.2530"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".165523761731235",
						"qValue": ".048063078602301",
						"foldChange": "6.7828"
					}
				]
			},
			{
				"metaboliteId": "0078dce4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f10f-0000-0000-0000-000000000000",
				"chemicalName": "4-methylcatechol sulfate",
				"plotName": null,
				"compoundId": "0000b442-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 204.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4094,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".773181687119056",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".829295540061108",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".947627113837512",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".716290685346324",
						"qValue": "1",
						"foldChange": "1.2430"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".964042039396439",
						"qValue": "1",
						"foldChange": "1.3221"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".682982085596678",
						"qValue": "1",
						"foldChange": "1.1517"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".424274086638641",
						"qValue": ".989197938984887",
						"foldChange": "1.3586"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".616281803710594",
						"qValue": ".928868848501546",
						"foldChange": "1.3329"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".764340918937351",
						"qValue": ".98622264532067",
						"foldChange": "1.1199"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".752183406037504",
						"qValue": "1",
						"foldChange": "1.4844"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".631271495604033",
						"qValue": "1",
						"foldChange": "1.3937"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".869283409373157",
						"qValue": "1",
						"foldChange": "1.0064"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".380157581821176",
						"qValue": "1",
						"foldChange": "0.7617"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".777453154357288",
						"qValue": "1",
						"foldChange": "0.8006"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".572983499154685",
						"qValue": "1",
						"foldChange": "1.0510"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".811449311400529",
						"qValue": "1",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".923957266560827",
						"qValue": "1",
						"foldChange": "1.1615"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".74237616642755",
						"qValue": "1",
						"foldChange": "1.0208"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".526216164247908",
						"qValue": "1",
						"foldChange": "1.0888"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".975024136367287",
						"qValue": "1",
						"foldChange": "0.9360"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".560441477436129",
						"qValue": ".985830071188835",
						"foldChange": "0.8597"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001542361088788",
						"qValue": ".003621710948554",
						"foldChange": "2.9105"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".142689808292163",
						"qValue": ".080543613845744",
						"foldChange": "2.4875"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".022770010477187",
						"qValue": ".010429827356314",
						"foldChange": "2.0459"
					}
				]
			},
			{
				"metaboliteId": "0078dce5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8e5-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-androstan-3beta,17alpha-diol disulfate",
				"plotName": null,
				"compoundId": "00009143-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 452.153859,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3056,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".864265489659616",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".427283293751481",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".743247187870354",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".112303050029464",
						"qValue": "1",
						"foldChange": "1.0608"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".114461061821973",
						"qValue": "1",
						"foldChange": "0.7463"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".992139452304448",
						"qValue": "1",
						"foldChange": "1.7518"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".779383833439707",
						"qValue": "1",
						"foldChange": "1.4845"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".777834384563123",
						"qValue": ".976406093916918",
						"foldChange": "1.2948"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".998386086410032",
						"qValue": "1",
						"foldChange": "1.0556"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".797606355074243",
						"qValue": "1",
						"foldChange": "1.5261"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".896102350408815",
						"qValue": "1",
						"foldChange": "1.5899"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".698882974555531",
						"qValue": "1",
						"foldChange": "1.5055"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".447617469157448",
						"qValue": "1",
						"foldChange": "0.8708"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".753819809366931",
						"qValue": "1",
						"foldChange": "1.4451"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".676763550785884",
						"qValue": "1",
						"foldChange": "1.6595"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".889349865552866",
						"qValue": "1",
						"foldChange": "1.8218"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".784820508814644",
						"qValue": "1",
						"foldChange": "1.6906"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".678809040297308",
						"qValue": "1",
						"foldChange": "0.9279"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".885034795065387",
						"qValue": "1",
						"foldChange": "1.5542"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".65383347445562",
						"qValue": "1",
						"foldChange": "1.7072"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".549514230208461",
						"qValue": ".985830071188835",
						"foldChange": "1.0984"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".089559026713432",
						"qValue": ".076423069702785",
						"foldChange": "3.2463"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".71898523631334",
						"qValue": ".270492211834564",
						"foldChange": "2.2366"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".167997706642819",
						"qValue": ".048666641414323",
						"foldChange": "1.9089"
					}
				]
			},
			{
				"metaboliteId": "0078dce6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7ee-0000-0000-0000-000000000000",
				"chemicalName": "3beta,7beta-dihydroxy-5-cholestenoate",
				"plotName": null,
				"compoundId": "00008fea-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 432.32396,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2902,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".314021719493204",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".240294130291608",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".436666603689774",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".102323351183477",
						"qValue": "1",
						"foldChange": "0.9017"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".083823471895105",
						"qValue": "1",
						"foldChange": "0.8948"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".920698532653721",
						"qValue": "1",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".318205836203496",
						"qValue": ".940254050726303",
						"foldChange": "1.1253"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".993549197965959",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".314362794553428",
						"qValue": ".957839854315834",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815621019085866",
						"qValue": "1",
						"foldChange": "0.9877"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".283475962567072",
						"qValue": "1",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".399420035784176",
						"qValue": "1",
						"foldChange": "0.8921"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".530863673742",
						"qValue": "1",
						"foldChange": "0.9215"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".322329501080517",
						"qValue": "1",
						"foldChange": "1.2677"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".110696085422564",
						"qValue": "1",
						"foldChange": "1.3756"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".750595532060292",
						"qValue": "1",
						"foldChange": "1.0397"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".145844841017272",
						"qValue": "1",
						"foldChange": "1.2839"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".232152466770293",
						"qValue": "1",
						"foldChange": "1.2348"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".99526045668781",
						"qValue": "1",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".232815658142363",
						"qValue": "1",
						"foldChange": "1.2420"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".2239999126116",
						"qValue": ".985830071188835",
						"foldChange": "1.2644"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".319564278551694",
						"qValue": ".186604454511643",
						"foldChange": "1.4955"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".089853583284189",
						"qValue": ".056686316581752",
						"foldChange": "1.2681"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".342786792125311",
						"qValue": ".083280304277061",
						"foldChange": "1.1820"
					}
				]
			},
			{
				"metaboliteId": "0078dce7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ca-0000-0000-0000-000000000000",
				"chemicalName": "I-urobilinogen",
				"plotName": null,
				"compoundId": "00007eaa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04157",
				"inChiKey": "OBHRVMZSZIDDEK-UHFFFAOYAH",
				"kegg": "C05790",
				"mass": 592.326085,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4017,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".329382547810546",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".091727903567884",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".703634517999714",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".321899492211393",
						"qValue": "1",
						"foldChange": "0.8741"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".166075159632072",
						"qValue": "1",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".686974404589046",
						"qValue": "1",
						"foldChange": "1.1885"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".290499799506657",
						"qValue": ".911265664999995",
						"foldChange": "0.9918"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".913280783232331",
						"qValue": "1",
						"foldChange": "2.6068"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".244489792162427",
						"qValue": ".957839854315834",
						"foldChange": "2.7820"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".082292587611958",
						"qValue": ".886468253895902",
						"foldChange": "0.7192"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".496294675250018",
						"qValue": "1",
						"foldChange": "0.8487"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".28153456909106",
						"qValue": "1",
						"foldChange": "1.9453"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".348084550463515",
						"qValue": "1",
						"foldChange": "1.1739"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".975893384306947",
						"qValue": "1",
						"foldChange": "0.7421"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".347415583259265",
						"qValue": "1",
						"foldChange": "0.6322"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".351225773722097",
						"qValue": "1",
						"foldChange": "1.4729"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".66990073823081",
						"qValue": "1",
						"foldChange": "0.4092"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".184195688799118",
						"qValue": "1",
						"foldChange": "0.2778"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".103279568831632",
						"qValue": "1",
						"foldChange": "2.6737"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".78311489014138",
						"qValue": "1",
						"foldChange": "1.2987"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".189720773996698",
						"qValue": ".985830071188835",
						"foldChange": "0.4857"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".474007229745045",
						"qValue": ".240335886105358",
						"foldChange": "3.0970"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00697295254468",
						"qValue": ".007936257150363",
						"foldChange": "9.9299"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".595684196613103",
						"qValue": ".126682110108298",
						"foldChange": "1.0168"
					}
				]
			},
			{
				"metaboliteId": "0078dce8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e623-0000-0000-0000-000000000000",
				"chemicalName": "p-cresol sulfate",
				"plotName": null,
				"compoundId": "00008d07-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11635",
				"inChiKey": "WGNAKZGUSRVWRH-REWHXWOFAB",
				"kegg": null,
				"mass": 188.014329,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4132,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".433973623793605",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".554256160630491",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".521265415589032",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".626004497720222",
						"qValue": "1",
						"foldChange": "1.2173"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".195552495504054",
						"qValue": "1",
						"foldChange": "1.3870"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".415081787972955",
						"qValue": "1",
						"foldChange": "1.1913"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".211291524352739",
						"qValue": ".824812326716427",
						"foldChange": "0.9579"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".274049819801094",
						"qValue": ".825038702902081",
						"foldChange": "0.9386"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".873166282480716",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".411223049167778",
						"qValue": "1",
						"foldChange": "1.0698"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".883322921515234",
						"qValue": "1",
						"foldChange": "1.1382"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".498872914047593",
						"qValue": "1",
						"foldChange": "1.1312"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".510788204479294",
						"qValue": "1",
						"foldChange": "0.8400"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".791791832685148",
						"qValue": "1",
						"foldChange": "1.2156"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".366356879961812",
						"qValue": "1",
						"foldChange": "1.4472"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".229806363218456",
						"qValue": "1",
						"foldChange": "0.7807"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".871425345115168",
						"qValue": "1",
						"foldChange": "1.0553"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".315579533503601",
						"qValue": "1",
						"foldChange": "1.3518"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".158220561985656",
						"qValue": "1",
						"foldChange": "0.7324"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".847179330548558",
						"qValue": "1",
						"foldChange": "0.9476"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".238204332548412",
						"qValue": ".985830071188835",
						"foldChange": "1.2938"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".010617065737448",
						"qValue": ".016051189794722",
						"foldChange": "2.6194"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".366160565146205",
						"qValue": ".15903827181305",
						"foldChange": "2.1275"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".060792064269418",
						"qValue": ".022013288542432",
						"foldChange": "1.7950"
					}
				]
			},
			{
				"metaboliteId": "0078dce9-0000-0000-0000-000000000000",
				"chemicalId": "0000039b-0000-0000-0000-000000000000",
				"chemicalName": "dihydroorotate",
				"plotName": null,
				"compoundId": "00000259-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03349",
				"inChiKey": "UFIVEPVSAGBUSI-UHFFFAOYAI",
				"kegg": "C00337",
				"mass": 158.032758,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3824,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".260713591783931",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".866583835461591",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".748430618257249",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".705517044391642",
						"qValue": "1",
						"foldChange": "3.7204"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".621886242164825",
						"qValue": "1",
						"foldChange": "2.3704"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".385107725365639",
						"qValue": "1",
						"foldChange": "6.4221"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".705914321738293",
						"qValue": "1",
						"foldChange": "1.0575"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".981126775786983",
						"qValue": "1",
						"foldChange": "2.9193"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".723531983358629",
						"qValue": ".976023509428735",
						"foldChange": "3.1909"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".874320792483108",
						"qValue": "1",
						"foldChange": "2.5550"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".301683379428015",
						"qValue": "1",
						"foldChange": "2.5332"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".380542218733616",
						"qValue": "1",
						"foldChange": "2.1003"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".861115122525051",
						"qValue": "1",
						"foldChange": "0.8228"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".667182594309794",
						"qValue": "1",
						"foldChange": "0.7459"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".542202098694156",
						"qValue": "1",
						"foldChange": "0.9066"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".845386545973217",
						"qValue": "1",
						"foldChange": "0.5719"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".818193954391349",
						"qValue": "1",
						"foldChange": "0.4961"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".671441341256664",
						"qValue": "1",
						"foldChange": "0.8674"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".730157791759497",
						"qValue": "1",
						"foldChange": "0.8361"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".054263605621845",
						"qValue": "1",
						"foldChange": "0.3471"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".099452416130585",
						"qValue": ".985830071188835",
						"foldChange": "0.4151"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000054159229478",
						"qValue": ".000333697823959",
						"foldChange": "0.1896"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".10298907837229",
						"qValue": ".062566749429275",
						"foldChange": "0.6852"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056185233413493",
						"qValue": ".020879844219214",
						"foldChange": "0.6965"
					}
				]
			},
			{
				"metaboliteId": "0078dcea-0000-0000-0000-000000000000",
				"chemicalId": "000000eb-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyphenylacetate",
				"plotName": null,
				"compoundId": "00000598-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00669",
				"inChiKey": "CCVYRRGZDBSHFU-UHFFFAOYAL",
				"kegg": "C05852",
				"mass": 152.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 146,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".238175023048625",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".913565044981061",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".02884744642345",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".110128089277857",
						"qValue": "1",
						"foldChange": "1.2731"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".873564175247681",
						"qValue": "1",
						"foldChange": "1.0762"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".148694375925353",
						"qValue": "1",
						"foldChange": "0.9000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".022158350529578",
						"qValue": ".359575885726771",
						"foldChange": "0.8380"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".087757512956841",
						"qValue": ".697458782960496",
						"foldChange": "0.8622"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".537278586598941",
						"qValue": ".957839854315834",
						"foldChange": "1.1354"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".219278188473707",
						"qValue": "1",
						"foldChange": "1.2339"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".126243624914984",
						"qValue": "1",
						"foldChange": "1.3002"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".756827130128722",
						"qValue": "1",
						"foldChange": "1.0559"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".612864896390672",
						"qValue": "1",
						"foldChange": "1.1457"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".633887791769208",
						"qValue": "1",
						"foldChange": "1.1423"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".998141260632889",
						"qValue": "1",
						"foldChange": "0.9970"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".044198367254557",
						"qValue": "1",
						"foldChange": "0.6585"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".776500016769661",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".026318793226309",
						"qValue": ".822195137696456",
						"foldChange": "1.5946"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".487574120820985",
						"qValue": "1",
						"foldChange": "0.8947"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".162786907105186",
						"qValue": "1",
						"foldChange": "1.3383"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".038658602451419",
						"qValue": ".985830071188835",
						"foldChange": "1.4957"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".145029586318406",
						"qValue": ".106731111544603",
						"foldChange": "1.6189"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".075093425046929",
						"qValue": ".050094579743821",
						"foldChange": "1.4216"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02898339570066",
						"qValue": ".012490842819832",
						"foldChange": "1.4542"
					}
				]
			},
			{
				"metaboliteId": "0078dceb-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2bd-0000-0000-0000-000000000000",
				"chemicalName": "acisoga",
				"plotName": null,
				"compoundId": "0000a8fa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "OAUYENAPBFTAQT-UHFFFAOYAN",
				"kegg": null,
				"mass": 184.121178,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 476,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".56335628052464",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".257975191920571",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".993848750112025",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".825693969420716",
						"qValue": "1",
						"foldChange": "1.0782"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".400874113991856",
						"qValue": "1",
						"foldChange": "0.9302"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".290406507659247",
						"qValue": "1",
						"foldChange": "0.9167"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".638348746960105",
						"qValue": "1",
						"foldChange": "1.1503"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".494403498410336",
						"qValue": ".902562957068497",
						"foldChange": "1.0543"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".251145770897041",
						"qValue": ".957839854315834",
						"foldChange": "0.9279"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".626459632237012",
						"qValue": "1",
						"foldChange": "1.1367"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".852391287396512",
						"qValue": "1",
						"foldChange": "1.0190"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".501693102569851",
						"qValue": "1",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".982250688786196",
						"qValue": "1",
						"foldChange": "0.9524"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".334678251820488",
						"qValue": "1",
						"foldChange": "0.9178"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".333630337805483",
						"qValue": "1",
						"foldChange": "0.9637"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".914565403191245",
						"qValue": "1",
						"foldChange": "1.0425"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".419875945030674",
						"qValue": "1",
						"foldChange": "0.9540"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".353183110847456",
						"qValue": "1",
						"foldChange": "0.9150"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".929477277704672",
						"qValue": "1",
						"foldChange": "1.0475"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".535617618791926",
						"qValue": "1",
						"foldChange": "0.9077"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".471886619968384",
						"qValue": ".985830071188835",
						"foldChange": "0.8666"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".708015191490703",
						"qValue": ".322444522527245",
						"foldChange": "1.1964"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".377946404561708",
						"qValue": ".163140882169833",
						"foldChange": "1.2668"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".7425215674194",
						"qValue": ".15153442168488",
						"foldChange": "0.9437"
					}
				]
			},
			{
				"metaboliteId": "0078dcec-0000-0000-0000-000000000000",
				"chemicalId": "05f5e72c-0000-0000-0000-000000000000",
				"chemicalName": "docosapentaenoate (n6 DPA; 22:5n6)",
				"plotName": null,
				"compoundId": "00009266-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01976",
				"inChiKey": "AVKOENOBFIYBSA-WMPRHZDHBF",
				"kegg": "C16513",
				"mass": 330.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1403,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".994520694518552",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".180176465779888",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".498764824275957",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".473161187967108",
						"qValue": "1",
						"foldChange": "1.1494"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".682672348248973",
						"qValue": "1",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".756596073348257",
						"qValue": "1",
						"foldChange": "1.0797"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".951486781890904",
						"qValue": "1",
						"foldChange": "1.0573"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".054150714901373",
						"qValue": ".687794901209975",
						"foldChange": "0.8838"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".061743187389545",
						"qValue": ".750620749550044",
						"foldChange": "0.8520"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".548649849541644",
						"qValue": "1",
						"foldChange": "0.9576"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".204117770385338",
						"qValue": "1",
						"foldChange": "0.8559"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".497657351250932",
						"qValue": "1",
						"foldChange": "0.9299"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".451143092863263",
						"qValue": "1",
						"foldChange": "1.1773"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".507740172702471",
						"qValue": "1",
						"foldChange": "1.0734"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".95848614872659",
						"qValue": "1",
						"foldChange": "0.9118"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".810160995883605",
						"qValue": "1",
						"foldChange": "1.0679"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".851383112443231",
						"qValue": "1",
						"foldChange": "0.8691"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".671361715672307",
						"qValue": "1",
						"foldChange": "0.8139"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".453488651929308",
						"qValue": "1",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".656468164160694",
						"qValue": "1",
						"foldChange": "0.8644"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".785455673157924",
						"qValue": ".997573179629986",
						"foldChange": "0.8565"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".090378885881561",
						"qValue": ".076727176155984",
						"foldChange": "0.6794"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".60228533558963",
						"qValue": ".236993165350789",
						"foldChange": "1.0459"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".747741238980661",
						"qValue": ".152175678796359",
						"foldChange": "0.9462"
					}
				]
			},
			{
				"metaboliteId": "0078dced-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0fb-0000-0000-0000-000000000000",
				"chemicalName": "5-hydroxydecanoate",
				"plotName": null,
				"compoundId": "0000a803-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB40329",
				"inChiKey": "LMHJFKYQYDSOQO-UHFFFAOYAA",
				"kegg": null,
				"mass": 188.141245,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1759,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".485721134381577",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".95718385895243",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".692533810290968",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".409095764228089",
						"qValue": "1",
						"foldChange": "2.0209"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".778619307771836",
						"qValue": "1",
						"foldChange": "0.9430"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".270198492576566",
						"qValue": "1",
						"foldChange": "0.9418"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".67792123482663",
						"qValue": "1",
						"foldChange": "1.2560"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".828853276805795",
						"qValue": ".997672048885051",
						"foldChange": "1.7628"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".52831253093432",
						"qValue": ".957839854315834",
						"foldChange": "2.0741"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".882388702621726",
						"qValue": "1",
						"foldChange": "1.0301"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".580189073592585",
						"qValue": "1",
						"foldChange": "1.6495"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".48383331245349",
						"qValue": "1",
						"foldChange": "1.5616"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".687082039454027",
						"qValue": "1",
						"foldChange": "1.1972"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".563723945477317",
						"qValue": "1",
						"foldChange": "0.6693"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".327562290139973",
						"qValue": "1",
						"foldChange": "0.5591"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".468662473904476",
						"qValue": "1",
						"foldChange": "0.7441"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".153447895888031",
						"qValue": "1",
						"foldChange": "0.4491"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".442187457822784",
						"qValue": "1",
						"foldChange": "0.6035"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".395395957347894",
						"qValue": "1",
						"foldChange": "1.2554"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".856701710868546",
						"qValue": "1",
						"foldChange": "0.9254"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".522513366176137",
						"qValue": ".985830071188835",
						"foldChange": "0.7371"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000009445489069",
						"qValue": ".000125092502156",
						"foldChange": "0.2163"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000049098225336",
						"qValue": ".000380301816803",
						"foldChange": "0.2419"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000035277727191",
						"qValue": ".000126040201651",
						"foldChange": "0.2242"
					}
				]
			},
			{
				"metaboliteId": "0078dcee-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2cf-0000-0000-0000-000000000000",
				"chemicalName": "indolelactate",
				"plotName": null,
				"compoundId": "000047ad-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00671",
				"inChiKey": "XGILAAMKEQUXLS-UHFFFAOYAH",
				"kegg": "C02043",
				"mass": 205.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 266,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".636036932038231",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".534575782838167",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".69677355341168",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".724673630542644",
						"qValue": "1",
						"foldChange": "1.0533"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".35236329809282",
						"qValue": "1",
						"foldChange": "1.0932"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".561390667056055",
						"qValue": "1",
						"foldChange": "1.0916"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".395960372115373",
						"qValue": ".973318075472851",
						"foldChange": "0.9719"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".986063079173448",
						"qValue": "1",
						"foldChange": "1.0446"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".38642163807271",
						"qValue": ".957839854315834",
						"foldChange": "1.0906"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".237177751566387",
						"qValue": "1",
						"foldChange": "0.9241"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".341119480162263",
						"qValue": "1",
						"foldChange": "0.9496"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".814808244101705",
						"qValue": "1",
						"foldChange": "0.9522"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".48793735909538",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".890254251908138",
						"qValue": "1",
						"foldChange": "1.0004"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".595005928540031",
						"qValue": "1",
						"foldChange": "0.9123"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".989903325942492",
						"qValue": "1",
						"foldChange": "0.9549"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".428927611940392",
						"qValue": "1",
						"foldChange": "0.8863"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".424836709313798",
						"qValue": "1",
						"foldChange": "0.9281"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".892060973398961",
						"qValue": "1",
						"foldChange": "1.0055"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".326858638882836",
						"qValue": "1",
						"foldChange": "0.8015"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".257354245570058",
						"qValue": ".985830071188835",
						"foldChange": "0.7971"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".219209179324028",
						"qValue": ".143177969939591",
						"foldChange": "1.2721"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002001693513868",
						"qValue": ".003327413202008",
						"foldChange": "1.5320"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".027101742752374",
						"qValue": ".011912567781405",
						"foldChange": "1.4107"
					}
				]
			},
			{
				"metaboliteId": "0078dcef-0000-0000-0000-000000000000",
				"chemicalId": "00000323-0000-0000-0000-000000000000",
				"chemicalName": "mannose",
				"plotName": null,
				"compoundId": "0000bc19-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00169",
				"inChiKey": "GZCGUPFRVQAUEE-KVTDHHQDBB",
				"kegg": "C00159",
				"mass": 180.06339,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1199,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".042165557463341",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".402143303304472",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".180670219447652",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".048741761855592",
						"qValue": "1",
						"foldChange": "0.8665"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".221797689662093",
						"qValue": "1",
						"foldChange": "0.9205"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".436647668232215",
						"qValue": "1",
						"foldChange": "1.0824"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".405963302364315",
						"qValue": ".981462415659181",
						"foldChange": "0.9856"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".058442619791568",
						"qValue": ".687896186587066",
						"foldChange": "0.9148"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".27673198705648",
						"qValue": ".957839854315834",
						"foldChange": "0.9521"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".396085908650888",
						"qValue": "1",
						"foldChange": "1.0774"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".397090223203051",
						"qValue": "1",
						"foldChange": "1.0772"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".99854508108439",
						"qValue": "1",
						"foldChange": "0.9979"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".989356135073135",
						"qValue": "1",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".177358768413685",
						"qValue": "1",
						"foldChange": "1.2114"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".171143460696105",
						"qValue": "1",
						"foldChange": "1.2615"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".527902075539619",
						"qValue": "1",
						"foldChange": "1.0316"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".008654458984807",
						"qValue": "1",
						"foldChange": "1.4052"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".031929870456112",
						"qValue": ".822195137696456",
						"foldChange": "1.3622"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".771651860998985",
						"qValue": "1",
						"foldChange": "0.9281"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".021585860870967",
						"qValue": "1",
						"foldChange": "1.3732"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".009472249467928",
						"qValue": ".895653810800791",
						"foldChange": "1.4796"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".027347713015918",
						"qValue": ".032108405861532",
						"foldChange": "1.5754"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".146000249925452",
						"qValue": ".081914788949874",
						"foldChange": "1.2047"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".667755158369036",
						"qValue": ".138706654372348",
						"foldChange": "1.1072"
					}
				]
			},
			{
				"metaboliteId": "0078dcf0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e695-0000-0000-0000-000000000000",
				"chemicalName": "1-margaroylglycerol (17:0)",
				"plotName": null,
				"compoundId": "00008657-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "SVUQHVRAGMNPLW-UHFFFAOYAY",
				"kegg": null,
				"mass": 344.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2555,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".57887839115202",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".28852000415349",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".313548248864377",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".972802650196437",
						"qValue": "1",
						"foldChange": "2.1925"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".739600528272653",
						"qValue": "1",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".714058822790657",
						"qValue": "1",
						"foldChange": "2.3990"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".113149377723947",
						"qValue": ".678406740976754",
						"foldChange": "0.8293"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".048703457302721",
						"qValue": ".678234398444917",
						"foldChange": "0.7776"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".684418136993812",
						"qValue": ".976023509428735",
						"foldChange": "1.6973"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".177934141905761",
						"qValue": "1",
						"foldChange": "2.7623"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".869095358157456",
						"qValue": "1",
						"foldChange": "0.9499"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".131869844714116",
						"qValue": ".960252848686774",
						"foldChange": "0.7523"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".206381543191695",
						"qValue": "1",
						"foldChange": "2.6532"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".385550602011796",
						"qValue": "1",
						"foldChange": "0.4929"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".036762679723732",
						"qValue": "1",
						"foldChange": "0.1858"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".855075155752014",
						"qValue": "1",
						"foldChange": "1.2080"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".709014523017647",
						"qValue": "1",
						"foldChange": "1.0967"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".576315238314267",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".841001224538489",
						"qValue": "1",
						"foldChange": "0.7119"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".465736964085028",
						"qValue": "1",
						"foldChange": "0.3861"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".57976983908572",
						"qValue": ".985830071188835",
						"foldChange": "0.5424"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".044131726012882",
						"qValue": ".045519000710629",
						"foldChange": "0.3264"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".019161330894477",
						"qValue": ".01746100957141",
						"foldChange": "0.5260"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".227757873235928",
						"qValue": ".06052558974986",
						"foldChange": "0.9189"
					}
				]
			},
			{
				"metaboliteId": "0078dcf1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e754-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoethanolamine",
				"plotName": null,
				"compoundId": "0000924f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00114",
				"inChiKey": "JZNWSCPGTDBMEW-UHFFFAOYAI",
				"kegg": "C01233",
				"mass": 215.055874,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1982,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".429241119720556",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".717770239339833",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".831923443534689",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".387593445522146",
						"qValue": "1",
						"foldChange": "1.2620"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".513361765118316",
						"qValue": "1",
						"foldChange": "0.8709"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".831906336467917",
						"qValue": "1",
						"foldChange": "1.9420"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".808712273344266",
						"qValue": "1",
						"foldChange": "1.5867"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".845375907651536",
						"qValue": "1",
						"foldChange": "1.3430"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".66226745001386",
						"qValue": ".975068512044627",
						"foldChange": "1.5604"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".468690247847543",
						"qValue": "1",
						"foldChange": "0.8236"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".81758772530108",
						"qValue": "1",
						"foldChange": "1.2320"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".340708393470572",
						"qValue": "1",
						"foldChange": "1.4990"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".574253613919681",
						"qValue": "1",
						"foldChange": "0.8255"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".745168196973252",
						"qValue": "1",
						"foldChange": "0.9568"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".3813006912961",
						"qValue": "1",
						"foldChange": "1.1589"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".572604843244035",
						"qValue": "1",
						"foldChange": "0.9957"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".675342412376134",
						"qValue": "1",
						"foldChange": "0.8579"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".906207665047887",
						"qValue": "1",
						"foldChange": "0.8616"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".936656929307097",
						"qValue": "1",
						"foldChange": "1.0959"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".232723439125667",
						"qValue": "1",
						"foldChange": "1.4493"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".194322356679843",
						"qValue": ".985830071188835",
						"foldChange": "1.3225"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000103411791886",
						"qValue": ".000494729217459",
						"foldChange": "5.7403"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".010425059479596",
						"qValue": ".010570875401204",
						"foldChange": "2.5071"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000162028399436",
						"qValue": ".000297172334425",
						"foldChange": "3.1869"
					}
				]
			},
			{
				"metaboliteId": "0078dcf2-0000-0000-0000-000000000000",
				"chemicalId": "000001e0-0000-0000-0000-000000000000",
				"chemicalName": "proline",
				"plotName": null,
				"compoundId": "0000076a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00162",
				"inChiKey": "ONIBWKKTOPOVIA-BYPYZUCNBZ",
				"kegg": "C00148",
				"mass": 115.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 426,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".928104342502052",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".17697780032706",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".503089454046945",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".668699958325341",
						"qValue": "1",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".204178929828845",
						"qValue": "1",
						"foldChange": "1.0677"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".092272545087286",
						"qValue": "1",
						"foldChange": "1.0958"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".457877672613298",
						"qValue": "1",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".955632400235624",
						"qValue": "1",
						"foldChange": "1.0080"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".491984292063133",
						"qValue": ".957839854315834",
						"foldChange": "1.0509"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".103372243911673",
						"qValue": "1",
						"foldChange": "0.9257"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".215656693263143",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".686845118305037",
						"qValue": "1",
						"foldChange": "0.9660"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".507357783784201",
						"qValue": "1",
						"foldChange": "1.0325"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".37214159745474",
						"qValue": "1",
						"foldChange": "1.0556"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".783798977194721",
						"qValue": "1",
						"foldChange": "1.0223"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".641126300854398",
						"qValue": "1",
						"foldChange": "1.0355"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".995669050422861",
						"qValue": "1",
						"foldChange": "1.0035"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".655597914671985",
						"qValue": "1",
						"foldChange": "0.9691"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".776033556257775",
						"qValue": "1",
						"foldChange": "0.9920"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".393417759313255",
						"qValue": "1",
						"foldChange": "0.8932"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".547950750032146",
						"qValue": ".985830071188835",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".658693671906962",
						"qValue": ".309928219877064",
						"foldChange": "0.9723"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".04541789081239",
						"qValue": ".034792904451889",
						"foldChange": "1.2045"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".018024489596236",
						"qValue": ".008599121402235",
						"foldChange": "1.1783"
					}
				]
			},
			{
				"metaboliteId": "0078dcf3-0000-0000-0000-000000000000",
				"chemicalId": "00000187-0000-0000-0000-000000000000",
				"chemicalName": "citrulline",
				"plotName": null,
				"compoundId": "00000854-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00904",
				"inChiKey": "RHGKLRLOHDJJDR-UHFFFAOYAQ",
				"kegg": "C00327",
				"mass": 175.095692,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 423,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".29060614610392",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".713818650616761",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".533105765340057",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".632756930357082",
						"qValue": "1",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".607319055072349",
						"qValue": "1",
						"foldChange": "1.0435"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".971127818469671",
						"qValue": "1",
						"foldChange": "1.0160"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".60037621082052",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".864843422577639",
						"qValue": "1",
						"foldChange": "1.0007"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".723230681642609",
						"qValue": ".976023509428735",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".826761928400032",
						"qValue": "1",
						"foldChange": "1.0214"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".170691974664347",
						"qValue": "1",
						"foldChange": "0.9331"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".113762602613264",
						"qValue": ".934715307502823",
						"foldChange": "0.8747"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".218650918725868",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".08145304212686",
						"qValue": "1",
						"foldChange": "1.1204"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".543252268019275",
						"qValue": "1",
						"foldChange": "1.0466"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".676762072248911",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".120380205877234",
						"qValue": "1",
						"foldChange": "1.1187"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".231653574784247",
						"qValue": "1",
						"foldChange": "1.0931"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".503996848504715",
						"qValue": "1",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".850238327176039",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".649365439036627",
						"qValue": ".985830071188835",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".01661649874673",
						"qValue": ".021745321672421",
						"foldChange": "1.2650"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000376541678558",
						"qValue": ".001215365026073",
						"foldChange": "1.4359"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000462199115248",
						"qValue": ".000594483906934",
						"foldChange": "1.4231"
					}
				]
			},
			{
				"metaboliteId": "0078dcf4-0000-0000-0000-000000000000",
				"chemicalId": "00000474-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglutamine",
				"plotName": null,
				"compoundId": "00000aaa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11738",
				"inChiKey": "JBFYFLXEJFQWMU-WDSKDSINBP",
				"kegg": "C05283",
				"mass": 275.111737,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 551,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".679272956461804",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".868103427899038",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".855557904630993",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".965802481637712",
						"qValue": "1",
						"foldChange": "1.0070"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".697847249190197",
						"qValue": "1",
						"foldChange": "1.0296"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".666476695586043",
						"qValue": "1",
						"foldChange": "1.0425"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".750788181932954",
						"qValue": "1",
						"foldChange": "1.0436"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".430795089304844",
						"qValue": ".894260227315856",
						"foldChange": "0.9872"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".270842229157207",
						"qValue": ".957839854315834",
						"foldChange": "0.9442"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".755077275038047",
						"qValue": "1",
						"foldChange": "1.0429"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".967879876018047",
						"qValue": "1",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".785823545037291",
						"qValue": "1",
						"foldChange": "0.9291"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".652280641953062",
						"qValue": "1",
						"foldChange": "0.9900"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".92616043693429",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".595407107222738",
						"qValue": "1",
						"foldChange": "1.0239"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".80992132930822",
						"qValue": "1",
						"foldChange": "0.9911"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".757545181263056",
						"qValue": "1",
						"foldChange": "1.0383"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".583388729269887",
						"qValue": "1",
						"foldChange": "1.0476"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".255202461132984",
						"qValue": "1",
						"foldChange": "0.8949"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".914567364439631",
						"qValue": "1",
						"foldChange": "0.9302"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".319694219103339",
						"qValue": ".985830071188835",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".885323001694646",
						"qValue": ".367756143534571",
						"foldChange": "1.0177"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".61766781113557",
						"qValue": ".24094927999131",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".136972533208627",
						"qValue": ".041550726710877",
						"foldChange": "1.1465"
					}
				]
			},
			{
				"metaboliteId": "0078dcf5-0000-0000-0000-000000000000",
				"chemicalId": "0000031f-0000-0000-0000-000000000000",
				"chemicalName": "betaine",
				"plotName": null,
				"compoundId": "00000c45-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00043",
				"inChiKey": "KWIUHFFTVRNATP-UHFFFAOYAI",
				"kegg": "C00719",
				"mass": 117.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 6,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".250094136454836",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".851445826711413",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".246817942576051",
						"qValue": ".959096206083192",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".315293848561774",
						"qValue": "1",
						"foldChange": "0.9628"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".253781972414294",
						"qValue": "1",
						"foldChange": "1.0554"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".034998483723433",
						"qValue": "1",
						"foldChange": "1.1029"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".713833927756118",
						"qValue": "1",
						"foldChange": "1.0344"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".541055014561124",
						"qValue": ".910963185640849",
						"foldChange": "0.9903"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".329803938477475",
						"qValue": ".957839854315834",
						"foldChange": "0.9684"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".906958744398109",
						"qValue": "1",
						"foldChange": "0.9986"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".672424612251643",
						"qValue": "1",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".759463454442864",
						"qValue": "1",
						"foldChange": "0.9381"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".190453579691485",
						"qValue": "1",
						"foldChange": "0.9023"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".247048726001043",
						"qValue": "1",
						"foldChange": "0.8994"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".930828941888357",
						"qValue": "1",
						"foldChange": "0.9968"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".534667116530194",
						"qValue": "1",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".463921867022282",
						"qValue": "1",
						"foldChange": "0.9344"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".880696973822941",
						"qValue": "1",
						"foldChange": "0.9669"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".031799509116794",
						"qValue": "1",
						"foldChange": "0.8425"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".05743457687042",
						"qValue": "1",
						"foldChange": "0.7962"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".878342274183368",
						"qValue": ".997573179629986",
						"foldChange": "0.9450"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".311900836927973",
						"qValue": ".183122279991681",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".157105076030561",
						"qValue": ".086921014578966",
						"foldChange": "1.1549"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00190376463709",
						"qValue": ".001663475611934",
						"foldChange": "1.2799"
					}
				]
			},
			{
				"metaboliteId": "0078dcf6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e2-0000-0000-0000-000000000000",
				"chemicalName": "tauro-beta-muricholate",
				"plotName": null,
				"compoundId": "000084bf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00932",
				"inChiKey": "XSOLDPYUICCHJX-OEYGYFRSBZ",
				"kegg": null,
				"mass": 515.291673,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3100,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".806581336320111",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".665853854768671",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".455081176127629",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".700503362880267",
						"qValue": "1",
						"foldChange": "1.1670"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".457495856164122",
						"qValue": "1",
						"foldChange": "1.5077"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".718883704400011",
						"qValue": "1",
						"foldChange": "1.4216"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".080009353504946",
						"qValue": ".613951105145249",
						"foldChange": "0.8205"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".095173103086611",
						"qValue": ".697458782960496",
						"foldChange": "0.8592"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".931652948826005",
						"qValue": "1",
						"foldChange": "1.0753"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".8039330451754",
						"qValue": "1",
						"foldChange": "1.4433"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".971789949136646",
						"qValue": "1",
						"foldChange": "1.1318"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".776728841150735",
						"qValue": "1",
						"foldChange": "1.8681"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".2328398982378",
						"qValue": "1",
						"foldChange": "3.5444"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".841827463377121",
						"qValue": "1",
						"foldChange": "0.6705"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".338366328826034",
						"qValue": "1",
						"foldChange": "0.1892"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".799655928945868",
						"qValue": "1",
						"foldChange": "1.3933"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".934491469777187",
						"qValue": "1",
						"foldChange": "0.5964"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".741491600377591",
						"qValue": "1",
						"foldChange": "0.4281"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".899944317163638",
						"qValue": "1",
						"foldChange": "1.4745"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".914028857304189",
						"qValue": "1",
						"foldChange": "0.8935"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".816300074582931",
						"qValue": ".997573179629986",
						"foldChange": "0.6060"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".050747562199769",
						"qValue": ".050027824449754",
						"foldChange": "0.3567"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".768082866232738",
						"qValue": ".282094648105086",
						"foldChange": "1.0707"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".369094797007594",
						"qValue": ".087653701428536",
						"foldChange": "0.5964"
					}
				]
			},
			{
				"metaboliteId": "0078dcf7-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9d0-0000-0000-0000-000000000000",
				"chemicalName": "2-docosahexaenoylglycerol (22:6)*",
				"plotName": null,
				"compoundId": "0000be23-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11557",
				"inChiKey": "NHNGEAQTTQPSCX-KUBAVDMBBH",
				"kegg": null,
				"mass": 402.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2576,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".585906915611836",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".524071824372727",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".770173706339889",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".173097114785431",
						"qValue": "1",
						"foldChange": "4.2705"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".801374660878961",
						"qValue": "1",
						"foldChange": "1.1588"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".264121512186899",
						"qValue": "1",
						"foldChange": "2.2782"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".654655985995722",
						"qValue": "1",
						"foldChange": "1.2329"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".493354859261887",
						"qValue": ".902562957068497",
						"foldChange": "1.6537"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".811238178857308",
						"qValue": "1",
						"foldChange": "1.4972"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".965023934603807",
						"qValue": "1",
						"foldChange": "1.2404"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".573641286404395",
						"qValue": "1",
						"foldChange": "1.0126"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".544307687106863",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".905701125932564",
						"qValue": "1",
						"foldChange": "0.6309"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".968390027718874",
						"qValue": "1",
						"foldChange": "0.6661"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".940884644400196",
						"qValue": "1",
						"foldChange": "1.0557"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".311748432987052",
						"qValue": "1",
						"foldChange": "0.3881"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".223059832236258",
						"qValue": "1",
						"foldChange": "0.3650"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".78610150061403",
						"qValue": "1",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".795987897823765",
						"qValue": "1",
						"foldChange": "0.7992"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".426382440613075",
						"qValue": "1",
						"foldChange": "0.4346"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".287884116565224",
						"qValue": ".985830071188835",
						"foldChange": "0.5438"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00299599633049",
						"qValue": ".006104150629979",
						"foldChange": "0.2492"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".069721226367052",
						"qValue": ".047650774141024",
						"foldChange": "0.5702"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".224294966400152",
						"qValue": ".060102049605585",
						"foldChange": "0.8184"
					}
				]
			},
			{
				"metaboliteId": "0078dcf8-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8e4-0000-0000-0000-000000000000",
				"chemicalName": "methyl-4-hydroxybenzoate sulfate",
				"plotName": null,
				"compoundId": "0000bd2d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 232.004159,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4125,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".049967970729449",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".627596855992039",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".646961869410756",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".681472077175093",
						"qValue": "1",
						"foldChange": "0.9712"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".154012932369161",
						"qValue": "1",
						"foldChange": "1.5860"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".305664542649922",
						"qValue": "1",
						"foldChange": "1.3765"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".713807691954905",
						"qValue": "1",
						"foldChange": "2.2040"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".919421669178558",
						"qValue": "1",
						"foldChange": "2.2012"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".640044711163803",
						"qValue": ".973034272612281",
						"foldChange": "2.1651"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".447520161851738",
						"qValue": "1",
						"foldChange": "3.0519"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".937363826149678",
						"qValue": "1",
						"foldChange": "1.1954"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".402392535313348",
						"qValue": "1",
						"foldChange": "0.9251"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".051699961525981",
						"qValue": "1",
						"foldChange": "0.2894"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".985325986657191",
						"qValue": "1",
						"foldChange": "1.1937"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".056783089078396",
						"qValue": "1",
						"foldChange": "4.1243"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".056037417223211",
						"qValue": "1",
						"foldChange": "0.3463"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".479999669542831",
						"qValue": "1",
						"foldChange": "1.1852"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".01193354338106",
						"qValue": ".677029694485477",
						"foldChange": "3.4220"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".286176239698097",
						"qValue": "1",
						"foldChange": "0.5909"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".43307685862816",
						"qValue": "1",
						"foldChange": "1.2456"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".070645555817263",
						"qValue": ".985830071188835",
						"foldChange": "2.1079"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".074926338816742",
						"qValue": ".066588562075421",
						"foldChange": "2.6757"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".145435986582393",
						"qValue": ".081762716296306",
						"foldChange": "3.6741"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001479957646166",
						"qValue": ".00135194064415",
						"foldChange": "18.6035"
					}
				]
			},
			{
				"metaboliteId": "0078dcf9-0000-0000-0000-000000000000",
				"chemicalId": "05f5f928-0000-0000-0000-000000000000",
				"chemicalName": "2-methoxyresorcinol sulfate",
				"plotName": null,
				"compoundId": "0000bd3d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 220.004159,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5353,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".761268366521947",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".997843976829386",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".330281390474106",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".107420939748284",
						"qValue": "1",
						"foldChange": "2.1457"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".30364207791332",
						"qValue": "1",
						"foldChange": "1.4057"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".550808811696327",
						"qValue": "1",
						"foldChange": "0.9632"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".733149910435278",
						"qValue": "1",
						"foldChange": "1.1728"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".496812081778137",
						"qValue": ".902562957068497",
						"foldChange": "0.9170"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".733997410353076",
						"qValue": ".976023509428735",
						"foldChange": "1.5644"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".246562655179684",
						"qValue": "1",
						"foldChange": "1.0619"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".794958489113597",
						"qValue": "1",
						"foldChange": "1.2690"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".366468769093736",
						"qValue": "1",
						"foldChange": "1.5111"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".407649070797836",
						"qValue": "1",
						"foldChange": "0.8037"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".762495678198449",
						"qValue": "1",
						"foldChange": "0.7205"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".620563220143665",
						"qValue": "1",
						"foldChange": "0.8964"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".706893220079268",
						"qValue": "1",
						"foldChange": "0.5762"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".174541318871759",
						"qValue": "1",
						"foldChange": "0.2623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".30036039211708",
						"qValue": "1",
						"foldChange": "0.4552"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".836486793172741",
						"qValue": "1",
						"foldChange": "0.7515"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".643927382659859",
						"qValue": "1",
						"foldChange": "0.5571"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".784061135906676",
						"qValue": ".997573179629986",
						"foldChange": "0.7414"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".757313470621199",
						"qValue": ".334795447198846",
						"foldChange": "1.1450"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".932736620185433",
						"qValue": ".321893919713879",
						"foldChange": "1.0104"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".248479273441904",
						"qValue": ".065170446978678",
						"foldChange": "2.4494"
					}
				]
			},
			{
				"metaboliteId": "0078dcfa-0000-0000-0000-000000000000",
				"chemicalId": "05f5f978-0000-0000-0000-000000000000",
				"chemicalName": "propyl 4-hydroxybenzoate sulfate",
				"plotName": null,
				"compoundId": "0000bd4c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 260.035459,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4127,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".028360325451467",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".274552755486454",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".851826653984545",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".869014542649891",
						"qValue": "1",
						"foldChange": "1.7805"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".210738660438082",
						"qValue": "1",
						"foldChange": "0.9190"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".275655378241719",
						"qValue": "1",
						"foldChange": "0.8854"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".268008575311782",
						"qValue": ".901656639749634",
						"foldChange": "5.6777"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".813171651282875",
						"qValue": ".991417013240296",
						"foldChange": "5.0473"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".381626993101841",
						"qValue": ".957839854315834",
						"foldChange": "2.0874"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".616768972782238",
						"qValue": "1",
						"foldChange": "3.4304"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".735893717435246",
						"qValue": "1",
						"foldChange": "1.0709"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".403426083320783",
						"qValue": "1",
						"foldChange": "1.1548"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".010508833910338",
						"qValue": "1",
						"foldChange": "0.0984"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".833874686384866",
						"qValue": "1",
						"foldChange": "0.9616"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".021905221856512",
						"qValue": "1",
						"foldChange": "9.7727"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".059737470294568",
						"qValue": "1",
						"foldChange": "0.1920"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".848615262350366",
						"qValue": "1",
						"foldChange": "1.0189"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".044603103893497",
						"qValue": ".822195137696456",
						"foldChange": "5.3065"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".083252239477388",
						"qValue": "1",
						"foldChange": "0.2930"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".756453841529148",
						"qValue": "1",
						"foldChange": "1.2109"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".047957247692891",
						"qValue": ".985830071188835",
						"foldChange": "4.1330"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".256048716597041",
						"qValue": ".160255804897312",
						"foldChange": "4.1057"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".155791599646165",
						"qValue": ".086365672231395",
						"foldChange": "7.3305"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00919914420215",
						"qValue": ".005092602293408",
						"foldChange": "34.8865"
					}
				]
			},
			{
				"metaboliteId": "0078dcfb-0000-0000-0000-000000000000",
				"chemicalId": "00000231-0000-0000-0000-000000000000",
				"chemicalName": "glutamate",
				"plotName": null,
				"compoundId": "00000039-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00148",
				"inChiKey": "WHUUTDBJXJRKMK-UHFFFAOYAD",
				"kegg": "C00025",
				"mass": 147.053159,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 42,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".570878913503639",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".987286017673247",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".812212384485837",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".801983966524255",
						"qValue": "1",
						"foldChange": "1.1178"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".567051168038473",
						"qValue": "1",
						"foldChange": "0.9554"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".411458178245552",
						"qValue": "1",
						"foldChange": "0.9697"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".838716370364826",
						"qValue": "1",
						"foldChange": "1.0593"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".861289961371693",
						"qValue": "1",
						"foldChange": "1.0328"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".705388783741468",
						"qValue": ".976023509428735",
						"foldChange": "1.0218"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".778259533161037",
						"qValue": "1",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".567366992506457",
						"qValue": "1",
						"foldChange": "1.1796"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".394563436960261",
						"qValue": "1",
						"foldChange": "1.1369"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".870891068508941",
						"qValue": "1",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".519916372697787",
						"qValue": "1",
						"foldChange": "1.0995"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".415160623358061",
						"qValue": "1",
						"foldChange": "1.1724"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".84404798376336",
						"qValue": "1",
						"foldChange": "0.9748"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".736143006570486",
						"qValue": "1",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".592630231965796",
						"qValue": "1",
						"foldChange": "1.0067"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".937032519576902",
						"qValue": "1",
						"foldChange": "1.0197"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".196126066000713",
						"qValue": "1",
						"foldChange": "1.2655"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".211506290677156",
						"qValue": ".985830071188835",
						"foldChange": "1.2410"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006868875972802",
						"qValue": ".011547347761294",
						"foldChange": "2.1730"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".052668082040684",
						"qValue": ".038471747600022",
						"foldChange": "1.5385"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".165457621634769",
						"qValue": ".048063078602301",
						"foldChange": "1.3691"
					}
				]
			},
			{
				"metaboliteId": "0078dcfc-0000-0000-0000-000000000000",
				"chemicalId": "00000350-0000-0000-0000-000000000000",
				"chemicalName": "cotinine",
				"plotName": null,
				"compoundId": "00000229-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01046",
				"inChiKey": "UIKROCXWUNQSPJ-VIFPVBQEBC",
				"kegg": null,
				"mass": 176.094963,
				"pathwayType": "ANIMAL",
				"subPathway": "Tobacco Metabolite",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4171,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".496703524550463",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".289036999037765",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".32034720871523",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".943146172564569",
						"qValue": "1",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".932152101390768",
						"qValue": "1",
						"foldChange": "1.0074"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".988971447186056",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".112683275706698",
						"qValue": "1",
						"foldChange": "1.2681"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".334639832326094",
						"qValue": "1",
						"foldChange": "0.9376"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".01265578845782",
						"qValue": ".566846104084484",
						"foldChange": "0.9066"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".250872122621425",
						"qValue": "1",
						"foldChange": "0.0352"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".473985248566231",
						"qValue": "1",
						"foldChange": "0.0556"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".696912682219646",
						"qValue": "1",
						"foldChange": "1.5783"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".246473073224389",
						"qValue": "1",
						"foldChange": "0.0302"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".63358998489352",
						"qValue": "1",
						"foldChange": "0.0862"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".518248885781059",
						"qValue": "1",
						"foldChange": "2.8546"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".24562713547163",
						"qValue": "1",
						"foldChange": "0.0299"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".380075925274534",
						"qValue": "1",
						"foldChange": "0.0359"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".815051603897405",
						"qValue": ".997573179629986",
						"foldChange": "1.2021"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.2021"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".504760146853632",
						"qValue": ".111935555055246",
						"foldChange": "24.5369"
					}
				]
			},
			{
				"metaboliteId": "0078dcfd-0000-0000-0000-000000000000",
				"chemicalId": "00000234-0000-0000-0000-000000000000",
				"chemicalName": "threonine",
				"plotName": null,
				"compoundId": "00000504-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00167",
				"inChiKey": "AYFVYJQAPQTCCC-GBXIJSLDBG",
				"kegg": "C00188",
				"mass": 119.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 16,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".467704944270933",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".288894861367324",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".976812446612254",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".507780664482604",
						"qValue": "1",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".746228175125466",
						"qValue": "1",
						"foldChange": "0.9903"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".733988079544008",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".464706088470982",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".267726699913286",
						"qValue": ".825038702902081",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".702436864841347",
						"qValue": ".976023509428735",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".361881536118295",
						"qValue": "1",
						"foldChange": "0.9465"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".316888322637298",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".927987739629235",
						"qValue": "1",
						"foldChange": "0.9473"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".224691118731574",
						"qValue": "1",
						"foldChange": "1.1187"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".360551730125319",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".80591431590677",
						"qValue": "1",
						"foldChange": "0.9477"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".231772708568245",
						"qValue": "1",
						"foldChange": "1.1659"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".457088533263757",
						"qValue": "1",
						"foldChange": "1.0623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".685988617730123",
						"qValue": "1",
						"foldChange": "0.9111"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".431965338220666",
						"qValue": "1",
						"foldChange": "1.1155"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".616862274284022",
						"qValue": "1",
						"foldChange": "0.9996"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".801933967946512",
						"qValue": ".997573179629986",
						"foldChange": "0.8961"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".715043624204251",
						"qValue": ".323863496327166",
						"foldChange": "1.1147"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".051051735822207",
						"qValue": ".037860624605923",
						"foldChange": "1.2507"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".178138623411705",
						"qValue": ".051135315117907",
						"foldChange": "1.0781"
					}
				]
			},
			{
				"metaboliteId": "0078dcfe-0000-0000-0000-000000000000",
				"chemicalId": "00000402-0000-0000-0000-000000000000",
				"chemicalName": "phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000640-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00224",
				"inChiKey": "SUHOOTKUPISOBE-UHFFFAOYAT",
				"kegg": "C00346",
				"mass": 141.019097,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1980,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".797109482516847",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".914205275349792",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".932698166210919",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".481760958614564",
						"qValue": "1",
						"foldChange": "3.0984"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".434316188698197",
						"qValue": "1",
						"foldChange": "0.8314"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".937004076977067",
						"qValue": "1",
						"foldChange": "5.6773"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".746013931970591",
						"qValue": "1",
						"foldChange": "1.9406"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".961826537693428",
						"qValue": "1",
						"foldChange": "2.2122"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".782466995158529",
						"qValue": ".991621675660644",
						"foldChange": "1.3487"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".816118355060619",
						"qValue": "1",
						"foldChange": "1.0137"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".914839701539887",
						"qValue": "1",
						"foldChange": "1.3824"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".734350965487196",
						"qValue": "1",
						"foldChange": "1.4864"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".595745861575787",
						"qValue": "1",
						"foldChange": "0.7295"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".90708533866245",
						"qValue": "1",
						"foldChange": "0.7503"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".693122502551595",
						"qValue": "1",
						"foldChange": "1.0285"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".575005617580834",
						"qValue": "1",
						"foldChange": "0.7642"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".725699001398689",
						"qValue": "1",
						"foldChange": "0.6823"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".85391003705263",
						"qValue": "1",
						"foldChange": "0.8928"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".716037431172336",
						"qValue": "1",
						"foldChange": "1.1592"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".425373486168622",
						"qValue": "1",
						"foldChange": "1.2531"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".641536286707985",
						"qValue": ".985830071188835",
						"foldChange": "1.0810"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000060001383677",
						"qValue": ".000348524448355",
						"foldChange": "6.0118"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".120795109443407",
						"qValue": ".070763206689846",
						"foldChange": "1.6799"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".053047693241576",
						"qValue": ".019973751122931",
						"foldChange": "2.3904"
					}
				]
			},
			{
				"metaboliteId": "0078dcff-0000-0000-0000-000000000000",
				"chemicalId": "00000154-0000-0000-0000-000000000000",
				"chemicalName": "glycine",
				"plotName": null,
				"compoundId": "0000003a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00123",
				"inChiKey": "DHMQDGOQFOQNFH-UHFFFAOYAW",
				"kegg": "C00037",
				"mass": 75.032029,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 1,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".97037370050193",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".373240567145526",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".260240843380005",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".307709786365332",
						"qValue": "1",
						"foldChange": "0.9452"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".471402424691221",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".761640003850704",
						"qValue": "1",
						"foldChange": "1.0245"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".589105102605571",
						"qValue": "1",
						"foldChange": "0.9810"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".463376336066249",
						"qValue": ".902562957068497",
						"foldChange": "0.9691"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".845882872738645",
						"qValue": "1",
						"foldChange": "0.9951"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".090405472700072",
						"qValue": ".938232405704401",
						"foldChange": "1.1758"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".605194676611566",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".029175937295193",
						"qValue": ".614826369149758",
						"foldChange": "0.8566"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".930269848002089",
						"qValue": "1",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".73013346669446",
						"qValue": "1",
						"foldChange": "0.9355"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".788120045066145",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".839025133233878",
						"qValue": "1",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".241240802914314",
						"qValue": "1",
						"foldChange": "1.2194"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".313454231926759",
						"qValue": "1",
						"foldChange": "1.2076"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".941334758066814",
						"qValue": "1",
						"foldChange": "0.9913"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".800833714959761",
						"qValue": "1",
						"foldChange": "0.9121"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".851501701306584",
						"qValue": ".997573179629986",
						"foldChange": "0.9200"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".203304925784211",
						"qValue": ".135437319802808",
						"foldChange": "1.1510"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".190165301799821",
						"qValue": ".09971470252619",
						"foldChange": "1.1489"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".042235527452656",
						"qValue": ".016441781642796",
						"foldChange": "1.2521"
					}
				]
			},
			{
				"metaboliteId": "0078dd00-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed4f-0000-0000-0000-000000000000",
				"chemicalName": "linoleoylcarnitine (C18:2)*",
				"plotName": null,
				"compoundId": "0000b48f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06469",
				"inChiKey": "MJLXQSQYKZWZCB-DQFWFXSYBW",
				"kegg": null,
				"mass": 423.334859,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1672,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".14931015759786",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".156015772390469",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".231946889105467",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".371204403022802",
						"qValue": "1",
						"foldChange": "1.1032"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".417222575268348",
						"qValue": "1",
						"foldChange": "1.0771"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".933116845255451",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".806310172230369",
						"qValue": "1",
						"foldChange": "1.0431"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".6316211311208",
						"qValue": ".928868848501546",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".469495750414209",
						"qValue": ".957839854315834",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".893881669586621",
						"qValue": "1",
						"foldChange": "1.0246"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".024311153413182",
						"qValue": ".884352967171025",
						"foldChange": "0.8508"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".017557457236474",
						"qValue": ".597655844329562",
						"foldChange": "0.8035"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".150473462682909",
						"qValue": "1",
						"foldChange": "0.8098"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".839372662730239",
						"qValue": "1",
						"foldChange": "0.9567"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".232643005348622",
						"qValue": "1",
						"foldChange": "1.1813"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".074554401889073",
						"qValue": "1",
						"foldChange": "0.7396"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".552743177487882",
						"qValue": "1",
						"foldChange": "0.8670"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".251580915884425",
						"qValue": "1",
						"foldChange": "1.1723"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".036687071809517",
						"qValue": "1",
						"foldChange": "0.7093"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".064041951583571",
						"qValue": "1",
						"foldChange": "0.7136"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".885641895341982",
						"qValue": ".997573179629986",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".330797905157238",
						"qValue": ".191475653452938",
						"foldChange": "1.2117"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".140876384228888",
						"qValue": ".080005862308231",
						"foldChange": "1.1576"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001431206149773",
						"qValue": ".001335272490554",
						"foldChange": "1.7656"
					}
				]
			},
			{
				"metaboliteId": "0078dd01-0000-0000-0000-000000000000",
				"chemicalId": "05f5e70f-0000-0000-0000-000000000000",
				"chemicalName": "1-arachidonoyl-GPC (20:4n6)*",
				"plotName": null,
				"compoundId": "000081cc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10395",
				"inChiKey": "LAXQYRRMGGEGOH-JXRLJXCWBN",
				"kegg": "C05208",
				"mass": 543.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2349,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".239750501667701",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".568209030935795",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".034315080252617",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".395619384879584",
						"qValue": "1",
						"foldChange": "1.1119"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".036702273232156",
						"qValue": "1",
						"foldChange": "1.2357"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".202580131712118",
						"qValue": "1",
						"foldChange": "1.1424"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".024201121791008",
						"qValue": ".359575885726771",
						"foldChange": "0.8750"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".110457558126742",
						"qValue": ".697458782960496",
						"foldChange": "0.9164"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".486481937105013",
						"qValue": ".957839854315834",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".634134715222783",
						"qValue": "1",
						"foldChange": "0.9696"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".139083159052707",
						"qValue": "1",
						"foldChange": "0.8777"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".310396267316949",
						"qValue": "1",
						"foldChange": "0.8623"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".620144785055973",
						"qValue": "1",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".153980277186603",
						"qValue": "1",
						"foldChange": "1.1711"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".321734556761749",
						"qValue": "1",
						"foldChange": "1.1267"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".111616818250816",
						"qValue": "1",
						"foldChange": "0.8036"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".580684401446094",
						"qValue": "1",
						"foldChange": "1.0584"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".037639121573741",
						"qValue": ".822195137696456",
						"foldChange": "1.3171"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".04554965006283",
						"qValue": "1",
						"foldChange": "0.7999"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".322192259676236",
						"qValue": "1",
						"foldChange": "0.8395"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".338914254161883",
						"qValue": ".985830071188835",
						"foldChange": "1.0494"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".152882842262255",
						"qValue": ".11124850666233",
						"foldChange": "1.3841"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".342664119805844",
						"qValue": ".152009550549949",
						"foldChange": "1.1815"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000231800398367",
						"qValue": ".00037312275394",
						"foldChange": "1.8941"
					}
				]
			},
			{
				"metaboliteId": "0078dd02-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e3-0000-0000-0000-000000000000",
				"chemicalName": "decanoylcarnitine (C10)",
				"plotName": null,
				"compoundId": "00008495-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00651",
				"inChiKey": "LZOSYCMHQXPBFU-UHFFFAOYAC",
				"kegg": null,
				"mass": 315.240959,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1644,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".314742465374617",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".744674169839221",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".107687028649416",
						"qValue": ".818229119470115",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".625469130335516",
						"qValue": "1",
						"foldChange": "1.2241"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".092996005347193",
						"qValue": "1",
						"foldChange": "1.3856"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".227435917149727",
						"qValue": "1",
						"foldChange": "1.4190"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".701892920993893",
						"qValue": "1",
						"foldChange": "1.0524"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".735147485156948",
						"qValue": ".957498790731047",
						"foldChange": "1.2264"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".964449190640169",
						"qValue": "1",
						"foldChange": "1.2424"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".325004200549779",
						"qValue": "1",
						"foldChange": "1.3161"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".209614820654297",
						"qValue": "1",
						"foldChange": "0.8199"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".027992341373399",
						"qValue": ".614826369149758",
						"foldChange": "0.6884"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".96059469594903",
						"qValue": "1",
						"foldChange": "1.1249"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".245036757099456",
						"qValue": "1",
						"foldChange": "1.6076"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".216104303590073",
						"qValue": "1",
						"foldChange": "1.4292"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".552064024883267",
						"qValue": "1",
						"foldChange": "0.8659"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".135800336051813",
						"qValue": "1",
						"foldChange": "1.6593"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".038373028515342",
						"qValue": ".822195137696456",
						"foldChange": "1.9162"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".182100164142997",
						"qValue": "1",
						"foldChange": "0.7534"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".500415655953417",
						"qValue": "1",
						"foldChange": "0.9142"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".539841152108467",
						"qValue": ".985830071188835",
						"foldChange": "1.2134"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".793598475256893",
						"qValue": ".344316995840098",
						"foldChange": "1.2302"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".132868571461528",
						"qValue": ".076391604744854",
						"foldChange": "1.6094"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000744020313626",
						"qValue": ".000860579503948",
						"foldChange": "2.0202"
					}
				]
			},
			{
				"metaboliteId": "0078dd03-0000-0000-0000-000000000000",
				"chemicalId": "05f5e566-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylvaline",
				"plotName": null,
				"compoundId": "0000ab35-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11172",
				"inChiKey": "SITLTJHOQZFJGG-UUEFVBAFBQ",
				"kegg": null,
				"mass": 246.121572,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 563,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".445039813040504",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".187753746296155",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".920533507568087",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".578878675742259",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".421818063570489",
						"qValue": "1",
						"foldChange": "1.0885"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".802589792736031",
						"qValue": "1",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".162842972717056",
						"qValue": ".745050375173196",
						"foldChange": "1.1312"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".110865821868813",
						"qValue": ".697458782960496",
						"foldChange": "1.1456"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".83693084803432",
						"qValue": "1",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".943135625265064",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".423058967885224",
						"qValue": "1",
						"foldChange": "1.0897"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".465114693863806",
						"qValue": "1",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".295940108947435",
						"qValue": "1",
						"foldChange": "0.8361"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".661884189327211",
						"qValue": "1",
						"foldChange": "1.0088"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".147646243445018",
						"qValue": "1",
						"foldChange": "1.2066"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".55380694659767",
						"qValue": "1",
						"foldChange": "0.9505"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".864258913732218",
						"qValue": "1",
						"foldChange": "0.9835"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".454935524672413",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".53261498193404",
						"qValue": "1",
						"foldChange": "0.9226"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".643292449522102",
						"qValue": "1",
						"foldChange": "1.0025"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".283355161872698",
						"qValue": ".985830071188835",
						"foldChange": "1.0866"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".077574022197049",
						"qValue": ".068490743523911",
						"foldChange": "1.4989"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003854858018377",
						"qValue": ".00548425224393",
						"foldChange": "1.3282"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".019603672686793",
						"qValue": ".009215785169932",
						"foldChange": "1.2791"
					}
				]
			},
			{
				"metaboliteId": "0078dd04-0000-0000-0000-000000000000",
				"chemicalId": "05f5e621-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylmethionine",
				"plotName": null,
				"compoundId": "0000af48-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29155",
				"inChiKey": "RQNSKRXMANOPQY-BQBZGAKWBR",
				"kegg": null,
				"mass": 278.093642,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 558,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".661716240522037",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".697171129099749",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".526870989380303",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".480787748557682",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".929512863650604",
						"qValue": "1",
						"foldChange": "1.0174"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".427941895082214",
						"qValue": "1",
						"foldChange": "1.1672"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".573445157966696",
						"qValue": "1",
						"foldChange": "0.9911"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".081525972361855",
						"qValue": ".697458782960496",
						"foldChange": "0.8859"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".23128842835832",
						"qValue": ".957839854315834",
						"foldChange": "0.9192"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".756396201248506",
						"qValue": "1",
						"foldChange": "1.1013"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".901901457972219",
						"qValue": "1",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".851606034289522",
						"qValue": "1",
						"foldChange": "0.9534"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".571672236306683",
						"qValue": "1",
						"foldChange": "1.0612"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".674434959972816",
						"qValue": "1",
						"foldChange": "1.0650"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".906061714927483",
						"qValue": "1",
						"foldChange": "1.0037"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".484997546107874",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".240674094121755",
						"qValue": "1",
						"foldChange": "1.1335"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".595287127393622",
						"qValue": "1",
						"foldChange": "1.1019"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".421692263157752",
						"qValue": "1",
						"foldChange": "0.8872"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".651968658360589",
						"qValue": "1",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".217149800634251",
						"qValue": ".985830071188835",
						"foldChange": "1.0896"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".304792630360959",
						"qValue": ".181174096921116",
						"foldChange": "1.2841"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".080579990793246",
						"qValue": ".05259844224847",
						"foldChange": "1.1923"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".238935615579717",
						"qValue": ".062975563306442",
						"foldChange": "1.1508"
					}
				]
			},
			{
				"metaboliteId": "0078dd05-0000-0000-0000-000000000000",
				"chemicalId": "000000d1-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 5'-monophosphate (AMP)",
				"plotName": null,
				"compoundId": "00007e56-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00045",
				"inChiKey": "UDMBCSSLTHHNCD-RWLQFSFNBF",
				"kegg": "C00020",
				"mass": 347.063088,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3761,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".792081469802163",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".853531476668557",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".956612188793154",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".736542584705303",
						"qValue": "1",
						"foldChange": "28.9689"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".73807560257964",
						"qValue": "1",
						"foldChange": "0.9488"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".998375396531794",
						"qValue": "1",
						"foldChange": "15.9217"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".703289542160462",
						"qValue": "1",
						"foldChange": "4.0796"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".584736067774394",
						"qValue": ".928868848501546",
						"foldChange": "8.5052"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".355305204686667",
						"qValue": ".957839854315834",
						"foldChange": "1.3285"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".986632209743092",
						"qValue": "1",
						"foldChange": "1.4714"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".976432374332294",
						"qValue": "1",
						"foldChange": "1.8560"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".989797560419069",
						"qValue": "1",
						"foldChange": "1.6876"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".935805154203968",
						"qValue": "1",
						"foldChange": "0.9000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".930537236660886",
						"qValue": "1",
						"foldChange": "0.6738"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".991001557282102",
						"qValue": "1",
						"foldChange": "0.7486"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".405320690675042",
						"qValue": "1",
						"foldChange": "0.6991"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".65374360339336",
						"qValue": "1",
						"foldChange": "0.5955"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".727788926920395",
						"qValue": "1",
						"foldChange": "0.8518"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".90546819632787",
						"qValue": "1",
						"foldChange": "1.3133"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".645296448127866",
						"qValue": "1",
						"foldChange": "1.3395"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".557733856401841",
						"qValue": ".985830071188835",
						"foldChange": "1.0199"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000045397611885",
						"qValue": ".000320994653109",
						"foldChange": "45.8424"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".225749769095749",
						"qValue": ".113422558049124",
						"foldChange": "0.7318"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005692926225876",
						"qValue": ".003575333813494",
						"foldChange": "4.4859"
					}
				]
			},
			{
				"metaboliteId": "0078dd06-0000-0000-0000-000000000000",
				"chemicalId": "05f5e70e-0000-0000-0000-000000000000",
				"chemicalName": "homostachydrine*",
				"plotName": null,
				"compoundId": "000080f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33433",
				"inChiKey": "XULZWQRXYTVUTE-ZETCQYMHBF",
				"kegg": "C08283",
				"mass": 157.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4440,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".14002237244611",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".830441768884997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".699084717042057",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".965241444348615",
						"qValue": "1",
						"foldChange": "1.0664"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".561618880530793",
						"qValue": "1",
						"foldChange": "1.2461"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".591215834762225",
						"qValue": "1",
						"foldChange": "1.3387"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".374159354717956",
						"qValue": ".957150284015863",
						"foldChange": "1.0924"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".415429533707111",
						"qValue": ".88881677553957",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".940067107569407",
						"qValue": "1",
						"foldChange": "1.1555"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".48428976398452",
						"qValue": "1",
						"foldChange": "2.3635"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".312102042754951",
						"qValue": "1",
						"foldChange": "1.8464"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".752848005276256",
						"qValue": "1",
						"foldChange": "1.1733"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".320578350230034",
						"qValue": "1",
						"foldChange": "0.6100"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".098564214376853",
						"qValue": "1",
						"foldChange": "0.5947"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".454445842657532",
						"qValue": "1",
						"foldChange": "0.9749"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".108774198338475",
						"qValue": "1",
						"foldChange": "0.4688"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".23002640735596",
						"qValue": "1",
						"foldChange": "0.5247"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".736885942825448",
						"qValue": "1",
						"foldChange": "1.1193"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".055299606093816",
						"qValue": "1",
						"foldChange": "0.4199"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".182649731972836",
						"qValue": "1",
						"foldChange": "0.5610"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".606552337753741",
						"qValue": ".985830071188835",
						"foldChange": "1.3362"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".709768564942333",
						"qValue": ".322799029297915",
						"foldChange": "1.2578"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".981803528033844",
						"qValue": ".331845357289365",
						"foldChange": "1.0365"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006780135718077",
						"qValue": ".00406747225191",
						"foldChange": "3.5190"
					}
				]
			},
			{
				"metaboliteId": "0078dd07-0000-0000-0000-000000000000",
				"chemicalId": "05f5e703-0000-0000-0000-000000000000",
				"chemicalName": "N-ethylglycinexylidide",
				"plotName": null,
				"compoundId": "00008138-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60656",
				"inChiKey": "WRMRXPASUROZGT-UHFFFAOYAO",
				"kegg": "C16561",
				"mass": 206.141913,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4815,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444216931193127",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339933",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".05117593384024",
						"qValue": ".68015325436554",
						"foldChange": "2.7312"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".05117593384024",
						"qValue": ".702430962871687",
						"foldChange": "2.7312"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999994",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".999999999999994",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999992",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".055477694210337",
						"qValue": "1",
						"foldChange": "2.7312"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".063380823973893",
						"qValue": ".985830071188835",
						"foldChange": "0.3661"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "2.7312"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dd08-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60d-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylhistidine",
				"plotName": null,
				"compoundId": "0000849a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB32055",
				"inChiKey": "KBOJOGQFRVVWBH-ZETCQYMHBN",
				"kegg": "C02997",
				"mass": 197.080041,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 70,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".924620846637283",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".414350465076276",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".449784275839895",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".61207765177835",
						"qValue": "1",
						"foldChange": "1.0466"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".643820485367146",
						"qValue": "1",
						"foldChange": "1.0496"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".964177562166085",
						"qValue": "1",
						"foldChange": "1.0056"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".03460802514294",
						"qValue": ".422966199115749",
						"foldChange": "0.8970"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".13743038044296",
						"qValue": ".759436712707527",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".509812350507661",
						"qValue": ".957839854315834",
						"foldChange": "1.0589"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".48305650358335",
						"qValue": "1",
						"foldChange": "0.9587"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".644002041514337",
						"qValue": "1",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".809935557405517",
						"qValue": "1",
						"foldChange": "0.9662"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".437028929407136",
						"qValue": "1",
						"foldChange": "1.0587"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".500106863537896",
						"qValue": "1",
						"foldChange": "1.0599"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".949674090518187",
						"qValue": "1",
						"foldChange": "1.0011"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".575902548083306",
						"qValue": "1",
						"foldChange": "0.9073"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".955734960404627",
						"qValue": "1",
						"foldChange": "1.0567"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".549591457843609",
						"qValue": "1",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".83467260474065",
						"qValue": "1",
						"foldChange": "0.9537"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".838267046579222",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".681162976833969",
						"qValue": ".990272584968167",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".314615326767967",
						"qValue": ".184364910335161",
						"foldChange": "1.1789"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".183891206018053",
						"qValue": ".097857649558608",
						"foldChange": "1.1930"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".074528720463216",
						"qValue": ".025768653035906",
						"foldChange": "1.3569"
					}
				]
			},
			{
				"metaboliteId": "0078dd09-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f2-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylarginine",
				"plotName": null,
				"compoundId": "000084a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04620",
				"inChiKey": "SNEIUMQYRCDYCH-LURJTMIEBE",
				"kegg": "C02562",
				"mass": 216.12224,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 430,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".883597460006044",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".095694269212097",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".6681765354347",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".2813806356342",
						"qValue": "1",
						"foldChange": "0.9370"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".945700427941789",
						"qValue": "1",
						"foldChange": "1.0222"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".252614928429897",
						"qValue": "1",
						"foldChange": "1.1251"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".360381796330661",
						"qValue": ".951252030575746",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".147947912591413",
						"qValue": ".782571329595308",
						"foldChange": "0.9331"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".586882484368197",
						"qValue": ".958612272931547",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".081220662769034",
						"qValue": ".886468253895902",
						"foldChange": "0.8775"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".451994755963124",
						"qValue": "1",
						"foldChange": "0.9563"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".311506405725013",
						"qValue": "1",
						"foldChange": "1.0210"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".709597130265726",
						"qValue": "1",
						"foldChange": "0.9831"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".52816630989876",
						"qValue": "1",
						"foldChange": "1.1343"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".773943581025832",
						"qValue": "1",
						"foldChange": "1.1538"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".664289925473864",
						"qValue": "1",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".679432323167832",
						"qValue": "1",
						"foldChange": "1.0524"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".997715487497501",
						"qValue": "1",
						"foldChange": "1.0229"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".958903313432734",
						"qValue": "1",
						"foldChange": "0.9124"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".694099027226734",
						"qValue": "1",
						"foldChange": "0.9427"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".650894689331523",
						"qValue": ".985830071188835",
						"foldChange": "1.0332"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".052704519398602",
						"qValue": ".051323442508686",
						"foldChange": "1.7241"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".257750479632959",
						"qValue": ".12434741170742",
						"foldChange": "1.3607"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".107216817305651",
						"qValue": ".034270144199069",
						"foldChange": "2.0459"
					}
				]
			},
			{
				"metaboliteId": "0078dd0a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e678-0000-0000-0000-000000000000",
				"chemicalName": "1-methylurate",
				"plotName": null,
				"compoundId": "0000865b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03099",
				"inChiKey": "QFDRTQONISXGJA-UHFFFAOYAS",
				"kegg": "C16359",
				"mass": 182.04399,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4159,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".687070182731037",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".509912079193562",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".181417137826084",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".450929205159433",
						"qValue": "1",
						"foldChange": "1.8436"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".056870461471932",
						"qValue": "1",
						"foldChange": "3.2302"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".239420227831361",
						"qValue": "1",
						"foldChange": "1.9010"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".568497609120333",
						"qValue": "1",
						"foldChange": "1.3915"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".185040714043399",
						"qValue": ".815904910108457",
						"foldChange": "0.8051"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".444790731865343",
						"qValue": ".957839854315834",
						"foldChange": "1.2507"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".265331261241378",
						"qValue": "1",
						"foldChange": "3.3700"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".227245363994225",
						"qValue": "1",
						"foldChange": "3.2694"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".923830167043483",
						"qValue": "1",
						"foldChange": "1.2341"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".359409738205709",
						"qValue": "1",
						"foldChange": "1.7377"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".487090434048697",
						"qValue": "1",
						"foldChange": "1.7280"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".858407287867835",
						"qValue": "1",
						"foldChange": "0.9944"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".901789162659026",
						"qValue": "1",
						"foldChange": "1.0650"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".345447765893711",
						"qValue": "1",
						"foldChange": "1.6240"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".396143138058012",
						"qValue": "1",
						"foldChange": "1.5248"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".296099437052991",
						"qValue": "1",
						"foldChange": "0.7846"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".749954002973934",
						"qValue": "1",
						"foldChange": "1.2451"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".183298077241296",
						"qValue": ".985830071188835",
						"foldChange": "1.5869"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".005741896365553",
						"qValue": ".010166245233236",
						"foldChange": "9.5833"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".018526981285416",
						"qValue": ".016994022361445",
						"foldChange": "6.0389"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003053789027784",
						"qValue": ".002262561027655",
						"foldChange": "4.4042"
					}
				]
			},
			{
				"metaboliteId": "0078dd0b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e58f-0000-0000-0000-000000000000",
				"chemicalName": "pro-hydroxy-pro",
				"plotName": null,
				"compoundId": "00008937-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06695",
				"inChiKey": "ONPXCLZMBSJLSP-ALKRTJFJBT",
				"kegg": null,
				"mass": 228.111007,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 443,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".205771094189501",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".416593486418297",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".446406537408235",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".639359184630791",
						"qValue": "1",
						"foldChange": "0.9951"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".037143009709111",
						"qValue": "1",
						"foldChange": "0.8251"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".100988763580276",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".713911631503276",
						"qValue": "1",
						"foldChange": "1.1092"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".85810854229797",
						"qValue": "1",
						"foldChange": "1.0826"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".850861462932069",
						"qValue": "1",
						"foldChange": "1.0071"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".561112785636793",
						"qValue": "1",
						"foldChange": "1.0997"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".808741138223997",
						"qValue": "1",
						"foldChange": "1.0897"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".733938580443616",
						"qValue": "1",
						"foldChange": "0.9202"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".194698383291748",
						"qValue": "1",
						"foldChange": "0.8094"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".822954956203316",
						"qValue": "1",
						"foldChange": "1.0510"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".139292969061389",
						"qValue": "1",
						"foldChange": "1.2985"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".431663992006952",
						"qValue": "1",
						"foldChange": "0.8208"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".381696682945024",
						"qValue": "1",
						"foldChange": "1.0950"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".10150957865066",
						"qValue": ".876681906260878",
						"foldChange": "1.3340"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".874891009574688",
						"qValue": "1",
						"foldChange": "1.0253"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".101639950330593",
						"qValue": "1",
						"foldChange": "1.3113"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".125999998550321",
						"qValue": ".985830071188835",
						"foldChange": "1.2789"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000088276391876",
						"qValue": ".000456679417483",
						"foldChange": "0.2428"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000000000093898",
						"qValue": ".000000026183238",
						"foldChange": "0.1756"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000005442564",
						"qValue": ".000000627635716",
						"foldChange": "0.1990"
					}
				]
			},
			{
				"metaboliteId": "0078dd0c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e763-0000-0000-0000-000000000000",
				"chemicalName": "ectoine",
				"plotName": null,
				"compoundId": "00008b43-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WQXNXVUDBPYKBA-YFKPBYRVBN",
				"kegg": "C06231",
				"mass": 142.074228,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5296,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844841984594528",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".122329330878621",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".282140428913191",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".119891301567826",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".167060615343625",
						"qValue": "1",
						"foldChange": "1.0181"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".857554957058179",
						"qValue": "1",
						"foldChange": "1.4746"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".771980971201542",
						"qValue": "1",
						"foldChange": "1.7309"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".049125696571544",
						"qValue": ".678234398444917",
						"foldChange": "0.6824"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".025226393208511",
						"qValue": ".580207043795744",
						"foldChange": "0.8817"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".414802176152403",
						"qValue": "1",
						"foldChange": "0.9793"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".777348713983475",
						"qValue": "1",
						"foldChange": "1.1710"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".592905169392879",
						"qValue": "1",
						"foldChange": "1.2596"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".508810309792563",
						"qValue": "1",
						"foldChange": "0.4890"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".782371399669524",
						"qValue": "1",
						"foldChange": "0.7068"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".720167528733587",
						"qValue": "1",
						"foldChange": "1.4454"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".561697935436457",
						"qValue": "1",
						"foldChange": "1.4089"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".862510380385297",
						"qValue": "1",
						"foldChange": "0.8052"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".699899913760057",
						"qValue": "1",
						"foldChange": "0.5715"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".317771288848984",
						"qValue": "1",
						"foldChange": "0.6278"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".674726310134622",
						"qValue": "1",
						"foldChange": "0.7868"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".1651166730569",
						"qValue": ".985830071188835",
						"foldChange": "1.2531"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".012413268836883",
						"qValue": ".017947233668142",
						"foldChange": "2.7456"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".871413769071505",
						"qValue": ".306453912600224",
						"foldChange": "0.4777"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".395160825692722",
						"qValue": ".092612748734229",
						"foldChange": "1.0128"
					}
				]
			},
			{
				"metaboliteId": "0078dd0d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1cb-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-1-methylhistidine*",
				"plotName": null,
				"compoundId": "0000a8f7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 211.095691,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 72,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".877089427289356",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".906151727577698",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".606519156730342",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".238269819466011",
						"qValue": "1",
						"foldChange": "0.9053"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".149429009875891",
						"qValue": "1",
						"foldChange": "0.8731"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".787669345889616",
						"qValue": "1",
						"foldChange": "0.9898"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".684648758686669",
						"qValue": "1",
						"foldChange": "1.0944"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".931816468650026",
						"qValue": "1",
						"foldChange": "1.0657"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".748404766872318",
						"qValue": ".981344309103764",
						"foldChange": "1.0376"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".778403656573671",
						"qValue": "1",
						"foldChange": "1.2672"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".558748425499245",
						"qValue": "1",
						"foldChange": "1.2664"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".761060519234722",
						"qValue": "1",
						"foldChange": "0.9815"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".883268125456291",
						"qValue": "1",
						"foldChange": "0.7667"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".506908554995353",
						"qValue": "1",
						"foldChange": "0.7471"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".589976302022316",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".66660292919158",
						"qValue": "1",
						"foldChange": "0.9666"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".88110290613047",
						"qValue": "1",
						"foldChange": "0.8017"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".568947697449099",
						"qValue": "1",
						"foldChange": "0.8294"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".675940221429053",
						"qValue": "1",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".955189584485621",
						"qValue": "1",
						"foldChange": "0.7687"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".72814482930738",
						"qValue": ".997573179629986",
						"foldChange": "0.7845"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000251365754379",
						"qValue": ".000979115718405",
						"foldChange": "3.6135"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004906271970736",
						"qValue": ".006363239435668",
						"foldChange": "2.5556"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".10198864315399",
						"qValue": ".033192951454601",
						"foldChange": "2.5148"
					}
				]
			},
			{
				"metaboliteId": "0078dd0e-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1c7-0000-0000-0000-000000000000",
				"chemicalName": "2-piperidinone",
				"plotName": null,
				"compoundId": "0000a988-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11749",
				"inChiKey": "XUWHAWMETYGRKB-UHFFFAOYAE",
				"kegg": null,
				"mass": 99.068414,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4189,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".55710230563878",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".078111360703391",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".010366801626056",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".383818381487087",
						"qValue": "1",
						"foldChange": "1.2064"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".763334108342565",
						"qValue": "1",
						"foldChange": "1.0934"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".567234059170408",
						"qValue": "1",
						"foldChange": "0.9432"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".791251512251993",
						"qValue": "1",
						"foldChange": "1.1126"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".93310419462912",
						"qValue": "1",
						"foldChange": "1.1312"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".7274618603745",
						"qValue": ".976023509428735",
						"foldChange": "1.1718"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".004764235848903",
						"qValue": ".543683917591992",
						"foldChange": "0.7019"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".000147738218272",
						"qValue": ".062862611874768",
						"foldChange": "0.5777"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".250994523149432",
						"qValue": "1",
						"foldChange": "0.7894"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".742261469355879",
						"qValue": "1",
						"foldChange": "1.2141"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".785607529520873",
						"qValue": "1",
						"foldChange": "0.9569"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".968083085850574",
						"qValue": "1",
						"foldChange": "0.7881"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".906862381570986",
						"qValue": "1",
						"foldChange": "0.8441"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".225930560174632",
						"qValue": "1",
						"foldChange": "0.4113"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".258316359434916",
						"qValue": "1",
						"foldChange": "0.4872"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".809402521369077",
						"qValue": "1",
						"foldChange": "1.1335"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".148529271821782",
						"qValue": "1",
						"foldChange": "0.3509"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".089558336480278",
						"qValue": ".985830071188835",
						"foldChange": "0.3096"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000352264226367",
						"qValue": ".001214910111892",
						"foldChange": "0.2420"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".31072276051082",
						"qValue": ".144647639353383",
						"foldChange": "1.0339"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".299126170132572",
						"qValue": ".075616801023889",
						"foldChange": "1.0528"
					}
				]
			},
			{
				"metaboliteId": "0078dd0f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f862-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/24:1, d18:2/24:0)*",
				"plotName": null,
				"compoundId": "0000b831-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12107",
				"inChiKey": "WKZHECFHXLTOLJ-QYKFWSDSBN",
				"kegg": null,
				"mass": 812.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2817,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".852043250997988",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".932458497811339",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".653378980279066",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".91354401140102",
						"qValue": "1",
						"foldChange": "1.0131"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".611781262059051",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".538179174601087",
						"qValue": "1",
						"foldChange": "1.0498"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".19737775331945",
						"qValue": ".797536225282113",
						"foldChange": "1.0916"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".64922333197185",
						"qValue": ".93168474790564",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".399836828732428",
						"qValue": ".957839854315834",
						"foldChange": "0.9679"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".64469659768047",
						"qValue": "1",
						"foldChange": "0.9827"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".492102942438001",
						"qValue": "1",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".8204584449301",
						"qValue": "1",
						"foldChange": "0.9743"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".829486500969264",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".495119160631824",
						"qValue": "1",
						"foldChange": "1.0395"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".623053981066301",
						"qValue": "1",
						"foldChange": "1.0509"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".314206202952752",
						"qValue": "1",
						"foldChange": "1.1086"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".638762017709838",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".61775914903712",
						"qValue": "1",
						"foldChange": "0.9614"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".8649523972947",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".990535952765043",
						"qValue": "1",
						"foldChange": "0.9847"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".85959744978765",
						"qValue": ".997573179629986",
						"foldChange": "0.9741"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".142684882790625",
						"qValue": ".106358291676719",
						"foldChange": "1.1742"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".030317264294489",
						"qValue": ".025011423833925",
						"foldChange": "1.1995"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".037022468944533",
						"qValue": ".015145856326781",
						"foldChange": "1.2379"
					}
				]
			},
			{
				"metaboliteId": "0078dd10-0000-0000-0000-000000000000",
				"chemicalId": "05f6052b-0000-0000-0000-000000000000",
				"chemicalName": "glycocholate glucuronide (1)",
				"plotName": null,
				"compoundId": "0000cef1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 641.341126,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3109,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".319072763166868",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".358887632518804",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".073573773434862",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".992385482825582",
						"qValue": "1",
						"foldChange": "1.4074"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".93219702609832",
						"qValue": "1",
						"foldChange": "1.0720"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".939786345176666",
						"qValue": "1",
						"foldChange": "1.3662"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".298286537353883",
						"qValue": ".911265664999995",
						"foldChange": "1.6403"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".308420951524937",
						"qValue": ".825038702902081",
						"foldChange": "1.7485"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".982498266470841",
						"qValue": "1",
						"foldChange": "1.0359"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".508412472029821",
						"qValue": "1",
						"foldChange": "1.0238"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".005614039340995",
						"qValue": ".522751459721039",
						"foldChange": "0.8159"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".030197030325988",
						"qValue": ".614826369149758",
						"foldChange": "0.8702"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".651529896186489",
						"qValue": "1",
						"foldChange": "2.7952"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".047645641202422",
						"qValue": "1",
						"foldChange": "6.0292"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".105581285517248",
						"qValue": "1",
						"foldChange": "2.1570"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".330122782430258",
						"qValue": "1",
						"foldChange": "2.2925"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".100348489341237",
						"qValue": "1",
						"foldChange": "2.3261"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".445171755840481",
						"qValue": "1",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".355967863834113",
						"qValue": "1",
						"foldChange": "3.1233"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".660815278956444",
						"qValue": "1",
						"foldChange": "1.6719"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".653518909643538",
						"qValue": ".985830071188835",
						"foldChange": "0.5353"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".066981097870752",
						"qValue": ".061431610112182",
						"foldChange": "3.8813"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".037022875793562",
						"qValue": ".029328685109511",
						"foldChange": "7.1986"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".041669248460724",
						"qValue": ".016260708233236",
						"foldChange": "2.3215"
					}
				]
			},
			{
				"metaboliteId": "0078dd11-0000-0000-0000-000000000000",
				"chemicalId": "00000023-0000-0000-0000-000000000000",
				"chemicalName": "S-1-pyrroline-5-carboxylate",
				"plotName": null,
				"compoundId": "0000a582-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01301",
				"inChiKey": "DWAKNKKXGALPNW-REWHXWOFAV",
				"kegg": "C04322",
				"mass": 113.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 60,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".018796500886892",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".955756902721888",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".94548724881538",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".58077741362645",
						"qValue": "1",
						"foldChange": "1.3177"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".741686404990219",
						"qValue": "1",
						"foldChange": "1.1288"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".823327021400522",
						"qValue": "1",
						"foldChange": "1.2354"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".582441138977311",
						"qValue": "1",
						"foldChange": "0.9886"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".661385971115877",
						"qValue": ".934949271461149",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".910627423736114",
						"qValue": "1",
						"foldChange": "1.0989"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".784225483572766",
						"qValue": "1",
						"foldChange": "1.2927"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".918745790294355",
						"qValue": "1",
						"foldChange": "1.1715"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".707165817869874",
						"qValue": "1",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".027643876832734",
						"qValue": "1",
						"foldChange": "1.3873"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".817216685287033",
						"qValue": "1",
						"foldChange": "0.9888"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".056306052368691",
						"qValue": "1",
						"foldChange": "0.7127"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".243018532096106",
						"qValue": "1",
						"foldChange": "1.1712"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".987837821143179",
						"qValue": "1",
						"foldChange": "0.9438"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".251545174715599",
						"qValue": "1",
						"foldChange": "0.8059"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".136563417099709",
						"qValue": "1",
						"foldChange": "1.3749"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".857281408017008",
						"qValue": "1",
						"foldChange": "0.8411"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".10470837810394",
						"qValue": ".985830071188835",
						"foldChange": "0.6118"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".386508468210119",
						"qValue": ".210096471647455",
						"foldChange": "1.3011"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001526656384222",
						"qValue": ".002782370586256",
						"foldChange": "2.3517"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".014171799898706",
						"qValue": ".007120263058475",
						"foldChange": "1.6970"
					}
				]
			},
			{
				"metaboliteId": "0078dd12-0000-0000-0000-000000000000",
				"chemicalId": "05f5fad6-0000-0000-0000-000000000000",
				"chemicalName": "adipoylcarnitine (C6-DC)",
				"plotName": null,
				"compoundId": "0000cefc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61677",
				"inChiKey": "BSVHAXJKBCWVDA-UHFFFAOYAF",
				"kegg": null,
				"mass": 289.152537,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1685,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".963344220807853",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".572188860282082",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".576521315504444",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".777623867479055",
						"qValue": "1",
						"foldChange": "1.6202"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".650917748199706",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".463294721758654",
						"qValue": "1",
						"foldChange": "1.0800"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".84848201058637",
						"qValue": "1",
						"foldChange": "1.3420"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".714897196314706",
						"qValue": ".950986052805043",
						"foldChange": "2.1651"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".5783936897341",
						"qValue": ".957839854315834",
						"foldChange": "1.7945"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".124370195759966",
						"qValue": "1",
						"foldChange": "1.7271"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".170818260282392",
						"qValue": "1",
						"foldChange": "1.8225"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".862860451849593",
						"qValue": "1",
						"foldChange": "1.0244"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".882188383989116",
						"qValue": "1",
						"foldChange": "1.0010"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".294784548890007",
						"qValue": "1",
						"foldChange": "0.6359"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".351691688298003",
						"qValue": "1",
						"foldChange": "0.6352"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".596563177191369",
						"qValue": "1",
						"foldChange": "0.8595"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".990532399739074",
						"qValue": "1",
						"foldChange": "0.7647"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".599650903592818",
						"qValue": "1",
						"foldChange": "0.8897"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".610384112576211",
						"qValue": "1",
						"foldChange": "1.3841"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".658963326498181",
						"qValue": "1",
						"foldChange": "1.0212"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".966806759166223",
						"qValue": "1",
						"foldChange": "0.7378"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000346659242698",
						"qValue": ".00120816435146",
						"foldChange": "0.2968"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000501070770559",
						"qValue": ".001470755801756",
						"foldChange": "0.3330"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000148993135308",
						"qValue": ".000281817747393",
						"foldChange": "0.2747"
					}
				]
			},
			{
				"metaboliteId": "0078dd13-0000-0000-0000-000000000000",
				"chemicalId": "05f603c8-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPC (18:0/18:1)",
				"plotName": null,
				"compoundId": "0000ccd6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08038",
				"inChiKey": "ATHVAWFAEPLPPQ-VRDBWYNSBX",
				"kegg": null,
				"mass": 787.609105,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2060,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".186670675011958",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".824801944487995",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".267366242236986",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".500071106639082",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".839049812892459",
						"qValue": "1",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".63672315383151",
						"qValue": "1",
						"foldChange": "0.9827"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".109087032700977",
						"qValue": ".678202995415259",
						"foldChange": "1.1463"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".437436507606366",
						"qValue": ".898869287881775",
						"foldChange": "1.0783"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".399774143693535",
						"qValue": ".957839854315834",
						"foldChange": "0.9718"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".170923516916284",
						"qValue": "1",
						"foldChange": "0.9099"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".924124124116903",
						"qValue": "1",
						"foldChange": "1.0210"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".143870272326895",
						"qValue": ".960252848686774",
						"foldChange": "1.0756"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".3113403716667",
						"qValue": "1",
						"foldChange": "1.0804"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".094195795032637",
						"qValue": "1",
						"foldChange": "1.1396"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".453248678973638",
						"qValue": "1",
						"foldChange": "1.0548"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".094556743346723",
						"qValue": "1",
						"foldChange": "1.1468"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".912089450158323",
						"qValue": "1",
						"foldChange": "0.9875"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".130667953695066",
						"qValue": ".931625125271228",
						"foldChange": "0.8611"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".154739428230853",
						"qValue": "1",
						"foldChange": "1.1296"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".109398094058826",
						"qValue": "1",
						"foldChange": "1.1083"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".788495084692164",
						"qValue": ".997573179629986",
						"foldChange": "0.9812"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".099019763935381",
						"qValue": ".081553512732947",
						"foldChange": "1.2319"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007833819374909",
						"qValue": ".00849973795256",
						"foldChange": "1.2005"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".032386747747174",
						"qValue": ".013666690793044",
						"foldChange": "1.2590"
					}
				]
			},
			{
				"metaboliteId": "0078dd14-0000-0000-0000-000000000000",
				"chemicalId": "05f60446-0000-0000-0000-000000000000",
				"chemicalName": "lactosyl-N-palmitoyl-sphingosine (d18:1/16:0)",
				"plotName": null,
				"compoundId": "0000cf12-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 861.617742,
				"pathwayType": "ANIMAL",
				"subPathway": "Lactosylceramides (LCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2775,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".619983210659828",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".350710089993856",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".013386066318262",
						"qValue": ".542454401754345",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".550669445993909",
						"qValue": "1",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".646606284399013",
						"qValue": "1",
						"foldChange": "1.0507"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".889550688200914",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".283147501559297",
						"qValue": ".911265664999995",
						"foldChange": "0.9482"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".639428918758359",
						"qValue": ".928868848501546",
						"foldChange": "1.0659"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".126056885968992",
						"qValue": ".932820956170539",
						"foldChange": "1.1434"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".023404835633565",
						"qValue": ".821720595261736",
						"foldChange": "1.2041"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".270480447504754",
						"qValue": "1",
						"foldChange": "0.9368"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".001156033937225",
						"qValue": ".306920185386474",
						"foldChange": "0.7541"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".482192671583772",
						"qValue": "1",
						"foldChange": "1.1076"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".603033600842298",
						"qValue": "1",
						"foldChange": "0.9570"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".22836933767588",
						"qValue": "1",
						"foldChange": "0.8640"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".837162272753532",
						"qValue": "1",
						"foldChange": "0.9718"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".636608305480634",
						"qValue": "1",
						"foldChange": "1.0658"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".495457567672015",
						"qValue": "1",
						"foldChange": "1.0968"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".48706230136051",
						"qValue": "1",
						"foldChange": "1.0876"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".170361068641323",
						"qValue": "1",
						"foldChange": "0.7904"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".041527935202491",
						"qValue": ".985830071188835",
						"foldChange": "0.7267"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".032451709655322",
						"qValue": ".036670497319171",
						"foldChange": "0.7592"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".211236353948693",
						"qValue": ".108077991841548",
						"foldChange": "1.1457"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".410785912957448",
						"qValue": ".094755356420249",
						"foldChange": "1.1211"
					}
				]
			},
			{
				"metaboliteId": "0078dd15-0000-0000-0000-000000000000",
				"chemicalId": "05f6045c-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linoleoyl-glycerol (16:0/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000cd99-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07103",
				"inChiKey": "SVXWJFFKLMLOHO-YAIZGCQRBM",
				"kegg": null,
				"mass": 592.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2607,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".937522554602064",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".256377653457784",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".012984735639839",
						"qValue": ".542454401754345",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".17023300477436",
						"qValue": "1",
						"foldChange": "0.8269"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".767835601852804",
						"qValue": "1",
						"foldChange": "1.0702"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".278778173894997",
						"qValue": "1",
						"foldChange": "1.5595"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".012826549866329",
						"qValue": ".303094620658292",
						"foldChange": "2.3991"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".047715334823727",
						"qValue": ".678234398444917",
						"foldChange": "1.8660"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".582663720530019",
						"qValue": ".957839854315834",
						"foldChange": "0.9928"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".023061770224135",
						"qValue": ".821720595261736",
						"foldChange": "0.7093"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".888089809319391",
						"qValue": "1",
						"foldChange": "1.2489"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".032189515857455",
						"qValue": ".614826369149758",
						"foldChange": "1.8718"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".150830812796531",
						"qValue": "1",
						"foldChange": "0.6518"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".82908733205938",
						"qValue": "1",
						"foldChange": "1.2408"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".108637663662671",
						"qValue": "1",
						"foldChange": "1.9038"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".407137215260704",
						"qValue": "1",
						"foldChange": "1.1163"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".687995098807499",
						"qValue": "1",
						"foldChange": "0.7301"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".227519196645442",
						"qValue": "1",
						"foldChange": "0.6541"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".895049877983861",
						"qValue": "1",
						"foldChange": "0.7766"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".765382812202609",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".66491317750193",
						"qValue": ".990272584968167",
						"foldChange": "1.2780"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".760888521517391",
						"qValue": ".335897373254408",
						"foldChange": "1.2323"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".176310958897652",
						"qValue": ".094727713748869",
						"foldChange": "1.3773"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".932063717798831",
						"qValue": ".183194868597935",
						"foldChange": "1.3003"
					}
				]
			},
			{
				"metaboliteId": "0078dd16-0000-0000-0000-000000000000",
				"chemicalId": "0000010a-0000-0000-0000-000000000000",
				"chemicalName": "cholesterol",
				"plotName": null,
				"compoundId": "0000003f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00067",
				"inChiKey": "HVYWMOMLDIMFJA-LBHVWCRMBB",
				"kegg": "C00187",
				"mass": 386.354866,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2880,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".136438157651315",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".555064347854212",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".286935700906203",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".794091876246884",
						"qValue": "1",
						"foldChange": "0.9986"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".911022764116267",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".709462340085734",
						"qValue": "1",
						"foldChange": "1.0447"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".028893166488178",
						"qValue": ".374891102793553",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".83812522676235",
						"qValue": "1",
						"foldChange": "1.0292"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".046133646622215",
						"qValue": ".679210111549633",
						"foldChange": "0.9024"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".98382630295094",
						"qValue": "1",
						"foldChange": "1.0037"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".603865715068298",
						"qValue": "1",
						"foldChange": "1.0547"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".61801903721003",
						"qValue": "1",
						"foldChange": "0.9976"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".910397760656574",
						"qValue": "1",
						"foldChange": "1.0246"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".110035650004793",
						"qValue": "1",
						"foldChange": "1.1824"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".125589629477711",
						"qValue": "1",
						"foldChange": "1.1540"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".111971690648743",
						"qValue": "1",
						"foldChange": "1.1717"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".078817610776064",
						"qValue": "1",
						"foldChange": "1.2393"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".786048746992947",
						"qValue": "1",
						"foldChange": "1.0577"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".869587388908941",
						"qValue": "1",
						"foldChange": "1.0247"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".064981297997935",
						"qValue": "1",
						"foldChange": "1.1934"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".082054046286473",
						"qValue": ".985830071188835",
						"foldChange": "1.1646"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".01157928011081",
						"qValue": ".017039069734078",
						"foldChange": "1.4128"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".02160980150721",
						"qValue": ".019313513607787",
						"foldChange": "1.2768"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004537309436297",
						"qValue": ".003039542094869",
						"foldChange": "1.3176"
					}
				]
			},
			{
				"metaboliteId": "0078dd17-0000-0000-0000-000000000000",
				"chemicalId": "05f60444-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyl-sphinganine (d18:0/16:0)",
				"plotName": null,
				"compoundId": "0000cd7c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11760",
				"inChiKey": "GCGTXOVNNFGTPQ-JHOUSYSJBP",
				"kegg": null,
				"mass": 539.527745,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydroceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2702,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".293790566972091",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".675061787332429",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".956793573199034",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".958611392605475",
						"qValue": "1",
						"foldChange": "1.0590"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".436081502528371",
						"qValue": "1",
						"foldChange": "1.1305"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".466985765757878",
						"qValue": "1",
						"foldChange": "1.1096"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".4652796185602",
						"qValue": "1",
						"foldChange": "1.1112"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".54134587503921",
						"qValue": ".910963185640849",
						"foldChange": "1.0822"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".90429004809601",
						"qValue": "1",
						"foldChange": "1.0398"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".88753504770291",
						"qValue": "1",
						"foldChange": "1.0005"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".875588045071201",
						"qValue": "1",
						"foldChange": "1.0438"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".7657912535843",
						"qValue": "1",
						"foldChange": "1.0224"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".812755427833593",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".140656228380989",
						"qValue": "1",
						"foldChange": "1.2338"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".198031667074809",
						"qValue": "1",
						"foldChange": "1.2423"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".528044448280523",
						"qValue": "1",
						"foldChange": "1.1050"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".173750042714368",
						"qValue": "1",
						"foldChange": "1.2174"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".427154748353781",
						"qValue": "1",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".9097261937966",
						"qValue": "1",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".259864639380566",
						"qValue": "1",
						"foldChange": "1.2020"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".295369182979076",
						"qValue": ".985830071188835",
						"foldChange": "1.1575"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".033098646201996",
						"qValue": ".036773994355072",
						"foldChange": "1.6829"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002296822259012",
						"qValue": ".003659775503012",
						"foldChange": "1.5772"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000086382188391",
						"qValue": ".0002026523942",
						"foldChange": "1.8027"
					}
				]
			},
			{
				"metaboliteId": "0078dd18-0000-0000-0000-000000000000",
				"chemicalId": "05f60431-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-linoleoyl-GPC (P-16:0/18:2)*",
				"plotName": null,
				"compoundId": "0000cdca-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11211",
				"inChiKey": "QLEHHUPUHJPURI-PWYDUFMYBJ",
				"kegg": null,
				"mass": 741.56724,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2516,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".979645014373643",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".05161089307199",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".955079558649575",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".150566568941844",
						"qValue": "1",
						"foldChange": "0.9159"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".216349076241881",
						"qValue": "1",
						"foldChange": "0.9192"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".836051474763233",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".303595027243139",
						"qValue": ".919428356526374",
						"foldChange": "0.9740"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".093721706712821",
						"qValue": ".697458782960496",
						"foldChange": "0.9060"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".506000801168746",
						"qValue": ".957839854315834",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".580202532467047",
						"qValue": "1",
						"foldChange": "0.9939"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".204967161915732",
						"qValue": "1",
						"foldChange": "0.9111"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".470087390436366",
						"qValue": "1",
						"foldChange": "0.9595"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".9054788991298",
						"qValue": "1",
						"foldChange": "1.0078"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".967841070127012",
						"qValue": "1",
						"foldChange": "1.0103"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".941226230867935",
						"qValue": "1",
						"foldChange": "1.0025"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".900444245752919",
						"qValue": "1",
						"foldChange": "1.0457"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".70221755212098",
						"qValue": "1",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".786589974916374",
						"qValue": "1",
						"foldChange": "0.9999"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".757709025183024",
						"qValue": "1",
						"foldChange": "0.9436"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".925229304679979",
						"qValue": "1",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".839273793151803",
						"qValue": ".997573179629986",
						"foldChange": "1.0158"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".060588260131077",
						"qValue": ".057233983568293",
						"foldChange": "0.8343"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006321435151328",
						"qValue": ".007447687312721",
						"foldChange": "0.7111"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".589766000908694",
						"qValue": ".125756196124363",
						"foldChange": "0.9596"
					}
				]
			},
			{
				"metaboliteId": "0078dd19-0000-0000-0000-000000000000",
				"chemicalId": "05f60509-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-stearoyl)-2-linoleoyl-GPE (P-18:0/18:2)*",
				"plotName": null,
				"compoundId": "0000ce0c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11376",
				"inChiKey": null,
				"kegg": null,
				"mass": 727.55159,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2512,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".50192167350124",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".386794626969153",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".782993656321048",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".713543718702237",
						"qValue": "1",
						"foldChange": "0.9896"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".7125473109418",
						"qValue": "1",
						"foldChange": "1.0632"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".462961654117826",
						"qValue": "1",
						"foldChange": "1.1090"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".611713983679791",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".386126578265531",
						"qValue": ".880948305908758",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".717703622915397",
						"qValue": ".976023509428735",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".152495344543734",
						"qValue": "1",
						"foldChange": "0.8991"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".331821001759568",
						"qValue": "1",
						"foldChange": "0.9456"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".638014011772271",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".45155529560055",
						"qValue": "1",
						"foldChange": "1.1368"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".135418512890364",
						"qValue": "1",
						"foldChange": "1.2445"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".413752912294315",
						"qValue": "1",
						"foldChange": "1.0947"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".49155952674483",
						"qValue": "1",
						"foldChange": "1.1717"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".378550741973963",
						"qValue": "1",
						"foldChange": "1.1608"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".811619152143327",
						"qValue": "1",
						"foldChange": "0.9907"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".941914724393631",
						"qValue": "1",
						"foldChange": "1.0209"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".450580087133739",
						"qValue": "1",
						"foldChange": "1.1328"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".482113568407076",
						"qValue": ".985830071188835",
						"foldChange": "1.1096"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".304214320648611",
						"qValue": ".181155573886462",
						"foldChange": "0.8918"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".237096223611662",
						"qValue": ".118270909086627",
						"foldChange": "0.8647"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".941123364231811",
						"qValue": ".184524360379125",
						"foldChange": "0.9482"
					}
				]
			},
			{
				"metaboliteId": "0078dd1a-0000-0000-0000-000000000000",
				"chemicalId": "05f60573-0000-0000-0000-000000000000",
				"chemicalName": "oleoylcholine",
				"plotName": null,
				"compoundId": "0000d00c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 367.34503,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1715,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".720458097837214",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".258498507084665",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".056377323106032",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".605047137136734",
						"qValue": "1",
						"foldChange": "2.0496"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".184323449966462",
						"qValue": "1",
						"foldChange": "2.1691"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".412691000250737",
						"qValue": "1",
						"foldChange": "4.1620"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".136268681870491",
						"qValue": ".738628332941326",
						"foldChange": "0.8277"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".287637409456506",
						"qValue": ".825038702902081",
						"foldChange": "1.2024"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".661917322229246",
						"qValue": ".975068512044627",
						"foldChange": "2.6216"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".207340913603595",
						"qValue": "1",
						"foldChange": "0.9181"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".006378613054152",
						"qValue": ".522751459721039",
						"foldChange": "0.3837"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".121945290731726",
						"qValue": ".952068279015587",
						"foldChange": "0.5925"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".27651405142183",
						"qValue": "1",
						"foldChange": "1.7706"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".251816611575905",
						"qValue": "1",
						"foldChange": "1.6856"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".903678365042162",
						"qValue": "1",
						"foldChange": "0.9520"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".539236966288312",
						"qValue": "1",
						"foldChange": "0.5903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".69242641910318",
						"qValue": "1",
						"foldChange": "0.7474"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".849214971584332",
						"qValue": "1",
						"foldChange": "1.2663"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".333035241050463",
						"qValue": "1",
						"foldChange": "1.1326"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".016451572250159",
						"qValue": "1",
						"foldChange": "0.2464"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".119104467124988",
						"qValue": ".985830071188835",
						"foldChange": "0.2175"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".069797655373501",
						"qValue": ".063542810408596",
						"foldChange": "1.8020"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000613505599794",
						"qValue": ".001710738531675",
						"foldChange": "12.9367"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000065979963817",
						"qValue": ".000172721742087",
						"foldChange": "9.0524"
					}
				]
			},
			{
				"metaboliteId": "0078dd1b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e83b-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylserine",
				"plotName": null,
				"compoundId": "000090d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02931",
				"inChiKey": "JJIHLJJYMXLCOY-BYPYZUCNBM",
				"kegg": null,
				"mass": 147.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 10,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".96980638861539",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".673646126533787",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".866438718537282",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".759329581402011",
						"qValue": "1",
						"foldChange": "1.0059"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".841379409073761",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".915316869612204",
						"qValue": "1",
						"foldChange": "1.0540"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".62034050356161",
						"qValue": "1",
						"foldChange": "1.0072"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".165254515990602",
						"qValue": ".787973217949622",
						"foldChange": "0.9363"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".367112760190536",
						"qValue": ".957839854315834",
						"foldChange": "0.9739"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".820971653077812",
						"qValue": "1",
						"foldChange": "0.9827"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".988639867557258",
						"qValue": "1",
						"foldChange": "1.0167"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".809925985137883",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".623214814353313",
						"qValue": "1",
						"foldChange": "1.0389"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".924490371678214",
						"qValue": "1",
						"foldChange": "0.9565"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".704586511591689",
						"qValue": "1",
						"foldChange": "0.9207"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".705210016984864",
						"qValue": "1",
						"foldChange": "1.0316"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".890102071122029",
						"qValue": "1",
						"foldChange": "1.0214"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".821898317336118",
						"qValue": "1",
						"foldChange": "0.9901"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".772906793574337",
						"qValue": "1",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".813911684883755",
						"qValue": "1",
						"foldChange": "0.9333"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".603016115167565",
						"qValue": ".985830071188835",
						"foldChange": "0.9856"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006963053896545",
						"qValue": ".011584932797588",
						"foldChange": "1.5544"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".010508103102048",
						"qValue": ".010588782853907",
						"foldChange": "1.3999"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".043239239647493",
						"qValue": ".016751394146653",
						"foldChange": "1.3895"
					}
				]
			},
			{
				"metaboliteId": "0078dd1c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea34-0000-0000-0000-000000000000",
				"chemicalName": "(16 or 17)-methylstearate (a19:0 or i19:0)",
				"plotName": null,
				"compoundId": "00009598-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB37397",
				"inChiKey": "YETXGSGCWODRAA-UHFFFAOYAR",
				"kegg": null,
				"mass": 298.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1466,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".980455163611174",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".290378736586373",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".063624280691114",
						"qValue": ".730905288705153",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".33535920776654",
						"qValue": "1",
						"foldChange": "1.1492"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".276934382680148",
						"qValue": "1",
						"foldChange": "1.1741"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".899798023019789",
						"qValue": "1",
						"foldChange": "1.0397"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".037157651027948",
						"qValue": ".433166589380598",
						"foldChange": "1.2719"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".314654992985035",
						"qValue": ".825038702902081",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".002732474501578",
						"qValue": ".150104626324489",
						"foldChange": "0.7569"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".778670799267253",
						"qValue": "1",
						"foldChange": "1.0188"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".508981900249249",
						"qValue": "1",
						"foldChange": "1.1539"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".347861477877244",
						"qValue": "1",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".823913928503269",
						"qValue": "1",
						"foldChange": "1.0656"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".653275293807871",
						"qValue": "1",
						"foldChange": "1.0074"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".806355687806915",
						"qValue": "1",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".436425730274736",
						"qValue": "1",
						"foldChange": "1.2340"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".853501350826504",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".347065912603994",
						"qValue": "1",
						"foldChange": "0.7484"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".38937075569983",
						"qValue": "1",
						"foldChange": "0.8873"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".799991969404915",
						"qValue": "1",
						"foldChange": "0.9896"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".276379299298356",
						"qValue": ".985830071188835",
						"foldChange": "1.1153"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".182084326606141",
						"qValue": ".124816578111916",
						"foldChange": "1.3646"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".311265770764394",
						"qValue": ".144658920749047",
						"foldChange": "1.3137"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".24129789571005",
						"qValue": ".063494093911902",
						"foldChange": "1.2373"
					}
				]
			},
			{
				"metaboliteId": "0078dd1d-0000-0000-0000-000000000000",
				"chemicalId": "05f60bab-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-arachidonoyl-glycerol (18:2/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000d6ac-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07257",
				"inChiKey": null,
				"kegg": null,
				"mass": 640.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2642,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".183730473306378",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".767578026300256",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".614339917503773",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".634472752773052",
						"qValue": "1",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".560672284441508",
						"qValue": "1",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".914894386804506",
						"qValue": "1",
						"foldChange": "1.0871"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".405300141137136",
						"qValue": ".981462415659181",
						"foldChange": "1.3031"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".565675246139371",
						"qValue": ".927965721746881",
						"foldChange": "1.1436"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".79508894384726",
						"qValue": ".995030428255909",
						"foldChange": "1.0863"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".192412713364301",
						"qValue": "1",
						"foldChange": "0.8852"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".315804878085989",
						"qValue": "1",
						"foldChange": "0.9135"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".759145708367552",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".123102096507025",
						"qValue": "1",
						"foldChange": "0.6657"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".415361076357389",
						"qValue": "1",
						"foldChange": "1.3473"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".022867399819933",
						"qValue": "1",
						"foldChange": "2.0238"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".421636756494262",
						"qValue": "1",
						"foldChange": "0.8538"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".765907368123048",
						"qValue": "1",
						"foldChange": "1.1194"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".280771096734645",
						"qValue": "1",
						"foldChange": "1.3110"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".375628796331596",
						"qValue": "1",
						"foldChange": "0.8024"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".588154735569046",
						"qValue": "1",
						"foldChange": "1.2337"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".16144402573122",
						"qValue": ".985830071188835",
						"foldChange": "1.5375"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".04799447458754",
						"qValue": ".048446704069777",
						"foldChange": "2.0533"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".048407305767678",
						"qValue": ".036481634978599",
						"foldChange": "1.6148"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056011386138689",
						"qValue": ".020879844219214",
						"foldChange": "1.6012"
					}
				]
			},
			{
				"metaboliteId": "0078dd1e-0000-0000-0000-000000000000",
				"chemicalId": "05f61eb0-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/25:0, d19:0/24:1, d20:1/23:0, d19:1/24:0)*",
				"plotName": null,
				"compoundId": "0000e086-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JONXXLXMISNQNG-BXMSAMRLBO",
				"kegg": null,
				"mass": 828.708425,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2819,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".007510111114321",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".286105105784453",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".522097364681902",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".431763739448261",
						"qValue": "1",
						"foldChange": "1.1616"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".115332696686948",
						"qValue": "1",
						"foldChange": "1.2541"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".42143611670548",
						"qValue": "1",
						"foldChange": "1.1034"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".909216531499505",
						"qValue": "1",
						"foldChange": "1.0748"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".464176732961289",
						"qValue": ".902562957068497",
						"foldChange": "1.1180"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".536067501936269",
						"qValue": ".957839854315834",
						"foldChange": "1.1038"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".158328777439547",
						"qValue": "1",
						"foldChange": "1.1817"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".922885505466165",
						"qValue": "1",
						"foldChange": "1.0480"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".187937700770432",
						"qValue": ".994724181158891",
						"foldChange": "0.8403"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".339672349484548",
						"qValue": "1",
						"foldChange": "1.0703"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".004752305751748",
						"qValue": "1",
						"foldChange": "1.5643"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".040130114412178",
						"qValue": "1",
						"foldChange": "1.4616"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".608613885554569",
						"qValue": "1",
						"foldChange": "1.1084"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".001346733756716",
						"qValue": ".851813617313741",
						"foldChange": "1.8301"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".004311212757193",
						"qValue": ".605991101987089",
						"foldChange": "1.6512"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".712121683009402",
						"qValue": "1",
						"foldChange": "1.0979"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".046865785848542",
						"qValue": "1",
						"foldChange": "1.3875"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".089598839883423",
						"qValue": ".985830071188835",
						"foldChange": "1.2637"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".09272192775768",
						"qValue": ".078115414298496",
						"foldChange": "1.4010"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".492613026072617",
						"qValue": ".200238176699823",
						"foldChange": "1.1241"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".103568196511652",
						"qValue": ".033571113234216",
						"foldChange": "1.2145"
					}
				]
			},
			{
				"metaboliteId": "0078dd1f-0000-0000-0000-000000000000",
				"chemicalId": "05f61edb-0000-0000-0000-000000000000",
				"chemicalName": "cerotoylcarnitine (C26)*",
				"plotName": null,
				"compoundId": "0000e0ac-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06347",
				"inChiKey": "KOCKWDDTAHPJSX-UHFFFAOYAQ",
				"kegg": null,
				"mass": 539.49136,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1654,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".385459916740676",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".397321334662666",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".420759713646796",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".225200455621878",
						"qValue": "1",
						"foldChange": "0.9339"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".717411999925149",
						"qValue": "1",
						"foldChange": "1.0376"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".11779455692574",
						"qValue": "1",
						"foldChange": "1.1386"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".099969349696044",
						"qValue": ".668256938010685",
						"foldChange": "0.9232"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".167594773735466",
						"qValue": ".787973217949622",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".783575189402815",
						"qValue": ".991621675660644",
						"foldChange": "1.0332"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".655002199543841",
						"qValue": "1",
						"foldChange": "1.0418"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".862951598259491",
						"qValue": "1",
						"foldChange": "1.0377"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".78367036332049",
						"qValue": "1",
						"foldChange": "0.9475"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".703789354441021",
						"qValue": "1",
						"foldChange": "1.0287"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".330788492261677",
						"qValue": "1",
						"foldChange": "1.2532"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".526532055124696",
						"qValue": "1",
						"foldChange": "1.2182"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".819973602730308",
						"qValue": "1",
						"foldChange": "1.0245"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".104344477875599",
						"qValue": "1",
						"foldChange": "1.4190"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".145814167788361",
						"qValue": "1",
						"foldChange": "1.3851"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".738872635684368",
						"qValue": "1",
						"foldChange": "0.9221"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".366900372455558",
						"qValue": "1",
						"foldChange": "1.2036"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".215784792431103",
						"qValue": ".985830071188835",
						"foldChange": "1.3052"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".01979584521559",
						"qValue": ".024826583648097",
						"foldChange": "1.9185"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001537656570991",
						"qValue": ".002784221185912",
						"foldChange": "1.3093"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000085072193401",
						"qValue": ".0002026523942",
						"foldChange": "1.9434"
					}
				]
			},
			{
				"metaboliteId": "0078dd20-0000-0000-0000-000000000000",
				"chemicalId": "05f61ede-0000-0000-0000-000000000000",
				"chemicalName": "eicosenoylcarnitine (C20:1)*",
				"plotName": null,
				"compoundId": "0000e0af-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 453.381805,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1668,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".449755359012177",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".104335786092674",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".432181731940232",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".271817662301887",
						"qValue": "1",
						"foldChange": "1.1106"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".466754443408327",
						"qValue": "1",
						"foldChange": "1.0651"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".707053805551864",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".947060819828941",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".51484235482766",
						"qValue": ".908244770314396",
						"foldChange": "0.9769"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".558335440828947",
						"qValue": ".957839854315834",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".045493092814373",
						"qValue": ".821720595261736",
						"foldChange": "1.2228"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".840653936642607",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".028686839488467",
						"qValue": ".614826369149758",
						"foldChange": "0.8298"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".636789633963625",
						"qValue": "1",
						"foldChange": "0.9575"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".33989471320808",
						"qValue": "1",
						"foldChange": "0.8684"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".59999402246634",
						"qValue": "1",
						"foldChange": "0.9070"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".237799754219945",
						"qValue": "1",
						"foldChange": "0.8806"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".731360455252002",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".424566381505754",
						"qValue": "1",
						"foldChange": "1.0861"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".197733647867365",
						"qValue": "1",
						"foldChange": "0.8552"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".137102645813491",
						"qValue": "1",
						"foldChange": "0.8158"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".776750774683445",
						"qValue": ".997573179629986",
						"foldChange": "0.9539"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".004060076863923",
						"qValue": ".007551984531473",
						"foldChange": "1.8577"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00000764097836",
						"qValue": ".000152189973079",
						"foldChange": "1.9889"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000007807584",
						"qValue": ".000000627635716",
						"foldChange": "3.1123"
					}
				]
			},
			{
				"metaboliteId": "0078dd21-0000-0000-0000-000000000000",
				"chemicalId": "05f61ee0-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linolenoylcarnitine (C20:3n3 or 6)*",
				"plotName": null,
				"compoundId": "0000e0b1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 449.350494,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1676,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".401198207522758",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".399656104451045",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".13661285392763",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".341240669540372",
						"qValue": "1",
						"foldChange": "1.0976"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".213174948276092",
						"qValue": "1",
						"foldChange": "1.1152"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".765181641310497",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".486691771833235",
						"qValue": "1",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".37830816279298",
						"qValue": ".875723678608451",
						"foldChange": "0.9801"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".851475455823425",
						"qValue": "1",
						"foldChange": "1.0074"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".906260798449669",
						"qValue": "1",
						"foldChange": "1.0217"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".040427924943444",
						"qValue": "1",
						"foldChange": "0.8726"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".030835063676458",
						"qValue": ".614826369149758",
						"foldChange": "0.8093"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".923593069868168",
						"qValue": "1",
						"foldChange": "0.9415"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".55825492083718",
						"qValue": "1",
						"foldChange": "0.8280"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".611884188503106",
						"qValue": "1",
						"foldChange": "0.8795"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".375820955417683",
						"qValue": "1",
						"foldChange": "0.8137"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".333876225639331",
						"qValue": "1",
						"foldChange": "0.7768"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".893414377557873",
						"qValue": "1",
						"foldChange": "0.9547"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".265093247537013",
						"qValue": "1",
						"foldChange": "0.8082"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".033425415104212",
						"qValue": "1",
						"foldChange": "0.6172"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".248112110584617",
						"qValue": ".985830071188835",
						"foldChange": "0.7637"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".791940978316904",
						"qValue": ".344316995840098",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".071956486982324",
						"qValue": ".048535883584844",
						"foldChange": "1.3124"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004773277679678",
						"qValue": ".003132980315785",
						"foldChange": "1.8854"
					}
				]
			},
			{
				"metaboliteId": "0078dd22-0000-0000-0000-000000000000",
				"chemicalId": "05f61ee5-0000-0000-0000-000000000000",
				"chemicalName": "docosahexaenoylcarnitine (C22:6)*",
				"plotName": null,
				"compoundId": "0000e0b3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 471.334869,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1683,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".137636887965953",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".639655520955966",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".11913363802266",
						"qValue": ".834275595151197",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".430274408843234",
						"qValue": "1",
						"foldChange": "1.1600"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".157987585159271",
						"qValue": "1",
						"foldChange": "1.2815"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".525990367055659",
						"qValue": "1",
						"foldChange": "1.2102"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".480654866661442",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".943376549291931",
						"qValue": "1",
						"foldChange": "1.1183"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".437945742173426",
						"qValue": ".957839854315834",
						"foldChange": "1.3411"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".165344515567204",
						"qValue": "1",
						"foldChange": "1.2743"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".35370748667292",
						"qValue": "1",
						"foldChange": "0.9116"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".023194347282541",
						"qValue": ".614826369149758",
						"foldChange": "0.6898"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".121728158498437",
						"qValue": "1",
						"foldChange": "0.6656"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".619236308038007",
						"qValue": "1",
						"foldChange": "0.8405"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".311672941555758",
						"qValue": "1",
						"foldChange": "1.2628"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".039569413750933",
						"qValue": "1",
						"foldChange": "0.4696"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".804326209343469",
						"qValue": "1",
						"foldChange": "0.8992"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".077292139853956",
						"qValue": ".822195137696456",
						"foldChange": "1.9150"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".043056273090767",
						"qValue": "1",
						"foldChange": "0.6341"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".17852402717988",
						"qValue": "1",
						"foldChange": "0.6127"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".528947161136717",
						"qValue": ".985830071188835",
						"foldChange": "0.9662"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".020337210990441",
						"qValue": ".025409279989444",
						"foldChange": "3.5576"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003966325794024",
						"qValue": ".005514500099902",
						"foldChange": "5.0271"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000012691534279",
						"qValue": ".0000703618314",
						"foldChange": "5.7979"
					}
				]
			},
			{
				"metaboliteId": "0078dd23-0000-0000-0000-000000000000",
				"chemicalId": "05f61ed7-0000-0000-0000-000000000000",
				"chemicalName": "linolenoylcarnitine (C18:3)*",
				"plotName": null,
				"compoundId": "0000e0a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 421.319214,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1673,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".438401423190976",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".183161496274573",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".363430619510181",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".591924219535946",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".622133218901191",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".965382123927946",
						"qValue": "1",
						"foldChange": "1.0262"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".557659990012587",
						"qValue": "1",
						"foldChange": "1.0977"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".708144155598871",
						"qValue": ".950986052805043",
						"foldChange": "1.0567"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".338376423974895",
						"qValue": ".957839854315834",
						"foldChange": "0.9389"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".866000688182146",
						"qValue": "1",
						"foldChange": "1.0216"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".028894606124653",
						"qValue": ".933767468184815",
						"foldChange": "0.8158"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".042608378783798",
						"qValue": ".684145855566273",
						"foldChange": "0.8135"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".37591126011158",
						"qValue": "1",
						"foldChange": "0.8612"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".947288487276435",
						"qValue": "1",
						"foldChange": "0.9693"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".355103053550033",
						"qValue": "1",
						"foldChange": "1.1255"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".383310722286974",
						"qValue": "1",
						"foldChange": "0.8444"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".694515016885547",
						"qValue": "1",
						"foldChange": "0.9145"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".656329384656535",
						"qValue": "1",
						"foldChange": "1.0830"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".147686928307693",
						"qValue": "1",
						"foldChange": "0.7962"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".08111847947396",
						"qValue": "1",
						"foldChange": "0.7228"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".690160691159553",
						"qValue": ".992105993541858",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".382669926206254",
						"qValue": ".208685604074736",
						"foldChange": "1.1602"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".508837146476953",
						"qValue": ".204743762557947",
						"foldChange": "1.1180"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01035333132919",
						"qValue": ".005585793215568",
						"foldChange": "1.5342"
					}
				]
			},
			{
				"metaboliteId": "0078dd24-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e6-0000-0000-0000-000000000000",
				"chemicalName": "3-acetylphenol sulfate",
				"plotName": null,
				"compoundId": "0000be35-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CGFRVKXGZRODPA-UHFFFAOYAL",
				"kegg": null,
				"mass": 216.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5247,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".532963588895256",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".504564594969766",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".981712668769783",
						"qValue": ".998133191305956",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".698543341319511",
						"qValue": "1",
						"foldChange": "1.5782"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".952028814137737",
						"qValue": "1",
						"foldChange": "1.7988"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".654670897348245",
						"qValue": "1",
						"foldChange": "1.6523"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".962241880579942",
						"qValue": "1",
						"foldChange": "1.7112"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".36873875422536",
						"qValue": ".875723678608451",
						"foldChange": "2.4091"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".344378529363037",
						"qValue": ".957839854315834",
						"foldChange": "2.1462"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".650493943272114",
						"qValue": "1",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".847016814972651",
						"qValue": "1",
						"foldChange": "1.2948"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".518852039105456",
						"qValue": "1",
						"foldChange": "1.1999"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".666809936688639",
						"qValue": "1",
						"foldChange": "1.3760"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".359643574872842",
						"qValue": "1",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".599757577856505",
						"qValue": "1",
						"foldChange": "0.6897"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".513192787158208",
						"qValue": "1",
						"foldChange": "0.9568"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".390377635917405",
						"qValue": "1",
						"foldChange": "1.4969"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".803602088191278",
						"qValue": "1",
						"foldChange": "1.5645"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".343331907527377",
						"qValue": "1",
						"foldChange": "1.5867"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".315323099118475",
						"qValue": "1",
						"foldChange": "1.1371"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".910831839482868",
						"qValue": ".997573179629986",
						"foldChange": "0.7166"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".229029610895425",
						"qValue": ".146389090979693",
						"foldChange": "2.2581"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".22382715025737",
						"qValue": ".113116401496229",
						"foldChange": "1.9321"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".412795950184229",
						"qValue": ".095082591706271",
						"foldChange": "2.8423"
					}
				]
			},
			{
				"metaboliteId": "0078dd25-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9d8-0000-0000-0000-000000000000",
				"chemicalName": "dopamine 4-sulfate",
				"plotName": null,
				"compoundId": "0000bd16-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04148",
				"inChiKey": "DEKNNWJXAQTLFA-UHFFFAOYAD",
				"kegg": "C13691",
				"mass": 0.0,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 205,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".614153943497398",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".265344241916848",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".157413415740192",
						"qValue": ".892830373251164",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".785499838419399",
						"qValue": "1",
						"foldChange": "1.2455"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".76683076783347",
						"qValue": "1",
						"foldChange": "1.7686"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".570061517184362",
						"qValue": "1",
						"foldChange": "1.8681"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007742301097658",
						"qValue": ".237900912197889",
						"foldChange": "0.6801"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".012124261993834",
						"qValue": ".567215491640796",
						"foldChange": "0.8912"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".863683149512681",
						"qValue": "1",
						"foldChange": "1.6144"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".728244257144339",
						"qValue": "1",
						"foldChange": "1.2301"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".809814092169562",
						"qValue": "1",
						"foldChange": "0.9863"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".556964985832256",
						"qValue": "1",
						"foldChange": "0.7936"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".291430469037743",
						"qValue": "1",
						"foldChange": "3.9537"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".740647770145624",
						"qValue": "1",
						"foldChange": "0.4969"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".492629914380079",
						"qValue": "1",
						"foldChange": "0.1257"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".353959216595106",
						"qValue": "1",
						"foldChange": "0.5735"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".399951561607236",
						"qValue": "1",
						"foldChange": "1.0774"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".081267640210468",
						"qValue": ".834952786556829",
						"foldChange": "1.8787"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".205730068513958",
						"qValue": "1",
						"foldChange": "0.5832"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".91188428625277",
						"qValue": "1",
						"foldChange": "0.7407"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".264934557504675",
						"qValue": ".985830071188835",
						"foldChange": "1.2700"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001143515434356",
						"qValue": ".002898421821409",
						"foldChange": "3.3909"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000881298276485",
						"qValue": ".002039209562601",
						"foldChange": "4.3670"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000335300270392",
						"qValue": ".00047706380293",
						"foldChange": "5.1724"
					}
				]
			},
			{
				"metaboliteId": "0078dd26-0000-0000-0000-000000000000",
				"chemicalId": "000003e9-0000-0000-0000-000000000000",
				"chemicalName": "trans-4-hydroxyproline",
				"plotName": null,
				"compoundId": "00007e32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00725",
				"inChiKey": "PMMYEEVYMWASQN-DMTCNVIQBF",
				"kegg": "C01157",
				"mass": 131.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 441,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".461305108925818",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".268614669543084",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".34151296923028",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".348035353116272",
						"qValue": "1",
						"foldChange": "0.9910"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".214252561659408",
						"qValue": "1",
						"foldChange": "0.9177"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".757193303261008",
						"qValue": "1",
						"foldChange": "1.0156"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".870487012281816",
						"qValue": "1",
						"foldChange": "1.0624"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".528011939697138",
						"qValue": ".910963185640849",
						"foldChange": "1.1270"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".639239862686136",
						"qValue": ".973034272612281",
						"foldChange": "1.0740"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".087302160675502",
						"qValue": ".92867673418565",
						"foldChange": "0.8620"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".795091785446112",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".050440936217564",
						"qValue": ".727024127920748",
						"foldChange": "1.2158"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".931497563316845",
						"qValue": "1",
						"foldChange": "0.9786"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".389692999802191",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".335462435710155",
						"qValue": "1",
						"foldChange": "1.1277"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".472751655436978",
						"qValue": "1",
						"foldChange": "1.1139"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".810953523402271",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".651433071197844",
						"qValue": "1",
						"foldChange": "0.8969"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".206406438059608",
						"qValue": "1",
						"foldChange": "1.2236"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".058913300712111",
						"qValue": "1",
						"foldChange": "1.2717"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".462319615479847",
						"qValue": ".985830071188835",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".148402340820072",
						"qValue": ".108945939385488",
						"foldChange": "0.8222"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".004414309471255",
						"qValue": ".005928677879055",
						"foldChange": "0.6807"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001537733436242",
						"qValue": ".001388935445069",
						"foldChange": "0.5841"
					}
				]
			},
			{
				"metaboliteId": "0078dd27-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e7-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPS (18:0)*",
				"plotName": null,
				"compoundId": "0000b38e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZPDQFUYPBVXUKS-KEKNWZKVBV",
				"kegg": null,
				"mass": 525.306669,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2409,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".855505713748947",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".759383962166695",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".787951786563314",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".608297073273716",
						"qValue": "1",
						"foldChange": "2.2263"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".15499310335142",
						"qValue": "1",
						"foldChange": "0.8481"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".357552908731398",
						"qValue": "1",
						"foldChange": "1.9904"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".961524774198335",
						"qValue": "1",
						"foldChange": "1.1850"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".905774747111693",
						"qValue": "1",
						"foldChange": "1.4086"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".944085615994205",
						"qValue": "1",
						"foldChange": "1.5187"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".900818081068342",
						"qValue": "1",
						"foldChange": "1.3486"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".803917034071964",
						"qValue": "1",
						"foldChange": "1.4175"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".709351400295206",
						"qValue": "1",
						"foldChange": "1.7998"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".79018126918482",
						"qValue": "1",
						"foldChange": "0.6131"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".680211975976559",
						"qValue": "1",
						"foldChange": "0.5571"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".869204763386836",
						"qValue": "1",
						"foldChange": "0.9086"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".841750092914672",
						"qValue": "1",
						"foldChange": "0.8407"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".957419823733643",
						"qValue": "1",
						"foldChange": "0.7727"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".804085555301103",
						"qValue": "1",
						"foldChange": "0.9191"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".293615518097547",
						"qValue": "1",
						"foldChange": "1.6494"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".236274013984754",
						"qValue": "1",
						"foldChange": "1.3079"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".843065819999881",
						"qValue": ".997573179629986",
						"foldChange": "0.7929"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000629585074505",
						"qValue": ".001834274195873",
						"foldChange": "5.4374"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".063346703315792",
						"qValue": ".043932039832042",
						"foldChange": "2.3728"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".044764003232851",
						"qValue": ".01730041788549",
						"foldChange": "2.5844"
					}
				]
			},
			{
				"metaboliteId": "0078dd28-0000-0000-0000-000000000000",
				"chemicalId": "05f5e719-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoleoyl-GPC (16:1)*",
				"plotName": null,
				"compoundId": "0000b80e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10383",
				"inChiKey": "GKXPPJQBVCKAGD-DJYGDJEFBY",
				"kegg": null,
				"mass": 493.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2320,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".125167225412591",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".456832687728737",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".016967241238439",
						"qValue": ".601630095579632",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".239106024895855",
						"qValue": "1",
						"foldChange": "1.6083"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".008342354894315",
						"qValue": "1",
						"foldChange": "2.7199"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".125430604961564",
						"qValue": "1",
						"foldChange": "1.5619"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".113290805446218",
						"qValue": ".678406740976754",
						"foldChange": "1.4744"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".884472636291914",
						"qValue": "1",
						"foldChange": "1.0865"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".14890633485817",
						"qValue": ".957839854315834",
						"foldChange": "0.8022"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".047577544319439",
						"qValue": ".821720595261736",
						"foldChange": "0.7540"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".47102771171856",
						"qValue": "1",
						"foldChange": "0.9012"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".197544564962333",
						"qValue": "1",
						"foldChange": "1.4945"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".029044763969804",
						"qValue": "1",
						"foldChange": "1.3248"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".016754401256201",
						"qValue": "1",
						"foldChange": "1.4053"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".72325103110722",
						"qValue": "1",
						"foldChange": "1.0607"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".014851069901015",
						"qValue": "1",
						"foldChange": "1.8756"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".927186112714905",
						"qValue": "1",
						"foldChange": "1.1299"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".014286978569275",
						"qValue": ".736033652800007",
						"foldChange": "0.6024"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".8972504328541",
						"qValue": "1",
						"foldChange": "1.0577"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".82324755275265",
						"qValue": "1",
						"foldChange": "0.8319"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".723513970052426",
						"qValue": ".997573179629986",
						"foldChange": "0.7866"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".002905563496721",
						"qValue": ".006012529587775",
						"foldChange": "3.3165"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000692724555651",
						"qValue": ".00182229978382",
						"foldChange": "2.6018"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001423862817903",
						"qValue": ".001335272490554",
						"foldChange": "2.9648"
					}
				]
			},
			{
				"metaboliteId": "0078dd29-0000-0000-0000-000000000000",
				"chemicalId": "05f5e628-0000-0000-0000-000000000000",
				"chemicalName": "erythronate*",
				"plotName": null,
				"compoundId": "0000a5b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00613",
				"inChiKey": "JPIJQSOTBSSVTP-PWNYCUMCBA",
				"kegg": null,
				"mass": 136.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1275,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".99300862062752",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".764793443593315",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".816802688038387",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".41571440770067",
						"qValue": "1",
						"foldChange": "0.9598"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".96160525500569",
						"qValue": "1",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".443551278536859",
						"qValue": "1",
						"foldChange": "1.0872"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".977646190913203",
						"qValue": "1",
						"foldChange": "1.0335"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".355999751846991",
						"qValue": ".862957753606784",
						"foldChange": "0.9808"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".341782240417669",
						"qValue": ".957839854315834",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".943807060908618",
						"qValue": "1",
						"foldChange": "1.0038"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".769567624806421",
						"qValue": "1",
						"foldChange": "0.9912"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".823903534991521",
						"qValue": "1",
						"foldChange": "0.9242"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".925818718643368",
						"qValue": "1",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".982479121589424",
						"qValue": "1",
						"foldChange": "0.9388"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".91028415037328",
						"qValue": "1",
						"foldChange": "0.9540"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".524504105206428",
						"qValue": "1",
						"foldChange": "1.0652"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".666574403362544",
						"qValue": "1",
						"foldChange": "1.0681"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".860228351819185",
						"qValue": "1",
						"foldChange": "1.0027"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".657355545541606",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".855246872133031",
						"qValue": "1",
						"foldChange": "0.9022"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".80787499874552",
						"qValue": ".997573179629986",
						"foldChange": "0.9402"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003374846260991",
						"qValue": ".006530423897099",
						"foldChange": "1.6025"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006330014058739",
						"qValue": ".007447687312721",
						"foldChange": "1.5424"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002450483873633",
						"qValue": ".001979792971323",
						"foldChange": "1.5713"
					}
				]
			},
			{
				"metaboliteId": "0078dd2a-0000-0000-0000-000000000000",
				"chemicalId": "00000110-0000-0000-0000-000000000000",
				"chemicalName": "corticosterone",
				"plotName": null,
				"compoundId": "0000175f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01547",
				"inChiKey": "OMFXVFTZEKFJBZ-HJTSIMOOBD",
				"kegg": "C02140",
				"mass": 346.21441,
				"pathwayType": "ANIMAL",
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2981,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".041491269650991",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".451891895216133",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".959796374540367",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".63802505732806",
						"qValue": "1",
						"foldChange": "1.0316"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".629093820368188",
						"qValue": "1",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".989958250732284",
						"qValue": "1",
						"foldChange": "1.4306"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".850971885423494",
						"qValue": "1",
						"foldChange": "1.5295"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".278768547116054",
						"qValue": ".825038702902081",
						"foldChange": "1.0115"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".369149769498127",
						"qValue": ".957839854315834",
						"foldChange": "0.8993"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".790774233360058",
						"qValue": "1",
						"foldChange": "1.5397"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".608564030833459",
						"qValue": "1",
						"foldChange": "1.1269"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".437881876844376",
						"qValue": "1",
						"foldChange": "0.8127"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".095226852107027",
						"qValue": "1",
						"foldChange": "2.9898"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".049726991767392",
						"qValue": "1",
						"foldChange": "1.8554"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".680000925677813",
						"qValue": "1",
						"foldChange": "0.6206"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".063097794621848",
						"qValue": "1",
						"foldChange": "3.3042"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".015896778363727",
						"qValue": "1",
						"foldChange": "2.6592"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".474248820774703",
						"qValue": "1",
						"foldChange": "0.8048"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".191630473542881",
						"qValue": "1",
						"foldChange": "2.2545"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".054144958389058",
						"qValue": "1",
						"foldChange": "2.1994"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".46209541957142",
						"qValue": ".985830071188835",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".954587070589349",
						"qValue": ".386375057388733",
						"foldChange": "0.6237"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".809596018661038",
						"qValue": ".291294153064217",
						"foldChange": "0.5960"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".23031873422666",
						"qValue": ".061105126373927",
						"foldChange": "0.2590"
					}
				]
			},
			{
				"metaboliteId": "0078dd2b-0000-0000-0000-000000000000",
				"chemicalId": "00000457-0000-0000-0000-000000000000",
				"chemicalName": "vanillylmandelate (VMA)",
				"plotName": null,
				"compoundId": "0000061f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00291",
				"inChiKey": "CGQCWMIAEPEHNQ-UHFFFAOYAY",
				"kegg": "C05584",
				"mass": 198.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 184,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".950999104219917",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".52932170260673",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".789176925083941",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".388964468642069",
						"qValue": "1",
						"foldChange": "0.9529"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".820149111960874",
						"qValue": "1",
						"foldChange": "0.9804"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".524712997530151",
						"qValue": "1",
						"foldChange": "1.2013"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".936569079756459",
						"qValue": "1",
						"foldChange": "1.1136"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".446610951498092",
						"qValue": ".900630141528144",
						"foldChange": "1.0213"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".400985411561641",
						"qValue": ".957839854315834",
						"foldChange": "0.9621"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".941126360293096",
						"qValue": "1",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".35282685713908",
						"qValue": "1",
						"foldChange": "0.9459"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".316392214616843",
						"qValue": "1",
						"foldChange": "0.8598"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".879328884243293",
						"qValue": "1",
						"foldChange": "0.9883"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".898002370626764",
						"qValue": "1",
						"foldChange": "0.9039"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".987430585628458",
						"qValue": "1",
						"foldChange": "0.9147"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".415382326507633",
						"qValue": "1",
						"foldChange": "1.1182"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".44900658876992",
						"qValue": "1",
						"foldChange": "1.1031"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".988981247048172",
						"qValue": "1",
						"foldChange": "0.9865"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".841682296574526",
						"qValue": "1",
						"foldChange": "1.0229"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".702582211687546",
						"qValue": "1",
						"foldChange": "0.8786"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".843124659013495",
						"qValue": ".997573179629986",
						"foldChange": "0.8590"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00040023581513",
						"qValue": ".001338528408034",
						"foldChange": "2.5864"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000232447655705",
						"qValue": ".000898002474794",
						"foldChange": "2.8938"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000179006321233",
						"qValue": ".000309461356268",
						"foldChange": "2.5975"
					}
				]
			},
			{
				"metaboliteId": "0078dd2c-0000-0000-0000-000000000000",
				"chemicalId": "00000147-0000-0000-0000-000000000000",
				"chemicalName": "flavin adenine dinucleotide (FAD)",
				"plotName": null,
				"compoundId": "00000856-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01248",
				"inChiKey": "VWWQXMAJTJZDQX-UYBVJOGSBL",
				"kegg": "C00016",
				"mass": 785.157134,
				"pathwayType": "ANIMAL",
				"subPathway": "Riboflavin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3942,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".332990415891402",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".562288219892542",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".801201335019313",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".236996053479333",
						"qValue": "1",
						"foldChange": "1.2052"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".354976273348063",
						"qValue": "1",
						"foldChange": "0.8158"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".793304960383155",
						"qValue": "1",
						"foldChange": "1.7039"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".70626882505224",
						"qValue": "1",
						"foldChange": "1.2697"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".490670977302108",
						"qValue": ".902562957068497",
						"foldChange": "1.1255"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".288326987859397",
						"qValue": ".957839854315834",
						"foldChange": "0.9888"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".805751293743858",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".796664078167013",
						"qValue": "1",
						"foldChange": "1.0562"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".990610910963126",
						"qValue": "1",
						"foldChange": "1.0862"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".458542544939563",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".804026093190446",
						"qValue": "1",
						"foldChange": "0.9858"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".331844080640121",
						"qValue": "1",
						"foldChange": "1.2080"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".437976281946801",
						"qValue": "1",
						"foldChange": "1.0083"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".268479350302478",
						"qValue": "1",
						"foldChange": "1.0971"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".699939557682101",
						"qValue": "1",
						"foldChange": "1.0881"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".635633177141046",
						"qValue": "1",
						"foldChange": "0.9496"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".392882204003405",
						"qValue": "1",
						"foldChange": "1.2630"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".184020438355928",
						"qValue": ".985830071188835",
						"foldChange": "1.3301"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000068015242713",
						"qValue": ".000381681472491",
						"foldChange": "4.9647"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000916160189246",
						"qValue": ".00207697567767",
						"foldChange": "3.0016"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000067669785626",
						"qValue": ".000172721742087",
						"foldChange": "3.7473"
					}
				]
			},
			{
				"metaboliteId": "0078dd2d-0000-0000-0000-000000000000",
				"chemicalId": "000007ed-0000-0000-0000-000000000000",
				"chemicalName": "azelate (C9-DC)",
				"plotName": null,
				"compoundId": "000047ba-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00784",
				"inChiKey": "BDJRBEYXGGNYIS-UHFFFAOYAK",
				"kegg": "C08261",
				"mass": 188.10486,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1503,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".498019886810012",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".980575869831983",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".715091588570631",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".480891951476755",
						"qValue": "1",
						"foldChange": "1.8274"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".678123893046614",
						"qValue": "1",
						"foldChange": "0.8820"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".264938361897541",
						"qValue": "1",
						"foldChange": "0.8757"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".545830405584186",
						"qValue": "1",
						"foldChange": "1.8726"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".870114262627975",
						"qValue": "1",
						"foldChange": "1.7150"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".443470881527333",
						"qValue": ".957839854315834",
						"foldChange": "3.2626"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".843042361324822",
						"qValue": "1",
						"foldChange": "1.4621"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".678868144862171",
						"qValue": "1",
						"foldChange": "2.1988"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".828766519709312",
						"qValue": "1",
						"foldChange": "1.3485"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".722562407447194",
						"qValue": "1",
						"foldChange": "1.3288"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".447252904770034",
						"qValue": "1",
						"foldChange": "0.6632"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".263254857758036",
						"qValue": "1",
						"foldChange": "0.4991"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".450999794868915",
						"qValue": "1",
						"foldChange": "0.6829"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".244985803357124",
						"qValue": "1",
						"foldChange": "0.3749"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".641685465178396",
						"qValue": "1",
						"foldChange": "0.5489"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".396717447768096",
						"qValue": "1",
						"foldChange": "1.5825"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".95154058646161",
						"qValue": "1",
						"foldChange": "0.7275"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".377356225884777",
						"qValue": ".985830071188835",
						"foldChange": "0.4597"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00000231497268",
						"qValue": ".000069678697434",
						"foldChange": "0.1290"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000100624320448",
						"qValue": ".000617602035247",
						"foldChange": "0.1993"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000009160899015",
						"qValue": ".000064533115359",
						"foldChange": "0.1377"
					}
				]
			},
			{
				"metaboliteId": "0078dd2e-0000-0000-0000-000000000000",
				"chemicalId": "00000088-0000-0000-0000-000000000000",
				"chemicalName": "cholate",
				"plotName": null,
				"compoundId": "0000593a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00619",
				"inChiKey": "BHQCQFFYRZLCQQ-OELDTZBJBZ",
				"kegg": "C00695",
				"mass": 408.287574,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3087,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".321312966182457",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".031380741443056",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".233872418378041",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".108571267902605",
						"qValue": "1",
						"foldChange": "0.9329"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".599176845142672",
						"qValue": "1",
						"foldChange": "1.2257"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".274240037371843",
						"qValue": "1",
						"foldChange": "2.6354"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".75824924043311",
						"qValue": "1",
						"foldChange": "1.6661"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".217118202277019",
						"qValue": ".825038702902081",
						"foldChange": "2.3567"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".351353032005697",
						"qValue": ".957839854315834",
						"foldChange": "1.7433"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".007854041691768",
						"qValue": ".668378947969462",
						"foldChange": "0.7118"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".336224028545873",
						"qValue": "1",
						"foldChange": "0.8650"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".077651108238014",
						"qValue": ".770965175880612",
						"foldChange": "7.9320"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".146982556128656",
						"qValue": "1",
						"foldChange": "0.5961"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".432415122632312",
						"qValue": "1",
						"foldChange": "0.6338"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".540765711357056",
						"qValue": "1",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".996967972888255",
						"qValue": "1",
						"foldChange": "0.2923"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".089120755802196",
						"qValue": "1",
						"foldChange": "0.1111"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".082421133541282",
						"qValue": ".834952786556829",
						"foldChange": "0.3800"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".868696749530043",
						"qValue": "1",
						"foldChange": "0.7464"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".25458134364215",
						"qValue": "1",
						"foldChange": "0.4527"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".312811303801723",
						"qValue": ".985830071188835",
						"foldChange": "0.6065"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".205164111923625",
						"qValue": ".136401419636262",
						"foldChange": "0.7123"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".172306051119686",
						"qValue": ".093114203787571",
						"foldChange": "0.2689"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".982898029212427",
						"qValue": ".190622841665902",
						"foldChange": "1.4686"
					}
				]
			},
			{
				"metaboliteId": "0078dd2f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e6-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltryptophan",
				"plotName": null,
				"compoundId": "000084a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13713",
				"inChiKey": "DZTHIGRZJZPRDV-LBPRGKRZBS",
				"kegg": "C03137",
				"mass": 246.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".403759308440166",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".722887599242114",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".880351305240534",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".762883287216693",
						"qValue": "1",
						"foldChange": "1.0397"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".634502691143545",
						"qValue": "1",
						"foldChange": "1.1197"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".438133056773853",
						"qValue": "1",
						"foldChange": "1.2138"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".837369870538159",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".528087737839035",
						"qValue": ".910963185640849",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".669716429587954",
						"qValue": ".976023509428735",
						"foldChange": "1.0025"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".401205190866226",
						"qValue": "1",
						"foldChange": "0.9012"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".678798307158633",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".668869477399464",
						"qValue": "1",
						"foldChange": "1.0382"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".297420673545382",
						"qValue": "1",
						"foldChange": "1.0689"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".156352927210563",
						"qValue": "1",
						"foldChange": "1.1754"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".649946426943955",
						"qValue": "1",
						"foldChange": "1.0997"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".268398753713153",
						"qValue": "1",
						"foldChange": "1.2398"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".279459070313466",
						"qValue": "1",
						"foldChange": "1.2172"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".971480854395351",
						"qValue": "1",
						"foldChange": "0.9818"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".701299010468607",
						"qValue": "1",
						"foldChange": "1.0332"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".370396237676505",
						"qValue": "1",
						"foldChange": "1.1624"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".582777385822675",
						"qValue": ".985830071188835",
						"foldChange": "1.1251"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003005148986562",
						"qValue": ".006104150629979",
						"foldChange": "3.0037"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00004590159244",
						"qValue": ".000377054242849",
						"foldChange": "2.7582"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".003195939691224",
						"qValue": ".002346256378146",
						"foldChange": "2.2845"
					}
				]
			},
			{
				"metaboliteId": "0078dd30-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fc-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylisoleucine",
				"plotName": null,
				"compoundId": "000084af-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61684",
				"inChiKey": "JDTWZSUNGHMMJM-MSZQBOFLBT",
				"kegg": null,
				"mass": 173.105193,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 331,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".174840949768637",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".401670016776424",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".769792117142534",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".37880300131368",
						"qValue": "1",
						"foldChange": "0.9493"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".799711260756959",
						"qValue": "1",
						"foldChange": "1.0563"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".258525242721307",
						"qValue": "1",
						"foldChange": "1.1262"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".514198602375721",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".224424512420825",
						"qValue": ".825038702902081",
						"foldChange": "0.9431"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".568962544484115",
						"qValue": ".957839854315834",
						"foldChange": "0.9822"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".475270637913893",
						"qValue": "1",
						"foldChange": "0.9600"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".451417539082097",
						"qValue": "1",
						"foldChange": "0.9633"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".96852606005583",
						"qValue": "1",
						"foldChange": "0.9550"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".087220583964951",
						"qValue": "1",
						"foldChange": "1.1220"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".122773506758717",
						"qValue": "1",
						"foldChange": "1.1179"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".934062301504149",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".062279154990394",
						"qValue": "1",
						"foldChange": "1.2238"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".106754249156852",
						"qValue": "1",
						"foldChange": "1.2027"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".86786682400344",
						"qValue": "1",
						"foldChange": "0.9828"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".390233807226586",
						"qValue": "1",
						"foldChange": "1.0986"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".346277016907826",
						"qValue": "1",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".893341081031839",
						"qValue": ".997573179629986",
						"foldChange": "0.9709"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".040689879983557",
						"qValue": ".043458181914969",
						"foldChange": "1.5458"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005333184031787",
						"qValue": ".00672913750047",
						"foldChange": "1.3625"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".136592753481825",
						"qValue": ".041513848244746",
						"foldChange": "1.2127"
					}
				]
			},
			{
				"metaboliteId": "0078dd31-0000-0000-0000-000000000000",
				"chemicalId": "05f5e607-0000-0000-0000-000000000000",
				"chemicalName": "epiandrosterone sulfate",
				"plotName": null,
				"compoundId": "000084b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZMITXKRGXGRMKS-KZBQTZLVBC",
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3014,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".318113356439207",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".233769911809232",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".094356621825292",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".042788267121782",
						"qValue": "1",
						"foldChange": "0.5971"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".043744385552336",
						"qValue": "1",
						"foldChange": "0.6037"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".992139495702847",
						"qValue": "1",
						"foldChange": "1.0155"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".200837605436138",
						"qValue": ".802407522188514",
						"foldChange": "9.4199"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".324239490337661",
						"qValue": ".831655911352372",
						"foldChange": "5.7765"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".76473727649413",
						"qValue": ".98622264532067",
						"foldChange": "0.9650"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".098649828437672",
						"qValue": ".987658870593633",
						"foldChange": "0.6449"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".177778183336013",
						"qValue": "1",
						"foldChange": "0.7139"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".75304350854153",
						"qValue": "1",
						"foldChange": "1.0367"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".011941260600248",
						"qValue": "1",
						"foldChange": "0.5068"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".520049348943258",
						"qValue": "1",
						"foldChange": "0.8274"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".066428441311764",
						"qValue": "1",
						"foldChange": "1.6327"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".559281709107343",
						"qValue": "1",
						"foldChange": "0.8982"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".636096508108279",
						"qValue": "1",
						"foldChange": "0.7279"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".935101416590465",
						"qValue": "1",
						"foldChange": "0.8104"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".445032430060404",
						"qValue": "1",
						"foldChange": "0.7914"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".775962371603763",
						"qValue": "1",
						"foldChange": "0.7630"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".652515761266064",
						"qValue": ".985830071188835",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".794572789957844",
						"qValue": ".344316995840098",
						"foldChange": "0.9515"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".165960752567065",
						"qValue": ".090740323989308",
						"foldChange": "0.6899"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".627153092161921",
						"qValue": ".131633310576547",
						"foldChange": "1.1996"
					}
				]
			},
			{
				"metaboliteId": "0078dd32-0000-0000-0000-000000000000",
				"chemicalId": "000001e2-0000-0000-0000-000000000000",
				"chemicalName": "lactate",
				"plotName": null,
				"compoundId": "0000020f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00190",
				"inChiKey": "JVTAAEKCZFNVCJ-UHFFFAOYAX",
				"kegg": "C00186",
				"mass": 90.031695,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".366944157213647",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".420715182497427",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".816187460180262",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".652214738073858",
						"qValue": "1",
						"foldChange": "0.9809"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".673301279825644",
						"qValue": "1",
						"foldChange": "0.9719"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".976742404149792",
						"qValue": "1",
						"foldChange": "1.0154"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".795474050894936",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".179689274945032",
						"qValue": ".804818805148537",
						"foldChange": "0.9458"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".1113052141608",
						"qValue": ".893591860856987",
						"foldChange": "0.9294"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".754869187946471",
						"qValue": "1",
						"foldChange": "0.9912"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".640485959482817",
						"qValue": "1",
						"foldChange": "0.9715"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".876703026550635",
						"qValue": "1",
						"foldChange": "0.9682"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".623119618025881",
						"qValue": "1",
						"foldChange": "0.9102"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".218269663881276",
						"qValue": "1",
						"foldChange": "0.8111"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".4276682717869",
						"qValue": "1",
						"foldChange": "0.8910"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".962618829541884",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".245786264564484",
						"qValue": "1",
						"foldChange": "0.8368"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".252540289652358",
						"qValue": "1",
						"foldChange": "0.8500"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".299968771354238",
						"qValue": "1",
						"foldChange": "0.8265"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".202483864631118",
						"qValue": "1",
						"foldChange": "0.7652"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".758172979885877",
						"qValue": ".997573179629986",
						"foldChange": "0.9258"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".165104613422025",
						"qValue": ".11755824508034",
						"foldChange": "0.8755"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".817837881737512",
						"qValue": ".292748630054708",
						"foldChange": "0.9699"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".487902199403219",
						"qValue": ".109100035313208",
						"foldChange": "1.1553"
					}
				]
			},
			{
				"metaboliteId": "0078dd33-0000-0000-0000-000000000000",
				"chemicalId": "00000169-0000-0000-0000-000000000000",
				"chemicalName": "inosine",
				"plotName": null,
				"compoundId": "00000463-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00195",
				"inChiKey": "UGQMRVRMYYASKQ-DGPXGRDGBU",
				"kegg": "C00294",
				"mass": 268.08077,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3743,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".453132968525661",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".914753648170085",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".798530994847871",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".960335736199854",
						"qValue": "1",
						"foldChange": "15.5646"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".831911506836732",
						"qValue": "1",
						"foldChange": "1.2625"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".793368200005731",
						"qValue": "1",
						"foldChange": "6.2866"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".220010821085088",
						"qValue": ".842371543132976",
						"foldChange": "2.5899"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".787729067274819",
						"qValue": ".978751596872832",
						"foldChange": "2.5455"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".335952114796225",
						"qValue": ".957839854315834",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".706074723446871",
						"qValue": "1",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".84930662379352",
						"qValue": "1",
						"foldChange": "1.0599"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".851393084402809",
						"qValue": "1",
						"foldChange": "1.2956"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".783851720142537",
						"qValue": "1",
						"foldChange": "1.1152"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".354839945784088",
						"qValue": "1",
						"foldChange": "1.2793"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".494108807978265",
						"qValue": "1",
						"foldChange": "1.1471"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".143274509823848",
						"qValue": "1",
						"foldChange": "2.0984"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".547333375090032",
						"qValue": "1",
						"foldChange": "0.8303"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".418209914503261",
						"qValue": "1",
						"foldChange": "0.3957"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".751453051829915",
						"qValue": "1",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".586994420426949",
						"qValue": "1",
						"foldChange": "0.9341"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".802531308182865",
						"qValue": ".997573179629986",
						"foldChange": "0.9063"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".236112587789007",
						"qValue": ".150130368777605",
						"foldChange": "0.9080"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".274819699675434",
						"qValue": ".131897582151521",
						"foldChange": "0.1968"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".298429105275234",
						"qValue": ".07555939245176",
						"foldChange": "0.1806"
					}
				]
			},
			{
				"metaboliteId": "0078dd34-0000-0000-0000-000000000000",
				"chemicalId": "05f5e299-0000-0000-0000-000000000000",
				"chemicalName": "2-isopropylmalate",
				"plotName": null,
				"compoundId": "00003d33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00402",
				"inChiKey": "BITYXLXUCSKTJS-UHFFFAOYAE",
				"kegg": "C02504",
				"mass": 176.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".305085036468795",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".57739315532032",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".71319235062051",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".855468072048773",
						"qValue": "1",
						"foldChange": "1.2955"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".860987297479833",
						"qValue": "1",
						"foldChange": "1.7464"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".721028740630546",
						"qValue": "1",
						"foldChange": "2.9109"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".532800503892718",
						"qValue": "1",
						"foldChange": "1.9993"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".084964951651612",
						"qValue": ".697458782960496",
						"foldChange": "2.4149"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".263824043542018",
						"qValue": ".957839854315834",
						"foldChange": "2.3935"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".679841662860361",
						"qValue": "1",
						"foldChange": "0.8891"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".799491730456621",
						"qValue": "1",
						"foldChange": "1.7669"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".873718872429949",
						"qValue": "1",
						"foldChange": "1.7781"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".653368971905862",
						"qValue": "1",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".648982153167413",
						"qValue": "1",
						"foldChange": "0.8080"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".975025407377003",
						"qValue": "1",
						"foldChange": "0.8359"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".755486219932544",
						"qValue": "1",
						"foldChange": "0.9406"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".488997926144968",
						"qValue": "1",
						"foldChange": "0.5554"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".31340862412174",
						"qValue": "1",
						"foldChange": "0.5905"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".315066677419772",
						"qValue": "1",
						"foldChange": "1.0752"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".386513160227538",
						"qValue": "1",
						"foldChange": "0.5477"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".065235775879402",
						"qValue": ".985830071188835",
						"foldChange": "0.5094"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".142950120436361",
						"qValue": ".106358291676719",
						"foldChange": "0.9071"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".848332958061555",
						"qValue": ".301727839694044",
						"foldChange": "1.3861"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".477711586498338",
						"qValue": ".107569356045467",
						"foldChange": "1.3062"
					}
				]
			},
			{
				"metaboliteId": "0078dd35-0000-0000-0000-000000000000",
				"chemicalId": "00000442-0000-0000-0000-000000000000",
				"chemicalName": "bilirubin (Z,Z)",
				"plotName": null,
				"compoundId": "0000ab1f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00054",
				"inChiKey": "BPYKTIZUTYGOLE-IFADSCNNBS",
				"kegg": "C00486",
				"mass": 584.263485,
				"pathwayType": "ANIMAL",
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4013,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".704362364309489",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".142439929492716",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".409415554690169",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".034391470567995",
						"qValue": "1",
						"foldChange": "0.7015"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".269769570630124",
						"qValue": "1",
						"foldChange": "0.9777"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".294003122637006",
						"qValue": "1",
						"foldChange": "2.0168"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".735344087807513",
						"qValue": "1",
						"foldChange": "1.1566"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".876309488000492",
						"qValue": "1",
						"foldChange": "2.7185"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".621824478081547",
						"qValue": ".967408831530889",
						"foldChange": "2.2477"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".481982597295613",
						"qValue": "1",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".09797362196194",
						"qValue": "1",
						"foldChange": "0.5621"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".332494833329419",
						"qValue": "1",
						"foldChange": "0.7754"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".215693893406498",
						"qValue": "1",
						"foldChange": "0.8920"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".409054592088079",
						"qValue": "1",
						"foldChange": "0.7267"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".71903963956288",
						"qValue": "1",
						"foldChange": "0.8146"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".897664969570695",
						"qValue": "1",
						"foldChange": "1.3994"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".863464826729416",
						"qValue": "1",
						"foldChange": "0.8465"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".958850204552916",
						"qValue": "1",
						"foldChange": "0.6049"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".757323410785564",
						"qValue": "1",
						"foldChange": "1.1273"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".197048374723509",
						"qValue": "1",
						"foldChange": "0.4595"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".304086450554304",
						"qValue": ".985830071188835",
						"foldChange": "0.4076"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003556797568007",
						"qValue": ".006729269755999",
						"foldChange": "0.3738"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".020313589648702",
						"qValue": ".018331301559304",
						"foldChange": "0.5295"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".059590664463761",
						"qValue": ".021774418074512",
						"foldChange": "0.6338"
					}
				]
			},
			{
				"metaboliteId": "0078dd36-0000-0000-0000-000000000000",
				"chemicalId": "05f5e680-0000-0000-0000-000000000000",
				"chemicalName": "2-myristoylglycerol (14:0)",
				"plotName": null,
				"compoundId": "0000864f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11530",
				"inChiKey": "TVIMZSOUQXNWHO-UHFFFAOYAT",
				"kegg": null,
				"mass": 302.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2568,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".567646946439311",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".096841253914189",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".097851788429753",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".041337663580921",
						"qValue": "1",
						"foldChange": "2.7259"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".328698701684018",
						"qValue": "1",
						"foldChange": "1.5210"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".272670570977559",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".15985157507019",
						"qValue": ".742487134132407",
						"foldChange": "0.8049"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".038405437141876",
						"qValue": ".678234398444917",
						"foldChange": "0.6864"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".486455741511058",
						"qValue": ".957839854315834",
						"foldChange": "0.9581"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".673524540197792",
						"qValue": "1",
						"foldChange": "2.1734"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".152232595846361",
						"qValue": "1",
						"foldChange": "0.7952"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".06636372282608",
						"qValue": ".727024127920748",
						"foldChange": "0.6731"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".183889773184908",
						"qValue": "1",
						"foldChange": "1.9463"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".751724144862187",
						"qValue": "1",
						"foldChange": "1.3042"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".332645672355863",
						"qValue": "1",
						"foldChange": "0.6701"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".122332146350596",
						"qValue": "1",
						"foldChange": "0.7006"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".323219597256401",
						"qValue": "1",
						"foldChange": "0.8211"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".620471322512473",
						"qValue": "1",
						"foldChange": "1.1721"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".237809013677686",
						"qValue": "1",
						"foldChange": "0.6043"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".094225684284357",
						"qValue": "1",
						"foldChange": "0.4417"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".559378201875063",
						"qValue": ".985830071188835",
						"foldChange": "0.7310"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".056955636940443",
						"qValue": ".05450137419199",
						"foldChange": "0.4833"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".275999699104575",
						"qValue": ".132158837497075",
						"foldChange": "0.7198"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".193161022838798",
						"qValue": ".054273110107643",
						"foldChange": "1.6214"
					}
				]
			},
			{
				"metaboliteId": "0078dd37-0000-0000-0000-000000000000",
				"chemicalId": "05f5e418-0000-0000-0000-000000000000",
				"chemicalName": "dehydroepiandrosterone sulfate (DHEA-S)",
				"plotName": null,
				"compoundId": "00007ea9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01032",
				"inChiKey": "CZWCKYRVOZZJNM-USOAJAOKBK",
				"kegg": "C04555",
				"mass": 368.165745,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3010,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".870229136200358",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".617433329884598",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".195804525099487",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".482352719797157",
						"qValue": "1",
						"foldChange": "0.8993"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".627402119755494",
						"qValue": "1",
						"foldChange": "0.9389"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".827190437900921",
						"qValue": "1",
						"foldChange": "1.0567"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".01750486419272",
						"qValue": ".330397363624153",
						"foldChange": "2.9348"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".042336318848372",
						"qValue": ".678234398444917",
						"foldChange": "2.5779"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".709205041842563",
						"qValue": ".976023509428735",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".982473780988711",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".810825267432141",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".793855384726823",
						"qValue": "1",
						"foldChange": "0.9266"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".456020613873298",
						"qValue": "1",
						"foldChange": "0.8988"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".749141971780845",
						"qValue": "1",
						"foldChange": "1.2627"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".296549440214017",
						"qValue": "1",
						"foldChange": "1.4048"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".542862911755482",
						"qValue": "1",
						"foldChange": "1.3743"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".530675117648151",
						"qValue": "1",
						"foldChange": "1.1560"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".957622194005422",
						"qValue": "1",
						"foldChange": "0.8411"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".724129001318711",
						"qValue": "1",
						"foldChange": "1.1608"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".675977631267255",
						"qValue": "1",
						"foldChange": "1.1207"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".931044230638659",
						"qValue": ".997573179629986",
						"foldChange": "0.9654"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".363339325599668",
						"qValue": ".20016344739947",
						"foldChange": "1.7446"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".382311255040335",
						"qValue": ".164769911752874",
						"foldChange": "1.3339"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".17659252030533",
						"qValue": ".050881406928333",
						"foldChange": "1.6455"
					}
				]
			},
			{
				"metaboliteId": "0078dd38-0000-0000-0000-000000000000",
				"chemicalId": "00000153-0000-0000-0000-000000000000",
				"chemicalName": "glutarate (C5-DC)",
				"plotName": null,
				"compoundId": "0000018c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00661",
				"inChiKey": "JFCQEDHGNNZCLN-NUQVWONBAE",
				"kegg": "C00489",
				"mass": 132.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1483,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".58485829770226",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".766196861208828",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".273377663988997",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".430610003931879",
						"qValue": "1",
						"foldChange": "1.3039"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".908586833124853",
						"qValue": "1",
						"foldChange": "1.0385"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".500130568457113",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".874163375494437",
						"qValue": "1",
						"foldChange": "1.2184"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".630971985172388",
						"qValue": ".928868848501546",
						"foldChange": "1.0357"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".523493982498045",
						"qValue": ".957839854315834",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".046103300582033",
						"qValue": ".821720595261736",
						"foldChange": "0.7732"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".5956110143044",
						"qValue": "1",
						"foldChange": "1.0691"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".136783266817035",
						"qValue": ".960252848686774",
						"foldChange": "1.3128"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".570801074952242",
						"qValue": "1",
						"foldChange": "1.2581"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".978435005630236",
						"qValue": "1",
						"foldChange": "0.9394"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".601908380196178",
						"qValue": "1",
						"foldChange": "0.7467"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".819989134020223",
						"qValue": "1",
						"foldChange": "0.9908"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".151273132396337",
						"qValue": "1",
						"foldChange": "0.4074"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".093361620715208",
						"qValue": ".841539404405048",
						"foldChange": "0.4112"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".791473948686342",
						"qValue": "1",
						"foldChange": "0.9325"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".753575308793615",
						"qValue": "1",
						"foldChange": "0.6155"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".564170422914233",
						"qValue": ".985830071188835",
						"foldChange": "0.6600"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000116069555618",
						"qValue": ".000541261094588",
						"foldChange": "0.4167"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001105148111306",
						"qValue": ".002265931013619",
						"foldChange": "0.4680"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".015974658973395",
						"qValue": ".007841339258513",
						"foldChange": "0.5890"
					}
				]
			},
			{
				"metaboliteId": "0078dd39-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2ba-0000-0000-0000-000000000000",
				"chemicalName": "quinate",
				"plotName": null,
				"compoundId": "0000479f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03072",
				"inChiKey": "AAWZDTNXLSGCEK-WYWMIBKRBU",
				"kegg": "C00296",
				"mass": 192.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4412,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".232377670984032",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".974756492796613",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".720020435487876",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".580136285308611",
						"qValue": "1",
						"foldChange": "1.4441"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".99236732499062",
						"qValue": "1",
						"foldChange": "1.8241"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".573635686105043",
						"qValue": "1",
						"foldChange": "1.5535"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".658646290360867",
						"qValue": "1",
						"foldChange": "3.3234"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".65575892889566",
						"qValue": ".934949271461149",
						"foldChange": "5.1719"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".376250285592654",
						"qValue": ".957839854315834",
						"foldChange": "2.1481"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".495654845833088",
						"qValue": "1",
						"foldChange": "1.6637"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".780170801738222",
						"qValue": "1",
						"foldChange": "0.9826"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".338271265535208",
						"qValue": "1",
						"foldChange": "0.7720"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".182152443491639",
						"qValue": "1",
						"foldChange": "0.7186"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".953731106450631",
						"qValue": "1",
						"foldChange": "1.1076"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".17684040503982",
						"qValue": "1",
						"foldChange": "1.5414"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".205452128350436",
						"qValue": "1",
						"foldChange": "0.3900"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".490396129436925",
						"qValue": "1",
						"foldChange": "1.2134"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".057608181483253",
						"qValue": ".822195137696456",
						"foldChange": "3.1117"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".260908653373651",
						"qValue": "1",
						"foldChange": "0.6203"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".925437187035236",
						"qValue": "1",
						"foldChange": "0.9497"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".319249023327738",
						"qValue": ".985830071188835",
						"foldChange": "1.5309"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00005356973527",
						"qValue": ".000333697823959",
						"foldChange": "172.8761"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001775140972903",
						"qValue": ".003152813649346",
						"foldChange": "14.1524"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000023777854669",
						"qValue": ".000103321796759",
						"foldChange": "57.2384"
					}
				]
			},
			{
				"metaboliteId": "0078dd3a-0000-0000-0000-000000000000",
				"chemicalId": "0000037d-0000-0000-0000-000000000000",
				"chemicalName": "arachidate (20:0)",
				"plotName": null,
				"compoundId": "0000045e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02212",
				"inChiKey": "VKOBVWXKNCXXDE-UHFFFAOYAB",
				"kegg": "C06425",
				"mass": 312.30283,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1347,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".088182900104748",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".14818170953936",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".002904807425165",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".952687020607449",
						"qValue": "1",
						"foldChange": "1.0098"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".753760697208317",
						"qValue": "1",
						"foldChange": "1.0263"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".799166288857801",
						"qValue": "1",
						"foldChange": "1.0225"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".012674244798352",
						"qValue": ".303094620658292",
						"foldChange": "1.2179"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".033466158896896",
						"qValue": ".678234398444917",
						"foldChange": "0.8825"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000017025578979",
						"qValue": ".012405015081279",
						"foldChange": "0.7393"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".312399730650408",
						"qValue": "1",
						"foldChange": "0.9332"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".810451569901356",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".439351939223179",
						"qValue": "1",
						"foldChange": "1.0655"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".657367897911055",
						"qValue": "1",
						"foldChange": "1.0492"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".034378049578241",
						"qValue": "1",
						"foldChange": "1.2328"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".078774965958599",
						"qValue": "1",
						"foldChange": "1.1750"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".024077043889304",
						"qValue": "1",
						"foldChange": "1.2833"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".190534768419367",
						"qValue": "1",
						"foldChange": "1.1143"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".376896080202435",
						"qValue": "1",
						"foldChange": "0.8684"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".159639916652729",
						"qValue": "1",
						"foldChange": "0.8884"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".086120945802092",
						"qValue": "1",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".002506098397379",
						"qValue": ".589350277992006",
						"foldChange": "1.3042"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".043653624479413",
						"qValue": ".045450642481281",
						"foldChange": "1.4494"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".73066663646576",
						"qValue": ".27274938593358",
						"foldChange": "1.0238"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".119330391971099",
						"qValue": ".03725330806537",
						"foldChange": "1.1661"
					}
				]
			},
			{
				"metaboliteId": "0078dd3b-0000-0000-0000-000000000000",
				"chemicalId": "00000207-0000-0000-0000-000000000000",
				"chemicalName": "myristate (14:0)",
				"plotName": null,
				"compoundId": "00000555-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00806",
				"inChiKey": "TUNFSRHWOTWDNC-UHFFFAOYAZ",
				"kegg": "C06424",
				"mass": 228.20893,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1341,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".996157861748691",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".357066292219862",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".43549913074085",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".65073882964691",
						"qValue": "1",
						"foldChange": "1.1426"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".389020525683783",
						"qValue": "1",
						"foldChange": "1.1723"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".68094470630449",
						"qValue": "1",
						"foldChange": "1.1374"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".465757504680116",
						"qValue": "1",
						"foldChange": "1.2043"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".165711572130826",
						"qValue": ".787973217949622",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".037227891955182",
						"qValue": ".6465497153849",
						"foldChange": "0.8281"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".501219337366665",
						"qValue": "1",
						"foldChange": "1.2221"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".952804355526028",
						"qValue": "1",
						"foldChange": "1.0780"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".464595282070887",
						"qValue": "1",
						"foldChange": "0.9081"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".680621112880247",
						"qValue": "1",
						"foldChange": "1.1633"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".841679149532911",
						"qValue": "1",
						"foldChange": "1.0157"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".845846286089361",
						"qValue": "1",
						"foldChange": "0.8731"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".575577327009095",
						"qValue": "1",
						"foldChange": "1.2991"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".724307681579506",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".855781367107508",
						"qValue": "1",
						"foldChange": "0.7968"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".347439704022629",
						"qValue": "1",
						"foldChange": "0.9276"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".731903849745546",
						"qValue": "1",
						"foldChange": "0.8160"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".573516345219537",
						"qValue": ".985830071188835",
						"foldChange": "0.8797"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".337405431040306",
						"qValue": ".192863611674612",
						"foldChange": "1.1841"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".422846653766243",
						"qValue": ".1778420581931",
						"foldChange": "1.5170"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".23217392296358",
						"qValue": ".061495842273666",
						"foldChange": "1.3267"
					}
				]
			},
			{
				"metaboliteId": "0078dd3c-0000-0000-0000-000000000000",
				"chemicalId": "0000005a-0000-0000-0000-000000000000",
				"chemicalName": "2-methylcitrate",
				"plotName": null,
				"compoundId": "0000926b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00379",
				"inChiKey": "YNOXCRMFGMSKIJ-DFZHHIFOAQ",
				"kegg": "C02225",
				"mass": 206.042655,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1302,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".5014468337544",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".746396444417671",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".760767703586352",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".47979753549363",
						"qValue": "1",
						"foldChange": "2.7255"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".874776349330059",
						"qValue": "1",
						"foldChange": "0.9586"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".388293277022878",
						"qValue": "1",
						"foldChange": "1.4017"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".842441339930007",
						"qValue": "1",
						"foldChange": "1.2326"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".460166329051297",
						"qValue": ".902562957068497",
						"foldChange": "2.6934"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".349688249480111",
						"qValue": ".957839854315834",
						"foldChange": "2.6835"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".856363454203161",
						"qValue": "1",
						"foldChange": "1.0820"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".462719615974051",
						"qValue": "1",
						"foldChange": "1.8484"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".579217432367431",
						"qValue": "1",
						"foldChange": "1.7101"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".906471990668504",
						"qValue": "1",
						"foldChange": "0.9430"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".434337155149465",
						"qValue": "1",
						"foldChange": "0.5054"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".491687966508124",
						"qValue": "1",
						"foldChange": "0.5359"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".299532554430861",
						"qValue": "1",
						"foldChange": "0.4669"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".200880635565158",
						"qValue": "1",
						"foldChange": "0.3788"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".757344007918863",
						"qValue": "1",
						"foldChange": "0.8114"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".443149763152445",
						"qValue": "1",
						"foldChange": "1.6959"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".896039785985098",
						"qValue": "1",
						"foldChange": "1.0689"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".542051450413487",
						"qValue": ".985830071188835",
						"foldChange": "0.6303"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".055500136044181",
						"qValue": ".053417362846285",
						"foldChange": "0.3883"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".075619150956824",
						"qValue": ".050321333519318",
						"foldChange": "0.5719"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".125663833969512",
						"qValue": ".038732159388337",
						"foldChange": "0.4417"
					}
				]
			},
			{
				"metaboliteId": "0078dd3d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e54e-0000-0000-0000-000000000000",
				"chemicalName": "dodecanedioate (C12-DC)",
				"plotName": null,
				"compoundId": "00007e84-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00623",
				"inChiKey": "TVIDDXQYHWJXFK-UHFFFAOYAC",
				"kegg": "C02678",
				"mass": 230.151809,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1510,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".754296009026488",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".716891733626929",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".877052696582114",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".451136895802996",
						"qValue": "1",
						"foldChange": "0.9700"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".83970623270396",
						"qValue": "1",
						"foldChange": "1.0762"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".340329491987172",
						"qValue": "1",
						"foldChange": "1.2506"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".624183688853266",
						"qValue": "1",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".397440892323906",
						"qValue": ".882161593933088",
						"foldChange": "0.9377"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".719891880720294",
						"qValue": ".976023509428735",
						"foldChange": "1.1888"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".883976828028265",
						"qValue": "1",
						"foldChange": "1.0381"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".845177913593888",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".733064890072997",
						"qValue": "1",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".450405851150833",
						"qValue": "1",
						"foldChange": "1.4032"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".690430921961233",
						"qValue": "1",
						"foldChange": "1.1119"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".745053153895527",
						"qValue": "1",
						"foldChange": "0.7924"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".354052994409477",
						"qValue": "1",
						"foldChange": "2.0136"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".460891021818018",
						"qValue": "1",
						"foldChange": "1.2401"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".884964017241597",
						"qValue": "1",
						"foldChange": "0.6158"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".879172676053224",
						"qValue": "1",
						"foldChange": "1.1663"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".688408611854802",
						"qValue": "1",
						"foldChange": "1.1302"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".791906091132855",
						"qValue": ".997573179629986",
						"foldChange": "0.9691"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".084989269991631",
						"qValue": ".072710975692342",
						"foldChange": "0.7722"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007192905809336",
						"qValue": ".008025405055036",
						"foldChange": "0.5604"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005925699741363",
						"qValue": ".003650229243504",
						"foldChange": "0.5865"
					}
				]
			},
			{
				"metaboliteId": "0078dd3e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7bb-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxycaproate",
				"plotName": null,
				"compoundId": "000090d1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01624",
				"inChiKey": "NYHNVHGFPZAZGA-UHFFFAOYAC",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1729,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".330252522660195",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".070611220056384",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".924276948766909",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".915268354374398",
						"qValue": "1",
						"foldChange": "5.9754"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".381758559248613",
						"qValue": "1",
						"foldChange": "3.3192"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".327268723450798",
						"qValue": "1",
						"foldChange": "4.7491"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".204297591191849",
						"qValue": ".802574227585289",
						"foldChange": "1.9650"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".710446182666072",
						"qValue": ".950986052805043",
						"foldChange": "3.3014"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".103358727266475",
						"qValue": ".870874028750201",
						"foldChange": "9.0715"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".226895736487163",
						"qValue": "1",
						"foldChange": "1.2311"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".853864068496365",
						"qValue": "1",
						"foldChange": "4.0038"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".165202205099715",
						"qValue": ".969566045102463",
						"foldChange": "3.6777"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".756116074973381",
						"qValue": "1",
						"foldChange": "0.9721"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".743722523828356",
						"qValue": "1",
						"foldChange": "0.5682"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".525381793424178",
						"qValue": "1",
						"foldChange": "0.5845"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".435363651615922",
						"qValue": "1",
						"foldChange": "0.6425"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".155133188545032",
						"qValue": "1",
						"foldChange": "0.3167"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".478753735700762",
						"qValue": "1",
						"foldChange": "0.4929"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".835577885954085",
						"qValue": "1",
						"foldChange": "1.1356"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".336859305277329",
						"qValue": "1",
						"foldChange": "0.5730"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".432893270817638",
						"qValue": ".985830071188835",
						"foldChange": "0.5046"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000310598936476",
						"qValue": ".001142626503395",
						"foldChange": "0.1579"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".047751819866262",
						"qValue": ".036113551631962",
						"foldChange": "0.2314"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000981037168061",
						"qValue": ".001044550689244",
						"foldChange": "0.2229"
					}
				]
			},
			{
				"metaboliteId": "0078dd3f-0000-0000-0000-000000000000",
				"chemicalId": "00000335-0000-0000-0000-000000000000",
				"chemicalName": "pseudouridine",
				"plotName": null,
				"compoundId": "000082a2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00767",
				"inChiKey": "HZIOZCLEXIYJAD-JWMKEVCDBU",
				"kegg": "C02067",
				"mass": 244.069538,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3839,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".602867281243833",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".482786663395982",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".698818057480453",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".329519565172122",
						"qValue": "1",
						"foldChange": "0.9822"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".732626780969059",
						"qValue": "1",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".524273986391188",
						"qValue": "1",
						"foldChange": "1.2379"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".695659912099585",
						"qValue": "1",
						"foldChange": "1.1051"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".613345417951229",
						"qValue": ".928868848501546",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".371471956464676",
						"qValue": ".957839854315834",
						"foldChange": "0.9560"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".964133540290206",
						"qValue": "1",
						"foldChange": "0.9954"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".246675179752418",
						"qValue": "1",
						"foldChange": "0.8921"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".265107274829867",
						"qValue": "1",
						"foldChange": "0.8478"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".740715943002178",
						"qValue": "1",
						"foldChange": "1.0118"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".499693777495252",
						"qValue": "1",
						"foldChange": "1.0370"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".709935437121904",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".159004886314251",
						"qValue": "1",
						"foldChange": "1.1915"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".168945741190854",
						"qValue": "1",
						"foldChange": "1.1833"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".964341842704157",
						"qValue": "1",
						"foldChange": "0.9932"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".826210908449764",
						"qValue": "1",
						"foldChange": "1.0463"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".99460890428377",
						"qValue": "1",
						"foldChange": "0.9285"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".82618326375425",
						"qValue": ".997573179629986",
						"foldChange": "0.8874"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000171590584681",
						"qValue": ".000728359333549",
						"foldChange": "2.6321"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000077212389505",
						"qValue": ".000526311519666",
						"foldChange": "2.8398"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000215910191663",
						"qValue": ".000357867627669",
						"foldChange": "2.5809"
					}
				]
			},
			{
				"metaboliteId": "0078dd40-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7f1-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPI (18:1)",
				"plotName": null,
				"compoundId": "00008efa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 598.311814,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2427,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".571115763753773",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".959459665552574",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".415963422282635",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".63842880873847",
						"qValue": "1",
						"foldChange": "1.6062"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".63401121210922",
						"qValue": "1",
						"foldChange": "1.8211"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".345844432252682",
						"qValue": "1",
						"foldChange": "1.5948"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".202272437231749",
						"qValue": ".802574227585289",
						"foldChange": "2.0362"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".225146197444161",
						"qValue": ".825038702902081",
						"foldChange": "1.7893"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".949225936474905",
						"qValue": "1",
						"foldChange": "1.1567"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".617393445685406",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".285574144932735",
						"qValue": "1",
						"foldChange": "0.9642"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".566565548508126",
						"qValue": "1",
						"foldChange": "0.7952"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".708562728914387",
						"qValue": "1",
						"foldChange": "0.8588"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".878694213047289",
						"qValue": "1",
						"foldChange": "0.6629"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".836869853426164",
						"qValue": "1",
						"foldChange": "0.7719"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".342951587764863",
						"qValue": "1",
						"foldChange": "1.6218"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".843673083403914",
						"qValue": "1",
						"foldChange": "1.1529"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".263588272118209",
						"qValue": "1",
						"foldChange": "0.7109"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".871425620388142",
						"qValue": "1",
						"foldChange": "1.0807"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".180159440868496",
						"qValue": "1",
						"foldChange": "0.5550"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".127357501120353",
						"qValue": ".985830071188835",
						"foldChange": "0.5136"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".406318110981072",
						"qValue": ".216501028847437",
						"foldChange": "1.6269"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002894404822253",
						"qValue": ".004362672887127",
						"foldChange": "2.9213"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".065910535609631",
						"qValue": ".023442602561205",
						"foldChange": "1.6285"
					}
				]
			},
			{
				"metaboliteId": "0078dd41-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7f2-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPI (18:2)*",
				"plotName": null,
				"compoundId": "00008ef2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 596.296164,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".876608485866264",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".455084677295004",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".367631213588035",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".155108271786723",
						"qValue": "1",
						"foldChange": "0.9172"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".829762677095337",
						"qValue": "1",
						"foldChange": "1.1161"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".103306628804095",
						"qValue": "1",
						"foldChange": "1.5245"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".517305400050139",
						"qValue": "1",
						"foldChange": "1.1883"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".320455132839081",
						"qValue": ".829615692203654",
						"foldChange": "1.3304"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".726684273798401",
						"qValue": ".976023509428735",
						"foldChange": "1.2026"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".303231995252868",
						"qValue": "1",
						"foldChange": "0.8922"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".256879045753762",
						"qValue": "1",
						"foldChange": "0.9341"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".915419014025437",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".661614288779066",
						"qValue": "1",
						"foldChange": "0.9157"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".972039023871265",
						"qValue": "1",
						"foldChange": "0.8843"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".697875407763066",
						"qValue": "1",
						"foldChange": "0.9657"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".363423485002733",
						"qValue": "1",
						"foldChange": "1.3982"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".860376130359242",
						"qValue": "1",
						"foldChange": "1.2607"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".482421894078173",
						"qValue": "1",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".964860719799193",
						"qValue": "1",
						"foldChange": "1.0765"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".358533223730426",
						"qValue": "1",
						"foldChange": "0.6452"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".325947442123718",
						"qValue": ".985830071188835",
						"foldChange": "0.5993"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".900345422512417",
						"qValue": ".371321601579918",
						"foldChange": "1.2195"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".057709665346133",
						"qValue": ".041156355001095",
						"foldChange": "1.9109"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".117660587171707",
						"qValue": ".036947244627765",
						"foldChange": "1.4547"
					}
				]
			},
			{
				"metaboliteId": "0078dd42-0000-0000-0000-000000000000",
				"chemicalId": "000001f5-0000-0000-0000-000000000000",
				"chemicalName": "salicylate",
				"plotName": null,
				"compoundId": "000005eb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01895",
				"inChiKey": "4000",
				"kegg": "C00805",
				"mass": 138.031695,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5146,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".268363135826638",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".991817849865028",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".897952602023719",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".874821956835102",
						"qValue": "1",
						"foldChange": "1.8194"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".611014506945882",
						"qValue": "1",
						"foldChange": "3.8045"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".725188958989235",
						"qValue": "1",
						"foldChange": "1.8272"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".521909293516588",
						"qValue": "1",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".94684507601555",
						"qValue": "1",
						"foldChange": "1.7671"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".56588591938848",
						"qValue": ".957839854315834",
						"foldChange": "1.6867"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".60581684288108",
						"qValue": "1",
						"foldChange": "1.7812"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".639406182260361",
						"qValue": "1",
						"foldChange": "1.4903"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".9619505593042",
						"qValue": "1",
						"foldChange": "1.3543"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".182770000798116",
						"qValue": "1",
						"foldChange": "0.2363"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".127333952235598",
						"qValue": "1",
						"foldChange": "0.0606"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".777968782331829",
						"qValue": "1",
						"foldChange": "0.2566"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".124454751220731",
						"qValue": "1",
						"foldChange": "0.3332"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".221788268802263",
						"qValue": "1",
						"foldChange": "0.2582"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".802858873929993",
						"qValue": "1",
						"foldChange": "0.7749"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".259328895823807",
						"qValue": "1",
						"foldChange": "0.3377"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".275353616154487",
						"qValue": "1",
						"foldChange": "0.0599"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".98058904701011",
						"qValue": "1",
						"foldChange": "0.1773"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".018876490106467",
						"qValue": ".024037800988778",
						"foldChange": "3.8530"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".066870854303901",
						"qValue": ".045814987084993",
						"foldChange": "18.0986"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".011477173718637",
						"qValue": ".006010596655651",
						"foldChange": "41.9713"
					}
				]
			},
			{
				"metaboliteId": "0078dd43-0000-0000-0000-000000000000",
				"chemicalId": "0000043c-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylvaline",
				"plotName": null,
				"compoundId": "00000637-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11757",
				"inChiKey": "IHYJTAOFMMMOPX-LURJTMIEBI",
				"kegg": null,
				"mass": 159.089544,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 355,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".775288235037872",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".596965840933361",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".44642064500604",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".120084506570753",
						"qValue": "1",
						"foldChange": "0.9254"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".954665256033855",
						"qValue": "1",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".133713507968059",
						"qValue": "1",
						"foldChange": "1.5035"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".97062080400846",
						"qValue": "1",
						"foldChange": "1.0519"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".301059220500258",
						"qValue": ".825038702902081",
						"foldChange": "0.9510"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".284458308701184",
						"qValue": ".957839854315834",
						"foldChange": "0.9582"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".929886789880149",
						"qValue": "1",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".735906997145231",
						"qValue": "1",
						"foldChange": "0.9610"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".803045393815883",
						"qValue": "1",
						"foldChange": "0.9051"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".965477753946891",
						"qValue": "1",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".901934414833661",
						"qValue": "1",
						"foldChange": "0.9744"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".932814130990822",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".122302520178733",
						"qValue": "1",
						"foldChange": "1.1815"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".152362302470434",
						"qValue": "1",
						"foldChange": "1.1871"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".973912936611882",
						"qValue": "1",
						"foldChange": "1.0047"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".406409004130281",
						"qValue": "1",
						"foldChange": "0.9487"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".884795965042796",
						"qValue": "1",
						"foldChange": "0.9107"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".5113370465756",
						"qValue": ".985830071188835",
						"foldChange": "0.9600"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000027059147535",
						"qValue": ".000271485731442",
						"foldChange": "2.6171"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000096726828441",
						"qValue": ".000612998451832",
						"foldChange": "2.7996"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000225303628611",
						"qValue": ".000369626513869",
						"foldChange": "2.3891"
					}
				]
			},
			{
				"metaboliteId": "0078dd44-0000-0000-0000-000000000000",
				"chemicalId": "000000c2-0000-0000-0000-000000000000",
				"chemicalName": "N-formylmethionine",
				"plotName": null,
				"compoundId": "00000b0d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01015",
				"inChiKey": "PYUSHNKNPOHWEZ-YFKPBYRVBS",
				"kegg": "C03145",
				"mass": 177.045966,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 373,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".456825361808448",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".502290391227183",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".684654223447238",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".246032614451987",
						"qValue": "1",
						"foldChange": "0.9441"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".747430656469841",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".399268462302597",
						"qValue": "1",
						"foldChange": "1.1969"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".7324029751064",
						"qValue": "1",
						"foldChange": "1.1268"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".559146700635892",
						"qValue": ".925746774788218",
						"foldChange": "1.0438"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".355928852351039",
						"qValue": ".957839854315834",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".716004360042297",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".280103158863586",
						"qValue": "1",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".471086229252542",
						"qValue": "1",
						"foldChange": "0.8758"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".669937644432529",
						"qValue": "1",
						"foldChange": "1.0491"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".325931408692449",
						"qValue": "1",
						"foldChange": "1.1115"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".550566965934417",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".127915192779272",
						"qValue": "1",
						"foldChange": "1.2469"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".128270455475801",
						"qValue": "1",
						"foldChange": "1.2724"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".932762378222755",
						"qValue": "1",
						"foldChange": "1.0205"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".800447206946766",
						"qValue": "1",
						"foldChange": "1.0875"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".688436328056162",
						"qValue": "1",
						"foldChange": "1.0385"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".868039958203747",
						"qValue": ".997573179629986",
						"foldChange": "0.9549"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000546850635591",
						"qValue": ".001697875856955",
						"foldChange": "2.7725"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000178818716313",
						"qValue": ".000805494946676",
						"foldChange": "2.4976"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000029088164133",
						"qValue": ".000118750031432",
						"foldChange": "2.2686"
					}
				]
			},
			{
				"metaboliteId": "0078dd45-0000-0000-0000-000000000000",
				"chemicalId": "05f5e736-0000-0000-0000-000000000000",
				"chemicalName": "isobutyrylglycine",
				"plotName": null,
				"compoundId": "00008a6d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00730",
				"inChiKey": "DCICDMMXFIELDF-UHFFFAOYAA",
				"kegg": null,
				"mass": 145.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".793171620695458",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".270606854928252",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".820222396430398",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".522753849651091",
						"qValue": "1",
						"foldChange": "1.0345"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".632901457617204",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".871118786886352",
						"qValue": "1",
						"foldChange": "1.4963"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".363386928749617",
						"qValue": ".951252030575746",
						"foldChange": "1.0442"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".646484959676003",
						"qValue": ".930662761919878",
						"foldChange": "1.0779"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".650374595391175",
						"qValue": ".973034272612281",
						"foldChange": "1.6654"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".521334660677071",
						"qValue": "1",
						"foldChange": "0.8887"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".105145008331438",
						"qValue": "1",
						"foldChange": "0.7768"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".319668076133344",
						"qValue": "1",
						"foldChange": "1.0933"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".842340570801013",
						"qValue": "1",
						"foldChange": "1.1742"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".851341372195993",
						"qValue": "1",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999450261022781",
						"qValue": "1",
						"foldChange": "0.8263"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".702914539167142",
						"qValue": "1",
						"foldChange": "0.9646"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".826703548856471",
						"qValue": "1",
						"foldChange": "0.8542"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".884443976464191",
						"qValue": "1",
						"foldChange": "0.8856"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".869423557788013",
						"qValue": "1",
						"foldChange": "0.8979"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".250539350885333",
						"qValue": "1",
						"foldChange": "0.6126"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".307706836393124",
						"qValue": ".985830071188835",
						"foldChange": "0.6823"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".110877024543565",
						"qValue": ".088458649483981",
						"foldChange": "1.8843"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".010518666968677",
						"qValue": ".010588782853907",
						"foldChange": "2.6478"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01066458534379",
						"qValue": ".005715361763509",
						"foldChange": "3.3212"
					}
				]
			},
			{
				"metaboliteId": "0078dd46-0000-0000-0000-000000000000",
				"chemicalId": "05f5e757-0000-0000-0000-000000000000",
				"chemicalName": "3-(2-hydroxyphenyl)propionate",
				"plotName": null,
				"compoundId": "00008b32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33752",
				"inChiKey": "CJBDUOMQLFKVQC-UHFFFAOYAX",
				"kegg": "C01198",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4121,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".138837760406064",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".035439231624078",
						"qValue": ".942393070546791",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".737513255414608",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".018490594074103",
						"qValue": "1",
						"foldChange": "3.9854"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".316741335179735",
						"qValue": "1",
						"foldChange": "1.9984"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".160066916060221",
						"qValue": "1",
						"foldChange": "0.7722"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".41553716265251",
						"qValue": ".989197938984887",
						"foldChange": "1.6026"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".279577668154888",
						"qValue": ".825038702902081",
						"foldChange": "1.9623"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".786538931928243",
						"qValue": ".991621675660644",
						"foldChange": "1.4119"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".205232369907433",
						"qValue": "1",
						"foldChange": "2.8964"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".366218426586056",
						"qValue": "1",
						"foldChange": "2.0058"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".711528068153309",
						"qValue": "1",
						"foldChange": "1.7133"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".526304075266234",
						"qValue": "1",
						"foldChange": "0.6255"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".916180781807545",
						"qValue": "1",
						"foldChange": "0.9657"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".47081436486297",
						"qValue": "1",
						"foldChange": "1.5439"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".02421951166959",
						"qValue": "1",
						"foldChange": "0.3669"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".36269238019622",
						"qValue": "1",
						"foldChange": "0.8076"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".200594330498322",
						"qValue": "1",
						"foldChange": "2.2014"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".543199450919863",
						"qValue": "1",
						"foldChange": "0.8497"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".949399088693499",
						"qValue": "1",
						"foldChange": "1.0818"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".513297361550453",
						"qValue": ".985830071188835",
						"foldChange": "1.2732"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".361068523581634",
						"qValue": ".199576612196798",
						"foldChange": "0.8763"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".011709509776795",
						"qValue": ".011578564289438",
						"foldChange": "0.5980"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".268539652594979",
						"qValue": ".069412716927198",
						"foldChange": "0.7351"
					}
				]
			},
			{
				"metaboliteId": "0078dd47-0000-0000-0000-000000000000",
				"chemicalId": "05f5e758-0000-0000-0000-000000000000",
				"chemicalName": "3-(3-hydroxyphenyl)propionate",
				"plotName": null,
				"compoundId": "00008b33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00375",
				"inChiKey": "QVWAEZJXDYOKEH-UHFFFAOYAP",
				"kegg": "C11457",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4151,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".300189215663649",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".63552097390119",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".516935847130523",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".835924196385381",
						"qValue": "1",
						"foldChange": "2.0991"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".272483985790611",
						"qValue": "1",
						"foldChange": "2.2430"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".371630612404611",
						"qValue": "1",
						"foldChange": "1.7007"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".564681592511006",
						"qValue": "1",
						"foldChange": "1.2612"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".177180690384625",
						"qValue": ".797781838715959",
						"foldChange": "1.7133"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".433588919602013",
						"qValue": ".957839854315834",
						"foldChange": "1.5286"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".745885498227292",
						"qValue": "1",
						"foldChange": "1.5246"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".466048292027884",
						"qValue": "1",
						"foldChange": "0.8756"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".294124794905126",
						"qValue": "1",
						"foldChange": "0.8196"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".219419891537334",
						"qValue": "1",
						"foldChange": "1.9347"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".154600264500261",
						"qValue": "1",
						"foldChange": "1.7377"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".781293150034498",
						"qValue": "1",
						"foldChange": "0.8982"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".165701866541356",
						"qValue": "1",
						"foldChange": "3.6259"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".138071854002255",
						"qValue": "1",
						"foldChange": "4.3108"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".855202799195966",
						"qValue": "1",
						"foldChange": "1.1889"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".18826074063574",
						"qValue": "1",
						"foldChange": "3.3900"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".558483418610056",
						"qValue": "1",
						"foldChange": "2.2691"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".491265468835886",
						"qValue": ".985830071188835",
						"foldChange": "0.6693"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".506401668399735",
						"qValue": ".253347970028165",
						"foldChange": "2.4138"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".162404241135968",
						"qValue": ".08932119266045",
						"foldChange": "3.6678"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".994287591777394",
						"qValue": ".192367633153322",
						"foldChange": "1.2939"
					}
				]
			},
			{
				"metaboliteId": "0078dd48-0000-0000-0000-000000000000",
				"chemicalId": "05f5e705-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxy-3-methylvalerate",
				"plotName": null,
				"compoundId": "00008f8a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00317",
				"inChiKey": "RILPIWOPNGRASR-UHFFFAOYAI",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 337,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".768932728913875",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".621705260718335",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".709769800045467",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".733559251692304",
						"qValue": "1",
						"foldChange": "0.9871"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".349721858297646",
						"qValue": "1",
						"foldChange": "1.1619"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".204360524347242",
						"qValue": "1",
						"foldChange": "1.2593"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".260661592631509",
						"qValue": ".898068887973337",
						"foldChange": "1.1125"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".496558518037565",
						"qValue": ".902562957068497",
						"foldChange": "1.0743"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".652495765796058",
						"qValue": ".973034272612281",
						"foldChange": "0.9933"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".851531871105815",
						"qValue": "1",
						"foldChange": "1.0121"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".943900894547055",
						"qValue": "1",
						"foldChange": "1.0172"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".796809304708006",
						"qValue": "1",
						"foldChange": "1.0044"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".875429218355509",
						"qValue": "1",
						"foldChange": "0.9940"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".863261922792453",
						"qValue": "1",
						"foldChange": "0.9331"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".742846812625211",
						"qValue": "1",
						"foldChange": "0.9388"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".360304836926658",
						"qValue": "1",
						"foldChange": "1.1363"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".920527711170487",
						"qValue": "1",
						"foldChange": "0.9336"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".323778190449274",
						"qValue": "1",
						"foldChange": "0.8216"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999010014587722",
						"qValue": "1",
						"foldChange": "0.9832"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".540602930417107",
						"qValue": "1",
						"foldChange": "0.8308"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".531607862199567",
						"qValue": ".985830071188835",
						"foldChange": "0.8450"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".282121493653424",
						"qValue": ".171705463132094",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".23631275217091",
						"qValue": ".118091344007845",
						"foldChange": "0.8598"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".28105635928337",
						"qValue": ".072183857783483",
						"foldChange": "0.8939"
					}
				]
			},
			{
				"metaboliteId": "0078dd49-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8ea-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3alpha, 17alpha) monosulfate (2)",
				"plotName": null,
				"compoundId": "00009157-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3035,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".788609921770688",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".88159390662873",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".256911851203169",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".184146199413158",
						"qValue": "1",
						"foldChange": "0.8428"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".580332496913624",
						"qValue": "1",
						"foldChange": "0.9498"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".432940137826516",
						"qValue": "1",
						"foldChange": "1.1597"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".087348505725548",
						"qValue": ".624651919096142",
						"foldChange": "1.7070"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".271643921258092",
						"qValue": ".825038702902081",
						"foldChange": "1.6533"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".529655036676444",
						"qValue": ".957839854315834",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".688194371306055",
						"qValue": "1",
						"foldChange": "1.0961"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".61408265560373",
						"qValue": "1",
						"foldChange": "0.9509"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".366686776480349",
						"qValue": "1",
						"foldChange": "0.9037"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".95060429601397",
						"qValue": "1",
						"foldChange": "1.1456"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".627549421860299",
						"qValue": "1",
						"foldChange": "1.7247"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".57786780627654",
						"qValue": "1",
						"foldChange": "1.5054"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".395768650259954",
						"qValue": "1",
						"foldChange": "1.4288"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".322290081741988",
						"qValue": "1",
						"foldChange": "1.9099"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".845355539270683",
						"qValue": "1",
						"foldChange": "1.3367"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".667641331831743",
						"qValue": "1",
						"foldChange": "1.2154"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".623826546994949",
						"qValue": "1",
						"foldChange": "1.6148"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".930764752985045",
						"qValue": ".997573179629986",
						"foldChange": "1.3286"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".961385732094217",
						"qValue": ".387704957121388",
						"foldChange": "1.8619"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".491134766090733",
						"qValue": ".199928732380935",
						"foldChange": "1.1880"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".521469553917861",
						"qValue": ".114535174484343",
						"foldChange": "0.7680"
					}
				]
			},
			{
				"metaboliteId": "0078dd4a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8df-0000-0000-0000-000000000000",
				"chemicalName": "5alpha-pregnan-diol disulfate",
				"plotName": null,
				"compoundId": "0000b45c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LSQGWMYMIHQKSA-UHFFFAOYAD",
				"kegg": null,
				"mass": 480.18516,
				"pathwayType": "ANIMAL",
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2965,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".739715377970886",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".713777799306656",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".97744482369932",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".900027996751327",
						"qValue": "1",
						"foldChange": "2.2840"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".951135406735395",
						"qValue": "1",
						"foldChange": "1.1442"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".851751326339355",
						"qValue": "1",
						"foldChange": "1.8266"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".578869438168823",
						"qValue": "1",
						"foldChange": "1.7096"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".688994999445726",
						"qValue": ".948394067196249",
						"foldChange": "1.2908"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".34112603772385",
						"qValue": ".957839854315834",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".859967770635858",
						"qValue": "1",
						"foldChange": "0.9770"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".642569708844155",
						"qValue": "1",
						"foldChange": "0.9451"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".77329702744093",
						"qValue": "1",
						"foldChange": "0.9772"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".478455416569989",
						"qValue": "1",
						"foldChange": "0.3022"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".679263282620557",
						"qValue": "1",
						"foldChange": "0.2827"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".790930122763318",
						"qValue": "1",
						"foldChange": "0.9356"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".593102328886483",
						"qValue": "1",
						"foldChange": "0.0672"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".587551335734578",
						"qValue": "1",
						"foldChange": "0.0604"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".969563009366537",
						"qValue": "1",
						"foldChange": "0.8984"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".397310733082731",
						"qValue": "1",
						"foldChange": "0.3487"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".554370385949869",
						"qValue": "1",
						"foldChange": "0.4429"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".828000196934444",
						"qValue": ".997573179629986",
						"foldChange": "1.2702"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".582036070348338",
						"qValue": ".283392156264468",
						"foldChange": "0.7879"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".81484910016789",
						"qValue": ".292429564618226",
						"foldChange": "1.1695"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".707263189609673",
						"qValue": ".14597033451552",
						"foldChange": "2.2965"
					}
				]
			},
			{
				"metaboliteId": "0078dd4b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e84c-0000-0000-0000-000000000000",
				"chemicalName": "4-allylphenol sulfate",
				"plotName": null,
				"compoundId": "0000913d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 214.029979,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4575,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".543619477651707",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".000000000000521",
						"qValue": ".000000000443772",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".460697587804803",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".000010883587448",
						"qValue": ".009261932918198",
						"foldChange": "4.4491"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".00000002020001",
						"qValue": ".000017190208414",
						"foldChange": "7.5753"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".077796103285679",
						"qValue": "1",
						"foldChange": "2.3454"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000055204126574",
						"qValue": ".025307661163412",
						"foldChange": "3.5895"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".000012335857904",
						"qValue": ".010497815076068",
						"foldChange": "4.5412"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".655405289603698",
						"qValue": ".974069856499521",
						"foldChange": "1.2927"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".000004196316008",
						"qValue": ".003571064923005",
						"foldChange": "5.1373"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".000012191978595",
						"qValue": ".010375373784184",
						"foldChange": "4.4437"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".755896118924389",
						"qValue": "1",
						"foldChange": "1.0397"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".96828024609478",
						"qValue": "1",
						"foldChange": "0.9143"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".565150412420696",
						"qValue": "1",
						"foldChange": "0.7034"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".581729449341102",
						"qValue": "1",
						"foldChange": "0.7693"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".566727719173137",
						"qValue": "1",
						"foldChange": "0.7145"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".876417052307245",
						"qValue": "1",
						"foldChange": "0.9249"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".691965724254557",
						"qValue": "1",
						"foldChange": "1.2945"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".113124156652293",
						"qValue": "1",
						"foldChange": "0.6183"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".10016593266829",
						"qValue": "1",
						"foldChange": "0.5448"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".878350661393285",
						"qValue": ".997573179629986",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".06475791817188",
						"qValue": ".059723502426388",
						"foldChange": "1.5192"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".060308555220326",
						"qValue": ".042147433578833",
						"foldChange": "1.5913"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004948907497514",
						"qValue": ".003208327258378",
						"foldChange": "2.3431"
					}
				]
			},
			{
				"metaboliteId": "0078dd4c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec81-0000-0000-0000-000000000000",
				"chemicalName": "(14 or 15)-methylpalmitate (a17:0 or i17:0)",
				"plotName": null,
				"compoundId": "00009770-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 270.25595,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".970018471679652",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".41557571354011",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".084969478620695",
						"qValue": ".769244960704375",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".572652254561218",
						"qValue": "1",
						"foldChange": "1.0994"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".377556739339413",
						"qValue": "1",
						"foldChange": "1.1677"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".748391344387369",
						"qValue": "1",
						"foldChange": "1.0705"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".234360776656348",
						"qValue": ".852077213869098",
						"foldChange": "1.2197"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".057767101832033",
						"qValue": ".687896186587066",
						"foldChange": "0.8871"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".002822178638298",
						"qValue": ".150104626324489",
						"foldChange": "0.7604"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".983558858820075",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".699109181521498",
						"qValue": "1",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".683949530044013",
						"qValue": "1",
						"foldChange": "1.0970"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".831578029470598",
						"qValue": "1",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".89144988037091",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".947176204821012",
						"qValue": "1",
						"foldChange": "0.9147"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".582789425223974",
						"qValue": "1",
						"foldChange": "1.2004"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".848220498742865",
						"qValue": "1",
						"foldChange": "0.9183"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".467404218831531",
						"qValue": "1",
						"foldChange": "0.7651"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".168436635353739",
						"qValue": "1",
						"foldChange": "0.8120"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".897130229363499",
						"qValue": "1",
						"foldChange": "0.8922"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".227502667708908",
						"qValue": ".985830071188835",
						"foldChange": "1.0987"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".164283595930841",
						"qValue": ".117225760336941",
						"foldChange": "1.2795"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".398221883926949",
						"qValue": ".170050161020859",
						"foldChange": "1.3864"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".205553762931296",
						"qValue": ".056492470970849",
						"foldChange": "1.2940"
					}
				]
			},
			{
				"metaboliteId": "0078dd4d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cb-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linolenate (20:3n3 or n6)",
				"plotName": null,
				"compoundId": "00008b86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02925",
				"inChiKey": "AHANXAKGNAKFSK-PDBXOOCHBM",
				"kegg": "C03242",
				"mass": 306.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1399,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".975502777358306",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".206841284325104",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".227220967101969",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".265072609690398",
						"qValue": "1",
						"foldChange": "1.1880"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".49173706776807",
						"qValue": "1",
						"foldChange": "1.1101"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".665661654865301",
						"qValue": "1",
						"foldChange": "1.0022"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".480203659496176",
						"qValue": "1",
						"foldChange": "1.0929"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".083468340240347",
						"qValue": ".697458782960496",
						"foldChange": "0.8981"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".016731489925524",
						"qValue": ".500264728100336",
						"foldChange": "0.8308"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".303053819448301",
						"qValue": "1",
						"foldChange": "0.9192"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".220386377880437",
						"qValue": "1",
						"foldChange": "0.8956"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".841855842899907",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".673242059600464",
						"qValue": "1",
						"foldChange": "1.0883"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".430697862522075",
						"qValue": "1",
						"foldChange": "1.1212"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".688470802038386",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".897778069919153",
						"qValue": "1",
						"foldChange": "1.0370"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".563846805994316",
						"qValue": "1",
						"foldChange": "0.8363"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".474820559991918",
						"qValue": "1",
						"foldChange": "0.8065"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".268205371383778",
						"qValue": "1",
						"foldChange": "0.9462"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".658801520930883",
						"qValue": "1",
						"foldChange": "0.8704"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".531267740454725",
						"qValue": ".985830071188835",
						"foldChange": "0.9199"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".878644095957138",
						"qValue": ".366386388695206",
						"foldChange": "0.9667"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".806926834839282",
						"qValue": ".29070888399887",
						"foldChange": "1.2153"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".040864589900282",
						"qValue": ".016103044291849",
						"foldChange": "1.2458"
					}
				]
			},
			{
				"metaboliteId": "0078dd4e-0000-0000-0000-000000000000",
				"chemicalId": "05f5f170-0000-0000-0000-000000000000",
				"chemicalName": "guaiacol sulfate",
				"plotName": null,
				"compoundId": "0000b41f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60013",
				"inChiKey": "AQTYXAPIHMXAAV-UHFFFAOYAZ",
				"kegg": null,
				"mass": 204.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".600008205268697",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".211971286177518",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".957173160028829",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".832281628078764",
						"qValue": "1",
						"foldChange": "1.2020"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".606812015000832",
						"qValue": "1",
						"foldChange": "1.2521"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".761605303306325",
						"qValue": "1",
						"foldChange": "1.1937"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".37337022867777",
						"qValue": ".957150284015863",
						"foldChange": "1.4345"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".238997478346259",
						"qValue": ".825038702902081",
						"foldChange": "1.8024"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".770211982251528",
						"qValue": ".98622264532067",
						"foldChange": "1.3099"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".655267632863911",
						"qValue": "1",
						"foldChange": "1.1639"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".173747868233085",
						"qValue": "1",
						"foldChange": "1.4545"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".356254657184213",
						"qValue": "1",
						"foldChange": "1.1832"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".65476539608113",
						"qValue": "1",
						"foldChange": "0.8413"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".6924030937489",
						"qValue": "1",
						"foldChange": "1.0086"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".403259761057769",
						"qValue": "1",
						"foldChange": "1.1989"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".979202501108169",
						"qValue": "1",
						"foldChange": "0.7992"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".575998536131107",
						"qValue": "1",
						"foldChange": "1.1369"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".549887719313776",
						"qValue": "1",
						"foldChange": "1.4225"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".965311497935109",
						"qValue": "1",
						"foldChange": "1.2230"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".326884635975333",
						"qValue": "1",
						"foldChange": "1.1876"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".295838609232549",
						"qValue": ".985830071188835",
						"foldChange": "0.9711"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".014214512780005",
						"qValue": ".019211694270599",
						"foldChange": "2.8422"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".152169599527844",
						"qValue": ".084863902578641",
						"foldChange": "2.2574"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001129006728797",
						"qValue": ".001141616958867",
						"foldChange": "3.4030"
					}
				]
			},
			{
				"metaboliteId": "0078dd4f-0000-0000-0000-000000000000",
				"chemicalId": "05f5eda8-0000-0000-0000-000000000000",
				"chemicalName": "N-stearoyltaurine",
				"plotName": null,
				"compoundId": "00009b32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LMIJIHJZVURGQK-UHFFFAOYAS",
				"kegg": null,
				"mass": 391.275629,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1919,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".202592149966136",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".867818585343279",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".109298586802959",
						"qValue": ".823124755480694",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".156721928755695",
						"qValue": "1",
						"foldChange": "0.9355"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".20612982765012",
						"qValue": "1",
						"foldChange": "0.9209"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".875839290952103",
						"qValue": "1",
						"foldChange": "1.0816"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".026197068055299",
						"qValue": ".359575885726771",
						"foldChange": "1.6737"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".540451260291492",
						"qValue": ".910963185640849",
						"foldChange": "1.4028"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".099915253381377",
						"qValue": ".861821748215854",
						"foldChange": "0.8191"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".653222293451803",
						"qValue": "1",
						"foldChange": "1.0655"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".959347568867594",
						"qValue": "1",
						"foldChange": "1.0387"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".617017043592439",
						"qValue": "1",
						"foldChange": "1.1689"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".287982295960163",
						"qValue": "1",
						"foldChange": "0.8175"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".352409985804822",
						"qValue": "1",
						"foldChange": "1.1944"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".051233735917907",
						"qValue": "1",
						"foldChange": "1.4610"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".186520815324841",
						"qValue": "1",
						"foldChange": "1.5035"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".129219429329197",
						"qValue": "1",
						"foldChange": "1.4321"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".777560457090467",
						"qValue": "1",
						"foldChange": "0.9525"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".86374067049996",
						"qValue": "1",
						"foldChange": "1.0504"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".081268421217887",
						"qValue": "1",
						"foldChange": "1.3597"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".10387631338575",
						"qValue": ".985830071188835",
						"foldChange": "1.2945"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000830180021483",
						"qValue": ".002290539946545",
						"foldChange": "2.7025"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".212933737950173",
						"qValue": ".108746913996026",
						"foldChange": "1.5048"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".497419124312463",
						"qValue": ".110765952570734",
						"foldChange": "1.2948"
					}
				]
			},
			{
				"metaboliteId": "0078dd50-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea66-0000-0000-0000-000000000000",
				"chemicalName": "ibuprofen acyl glucuronide",
				"plotName": null,
				"compoundId": "000095de-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ABOLXXZAJIAUGR-JPMMFUSZBB",
				"kegg": null,
				"mass": 382.162768,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4796,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".2378849799305",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".452956125037819",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".493630812711852",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".705602226513376",
						"qValue": "1",
						"foldChange": "1.2037"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".705602226513376",
						"qValue": "1",
						"foldChange": "1.2037"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".768397248723945",
						"qValue": ".97174445063751",
						"foldChange": "1.1353"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".768397248723945",
						"qValue": ".98622264532067",
						"foldChange": "1.1353"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".044629341282382",
						"qValue": ".821720595261736",
						"foldChange": "0.8756"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".164104114370581",
						"qValue": "1",
						"foldChange": "1.4194"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".519346462440105",
						"qValue": "1",
						"foldChange": "1.4834"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".033911108583831",
						"qValue": "1",
						"foldChange": "26.6316"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".029914832291981",
						"qValue": "1",
						"foldChange": "26.6316"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999996",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".917777848591634",
						"qValue": "1",
						"foldChange": "0.9431"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".761251141321223",
						"qValue": "1",
						"foldChange": "1.2324"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".680993924190718",
						"qValue": ".990272584968167",
						"foldChange": "1.3067"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".787814166691164",
						"qValue": ".344316995840098",
						"foldChange": "0.6598"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137914",
						"qValue": ".152009550549949",
						"foldChange": "1.1353"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".534734643026782",
						"qValue": ".116084243862522",
						"foldChange": "0.3280"
					}
				]
			},
			{
				"metaboliteId": "0078dd51-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec89-0000-0000-0000-000000000000",
				"chemicalName": "16-hydroxypalmitate",
				"plotName": null,
				"compoundId": "00009ab9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06294",
				"inChiKey": "4000",
				"kegg": "C18218",
				"mass": 272.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1765,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".97633095983742",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".387322962113207",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".056796249208629",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".31294965130417",
						"qValue": "1",
						"foldChange": "0.9358"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".519828848195632",
						"qValue": "1",
						"foldChange": "1.0816"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".101797300560265",
						"qValue": "1",
						"foldChange": "1.2044"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".31040399863393",
						"qValue": ".926855448552541",
						"foldChange": "1.1754"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".977083926708248",
						"qValue": "1",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".324084666942601",
						"qValue": ".957839854315834",
						"foldChange": "0.9355"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".038013108567433",
						"qValue": ".821720595261736",
						"foldChange": "1.3127"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".70152690469337",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".015147615757262",
						"qValue": ".573029207973764",
						"foldChange": "0.7869"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".878303211532128",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".625886884142038",
						"qValue": "1",
						"foldChange": "0.9082"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".725182301575311",
						"qValue": "1",
						"foldChange": "0.8805"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".272640356874302",
						"qValue": "1",
						"foldChange": "1.2172"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".146186210144668",
						"qValue": "1",
						"foldChange": "1.1481"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".662013937870559",
						"qValue": "1",
						"foldChange": "0.9432"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".586200687576128",
						"qValue": "1",
						"foldChange": "0.9048"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".266497655512799",
						"qValue": "1",
						"foldChange": "0.7395"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".537037967361957",
						"qValue": ".985830071188835",
						"foldChange": "0.8173"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".984847987866011",
						"qValue": ".393334019199098",
						"foldChange": "1.0457"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".076695437303628",
						"qValue": ".050678316941893",
						"foldChange": "1.2991"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".041288420430859",
						"qValue": ".016200281917529",
						"foldChange": "1.4410"
					}
				]
			},
			{
				"metaboliteId": "0078dd52-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec88-0000-0000-0000-000000000000",
				"chemicalName": "docosadioate (C22-DC)",
				"plotName": null,
				"compoundId": "00009b9d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "4000",
				"kegg": "C19625",
				"mass": 370.30831,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1528,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".039262435531596",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".586766660129668",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".430207184514854",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".440745247378245",
						"qValue": "1",
						"foldChange": "1.1616"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".573366322011855",
						"qValue": "1",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".834244766380594",
						"qValue": "1",
						"foldChange": "1.5314"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".402701490909328",
						"qValue": ".979139910753823",
						"foldChange": "1.0800"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".984329060435363",
						"qValue": "1",
						"foldChange": "1.1533"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".413714224696006",
						"qValue": ".957839854315834",
						"foldChange": "1.2643"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".527882558236068",
						"qValue": "1",
						"foldChange": "1.2751"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".25565269485146",
						"qValue": "1",
						"foldChange": "0.8544"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".080362691787799",
						"qValue": ".770965175880612",
						"foldChange": "0.7223"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".308424648380337",
						"qValue": "1",
						"foldChange": "0.9067"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".666992511391622",
						"qValue": "1",
						"foldChange": "1.0951"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".155272559881234",
						"qValue": "1",
						"foldChange": "1.2077"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".2971146794119",
						"qValue": "1",
						"foldChange": "0.7275"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".067401926223698",
						"qValue": "1",
						"foldChange": "1.2354"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".004676162642848",
						"qValue": ".605991101987089",
						"foldChange": "1.6981"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".649252436772432",
						"qValue": "1",
						"foldChange": "0.8577"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".834942809195581",
						"qValue": "1",
						"foldChange": "0.8626"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".82016228590654",
						"qValue": ".997573179629986",
						"foldChange": "1.0057"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".222288536829726",
						"qValue": ".14353959648982",
						"foldChange": "1.3491"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".412601408422576",
						"qValue": ".174851723979602",
						"foldChange": "0.6682"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".144752709919774",
						"qValue": ".043338454807854",
						"foldChange": "1.4216"
					}
				]
			},
			{
				"metaboliteId": "0078dd53-0000-0000-0000-000000000000",
				"chemicalId": "05f5f754-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPG (18:1)*",
				"plotName": null,
				"compoundId": "0000b390-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 510.29577,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2419,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".414175865723601",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".343770282003139",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".534222164873797",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".793164718812598",
						"qValue": "1",
						"foldChange": "1.5817"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".913814660757448",
						"qValue": "1",
						"foldChange": "1.5977"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".711184263822737",
						"qValue": "1",
						"foldChange": "1.1360"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".601776836697534",
						"qValue": "1",
						"foldChange": "1.4412"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".571367235704242",
						"qValue": ".928868848501546",
						"foldChange": "1.2060"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".278860699517183",
						"qValue": ".957839854315834",
						"foldChange": "0.9128"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".3696194606525",
						"qValue": "1",
						"foldChange": "0.9413"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".057260463857756",
						"qValue": "1",
						"foldChange": "0.6709"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".302436977946626",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".434574172630748",
						"qValue": "1",
						"foldChange": "1.0831"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".289257529816355",
						"qValue": "1",
						"foldChange": "1.1085"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".740416206314333",
						"qValue": "1",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".13179007529",
						"qValue": "1",
						"foldChange": "1.6049"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".669409819563644",
						"qValue": "1",
						"foldChange": "1.0603"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".302928463394152",
						"qValue": "1",
						"foldChange": "0.6606"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".876416766889664",
						"qValue": "1",
						"foldChange": "1.1734"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".357338621625804",
						"qValue": "1",
						"foldChange": "0.7472"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".274944237252533",
						"qValue": ".985830071188835",
						"foldChange": "0.6368"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".301748717021643",
						"qValue": ".180336030541388",
						"foldChange": "1.4697"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".047344525351387",
						"qValue": ".036046431626031",
						"foldChange": "2.1361"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".028606424375977",
						"qValue": ".012396830784536",
						"foldChange": "1.9301"
					}
				]
			},
			{
				"metaboliteId": "0078dd54-0000-0000-0000-000000000000",
				"chemicalId": "05f5f5f7-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylalliin",
				"plotName": null,
				"compoundId": "0000b15c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 219.056529,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".232376781379201",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".076010383990347",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".600763044120633",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".762389092622734",
						"qValue": "1",
						"foldChange": "2.9783"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".563210702118474",
						"qValue": "1",
						"foldChange": "2.2196"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".379934126134091",
						"qValue": "1",
						"foldChange": "2.6528"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".046693725959299",
						"qValue": ".4787513348357",
						"foldChange": "4.5310"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".042224647774352",
						"qValue": ".678234398444917",
						"foldChange": "5.2893"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".964106314065093",
						"qValue": "1",
						"foldChange": "1.6479"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".265782996051379",
						"qValue": "1",
						"foldChange": "3.2050"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".202254360600816",
						"qValue": "1",
						"foldChange": "2.4927"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".868160063619112",
						"qValue": "1",
						"foldChange": "2.1668"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".534953557721771",
						"qValue": "1",
						"foldChange": "1.7617"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".924865152191974",
						"qValue": "1",
						"foldChange": "1.1510"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".613579596501808",
						"qValue": "1",
						"foldChange": "0.6533"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".035031224057297",
						"qValue": "1",
						"foldChange": "3.0091"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".288685261166503",
						"qValue": "1",
						"foldChange": "2.2177"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".320003985877162",
						"qValue": "1",
						"foldChange": "0.7370"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".118610817993574",
						"qValue": "1",
						"foldChange": "3.5573"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".544679636434708",
						"qValue": "1",
						"foldChange": "1.3488"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".364644411448398",
						"qValue": ".985830071188835",
						"foldChange": "0.3792"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001060833645737",
						"qValue": ".002744000298866",
						"foldChange": "7.0327"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000173585565572",
						"qValue": ".000805494946676",
						"foldChange": "26.3321"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013476772488155",
						"qValue": ".0068567737556",
						"foldChange": "3.7423"
					}
				]
			},
			{
				"metaboliteId": "0078dd55-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7ff-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyacetaminophen sulfate*",
				"plotName": null,
				"compoundId": "00008195-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 247.015058,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4776,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".105564748706148",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".952853373124545",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".559482317240539",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".301458598170752",
						"qValue": "1",
						"foldChange": "2.9871"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".23206237867869",
						"qValue": "1",
						"foldChange": "8.6433"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".869000328880196",
						"qValue": "1",
						"foldChange": "3.4882"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".970539732198855",
						"qValue": "1",
						"foldChange": "21.1497"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".380749425481935",
						"qValue": ".875723678608451",
						"foldChange": "1.0398"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".361250428531076",
						"qValue": ".957839854315834",
						"foldChange": "0.8860"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".609837263785888",
						"qValue": "1",
						"foldChange": "1.1263"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".973914994391578",
						"qValue": "1",
						"foldChange": "1.5929"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".632840529489092",
						"qValue": "1",
						"foldChange": "24.3174"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".164690636177096",
						"qValue": "1",
						"foldChange": "0.0348"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".407113970446862",
						"qValue": "1",
						"foldChange": "0.9623"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".609756287625528",
						"qValue": "1",
						"foldChange": "27.6749"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".057490615565077",
						"qValue": "1",
						"foldChange": "0.0765"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".107795959009382",
						"qValue": "1",
						"foldChange": "0.0529"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".833518717097258",
						"qValue": "1",
						"foldChange": "0.6918"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".016215444888339",
						"qValue": "1",
						"foldChange": "0.0073"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".149217819725021",
						"qValue": "1",
						"foldChange": "0.7016"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".355861999210399",
						"qValue": ".985830071188835",
						"foldChange": "95.7124"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".106849131898529",
						"qValue": ".086074798494619",
						"foldChange": "117.1543"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".189555389027825",
						"qValue": ".09971470252619",
						"foldChange": "1.2240"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007335829862125",
						"qValue": ".004319531299569",
						"foldChange": "166.9863"
					}
				]
			},
			{
				"metaboliteId": "0078dd56-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0f3-0000-0000-0000-000000000000",
				"chemicalName": "glycohyocholate",
				"plotName": null,
				"compoundId": "0000a64e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 465.309038,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3153,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".067156522051939",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".522056302989153",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".656594101735147",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".414214424286582",
						"qValue": "1",
						"foldChange": "1.3784"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".949489034451835",
						"qValue": "1",
						"foldChange": "1.2982"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".450991869320201",
						"qValue": "1",
						"foldChange": "1.0977"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".943991520991965",
						"qValue": "1",
						"foldChange": "1.5938"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".187259971804195",
						"qValue": ".821388069834477",
						"foldChange": "1.4760"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".165445376592955",
						"qValue": ".957839854315834",
						"foldChange": "0.9662"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".584393104891933",
						"qValue": "1",
						"foldChange": "1.5426"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".467188658222403",
						"qValue": "1",
						"foldChange": "1.2989"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".856199998591415",
						"qValue": "1",
						"foldChange": "1.1030"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".039881469811895",
						"qValue": "1",
						"foldChange": "5.9464"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".699754623285453",
						"qValue": "1",
						"foldChange": "0.8238"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".01860748255853",
						"qValue": "1",
						"foldChange": "0.1385"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".099345427102429",
						"qValue": "1",
						"foldChange": "2.4614"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".606055338585408",
						"qValue": "1",
						"foldChange": "0.8073"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".036443405319884",
						"qValue": ".822195137696456",
						"foldChange": "0.3280"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".183372775432069",
						"qValue": "1",
						"foldChange": "3.2415"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".997555731201616",
						"qValue": "1",
						"foldChange": "1.1235"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".198223958745097",
						"qValue": ".985830071188835",
						"foldChange": "0.3466"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000104596887406",
						"qValue": ".000494729217459",
						"foldChange": "0.1885"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".285470840973382",
						"qValue": ".134919539111586",
						"foldChange": "0.9460"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006550188549711",
						"qValue": ".003971092561775",
						"foldChange": "0.2761"
					}
				]
			},
			{
				"metaboliteId": "0078dd57-0000-0000-0000-000000000000",
				"chemicalId": "05f5e92e-0000-0000-0000-000000000000",
				"chemicalName": "gamma-CEHC",
				"plotName": null,
				"compoundId": "0000af4c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01931",
				"inChiKey": "VMJQLPNCUPGMNQ-UHFFFAOYAS",
				"kegg": null,
				"mass": 264.136159,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3976,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".095768817253593",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".000051392167573",
						"qValue": ".021867367302262",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".772683877604616",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".047852641081315",
						"qValue": "1",
						"foldChange": "0.8130"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".378356086839502",
						"qValue": "1",
						"foldChange": "1.1184"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".259335424619559",
						"qValue": "1",
						"foldChange": "1.3208"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001672432496885",
						"qValue": ".1236170295117",
						"foldChange": "0.6630"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".015996676615017",
						"qValue": ".567215491640796",
						"foldChange": "0.7235"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".408784485827454",
						"qValue": ".957839854315834",
						"foldChange": "1.2912"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".004151602542566",
						"qValue": ".543683917591992",
						"foldChange": "0.6230"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".018541197557435",
						"qValue": ".830450480072473",
						"foldChange": "0.7119"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".569478553420245",
						"qValue": "1",
						"foldChange": "1.0926"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".571029327810024",
						"qValue": "1",
						"foldChange": "1.1257"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".029330031785374",
						"qValue": "1",
						"foldChange": "1.5987"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".086565791219675",
						"qValue": "1",
						"foldChange": "1.4201"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".824183406347429",
						"qValue": "1",
						"foldChange": "0.9407"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".146709768924148",
						"qValue": "1",
						"foldChange": "1.2927"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".09058141663095",
						"qValue": ".834952786556829",
						"foldChange": "1.3742"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".640417930931481",
						"qValue": "1",
						"foldChange": "0.8743"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".268077468096743",
						"qValue": "1",
						"foldChange": "1.1159"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".11557641523405",
						"qValue": ".985830071188835",
						"foldChange": "1.2764"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".371539922448414",
						"qValue": ".203327888166431",
						"foldChange": "0.7099"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000550291911094",
						"qValue": ".001565785112628",
						"foldChange": "0.4812"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".143530668314275",
						"qValue": ".043052752551092",
						"foldChange": "0.7283"
					}
				]
			},
			{
				"metaboliteId": "0078dd58-0000-0000-0000-000000000000",
				"chemicalId": "00000136-0000-0000-0000-000000000000",
				"chemicalName": "cystathionine",
				"plotName": null,
				"compoundId": "00003d59-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00099",
				"inChiKey": "ILRYLPWNYFXEMH-UHFFFAOYAH",
				"kegg": "C02291",
				"mass": 222.06743,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".842721601143728",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".229942795602472",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".496277702011318",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".865291442893877",
						"qValue": "1",
						"foldChange": "1.0205"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".070497011860173",
						"qValue": "1",
						"foldChange": "1.5342"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".048940745191686",
						"qValue": "1",
						"foldChange": "1.5470"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".853020536905529",
						"qValue": "1",
						"foldChange": "1.1333"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".717765657613131",
						"qValue": ".950986052805043",
						"foldChange": "1.2195"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".860005864652902",
						"qValue": "1",
						"foldChange": "1.0762"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".942329476176134",
						"qValue": "1",
						"foldChange": "1.0799"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".363932795021553",
						"qValue": "1",
						"foldChange": "1.2512"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".402921692619739",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".973306071318185",
						"qValue": "1",
						"foldChange": "1.1716"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".736943574984168",
						"qValue": "1",
						"foldChange": "0.7021"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".706605827251149",
						"qValue": "1",
						"foldChange": "0.5992"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".976324431504611",
						"qValue": "1",
						"foldChange": "1.0562"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".847694482704703",
						"qValue": "1",
						"foldChange": "0.9176"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".82161660725692",
						"qValue": "1",
						"foldChange": "0.8688"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".192852958757389",
						"qValue": "1",
						"foldChange": "0.7161"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".399391213576548",
						"qValue": "1",
						"foldChange": "0.6168"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".683675454715775",
						"qValue": ".990272584968167",
						"foldChange": "0.8613"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".058677830818892",
						"qValue": ".055688190013004",
						"foldChange": "2.3967"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".032336919669984",
						"qValue": ".026365599326908",
						"foldChange": "2.2208"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004267838581137",
						"qValue": ".002931838133002",
						"foldChange": "2.8648"
					}
				]
			},
			{
				"metaboliteId": "0078dd59-0000-0000-0000-000000000000",
				"chemicalId": "05f5e296-0000-0000-0000-000000000000",
				"chemicalName": "ribitol",
				"plotName": null,
				"compoundId": "00003d9c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00508",
				"inChiKey": "HEBKCHPVOIAQTA-ZXFHETKHBG",
				"kegg": "C00474",
				"mass": 152.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1112,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".994966084315064",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".225233190221091",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".363144058257279",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".729514114356041",
						"qValue": "1",
						"foldChange": "0.9839"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".849190285135274",
						"qValue": "1",
						"foldChange": "1.0218"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".592394127174832",
						"qValue": "1",
						"foldChange": "1.0509"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".525647414182284",
						"qValue": "1",
						"foldChange": "1.0918"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".566627816845733",
						"qValue": ".927965721746881",
						"foldChange": "1.0771"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".950567960746177",
						"qValue": "1",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".096903936846667",
						"qValue": ".981729169720398",
						"foldChange": "0.9003"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".468808720947937",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".019196414030776",
						"qValue": ".605042531118158",
						"foldChange": "1.1496"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".823495622922128",
						"qValue": "1",
						"foldChange": "0.9627"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".821909944683905",
						"qValue": "1",
						"foldChange": "0.9904"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".655399952652448",
						"qValue": "1",
						"foldChange": "1.0288"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".657067536950687",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".465062962791464",
						"qValue": "1",
						"foldChange": "0.9213"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".241122268589847",
						"qValue": "1",
						"foldChange": "0.8596"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".979202601791862",
						"qValue": "1",
						"foldChange": "1.0165"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".542029601450829",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".548865061523657",
						"qValue": ".985830071188835",
						"foldChange": "0.9766"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".074162185143937",
						"qValue": ".066363247774734",
						"foldChange": "1.3106"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006326591299935",
						"qValue": ".007447687312721",
						"foldChange": "1.4430"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".146809686520507",
						"qValue": ".043629320774403",
						"foldChange": "1.1265"
					}
				]
			},
			{
				"metaboliteId": "0078dd5a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e20d-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphorylcholine (GPC)",
				"plotName": null,
				"compoundId": "00003e76-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00086",
				"inChiKey": "SUHOQUVVVLNYQR-MRVPVSSYBR",
				"kegg": "C00670",
				"mass": 257.102824,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1978,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".435432178152772",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".766354179788381",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".961200919630295",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".864704118313951",
						"qValue": "1",
						"foldChange": "1.0094"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".992902235145695",
						"qValue": "1",
						"foldChange": "1.0275"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".857717302971083",
						"qValue": "1",
						"foldChange": "1.0506"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".723747908422909",
						"qValue": "1",
						"foldChange": "1.0039"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".479131383614622",
						"qValue": ".902562957068497",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".72210742743679",
						"qValue": ".976023509428735",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".482916602737839",
						"qValue": "1",
						"foldChange": "0.9573"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".907963167560577",
						"qValue": "1",
						"foldChange": "0.9970"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".557365799616355",
						"qValue": "1",
						"foldChange": "1.0075"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".664838462853129",
						"qValue": "1",
						"foldChange": "0.9233"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".623663426016251",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".357720208452363",
						"qValue": "1",
						"foldChange": "1.1013"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".570178339721598",
						"qValue": "1",
						"foldChange": "0.9312"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".963202995387545",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".550448775181878",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".323668339252696",
						"qValue": "1",
						"foldChange": "0.8891"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".69709223948918",
						"qValue": "1",
						"foldChange": "0.9507"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".177588880911148",
						"qValue": ".985830071188835",
						"foldChange": "1.0693"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001146795678972",
						"qValue": ".002898421821409",
						"foldChange": "1.8095"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".02341978303331",
						"qValue": ".020536236329704",
						"foldChange": "1.3164"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000172966316015",
						"qValue": ".0003089868748",
						"foldChange": "1.8616"
					}
				]
			},
			{
				"metaboliteId": "0078dd5b-0000-0000-0000-000000000000",
				"chemicalId": "0000065c-0000-0000-0000-000000000000",
				"chemicalName": "glycochenodeoxycholate",
				"plotName": null,
				"compoundId": "00007e5a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00637",
				"inChiKey": "GHCZAUBVMUEKKP-UHFFFAOYAS",
				"kegg": "C05466",
				"mass": 449.314123,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3095,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".13078097697768",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".71978659040227",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".995349565261767",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".65065356030351",
						"qValue": "1",
						"foldChange": "1.4273"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".628494623983773",
						"qValue": "1",
						"foldChange": "2.3471"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".975190169126775",
						"qValue": "1",
						"foldChange": "1.6921"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".654498897343177",
						"qValue": "1",
						"foldChange": "1.8334"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".541791125970186",
						"qValue": ".910963185640849",
						"foldChange": "5.9961"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".870292825919175",
						"qValue": "1",
						"foldChange": "1.7984"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".926114034545418",
						"qValue": "1",
						"foldChange": "1.5034"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".748193052768177",
						"qValue": "1",
						"foldChange": "1.3302"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".679193342438201",
						"qValue": "1",
						"foldChange": "1.2620"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".554639801024313",
						"qValue": "1",
						"foldChange": "2.1421"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".332483697385733",
						"qValue": "1",
						"foldChange": "0.4090"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".12108989396724",
						"qValue": "1",
						"foldChange": "0.1909"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".567672073515924",
						"qValue": "1",
						"foldChange": "0.7940"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".187087024647759",
						"qValue": "1",
						"foldChange": "0.3087"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".059944749708552",
						"qValue": ".822195137696456",
						"foldChange": "0.3888"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".511872281220989",
						"qValue": "1",
						"foldChange": "1.3478"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".288779281369995",
						"qValue": "1",
						"foldChange": "0.4684"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".088698092267322",
						"qValue": ".985830071188835",
						"foldChange": "0.3475"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".018355348252516",
						"qValue": ".02355536082516",
						"foldChange": "0.2886"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".676140848964501",
						"qValue": ".259339022686739",
						"foldChange": "1.0713"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".445935556299229",
						"qValue": ".101265112571297",
						"foldChange": "0.7200"
					}
				]
			},
			{
				"metaboliteId": "0078dd5c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7e7-0000-0000-0000-000000000000",
				"chemicalName": "pyrraline",
				"plotName": null,
				"compoundId": "0000bd2c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33143",
				"inChiKey": "VTYFITADLSVOAS-UHFFFAOYAA",
				"kegg": null,
				"mass": 254.126657,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4605,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".523761101846498",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".35716744840648",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".485658785800931",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".895509971778881",
						"qValue": "1",
						"foldChange": "1.2256"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".489787799770772",
						"qValue": "1",
						"foldChange": "1.6618"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".411752144076211",
						"qValue": "1",
						"foldChange": "1.8197"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".135662350145455",
						"qValue": ".738628332941326",
						"foldChange": "1.1821"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".354360757784621",
						"qValue": ".861602871070606",
						"foldChange": "1.3328"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".56299845302824",
						"qValue": ".957839854315834",
						"foldChange": "1.9184"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".439976081747839",
						"qValue": "1",
						"foldChange": "0.8583"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".118308084613195",
						"qValue": "1",
						"foldChange": "0.7483"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".420967990992854",
						"qValue": "1",
						"foldChange": "0.8542"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".461063326757801",
						"qValue": "1",
						"foldChange": "1.3139"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".14429594200548",
						"qValue": "1",
						"foldChange": "1.4380"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".429446183164892",
						"qValue": "1",
						"foldChange": "1.0945"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".625813052980941",
						"qValue": "1",
						"foldChange": "0.8347"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".393252386505026",
						"qValue": "1",
						"foldChange": "1.2327"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".179996888951159",
						"qValue": "1",
						"foldChange": "1.4768"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".456314354626463",
						"qValue": "1",
						"foldChange": "1.1932"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".52151666049078",
						"qValue": "1",
						"foldChange": "0.8211"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".947851582959826",
						"qValue": ".99847190363668",
						"foldChange": "0.6881"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".800209871897214",
						"qValue": ".344316995840098",
						"foldChange": "1.2499"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".35827411165971",
						"qValue": ".15667881068273",
						"foldChange": "2.1008"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".029056435753148",
						"qValue": ".012490842819832",
						"foldChange": "1.8333"
					}
				]
			},
			{
				"metaboliteId": "0078dd5d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f7ca-0000-0000-0000-000000000000",
				"chemicalName": "9-hydroxystearate",
				"plotName": null,
				"compoundId": "0000b810-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61661",
				"inChiKey": "RKHXDCVAPIMDMG-UHFFFAOYAA",
				"kegg": null,
				"mass": 300.266445,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1784,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".894560736594343",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".014401542436237",
						"qValue": ".614455251574347",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".767447643394939",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".910822675214057",
						"qValue": "1",
						"foldChange": "1.0235"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".453448172417769",
						"qValue": "1",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".523135650188868",
						"qValue": "1",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".372915745148894",
						"qValue": ".957150284015863",
						"foldChange": "1.1752"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".164479166281735",
						"qValue": ".787973217949622",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".025163597921056",
						"qValue": ".580207043795744",
						"foldChange": "0.7981"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".839840695996164",
						"qValue": "1",
						"foldChange": "1.0808"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".073758186066238",
						"qValue": "1",
						"foldChange": "0.8232"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".047785653340298",
						"qValue": ".726171267724886",
						"foldChange": "0.7779"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".797190647735477",
						"qValue": "1",
						"foldChange": "1.1744"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".909453638962043",
						"qValue": "1",
						"foldChange": "1.0964"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".894582952191486",
						"qValue": "1",
						"foldChange": "0.9336"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".407600267212458",
						"qValue": "1",
						"foldChange": "1.3196"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".765556340630846",
						"qValue": "1",
						"foldChange": "1.0346"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".617555798745987",
						"qValue": "1",
						"foldChange": "0.7840"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".928202687855027",
						"qValue": "1",
						"foldChange": "1.1764"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".581696909679213",
						"qValue": "1",
						"foldChange": "0.8046"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".633026525810903",
						"qValue": ".985830071188835",
						"foldChange": "0.6840"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".123871402467747",
						"qValue": ".096241425791157",
						"foldChange": "0.7168"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".801782888243915",
						"qValue": ".289229372591477",
						"foldChange": "1.3348"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".910711806671127",
						"qValue": ".18009905207848",
						"foldChange": "0.9486"
					}
				]
			},
			{
				"metaboliteId": "0078dd5e-0000-0000-0000-000000000000",
				"chemicalId": "00000032-0000-0000-0000-000000000000",
				"chemicalName": "spermidine",
				"plotName": null,
				"compoundId": "000001e5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01257",
				"inChiKey": "ATHGHQPFGPMSJY-UHFFFAOYAK",
				"kegg": "C00315",
				"mass": 145.157897,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 470,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".531567431187895",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".988631688526895",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".856303929842858",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".963286428165875",
						"qValue": "1",
						"foldChange": "4.8193"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".531364192506044",
						"qValue": "1",
						"foldChange": "0.8984"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".561808305837171",
						"qValue": "1",
						"foldChange": "1.7214"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".935168572809117",
						"qValue": "1",
						"foldChange": "1.1777"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".422331836004796",
						"qValue": ".891822313747101",
						"foldChange": "1.9385"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".470431363202938",
						"qValue": ".957839854315834",
						"foldChange": "1.6379"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".828969810454256",
						"qValue": "1",
						"foldChange": "1.1882"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".987553517702696",
						"qValue": "1",
						"foldChange": "1.3177"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".841141430146268",
						"qValue": "1",
						"foldChange": "1.1406"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".258703851440879",
						"qValue": "1",
						"foldChange": "0.6613"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".657702169475886",
						"qValue": "1",
						"foldChange": "0.7451"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".51970360377673",
						"qValue": "1",
						"foldChange": "1.1268"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".316084923717691",
						"qValue": "1",
						"foldChange": "0.3398"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".863391444607644",
						"qValue": "1",
						"foldChange": "0.4013"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".425753116220319",
						"qValue": "1",
						"foldChange": "1.1812"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".752306461259562",
						"qValue": "1",
						"foldChange": "1.2081"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".848347150809725",
						"qValue": "1",
						"foldChange": "1.0392"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".912498198121726",
						"qValue": ".997573179629986",
						"foldChange": "0.8602"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".031646113797224",
						"qValue": ".036255122858497",
						"foldChange": "2.3289"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".508747661120821",
						"qValue": ".204743762557947",
						"foldChange": "0.6297"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".48435691954222",
						"qValue": ".108761075078072",
						"foldChange": "1.0756"
					}
				]
			},
			{
				"metaboliteId": "0078dd5f-0000-0000-0000-000000000000",
				"chemicalId": "000003fe-0000-0000-0000-000000000000",
				"chemicalName": "picolinate",
				"plotName": null,
				"compoundId": "000005e8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02243",
				"inChiKey": "SIOXPEMLGUPBBT-UHFFFAOYAC",
				"kegg": "C10164",
				"mass": 123.032029,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 254,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".955442356023865",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".965069506799268",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".748275796610091",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".450537811268022",
						"qValue": "1",
						"foldChange": "1.2096"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".629826433910695",
						"qValue": "1",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".783997921924106",
						"qValue": "1",
						"foldChange": "1.0038"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".351046788992445",
						"qValue": ".951252030575746",
						"foldChange": "0.9560"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".27787061870965",
						"qValue": ".825038702902081",
						"foldChange": "0.9369"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".876817338346995",
						"qValue": "1",
						"foldChange": "1.0617"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".883301132314673",
						"qValue": "1",
						"foldChange": "1.0431"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".875693940693555",
						"qValue": "1",
						"foldChange": "1.0454"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".99230218348328",
						"qValue": "1",
						"foldChange": "0.9987"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".657358852410392",
						"qValue": "1",
						"foldChange": "0.9919"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".981815172488053",
						"qValue": "1",
						"foldChange": "0.8755"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".650582880069034",
						"qValue": "1",
						"foldChange": "0.8826"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".539146427579736",
						"qValue": "1",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".697705301041873",
						"qValue": "1",
						"foldChange": "0.8121"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".843117475873615",
						"qValue": "1",
						"foldChange": "0.8804"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".594228756925971",
						"qValue": "1",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".829364733225051",
						"qValue": "1",
						"foldChange": "0.8479"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".767297222004621",
						"qValue": ".997573179629986",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".485295371036279",
						"qValue": ".244934041148258",
						"foldChange": "0.9093"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".801142129134182",
						"qValue": ".289229372591477",
						"foldChange": "1.0951"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".924697170544304",
						"qValue": ".181969448096492",
						"foldChange": "1.0284"
					}
				]
			},
			{
				"metaboliteId": "0078dd60-0000-0000-0000-000000000000",
				"chemicalId": "05f5f183-0000-0000-0000-000000000000",
				"chemicalName": "2-aminooctanoate",
				"plotName": null,
				"compoundId": "0000a94f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00991",
				"inChiKey": "AKVBCGQVQXPRLD-UHFFFAOYAX",
				"kegg": null,
				"mass": 159.125929,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Amino",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1579,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".553350834818116",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".927742629712729",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".445172668557713",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".30158514828309",
						"qValue": "1",
						"foldChange": "0.9112"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".453673043888604",
						"qValue": "1",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".773949110153326",
						"qValue": "1",
						"foldChange": "1.1466"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".423221465863595",
						"qValue": ".989197938984887",
						"foldChange": "1.0552"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".87464700567839",
						"qValue": "1",
						"foldChange": "1.2452"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".338713061991912",
						"qValue": ".957839854315834",
						"foldChange": "1.2620"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".251033265148522",
						"qValue": "1",
						"foldChange": "1.4618"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".988234814602978",
						"qValue": "1",
						"foldChange": "1.1444"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".257047858202379",
						"qValue": "1",
						"foldChange": "0.7843"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".239979239705153",
						"qValue": "1",
						"foldChange": "0.7482"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".341331718676443",
						"qValue": "1",
						"foldChange": "0.7861"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".866784883234677",
						"qValue": "1",
						"foldChange": "1.0507"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".315161789730078",
						"qValue": "1",
						"foldChange": "0.5756"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".694050787080827",
						"qValue": "1",
						"foldChange": "0.7658"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".171806667090236",
						"qValue": "1",
						"foldChange": "1.3305"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".538315018682532",
						"qValue": "1",
						"foldChange": "0.7938"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".618036490260184",
						"qValue": "1",
						"foldChange": "0.7001"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".931561338360519",
						"qValue": ".997573179629986",
						"foldChange": "0.8819"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".767012825544615",
						"qValue": ".336804680872625",
						"foldChange": "1.1299"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".768853034761243",
						"qValue": ".282094648105086",
						"foldChange": "1.1024"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".440050849752641",
						"qValue": ".100070128128093",
						"foldChange": "1.4240"
					}
				]
			},
			{
				"metaboliteId": "0078dd61-0000-0000-0000-000000000000",
				"chemicalId": "05f5f5e6-0000-0000-0000-000000000000",
				"chemicalName": "1-linolenoyl-GPC (18:3)*",
				"plotName": null,
				"compoundId": "0000b37f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10388",
				"inChiKey": null,
				"kegg": null,
				"mass": 517.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2333,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".795986689132977",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".613716365823353",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".004582261712424",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".080363380638133",
						"qValue": "1",
						"foldChange": "1.2962"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".022920418934562",
						"qValue": "1",
						"foldChange": "1.3506"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".575448090793705",
						"qValue": "1",
						"foldChange": "1.0876"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".812369276478941",
						"qValue": "1",
						"foldChange": "1.0315"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".573085317712417",
						"qValue": ".928868848501546",
						"foldChange": "1.0864"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".424170752269341",
						"qValue": ".957839854315834",
						"foldChange": "1.1520"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".004787625529502",
						"qValue": ".543683917591992",
						"foldChange": "0.7602"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".00571349269948",
						"qValue": ".522751459721039",
						"foldChange": "0.7475"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".948459761808373",
						"qValue": "1",
						"foldChange": "0.9593"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".150041498409274",
						"qValue": "1",
						"foldChange": "1.2006"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".006067246527422",
						"qValue": "1",
						"foldChange": "1.5299"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".134878839028194",
						"qValue": "1",
						"foldChange": "1.2743"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".964418918436836",
						"qValue": "1",
						"foldChange": "1.0100"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".538269749814836",
						"qValue": "1",
						"foldChange": "0.9677"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".556924594041768",
						"qValue": "1",
						"foldChange": "0.9581"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".916409822806789",
						"qValue": "1",
						"foldChange": "1.0258"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".336688135998307",
						"qValue": "1",
						"foldChange": "0.8039"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".278371576552182",
						"qValue": ".985830071188835",
						"foldChange": "0.7837"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".818173940908083",
						"qValue": ".350439447965646",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".631902692725504",
						"qValue": ".245751488228926",
						"foldChange": "0.9128"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".075980346571477",
						"qValue": ".026158051790088",
						"foldChange": "1.2891"
					}
				]
			},
			{
				"metaboliteId": "0078dd62-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebac-0000-0000-0000-000000000000",
				"chemicalName": "diphenhydramine",
				"plotName": null,
				"compoundId": "0000970d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01927",
				"inChiKey": "ZZVUWRFHKOJYTH-UHFFFAOYAI",
				"kegg": "C06960",
				"mass": 255.162314,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Respiratory",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".153647356306885",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".504522063876015",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".572221855103108",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".219707256003401",
						"qValue": "1",
						"foldChange": "0.8016"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".073589817250801",
						"qValue": "1",
						"foldChange": "0.8943"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".56086943877856",
						"qValue": "1",
						"foldChange": "1.1233"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".886511288204561",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".886511288204561",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".446695794355965",
						"qValue": "1",
						"foldChange": "12.5913"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".982659317919938",
						"qValue": "1",
						"foldChange": "0.9921"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".433940019407225",
						"qValue": "1",
						"foldChange": "1.6670"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".024881417876505",
						"qValue": "1",
						"foldChange": "0.0402"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".082537459950322",
						"qValue": "1",
						"foldChange": "0.0782"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".669114303314298",
						"qValue": "1",
						"foldChange": "1.9437"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".121342487709717",
						"qValue": "1",
						"foldChange": "0.0872"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".64006899653302",
						"qValue": "1",
						"foldChange": "2.3762"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".300379945894034",
						"qValue": "1",
						"foldChange": "27.2446"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".241744741442534",
						"qValue": "1",
						"foldChange": "0.2067"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".53168763039362",
						"qValue": "1",
						"foldChange": "0.6473"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".616984700807141",
						"qValue": ".985830071188835",
						"foldChange": "3.1317"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".170153236160705",
						"qValue": ".119609629397942",
						"foldChange": "3.1317"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".742705273662004",
						"qValue": ".15153442168488",
						"foldChange": "0.7757"
					}
				]
			},
			{
				"metaboliteId": "0078dd63-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee74-0000-0000-0000-000000000000",
				"chemicalName": "escitalopram",
				"plotName": null,
				"compoundId": "00009e0b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05028",
				"inChiKey": "WSEQXVZVJXJVFP-FQEVSTJZBT",
				"kegg": "D07913",
				"mass": 324.163792,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5106,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".979083708761536",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".503099309341381",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".456582952268624",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".432753043509872",
						"qValue": "1",
						"foldChange": "0.9148"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".966058877679691",
						"qValue": "1",
						"foldChange": "0.9916"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".457889707549055",
						"qValue": "1",
						"foldChange": "1.3291"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".368743150980081",
						"qValue": ".957150284015863",
						"foldChange": "0.9172"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".037587485241142",
						"qValue": ".678234398444917",
						"foldChange": "0.9016"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".224247930554989",
						"qValue": ".957839854315834",
						"foldChange": "0.9092"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".972485920679232",
						"qValue": "1",
						"foldChange": "1.0077"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".876267989010575",
						"qValue": "1",
						"foldChange": "1.0390"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".90351555556834",
						"qValue": "1",
						"foldChange": "1.0261"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".586903155873433",
						"qValue": "1",
						"foldChange": "4.0394"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".77589708389664",
						"qValue": "1",
						"foldChange": "0.7168"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".414666220368716",
						"qValue": "1",
						"foldChange": "0.1774"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".618135718097843",
						"qValue": "1",
						"foldChange": "1.7768"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".824764373344416",
						"qValue": "1",
						"foldChange": "1.1373"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".797491516800762",
						"qValue": "1",
						"foldChange": "0.6401"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".460688653927476",
						"qValue": "1",
						"foldChange": "0.5553"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".875169880727835",
						"qValue": "1",
						"foldChange": "0.7569"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".578931351066627",
						"qValue": ".985830071188835",
						"foldChange": "1.3631"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.3631"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.8010"
					}
				]
			},
			{
				"metaboliteId": "0078dd64-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9e6-0000-0000-0000-000000000000",
				"chemicalName": "1,2,3-benzenetriol sulfate (2)",
				"plotName": null,
				"compoundId": "0000be7a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 205.988509,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5351,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".845448892650352",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".997177469904274",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".269926511923319",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".313253082958739",
						"qValue": "1",
						"foldChange": "5.0676"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".083830037180738",
						"qValue": "1",
						"foldChange": "5.4388"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".459044495685476",
						"qValue": "1",
						"foldChange": "3.7289"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".887686375365516",
						"qValue": "1",
						"foldChange": "2.4000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".318312981113412",
						"qValue": ".828392498249276",
						"foldChange": "3.2590"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".390565977166307",
						"qValue": ".957839854315834",
						"foldChange": "2.1419"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".388293454708675",
						"qValue": "1",
						"foldChange": "1.0665"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".381670354968333",
						"qValue": "1",
						"foldChange": "0.7372"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".990225177652876",
						"qValue": "1",
						"foldChange": "1.6521"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".186966375256168",
						"qValue": "1",
						"foldChange": "0.6938"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".205598849160232",
						"qValue": "1",
						"foldChange": "0.7164"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".986025407729129",
						"qValue": "1",
						"foldChange": "1.0326"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".566149444001175",
						"qValue": "1",
						"foldChange": "0.4781"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".943861777076192",
						"qValue": "1",
						"foldChange": "0.3437"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".628374186413791",
						"qValue": "1",
						"foldChange": "0.7187"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".648715299208619",
						"qValue": "1",
						"foldChange": "0.6828"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".688221945103353",
						"qValue": "1",
						"foldChange": "0.6397"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".975783260199034",
						"qValue": "1",
						"foldChange": "0.9370"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".180026441546495",
						"qValue": ".123661951967529",
						"foldChange": "2.3069"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".283790248174165",
						"qValue": ".13435297241875",
						"foldChange": "1.4268"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".064651286259962",
						"qValue": ".023098600182457",
						"foldChange": "3.6865"
					}
				]
			},
			{
				"metaboliteId": "0078dd65-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0f8-0000-0000-0000-000000000000",
				"chemicalName": "retinal",
				"plotName": null,
				"compoundId": "00009f67-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01358",
				"inChiKey": "NCYCYZXNIZJOKI-OVSJKPMPBO",
				"kegg": "C00376",
				"mass": 284.214015,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4040,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".933588927506959",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".110458855975697",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".711342855707826",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".579796126395296",
						"qValue": "1",
						"foldChange": "1.2841"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".236609315304268",
						"qValue": "1",
						"foldChange": "1.3518"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".524557887843872",
						"qValue": "1",
						"foldChange": "1.4287"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".062867842790196",
						"qValue": ".563163518046909",
						"foldChange": "1.5296"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".629713677494942",
						"qValue": ".928868848501546",
						"foldChange": "1.3183"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".162329308643497",
						"qValue": ".957839854315834",
						"foldChange": "0.8541"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".241149955139138",
						"qValue": "1",
						"foldChange": "1.3198"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".37803032817452",
						"qValue": "1",
						"foldChange": "1.2489"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".76772615573992",
						"qValue": "1",
						"foldChange": "0.9783"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".699165085999042",
						"qValue": "1",
						"foldChange": "0.9399"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".733597220050729",
						"qValue": "1",
						"foldChange": "0.8876"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".979460722070694",
						"qValue": "1",
						"foldChange": "0.9443"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".475497744292608",
						"qValue": "1",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".807000537975082",
						"qValue": "1",
						"foldChange": "0.9504"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".658544255753171",
						"qValue": "1",
						"foldChange": "0.9698"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".30145917684513",
						"qValue": "1",
						"foldChange": "0.7821"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".580753052944397",
						"qValue": "1",
						"foldChange": "0.7948"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".661116010217962",
						"qValue": ".990272584968167",
						"foldChange": "1.0162"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000279716867784",
						"qValue": ".001040580033087",
						"foldChange": "3.5073"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00023255233401",
						"qValue": ".000898002474794",
						"foldChange": "3.5679"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00018428530811",
						"qValue": ".000315198299033",
						"foldChange": "5.0734"
					}
				]
			},
			{
				"metaboliteId": "0078dd66-0000-0000-0000-000000000000",
				"chemicalId": "05f5e71f-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPE (16:0)",
				"plotName": null,
				"compoundId": "00008b2f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11503",
				"inChiKey": "YVYMBNSKXOXSKW-HXUWFJFHBX",
				"kegg": null,
				"mass": 453.285539,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".941165350415559",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".918507724347033",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".285968154456339",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".142104336563544",
						"qValue": "1",
						"foldChange": "1.1446"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".14371959166056",
						"qValue": "1",
						"foldChange": "1.1515"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".995113681211423",
						"qValue": "1",
						"foldChange": "1.0082"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".298758073484135",
						"qValue": ".911265664999995",
						"foldChange": "0.9766"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".974121582574726",
						"qValue": "1",
						"foldChange": "1.0329"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".284180139603476",
						"qValue": ".957839854315834",
						"foldChange": "1.1061"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".52029733581277",
						"qValue": "1",
						"foldChange": "0.9573"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".344047008621399",
						"qValue": "1",
						"foldChange": "0.9627"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".75942909104575",
						"qValue": "1",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".485236406035418",
						"qValue": "1",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".375637691748433",
						"qValue": "1",
						"foldChange": "1.0443"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".815411607206621",
						"qValue": "1",
						"foldChange": "1.0163"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".258889375688863",
						"qValue": "1",
						"foldChange": "0.8903"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".529113905661721",
						"qValue": "1",
						"foldChange": "0.9498"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".64963257366376",
						"qValue": "1",
						"foldChange": "1.0668"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".708649921848717",
						"qValue": "1",
						"foldChange": "0.9672"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".394752783750982",
						"qValue": "1",
						"foldChange": "0.8788"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".608564820390707",
						"qValue": ".985830071188835",
						"foldChange": "0.9086"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001277902627959",
						"qValue": ".003134085450157",
						"foldChange": "1.8836"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000047003866901",
						"qValue": ".000377054242849",
						"foldChange": "1.6622"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000018136578808",
						"qValue": ".000094015654658",
						"foldChange": "2.2835"
					}
				]
			},
			{
				"metaboliteId": "0078dd67-0000-0000-0000-000000000000",
				"chemicalId": "05f5e702-0000-0000-0000-000000000000",
				"chemicalName": "lidocaine",
				"plotName": null,
				"compoundId": "00008b4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB14426",
				"inChiKey": "NNJVILVZKWQKPM-UHFFFAOYAR",
				"kegg": "D00358",
				"mass": 234.173213,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4814,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".299468531870947",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".898831281357412",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".839605660538104",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".805455646458746",
						"qValue": "1",
						"foldChange": "1.4530"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".402726368842605",
						"qValue": ".882901404171953",
						"foldChange": "11.1368"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".280330775363633",
						"qValue": ".957839854315834",
						"foldChange": "2.5020"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".591834845960301",
						"qValue": "1",
						"foldChange": "1.3012"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".591834845960301",
						"qValue": "1",
						"foldChange": "0.9215"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".398846285414425",
						"qValue": "1",
						"foldChange": "2.1095"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".413719766461826",
						"qValue": "1",
						"foldChange": "0.4740"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".527711455087108",
						"qValue": "1",
						"foldChange": "1.5447"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".623054419090907",
						"qValue": "1",
						"foldChange": "1.2677"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".913482818991924",
						"qValue": "1",
						"foldChange": "0.8207"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".11893066217497",
						"qValue": "1",
						"foldChange": "11.2285"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".130727970358876",
						"qValue": ".985830071188835",
						"foldChange": "0.0891"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "11.2285"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078dd68-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9c9-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-cadaverine",
				"plotName": null,
				"compoundId": "0000aa0a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02284",
				"inChiKey": null,
				"kegg": null,
				"mass": 144.126263,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 126,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".171013893779815",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".831386345756705",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".018485584759719",
						"qValue": ".620468227380083",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".116317683725402",
						"qValue": "1",
						"foldChange": "1.8446"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".113702847787942",
						"qValue": "1",
						"foldChange": "1.6396"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".990585073947402",
						"qValue": "1",
						"foldChange": "1.1430"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".276445040233837",
						"qValue": ".911265664999995",
						"foldChange": "1.7820"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".621052191494396",
						"qValue": ".928868848501546",
						"foldChange": "1.3608"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".116506603581861",
						"qValue": ".918028885631147",
						"foldChange": "0.8113"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".011958553938455",
						"qValue": ".678448626775002",
						"foldChange": "0.5749"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".052023753871584",
						"qValue": "1",
						"foldChange": "0.6776"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".538038809299198",
						"qValue": "1",
						"foldChange": "1.3374"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".150988857518391",
						"qValue": "1",
						"foldChange": "5.0203"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".00579337473791",
						"qValue": "1",
						"foldChange": "8.7937"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".117840284640717",
						"qValue": "1",
						"foldChange": "1.7516"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".23107126075388",
						"qValue": "1",
						"foldChange": "3.8460"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".281969210650181",
						"qValue": "1",
						"foldChange": "2.0010"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".951289325654955",
						"qValue": "1",
						"foldChange": "0.5203"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".599230875184418",
						"qValue": "1",
						"foldChange": "3.8048"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".180206299569476",
						"qValue": "1",
						"foldChange": "3.1839"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".38030146998111",
						"qValue": ".985830071188835",
						"foldChange": "0.8368"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".062638910483021",
						"qValue": ".058256050179675",
						"foldChange": "4.4585"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".256280153285957",
						"qValue": ".12434741170742",
						"foldChange": "2.7641"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010838193579194",
						"qValue": ".005739703612364",
						"foldChange": "2.3660"
					}
				]
			},
			{
				"metaboliteId": "0078dd69-0000-0000-0000-000000000000",
				"chemicalId": "05f5e53b-0000-0000-0000-000000000000",
				"chemicalName": "indolepropionate",
				"plotName": null,
				"compoundId": "00007e95-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02302",
				"inChiKey": "GOLXRNDWAUTYKT-UHFFFAOYAW",
				"kegg": null,
				"mass": 189.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 270,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".774325872776553",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".443662768440239",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".049304201603466",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".899094173339756",
						"qValue": "1",
						"foldChange": "1.1659"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".070042001790143",
						"qValue": "1",
						"foldChange": "1.4532"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".053402106055221",
						"qValue": "1",
						"foldChange": "1.5667"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".15256348758969",
						"qValue": ".742487134132407",
						"foldChange": "1.5461"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".040521671265681",
						"qValue": ".678234398444917",
						"foldChange": "1.6400"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".517693147229472",
						"qValue": ".957839854315834",
						"foldChange": "1.1799"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".981741784817242",
						"qValue": "1",
						"foldChange": "1.0339"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".133796321484875",
						"qValue": "1",
						"foldChange": "0.7869"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".139622883655973",
						"qValue": ".960252848686774",
						"foldChange": "0.7384"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".868583973537388",
						"qValue": "1",
						"foldChange": "1.5178"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".400468016924308",
						"qValue": "1",
						"foldChange": "1.5865"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".309055551010349",
						"qValue": "1",
						"foldChange": "1.0453"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".841673667355542",
						"qValue": "1",
						"foldChange": "2.2131"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".387630136518185",
						"qValue": "1",
						"foldChange": "1.7492"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".487275830545638",
						"qValue": "1",
						"foldChange": "0.7904"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".896187005609093",
						"qValue": "1",
						"foldChange": "1.5382"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".959473990237546",
						"qValue": "1",
						"foldChange": "0.8316"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".858402376591078",
						"qValue": ".997573179629986",
						"foldChange": "0.5406"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".416762205654367",
						"qValue": ".219723001264169",
						"foldChange": "0.6466"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".455031412693476",
						"qValue": ".188534754933461",
						"foldChange": "1.0322"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".208981377485102",
						"qValue": ".057313684477154",
						"foldChange": "0.7048"
					}
				]
			},
			{
				"metaboliteId": "0078dd6a-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebf6-0000-0000-0000-000000000000",
				"chemicalName": "gabapentin",
				"plotName": null,
				"compoundId": "0000971e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05015",
				"inChiKey": "UGJMXCAKCUNAIE-UHFFFAOYAG",
				"kegg": "D00332",
				"mass": 171.125929,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Neurological",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5065,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".636769016400654",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".491736211051808",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".306228009418193",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".322970372182176",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".114038417046161",
						"qValue": "1",
						"foldChange": "0.9673"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".544103488026247",
						"qValue": "1",
						"foldChange": "0.9863"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".329824321919657",
						"qValue": ".951252030575746",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".281062596885041",
						"qValue": ".825038702902081",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".91622224770669",
						"qValue": "1",
						"foldChange": "1.0024"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".502016831001547",
						"qValue": "1",
						"foldChange": "0.9839"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".16595422199076",
						"qValue": "1",
						"foldChange": "0.9688"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".468763998188273",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".163823367049055",
						"qValue": "1",
						"foldChange": "0.8801"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".48322275785861",
						"qValue": "1",
						"foldChange": "0.9281"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".516629272596618",
						"qValue": "1",
						"foldChange": "1.0546"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".468545835934651",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".5435334728728",
						"qValue": "1",
						"foldChange": "0.9342"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".935386182944105",
						"qValue": "1",
						"foldChange": "1.0028"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".635577716461263",
						"qValue": "1",
						"foldChange": "0.9571"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".507966015828507",
						"qValue": "1",
						"foldChange": "0.9390"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".825269267185813",
						"qValue": ".997573179629986",
						"foldChange": "0.9811"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".192863611674612",
						"foldChange": "1.0087"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "1.0282"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087334",
						"qValue": ".083280304277061",
						"foldChange": "1.0742"
					}
				]
			},
			{
				"metaboliteId": "0078dd6b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e554-0000-0000-0000-000000000000",
				"chemicalName": "3-methylxanthine",
				"plotName": null,
				"compoundId": "00007ebd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01886",
				"inChiKey": "GMSNIKWWOQHZGF-UHFFFAOYAL",
				"kegg": "C16357",
				"mass": 166.049075,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4166,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".121530281387079",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".578179921287886",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".994417738359701",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".985279599000295",
						"qValue": "1",
						"foldChange": "1.8631"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".604599890733497",
						"qValue": "1",
						"foldChange": "1.3836"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".617482313439868",
						"qValue": "1",
						"foldChange": "1.1259"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".563762634534231",
						"qValue": "1",
						"foldChange": "1.1378"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".509583858344299",
						"qValue": ".905893288986492",
						"foldChange": "1.0954"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".934075193008878",
						"qValue": "1",
						"foldChange": "1.2296"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".593599597213667",
						"qValue": "1",
						"foldChange": "1.0678"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".537114041963618",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".93306384599496",
						"qValue": "1",
						"foldChange": "1.0913"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".673918424019863",
						"qValue": "1",
						"foldChange": "0.8778"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".158708077286021",
						"qValue": "1",
						"foldChange": "1.5251"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".067375838418937",
						"qValue": "1",
						"foldChange": "1.7375"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".469507830310146",
						"qValue": "1",
						"foldChange": "0.6121"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".262231648283715",
						"qValue": "1",
						"foldChange": "1.3970"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".068809178671819",
						"qValue": ".822195137696456",
						"foldChange": "2.2825"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".62918136474835",
						"qValue": "1",
						"foldChange": "1.1956"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".181025959253245",
						"qValue": "1",
						"foldChange": "1.4048"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".06965476594006",
						"qValue": ".985830071188835",
						"foldChange": "1.1749"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000148596197195",
						"qValue": ".000664848684926",
						"foldChange": "5.3578"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".010820977029424",
						"qValue": ".010853924227032",
						"foldChange": "6.0032"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005518860742734",
						"qValue": ".003507114300061",
						"foldChange": "4.2975"
					}
				]
			},
			{
				"metaboliteId": "0078dd6c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2eb-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylphenylalanine",
				"plotName": null,
				"compoundId": "0000828e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00594",
				"inChiKey": "XHHOHZPNYFQJKL-QWRGUYRKBO",
				"kegg": null,
				"mass": 294.121573,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".60574990063379",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".950676258030248",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".809126662826767",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".665548789883593",
						"qValue": "1",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".51323429315206",
						"qValue": "1",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".823898754482396",
						"qValue": "1",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".895933610044627",
						"qValue": "1",
						"foldChange": "1.2528"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".379951403687381",
						"qValue": ".875723678608451",
						"foldChange": "1.2434"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".454138686860087",
						"qValue": ".957839854315834",
						"foldChange": "1.1380"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".836311841500134",
						"qValue": "1",
						"foldChange": "1.0011"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".584579382360675",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".733329439913156",
						"qValue": "1",
						"foldChange": "0.9318"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".450426927088822",
						"qValue": "1",
						"foldChange": "0.8869"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".631054711590598",
						"qValue": "1",
						"foldChange": "1.0798"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".224052160251352",
						"qValue": "1",
						"foldChange": "1.2176"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".709177873485531",
						"qValue": "1",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".53610495612074",
						"qValue": "1",
						"foldChange": "1.1412"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".3215736621999",
						"qValue": "1",
						"foldChange": "1.2371"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".786294170275111",
						"qValue": "1",
						"foldChange": "1.0145"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".597081948254374",
						"qValue": "1",
						"foldChange": "1.0596"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".779868360630596",
						"qValue": ".997573179629986",
						"foldChange": "1.0445"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".144891988498488",
						"qValue": ".106731111544603",
						"foldChange": "1.7099"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000006354290708",
						"qValue": ".000146117023651",
						"foldChange": "2.1833"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008747027608659",
						"qValue": ".004900039785429",
						"foldChange": "1.8057"
					}
				]
			},
			{
				"metaboliteId": "0078dd6d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e610-0000-0000-0000-000000000000",
				"chemicalName": "stachydrine",
				"plotName": null,
				"compoundId": "00008650-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04827",
				"inChiKey": "CMUNUTVVOOHQPW-LURJTMIEBI",
				"kegg": "C10172",
				"mass": 143.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".648931942399522",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".917534589683579",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".905921302397114",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".64602098452815",
						"qValue": "1",
						"foldChange": "6.5523"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".832796140790177",
						"qValue": "1",
						"foldChange": "4.9320"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".803701578433468",
						"qValue": "1",
						"foldChange": "1.2908"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".364404420643588",
						"qValue": ".951252030575746",
						"foldChange": "1.9526"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".81116207253148",
						"qValue": ".991417013240296",
						"foldChange": "2.2726"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".502632093803479",
						"qValue": ".957839854315834",
						"foldChange": "1.7785"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".950774760580287",
						"qValue": "1",
						"foldChange": "1.3488"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".812719339264556",
						"qValue": "1",
						"foldChange": "1.3159"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".765257118769873",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".309845209861455",
						"qValue": "1",
						"foldChange": "0.6250"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".407542064072765",
						"qValue": "1",
						"foldChange": "0.5688"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".890325135663469",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".86862886785715",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".610358493730682",
						"qValue": "1",
						"foldChange": "0.5162"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".717139573521105",
						"qValue": "1",
						"foldChange": "0.5219"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".461597409030549",
						"qValue": "1",
						"foldChange": "0.8428"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".393934943714755",
						"qValue": "1",
						"foldChange": "0.5033"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".871583575825136",
						"qValue": ".997573179629986",
						"foldChange": "0.5972"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".094198374942674",
						"qValue": ".078304789052164",
						"foldChange": "1.6155"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".404577159020725",
						"qValue": ".171712178066145",
						"foldChange": "1.6100"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".101858836103825",
						"qValue": ".033192951454601",
						"foldChange": "1.8249"
					}
				]
			},
			{
				"metaboliteId": "0078dd6e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6cd-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylisoleucine*",
				"plotName": null,
				"compoundId": "00008698-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11170",
				"inChiKey": "SNCKGJWJABDZHI-SIPWABPFBP",
				"kegg": null,
				"mass": 260.137222,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 554,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".404922276972217",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".674421747329685",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".502905487507543",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".151078101076455",
						"qValue": "1",
						"foldChange": "0.9248"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".641445523647673",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".326965694363459",
						"qValue": "1",
						"foldChange": "1.0914"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".811598019004331",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".274461332709266",
						"qValue": ".825038702902081",
						"foldChange": "0.9516"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".184893820104583",
						"qValue": ".957839854315834",
						"foldChange": "0.9439"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".93952299825286",
						"qValue": "1",
						"foldChange": "1.0182"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".968112738047516",
						"qValue": "1",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".971365508794601",
						"qValue": "1",
						"foldChange": "0.9447"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".86434528820751",
						"qValue": "1",
						"foldChange": "0.8965"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".462808745001959",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".360174560921867",
						"qValue": "1",
						"foldChange": "1.1466"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".470712241779077",
						"qValue": "1",
						"foldChange": "1.0923"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".137346021723223",
						"qValue": "1",
						"foldChange": "1.1694"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".400357322124611",
						"qValue": "1",
						"foldChange": "1.0706"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".632692104372678",
						"qValue": "1",
						"foldChange": "0.9312"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".323809247864072",
						"qValue": "1",
						"foldChange": "1.0577"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".144609603810726",
						"qValue": ".985830071188835",
						"foldChange": "1.1359"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".073065434977786",
						"qValue": ".065916288873139",
						"foldChange": "1.6795"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008603869802223",
						"qValue": ".008985612265991",
						"foldChange": "1.3805"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".022514351605707",
						"qValue": ".010342187461663",
						"foldChange": "1.3716"
					}
				]
			},
			{
				"metaboliteId": "0078dd6f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e61f-0000-0000-0000-000000000000",
				"chemicalName": "ophthalmate",
				"plotName": null,
				"compoundId": "00008720-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05765",
				"inChiKey": "JCMUOFQHZLPHQP-UHFFFAOYAD",
				"kegg": null,
				"mass": 289.127385,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".273599531485562",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".267037498617835",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".258787028455876",
						"qValue": ".967251975631872",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".016627233674752",
						"qValue": "1",
						"foldChange": "3.5779"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".016627233674752",
						"qValue": "1",
						"foldChange": "0.8492"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".013029722219913",
						"qValue": "1",
						"foldChange": "0.2795"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".018619887049725",
						"qValue": "1",
						"foldChange": "0.2795"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".289539672633034",
						"qValue": ".174458457291099",
						"foldChange": "0.9710"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".01356790072432",
						"qValue": ".013136669554595",
						"foldChange": "0.5495"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".061930422691237",
						"qValue": ".02232493438645",
						"foldChange": "0.5998"
					}
				]
			},
			{
				"metaboliteId": "0078dd70-0000-0000-0000-000000000000",
				"chemicalId": "05f5f056-0000-0000-0000-000000000000",
				"chemicalName": "(R)-3-hydroxybutyrylcarnitine",
				"plotName": null,
				"compoundId": "0000a900-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13127",
				"inChiKey": null,
				"kegg": null,
				"mass": 247.141973,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1695,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".874572025849093",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".677078826692383",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".14091291752875",
						"qValue": ".879348068443109",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".21586001754591",
						"qValue": "1",
						"foldChange": "0.9800"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".173811486010183",
						"qValue": "1",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".899942127162897",
						"qValue": "1",
						"foldChange": "1.0200"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".727701150317906",
						"qValue": "1",
						"foldChange": "1.1851"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".343979775712269",
						"qValue": ".847814688346659",
						"foldChange": "0.9468"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".197779684845918",
						"qValue": ".957839854315834",
						"foldChange": "0.8681"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".077868073863079",
						"qValue": ".886468253895902",
						"foldChange": "1.4776"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".104125410662291",
						"qValue": "1",
						"foldChange": "1.5880"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".885199118586144",
						"qValue": "1",
						"foldChange": "1.1395"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".481323293323254",
						"qValue": "1",
						"foldChange": "0.5765"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".130039615269381",
						"qValue": "1",
						"foldChange": "0.4308"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".375173971504649",
						"qValue": "1",
						"foldChange": "0.7473"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".868273820949141",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".914469631048924",
						"qValue": "1",
						"foldChange": "0.8430"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".959604544221682",
						"qValue": "1",
						"foldChange": "0.7889"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".656024466113681",
						"qValue": "1",
						"foldChange": "0.7268"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".925843836367357",
						"qValue": "1",
						"foldChange": "0.8840"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".598631940521244",
						"qValue": ".985830071188835",
						"foldChange": "1.2164"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".408317878778511",
						"qValue": ".216501028847437",
						"foldChange": "0.7429"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".155202429394193",
						"qValue": ".086210448415059",
						"foldChange": "0.5927"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".186343399308407",
						"qValue": ".053025719675672",
						"foldChange": "0.7585"
					}
				]
			},
			{
				"metaboliteId": "0078dd71-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2cb-0000-0000-0000-000000000000",
				"chemicalName": "benzoylcarnitine*",
				"plotName": null,
				"compoundId": "0000a901-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 265.131408,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5326,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".430886254812798",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".93512032455599",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".143197408670461",
						"qValue": ".883050686801176",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".06928416778429",
						"qValue": "1",
						"foldChange": "0.9352"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".087897612433583",
						"qValue": "1",
						"foldChange": "0.7816"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".90807730776082",
						"qValue": "1",
						"foldChange": "1.5737"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".526384428962731",
						"qValue": "1",
						"foldChange": "1.1847"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".230699499317825",
						"qValue": ".825038702902081",
						"foldChange": "1.4625"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".567454208901596",
						"qValue": ".957839854315834",
						"foldChange": "1.2457"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".299677638175321",
						"qValue": "1",
						"foldChange": "1.3075"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".986235379836943",
						"qValue": "1",
						"foldChange": "1.1236"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".291845073710494",
						"qValue": "1",
						"foldChange": "0.8705"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".027040831500565",
						"qValue": "1",
						"foldChange": "0.3892"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".103923105659411",
						"qValue": "1",
						"foldChange": "0.4427"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".608532370392628",
						"qValue": "1",
						"foldChange": "1.1374"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".507590494933307",
						"qValue": "1",
						"foldChange": "0.6807"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".852399993379184",
						"qValue": "1",
						"foldChange": "0.7929"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".406134411871602",
						"qValue": "1",
						"foldChange": "1.1648"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".702108126114787",
						"qValue": "1",
						"foldChange": "1.1039"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".55914529174082",
						"qValue": "1",
						"foldChange": "0.6676"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".819104149750605",
						"qValue": ".997573179629986",
						"foldChange": "0.6048"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".025139005662182",
						"qValue": ".029832574304035",
						"foldChange": "1.5220"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".094657534496464",
						"qValue": ".058786005853995",
						"foldChange": "2.5166"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".034321138718802",
						"qValue": ".01425843070254",
						"foldChange": "2.2798"
					}
				]
			},
			{
				"metaboliteId": "0078dd72-0000-0000-0000-000000000000",
				"chemicalId": "05f5e85a-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl-N-stearoyl-sphingosine (d18:1/18:0)",
				"plotName": null,
				"compoundId": "0000cc0a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 727.596219,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2746,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".799985853652593",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".876285618453769",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".610287165843529",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".850382279145093",
						"qValue": "1",
						"foldChange": "1.0272"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".393795202139152",
						"qValue": "1",
						"foldChange": "1.1156"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".298910512934258",
						"qValue": "1",
						"foldChange": "1.1570"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".221717060144554",
						"qValue": ".842371543132976",
						"foldChange": "1.1788"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".561677916695591",
						"qValue": ".926856688788175",
						"foldChange": "1.0915"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".516463065293039",
						"qValue": ".957839854315834",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".785837552521635",
						"qValue": "1",
						"foldChange": "1.0258"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".587698023409583",
						"qValue": "1",
						"foldChange": "1.0157"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".786326849281668",
						"qValue": "1",
						"foldChange": "0.9652"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".864327349589611",
						"qValue": "1",
						"foldChange": "0.9441"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".394208943251446",
						"qValue": "1",
						"foldChange": "1.0863"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".478158537352914",
						"qValue": "1",
						"foldChange": "1.1507"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".37427440176654",
						"qValue": "1",
						"foldChange": "1.1689"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".422221989943364",
						"qValue": "1",
						"foldChange": "1.1660"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".968427373522393",
						"qValue": "1",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".993012480942172",
						"qValue": "1",
						"foldChange": "0.9750"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".892284028941383",
						"qValue": "1",
						"foldChange": "0.9701"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".896378679386869",
						"qValue": ".997573179629986",
						"foldChange": "0.9950"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".114505889428848",
						"qValue": ".090481676739354",
						"foldChange": "1.4048"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".041284849460542",
						"qValue": ".03197814818926",
						"foldChange": "1.4845"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004002131160285",
						"qValue": ".00283456529792",
						"foldChange": "2.0254"
					}
				]
			},
			{
				"metaboliteId": "0078dd73-0000-0000-0000-000000000000",
				"chemicalId": "05f60538-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl-N-palmitoyl-sphingosine (d18:1/16:0)",
				"plotName": null,
				"compoundId": "0000cf15-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 699.564918,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".92668631514653",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".928505329140863",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".554716692177274",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".44632870837573",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".577747586467246",
						"qValue": "1",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".190340159520945",
						"qValue": "1",
						"foldChange": "1.0814"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".722842069913444",
						"qValue": "1",
						"foldChange": "1.0369"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".861332108413318",
						"qValue": "1",
						"foldChange": "1.0160"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".85702689775463",
						"qValue": "1",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".824922682401281",
						"qValue": "1",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".291545289304613",
						"qValue": "1",
						"foldChange": "0.9551"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".402833184833713",
						"qValue": "1",
						"foldChange": "0.9475"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".900109709712536",
						"qValue": "1",
						"foldChange": "0.9978"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".924915098175923",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".980014023376426",
						"qValue": "1",
						"foldChange": "1.0101"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".487488237793558",
						"qValue": "1",
						"foldChange": "1.0759"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".723602077483226",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".754958942391682",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".937948499481388",
						"qValue": "1",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".466095386264197",
						"qValue": "1",
						"foldChange": "0.9132"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".501642045739466",
						"qValue": ".985830071188835",
						"foldChange": "0.9275"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".171545280129734",
						"qValue": ".120078283522674",
						"foldChange": "0.8651"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".361030777366161",
						"qValue": ".157080662814445",
						"foldChange": "0.9423"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".582129789011537",
						"qValue": ".12429276610658",
						"foldChange": "1.0551"
					}
				]
			},
			{
				"metaboliteId": "0078dd74-0000-0000-0000-000000000000",
				"chemicalId": "05f6041e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-GPE (16:0/20:4)*",
				"plotName": null,
				"compoundId": "0000ccf0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05323",
				"inChiKey": "DRIVXEVMDWCWLI-CAQMIEAIBF",
				"kegg": null,
				"mass": 739.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2148,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".567387966361191",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".777107543501385",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".754630071703591",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".508125153249716",
						"qValue": "1",
						"foldChange": "1.0986"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".471854454442355",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".170356517646641",
						"qValue": "1",
						"foldChange": "0.9295"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".674202164404586",
						"qValue": "1",
						"foldChange": "1.0954"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".508779588549885",
						"qValue": ".905893288986492",
						"foldChange": "1.1062"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".809241083845108",
						"qValue": "1",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".965604494135109",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".864420209798099",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".898431303558175",
						"qValue": "1",
						"foldChange": "0.9859"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".672241181227953",
						"qValue": "1",
						"foldChange": "1.1505"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".74544904493464",
						"qValue": "1",
						"foldChange": "0.9348"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".459030788848254",
						"qValue": "1",
						"foldChange": "0.8125"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".792541588495824",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".498036876491233",
						"qValue": "1",
						"foldChange": "0.8485"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".344370843649826",
						"qValue": "1",
						"foldChange": "0.8484"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".218205649921947",
						"qValue": "1",
						"foldChange": "1.2326"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".846541619642224",
						"qValue": "1",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".317459273198189",
						"qValue": ".985830071188835",
						"foldChange": "0.7858"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008273196518996",
						"qValue": ".01311944536262",
						"foldChange": "1.5450"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00029369213378",
						"qValue": ".001063571510613",
						"foldChange": "2.0506"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000775606958445",
						"qValue": ".000890706592542",
						"foldChange": "1.7999"
					}
				]
			},
			{
				"metaboliteId": "0078dd75-0000-0000-0000-000000000000",
				"chemicalId": "05f60426-0000-0000-0000-000000000000",
				"chemicalName": "gamma-tocopherol/beta-tocopherol",
				"plotName": null,
				"compoundId": "0000ccf9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 416.365431,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3990,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".665845041097401",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".472311342164382",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".272066265187941",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".183737670150583",
						"qValue": "1",
						"foldChange": "0.9044"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".260739156794915",
						"qValue": "1",
						"foldChange": "0.8861"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".834009003978042",
						"qValue": "1",
						"foldChange": "1.0994"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".340256355945958",
						"qValue": ".951252030575746",
						"foldChange": "1.2128"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".746489747562764",
						"qValue": ".960368665676976",
						"foldChange": "1.0987"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".203791384666964",
						"qValue": ".957839854315834",
						"foldChange": "0.8947"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".139912022215551",
						"qValue": "1",
						"foldChange": "0.8866"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".86591257331004",
						"qValue": "1",
						"foldChange": "1.0226"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".189406623514766",
						"qValue": ".994724181158891",
						"foldChange": "1.2961"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".88898024069393",
						"qValue": "1",
						"foldChange": "0.8713"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".531979819360744",
						"qValue": "1",
						"foldChange": "1.2197"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".438852611064019",
						"qValue": "1",
						"foldChange": "1.3998"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".204712559562792",
						"qValue": "1",
						"foldChange": "1.3424"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".630235861765955",
						"qValue": "1",
						"foldChange": "1.2708"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".456615933290195",
						"qValue": "1",
						"foldChange": "0.9467"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".703244101435005",
						"qValue": "1",
						"foldChange": "1.0634"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".236065548822384",
						"qValue": "1",
						"foldChange": "1.6406"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".394621832547995",
						"qValue": ".985830071188835",
						"foldChange": "1.5428"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".699090282838733",
						"qValue": ".320657800180001",
						"foldChange": "0.9881"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".007098981553016",
						"qValue": ".007981958638321",
						"foldChange": "0.5618"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000439527066686",
						"qValue": ".000579224335096",
						"foldChange": "0.4833"
					}
				]
			},
			{
				"metaboliteId": "0078dd76-0000-0000-0000-000000000000",
				"chemicalId": "000005f8-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPI (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000cce2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09784",
				"inChiKey": "BSNJSZUDOMPYIR-DMDPBSJXBZ",
				"kegg": null,
				"mass": 834.52573,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".746833174680835",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".958760625090824",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".49984714058383",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".366798953581846",
						"qValue": "1",
						"foldChange": "0.9113"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".844225807902371",
						"qValue": "1",
						"foldChange": "1.0164"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".478968923713474",
						"qValue": "1",
						"foldChange": "1.1944"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".182675560913189",
						"qValue": ".797133099236872",
						"foldChange": "1.3502"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".747851321686626",
						"qValue": ".960368665676976",
						"foldChange": "1.2012"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".308736710657579",
						"qValue": ".957839854315834",
						"foldChange": "0.9304"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".616501411752987",
						"qValue": "1",
						"foldChange": "0.9630"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".834208540775183",
						"qValue": "1",
						"foldChange": "1.1842"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".478352642786355",
						"qValue": "1",
						"foldChange": "1.1175"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".885989201101848",
						"qValue": "1",
						"foldChange": "1.1602"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".891138664080423",
						"qValue": "1",
						"foldChange": "1.0056"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".998950499003602",
						"qValue": "1",
						"foldChange": "0.8668"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".157754426511156",
						"qValue": "1",
						"foldChange": "1.4911"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".729635853887195",
						"qValue": "1",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".305272860861615",
						"qValue": "1",
						"foldChange": "0.7770"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".660692856773927",
						"qValue": "1",
						"foldChange": "1.1669"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".711254284433384",
						"qValue": "1",
						"foldChange": "1.1228"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".963159037555328",
						"qValue": "1",
						"foldChange": "0.9622"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".698691997822739",
						"qValue": ".320657800180001",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".855005517950771",
						"qValue": ".302728192421515",
						"foldChange": "0.9868"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".52791076273624",
						"qValue": ".11547665170293",
						"foldChange": "0.9389"
					}
				]
			},
			{
				"metaboliteId": "0078dd77-0000-0000-0000-000000000000",
				"chemicalId": "05f5fb46-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl ethanolamide",
				"plotName": null,
				"compoundId": "0000cd80-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12252",
				"inChiKey": "KQXDGUVSAAQARU-HZJYTTRNBB",
				"kegg": null,
				"mass": 323.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1927,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".627499808825431",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".032171318076296",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".120092801846856",
						"qValue": ".834275595151197",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".776963754725989",
						"qValue": "1",
						"foldChange": "1.1063"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".895515392027995",
						"qValue": "1",
						"foldChange": "1.0005"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".678607646112837",
						"qValue": "1",
						"foldChange": "0.9799"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".031797405309337",
						"qValue": ".397935175268321",
						"foldChange": "1.5126"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".131658952108606",
						"qValue": ".746945121629492",
						"foldChange": "0.9646"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".000482859287549",
						"qValue": ".058701893386367",
						"foldChange": "0.6290"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".504550782996643",
						"qValue": "1",
						"foldChange": "0.9170"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".190363434490495",
						"qValue": "1",
						"foldChange": "0.9188"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".515028761334227",
						"qValue": "1",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".838664576851393",
						"qValue": "1",
						"foldChange": "1.0603"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".194354031667609",
						"qValue": "1",
						"foldChange": "1.2456"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".255885005518898",
						"qValue": "1",
						"foldChange": "1.1748"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".126875589667583",
						"qValue": "1",
						"foldChange": "1.4781"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".542024205107048",
						"qValue": "1",
						"foldChange": "1.0809"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".385821448379464",
						"qValue": "1",
						"foldChange": "0.7313"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".439746860941229",
						"qValue": "1",
						"foldChange": "0.9351"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".677729920555111",
						"qValue": "1",
						"foldChange": "1.1068"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".242569422041573",
						"qValue": ".985830071188835",
						"foldChange": "1.1836"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".262273348398351",
						"qValue": ".162919770750099",
						"foldChange": "1.6850"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".327038324006824",
						"qValue": ".150733012875934",
						"foldChange": "1.4445"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".108770413609692",
						"qValue": ".034629036452538",
						"foldChange": "1.4447"
					}
				]
			},
			{
				"metaboliteId": "0078dd78-0000-0000-0000-000000000000",
				"chemicalId": "05f603c7-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPC (18:2/18:2)",
				"plotName": null,
				"compoundId": "0000cd7b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08138",
				"inChiKey": "FVXDQWZBHIXIEJ-LNDKUQBDBA",
				"kegg": null,
				"mass": 781.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2096,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".74413799791528",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".816502946229826",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".268417852678044",
						"qValue": ".969351633560984",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".952347080591982",
						"qValue": "1",
						"foldChange": "1.0412"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".428611970935083",
						"qValue": "1",
						"foldChange": "1.1118"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".39493707082333",
						"qValue": "1",
						"foldChange": "1.1334"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".525714881104869",
						"qValue": "1",
						"foldChange": "1.1716"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".404929136690641",
						"qValue": ".882901404171953",
						"foldChange": "1.1813"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".841522046151166",
						"qValue": "1",
						"foldChange": "1.0582"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".128825606316838",
						"qValue": "1",
						"foldChange": "0.8739"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".068857320248957",
						"qValue": "1",
						"foldChange": "0.8295"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".753557425443235",
						"qValue": "1",
						"foldChange": "0.9161"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".622748613914023",
						"qValue": "1",
						"foldChange": "1.1516"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".185867554240628",
						"qValue": "1",
						"foldChange": "1.2493"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".374625817691995",
						"qValue": "1",
						"foldChange": "1.0849"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".352501117231433",
						"qValue": "1",
						"foldChange": "1.1707"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".728928637404435",
						"qValue": "1",
						"foldChange": "1.1259"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".583044255959385",
						"qValue": "1",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".623303517432205",
						"qValue": "1",
						"foldChange": "1.0815"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".688560502458513",
						"qValue": "1",
						"foldChange": "0.9254"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".377035346895093",
						"qValue": ".985830071188835",
						"foldChange": "0.8556"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".041128471110375",
						"qValue": ".043785369095927",
						"foldChange": "0.7663"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".189230080990386",
						"qValue": ".09971470252619",
						"foldChange": "0.8464"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".730089474446614",
						"qValue": ".149911631611322",
						"foldChange": "0.9525"
					}
				]
			},
			{
				"metaboliteId": "0078dd79-0000-0000-0000-000000000000",
				"chemicalId": "000001bc-0000-0000-0000-000000000000",
				"chemicalName": "ornithine",
				"plotName": null,
				"compoundId": "000005d5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03374",
				"inChiKey": "AHLPHDHHMVZTML-BYPYZUCNBZ",
				"kegg": "C00077",
				"mass": 132.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 418,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".670558136031603",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".657435069626548",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".470815848281206",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".943933101262727",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".774585313568039",
						"qValue": "1",
						"foldChange": "1.0161"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".828891466081791",
						"qValue": "1",
						"foldChange": "1.0107"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".296726782969099",
						"qValue": ".911265664999995",
						"foldChange": "0.9659"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".93025173730201",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".258730404383501",
						"qValue": ".957839854315834",
						"foldChange": "1.0510"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".634673750525311",
						"qValue": "1",
						"foldChange": "0.9850"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".115816777394198",
						"qValue": "1",
						"foldChange": "0.9427"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".266879090032543",
						"qValue": "1",
						"foldChange": "0.9117"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".592323964709517",
						"qValue": "1",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".948671349602427",
						"qValue": "1",
						"foldChange": "0.9782"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".650466892057119",
						"qValue": "1",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".25464528278275",
						"qValue": "1",
						"foldChange": "0.9412"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".706535719196516",
						"qValue": "1",
						"foldChange": "0.9463"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".468714543795456",
						"qValue": "1",
						"foldChange": "1.0055"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".514664310469753",
						"qValue": "1",
						"foldChange": "0.9654"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".258981450707999",
						"qValue": "1",
						"foldChange": "0.8575"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".594433912290563",
						"qValue": ".985830071188835",
						"foldChange": "0.8882"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".093662248246678",
						"qValue": ".078304789052164",
						"foldChange": "1.2745"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003724362529797",
						"qValue": ".005325770447309",
						"foldChange": "1.2737"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000742815483117",
						"qValue": ".000860579503948",
						"foldChange": "1.3049"
					}
				]
			},
			{
				"metaboliteId": "0078dd7a-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9df-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyhexanoate",
				"plotName": null,
				"compoundId": "0000cfee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HPMGFDVTYHWBAG-UHFFFAOYAJ",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1744,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".48844063213604",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".233656790822946",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".459891018627079",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".223239336981443",
						"qValue": "1",
						"foldChange": "0.9207"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".318125148341897",
						"qValue": "1",
						"foldChange": "0.9255"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".822845759463301",
						"qValue": "1",
						"foldChange": "1.0957"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".605905878351152",
						"qValue": "1",
						"foldChange": "1.1360"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".299006785634913",
						"qValue": ".825038702902081",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".123152423821499",
						"qValue": ".932820956170539",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".235977164853389",
						"qValue": "1",
						"foldChange": "1.2472"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".781257164928744",
						"qValue": "1",
						"foldChange": "0.9876"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".145572749358237",
						"qValue": ".960252848686774",
						"foldChange": "0.8295"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".190930945377422",
						"qValue": "1",
						"foldChange": "0.6368"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".430846855545678",
						"qValue": "1",
						"foldChange": "0.7019"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".639156340500322",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".878139333781782",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".418481119929265",
						"qValue": "1",
						"foldChange": "1.1154"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".329498314770107",
						"qValue": "1",
						"foldChange": "1.1141"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".193788254164385",
						"qValue": "1",
						"foldChange": "0.7847"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".737816099088329",
						"qValue": "1",
						"foldChange": "0.8880"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".355852234958046",
						"qValue": ".985830071188835",
						"foldChange": "1.1317"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".659002318438879",
						"qValue": ".309928219877064",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".919056715259465",
						"qValue": ".318716840755599",
						"foldChange": "0.9176"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".355174951415905",
						"qValue": ".084849301447442",
						"foldChange": "1.1293"
					}
				]
			},
			{
				"metaboliteId": "0078dd7b-0000-0000-0000-000000000000",
				"chemicalId": "00000456-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylalanine",
				"plotName": null,
				"compoundId": "00000631-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00766",
				"inChiKey": "KTHDTJVBEPMMGL-VKHMYHEABO",
				"kegg": "C02847",
				"mass": 131.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 30,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".835570012074256",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".745085232933077",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".459056349910781",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".178151893963096",
						"qValue": "1",
						"foldChange": "0.9350"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".854247471728204",
						"qValue": "1",
						"foldChange": "1.0157"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".127426240632425",
						"qValue": "1",
						"foldChange": "1.1087"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".606426073538963",
						"qValue": "1",
						"foldChange": "1.0511"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".990688930054199",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".598331210871349",
						"qValue": ".962532817488692",
						"foldChange": "0.9959"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".677115577282117",
						"qValue": "1",
						"foldChange": "0.9866"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".336353907298858",
						"qValue": "1",
						"foldChange": "0.9501"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".58339538693057",
						"qValue": "1",
						"foldChange": "0.9009"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".828478247714599",
						"qValue": "1",
						"foldChange": "0.9914"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".869783556546141",
						"qValue": "1",
						"foldChange": "0.9789"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".96652598771476",
						"qValue": "1",
						"foldChange": "0.9874"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".229602297244432",
						"qValue": "1",
						"foldChange": "1.1437"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".528676537778299",
						"qValue": "1",
						"foldChange": "1.0913"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".597787960845202",
						"qValue": "1",
						"foldChange": "0.9542"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".911106944310864",
						"qValue": "1",
						"foldChange": "1.0211"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".651486459390885",
						"qValue": "1",
						"foldChange": "0.8898"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".568533264951432",
						"qValue": ".985830071188835",
						"foldChange": "0.8715"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".094125660285083",
						"qValue": ".078304789052164",
						"foldChange": "1.4046"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001504622812833",
						"qValue": ".002760254715315",
						"foldChange": "1.4753"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002162684892368",
						"qValue": ".001830040289805",
						"foldChange": "1.4051"
					}
				]
			},
			{
				"metaboliteId": "0078dd7c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f7da-0000-0000-0000-000000000000",
				"chemicalName": "3-methylglutarylcarnitine (2)",
				"plotName": null,
				"compoundId": "0000b5d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00552",
				"inChiKey": "HFCPFJNSBPQJDP-UHFFFAOYAE",
				"kegg": null,
				"mass": 289.152537,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 320,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".421352473938432",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".183307591909515",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".413560045880454",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".101459859131073",
						"qValue": "1",
						"foldChange": "0.8875"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".877617791190445",
						"qValue": "1",
						"foldChange": "1.0024"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".13632242692858",
						"qValue": "1",
						"foldChange": "1.4073"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".06770232323794",
						"qValue": ".581966435105931",
						"foldChange": "0.8410"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".683106667060833",
						"qValue": ".945241908404502",
						"foldChange": "1.0882"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".151220182665314",
						"qValue": ".957839854315834",
						"foldChange": "1.2477"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".846250084269195",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".497437568719947",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".383952671593813",
						"qValue": "1",
						"foldChange": "0.8553"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".862709044264581",
						"qValue": "1",
						"foldChange": "1.0558"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".24034222900919",
						"qValue": "1",
						"foldChange": "0.5686"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".172943657406398",
						"qValue": "1",
						"foldChange": "0.5385"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".896296794386474",
						"qValue": "1",
						"foldChange": "0.9556"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".648305105480528",
						"qValue": "1",
						"foldChange": "0.6365"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".553464081214047",
						"qValue": "1",
						"foldChange": "0.6661"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".939237559096338",
						"qValue": "1",
						"foldChange": "1.0745"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".165360173945195",
						"qValue": "1",
						"foldChange": "0.5062"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".136227335412001",
						"qValue": ".985830071188835",
						"foldChange": "0.4711"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".100428748151439",
						"qValue": ".082304488947603",
						"foldChange": "0.7044"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".602583104529102",
						"qValue": ".236993165350789",
						"foldChange": "1.4564"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".525269758108688",
						"qValue": ".115212454366588",
						"foldChange": "1.3794"
					}
				]
			},
			{
				"metaboliteId": "0078dd7d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6bd-0000-0000-0000-000000000000",
				"chemicalName": "N1-Methyl-4-pyridone-3-carboxamide",
				"plotName": null,
				"compoundId": "0000e0f0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04194",
				"inChiKey": "KTLRWTOPTKGYQY-UHFFFAOYAD",
				"kegg": "C05843",
				"mass": 152.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3936,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".417865289243871",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".189657239987947",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".711388837023184",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".535338880926737",
						"qValue": "1",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".742542444237501",
						"qValue": "1",
						"foldChange": "1.1826"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".344480694522552",
						"qValue": "1",
						"foldChange": "1.4283"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".235588163288695",
						"qValue": ".852077213869098",
						"foldChange": "0.8527"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".135011044082259",
						"qValue": ".755884200750014",
						"foldChange": "0.9047"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".751039516900668",
						"qValue": ".983284044434567",
						"foldChange": "1.1568"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".174530340249974",
						"qValue": "1",
						"foldChange": "0.8374"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".69727792717431",
						"qValue": "1",
						"foldChange": "0.9735"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".328656714732917",
						"qValue": "1",
						"foldChange": "1.1850"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".460605314361075",
						"qValue": "1",
						"foldChange": "0.3770"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".451543877095374",
						"qValue": "1",
						"foldChange": "0.3706"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".954655135645397",
						"qValue": "1",
						"foldChange": "0.9830"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".324056391942209",
						"qValue": "1",
						"foldChange": "0.3335"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".257132154829265",
						"qValue": "1",
						"foldChange": "0.2822"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".83375521109942",
						"qValue": "1",
						"foldChange": "0.8463"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".114218523746399",
						"qValue": "1",
						"foldChange": "0.3080"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".274944433538686",
						"qValue": "1",
						"foldChange": "0.2740"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".666885286820551",
						"qValue": ".990272584968167",
						"foldChange": "0.8895"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003102599243735",
						"qValue": ".006263667170027",
						"foldChange": "2.7044"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000864402472399",
						"qValue": ".002039209562601",
						"foldChange": "4.0659"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".010773692233635",
						"qValue": ".005739703612364",
						"foldChange": "7.5932"
					}
				]
			},
			{
				"metaboliteId": "0078dd7e-0000-0000-0000-000000000000",
				"chemicalId": "05f60b75-0000-0000-0000-000000000000",
				"chemicalName": "2,3-dihydroxy-2-methylbutyrate",
				"plotName": null,
				"compoundId": "0000e0cb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29576",
				"inChiKey": "AOWPAWLEXIYETE-UHFFFAOYAH",
				"kegg": null,
				"mass": 134.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 366,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".151767056385766",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".010164156906328",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".014575540421987",
						"qValue": ".563808404505058",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".676814913485137",
						"qValue": "1",
						"foldChange": "1.1509"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".414951637280861",
						"qValue": "1",
						"foldChange": "1.1645"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".688763679058009",
						"qValue": "1",
						"foldChange": "1.0995"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000089216196816",
						"qValue": ".025307661163412",
						"foldChange": "1.7145"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".0000525610618",
						"qValue": ".014909821197287",
						"foldChange": "1.7308"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".872072850261046",
						"qValue": "1",
						"foldChange": "1.0391"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".906200275802443",
						"qValue": "1",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".941485889717508",
						"qValue": "1",
						"foldChange": "1.0187"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".848361858995",
						"qValue": "1",
						"foldChange": "0.9484"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".824861018512276",
						"qValue": "1",
						"foldChange": "0.8447"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".844851202033647",
						"qValue": "1",
						"foldChange": "0.8563"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".678484459846358",
						"qValue": "1",
						"foldChange": "1.0138"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".02432818847483",
						"qValue": "1",
						"foldChange": "1.4746"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".998325661975134",
						"qValue": "1",
						"foldChange": "0.9845"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".02904036787747",
						"qValue": ".822195137696456",
						"foldChange": "0.6676"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".036526374018269",
						"qValue": "1",
						"foldChange": "1.3417"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".690909870769617",
						"qValue": "1",
						"foldChange": "0.8063"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".01620167301947",
						"qValue": ".985830071188835",
						"foldChange": "0.6009"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013185432933262",
						"qValue": ".018576905432704",
						"foldChange": "1.5199"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000831825455354",
						"qValue": ".002016970155972",
						"foldChange": "1.5806"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".128997506080968",
						"qValue": ".039504176812515",
						"foldChange": "1.3645"
					}
				]
			},
			{
				"metaboliteId": "0078dd7f-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba7-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-oleoyl-glycerol (18:1/18:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6a2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07218",
				"inChiKey": null,
				"kegg": null,
				"mass": 620.537975,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2624,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".979448397560875",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".106340292600603",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".016055957944781",
						"qValue": ".594070443956888",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".654032770026059",
						"qValue": "1",
						"foldChange": "1.0597"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".086922981900989",
						"qValue": "1",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".032758487297447",
						"qValue": "1",
						"foldChange": "0.8328"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000874115817577",
						"qValue": ".106267508679706",
						"foldChange": "1.4234"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".082414451384061",
						"qValue": ".697458782960496",
						"foldChange": "1.2065"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".08206200791684",
						"qValue": ".796492767737576",
						"foldChange": "0.8994"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".855457736681946",
						"qValue": "1",
						"foldChange": "1.0163"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".360468190923894",
						"qValue": "1",
						"foldChange": "1.1151"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".274048880107628",
						"qValue": "1",
						"foldChange": "1.0652"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".384648365870878",
						"qValue": "1",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".662489873946887",
						"qValue": "1",
						"foldChange": "0.9680"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".689672770807521",
						"qValue": "1",
						"foldChange": "1.1660"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".831747481038036",
						"qValue": "1",
						"foldChange": "1.0108"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".507864140969948",
						"qValue": "1",
						"foldChange": "0.8755"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".378437506585896",
						"qValue": "1",
						"foldChange": "0.8661"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".686559735983971",
						"qValue": "1",
						"foldChange": "1.0284"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".60091338275323",
						"qValue": "1",
						"foldChange": "1.1028"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".884176133717829",
						"qValue": ".997573179629986",
						"foldChange": "1.0724"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".427732710364962",
						"qValue": ".224790913773474",
						"foldChange": "1.4310"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".016968545900179",
						"qValue": ".015812922971375",
						"foldChange": "1.6148"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".107580088987797",
						"qValue": ".034318031490764",
						"foldChange": "1.6777"
					}
				]
			},
			{
				"metaboliteId": "0078dd80-0000-0000-0000-000000000000",
				"chemicalId": "05f60bac-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-arachidonoyl-glycerol (16:0/20:4) [1]*",
				"plotName": null,
				"compoundId": "0000d6ad-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07112",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2614,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".965941802925272",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".216601933653685",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".090229762780914",
						"qValue": ".799849251318312",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".428852504290122",
						"qValue": "1",
						"foldChange": "1.0844"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".171488011756416",
						"qValue": "1",
						"foldChange": "3.4997"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".033716873272792",
						"qValue": "1",
						"foldChange": "4.6314"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".01180083825072",
						"qValue": ".302374575176522",
						"foldChange": "3.9275"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".090137353570149",
						"qValue": ".697458782960496",
						"foldChange": "3.6740"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".378788155254498",
						"qValue": ".957839854315834",
						"foldChange": "1.0270"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".690055693634027",
						"qValue": "1",
						"foldChange": "2.6204"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".98664116513972",
						"qValue": "1",
						"foldChange": "2.2125"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".677790975288634",
						"qValue": "1",
						"foldChange": "1.0604"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".439394740779253",
						"qValue": "1",
						"foldChange": "0.8177"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".887591463984385",
						"qValue": "1",
						"foldChange": "1.2099"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".544880651068752",
						"qValue": "1",
						"foldChange": "1.4797"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".154179693224032",
						"qValue": "1",
						"foldChange": "1.2956"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".522889099415368",
						"qValue": "1",
						"foldChange": "0.8834"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".460457950054487",
						"qValue": "1",
						"foldChange": "0.6819"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".545906785255973",
						"qValue": "1",
						"foldChange": "0.6876"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".301384483461885",
						"qValue": "1",
						"foldChange": "0.6117"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".632396791090063",
						"qValue": ".985830071188835",
						"foldChange": "0.8897"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".534013309310147",
						"qValue": ".264680811298354",
						"foldChange": "2.4079"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".093618378580941",
						"qValue": ".058343553680028",
						"foldChange": "1.7655"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".105517104601992",
						"qValue": ".033929219314067",
						"foldChange": "2.4715"
					}
				]
			},
			{
				"metaboliteId": "0078dd81-0000-0000-0000-000000000000",
				"chemicalId": "05f60baf-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linolenoyl-glycerol (18:2/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07250",
				"inChiKey": null,
				"kegg": null,
				"mass": 614.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2634,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".186779644388526",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".912415016840649",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".051182719386392",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".671898451282022",
						"qValue": "1",
						"foldChange": "1.0167"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".411661894832429",
						"qValue": "1",
						"foldChange": "0.9541"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".689444344352467",
						"qValue": "1",
						"foldChange": "0.9855"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".011142973427049",
						"qValue": ".296333449575595",
						"foldChange": "1.7272"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".089497585026298",
						"qValue": ".697458782960496",
						"foldChange": "1.4976"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".368851620460872",
						"qValue": ".957839854315834",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".118486922763495",
						"qValue": "1",
						"foldChange": "0.8432"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".276127164056603",
						"qValue": "1",
						"foldChange": "0.9124"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".627762624801257",
						"qValue": "1",
						"foldChange": "1.0333"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".031742260295418",
						"qValue": "1",
						"foldChange": "0.5827"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".469077642458566",
						"qValue": "1",
						"foldChange": "1.5258"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".006053675799035",
						"qValue": "1",
						"foldChange": "2.6184"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".592266168983679",
						"qValue": "1",
						"foldChange": "0.8652"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".965175719896779",
						"qValue": "1",
						"foldChange": "1.3528"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".573241803971604",
						"qValue": "1",
						"foldChange": "1.5636"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".426012057067542",
						"qValue": "1",
						"foldChange": "0.7052"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".58846161734319",
						"qValue": "1",
						"foldChange": "1.0623"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".188777360498441",
						"qValue": ".985830071188835",
						"foldChange": "1.5063"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".410245408910722",
						"qValue": ".216978266312259",
						"foldChange": "1.2158"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".830511355809809",
						"qValue": ".296144675134907",
						"foldChange": "1.0004"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".744522332414523",
						"qValue": ".151712628113874",
						"foldChange": "1.2038"
					}
				]
			},
			{
				"metaboliteId": "0078dd82-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb2-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoyl-linoleoyl-glycerol (16:1/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000d6b7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07132",
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2612,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".870146275044436",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".382293400073043",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".281401972615041",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".744345986690085",
						"qValue": "1",
						"foldChange": "1.1228"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".549737438183242",
						"qValue": "1",
						"foldChange": "0.9772"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".356820694509049",
						"qValue": "1",
						"foldChange": "0.9648"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".018140331774693",
						"qValue": ".330397363624153",
						"foldChange": "1.6428"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".057061962809074",
						"qValue": ".687896186587066",
						"foldChange": "1.4646"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".621650127278224",
						"qValue": ".967408831530889",
						"foldChange": "1.0562"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".828572839737578",
						"qValue": "1",
						"foldChange": "1.0200"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".78099353804505",
						"qValue": "1",
						"foldChange": "1.0353"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".950920739318086",
						"qValue": "1",
						"foldChange": "0.9796"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".327712426865545",
						"qValue": "1",
						"foldChange": "0.7376"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".658268878823675",
						"qValue": "1",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".616604862326227",
						"qValue": "1",
						"foldChange": "1.1919"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".91237741593069",
						"qValue": "1",
						"foldChange": "0.8879"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".500997425841449",
						"qValue": "1",
						"foldChange": "0.7209"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".55944489448817",
						"qValue": "1",
						"foldChange": "0.8120"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".926808934281411",
						"qValue": "1",
						"foldChange": "0.8604"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".752326298542546",
						"qValue": "1",
						"foldChange": "0.7488"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".680376867252324",
						"qValue": ".990272584968167",
						"foldChange": "0.8703"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".928066149002393",
						"qValue": ".379350579448788",
						"foldChange": "1.0973"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".219668973838759",
						"qValue": ".111777210949088",
						"foldChange": "1.4325"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".916176289574221",
						"qValue": ".180735075595679",
						"foldChange": "1.4363"
					}
				]
			},
			{
				"metaboliteId": "0078dd83-0000-0000-0000-000000000000",
				"chemicalId": "05f60bc4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-palmitoyl-glycerol (16:0/16:0) [2]*",
				"plotName": null,
				"compoundId": "0000d6ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07098",
				"inChiKey": null,
				"kegg": null,
				"mass": 568.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2602,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".91130426094094",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".454477274910964",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".006414108459068",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".513545452465994",
						"qValue": "1",
						"foldChange": "1.1482"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".603090656558723",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".243172502544839",
						"qValue": "1",
						"foldChange": "0.8935"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".001888391755173",
						"qValue": ".1236170295117",
						"foldChange": "1.8346"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".014460908541182",
						"qValue": ".567215491640796",
						"foldChange": "1.6097"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".456252174984453",
						"qValue": ".957839854315834",
						"foldChange": "0.9834"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".060322806007846",
						"qValue": ".821720595261736",
						"foldChange": "0.7812"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".883276600058086",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".043721144498117",
						"qValue": ".689012851257361",
						"foldChange": "1.3419"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".570455330512111",
						"qValue": "1",
						"foldChange": "1.0483"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".914018880555384",
						"qValue": "1",
						"foldChange": "1.0955"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".660076869565272",
						"qValue": "1",
						"foldChange": "1.0450"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".778374464016083",
						"qValue": "1",
						"foldChange": "0.9275"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".326511375074666",
						"qValue": "1",
						"foldChange": "0.5029"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".204775234656323",
						"qValue": "1",
						"foldChange": "0.5422"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".657810213866645",
						"qValue": "1",
						"foldChange": "0.9527"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".909093984467306",
						"qValue": "1",
						"foldChange": "0.8889"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".754618730657866",
						"qValue": ".997573179629986",
						"foldChange": "0.9330"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".701186484751708",
						"qValue": ".320657800180001",
						"foldChange": "1.1746"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".198012968815394",
						"qValue": ".102821623218658",
						"foldChange": "1.4176"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".721119705079015",
						"qValue": ".14844902000236",
						"foldChange": "1.6453"
					}
				]
			},
			{
				"metaboliteId": "0078dd84-0000-0000-0000-000000000000",
				"chemicalId": "05f60bcf-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (12:0/18:1, 14:0/16:1, 16:0/14:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6d9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 538.459725,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2587,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".891690828623577",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".721550610332979",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".304852792488245",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".223210759253481",
						"qValue": "1",
						"foldChange": "1.7770"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".82264926548864",
						"qValue": "1",
						"foldChange": "1.5277"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".150977420159867",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".287728953581377",
						"qValue": ".911265664999995",
						"foldChange": "1.6982"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".120376611895075",
						"qValue": ".716367109949013",
						"foldChange": "2.7827"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".614993138847214",
						"qValue": ".967408831530889",
						"foldChange": "1.8601"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".393861197522426",
						"qValue": "1",
						"foldChange": "0.9256"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".830827139448803",
						"qValue": "1",
						"foldChange": "1.0925"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".52163591444269",
						"qValue": "1",
						"foldChange": "1.2445"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".993299459306568",
						"qValue": "1",
						"foldChange": "1.1368"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".932627655050491",
						"qValue": "1",
						"foldChange": "1.1520"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".924525837225867",
						"qValue": "1",
						"foldChange": "1.0134"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".915745261905139",
						"qValue": "1",
						"foldChange": "1.0335"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".404212254127612",
						"qValue": "1",
						"foldChange": "0.5795"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".451250479591836",
						"qValue": "1",
						"foldChange": "0.5607"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".439618919641131",
						"qValue": "1",
						"foldChange": "0.9038"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".933472083853366",
						"qValue": "1",
						"foldChange": "0.7629"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".506480234060453",
						"qValue": ".985830071188835",
						"foldChange": "0.8442"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".452433578288368",
						"qValue": ".233643038757568",
						"foldChange": "1.0009"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".777638625807458",
						"qValue": ".284180542086041",
						"foldChange": "0.8280"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".557880761635274",
						"qValue": ".11991148819826",
						"foldChange": "1.3135"
					}
				]
			},
			{
				"metaboliteId": "0078dd85-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4af-0000-0000-0000-000000000000",
				"chemicalName": "2-oleoylglycerol (18:1)",
				"plotName": null,
				"compoundId": "000052f0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11537",
				"inChiKey": "UPWGQKDVAURUGE-KTKRTIGZBO",
				"kegg": null,
				"mass": 356.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2572,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".069566116461734",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".761052126216404",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".959696719534768",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".865887811719632",
						"qValue": "1",
						"foldChange": "1.7601"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".567905244403754",
						"qValue": "1",
						"foldChange": "0.9924"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".687046595829964",
						"qValue": "1",
						"foldChange": "1.3672"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".390975344549377",
						"qValue": ".964405849888462",
						"foldChange": "2.3158"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".838154283067889",
						"qValue": "1",
						"foldChange": "2.4644"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".512213019615756",
						"qValue": ".957839854315834",
						"foldChange": "1.5500"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".874353258983214",
						"qValue": "1",
						"foldChange": "1.5570"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".94700129074414",
						"qValue": "1",
						"foldChange": "2.0719"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".822316014692711",
						"qValue": "1",
						"foldChange": "1.4750"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".227066351079832",
						"qValue": "1",
						"foldChange": "0.7732"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".046255133332722",
						"qValue": "1",
						"foldChange": "0.4559"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".370017778540021",
						"qValue": "1",
						"foldChange": "0.5897"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".695826217438165",
						"qValue": "1",
						"foldChange": "0.7707"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".082113071403086",
						"qValue": "1",
						"foldChange": "0.3684"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".157605883963738",
						"qValue": "1",
						"foldChange": "0.4779"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".565752755332275",
						"qValue": "1",
						"foldChange": "0.8101"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".10679630090223",
						"qValue": "1",
						"foldChange": "0.4010"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".267965669503886",
						"qValue": ".985830071188835",
						"foldChange": "0.4950"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".009647368264626",
						"qValue": ".014719598415213",
						"foldChange": "0.3192"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".033143602159316",
						"qValue": ".026832442685347",
						"foldChange": "0.5587"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".27655549062643",
						"qValue": ".071255550752483",
						"foldChange": "0.8167"
					}
				]
			},
			{
				"metaboliteId": "0078dd86-0000-0000-0000-000000000000",
				"chemicalId": "000000c0-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylputrescine",
				"plotName": null,
				"compoundId": "00009278-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02064",
				"inChiKey": "KLZGKIDSEJWEDW-UHFFFAOYAE",
				"kegg": "C02714",
				"mass": 130.110613,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 465,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".30889073754858",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".30912900542106",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".40504063480239",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".321055329803617",
						"qValue": "1",
						"foldChange": "0.9502"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".980665759105731",
						"qValue": "1",
						"foldChange": "1.0115"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".309560849195452",
						"qValue": "1",
						"foldChange": "1.0662"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".893896727151254",
						"qValue": "1",
						"foldChange": "1.0331"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".818011029649119",
						"qValue": ".991769031882419",
						"foldChange": "1.0408"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".922904610185651",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".110678759302109",
						"qValue": "1",
						"foldChange": "0.9083"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".046262587287545",
						"qValue": "1",
						"foldChange": "0.8915"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".675792384487639",
						"qValue": "1",
						"foldChange": "0.9106"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".412771852361446",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".074780041755105",
						"qValue": "1",
						"foldChange": "1.2178"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".287681411892168",
						"qValue": "1",
						"foldChange": "1.1264"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".169317880939171",
						"qValue": "1",
						"foldChange": "1.1588"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".145102978091181",
						"qValue": "1",
						"foldChange": "1.1710"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".867953646103604",
						"qValue": "1",
						"foldChange": "1.0106"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".359679334584571",
						"qValue": "1",
						"foldChange": "1.1013"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".439462694320713",
						"qValue": "1",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".922456179194006",
						"qValue": ".997573179629986",
						"foldChange": "0.9213"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".68987539324325",
						"qValue": ".319010127601798",
						"foldChange": "1.1579"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".170859398287267",
						"qValue": ".092511718758047",
						"foldChange": "1.1527"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".538255012997097",
						"qValue": ".116352240974139",
						"foldChange": "1.0832"
					}
				]
			},
			{
				"metaboliteId": "0078dd87-0000-0000-0000-000000000000",
				"chemicalId": "05f61e8f-0000-0000-0000-000000000000",
				"chemicalName": "stearoylcholine*",
				"plotName": null,
				"compoundId": "0000e078-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 369.360687,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1721,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".877408985831664",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".229481502054085",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".07051323249367",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".605220217636921",
						"qValue": "1",
						"foldChange": "2.3636"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".380223135093522",
						"qValue": "1",
						"foldChange": "2.6335"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".716485114802247",
						"qValue": "1",
						"foldChange": "3.9517"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".153985528111044",
						"qValue": ".742487134132407",
						"foldChange": "0.9942"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".686158398727036",
						"qValue": ".946387029686722",
						"foldChange": "1.7336"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".302657899430549",
						"qValue": ".957839854315834",
						"foldChange": "3.0005"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".109560198210918",
						"qValue": "1",
						"foldChange": "0.8890"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".004476105452652",
						"qValue": ".522751459721039",
						"foldChange": "0.3406"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".182517578147301",
						"qValue": ".989315025499063",
						"foldChange": "0.9220"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".512766888380812",
						"qValue": "1",
						"foldChange": "1.6463"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".232751608475881",
						"qValue": "1",
						"foldChange": "1.8708"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".552421189681184",
						"qValue": "1",
						"foldChange": "1.1364"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".389219359014347",
						"qValue": "1",
						"foldChange": "0.4582"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".61368367096916",
						"qValue": "1",
						"foldChange": "0.6789"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".74975619896338",
						"qValue": "1",
						"foldChange": "1.4816"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".71599553833274",
						"qValue": "1",
						"foldChange": "1.0389"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".065461461134349",
						"qValue": "1",
						"foldChange": "0.2181"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".121897228507561",
						"qValue": ".985830071188835",
						"foldChange": "0.2100"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".260494968837004",
						"qValue": ".1621192350951",
						"foldChange": "1.2376"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000684601607532",
						"qValue": ".001818083065807",
						"foldChange": "9.8758"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001761092907425",
						"qValue": ".001555721614729",
						"foldChange": "7.5771"
					}
				]
			},
			{
				"metaboliteId": "0078dd88-0000-0000-0000-000000000000",
				"chemicalId": "05f61ee1-0000-0000-0000-000000000000",
				"chemicalName": "erucoylcarnitine (C22:1)*",
				"plotName": null,
				"compoundId": "0000e0b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 481.413116,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1669,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".370161023031523",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".8129509926736",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".013076729756183",
						"qValue": ".542454401754345",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".701476618391712",
						"qValue": "1",
						"foldChange": "1.0070"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".329197477151938",
						"qValue": "1",
						"foldChange": "1.1031"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".176633843685775",
						"qValue": "1",
						"foldChange": "1.1480"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".132290408798742",
						"qValue": ".731033362907336",
						"foldChange": "0.9350"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".620852773175581",
						"qValue": ".928868848501546",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".306636280129975",
						"qValue": ".957839854315834",
						"foldChange": "1.1201"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".007625817710756",
						"qValue": ".668378947969462",
						"foldChange": "1.3343"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".895952613691494",
						"qValue": "1",
						"foldChange": "1.0440"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".010751451455219",
						"qValue": ".566846104084484",
						"foldChange": "0.7922"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".459761313764557",
						"qValue": "1",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".213717008518482",
						"qValue": "1",
						"foldChange": "0.8353"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".570750424507477",
						"qValue": "1",
						"foldChange": "0.8800"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".174002439570046",
						"qValue": "1",
						"foldChange": "0.8579"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".535443356461387",
						"qValue": "1",
						"foldChange": "1.1399"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".054625433143385",
						"qValue": ".822195137696456",
						"foldChange": "1.3287"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".113598975960225",
						"qValue": "1",
						"foldChange": "0.8172"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".089301815773746",
						"qValue": "1",
						"foldChange": "0.7919"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".829109548616308",
						"qValue": ".997573179629986",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".003469010644172",
						"qValue": ".006639056548229",
						"foldChange": "1.8334"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001004911819568",
						"qValue": ".00216872027018",
						"foldChange": "2.1462"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000010665070732",
						"qValue": ".000068587462712",
						"foldChange": "3.6283"
					}
				]
			},
			{
				"metaboliteId": "0078dd89-0000-0000-0000-000000000000",
				"chemicalId": "05f61f0a-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl ceramide (d18:1/20:0, d16:1/22:0)*",
				"plotName": null,
				"compoundId": "0000e0fb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 755.627519,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2766,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844190668077573",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".795875217642761",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".523890500749924",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".826987182919573",
						"qValue": "1",
						"foldChange": "0.9967"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".319930779659101",
						"qValue": "1",
						"foldChange": "1.0772"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".226636558543889",
						"qValue": "1",
						"foldChange": "1.1048"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".476270797710385",
						"qValue": "1",
						"foldChange": "0.9860"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".9531196258122",
						"qValue": "1",
						"foldChange": "1.0132"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".513141758276995",
						"qValue": ".957839854315834",
						"foldChange": "1.0540"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".834490912561597",
						"qValue": "1",
						"foldChange": "0.9933"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".261807304762738",
						"qValue": "1",
						"foldChange": "0.9493"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".359383368442685",
						"qValue": "1",
						"foldChange": "0.8951"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".83135615364359",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".445968969177369",
						"qValue": "1",
						"foldChange": "1.1152"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".564220924693107",
						"qValue": "1",
						"foldChange": "1.1209"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".984585858065247",
						"qValue": "1",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".446684989792265",
						"qValue": "1",
						"foldChange": "1.1670"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".446721121105141",
						"qValue": "1",
						"foldChange": "1.1893"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".81621660285195",
						"qValue": "1",
						"foldChange": "0.9244"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".911314942807948",
						"qValue": "1",
						"foldChange": "0.9297"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".911530947106261",
						"qValue": ".997573179629986",
						"foldChange": "1.0058"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".740299002891525",
						"qValue": ".330326907870435",
						"foldChange": "1.1141"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".209141189680273",
						"qValue": ".107417426107465",
						"foldChange": "1.1635"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".028318818727896",
						"qValue": ".012305362589603",
						"foldChange": "1.5617"
					}
				]
			},
			{
				"metaboliteId": "0078dd8a-0000-0000-0000-000000000000",
				"chemicalId": "00000371-0000-0000-0000-000000000000",
				"chemicalName": "cytosine",
				"plotName": null,
				"compoundId": "0000023d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00630",
				"inChiKey": "OPTASPLRGRRNAP-UHFFFAOYAY",
				"kegg": "C00380",
				"mass": 111.043262,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3869,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".645767883764061",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".04896239171801",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".904673338423471",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".833941497436108",
						"qValue": "1",
						"foldChange": "0.9507"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".175020312356458",
						"qValue": "1",
						"foldChange": "1.8272"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".118987714442511",
						"qValue": "1",
						"foldChange": "2.0025"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".192223265104541",
						"qValue": ".797133099236872",
						"foldChange": "0.9159"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".636592842879859",
						"qValue": ".928868848501546",
						"foldChange": "1.8852"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".078447072410695",
						"qValue": ".794743555017874",
						"foldChange": "2.2333"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".932380869008418",
						"qValue": "1",
						"foldChange": "1.3898"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".366102261843729",
						"qValue": "1",
						"foldChange": "2.7842"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".323415339694812",
						"qValue": "1",
						"foldChange": "1.6812"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".814489367766631",
						"qValue": "1",
						"foldChange": "1.1279"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".975604001289453",
						"qValue": "1",
						"foldChange": "1.0243"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".844625297349133",
						"qValue": "1",
						"foldChange": "0.9082"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".32599293870659",
						"qValue": "1",
						"foldChange": "0.5865"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".958983213230753",
						"qValue": "1",
						"foldChange": "1.3914"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".315566000884777",
						"qValue": "1",
						"foldChange": "2.3725"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".369176519533392",
						"qValue": "1",
						"foldChange": "0.6250"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".757258645558086",
						"qValue": "1",
						"foldChange": "1.1405"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".578295190921207",
						"qValue": ".985830071188835",
						"foldChange": "1.8249"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".499712813294209",
						"qValue": ".250682137067916",
						"foldChange": "2.2290"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".276785892487042",
						"qValue": ".132158837497075",
						"foldChange": "1.3066"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".405900980334748",
						"qValue": ".094168981073947",
						"foldChange": "1.3512"
					}
				]
			},
			{
				"metaboliteId": "0078dd8b-0000-0000-0000-000000000000",
				"chemicalId": "05f5f992-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/20:0, d16:1/22:0)*",
				"plotName": null,
				"compoundId": "0000bd6a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12102",
				"inChiKey": "AADLTHQNYQJHQV-SVLGDMRNBJ",
				"kegg": null,
				"mass": 758.630175,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2809,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".002740099769486",
						"qValue": ".750120401842876",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".232936215486535",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".921065917883038",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".288647203942906",
						"qValue": "1",
						"foldChange": "0.9603"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".726764580503238",
						"qValue": "1",
						"foldChange": "1.0255"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".160783421538632",
						"qValue": "1",
						"foldChange": "1.0835"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".666388415171038",
						"qValue": "1",
						"foldChange": "0.9831"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".97710748172816",
						"qValue": "1",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".645720128794931",
						"qValue": ".973034272612281",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".253654292568544",
						"qValue": "1",
						"foldChange": "0.9508"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".580512122964998",
						"qValue": "1",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".552013309280947",
						"qValue": "1",
						"foldChange": "1.0393"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".184206761315389",
						"qValue": "1",
						"foldChange": "0.8868"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".027853377787044",
						"qValue": "1",
						"foldChange": "1.1728"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".000745212418649",
						"qValue": ".63417576827011",
						"foldChange": "1.3225"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".358942462805948",
						"qValue": "1",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".034410668006638",
						"qValue": "1",
						"foldChange": "1.1915"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".003123571214208",
						"qValue": ".605991101987089",
						"foldChange": "1.2670"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".12694211773608",
						"qValue": "1",
						"foldChange": "0.8941"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".094634400434595",
						"qValue": "1",
						"foldChange": "1.1304"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".002229887420082",
						"qValue": ".589350277992006",
						"foldChange": "1.2643"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008459439877074",
						"qValue": ".013157324947472",
						"foldChange": "1.2222"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".728540814019565",
						"qValue": ".272320393121461",
						"foldChange": "0.9781"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".249737847103493",
						"qValue": ".065287532255804",
						"foldChange": "1.1095"
					}
				]
			},
			{
				"metaboliteId": "0078dd8c-0000-0000-0000-000000000000",
				"chemicalId": "000000f1-0000-0000-0000-000000000000",
				"chemicalName": "phenylpyruvate",
				"plotName": null,
				"compoundId": "00000236-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00205",
				"inChiKey": "BTNMPGBKDVTSJY-UHFFFAOYAU",
				"kegg": "C00166",
				"mass": 164.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 140,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".788971684266648",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".575311419340419",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".715382985402434",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".563158849669727",
						"qValue": "1",
						"foldChange": "1.0862"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".160743460099841",
						"qValue": "1",
						"foldChange": "1.2043"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".403742303847146",
						"qValue": "1",
						"foldChange": "1.1586"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".324533404179869",
						"qValue": ".942586781423443",
						"foldChange": "1.1402"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".694965871052389",
						"qValue": ".950224867310804",
						"foldChange": "1.0880"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".550727834017384",
						"qValue": ".957839854315834",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".672588046062755",
						"qValue": "1",
						"foldChange": "0.9770"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".976628988760942",
						"qValue": "1",
						"foldChange": "1.0207"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".651408650367252",
						"qValue": "1",
						"foldChange": "0.9936"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".621562619675123",
						"qValue": "1",
						"foldChange": "1.0878"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".290626869415845",
						"qValue": "1",
						"foldChange": "1.2628"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".541915327568525",
						"qValue": "1",
						"foldChange": "1.1608"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".475133080221007",
						"qValue": "1",
						"foldChange": "1.0765"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".642263137489394",
						"qValue": "1",
						"foldChange": "1.1085"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".827878708243559",
						"qValue": "1",
						"foldChange": "1.0297"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".880995385017885",
						"qValue": "1",
						"foldChange": "0.9377"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".797785239948834",
						"qValue": "1",
						"foldChange": "1.0005"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".683877209580611",
						"qValue": ".990272584968167",
						"foldChange": "1.0670"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".963925467464529",
						"qValue": ".387784510668635",
						"foldChange": "1.0756"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".848164786985454",
						"qValue": ".301727839694044",
						"foldChange": "0.9941"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".538426816845864",
						"qValue": ".116352240974139",
						"foldChange": "1.1458"
					}
				]
			},
			{
				"metaboliteId": "0078dd8d-0000-0000-0000-000000000000",
				"chemicalId": "05f61e79-0000-0000-0000-000000000000",
				"chemicalName": "ceramide (d18:1/17:0, d17:1/18:0)*",
				"plotName": null,
				"compoundId": "0000e05a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 551.527771,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2737,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".124768149712008",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".132604032605488",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".932264468870307",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".440870598257754",
						"qValue": "1",
						"foldChange": "1.1359"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".284686229484112",
						"qValue": "1",
						"foldChange": "1.1852"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".761863030818313",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".137892501574012",
						"qValue": ".742487134132407",
						"foldChange": "1.1966"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".486547734828646",
						"qValue": ".902562957068497",
						"foldChange": "1.0952"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".423292972038381",
						"qValue": ".957839854315834",
						"foldChange": "0.9462"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".373826374267934",
						"qValue": "1",
						"foldChange": "1.1154"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".236121060637983",
						"qValue": "1",
						"foldChange": "1.1631"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".763911169858702",
						"qValue": "1",
						"foldChange": "1.0336"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".221093907361435",
						"qValue": "1",
						"foldChange": "0.8420"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".553229435996016",
						"qValue": "1",
						"foldChange": "1.0878"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".076988065787627",
						"qValue": "1",
						"foldChange": "1.2919"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".395370604041544",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".490996132557508",
						"qValue": "1",
						"foldChange": "1.1889"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".130622199389809",
						"qValue": ".931625125271228",
						"foldChange": "1.2652"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".144769482990374",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".485637415109498",
						"qValue": "1",
						"foldChange": "1.1411"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".036870099207675",
						"qValue": ".985830071188835",
						"foldChange": "1.3982"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".060953006438355",
						"qValue": ".057332292324391",
						"foldChange": "1.4212"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".185133510087553",
						"qValue": ".098331086547422",
						"foldChange": "1.2990"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001970613847557",
						"qValue": ".001703372343904",
						"foldChange": "1.7641"
					}
				]
			},
			{
				"metaboliteId": "0078dd8e-0000-0000-0000-000000000000",
				"chemicalId": "05f61e6d-0000-0000-0000-000000000000",
				"chemicalName": "N-nervonoyl-hexadecasphingosine (d16:1/24:1)*",
				"plotName": null,
				"compoundId": "0000e05b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 619.590345,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2731,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".797414094026489",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".063542079373812",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".288316145444073",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".010133609882212",
						"qValue": "1",
						"foldChange": "1.3662"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".024638284507512",
						"qValue": "1",
						"foldChange": "1.3159"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".722609904111111",
						"qValue": "1",
						"foldChange": "1.0497"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".337396918409944",
						"qValue": ".951252030575746",
						"foldChange": "1.1207"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".913918560531874",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".393876839864733",
						"qValue": ".957839854315834",
						"foldChange": "0.9543"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".585769321807949",
						"qValue": "1",
						"foldChange": "1.0684"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".493682486696089",
						"qValue": "1",
						"foldChange": "1.0062"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".221585554759909",
						"qValue": "1",
						"foldChange": "0.8852"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".860420683699504",
						"qValue": "1",
						"foldChange": "1.0113"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".462102645774963",
						"qValue": "1",
						"foldChange": "1.1545"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".558963652350873",
						"qValue": "1",
						"foldChange": "1.1416"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".406331923281039",
						"qValue": "1",
						"foldChange": "0.8845"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".689205110020408",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".69096785734257",
						"qValue": "1",
						"foldChange": "1.1043"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".276027281970769",
						"qValue": "1",
						"foldChange": "0.7597"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".360250517948647",
						"qValue": "1",
						"foldChange": "0.8304"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".90360026347049",
						"qValue": ".997573179629986",
						"foldChange": "1.0931"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".454664843069141",
						"qValue": ".233643038757568",
						"foldChange": "1.3380"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006211580289825",
						"qValue": ".007408084959211",
						"foldChange": "1.4438"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001921085651459",
						"qValue": ".001669536835842",
						"foldChange": "2.2644"
					}
				]
			},
			{
				"metaboliteId": "0078dd8f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8e3-0000-0000-0000-000000000000",
				"chemicalName": "arabonate/xylonate",
				"plotName": null,
				"compoundId": "0000bc7f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 166.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1133,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".769474019373574",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".988136019369636",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".891862180566696",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".378504054327281",
						"qValue": "1",
						"foldChange": "0.9812"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".488572913503712",
						"qValue": "1",
						"foldChange": "0.9556"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".849394524458373",
						"qValue": "1",
						"foldChange": "1.0930"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".68961947095239",
						"qValue": "1",
						"foldChange": "1.1277"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".77660509574763",
						"qValue": ".976406093916918",
						"foldChange": "1.1554"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".907785485524261",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".822171512915874",
						"qValue": "1",
						"foldChange": "1.0679"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".795833338782836",
						"qValue": "1",
						"foldChange": "1.0802"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".972859980359674",
						"qValue": "1",
						"foldChange": "0.9594"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".557856066261625",
						"qValue": "1",
						"foldChange": "0.9130"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".959354821406414",
						"qValue": "1",
						"foldChange": "0.9953"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".606061286350216",
						"qValue": "1",
						"foldChange": "1.0902"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".656784098120564",
						"qValue": "1",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".415935083704457",
						"qValue": "1",
						"foldChange": "1.1769"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".685700092939537",
						"qValue": "1",
						"foldChange": "1.1333"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".84156536700582",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".486672887874997",
						"qValue": "1",
						"foldChange": "1.0368"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".603181588347078",
						"qValue": ".985830071188835",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000062206940131",
						"qValue": ".000355105733533",
						"foldChange": "2.0058"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008162324862615",
						"qValue": ".008753981692208",
						"foldChange": "1.6989"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000258740056868",
						"qValue": ".000392445008508",
						"foldChange": "1.8484"
					}
				]
			},
			{
				"metaboliteId": "0078dd90-0000-0000-0000-000000000000",
				"chemicalId": "05f5e801-0000-0000-0000-000000000000",
				"chemicalName": "2-methoxyacetaminophen sulfate*",
				"plotName": null,
				"compoundId": "0000b7b7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 261.030708,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4777,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".206483416676561",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".589175172721302",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".612199868075147",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".436293279265483",
						"qValue": "1",
						"foldChange": "2.3574"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".49079098397461",
						"qValue": "1",
						"foldChange": "1.4767"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".145563984489749",
						"qValue": "1",
						"foldChange": "1.4195"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".760001851571029",
						"qValue": "1",
						"foldChange": "2.8612"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".605560621199823",
						"qValue": ".928868848501546",
						"foldChange": "0.9150"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".412290537191941",
						"qValue": ".957839854315834",
						"foldChange": "0.9049"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".323717316470054",
						"qValue": "1",
						"foldChange": "0.8798"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".73518973955906",
						"qValue": "1",
						"foldChange": "0.9162"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".514384595298516",
						"qValue": "1",
						"foldChange": "8.2733"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".12371999948048",
						"qValue": "1",
						"foldChange": "0.0849"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".446979282041142",
						"qValue": "1",
						"foldChange": "2.2314"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".464530596603715",
						"qValue": "1",
						"foldChange": "26.2823"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".072766852050837",
						"qValue": "1",
						"foldChange": "0.0651"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".092216229263518",
						"qValue": "1",
						"foldChange": "0.0502"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".985409561341883",
						"qValue": "1",
						"foldChange": "0.7709"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".151529628967956",
						"qValue": "1",
						"foldChange": "0.0707"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".555631221490136",
						"qValue": "1",
						"foldChange": "1.3514"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".423268936828667",
						"qValue": ".985830071188835",
						"foldChange": "19.1248"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".17012033765136",
						"qValue": ".119609629397942",
						"foldChange": "19.1248"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".10674508100076",
						"qValue": ".034187328074339",
						"foldChange": "14.1514"
					}
				]
			},
			{
				"metaboliteId": "0078dd91-0000-0000-0000-000000000000",
				"chemicalId": "05f5e592-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxy-2-ethylpropionate",
				"plotName": null,
				"compoundId": "00007e8d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00396",
				"inChiKey": "ZMZQVAUJTDKQGE-UHFFFAOYAZ",
				"kegg": null,
				"mass": 118.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 345,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".613963529030752",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".577584139393386",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".274522823434899",
						"qValue": ".969373123415348",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".139625151806246",
						"qValue": "1",
						"foldChange": "0.8908"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".375869655081053",
						"qValue": "1",
						"foldChange": "1.1478"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".020525199898898",
						"qValue": "1",
						"foldChange": "1.3311"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".721273246390417",
						"qValue": "1",
						"foldChange": "1.1141"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".751592868790677",
						"qValue": ".960368665676976",
						"foldChange": "0.9993"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".501517121783601",
						"qValue": ".957839854315834",
						"foldChange": "0.9817"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".984686416352838",
						"qValue": "1",
						"foldChange": "1.0511"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".977472378399977",
						"qValue": "1",
						"foldChange": "1.0321"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".962169369256463",
						"qValue": "1",
						"foldChange": "0.9822"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".600061749533622",
						"qValue": "1",
						"foldChange": "0.8803"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".725574716533907",
						"qValue": "1",
						"foldChange": "1.0478"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".387310758151823",
						"qValue": "1",
						"foldChange": "1.1903"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".543902483307435",
						"qValue": "1",
						"foldChange": "1.1603"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".229321591818761",
						"qValue": "1",
						"foldChange": "1.2090"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".514541977300165",
						"qValue": "1",
						"foldChange": "1.0420"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".211848897343962",
						"qValue": "1",
						"foldChange": "0.7800"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".879854872069623",
						"qValue": "1",
						"foldChange": "0.9167"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".289614455184332",
						"qValue": ".985830071188835",
						"foldChange": "1.1753"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".155332595611803",
						"qValue": ".112290744136665",
						"foldChange": "1.4244"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".119707976237929",
						"qValue": ".070273985597671",
						"foldChange": "1.2098"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006066312638367",
						"qValue": ".003722583971575",
						"foldChange": "1.6479"
					}
				]
			},
			{
				"metaboliteId": "0078dd92-0000-0000-0000-000000000000",
				"chemicalId": "000000d7-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 5'-diphosphoribose (ADP-ribose)",
				"plotName": null,
				"compoundId": "0000022e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01178",
				"inChiKey": "SDMADEZQMYCSNO-UHFFFAOYAX",
				"kegg": "C00301",
				"mass": 559.071673,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3937,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".066678476796539",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".785372189974276",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".574856323928909",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".983504666550487",
						"qValue": "1",
						"foldChange": "5.9689"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".386896254920951",
						"qValue": "1",
						"foldChange": "0.8733"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".375786259262478",
						"qValue": "1",
						"foldChange": "2.2810"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".523224715361026",
						"qValue": "1",
						"foldChange": "1.0458"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".59002400596389",
						"qValue": ".928868848501546",
						"foldChange": "1.2716"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".920247139859995",
						"qValue": "1",
						"foldChange": "2.5825"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".560822035299189",
						"qValue": "1",
						"foldChange": "1.2767"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".435068627199147",
						"qValue": "1",
						"foldChange": "2.6825"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".176182340831567",
						"qValue": ".989315025499063",
						"foldChange": "2.8259"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".205594097893397",
						"qValue": "1",
						"foldChange": "0.4205"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".27397626008071",
						"qValue": "1",
						"foldChange": "0.5137"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".914280892195038",
						"qValue": "1",
						"foldChange": "1.2216"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".056875868383973",
						"qValue": "1",
						"foldChange": "0.2307"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".086918499936532",
						"qValue": "1",
						"foldChange": "0.2236"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".923180085254703",
						"qValue": "1",
						"foldChange": "0.9692"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".369397058299414",
						"qValue": "1",
						"foldChange": "0.8368"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".562126166824626",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".145508591074333",
						"qValue": ".985830071188835",
						"foldChange": "1.3187"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000079786957536",
						"qValue": ".000433060834259",
						"foldChange": "7.0719"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006575893072151",
						"qValue": ".007608565896158",
						"foldChange": "5.3626"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001083593826832",
						"qValue": ".001109654747314",
						"foldChange": "6.4084"
					}
				]
			},
			{
				"metaboliteId": "0078dd93-0000-0000-0000-000000000000",
				"chemicalId": "000004e6-0000-0000-0000-000000000000",
				"chemicalName": "glycerol",
				"plotName": null,
				"compoundId": "00003b12-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00131",
				"inChiKey": "PEDCQBHIVMGVHV-UHFFFAOYAF",
				"kegg": "C00116",
				"mass": 92.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2543,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".781646392604809",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".041070193215755",
						"qValue": ".998592412188778",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".629237335706476",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".319054063928482",
						"qValue": "1",
						"foldChange": "1.1786"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".471078909439771",
						"qValue": "1",
						"foldChange": "1.1505"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".78038796760281",
						"qValue": "1",
						"foldChange": "1.0399"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".244885352989749",
						"qValue": ".861146427249076",
						"foldChange": "1.2145"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".302237130111976",
						"qValue": ".825038702902081",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".031165016129901",
						"qValue": ".602759743785132",
						"foldChange": "0.8084"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".277483121034407",
						"qValue": "1",
						"foldChange": "1.2277"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".449045263437678",
						"qValue": "1",
						"foldChange": "0.9374"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".068771723467859",
						"qValue": ".727024127920748",
						"foldChange": "0.7863"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".405669827525355",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".822026599818967",
						"qValue": "1",
						"foldChange": "0.9064"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".563679194117929",
						"qValue": "1",
						"foldChange": "0.8758"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".370553484436872",
						"qValue": "1",
						"foldChange": "1.2557"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".754272337518389",
						"qValue": "1",
						"foldChange": "1.0232"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".582301353826328",
						"qValue": "1",
						"foldChange": "0.8148"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".860287375384569",
						"qValue": "1",
						"foldChange": "1.0176"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".529034092793824",
						"qValue": "1",
						"foldChange": "0.7892"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".634576229556164",
						"qValue": ".985830071188835",
						"foldChange": "0.7755"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".800563469135553",
						"qValue": ".344316995840098",
						"foldChange": "0.9406"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".94295705595024",
						"qValue": ".323818004026464",
						"foldChange": "1.1163"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".770325965756544",
						"qValue": ".155395064609154",
						"foldChange": "1.0075"
					}
				]
			},
			{
				"metaboliteId": "0078dd94-0000-0000-0000-000000000000",
				"chemicalId": "00000470-0000-0000-0000-000000000000",
				"chemicalName": "valerate (5:0)",
				"plotName": null,
				"compoundId": "000082a3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00892",
				"inChiKey": "NQPDZGIKBAWPEJ-UHFFFAOYAU",
				"kegg": "C00803",
				"mass": 102.06808,
				"pathwayType": "ANIMAL",
				"subPathway": "Short Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".461288261986332",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".685555990973902",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".738374821456977",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".417159445432665",
						"qValue": "1",
						"foldChange": "1.5145"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".741714494562046",
						"qValue": "1",
						"foldChange": "1.0957"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".628523676006728",
						"qValue": "1",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".379038796842084",
						"qValue": ".958380391854625",
						"foldChange": "0.9110"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".822347587932124",
						"qValue": ".99473270523087",
						"foldChange": "2.1057"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".270984572409191",
						"qValue": ".957839854315834",
						"foldChange": "2.1925"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".479317494786086",
						"qValue": "1",
						"foldChange": "1.4018"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".479317494786086",
						"qValue": "1",
						"foldChange": "1.3571"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".558276726623323",
						"qValue": "1",
						"foldChange": "1.3709"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".521180184697672",
						"qValue": "1",
						"foldChange": "0.6960"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".222945292336924",
						"qValue": "1",
						"foldChange": "0.5077"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".396227192396241",
						"qValue": "1",
						"foldChange": "0.6640"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".192494104622719",
						"qValue": "1",
						"foldChange": "0.4966"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".603395148246696",
						"qValue": "1",
						"foldChange": "0.7478"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".628218324714514",
						"qValue": "1",
						"foldChange": "1.3094"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".768015402826261",
						"qValue": "1",
						"foldChange": "0.7830"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".441348289391765",
						"qValue": ".985830071188835",
						"foldChange": "0.5980"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".009641842939732",
						"qValue": ".014719598415213",
						"foldChange": "0.2358"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001242399945369",
						"qValue": ".002474562880934",
						"foldChange": "0.2744"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".016045938984706",
						"qValue": ".007841339258513",
						"foldChange": "0.2660"
					}
				]
			},
			{
				"metaboliteId": "0078dd95-0000-0000-0000-000000000000",
				"chemicalId": "0000043b-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylmethionine",
				"plotName": null,
				"compoundId": "00000635-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11745",
				"inChiKey": "XUYPXLNMDZIRQH-LURJTMIEBN",
				"kegg": "C02712",
				"mass": 191.061616,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 372,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".542311252093148",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".788962726592113",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".718585038439599",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".429398289041696",
						"qValue": "1",
						"foldChange": "1.1954"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".914508630654935",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".494115162042243",
						"qValue": "1",
						"foldChange": "2.8393"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".645992475493384",
						"qValue": "1",
						"foldChange": "1.8653"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".387712193651321",
						"qValue": ".880987329915339",
						"foldChange": "1.2983"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".188516534015886",
						"qValue": ".957839854315834",
						"foldChange": "1.0617"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".916416997995527",
						"qValue": "1",
						"foldChange": "0.9923"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".809708038525183",
						"qValue": "1",
						"foldChange": "0.9733"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".891893442338924",
						"qValue": "1",
						"foldChange": "0.9935"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".746285407165099",
						"qValue": "1",
						"foldChange": "0.9540"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".705167951570016",
						"qValue": "1",
						"foldChange": "1.0225"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".483895282525123",
						"qValue": "1",
						"foldChange": "1.0718"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".398996494451308",
						"qValue": "1",
						"foldChange": "0.9479"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".323109252121717",
						"qValue": "1",
						"foldChange": "1.0021"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".844076788594826",
						"qValue": "1",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".317060658504458",
						"qValue": "1",
						"foldChange": "0.9783"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".806265977939253",
						"qValue": "1",
						"foldChange": "1.0175"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".22346093990146",
						"qValue": ".985830071188835",
						"foldChange": "1.0401"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000769097732",
						"qValue": ".000036377288921",
						"foldChange": "7.3192"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000458070864304",
						"qValue": ".00140176865258",
						"foldChange": "7.8052"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000009633250803",
						"qValue": ".000064533115359",
						"foldChange": "5.6554"
					}
				]
			},
			{
				"metaboliteId": "0078dd96-0000-0000-0000-000000000000",
				"chemicalId": "0000065d-0000-0000-0000-000000000000",
				"chemicalName": "taurochenodeoxycholate",
				"plotName": null,
				"compoundId": "0000483e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00951",
				"inChiKey": "BHTRKEVKTKCXOH-BJLOMENOBE",
				"kegg": "C05465",
				"mass": 499.296761,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3096,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".320122406838093",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".990777226480099",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".488091133892742",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".316540599004848",
						"qValue": "1",
						"foldChange": "1.6573"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".357976684489265",
						"qValue": "1",
						"foldChange": "1.9205"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".933399894429719",
						"qValue": "1",
						"foldChange": "1.6017"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".297227265676185",
						"qValue": ".911265664999995",
						"foldChange": "0.9336"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".159835342847226",
						"qValue": ".786755805582478",
						"foldChange": "2.1288"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".710119251675184",
						"qValue": ".976023509428735",
						"foldChange": "2.2030"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".911737996242311",
						"qValue": "1",
						"foldChange": "1.5584"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".858592536794504",
						"qValue": "1",
						"foldChange": "1.5600"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".772640451408169",
						"qValue": "1",
						"foldChange": "1.3522"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".190222789071214",
						"qValue": "1",
						"foldChange": "2.3053"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".610076273180175",
						"qValue": "1",
						"foldChange": "0.3549"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".076699954002401",
						"qValue": "1",
						"foldChange": "0.1539"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".926288481802806",
						"qValue": "1",
						"foldChange": "0.5923"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".247954540376673",
						"qValue": "1",
						"foldChange": "0.2745"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".204048354262708",
						"qValue": "1",
						"foldChange": "0.4635"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".941623291126628",
						"qValue": "1",
						"foldChange": "0.7370"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".350835528972714",
						"qValue": "1",
						"foldChange": "0.3187"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".375793572744913",
						"qValue": ".985830071188835",
						"foldChange": "0.4325"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".012963589199266",
						"qValue": ".018494228676856",
						"foldChange": "0.1774"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".941579033192058",
						"qValue": ".323818004026464",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".376013293220257",
						"qValue": ".088772222417851",
						"foldChange": "0.6750"
					}
				]
			},
			{
				"metaboliteId": "0078dd97-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4a8-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl-2-oxobutyrate",
				"plotName": null,
				"compoundId": "0000adee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00019",
				"inChiKey": "QHKABHOOEWYVLI-UHFFFAOYAY",
				"kegg": "C00141",
				"mass": 116.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 360,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".824803318923647",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".387803372187431",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".241954947846592",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".32256586090931",
						"qValue": "1",
						"foldChange": "0.9519"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".478416703903385",
						"qValue": "1",
						"foldChange": "0.9656"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".77682466007807",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".846508774566424",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".061973562157994",
						"qValue": ".687896186587066",
						"foldChange": "0.9158"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".040484966104755",
						"qValue": ".675543257944046",
						"foldChange": "0.9185"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".368668130630397",
						"qValue": "1",
						"foldChange": "0.9556"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".837034898908848",
						"qValue": "1",
						"foldChange": "1.0189"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".270689323756765",
						"qValue": "1",
						"foldChange": "1.0294"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".800499447171296",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".670566564263912",
						"qValue": "1",
						"foldChange": "1.0254"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".848204363212585",
						"qValue": "1",
						"foldChange": "1.0143"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".417229998523525",
						"qValue": "1",
						"foldChange": "1.1237"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".658568961921167",
						"qValue": "1",
						"foldChange": "1.0231"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".736718275502981",
						"qValue": "1",
						"foldChange": "0.9105"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".787945539164938",
						"qValue": "1",
						"foldChange": "0.9758"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".400450007217764",
						"qValue": "1",
						"foldChange": "1.0492"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".264485789207447",
						"qValue": ".985830071188835",
						"foldChange": "1.0752"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".778852750733706",
						"qValue": ".341098964551616",
						"foldChange": "1.0832"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".790424744606425",
						"qValue": ".286615249780362",
						"foldChange": "0.9594"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".958345641663976",
						"qValue": ".186988959980638",
						"foldChange": "1.0004"
					}
				]
			},
			{
				"metaboliteId": "0078dd98-0000-0000-0000-000000000000",
				"chemicalId": "05f5e71a-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoyl-GPC (16:0)*",
				"plotName": null,
				"compoundId": "000089b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61702",
				"inChiKey": "NEGQHKSYEYVFTD-HSZRJFAPBY",
				"kegg": null,
				"mass": 495.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2318,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".609977242504338",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".994843766205837",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".00205987582552",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".534563847735743",
						"qValue": "1",
						"foldChange": "0.9750"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".154987991387241",
						"qValue": "1",
						"foldChange": "1.1651"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".043812524010615",
						"qValue": "1",
						"foldChange": "1.2342"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".007433963731211",
						"qValue": ".237900912197889",
						"foldChange": "1.3273"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".891935728153509",
						"qValue": "1",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".005149184530978",
						"qValue": ".199179819811905",
						"foldChange": "0.8012"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".047186506964143",
						"qValue": ".821720595261736",
						"foldChange": "0.8410"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".168732068138123",
						"qValue": "1",
						"foldChange": "0.8986"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".525455861489575",
						"qValue": "1",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".798681148253042",
						"qValue": "1",
						"foldChange": "0.9538"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".066869492540991",
						"qValue": "1",
						"foldChange": "1.2151"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".034875402457941",
						"qValue": "1",
						"foldChange": "1.2740"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".022199876280407",
						"qValue": "1",
						"foldChange": "1.3294"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".478370886025239",
						"qValue": "1",
						"foldChange": "1.1766"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".127194108430586",
						"qValue": ".931625125271228",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".13674309853565",
						"qValue": "1",
						"foldChange": "0.8545"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".739306740973188",
						"qValue": "1",
						"foldChange": "0.8946"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".267507334491783",
						"qValue": ".985830071188835",
						"foldChange": "1.0470"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000236972609959",
						"qValue": ".000945293992511",
						"foldChange": "2.1327"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000322710146132",
						"qValue": ".001126992981598",
						"foldChange": "1.6047"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000269222358776",
						"qValue": ".000400782154107",
						"foldChange": "1.9018"
					}
				]
			},
			{
				"metaboliteId": "0078dd99-0000-0000-0000-000000000000",
				"chemicalId": "05f5e227-0000-0000-0000-000000000000",
				"chemicalName": "tartarate",
				"plotName": null,
				"compoundId": "00003be8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00956",
				"inChiKey": "FEWJPZIEWOKRBE-UHFFFAOYAZ",
				"kegg": "C00898",
				"mass": 150.016438,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".527500637192703",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".190985165103126",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".337522405018481",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".853970319967282",
						"qValue": "1",
						"foldChange": "1.4418"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".562366452711861",
						"qValue": "1",
						"foldChange": "1.3340"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".445994671268429",
						"qValue": "1",
						"foldChange": "2.4653"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".345922466414646",
						"qValue": ".951252030575746",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".647317875292691",
						"qValue": ".930662761919878",
						"foldChange": "1.6719"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".625311538224919",
						"qValue": ".971058611367529",
						"foldChange": "2.1038"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".066625994210411",
						"qValue": ".821720595261736",
						"foldChange": "0.6929"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".022376046768449",
						"qValue": ".884352967171025",
						"foldChange": "0.5882"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".631020419122594",
						"qValue": "1",
						"foldChange": "0.9569"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".187544314636296",
						"qValue": "1",
						"foldChange": "3.6317"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".099011511784064",
						"qValue": "1",
						"foldChange": "1.9566"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".669567416703729",
						"qValue": "1",
						"foldChange": "0.5388"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".399703048704409",
						"qValue": "1",
						"foldChange": "1.5535"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".602814203459635",
						"qValue": "1",
						"foldChange": "1.6634"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".775605283435102",
						"qValue": "1",
						"foldChange": "1.0707"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".522322197243929",
						"qValue": "1",
						"foldChange": "2.3785"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".765852508006671",
						"qValue": "1",
						"foldChange": "0.7182"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".356584572702068",
						"qValue": ".985830071188835",
						"foldChange": "0.3020"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008167753785929",
						"qValue": ".01308579377988",
						"foldChange": "4.1021"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008293832092443",
						"qValue": ".008770671777843",
						"foldChange": "13.5848"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".007971548181958",
						"qValue": ".004563117531926",
						"foldChange": "5.7115"
					}
				]
			},
			{
				"metaboliteId": "0078dd9a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e12a-0000-0000-0000-000000000000",
				"chemicalName": "3-methylhistidine",
				"plotName": null,
				"compoundId": "00003d3d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00479",
				"inChiKey": "JDHILDINMRGULE-LURJTMIEBU",
				"kegg": "C01152",
				"mass": 169.085127,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 69,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".991992036168357",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".362593244695633",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".857528890951372",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".205997969460273",
						"qValue": "1",
						"foldChange": "0.9580"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".55780278839132",
						"qValue": "1",
						"foldChange": "2.3530"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".492412578657612",
						"qValue": "1",
						"foldChange": "3.0878"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".566798331498319",
						"qValue": "1",
						"foldChange": "1.4272"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".205537800844239",
						"qValue": ".825038702902081",
						"foldChange": "1.1997"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".483247957255092",
						"qValue": ".957839854315834",
						"foldChange": "3.6947"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".876159597706363",
						"qValue": "1",
						"foldChange": "1.5582"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".639588224090623",
						"qValue": "1",
						"foldChange": "1.3607"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".754436845589164",
						"qValue": "1",
						"foldChange": "1.1790"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".981319966554833",
						"qValue": "1",
						"foldChange": "0.7593"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".726309566611488",
						"qValue": "1",
						"foldChange": "0.6674"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".703050524600024",
						"qValue": "1",
						"foldChange": "0.8790"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".539831602392581",
						"qValue": "1",
						"foldChange": "1.4682"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".618948790602961",
						"qValue": "1",
						"foldChange": "1.0542"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".932413823084076",
						"qValue": "1",
						"foldChange": "0.7180"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".631026116307261",
						"qValue": "1",
						"foldChange": "0.6324"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".77837913043149",
						"qValue": "1",
						"foldChange": "0.5361"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".859286339087952",
						"qValue": ".997573179629986",
						"foldChange": "0.8478"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00073730770417",
						"qValue": ".002104445199995",
						"foldChange": "6.9079"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".089728466105916",
						"qValue": ".056686316581752",
						"foldChange": "2.6202"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".196538274238467",
						"qValue": ".054763643018216",
						"foldChange": "3.5913"
					}
				]
			},
			{
				"metaboliteId": "0078dd9b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2b4-0000-0000-0000-000000000000",
				"chemicalName": "glycodeoxycholate",
				"plotName": null,
				"compoundId": "0000482d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00631",
				"inChiKey": "WVULKSPCQVQLCU-ZGFCPQMKBG",
				"kegg": "C05464",
				"mass": 449.314123,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3118,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".862364216228761",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".650004055038426",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".478397109573148",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".678685119026123",
						"qValue": "1",
						"foldChange": "1.6367"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".208400825071199",
						"qValue": "1",
						"foldChange": "3.7244"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".39497461234187",
						"qValue": "1",
						"foldChange": "3.3705"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".120255705876018",
						"qValue": ".703886302155126",
						"foldChange": "1.3027"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".702725621524964",
						"qValue": ".950986052805043",
						"foldChange": "5.6602"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".236837232207362",
						"qValue": ".957839854315834",
						"foldChange": "4.4512"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".689519894059088",
						"qValue": "1",
						"foldChange": "3.2573"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".967026102137728",
						"qValue": "1",
						"foldChange": "1.1825"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".720147697009703",
						"qValue": "1",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".498195022094532",
						"qValue": "1",
						"foldChange": "2.6703"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".861190142126663",
						"qValue": "1",
						"foldChange": "0.6121"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".404445649931299",
						"qValue": "1",
						"foldChange": "0.2292"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".513529095633336",
						"qValue": "1",
						"foldChange": "0.4407"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".866108213756936",
						"qValue": "1",
						"foldChange": "0.2162"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".644746083185958",
						"qValue": "1",
						"foldChange": "0.4905"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".644880676958615",
						"qValue": "1",
						"foldChange": "0.9193"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".3273315943079",
						"qValue": "1",
						"foldChange": "0.3948"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".574937467679634",
						"qValue": ".985830071188835",
						"foldChange": "0.4295"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".135799069556351",
						"qValue": ".102418665460457",
						"foldChange": "1.5027"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".576176211549337",
						"qValue": ".22821688366872",
						"foldChange": "1.9439"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008741944778698",
						"qValue": ".004900039785429",
						"foldChange": "4.8886"
					}
				]
			},
			{
				"metaboliteId": "0078dd9c-0000-0000-0000-000000000000",
				"chemicalId": "0000043a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylleucine",
				"plotName": null,
				"compoundId": "00000633-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11756",
				"inChiKey": "WXNXCEHXYPACJF-ZETCQYMHBF",
				"kegg": "C02710",
				"mass": 173.105194,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 290,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".805424753739233",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".637284435978704",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".986967885336372",
						"qValue": ".999283527063851",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".896975984263459",
						"qValue": "1",
						"foldChange": "1.0152"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".451996537342432",
						"qValue": "1",
						"foldChange": "1.0697"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".378734854182042",
						"qValue": "1",
						"foldChange": "1.0974"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".792834292304775",
						"qValue": "1",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".749372620081615",
						"qValue": ".960368665676976",
						"foldChange": "1.0667"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".561017008820083",
						"qValue": ".957839854315834",
						"foldChange": "1.0779"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".944584640282916",
						"qValue": "1",
						"foldChange": "1.0149"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".854620124152539",
						"qValue": "1",
						"foldChange": "1.0415"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".909443293745365",
						"qValue": "1",
						"foldChange": "0.9670"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".901315255570001",
						"qValue": "1",
						"foldChange": "1.0031"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".554831358286946",
						"qValue": "1",
						"foldChange": "1.1114"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".627284262690733",
						"qValue": "1",
						"foldChange": "1.1081"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".948616536240117",
						"qValue": "1",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".493194122158727",
						"qValue": "1",
						"foldChange": "1.1119"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".522144440766496",
						"qValue": "1",
						"foldChange": "1.1132"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".920051878663578",
						"qValue": "1",
						"foldChange": "1.0225"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".742567020950826",
						"qValue": "1",
						"foldChange": "1.0262"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".664758456360456",
						"qValue": ".990272584968167",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013993495483632",
						"qValue": ".019190219933152",
						"foldChange": "1.8200"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".02426035397751",
						"qValue": ".021074496477334",
						"foldChange": "1.5269"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".020682079851364",
						"qValue": ".009666222189081",
						"foldChange": "1.4304"
					}
				]
			},
			{
				"metaboliteId": "0078dd9d-0000-0000-0000-000000000000",
				"chemicalId": "000004d7-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxystearate",
				"plotName": null,
				"compoundId": "00004619-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KIHBGTRZFAVZRV-UHFFFAOYAD",
				"kegg": "C03045",
				"mass": 300.266445,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1737,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".936470791039681",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".08102946718294",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".152736711662576",
						"qValue": ".88421048724389",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".898195442691722",
						"qValue": "1",
						"foldChange": "1.0065"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".937507137377552",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".960484440464663",
						"qValue": "1",
						"foldChange": "1.0295"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".024255479588822",
						"qValue": ".359575885726771",
						"foldChange": "1.2549"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".28257938063456",
						"qValue": ".825038702902081",
						"foldChange": "0.9732"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".001312705625181",
						"qValue": ".101555680639041",
						"foldChange": "0.7872"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".898248878667253",
						"qValue": "1",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".374461861896134",
						"qValue": "1",
						"foldChange": "0.9395"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".446281921814508",
						"qValue": "1",
						"foldChange": "0.8901"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".557869314709882",
						"qValue": "1",
						"foldChange": "0.9551"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".923989409179314",
						"qValue": "1",
						"foldChange": "1.0058"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".506208497970258",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".391432723284119",
						"qValue": "1",
						"foldChange": "1.1505"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".927589245258427",
						"qValue": "1",
						"foldChange": "0.9970"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".460294989711963",
						"qValue": "1",
						"foldChange": "0.8666"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".242986401462617",
						"qValue": "1",
						"foldChange": "0.9062"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".677838889148511",
						"qValue": "1",
						"foldChange": "0.8699"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".476200252360304",
						"qValue": ".985830071188835",
						"foldChange": "0.9600"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".134181013689396",
						"qValue": ".10189465840502",
						"foldChange": "1.3919"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".078242186397606",
						"qValue": ".051456498183457",
						"foldChange": "1.4658"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".012243376230064",
						"qValue": ".006386496082241",
						"foldChange": "1.4580"
					}
				]
			},
			{
				"metaboliteId": "0078dd9e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e62b-0000-0000-0000-000000000000",
				"chemicalName": "Fibrinopeptide A, des-ala(1)*",
				"plotName": null,
				"compoundId": "00007b3c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JAMFMQAPZKXSKC-UHFFFAOYAY",
				"kegg": null,
				"mass": 1464.648101,
				"pathwayType": "ANIMAL",
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1016,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".545809374748414",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".163038595979311",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".252702804421673",
						"qValue": ".964350163958941",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".777195907263881",
						"qValue": "1",
						"foldChange": "1.1723"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".514988051866532",
						"qValue": "1",
						"foldChange": "1.7374"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".711990174558058",
						"qValue": "1",
						"foldChange": "1.6870"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".013590160549273",
						"qValue": ".303094620658292",
						"foldChange": "5.2556"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".304566756806533",
						"qValue": ".825038702902081",
						"foldChange": "3.1150"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".133935115488444",
						"qValue": ".957839854315834",
						"foldChange": "0.8034"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".176595809333867",
						"qValue": "1",
						"foldChange": "5.7414"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".176595809333867",
						"qValue": ".989315025499063",
						"foldChange": "5.1901"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".948603928805427",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".951397366787205",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".059815987146706",
						"qValue": "1",
						"foldChange": "4.4079"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".758031311291237",
						"qValue": "1",
						"foldChange": "1.0682"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".033443071521266",
						"qValue": ".822195137696456",
						"foldChange": "0.2423"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".812363117816565",
						"qValue": "1",
						"foldChange": "1.7725"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".527376863652871",
						"qValue": "1",
						"foldChange": "3.0747"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".675840659278448",
						"qValue": ".990272584968167",
						"foldChange": "1.7347"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".435084070314349",
						"qValue": ".227833916601623",
						"foldChange": "3.9651"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "3.1150"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".577550348948528",
						"qValue": ".123643394387725",
						"foldChange": "0.5781"
					}
				]
			},
			{
				"metaboliteId": "0078dd9f-0000-0000-0000-000000000000",
				"chemicalId": "0000023c-0000-0000-0000-000000000000",
				"chemicalName": "glucose",
				"plotName": null,
				"compoundId": "00005008-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00122",
				"inChiKey": "WQZGKKKJIJFFOK-DVKNGEFBBQ",
				"kegg": "C00031",
				"mass": 180.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1073,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".073795320325705",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".292656858435684",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".508175761045904",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".334631486815673",
						"qValue": "1",
						"foldChange": "0.9531"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".875215759433154",
						"qValue": "1",
						"foldChange": "1.0205"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".263047970624354",
						"qValue": "1",
						"foldChange": "1.0772"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".269110014053344",
						"qValue": ".901656639749634",
						"foldChange": "0.9519"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".226945728849206",
						"qValue": ".825038702902081",
						"foldChange": "0.9555"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".916104922912737",
						"qValue": "1",
						"foldChange": "1.0031"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".883347685874",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".150979378495676",
						"qValue": "1",
						"foldChange": "0.9329"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".195809769466361",
						"qValue": "1",
						"foldChange": "0.9043"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".529364953774442",
						"qValue": "1",
						"foldChange": "1.0461"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".045731498726964",
						"qValue": "1",
						"foldChange": "1.1873"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".143264597238717",
						"qValue": "1",
						"foldChange": "1.1349"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".569822626060669",
						"qValue": "1",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".012303335970582",
						"qValue": "1",
						"foldChange": "1.2377"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".039945516790259",
						"qValue": ".822195137696456",
						"foldChange": "1.1920"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".760507642256164",
						"qValue": "1",
						"foldChange": "0.9703"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".371336542391558",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".227953141053975",
						"qValue": ".985830071188835",
						"foldChange": "1.0484"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".382518352678152",
						"qValue": ".208685604074736",
						"foldChange": "1.0995"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".621149407583224",
						"qValue": ".241906846043093",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".160855628184699",
						"qValue": ".047192904642545",
						"foldChange": "1.1159"
					}
				]
			},
			{
				"metaboliteId": "0078dda0-0000-0000-0000-000000000000",
				"chemicalId": "0000039d-0000-0000-0000-000000000000",
				"chemicalName": "heptanoate (7:0)",
				"plotName": null,
				"compoundId": "0000066c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00666",
				"inChiKey": "MNWFXJYAOYHMED-UHFFFAOYAP",
				"kegg": "C17714",
				"mass": 130.09938,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1329,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".788717964926532",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".623075699217827",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".862185558311398",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".576024775681438",
						"qValue": "1",
						"foldChange": "1.8123"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".755964095851754",
						"qValue": "1",
						"foldChange": "1.4671"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".803286028941923",
						"qValue": "1",
						"foldChange": "1.5381"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".59800397543492",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".739045018128405",
						"qValue": ".957498790731047",
						"foldChange": "2.1873"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".390877369606007",
						"qValue": ".957839854315834",
						"foldChange": "2.7550"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".504902348696045",
						"qValue": "1",
						"foldChange": "1.0234"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".70605331744755",
						"qValue": "1",
						"foldChange": "1.5480"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".298402477063833",
						"qValue": "1",
						"foldChange": "1.6242"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".811515895418064",
						"qValue": "1",
						"foldChange": "1.1833"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".885060514951634",
						"qValue": "1",
						"foldChange": "0.7553"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".704747484408646",
						"qValue": "1",
						"foldChange": "0.6383"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".500576673938735",
						"qValue": "1",
						"foldChange": "0.6145"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".241655584109819",
						"qValue": "1",
						"foldChange": "0.4204"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".581054783269348",
						"qValue": "1",
						"foldChange": "0.6841"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".808005370277829",
						"qValue": "1",
						"foldChange": "0.8386"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".94275875767781",
						"qValue": "1",
						"foldChange": "0.5367"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".757418517683006",
						"qValue": ".997573179629986",
						"foldChange": "0.6400"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000002073782366",
						"qValue": ".000069678697434",
						"foldChange": "0.2015"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".00022233979292",
						"qValue": ".000898002474794",
						"foldChange": "0.2846"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001378704336961",
						"qValue": ".001319147136066",
						"foldChange": "0.3622"
					}
				]
			},
			{
				"metaboliteId": "0078dda1-0000-0000-0000-000000000000",
				"chemicalId": "00000670-0000-0000-0000-000000000000",
				"chemicalName": "taurocholate",
				"plotName": null,
				"compoundId": "00004841-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00036",
				"inChiKey": "WBWWGRHZICKQGZ-HZAMXZRMBW",
				"kegg": "C05122",
				"mass": 515.291673,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".214694521185026",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".771769429353494",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".502098838841077",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".313085507528779",
						"qValue": "1",
						"foldChange": "1.7170"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".299767067822605",
						"qValue": "1",
						"foldChange": "1.5465"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".977158040581003",
						"qValue": "1",
						"foldChange": "1.2905"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".390814142132765",
						"qValue": ".964405849888462",
						"foldChange": "1.4492"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".329243457798723",
						"qValue": ".831655911352372",
						"foldChange": "2.0355"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".905230391790834",
						"qValue": "1",
						"foldChange": "1.6572"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".632172324576913",
						"qValue": "1",
						"foldChange": "1.6138"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".288474352756404",
						"qValue": "1",
						"foldChange": "1.7895"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".556885641629704",
						"qValue": "1",
						"foldChange": "1.6886"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".512798362597663",
						"qValue": "1",
						"foldChange": "1.7381"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".183802186190818",
						"qValue": "1",
						"foldChange": "0.1951"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".049771937863105",
						"qValue": "1",
						"foldChange": "0.1122"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".678723837367295",
						"qValue": "1",
						"foldChange": "0.3054"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".110249014211628",
						"qValue": "1",
						"foldChange": "0.1203"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".211470209503134",
						"qValue": "1",
						"foldChange": "0.3938"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".619427604430213",
						"qValue": "1",
						"foldChange": "0.3802"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".200129990334677",
						"qValue": "1",
						"foldChange": "0.1598"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".400282997127093",
						"qValue": ".985830071188835",
						"foldChange": "0.4202"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".029800290764054",
						"qValue": ".034741534746411",
						"foldChange": "0.2959"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".41926702872328",
						"qValue": ".17713805890593",
						"foldChange": "1.7155"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".604846599705423",
						"qValue": ".127785504646744",
						"foldChange": "2.5648"
					}
				]
			},
			{
				"metaboliteId": "0078dda2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e527-0000-0000-0000-000000000000",
				"chemicalName": "7-ketodeoxycholate",
				"plotName": null,
				"compoundId": "00007ca0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00391",
				"inChiKey": "RHCPKKNRWFXMAT-RRWYKFPJBR",
				"kegg": null,
				"mass": 406.271924,
				"pathwayType": "ANIMAL",
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3164,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".232313501385903",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".132779977104797",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".339088656230278",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".335081157521221",
						"qValue": "1",
						"foldChange": "1.0371"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".320649739195968",
						"qValue": "1",
						"foldChange": "0.9124"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".976356243541194",
						"qValue": "1",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".54431135793691",
						"qValue": "1",
						"foldChange": "1.0385"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".272892047717207",
						"qValue": ".825038702902081",
						"foldChange": "1.6658"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".091911316150112",
						"qValue": ".832090745146225",
						"foldChange": "1.7800"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".063500416147276",
						"qValue": ".821720595261736",
						"foldChange": "0.7839"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".109465130546668",
						"qValue": "1",
						"foldChange": "0.7886"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".789265765895238",
						"qValue": "1",
						"foldChange": "1.2113"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".069225381817783",
						"qValue": "1",
						"foldChange": "0.2054"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".322969113268522",
						"qValue": "1",
						"foldChange": "0.2545"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".436033121209021",
						"qValue": "1",
						"foldChange": "1.2391"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".106625803898671",
						"qValue": "1",
						"foldChange": "0.2525"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".130824142549235",
						"qValue": "1",
						"foldChange": "0.2328"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".984741473264193",
						"qValue": "1",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".465919112056068",
						"qValue": "1",
						"foldChange": "0.5204"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".175606219522579",
						"qValue": "1",
						"foldChange": "0.3606"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".487496621152457",
						"qValue": ".985830071188835",
						"foldChange": "0.6928"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".884078105604215",
						"qValue": ".367726057898445",
						"foldChange": "0.9318"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".945162970448022",
						"qValue": ".324176298495471",
						"foldChange": "0.7094"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056233374373077",
						"qValue": ".020879844219214",
						"foldChange": "3.4959"
					}
				]
			},
			{
				"metaboliteId": "0078dda3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6c9-0000-0000-0000-000000000000",
				"chemicalName": "1-docosahexaenoylglycerol (22:6)",
				"plotName": null,
				"compoundId": "00008951-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11587",
				"inChiKey": "JWPHORDRFVRZPP-KUBAVDMBBG",
				"kegg": null,
				"mass": 402.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2567,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".715152163679628",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".505845903095888",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".764365343408161",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".126003598846523",
						"qValue": "1",
						"foldChange": "7.0668"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".873300752732966",
						"qValue": "1",
						"foldChange": "1.3283"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".168889119811136",
						"qValue": "1",
						"foldChange": "5.6401"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".880762947991394",
						"qValue": "1",
						"foldChange": "1.5428"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".884008780133774",
						"qValue": "1",
						"foldChange": "1.5929"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".996717325481434",
						"qValue": "1",
						"foldChange": "1.9837"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815819651115497",
						"qValue": "1",
						"foldChange": "1.4332"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".835584603851379",
						"qValue": "1",
						"foldChange": "1.2982"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".659839616598437",
						"qValue": "1",
						"foldChange": "1.0465"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".979155949766336",
						"qValue": "1",
						"foldChange": "0.4828"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".938015126340561",
						"qValue": "1",
						"foldChange": "0.4101"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".916402079909484",
						"qValue": "1",
						"foldChange": "0.8496"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".165698445090044",
						"qValue": "1",
						"foldChange": "0.2549"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".267778070576627",
						"qValue": "1",
						"foldChange": "0.2211"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".831704930323162",
						"qValue": "1",
						"foldChange": "0.8674"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".822305693465572",
						"qValue": "1",
						"foldChange": "0.4969"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".704151813842043",
						"qValue": "1",
						"foldChange": "0.3941"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".863738888585391",
						"qValue": ".997573179629986",
						"foldChange": "0.7932"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000600194505507",
						"qValue": ".001779400120967",
						"foldChange": "0.2257"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001446394432312",
						"qValue": ".002729417140557",
						"foldChange": "0.3020"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".019207009617068",
						"qValue": ".009055790785388",
						"foldChange": "0.6850"
					}
				]
			},
			{
				"metaboliteId": "0078dda4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e751-0000-0000-0000-000000000000",
				"chemicalName": "undecanedioate (C11-DC)",
				"plotName": null,
				"compoundId": "0000a59b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00888",
				"inChiKey": "LWBHHRRTOZQPDM-UHFFFAOYAZ",
				"kegg": null,
				"mass": 216.136159,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1507,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".561673949297026",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".845562123280022",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".820309476591938",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".451365715770535",
						"qValue": "1",
						"foldChange": "1.5681"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".888464253668046",
						"qValue": "1",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".372440256645561",
						"qValue": "1",
						"foldChange": "0.9165"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".755571252677017",
						"qValue": "1",
						"foldChange": "1.6048"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".742709478575744",
						"qValue": ".959776391327525",
						"foldChange": "1.5696"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".523120032903579",
						"qValue": ".957839854315834",
						"foldChange": "2.2894"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".720050561893491",
						"qValue": "1",
						"foldChange": "1.2739"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".49557020736641",
						"qValue": "1",
						"foldChange": "1.5493"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".745688377718427",
						"qValue": "1",
						"foldChange": "1.2668"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".731432245331872",
						"qValue": "1",
						"foldChange": "1.1641"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".480745684635306",
						"qValue": "1",
						"foldChange": "0.6210"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".292933380250722",
						"qValue": "1",
						"foldChange": "0.5334"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".559617792594326",
						"qValue": "1",
						"foldChange": "0.9243"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".30995384346775",
						"qValue": "1",
						"foldChange": "0.5539"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".632617500471428",
						"qValue": "1",
						"foldChange": "0.5993"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".458927954652037",
						"qValue": "1",
						"foldChange": "1.3482"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".987762606611979",
						"qValue": "1",
						"foldChange": "0.8454"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".482760858588428",
						"qValue": ".985830071188835",
						"foldChange": "0.6271"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000002556029871",
						"qValue": ".000070523119953",
						"foldChange": "0.1756"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000035032882343",
						"qValue": ".000350605657304",
						"foldChange": "0.2189"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000002751389354",
						"qValue": ".000027647322309",
						"foldChange": "0.1751"
					}
				]
			},
			{
				"metaboliteId": "0078dda5-0000-0000-0000-000000000000",
				"chemicalId": "05f5f293-0000-0000-0000-000000000000",
				"chemicalName": "6-oxopiperidine-2-carboxylate",
				"plotName": null,
				"compoundId": "0000a8df-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61705",
				"inChiKey": "FZXCPFJMYOQZCA-UHFFFAOYAC",
				"kegg": null,
				"mass": 143.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 124,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".725282388897701",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".583007483371177",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".688385501952055",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".694240900561264",
						"qValue": "1",
						"foldChange": "1.0648"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".567497400198597",
						"qValue": "1",
						"foldChange": "1.1684"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".33631998738844",
						"qValue": "1",
						"foldChange": "1.3986"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".813333711136286",
						"qValue": "1",
						"foldChange": "1.1099"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".840659449260024",
						"qValue": "1",
						"foldChange": "1.2286"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".971997011815612",
						"qValue": "1",
						"foldChange": "1.4317"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".271522500322209",
						"qValue": "1",
						"foldChange": "0.8512"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".16512745576057",
						"qValue": "1",
						"foldChange": "0.7750"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".767627401149176",
						"qValue": "1",
						"foldChange": "0.9951"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".693978670932348",
						"qValue": "1",
						"foldChange": "1.1656"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".776752521837116",
						"qValue": "1",
						"foldChange": "1.0630"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".927629979243322",
						"qValue": "1",
						"foldChange": "0.9119"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".602309313307129",
						"qValue": "1",
						"foldChange": "1.3357"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".784090000054414",
						"qValue": "1",
						"foldChange": "0.9274"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".433152178398861",
						"qValue": "1",
						"foldChange": "0.6943"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".854500788263421",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".237750420629685",
						"qValue": "1",
						"foldChange": "0.6951"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".300825504035161",
						"qValue": ".985830071188835",
						"foldChange": "0.6353"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".038478819169221",
						"qValue": ".041498289781283",
						"foldChange": "2.3656"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003930664690192",
						"qValue": ".005514500099902",
						"foldChange": "2.9729"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00000129695263",
						"qValue": ".00001895625031",
						"foldChange": "5.0829"
					}
				]
			},
			{
				"metaboliteId": "0078dda6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8eb-0000-0000-0000-000000000000",
				"chemicalName": "androstenediol (3alpha, 17alpha) monosulfate (3)",
				"plotName": null,
				"compoundId": "00009159-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 370.181395,
				"pathwayType": "ANIMAL",
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3036,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".865895576283993",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".768763935913158",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".17012646324339",
						"qValue": ".926698788916122",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".470730145089024",
						"qValue": "1",
						"foldChange": "0.8819"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".888422781275499",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".560623250524311",
						"qValue": "1",
						"foldChange": "1.1585"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".023017889163049",
						"qValue": ".359575885726771",
						"foldChange": "4.4681"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".052005034644355",
						"qValue": ".68015325436554",
						"foldChange": "3.4448"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".723526513653636",
						"qValue": ".976023509428735",
						"foldChange": "0.9527"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".772644058813255",
						"qValue": "1",
						"foldChange": "0.9477"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".460877237822221",
						"qValue": "1",
						"foldChange": "0.8624"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".652717128714265",
						"qValue": "1",
						"foldChange": "0.8571"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".197357475004311",
						"qValue": "1",
						"foldChange": "0.8123"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999731714950879",
						"qValue": "1",
						"foldChange": "1.2105"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".211315947671826",
						"qValue": "1",
						"foldChange": "1.4903"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".813691705673905",
						"qValue": "1",
						"foldChange": "1.2891"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".836176257533727",
						"qValue": "1",
						"foldChange": "1.0706"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".986857848703354",
						"qValue": "1",
						"foldChange": "0.8305"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".805923407877894",
						"qValue": "1",
						"foldChange": "1.0650"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".750008102675485",
						"qValue": "1",
						"foldChange": "0.9011"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".929440031800249",
						"qValue": ".997573179629986",
						"foldChange": "0.8461"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".981137695119923",
						"qValue": ".392325433453615",
						"foldChange": "1.1479"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".942442380793807",
						"qValue": ".323818004026464",
						"foldChange": "1.1726"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".220056500385111",
						"qValue": ".059064752064812",
						"foldChange": "1.3981"
					}
				]
			},
			{
				"metaboliteId": "0078dda7-0000-0000-0000-000000000000",
				"chemicalId": "05f5f7e8-0000-0000-0000-000000000000",
				"chemicalName": "methyl glucopyranoside (alpha + beta)",
				"plotName": null,
				"compoundId": "0000b440-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 194.07908,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4577,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".681004474802486",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".138865690005981",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".608190399070803",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".908368904063477",
						"qValue": "1",
						"foldChange": "2.1596"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".305499453445929",
						"qValue": "1",
						"foldChange": "2.3126"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".255240102102094",
						"qValue": "1",
						"foldChange": "1.8674"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".286786479802446",
						"qValue": ".911265664999995",
						"foldChange": "1.8146"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".471336424512791",
						"qValue": ".902562957068497",
						"foldChange": "1.6023"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".72702839112197",
						"qValue": ".976023509428735",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".065518130108481",
						"qValue": ".821720595261736",
						"foldChange": "2.5963"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".174489888369102",
						"qValue": "1",
						"foldChange": "2.8880"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".614846512870069",
						"qValue": "1",
						"foldChange": "1.0283"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".522455935674003",
						"qValue": "1",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".197951164923083",
						"qValue": "1",
						"foldChange": "0.5946"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".479507464766005",
						"qValue": "1",
						"foldChange": "0.6136"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".822887965724213",
						"qValue": "1",
						"foldChange": "1.3093"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".824227463614553",
						"qValue": "1",
						"foldChange": "1.3248"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".991449020451839",
						"qValue": "1",
						"foldChange": "1.0118"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".367582590257947",
						"qValue": "1",
						"foldChange": "0.6965"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".323703879680402",
						"qValue": "1",
						"foldChange": "0.5755"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".889027774537528",
						"qValue": ".997573179629986",
						"foldChange": "0.8262"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".02492733221274",
						"qValue": ".029687788285768",
						"foldChange": "1.8928"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".0022684675966",
						"qValue": ".003635368484606",
						"foldChange": "3.0886"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013254779505049",
						"qValue": ".00678678146653",
						"foldChange": "1.7087"
					}
				]
			},
			{
				"metaboliteId": "0078dda8-0000-0000-0000-000000000000",
				"chemicalId": "05f5f110-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl catechol sulfate (1)",
				"plotName": null,
				"compoundId": "0000b455-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 204.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4091,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".458054391273951",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".797328875721945",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".808895611000511",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".782515755370372",
						"qValue": "1",
						"foldChange": "1.4090"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".713123335464432",
						"qValue": "1",
						"foldChange": "2.2043"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".926881420722986",
						"qValue": "1",
						"foldChange": "2.7410"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".635582647867961",
						"qValue": "1",
						"foldChange": "1.1495"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".48351973533395",
						"qValue": ".902562957068497",
						"foldChange": "3.5354"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".242614943574551",
						"qValue": ".957839854315834",
						"foldChange": "3.6790"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".683715485297592",
						"qValue": "1",
						"foldChange": "0.9282"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".47716529277208",
						"qValue": "1",
						"foldChange": "0.9159"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".760474359968977",
						"qValue": "1",
						"foldChange": "0.8373"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".326792558658719",
						"qValue": "1",
						"foldChange": "0.8163"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".737808087393808",
						"qValue": "1",
						"foldChange": "0.8649"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".198396599103667",
						"qValue": "1",
						"foldChange": "1.0595"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".271877116957832",
						"qValue": "1",
						"foldChange": "0.3328"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".816036975321318",
						"qValue": "1",
						"foldChange": "0.9167"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".19442281096841",
						"qValue": "1",
						"foldChange": "2.7541"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".786908641812729",
						"qValue": "1",
						"foldChange": "0.9384"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".93532622260516",
						"qValue": "1",
						"foldChange": "0.8353"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".730202289133338",
						"qValue": ".997573179629986",
						"foldChange": "0.8901"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".011248750871714",
						"qValue": ".016852287643426",
						"foldChange": "5.0146"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".496064679954315",
						"qValue": ".201110159367765",
						"foldChange": "1.3330"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".30182255285756",
						"qValue": ".075859325332038",
						"foldChange": "2.5846"
					}
				]
			},
			{
				"metaboliteId": "0078dda9-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed2f-0000-0000-0000-000000000000",
				"chemicalName": "N-oleoyltaurine",
				"plotName": null,
				"compoundId": "00009b34-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KOGRJTUIKPMZEJ-KTKRTIGZBU",
				"kegg": null,
				"mass": 389.259979,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1918,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".999595598601337",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".103483030888808",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".721480084838004",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".715553840082913",
						"qValue": "1",
						"foldChange": "1.3628"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".27282763755557",
						"qValue": "1",
						"foldChange": "1.2514"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".461262143474194",
						"qValue": "1",
						"foldChange": "0.9839"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".51133839242469",
						"qValue": "1",
						"foldChange": "1.6067"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".786636988682455",
						"qValue": ".978751596872832",
						"foldChange": "1.1802"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".355039292971904",
						"qValue": ".957839854315834",
						"foldChange": "1.1936"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".432030608847063",
						"qValue": "1",
						"foldChange": "1.0033"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".046351487631165",
						"qValue": "1",
						"foldChange": "0.6487"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".216392663943285",
						"qValue": "1",
						"foldChange": "1.0691"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".595041004685881",
						"qValue": "1",
						"foldChange": "0.8151"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".669267426022122",
						"qValue": "1",
						"foldChange": "0.9714"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".342194975114525",
						"qValue": "1",
						"foldChange": "1.1917"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".750496346478222",
						"qValue": "1",
						"foldChange": "1.2608"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".964686358074474",
						"qValue": "1",
						"foldChange": "0.9098"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".792770981527417",
						"qValue": "1",
						"foldChange": "0.7216"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".84491750838381",
						"qValue": "1",
						"foldChange": "0.9356"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".667781427588588",
						"qValue": "1",
						"foldChange": "0.7016"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".52969112827389",
						"qValue": ".985830071188835",
						"foldChange": "0.7499"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".123048367024928",
						"qValue": ".095859191778672",
						"foldChange": "1.7335"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".049797088153432",
						"qValue": ".037427872815125",
						"foldChange": "1.9666"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".019747715198671",
						"qValue": ".009256434681062",
						"foldChange": "3.3715"
					}
				]
			},
			{
				"metaboliteId": "0078ddaa-0000-0000-0000-000000000000",
				"chemicalId": "05f5e44b-0000-0000-0000-000000000000",
				"chemicalName": "HEPES",
				"plotName": null,
				"compoundId": "00005300-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JKMHFZQWWAIEOD-UHFFFAOYAC",
				"kegg": null,
				"mass": 238.098728,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					}
				]
			},
			{
				"metaboliteId": "0078ddab-0000-0000-0000-000000000000",
				"chemicalId": "05f5e127-0000-0000-0000-000000000000",
				"chemicalName": "methionine sulfoxide",
				"plotName": null,
				"compoundId": "000047c6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02005",
				"inChiKey": "QEFRNWWLZKMPFJ-YGVKFDHGBW",
				"kegg": "C02989",
				"mass": 165.045966,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 376,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".876411219909499",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".941971938348635",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".908186655897606",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".792606811922499",
						"qValue": "1",
						"foldChange": "1.0777"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".790793542675096",
						"qValue": "1",
						"foldChange": "1.0603"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".998120312836357",
						"qValue": "1",
						"foldChange": "1.0600"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".672174429719495",
						"qValue": "1",
						"foldChange": "0.9803"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".315863872891345",
						"qValue": ".825038702902081",
						"foldChange": "0.9764"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".559417013550861",
						"qValue": ".957839854315834",
						"foldChange": "0.9963"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".905325350397005",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".92951358237233",
						"qValue": "1",
						"foldChange": "1.0162"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".835729213339646",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".675424719717986",
						"qValue": "1",
						"foldChange": "1.0236"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".839717179458268",
						"qValue": "1",
						"foldChange": "0.9299"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".54041511501848",
						"qValue": "1",
						"foldChange": "0.9085"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".862492654792131",
						"qValue": "1",
						"foldChange": "0.9752"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".596452569858269",
						"qValue": "1",
						"foldChange": "0.9133"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".707641849963569",
						"qValue": "1",
						"foldChange": "0.9365"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".503944721397021",
						"qValue": "1",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".728876697389003",
						"qValue": "1",
						"foldChange": "0.9050"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".769418567696328",
						"qValue": ".997573179629986",
						"foldChange": "0.9656"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00242918921997",
						"qValue": ".005256743094288",
						"foldChange": "1.5663"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000884875403108",
						"qValue": ".002039209562601",
						"foldChange": "1.8240"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".005803455414069",
						"qValue": ".003616495690954",
						"foldChange": "1.4957"
					}
				]
			},
			{
				"metaboliteId": "0078ddac-0000-0000-0000-000000000000",
				"chemicalId": "05f5e207-0000-0000-0000-000000000000",
				"chemicalName": "imidazole lactate",
				"plotName": null,
				"compoundId": "00003d64-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02320",
				"inChiKey": "ACZFBYCNAVEFLC-YFKPBYRVBI",
				"kegg": "C05568",
				"mass": 156.053492,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 78,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".026652672860016",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".590814750015748",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".426351530864578",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".775048267180289",
						"qValue": "1",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".825852754150513",
						"qValue": "1",
						"foldChange": "1.0187"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".613347058996024",
						"qValue": "1",
						"foldChange": "1.0394"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".426474784571985",
						"qValue": ".99041177153024",
						"foldChange": "1.0463"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".916384394695684",
						"qValue": "1",
						"foldChange": "1.0035"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".368625036148121",
						"qValue": ".957839854315834",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".181823085590496",
						"qValue": "1",
						"foldChange": "0.9387"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".077486750743995",
						"qValue": "1",
						"foldChange": "0.9218"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".655213385682141",
						"qValue": "1",
						"foldChange": "0.9290"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".04427533347574",
						"qValue": "1",
						"foldChange": "1.4085"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".956899065769455",
						"qValue": "1",
						"foldChange": "1.0008"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".04523852263285",
						"qValue": "1",
						"foldChange": "0.7105"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".019780268357293",
						"qValue": "1",
						"foldChange": "1.4685"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".667857370643799",
						"qValue": "1",
						"foldChange": "0.9664"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".008334325699981",
						"qValue": ".653136865625524",
						"foldChange": "0.6581"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".055959315506348",
						"qValue": "1",
						"foldChange": "1.3617"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".454438345994395",
						"qValue": "1",
						"foldChange": "0.8648"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".011384115314816",
						"qValue": ".968788213290821",
						"foldChange": "0.6351"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".043951659220335",
						"qValue": ".045474940023209",
						"foldChange": "1.3507"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000238311031855",
						"qValue": ".000898002474794",
						"foldChange": "2.0404"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".033401677526606",
						"qValue": ".013912397570022",
						"foldChange": "1.3144"
					}
				]
			},
			{
				"metaboliteId": "0078ddad-0000-0000-0000-000000000000",
				"chemicalId": "0000031c-0000-0000-0000-000000000000",
				"chemicalName": "alpha-ketobutyrate",
				"plotName": null,
				"compoundId": "00001368-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00005",
				"inChiKey": "TYEYBOSBBBHJIV-REWHXWOFAN",
				"kegg": "C00109",
				"mass": 102.031695,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 390,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".437774863541854",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".532704396848928",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".430484631268839",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".215881826843713",
						"qValue": "1",
						"foldChange": "0.9359"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".577650486857092",
						"qValue": "1",
						"foldChange": "0.8897"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".491368449687495",
						"qValue": "1",
						"foldChange": "1.5980"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".933560783057272",
						"qValue": "1",
						"foldChange": "1.3286"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".092273949391294",
						"qValue": ".697458782960496",
						"foldChange": "0.9211"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".108796932278099",
						"qValue": ".893591860856987",
						"foldChange": "1.0428"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".978151360553734",
						"qValue": "1",
						"foldChange": "1.0402"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".790669838257345",
						"qValue": "1",
						"foldChange": "1.3117"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".769679615094683",
						"qValue": "1",
						"foldChange": "1.3803"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".598374213112557",
						"qValue": "1",
						"foldChange": "0.7822"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".850908166256646",
						"qValue": "1",
						"foldChange": "0.8858"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".48294258442092",
						"qValue": "1",
						"foldChange": "1.1324"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".817609982757269",
						"qValue": "1",
						"foldChange": "1.1243"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".351918959065465",
						"qValue": "1",
						"foldChange": "1.0721"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".463714294042133",
						"qValue": "1",
						"foldChange": "0.9535"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".227388058351386",
						"qValue": "1",
						"foldChange": "0.7053"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".483300065477259",
						"qValue": "1",
						"foldChange": "1.1311"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".062833961412707",
						"qValue": ".985830071188835",
						"foldChange": "1.6038"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00044011521281",
						"qValue": ".001435732104421",
						"foldChange": "4.0307"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".006216654130164",
						"qValue": ".007408084959211",
						"foldChange": "3.6944"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008434444975801",
						"qValue": ".004758090027302",
						"foldChange": "3.0422"
					}
				]
			},
			{
				"metaboliteId": "0078ddae-0000-0000-0000-000000000000",
				"chemicalId": "00000320-0000-0000-0000-000000000000",
				"chemicalName": "cysteine",
				"plotName": null,
				"compoundId": "0000074c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00574",
				"inChiKey": "XUJNEKJLAYXESH-REOHCLBHBU",
				"kegg": "C00097",
				"mass": 121.019751,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 391,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".062734149099895",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".705376299596591",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".075526276458217",
						"qValue": ".756151309011088",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".034250425708748",
						"qValue": "1",
						"foldChange": "0.8585"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".494174748295051",
						"qValue": "1",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".142680353953012",
						"qValue": "1",
						"foldChange": "1.2125"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".993008590634959",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".305648384446854",
						"qValue": ".825038702902081",
						"foldChange": "0.9460"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".309736229160293",
						"qValue": ".957839854315834",
						"foldChange": "0.9813"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".315544542346894",
						"qValue": "1",
						"foldChange": "1.1321"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".074660218846719",
						"qValue": "1",
						"foldChange": "1.2523"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".423119429818929",
						"qValue": "1",
						"foldChange": "1.0139"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".159844660966081",
						"qValue": "1",
						"foldChange": "0.8277"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".755580338443582",
						"qValue": "1",
						"foldChange": "0.9606"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".293534112697365",
						"qValue": "1",
						"foldChange": "1.1605"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".858831133641347",
						"qValue": "1",
						"foldChange": "0.9817"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".055200423574263",
						"qValue": "1",
						"foldChange": "1.2188"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".072007729043337",
						"qValue": ".822195137696456",
						"foldChange": "1.2415"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".106508285350276",
						"qValue": "1",
						"foldChange": "0.8307"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".134699533779208",
						"qValue": "1",
						"foldChange": "1.1453"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".002770154068118",
						"qValue": ".589350277992006",
						"foldChange": "1.3786"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".135422986549603",
						"qValue": ".102378280720937",
						"foldChange": "1.4449"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".829348992125816",
						"qValue": ".29610885400617",
						"foldChange": "1.0690"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01288804784863",
						"qValue": ".006641306925408",
						"foldChange": "1.3963"
					}
				]
			},
			{
				"metaboliteId": "0078ddaf-0000-0000-0000-000000000000",
				"chemicalId": "000001f8-0000-0000-0000-000000000000",
				"chemicalName": "serotonin",
				"plotName": null,
				"compoundId": "00000926-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00259",
				"inChiKey": "QZAYGJVTTNCVMB-UHFFFAOYAX",
				"kegg": "C00780",
				"mass": 176.094963,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".718660878421511",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".728611239747255",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".947984968256086",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".71166319111722",
						"qValue": "1",
						"foldChange": "14.3153"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".310012521679012",
						"qValue": "1",
						"foldChange": "0.7690"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".516088100131269",
						"qValue": "1",
						"foldChange": "7.0424"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".67323342720949",
						"qValue": "1",
						"foldChange": "8.2504"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".977504507933789",
						"qValue": "1",
						"foldChange": "11.2539"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".652834485527707",
						"qValue": ".973034272612281",
						"foldChange": "1.5979"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".912320332004684",
						"qValue": "1",
						"foldChange": "1.3031"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".858126400394511",
						"qValue": "1",
						"foldChange": "1.9408"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".945253325796217",
						"qValue": "1",
						"foldChange": "2.0377"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".297807838348893",
						"qValue": "1",
						"foldChange": "0.5059"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".437063009817464",
						"qValue": "1",
						"foldChange": "0.4124"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".829683237018607",
						"qValue": "1",
						"foldChange": "0.8151"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".672524540920282",
						"qValue": "1",
						"foldChange": "0.5984"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".558704939160169",
						"qValue": "1",
						"foldChange": "0.3510"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".849182316706789",
						"qValue": "1",
						"foldChange": "0.5867"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".808749801278738",
						"qValue": "1",
						"foldChange": "1.2877"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".885902379913371",
						"qValue": "1",
						"foldChange": "0.9320"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".930271120038418",
						"qValue": ".997573179629986",
						"foldChange": "0.7238"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".090173671426818",
						"qValue": ".076727176155984",
						"foldChange": "6.9631"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".590821556316516",
						"qValue": ".233685799108455",
						"foldChange": "1.8449"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".438615685165293",
						"qValue": ".099999344361132",
						"foldChange": "2.1830"
					}
				]
			},
			{
				"metaboliteId": "0078ddb0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e10b-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetate",
				"plotName": null,
				"compoundId": "00003e56-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00209",
				"inChiKey": "WLJVXDMOQOGPHL-UHFFFAOYAR",
				"kegg": "C07086",
				"mass": 136.05243,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 145,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".797233133440747",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".159052265313633",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".686087065159219",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".351541199443538",
						"qValue": "1",
						"foldChange": "0.8834"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".449993807823139",
						"qValue": "1",
						"foldChange": "1.4163"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".095147852249095",
						"qValue": "1",
						"foldChange": "2.0257"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".3817755027482",
						"qValue": ".958380391854625",
						"foldChange": "1.2949"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".813009695113723",
						"qValue": ".991417013240296",
						"foldChange": "1.6631"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".522067946771546",
						"qValue": ".957839854315834",
						"foldChange": "1.6403"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".171371126051423",
						"qValue": "1",
						"foldChange": "0.8260"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".22654559286253",
						"qValue": "1",
						"foldChange": "0.8222"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".870764440810537",
						"qValue": "1",
						"foldChange": "1.3522"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".872023880347734",
						"qValue": "1",
						"foldChange": "0.9327"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".840417895175056",
						"qValue": "1",
						"foldChange": "0.8200"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".959921715861878",
						"qValue": "1",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".805377406359154",
						"qValue": "1",
						"foldChange": "0.9317"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".830388314798671",
						"qValue": "1",
						"foldChange": "0.7379"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".647105439257165",
						"qValue": "1",
						"foldChange": "0.7920"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".513533049755016",
						"qValue": "1",
						"foldChange": "0.9018"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".162490592259376",
						"qValue": "1",
						"foldChange": "0.5812"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".419846943423301",
						"qValue": ".985830071188835",
						"foldChange": "0.6445"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".017396489207868",
						"qValue": ".022587505433464",
						"foldChange": "2.7124"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".033198174185539",
						"qValue": ".026832442685347",
						"foldChange": "2.2842"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".008216587528224",
						"qValue": ".004651512063501",
						"foldChange": "2.4516"
					}
				]
			},
			{
				"metaboliteId": "0078ddb1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e558-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxylaurate",
				"plotName": null,
				"compoundId": "00007ec9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00387",
				"inChiKey": "MUCMKTPAZLSKTL-UHFFFAOYAV",
				"kegg": null,
				"mass": 216.172545,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1750,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".526438730129047",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".588834379160725",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".551243486812202",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".767526954894043",
						"qValue": "1",
						"foldChange": "1.0909"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".651363670233371",
						"qValue": "1",
						"foldChange": "1.1532"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".4557186972195",
						"qValue": "1",
						"foldChange": "1.6511"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".701248465214596",
						"qValue": "1",
						"foldChange": "1.1098"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".101297265217918",
						"qValue": ".697458782960496",
						"foldChange": "0.9759"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".205053891320185",
						"qValue": ".957839854315834",
						"foldChange": "0.9745"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".836201448430494",
						"qValue": "1",
						"foldChange": "1.1191"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".572904612228034",
						"qValue": "1",
						"foldChange": "0.9382"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".441818352153477",
						"qValue": "1",
						"foldChange": "0.8631"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".82583890840505",
						"qValue": "1",
						"foldChange": "1.0781"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".776880989539308",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".615006625862983",
						"qValue": "1",
						"foldChange": "0.9913"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".787902175768082",
						"qValue": "1",
						"foldChange": "0.9735"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".528072697083153",
						"qValue": "1",
						"foldChange": "1.0129"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".365755944119287",
						"qValue": "1",
						"foldChange": "1.0405"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".099175001539263",
						"qValue": "1",
						"foldChange": "0.7343"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".668674531725577",
						"qValue": "1",
						"foldChange": "0.8091"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".240740877226103",
						"qValue": ".985830071188835",
						"foldChange": "1.1018"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".265806223280113",
						"qValue": ".164497083066889",
						"foldChange": "0.6982"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".302599296850865",
						"qValue": ".141813003429858",
						"foldChange": "0.9244"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".781110152619795",
						"qValue": ".157336322139938",
						"foldChange": "1.1099"
					}
				]
			},
			{
				"metaboliteId": "0078ddb2-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8cc-0000-0000-0000-000000000000",
				"chemicalName": "tyramine O-sulfate",
				"plotName": null,
				"compoundId": "0000bd18-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06409",
				"inChiKey": "DYDUXGMDSXJQFT-UHFFFAOYAH",
				"kegg": null,
				"mass": 217.040879,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 208,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".511022898677586",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".405915821477498",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".63484110710612",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".789421499501302",
						"qValue": "1",
						"foldChange": "1.3848"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".451235065952315",
						"qValue": "1",
						"foldChange": "1.4450"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".309050357672884",
						"qValue": "1",
						"foldChange": "1.7604"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".196798418225544",
						"qValue": ".797536225282113",
						"foldChange": "1.1206"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".14906094672902",
						"qValue": ".782571329595308",
						"foldChange": "0.8890"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".875516842521984",
						"qValue": "1",
						"foldChange": "1.1968"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".437805010325697",
						"qValue": "1",
						"foldChange": "1.1409"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".461977633920632",
						"qValue": "1",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".967555037124836",
						"qValue": "1",
						"foldChange": "1.4498"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".111727685479689",
						"qValue": "1",
						"foldChange": "1.6709"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".200519566548107",
						"qValue": "1",
						"foldChange": "1.5976"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".811904876065807",
						"qValue": "1",
						"foldChange": "0.9561"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".384061393849195",
						"qValue": "1",
						"foldChange": "1.4649"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".376056161947285",
						"qValue": "1",
						"foldChange": "1.6707"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".948979750500598",
						"qValue": "1",
						"foldChange": "1.1405"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".985802589944835",
						"qValue": "1",
						"foldChange": "1.0927"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".854314548708142",
						"qValue": "1",
						"foldChange": "0.7810"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".83732154534743",
						"qValue": ".997573179629986",
						"foldChange": "0.7147"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".046062669174854",
						"qValue": ".047070729444291",
						"foldChange": "1.4687"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".201580275087292",
						"qValue": ".104479446704571",
						"foldChange": "1.8627"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".378249622015719",
						"qValue": ".089038698811059",
						"foldChange": "1.2935"
					}
				]
			},
			{
				"metaboliteId": "0078ddb3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e729-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylcitrulline",
				"plotName": null,
				"compoundId": "0000bd32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00856",
				"inChiKey": "WMQMIOYQXNRROC-LURJTMIEBM",
				"kegg": "C15532",
				"mass": 217.106256,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 434,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".787776648345015",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".305400216093479",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".720193998074866",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".321206497494854",
						"qValue": "1",
						"foldChange": "0.9797"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".822439548778845",
						"qValue": "1",
						"foldChange": "1.1459"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".225442772865804",
						"qValue": "1",
						"foldChange": "1.8572"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".54686315415285",
						"qValue": "1",
						"foldChange": "0.9870"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".360320286437651",
						"qValue": ".868647489400683",
						"foldChange": "1.1168"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".752754936387843",
						"qValue": ".983361746128381",
						"foldChange": "1.2667"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".359134631616087",
						"qValue": "1",
						"foldChange": "0.8547"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".191455381680588",
						"qValue": "1",
						"foldChange": "0.8603"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".691607581004044",
						"qValue": "1",
						"foldChange": "0.9659"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".810133757950699",
						"qValue": "1",
						"foldChange": "0.8189"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".390934559431412",
						"qValue": "1",
						"foldChange": "1.4221"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".516519542339745",
						"qValue": "1",
						"foldChange": "1.7367"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".638107344120977",
						"qValue": "1",
						"foldChange": "1.0358"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".385322787766632",
						"qValue": "1",
						"foldChange": "1.3833"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".661968371111193",
						"qValue": "1",
						"foldChange": "1.3355"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".722488704765114",
						"qValue": "1",
						"foldChange": "0.8803"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".977256076181949",
						"qValue": "1",
						"foldChange": "0.9046"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".709042271827834",
						"qValue": ".996918325615539",
						"foldChange": "1.0276"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001083014529974",
						"qValue": ".002779658297023",
						"foldChange": "9.4618"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002113308993549",
						"qValue": ".003414803198587",
						"foldChange": "7.0877"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004321826514068",
						"qValue": ".002931838133002",
						"foldChange": "7.0413"
					}
				]
			},
			{
				"metaboliteId": "0078ddb4-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8d2-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxypyridine sulfate",
				"plotName": null,
				"compoundId": "0000bd40-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 174.993928,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".233914303367985",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".615497270530314",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".787463198907105",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".556527434052471",
						"qValue": "1",
						"foldChange": "1.5960"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".8089185949745",
						"qValue": "1",
						"foldChange": "2.1195"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".728620068305134",
						"qValue": "1",
						"foldChange": "1.3245"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".509092332904823",
						"qValue": "1",
						"foldChange": "1.3522"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".415448054115073",
						"qValue": ".88881677553957",
						"foldChange": "2.6363"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".143639417376151",
						"qValue": ".957839854315834",
						"foldChange": "2.9723"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".613506245670059",
						"qValue": "1",
						"foldChange": "1.3168"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".645729763440865",
						"qValue": "1",
						"foldChange": "1.3733"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".963677947291487",
						"qValue": "1",
						"foldChange": "0.9598"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".291879132850412",
						"qValue": "1",
						"foldChange": "1.0918"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".705975906329522",
						"qValue": "1",
						"foldChange": "0.8036"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".162666360572829",
						"qValue": "1",
						"foldChange": "0.7360"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".282737684981049",
						"qValue": "1",
						"foldChange": "0.2114"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".347236028036884",
						"qValue": "1",
						"foldChange": "1.2262"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".049720806711415",
						"qValue": ".822195137696456",
						"foldChange": "5.8010"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".604224263565116",
						"qValue": "1",
						"foldChange": "0.8361"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".457290249369397",
						"qValue": "1",
						"foldChange": "1.2081"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".210138873638781",
						"qValue": ".985830071188835",
						"foldChange": "1.4450"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000048900916157",
						"qValue": ".000325158627929",
						"foldChange": "45.5370"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".096473227154238",
						"qValue": ".059384582923626",
						"foldChange": "1.5602"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004311935728152",
						"qValue": ".002931838133002",
						"foldChange": "27.5124"
					}
				]
			},
			{
				"metaboliteId": "0078ddb5-0000-0000-0000-000000000000",
				"chemicalId": "05f5f854-0000-0000-0000-000000000000",
				"chemicalName": "alpha-CEHC sulfate",
				"plotName": null,
				"compoundId": "0000ba32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 358.108624,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3979,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".783071695847481",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".010408362228273",
						"qValue": ".576507991669672",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".424151236390405",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".040061923212689",
						"qValue": "1",
						"foldChange": "0.7077"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".962931872688622",
						"qValue": "1",
						"foldChange": "1.0522"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".044478416884608",
						"qValue": "1",
						"foldChange": "1.7606"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".14572207905727",
						"qValue": ".742487134132407",
						"foldChange": "0.9741"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".485508291140063",
						"qValue": ".902562957068497",
						"foldChange": "2.0710"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".034057985169955",
						"qValue": ".630072725644175",
						"foldChange": "2.3152"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".110794663917714",
						"qValue": "1",
						"foldChange": "0.7211"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".107764563183688",
						"qValue": "1",
						"foldChange": "0.7568"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".988626647708448",
						"qValue": "1",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".403748594613945",
						"qValue": "1",
						"foldChange": "0.7641"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".837357203542774",
						"qValue": "1",
						"foldChange": "1.7806"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".54752142328498",
						"qValue": "1",
						"foldChange": "2.3303"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".564495062213161",
						"qValue": "1",
						"foldChange": "0.6228"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".944492153627837",
						"qValue": "1",
						"foldChange": "1.4581"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".625974909128329",
						"qValue": "1",
						"foldChange": "2.3413"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".566010338847553",
						"qValue": "1",
						"foldChange": "0.6385"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".427287465943427",
						"qValue": "1",
						"foldChange": "1.2224"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".794838478077494",
						"qValue": ".997573179629986",
						"foldChange": "1.9145"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".596786283203458",
						"qValue": ".288874741147588",
						"foldChange": "3.4652"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".086157556594912",
						"qValue": ".054890080963378",
						"foldChange": "1.8760"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000888736542406",
						"qValue": ".000978681068437",
						"foldChange": "7.0481"
					}
				]
			},
			{
				"metaboliteId": "0078ddb6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f65a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltaurine",
				"plotName": null,
				"compoundId": "0000bc3b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CXJAAWRLVGAKDV-UHFFFAOYAS",
				"kegg": null,
				"mass": 167.025228,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 408,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".338240517465545",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".53272928861872",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".971235543224025",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".790136121803409",
						"qValue": "1",
						"foldChange": "1.0651"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".591621310585655",
						"qValue": "1",
						"foldChange": "0.9370"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".78641964279625",
						"qValue": "1",
						"foldChange": "1.0768"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".537726193313263",
						"qValue": "1",
						"foldChange": "1.1842"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".787831778916439",
						"qValue": ".978751596872832",
						"foldChange": "1.1415"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".377189831886978",
						"qValue": ".957839854315834",
						"foldChange": "0.9644"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".904115395943146",
						"qValue": "1",
						"foldChange": "0.9856"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".454333190841109",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".529587634840595",
						"qValue": "1",
						"foldChange": "0.8862"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".481731190504121",
						"qValue": "1",
						"foldChange": "1.1034"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".915506205348313",
						"qValue": "1",
						"foldChange": "0.8945"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".4299083356563",
						"qValue": "1",
						"foldChange": "0.8106"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".187436051463884",
						"qValue": "1",
						"foldChange": "1.2825"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".991944318145005",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".197867021847845",
						"qValue": "1",
						"foldChange": "0.7712"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".363797583740258",
						"qValue": "1",
						"foldChange": "1.2807"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".775393813390338",
						"qValue": "1",
						"foldChange": "0.9068"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".242981497030068",
						"qValue": ".985830071188835",
						"foldChange": "0.7080"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".006305914308674",
						"qValue": ".010874108815054",
						"foldChange": "2.0256"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".005781968839573",
						"qValue": ".007040530280026",
						"foldChange": "2.1475"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".001154811539121",
						"qValue": ".001142136931488",
						"foldChange": "1.8603"
					}
				]
			},
			{
				"metaboliteId": "0078ddb7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e803-0000-0000-0000-000000000000",
				"chemicalName": "2-methoxyacetaminophen glucuronide*",
				"plotName": null,
				"compoundId": "00008189-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 357.105981,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4778,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".312278973543294",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".442637759515421",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".371216193273366",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".427675833425374",
						"qValue": "1",
						"foldChange": "3.2180"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".225486958229095",
						"qValue": "1",
						"foldChange": "0.9246"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".048149253651249",
						"qValue": "1",
						"foldChange": "0.8912"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".783542815489143",
						"qValue": "1",
						"foldChange": "1.9227"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".64741757350948",
						"qValue": ".930662761919878",
						"foldChange": "0.9223"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".465013705645951",
						"qValue": ".957839854315834",
						"foldChange": "0.9091"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".245556613156722",
						"qValue": "1",
						"foldChange": "0.8791"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".735209790893185",
						"qValue": "1",
						"foldChange": "0.9214"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".407622155086017",
						"qValue": "1",
						"foldChange": "3.6415"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".174659983409431",
						"qValue": "1",
						"foldChange": "0.1577"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".611508942445608",
						"qValue": "1",
						"foldChange": "1.6835"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".420789442426688",
						"qValue": "1",
						"foldChange": "10.6744"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".089746898921268",
						"qValue": "1",
						"foldChange": "0.1436"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".080234771609818",
						"qValue": "1",
						"foldChange": "0.0919"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".878416823180027",
						"qValue": "1",
						"foldChange": "0.6400"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".396231599717971",
						"qValue": "1",
						"foldChange": "0.3146"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".975732116548949",
						"qValue": "1",
						"foldChange": "2.1502"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".39379155445426",
						"qValue": ".985830071188835",
						"foldChange": "6.8342"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".175288936257805",
						"qValue": ".120909411301099",
						"foldChange": "6.8342"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".217430745462224",
						"qValue": ".058709471656433",
						"foldChange": "3.1784"
					}
				]
			},
			{
				"metaboliteId": "0078ddb8-0000-0000-0000-000000000000",
				"chemicalId": "000000f4-0000-0000-0000-000000000000",
				"chemicalName": "beta-alanine",
				"plotName": null,
				"compoundId": "00000037-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00056",
				"inChiKey": "UCMIRNVEIXFBKS-UHFFFAOYAL",
				"kegg": "C00099",
				"mass": 89.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3857,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".913089103143196",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".504257017189342",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".67422882614492",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".118301722839557",
						"qValue": "1",
						"foldChange": "0.9084"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".466621137396012",
						"qValue": "1",
						"foldChange": "0.9612"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".395918863496796",
						"qValue": "1",
						"foldChange": "1.0993"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".981581165007902",
						"qValue": "1",
						"foldChange": "1.0359"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".632469111500072",
						"qValue": ".928868848501546",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".616192348679072",
						"qValue": ".967408831530889",
						"foldChange": "0.9893"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".917519111282564",
						"qValue": "1",
						"foldChange": "1.0311"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".462060550699319",
						"qValue": "1",
						"foldChange": "0.9439"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".40202130282136",
						"qValue": "1",
						"foldChange": "0.9251"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".50811298441117",
						"qValue": "1",
						"foldChange": "0.8770"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".806762670012284",
						"qValue": "1",
						"foldChange": "0.9008"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".695225934290322",
						"qValue": "1",
						"foldChange": "1.0271"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".746964905617181",
						"qValue": "1",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".461311211874278",
						"qValue": "1",
						"foldChange": "1.1007"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".657051581058131",
						"qValue": "1",
						"foldChange": "1.0762"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".621817989378588",
						"qValue": "1",
						"foldChange": "0.9125"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".783344271689381",
						"qValue": "1",
						"foldChange": "0.9114"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".844065525220238",
						"qValue": ".997573179629986",
						"foldChange": "0.9988"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".012474911787807",
						"qValue": ".017957938743609",
						"foldChange": "1.3336"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".119023071668532",
						"qValue": ".070019324804105",
						"foldChange": "1.1873"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".305611781014999",
						"qValue": ".07653429207639",
						"foldChange": "1.1997"
					}
				]
			},
			{
				"metaboliteId": "0078ddb9-0000-0000-0000-000000000000",
				"chemicalId": "0000018d-0000-0000-0000-000000000000",
				"chemicalName": "leucine",
				"plotName": null,
				"compoundId": "0000003c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 131.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".721524922310009",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".463636712674266",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".676479944338814",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".283259727475615",
						"qValue": "1",
						"foldChange": "0.9701"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".99404441776518",
						"qValue": "1",
						"foldChange": "1.0039"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".28656184163889",
						"qValue": "1",
						"foldChange": "1.0442"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".439494905988519",
						"qValue": "1",
						"foldChange": "1.0312"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".451132948487039",
						"qValue": ".901759479402622",
						"foldChange": "1.0328"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".984283637196472",
						"qValue": "1",
						"foldChange": "1.0077"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".323503693585206",
						"qValue": "1",
						"foldChange": "0.9633"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".983232157910936",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".313467177478064",
						"qValue": "1",
						"foldChange": "0.9862"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".713776181338038",
						"qValue": "1",
						"foldChange": "0.9620"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".517928515722689",
						"qValue": "1",
						"foldChange": "1.0643"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".31169126459164",
						"qValue": "1",
						"foldChange": "1.1063"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".675056299037207",
						"qValue": "1",
						"foldChange": "1.0264"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".51086487928501",
						"qValue": "1",
						"foldChange": "1.0184"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".787839808157202",
						"qValue": "1",
						"foldChange": "0.9922"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".957657273871252",
						"qValue": "1",
						"foldChange": "0.9796"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".510144290875633",
						"qValue": "1",
						"foldChange": "0.9878"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".468225005612034",
						"qValue": ".985830071188835",
						"foldChange": "1.0084"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".879752566805036",
						"qValue": ".366387165901439",
						"foldChange": "1.0327"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".050429151772547",
						"qValue": ".037699703767353",
						"foldChange": "1.1057"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".71531042480138",
						"qValue": ".147441913122787",
						"foldChange": "0.9803"
					}
				]
			},
			{
				"metaboliteId": "0078ddba-0000-0000-0000-000000000000",
				"chemicalId": "000000ea-0000-0000-0000-000000000000",
				"chemicalName": "aspartate",
				"plotName": null,
				"compoundId": "000001bb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00191",
				"inChiKey": "CKLJMWTZIZZHCS-REOHCLBHBW",
				"kegg": "C00049",
				"mass": 133.037509,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 35,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".094388273998409",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".949268432214363",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".958768437454731",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".656368055135449",
						"qValue": "1",
						"foldChange": "1.7942"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".74826871494763",
						"qValue": "1",
						"foldChange": "0.9280"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".901086089800265",
						"qValue": "1",
						"foldChange": "2.4559"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".897447157678778",
						"qValue": "1",
						"foldChange": "1.4827"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".692592907648127",
						"qValue": ".949108799369656",
						"foldChange": "1.4160"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".600413642312882",
						"qValue": ".964060395487288",
						"foldChange": "1.4905"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".843214296230582",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".788255471498062",
						"qValue": "1",
						"foldChange": "1.2959"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".641236407607417",
						"qValue": "1",
						"foldChange": "1.3392"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".527673290791352",
						"qValue": "1",
						"foldChange": "0.7646"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".567903957770832",
						"qValue": "1",
						"foldChange": "1.0089"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".233101728773384",
						"qValue": "1",
						"foldChange": "1.3194"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".960404279540323",
						"qValue": "1",
						"foldChange": "0.7353"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".424468594194111",
						"qValue": "1",
						"foldChange": "0.9354"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".386588696771284",
						"qValue": "1",
						"foldChange": "1.2722"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".492072591617698",
						"qValue": "1",
						"foldChange": "1.0019"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".248195389910892",
						"qValue": "1",
						"foldChange": "1.4720"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".066760203807378",
						"qValue": ".985830071188835",
						"foldChange": "1.4692"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000139755137",
						"qValue": ".000015537435463",
						"foldChange": "13.4515"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000674578513354",
						"qValue": ".001808690555465",
						"foldChange": "6.0764"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000003200945437",
						"qValue": ".000030272644131",
						"foldChange": "7.0458"
					}
				]
			},
			{
				"metaboliteId": "0078ddbb-0000-0000-0000-000000000000",
				"chemicalId": "0000032f-0000-0000-0000-000000000000",
				"chemicalName": "tyrosine",
				"plotName": null,
				"compoundId": "00000513-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00158",
				"inChiKey": "OUYCCCASQSFEME-QMMMGPOBBZ",
				"kegg": "C00082",
				"mass": 181.073894,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".779020044126682",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".601061488298649",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".863385461142711",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".460406438272441",
						"qValue": "1",
						"foldChange": "0.9421"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".978375603856399",
						"qValue": "1",
						"foldChange": "1.0089"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".476901084715213",
						"qValue": "1",
						"foldChange": "1.1070"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".363964745886522",
						"qValue": ".951252030575746",
						"foldChange": "0.9678"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".259506962985622",
						"qValue": ".825038702902081",
						"foldChange": "0.9378"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".823309149122668",
						"qValue": "1",
						"foldChange": "1.0372"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".93176336750388",
						"qValue": "1",
						"foldChange": "1.0452"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".691356216140739",
						"qValue": "1",
						"foldChange": "0.9890"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".629533411182229",
						"qValue": "1",
						"foldChange": "0.9340"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".428778853921326",
						"qValue": "1",
						"foldChange": "1.0794"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".70914896973354",
						"qValue": "1",
						"foldChange": "1.0358"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".699349122083451",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".48560958792631",
						"qValue": "1",
						"foldChange": "1.0957"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".334073514640218",
						"qValue": "1",
						"foldChange": "1.1462"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".751357305883154",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".996869226412042",
						"qValue": "1",
						"foldChange": "0.9693"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".928347952009013",
						"qValue": "1",
						"foldChange": "0.9199"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".923586263992165",
						"qValue": ".997573179629986",
						"foldChange": "0.9490"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".953636709003866",
						"qValue": ".386375057388733",
						"foldChange": "1.0188"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".003247150761699",
						"qValue": ".004836924167927",
						"foldChange": "1.3526"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".489737923261557",
						"qValue": ".10935842442213",
						"foldChange": "1.1367"
					}
				]
			},
			{
				"metaboliteId": "0078ddbc-0000-0000-0000-000000000000",
				"chemicalId": "0000019f-0000-0000-0000-000000000000",
				"chemicalName": "methionine",
				"plotName": null,
				"compoundId": "00000516-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00696",
				"inChiKey": "FFEARJCKVFRZRR-BYPYZUCNBW",
				"kegg": "C00073",
				"mass": 149.051051,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".964248577235412",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".865566877763125",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".68443165278011",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".914237000348415",
						"qValue": "1",
						"foldChange": "1.0119"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".367389812340962",
						"qValue": "1",
						"foldChange": "1.0576"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".426679649588729",
						"qValue": "1",
						"foldChange": "1.0466"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".887133219889145",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".719057196138477",
						"qValue": ".950986052805043",
						"foldChange": "1.0003"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".827458930528254",
						"qValue": "1",
						"foldChange": "0.9949"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".409949177452043",
						"qValue": "1",
						"foldChange": "0.9678"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".257630538143746",
						"qValue": "1",
						"foldChange": "0.9481"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".754598549772787",
						"qValue": "1",
						"foldChange": "0.9238"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".599134701359218",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".520386089903302",
						"qValue": "1",
						"foldChange": "1.0228"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".880732258447107",
						"qValue": "1",
						"foldChange": "0.9929"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".711977572990279",
						"qValue": "1",
						"foldChange": "1.0268"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".967237210979471",
						"qValue": "1",
						"foldChange": "0.9873"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".752265600503141",
						"qValue": "1",
						"foldChange": "0.9615"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".778679471799269",
						"qValue": "1",
						"foldChange": "0.9755"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".516309650048914",
						"qValue": "1",
						"foldChange": "0.8817"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".693695759064145",
						"qValue": ".992710494680197",
						"foldChange": "0.9038"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".851027360765735",
						"qValue": ".359396876862354",
						"foldChange": "1.0770"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000199554593838",
						"qValue": ".000869077862093",
						"foldChange": "1.2833"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".116377349742958",
						"qValue": ".036687599157154",
						"foldChange": "1.1102"
					}
				]
			},
			{
				"metaboliteId": "0078ddbd-0000-0000-0000-000000000000",
				"chemicalId": "000000d4-0000-0000-0000-000000000000",
				"chemicalName": "5-methylthioadenosine (MTA)",
				"plotName": null,
				"compoundId": "0000058b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01173",
				"inChiKey": "WUUGFSXJNOTRMR-IOSLPCCCBD",
				"kegg": "C00170",
				"mass": 297.08956,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 480,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".568398661444861",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".294025263632207",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".096183025728386",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".084511981369322",
						"qValue": "1",
						"foldChange": "0.8571"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".921370640175228",
						"qValue": "1",
						"foldChange": "1.0195"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".06890541928434",
						"qValue": "1",
						"foldChange": "1.3295"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".904441377112254",
						"qValue": "1",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".043245975576973",
						"qValue": ".678234398444917",
						"foldChange": "0.8910"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".032882811348433",
						"qValue": ".621850499055917",
						"foldChange": "0.8513"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".815091961484816",
						"qValue": "1",
						"foldChange": "0.9925"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".448772323797818",
						"qValue": "1",
						"foldChange": "0.9387"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".599540466874026",
						"qValue": "1",
						"foldChange": "0.8566"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".727298125141949",
						"qValue": "1",
						"foldChange": "0.9377"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".769031189237916",
						"qValue": "1",
						"foldChange": "1.0146"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".523682113752652",
						"qValue": "1",
						"foldChange": "1.0820"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".270146774534352",
						"qValue": "1",
						"foldChange": "1.1155"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".163882853609956",
						"qValue": "1",
						"foldChange": "1.1811"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".715316780138305",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".054517519292104",
						"qValue": "1",
						"foldChange": "0.7819"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".708255353837005",
						"qValue": "1",
						"foldChange": "0.8394"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".134671262734676",
						"qValue": ".985830071188835",
						"foldChange": "1.0735"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".061957170007086",
						"qValue": ".057784326780781",
						"foldChange": "1.6890"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".0281226371312",
						"qValue": ".023620172534915",
						"foldChange": "1.6724"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".0007995521642",
						"qValue": ".000911693159298",
						"foldChange": "1.8882"
					}
				]
			},
			{
				"metaboliteId": "0078ddbe-0000-0000-0000-000000000000",
				"chemicalId": "05f5e677-0000-0000-0000-000000000000",
				"chemicalName": "1,7-dimethylurate",
				"plotName": null,
				"compoundId": "00008660-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11103",
				"inChiKey": "NOFNCLGCUJJPKU-UHFFFAOYAG",
				"kegg": "C16356",
				"mass": 196.05964,
				"pathwayType": "ANIMAL",
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4162,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".101566724156111",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".170860384785506",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".999133898758633",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".442294197870557",
						"qValue": "1",
						"foldChange": "1.3650"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".360262754788112",
						"qValue": "1",
						"foldChange": "0.9808"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".882207722680457",
						"qValue": "1",
						"foldChange": "2.0495"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".455948040556527",
						"qValue": "1",
						"foldChange": "1.4742"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".26382072332839",
						"qValue": ".825038702902081",
						"foldChange": "1.4061"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".70633932991175",
						"qValue": ".976023509428735",
						"foldChange": "1.1491"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".491054685265201",
						"qValue": "1",
						"foldChange": "0.9737"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".239652577038151",
						"qValue": "1",
						"foldChange": "0.7065"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".621683917138898",
						"qValue": "1",
						"foldChange": "0.9736"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".663386505735539",
						"qValue": "1",
						"foldChange": "1.6092"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".056364142035774",
						"qValue": "1",
						"foldChange": "2.3046"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".119504071949234",
						"qValue": "1",
						"foldChange": "1.4321"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".639066863893971",
						"qValue": "1",
						"foldChange": "1.1758"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".054002258590272",
						"qValue": "1",
						"foldChange": "1.9034"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".122293524931489",
						"qValue": ".929212408184793",
						"foldChange": "1.6189"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".72360103853531",
						"qValue": "1",
						"foldChange": "1.9177"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".081176632762898",
						"qValue": "1",
						"foldChange": "2.0771"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".144201711543429",
						"qValue": ".985830071188835",
						"foldChange": "1.0831"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000000187712196",
						"qValue": ".000015537435463",
						"foldChange": "34.5311"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000345997024579",
						"qValue": ".001151023864247",
						"foldChange": "33.9269"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000301371542641",
						"qValue": ".000440484429854",
						"foldChange": "9.7365"
					}
				]
			},
			{
				"metaboliteId": "0078ddbf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74b-0000-0000-0000-000000000000",
				"chemicalName": "3beta,7alpha-dihydroxy-5-cholestenoate",
				"plotName": null,
				"compoundId": "00008fc3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GYJSAWZGYQXRBS-GRJZKGIBBR",
				"kegg": "C17335",
				"mass": 432.32396,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2893,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".570244811303202",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".004343361899646",
						"qValue": ".369620097659866",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".151528128707045",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".15969945437214",
						"qValue": "1",
						"foldChange": "0.9244"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".035684494831606",
						"qValue": "1",
						"foldChange": "0.8878"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".46703985118993",
						"qValue": "1",
						"foldChange": "0.9814"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".334236960957947",
						"qValue": ".951252030575746",
						"foldChange": "1.0655"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".400980973856669",
						"qValue": ".882161593933088",
						"foldChange": "0.9615"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".074561520208444",
						"qValue": ".794743555017874",
						"foldChange": "0.9052"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".013718737923926",
						"qValue": ".712854677951913",
						"foldChange": "0.8749"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".005164901450137",
						"qValue": ".522751459721039",
						"foldChange": "0.8449"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".711450628728369",
						"qValue": "1",
						"foldChange": "0.9248"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".63216991180395",
						"qValue": "1",
						"foldChange": "0.9509"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".230954283684391",
						"qValue": "1",
						"foldChange": "1.2083"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".095353294209264",
						"qValue": "1",
						"foldChange": "1.2707"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".565053905001058",
						"qValue": "1",
						"foldChange": "1.0950"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".506745297626425",
						"qValue": "1",
						"foldChange": "1.1659"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".901537219738942",
						"qValue": "1",
						"foldChange": "1.0647"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".896772518530098",
						"qValue": "1",
						"foldChange": "1.0198"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".42041873453088",
						"qValue": "1",
						"foldChange": "1.0990"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".483169370840635",
						"qValue": ".985830071188835",
						"foldChange": "1.0777"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".610800007945421",
						"qValue": ".29139788954717",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".143060153584605",
						"qValue": ".08058952465983",
						"foldChange": "0.7519"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".018398931287094",
						"qValue": ".008751790296458",
						"foldChange": "0.7662"
					}
				]
			},
			{
				"metaboliteId": "0078ddc0-0000-0000-0000-000000000000",
				"chemicalId": "05f5eba5-0000-0000-0000-000000000000",
				"chemicalName": "omeprazole",
				"plotName": null,
				"compoundId": "000096c8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01913",
				"inChiKey": "SUBDBMMJDZJVOS-UHFFFAOYAZ",
				"kegg": "C07324",
				"mass": 345.114712,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Gastrointestinal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5010,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".444216931193145",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".458150933086409",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".506982921339933",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".057013268516987",
						"qValue": ".533236366427116",
						"foldChange": "0.9077"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".923720616702655",
						"qValue": "1",
						"foldChange": "1.0135"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".046216294208408",
						"qValue": ".679210111549633",
						"foldChange": "2.3691"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".185598278522255",
						"qValue": "1",
						"foldChange": "2.1946"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".199517562422196",
						"qValue": "1",
						"foldChange": "0.4557"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".165205986590289",
						"qValue": "1",
						"foldChange": "2.3691"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".999999999999998",
						"qValue": "1",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".178596917156489",
						"qValue": ".985830071188835",
						"foldChange": "0.5077"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".346593507087333",
						"qValue": ".192863611674612",
						"foldChange": "1.2028"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".343436396137913",
						"qValue": ".152009550549949",
						"foldChange": "2.3691"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".346593507087335",
						"qValue": ".083280304277061",
						"foldChange": "0.4678"
					}
				]
			},
			{
				"metaboliteId": "0078ddc1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f3-0000-0000-0000-000000000000",
				"chemicalName": "piperine",
				"plotName": null,
				"compoundId": "0000848f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29377",
				"inChiKey": "MXXWOMGUGJBKIW-YPCIICBEBY",
				"kegg": "C03882",
				"mass": 285.136493,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".292269840883787",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".027481295630584",
						"qValue": ".912593056097607",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".041806666341356",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".451443283030128",
						"qValue": "1",
						"foldChange": "1.9354"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".901243677692606",
						"qValue": "1",
						"foldChange": "1.9126"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".38112845116196",
						"qValue": "1",
						"foldChange": "0.8746"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".000167753892399",
						"qValue": ".035689640607789",
						"foldChange": "13.7708"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".00138323144473",
						"qValue": ".173657990763309",
						"foldChange": "3.7197"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".495418762967028",
						"qValue": ".957839854315834",
						"foldChange": "1.1492"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".884686532268321",
						"qValue": "1",
						"foldChange": "1.1120"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".951876629738607",
						"qValue": "1",
						"foldChange": "1.1086"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".837296477550347",
						"qValue": "1",
						"foldChange": "1.0245"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".033297514791607",
						"qValue": "1",
						"foldChange": "0.4912"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".296851244853327",
						"qValue": "1",
						"foldChange": "0.6201"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".305473573711426",
						"qValue": "1",
						"foldChange": "1.2623"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".699535797962899",
						"qValue": "1",
						"foldChange": "1.3209"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".132899411698163",
						"qValue": "1",
						"foldChange": "0.6999"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".057626822265298",
						"qValue": ".822195137696456",
						"foldChange": "0.5299"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".564531593981858",
						"qValue": "1",
						"foldChange": "1.0485"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".318796715964034",
						"qValue": "1",
						"foldChange": "0.5995"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".116978101583227",
						"qValue": ".985830071188835",
						"foldChange": "0.5718"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".224102235155815",
						"qValue": ".144354360669133",
						"foldChange": "1.2656"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".038439017695677",
						"qValue": ".030193192361146",
						"foldChange": "2.0584"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".164190447138382",
						"qValue": ".048063078602301",
						"foldChange": "1.7050"
					}
				]
			},
			{
				"metaboliteId": "0078ddc2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5df-0000-0000-0000-000000000000",
				"chemicalName": "octanoylcarnitine (C8)",
				"plotName": null,
				"compoundId": "00008490-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00791",
				"inChiKey": "CXTATJFJDMJMIY-UHFFFAOYAP",
				"kegg": "C02838",
				"mass": 287.209658,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1641,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".315175484575672",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".726677251990537",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".291253579392561",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".761507527671613",
						"qValue": "1",
						"foldChange": "1.0299"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".370573698259658",
						"qValue": "1",
						"foldChange": "1.1849"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".232541496821862",
						"qValue": "1",
						"foldChange": "1.3560"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".90967922561544",
						"qValue": "1",
						"foldChange": "1.1208"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".641803622701421",
						"qValue": ".928868848501546",
						"foldChange": "1.1551"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".563168275642059",
						"qValue": ".957839854315834",
						"foldChange": "1.0589"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".298419755943454",
						"qValue": "1",
						"foldChange": "1.3011"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".395252909254577",
						"qValue": "1",
						"foldChange": "0.9007"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".062244100323473",
						"qValue": ".727024127920748",
						"foldChange": "0.7764"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".716346928825777",
						"qValue": "1",
						"foldChange": "1.0099"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".376419521967618",
						"qValue": "1",
						"foldChange": "1.5406"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".21132429055428",
						"qValue": "1",
						"foldChange": "1.5256"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".902235307624986",
						"qValue": "1",
						"foldChange": "0.9643"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".100722408370315",
						"qValue": "1",
						"foldChange": "1.7255"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".073138611323443",
						"qValue": ".822195137696456",
						"foldChange": "1.7893"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".254744756389177",
						"qValue": "1",
						"foldChange": "0.8157"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".912555958831294",
						"qValue": "1",
						"foldChange": "1.1778"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".320354679296303",
						"qValue": ".985830071188835",
						"foldChange": "1.4439"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".307508414942759",
						"qValue": ".181809183403729",
						"foldChange": "1.7285"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".085187255273559",
						"qValue": ".054482025160167",
						"foldChange": "1.6731"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000472226992052",
						"qValue": ".000599366600162",
						"foldChange": "1.7936"
					}
				]
			},
			{
				"metaboliteId": "0078ddc3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f7-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPC (18:0)",
				"plotName": null,
				"compoundId": "000084a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10384",
				"inChiKey": "IHNKQIMGVNPMTC-RUZDIDTEBJ",
				"kegg": null,
				"mass": 523.36379,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2326,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".413346575046988",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".358348327808042",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".072290083939179",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".151600475648684",
						"qValue": "1",
						"foldChange": "1.1372"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".01958966655952",
						"qValue": "1",
						"foldChange": "1.1950"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".342870263462818",
						"qValue": "1",
						"foldChange": "1.0727"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".898968522735836",
						"qValue": "1",
						"foldChange": "1.0161"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".473560071840303",
						"qValue": ".902562957068497",
						"foldChange": "1.0572"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".399529852054259",
						"qValue": ".957839854315834",
						"foldChange": "1.0751"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".039465902256079",
						"qValue": ".821720595261736",
						"foldChange": "0.8728"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".158026850431455",
						"qValue": "1",
						"foldChange": "0.9339"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".497932054559991",
						"qValue": "1",
						"foldChange": "0.9898"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".808075241821663",
						"qValue": "1",
						"foldChange": "0.9784"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".035709686275994",
						"qValue": "1",
						"foldChange": "1.2538"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".053340888415172",
						"qValue": "1",
						"foldChange": "1.2815"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".510883838629138",
						"qValue": "1",
						"foldChange": "0.9239"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".870553880181763",
						"qValue": "1",
						"foldChange": "1.0616"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".422461015350616",
						"qValue": "1",
						"foldChange": "1.1491"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".457317924236044",
						"qValue": "1",
						"foldChange": "0.8996"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".970750848493583",
						"qValue": "1",
						"foldChange": "0.9437"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".448884392379378",
						"qValue": ".985830071188835",
						"foldChange": "1.0490"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".286213261154958",
						"qValue": ".173557724656379",
						"foldChange": "1.1818"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".30747819776972",
						"qValue": ".143616752427177",
						"foldChange": "1.0797"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".004774223486604",
						"qValue": ".003132980315785",
						"foldChange": "1.4816"
					}
				]
			},
			{
				"metaboliteId": "0078ddc4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f8-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPC (18:1)",
				"plotName": null,
				"compoundId": "0000bc82-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02815",
				"inChiKey": "YAMUFBLWGFFICM-PTGWMXDIBX",
				"kegg": null,
				"mass": 521.34814,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844289848115437",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".920025324996972",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".049999133208085",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".120195320284859",
						"qValue": "1",
						"foldChange": "1.1242"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".014379638802836",
						"qValue": "1",
						"foldChange": "1.1861"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".342950571850033",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".185686390448568",
						"qValue": ".797133099236872",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".561995360064275",
						"qValue": ".926856688788175",
						"foldChange": "0.9724"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".451708984620895",
						"qValue": ".957839854315834",
						"foldChange": "1.0731"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".604044808036361",
						"qValue": "1",
						"foldChange": "0.9843"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".155399132912836",
						"qValue": "1",
						"foldChange": "0.9171"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".361462341107821",
						"qValue": "1",
						"foldChange": "0.8840"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".13212188266899",
						"qValue": "1",
						"foldChange": "1.0875"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".07615884336477",
						"qValue": "1",
						"foldChange": "1.1344"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".709998562927339",
						"qValue": "1",
						"foldChange": "1.0431"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".686699566855151",
						"qValue": "1",
						"foldChange": "0.9539"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".659197513383874",
						"qValue": "1",
						"foldChange": "1.0532"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".400942807532957",
						"qValue": "1",
						"foldChange": "1.1041"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".573115358339268",
						"qValue": "1",
						"foldChange": "0.9468"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".428531527084451",
						"qValue": "1",
						"foldChange": "0.8700"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".789444632526024",
						"qValue": ".997573179629986",
						"foldChange": "0.9190"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".149730002640006",
						"qValue": ".109677422563901",
						"foldChange": "1.2404"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".064769545362209",
						"qValue": ".044703706764271",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000307319232522",
						"qValue": ".000445130924337",
						"foldChange": "1.8349"
					}
				]
			},
			{
				"metaboliteId": "0078ddc5-0000-0000-0000-000000000000",
				"chemicalId": "00000158-0000-0000-0000-000000000000",
				"chemicalName": "guanidinoacetate",
				"plotName": null,
				"compoundId": "0000ab1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00128",
				"inChiKey": "BPMFZUMJYQTVII-UHFFFAOYAQ",
				"kegg": "C00581",
				"mass": 117.053827,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 455,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".516131457662955",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".663906496702625",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".834968248530568",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".880704232511708",
						"qValue": "1",
						"foldChange": "1.0848"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".990242667588207",
						"qValue": "1",
						"foldChange": "1.0437"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".890358007337727",
						"qValue": "1",
						"foldChange": "1.1116"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".43336771707586",
						"qValue": ".994846434063709",
						"foldChange": "0.9826"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".537880264904217",
						"qValue": ".910963185640849",
						"foldChange": "0.9882"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".866034305772503",
						"qValue": "1",
						"foldChange": "1.1114"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".803832111306186",
						"qValue": "1",
						"foldChange": "1.0812"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".364773199880793",
						"qValue": "1",
						"foldChange": "0.9351"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".249874837953793",
						"qValue": "1",
						"foldChange": "0.8692"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".891788081294476",
						"qValue": "1",
						"foldChange": "1.0522"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".752125729586093",
						"qValue": "1",
						"foldChange": "1.1036"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".649030708856639",
						"qValue": "1",
						"foldChange": "1.0488"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".465074998313597",
						"qValue": "1",
						"foldChange": "0.8735"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".480171122520922",
						"qValue": "1",
						"foldChange": "1.0549"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".154657502777703",
						"qValue": "1",
						"foldChange": "1.2076"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".478680787016007",
						"qValue": "1",
						"foldChange": "0.9018"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".558462436382144",
						"qValue": "1",
						"foldChange": "0.8839"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".930433221421303",
						"qValue": ".997573179629986",
						"foldChange": "0.9801"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000808460789792",
						"qValue": ".002268421697781",
						"foldChange": "1.8376"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000179097450469",
						"qValue": ".000805494946676",
						"foldChange": "1.8939"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000001964081195",
						"qValue": ".000021865067824",
						"foldChange": "2.5830"
					}
				]
			},
			{
				"metaboliteId": "0078ddc6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e704-0000-0000-0000-000000000000",
				"chemicalName": "pyroglutamine*",
				"plotName": null,
				"compoundId": "0000b491-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NPWMTBZSRRLQNJ-UHFFFAOYAH",
				"kegg": null,
				"mass": 128.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 52,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".572629340129112",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".927384868945523",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".563809397602576",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".345193548610097",
						"qValue": "1",
						"foldChange": "0.9543"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".510895822137454",
						"qValue": "1",
						"foldChange": "0.9721"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".772334309967735",
						"qValue": "1",
						"foldChange": "1.0271"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".154680382852155",
						"qValue": ".742487134132407",
						"foldChange": "1.1194"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".397941445768174",
						"qValue": ".882161593933088",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".555921417162903",
						"qValue": ".957839854315834",
						"foldChange": "0.9729"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".918782186327779",
						"qValue": "1",
						"foldChange": "1.0114"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".815035555209094",
						"qValue": "1",
						"foldChange": "1.0013"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".737039360017222",
						"qValue": "1",
						"foldChange": "0.9637"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".507216354621759",
						"qValue": "1",
						"foldChange": "1.1970"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".431081244109548",
						"qValue": "1",
						"foldChange": "1.2167"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".867905352409791",
						"qValue": "1",
						"foldChange": "1.0165"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".263260618142819",
						"qValue": "1",
						"foldChange": "1.3233"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".324997330907999",
						"qValue": "1",
						"foldChange": "1.2207"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".936824859289691",
						"qValue": "1",
						"foldChange": "0.9225"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".340691072041117",
						"qValue": "1",
						"foldChange": "1.3328"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".387515291108459",
						"qValue": "1",
						"foldChange": "1.2052"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".968482097136154",
						"qValue": "1",
						"foldChange": "0.9043"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000482632558051",
						"qValue": ".001551408724945",
						"foldChange": "3.1808"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".002414282478303",
						"qValue": ".003782101557081",
						"foldChange": "2.7171"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".002526574425515",
						"qValue": ".002020956864873",
						"foldChange": "2.7144"
					}
				]
			},
			{
				"metaboliteId": "0078ddc7-0000-0000-0000-000000000000",
				"chemicalId": "000000e9-0000-0000-0000-000000000000",
				"chemicalName": "ascorbate (Vitamin C)",
				"plotName": null,
				"compoundId": "00007e62-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00044",
				"inChiKey": "CIWBSHSKHKDKBQ-JLAZNSOCBT",
				"kegg": "C00072",
				"mass": 176.03209,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3956,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".59044706057347",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".399735122722272",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".887626605359121",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".787527678486841",
						"qValue": "1",
						"foldChange": "2.2594"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".870816792861895",
						"qValue": "1",
						"foldChange": "0.9574"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".9148086332424",
						"qValue": "1",
						"foldChange": "2.8170"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".223566939690817",
						"qValue": ".825038702902081",
						"foldChange": "5.5595"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".223566939690817",
						"qValue": ".957839854315834",
						"foldChange": "5.5595"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".398173046108632",
						"qValue": "1",
						"foldChange": "2.2196"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".398173046108633",
						"qValue": "1",
						"foldChange": "2.0841"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".286394874766337",
						"qValue": "1",
						"foldChange": "0.2588"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".313218686692995",
						"qValue": "1",
						"foldChange": "0.2588"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".414793777203638",
						"qValue": "1",
						"foldChange": "0.4225"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".440525697694202",
						"qValue": "1",
						"foldChange": "0.4225"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".999999999999999",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".861667161888019",
						"qValue": "1",
						"foldChange": "1.9041"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".944467208060379",
						"qValue": "1",
						"foldChange": "0.7138"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".810242538371781",
						"qValue": ".997573179629986",
						"foldChange": "0.3749"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000002857724942",
						"qValue": ".000072781987586",
						"foldChange": "0.0599"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000003595514526",
						"qValue": ".000119001103967",
						"foldChange": "0.1260"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000019961930354",
						"qValue": ".000097254476803",
						"foldChange": "0.0916"
					}
				]
			},
			{
				"metaboliteId": "0078ddc8-0000-0000-0000-000000000000",
				"chemicalId": "000004c2-0000-0000-0000-000000000000",
				"chemicalName": "acetoacetate",
				"plotName": null,
				"compoundId": "000084ab-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00060",
				"inChiKey": "WDJHALXBUFZDSR-UHFFFAOYAH",
				"kegg": "C00164",
				"mass": 102.031695,
				"pathwayType": "ANIMAL",
				"subPathway": "Ketone Bodies",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1708,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".844673069626939",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".861587644833602",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".861348295490803",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".499890168957335",
						"qValue": "1",
						"foldChange": "1.9150"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".884161310434515",
						"qValue": "1",
						"foldChange": "1.2482"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".596261274969467",
						"qValue": "1",
						"foldChange": "1.1211"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".455588891758676",
						"qValue": "1",
						"foldChange": "0.9852"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".503326867940049",
						"qValue": ".905562715892139",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".938247443000048",
						"qValue": "1",
						"foldChange": "1.2247"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".578851894488046",
						"qValue": "1",
						"foldChange": "1.8473"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".920376648590099",
						"qValue": "1",
						"foldChange": "1.7413"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".648749082036157",
						"qValue": "1",
						"foldChange": "0.9283"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".344232052569825",
						"qValue": "1",
						"foldChange": "0.8353"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".680581518392791",
						"qValue": "1",
						"foldChange": "0.6705"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".618559916261099",
						"qValue": "1",
						"foldChange": "0.8027"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".978753476274826",
						"qValue": "1",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".723269295150843",
						"qValue": "1",
						"foldChange": "1.1347"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".697678945322592",
						"qValue": "1",
						"foldChange": "0.9665"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".690028186261488",
						"qValue": "1",
						"foldChange": "0.9922"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".700023867710781",
						"qValue": "1",
						"foldChange": "1.0612"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".993330001192866",
						"qValue": "1",
						"foldChange": "1.0695"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".267587757909927",
						"qValue": ".164841896302784",
						"foldChange": "0.5106"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".375596104467457",
						"qValue": ".162629871218394",
						"foldChange": "0.6893"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".293576388078355",
						"qValue": ".074447971815379",
						"foldChange": "0.6250"
					}
				]
			},
			{
				"metaboliteId": "0078ddc9-0000-0000-0000-000000000000",
				"chemicalId": "0000010b-0000-0000-0000-000000000000",
				"chemicalName": "choline phosphate",
				"plotName": null,
				"compoundId": "0000865c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01565",
				"inChiKey": "YHHSONZFOIEMCP-UHFFFAOYAD",
				"kegg": "C00588",
				"mass": 183.066044,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1975,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".655131366317026",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".806032492161582",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".911301597897416",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".300787024094048",
						"qValue": "1",
						"foldChange": "1.7501"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".468732854906209",
						"qValue": "1",
						"foldChange": "0.8585"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".75360667953715",
						"qValue": "1",
						"foldChange": "4.4263"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".832613559213542",
						"qValue": "1",
						"foldChange": "1.2859"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".909169370184068",
						"qValue": "1",
						"foldChange": "1.4036"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".922487017244617",
						"qValue": "1",
						"foldChange": "1.2516"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".78169591224108",
						"qValue": "1",
						"foldChange": "0.9738"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".894275620882196",
						"qValue": "1",
						"foldChange": "1.2247"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".681976078057499",
						"qValue": "1",
						"foldChange": "1.2854"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".414254980168132",
						"qValue": "1",
						"foldChange": "0.7131"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".875592737920993",
						"qValue": "1",
						"foldChange": "0.8002"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".527772289772809",
						"qValue": "1",
						"foldChange": "1.1221"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".618292206298113",
						"qValue": "1",
						"foldChange": "0.7803"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".553983916438704",
						"qValue": "1",
						"foldChange": "0.7889"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".901387852569659",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".946191087034782",
						"qValue": "1",
						"foldChange": "1.0497"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".47607709315381",
						"qValue": "1",
						"foldChange": "1.1777"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".506110964967548",
						"qValue": ".985830071188835",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000152631858129",
						"qValue": ".000673799627286",
						"foldChange": "3.6123"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".09535503322618",
						"qValue": ".05908758148217",
						"foldChange": "1.5716"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".012274395811696",
						"qValue": ".006386496082241",
						"foldChange": "2.3235"
					}
				]
			},
			{
				"metaboliteId": "0078ddca-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6b0-0000-0000-0000-000000000000",
				"chemicalName": "7-methylguanine",
				"plotName": null,
				"compoundId": "0000892a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00897",
				"inChiKey": "FZWGECJQACGGTI-UHFFFAOYAT",
				"kegg": "C02242",
				"mass": 165.06506,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3806,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".403414880807725",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".276047479185456",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".319408621654095",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".032043607061221",
						"qValue": "1",
						"foldChange": "0.9213"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".032447034279836",
						"qValue": "1",
						"foldChange": "0.9230"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".995706851989269",
						"qValue": "1",
						"foldChange": "1.0023"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".990647126255286",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".996390105303071",
						"qValue": "1",
						"foldChange": "1.0079"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".994256863857907",
						"qValue": "1",
						"foldChange": "1.0049"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".684951178929152",
						"qValue": "1",
						"foldChange": "1.0201"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".583657710776166",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".341881439607863",
						"qValue": "1",
						"foldChange": "0.9022"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".992590152171806",
						"qValue": "1",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".236084816910221",
						"qValue": "1",
						"foldChange": "0.8929"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".228434826049792",
						"qValue": "1",
						"foldChange": "0.9004"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".284635037622272",
						"qValue": "1",
						"foldChange": "1.0788"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".968180637389377",
						"qValue": "1",
						"foldChange": "0.9658"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".318737122849446",
						"qValue": "1",
						"foldChange": "0.8953"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".28431887757898",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".660604877963727",
						"qValue": "1",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".141008586920208",
						"qValue": ".985830071188835",
						"foldChange": "0.8192"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".247123863565915",
						"qValue": ".156444332562",
						"foldChange": "0.8813"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".607691803109645",
						"qValue": ".237661566132019",
						"foldChange": "1.0345"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".592866396549446",
						"qValue": ".12624985541972",
						"foldChange": "0.9734"
					}
				]
			},
			{
				"metaboliteId": "0078ddcb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6ba-0000-0000-0000-000000000000",
				"chemicalName": "3-methylcytidine",
				"plotName": null,
				"compoundId": "0000893c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BWXZYULEOLQCKN-NNRPTJCQBK",
				"kegg": null,
				"mass": 257.101171,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3870,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".309161128816132",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".04355187380232",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".058520879593515",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".003207467406701",
						"qValue": ".909851587700747",
						"foldChange": "0.8260"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".027485244925776",
						"qValue": "1",
						"foldChange": "0.8627"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".41115735505585",
						"qValue": "1",
						"foldChange": "1.0736"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".649926987321858",
						"qValue": "1",
						"foldChange": "1.0578"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".500598960912257",
						"qValue": ".902562957068497",
						"foldChange": "1.0785"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".825291474572342",
						"qValue": "1",
						"foldChange": "1.0439"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".136674635714306",
						"qValue": "1",
						"foldChange": "0.9076"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".506499919493172",
						"qValue": "1",
						"foldChange": "1.0629"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".033961758232569",
						"qValue": ".614826369149758",
						"foldChange": "1.0876"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".010242979685679",
						"qValue": "1",
						"foldChange": "0.8060"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".056807050715061",
						"qValue": "1",
						"foldChange": "0.8426"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".554218514414893",
						"qValue": "1",
						"foldChange": "1.0454"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".743421602056959",
						"qValue": "1",
						"foldChange": "0.9599"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".316280507266399",
						"qValue": "1",
						"foldChange": "0.8671"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".475691681599764",
						"qValue": "1",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".46240689113938",
						"qValue": "1",
						"foldChange": "0.9162"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".938490752558007",
						"qValue": "1",
						"foldChange": "0.9047"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".526368878461091",
						"qValue": ".985830071188835",
						"foldChange": "0.9875"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".186085532177983",
						"qValue": ".126300900185341",
						"foldChange": "0.8076"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".133598802146283",
						"qValue": ".076495996979391",
						"foldChange": "0.8839"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".923164447788546",
						"qValue": ".181890458556839",
						"foldChange": "0.9890"
					}
				]
			},
			{
				"metaboliteId": "0078ddcc-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef5a-0000-0000-0000-000000000000",
				"chemicalName": "prolylglycine",
				"plotName": null,
				"compoundId": "00009eff-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11178",
				"inChiKey": "RNKSNIBMTUYWSH-YFKPBYRVBZ",
				"kegg": null,
				"mass": 172.084792,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".059727449627874",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".952377633854628",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".974224597860551",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".709474044508358",
						"qValue": "1",
						"foldChange": "1.0322"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".849106291548653",
						"qValue": "1",
						"foldChange": "1.1404"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".573983408965807",
						"qValue": "1",
						"foldChange": "1.1950"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".949997605516696",
						"qValue": "1",
						"foldChange": "1.2313"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".802875865159887",
						"qValue": ".988780551738153",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".754843138653502",
						"qValue": ".983723600297289",
						"foldChange": "1.2312"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".861100318725784",
						"qValue": "1",
						"foldChange": "1.0289"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".717887297654483",
						"qValue": "1",
						"foldChange": "1.0460"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".85205598124474",
						"qValue": "1",
						"foldChange": "0.9547"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".669379353135953",
						"qValue": "1",
						"foldChange": "1.0521"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".077762388138508",
						"qValue": "1",
						"foldChange": "1.3741"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".16031314981459",
						"qValue": "1",
						"foldChange": "1.3061"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".413473806570963",
						"qValue": "1",
						"foldChange": "1.1215"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".055054653238252",
						"qValue": "1",
						"foldChange": "1.4068"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".23304393903156",
						"qValue": "1",
						"foldChange": "1.2544"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".968597342924239",
						"qValue": "1",
						"foldChange": "1.0502"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".199067102840335",
						"qValue": "1",
						"foldChange": "1.3132"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".201344781683617",
						"qValue": ".985830071188835",
						"foldChange": "1.2504"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".019660103682561",
						"qValue": ".024750096345107",
						"foldChange": "1.8925"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".042472609911022",
						"qValue": ".032716398129185",
						"foldChange": "1.4347"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".068924241619774",
						"qValue": ".0242480490477",
						"foldChange": "1.2614"
					}
				]
			},
			{
				"metaboliteId": "0078ddcd-0000-0000-0000-000000000000",
				"chemicalId": "00000116-0000-0000-0000-000000000000",
				"chemicalName": "cysteinylglycine",
				"plotName": null,
				"compoundId": "00008b35-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00078",
				"inChiKey": "ZUKPVRWZDMRIEO-UHFFFAOYAS",
				"kegg": "C01419",
				"mass": 178.041215,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 496,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".087178647042609",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".575414758778064",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".418792519196117",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".040133531284271",
						"qValue": "1",
						"foldChange": "0.9121"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".448795098594502",
						"qValue": "1",
						"foldChange": "0.9320"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".184653178206762",
						"qValue": "1",
						"foldChange": "1.5490"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".677808736022361",
						"qValue": "1",
						"foldChange": "1.0507"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".997006186735843",
						"qValue": "1",
						"foldChange": "1.0773"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".675072113117272",
						"qValue": ".976023509428735",
						"foldChange": "1.1450"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".496339454186275",
						"qValue": "1",
						"foldChange": "1.2051"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".797095137566262",
						"qValue": "1",
						"foldChange": "1.1212"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".671333591815462",
						"qValue": "1",
						"foldChange": "0.9633"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".081050585586362",
						"qValue": "1",
						"foldChange": "0.7372"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".719547281033583",
						"qValue": "1",
						"foldChange": "1.0301"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".182143428616668",
						"qValue": "1",
						"foldChange": "1.3974"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".717638412476107",
						"qValue": "1",
						"foldChange": "0.7384"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".069343787185676",
						"qValue": "1",
						"foldChange": "1.1318"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".028431423406037",
						"qValue": ".822195137696456",
						"foldChange": "1.5327"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".257688037847186",
						"qValue": "1",
						"foldChange": "0.7488"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".655580668948685",
						"qValue": "1",
						"foldChange": "1.0168"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".122679210034413",
						"qValue": ".985830071188835",
						"foldChange": "1.3580"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".172777763424821",
						"qValue": ".12021452780783",
						"foldChange": "0.7929"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000368129880533",
						"qValue": ".001207667142251",
						"foldChange": "0.5946"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".195361068119608",
						"qValue": ".054624966302138",
						"foldChange": "0.7875"
					}
				]
			},
			{
				"metaboliteId": "0078ddce-0000-0000-0000-000000000000",
				"chemicalId": "00000602-0000-0000-0000-000000000000",
				"chemicalName": "stearoyl sphingomyelin (d18:1/18:0)",
				"plotName": null,
				"compoundId": "00004c2f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01348",
				"inChiKey": "LKQLRGMMMAHREN-YJFXYUILBI",
				"kegg": "C00550",
				"mass": 730.598875,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2793,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".080068513052387",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".311401175229367",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".338064081210982",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".929152853420983",
						"qValue": "1",
						"foldChange": "1.0093"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".088110699559955",
						"qValue": "1",
						"foldChange": "1.1011"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".10516330415329",
						"qValue": "1",
						"foldChange": "1.0875"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".451134517698606",
						"qValue": "1",
						"foldChange": "1.0417"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".292250527348508",
						"qValue": ".825038702902081",
						"foldChange": "1.0644"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".76155491731945",
						"qValue": ".98622264532067",
						"foldChange": "1.0213"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".249936277108887",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".839347946339662",
						"qValue": "1",
						"foldChange": "1.0048"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".177548575122285",
						"qValue": ".989315025499063",
						"foldChange": "0.9449"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".257859649183393",
						"qValue": "1",
						"foldChange": "0.8780"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".259052247340965",
						"qValue": "1",
						"foldChange": "1.0914"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".028504174565998",
						"qValue": "1",
						"foldChange": "1.2430"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".402876696547611",
						"qValue": "1",
						"foldChange": "0.9293"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".104403913556012",
						"qValue": "1",
						"foldChange": "1.2052"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".016351357845379",
						"qValue": ".773055862578736",
						"foldChange": "1.2969"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".142345038509653",
						"qValue": "1",
						"foldChange": "0.8767"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".798501820976958",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".094924177696431",
						"qValue": ".985830071188835",
						"foldChange": "1.1832"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".392710415278699",
						"qValue": ".212359369717559",
						"foldChange": "1.0792"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".970099102004669",
						"qValue": ".329888636852484",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".210830806830684",
						"qValue": ".057402325045719",
						"foldChange": "1.1145"
					}
				]
			},
			{
				"metaboliteId": "0078ddcf-0000-0000-0000-000000000000",
				"chemicalId": "000005ee-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyl-sphingosine (d18:1/16:0)",
				"plotName": null,
				"compoundId": "0000af4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04949",
				"inChiKey": "YDNKGFDKKRUKPY-TURZORIXBD",
				"kegg": null,
				"mass": 537.512094,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2710,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".224506845735589",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".738979192896028",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".736059423550884",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".636910418103009",
						"qValue": "1",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".95480370545921",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".677759551803395",
						"qValue": "1",
						"foldChange": "1.0375"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".547611857883422",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".871079309722354",
						"qValue": "1",
						"foldChange": "1.0462"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".660133000208067",
						"qValue": ".975068512044627",
						"foldChange": "1.1900"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".258183499926446",
						"qValue": "1",
						"foldChange": "1.2351"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".987992897328525",
						"qValue": "1",
						"foldChange": "1.0740"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".252026786201822",
						"qValue": "1",
						"foldChange": "0.8049"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".88333640120042",
						"qValue": "1",
						"foldChange": "1.0531"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".416894183939148",
						"qValue": "1",
						"foldChange": "1.1599"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".489962143450195",
						"qValue": "1",
						"foldChange": "1.1014"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".449844450995482",
						"qValue": "1",
						"foldChange": "0.9299"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".160223671570391",
						"qValue": "1",
						"foldChange": "1.3028"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".032103467721602",
						"qValue": ".822195137696456",
						"foldChange": "1.4010"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".971547362395885",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".451578417366071",
						"qValue": "1",
						"foldChange": "1.1083"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".42061805232224",
						"qValue": ".985830071188835",
						"foldChange": "1.0963"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".585645950103695",
						"qValue": ".28473107706299",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".220458133487186",
						"qValue": ".111974437084907",
						"foldChange": "1.2308"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".022845603174897",
						"qValue": ".01043472411264",
						"foldChange": "1.4994"
					}
				]
			},
			{
				"metaboliteId": "0078ddd0-0000-0000-0000-000000000000",
				"chemicalId": "05f603e2-0000-0000-0000-000000000000",
				"chemicalName": "oleate/vaccenate (18:1)",
				"plotName": null,
				"compoundId": "0000cc3d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 282.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".904927116564429",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".127845715719368",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".368561386086674",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".615895679152531",
						"qValue": "1",
						"foldChange": "1.0781"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".749571850830244",
						"qValue": "1",
						"foldChange": "1.0514"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".854850859624982",
						"qValue": "1",
						"foldChange": "1.0069"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".129008319587769",
						"qValue": ".722967631129409",
						"foldChange": "1.2574"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".171683086296605",
						"qValue": ".789529107655781",
						"foldChange": "0.9633"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".005144595003229",
						"qValue": ".199179819811905",
						"foldChange": "0.7970"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".525528013152231",
						"qValue": "1",
						"foldChange": "1.1142"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".849639769297507",
						"qValue": "1",
						"foldChange": "1.0550"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".655515509967046",
						"qValue": "1",
						"foldChange": "0.9415"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".961618602020541",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".801854210612832",
						"qValue": "1",
						"foldChange": "1.0117"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".83328121529428",
						"qValue": "1",
						"foldChange": "0.9975"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".490829861094702",
						"qValue": "1",
						"foldChange": "1.1477"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".718494940050686",
						"qValue": "1",
						"foldChange": "1.0255"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".764540370989321",
						"qValue": "1",
						"foldChange": "0.8935"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".297791840547971",
						"qValue": "1",
						"foldChange": "0.9034"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".860469191271516",
						"qValue": "1",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".235901843817324",
						"qValue": ".985830071188835",
						"foldChange": "1.0646"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".12054689661767",
						"qValue": ".09457806443489",
						"foldChange": "1.3106"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".47988539395421",
						"qValue": ".195921426493086",
						"foldChange": "1.2519"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".119790530364448",
						"qValue": ".037324482223975",
						"foldChange": "1.2836"
					}
				]
			},
			{
				"metaboliteId": "0078ddd1-0000-0000-0000-000000000000",
				"chemicalId": "05f5f974-0000-0000-0000-000000000000",
				"chemicalName": "6-hydroxyindole sulfate",
				"plotName": null,
				"compoundId": "0000be3a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 213.009578,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5365,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".9653913034426",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".641089064322541",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".313617189388192",
						"qValue": ".978149427357095",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".772219031066269",
						"qValue": "1",
						"foldChange": "1.1375"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".543011768415645",
						"qValue": "1",
						"foldChange": "1.2392"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".749213603605859",
						"qValue": "1",
						"foldChange": "1.1001"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".558290679249649",
						"qValue": "1",
						"foldChange": "1.2086"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".304506672201992",
						"qValue": ".825038702902081",
						"foldChange": "1.3315"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".655866072590159",
						"qValue": ".974069856499521",
						"foldChange": "1.1667"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".048523662273454",
						"qValue": ".821720595261736",
						"foldChange": "0.7590"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".204183350548748",
						"qValue": "1",
						"foldChange": "0.8806"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".464675309087531",
						"qValue": "1",
						"foldChange": "1.0985"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".920429630573983",
						"qValue": "1",
						"foldChange": "1.0427"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".3671798975143",
						"qValue": "1",
						"foldChange": "1.2778"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".407555235754782",
						"qValue": "1",
						"foldChange": "1.2254"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".794845116141397",
						"qValue": "1",
						"foldChange": "1.0685"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".637975278692725",
						"qValue": "1",
						"foldChange": "0.9952"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".46409612054462",
						"qValue": "1",
						"foldChange": "0.9314"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".747446665488481",
						"qValue": "1",
						"foldChange": "1.1732"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".832831297528337",
						"qValue": "1",
						"foldChange": "1.0456"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".59788391867097",
						"qValue": ".985830071188835",
						"foldChange": "0.8912"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00366832905006",
						"qValue": ".006861860065306",
						"foldChange": "2.8853"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000323330067026",
						"qValue": ".001126992981598",
						"foldChange": "3.4633"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00892117351134",
						"qValue": ".004980242660703",
						"foldChange": "1.9898"
					}
				]
			},
			{
				"metaboliteId": "0078ddd2-0000-0000-0000-000000000000",
				"chemicalId": "05f604ca-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-palmitoyl-GPC (P-16:0/16:0)*",
				"plotName": null,
				"compoundId": "0000cdec-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11206",
				"inChiKey": null,
				"kegg": null,
				"mass": 717.56724,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".405620332805093",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".07942779648946",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".03979793497929",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".524996544447245",
						"qValue": "1",
						"foldChange": "0.9724"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".46384866774504",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".922269685303132",
						"qValue": "1",
						"foldChange": "1.0018"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".038504909040919",
						"qValue": ".436430439671866",
						"foldChange": "0.9087"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".046092392948973",
						"qValue": ".678234398444917",
						"foldChange": "0.9071"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".936305942175955",
						"qValue": "1",
						"foldChange": "1.0260"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".041501196923077",
						"qValue": ".821720595261736",
						"foldChange": "1.1599"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".351152762438376",
						"qValue": "1",
						"foldChange": "0.9495"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".003864470266743",
						"qValue": ".437982515918976",
						"foldChange": "0.8218"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".636071727838032",
						"qValue": "1",
						"foldChange": "0.9590"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".154289147984681",
						"qValue": "1",
						"foldChange": "0.8714"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".311877169627011",
						"qValue": "1",
						"foldChange": "0.9086"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".160298011241392",
						"qValue": "1",
						"foldChange": "0.9033"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".677454388974878",
						"qValue": "1",
						"foldChange": "1.0436"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".07670060042264",
						"qValue": ".822195137696456",
						"foldChange": "1.1553"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".197731131811264",
						"qValue": "1",
						"foldChange": "0.8813"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".112495040798153",
						"qValue": "1",
						"foldChange": "0.8473"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".697023757004268",
						"qValue": ".993114870458668",
						"foldChange": "0.9614"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".442516148428604",
						"qValue": ".230052384131036",
						"foldChange": "1.0611"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".771031279567062",
						"qValue": ".282522113498109",
						"foldChange": "0.9552"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".056844542047125",
						"qValue": ".020961544936936",
						"foldChange": "1.2239"
					}
				]
			},
			{
				"metaboliteId": "0078ddd3-0000-0000-0000-000000000000",
				"chemicalId": "05f603fc-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/23:0, d18:1/23:1, d17:1/24:1)*",
				"plotName": null,
				"compoundId": "0000ccd3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 798.6614,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2816,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".952714822216914",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".662455474639209",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".084843049560108",
						"qValue": ".769244960704375",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".410114954000956",
						"qValue": "1",
						"foldChange": "1.0871"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".081082803878111",
						"qValue": "1",
						"foldChange": "1.1587"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".346366502245475",
						"qValue": "1",
						"foldChange": "1.0909"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".941254549543042",
						"qValue": "1",
						"foldChange": "1.0318"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".234672958257992",
						"qValue": ".825038702902081",
						"foldChange": "1.1179"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".264320409247843",
						"qValue": ".957839854315834",
						"foldChange": "1.0998"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".600474862860859",
						"qValue": "1",
						"foldChange": "1.0481"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".124222378537633",
						"qValue": "1",
						"foldChange": "0.9149"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".041760210763091",
						"qValue": ".683421910757508",
						"foldChange": "0.8315"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".68526508731019",
						"qValue": "1",
						"foldChange": "1.0704"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".446453699110879",
						"qValue": "1",
						"foldChange": "1.0694"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".696458058235048",
						"qValue": "1",
						"foldChange": "0.9991"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".928402129336297",
						"qValue": "1",
						"foldChange": "1.0550"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".511863025818614",
						"qValue": "1",
						"foldChange": "1.1511"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".558116129880728",
						"qValue": "1",
						"foldChange": "1.0911"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".88874723232661",
						"qValue": "1",
						"foldChange": "1.0341"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".558488072951634",
						"qValue": "1",
						"foldChange": "0.8559"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".463382778461182",
						"qValue": ".985830071188835",
						"foldChange": "0.8277"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".731280799978268",
						"qValue": ".328877982604021",
						"foldChange": "1.0492"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".073723881763019",
						"qValue": ".04930903693404",
						"foldChange": "1.3968"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".061480603986439",
						"qValue": ".022212585887372",
						"foldChange": "1.4185"
					}
				]
			},
			{
				"metaboliteId": "0078ddd4-0000-0000-0000-000000000000",
				"chemicalId": "05f6053b-0000-0000-0000-000000000000",
				"chemicalName": "methylsuccinoylcarnitine",
				"plotName": null,
				"compoundId": "0000cf27-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 275.136887,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 349,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".980676742201209",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".303044204134019",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".032489418288677",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".027867970954379",
						"qValue": "1",
						"foldChange": "1.5321"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".346118477641533",
						"qValue": "1",
						"foldChange": "1.2969"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".194259408370091",
						"qValue": "1",
						"foldChange": "0.8718"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".351417159534911",
						"qValue": ".951252030575746",
						"foldChange": "0.9480"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".048000850300039",
						"qValue": ".678234398444917",
						"foldChange": "1.2659"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".004636263215238",
						"qValue": ".197272999808392",
						"foldChange": "1.4318"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".807396756055661",
						"qValue": "1",
						"foldChange": "0.9957"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".873912132331229",
						"qValue": "1",
						"foldChange": "1.0133"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".932145003720223",
						"qValue": "1",
						"foldChange": "0.9577"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".75700470331584",
						"qValue": "1",
						"foldChange": "0.9874"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".757648971021678",
						"qValue": "1",
						"foldChange": "1.0981"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".986919897652117",
						"qValue": "1",
						"foldChange": "1.1121"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".343425270746552",
						"qValue": "1",
						"foldChange": "0.8743"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".51883607520397",
						"qValue": "1",
						"foldChange": "0.8623"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".793774261019381",
						"qValue": "1",
						"foldChange": "0.9863"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".486273028154913",
						"qValue": "1",
						"foldChange": "1.2267"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".908366629061654",
						"qValue": "1",
						"foldChange": "1.0152"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".429006408235212",
						"qValue": ".985830071188835",
						"foldChange": "0.8276"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".7043033909791",
						"qValue": ".321638931936377",
						"foldChange": "1.5159"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001428127322428",
						"qValue": ".002727590557944",
						"foldChange": "2.1558"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".203443039250725",
						"qValue": ".05617816025054",
						"foldChange": "1.3499"
					}
				]
			},
			{
				"metaboliteId": "0078ddd5-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec3b-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-GPC (P-16:0)*",
				"plotName": null,
				"compoundId": "0000ccfa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10407",
				"inChiKey": "HTZINLFNXLXRBC-CQLBIITFBS",
				"kegg": null,
				"mass": 479.337575,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2535,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".375884563592116",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".682298623379484",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".150644882730229",
						"qValue": ".883222174860925",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".322441324716347",
						"qValue": "1",
						"foldChange": "1.1278"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".114886517361141",
						"qValue": "1",
						"foldChange": "1.1830"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".547368683573315",
						"qValue": "1",
						"foldChange": "1.0651"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".096328210304294",
						"qValue": ".661091185233501",
						"foldChange": "0.9257"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".405959874429342",
						"qValue": ".882901404171953",
						"foldChange": "0.9585"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".395276145839964",
						"qValue": ".957839854315834",
						"foldChange": "1.1203"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".392599321647518",
						"qValue": "1",
						"foldChange": "0.9441"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".126844516533861",
						"qValue": "1",
						"foldChange": "0.8981"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".492936145007006",
						"qValue": "1",
						"foldChange": "0.8889"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".968446188714012",
						"qValue": "1",
						"foldChange": "0.9366"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".398485395334623",
						"qValue": "1",
						"foldChange": "1.0681"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".366770006242037",
						"qValue": "1",
						"foldChange": "1.1405"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".084039458932364",
						"qValue": "1",
						"foldChange": "0.7808"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".730221179312923",
						"qValue": "1",
						"foldChange": "0.9894"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".181634352389087",
						"qValue": "1",
						"foldChange": "1.2672"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".110190538884699",
						"qValue": "1",
						"foldChange": "0.7874"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".242111536261781",
						"qValue": "1",
						"foldChange": "0.7841"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".716957123417378",
						"qValue": ".997573179629986",
						"foldChange": "0.9958"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".053944142738435",
						"qValue": ".052223386188926",
						"foldChange": "1.4815"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".071787817590101",
						"qValue": ".048535883584844",
						"foldChange": "1.2133"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000065245339518",
						"qValue": ".000172721742087",
						"foldChange": "2.3102"
					}
				]
			},
			{
				"metaboliteId": "0078ddd6-0000-0000-0000-000000000000",
				"chemicalId": "05f5fa23-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglucosamine/N-acetylgalactosamine",
				"plotName": null,
				"compoundId": "0000b5cb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00215",
				"inChiKey": null,
				"kegg": null,
				"mass": 221.089937,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1277,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".852995123247538",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".708777000892563",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".545553082788828",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".642977405581262",
						"qValue": "1",
						"foldChange": "1.1043"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".265086213242078",
						"qValue": "1",
						"foldChange": "0.9237"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".117476771518286",
						"qValue": "1",
						"foldChange": "0.9336"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".307441675221386",
						"qValue": ".921611482331303",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".706240295687573",
						"qValue": ".950986052805043",
						"foldChange": "0.9917"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".517247374821107",
						"qValue": ".957839854315834",
						"foldChange": "1.0637"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".82850039984675",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".913091738343589",
						"qValue": "1",
						"foldChange": "1.0109"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".914371955305999",
						"qValue": "1",
						"foldChange": "0.9425"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".691385635129182",
						"qValue": "1",
						"foldChange": "0.9851"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".839907254040888",
						"qValue": "1",
						"foldChange": "0.9648"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".858869434403295",
						"qValue": "1",
						"foldChange": "0.9794"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".479609702473044",
						"qValue": "1",
						"foldChange": "0.9076"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".974741249411489",
						"qValue": "1",
						"foldChange": "0.9823"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".473330715627437",
						"qValue": "1",
						"foldChange": "1.0823"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".326905627730241",
						"qValue": "1",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".27315018370758",
						"qValue": "1",
						"foldChange": "1.0469"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".861047781347665",
						"qValue": ".997573179629986",
						"foldChange": "0.9376"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".001214421697822",
						"qValue": ".003046087856991",
						"foldChange": "0.6316"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001361478385023",
						"qValue": ".002636412517039",
						"foldChange": "0.6740"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000005435943209",
						"qValue": ".000045998356586",
						"foldChange": "0.5651"
					}
				]
			},
			{
				"metaboliteId": "0078ddd7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e93b-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl sphingomyelin (d18:1/16:0)",
				"plotName": null,
				"compoundId": "00009282-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RWKUXQNLWDTSLO-GWQJGLRPBP",
				"kegg": null,
				"mass": 702.567575,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2791,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".214268932352187",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".860061101767292",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".039599499388821",
						"qValue": ".688305314988485",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".942961873312356",
						"qValue": "1",
						"foldChange": "1.0087"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".485654030104297",
						"qValue": "1",
						"foldChange": "1.0337"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".531196533131624",
						"qValue": "1",
						"foldChange": "1.0302"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".071877190370787",
						"qValue": ".593497652236823",
						"foldChange": "0.9426"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".669239187968998",
						"qValue": ".93517659928016",
						"foldChange": "0.9896"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".16478370401887",
						"qValue": ".957839854315834",
						"foldChange": "1.0602"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".137068360074287",
						"qValue": "1",
						"foldChange": "1.0647"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".281126363283162",
						"qValue": "1",
						"foldChange": "0.9638"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".012284521679072",
						"qValue": ".566846104084484",
						"foldChange": "0.8792"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".452111485094025",
						"qValue": "1",
						"foldChange": "1.0482"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".131073366478124",
						"qValue": "1",
						"foldChange": "1.1072"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".403229350981775",
						"qValue": "1",
						"foldChange": "1.0563"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".774049601292235",
						"qValue": "1",
						"foldChange": "0.9908"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".022559718669603",
						"qValue": "1",
						"foldChange": "1.1949"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".009916096342734",
						"qValue": ".653136865625524",
						"foldChange": "1.2061"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".905830547075582",
						"qValue": "1",
						"foldChange": "1.0073"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".599506854572707",
						"qValue": "1",
						"foldChange": "1.0188"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".671704364489485",
						"qValue": ".990272584968167",
						"foldChange": "1.0114"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".636808637041487",
						"qValue": ".301632829345998",
						"foldChange": "0.9720"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".575561728196208",
						"qValue": ".22821688366872",
						"foldChange": "1.0340"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".091181554028659",
						"qValue": ".030414519321203",
						"foldChange": "1.1152"
					}
				]
			},
			{
				"metaboliteId": "0078ddd8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74f-0000-0000-0000-000000000000",
				"chemicalName": "octadecanedioate (C18-DC)",
				"plotName": null,
				"compoundId": "00008f92-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00782",
				"inChiKey": "BNJOQKFENDDGSC-UHFFFAOYAE",
				"kegg": null,
				"mass": 314.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1522,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".290591631725733",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".769812102639566",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".122708177929485",
						"qValue": ".842134350145094",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".232239670459776",
						"qValue": "1",
						"foldChange": "0.8807"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".907879719698976",
						"qValue": "1",
						"foldChange": "1.2308"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".19103893387506",
						"qValue": "1",
						"foldChange": "1.3325"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".458793540653474",
						"qValue": "1",
						"foldChange": "1.3213"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".274782743353361",
						"qValue": ".825038702902081",
						"foldChange": "0.9424"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".070241763787117",
						"qValue": ".785109179715988",
						"foldChange": "0.8617"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".121413541226075",
						"qValue": "1",
						"foldChange": "1.4922"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".300122781056506",
						"qValue": "1",
						"foldChange": "1.2395"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".599035571667943",
						"qValue": "1",
						"foldChange": "1.0709"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".390323309908796",
						"qValue": "1",
						"foldChange": "0.8850"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".887305426622978",
						"qValue": "1",
						"foldChange": "1.0139"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".491075634737291",
						"qValue": "1",
						"foldChange": "1.1456"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".684190520662543",
						"qValue": "1",
						"foldChange": "1.2552"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".10175729847859",
						"qValue": "1",
						"foldChange": "1.4103"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".195072322659302",
						"qValue": "1",
						"foldChange": "1.1236"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".107657346441106",
						"qValue": "1",
						"foldChange": "0.6918"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".631068857165358",
						"qValue": "1",
						"foldChange": "1.1116"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".042840521545021",
						"qValue": ".985830071188835",
						"foldChange": "1.6069"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".104724950191345",
						"qValue": ".084776150624441",
						"foldChange": "1.6467"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".772248569826815",
						"qValue": ".282596805024827",
						"foldChange": "1.0479"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".009709787320012",
						"qValue": ".005346232868359",
						"foldChange": "1.7888"
					}
				]
			},
			{
				"metaboliteId": "0078ddd9-0000-0000-0000-000000000000",
				"chemicalId": "00000218-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxyuridine",
				"plotName": null,
				"compoundId": "0000cd7a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00012",
				"inChiKey": "MXHRCPNRJAMMIM-SHYZEUOFBK",
				"kegg": "C00526",
				"mass": 228.074623,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3853,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".735084915473191",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".958226941178014",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".086282345305956",
						"qValue": ".772908166898616",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".274180950255331",
						"qValue": "1",
						"foldChange": "0.9045"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".288624835914005",
						"qValue": "1",
						"foldChange": "1.6994"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".034259266787793",
						"qValue": "1",
						"foldChange": "2.1987"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".346655000038827",
						"qValue": ".951252030575746",
						"foldChange": "1.3399"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".860688922508361",
						"qValue": "1",
						"foldChange": "1.2453"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".265398546442647",
						"qValue": ".957839854315834",
						"foldChange": "0.8822"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".838335932326894",
						"qValue": "1",
						"foldChange": "1.1760"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".212842977237712",
						"qValue": "1",
						"foldChange": "0.7911"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".148707260192568",
						"qValue": ".960252848686774",
						"foldChange": "0.8333"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".687509440872566",
						"qValue": "1",
						"foldChange": "1.1470"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".597102526412089",
						"qValue": "1",
						"foldChange": "1.2568"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".879004726273825",
						"qValue": "1",
						"foldChange": "1.0958"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".049942949768426",
						"qValue": "1",
						"foldChange": "1.5126"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".131931810587081",
						"qValue": "1",
						"foldChange": "1.2044"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".708942739102832",
						"qValue": "1",
						"foldChange": "0.7962"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".561399669868608",
						"qValue": "1",
						"foldChange": "0.7698"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".203718683338814",
						"qValue": "1",
						"foldChange": "0.6211"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".455412387522738",
						"qValue": ".985830071188835",
						"foldChange": "0.8068"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".952911622151956",
						"qValue": ".386375057388733",
						"foldChange": "1.1310"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".083324239843406",
						"qValue": ".053783953118536",
						"foldChange": "1.5271"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".01604574739369",
						"qValue": ".007841339258513",
						"foldChange": "2.5689"
					}
				]
			},
			{
				"metaboliteId": "0078ddda-0000-0000-0000-000000000000",
				"chemicalId": "05f6046d-0000-0000-0000-000000000000",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-linoleoyl-GPE (P-16:0/18:2)*",
				"plotName": null,
				"compoundId": "0000cdc5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11343",
				"inChiKey": null,
				"kegg": null,
				"mass": 699.52029,
				"pathwayType": "ANIMAL",
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".804468495537265",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".03654403211286",
						"qValue": ".942393070546791",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".564656935775432",
						"qValue": ".993864952980131",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".214618441288818",
						"qValue": "1",
						"foldChange": "0.9117"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".837212420121787",
						"qValue": "1",
						"foldChange": "1.0223"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".298508938505012",
						"qValue": "1",
						"foldChange": "1.1465"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".276077880265864",
						"qValue": ".911265664999995",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".076368832643637",
						"qValue": ".687896186587066",
						"foldChange": "0.8628"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".481453653277144",
						"qValue": ".957839854315834",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".03653682563698",
						"qValue": ".821720595261736",
						"foldChange": "0.8327"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".37542684607189",
						"qValue": "1",
						"foldChange": "0.9419"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".215066818588555",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".42137689442113",
						"qValue": "1",
						"foldChange": "1.0926"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".463960531072243",
						"qValue": "1",
						"foldChange": "1.0321"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".97660722126377",
						"qValue": "1",
						"foldChange": "0.9447"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".356872768247502",
						"qValue": "1",
						"foldChange": "1.1898"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".847719774327765",
						"qValue": "1",
						"foldChange": "0.9899"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".48480702662614",
						"qValue": "1",
						"foldChange": "0.8320"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".967966746778182",
						"qValue": "1",
						"foldChange": "1.0158"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".73302240267903",
						"qValue": "1",
						"foldChange": "1.0252"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".69786450356555",
						"qValue": ".993114870458668",
						"foldChange": "1.0092"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".219922702862783",
						"qValue": ".143177969939591",
						"foldChange": "0.8364"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".187645888654937",
						"qValue": ".099476023307784",
						"foldChange": "0.8074"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".262655026421794",
						"qValue": ".068156467360188",
						"foldChange": "0.8052"
					}
				]
			},
			{
				"metaboliteId": "0078dddb-0000-0000-0000-000000000000",
				"chemicalId": "05f604ab-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-arachidonoyl-GPC (18:2/20:4n6)*",
				"plotName": null,
				"compoundId": "0000cde6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08147",
				"inChiKey": null,
				"kegg": null,
				"mass": 805.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2101,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".762416744645378",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".920705282396168",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".106631113577931",
						"qValue": ".817505204097468",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".162415889361146",
						"qValue": "1",
						"foldChange": "1.1300"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".150889732589253",
						"qValue": "1",
						"foldChange": "1.1086"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".967560499039314",
						"qValue": "1",
						"foldChange": "1.0164"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".99258431540824",
						"qValue": "1",
						"foldChange": "1.0370"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".885946710353697",
						"qValue": "1",
						"foldChange": "1.0172"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".893289168620732",
						"qValue": "1",
						"foldChange": "1.0293"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".057002025955285",
						"qValue": ".821720595261736",
						"foldChange": "0.8810"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".045623669338939",
						"qValue": "1",
						"foldChange": "0.8788"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".919190033920989",
						"qValue": "1",
						"foldChange": "0.9369"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".418030733374875",
						"qValue": "1",
						"foldChange": "1.0937"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".046564201631666",
						"qValue": "1",
						"foldChange": "1.2212"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".197418767961614",
						"qValue": "1",
						"foldChange": "1.1165"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".942754387185364",
						"qValue": "1",
						"foldChange": "0.9902"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".976476599401503",
						"qValue": "1",
						"foldChange": "1.0414"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".968609184486753",
						"qValue": "1",
						"foldChange": "1.0517"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".986301368779164",
						"qValue": "1",
						"foldChange": "1.0036"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".906113790578694",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".917017739120101",
						"qValue": ".997573179629986",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".825714206448249",
						"qValue": ".352045466002712",
						"foldChange": "1.0475"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".428640875609524",
						"qValue": ".179736816124255",
						"foldChange": "1.0796"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".057764058456668",
						"qValue": ".021251875720774",
						"foldChange": "1.2355"
					}
				]
			},
			{
				"metaboliteId": "0078dddc-0000-0000-0000-000000000000",
				"chemicalId": "05f605bf-0000-0000-0000-000000000000",
				"chemicalName": "pimeloylcarnitine/3-methyladipoylcarnitine (C7-DC)",
				"plotName": null,
				"compoundId": "0000cfe8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 303.168188,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1690,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".344137556092264",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".345944821761151",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".013071554934407",
						"qValue": ".542454401754345",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".492914782902846",
						"qValue": "1",
						"foldChange": "1.1022"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".215006737591862",
						"qValue": "1",
						"foldChange": "1.2761"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".057338245235539",
						"qValue": "1",
						"foldChange": "1.7378"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".134343143830074",
						"qValue": ".737587196125114",
						"foldChange": "0.9480"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".623280878722978",
						"qValue": ".928868848501546",
						"foldChange": "1.0461"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".308864413706683",
						"qValue": ".957839854315834",
						"foldChange": "1.3515"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".003070756850229",
						"qValue": ".543683917591992",
						"foldChange": "1.8984"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".10131519055832",
						"qValue": "1",
						"foldChange": "1.4216"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".154150784277632",
						"qValue": ".964575863384303",
						"foldChange": "0.7572"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".500233105001044",
						"qValue": "1",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".075803287840183",
						"qValue": "1",
						"foldChange": "0.6588"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".234701409230449",
						"qValue": "1",
						"foldChange": "0.7936"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".243581102354032",
						"qValue": "1",
						"foldChange": "0.6777"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".487260766929692",
						"qValue": "1",
						"foldChange": "1.2377"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".069283621477077",
						"qValue": ".822195137696456",
						"foldChange": "1.8262"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".074174065948032",
						"qValue": "1",
						"foldChange": "0.6336"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".142316649419255",
						"qValue": "1",
						"foldChange": "0.6615"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".810259258805851",
						"qValue": ".997573179629986",
						"foldChange": "1.0441"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".026271869368067",
						"qValue": ".031065602657974",
						"foldChange": "1.8663"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".029395166757479",
						"qValue": ".024467873549908",
						"foldChange": "1.7394"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000000444047792",
						"qValue": ".000011535191609",
						"foldChange": "5.8555"
					}
				]
			},
			{
				"metaboliteId": "0078dddd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e518-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoylglycerol (16:0)",
				"plotName": null,
				"compoundId": "0000828b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11533",
				"inChiKey": "BBNYCLAREVXOSG-UHFFFAOYAG",
				"kegg": null,
				"mass": 330.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2569,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".420072048228993",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".819347911012311",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".869246480562648",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".956578856565426",
						"qValue": "1",
						"foldChange": "5.3948"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".958162644317487",
						"qValue": "1",
						"foldChange": "1.2167"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".914866876425925",
						"qValue": "1",
						"foldChange": "4.8933"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".730495103301501",
						"qValue": "1",
						"foldChange": "1.5096"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".238761437166276",
						"qValue": ".825038702902081",
						"foldChange": "1.1578"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".401504635409031",
						"qValue": ".957839854315834",
						"foldChange": "1.1869"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".648187906779538",
						"qValue": "1",
						"foldChange": "2.7454"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".864338553293695",
						"qValue": "1",
						"foldChange": "1.3687"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".775048418181177",
						"qValue": "1",
						"foldChange": "1.4956"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".341353196621705",
						"qValue": "1",
						"foldChange": "1.7134"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".552489872513217",
						"qValue": "1",
						"foldChange": "0.3951"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".128306939683821",
						"qValue": "1",
						"foldChange": "0.2306"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".482484585979526",
						"qValue": "1",
						"foldChange": "0.9296"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".900320506750043",
						"qValue": "1",
						"foldChange": "0.5956"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".419155857979145",
						"qValue": "1",
						"foldChange": "0.6407"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".895822090718022",
						"qValue": "1",
						"foldChange": "0.7590"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".629668348327605",
						"qValue": "1",
						"foldChange": "0.3825"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".713351434018182",
						"qValue": ".997573179629986",
						"foldChange": "0.5039"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00001538423391",
						"qValue": ".000181913396091",
						"foldChange": "0.1724"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".001322306449747",
						"qValue": ".002578464669276",
						"foldChange": "0.3563"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".009966221613316",
						"qValue": ".005431621888822",
						"foldChange": "0.5571"
					}
				]
			},
			{
				"metaboliteId": "0078ddde-0000-0000-0000-000000000000",
				"chemicalId": "000001b7-0000-0000-0000-000000000000",
				"chemicalName": "stearate (18:0)",
				"plotName": null,
				"compoundId": "0000054e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00827",
				"inChiKey": "QIQXTHQIDYTFRH-UHFFFAOYAB",
				"kegg": "C01530",
				"mass": 284.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1345,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".623892898280341",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".181776789740499",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".007594125264662",
						"qValue": ".519146648790059",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".801088094113547",
						"qValue": "1",
						"foldChange": "1.0341"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".582389227759365",
						"qValue": "1",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".765237810587546",
						"qValue": "1",
						"foldChange": "1.0314"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".009937992271527",
						"qValue": ".281907714102307",
						"foldChange": "1.2210"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".091403077389444",
						"qValue": ".697458782960496",
						"foldChange": "0.9173"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".00005865271376",
						"qValue": ".012405015081279",
						"foldChange": "0.7601"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".762040983663656",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".658975753933845",
						"qValue": "1",
						"foldChange": "1.0626"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".457741467522672",
						"qValue": "1",
						"foldChange": "1.0326"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".974264352641078",
						"qValue": "1",
						"foldChange": "1.0232"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".390699115712213",
						"qValue": "1",
						"foldChange": "1.1124"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".362913277626465",
						"qValue": "1",
						"foldChange": "1.0872"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".196912290473674",
						"qValue": "1",
						"foldChange": "1.1858"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".586588585968191",
						"qValue": "1",
						"foldChange": "1.0582"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".481745570386005",
						"qValue": "1",
						"foldChange": "0.8924"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".195936205203231",
						"qValue": "1",
						"foldChange": "0.8761"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".41596924393852",
						"qValue": "1",
						"foldChange": "1.0551"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".04083358709541",
						"qValue": ".985830071188835",
						"foldChange": "1.2044"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".09392300716157",
						"qValue": ".078304789052164",
						"foldChange": "1.3376"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".444177878156874",
						"qValue": ".184861821776258",
						"foldChange": "1.1504"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".225588155152298",
						"qValue": ".060339435568167",
						"foldChange": "1.1598"
					}
				]
			},
			{
				"metaboliteId": "0078dddf-0000-0000-0000-000000000000",
				"chemicalId": "0000009e-0000-0000-0000-000000000000",
				"chemicalName": "5,6-dihydrothymine",
				"plotName": null,
				"compoundId": "0000058a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00079",
				"inChiKey": "NBAKTGXDIBVZOO-UHFFFAOYAR",
				"kegg": "C00906",
				"mass": 128.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3893,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".4165973994344",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".260523131899073",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".131402161340689",
						"qValue": ".873619057038484",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".279078662819542",
						"qValue": "1",
						"foldChange": "0.8853"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".948114496532035",
						"qValue": "1",
						"foldChange": "1.0268"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".251709516368334",
						"qValue": "1",
						"foldChange": "2.0597"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".902367610588047",
						"qValue": "1",
						"foldChange": "1.2398"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".011332940134289",
						"qValue": ".567215491640796",
						"foldChange": "0.8097"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".015484388105273",
						"qValue": ".488044973243978",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".905535507882665",
						"qValue": "1",
						"foldChange": "1.0012"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".668265011002623",
						"qValue": "1",
						"foldChange": "0.9163"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".75647958920052",
						"qValue": "1",
						"foldChange": "0.9403"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".862714383435012",
						"qValue": "1",
						"foldChange": "1.0564"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".717896336932663",
						"qValue": "1",
						"foldChange": "1.0291"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".839361024904067",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".26240129851576",
						"qValue": "1",
						"foldChange": "1.2705"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".212510743087998",
						"qValue": "1",
						"foldChange": "1.3095"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".84640612373062",
						"qValue": "1",
						"foldChange": "1.0306"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".022451299260285",
						"qValue": "1",
						"foldChange": "0.7072"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".904294540702175",
						"qValue": "1",
						"foldChange": "0.8729"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".03605563798307",
						"qValue": ".985830071188835",
						"foldChange": "1.2342"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000045566830125",
						"qValue": ".000320994653109",
						"foldChange": "4.8483"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".012297087059436",
						"qValue": ".012031575311664",
						"foldChange": "4.4498"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000055185031723",
						"qValue": ".000162845078659",
						"foldChange": "5.1238"
					}
				]
			},
			{
				"metaboliteId": "0078dde0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e581-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyadipate",
				"plotName": null,
				"compoundId": "00007cbe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00321",
				"inChiKey": "OTTXIFWBPRRYOG-UHFFFAOYAL",
				"kegg": "C02360",
				"mass": 162.052823,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1491,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".823967270805501",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".238875033645041",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".687513870856922",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".134477569910102",
						"qValue": "1",
						"foldChange": "0.7456"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".202724670114236",
						"qValue": "1",
						"foldChange": "0.7784"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".818374821033818",
						"qValue": "1",
						"foldChange": "1.1449"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".62326451190904",
						"qValue": "1",
						"foldChange": "1.0805"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".931932662152027",
						"qValue": "1",
						"foldChange": "1.5804"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".56443788175921",
						"qValue": ".957839854315834",
						"foldChange": "1.6335"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".445329119040953",
						"qValue": "1",
						"foldChange": "1.0536"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".518038035153282",
						"qValue": "1",
						"foldChange": "2.2178"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".161856002276814",
						"qValue": ".969566045102463",
						"foldChange": "1.6845"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".250532857361452",
						"qValue": "1",
						"foldChange": "0.7964"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".374983201214316",
						"qValue": "1",
						"foldChange": "1.0178"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".836113502200405",
						"qValue": "1",
						"foldChange": "1.2780"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".898732635111907",
						"qValue": "1",
						"foldChange": "0.9895"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".811507517329264",
						"qValue": "1",
						"foldChange": "0.9124"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".903641120209839",
						"qValue": "1",
						"foldChange": "0.9222"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".853710821232651",
						"qValue": "1",
						"foldChange": "1.3103"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".349681066424409",
						"qValue": "1",
						"foldChange": "1.3214"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".434987484206989",
						"qValue": ".985830071188835",
						"foldChange": "1.0084"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".707017056810594",
						"qValue": ".322433464017368",
						"foldChange": "0.9706"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".035437968663518",
						"qValue": ".02831447405639",
						"foldChange": "0.6647"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".039310881115201",
						"qValue": ".015596206528884",
						"foldChange": "0.6875"
					}
				]
			},
			{
				"metaboliteId": "0078dde1-0000-0000-0000-000000000000",
				"chemicalId": "05f5edc7-0000-0000-0000-000000000000",
				"chemicalName": "beta-citrylglutamate",
				"plotName": null,
				"compoundId": "0000d68b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GAQNUGISBQJMKO-UHFFFAOYAK",
				"kegg": "C20775",
				"mass": 321.069596,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 54,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".396369559596482",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".900012986465997",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".05866654445609",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".58405377466559",
						"qValue": "1",
						"foldChange": "1.0136"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".053845784308908",
						"qValue": "1",
						"foldChange": "0.8214"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".160507400327781",
						"qValue": "1",
						"foldChange": "0.8518"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".729830348384614",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".747783050183999",
						"qValue": ".960368665676976",
						"foldChange": "1.0506"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".980989069801347",
						"qValue": "1",
						"foldChange": "1.1408"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".862421159548514",
						"qValue": "1",
						"foldChange": "1.2152"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".036991416989752",
						"qValue": "1",
						"foldChange": "1.6677"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".054460526297652",
						"qValue": ".727024127920748",
						"foldChange": "1.6621"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".146006721031791",
						"qValue": "1",
						"foldChange": "0.7366"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".101890349195129",
						"qValue": "1",
						"foldChange": "0.7149"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".784834810321088",
						"qValue": "1",
						"foldChange": "0.9706"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".210732874611173",
						"qValue": "1",
						"foldChange": "0.7338"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".317128867750918",
						"qValue": "1",
						"foldChange": "0.7763"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".8487169126802",
						"qValue": "1",
						"foldChange": "1.0579"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".928673850846054",
						"qValue": "1",
						"foldChange": "1.0601"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".039484692639637",
						"qValue": "1",
						"foldChange": "1.5928"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".042864599320585",
						"qValue": ".985830071188835",
						"foldChange": "1.5025"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".041624642940279",
						"qValue": ".043890215328079",
						"foldChange": "1.8212"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".157451597756318",
						"qValue": ".086940233065009",
						"foldChange": "0.7216"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".169428848577607",
						"qValue": ".048904989492587",
						"foldChange": "0.7947"
					}
				]
			},
			{
				"metaboliteId": "0078dde2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8b9-0000-0000-0000-000000000000",
				"chemicalName": "beta-cryptoxanthin",
				"plotName": null,
				"compoundId": "0000e0f7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33844",
				"inChiKey": "DMASLKHVQRHNES-QQGJMDNJBV",
				"kegg": null,
				"mass": 552.433116,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4039,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".842495576634306",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".761870671714156",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".372373625376463",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".18203510337488",
						"qValue": "1",
						"foldChange": "0.8908"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".388528039049247",
						"qValue": "1",
						"foldChange": "0.9655"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".630626611891396",
						"qValue": "1",
						"foldChange": "1.0769"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".357748879267657",
						"qValue": ".951252030575746",
						"foldChange": "1.2776"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".24683002181104",
						"qValue": ".825038702902081",
						"foldChange": "1.2645"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".808430230912625",
						"qValue": "1",
						"foldChange": "1.0438"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".485228411231521",
						"qValue": "1",
						"foldChange": "0.9323"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".260802776834913",
						"qValue": "1",
						"foldChange": "0.9175"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".665831574235886",
						"qValue": "1",
						"foldChange": "0.9347"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".937663963868371",
						"qValue": "1",
						"foldChange": "1.4046"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".939852322029513",
						"qValue": "1",
						"foldChange": "1.0513"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".998769786132146",
						"qValue": "1",
						"foldChange": "0.7485"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".427647689157282",
						"qValue": "1",
						"foldChange": "1.7009"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".794526649110037",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".613776876371808",
						"qValue": "1",
						"foldChange": "0.6300"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".475758742343492",
						"qValue": "1",
						"foldChange": "1.5231"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".978406124459388",
						"qValue": "1",
						"foldChange": "0.9243"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".472555229832789",
						"qValue": ".985830071188835",
						"foldChange": "0.6069"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".072488814111958",
						"qValue": ".06557476577444",
						"foldChange": "0.6799"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".967886041200433",
						"qValue": ".32953794638533",
						"foldChange": "1.0677"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".150616163762337",
						"qValue": ".044432022793897",
						"foldChange": "0.7118"
					}
				]
			},
			{
				"metaboliteId": "0078dde3-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb7-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (14:0/18:1, 16:0/16:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6aa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 566.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2589,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".911497573530813",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".221762240599399",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".068811697604662",
						"qValue": ".747822329898273",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".318628105833134",
						"qValue": "1",
						"foldChange": "1.2385"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".862112357112234",
						"qValue": "1",
						"foldChange": "1.0687"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".242883068455724",
						"qValue": "1",
						"foldChange": "0.8962"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".024111467572302",
						"qValue": ".359575885726771",
						"foldChange": "1.6108"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".015588680887935",
						"qValue": ".567215491640796",
						"foldChange": "1.6542"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".859005158914064",
						"qValue": "1",
						"foldChange": "1.1945"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".269188362742608",
						"qValue": "1",
						"foldChange": "0.8627"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".453938851354279",
						"qValue": "1",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".067184377314398",
						"qValue": ".727024127920748",
						"foldChange": "1.2669"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".744648141336352",
						"qValue": "1",
						"foldChange": "1.0529"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".882310014183436",
						"qValue": "1",
						"foldChange": "0.9993"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".869676143733958",
						"qValue": "1",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".92184799477299",
						"qValue": "1",
						"foldChange": "0.9049"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".38211944338627",
						"qValue": "1",
						"foldChange": "0.5509"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".323243241072556",
						"qValue": "1",
						"foldChange": "0.6087"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".566318483296398",
						"qValue": "1",
						"foldChange": "0.9718"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".859210399089264",
						"qValue": "1",
						"foldChange": "0.8154"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".707725528922505",
						"qValue": ".996918325615539",
						"foldChange": "0.8390"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".989681730248145",
						"qValue": ".394788323537321",
						"foldChange": "1.2353"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".104140199298914",
						"qValue": ".062990060151526",
						"foldChange": "1.7786"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".563822670620562",
						"qValue": ".120865478747961",
						"foldChange": "1.9782"
					}
				]
			},
			{
				"metaboliteId": "0078dde4-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb8-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-arachidonoyl-glycerol (18:1/20:4) [1]*",
				"plotName": null,
				"compoundId": "0000d6b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07228",
				"inChiKey": null,
				"kegg": null,
				"mass": 642.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2639,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".470479339353672",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".790010847092175",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".103007435870219",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".487383613570744",
						"qValue": "1",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".534546807461458",
						"qValue": "1",
						"foldChange": "0.9708"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".941092792027525",
						"qValue": "1",
						"foldChange": "1.0614"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".098462679600015",
						"qValue": ".666432965082792",
						"foldChange": "1.2479"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".846655942326708",
						"qValue": "1",
						"foldChange": "1.0410"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".066250366297843",
						"qValue": ".783042523881455",
						"foldChange": "0.8776"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".081762533934639",
						"qValue": ".886468253895902",
						"foldChange": "0.8644"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".800047454112516",
						"qValue": "1",
						"foldChange": "1.0180"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".134279615384081",
						"qValue": ".960252848686774",
						"foldChange": "1.1475"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".279317760635916",
						"qValue": "1",
						"foldChange": "0.8273"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".503108355439253",
						"qValue": "1",
						"foldChange": "1.2081"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".087432179047221",
						"qValue": "1",
						"foldChange": "1.4603"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".856827734581138",
						"qValue": "1",
						"foldChange": "1.0777"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".958882771603604",
						"qValue": "1",
						"foldChange": "0.9777"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".902923046165101",
						"qValue": "1",
						"foldChange": "0.9073"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".3994538359609",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".39352876206789",
						"qValue": "1",
						"foldChange": "1.1120"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".095741781896111",
						"qValue": ".985830071188835",
						"foldChange": "1.2596"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".008144835854824",
						"qValue": ".01308579377988",
						"foldChange": "1.8595"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".019699127994203",
						"qValue": ".017892611518584",
						"foldChange": "1.7872"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".006238992028212",
						"qValue": ".003799544274491",
						"foldChange": "1.7654"
					}
				]
			},
			{
				"metaboliteId": "0078dde5-0000-0000-0000-000000000000",
				"chemicalId": "05f6046a-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPI (16:0/18:1)*",
				"plotName": null,
				"compoundId": "0000cdbd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09783",
				"inChiKey": null,
				"kegg": null,
				"mass": 836.541479,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2257,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".746378218413428",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".479919760562787",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".103035488854808",
						"qValue": ".811881490883715",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".070677953780786",
						"qValue": "1",
						"foldChange": "1.2022"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".293609521707614",
						"qValue": "1",
						"foldChange": "1.1267"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".435295920336469",
						"qValue": "1",
						"foldChange": "0.9625"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".098672800940578",
						"qValue": ".666432965082792",
						"foldChange": "1.2786"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".057376925559314",
						"qValue": ".687896186587066",
						"foldChange": "1.2482"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".793548670550274",
						"qValue": ".99456541772943",
						"foldChange": "1.0565"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".136153623204941",
						"qValue": "1",
						"foldChange": "0.8735"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".318434638862701",
						"qValue": "1",
						"foldChange": "0.9181"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".614097969941283",
						"qValue": "1",
						"foldChange": "0.9719"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".537000086983184",
						"qValue": "1",
						"foldChange": "1.2762"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".458578658291909",
						"qValue": "1",
						"foldChange": "1.1993"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".870571161108575",
						"qValue": "1",
						"foldChange": "0.9397"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".597235399067223",
						"qValue": "1",
						"foldChange": "1.0838"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".672198291281822",
						"qValue": "1",
						"foldChange": "0.9203"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".347332453660452",
						"qValue": "1",
						"foldChange": "0.8492"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".371381546627489",
						"qValue": "1",
						"foldChange": "1.1994"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".980665912442783",
						"qValue": "1",
						"foldChange": "0.8954"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".399847693962688",
						"qValue": ".985830071188835",
						"foldChange": "0.7465"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".800171171565208",
						"qValue": ".344316995840098",
						"foldChange": "1.0681"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".024152396176132",
						"qValue": ".021046280376589",
						"foldChange": "1.6378"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".211567395171743",
						"qValue": ".057457673613198",
						"foldChange": "1.4461"
					}
				]
			},
			{
				"metaboliteId": "0078dde6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e136-0000-0000-0000-000000000000",
				"chemicalName": "5-hydroxylysine",
				"plotName": null,
				"compoundId": "00003d45-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00450",
				"inChiKey": "YSMODUONRAFBET-UHFFFAOYAX",
				"kegg": "C16741",
				"mass": 162.100443,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 111,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".950614314273957",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".337365917769861",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".696974654953043",
						"qValue": ".996720052268086",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".873858867252608",
						"qValue": "1",
						"foldChange": "1.0296"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".182658048874296",
						"qValue": "1",
						"foldChange": "0.9031"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".238903856102756",
						"qValue": "1",
						"foldChange": "0.9637"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".258677661928108",
						"qValue": ".897869748007503",
						"foldChange": "0.9163"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".589520530381389",
						"qValue": ".928868848501546",
						"foldChange": "1.0236"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".55139835368566",
						"qValue": ".957839854315834",
						"foldChange": "1.0949"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".33363332101656",
						"qValue": "1",
						"foldChange": "0.9145"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".748089536155575",
						"qValue": "1",
						"foldChange": "0.9809"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".516456306789782",
						"qValue": "1",
						"foldChange": "0.9953"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".825485632819691",
						"qValue": "1",
						"foldChange": "0.8160"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".986227917828862",
						"qValue": "1",
						"foldChange": "0.8068"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".84467478965363",
						"qValue": "1",
						"foldChange": "0.9887"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".444476230724942",
						"qValue": "1",
						"foldChange": "0.8199"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".612471324066796",
						"qValue": "1",
						"foldChange": "0.8692"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".823000657455816",
						"qValue": "1",
						"foldChange": "1.0601"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".783832316834236",
						"qValue": "1",
						"foldChange": "1.0330"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".584827079083687",
						"qValue": "1",
						"foldChange": "0.9989"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".768183710288101",
						"qValue": ".997573179629986",
						"foldChange": "0.9670"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".000227114432755",
						"qValue": ".000917017703244",
						"foldChange": "0.3052"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000003840859259",
						"qValue": ".000119001103967",
						"foldChange": "0.4215"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".000032719640327",
						"qValue": ".000123265571784",
						"foldChange": "0.4142"
					}
				]
			},
			{
				"metaboliteId": "0078dde7-0000-0000-0000-000000000000",
				"chemicalId": "05f61e92-0000-0000-0000-000000000000",
				"chemicalName": "docosatrienoate (22:3n6)*",
				"plotName": null,
				"compoundId": "0000e07b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 334.28717,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".777870380828682",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".95451856470127",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".221850630120352",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".534149041487798",
						"qValue": "1",
						"foldChange": "2.8658"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".690775805828848",
						"qValue": "1",
						"foldChange": "2.1228"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".822168824031313",
						"qValue": "1",
						"foldChange": "2.8455"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".56203678633599",
						"qValue": "1",
						"foldChange": "4.2425"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".414689448180005",
						"qValue": ".88881677553957",
						"foldChange": "1.7450"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".165964173912398",
						"qValue": ".957839854315834",
						"foldChange": "1.5330"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".113942373152003",
						"qValue": "1",
						"foldChange": "0.6881"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".951239430183753",
						"qValue": "1",
						"foldChange": "2.0404"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".101124650920586",
						"qValue": ".869263413468873",
						"foldChange": "4.6767"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".388100818217695",
						"qValue": "1",
						"foldChange": "1.1892"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".38375564259617",
						"qValue": "1",
						"foldChange": "1.3535"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".954969385815312",
						"qValue": "1",
						"foldChange": "1.1382"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".422188012252755",
						"qValue": "1",
						"foldChange": "1.6519"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".325360948682716",
						"qValue": "1",
						"foldChange": "0.8211"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".077112470941195",
						"qValue": ".822195137696456",
						"foldChange": "0.4971"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".896497574898805",
						"qValue": "1",
						"foldChange": "0.9891"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".544729296398042",
						"qValue": "1",
						"foldChange": "1.5723"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".455860776831858",
						"qValue": ".985830071188835",
						"foldChange": "1.5897"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".679499231935942",
						"qValue": ".315092159646901",
						"foldChange": "1.6634"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".515646888168915",
						"qValue": ".207184869868946",
						"foldChange": "0.7541"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".299678110891095",
						"qValue": ".075637400682724",
						"foldChange": "1.9591"
					}
				]
			},
			{
				"metaboliteId": "0078dde8-0000-0000-0000-000000000000",
				"chemicalId": "05f61edf-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linoleoylcarnitine (C20:2)*",
				"plotName": null,
				"compoundId": "0000e0b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 451.36615,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1674,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".30007666167877",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".237200774331711",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".23926771311086",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".249725450042262",
						"qValue": "1",
						"foldChange": "1.1386"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".36736649464542",
						"qValue": "1",
						"foldChange": "1.0904"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".799719963228401",
						"qValue": "1",
						"foldChange": "1.0061"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".775808305899576",
						"qValue": "1",
						"foldChange": "0.9972"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".611679868797686",
						"qValue": ".928868848501546",
						"foldChange": "1.0099"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".823224586224903",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".677570178071315",
						"qValue": "1",
						"foldChange": "1.0653"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".055946137916121",
						"qValue": "1",
						"foldChange": "0.8613"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".021475639981132",
						"qValue": ".614826369149758",
						"foldChange": "0.8057"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".656320998283807",
						"qValue": "1",
						"foldChange": "0.9372"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".494467547845104",
						"qValue": "1",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".786890923431586",
						"qValue": "1",
						"foldChange": "0.9445"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".208793270144014",
						"qValue": "1",
						"foldChange": "0.7831"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".28521186908028",
						"qValue": "1",
						"foldChange": "0.8092"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".898671559049243",
						"qValue": "1",
						"foldChange": "1.0334"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".217384152122766",
						"qValue": "1",
						"foldChange": "0.8097"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".025751351904844",
						"qValue": "1",
						"foldChange": "0.6722"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".251780482230412",
						"qValue": ".985830071188835",
						"foldChange": "0.8302"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".179105129909871",
						"qValue": ".123284871589372",
						"foldChange": "1.3001"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".000066588155798",
						"qValue": ".000488628165031",
						"foldChange": "1.6484"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00001209270439",
						"qValue": ".0000703618314",
						"foldChange": "2.3585"
					}
				]
			},
			{
				"metaboliteId": "0078dde9-0000-0000-0000-000000000000",
				"chemicalId": "05f61eeb-0000-0000-0000-000000000000",
				"chemicalName": "docosapentaenoylcarnitine (C22:5n3)*",
				"plotName": null,
				"compoundId": "0000e0b9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 473.350494,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1681,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".32269922464181",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".896251714728433",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".397974102024914",
						"qValue": ".982590594541072",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".888894961536327",
						"qValue": "1",
						"foldChange": "1.1770"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".445989341789041",
						"qValue": "1",
						"foldChange": "1.1832"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".53298384928752",
						"qValue": "1",
						"foldChange": "1.1784"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".962365776952628",
						"qValue": "1",
						"foldChange": "1.0403"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".732907089111651",
						"qValue": ".957498790731047",
						"foldChange": "1.2674"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".768642826053432",
						"qValue": ".98622264532067",
						"foldChange": "1.2430"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".928007387778884",
						"qValue": "1",
						"foldChange": "1.0002"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".105278959652229",
						"qValue": "1",
						"foldChange": "0.8285"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".125302278948766",
						"qValue": ".952073565941074",
						"foldChange": "0.7518"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".196916152402112",
						"qValue": "1",
						"foldChange": "0.6947"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".741951556968951",
						"qValue": "1",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".356392542211798",
						"qValue": "1",
						"foldChange": "1.2795"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".181385538791096",
						"qValue": "1",
						"foldChange": "0.6199"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".658952429584793",
						"qValue": "1",
						"foldChange": "0.7453"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".3927130496787",
						"qValue": "1",
						"foldChange": "1.2023"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".132784615935594",
						"qValue": "1",
						"foldChange": "0.6079"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".133497693328727",
						"qValue": "1",
						"foldChange": "0.5881"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".934430240497269",
						"qValue": ".997573179629986",
						"foldChange": "0.9675"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".013140829479454",
						"qValue": ".018576905432704",
						"foldChange": "3.1703"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".008949992408214",
						"qValue": ".009277596772952",
						"foldChange": "2.1754"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".00002292182494",
						"qValue": ".000102368819781",
						"foldChange": "6.0709"
					}
				]
			},
			{
				"metaboliteId": "0078ddea-0000-0000-0000-000000000000",
				"chemicalId": "000004cf-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linoleate (20:2n6)",
				"plotName": null,
				"compoundId": "0000458d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05060",
				"inChiKey": "XSXIVVZCUAHUJO-HZJYTTRNBM",
				"kegg": "C16525",
				"mass": 308.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1397,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".923285895797748",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".178814163515101",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".209201046850125",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".840843822209443",
						"qValue": "1",
						"foldChange": "1.1479"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".982419540056757",
						"qValue": "1",
						"foldChange": "1.0974"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".823664444975595",
						"qValue": "1",
						"foldChange": "1.0506"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".057020575023346",
						"qValue": ".533236366427116",
						"foldChange": "1.5218"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".249070171605924",
						"qValue": ".825038702902081",
						"foldChange": "0.9645"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".003084742145627",
						"qValue": ".154418562701661",
						"foldChange": "0.6862"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".601304091326612",
						"qValue": "1",
						"foldChange": "1.0049"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".537649136534355",
						"qValue": "1",
						"foldChange": "1.0002"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".924852414002719",
						"qValue": "1",
						"foldChange": "1.1652"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".664040851535409",
						"qValue": "1",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".793972970243564",
						"qValue": "1",
						"foldChange": "1.0382"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".492104571811296",
						"qValue": "1",
						"foldChange": "1.1262"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".491164491629287",
						"qValue": "1",
						"foldChange": "1.1322"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".818748586629189",
						"qValue": "1",
						"foldChange": "0.8198"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".368465001071869",
						"qValue": "1",
						"foldChange": "0.7240"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".243488453032155",
						"qValue": "1",
						"foldChange": "0.8281"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".881164944060697",
						"qValue": "1",
						"foldChange": "0.8873"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".327098450097166",
						"qValue": ".985830071188835",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".30936287165016",
						"qValue": ".182579564835138",
						"foldChange": "1.2153"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".595192646846148",
						"qValue": ".234748729009723",
						"foldChange": "1.3826"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".339918772349265",
						"qValue": ".083280304277061",
						"foldChange": "1.2920"
					}
				]
			},
			{
				"metaboliteId": "0078ddeb-0000-0000-0000-000000000000",
				"chemicalId": "00000801-0000-0000-0000-000000000000",
				"chemicalName": "4-acetaminophen sulfate",
				"plotName": null,
				"compoundId": "00009263-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB59911",
				"inChiKey": "IGTYILLPRJOVFY-UHFFFAOYAN",
				"kegg": "C06804",
				"mass": 231.020146,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4774,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".174568332939418",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".861291719292042",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".749002958150237",
						"qValue": ".996970876120598",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".839403327101451",
						"qValue": "1",
						"foldChange": "2.4328"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".7147044391206",
						"qValue": "1",
						"foldChange": "14.4818"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".870456442660748",
						"qValue": "1",
						"foldChange": "14.4840"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".634455990580829",
						"qValue": "1",
						"foldChange": "70.3492"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".252581405435338",
						"qValue": ".825038702902081",
						"foldChange": "0.8631"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".500075665101218",
						"qValue": ".957839854315834",
						"foldChange": "1.5291"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".514853253733101",
						"qValue": "1",
						"foldChange": "1.1967"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".875522383000944",
						"qValue": "1",
						"foldChange": "2.3507"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".419864610825829",
						"qValue": "1",
						"foldChange": "117.3745"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".205425025430344",
						"qValue": "1",
						"foldChange": "0.0243"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".381844637013974",
						"qValue": "1",
						"foldChange": "0.7875"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".734437025829813",
						"qValue": "1",
						"foldChange": "32.3745"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".10344626865609",
						"qValue": "1",
						"foldChange": "0.0478"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".176682472077912",
						"qValue": "1",
						"foldChange": "0.0242"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".837781309801035",
						"qValue": "1",
						"foldChange": "0.5064"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".039220602644127",
						"qValue": "1",
						"foldChange": "0.0027"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".326274241985784",
						"qValue": "1",
						"foldChange": "0.6907"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".300025815918327",
						"qValue": ".985830071188835",
						"foldChange": "253.6812"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".057713356706773",
						"qValue": ".055067289208481",
						"foldChange": "433.0027"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".101725378608271",
						"qValue": ".062069496293387",
						"foldChange": "1.7069"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".013949885205668",
						"qValue": ".007030738682101",
						"foldChange": "626.9022"
					}
				]
			},
			{
				"metaboliteId": "0078ddec-0000-0000-0000-000000000000",
				"chemicalId": "05f61e81-0000-0000-0000-000000000000",
				"chemicalName": "glycosyl ceramide (d18:2/24:1, d18:1/24:2)*",
				"plotName": null,
				"compoundId": "0000e06d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 807.658813,
				"pathwayType": "ANIMAL",
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2770,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".532186704009224",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".536350286409292",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".050742192937985",
						"qValue": ".717738368088156",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".993415137730877",
						"qValue": "1",
						"foldChange": "1.0308"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".293010649689698",
						"qValue": "1",
						"foldChange": "1.1071"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".289304123811736",
						"qValue": "1",
						"foldChange": "1.1132"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".758097996727134",
						"qValue": "1",
						"foldChange": "1.0733"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".691372017688835",
						"qValue": ".948963850085804",
						"foldChange": "1.0651"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".928934027810873",
						"qValue": "1",
						"foldChange": "1.0222"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".490279237089286",
						"qValue": "1",
						"foldChange": "1.0732"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".02997981653771",
						"qValue": ".933767468184815",
						"foldChange": "0.8552"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".00514668056309",
						"qValue": ".437982515918976",
						"foldChange": "0.7580"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".654262774119103",
						"qValue": "1",
						"foldChange": "1.0754"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".742229203908835",
						"qValue": "1",
						"foldChange": "0.9831"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".442557424781549",
						"qValue": "1",
						"foldChange": "0.9142"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".571262567980983",
						"qValue": "1",
						"foldChange": "1.1235"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".959176222578483",
						"qValue": "1",
						"foldChange": "1.0689"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".548156603928873",
						"qValue": "1",
						"foldChange": "0.9514"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".858133426264986",
						"qValue": "1",
						"foldChange": "0.9962"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".115048371947109",
						"qValue": "1",
						"foldChange": "0.7313"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".076137224172244",
						"qValue": ".985830071188835",
						"foldChange": "0.7341"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".21607404474282",
						"qValue": ".141944609283639",
						"foldChange": "0.8535"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".476029881723399",
						"qValue": ".195204762397292",
						"foldChange": "1.1157"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".197126668296854",
						"qValue": ".054832563353056",
						"foldChange": "1.3392"
					}
				]
			},
			{
				"metaboliteId": "0078dded-0000-0000-0000-000000000000",
				"chemicalId": "00000070-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxy-3-methylglutarate",
				"plotName": null,
				"compoundId": "00000213-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00355",
				"inChiKey": "NPOAOTPXWNWTSH-NUQVWONBAH",
				"kegg": "C03761",
				"mass": 162.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Mevalonate Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2865,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".585864023913799",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".696553519860518",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".217186665636758",
						"qValue": ".945354393053805",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".0295579393659",
						"qValue": "1",
						"foldChange": "0.7540"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".167946101330403",
						"qValue": "1",
						"foldChange": "0.8545"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".403473473523029",
						"qValue": "1",
						"foldChange": "1.2243"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".643415303904956",
						"qValue": "1",
						"foldChange": "1.1750"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".88628614850001",
						"qValue": "1",
						"foldChange": "1.0786"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".544976968562085",
						"qValue": ".957839854315834",
						"foldChange": "1.0796"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".332671743877662",
						"qValue": "1",
						"foldChange": "1.2436"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".961189728591783",
						"qValue": "1",
						"foldChange": "1.0934"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".357173309924917",
						"qValue": "1",
						"foldChange": "0.9170"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".141921405437753",
						"qValue": "1",
						"foldChange": "0.6553"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".462503676121766",
						"qValue": "1",
						"foldChange": "0.8084"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".492916763143449",
						"qValue": "1",
						"foldChange": "1.2336"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".925184631564727",
						"qValue": "1",
						"foldChange": "0.9655"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".278270945611371",
						"qValue": "1",
						"foldChange": "1.2879"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".307112663566674",
						"qValue": "1",
						"foldChange": "1.3339"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".458225764188029",
						"qValue": "1",
						"foldChange": "0.7574"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".942213589441063",
						"qValue": "1",
						"foldChange": "0.8548"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".428304844550867",
						"qValue": ".985830071188835",
						"foldChange": "1.1286"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".00828161282426",
						"qValue": ".01311944536262",
						"foldChange": "2.0114"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".016115557088272",
						"qValue": ".01523310421101",
						"foldChange": "1.6895"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".02818744798319",
						"qValue": ".012292028007884",
						"foldChange": "2.2734"
					}
				]
			},
			{
				"metaboliteId": "0078ddee-0000-0000-0000-000000000000",
				"chemicalId": "05f60442-0000-0000-0000-000000000000",
				"chemicalName": "behenoyl dihydrosphingomyelin (d18:0/22:0)*",
				"plotName": null,
				"compoundId": "0000dff3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12091",
				"inChiKey": "FONAXCRWZQFJHY-JCGOJSMZBF",
				"kegg": null,
				"mass": 788.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2788,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b02-0000-0000-0000-000000000000",
						"pValue": ".209397899944531",
						"qValue": ".999123818858638",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b03-0000-0000-0000-000000000000",
						"pValue": ".742633326597811",
						"qValue": "1",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b04-0000-0000-0000-000000000000",
						"pValue": ".470329256987073",
						"qValue": ".989194290698189",
						"foldChange": "1.0000"
					},
					{
						"statsId": "3b9c6b05-0000-0000-0000-000000000000",
						"pValue": ".914881105611103",
						"qValue": "1",
						"foldChange": "1.1330"
					},
					{
						"statsId": "3b9c6b06-0000-0000-0000-000000000000",
						"pValue": ".736858178206305",
						"qValue": "1",
						"foldChange": "1.3089"
					},
					{
						"statsId": "3b9c6b07-0000-0000-0000-000000000000",
						"pValue": ".818664798961294",
						"qValue": "1",
						"foldChange": "1.2880"
					},
					{
						"statsId": "3b9c6b08-0000-0000-0000-000000000000",
						"pValue": ".323478013225929",
						"qValue": ".942586781423443",
						"foldChange": "0.9846"
					},
					{
						"statsId": "3b9c6b09-0000-0000-0000-000000000000",
						"pValue": ".926898325018035",
						"qValue": "1",
						"foldChange": "1.1889"
					},
					{
						"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
						"pValue": ".369794293052367",
						"qValue": ".957839854315834",
						"foldChange": "1.3859"
					},
					{
						"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
						"pValue": ".952567503640044",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
						"pValue": ".146246493299624",
						"qValue": "1",
						"foldChange": "0.8593"
					},
					{
						"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
						"pValue": ".162924353814215",
						"qValue": ".969566045102463",
						"foldChange": "0.7611"
					},
					{
						"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
						"pValue": ".342212916523985",
						"qValue": "1",
						"foldChange": "1.1004"
					},
					{
						"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
						"pValue": ".054529827790387",
						"qValue": "1",
						"foldChange": "1.5291"
					},
					{
						"statsId": "3b9c6b10-0000-0000-0000-000000000000",
						"pValue": ".280045470391858",
						"qValue": "1",
						"foldChange": "1.3896"
					},
					{
						"statsId": "3b9c6b11-0000-0000-0000-000000000000",
						"pValue": ".802426197620143",
						"qValue": "1",
						"foldChange": "0.9592"
					},
					{
						"statsId": "3b9c6b12-0000-0000-0000-000000000000",
						"pValue": ".068613245889526",
						"qValue": "1",
						"foldChange": "1.5193"
					},
					{
						"statsId": "3b9c6b13-0000-0000-0000-000000000000",
						"pValue": ".102313573812726",
						"qValue": ".876681906260878",
						"foldChange": "1.5838"
					},
					{
						"statsId": "3b9c6b14-0000-0000-0000-000000000000",
						"pValue": ".503732374785403",
						"qValue": "1",
						"foldChange": "1.2705"
					},
					{
						"statsId": "3b9c6b15-0000-0000-0000-000000000000",
						"pValue": ".448354358891784",
						"qValue": "1",
						"foldChange": "1.3115"
					},
					{
						"statsId": "3b9c6b16-0000-0000-0000-000000000000",
						"pValue": ".896652303287599",
						"qValue": ".997573179629986",
						"foldChange": "1.0322"
					},
					{
						"statsId": "3b9c6b17-0000-0000-0000-000000000000",
						"pValue": ".765895013199033",
						"qValue": ".336760467766698",
						"foldChange": "1.3128"
					},
					{
						"statsId": "3b9c6b18-0000-0000-0000-000000000000",
						"pValue": ".223923437408933",
						"qValue": ".113116401496229",
						"foldChange": "1.3689"
					},
					{
						"statsId": "3b9c6b19-0000-0000-0000-000000000000",
						"pValue": ".139604185482958",
						"qValue": ".042189833598699",
						"foldChange": "1.3543"
					}
				]
			}
		],
		"stats": [
			{
				"statsId": "3b9c6b02-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
				"comparison": "FTEST - PARAM_DIET",
				"reportName": "Diet Main Effect",
				"foldChange": "(G1)/(G1)",
				"testSequence": 1,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 0,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b03-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
				"comparison": "FTEST - PARAM_TIME_POINT",
				"reportName": "Time Point Main Effect",
				"foldChange": "(G19)/(G19)",
				"testSequence": 1,
				"compSequence": 2,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 1,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b04-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE MAIN EFFECT_CRC_EXCL",
				"comparison": "FTEST - PARAM_DIET:PARAM_TIME_POINT",
				"reportName": "Diet:Time Point Interaction",
				"foldChange": "(G2)/(G2)",
				"testSequence": 1,
				"compSequence": 3,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 2,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b05-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "CONTROL:2_WEEKS VS CONTROL:BASELINE",
				"reportName": "CRC Cntr 2W//CRC Cntr Base",
				"foldChange": "AVG_RAT_G22TOG19",
				"testSequence": 3,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 3,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b06-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "CONTROL:4_WEEKS VS CONTROL:BASELINE",
				"reportName": "CRC Cntr 4W//CRC Cntr Base",
				"foldChange": "AVG_RAT_G25TOG19",
				"testSequence": 3,
				"compSequence": 2,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 4,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b07-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "CONTROL:4_WEEKS VS CONTROL:2_WEEKS",
				"reportName": "CRC Cntr 4W//CRC Cntr 2W",
				"foldChange": "AVG_RAT_G25TOG22",
				"testSequence": 3,
				"compSequence": 3,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 5,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b08-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:2_WEEKS VS NAVY_BEANS:BASELINE",
				"reportName": "CRC NB 2W//CRC NB Base",
				"foldChange": "AVG_RAT_G23TOG20",
				"testSequence": 3,
				"compSequence": 4,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 6,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b09-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:4_WEEKS VS NAVY_BEANS:BASELINE",
				"reportName": "CRC NB 4W//CRC NB Base",
				"foldChange": "AVG_RAT_G26TOG20",
				"testSequence": 3,
				"compSequence": 5,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 7,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0a-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:4_WEEKS VS NAVY_BEANS:2_WEEKS",
				"reportName": "CRC NB 4W//CRC NB 2W",
				"foldChange": "AVG_RAT_G26TOG23",
				"testSequence": 3,
				"compSequence": 6,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 8,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0b-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:2_WEEKS VS RICE_BRAN:BASELINE",
				"reportName": "CRC RB 2W//CRC RB Base",
				"foldChange": "AVG_RAT_G24TOG21",
				"testSequence": 3,
				"compSequence": 7,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 9,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0c-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:4_WEEKS VS RICE_BRAN:BASELINE",
				"reportName": "CRC RB 4W//CRC RB Base",
				"foldChange": "AVG_RAT_G27TOG21",
				"testSequence": 3,
				"compSequence": 8,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 10,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0d-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:4_WEEKS VS RICE_BRAN:2_WEEKS",
				"reportName": "CRC RB 4W//CRC RB 2W",
				"foldChange": "AVG_RAT_G27TOG24",
				"testSequence": 3,
				"compSequence": 9,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 11,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0e-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:BASELINE VS CONTROL:BASELINE",
				"reportName": "CRC NB Base//CRC Cntr Base",
				"foldChange": "(G20)/(G19)",
				"testSequence": 3,
				"compSequence": 10,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 12,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b0f-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:BASELINE VS CONTROL:BASELINE",
				"reportName": "CRC RB Base//CRC Cntr Base",
				"foldChange": "(G21)/(G19)",
				"testSequence": 3,
				"compSequence": 11,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 13,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b10-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:BASELINE VS NAVY_BEANS:BASELINE",
				"reportName": "CRC RB Base//CRC NB Base",
				"foldChange": "(G21)/(G20)",
				"testSequence": 3,
				"compSequence": 12,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 14,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b11-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:2_WEEKS VS CONTROL:2_WEEKS",
				"reportName": "CRC NB 2W//CRC Cntr 2W",
				"foldChange": "(G23)/(G22)",
				"testSequence": 3,
				"compSequence": 13,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 15,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b12-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:2_WEEKS VS CONTROL:2_WEEKS",
				"reportName": "CRC RB 2W//CRC Cntr 2W",
				"foldChange": "(G24)/(G22)",
				"testSequence": 3,
				"compSequence": 14,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 16,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b13-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:2_WEEKS VS NAVY_BEANS:2_WEEKS",
				"reportName": "CRC RB 2W//CRC NB 2W",
				"foldChange": "(G24)/(G23)",
				"testSequence": 3,
				"compSequence": 15,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 17,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b14-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "NAVY_BEANS:4_WEEKS VS CONTROL:4_WEEKS",
				"reportName": "CRC NB 4W//CRC Cntr 4W",
				"foldChange": "(G26)/(G25)",
				"testSequence": 3,
				"compSequence": 16,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 18,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b15-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:4_WEEKS VS CONTROL:4_WEEKS",
				"reportName": "CRC RB 4W//CRC Cntr 4W",
				"foldChange": "(G27)/(G25)",
				"testSequence": 3,
				"compSequence": 17,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 19,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b16-0000-0000-0000-000000000000",
				"test": "TWO-WAY ANOVA REPEATED MEASURE_CONTRASTS_CRC EXCL",
				"comparison": "RICE_BRAN:4_WEEKS VS NAVY_BEANS:4_WEEKS",
				"reportName": "CRC RB 4W//CRC NB 4W",
				"foldChange": "(G27)/(G26)",
				"testSequence": 3,
				"compSequence": 18,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 20,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b17-0000-0000-0000-000000000000",
				"test": "WELCH TWO-SAMPLE T-TEST COMPARED G7TOG27",
				"comparison": "7 VS 27",
				"reportName": "CRC RB 4w//Child RB 4w",
				"foldChange": "(G27)/(G7)",
				"testSequence": 7,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 21,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b18-0000-0000-0000-000000000000",
				"test": "WELCH TWO-SAMPLE T-TEST COMPARED G6TOG26",
				"comparison": "6 VS 26",
				"reportName": "CRC NB 4w//Child NB 4w",
				"foldChange": "(G26)/(G6)",
				"testSequence": 6,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 22,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b19-0000-0000-0000-000000000000",
				"test": "WELCH TWO-SAMPLE T-TEST COMPARE G5TOG25",
				"comparison": "5 VS 25",
				"reportName": "CRC Cntr 4w//Child Cntr 4w",
				"foldChange": "(G25)/(G5)",
				"testSequence": 5,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 23,
				"customerVisible": true
			}
		],
		"customerVisible": true,
		"publishDate": "2016-07-22T00:00:00",
		"lastPublishDate": "2021-04-06T20:51:20.333543Z",
		"lastUpdateDate": "2021-04-06T20:51:20.333543Z"
	},
	{
		"sampleSetId": "00003aa4-0000-0000-0000-000000000000",
		"projectId": "0000168b-0000-0000-0000-000000000000",
		"sample": {
			"sampleId": "00003aa4-0000-0000-0000-000000000000",
			"name": "Effect of diet on cancer survivors, food COSU-0201-15VWBL",
			"organism": "SYNTHETIC",
			"species": "SYNTHETIC",
			"matrix": "FOOD",
			"matrixType": "FOOD"
		},
		"metabolites": [
			{
				"metaboliteId": "0078d80c-0000-0000-0000-000000000000",
				"chemicalId": "000001bf-0000-0000-0000-000000000000",
				"chemicalName": "oxaloacetate",
				"plotName": null,
				"compoundId": "0000b845-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00223",
				"inChiKey": "KHPXUQMNIQBQEV-UHFFFAOYAP",
				"kegg": "C00036",
				"mass": 132.005875,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1296,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3870"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2973"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5517"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5597"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5867"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0899"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0842"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5214"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6963"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9188"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6420"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4683"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0020"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9109"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7978"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5825"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9188"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0602"
					}
				]
			},
			{
				"metaboliteId": "0078d80d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4a8-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl-2-oxobutyrate",
				"plotName": null,
				"compoundId": "0000adee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00019",
				"inChiKey": "QHKABHOOEWYVLI-UHFFFAOYAY",
				"kegg": "C00141",
				"mass": 116.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 360,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d80e-0000-0000-0000-000000000000",
				"chemicalId": "00000454-0000-0000-0000-000000000000",
				"chemicalName": "N-carbamoylaspartate",
				"plotName": null,
				"compoundId": "0000063a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00828",
				"inChiKey": "HLKXYZVTANABHZ-MRIHDYNCBG",
				"kegg": "C00438",
				"mass": 176.043323,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3823,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d80f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e469-0000-0000-0000-000000000000",
				"chemicalName": "galacturonate",
				"plotName": null,
				"compoundId": "00005116-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02545",
				"inChiKey": "IAJILQKETJEXLJ-RSJOWCBRBL",
				"kegg": "C08348",
				"mass": 194.042653,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4423,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d810-0000-0000-0000-000000000000",
				"chemicalId": "000001c4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleate (16:1n7)",
				"plotName": null,
				"compoundId": "000082a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03229",
				"inChiKey": "SECPZKHBENQXJG-FPLPWBNLBE",
				"kegg": "C08362",
				"mass": 254.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1357,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2289"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0248"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6089"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5799"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5826"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2952"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4719"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0490"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5804"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2060"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0851"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1218"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4639"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5955"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0228"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5563"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7465"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9834"
					}
				]
			},
			{
				"metaboliteId": "0078d811-0000-0000-0000-000000000000",
				"chemicalId": "00000402-0000-0000-0000-000000000000",
				"chemicalName": "phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000640-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00224",
				"inChiKey": "SUHOOTKUPISOBE-UHFFFAOYAT",
				"kegg": "C00346",
				"mass": 141.019097,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1980,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d812-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e6-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltryptophan",
				"plotName": null,
				"compoundId": "000084a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13713",
				"inChiKey": "DZTHIGRZJZPRDV-LBPRGKRZBS",
				"kegg": "C03137",
				"mass": 246.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d813-0000-0000-0000-000000000000",
				"chemicalId": "05f5e71a-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoyl-GPC (16:0)*",
				"plotName": null,
				"compoundId": "000089b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61702",
				"inChiKey": "NEGQHKSYEYVFTD-HSZRJFAPBY",
				"kegg": null,
				"mass": 495.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2318,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d814-0000-0000-0000-000000000000",
				"chemicalId": "0000044e-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyltyrosine",
				"plotName": null,
				"compoundId": "00000aae-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11741",
				"inChiKey": "VVLXCWVSSLFQDS-QWRGUYRKBA",
				"kegg": null,
				"mass": 310.116488,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 562,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d815-0000-0000-0000-000000000000",
				"chemicalId": "000000cf-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 3',5'-cyclic monophosphate (cAMP)",
				"plotName": null,
				"compoundId": "00000b0f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00058",
				"inChiKey": "IVOMOUWHDPKRLL-UHFFFAOYAA",
				"kegg": "C00575",
				"mass": 329.052523,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3764,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d816-0000-0000-0000-000000000000",
				"chemicalId": "05f5e12c-0000-0000-0000-000000000000",
				"chemicalName": "anserine",
				"plotName": null,
				"compoundId": "00003d83-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00194",
				"inChiKey": "MYYIAHXIVFADCU-QMMMGPOBBJ",
				"kegg": "C01262",
				"mass": 240.122241,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 82,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d817-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4e5-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxydecanoate",
				"plotName": null,
				"compoundId": "00005625-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02203",
				"inChiKey": "FYSSBMZUBSBFJL-UHFFFAOYAJ",
				"kegg": null,
				"mass": 188.141245,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1747,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3450"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7612"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3349"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3250"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5322"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3440"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1394"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6127"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0002"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3316"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2834"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9824"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0871"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5460"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3752"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3764"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.8113"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4034"
					}
				]
			},
			{
				"metaboliteId": "0078d818-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3fb-0000-0000-0000-000000000000",
				"chemicalName": "5-methylcytidine",
				"plotName": null,
				"compoundId": "00005667-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00982",
				"inChiKey": "ZAYHVCMSTBRABG-JXOAFFINBK",
				"kegg": null,
				"mass": 257.101171,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3871,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d819-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6ac-0000-0000-0000-000000000000",
				"chemicalName": "isovalerylglycine",
				"plotName": null,
				"compoundId": "00008923-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00678",
				"inChiKey": "ZRQXMKMBBMNNQC-UHFFFAOYAA",
				"kegg": null,
				"mass": 159.089543,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d81a-0000-0000-0000-000000000000",
				"chemicalId": "00000153-0000-0000-0000-000000000000",
				"chemicalName": "glutarate (C5-DC)",
				"plotName": null,
				"compoundId": "0000018c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00661",
				"inChiKey": "JFCQEDHGNNZCLN-NUQVWONBAE",
				"kegg": "C00489",
				"mass": 132.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1483,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d81b-0000-0000-0000-000000000000",
				"chemicalId": "0000043c-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylvaline",
				"plotName": null,
				"compoundId": "00000637-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11757",
				"inChiKey": "IHYJTAOFMMMOPX-LURJTMIEBI",
				"kegg": null,
				"mass": 159.089544,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 355,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4300"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2476"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1319"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5193"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6098"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4240"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5686"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1099"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2461"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3931"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2005"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1534"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4300"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1837"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4162"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7945"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6973"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2955"
					}
				]
			},
			{
				"metaboliteId": "0078d81c-0000-0000-0000-000000000000",
				"chemicalId": "00000368-0000-0000-0000-000000000000",
				"chemicalName": "thymidine",
				"plotName": null,
				"compoundId": "00000887-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00273",
				"inChiKey": "IQFYYKKMVGJFEH-XLPZGREQBQ",
				"kegg": "C00214",
				"mass": 242.090272,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3889,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1868"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8818"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3955"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5402"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6210"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5030"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4114"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3441"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6557"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1924"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0467"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2932"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5538"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4177"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7741"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1838"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0504"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3476"
					}
				]
			},
			{
				"metaboliteId": "0078d81d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6a5-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPA (16:0)",
				"plotName": null,
				"compoundId": "0000867c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00327",
				"inChiKey": "YNDYKPRNFWPPFU-GOSISDBHBW",
				"kegg": "C04036",
				"mass": 410.24334,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2302,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1027"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3654"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5165"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0740"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7592"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0026"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4407"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0298"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5466"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1361"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0812"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5604"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6961"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3548"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1114"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1429"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1361"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0318"
					}
				]
			},
			{
				"metaboliteId": "0078d81e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e639-0000-0000-0000-000000000000",
				"chemicalName": "linolenate [alpha or gamma; (18:3n3 or 6)]",
				"plotName": null,
				"compoundId": "000084f3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03073",
				"inChiKey": "DTOSIQBPPRVQHS-PDBXOOCHBH",
				"kegg": "C06426",
				"mass": 278.22458,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1396,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3075"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3148"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4283"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3923"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5755"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8326"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8395"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1070"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1789"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0721"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3298"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8038"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4204"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7722"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2310"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4291"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0659"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0573"
					}
				]
			},
			{
				"metaboliteId": "0078d81f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e645-0000-0000-0000-000000000000",
				"chemicalName": "chlorogenate",
				"plotName": null,
				"compoundId": "00008548-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CWVRJTMFETXNAD-DUXPYHPUBG",
				"kegg": null,
				"mass": 354.095082,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4355,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d820-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec89-0000-0000-0000-000000000000",
				"chemicalName": "16-hydroxypalmitate",
				"plotName": null,
				"compoundId": "00009ab9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06294",
				"inChiKey": "4000",
				"kegg": "C18218",
				"mass": 272.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1765,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3170"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4514"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3730"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3362"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0150"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4111"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8395"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1349"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7440"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5923"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4003"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6090"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1407"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9611"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0286"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9528"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0279"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9402"
					}
				]
			},
			{
				"metaboliteId": "0078d821-0000-0000-0000-000000000000",
				"chemicalId": "05f5f754-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPG (18:1)*",
				"plotName": null,
				"compoundId": "0000b390-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 510.29577,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2419,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d822-0000-0000-0000-000000000000",
				"chemicalId": "0000004a-0000-0000-0000-000000000000",
				"chemicalName": "guanosine-2',3'-cyclic monophosphate",
				"plotName": null,
				"compoundId": "00009113-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11629",
				"inChiKey": "UASRYODFRYWBRC-UHFFFAOYAT",
				"kegg": "C06194",
				"mass": 345.047438,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3801,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d823-0000-0000-0000-000000000000",
				"chemicalId": "05f5e903-0000-0000-0000-000000000000",
				"chemicalName": "vanillin",
				"plotName": null,
				"compoundId": "00009242-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12308",
				"inChiKey": "MWOOGOJBHIARFG-UHFFFAOYAS",
				"kegg": "C00755",
				"mass": 152.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4274,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d824-0000-0000-0000-000000000000",
				"chemicalId": "00000049-0000-0000-0000-000000000000",
				"chemicalName": "cytidine 2',3'-cyclic monophosphate",
				"plotName": null,
				"compoundId": "00009259-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11691",
				"inChiKey": "NMPZCCZXCOMSDQ-UHFFFAOYAT",
				"kegg": "C02354",
				"mass": 305.04129,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3867,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d825-0000-0000-0000-000000000000",
				"chemicalId": "05f5e1fb-0000-0000-0000-000000000000",
				"chemicalName": "flavin mononucleotide (FMN)",
				"plotName": null,
				"compoundId": "00003db5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01520",
				"inChiKey": "FVTCRASFADXXNN-UHFFFAOYAV",
				"kegg": "C00061",
				"mass": 456.104615,
				"pathwayType": "ANIMAL",
				"subPathway": "Riboflavin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3943,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d826-0000-0000-0000-000000000000",
				"chemicalId": "00000172-0000-0000-0000-000000000000",
				"chemicalName": "inositol 1-phosphate (I1P)",
				"plotName": null,
				"compoundId": "0000ab49-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00213",
				"inChiKey": "INAPMGSXUVUWAF-PTQMNWPWBP",
				"kegg": "C01177",
				"mass": 260.029719,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1954,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d827-0000-0000-0000-000000000000",
				"chemicalId": "05f5e732-0000-0000-0000-000000000000",
				"chemicalName": "gulonate*",
				"plotName": null,
				"compoundId": "0000b76d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03290",
				"inChiKey": "RGHNJXZEOKUKBD-NRXMZTRTBF",
				"kegg": "C00257",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3965,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d828-0000-0000-0000-000000000000",
				"chemicalId": "05f5e21a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglutamate",
				"plotName": null,
				"compoundId": "00003d68-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01138",
				"inChiKey": "RFMMMVDNIPUKGG-QUCRTXIXBK",
				"kegg": "C00624",
				"mass": 189.063722,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 45,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3952"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8397"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7069"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7057"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6512"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2385"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7231"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5568"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8085"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7830"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3475"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0640"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1767"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3962"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6284"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3952"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7673"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3554"
					}
				]
			},
			{
				"metaboliteId": "0078d829-0000-0000-0000-000000000000",
				"chemicalId": "05f5e695-0000-0000-0000-000000000000",
				"chemicalName": "1-margaroylglycerol (17:0)",
				"plotName": null,
				"compoundId": "00008657-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "SVUQHVRAGMNPLW-UHFFFAOYAY",
				"kegg": null,
				"mass": 344.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2555,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d82a-0000-0000-0000-000000000000",
				"chemicalId": "0000011a-0000-0000-0000-000000000000",
				"chemicalName": "cytidine 5'-monophosphate (5'-CMP)",
				"plotName": null,
				"compoundId": "00000944-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00095",
				"inChiKey": "IERHLVCPSMICTF-FHLVPPQOBD",
				"kegg": "C00055",
				"mass": 323.051855,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3864,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d82b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e45f-0000-0000-0000-000000000000",
				"chemicalName": "adipate (C6-DC)",
				"plotName": null,
				"compoundId": "0000528e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00448",
				"inChiKey": "WNLRTRBMVRJNCN-UHFFFAOYAY",
				"kegg": "C06104",
				"mass": 146.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d82c-0000-0000-0000-000000000000",
				"chemicalId": "0000018d-0000-0000-0000-000000000000",
				"chemicalName": "leucine",
				"plotName": null,
				"compoundId": "0000003c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 131.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4685"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1977"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4277"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5744"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1565"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4787"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2623"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9053"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5737"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3868"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1500"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0623"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7065"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1876"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5839"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6075"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5910"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7522"
					}
				]
			},
			{
				"metaboliteId": "0078d82d-0000-0000-0000-000000000000",
				"chemicalId": "00000234-0000-0000-0000-000000000000",
				"chemicalName": "threonine",
				"plotName": null,
				"compoundId": "00000504-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00167",
				"inChiKey": "AYFVYJQAPQTCCC-GBXIJSLDBG",
				"kegg": "C00188",
				"mass": 119.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 16,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2355"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1989"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3270"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4536"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6134"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5250"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3028"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0246"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4357"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5266"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2844"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7480"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2847"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1922"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1565"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5109"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4449"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0469"
					}
				]
			},
			{
				"metaboliteId": "0078d82e-0000-0000-0000-000000000000",
				"chemicalId": "00000031-0000-0000-0000-000000000000",
				"chemicalName": "putrescine",
				"plotName": null,
				"compoundId": "00000580-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01414",
				"inChiKey": "KIDHWZJUCRJVML-UHFFFAOYAX",
				"kegg": "C00134",
				"mass": 88.100048,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 464,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d82f-0000-0000-0000-000000000000",
				"chemicalId": "000001f7-0000-0000-0000-000000000000",
				"chemicalName": "serine",
				"plotName": null,
				"compoundId": "00000670-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00187",
				"inChiKey": "MTCFGRXMJLQNBG-REOHCLBHBX",
				"kegg": "C00065",
				"mass": 105.042594,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 9,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2412"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9320"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0039"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0140"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1796"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7104"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2663"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9035"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4196"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4031"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4041"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8741"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1481"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2344"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1823"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1654"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2499"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5796"
					}
				]
			},
			{
				"metaboliteId": "0078d830-0000-0000-0000-000000000000",
				"chemicalId": "05f5e95f-0000-0000-0000-000000000000",
				"chemicalName": "naringenin 7-O-glucoside",
				"plotName": null,
				"compoundId": "0000925f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": "C09099",
				"mass": 434.121297,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4474,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1374"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5130"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2880"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5634"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6452"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5928"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7495"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0116"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3492"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7639"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4250"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4372"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9578"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6544"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2468"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2811"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1479"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2143"
					}
				]
			},
			{
				"metaboliteId": "0078d831-0000-0000-0000-000000000000",
				"chemicalId": "00000337-0000-0000-0000-000000000000",
				"chemicalName": "pyruvate",
				"plotName": null,
				"compoundId": "0000bf5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00243",
				"inChiKey": "LCTONWCANYUPML-REWHXWOFAD",
				"kegg": "C00022",
				"mass": 88.016045,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3552"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9522"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0728"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2108"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6056"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0564"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0405"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0487"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2946"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4034"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0950"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2339"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1459"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4541"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1663"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3923"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3650"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1355"
					}
				]
			},
			{
				"metaboliteId": "0078d832-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e9-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:2/16:0, d18:1/16:1)*",
				"plotName": null,
				"compoundId": "0000a5db-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 700.5519,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2805,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d833-0000-0000-0000-000000000000",
				"chemicalId": "00000069-0000-0000-0000-000000000000",
				"chemicalName": "cytidine 3'-monophosphate (3'-CMP)",
				"plotName": null,
				"compoundId": "00000b8f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "UOOOPKANIPLQPU-UHFFFAOYAI",
				"kegg": "C05822",
				"mass": 323.051855,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3865,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d834-0000-0000-0000-000000000000",
				"chemicalId": "05f5e987-0000-0000-0000-000000000000",
				"chemicalName": "S-methylmethionine",
				"plotName": null,
				"compoundId": "000094ef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB38670",
				"inChiKey": "YDBYJHTYSHBBAU-UHFFFAOYAF",
				"kegg": "C05319",
				"mass": 163.066699,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 374,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1641"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8239"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7309"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9217"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7826"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1548"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1450"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1440"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8950"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9154"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8052"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8052"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9433"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9406"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7275"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5034"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3995"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1546"
					}
				]
			},
			{
				"metaboliteId": "0078d835-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e5-0000-0000-0000-000000000000",
				"chemicalName": "2-methylbutyrylcarnitine (C5)",
				"plotName": null,
				"compoundId": "0000b027-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00378",
				"inChiKey": "IHCPDBBYTYJYIL-UHFFFAOYAT",
				"kegg": null,
				"mass": 245.162708,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d836-0000-0000-0000-000000000000",
				"chemicalId": "05f5e544-0000-0000-0000-000000000000",
				"chemicalName": "trigonelline (N'-methylnicotinate)",
				"plotName": null,
				"compoundId": "00007e91-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00875",
				"inChiKey": "WWNNZCOKKKDOPX-UHFFFAOYAV",
				"kegg": "C01004",
				"mass": 137.047678,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3933,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2315"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2167"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1007"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4932"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8938"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2894"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2689"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8588"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7348"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4354"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9756"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4685"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3348"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7263"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6813"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1538"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1642"
					}
				]
			},
			{
				"metaboliteId": "0078d837-0000-0000-0000-000000000000",
				"chemicalId": "05f5e58a-0000-0000-0000-000000000000",
				"chemicalName": "propionylcarnitine (C3)",
				"plotName": null,
				"compoundId": "00007ec4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00824",
				"inChiKey": "UFAHZIUFPNSHSL-UHFFFAOYAT",
				"kegg": "C03017",
				"mass": 217.131408,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1595,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d838-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebbd-0000-0000-0000-000000000000",
				"chemicalName": "S-methylcysteine",
				"plotName": null,
				"compoundId": "00009aa8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02108",
				"inChiKey": "IDIDJDIHTAOVLG-VKHMYHEABD",
				"kegg": null,
				"mass": 135.035399,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 393,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d839-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e5-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglutamine",
				"plotName": null,
				"compoundId": "00008497-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06029",
				"inChiKey": "KSMRODHGGIIXDV-YFKPBYRVBV",
				"kegg": "C02716",
				"mass": 188.079707,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 46,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4882"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2178"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4807"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5356"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2798"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0818"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2598"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2950"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5870"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3864"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3447"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7083"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3103"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7225"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3602"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8442"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3681"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1275"
					}
				]
			},
			{
				"metaboliteId": "0078d83a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e8-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylphenylalanine",
				"plotName": null,
				"compoundId": "0000849e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00512",
				"inChiKey": "CBQJSKKFNMDLON-JTQLQIEIBY",
				"kegg": "C03519",
				"mass": 207.089543,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3981"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1180"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7450"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2683"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7615"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6628"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5272"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3643"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9170"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0712"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0972"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0011"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5127"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2608"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1690"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5021"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2689"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0010"
					}
				]
			},
			{
				"metaboliteId": "0078d83b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e648-0000-0000-0000-000000000000",
				"chemicalName": "cyanidin glucoside",
				"plotName": null,
				"compoundId": "00008551-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB30684",
				"inChiKey": null,
				"kegg": "C08604",
				"mass": 448.100561,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4377,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d83c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e684-0000-0000-0000-000000000000",
				"chemicalName": "N2,N2-dimethylguanosine",
				"plotName": null,
				"compoundId": "00008941-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04824",
				"inChiKey": "RSPURTUNRHNVGF-IOSLPCCCBE",
				"kegg": null,
				"mass": 311.122969,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d83d-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef04-0000-0000-0000-000000000000",
				"chemicalName": "phenylalanylglycine",
				"plotName": null,
				"compoundId": "0000a19a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28995",
				"inChiKey": "GLUBLISJVJFHQS-UHFFFAOYAD",
				"kegg": null,
				"mass": 222.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 836,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d83e-0000-0000-0000-000000000000",
				"chemicalId": "00000116-0000-0000-0000-000000000000",
				"chemicalName": "cysteinylglycine",
				"plotName": null,
				"compoundId": "00008b35-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00078",
				"inChiKey": "ZUKPVRWZDMRIEO-UHFFFAOYAS",
				"kegg": "C01419",
				"mass": 178.041215,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 496,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d83f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0cf-0000-0000-0000-000000000000",
				"chemicalName": "2'-O-methyladenosine",
				"plotName": null,
				"compoundId": "0000a66f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FPUGCISOLXNPPC-IOSLPCCCBZ",
				"kegg": null,
				"mass": 281.112404,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3777,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1460"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8160"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6791"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3154"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4197"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6682"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6592"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8109"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1622"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4833"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9517"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8723"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0760"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4855"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3709"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0960"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5616"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1483"
					}
				]
			},
			{
				"metaboliteId": "0078d840-0000-0000-0000-000000000000",
				"chemicalId": "05f5e80a-0000-0000-0000-000000000000",
				"chemicalName": "azetidine-2-carboxylic acid",
				"plotName": null,
				"compoundId": "000090cc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29615",
				"inChiKey": "IADUEWIQBXOCDZ-UHFFFAOYAG",
				"kegg": "C08267",
				"mass": 101.047678,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4335,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d841-0000-0000-0000-000000000000",
				"chemicalId": "05f5e862-0000-0000-0000-000000000000",
				"chemicalName": "alanylleucine",
				"plotName": null,
				"compoundId": "000090e5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28691",
				"inChiKey": "RDIKFPRVLJLMER-UHFFFAOYAD",
				"kegg": null,
				"mass": 202.131742,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 579,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d842-0000-0000-0000-000000000000",
				"chemicalId": "05f5f29d-0000-0000-0000-000000000000",
				"chemicalName": "S-allylcysteine",
				"plotName": null,
				"compoundId": "0000a8e7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34323",
				"inChiKey": "ZFAHNWWNDFHPOH-UHFFFAOYAF",
				"kegg": null,
				"mass": 161.051049,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4534,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d843-0000-0000-0000-000000000000",
				"chemicalId": "05f6046a-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPI (16:0/18:1)*",
				"plotName": null,
				"compoundId": "0000cdbd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09783",
				"inChiKey": null,
				"kegg": null,
				"mass": 836.541479,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2257,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3802"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5472"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3513"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1158"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7958"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6442"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4219"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7478"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8493"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7810"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1686"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2825"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8937"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0061"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4593"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0577"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2131"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0710"
					}
				]
			},
			{
				"metaboliteId": "0078d844-0000-0000-0000-000000000000",
				"chemicalId": "000005f6-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPE (16:0/18:1)",
				"plotName": null,
				"compoundId": "00004b3f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05320",
				"inChiKey": "FHQVHHIBKUMWTI-OTMQOFQLBI",
				"kegg": null,
				"mass": 717.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2143,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4833"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9175"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6951"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1281"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3093"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5570"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3537"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1567"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1792"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6005"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3572"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1957"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2931"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2544"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1160"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1135"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9814"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2736"
					}
				]
			},
			{
				"metaboliteId": "0078d845-0000-0000-0000-000000000000",
				"chemicalId": "00000603-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-oleoyl-GPC (16:0/18:1)",
				"plotName": null,
				"compoundId": "0000cced-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07972",
				"inChiKey": "WTJKGGKOPKCXLL-MRCUWXFGBH",
				"kegg": null,
				"mass": 759.577805,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2023,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8230"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8757"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4920"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9888"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4367"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2112"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3956"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3199"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2560"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1130"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1722"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7309"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1062"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2450"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7836"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5340"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5765"
					}
				]
			},
			{
				"metaboliteId": "0078d846-0000-0000-0000-000000000000",
				"chemicalId": "05f6045f-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linoleoyl-glycerol (16:0/18:2) [2]*",
				"plotName": null,
				"compoundId": "0000cd9a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07103",
				"inChiKey": null,
				"kegg": null,
				"mass": 606.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2608,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3850"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1023"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3398"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3340"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2604"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5631"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4883"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2058"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0692"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6713"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9741"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2442"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2370"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5800"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8437"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0682"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6767"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0028"
					}
				]
			},
			{
				"metaboliteId": "0078d847-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8bb-0000-0000-0000-000000000000",
				"chemicalName": "alpha-tocotrienol",
				"plotName": null,
				"compoundId": "0000927e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06327",
				"inChiKey": "RZFHLOLGZPDCHJ-XZXLULOTBD",
				"kegg": "C14153",
				"mass": 424.334131,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3974,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d848-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebad-0000-0000-0000-000000000000",
				"chemicalName": "guanosine 2'-monophosphate (2'-GMP)*",
				"plotName": null,
				"compoundId": "0000ccda-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WTIFIAZWCCBCGE-UUOKFMHZBV",
				"kegg": null,
				"mass": 363.05844,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3821,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d849-0000-0000-0000-000000000000",
				"chemicalId": "05f603c7-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPC (18:2/18:2)",
				"plotName": null,
				"compoundId": "0000cd7b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08138",
				"inChiKey": "FVXDQWZBHIXIEJ-LNDKUQBDBA",
				"kegg": null,
				"mass": 781.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2096,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9042"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1509"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6706"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0980"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5441"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4591"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1366"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0697"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0633"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1540"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0996"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1208"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7204"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8286"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4523"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4911"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3132"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2676"
					}
				]
			},
			{
				"metaboliteId": "0078d84a-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecad-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-linoleoyl-glycerol (18:1/18:2) [1]",
				"plotName": null,
				"compoundId": "0000b6ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07219",
				"inChiKey": "BLZVZPYMHLXLHG-RQOIEFAZBI",
				"kegg": null,
				"mass": 618.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2626,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6882"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6911"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8669"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6098"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0393"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1083"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8284"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1932"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2424"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2541"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1275"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1076"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9294"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6364"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2074"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1093"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8373"
					}
				]
			},
			{
				"metaboliteId": "0078d84b-0000-0000-0000-000000000000",
				"chemicalId": "05f60474-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-alpha-linolenoyl-GPC (16:0/18:3n3)*",
				"plotName": null,
				"compoundId": "0000cdcc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07975",
				"inChiKey": "JMAYDGBZRHQJAT-QWFQJEORBL",
				"kegg": null,
				"mass": 755.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2028,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1709"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2216"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0992"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0915"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2049"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7967"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1507"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2149"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0342"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0614"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5792"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7104"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2528"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1084"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7713"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9219"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6352"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1416"
					}
				]
			},
			{
				"metaboliteId": "0078d84c-0000-0000-0000-000000000000",
				"chemicalId": "05f6047c-0000-0000-0000-000000000000",
				"chemicalName": "1-linolenoyl-GPA (18:3)*",
				"plotName": null,
				"compoundId": "0000cdd5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 432.22769,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2308,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d84d-0000-0000-0000-000000000000",
				"chemicalId": "05f603d8-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/17:0, d17:1/18:0, d19:1/16:0)",
				"plotName": null,
				"compoundId": "0000cd87-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 716.583225,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2806,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d84e-0000-0000-0000-000000000000",
				"chemicalId": "000000e8-0000-0000-0000-000000000000",
				"chemicalName": "argininosuccinate",
				"plotName": null,
				"compoundId": "00003c89-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00052",
				"inChiKey": "KDZOASGQNOPSCU-UHFFFAOYAF",
				"kegg": "C03406",
				"mass": 290.122636,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 415,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d84f-0000-0000-0000-000000000000",
				"chemicalId": "05f60b95-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyl-alpha-lysine",
				"plotName": null,
				"compoundId": "0000d6e7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LNLLNTMHVMIMOG-YUMQZZPRBS",
				"kegg": null,
				"mass": 275.148121,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 556,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d850-0000-0000-0000-000000000000",
				"chemicalId": "05f5e569-0000-0000-0000-000000000000",
				"chemicalName": "O-acetylhomoserine",
				"plotName": null,
				"compoundId": "00007b33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FCXZBWSIAGGPCB-YFKPBYRVBL",
				"kegg": "C01077",
				"mass": 161.068808,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 22,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2689"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8717"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5356"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0636"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0087"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3231"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9359"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7700"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8183"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5652"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7425"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5965"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3106"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7394"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2069"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5107"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5842"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5498"
					}
				]
			},
			{
				"metaboliteId": "0078d851-0000-0000-0000-000000000000",
				"chemicalId": "000000e7-0000-0000-0000-000000000000",
				"chemicalName": "arginine",
				"plotName": null,
				"compoundId": "00000666-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00517",
				"inChiKey": "ODKSFYDXXFIFQN-UHFFFAOYAT",
				"kegg": "C00062",
				"mass": 174.111676,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 414,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9127"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4395"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1323"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8841"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8277"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1190"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5923"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1544"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3918"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7406"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3331"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0220"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6851"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0267"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4386"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1461"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8880"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2321"
					}
				]
			},
			{
				"metaboliteId": "0078d852-0000-0000-0000-000000000000",
				"chemicalId": "05f5f996-0000-0000-0000-000000000000",
				"chemicalName": "behenoyl sphingomyelin (d18:1/22:0)*",
				"plotName": null,
				"compoundId": "0000bd6c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12103",
				"inChiKey": null,
				"kegg": null,
				"mass": 786.661475,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2794,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d853-0000-0000-0000-000000000000",
				"chemicalId": "05f5f992-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/20:0, d16:1/22:0)*",
				"plotName": null,
				"compoundId": "0000bd6a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12102",
				"inChiKey": "AADLTHQNYQJHQV-SVLGDMRNBJ",
				"kegg": null,
				"mass": 758.630175,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2809,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d854-0000-0000-0000-000000000000",
				"chemicalId": "05f61e8b-0000-0000-0000-000000000000",
				"chemicalName": "ceramide (d18:1/20:0, d16:1/22:0, d20:1/18:0)*",
				"plotName": null,
				"compoundId": "0000e060-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 593.574707,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2739,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d855-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8e3-0000-0000-0000-000000000000",
				"chemicalName": "arabonate/xylonate",
				"plotName": null,
				"compoundId": "0000bc7f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 166.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1133,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2392"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8268"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9761"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8195"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5182"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1614"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3695"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0856"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8561"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5399"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7588"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2560"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2460"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7782"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5643"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4393"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4469"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7468"
					}
				]
			},
			{
				"metaboliteId": "0078d856-0000-0000-0000-000000000000",
				"chemicalId": "05f6047e-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-linolenoyl-GPA (18:2/18:3)*",
				"plotName": null,
				"compoundId": "0000cdd7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 694.457356,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1997,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8385"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1656"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5955"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5646"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0871"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4755"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2125"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9857"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3819"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7305"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6005"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0481"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1914"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6012"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7060"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7629"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3161"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9790"
					}
				]
			},
			{
				"metaboliteId": "0078d857-0000-0000-0000-000000000000",
				"chemicalId": "05f604f4-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dipalmitoyl-GPE (16:0/16:0)*",
				"plotName": null,
				"compoundId": "0000dffd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08923",
				"inChiKey": null,
				"kegg": null,
				"mass": 691.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2139,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d858-0000-0000-0000-000000000000",
				"chemicalId": "05f5e22b-0000-0000-0000-000000000000",
				"chemicalName": "xanthosine",
				"plotName": null,
				"compoundId": "00003b20-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00299",
				"inChiKey": "UBORTCNDUKBEOP-UUOKFMHZBM",
				"kegg": "C01762",
				"mass": 284.075684,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3747,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3454"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1168"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2864"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7325"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3946"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8218"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0044"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0143"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1514"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1933"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1300"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0615"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9621"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1009"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9621"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0392"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8546"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8104"
					}
				]
			},
			{
				"metaboliteId": "0078d859-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec8f-0000-0000-0000-000000000000",
				"chemicalName": "chrysoeriol (3'-O-methylluteolin)",
				"plotName": null,
				"compoundId": "00009e1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB30667",
				"inChiKey": "SCZVLDHREVKTSH-UHFFFAOYAS",
				"kegg": "C04293",
				"mass": 300.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4358,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d85a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e413-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylaspartate (NAA)",
				"plotName": null,
				"compoundId": "000056a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00812",
				"inChiKey": "OTCCIMWXFLJLIA-BYPYZUCNBM",
				"kegg": "C01042",
				"mass": 175.048072,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 36,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2198"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6918"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0759"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5521"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1789"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3108"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4150"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4684"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8907"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6896"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7892"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8011"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9691"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5098"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4891"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3404"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6671"
					}
				]
			},
			{
				"metaboliteId": "0078d85b-0000-0000-0000-000000000000",
				"chemicalId": "00000456-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylalanine",
				"plotName": null,
				"compoundId": "00000631-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00766",
				"inChiKey": "KTHDTJVBEPMMGL-VKHMYHEABO",
				"kegg": "C02847",
				"mass": 131.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 30,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2408"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0943"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2543"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2432"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3286"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.4810"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1712"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4598"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8479"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0288"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2051"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9653"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7596"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1536"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3902"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2724"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2523"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7538"
					}
				]
			},
			{
				"metaboliteId": "0078d85c-0000-0000-0000-000000000000",
				"chemicalId": "00000189-0000-0000-0000-000000000000",
				"chemicalName": "lactose",
				"plotName": null,
				"compoundId": "00000237-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00186",
				"inChiKey": "GUBGYTABKSRVRQ-DCSYEGIMBP",
				"kegg": "C00243",
				"mass": 342.116215,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1151,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8072"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8719"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7764"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4866"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3839"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4202"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3518"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1397"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9486"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1199"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1529"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1341"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0733"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8834"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8881"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9951"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7063"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5600"
					}
				]
			},
			{
				"metaboliteId": "0078d85d-0000-0000-0000-000000000000",
				"chemicalId": "000003a7-0000-0000-0000-000000000000",
				"chemicalName": "sucrose",
				"plotName": null,
				"compoundId": "000005ef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00258",
				"inChiKey": "CZMRCDWAGMRECN-UGDNZRGBBE",
				"kegg": "C00089",
				"mass": 342.116215,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2331"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1507"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1438"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5164"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5859"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4033"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6730"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2247"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7243"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1504"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2437"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0339"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1062"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8662"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7333"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7383"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2984"
					}
				]
			},
			{
				"metaboliteId": "0078d85e-0000-0000-0000-000000000000",
				"chemicalId": "000000b4-0000-0000-0000-000000000000",
				"chemicalName": "linoleate (18:2n6)",
				"plotName": null,
				"compoundId": "00000451-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00673",
				"inChiKey": "OYHQOLUKZRVURQ-HZJYTTRNBX",
				"kegg": "C01595",
				"mass": 280.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1394,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1281"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1519"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1645"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5116"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8940"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4301"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9465"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3027"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9927"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5656"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2763"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9119"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6467"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5590"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4961"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2652"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6668"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0663"
					}
				]
			},
			{
				"metaboliteId": "0078d85f-0000-0000-0000-000000000000",
				"chemicalId": "000004e6-0000-0000-0000-000000000000",
				"chemicalName": "glycerol",
				"plotName": null,
				"compoundId": "00003b12-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00131",
				"inChiKey": "PEDCQBHIVMGVHV-UHFFFAOYAF",
				"kegg": "C00116",
				"mass": 92.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2543,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3975"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5163"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2193"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6799"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1682"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2226"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7757"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3886"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0151"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1746"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5057"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2557"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4210"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7628"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2542"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6811"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8635"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7834"
					}
				]
			},
			{
				"metaboliteId": "0078d860-0000-0000-0000-000000000000",
				"chemicalId": "00000062-0000-0000-0000-000000000000",
				"chemicalName": "kynurenate",
				"plotName": null,
				"compoundId": "00000589-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00715",
				"inChiKey": "HCZHHEIFKROPDY-UHFFFAOYAN",
				"kegg": "C01717",
				"mass": 189.042593,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 244,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9859"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0439"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8556"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3525"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5963"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1958"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9837"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6999"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5851"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3961"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2153"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0917"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1501"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2096"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2372"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7337"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8963"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7743"
					}
				]
			},
			{
				"metaboliteId": "0078d861-0000-0000-0000-000000000000",
				"chemicalId": "00000464-0000-0000-0000-000000000000",
				"chemicalName": "citrate",
				"plotName": null,
				"compoundId": "0000061c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00094",
				"inChiKey": "KRKNYBCHXYNGOX-UHFFFAOYAM",
				"kegg": "C00158",
				"mass": 192.027005,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1283,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4416"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2115"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0466"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9788"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9405"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4434"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7542"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4768"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2235"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2915"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9599"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1297"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6649"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5527"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7909"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5751"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1602"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2923"
					}
				]
			},
			{
				"metaboliteId": "0078d862-0000-0000-0000-000000000000",
				"chemicalId": "00000088-0000-0000-0000-000000000000",
				"chemicalName": "cholate",
				"plotName": null,
				"compoundId": "0000593a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00619",
				"inChiKey": "BHQCQFFYRZLCQQ-OELDTZBJBZ",
				"kegg": "C00695",
				"mass": 408.287574,
				"pathwayType": "ANIMAL",
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3087,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d863-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fc-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylisoleucine",
				"plotName": null,
				"compoundId": "000084af-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61684",
				"inChiKey": "JDTWZSUNGHMMJM-MSZQBOFLBT",
				"kegg": null,
				"mass": 173.105193,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 331,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3290"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1567"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3043"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3498"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0423"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2891"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4498"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6005"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8029"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3933"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3076"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0363"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2849"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3378"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1325"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1847"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3059"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0364"
					}
				]
			},
			{
				"metaboliteId": "0078d864-0000-0000-0000-000000000000",
				"chemicalId": "0000036f-0000-0000-0000-000000000000",
				"chemicalName": "raffinose",
				"plotName": null,
				"compoundId": "0000024a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03213",
				"inChiKey": "MUPFEKGTMRGPLJ-ZQSKZDJDBO",
				"kegg": "C00492",
				"mass": 504.16904,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1182,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3960"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0019"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7598"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1071"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6776"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4726"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4582"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1415"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3729"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9050"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0427"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2877"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2326"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3578"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4797"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9381"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9374"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1629"
					}
				]
			},
			{
				"metaboliteId": "0078d865-0000-0000-0000-000000000000",
				"chemicalId": "000003fd-0000-0000-0000-000000000000",
				"chemicalName": "5-oxoproline",
				"plotName": null,
				"compoundId": "000005d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00267",
				"inChiKey": "ODHCTXKNWHHXJC-VKHMYHEABY",
				"kegg": "C01879",
				"mass": 129.042594,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 499,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9742"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2093"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2058"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7224"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7367"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3555"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4922"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7447"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4400"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0083"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5653"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7187"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3823"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6978"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1168"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0037"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4125"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6058"
					}
				]
			},
			{
				"metaboliteId": "0078d866-0000-0000-0000-000000000000",
				"chemicalId": "05f5e227-0000-0000-0000-000000000000",
				"chemicalName": "tartarate",
				"plotName": null,
				"compoundId": "00003be8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00956",
				"inChiKey": "FEWJPZIEWOKRBE-UHFFFAOYAZ",
				"kegg": "C00898",
				"mass": 150.016438,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3571"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6474"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7429"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8098"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8167"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8765"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8373"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9442"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0372"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7253"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1401"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1661"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5108"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3521"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8496"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5618"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4013"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1083"
					}
				]
			},
			{
				"metaboliteId": "0078d867-0000-0000-0000-000000000000",
				"chemicalId": "00000372-0000-0000-0000-000000000000",
				"chemicalName": "thymine",
				"plotName": null,
				"compoundId": "0000025c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00262",
				"inChiKey": "RWQNBRDOKXIBIV-UHFFFAOYAL",
				"kegg": "C00178",
				"mass": 126.042928,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3892,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d868-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e4-0000-0000-0000-000000000000",
				"chemicalName": "methyl-4-hydroxybenzoate",
				"plotName": null,
				"compoundId": "00008652-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB32572",
				"inChiKey": "LXCFILQKKLGQFO-UHFFFAOYAH",
				"kegg": "D01400",
				"mass": 152.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4100,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d869-0000-0000-0000-000000000000",
				"chemicalId": "000003fc-0000-0000-0000-000000000000",
				"chemicalName": "glucarate (saccharate)",
				"plotName": null,
				"compoundId": "000005c4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00663",
				"inChiKey": "DSLZVSRJTYRBFB-LLEIAEIEBD",
				"kegg": "C00818",
				"mass": 210.03757,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3955,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d86a-0000-0000-0000-000000000000",
				"chemicalId": "000003ea-0000-0000-0000-000000000000",
				"chemicalName": "allantoin",
				"plotName": null,
				"compoundId": "00000453-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00462",
				"inChiKey": "POJWUDADGALRAB-UHFFFAOYAE",
				"kegg": "C02350",
				"mass": 158.043991,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3755,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2604"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4783"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2205"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5938"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2106"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6166"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7491"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0579"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6940"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9417"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8869"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1047"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0527"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0882"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9504"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4874"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7015"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2175"
					}
				]
			},
			{
				"metaboliteId": "0078d86b-0000-0000-0000-000000000000",
				"chemicalId": "000001bd-0000-0000-0000-000000000000",
				"chemicalName": "orotate",
				"plotName": null,
				"compoundId": "000005e1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00226",
				"inChiKey": "PXQPEWDEAKTCGB-UHFFFAOYAD",
				"kegg": "C00295",
				"mass": 156.017108,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3825,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7016"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8838"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7224"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4004"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2526"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5984"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4834"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8366"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7146"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2107"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0130"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1371"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1825"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9168"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9410"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4694"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6752"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0523"
					}
				]
			},
			{
				"metaboliteId": "0078d86c-0000-0000-0000-000000000000",
				"chemicalId": "0000041c-0000-0000-0000-000000000000",
				"chemicalName": "glycerate",
				"plotName": null,
				"compoundId": "00000624-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00139",
				"inChiKey": "RBNPOMFGQQGHHO-UHFFFAOYAE",
				"kegg": "C00258",
				"mass": 106.02661,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1093,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5858"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6412"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2362"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1474"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0980"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0196"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4622"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7424"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5518"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6068"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3033"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7992"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6222"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8804"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6225"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0135"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5440"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4775"
					}
				]
			},
			{
				"metaboliteId": "0078d86d-0000-0000-0000-000000000000",
				"chemicalId": "0000033a-0000-0000-0000-000000000000",
				"chemicalName": "xylose",
				"plotName": null,
				"compoundId": "00003cdd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00098",
				"inChiKey": "PYMYPHUHKUWMLA-WISUUJSJBP",
				"kegg": "C00181",
				"mass": 150.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1117,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0568"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0671"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1555"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0343"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1540"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0105"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8784"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1878"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6106"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5832"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4024"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5832"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3559"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3201"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2719"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1055"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1647"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0725"
					}
				]
			},
			{
				"metaboliteId": "0078d86e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e614-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxyisovalerate",
				"plotName": null,
				"compoundId": "0000b5c9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00407",
				"inChiKey": "NGEWQZIDQIYUNV-UHFFFAOYAS",
				"kegg": null,
				"mass": 118.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7584"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6852"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9802"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9003"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7343"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9028"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1475"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7727"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5217"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5438"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0431"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6421"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3178"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9778"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2916"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7037"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7842"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7587"
					}
				]
			},
			{
				"metaboliteId": "0078d86f-0000-0000-0000-000000000000",
				"chemicalId": "000000b5-0000-0000-0000-000000000000",
				"chemicalName": "laurate (12:0)",
				"plotName": null,
				"compoundId": "0000066d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00638",
				"inChiKey": "POULHZVOKOAJMA-UHFFFAOYAP",
				"kegg": "C02679",
				"mass": 200.17763,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1337,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0380"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3366"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6831"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6363"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1977"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0351"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2795"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2673"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9639"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3949"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9819"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2374"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3238"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4459"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0805"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0074"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8114"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3762"
					}
				]
			},
			{
				"metaboliteId": "0078d870-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6a6-0000-0000-0000-000000000000",
				"chemicalName": "5-methyluridine (ribothymidine)",
				"plotName": null,
				"compoundId": "00008940-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00884",
				"inChiKey": "DWRXFEITVBNRMK-JXOAFFINBW",
				"kegg": null,
				"mass": 258.085186,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3842,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d871-0000-0000-0000-000000000000",
				"chemicalId": "05f5e752-0000-0000-0000-000000000000",
				"chemicalName": "1-myristoylglycerol (14:0)",
				"plotName": null,
				"compoundId": "00008b29-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11561",
				"inChiKey": "DCBSHORRWZKAKO-UHFFFAOYAJ",
				"kegg": "C01885",
				"mass": 302.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7495"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7258"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5179"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6622"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6838"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8014"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3065"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8775"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1411"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9042"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8384"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0127"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5262"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8014"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6602"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8718"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0706"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5261"
					}
				]
			},
			{
				"metaboliteId": "0078d872-0000-0000-0000-000000000000",
				"chemicalId": "05f5f293-0000-0000-0000-000000000000",
				"chemicalName": "6-oxopiperidine-2-carboxylate",
				"plotName": null,
				"compoundId": "0000a8df-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61705",
				"inChiKey": "FZXCPFJMYOQZCA-UHFFFAOYAC",
				"kegg": null,
				"mass": 143.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 124,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4526"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2604"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8630"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6654"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3319"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2601"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3211"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8271"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7334"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6541"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4763"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6267"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0824"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7720"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6582"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8931"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3475"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1264"
					}
				]
			},
			{
				"metaboliteId": "0078d873-0000-0000-0000-000000000000",
				"chemicalId": "0000003e-0000-0000-0000-000000000000",
				"chemicalName": "12,13-DiHOME",
				"plotName": null,
				"compoundId": "000095fb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04705",
				"inChiKey": "CQSLTKIXAJTQGA-FLIBITNWBI",
				"kegg": "C14829",
				"mass": 314.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1794,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5971"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2975"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8236"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9579"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1720"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6768"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7845"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2035"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9265"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3585"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5433"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8092"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6398"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7589"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2538"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8394"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5128"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3492"
					}
				]
			},
			{
				"metaboliteId": "0078d874-0000-0000-0000-000000000000",
				"chemicalId": "05f5f755-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPG (16:0)*",
				"plotName": null,
				"compoundId": "0000b392-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BVJSKAUUFXBDOB-UHFFFAOYAJ",
				"kegg": null,
				"mass": 484.28012,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2415,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2890"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1120"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2902"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6618"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0044"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4109"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7097"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0080"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4485"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0881"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2877"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7241"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7761"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9201"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5556"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1192"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2946"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9011"
					}
				]
			},
			{
				"metaboliteId": "0078d875-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee65-0000-0000-0000-000000000000",
				"chemicalName": "equol sulfate",
				"plotName": null,
				"compoundId": "00009e1e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "UXOJWGSGKUYMIA-UHFFFAOYAD",
				"kegg": null,
				"mass": 322.051109,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4400,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d876-0000-0000-0000-000000000000",
				"chemicalId": "05f5e87f-0000-0000-0000-000000000000",
				"chemicalName": "syringic acid",
				"plotName": null,
				"compoundId": "000090e3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02085",
				"inChiKey": "JMSVCTWVEWCHDZ-UHFFFAOYAU",
				"kegg": "C10833",
				"mass": 198.052823,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d877-0000-0000-0000-000000000000",
				"chemicalId": "000004b7-0000-0000-0000-000000000000",
				"chemicalName": "phenethylamine",
				"plotName": null,
				"compoundId": "00002f6e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02017",
				"inChiKey": "BHHGXPLMPWCGHP-UHFFFAOYAE",
				"kegg": "C05332",
				"mass": 121.089149,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 144,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8217"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1106"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1150"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9526"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9324"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0889"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1431"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4164"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4616"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9553"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7229"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6051"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9578"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0971"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7946"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0941"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6285"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4472"
					}
				]
			},
			{
				"metaboliteId": "0078d878-0000-0000-0000-000000000000",
				"chemicalId": "0000005d-0000-0000-0000-000000000000",
				"chemicalName": "alpha-ketoglutarate",
				"plotName": null,
				"compoundId": "00000210-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00208",
				"inChiKey": "KPGXRSRHYNQIFN-UHFFFAOYAN",
				"kegg": "C00026",
				"mass": 146.021525,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3229"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1148"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8232"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2521"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0890"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2778"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0791"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3118"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4585"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0293"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0578"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2007"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0068"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3473"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5850"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4147"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4147"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0257"
					}
				]
			},
			{
				"metaboliteId": "0078d879-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2e7-0000-0000-0000-000000000000",
				"chemicalName": "glycylvaline",
				"plotName": null,
				"compoundId": "000047b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28854",
				"inChiKey": "STKYPAFSDFAEPH-LURJTMIEBB",
				"kegg": null,
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 739,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d87a-0000-0000-0000-000000000000",
				"chemicalId": "00000136-0000-0000-0000-000000000000",
				"chemicalName": "cystathionine",
				"plotName": null,
				"compoundId": "00003d59-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00099",
				"inChiKey": "ILRYLPWNYFXEMH-UHFFFAOYAH",
				"kegg": "C02291",
				"mass": 222.06743,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d87b-0000-0000-0000-000000000000",
				"chemicalId": "00000803-0000-0000-0000-000000000000",
				"chemicalName": "methylsuccinate",
				"plotName": null,
				"compoundId": "00003d81-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01844",
				"inChiKey": "WXUAQHNMJWJLTG-UHFFFAOYAC",
				"kegg": null,
				"mass": 132.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 348,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6584"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1597"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6125"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6904"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1237"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3698"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0451"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0758"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8762"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0562"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1874"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8038"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2564"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1884"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1504"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7735"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5999"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3858"
					}
				]
			},
			{
				"metaboliteId": "0078d87c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed26-0000-0000-0000-000000000000",
				"chemicalName": "indoleacetylaspartate",
				"plotName": null,
				"compoundId": "00009abd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB38666",
				"inChiKey": "VAFNMNRKDDAKRM-UHFFFAOYAD",
				"kegg": null,
				"mass": 290.090272,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4225,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d87d-0000-0000-0000-000000000000",
				"chemicalId": "000000f9-0000-0000-0000-000000000000",
				"chemicalName": "carnosine",
				"plotName": null,
				"compoundId": "000006e8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00033",
				"inChiKey": "CQOVPNPJLQNMDC-ZETCQYMHBX",
				"kegg": "C00386",
				"mass": 226.106591,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 79,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d87e-0000-0000-0000-000000000000",
				"chemicalId": "00000187-0000-0000-0000-000000000000",
				"chemicalName": "citrulline",
				"plotName": null,
				"compoundId": "00000854-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00904",
				"inChiKey": "RHGKLRLOHDJJDR-UHFFFAOYAQ",
				"kegg": "C00327",
				"mass": 175.095692,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 423,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8723"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9251"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7926"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7791"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6520"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7574"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3313"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1434"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2052"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0385"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3694"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5380"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0283"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7177"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0500"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6203"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4451"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9539"
					}
				]
			},
			{
				"metaboliteId": "0078d87f-0000-0000-0000-000000000000",
				"chemicalId": "000001f8-0000-0000-0000-000000000000",
				"chemicalName": "serotonin",
				"plotName": null,
				"compoundId": "00000926-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00259",
				"inChiKey": "QZAYGJVTTNCVMB-UHFFFAOYAX",
				"kegg": "C00780",
				"mass": 176.094963,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6182"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9988"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2175"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2834"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4011"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4160"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3148"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2807"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6567"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9595"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7247"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9841"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4160"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2205"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0841"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0594"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4008"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2627"
					}
				]
			},
			{
				"metaboliteId": "0078d880-0000-0000-0000-000000000000",
				"chemicalId": "000000ab-0000-0000-0000-000000000000",
				"chemicalName": "hypoxanthine",
				"plotName": null,
				"compoundId": "00000c37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00157",
				"inChiKey": "FDGQSTZJBFJUBT-UHFFFAOYAJ",
				"kegg": "C00262",
				"mass": 136.038511,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3744,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2031"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2666"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9741"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7972"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8571"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4577"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8357"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1144"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1490"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1773"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6195"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0249"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2736"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2265"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3825"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1250"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2381"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2411"
					}
				]
			},
			{
				"metaboliteId": "0078d881-0000-0000-0000-000000000000",
				"chemicalId": "00000447-0000-0000-0000-000000000000",
				"chemicalName": "tryptamine",
				"plotName": null,
				"compoundId": "000017d8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00303",
				"inChiKey": "APJYDQYYACXCRM-UHFFFAOYAU",
				"kegg": "C00398",
				"mass": 160.100048,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 264,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6400"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5221"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7129"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5084"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1834"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1447"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1775"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3261"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4811"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2662"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7780"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5605"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7844"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6330"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0566"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0885"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8999"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6361"
					}
				]
			},
			{
				"metaboliteId": "0078d882-0000-0000-0000-000000000000",
				"chemicalId": "0000053e-0000-0000-0000-000000000000",
				"chemicalName": "3-methoxytyrosine",
				"plotName": null,
				"compoundId": "00002ef1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01434",
				"inChiKey": "PFDUUKDQEHURQC-ZETCQYMHBZ",
				"kegg": null,
				"mass": 211.084459,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 186,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d883-0000-0000-0000-000000000000",
				"chemicalId": "05f5e697-0000-0000-0000-000000000000",
				"chemicalName": "1-pentadecanoylglycerol (15:0)",
				"plotName": null,
				"compoundId": "0000bb1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QSKPZDMBULYMDQ-UHFFFAOYAZ",
				"kegg": null,
				"mass": 316.26136,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d884-0000-0000-0000-000000000000",
				"chemicalId": "00000231-0000-0000-0000-000000000000",
				"chemicalName": "glutamate",
				"plotName": null,
				"compoundId": "00000039-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00148",
				"inChiKey": "WHUUTDBJXJRKMK-UHFFFAOYAD",
				"kegg": "C00025",
				"mass": 147.053159,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 42,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1051"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0110"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6515"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6610"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7554"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7549"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5195"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5057"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2246"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6983"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5227"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1577"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1918"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4996"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1790"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0215"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4774"
					}
				]
			},
			{
				"metaboliteId": "0078d885-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5bb-0000-0000-0000-000000000000",
				"chemicalName": "sebacate (C10-DC)",
				"plotName": null,
				"compoundId": "00007e8e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00792",
				"inChiKey": "CXMXRPHRNRROMY-UHFFFAOYAE",
				"kegg": "C08277",
				"mass": 202.120509,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0300"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0882"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9576"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8334"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6988"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0440"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1922"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5389"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5302"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8732"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0519"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7927"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8745"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4838"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5373"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1367"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7777"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4795"
					}
				]
			},
			{
				"metaboliteId": "0078d886-0000-0000-0000-000000000000",
				"chemicalId": "00000188-0000-0000-0000-000000000000",
				"chemicalName": "saccharopine",
				"plotName": null,
				"compoundId": "000005d7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00279",
				"inChiKey": "ZDGJAHTZVHVLOT-YUMQZZPRBV",
				"kegg": "C00449",
				"mass": 276.132138,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 114,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3201"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0939"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0552"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1739"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1288"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7501"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9290"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5228"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6563"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1288"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3110"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5695"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3110"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9020"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5763"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5829"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2457"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2969"
					}
				]
			},
			{
				"metaboliteId": "0078d887-0000-0000-0000-000000000000",
				"chemicalId": "00000036-0000-0000-0000-000000000000",
				"chemicalName": "1-methyladenine",
				"plotName": null,
				"compoundId": "000005f7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11599",
				"inChiKey": "HPZMWTNATZPBIH-UHFFFAOYAF",
				"kegg": "C02216",
				"mass": 149.070145,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3770,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7427"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9659"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3641"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0497"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3521"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7103"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5378"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6531"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4090"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2468"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1563"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5632"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1016"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2824"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3394"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5000"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1208"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5934"
					}
				]
			},
			{
				"metaboliteId": "0078d888-0000-0000-0000-000000000000",
				"chemicalId": "00000459-0000-0000-0000-000000000000",
				"chemicalName": "4-acetamidobutanoate",
				"plotName": null,
				"compoundId": "00000616-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03681",
				"inChiKey": "UZTFMUBKZQVKLK-UHFFFAOYAY",
				"kegg": "C02946",
				"mass": 145.073894,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 484,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5112"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0908"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9454"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5899"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7494"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2828"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1196"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4718"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1555"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5811"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8245"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4748"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9229"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9503"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0230"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2027"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9179"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0059"
					}
				]
			},
			{
				"metaboliteId": "0078d889-0000-0000-0000-000000000000",
				"chemicalId": "00000218-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxyuridine",
				"plotName": null,
				"compoundId": "00000584-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00012",
				"inChiKey": "MXHRCPNRJAMMIM-SHYZEUOFBK",
				"kegg": "C00526",
				"mass": 228.074623,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3853,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d88a-0000-0000-0000-000000000000",
				"chemicalId": "05f5fa1e-0000-0000-0000-000000000000",
				"chemicalName": "arabitol/xylitol",
				"plotName": null,
				"compoundId": "0000bef5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 152.068475,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1127,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1446"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5464"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5273"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3942"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9768"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2323"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4730"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8469"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0920"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8882"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0947"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7354"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1225"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0789"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7582"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7027"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0935"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7554"
					}
				]
			},
			{
				"metaboliteId": "0078d88b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e299-0000-0000-0000-000000000000",
				"chemicalName": "2-isopropylmalate",
				"plotName": null,
				"compoundId": "00003d33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00402",
				"inChiKey": "BITYXLXUCSKTJS-UHFFFAOYAE",
				"kegg": "C02504",
				"mass": 176.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1664"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5097"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9590"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1101"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0610"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0961"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2621"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6415"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3201"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4681"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4310"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2440"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9209"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9649"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0869"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3372"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0232"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6722"
					}
				]
			},
			{
				"metaboliteId": "0078d88c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f5e6-0000-0000-0000-000000000000",
				"chemicalName": "1-linolenoyl-GPC (18:3)*",
				"plotName": null,
				"compoundId": "0000b37f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10388",
				"inChiKey": null,
				"kegg": null,
				"mass": 517.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2333,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8042"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0675"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0891"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8175"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4525"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0085"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5782"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0115"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3269"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0545"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2720"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5641"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5264"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2523"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1890"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1890"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1557"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1875"
					}
				]
			},
			{
				"metaboliteId": "0078d88d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e7-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoyl-GPC (16:1)*",
				"plotName": null,
				"compoundId": "000081ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10383",
				"inChiKey": "LFUDDCMNKWEORN-KHPPLWFEBO",
				"kegg": null,
				"mass": 493.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2319,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1438"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7546"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9572"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3952"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4107"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9466"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8094"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7479"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2446"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1066"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9394"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1027"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9617"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5787"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1208"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7291"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3417"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9600"
					}
				]
			},
			{
				"metaboliteId": "0078d88e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f3-0000-0000-0000-000000000000",
				"chemicalName": "piperine",
				"plotName": null,
				"compoundId": "0000848f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29377",
				"inChiKey": "MXXWOMGUGJBKIW-YPCIICBEBY",
				"kegg": "C03882",
				"mass": 285.136493,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d88f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f7-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPC (18:0)",
				"plotName": null,
				"compoundId": "000084a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10384",
				"inChiKey": "IHNKQIMGVNPMTC-RUZDIDTEBJ",
				"kegg": null,
				"mass": 523.36379,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2326,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2822"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2965"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9956"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3654"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3556"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6474"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9478"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3765"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2504"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0549"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2745"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1469"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6037"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6329"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3810"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0445"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8352"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7111"
					}
				]
			},
			{
				"metaboliteId": "0078d890-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f8-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPC (18:1)",
				"plotName": null,
				"compoundId": "0000bc82-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02815",
				"inChiKey": "YAMUFBLWGFFICM-PTGWMXDIBX",
				"kegg": null,
				"mass": 521.34814,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9998"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3391"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7218"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7216"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4035"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2338"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1856"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2726"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1809"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8765"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1800"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2859"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1728"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0909"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1409"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2298"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7682"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3872"
					}
				]
			},
			{
				"metaboliteId": "0078d891-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea14-0000-0000-0000-000000000000",
				"chemicalName": "S-carboxymethyl-L-cysteine",
				"plotName": null,
				"compoundId": "0000bb19-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29415",
				"inChiKey": "GBFLZEXEOZUWRN-UHFFFAOYAN",
				"kegg": "C03727",
				"mass": 179.025228,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Other",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5174,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1089"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1084"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2959"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5057"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5188"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4144"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5515"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4673"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8593"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9648"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0439"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4673"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3905"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1391"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0606"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3258"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1113"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6737"
					}
				]
			},
			{
				"metaboliteId": "0078d892-0000-0000-0000-000000000000",
				"chemicalId": "05f5e754-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoethanolamine",
				"plotName": null,
				"compoundId": "0000924f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00114",
				"inChiKey": "JZNWSCPGTDBMEW-UHFFFAOYAI",
				"kegg": "C01233",
				"mass": 215.055874,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1982,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0270"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5532"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6388"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4830"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9023"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4560"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4419"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1828"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4635"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2202"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8436"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3210"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2349"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9087"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9618"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.0513"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9954"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3823"
					}
				]
			},
			{
				"metaboliteId": "0078d893-0000-0000-0000-000000000000",
				"chemicalId": "05f5e923-0000-0000-0000-000000000000",
				"chemicalName": "N-methyltryptophan",
				"plotName": null,
				"compoundId": "00009250-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CZCIKBSVHDNIDH-UHFFFAOYAT",
				"kegg": "C02983",
				"mass": 218.105528,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 231,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d894-0000-0000-0000-000000000000",
				"chemicalId": "05f5e621-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylmethionine",
				"plotName": null,
				"compoundId": "0000af48-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29155",
				"inChiKey": "RQNSKRXMANOPQY-BQBZGAKWBR",
				"kegg": null,
				"mass": 278.093642,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 558,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d895-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec00-0000-0000-0000-000000000000",
				"chemicalName": "sulforaphane",
				"plotName": null,
				"compoundId": "00009729-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05792",
				"inChiKey": "SUVMJBTUFCVSAD-UHFFFAOYAY",
				"kegg": null,
				"mass": 177.028205,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4554,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d896-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3cb-0000-0000-0000-000000000000",
				"chemicalName": "1-methylguanidine",
				"plotName": null,
				"compoundId": "0000bbf2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01522",
				"inChiKey": "VJQCNCOGZPSOQZ-UHFFFAOYAR",
				"kegg": "C02294",
				"mass": 73.063997,
				"pathwayType": "ANIMAL",
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 485,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d897-0000-0000-0000-000000000000",
				"chemicalId": "05f5e70e-0000-0000-0000-000000000000",
				"chemicalName": "homostachydrine*",
				"plotName": null,
				"compoundId": "000080f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33433",
				"inChiKey": "XULZWQRXYTVUTE-ZETCQYMHBF",
				"kegg": "C08283",
				"mass": 157.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4440,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4986"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4700"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5122"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4078"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3363"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2906"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0881"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1191"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1289"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1595"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3371"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2638"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2832"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2496"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4881"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5553"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5553"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3622"
					}
				]
			},
			{
				"metaboliteId": "0078d898-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5eb-0000-0000-0000-000000000000",
				"chemicalName": "S-methylglutathione",
				"plotName": null,
				"compoundId": "00008498-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QTQDDTSVRVWHMO-UHFFFAOYAD",
				"kegg": "C11347",
				"mass": 321.099456,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 494,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d899-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f2-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylarginine",
				"plotName": null,
				"compoundId": "000084a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04620",
				"inChiKey": "SNEIUMQYRCDYCH-LURJTMIEBE",
				"kegg": "C02562",
				"mass": 216.12224,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 430,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0210"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4433"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2506"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4509"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4148"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1351"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8333"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5502"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1143"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8389"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6146"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7445"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2093"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2112"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4693"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0400"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3162"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4514"
					}
				]
			},
			{
				"metaboliteId": "0078d89a-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed71-0000-0000-0000-000000000000",
				"chemicalName": "leucylglycine",
				"plotName": null,
				"compoundId": "00009c6d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28929",
				"inChiKey": "LESXFEZIFXFIQR-UHFFFAOYAD",
				"kegg": null,
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 782,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3000"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2639"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8557"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2139"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3500"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7366"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0840"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3210"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8581"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1377"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3908"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3908"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7498"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2272"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7055"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6129"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8242"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4776"
					}
				]
			},
			{
				"metaboliteId": "0078d89b-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee45-0000-0000-0000-000000000000",
				"chemicalName": "trimethylamine N-oxide",
				"plotName": null,
				"compoundId": "00009dd6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00925",
				"inChiKey": "UYPYRKYUKCHHIB-UHFFFAOYAU",
				"kegg": "C01104",
				"mass": 75.068414,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1985,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7242"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8460"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0348"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7960"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7767"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4269"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7008"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3319"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0834"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2145"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3319"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8669"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4237"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8695"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8583"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8723"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9403"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8158"
					}
				]
			},
			{
				"metaboliteId": "0078d89c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e72d-0000-0000-0000-000000000000",
				"chemicalName": "N-methylleucine",
				"plotName": null,
				"compoundId": "00008b52-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "XJODGRWDFZVTKW-LURJTMIEBL",
				"kegg": null,
				"mass": 145.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 295,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d89d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e8-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/14:0, d16:1/16:0)*",
				"plotName": null,
				"compoundId": "0000a5df-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12097",
				"inChiKey": "KYICBZWZQPCUMO-PSALXKTOBW",
				"kegg": null,
				"mass": 674.536275,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2801,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d89e-0000-0000-0000-000000000000",
				"chemicalId": "05f60537-0000-0000-0000-000000000000",
				"chemicalName": "(S)-3-hydroxybutyrylcarnitine",
				"plotName": null,
				"compoundId": "0000cef8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13127",
				"inChiKey": null,
				"kegg": null,
				"mass": 247.141973,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1696,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d89f-0000-0000-0000-000000000000",
				"chemicalId": "05f60511-0000-0000-0000-000000000000",
				"chemicalName": "palmitoylcholine",
				"plotName": null,
				"compoundId": "0000ced0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 341.32938,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1714,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6696"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3553"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3223"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7526"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3664"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6214"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1631"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4561"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4342"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3495"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3788"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4801"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4342"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3089"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0726"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2679"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7579"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1669"
					}
				]
			},
			{
				"metaboliteId": "0078d8a0-0000-0000-0000-000000000000",
				"chemicalId": "05f5edbc-0000-0000-0000-000000000000",
				"chemicalName": "carboxyethyl-GABA",
				"plotName": null,
				"compoundId": "00009c47-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02201",
				"inChiKey": "SRGQUICKDUQCKO-UHFFFAOYAD",
				"kegg": null,
				"mass": 175.084458,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 56,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5405"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0332"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8295"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9855"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0612"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9914"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1219"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4103"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2413"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4014"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2989"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2706"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5074"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1123"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0543"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0126"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5948"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3701"
					}
				]
			},
			{
				"metaboliteId": "0078d8a1-0000-0000-0000-000000000000",
				"chemicalId": "05f5f01a-0000-0000-0000-000000000000",
				"chemicalName": "coniferin",
				"plotName": null,
				"compoundId": "0000a9ec-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13682",
				"inChiKey": "SFLMUHDGSQZDOW-FAOXUISGBA",
				"kegg": "C00761",
				"mass": 342.131468,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4346,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2824"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3813"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4460"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4285"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4068"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3778"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4542"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4676"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5182"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.4025"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1497"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7543"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0994"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7877"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7257"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7996"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5329"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1344"
					}
				]
			},
			{
				"metaboliteId": "0078d8a2-0000-0000-0000-000000000000",
				"chemicalId": "05f5f282-0000-0000-0000-000000000000",
				"chemicalName": "soyasaponin II",
				"plotName": null,
				"compoundId": "0000aa45-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34650",
				"inChiKey": null,
				"kegg": "C12081",
				"mass": 912.508251,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4548,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8a3-0000-0000-0000-000000000000",
				"chemicalId": "05f5f281-0000-0000-0000-000000000000",
				"chemicalName": "soyasaponin I",
				"plotName": null,
				"compoundId": "0000aa46-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34649",
				"inChiKey": null,
				"kegg": "C08983",
				"mass": 942.518816,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4547,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8a4-0000-0000-0000-000000000000",
				"chemicalId": "05f60481-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linolenoyl-GPA (16:0/18:3)*",
				"plotName": null,
				"compoundId": "0000cdd6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 670.457356,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1988,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4852"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0886"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4700"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7481"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0954"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3211"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8563"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6845"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3251"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4306"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1577"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8913"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0726"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6568"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0072"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4603"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9596"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3694"
					}
				]
			},
			{
				"metaboliteId": "0078d8a5-0000-0000-0000-000000000000",
				"chemicalId": "000001b7-0000-0000-0000-000000000000",
				"chemicalName": "stearate (18:0)",
				"plotName": null,
				"compoundId": "0000054e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00827",
				"inChiKey": "QIQXTHQIDYTFRH-UHFFFAOYAB",
				"kegg": "C01530",
				"mass": 284.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1345,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2118"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1399"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3309"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2574"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1603"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2209"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0202"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7365"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5068"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4492"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0100"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8987"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3055"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1363"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0367"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1701"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3657"
					}
				]
			},
			{
				"metaboliteId": "0078d8a6-0000-0000-0000-000000000000",
				"chemicalId": "0000039e-0000-0000-0000-000000000000",
				"chemicalName": "caproate (6:0)",
				"plotName": null,
				"compoundId": "00007ee9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00535",
				"inChiKey": "FUZZWVXGSFPDMH-UHFFFAOYAY",
				"kegg": "C01585",
				"mass": 116.08373,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8a7-0000-0000-0000-000000000000",
				"chemicalId": "05f6057f-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-linolenoyl-GPC (18:2/18:3)*",
				"plotName": null,
				"compoundId": "0000cfb8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08141",
				"inChiKey": null,
				"kegg": null,
				"mass": 779.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2097,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1688"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3938"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0526"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2348"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2903"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9068"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1742"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2253"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1690"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0932"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4225"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5859"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1223"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2802"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5546"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8342"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3788"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1192"
					}
				]
			},
			{
				"metaboliteId": "0078d8a8-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbb-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linolenoyl-glycerol (16:0/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6b5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07105",
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2609,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3611"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0286"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3717"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0018"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5835"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0528"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5158"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5442"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4642"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0322"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5286"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4630"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8428"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5600"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8622"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8465"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2764"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5025"
					}
				]
			},
			{
				"metaboliteId": "0078d8a9-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb2-0000-0000-0000-000000000000",
				"chemicalName": "palmitoleoyl-linoleoyl-glycerol (16:1/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000d6b7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07132",
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2612,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4744"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4061"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2188"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9997"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9496"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3254"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8969"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2630"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3805"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4381"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1196"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9994"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9767"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1109"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9319"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5942"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5761"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9888"
					}
				]
			},
			{
				"metaboliteId": "0078d8aa-0000-0000-0000-000000000000",
				"chemicalId": "05f60bc4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-palmitoyl-glycerol (16:0/16:0) [2]*",
				"plotName": null,
				"compoundId": "0000d6ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07098",
				"inChiKey": null,
				"kegg": null,
				"mass": 568.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2602,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5981"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4615"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8101"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1237"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6188"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1264"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2228"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4351"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1473"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4856"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5796"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0904"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0058"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1457"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5695"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3789"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3789"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5586"
					}
				]
			},
			{
				"metaboliteId": "0078d8ab-0000-0000-0000-000000000000",
				"chemicalId": "05f5e73d-0000-0000-0000-000000000000",
				"chemicalName": "tiglylcarnitine (C5:1-DC)",
				"plotName": null,
				"compoundId": "00008a64-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02366",
				"inChiKey": "WURBQCVBQNMUQT-RMKNXTFCBN",
				"kegg": null,
				"mass": 243.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 342,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8ac-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4af-0000-0000-0000-000000000000",
				"chemicalName": "2-oleoylglycerol (18:1)",
				"plotName": null,
				"compoundId": "000052f0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11537",
				"inChiKey": "UPWGQKDVAURUGE-KTKRTIGZBO",
				"kegg": null,
				"mass": 356.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2572,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3280"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0598"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0060"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0186"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1986"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3580"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6325"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0515"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5227"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3734"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6975"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0746"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0035"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2609"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0949"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6707"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9639"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2201"
					}
				]
			},
			{
				"metaboliteId": "0078d8ad-0000-0000-0000-000000000000",
				"chemicalId": "05f61e90-0000-0000-0000-000000000000",
				"chemicalName": "linoleoylcholine*",
				"plotName": null,
				"compoundId": "0000e077-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 365.329376,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1719,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6218"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6113"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3005"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9606"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4263"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8567"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3547"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0841"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1925"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1686"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1069"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3393"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5044"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1554"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1429"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4868"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3435"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3364"
					}
				]
			},
			{
				"metaboliteId": "0078d8ae-0000-0000-0000-000000000000",
				"chemicalId": "05f61eaa-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:0/20:0, d16:0/22:0)*",
				"plotName": null,
				"compoundId": "0000e084-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 760.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2790,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8af-0000-0000-0000-000000000000",
				"chemicalId": "05f603ca-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dioleoyl-GPE (18:1/18:1)",
				"plotName": null,
				"compoundId": "0000cd81-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "MWRBNPKJOOWZPW-NYVOMTAGBY",
				"kegg": null,
				"mass": 743.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2170,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5149"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2800"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3943"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7660"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9409"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2149"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4492"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3371"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8476"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1764"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4151"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5504"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0499"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9134"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3714"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3034"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.7033"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2805"
					}
				]
			},
			{
				"metaboliteId": "0078d8b0-0000-0000-0000-000000000000",
				"chemicalId": "000003e9-0000-0000-0000-000000000000",
				"chemicalName": "trans-4-hydroxyproline",
				"plotName": null,
				"compoundId": "00007e32-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00725",
				"inChiKey": "PMMYEEVYMWASQN-DMTCNVIQBF",
				"kegg": "C01157",
				"mass": 131.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 441,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1744"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0678"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2796"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1942"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2453"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7824"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2255"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4619"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6384"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0091"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9101"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4808"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8872"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8828"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1500"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9373"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0683"
					}
				]
			},
			{
				"metaboliteId": "0078d8b1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e755-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoinositol*",
				"plotName": null,
				"compoundId": "0000b833-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 334.066498,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1984,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4137"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3439"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4908"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0407"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3876"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9971"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6822"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9614"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1504"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3657"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0209"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6912"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4416"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1532"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3464"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5615"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6249"
					}
				]
			},
			{
				"metaboliteId": "0078d8b2-0000-0000-0000-000000000000",
				"chemicalId": "05f5fa26-0000-0000-0000-000000000000",
				"chemicalName": "citraconate/glutaconate",
				"plotName": null,
				"compoundId": "0000b7e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 130.02668,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1304,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0598"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6718"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2608"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1581"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0708"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8496"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9894"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7368"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6144"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0091"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2945"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1287"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2779"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2226"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8151"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0747"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9091"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.8652"
					}
				]
			},
			{
				"metaboliteId": "0078d8b3-0000-0000-0000-000000000000",
				"chemicalId": "0000006f-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyisobutyrate",
				"plotName": null,
				"compoundId": "0000060d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00336",
				"inChiKey": "DBXBTMSZEOQQDU-REWHXWOFAW",
				"kegg": "C06001",
				"mass": 104.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 365,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8b4-0000-0000-0000-000000000000",
				"chemicalId": "00000475-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyphenylpyruvate",
				"plotName": null,
				"compoundId": "00000685-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00707",
				"inChiKey": "KKADPXVIOXHVKN-UHFFFAOYAH",
				"kegg": "C01179",
				"mass": 180.04226,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1245"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3571"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9812"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0160"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3823"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4413"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4455"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2199"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9299"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8545"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1199"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1874"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7908"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5309"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7905"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0828"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1874"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8150"
					}
				]
			},
			{
				"metaboliteId": "0078d8b5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e187-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxyinosine",
				"plotName": null,
				"compoundId": "00003ae4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00071",
				"inChiKey": "VGONTNSXDCQUGY-RRKCRQDMBC",
				"kegg": "C05512",
				"mass": 252.085855,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3751,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8b6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fd-0000-0000-0000-000000000000",
				"chemicalName": "10-nonadecenoate (19:1n9)",
				"plotName": null,
				"compoundId": "000084b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13622",
				"inChiKey": "BBOWBNGUEWHNQZ-KTKRTIGZBX",
				"kegg": null,
				"mass": 296.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1367,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2981"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4322"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8072"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4831"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9236"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5295"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8976"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6854"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8011"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1744"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1057"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4906"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0897"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5392"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5895"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1616"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8161"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1034"
					}
				]
			},
			{
				"metaboliteId": "0078d8b7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e214-0000-0000-0000-000000000000",
				"chemicalName": "maltotriose",
				"plotName": null,
				"compoundId": "0000ae90-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01262",
				"inChiKey": "FYGDTMLNYKFZSV-DZOUCCHMBX",
				"kegg": "C01835",
				"mass": 504.169035,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycogen Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1143,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8734"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6541"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5156"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8292"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9595"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0684"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7822"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9540"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2759"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9417"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0526"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5176"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1565"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7295"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9399"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7819"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8818"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8791"
					}
				]
			},
			{
				"metaboliteId": "0078d8b8-0000-0000-0000-000000000000",
				"chemicalId": "00000217-0000-0000-0000-000000000000",
				"chemicalName": "uridine",
				"plotName": null,
				"compoundId": "0000025e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00296",
				"inChiKey": "DRTQHJPVMGBUCF-XVFCMESIBG",
				"kegg": "C00299",
				"mass": 244.069536,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3837,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6589"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6423"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1982"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4341"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9020"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5849"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8688"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4069"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9169"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3056"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2798"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9696"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3439"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4822"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4493"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4241"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1900"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7491"
					}
				]
			},
			{
				"metaboliteId": "0078d8b9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e405-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyoctanoate",
				"plotName": null,
				"compoundId": "000055f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01954",
				"inChiKey": "NDPLAKGOSZHTPH-UHFFFAOYAI",
				"kegg": null,
				"mass": 160.109944,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5808"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0623"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2262"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9884"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3956"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1102"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4669"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2572"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3748"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1961"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3417"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6844"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7193"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5478"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4805"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6337"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5482"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1539"
					}
				]
			},
			{
				"metaboliteId": "0078d8ba-0000-0000-0000-000000000000",
				"chemicalId": "05f5e680-0000-0000-0000-000000000000",
				"chemicalName": "2-myristoylglycerol (14:0)",
				"plotName": null,
				"compoundId": "0000864f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11530",
				"inChiKey": "TVIMZSOUQXNWHO-UHFFFAOYAT",
				"kegg": null,
				"mass": 302.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2568,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8bb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e69b-0000-0000-0000-000000000000",
				"chemicalName": "1-linolenoylglycerol (18:3)",
				"plotName": null,
				"compoundId": "00008659-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11569",
				"inChiKey": "XQBHBEXBUZDCRY-QNEBEIHSBO",
				"kegg": null,
				"mass": 352.26136,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8333"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5877"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9909"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8207"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7213"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2240"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3561"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5001"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1775"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6106"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1209"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0074"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3189"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0761"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1068"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6433"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2994"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2746"
					}
				]
			},
			{
				"metaboliteId": "0078d8bc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e108-0000-0000-0000-000000000000",
				"chemicalName": "benzoate",
				"plotName": null,
				"compoundId": "00003da2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01870",
				"inChiKey": "WPYMKLBDIGXBTP-UHFFFAOYAD",
				"kegg": "C00180",
				"mass": 122.03678,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4069,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5067"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6051"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7452"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0656"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8772"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0656"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6130"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4597"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2183"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2952"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5574"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5727"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8291"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5038"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2368"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9682"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6721"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4971"
					}
				]
			},
			{
				"metaboliteId": "0078d8bd-0000-0000-0000-000000000000",
				"chemicalId": "000001e2-0000-0000-0000-000000000000",
				"chemicalName": "lactate",
				"plotName": null,
				"compoundId": "0000020f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00190",
				"inChiKey": "JVTAAEKCZFNVCJ-UHFFFAOYAX",
				"kegg": "C00186",
				"mass": 90.031695,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0074"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2445"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2147"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0647"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3735"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5945"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7928"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8665"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8785"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2758"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5806"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0836"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7317"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4245"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4985"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7667"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6467"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8484"
					}
				]
			},
			{
				"metaboliteId": "0078d8be-0000-0000-0000-000000000000",
				"chemicalId": "00000126-0000-0000-0000-000000000000",
				"chemicalName": "mannose-6-phosphate",
				"plotName": null,
				"compoundId": "000005bd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01078",
				"inChiKey": "VFRROHXSMXFLSN-QPDBYLPOBX",
				"kegg": "C00275",
				"mass": 260.029723,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1200,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8bf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e44e-0000-0000-0000-000000000000",
				"chemicalName": "erythritol",
				"plotName": null,
				"compoundId": "000050db-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02994",
				"inChiKey": "UNXHWFMMPAWVPI-UHFFFAOYAU",
				"kegg": "C00503",
				"mass": 122.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4403,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0889"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4673"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8520"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6956"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8137"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6632"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5216"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0418"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1453"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5831"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4025"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7075"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3157"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7319"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0834"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1998"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6244"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0847"
					}
				]
			},
			{
				"metaboliteId": "0078d8c0-0000-0000-0000-000000000000",
				"chemicalId": "0000037b-0000-0000-0000-000000000000",
				"chemicalName": "margarate (17:0)",
				"plotName": null,
				"compoundId": "00000461-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02259",
				"inChiKey": "KEMQGTRYUADPNZ-UHFFFAOYAT",
				"kegg": null,
				"mass": 270.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1344,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2717"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0130"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4673"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2284"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0028"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0107"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6879"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1364"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7924"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2509"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1028"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1498"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9349"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3442"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1129"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6083"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5505"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0243"
					}
				]
			},
			{
				"metaboliteId": "0078d8c1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e54e-0000-0000-0000-000000000000",
				"chemicalName": "dodecanedioate (C12-DC)",
				"plotName": null,
				"compoundId": "00007e84-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00623",
				"inChiKey": "TVIDDXQYHWJXFK-UHFFFAOYAC",
				"kegg": "C02678",
				"mass": 230.151809,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1510,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2837"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1292"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3247"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2223"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0760"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0613"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5034"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5484"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1714"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2762"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7842"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1479"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1714"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3679"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4123"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4284"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "3.2198"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1199"
					}
				]
			},
			{
				"metaboliteId": "0078d8c2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e22c-0000-0000-0000-000000000000",
				"chemicalName": "galactose 1-phosphate",
				"plotName": null,
				"compoundId": "00003d5a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00645",
				"inChiKey": null,
				"kegg": "C00446",
				"mass": 260.029719,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1210,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7247"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8452"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7414"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4761"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2974"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0198"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4366"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2604"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1364"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1324"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5585"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1353"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5644"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2915"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3908"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1193"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2887"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1175"
					}
				]
			},
			{
				"metaboliteId": "0078d8c3-0000-0000-0000-000000000000",
				"chemicalId": "000000c2-0000-0000-0000-000000000000",
				"chemicalName": "N-formylmethionine",
				"plotName": null,
				"compoundId": "00000b0d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01015",
				"inChiKey": "PYUSHNKNPOHWEZ-YFKPBYRVBS",
				"kegg": "C03145",
				"mass": 177.045966,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 373,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8c4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e498-0000-0000-0000-000000000000",
				"chemicalName": "naringenin",
				"plotName": null,
				"compoundId": "0000ae77-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02670",
				"inChiKey": "4000",
				"kegg": "C00509",
				"mass": 272.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4473,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3458"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2164"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5063"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1379"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3707"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7282"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7247"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6176"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8408"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4705"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3382"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4726"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5263"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1305"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0357"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4140"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6687"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5150"
					}
				]
			},
			{
				"metaboliteId": "0078d8c5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6cd-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylisoleucine*",
				"plotName": null,
				"compoundId": "00008699-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11170",
				"inChiKey": "SNCKGJWJABDZHI-SIPWABPFBP",
				"kegg": null,
				"mass": 260.137222,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 554,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8c6-0000-0000-0000-000000000000",
				"chemicalId": "00000816-0000-0000-0000-000000000000",
				"chemicalName": "epicatechin",
				"plotName": null,
				"compoundId": "0000456e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01871",
				"inChiKey": "PFTAWBLQPZVEMU-DZGCQCFKBX",
				"kegg": "C09727",
				"mass": 290.07904,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4241,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8c7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7b6-0000-0000-0000-000000000000",
				"chemicalName": "2,4,6-trihydroxybenzoate",
				"plotName": null,
				"compoundId": "00008c34-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29649",
				"inChiKey": "IBHWREHFNDMRPR-UHFFFAOYAP",
				"kegg": null,
				"mass": 170.021523,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4081,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8032"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5523"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.5759"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0515"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0083"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9238"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7525"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7266"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2365"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5351"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5777"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0516"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4277"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8252"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0125"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9196"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5406"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7424"
					}
				]
			},
			{
				"metaboliteId": "0078d8c8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cb-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linolenate (20:3n3 or n6)",
				"plotName": null,
				"compoundId": "00008b86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02925",
				"inChiKey": "AHANXAKGNAKFSK-PDBXOOCHBM",
				"kegg": "C03242",
				"mass": 306.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1399,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1797"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4762"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8766"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7080"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1308"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7881"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0548"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5233"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6646"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6210"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9840"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5739"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0414"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5233"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1904"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0989"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3957"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0568"
					}
				]
			},
			{
				"metaboliteId": "0078d8c9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6e6-0000-0000-0000-000000000000",
				"chemicalName": "phenol sulfate",
				"plotName": null,
				"compoundId": "00007f29-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60015",
				"inChiKey": "CTYRPMDGLDAWRQ-UHFFFAOYAC",
				"kegg": "C02180",
				"mass": 173.998679,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8ca-0000-0000-0000-000000000000",
				"chemicalId": "000000fe-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxybutyrate (BHBA)",
				"plotName": null,
				"compoundId": "0000021e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00357",
				"inChiKey": "WHBMMWSBFZVSSR-UHFFFAOYAO",
				"kegg": "C01089",
				"mass": 104.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Ketone Bodies",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1710,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3622"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3243"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1426"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2232"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9072"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1727"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0259"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1828"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8096"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8594"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8594"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7530"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6519"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1904"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3717"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1963"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3953"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0238"
					}
				]
			},
			{
				"metaboliteId": "0078d8cb-0000-0000-0000-000000000000",
				"chemicalId": "0000016b-0000-0000-0000-000000000000",
				"chemicalName": "myo-inositol",
				"plotName": null,
				"compoundId": "00000464-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00211",
				"inChiKey": "CDAISMWEOUEBRE-GPIVLXJGBG",
				"kegg": "C00137",
				"mass": 180.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1945,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8292"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9705"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9353"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9045"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6752"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8075"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9084"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3675"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6379"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0093"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4838"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7161"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8559"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0254"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0255"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9815"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4298"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8797"
					}
				]
			},
			{
				"metaboliteId": "0078d8cc-0000-0000-0000-000000000000",
				"chemicalId": "05f5efda-0000-0000-0000-000000000000",
				"chemicalName": "cryptochlorogenic acid",
				"plotName": null,
				"compoundId": "0000a3c4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GYFFKZTYYAFCTR-DUXPYHPUBU",
				"kegg": null,
				"mass": 354.095082,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4376,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8cd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e81b-0000-0000-0000-000000000000",
				"chemicalName": "dihydrokaempferol",
				"plotName": null,
				"compoundId": "000090fc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB30847",
				"inChiKey": null,
				"kegg": "C00974",
				"mass": 288.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4200,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8338"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4462"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6768"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7822"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2757"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2535"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8182"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0422"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1572"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5099"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5099"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3934"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9931"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5485"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7266"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3266"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2073"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0901"
					}
				]
			},
			{
				"metaboliteId": "0078d8ce-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed61-0000-0000-0000-000000000000",
				"chemicalName": "isoleucylglycine",
				"plotName": null,
				"compoundId": "00009c48-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28907",
				"inChiKey": "UCGDDTHMMVWVMV-UHFFFAOYAU",
				"kegg": null,
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8cf-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee91-0000-0000-0000-000000000000",
				"chemicalName": "alliin",
				"plotName": null,
				"compoundId": "0000a216-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33592",
				"inChiKey": "XUHLIQGRKRUKPH-ITZCMCNPBT",
				"kegg": "C08265",
				"mass": 177.045964,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8d0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e539-0000-0000-0000-000000000000",
				"chemicalName": "2-pyrrolidinone",
				"plotName": null,
				"compoundId": "00007bbb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02039",
				"inChiKey": "HNJBEVLQSNELDL-UHFFFAOYAP",
				"kegg": null,
				"mass": 85.052764,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 58,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2613"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9679"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7454"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3210"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2618"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4300"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1925"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2119"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9539"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9489"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4046"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3623"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1617"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8133"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0596"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3940"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4303"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0879"
					}
				]
			},
			{
				"metaboliteId": "0078d8d1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2cf-0000-0000-0000-000000000000",
				"chemicalName": "indolelactate",
				"plotName": null,
				"compoundId": "000047ad-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00671",
				"inChiKey": "XGILAAMKEQUXLS-UHFFFAOYAH",
				"kegg": "C02043",
				"mass": 205.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 266,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8d2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e127-0000-0000-0000-000000000000",
				"chemicalName": "methionine sulfoxide",
				"plotName": null,
				"compoundId": "000047c6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02005",
				"inChiKey": "QEFRNWWLZKMPFJ-YGVKFDHGBW",
				"kegg": "C02989",
				"mass": 165.045966,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 376,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2777"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3832"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9826"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4084"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9487"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4202"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0894"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8264"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1320"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1921"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0413"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0765"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1332"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3158"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0194"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4837"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0635"
					}
				]
			},
			{
				"metaboliteId": "0078d8d3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9b5-0000-0000-0000-000000000000",
				"chemicalName": "dihydroquercetin",
				"plotName": null,
				"compoundId": "0000bb18-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CXQWRCVTCMQVQX-UHFFFAOYAJ",
				"kegg": "C12316",
				"mass": 304.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4392,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8d4-0000-0000-0000-000000000000",
				"chemicalId": "000004c5-0000-0000-0000-000000000000",
				"chemicalName": "creatine",
				"plotName": null,
				"compoundId": "00006c46-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00064",
				"inChiKey": "CVSVTCORWBXHQV-UHFFFAOYAV",
				"kegg": "C00300",
				"mass": 131.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 456,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8d5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2bf-0000-0000-0000-000000000000",
				"chemicalName": "gentisate",
				"plotName": null,
				"compoundId": "00004768-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00152",
				"inChiKey": "WXTMDXOMEHJXQO-UHFFFAOYAO",
				"kegg": "C00628",
				"mass": 154.026609,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 201,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1834"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5603"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3975"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8530"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5855"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7286"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8220"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8538"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9281"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1440"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0794"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1822"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3129"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0416"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4447"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3164"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0399"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0195"
					}
				]
			},
			{
				"metaboliteId": "0078d8d6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e502-0000-0000-0000-000000000000",
				"chemicalName": "galactonate",
				"plotName": null,
				"compoundId": "00006c47-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00565",
				"inChiKey": "RGHNJXZEOKUKBD-SQOUGZDYBY",
				"kegg": "C00880",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1074"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2116"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0682"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6987"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8888"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0460"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0929"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3412"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6987"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0565"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0317"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2938"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7294"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0105"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1816"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3048"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2132"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9326"
					}
				]
			},
			{
				"metaboliteId": "0078d8d7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea71-0000-0000-0000-000000000000",
				"chemicalName": "2,3-dihydroxyisovalerate",
				"plotName": null,
				"compoundId": "00009584-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12141",
				"inChiKey": "JTEYKUFKXGDTEU-UHFFFAOYAT",
				"kegg": "C04039",
				"mass": 134.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4281,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4210"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5300"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1291"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1794"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1854"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1759"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4228"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3674"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2986"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3244"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3178"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9394"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6046"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5847"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2203"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1128"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8571"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0016"
					}
				]
			},
			{
				"metaboliteId": "0078d8d8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e17d-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxycytidine",
				"plotName": null,
				"compoundId": "00003e4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00014",
				"inChiKey": "CKTSBUTUHBMZGZ-SHYZEUOFBO",
				"kegg": "C00881",
				"mass": 227.090607,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3878,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7720"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1025"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7452"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4080"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5229"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0534"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7969"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9196"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9902"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5229"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2993"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2412"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2933"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0343"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0855"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0007"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2287"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6704"
					}
				]
			},
			{
				"metaboliteId": "0078d8d9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e20d-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphorylcholine (GPC)",
				"plotName": null,
				"compoundId": "00003e76-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00086",
				"inChiKey": "SUHOQUVVVLNYQR-MRVPVSSYBR",
				"kegg": "C00670",
				"mass": 257.102824,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1978,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1521"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6347"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6764"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1060"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7279"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3370"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8158"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9979"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0767"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1916"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2511"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7823"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7613"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5431"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7926"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.1092"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8675"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0857"
					}
				]
			},
			{
				"metaboliteId": "0078d8da-0000-0000-0000-000000000000",
				"chemicalId": "000001c0-0000-0000-0000-000000000000",
				"chemicalName": "glutathione, oxidized (GSSG)",
				"plotName": null,
				"compoundId": "00006c4f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03337",
				"inChiKey": "YPZRWBKMTBYPTK-BJDJZHNGBD",
				"kegg": "C00127",
				"mass": 612.151968,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 490,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8db-0000-0000-0000-000000000000",
				"chemicalId": "000001e0-0000-0000-0000-000000000000",
				"chemicalName": "proline",
				"plotName": null,
				"compoundId": "0000076a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00162",
				"inChiKey": "ONIBWKKTOPOVIA-BYPYZUCNBZ",
				"kegg": "C00148",
				"mass": 115.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 426,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7442"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6326"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0105"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3311"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9774"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2935"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2297"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5502"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4377"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5542"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9758"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0090"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7185"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7980"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5572"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1326"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9564"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1874"
					}
				]
			},
			{
				"metaboliteId": "0078d8dc-0000-0000-0000-000000000000",
				"chemicalId": "05f5f87f-0000-0000-0000-000000000000",
				"chemicalName": "eriocitrin (eriodictyol 7-O-rutinoside)",
				"plotName": null,
				"compoundId": "0000bced-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05811",
				"inChiKey": "AZXIAVOSESIQNG-SCXABSQUBW",
				"kegg": null,
				"mass": 596.17412,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4623,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8dd-0000-0000-0000-000000000000",
				"chemicalId": "05f5f65a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltaurine",
				"plotName": null,
				"compoundId": "0000bc3b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CXJAAWRLVGAKDV-UHFFFAOYAS",
				"kegg": null,
				"mass": 167.025228,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 408,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8de-0000-0000-0000-000000000000",
				"chemicalId": "000000ea-0000-0000-0000-000000000000",
				"chemicalName": "aspartate",
				"plotName": null,
				"compoundId": "000001bb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00191",
				"inChiKey": "CKLJMWTZIZZHCS-REOHCLBHBW",
				"kegg": "C00049",
				"mass": 133.037509,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 35,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0773"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1789"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2360"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8365"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8935"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0076"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2257"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5428"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0831"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9075"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9106"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1990"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8511"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7104"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3987"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6525"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5378"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6467"
					}
				]
			},
			{
				"metaboliteId": "0078d8df-0000-0000-0000-000000000000",
				"chemicalId": "05f5e408-0000-0000-0000-000000000000",
				"chemicalName": "palmitoylcarnitine (C16)",
				"plotName": null,
				"compoundId": "0000ae89-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00222",
				"inChiKey": "XOMRRQXKHMYMOC-UHFFFAOYAU",
				"kegg": "C02990",
				"mass": 399.334859,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1648,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8e0-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebd5-0000-0000-0000-000000000000",
				"chemicalName": "solanidine",
				"plotName": null,
				"compoundId": "00009b6c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03236",
				"inChiKey": "JVKYZPBMZPJNAJ-OQFNDJACBI",
				"kegg": "C06543",
				"mass": 397.334465,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4544,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8e1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e721-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPE (18:1)",
				"plotName": null,
				"compoundId": "00008b2c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11506",
				"inChiKey": "PYVRVRFVLRNJLY-MZMPXXGTBD",
				"kegg": null,
				"mass": 479.301189,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2380,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3404"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0275"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3041"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4564"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6120"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8242"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7470"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3549"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7044"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1011"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3236"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9884"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5111"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1165"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2989"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2553"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5491"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3130"
					}
				]
			},
			{
				"metaboliteId": "0078d8e2-0000-0000-0000-000000000000",
				"chemicalId": "05f5f31b-0000-0000-0000-000000000000",
				"chemicalName": "methionine sulfone",
				"plotName": null,
				"compoundId": "0000af4e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "UCUNFLYVYCGDHP-UHFFFAOYAG",
				"kegg": null,
				"mass": 181.040879,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 375,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5098"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3702"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5005"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7059"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3990"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2473"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3071"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3081"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4786"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6195"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7796"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7886"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7886"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3757"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1562"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4344"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3106"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0160"
					}
				]
			},
			{
				"metaboliteId": "0078d8e3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e51e-0000-0000-0000-000000000000",
				"chemicalName": "butyrylcarnitine (C4)",
				"plotName": null,
				"compoundId": "00007e9c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02013",
				"inChiKey": "QWYFHHGCZUCMBN-SECBINFHBA",
				"kegg": "C02862",
				"mass": 231.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1592,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8e4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e780-0000-0000-0000-000000000000",
				"chemicalName": "N6-succinyladenosine",
				"plotName": null,
				"compoundId": "0000bc02-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00912",
				"inChiKey": null,
				"kegg": null,
				"mass": 383.107713,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2268"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1372"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5402"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9841"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2779"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7434"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9861"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3233"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9057"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1923"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2154"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4414"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0441"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4634"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6399"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4414"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5583"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8892"
					}
				]
			},
			{
				"metaboliteId": "0078d8e5-0000-0000-0000-000000000000",
				"chemicalId": "05f5eaf0-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyglutamate",
				"plotName": null,
				"compoundId": "00009e33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01344",
				"inChiKey": "HBDWQSHEVMSFGY-SCQFTWEKBW",
				"kegg": "C03079",
				"mass": 163.048072,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 48,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2836"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0745"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2689"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4676"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5726"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6185"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6119"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2631"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7375"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5672"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8331"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8331"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6932"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0739"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8075"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3485"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8619"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3186"
					}
				]
			},
			{
				"metaboliteId": "0078d8e6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6b0-0000-0000-0000-000000000000",
				"chemicalName": "7-methylguanine",
				"plotName": null,
				"compoundId": "0000892a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00897",
				"inChiKey": "FZWGECJQACGGTI-UHFFFAOYAT",
				"kegg": "C02242",
				"mass": 165.06506,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3806,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6857"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8851"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9674"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3624"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4748"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3212"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4177"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0979"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2304"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0592"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0840"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9585"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2870"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8613"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2079"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8892"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8720"
					}
				]
			},
			{
				"metaboliteId": "0078d8e7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee6a-0000-0000-0000-000000000000",
				"chemicalName": "imidazole propionate",
				"plotName": null,
				"compoundId": "00009f1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02271",
				"inChiKey": "ZCKYOWGFRHAZIQ-UHFFFAOYAI",
				"kegg": null,
				"mass": 140.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 76,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8e8-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef05-0000-0000-0000-000000000000",
				"chemicalName": "phenylalanylalanine",
				"plotName": null,
				"compoundId": "0000a19e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "MIDZLCFIAINOQN-WPRPVWTQBF",
				"kegg": null,
				"mass": 236.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 831,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8e9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7e8-0000-0000-0000-000000000000",
				"chemicalName": "N6-carboxymethyllysine",
				"plotName": null,
				"compoundId": "00008f69-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NUXSIDPKKIEIMI-UHFFFAOYAH",
				"kegg": null,
				"mass": 204.111007,
				"pathwayType": "ANIMAL",
				"subPathway": "Advanced Glycation End-product",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1279,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8ea-0000-0000-0000-000000000000",
				"chemicalId": "05f5e89c-0000-0000-0000-000000000000",
				"chemicalName": "succinylcarnitine (C4-DC)",
				"plotName": null,
				"compoundId": "000090c2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61717",
				"inChiKey": null,
				"kegg": null,
				"mass": 261.121237,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1291,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8eb-0000-0000-0000-000000000000",
				"chemicalId": "05f603fa-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl dihydrosphingomyelin (d18:0/16:0)*",
				"plotName": null,
				"compoundId": "0000ccd2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QHZIGNLCLJPLCU-QPPIDDCLBH",
				"kegg": null,
				"mass": 704.58328,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2787,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8ec-0000-0000-0000-000000000000",
				"chemicalId": "05f5e391-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dipalmitoyl-GPC (16:0/16:0)",
				"plotName": null,
				"compoundId": "00004aba-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00564",
				"inChiKey": "KILNVBDSWZSGLL-KXQOOQHDBV",
				"kegg": null,
				"mass": 733.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2019,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3398"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8517"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8813"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5638"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5806"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0148"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2370"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2636"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7520"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0975"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1273"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8724"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2538"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9335"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4451"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7849"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.1609"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0092"
					}
				]
			},
			{
				"metaboliteId": "0078d8ed-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c0-0000-0000-0000-000000000000",
				"chemicalName": "delta-tocopherol",
				"plotName": null,
				"compoundId": "0000828a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02902",
				"inChiKey": "GZIFEOYASATJEH-VHFRWLAGBS",
				"kegg": "C14151",
				"mass": 402.349781,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3972,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8ee-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8ea-0000-0000-0000-000000000000",
				"chemicalName": "ribulose/xylulose",
				"plotName": null,
				"compoundId": "0000d58f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 150.052823,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1114,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5498"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4233"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4340"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3018"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3671"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1210"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8972"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2401"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5145"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3449"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8018"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0434"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1105"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4689"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2652"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3442"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2099"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3552"
					}
				]
			},
			{
				"metaboliteId": "0078d8ef-0000-0000-0000-000000000000",
				"chemicalId": "05f5e64b-0000-0000-0000-000000000000",
				"chemicalName": "quercetin 3-galactoside",
				"plotName": null,
				"compoundId": "00008542-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB30775",
				"inChiKey": "OVSQVDMCBVZWGM-DTGCRPNFBG",
				"kegg": "C10073",
				"mass": 464.095476,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4218,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e167-0000-0000-0000-000000000000",
				"chemicalName": "agmatine",
				"plotName": null,
				"compoundId": "00003c88-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01432",
				"inChiKey": "QYPPJABKJHAVHS-UHFFFAOYAX",
				"kegg": "C00179",
				"mass": 130.121846,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2593"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9773"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1909"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5334"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2664"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3378"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4735"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8179"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5238"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7179"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7342"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3457"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4144"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3068"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6964"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8179"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3434"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0134"
					}
				]
			},
			{
				"metaboliteId": "0078d8f1-0000-0000-0000-000000000000",
				"chemicalId": "0000031e-0000-0000-0000-000000000000",
				"chemicalName": "adenosine",
				"plotName": null,
				"compoundId": "0000022b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00050",
				"inChiKey": "OIRDTQYFTABQOQ-KQYNXXCUBX",
				"kegg": "C00212",
				"mass": 267.096755,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3768,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7094"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6914"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1730"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3518"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6984"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8067"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7993"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0271"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2168"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1345"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0297"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.2601"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2253"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8191"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2106"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2579"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2686"
					}
				]
			},
			{
				"metaboliteId": "0078d8f2-0000-0000-0000-000000000000",
				"chemicalId": "05f5f283-0000-0000-0000-000000000000",
				"chemicalName": "soyasaponin III",
				"plotName": null,
				"compoundId": "0000d68d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 796.460907,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4549,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f3-0000-0000-0000-000000000000",
				"chemicalId": "05f60bae-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linolenoyl-glycerol (18:2/18:3) [1]*",
				"plotName": null,
				"compoundId": "0000d6b3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07249",
				"inChiKey": null,
				"kegg": null,
				"mass": 614.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2633,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4991"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6403"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7455"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9129"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0923"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0629"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1441"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9379"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7960"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7269"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7521"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0800"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8640"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9471"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4451"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5047"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4609"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4870"
					}
				]
			},
			{
				"metaboliteId": "0078d8f4-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba6-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-oleoyl-glycerol (18:1/18:1)  [1]*",
				"plotName": null,
				"compoundId": "0000d6a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07218",
				"inChiKey": null,
				"kegg": null,
				"mass": 620.537975,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2623,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f5-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb4-0000-0000-0000-000000000000",
				"chemicalName": "linolenoyl-linolenoyl-glycerol (18:3/18:3) [1]*",
				"plotName": null,
				"compoundId": "0000d6a7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07278",
				"inChiKey": null,
				"kegg": null,
				"mass": 612.475375,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2635,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0d6-0000-0000-0000-000000000000",
				"chemicalName": "margaroylcarnitine (C17)*",
				"plotName": null,
				"compoundId": "0000e0a8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06210",
				"inChiKey": null,
				"kegg": null,
				"mass": 413.350509,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1649,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec62-0000-0000-0000-000000000000",
				"chemicalName": "3-(4-hydroxyphenyl)propionate",
				"plotName": null,
				"compoundId": "00009aa3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02199",
				"inChiKey": "NMHMNPHRMNGLLB-UHFFFAOYAT",
				"kegg": "C01744",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8f8-0000-0000-0000-000000000000",
				"chemicalId": "00000371-0000-0000-0000-000000000000",
				"chemicalName": "cytosine",
				"plotName": null,
				"compoundId": "0000023d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00630",
				"inChiKey": "OPTASPLRGRRNAP-UHFFFAOYAY",
				"kegg": "C00380",
				"mass": 111.043262,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3869,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0854"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9531"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2197"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1680"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4290"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8301"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0080"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2046"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8063"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8941"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3883"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6173"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2351"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5887"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0459"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4846"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4274"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7881"
					}
				]
			},
			{
				"metaboliteId": "0078d8f9-0000-0000-0000-000000000000",
				"chemicalId": "05f60b7f-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-galactosylglycerol (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000d673-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 778.559499,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2679,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3831"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0189"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8311"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9540"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7050"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2977"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9316"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2089"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0872"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3707"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5566"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6481"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7753"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0103"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9744"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6092"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5840"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1112"
					}
				]
			},
			{
				"metaboliteId": "0078d8fa-0000-0000-0000-000000000000",
				"chemicalId": "05f61dfa-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-myristoyl-glycerol (16:0/14:0) [2]",
				"plotName": null,
				"compoundId": "0000e014-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07095",
				"inChiKey": null,
				"kegg": null,
				"mass": 540.475375,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2598,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d8fb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e78b-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxycinnamate",
				"plotName": null,
				"compoundId": "00008fa2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02035",
				"inChiKey": "NGSWKAQJJWESNS-ZZXKWVIFBJ",
				"kegg": "C00811",
				"mass": 164.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4664,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4951"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6722"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8255"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4218"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9313"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0732"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0853"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6072"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2994"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2098"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4952"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3891"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9634"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2539"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0544"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4050"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4736"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0631"
					}
				]
			},
			{
				"metaboliteId": "0078d8fc-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebd1-0000-0000-0000-000000000000",
				"chemicalName": "argininate*",
				"plotName": null,
				"compoundId": "0000e075-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03148",
				"inChiKey": "BMFMQGXDDJALKQ-BYPYZUCNBB",
				"kegg": null,
				"mass": 175.095691,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 450,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9150"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7087"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0927"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1310"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7222"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3468"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5760"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1285"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1715"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0844"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2933"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9174"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7079"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1879"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1091"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5644"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9681"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7295"
					}
				]
			},
			{
				"metaboliteId": "0078d8fd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4ef-0000-0000-0000-000000000000",
				"chemicalName": "ribonate",
				"plotName": null,
				"compoundId": "00006c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00867",
				"inChiKey": "QXKAIJAYHKCRRA-BXXZVTAOBP",
				"kegg": "C01685",
				"mass": 166.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1113,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3601"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2950"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3139"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6236"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1138"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7312"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6159"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2707"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8328"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1610"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9402"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0682"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2577"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2104"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6966"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5683"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5070"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6472"
					}
				]
			},
			{
				"metaboliteId": "0078d8fe-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba5-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-oleoyl-glycerol (16:0/18:1) [2]*",
				"plotName": null,
				"compoundId": "0000d69e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07102",
				"inChiKey": null,
				"kegg": "C13861",
				"mass": 594.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2606,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2870"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2001"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4428"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9346"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4718"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0772"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2155"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0348"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9912"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1317"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0888"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3033"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7379"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6760"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0223"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.9933"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2981"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5407"
					}
				]
			},
			{
				"metaboliteId": "0078d8ff-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebe0-0000-0000-0000-000000000000",
				"chemicalName": "2-oxoarginine*",
				"plotName": null,
				"compoundId": "0000d720-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04225",
				"inChiKey": "ARBHXJXXVVHMET-UHFFFAOYAC",
				"kegg": "C03771",
				"mass": 173.080041,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 422,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d900-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4aa-0000-0000-0000-000000000000",
				"chemicalName": "turanose",
				"plotName": null,
				"compoundId": "00005238-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11740",
				"inChiKey": "SEWFWJUQVJHATO-UGDNZRGBBL",
				"kegg": "C19636",
				"mass": 342.116212,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4571,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d901-0000-0000-0000-000000000000",
				"chemicalId": "00000123-0000-0000-0000-000000000000",
				"chemicalName": "glucose 6-phosphate",
				"plotName": null,
				"compoundId": "00007a1c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01401",
				"inChiKey": "VFRROHXSMXFLSN-NGXFZYQLBX",
				"kegg": "C00668",
				"mass": 260.029723,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1074,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d902-0000-0000-0000-000000000000",
				"chemicalId": "05f5e48c-0000-0000-0000-000000000000",
				"chemicalName": "galactinol",
				"plotName": null,
				"compoundId": "00008792-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05826",
				"inChiKey": "VCWMRQDBPZKXKG-BJBMSVMHBS",
				"kegg": "C01235",
				"mass": 342.116212,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1177,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0835"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9036"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0523"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2851"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5015"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1853"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2729"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5952"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3014"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6332"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3200"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6807"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9066"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0119"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9066"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2134"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7546"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1170"
					}
				]
			},
			{
				"metaboliteId": "0078d903-0000-0000-0000-000000000000",
				"chemicalId": "05f5e628-0000-0000-0000-000000000000",
				"chemicalName": "erythronate*",
				"plotName": null,
				"compoundId": "0000a5b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00613",
				"inChiKey": "JPIJQSOTBSSVTP-PWNYCUMCBA",
				"kegg": null,
				"mass": 136.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1275,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6244"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3968"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2470"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0133"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2026"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5905"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6754"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8553"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2912"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1269"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4661"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1837"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1798"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2479"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9356"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4984"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9594"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0191"
					}
				]
			},
			{
				"metaboliteId": "0078d904-0000-0000-0000-000000000000",
				"chemicalId": "05f5e687-0000-0000-0000-000000000000",
				"chemicalName": "N6-carbamoylthreonyladenosine",
				"plotName": null,
				"compoundId": "00008955-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB41623",
				"inChiKey": "GYCVHQYQICRFAX-GQFURFNTBM",
				"kegg": null,
				"mass": 412.134262,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3785,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4534"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1107"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2676"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3456"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2240"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1887"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3088"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3184"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7810"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7411"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0273"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3868"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1946"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1380"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1145"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9900"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3360"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5363"
					}
				]
			},
			{
				"metaboliteId": "0078d905-0000-0000-0000-000000000000",
				"chemicalId": "05f5e43b-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoylglycerol (16:0)",
				"plotName": null,
				"compoundId": "00005287-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB31074",
				"inChiKey": "QHZLMUACJMDIAE-UHFFFAOYAU",
				"kegg": null,
				"mass": 330.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2553,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6699"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8727"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3930"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4954"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8861"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2601"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2803"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2698"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8855"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2183"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0147"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8125"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2071"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0932"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8409"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1825"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9450"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7587"
					}
				]
			},
			{
				"metaboliteId": "0078d906-0000-0000-0000-000000000000",
				"chemicalId": "05f5e45e-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxybenzoate",
				"plotName": null,
				"compoundId": "0000528d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00500",
				"inChiKey": "FJKROLUGYXJWQN-UHFFFAOYAQ",
				"kegg": "C00156",
				"mass": 138.031694,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4079,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5337"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7828"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3646"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0210"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1027"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4456"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5739"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0259"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5899"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9746"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4930"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8208"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0063"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0788"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3877"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3418"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0004"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4939"
					}
				]
			},
			{
				"metaboliteId": "0078d907-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fb-0000-0000-0000-000000000000",
				"chemicalName": "phenylacetylglycine",
				"plotName": null,
				"compoundId": "00008499-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00821",
				"inChiKey": "UTYVDVLMYQPLQB-UHFFFAOYAZ",
				"kegg": "C05598",
				"mass": 193.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1040,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d908-0000-0000-0000-000000000000",
				"chemicalId": "05f5e561-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxate",
				"plotName": null,
				"compoundId": "00007b43-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00017",
				"inChiKey": "HXACOUQIXZGNBF-UHFFFAOYAG",
				"kegg": "C00847",
				"mass": 183.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4053,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7575"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8446"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0727"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3358"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4079"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0890"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5495"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4595"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2885"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0074"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0007"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0666"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5052"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0825"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4385"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0076"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0469"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4331"
					}
				]
			},
			{
				"metaboliteId": "0078d909-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5fe-0000-0000-0000-000000000000",
				"chemicalName": "10-heptadecenoate (17:1n7)",
				"plotName": null,
				"compoundId": "000084b3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB60038",
				"inChiKey": "GDTXICBNEOEPAZ-FPLPWBNLBM",
				"kegg": null,
				"mass": 268.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1359,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2851"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1288"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9803"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6841"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4467"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5277"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0578"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2446"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3465"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6532"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0393"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1233"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2299"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5601"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0594"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4187"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6761"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1319"
					}
				]
			},
			{
				"metaboliteId": "0078d90a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4d4-0000-0000-0000-000000000000",
				"chemicalName": "1-kestose",
				"plotName": null,
				"compoundId": "000055fc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11729",
				"inChiKey": "VAWYEUIPHLMNNF-OESPXIITBK",
				"kegg": "C03661",
				"mass": 504.169035,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4248,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3256"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0144"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6770"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2051"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6637"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2339"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.1833"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3220"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2989"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4963"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5518"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5830"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1124"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0688"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5307"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8413"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0756"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1451"
					}
				]
			},
			{
				"metaboliteId": "0078d90b-0000-0000-0000-000000000000",
				"chemicalId": "00000199-0000-0000-0000-000000000000",
				"chemicalName": "malate",
				"plotName": null,
				"compoundId": "00000517-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00156",
				"inChiKey": "BJEPYKJPYRNKOW-UHFFFAOYAM",
				"kegg": "C00149",
				"mass": 134.021525,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1295,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1107"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9155"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5468"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2785"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2799"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2325"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8085"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7064"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8535"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5968"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6636"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8109"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8802"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7721"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5435"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6997"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1043"
					}
				]
			},
			{
				"metaboliteId": "0078d90c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e327-0000-0000-0000-000000000000",
				"chemicalName": "4-methyl-2-oxopentanoate",
				"plotName": null,
				"compoundId": "00005664-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00695",
				"inChiKey": "BKAJNAXTPSGJCU-UHFFFAOYAG",
				"kegg": "C00233",
				"mass": 130.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 296,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3793"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2304"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2299"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0721"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3589"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4977"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.7296"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7152"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5626"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9362"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7604"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2017"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0134"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4569"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7743"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7312"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4834"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7513"
					}
				]
			},
			{
				"metaboliteId": "0078d90d-0000-0000-0000-000000000000",
				"chemicalId": "00000152-0000-0000-0000-000000000000",
				"chemicalName": "gluconate",
				"plotName": null,
				"compoundId": "0000024b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00625",
				"inChiKey": "RGHNJXZEOKUKBD-SQOUGZDYBY",
				"kegg": "C00257",
				"mass": 196.058303,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4316,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2562"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6793"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4948"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4895"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4102"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0821"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6327"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4664"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3590"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5669"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1517"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5311"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0077"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9402"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9169"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6955"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4914"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8276"
					}
				]
			},
			{
				"metaboliteId": "0078d90e-0000-0000-0000-000000000000",
				"chemicalId": "00000365-0000-0000-0000-000000000000",
				"chemicalName": "uridine 5'-monophosphate (UMP)",
				"plotName": null,
				"compoundId": "00000b28-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00288",
				"inChiKey": "DJJCXFVJDGTHFX-FHLVPPQOBI",
				"kegg": "C00105",
				"mass": 324.035871,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3832,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d90f-0000-0000-0000-000000000000",
				"chemicalId": "00000378-0000-0000-0000-000000000000",
				"chemicalName": "caprate (10:0)",
				"plotName": null,
				"compoundId": "0000066a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00511",
				"inChiKey": "GHVNFZFCNZKVNT-UHFFFAOYAC",
				"kegg": "C01571",
				"mass": 172.14633,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1332,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3368"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9523"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0305"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1798"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6553"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8486"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5210"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3525"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7961"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3646"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0492"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0722"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1184"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3729"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4564"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8005"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9220"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8497"
					}
				]
			},
			{
				"metaboliteId": "0078d910-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c5-0000-0000-0000-000000000000",
				"chemicalName": "genistein",
				"plotName": null,
				"compoundId": "00007ec0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03217",
				"inChiKey": "TZBJGXHYKVUXJN-UHFFFAOYAH",
				"kegg": "C06563",
				"mass": 270.052823,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4235,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d911-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7f2-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPI (18:2)*",
				"plotName": null,
				"compoundId": "00008ef2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 596.296164,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3307"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6837"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1019"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0716"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9260"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6318"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5011"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0983"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1606"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2587"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0216"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7203"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2239"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2045"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4349"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0100"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3216"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1721"
					}
				]
			},
			{
				"metaboliteId": "0078d912-0000-0000-0000-000000000000",
				"chemicalId": "00000400-0000-0000-0000-000000000000",
				"chemicalName": "pantothenate",
				"plotName": null,
				"compoundId": "000005e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00210",
				"inChiKey": "GHOKWGTUZJEAQD-ZETCQYMHBS",
				"kegg": "C00864",
				"mass": 219.110673,
				"pathwayType": "ANIMAL",
				"subPathway": "Pantothenate and CoA Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3945,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7173"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2772"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4202"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8834"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5144"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8415"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6617"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8215"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8256"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0480"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0588"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4595"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4834"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9679"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3980"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3415"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4201"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2634"
					}
				]
			},
			{
				"metaboliteId": "0078d913-0000-0000-0000-000000000000",
				"chemicalId": "05f5e496-0000-0000-0000-000000000000",
				"chemicalName": "biochanin A",
				"plotName": null,
				"compoundId": "000052bd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02338",
				"inChiKey": "WUADCCWRTIWANL-UHFFFAOYAM",
				"kegg": "C00814",
				"mass": 284.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4340,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d914-0000-0000-0000-000000000000",
				"chemicalId": "05f5ee68-0000-0000-0000-000000000000",
				"chemicalName": "dihydroferulate",
				"plotName": null,
				"compoundId": "00009e21-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BOLQJTPHPSDZHR-UHFFFAOYAM",
				"kegg": null,
				"mass": 196.073559,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d915-0000-0000-0000-000000000000",
				"chemicalId": "05f5f10f-0000-0000-0000-000000000000",
				"chemicalName": "4-methylcatechol sulfate",
				"plotName": null,
				"compoundId": "0000b442-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 204.009244,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4094,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d916-0000-0000-0000-000000000000",
				"chemicalId": "05f5eea7-0000-0000-0000-000000000000",
				"chemicalName": "pinoresinol",
				"plotName": null,
				"compoundId": "0000a7d3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HGXBRUKMWQGOIE-AFHBHXEDBE",
				"kegg": "C05366",
				"mass": 358.141638,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4495,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d917-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9ac-0000-0000-0000-000000000000",
				"chemicalName": "hydroquinone beta-D-glucopyranoside",
				"plotName": null,
				"compoundId": "00009510-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29943",
				"inChiKey": "BJRNKVDFDLYUGJ-UHFFFAOYAP",
				"kegg": "C06186",
				"mass": 272.089603,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4441,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d918-0000-0000-0000-000000000000",
				"chemicalId": "05f5e989-0000-0000-0000-000000000000",
				"chemicalName": "indole-3-carboxylate",
				"plotName": null,
				"compoundId": "000094e4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03320",
				"inChiKey": null,
				"kegg": "C19837",
				"mass": 161.047678,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 275,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d919-0000-0000-0000-000000000000",
				"chemicalId": "05f5e76b-0000-0000-0000-000000000000",
				"chemicalName": "jasmonic acid",
				"plotName": null,
				"compoundId": "00008b4b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB32797",
				"inChiKey": "ZNJFBWYDHIGLCU-HWKXXFMVBZ",
				"kegg": "C08491",
				"mass": 210.125594,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4453,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d91a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e209-0000-0000-0000-000000000000",
				"chemicalName": "kynurenine",
				"plotName": null,
				"compoundId": "00003b24-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00684",
				"inChiKey": "YGPSJZOEDVAXAB-QMMMGPOBBJ",
				"kegg": "C00328",
				"mass": 208.084792,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 240,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2562"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6400"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0229"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5721"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9597"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0544"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4988"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7244"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4298"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1631"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0257"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0622"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4242"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0867"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0511"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3172"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0257"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2402"
					}
				]
			},
			{
				"metaboliteId": "0078d91b-0000-0000-0000-000000000000",
				"chemicalId": "05f5fa23-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglucosamine/N-acetylgalactosamine",
				"plotName": null,
				"compoundId": "0000b5cb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00215",
				"inChiKey": null,
				"kegg": null,
				"mass": 221.089937,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1277,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7340"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9064"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9527"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4159"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4516"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6273"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4921"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1542"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3058"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6049"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4628"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4929"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4405"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0472"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9973"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7439"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0965"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2039"
					}
				]
			},
			{
				"metaboliteId": "0078d91c-0000-0000-0000-000000000000",
				"chemicalId": "000004da-0000-0000-0000-000000000000",
				"chemicalName": "N1-methyladenosine",
				"plotName": null,
				"compoundId": "00003d22-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03331",
				"inChiKey": "QQBGTSSELNKRID-IOSLPCCCBM",
				"kegg": "C02494",
				"mass": 281.112405,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3774,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d91d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4a1-0000-0000-0000-000000000000",
				"chemicalName": "quercitrin (quercetin 3-rhamnoside)",
				"plotName": null,
				"compoundId": "000052c2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33751",
				"inChiKey": "OXGUCUVFOIWWQJ-HQBVPOQABA",
				"kegg": "C01750",
				"mass": 448.100561,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d91e-0000-0000-0000-000000000000",
				"chemicalId": "0000031f-0000-0000-0000-000000000000",
				"chemicalName": "betaine",
				"plotName": null,
				"compoundId": "00000c45-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00043",
				"inChiKey": "KWIUHFFTVRNATP-UHFFFAOYAI",
				"kegg": "C00719",
				"mass": 117.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 6,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1806"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6901"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0237"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1160"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1294"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0832"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5791"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4241"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8391"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8286"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5108"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3044"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0941"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3903"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0667"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6772"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0266"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5417"
					}
				]
			},
			{
				"metaboliteId": "0078d91f-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed37-0000-0000-0000-000000000000",
				"chemicalName": "lotaustralin",
				"plotName": null,
				"compoundId": "00009ab8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33865",
				"inChiKey": "WEWBWVMTOYUPHH-QHAQEBJBBY",
				"kegg": "C08334",
				"mass": 261.121237,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4591,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d920-0000-0000-0000-000000000000",
				"chemicalId": "00000113-0000-0000-0000-000000000000",
				"chemicalName": "creatinine",
				"plotName": null,
				"compoundId": "00000201-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00562",
				"inChiKey": "DDRJAANPRJIHGJ-UHFFFAOYAV",
				"kegg": "C00791",
				"mass": 113.058912,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 457,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3665"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5174"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3327"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3090"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2190"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5458"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3402"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3344"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0162"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0676"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1502"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1607"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9611"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6692"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7215"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7662"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8417"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2670"
					}
				]
			},
			{
				"metaboliteId": "0078d921-0000-0000-0000-000000000000",
				"chemicalId": "00000370-0000-0000-0000-000000000000",
				"chemicalName": "adenine",
				"plotName": null,
				"compoundId": "0000022a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00034",
				"inChiKey": "GFFGJBXGBJISGV-UHFFFAOYAT",
				"kegg": "C00147",
				"mass": 135.054495,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3769,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0153"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0918"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7886"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9695"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5398"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6733"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1387"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3751"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2952"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1854"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4332"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2996"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7442"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4324"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8466"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5090"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5063"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1978"
					}
				]
			},
			{
				"metaboliteId": "0078d922-0000-0000-0000-000000000000",
				"chemicalId": "0000032f-0000-0000-0000-000000000000",
				"chemicalName": "tyrosine",
				"plotName": null,
				"compoundId": "00000513-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00158",
				"inChiKey": "OUYCCCASQSFEME-QMMMGPOBBZ",
				"kegg": "C00082",
				"mass": 181.073894,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4914"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3062"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6072"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9032"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4306"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1683"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8224"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6282"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2006"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1517"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2874"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7775"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1806"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5336"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3600"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5842"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9677"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7213"
					}
				]
			},
			{
				"metaboliteId": "0078d923-0000-0000-0000-000000000000",
				"chemicalId": "000000d4-0000-0000-0000-000000000000",
				"chemicalName": "5-methylthioadenosine (MTA)",
				"plotName": null,
				"compoundId": "0000058b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01173",
				"inChiKey": "WUUGFSXJNOTRMR-IOSLPCCCBD",
				"kegg": "C00170",
				"mass": 297.08956,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 480,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0772"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2271"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6954"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7028"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6331"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7452"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2323"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0603"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3608"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6571"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2736"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9233"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1207"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7031"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7123"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0404"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6853"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0334"
					}
				]
			},
			{
				"metaboliteId": "0078d924-0000-0000-0000-000000000000",
				"chemicalId": "00000237-0000-0000-0000-000000000000",
				"chemicalName": "nicotinate",
				"plotName": null,
				"compoundId": "000005e0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01488",
				"inChiKey": "PVNIIMVLHYAWGP-UHFFFAOYAA",
				"kegg": "C00253",
				"mass": 123.032029,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3913,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6625"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0279"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2752"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3433"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5306"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2508"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0768"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8150"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1111"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1180"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0432"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0739"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0643"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9998"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7622"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3867"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1404"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1229"
					}
				]
			},
			{
				"metaboliteId": "0078d925-0000-0000-0000-000000000000",
				"chemicalId": "0000046d-0000-0000-0000-000000000000",
				"chemicalName": "tyramine",
				"plotName": null,
				"compoundId": "00000643-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00306",
				"inChiKey": "DZGWFCGJZKJUFP-UHFFFAOYAB",
				"kegg": "C00483",
				"mass": 137.084064,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 158,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8112"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1611"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1840"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4203"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5279"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2264"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2502"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5242"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0616"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1279"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8938"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2740"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7367"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5531"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2859"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2652"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0995"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5391"
					}
				]
			},
			{
				"metaboliteId": "0078d926-0000-0000-0000-000000000000",
				"chemicalId": "0000046e-0000-0000-0000-000000000000",
				"chemicalName": "urate",
				"plotName": null,
				"compoundId": "00000644-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00289",
				"inChiKey": "LEHOTFFKMJEONL-UHFFFAOYAN",
				"kegg": "C00366",
				"mass": 168.02834,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3753,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d927-0000-0000-0000-000000000000",
				"chemicalId": "05f5e753-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoglycerol",
				"plotName": null,
				"compoundId": "0000bed9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LLCSXHMJULHSJN-UHFFFAOYAC",
				"kegg": "C03274",
				"mass": 246.050454,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2549,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3355"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1916"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6359"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1079"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3887"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3677"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3311"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8289"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0008"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0804"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9768"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3277"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0050"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6182"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1542"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.1642"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1894"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3649"
					}
				]
			},
			{
				"metaboliteId": "0078d928-0000-0000-0000-000000000000",
				"chemicalId": "05f5e71f-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPE (16:0)",
				"plotName": null,
				"compoundId": "00008b2f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11503",
				"inChiKey": "YVYMBNSKXOXSKW-HXUWFJFHBX",
				"kegg": null,
				"mass": 453.285539,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8871"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5722"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9825"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5899"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8034"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5617"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7949"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4902"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4546"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3144"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4896"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.8528"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4674"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3191"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0722"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0765"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9752"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9157"
					}
				]
			},
			{
				"metaboliteId": "0078d929-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6b5-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPE (18:0)",
				"plotName": null,
				"compoundId": "0000a59e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11130",
				"inChiKey": "BBYWOYAFBUOUFP-JOCHJYFZBR",
				"kegg": null,
				"mass": 481.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2378,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d92a-0000-0000-0000-000000000000",
				"chemicalId": "00000139-0000-0000-0000-000000000000",
				"chemicalName": "sphinganine",
				"plotName": null,
				"compoundId": "00004569-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00269",
				"inChiKey": "OTKJDMGTUTTYMP-UHFFFAOYAS",
				"kegg": "C00836",
				"mass": 301.298079,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2696,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1796"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8461"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1791"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7226"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4810"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5578"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7983"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7875"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0096"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4348"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5737"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8216"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0152"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2531"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0464"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3153"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0472"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6595"
					}
				]
			},
			{
				"metaboliteId": "0078d92b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e92f-0000-0000-0000-000000000000",
				"chemicalName": "indole-3-acetamide",
				"plotName": null,
				"compoundId": "00009248-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29739",
				"inChiKey": "ZOAMBXDOGPRZLP-UHFFFAOYAC",
				"kegg": "C02693",
				"mass": 174.079313,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d92c-0000-0000-0000-000000000000",
				"chemicalId": "00000158-0000-0000-0000-000000000000",
				"chemicalName": "guanidinoacetate",
				"plotName": null,
				"compoundId": "0000ab1a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00128",
				"inChiKey": "BPMFZUMJYQTVII-UHFFFAOYAQ",
				"kegg": "C00581",
				"mass": 117.053827,
				"pathwayType": "ANIMAL",
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 455,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5675"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5986"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1038"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8822"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7333"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5373"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4327"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1827"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0604"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6797"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1481"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3653"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1090"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3657"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7014"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8756"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9456"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8395"
					}
				]
			},
			{
				"metaboliteId": "0078d92d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e566-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylvaline",
				"plotName": null,
				"compoundId": "0000ab35-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11172",
				"inChiKey": "SITLTJHOQZFJGG-UUEFVBAFBQ",
				"kegg": null,
				"mass": 246.121572,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 563,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2870"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7368"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4619"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6899"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7089"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0034"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0137"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6736"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2541"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1339"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3787"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0307"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3900"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3971"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0645"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2724"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0235"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6256"
					}
				]
			},
			{
				"metaboliteId": "0078d92e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e202-0000-0000-0000-000000000000",
				"chemicalName": "glycerol 3-phosphate",
				"plotName": null,
				"compoundId": "0000ab47-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00126",
				"inChiKey": "AWUCVROLDVIAJX-UHFFFAOYAM",
				"kegg": "C00093",
				"mass": 172.013675,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2544,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9616"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6836"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9753"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3053"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9926"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0767"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9453"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7319"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5302"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1597"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5769"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1029"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2813"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4659"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2568"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0795"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2190"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1119"
					}
				]
			},
			{
				"metaboliteId": "0078d92f-0000-0000-0000-000000000000",
				"chemicalId": "000000d1-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 5'-monophosphate (AMP)",
				"plotName": null,
				"compoundId": "00007e56-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00045",
				"inChiKey": "UDMBCSSLTHHNCD-RWLQFSFNBF",
				"kegg": "C00020",
				"mass": 347.063088,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3761,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d930-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5b7-0000-0000-0000-000000000000",
				"chemicalName": "4-imidazoleacetate",
				"plotName": null,
				"compoundId": "00007e5d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02024",
				"inChiKey": "PRJKNHOMHKJCEJ-UHFFFAOYAF",
				"kegg": "C02835",
				"mass": 126.042927,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 90,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4826"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2432"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9357"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9792"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0305"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8424"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2518"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2518"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5413"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2572"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5194"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9747"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5049"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5551"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0580"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1798"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4794"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1916"
					}
				]
			},
			{
				"metaboliteId": "0078d931-0000-0000-0000-000000000000",
				"chemicalId": "000004bf-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglucosaminylasparagine",
				"plotName": null,
				"compoundId": "0000bc15-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00489",
				"inChiKey": "YTTRPBWEMMPYSW-VFFCQLMOBE",
				"kegg": "C04540",
				"mass": 335.132867,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1274,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d932-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8a0-0000-0000-0000-000000000000",
				"chemicalName": "feruloylputrescine",
				"plotName": null,
				"compoundId": "0000bd86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "SFUVCMKSYKHYLD-FNORWQNLBE",
				"kegg": "C10497",
				"mass": 264.147393,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4598,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d933-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5e9-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylasparagine",
				"plotName": null,
				"compoundId": "00008496-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06028",
				"inChiKey": "HXFOXFJUNFFYMO-BYPYZUCNBZ",
				"kegg": null,
				"mass": 174.064057,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 40,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d934-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed8a-0000-0000-0000-000000000000",
				"chemicalName": "valylleucine",
				"plotName": null,
				"compoundId": "00009c3a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29131",
				"inChiKey": "XCTHZFGSVQBHBW-UHFFFAOYAS",
				"kegg": null,
				"mass": 230.163043,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 942,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3247"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2336"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1589"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5443"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9228"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2267"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.4223"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2202"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1072"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0724"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5330"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5330"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2852"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1074"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7637"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6067"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5091"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0453"
					}
				]
			},
			{
				"metaboliteId": "0078d935-0000-0000-0000-000000000000",
				"chemicalId": "05f5e61f-0000-0000-0000-000000000000",
				"chemicalName": "ophthalmate",
				"plotName": null,
				"compoundId": "00008720-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05765",
				"inChiKey": "JCMUOFQHZLPHQP-UHFFFAOYAD",
				"kegg": null,
				"mass": 289.127385,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d936-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6ba-0000-0000-0000-000000000000",
				"chemicalName": "3-methylcytidine",
				"plotName": null,
				"compoundId": "0000893c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BWXZYULEOLQCKN-NNRPTJCQBK",
				"kegg": null,
				"mass": 257.101171,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3870,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d937-0000-0000-0000-000000000000",
				"chemicalId": "05f5e75c-0000-0000-0000-000000000000",
				"chemicalName": "sedoheptulose-7-phosphate",
				"plotName": null,
				"compoundId": "00008b41-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01068",
				"inChiKey": "JDTUMPKOJBQPKX-UHFFFAOYAT",
				"kegg": "C05382",
				"mass": 290.040283,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Phosphate Pathway",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1107,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d938-0000-0000-0000-000000000000",
				"chemicalId": "05f5f01b-0000-0000-0000-000000000000",
				"chemicalName": "N6,N6-dimethyladenosine",
				"plotName": null,
				"compoundId": "0000a461-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WVGPGNPCZPYCLK-UHFFFAOYAS",
				"kegg": null,
				"mass": 295.128054,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3776,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d939-0000-0000-0000-000000000000",
				"chemicalId": "05f5e833-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylalanine",
				"plotName": null,
				"compoundId": "000090c7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29142",
				"inChiKey": "WQXXXVRAFAKQJM-WHFBIAKZBJ",
				"kegg": null,
				"mass": 218.090272,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 548,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2179"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0962"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4232"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0343"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3186"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8161"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4443"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9186"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6078"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1643"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1155"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6481"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2812"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9982"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1564"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5467"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4274"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8967"
					}
				]
			},
			{
				"metaboliteId": "0078d93a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e83b-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylserine",
				"plotName": null,
				"compoundId": "000090d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02931",
				"inChiKey": "JJIHLJJYMXLCOY-BYPYZUCNBM",
				"kegg": null,
				"mass": 147.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 10,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8005"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0511"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8513"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6955"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6650"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6735"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5031"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2976"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3858"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3164"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9411"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0435"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2219"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2243"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0602"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9648"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4532"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3312"
					}
				]
			},
			{
				"metaboliteId": "0078d93b-0000-0000-0000-000000000000",
				"chemicalId": "05f603e2-0000-0000-0000-000000000000",
				"chemicalName": "oleate/vaccenate (18:1)",
				"plotName": null,
				"compoundId": "0000cc3d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 282.25588,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6451"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4494"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2444"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8795"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9926"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0489"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4830"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1592"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5258"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2615"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8859"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1730"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0236"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6696"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0106"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9694"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1719"
					}
				]
			},
			{
				"metaboliteId": "0078d93c-0000-0000-0000-000000000000",
				"chemicalId": "05f603fb-0000-0000-0000-000000000000",
				"chemicalName": "tricosanoyl sphingomyelin (d18:1/23:0)*",
				"plotName": null,
				"compoundId": "0000ccd4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12105",
				"inChiKey": null,
				"kegg": null,
				"mass": 800.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d93d-0000-0000-0000-000000000000",
				"chemicalId": "05f603c8-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPC (18:0/18:1)",
				"plotName": null,
				"compoundId": "0000ccd6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08038",
				"inChiKey": "ATHVAWFAEPLPPQ-VRDBWYNSBX",
				"kegg": null,
				"mass": 787.609105,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2060,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7633"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5894"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8005"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9127"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0071"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0177"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1411"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0568"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8790"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3291"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2920"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4924"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0075"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0938"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8999"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7436"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3228"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2241"
					}
				]
			},
			{
				"metaboliteId": "0078d93e-0000-0000-0000-000000000000",
				"chemicalId": "05f60441-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/21:0, d17:1/22:0, d16:1/23:0)*",
				"plotName": null,
				"compoundId": "0000cd0f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 772.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d93f-0000-0000-0000-000000000000",
				"chemicalId": "00000392-0000-0000-0000-000000000000",
				"chemicalName": "ribose",
				"plotName": null,
				"compoundId": "000005bf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00283",
				"inChiKey": "PYMYPHUHKUWMLA-LMVFSUKVBD",
				"kegg": "C00121",
				"mass": 150.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1111,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6268"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3155"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9051"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1101"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6612"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6608"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4265"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0341"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4489"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7529"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7753"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3818"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5605"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6180"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6231"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1876"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1639"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0712"
					}
				]
			},
			{
				"metaboliteId": "0078d940-0000-0000-0000-000000000000",
				"chemicalId": "000005f8-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPI (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000cce2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09784",
				"inChiKey": "BSNJSZUDOMPYIR-DMDPBSJXBZ",
				"kegg": null,
				"mass": 834.52573,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4806"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6676"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4931"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9306"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3217"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9073"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0111"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3738"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4637"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7104"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7907"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5443"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7163"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7596"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0957"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7137"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4988"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6743"
					}
				]
			},
			{
				"metaboliteId": "0078d941-0000-0000-0000-000000000000",
				"chemicalId": "05f60413-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-2-linoleoyl-GPI (18:1/18:2)*",
				"plotName": null,
				"compoundId": "0000cce3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09838",
				"inChiKey": "MXJMOLQIXMJUEL-KRVWYVMSBN",
				"kegg": null,
				"mass": 860.54121,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2268,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3544"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2452"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1427"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0563"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9250"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.7483"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3022"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1048"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5512"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3568"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9927"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0442"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1417"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3020"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3018"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7344"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4423"
					}
				]
			},
			{
				"metaboliteId": "0078d942-0000-0000-0000-000000000000",
				"chemicalId": "05f5e93b-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl sphingomyelin (d18:1/16:0)",
				"plotName": null,
				"compoundId": "00009282-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "RWKUXQNLWDTSLO-GWQJGLRPBP",
				"kegg": null,
				"mass": 702.567575,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2791,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d943-0000-0000-0000-000000000000",
				"chemicalId": "05f5e787-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPA (18:1)",
				"plotName": null,
				"compoundId": "00008fcc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WRGQSWVCFNIUNZ-KTKRTIGZBY",
				"kegg": null,
				"mass": 436.25899,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d944-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9d8-0000-0000-0000-000000000000",
				"chemicalName": "coumestrol",
				"plotName": null,
				"compoundId": "000094f6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02326",
				"inChiKey": "ZZIALNLLNHEQPJ-UHFFFAOYAN",
				"kegg": "C10205",
				"mass": 268.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4375,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d945-0000-0000-0000-000000000000",
				"chemicalId": "05f6046e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPA (16:0/18:2)*",
				"plotName": null,
				"compoundId": "0000cdc7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07860",
				"inChiKey": "YQMUIZXKIKXZHD-UMKNCJEQBW",
				"kegg": null,
				"mass": 672.473006,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1987,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6317"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1272"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7364"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5574"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2853"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7540"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0159"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4503"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1211"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9720"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4965"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3610"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4805"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5437"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8044"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0390"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7210"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7151"
					}
				]
			},
			{
				"metaboliteId": "0078d946-0000-0000-0000-000000000000",
				"chemicalId": "00000219-0000-0000-0000-000000000000",
				"chemicalName": "trans-urocanate",
				"plotName": null,
				"compoundId": "0000025f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00301",
				"inChiKey": "LOIYMIARKYCTBW-OWOJBTEDBA",
				"kegg": "C00785",
				"mass": 138.042928,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 74,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7976"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1000"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3609"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8629"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1958"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1348"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5856"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1113"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0559"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2486"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8670"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6308"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1483"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3309"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4934"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2094"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6474"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0060"
					}
				]
			},
			{
				"metaboliteId": "0078d947-0000-0000-0000-000000000000",
				"chemicalId": "0000015c-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxyguanosine",
				"plotName": null,
				"compoundId": "00000583-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00085",
				"inChiKey": "YKBGVTZYEHREMT-KVQBGUIXBI",
				"kegg": "C00330",
				"mass": 267.096755,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3820,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d948-0000-0000-0000-000000000000",
				"chemicalId": "05f60b9e-0000-0000-0000-000000000000",
				"chemicalName": "coumaroylquinate (5)",
				"plotName": null,
				"compoundId": "0000d692-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 338.100168,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4374,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d949-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbd-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linoleoyl-glycerol (18:2/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000d6b8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07248",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2631,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0996"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2921"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9249"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4686"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6846"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2402"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5562"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3994"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3122"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3063"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3384"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8762"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1656"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7616"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1969"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7431"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5201"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3325"
					}
				]
			},
			{
				"metaboliteId": "0078d94a-0000-0000-0000-000000000000",
				"chemicalId": "05f61e8f-0000-0000-0000-000000000000",
				"chemicalName": "stearoylcholine*",
				"plotName": null,
				"compoundId": "0000e078-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 369.360687,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1721,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d94b-0000-0000-0000-000000000000",
				"chemicalId": "05f60443-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:0/18:0, d19:0/17:0)*",
				"plotName": null,
				"compoundId": "0000e081-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12087",
				"inChiKey": null,
				"kegg": null,
				"mass": 732.614525,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2789,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d94c-0000-0000-0000-000000000000",
				"chemicalId": "05f60b79-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-digalactosylglycerol (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000d671-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "MUUBILNSVLPLLL-WVZYQCMWBN",
				"kegg": null,
				"mass": 940.612322,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2678,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4984"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0384"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9424"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3919"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1984"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5498"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0811"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3643"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5230"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1839"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8411"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2650"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4648"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4502"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7175"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7082"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2157"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2585"
					}
				]
			},
			{
				"metaboliteId": "0078d94d-0000-0000-0000-000000000000",
				"chemicalId": "000004cf-0000-0000-0000-000000000000",
				"chemicalName": "dihomo-linoleate (20:2n6)",
				"plotName": null,
				"compoundId": "0000458d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05060",
				"inChiKey": "XSXIVVZCUAHUJO-HZJYTTRNBM",
				"kegg": "C16525",
				"mass": 308.27153,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1397,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1421"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1545"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8525"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2551"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1607"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6734"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1064"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9265"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2192"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2552"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2144"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4911"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4792"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4792"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3531"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8019"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6555"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4710"
					}
				]
			},
			{
				"metaboliteId": "0078d94e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2be-0000-0000-0000-000000000000",
				"chemicalName": "mesaconate (methylfumarate)",
				"plotName": null,
				"compoundId": "0000483d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00749",
				"inChiKey": "HNEGQIOMVPPMNR-NSCUHMNNBN",
				"kegg": "C01732",
				"mass": 130.026609,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1303,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d94f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c1-0000-0000-0000-000000000000",
				"chemicalName": "glycitin (glycitein 7-O-glucoside)",
				"plotName": null,
				"compoundId": "00007eb0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02219",
				"inChiKey": "OZBAVEKZGSOMOJ-MIUGBVLSBI",
				"kegg": "C16195",
				"mass": 446.121297,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4433,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d950-0000-0000-0000-000000000000",
				"chemicalId": "0000044b-0000-0000-0000-000000000000",
				"chemicalName": "guanosine",
				"plotName": null,
				"compoundId": "00000625-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00133",
				"inChiKey": "NYHBQMYGNKIUIF-UUOKFMHZBU",
				"kegg": "C00387",
				"mass": 283.09167,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3803,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6217"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6550"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0149"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7568"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7680"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3136"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9358"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5686"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0009"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2883"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4310"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4728"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0558"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1335"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6815"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5618"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5295"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7328"
					}
				]
			},
			{
				"metaboliteId": "0078d951-0000-0000-0000-000000000000",
				"chemicalId": "00000448-0000-0000-0000-000000000000",
				"chemicalName": "UDP-glucose",
				"plotName": null,
				"compoundId": "00007e58-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00286",
				"inChiKey": "HSCJRCZFDFQWRP-JZMIEXBBBU",
				"kegg": "C00029",
				"mass": 566.055029,
				"pathwayType": "ANIMAL",
				"subPathway": "Nucleotide Sugar",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1226,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d952-0000-0000-0000-000000000000",
				"chemicalId": "000004b6-0000-0000-0000-000000000000",
				"chemicalName": "isocitrate",
				"plotName": null,
				"compoundId": "00002f4e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00193",
				"inChiKey": "ODBLHEXUDAPZAU-UHFFFAOYAX",
				"kegg": "C00311",
				"mass": 192.027005,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1287,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1860"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1956"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3444"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7614"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6277"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0082"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9178"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3300"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4117"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8930"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4082"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5114"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8989"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4618"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5144"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7368"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7950"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3018"
					}
				]
			},
			{
				"metaboliteId": "0078d953-0000-0000-0000-000000000000",
				"chemicalId": "000005a2-0000-0000-0000-000000000000",
				"chemicalName": "beta-hydroxyisovalerate",
				"plotName": null,
				"compoundId": "00002f61-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00754",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 118.062995,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 313,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d954-0000-0000-0000-000000000000",
				"chemicalId": "05f5e592-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxy-2-ethylpropionate",
				"plotName": null,
				"compoundId": "00007e8d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00396",
				"inChiKey": "ZMZQVAUJTDKQGE-UHFFFAOYAZ",
				"kegg": null,
				"mass": 118.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 345,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d955-0000-0000-0000-000000000000",
				"chemicalId": "05f5e10e-0000-0000-0000-000000000000",
				"chemicalName": "hippurate",
				"plotName": null,
				"compoundId": "00003d89-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00714",
				"inChiKey": "QIAFMBKCNZACKA-UHFFFAOYAD",
				"kegg": "C01586",
				"mass": 179.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4055,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d956-0000-0000-0000-000000000000",
				"chemicalId": "000007ed-0000-0000-0000-000000000000",
				"chemicalName": "azelate (C9-DC)",
				"plotName": null,
				"compoundId": "000047ba-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00784",
				"inChiKey": "BDJRBEYXGGNYIS-UHFFFAOYAK",
				"kegg": "C08261",
				"mass": 188.10486,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1503,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3886"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2141"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1660"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8457"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0351"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0992"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2329"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7233"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5898"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5753"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1697"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3983"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6697"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6189"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1979"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0975"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8502"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7623"
					}
				]
			},
			{
				"metaboliteId": "0078d957-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5d0-0000-0000-0000-000000000000",
				"chemicalName": "5-dodecenoate (12:1n7)",
				"plotName": null,
				"compoundId": "000084b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00529",
				"inChiKey": "IJBFSOLHRKELLR-FPLPWBNLBG",
				"kegg": null,
				"mass": 198.16198,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1338,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3956"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8895"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7185"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0490"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6716"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0574"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4968"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3523"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2248"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5042"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9231"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2328"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3523"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4776"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8650"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1930"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1690"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9196"
					}
				]
			},
			{
				"metaboliteId": "0078d958-0000-0000-0000-000000000000",
				"chemicalId": "0000043a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylleucine",
				"plotName": null,
				"compoundId": "00000633-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11756",
				"inChiKey": "WXNXCEHXYPACJF-ZETCQYMHBF",
				"kegg": "C02710",
				"mass": 173.105194,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 290,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6201"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4312"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5050"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5826"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3606"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0622"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2615"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4079"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7225"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1634"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4817"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7501"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2791"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9197"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1159"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9104"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3293"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4050"
					}
				]
			},
			{
				"metaboliteId": "0078d959-0000-0000-0000-000000000000",
				"chemicalId": "00000332-0000-0000-0000-000000000000",
				"chemicalName": "malonate",
				"plotName": null,
				"compoundId": "00003e00-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00691",
				"inChiKey": "OFOBLEOULBTSOW-UHFFFAOYAJ",
				"kegg": "C00383",
				"mass": 104.01096,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1313,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5746"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8475"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8859"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2533"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2183"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6527"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5282"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9334"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8457"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3199"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2267"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9074"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8236"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7771"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8179"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2120"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7896"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7617"
					}
				]
			},
			{
				"metaboliteId": "0078d95a-0000-0000-0000-000000000000",
				"chemicalId": "000000fc-0000-0000-0000-000000000000",
				"chemicalName": "succinate",
				"plotName": null,
				"compoundId": "0000059d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00254",
				"inChiKey": "KDYFGRWQOYBRFD-UHFFFAOYAC",
				"kegg": "C00042",
				"mass": 118.02661,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1292,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5946"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5465"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5655"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7069"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6807"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4900"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0915"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7051"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2049"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0682"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6946"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1657"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9440"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2344"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1243"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7625"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7710"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4118"
					}
				]
			},
			{
				"metaboliteId": "0078d95b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e201-0000-0000-0000-000000000000",
				"chemicalName": "glucuronate",
				"plotName": null,
				"compoundId": "00003c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00127",
				"inChiKey": "IAJILQKETJEXLJ-QTBDOELSBX",
				"kegg": "C00191",
				"mass": 194.042653,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1251,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3071"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4134"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5084"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0186"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4105"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3985"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5809"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6319"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9865"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7896"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0256"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9009"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9324"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4519"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2225"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3700"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9272"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9173"
					}
				]
			},
			{
				"metaboliteId": "0078d95c-0000-0000-0000-000000000000",
				"chemicalId": "00000391-0000-0000-0000-000000000000",
				"chemicalName": "maltose",
				"plotName": null,
				"compoundId": "00003ce2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00163",
				"inChiKey": "GUBGYTABKSRVRQ-ASMJPISFBF",
				"kegg": "C00208",
				"mass": 342.116215,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycogen Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1146,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1468"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6382"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2174"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0387"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7645"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1035"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7888"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6573"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0277"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5315"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3204"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0471"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1563"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0387"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2479"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5358"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6870"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5811"
					}
				]
			},
			{
				"metaboliteId": "0078d95d-0000-0000-0000-000000000000",
				"chemicalId": "0000037d-0000-0000-0000-000000000000",
				"chemicalName": "arachidate (20:0)",
				"plotName": null,
				"compoundId": "0000045e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02212",
				"inChiKey": "VKOBVWXKNCXXDE-UHFFFAOYAB",
				"kegg": "C06425",
				"mass": 312.30283,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1347,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6613"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5435"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3084"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5372"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2535"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8532"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1521"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9054"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9697"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4045"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0143"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6501"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0147"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8846"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5634"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4598"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1347"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3771"
					}
				]
			},
			{
				"metaboliteId": "0078d95e-0000-0000-0000-000000000000",
				"chemicalId": "0000039d-0000-0000-0000-000000000000",
				"chemicalName": "heptanoate (7:0)",
				"plotName": null,
				"compoundId": "0000066c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00666",
				"inChiKey": "MNWFXJYAOYHMED-UHFFFAOYAP",
				"kegg": "C17714",
				"mass": 130.09938,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1329,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1831"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6903"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5621"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9966"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2647"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8665"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0934"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7745"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2319"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9475"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0785"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0286"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4227"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4389"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3426"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6336"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7615"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5606"
					}
				]
			},
			{
				"metaboliteId": "0078d95f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ae-0000-0000-0000-000000000000",
				"chemicalName": "myristoleate (14:1n5)",
				"plotName": null,
				"compoundId": "00007ea2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02000",
				"inChiKey": "YWWVWXASSLXJHU-WAYWQWQTBZ",
				"kegg": "C08322",
				"mass": 226.19328,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1787"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3556"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7318"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6188"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9897"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5380"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5555"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1538"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1329"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7701"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0804"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4105"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2883"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7286"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0555"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0968"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5056"
					}
				]
			},
			{
				"metaboliteId": "0078d960-0000-0000-0000-000000000000",
				"chemicalId": "05f5e454-0000-0000-0000-000000000000",
				"chemicalName": "glucoheptose",
				"plotName": null,
				"compoundId": "000050e1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "YPZMPEPLWKRVLD-BHOGCKGPBQ",
				"kegg": null,
				"mass": 210.073953,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4428,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1733"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2771"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9571"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5168"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4083"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1269"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5439"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0312"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0933"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0696"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1210"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3569"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2547"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6971"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4031"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3524"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1210"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2414"
					}
				]
			},
			{
				"metaboliteId": "0078d961-0000-0000-0000-000000000000",
				"chemicalId": "00000335-0000-0000-0000-000000000000",
				"chemicalName": "pseudouridine",
				"plotName": null,
				"compoundId": "000082a2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00767",
				"inChiKey": "HZIOZCLEXIYJAD-JWMKEVCDBU",
				"kegg": "C02067",
				"mass": 244.069538,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3839,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5338"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8229"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3258"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0473"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8528"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7855"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7898"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5536"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6768"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0971"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4504"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9283"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0210"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4226"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2485"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1504"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2437"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1537"
					}
				]
			},
			{
				"metaboliteId": "0078d962-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed25-0000-0000-0000-000000000000",
				"chemicalName": "2-oxindole-3-acetate",
				"plotName": null,
				"compoundId": "00009e1f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB35514",
				"inChiKey": "ILGMGHZPXRDCCS-UHFFFAOYAC",
				"kegg": null,
				"mass": 191.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4290,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d963-0000-0000-0000-000000000000",
				"chemicalId": "000001a5-0000-0000-0000-000000000000",
				"chemicalName": "inositol hexakisphosphate",
				"plotName": null,
				"compoundId": "00009a97-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03502",
				"inChiKey": "IMQLKJBTEOYOSI-XNTDZNLYBB",
				"kegg": "C01204",
				"mass": 659.861388,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1970,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d964-0000-0000-0000-000000000000",
				"chemicalId": "05f5f608-0000-0000-0000-000000000000",
				"chemicalName": "O-sulfo-L-tyrosine",
				"plotName": null,
				"compoundId": "0000b165-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CIQHWLTYGMYQQR-QMMMGPOBBK",
				"kegg": null,
				"mass": 261.030708,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d965-0000-0000-0000-000000000000",
				"chemicalId": "05f5e745-0000-0000-0000-000000000000",
				"chemicalName": "catechol sulfate",
				"plotName": null,
				"compoundId": "000089f8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB59724",
				"inChiKey": "MZPWKJZDOCIALD-UHFFFAOYAC",
				"kegg": null,
				"mass": 189.993594,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4087,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d966-0000-0000-0000-000000000000",
				"chemicalId": "05f5eb90-0000-0000-0000-000000000000",
				"chemicalName": "guanosine 3'-monophosphate (3'-GMP)",
				"plotName": null,
				"compoundId": "00009b6a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZDPUTNZENXVHJC-UHFFFAOYAG",
				"kegg": null,
				"mass": 363.057999,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3799,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d967-0000-0000-0000-000000000000",
				"chemicalId": "05f5e449-0000-0000-0000-000000000000",
				"chemicalName": "oxalate (ethanedioate)",
				"plotName": null,
				"compoundId": "000050d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02329",
				"inChiKey": "MUBZPKHOEPUJKR-UHFFFAOYAQ",
				"kegg": "C00209",
				"mass": 89.995309,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3964,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6475"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4325"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5260"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1037"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3183"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5765"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5170"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8960"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2527"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7869"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5613"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0278"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7389"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6781"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6827"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8705"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1275"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2038"
					}
				]
			},
			{
				"metaboliteId": "0078d968-0000-0000-0000-000000000000",
				"chemicalId": "05f5effb-0000-0000-0000-000000000000",
				"chemicalName": "glucoraphanin",
				"plotName": null,
				"compoundId": "0000a64b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB38404",
				"inChiKey": "GMMLNKINDDUDCF-RFOBZYEEBD",
				"kegg": null,
				"mass": 437.048408,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4312,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d969-0000-0000-0000-000000000000",
				"chemicalId": "00000048-0000-0000-0000-000000000000",
				"chemicalName": "adenosine-2',3'-cyclic monophosphate",
				"plotName": null,
				"compoundId": "0000925b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11616",
				"inChiKey": "KMYWVDDIPVNLME-UHFFFAOYAI",
				"kegg": "C02353",
				"mass": 329.052523,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3765,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d96a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e489-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxypyridine",
				"plotName": null,
				"compoundId": "000052b1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GRFNBEZIAWKNCO-UHFFFAOYAT",
				"kegg": null,
				"mass": 95.037114,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0572"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3596"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1812"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4130"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7830"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5658"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1158"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0890"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7095"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9417"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6487"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5183"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4367"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6225"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4367"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8329"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7385"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7652"
					}
				]
			},
			{
				"metaboliteId": "0078d96b-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec41-0000-0000-0000-000000000000",
				"chemicalName": "inositol triphosphate",
				"plotName": null,
				"compoundId": "000095f5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01143",
				"inChiKey": null,
				"kegg": "C01243",
				"mass": 419.962379,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1960,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d96c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e160-0000-0000-0000-000000000000",
				"chemicalName": "4-guanidinobutanoate",
				"plotName": null,
				"compoundId": "00003d41-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03464",
				"inChiKey": "TUHVEAJXIMEOSA-UHFFFAOYAM",
				"kegg": "C01035",
				"mass": 145.085127,
				"pathwayType": "ANIMAL",
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 486,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3931"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1450"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3210"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9072"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9955"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2092"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2004"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1124"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0680"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1021"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5095"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0588"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7826"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5065"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0724"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7229"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5549"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5568"
					}
				]
			},
			{
				"metaboliteId": "0078d96d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e207-0000-0000-0000-000000000000",
				"chemicalName": "imidazole lactate",
				"plotName": null,
				"compoundId": "00003d64-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02320",
				"inChiKey": "ACZFBYCNAVEFLC-YFKPBYRVBI",
				"kegg": "C05568",
				"mass": 156.053492,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 78,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4134"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8335"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6660"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0793"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1445"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8613"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3025"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2781"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0683"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2296"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5393"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1162"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0733"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3392"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2645"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5017"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9798"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8272"
					}
				]
			},
			{
				"metaboliteId": "0078d96e-0000-0000-0000-000000000000",
				"chemicalId": "000006d5-0000-0000-0000-000000000000",
				"chemicalName": "acetylcholine",
				"plotName": null,
				"compoundId": "00004966-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00895",
				"inChiKey": null,
				"kegg": "C01996",
				"mass": 145.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Neurotransmitter",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1711,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3466"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9670"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0762"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9566"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0605"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2197"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2067"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8748"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5778"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1614"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4307"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0682"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8379"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7988"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8379"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9732"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6731"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7068"
					}
				]
			},
			{
				"metaboliteId": "0078d96f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e162-0000-0000-0000-000000000000",
				"chemicalName": "5-(2-Hydroxyethyl)-4-methylthiazole",
				"plotName": null,
				"compoundId": "00006c19-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "BKAWJIRCKVUVED-UHFFFAOYAZ",
				"kegg": "C04294",
				"mass": 143.040486,
				"pathwayType": "ANIMAL",
				"subPathway": "Thiamine Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4028,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3084"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7332"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1367"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1044"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5503"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0708"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8561"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7507"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3295"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2350"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1174"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2463"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1906"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5526"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9738"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7356"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4716"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8496"
					}
				]
			},
			{
				"metaboliteId": "0078d970-0000-0000-0000-000000000000",
				"chemicalId": "05f5e55e-0000-0000-0000-000000000000",
				"chemicalName": "7-methylguanosine",
				"plotName": null,
				"compoundId": "00007b5c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZBDDPUHBOFHFRK-KQYNXXCUBG",
				"kegg": "C20674",
				"mass": 297.107319,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3809,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d971-0000-0000-0000-000000000000",
				"chemicalId": "00000238-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxamine",
				"plotName": null,
				"compoundId": "00000866-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01431",
				"inChiKey": "NHZMQXZHNVQTQA-UHFFFAOYAS",
				"kegg": "C00534",
				"mass": 168.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4049,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9837"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8813"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9078"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3079"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1547"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3771"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4120"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4416"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8053"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2193"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9418"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6450"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1645"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9072"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9275"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7438"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2577"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9692"
					}
				]
			},
			{
				"metaboliteId": "0078d972-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed34-0000-0000-0000-000000000000",
				"chemicalName": "linamarin",
				"plotName": null,
				"compoundId": "00009ab6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33699",
				"inChiKey": "QLTCHMYAEJEXBT-ZEBDFXRSBH",
				"kegg": "C01594",
				"mass": 247.105587,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4457,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d973-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebd8-0000-0000-0000-000000000000",
				"chemicalName": "rosmarinate",
				"plotName": null,
				"compoundId": "00009b69-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03572",
				"inChiKey": "DOUMFZQKYFQNTF-WUTVXBCWBB",
				"kegg": "C01850",
				"mass": 360.084517,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4529,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6190"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6107"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3142"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6034"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6989"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6203"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4336"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6360"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1247"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4731"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0363"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5124"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5756"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8030"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9217"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3011"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9843"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5756"
					}
				]
			},
			{
				"metaboliteId": "0078d974-0000-0000-0000-000000000000",
				"chemicalId": "05f5e46f-0000-0000-0000-000000000000",
				"chemicalName": "abscisate",
				"plotName": null,
				"compoundId": "000052a4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB35140",
				"inChiKey": "JLIDBLDQVAYHNE-YKALOCIXBP",
				"kegg": "C06082",
				"mass": 264.136159,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4322,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3292"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3525"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2857"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1252"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1038"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9253"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8599"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5224"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1962"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3109"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7762"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4259"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6437"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5218"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6460"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6960"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7827"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4858"
					}
				]
			},
			{
				"metaboliteId": "0078d975-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9d1-0000-0000-0000-000000000000",
				"chemicalName": "kaempferol 3-O-glucoside/galactoside",
				"plotName": null,
				"compoundId": "0000be24-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 448.100561,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4627,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d976-0000-0000-0000-000000000000",
				"chemicalId": "0000033b-0000-0000-0000-000000000000",
				"chemicalName": "cytidine",
				"plotName": null,
				"compoundId": "00000202-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00089",
				"inChiKey": "UHDGCWIWMRVCDJ-XVFCMESIBD",
				"kegg": "C00475",
				"mass": 243.085522,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3868,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1065"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8166"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2352"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2621"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8668"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1897"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2147"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2708"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3892"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0140"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1937"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9961"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5994"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1195"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6115"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4487"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1727"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4002"
					}
				]
			},
			{
				"metaboliteId": "0078d977-0000-0000-0000-000000000000",
				"chemicalId": "0000032b-0000-0000-0000-000000000000",
				"chemicalName": "alanine",
				"plotName": null,
				"compoundId": "00000466-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00161",
				"inChiKey": "QNAYBMKLOCPYGJ-REOHCLBHBS",
				"kegg": "C00041",
				"mass": 89.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 28,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4781"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5331"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6369"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3796"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3019"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1841"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3929"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2169"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7248"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1883"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5978"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5851"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5751"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6825"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1055"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2693"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7532"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9085"
					}
				]
			},
			{
				"metaboliteId": "0078d978-0000-0000-0000-000000000000",
				"chemicalId": "00000197-0000-0000-0000-000000000000",
				"chemicalName": "lysine",
				"plotName": null,
				"compoundId": "00000515-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00182",
				"inChiKey": "KDXKERNSBIXSRK-YFKPBYRVBE",
				"kegg": "C00047",
				"mass": 146.105528,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 98,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4441"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2708"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3358"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8492"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2457"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1945"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5209"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2625"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2936"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4550"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0078"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0622"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3927"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2305"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1494"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2603"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0978"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5594"
					}
				]
			},
			{
				"metaboliteId": "0078d979-0000-0000-0000-000000000000",
				"chemicalId": "0000008d-0000-0000-0000-000000000000",
				"chemicalName": "gamma-aminobutyrate (GABA)",
				"plotName": null,
				"compoundId": "00000588-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00112",
				"inChiKey": "BTCSSZJGUNDROE-UHFFFAOYAC",
				"kegg": "C00334",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 55,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5864"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8142"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4180"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4738"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5208"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3500"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3666"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2047"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3557"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9691"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5073"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1747"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2665"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1441"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2539"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5063"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0732"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2207"
					}
				]
			},
			{
				"metaboliteId": "0078d97a-0000-0000-0000-000000000000",
				"chemicalId": "00000401-0000-0000-0000-000000000000",
				"chemicalName": "pipecolate",
				"plotName": null,
				"compoundId": "000005a4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00070",
				"inChiKey": "HXEACLLIILLPRG-UHFFFAOYAL",
				"kegg": "C00408",
				"mass": 129.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 123,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6246"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3131"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9435"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3559"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8955"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5803"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8032"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1737"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2822"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6867"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3638"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8426"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6013"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3674"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9565"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3712"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7765"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1690"
					}
				]
			},
			{
				"metaboliteId": "0078d97b-0000-0000-0000-000000000000",
				"chemicalId": "00000154-0000-0000-0000-000000000000",
				"chemicalName": "glycine",
				"plotName": null,
				"compoundId": "0000003a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00123",
				"inChiKey": "DHMQDGOQFOQNFH-UHFFFAOYAW",
				"kegg": "C00037",
				"mass": 75.032029,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 1,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4394"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1017"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3935"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2934"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5269"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2661"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0111"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0827"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2989"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0785"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5200"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6429"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8249"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5957"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3481"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7091"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4203"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1359"
					}
				]
			},
			{
				"metaboliteId": "0078d97c-0000-0000-0000-000000000000",
				"chemicalId": "05f5e673-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPC (18:2)",
				"plotName": null,
				"compoundId": "00008673-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10386",
				"inChiKey": "SPJFYYJXNPEZDW-UTJQPWESBU",
				"kegg": "C04100",
				"mass": 519.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2331,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6424"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2779"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4933"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3354"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6886"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2154"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1785"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1831"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1885"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5007"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4935"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6846"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5613"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1952"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6121"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0524"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3048"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5787"
					}
				]
			},
			{
				"metaboliteId": "0078d97d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e96a-0000-0000-0000-000000000000",
				"chemicalName": "ergothioneine",
				"plotName": null,
				"compoundId": "00009253-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03045",
				"inChiKey": "SSISHJJTAXXQAX-UHFFFAOYAO",
				"kegg": "C05570",
				"mass": 229.088497,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d97e-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed6f-0000-0000-0000-000000000000",
				"chemicalName": "leucylglutamine*",
				"plotName": null,
				"compoundId": "0000bc3d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28927",
				"inChiKey": "JYOAXOMPIXKMKK-UHFFFAOYAJ",
				"kegg": null,
				"mass": 259.153206,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 952,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d97f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e624-0000-0000-0000-000000000000",
				"chemicalName": "nicotinate ribonucleoside",
				"plotName": null,
				"compoundId": "000082bf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06809",
				"inChiKey": "PUEDDPCUCPRQNY-ZYUZMQFOBW",
				"kegg": "C05841",
				"mass": 255.074287,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3914,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5580"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1302"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8092"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0501"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1995"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1402"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0725"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5105"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2659"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2264"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3425"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5876"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2295"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8391"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0686"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8990"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0022"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1707"
					}
				]
			},
			{
				"metaboliteId": "0078d980-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed7c-0000-0000-0000-000000000000",
				"chemicalName": "lysylleucine",
				"plotName": null,
				"compoundId": "00009c54-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28955",
				"inChiKey": "ATIPDCIQTUXABX-UHFFFAOYAK",
				"kegg": null,
				"mass": 259.189592,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 802,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d981-0000-0000-0000-000000000000",
				"chemicalId": "05f5e610-0000-0000-0000-000000000000",
				"chemicalName": "stachydrine",
				"plotName": null,
				"compoundId": "00008650-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04827",
				"inChiKey": "CMUNUTVVOOHQPW-LURJTMIEBI",
				"kegg": "C10172",
				"mass": 143.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5412"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5156"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2966"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5849"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3539"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5403"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5532"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9575"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8828"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2625"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3170"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3449"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2388"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0739"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4707"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5858"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5535"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8358"
					}
				]
			},
			{
				"metaboliteId": "0078d982-0000-0000-0000-000000000000",
				"chemicalId": "000001d5-0000-0000-0000-000000000000",
				"chemicalName": "phytosphingosine",
				"plotName": null,
				"compoundId": "000005e6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04610",
				"inChiKey": "AERBNCYCJBRYDG-FGTMMUONBE",
				"kegg": "C12144",
				"mass": 317.292994,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2699,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7892"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5085"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7205"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0744"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3380"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7672"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3918"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4796"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0484"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6004"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4391"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2559"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4581"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0850"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7446"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5856"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2351"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8535"
					}
				]
			},
			{
				"metaboliteId": "0078d983-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef5a-0000-0000-0000-000000000000",
				"chemicalName": "prolylglycine",
				"plotName": null,
				"compoundId": "00009eff-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11178",
				"inChiKey": "RNKSNIBMTUYWSH-YFKPBYRVBZ",
				"kegg": null,
				"mass": 172.084792,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d984-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef38-0000-0000-0000-000000000000",
				"chemicalName": "valylglutamine",
				"plotName": null,
				"compoundId": "0000a45f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29125",
				"inChiKey": "XXDVDTMEVBYRPK-UHFFFAOYAL",
				"kegg": null,
				"mass": 245.137556,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 938,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3650"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1723"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0198"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0093"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1189"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3140"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5113"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5057"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7889"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1904"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7889"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7331"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0529"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6657"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3943"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6699"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1876"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7591"
					}
				]
			},
			{
				"metaboliteId": "0078d985-0000-0000-0000-000000000000",
				"chemicalId": "05f5e77e-0000-0000-0000-000000000000",
				"chemicalName": "deoxycarnitine",
				"plotName": null,
				"compoundId": "00008f8b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01161",
				"inChiKey": "JHPNVNIEXXLNTR-UHFFFAOYAL",
				"kegg": "C01181",
				"mass": 145.110279,
				"pathwayType": "ANIMAL",
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1703,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8888"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7853"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7150"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6604"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1459"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2654"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3755"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8909"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4422"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2828"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0254"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2713"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8749"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8695"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9575"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5187"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8522"
					}
				]
			},
			{
				"metaboliteId": "0078d986-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7c6-0000-0000-0000-000000000000",
				"chemicalName": "N6-acetyllysine",
				"plotName": null,
				"compoundId": "00008f90-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00206",
				"inChiKey": "DTERQYGMUDWYAZ-ZETCQYMHBE",
				"kegg": "C02727",
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 100,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3078"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1012"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3169"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9101"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9712"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5795"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8358"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2646"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7669"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4985"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0114"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0711"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0066"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5415"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1315"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0932"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6352"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0648"
					}
				]
			},
			{
				"metaboliteId": "0078d987-0000-0000-0000-000000000000",
				"chemicalId": "05f5e80d-0000-0000-0000-000000000000",
				"chemicalName": "methylphosphate",
				"plotName": null,
				"compoundId": "000090ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61711",
				"inChiKey": "CAAULPUQFIIOTL-UHFFFAOYAW",
				"kegg": null,
				"mass": 111.992545,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine and Pyrimidine Metabolism",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3896,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d988-0000-0000-0000-000000000000",
				"chemicalId": "000000d5-0000-0000-0000-000000000000",
				"chemicalName": "N6-methyladenosine",
				"plotName": null,
				"compoundId": "000090fa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04044",
				"inChiKey": "VQAYFKKCNSOZKM-IOSLPCCCBA",
				"kegg": null,
				"mass": 281.112405,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3775,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2316"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9346"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7139"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3061"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7601"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5857"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6117"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6310"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6096"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8036"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0975"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3182"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6310"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0017"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6130"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9959"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3410"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9700"
					}
				]
			},
			{
				"metaboliteId": "0078d989-0000-0000-0000-000000000000",
				"chemicalId": "05f5e880-0000-0000-0000-000000000000",
				"chemicalName": "quercetin 3-glucoside",
				"plotName": null,
				"compoundId": "0000910b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB37362",
				"inChiKey": null,
				"kegg": "C05623",
				"mass": 464.095476,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4526,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5604"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3241"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2400"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4285"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1157"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0019"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2372"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7305"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2263"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6704"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2720"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1167"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8653"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7750"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0322"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5417"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3303"
					}
				]
			},
			{
				"metaboliteId": "0078d98a-0000-0000-0000-000000000000",
				"chemicalId": "05f603e0-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxybutyrate/2-hydroxyisobutyrate",
				"plotName": null,
				"compoundId": "0000cc39-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 104.047344,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 502,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2704"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8150"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4703"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5327"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3510"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7132"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1338"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0254"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3178"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4735"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2493"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6447"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0648"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2010"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3985"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2827"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2428"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1172"
					}
				]
			},
			{
				"metaboliteId": "0078d98b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5cf-0000-0000-0000-000000000000",
				"chemicalName": "nervonate (24:1n9)*",
				"plotName": null,
				"compoundId": "0000cdc2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02368",
				"inChiKey": "GWHCXVQVJPWHRF-KTKRTIGZBL",
				"kegg": "C08323",
				"mass": 366.349781,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1376,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d98c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f997-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/22:1, d18:2/22:0, d16:1/24:1)*",
				"plotName": null,
				"compoundId": "0000bd6d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12104",
				"inChiKey": "VBFKEZGCUWHGSK-ADSSWVSQBB",
				"kegg": null,
				"mass": 784.645825,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2814,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d98d-0000-0000-0000-000000000000",
				"chemicalId": "05f603f8-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoylglycerol (16:1)*",
				"plotName": null,
				"compoundId": "0000cccf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11565",
				"inChiKey": null,
				"kegg": null,
				"mass": 328.261353,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2554,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6454"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4254"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5098"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7088"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8424"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6276"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1269"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0150"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8574"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1654"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2746"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3241"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0662"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0662"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4072"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2109"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2749"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2164"
					}
				]
			},
			{
				"metaboliteId": "0078d98e-0000-0000-0000-000000000000",
				"chemicalId": "05f6041e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-GPE (16:0/20:4)*",
				"plotName": null,
				"compoundId": "0000ccf0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05323",
				"inChiKey": "DRIVXEVMDWCWLI-CAQMIEAIBF",
				"kegg": null,
				"mass": 739.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2148,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d98f-0000-0000-0000-000000000000",
				"chemicalId": "00000607-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPG (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000cd94-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10575",
				"inChiKey": "ATBOMIWRCZXYSZ-DLPCDOJJBW",
				"kegg": null,
				"mass": 746.509788,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylglycerol (PG)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2244,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d990-0000-0000-0000-000000000000",
				"chemicalId": "05f6045c-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-linoleoyl-glycerol (16:0/18:2) [1]*",
				"plotName": null,
				"compoundId": "0000cd99-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07103",
				"inChiKey": "SVXWJFFKLMLOHO-YAIZGCQRBM",
				"kegg": null,
				"mass": 592.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2607,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1451"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4532"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7663"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3762"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5163"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0510"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4183"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2247"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3464"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2839"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0588"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7816"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.7486"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3549"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3340"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3232"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8289"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0105"
					}
				]
			},
			{
				"metaboliteId": "0078d991-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea18-0000-0000-0000-000000000000",
				"chemicalName": "alpha-tocopherol acetate",
				"plotName": null,
				"compoundId": "00009590-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34227",
				"inChiKey": "ZAKOWWREFLAJOT-UHFFFAOYAN",
				"kegg": "C13202",
				"mass": 472.391646,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3969,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d992-0000-0000-0000-000000000000",
				"chemicalId": "05f60501-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPE (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000cfb6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09093",
				"inChiKey": null,
				"kegg": null,
				"mass": 739.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2178,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9404"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0435"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8056"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9037"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5071"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6575"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7578"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1897"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9155"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3369"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1600"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7930"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1624"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6674"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4405"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3240"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6770"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3634"
					}
				]
			},
			{
				"metaboliteId": "0078d993-0000-0000-0000-000000000000",
				"chemicalId": "05f603d9-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-stearoyl-GPC (16:0/18:0)",
				"plotName": null,
				"compoundId": "0000cd88-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07970",
				"inChiKey": "PZNPLUBHRSSFHT-RRHRGVEJBV",
				"kegg": null,
				"mass": 761.593455,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2021,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2593"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8803"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2421"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9050"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3578"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4145"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5989"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7888"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2834"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1509"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1907"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5633"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3230"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0942"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3975"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8511"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8511"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7299"
					}
				]
			},
			{
				"metaboliteId": "0078d994-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecae-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-linoleoyl-glycerol (18:1/18:2) [2]",
				"plotName": null,
				"compoundId": "0000b6cf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07219",
				"inChiKey": null,
				"kegg": null,
				"mass": 618.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2627,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5404"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6148"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7805"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3045"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8953"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6051"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5394"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4269"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6012"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2315"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7549"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9167"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0284"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2749"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5577"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4226"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2210"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8470"
					}
				]
			},
			{
				"metaboliteId": "0078d995-0000-0000-0000-000000000000",
				"chemicalId": "05f60510-0000-0000-0000-000000000000",
				"chemicalName": "thioproline",
				"plotName": null,
				"compoundId": "0000cfef-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DZLNHFMRPBPULJ-VKHMYHEABX",
				"kegg": null,
				"mass": 133.019749,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5369,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5741"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2386"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3777"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7730"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8024"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1168"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4481"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3002"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2845"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1249"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6488"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4668"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4415"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1985"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7596"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2513"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9132"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6305"
					}
				]
			},
			{
				"metaboliteId": "0078d996-0000-0000-0000-000000000000",
				"chemicalId": "05f60476-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-2-linoleoyl-GPE (18:1/18:2)*",
				"plotName": null,
				"compoundId": "0000cdcf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05349",
				"inChiKey": null,
				"kegg": null,
				"mass": 741.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2171,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3245"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8883"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5759"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9059"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5583"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5549"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1073"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1118"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0185"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2213"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1817"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0087"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6756"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9686"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1258"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4689"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6887"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5438"
					}
				]
			},
			{
				"metaboliteId": "0078d997-0000-0000-0000-000000000000",
				"chemicalId": "05f605b0-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinolenoyl-digalactosylglycerol (18:3/18:3)",
				"plotName": null,
				"compoundId": "0000d66c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 936.581022,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2683,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5869"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3028"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7364"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9210"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4941"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3293"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3970"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0637"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0784"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9794"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1213"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7623"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4755"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5056"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6819"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2573"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1460"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2184"
					}
				]
			},
			{
				"metaboliteId": "0078d998-0000-0000-0000-000000000000",
				"chemicalId": "00000236-0000-0000-0000-000000000000",
				"chemicalName": "valine",
				"plotName": null,
				"compoundId": "00000671-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00883",
				"inChiKey": "KZSNJWFQEVHDMF-BYPYZUCNBW",
				"kegg": "C00183",
				"mass": 117.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6009"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1909"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3927"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8758"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3940"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2137"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7073"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0042"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4912"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1270"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5736"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1429"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1915"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7068"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7032"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6234"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4602"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1864"
					}
				]
			},
			{
				"metaboliteId": "0078d999-0000-0000-0000-000000000000",
				"chemicalId": "05f5e510-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoylglycerol (18:2)",
				"plotName": null,
				"compoundId": "00006b37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WECGLUPZRHILCT-HZJYTTRNBQ",
				"kegg": null,
				"mass": 354.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2558,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3764"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5026"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1460"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3096"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0631"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5626"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0857"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5488"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5723"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0756"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1920"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0102"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6657"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6278"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6224"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0619"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1529"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3436"
					}
				]
			},
			{
				"metaboliteId": "0078d99a-0000-0000-0000-000000000000",
				"chemicalId": "05f60b9d-0000-0000-0000-000000000000",
				"chemicalName": "coumaroylquinate (4)",
				"plotName": null,
				"compoundId": "0000d691-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 338.100168,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4373,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d99b-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba7-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-oleoyl-glycerol (18:1/18:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6a2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07218",
				"inChiKey": null,
				"kegg": null,
				"mass": 620.537975,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2624,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0125"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1279"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6271"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1395"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8256"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0404"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7306"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9385"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8248"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0394"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3884"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9233"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5932"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8248"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5829"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2632"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1074"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6846"
					}
				]
			},
			{
				"metaboliteId": "0078d99c-0000-0000-0000-000000000000",
				"chemicalId": "05f61eab-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/19:0, d19:1/18:0)*",
				"plotName": null,
				"compoundId": "0000e083-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 744.614502,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2808,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d99d-0000-0000-0000-000000000000",
				"chemicalId": "05f60b7d-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinolenoyl-galactosylglycerol (18:3/18:3)*",
				"plotName": null,
				"compoundId": "0000d66b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": "C13871",
				"mass": 774.528199,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2682,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4177"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4308"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1478"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9012"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6966"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0308"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2462"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0932"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3437"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3785"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2844"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5308"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1022"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3379"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6376"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7157"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8921"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0750"
					}
				]
			},
			{
				"metaboliteId": "0078d99e-0000-0000-0000-000000000000",
				"chemicalId": "000000f1-0000-0000-0000-000000000000",
				"chemicalName": "phenylpyruvate",
				"plotName": null,
				"compoundId": "00000236-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00205",
				"inChiKey": "BTNMPGBKDVTSJY-UHFFFAOYAU",
				"kegg": "C00166",
				"mass": 164.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 140,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d99f-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2ab-0000-0000-0000-000000000000",
				"chemicalName": "N-delta-acetylornithine",
				"plotName": null,
				"compoundId": "0000a8f1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "SRXKAYJJGAAOBP-UHFFFAOYAF",
				"kegg": null,
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 436,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7820"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1247"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9141"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8151"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7076"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1293"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1454"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2947"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4293"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9989"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0824"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8757"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4829"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3403"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1881"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0574"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3861"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3012"
					}
				]
			},
			{
				"metaboliteId": "0078d9a0-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1e1-0000-0000-0000-000000000000",
				"chemicalName": "2,8-quinolinediol sulfate",
				"plotName": null,
				"compoundId": "0000be33-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 241.004493,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4286,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a1-0000-0000-0000-000000000000",
				"chemicalId": "05f60442-0000-0000-0000-000000000000",
				"chemicalName": "behenoyl dihydrosphingomyelin (d18:0/22:0)*",
				"plotName": null,
				"compoundId": "0000dff3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12091",
				"inChiKey": "FONAXCRWZQFJHY-JCGOJSMZBF",
				"kegg": null,
				"mass": 788.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2788,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a2-0000-0000-0000-000000000000",
				"chemicalId": "05f60470-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPA (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000cdc8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "OBXRDFNCKFWKNY-UCEXZFFABO",
				"kegg": null,
				"mass": 696.473006,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1996,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9376"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3486"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1528"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0713"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0409"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9755"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0854"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1450"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1642"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4839"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5104"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8651"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0354"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5876"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1811"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2968"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6817"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7727"
					}
				]
			},
			{
				"metaboliteId": "0078d9a3-0000-0000-0000-000000000000",
				"chemicalId": "0000048a-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylneuraminate",
				"plotName": null,
				"compoundId": "00007e79-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00230",
				"inChiKey": "KBGAYAKRZNYFFG-BOHATCBPBL",
				"kegg": "C00270",
				"mass": 309.105981,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1263,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a4-0000-0000-0000-000000000000",
				"chemicalId": "0000006a-0000-0000-0000-000000000000",
				"chemicalName": "uridine 3'-monophosphate (3'-UMP)",
				"plotName": null,
				"compoundId": "00009b54-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FOGRQMPFHUHIGU-XVFCMESIBZ",
				"kegg": "C01368",
				"mass": 324.035871,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3834,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a5-0000-0000-0000-000000000000",
				"chemicalId": "00000200-0000-0000-0000-000000000000",
				"chemicalName": "taurine",
				"plotName": null,
				"compoundId": "0000084d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00251",
				"inChiKey": "XOAAWQZATWQOTB-UHFFFAOYAA",
				"kegg": "C00245",
				"mass": 125.014666,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 406,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a6-0000-0000-0000-000000000000",
				"chemicalId": "00000163-0000-0000-0000-000000000000",
				"chemicalName": "histidine",
				"plotName": null,
				"compoundId": "0000003b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00177",
				"inChiKey": "HNDVDQJCIGZPNO-UHFFFAOYAG",
				"kegg": "C00135",
				"mass": 155.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 67,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5162"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3029"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1214"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4535"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3246"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7409"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0317"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4392"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2455"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2192"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2666"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4845"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2541"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7631"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1222"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8242"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1182"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1739"
					}
				]
			},
			{
				"metaboliteId": "0078d9a7-0000-0000-0000-000000000000",
				"chemicalId": "00000090-0000-0000-0000-000000000000",
				"chemicalName": "4-hydroxyphenylacetate",
				"plotName": null,
				"compoundId": "0000021d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00020",
				"inChiKey": "XQXPVVBIMDBYFF-REWHXWOFAL",
				"kegg": "C00642",
				"mass": 152.047345,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 149,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e124-0000-0000-0000-000000000000",
				"chemicalName": "3-methyl-2-oxovalerate",
				"plotName": null,
				"compoundId": "00003d3c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03736",
				"inChiKey": "JVQYSWDUAOAHFM-UHFFFAOYAW",
				"kegg": "C00671",
				"mass": 130.062995,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9a9-0000-0000-0000-000000000000",
				"chemicalId": "000004f4-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylleucine",
				"plotName": null,
				"compoundId": "000047c1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11171",
				"inChiKey": "MYFMARDICOWMQP-YUMQZZPRBM",
				"kegg": null,
				"mass": 260.137223,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 555,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9aa-0000-0000-0000-000000000000",
				"chemicalId": "000000f0-0000-0000-0000-000000000000",
				"chemicalName": "3-(4-hydroxyphenyl)lactate",
				"plotName": null,
				"compoundId": "00007dc5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00755",
				"inChiKey": "JVGVDSSUAVXRDY-UHFFFAOYAV",
				"kegg": "C03672",
				"mass": 182.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 168,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6613"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7227"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8568"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0465"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6479"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3095"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0652"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6506"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6714"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6446"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5173"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7057"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2804"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5322"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9363"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8994"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8509"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6309"
					}
				]
			},
			{
				"metaboliteId": "0078d9ab-0000-0000-0000-000000000000",
				"chemicalId": "000004d7-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxystearate",
				"plotName": null,
				"compoundId": "00004619-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KIHBGTRZFAVZRV-UHFFFAOYAD",
				"kegg": "C03045",
				"mass": 300.266445,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1737,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2806"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2316"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2973"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8542"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1112"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8098"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5642"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2088"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5828"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9574"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2632"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1481"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8550"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7082"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4267"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7553"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3818"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0743"
					}
				]
			},
			{
				"metaboliteId": "0078d9ac-0000-0000-0000-000000000000",
				"chemicalId": "00000814-0000-0000-0000-000000000000",
				"chemicalName": "catechin",
				"plotName": null,
				"compoundId": "00004504-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02780",
				"inChiKey": "PFTAWBLQPZVEMU-DZGCQCFKBX",
				"kegg": "C06562",
				"mass": 290.079038,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4240,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9ad-0000-0000-0000-000000000000",
				"chemicalId": "00000207-0000-0000-0000-000000000000",
				"chemicalName": "myristate (14:0)",
				"plotName": null,
				"compoundId": "00000555-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00806",
				"inChiKey": "TUNFSRHWOTWDNC-UHFFFAOYAZ",
				"kegg": "C06424",
				"mass": 228.20893,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1341,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0654"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2339"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5572"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5072"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7846"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8532"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9238"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6110"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1618"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4350"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7495"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1623"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0663"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7526"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0438"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6585"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8077"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8474"
					}
				]
			},
			{
				"metaboliteId": "0078d9ae-0000-0000-0000-000000000000",
				"chemicalId": "05f5e844-0000-0000-0000-000000000000",
				"chemicalName": "pinitol",
				"plotName": null,
				"compoundId": "000090de-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34219",
				"inChiKey": "DSCFFEYYQKSRSV-KLJZZCKABB",
				"kegg": "C03844",
				"mass": 194.079038,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1947,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9af-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c3-0000-0000-0000-000000000000",
				"chemicalName": "daidzein",
				"plotName": null,
				"compoundId": "00007ec5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03312",
				"inChiKey": "ZQSIJRDFPHDXIC-UHFFFAOYAG",
				"kegg": "C10208",
				"mass": 254.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4381,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e72b-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxypalmitate",
				"plotName": null,
				"compoundId": "00008b5b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB31057",
				"inChiKey": "JGHSBPIZNUXPLA-UHFFFAOYAI",
				"kegg": null,
				"mass": 272.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1735,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5427"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4254"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9669"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0346"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0483"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4865"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1602"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7781"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8717"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2517"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4989"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1839"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2060"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3184"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7440"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3110"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1156"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1178"
					}
				]
			},
			{
				"metaboliteId": "0078d9b1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5c2-0000-0000-0000-000000000000",
				"chemicalName": "daidzin (daidzein 7-O-glucoside)",
				"plotName": null,
				"compoundId": "00007eb3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33991",
				"inChiKey": "KYQZWONCHDNPDP-MKJMBMEGBK",
				"kegg": "C10216",
				"mass": 416.110732,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4383,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e799-0000-0000-0000-000000000000",
				"chemicalName": "pheophorbide A",
				"plotName": null,
				"compoundId": "00008c27-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": "C18021",
				"mass": 592.26857,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4488,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e623-0000-0000-0000-000000000000",
				"chemicalName": "p-cresol sulfate",
				"plotName": null,
				"compoundId": "00008d07-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11635",
				"inChiKey": "WGNAKZGUSRVWRH-REWHXWOFAB",
				"kegg": null,
				"mass": 188.014329,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4132,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8443"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0381"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6329"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2891"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1274"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9218"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3560"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3456"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3617"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2509"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0976"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1799"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3808"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0770"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8445"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4082"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6423"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2259"
					}
				]
			},
			{
				"metaboliteId": "0078d9b4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e446-0000-0000-0000-000000000000",
				"chemicalName": "hesperidin (hesperetin 7-rutinoside)",
				"plotName": null,
				"compoundId": "00005334-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03265",
				"inChiKey": "QUQPHWDTPGMPEX-QJBIFVCTBQ",
				"kegg": "C09755",
				"mass": 610.18977,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4579,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9cd-0000-0000-0000-000000000000",
				"chemicalName": "cinnamoylglycine",
				"plotName": null,
				"compoundId": "000096ed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11621",
				"inChiKey": "YAADMLWHGMUGQL-VOTSOKGWBK",
				"kegg": null,
				"mass": 205.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b6-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec47-0000-0000-0000-000000000000",
				"chemicalName": "eriodictyol",
				"plotName": null,
				"compoundId": "00009abb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05810",
				"inChiKey": "SBHXYTNGIZCORC-UHFFFAOYAW",
				"kegg": "C05631",
				"mass": 288.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4402,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b7-0000-0000-0000-000000000000",
				"chemicalId": "05f5ece7-0000-0000-0000-000000000000",
				"chemicalName": "1,1-kestotetraose",
				"plotName": null,
				"compoundId": "00009b74-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB39176",
				"inChiKey": "4000",
				"kegg": null,
				"mass": 666.221858,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4250,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b8-0000-0000-0000-000000000000",
				"chemicalId": "05f5e848-0000-0000-0000-000000000000",
				"chemicalName": "verbascose",
				"plotName": null,
				"compoundId": "0000910c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "FLUADVWHMHPUCG-SWPIJASHBO",
				"kegg": "C08252",
				"mass": 828.274682,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1184,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9b9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e107-0000-0000-0000-000000000000",
				"chemicalName": "carnitine",
				"plotName": null,
				"compoundId": "00003c8c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00062",
				"inChiKey": "PHIQHXFUZVPYII-ZCFIWIBFBS",
				"kegg": "C00318",
				"mass": 161.105193,
				"pathwayType": "ANIMAL",
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1704,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8031"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7337"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6622"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6525"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5760"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5894"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2879"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3865"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2059"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3120"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3225"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0016"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4346"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9773"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9067"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7721"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6202"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0058"
					}
				]
			},
			{
				"metaboliteId": "0078d9ba-0000-0000-0000-000000000000",
				"chemicalId": "0000036e-0000-0000-0000-000000000000",
				"chemicalName": "fructose",
				"plotName": null,
				"compoundId": "0000bc43-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00660",
				"inChiKey": "BJHIKXHVCXFQLS-UYFOZJQFBH",
				"kegg": "C00095",
				"mass": 180.06339,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1193,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7055"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5744"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3545"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1484"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0373"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5096"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2183"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9546"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5468"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6747"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5438"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7510"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7727"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4569"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7007"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9160"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9082"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8900"
					}
				]
			},
			{
				"metaboliteId": "0078d9bb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e228-0000-0000-0000-000000000000",
				"chemicalName": "trehalose 6-phosphate",
				"plotName": null,
				"compoundId": "00008fdc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01124",
				"inChiKey": "LABSPYBHMPDTEL-LIZSDCNHBI",
				"kegg": "C00689",
				"mass": 422.082542,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1175,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1017"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3045"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3670"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1309"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4914"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7302"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5174"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0002"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0002"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2631"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5844"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4167"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1753"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8735"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0787"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0652"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7565"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9166"
					}
				]
			},
			{
				"metaboliteId": "0078d9bc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9d5-0000-0000-0000-000000000000",
				"chemicalName": "tribuloside",
				"plotName": null,
				"compoundId": "0000951f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "DVGGLGXQSFURLP-VWMSDXGPBE",
				"kegg": "C17140",
				"mass": 594.137341,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4569,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9bd-0000-0000-0000-000000000000",
				"chemicalId": "000001f4-0000-0000-0000-000000000000",
				"chemicalName": "riboflavin (Vitamin B2)",
				"plotName": null,
				"compoundId": "00000723-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00244",
				"inChiKey": "AUNGANRZJHBGPY-SCRDCRAPBG",
				"kegg": "C00255",
				"mass": 376.138286,
				"pathwayType": "ANIMAL",
				"subPathway": "Riboflavin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3941,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9be-0000-0000-0000-000000000000",
				"chemicalId": "00000320-0000-0000-0000-000000000000",
				"chemicalName": "cysteine",
				"plotName": null,
				"compoundId": "0000074c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00574",
				"inChiKey": "XUJNEKJLAYXESH-REOHCLBHBU",
				"kegg": "C00097",
				"mass": 121.019751,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 391,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9bf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e86f-0000-0000-0000-000000000000",
				"chemicalName": "cosmosiin (apigenin 7-O-glucoside)",
				"plotName": null,
				"compoundId": "00009107-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB37340",
				"inChiKey": "KMOUJOKENFFTPU-QNDFHXLGBM",
				"kegg": "C04608",
				"mass": 432.105647,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4258,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9c0-0000-0000-0000-000000000000",
				"chemicalId": "0000041d-0000-0000-0000-000000000000",
				"chemicalName": "3-ureidopropionate",
				"plotName": null,
				"compoundId": "00000c53-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00026",
				"inChiKey": "JSJWCHRYRHKBBW-UHFFFAOYAH",
				"kegg": "C02642",
				"mass": 132.053493,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9c1-0000-0000-0000-000000000000",
				"chemicalId": "00000326-0000-0000-0000-000000000000",
				"chemicalName": "dimethylglycine",
				"plotName": null,
				"compoundId": "000013de-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00092",
				"inChiKey": "FFDGPVCHZBVARC-UHFFFAOYAX",
				"kegg": "C01026",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 5,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9c2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7e7-0000-0000-0000-000000000000",
				"chemicalName": "pyrraline",
				"plotName": null,
				"compoundId": "0000bd2c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB33143",
				"inChiKey": "VTYFITADLSVOAS-UHFFFAOYAA",
				"kegg": null,
				"mass": 254.126657,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4605,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9c3-0000-0000-0000-000000000000",
				"chemicalId": "000001cc-0000-0000-0000-000000000000",
				"chemicalName": "phenylalanine",
				"plotName": null,
				"compoundId": "00000040-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00159",
				"inChiKey": "COLNVLDHVKWLRT-QMMMGPOBBC",
				"kegg": "C00079",
				"mass": 165.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 130,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2334"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2346"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3294"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3407"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5452"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0938"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9405"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1979"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7724"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0846"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3003"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3865"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9818"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5993"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4374"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8598"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0730"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1938"
					}
				]
			},
			{
				"metaboliteId": "0078d9c4-0000-0000-0000-000000000000",
				"chemicalId": "00000395-0000-0000-0000-000000000000",
				"chemicalName": "asparagine",
				"plotName": null,
				"compoundId": "00000200-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00168",
				"inChiKey": "DCXYFEDJOCDNAF-REOHCLBHBD",
				"kegg": "C00152",
				"mass": 132.053493,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 39,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4323"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6431"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6710"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2416"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3097"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8007"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2705"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5384"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4455"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6353"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8156"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9683"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4672"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6746"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8318"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3665"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1367"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3561"
					}
				]
			},
			{
				"metaboliteId": "0078d9c5-0000-0000-0000-000000000000",
				"chemicalId": "00000178-0000-0000-0000-000000000000",
				"chemicalName": "isoleucine",
				"plotName": null,
				"compoundId": "00000465-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00172",
				"inChiKey": "AGPKZVBTJJNPAG-UHFFFAOYAW",
				"kegg": "C00407",
				"mass": 131.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 329,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5454"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3222"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4172"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0512"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5142"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2491"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0529"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0716"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6059"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4953"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0654"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0718"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7438"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5471"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5240"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3091"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2473"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1069"
					}
				]
			},
			{
				"metaboliteId": "0078d9c6-0000-0000-0000-000000000000",
				"chemicalId": "0000019f-0000-0000-0000-000000000000",
				"chemicalName": "methionine",
				"plotName": null,
				"compoundId": "00000516-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00696",
				"inChiKey": "FFEARJCKVFRZRR-BYPYZUCNBW",
				"kegg": "C00073",
				"mass": 149.051051,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3430"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1659"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2864"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7642"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3033"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1523"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2951"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3472"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7948"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2010"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1721"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2248"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0297"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3625"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8432"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7394"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7971"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7168"
					}
				]
			},
			{
				"metaboliteId": "0078d9c7-0000-0000-0000-000000000000",
				"chemicalId": "000000d3-0000-0000-0000-000000000000",
				"chemicalName": "2'-deoxyadenosine",
				"plotName": null,
				"compoundId": "00000611-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00101",
				"inChiKey": "OLXZPDWKRNYJJZ-RRKCRQDMBK",
				"kegg": "C00559",
				"mass": 251.10184,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3790,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0926"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5343"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9119"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2887"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3730"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4852"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3652"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3232"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1009"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8573"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4692"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9355"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7851"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3388"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2696"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7369"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1667"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5095"
					}
				]
			},
			{
				"metaboliteId": "0078d9c8-0000-0000-0000-000000000000",
				"chemicalId": "0000023c-0000-0000-0000-000000000000",
				"chemicalName": "glucose",
				"plotName": null,
				"compoundId": "0000bc18-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00122",
				"inChiKey": "WQZGKKKJIJFFOK-DVKNGEFBBQ",
				"kegg": "C00031",
				"mass": 180.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1073,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4429"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2148"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1141"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4875"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3821"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4555"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0980"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7813"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4082"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0964"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9098"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3293"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4803"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6228"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8899"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8311"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8244"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7249"
					}
				]
			},
			{
				"metaboliteId": "0078d9c9-0000-0000-0000-000000000000",
				"chemicalId": "0000045a-0000-0000-0000-000000000000",
				"chemicalName": "3-aminoisobutyrate",
				"plotName": null,
				"compoundId": "0000061e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03911",
				"inChiKey": "QCHPKSFMDHPSNR-UHFFFAOYAN",
				"kegg": "C05145",
				"mass": 103.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3895,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9ca-0000-0000-0000-000000000000",
				"chemicalId": "00000162-0000-0000-0000-000000000000",
				"chemicalName": "histamine",
				"plotName": null,
				"compoundId": "00000626-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00870",
				"inChiKey": "NTYJJOPFIAHURM-UHFFFAOYAP",
				"kegg": "C00388",
				"mass": 111.079647,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 83,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9cb-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2c7-0000-0000-0000-000000000000",
				"chemicalName": "itaconate",
				"plotName": null,
				"compoundId": "000047c5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02092",
				"inChiKey": "LVHBHZANLOWSRM-UHFFFAOYAS",
				"kegg": "C00490",
				"mass": 130.026609,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1299,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0765"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0442"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7356"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2063"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0598"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0490"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3201"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3980"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2161"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1801"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5075"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4938"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2169"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9528"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8781"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5888"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3926"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2063"
					}
				]
			},
			{
				"metaboliteId": "0078d9cc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e762-0000-0000-0000-000000000000",
				"chemicalName": "vanillate",
				"plotName": null,
				"compoundId": "00008b37-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00484",
				"inChiKey": "WKOLLVMJNQIZCI-UHFFFAOYAH",
				"kegg": "C06672",
				"mass": 168.042259,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4273,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3528"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1497"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2804"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3582"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1051"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0155"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3452"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2625"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1663"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9636"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2451"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3150"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1268"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2831"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1738"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.6821"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7928"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0196"
					}
				]
			},
			{
				"metaboliteId": "0078d9cd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9fc-0000-0000-0000-000000000000",
				"chemicalName": "5-methyl-2'-deoxycytidine",
				"plotName": null,
				"compoundId": "0000950f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02224",
				"inChiKey": "LUCHPKXVUGJYGU-XLPZGREQBL",
				"kegg": "C03592",
				"mass": 241.106256,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3880,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9ce-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea5e-0000-0000-0000-000000000000",
				"chemicalName": "piperidine",
				"plotName": null,
				"compoundId": "00009527-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB34301",
				"inChiKey": "NQRYJNQNLNOLGT-UHFFFAOYAY",
				"kegg": "C01746",
				"mass": 85.089149,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4188,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5118"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7778"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4765"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4406"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9896"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2378"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1804"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4841"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1060"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6809"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7720"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6864"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5022"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2353"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0989"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2632"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0772"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3931"
					}
				]
			},
			{
				"metaboliteId": "0078d9cf-0000-0000-0000-000000000000",
				"chemicalId": "05f5e704-0000-0000-0000-000000000000",
				"chemicalName": "pyroglutamine*",
				"plotName": null,
				"compoundId": "0000b491-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NPWMTBZSRRLQNJ-UHFFFAOYAH",
				"kegg": null,
				"mass": 128.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 52,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2350"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0300"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8586"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2043"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4468"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0052"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0836"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0817"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1231"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7657"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9046"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3630"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2116"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2416"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9081"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5491"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2189"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5039"
					}
				]
			},
			{
				"metaboliteId": "0078d9d0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e622-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylthreonine",
				"plotName": null,
				"compoundId": "00008254-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29159",
				"inChiKey": "GWNXFCYUJXASDX-JUZNGCLZBW",
				"kegg": null,
				"mass": 248.100836,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9d1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e51f-0000-0000-0000-000000000000",
				"chemicalName": "isobutyrylcarnitine (C4)",
				"plotName": null,
				"compoundId": "000082a1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00736",
				"inChiKey": null,
				"kegg": null,
				"mass": 231.147058,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 363,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9d2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e54f-0000-0000-0000-000000000000",
				"chemicalName": "glutamate, gamma-methyl ester",
				"plotName": null,
				"compoundId": "000082cf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61715",
				"inChiKey": "ZGEYCCHDTIDZAE-UHFFFAOYAN",
				"kegg": null,
				"mass": 161.068808,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 51,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9d3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60d-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylhistidine",
				"plotName": null,
				"compoundId": "0000849a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB32055",
				"inChiKey": "KBOJOGQFRVVWBH-ZETCQYMHBN",
				"kegg": "C02997",
				"mass": 197.080041,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 70,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9d4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ea-0000-0000-0000-000000000000",
				"chemicalName": "glycylleucine",
				"plotName": null,
				"compoundId": "0000865e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00759",
				"inChiKey": "DKEXFJVMVGETOO-LURJTMIEBJ",
				"kegg": "C02155",
				"mass": 188.116092,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 729,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9d5-0000-0000-0000-000000000000",
				"chemicalId": "05f5e763-0000-0000-0000-000000000000",
				"chemicalName": "ectoine",
				"plotName": null,
				"compoundId": "00008b43-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WQXNXVUDBPYKBA-YFKPBYRVBN",
				"kegg": "C06231",
				"mass": 142.074228,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5296,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1436"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5077"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3490"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5411"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5950"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8208"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9072"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3643"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2118"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4873"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2804"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2699"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1697"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1692"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3935"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0643"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0967"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0612"
					}
				]
			},
			{
				"metaboliteId": "0078d9d6-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0c3-0000-0000-0000-000000000000",
				"chemicalName": "nicotianamine",
				"plotName": null,
				"compoundId": "0000a812-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KRGPXXHMOXVMMM-CIUDSAMLBB",
				"kegg": "C05324",
				"mass": 303.143035,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4477,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1324"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5417"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0620"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9301"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4427"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2977"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7027"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6129"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9007"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9440"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4725"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0040"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.7206"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5473"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4254"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1059"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1256"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4163"
					}
				]
			},
			{
				"metaboliteId": "0078d9d7-0000-0000-0000-000000000000",
				"chemicalId": "05f5e840-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-oleoyl-GPE (18:0/18:1)",
				"plotName": null,
				"compoundId": "0000a5d0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08993",
				"inChiKey": null,
				"kegg": null,
				"mass": 745.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2157,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2338"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6078"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5664"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8386"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7933"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2108"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5543"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0373"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6157"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2879"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2476"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3575"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2794"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9609"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3406"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4688"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3575"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1870"
					}
				]
			},
			{
				"metaboliteId": "0078d9d8-0000-0000-0000-000000000000",
				"chemicalId": "0000013a-0000-0000-0000-000000000000",
				"chemicalName": "behenate (22:0)*",
				"plotName": null,
				"compoundId": "00002f5d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00944",
				"inChiKey": "UKMSUNONTOPOIO-UHFFFAOYAN",
				"kegg": "C08281",
				"mass": 340.33413,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1349,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8316"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6259"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2334"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6816"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2576"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6995"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0537"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7899"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8541"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7163"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0013"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4690"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0611"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0026"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6285"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2796"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1534"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3128"
					}
				]
			},
			{
				"metaboliteId": "0078d9d9-0000-0000-0000-000000000000",
				"chemicalId": "00000601-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPC (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000a5ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07973",
				"inChiKey": "JLPULHDHAOZNQI-ZTIMHPMXBU",
				"kegg": null,
				"mass": 757.562155,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2026,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3961"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0940"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5082"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2384"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4830"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5548"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0806"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0063"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0027"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3121"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1734"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2407"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8084"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0176"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2321"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5697"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.6604"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1578"
					}
				]
			},
			{
				"metaboliteId": "0078d9da-0000-0000-0000-000000000000",
				"chemicalId": "05f60426-0000-0000-0000-000000000000",
				"chemicalName": "gamma-tocopherol/beta-tocopherol",
				"plotName": null,
				"compoundId": "0000ccf9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 416.365431,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3990,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4191"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5497"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7485"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2835"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1345"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4548"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8354"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3852"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7216"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2715"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1524"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3019"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7899"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4129"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0944"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3128"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3725"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7804"
					}
				]
			},
			{
				"metaboliteId": "0078d9db-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8bd-0000-0000-0000-000000000000",
				"chemicalName": "gamma-tocotrienol",
				"plotName": null,
				"compoundId": "0000927d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12958",
				"inChiKey": "OTXNTMVVOOBZCV-WAZJVIJMBV",
				"kegg": "C14155",
				"mass": 410.318481,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3975,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9dc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e84e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-GPE (16:0/18:2)",
				"plotName": null,
				"compoundId": "0000a5d1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB05322",
				"inChiKey": "HBZNVZIRJWODIB-NHCUFCNUBC",
				"kegg": null,
				"mass": 715.515205,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2145,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5556"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0205"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2063"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1280"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4480"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4495"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3978"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0760"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0910"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8090"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1063"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1256"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0215"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0381"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3180"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7996"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3581"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1189"
					}
				]
			},
			{
				"metaboliteId": "0078d9dd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e994-0000-0000-0000-000000000000",
				"chemicalName": "13-HODE + 9-HODE",
				"plotName": null,
				"compoundId": "00009378-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 296.235145,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1769,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1976"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2136"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1393"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3007"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1052"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0342"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8855"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5143"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3472"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2306"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0755"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1772"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7835"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4264"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2599"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0785"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6442"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3041"
					}
				]
			},
			{
				"metaboliteId": "0078d9de-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60f-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyltryptophan",
				"plotName": null,
				"compoundId": "0000849b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29160",
				"inChiKey": "CATMPQFFVNKDEY-AAEUAGOBBU",
				"kegg": null,
				"mass": 333.132471,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 561,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9df-0000-0000-0000-000000000000",
				"chemicalId": "05f5eb3b-0000-0000-0000-000000000000",
				"chemicalName": "sedoheptulose",
				"plotName": null,
				"compoundId": "0000cff5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03219",
				"inChiKey": "HSNZZMHEPUFJNZ-SHUUEZRQBR",
				"kegg": null,
				"mass": 210.073953,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1136,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9776"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1242"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2530"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5113"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4964"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6514"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7547"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3746"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3367"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3553"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3553"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1835"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2447"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3148"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2337"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0987"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8966"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3747"
					}
				]
			},
			{
				"metaboliteId": "0078d9e0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e518-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoylglycerol (16:0)",
				"plotName": null,
				"compoundId": "0000828b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11533",
				"inChiKey": "BBNYCLAREVXOSG-UHFFFAOYAG",
				"kegg": null,
				"mass": 330.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2569,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9559"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8469"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0997"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0560"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5498"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6914"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2626"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5888"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3919"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0035"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0708"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2517"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4501"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6914"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3251"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5334"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7370"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4397"
					}
				]
			},
			{
				"metaboliteId": "0078d9e1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c2-0000-0000-0000-000000000000",
				"chemicalName": "alpha-hydroxyisocaproate",
				"plotName": null,
				"compoundId": "00005674-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00746",
				"inChiKey": "LVRFTAZAXQPQHI-YFKPBYRVBW",
				"kegg": "C03264",
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 300,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6467"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9611"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1041"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0276"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8687"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0390"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8666"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4198"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5800"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8247"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5521"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9164"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6894"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1831"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1893"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9643"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0514"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7201"
					}
				]
			},
			{
				"metaboliteId": "0078d9e2-0000-0000-0000-000000000000",
				"chemicalId": "000003a8-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxine (Vitamin B6)",
				"plotName": null,
				"compoundId": "00000260-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02075",
				"inChiKey": "LXNHXLLTXMVWPM-UHFFFAOYAQ",
				"kegg": "C00314",
				"mass": 169.073894,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4047,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3436"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3071"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8945"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9501"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5181"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7158"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5953"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6144"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1649"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5515"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5619"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7864"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0990"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3637"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4760"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5479"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5880"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5699"
					}
				]
			},
			{
				"metaboliteId": "0078d9e3-0000-0000-0000-000000000000",
				"chemicalId": "05f5e38c-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dipalmitoyl-GPG (16:0/16:0)",
				"plotName": null,
				"compoundId": "0000cd91-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10570",
				"inChiKey": "BIABMEZBCHDPBV-BEBVUIBBBA",
				"kegg": null,
				"mass": 722.509785,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylglycerol (PG)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2239,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9e4-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb5-0000-0000-0000-000000000000",
				"chemicalName": "linolenoyl-linolenoyl-glycerol (18:3/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6a8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07278",
				"inChiKey": null,
				"kegg": null,
				"mass": 612.475375,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2636,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4656"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0706"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1311"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2254"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9285"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8543"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1604"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4165"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7162"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5480"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4075"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5480"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1951"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4045"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2050"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1526"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6970"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6081"
					}
				]
			},
			{
				"metaboliteId": "0078d9e5-0000-0000-0000-000000000000",
				"chemicalId": "05f60bbc-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (16:1/18:2 [2], 16:0/18:3 [1])*",
				"plotName": null,
				"compoundId": "0000d6b6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 590.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2590,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5225"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5728"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4289"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8658"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4308"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9806"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4798"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2656"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6217"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7943"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2014"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3012"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1040"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5401"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0457"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6208"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6767"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2722"
					}
				]
			},
			{
				"metaboliteId": "0078d9e6-0000-0000-0000-000000000000",
				"chemicalId": "05f60bcf-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (12:0/18:1, 14:0/16:1, 16:0/14:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6d9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 538.459725,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2587,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8096"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9906"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5907"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4983"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0920"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0373"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1969"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0782"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3835"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6375"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9562"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3358"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4773"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2416"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1692"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.8545"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7453"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9792"
					}
				]
			},
			{
				"metaboliteId": "0078d9e7-0000-0000-0000-000000000000",
				"chemicalId": "000000c0-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylputrescine",
				"plotName": null,
				"compoundId": "00009278-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02064",
				"inChiKey": "KLZGKIDSEJWEDW-UHFFFAOYAE",
				"kegg": "C02714",
				"mass": 130.110613,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 465,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8849"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8402"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0902"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7277"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1955"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2833"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3483"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1842"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0127"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9680"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5019"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5019"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1718"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4099"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2370"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4215"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3416"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0120"
					}
				]
			},
			{
				"metaboliteId": "0078d9e8-0000-0000-0000-000000000000",
				"chemicalId": "05f61e32-0000-0000-0000-000000000000",
				"chemicalName": "glycerophosphoserine*",
				"plotName": null,
				"compoundId": "0000e03c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ZWZWYGMENQVNFU-AKGZTFGVBY",
				"kegg": null,
				"mass": 259.045703,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1983,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0087"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1220"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8463"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3156"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6413"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2076"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5666"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7413"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5699"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9472"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3660"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4990"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8283"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4039"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6993"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.0105"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8049"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6767"
					}
				]
			},
			{
				"metaboliteId": "0078d9e9-0000-0000-0000-000000000000",
				"chemicalId": "05f60b78-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-linolenoyl-digalactosylglycerol (18:2/18:3)*",
				"plotName": null,
				"compoundId": "0000d670-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GKSHYDZIFVNLSS-DKWOYBOIBV",
				"kegg": null,
				"mass": 938.596672,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2681,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3000"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9445"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6567"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0166"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7014"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0265"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4208"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5740"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4147"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4582"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2971"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6495"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9614"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0423"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4401"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4602"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1881"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3572"
					}
				]
			},
			{
				"metaboliteId": "0078d9ea-0000-0000-0000-000000000000",
				"chemicalId": "05f60b7e-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-2-linolenoyl-galactosylglycerol (18:2/18:3)*",
				"plotName": null,
				"compoundId": "0000d672-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 776.543823,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2680,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1053"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9576"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6306"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1908"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9721"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6064"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1666"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8978"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2034"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6518"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1703"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7283"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9442"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1746"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7014"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5870"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7283"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6061"
					}
				]
			},
			{
				"metaboliteId": "0078d9eb-0000-0000-0000-000000000000",
				"chemicalId": "05f60b8d-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-galactosylglycerol (16:0/18:2)*",
				"plotName": null,
				"compoundId": "0000d675-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 754.559499,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2671,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5110"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2026"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7573"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2435"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7352"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1389"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6271"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0050"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1288"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9485"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7479"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3634"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0124"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2155"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6157"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9211"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8077"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1382"
					}
				]
			},
			{
				"metaboliteId": "0078d9ec-0000-0000-0000-000000000000",
				"chemicalId": "05f60485-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-2-linoleoyl-GPA (18:1/18:2)*",
				"plotName": null,
				"compoundId": "0000e004-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 698.488656,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1994,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2720"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6444"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5304"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5401"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1354"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9431"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0088"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3545"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1850"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3572"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5995"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9976"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0747"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6361"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3658"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3199"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1212"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7651"
					}
				]
			},
			{
				"metaboliteId": "0078d9ed-0000-0000-0000-000000000000",
				"chemicalId": "00000070-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxy-3-methylglutarate",
				"plotName": null,
				"compoundId": "00000213-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00355",
				"inChiKey": "NPOAOTPXWNWTSH-NUQVWONBAH",
				"kegg": "C03761",
				"mass": 162.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Mevalonate Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2865,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0027"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0461"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2361"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5846"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5747"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3756"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3478"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8389"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9790"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9572"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1802"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6984"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2915"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8103"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6589"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2769"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1432"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6027"
					}
				]
			},
			{
				"metaboliteId": "0078d9ee-0000-0000-0000-000000000000",
				"chemicalId": "05f6056d-0000-0000-0000-000000000000",
				"chemicalName": "3-carboxyadipate",
				"plotName": null,
				"compoundId": "0000d5c3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LOGBRYZYTBQBTB-UHFFFAOYAW",
				"kegg": null,
				"mass": 190.047738,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1490,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6729"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1805"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4321"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0029"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0982"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9697"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3030"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6491"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4407"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0314"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2724"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5487"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2942"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1757"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0116"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1533"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5235"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7572"
					}
				]
			},
			{
				"metaboliteId": "0078d9ef-0000-0000-0000-000000000000",
				"chemicalId": "05f6049d-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinolenoyl-GPA (18:3/18:3)*",
				"plotName": null,
				"compoundId": "0000dff1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 692.441706,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidic acid (PA)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1998,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3394"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0275"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2356"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1784"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1123"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4130"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0473"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3331"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2982"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3594"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1172"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2023"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0543"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4848"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0397"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1811"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0634"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8932"
					}
				]
			},
			{
				"metaboliteId": "0078d9f0-0000-0000-0000-000000000000",
				"chemicalId": "05f5e916-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyglutarate",
				"plotName": null,
				"compoundId": "00009185-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB0059655",
				"inChiKey": "HWXBTNAVRSUOJR-UHFFFAOYAI",
				"kegg": "C02630",
				"mass": 148.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4043"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8001"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4495"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1441"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9564"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0552"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4754"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5160"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4466"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3962"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9498"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5096"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0095"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4835"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6953"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2803"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9578"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8893"
					}
				]
			},
			{
				"metaboliteId": "0078d9f1-0000-0000-0000-000000000000",
				"chemicalId": "05f5e3c3-0000-0000-0000-000000000000",
				"chemicalName": "maleate",
				"plotName": null,
				"compoundId": "000050c4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00176",
				"inChiKey": "VZCYOOQTPOCHFL-UPHRSURJBG",
				"kegg": "C01384",
				"mass": 116.010959,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1495,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2886"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4201"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9247"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1924"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0147"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3926"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4160"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2255"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1666"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9028"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1056"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3480"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1656"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0514"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2950"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5949"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7545"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9285"
					}
				]
			},
			{
				"metaboliteId": "0078d9f2-0000-0000-0000-000000000000",
				"chemicalId": "05f5e984-0000-0000-0000-000000000000",
				"chemicalName": "fructose 1,6-diphosphate/glucose 1,6-diphosphate/myo-inositol diphosphates",
				"plotName": null,
				"compoundId": "0000b730-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": "C00354",
				"mass": 339.99625,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1080,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5863"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0165"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6298"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5597"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3108"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7382"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3407"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2161"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0465"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5863"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0527"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8508"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1030"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5079"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3117"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9841"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0452"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5602"
					}
				]
			},
			{
				"metaboliteId": "0078d9f3-0000-0000-0000-000000000000",
				"chemicalId": "05f5eae0-0000-0000-0000-000000000000",
				"chemicalName": "sulfate*",
				"plotName": null,
				"compoundId": "0000b770-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01448",
				"inChiKey": "QAOWNCQODCNURD-UHFFFAOYAC",
				"kegg": "C00059",
				"mass": 97.967379,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5227,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9053"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2251"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3582"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7395"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4504"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8257"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2019"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3302"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9428"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2423"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4039"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0151"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9029"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8458"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0366"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3433"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1035"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2967"
					}
				]
			},
			{
				"metaboliteId": "0078d9f4-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2eb-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylphenylalanine",
				"plotName": null,
				"compoundId": "0000828e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00594",
				"inChiKey": "XHHOHZPNYFQJKL-QWRGUYRKBO",
				"kegg": null,
				"mass": 294.121573,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9f5-0000-0000-0000-000000000000",
				"chemicalId": "00000817-0000-0000-0000-000000000000",
				"chemicalName": "rutin (quercetin 3-rutinoside)",
				"plotName": null,
				"compoundId": "000047ce-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03249",
				"inChiKey": "IKGXIBQEEMLURG-NVPNHPEKBW",
				"kegg": "C05625",
				"mass": 610.15339,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4530,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9f6-0000-0000-0000-000000000000",
				"chemicalId": "05f5e406-0000-0000-0000-000000000000",
				"chemicalName": "phenyllactate (PLA)",
				"plotName": null,
				"compoundId": "00005672-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00779",
				"inChiKey": "VOXXWSYKYCBWHO-UHFFFAOYAI",
				"kegg": "C05607",
				"mass": 166.062994,
				"pathwayType": "ANIMAL",
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 141,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0379"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9470"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1378"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7520"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9347"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1927"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5318"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4942"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5051"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7862"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6136"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8580"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6434"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1011"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1534"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3803"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2841"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3198"
					}
				]
			},
			{
				"metaboliteId": "0078d9f7-0000-0000-0000-000000000000",
				"chemicalId": "0000015b-0000-0000-0000-000000000000",
				"chemicalName": "guanosine 5'- monophosphate (5'-GMP)",
				"plotName": null,
				"compoundId": "00000b21-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01397",
				"inChiKey": "RQFCJASXJCIDSX-UHFFFAOYAQ",
				"kegg": "C00144",
				"mass": 363.057999,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3798,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9f8-0000-0000-0000-000000000000",
				"chemicalId": "000004d1-0000-0000-0000-000000000000",
				"chemicalName": "glucose 1-phosphate",
				"plotName": null,
				"compoundId": "00003cc9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01586",
				"inChiKey": "HXXFSFRBOHSIMQ-VFUOTHLCBD",
				"kegg": "C00103",
				"mass": 260.029723,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1075,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5628"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5792"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6940"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5912"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1731"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6616"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0533"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3091"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5038"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9233"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5639"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3311"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4195"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6001"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0775"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5639"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1945"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5929"
					}
				]
			},
			{
				"metaboliteId": "0078d9f9-0000-0000-0000-000000000000",
				"chemicalId": "05f5e465-0000-0000-0000-000000000000",
				"chemicalName": "sinapate",
				"plotName": null,
				"compoundId": "0000529e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB32616",
				"inChiKey": "PCMORTLOPMLEFB-ONEGZZNKBS",
				"kegg": "C00482",
				"mass": 224.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4540,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8765"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8018"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7584"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0704"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3403"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2226"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3536"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0361"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9490"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0522"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2947"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2463"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0452"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4671"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1057"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4523"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3892"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6214"
					}
				]
			},
			{
				"metaboliteId": "0078d9fa-0000-0000-0000-000000000000",
				"chemicalId": "05f5eafa-0000-0000-0000-000000000000",
				"chemicalName": "homocitrate",
				"plotName": null,
				"compoundId": "00009ab1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03518",
				"inChiKey": "4000",
				"kegg": "C01251",
				"mass": 206.042653,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4439,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078d9fb-0000-0000-0000-000000000000",
				"chemicalId": "000001a8-0000-0000-0000-000000000000",
				"chemicalName": "palmitate (16:0)",
				"plotName": null,
				"compoundId": "00000538-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00220",
				"inChiKey": "IPCSVZSSVZVIGE-UHFFFAOYAJ",
				"kegg": "C00249",
				"mass": 256.24023,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1343,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3773"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2547"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2080"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4885"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1509"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8650"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0825"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7856"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0921"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3197"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1579"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7749"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1883"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1049"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6103"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5238"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1713"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2047"
					}
				]
			},
			{
				"metaboliteId": "0078d9fc-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7f1-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-GPI (18:1)",
				"plotName": null,
				"compoundId": "00008efa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 598.311814,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2427,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8138"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1122"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4826"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2906"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1439"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8262"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9596"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3433"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0154"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0040"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1644"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0032"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7903"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8262"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3124"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4517"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7251"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5937"
					}
				]
			},
			{
				"metaboliteId": "0078d9fd-0000-0000-0000-000000000000",
				"chemicalId": "05f5e777-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPI (16:0)",
				"plotName": null,
				"compoundId": "000089e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61695",
				"inChiKey": null,
				"kegg": null,
				"mass": 572.296164,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2422,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2294"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9306"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9833"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4932"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1277"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4619"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0615"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5920"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7075"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1194"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1497"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2602"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8297"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9442"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1120"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7558"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8959"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1991"
					}
				]
			},
			{
				"metaboliteId": "0078d9fe-0000-0000-0000-000000000000",
				"chemicalId": "0000014a-0000-0000-0000-000000000000",
				"chemicalName": "fumarate",
				"plotName": null,
				"compoundId": "0000066b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00134",
				"inChiKey": "VZCYOOQTPOCHFL-OWOJBTEDBF",
				"kegg": "C00122",
				"mass": 116.01096,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1293,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4858"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8119"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0123"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3639"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2075"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9043"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6754"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6550"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2604"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7796"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0481"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2924"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2187"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9021"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0283"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3195"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7500"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7833"
					}
				]
			},
			{
				"metaboliteId": "0078d9ff-0000-0000-0000-000000000000",
				"chemicalId": "05f5e706-0000-0000-0000-000000000000",
				"chemicalName": "ferulate",
				"plotName": null,
				"compoundId": "00008b2e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00954",
				"inChiKey": "KSEBMYQBYZTDHS-HWKANZROBE",
				"kegg": "C01494",
				"mass": 194.057909,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4408,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6494"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0662"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7708"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1073"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3647"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2212"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1809"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9312"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1438"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1546"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4852"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2786"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0317"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2656"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0298"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8022"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3501"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1538"
					}
				]
			},
			{
				"metaboliteId": "0078da00-0000-0000-0000-000000000000",
				"chemicalId": "05f5e74e-0000-0000-0000-000000000000",
				"chemicalName": "hexadecanedioate (C16-DC)",
				"plotName": null,
				"compoundId": "00008b5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00672",
				"inChiKey": "QQHJDPROMQRDLA-UHFFFAOYAF",
				"kegg": "C19615",
				"mass": 286.214409,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1519,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3004"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3375"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5855"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0706"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2495"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1247"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0448"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0605"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0944"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1839"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2050"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5533"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0261"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1964"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4419"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6366"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1076"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0740"
					}
				]
			},
			{
				"metaboliteId": "0078da01-0000-0000-0000-000000000000",
				"chemicalId": "05f5e705-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxy-3-methylvalerate",
				"plotName": null,
				"compoundId": "00008f8a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00317",
				"inChiKey": "RILPIWOPNGRASR-UHFFFAOYAI",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 337,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6591"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7242"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1351"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1063"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9663"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1282"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1676"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8743"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7679"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8486"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3953"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9113"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8667"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2007"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2963"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6507"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7650"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8789"
					}
				]
			},
			{
				"metaboliteId": "0078da02-0000-0000-0000-000000000000",
				"chemicalId": "05f5e79e-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 2'-monophosphate (2'-AMP)",
				"plotName": null,
				"compoundId": "00008fcf-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11617",
				"inChiKey": null,
				"kegg": "C00946",
				"mass": 347.063084,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1238"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3435"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7321"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2011"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3282"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1033"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4692"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5082"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4943"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3306"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9971"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9123"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4421"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4782"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4421"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1142"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3759"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9872"
					}
				]
			},
			{
				"metaboliteId": "0078da03-0000-0000-0000-000000000000",
				"chemicalId": "05f5e751-0000-0000-0000-000000000000",
				"chemicalName": "undecanedioate (C11-DC)",
				"plotName": null,
				"compoundId": "0000a59b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00888",
				"inChiKey": "LWBHHRRTOZQPDM-UHFFFAOYAZ",
				"kegg": null,
				"mass": 216.136159,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1507,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1153"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0550"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6207"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1515"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5605"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2826"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9079"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0760"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4598"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1115"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8480"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0321"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3280"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6582"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1579"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7949"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2542"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1407"
					}
				]
			},
			{
				"metaboliteId": "0078da04-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec81-0000-0000-0000-000000000000",
				"chemicalName": "(14 or 15)-methylpalmitate (a17:0 or i17:0)",
				"plotName": null,
				"compoundId": "00009770-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 270.25595,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2353"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9197"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9879"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2712"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1590"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6018"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0386"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8735"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4968"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9229"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5270"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1087"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2328"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7631"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5129"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9892"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2582"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1472"
					}
				]
			},
			{
				"metaboliteId": "0078da05-0000-0000-0000-000000000000",
				"chemicalId": "0000039b-0000-0000-0000-000000000000",
				"chemicalName": "dihydroorotate",
				"plotName": null,
				"compoundId": "00000259-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03349",
				"inChiKey": "UFIVEPVSAGBUSI-UHFFFAOYAI",
				"kegg": "C00337",
				"mass": 158.032758,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3824,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da06-0000-0000-0000-000000000000",
				"chemicalId": "0000004b-0000-0000-0000-000000000000",
				"chemicalName": "uridine-2',3'-cyclic monophosphate",
				"plotName": null,
				"compoundId": "00009111-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11640",
				"inChiKey": "HWDMHJDYMFRXOX-UHFFFAOYAA",
				"kegg": "C02355",
				"mass": 306.025306,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3836,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da07-0000-0000-0000-000000000000",
				"chemicalId": "000005d1-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl ethanolamide",
				"plotName": null,
				"compoundId": "00009515-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02100",
				"inChiKey": "HXYVTAGFYLMHSO-UHFFFAOYAE",
				"kegg": "C16512",
				"mass": 299.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1908,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1740"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1756"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1679"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4069"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7833"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6346"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2632"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3240"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0042"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5946"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0338"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2998"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3046"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7700"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7990"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7442"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2076"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6697"
					}
				]
			},
			{
				"metaboliteId": "0078da08-0000-0000-0000-000000000000",
				"chemicalId": "000000da-0000-0000-0000-000000000000",
				"chemicalName": "allantoic acid",
				"plotName": null,
				"compoundId": "0000829a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01209",
				"inChiKey": "NUCLJNSWZCHRKL-UHFFFAOYAF",
				"kegg": "C00499",
				"mass": 176.054556,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3756,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da09-0000-0000-0000-000000000000",
				"chemicalId": "000004e8-0000-0000-0000-000000000000",
				"chemicalName": "choline",
				"plotName": null,
				"compoundId": "00003c92-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00097",
				"inChiKey": "CRBHXDCYXIISFC-UHFFFAOYAW",
				"kegg": "C00114",
				"mass": 103.099714,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1974,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3644"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5376"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1782"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9869"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0757"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8452"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3281"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3035"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2574"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2242"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0424"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3647"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4386"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0657"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5327"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3381"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2946"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7010"
					}
				]
			},
			{
				"metaboliteId": "0078da0a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e422-0000-0000-0000-000000000000",
				"chemicalName": "acetylcarnitine (C2)",
				"plotName": null,
				"compoundId": "00007dc6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00201",
				"inChiKey": "RDHQFKQIGNGIED-MRVPVSSYBU",
				"kegg": "C02571",
				"mass": 203.115758,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Short Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1634,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7974"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8297"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7811"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3757"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3368"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5655"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6151"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5218"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1891"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1685"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0912"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0269"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2632"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0040"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9494"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5989"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5529"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3686"
					}
				]
			},
			{
				"metaboliteId": "0078da0b-0000-0000-0000-000000000000",
				"chemicalId": "000004d3-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylhistidine",
				"plotName": null,
				"compoundId": "00004745-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "PXVCMZCJAUJLJP-YUMQZZPRBD",
				"kegg": null,
				"mass": 284.112071,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 553,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da0c-0000-0000-0000-000000000000",
				"chemicalId": "05f5ec40-0000-0000-0000-000000000000",
				"chemicalName": "inositol pentakisphosphate",
				"plotName": null,
				"compoundId": "000095f4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03529",
				"inChiKey": null,
				"kegg": "C01284",
				"mass": 579.895055,
				"pathwayType": "ANIMAL",
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1967,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da0d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e960-0000-0000-0000-000000000000",
				"chemicalName": "secoisolariciresinol",
				"plotName": null,
				"compoundId": "000094d9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13692",
				"inChiKey": "PUETUDUXMCLALY-HOTGVXAUBU",
				"kegg": "C18167",
				"mass": 362.172939,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4535,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da0e-0000-0000-0000-000000000000",
				"chemicalId": "0000012d-0000-0000-0000-000000000000",
				"chemicalName": "dehydroascorbate",
				"plotName": null,
				"compoundId": "0000067b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01264",
				"inChiKey": "SBJKKFFYIZUCET-UHFFFAOYAZ",
				"kegg": "C05422",
				"mass": 174.01644,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3959,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da0f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e50a-0000-0000-0000-000000000000",
				"chemicalName": "indoleacetate",
				"plotName": null,
				"compoundId": "00006b79-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00197",
				"inChiKey": "SEOVTRFCIGRIMH-UHFFFAOYAT",
				"kegg": "C00954",
				"mass": 175.063329,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 267,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1476"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0206"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4421"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0214"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1014"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0784"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4156"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7016"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1225"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3732"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3583"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0200"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4935"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3220"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7086"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1131"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8042"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5112"
					}
				]
			},
			{
				"metaboliteId": "0078da10-0000-0000-0000-000000000000",
				"chemicalId": "05f5e296-0000-0000-0000-000000000000",
				"chemicalName": "ribitol",
				"plotName": null,
				"compoundId": "00003d9c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00508",
				"inChiKey": "HEBKCHPVOIAQTA-ZXFHETKHBG",
				"kegg": "C00474",
				"mass": 152.068473,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1112,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6052"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2046"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0663"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3798"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5997"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3624"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3960"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6698"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8823"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4194"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2682"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1726"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0769"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0078"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5180"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1530"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3269"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5107"
					}
				]
			},
			{
				"metaboliteId": "0078da11-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4a7-0000-0000-0000-000000000000",
				"chemicalName": "apigenin",
				"plotName": null,
				"compoundId": "000052c5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02124",
				"inChiKey": "KZNIFHPLKGYRTM-UHFFFAOYAX",
				"kegg": "C01477",
				"mass": 270.052823,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4256,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da12-0000-0000-0000-000000000000",
				"chemicalId": "00000233-0000-0000-0000-000000000000",
				"chemicalName": "glutamine",
				"plotName": null,
				"compoundId": "00000035-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00641",
				"inChiKey": "ZDXPYRJPNDTMRX-VKHMYHEABV",
				"kegg": "C00064",
				"mass": 146.069142,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 43,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3910"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7934"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0373"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1441"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5160"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3712"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4514"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5086"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5128"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0995"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5171"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7078"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5172"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7664"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8797"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6104"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2156"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2870"
					}
				]
			},
			{
				"metaboliteId": "0078da13-0000-0000-0000-000000000000",
				"chemicalId": "00000235-0000-0000-0000-000000000000",
				"chemicalName": "tryptophan",
				"plotName": null,
				"compoundId": "00000036-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00929",
				"inChiKey": "QIVBCDIJIAJPQS-VIFPVBQEBP",
				"kegg": "C00078",
				"mass": 204.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 229,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6575"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1785"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6361"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9393"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0380"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6857"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0430"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6658"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0993"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2720"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0613"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7484"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1013"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0479"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5085"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4903"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8173"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9100"
					}
				]
			},
			{
				"metaboliteId": "0078da14-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8d2-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxypyridine sulfate",
				"plotName": null,
				"compoundId": "0000bd40-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 174.993928,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da15-0000-0000-0000-000000000000",
				"chemicalId": "00000166-0000-0000-0000-000000000000",
				"chemicalName": "hypotaurine",
				"plotName": null,
				"compoundId": "0000024e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00965",
				"inChiKey": "VVIUBCNYACGLLV-UHFFFAOYAM",
				"kegg": "C00519",
				"mass": 109.019751,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 405,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da16-0000-0000-0000-000000000000",
				"chemicalId": "00000169-0000-0000-0000-000000000000",
				"chemicalName": "inosine",
				"plotName": null,
				"compoundId": "00000463-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00195",
				"inChiKey": "UGQMRVRMYYASKQ-DGPXGRDGBU",
				"kegg": "C00294",
				"mass": 268.08077,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3743,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4619"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0525"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3149"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1740"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3484"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3610"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2662"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5392"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7213"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5050"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2167"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4962"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3980"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3496"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6308"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2292"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0588"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8466"
					}
				]
			},
			{
				"metaboliteId": "0078da17-0000-0000-0000-000000000000",
				"chemicalId": "05f5e492-0000-0000-0000-000000000000",
				"chemicalName": "caffeate",
				"plotName": null,
				"compoundId": "000052b9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01964",
				"inChiKey": "QAIPRVGONGVQAS-DUXPYHPUBE",
				"kegg": "C01197",
				"mass": 180.042259,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4342,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0436"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1417"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2457"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8655"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4250"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6053"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0635"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4190"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9154"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1969"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0312"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4997"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6824"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0252"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1295"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0001"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6015"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2826"
					}
				]
			},
			{
				"metaboliteId": "0078da18-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5df-0000-0000-0000-000000000000",
				"chemicalName": "octanoylcarnitine (C8)",
				"plotName": null,
				"compoundId": "00008490-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00791",
				"inChiKey": "CXTATJFJDMJMIY-UHFFFAOYAP",
				"kegg": "C02838",
				"mass": 287.209658,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1641,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da19-0000-0000-0000-000000000000",
				"chemicalId": "05f5f7e8-0000-0000-0000-000000000000",
				"chemicalName": "methyl glucopyranoside (alpha + beta)",
				"plotName": null,
				"compoundId": "0000b440-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 194.079038,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4577,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5883"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9642"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2737"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3512"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2213"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8701"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1848"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8410"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8226"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2954"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3120"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1899"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8849"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6023"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4400"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5745"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4660"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6197"
					}
				]
			},
			{
				"metaboliteId": "0078da1a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5b8-0000-0000-0000-000000000000",
				"chemicalName": "1-methyl-4-imidazoleacetate",
				"plotName": null,
				"compoundId": "00007e5e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02820",
				"inChiKey": "ZHCKPJGJQOPTLB-UHFFFAOYAW",
				"kegg": "C05828",
				"mass": 140.058578,
				"pathwayType": "ANIMAL",
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 86,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8759"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0826"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6304"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3809"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0046"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2060"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1460"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4078"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2358"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4285"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9206"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5174"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9686"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1422"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6574"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9246"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4078"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7514"
					}
				]
			},
			{
				"metaboliteId": "0078da1b-0000-0000-0000-000000000000",
				"chemicalId": "000000ac-0000-0000-0000-000000000000",
				"chemicalName": "guanine",
				"plotName": null,
				"compoundId": "00007e60-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00132",
				"inChiKey": "UYTPUPDQBNUYGX-UHFFFAOYAE",
				"kegg": "C00242",
				"mass": 151.04941,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3804,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0899"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1497"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0889"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6407"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9417"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0224"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3220"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5328"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5870"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2342"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0914"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2070"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0444"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5172"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5280"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8379"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0616"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6496"
					}
				]
			},
			{
				"metaboliteId": "0078da1c-0000-0000-0000-000000000000",
				"chemicalId": "000000e9-0000-0000-0000-000000000000",
				"chemicalName": "ascorbate (Vitamin C)",
				"plotName": null,
				"compoundId": "00007e62-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00044",
				"inChiKey": "CIWBSHSKHKDKBQ-JLAZNSOCBT",
				"kegg": "C00072",
				"mass": 176.03209,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3956,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1002"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4386"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9427"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5962"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0261"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7892"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3704"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1615"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3750"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8555"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3704"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2249"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0330"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6253"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9164"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1395"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5971"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2446"
					}
				]
			},
			{
				"metaboliteId": "0078da1d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e61e-0000-0000-0000-000000000000",
				"chemicalName": "nicotinamide riboside",
				"plotName": null,
				"compoundId": "000080f5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00855",
				"inChiKey": null,
				"kegg": "C03150",
				"mass": 254.090272,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3919,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da1e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e60e-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglycine",
				"plotName": null,
				"compoundId": "0000849d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11667",
				"inChiKey": "ACIJGUBIMXQCMF-BYPYZUCNBW",
				"kegg": null,
				"mass": 204.074622,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da1f-0000-0000-0000-000000000000",
				"chemicalId": "0000010b-0000-0000-0000-000000000000",
				"chemicalName": "choline phosphate",
				"plotName": null,
				"compoundId": "0000865c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01565",
				"inChiKey": "YHHSONZFOIEMCP-UHFFFAOYAD",
				"kegg": "C00588",
				"mass": 183.066044,
				"pathwayType": "ANIMAL",
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1975,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8934"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1332"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6289"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4857"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4188"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2100"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1212"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4840"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6546"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0816"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5971"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1009"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5211"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6983"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5275"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2772"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4523"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9354"
					}
				]
			},
			{
				"metaboliteId": "0078da20-0000-0000-0000-000000000000",
				"chemicalId": "05f5e683-0000-0000-0000-000000000000",
				"chemicalName": "beta-guanidinopropanoate",
				"plotName": null,
				"compoundId": "0000891d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13222",
				"inChiKey": "KMXXSJLYVJEBHI-UHFFFAOYAJ",
				"kegg": "C03065",
				"mass": 131.069477,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4656"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5197"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5070"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5649"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7184"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3448"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6349"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2519"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5972"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8965"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7797"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2173"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2369"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2892"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0888"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9548"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3389"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3747"
					}
				]
			},
			{
				"metaboliteId": "0078da21-0000-0000-0000-000000000000",
				"chemicalId": "0000014b-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglutamate",
				"plotName": null,
				"compoundId": "00008f82-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11737",
				"inChiKey": "OWQDWQKWSLFFFR-WDSKDSINBR",
				"kegg": "C05282",
				"mass": 276.095753,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1203"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9413"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1481"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4717"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4925"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0632"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2499"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8161"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6987"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2032"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2804"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0655"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0314"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1466"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2093"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0010"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3186"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8929"
					}
				]
			},
			{
				"metaboliteId": "0078da22-0000-0000-0000-000000000000",
				"chemicalId": "05f5e812-0000-0000-0000-000000000000",
				"chemicalName": "dimethylarginine (SDMA + ADMA)",
				"plotName": null,
				"compoundId": "00008fc8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01539",
				"inChiKey": "YDGMGEXADBMOMJ-LURJTMIEBT",
				"kegg": "C03626",
				"mass": 202.142976,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0821"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8386"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3139"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1272"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4231"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1518"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6010"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3850"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2184"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0551"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4868"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6095"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7377"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6100"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5745"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3099"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7081"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5180"
					}
				]
			},
			{
				"metaboliteId": "0078da23-0000-0000-0000-000000000000",
				"chemicalId": "05f5e8a4-0000-0000-0000-000000000000",
				"chemicalName": "N-methylproline",
				"plotName": null,
				"compoundId": "00009237-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CWLQUGTUXBXTLF-UHFFFAOYAJ",
				"kegg": null,
				"mass": 129.078979,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 446,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2406"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5124"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0852"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9196"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0346"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3039"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5809"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2387"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7404"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7171"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1384"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5088"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7566"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3484"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0259"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8801"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4031"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9116"
					}
				]
			},
			{
				"metaboliteId": "0078da24-0000-0000-0000-000000000000",
				"chemicalId": "05f5f056-0000-0000-0000-000000000000",
				"chemicalName": "(R)-3-hydroxybutyrylcarnitine",
				"plotName": null,
				"compoundId": "0000a900-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13127",
				"inChiKey": null,
				"kegg": null,
				"mass": 247.141973,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1695,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da25-0000-0000-0000-000000000000",
				"chemicalId": "05f5f1bc-0000-0000-0000-000000000000",
				"chemicalName": "dimethyl sulfone",
				"plotName": null,
				"compoundId": "0000a9a0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04983",
				"inChiKey": "HHVIBTZHLRERCL-UHFFFAOYAG",
				"kegg": "C11142",
				"mass": 94.00885,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5294,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6196"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0898"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2591"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8985"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0694"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3097"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1020"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7991"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0499"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7666"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5947"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6359"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6620"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5348"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4590"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5676"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4536"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9327"
					}
				]
			},
			{
				"metaboliteId": "0078da26-0000-0000-0000-000000000000",
				"chemicalId": "00000602-0000-0000-0000-000000000000",
				"chemicalName": "stearoyl sphingomyelin (d18:1/18:0)",
				"plotName": null,
				"compoundId": "00004c2f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01348",
				"inChiKey": "LKQLRGMMMAHREN-YJFXYUILBI",
				"kegg": "C00550",
				"mass": 730.598875,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2793,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da27-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9b3-0000-0000-0000-000000000000",
				"chemicalName": "4-cholesten-3-one",
				"plotName": null,
				"compoundId": "000094ed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00921",
				"inChiKey": "NYOXRYYXRWJDKP-UHFFFAOYAV",
				"kegg": "C00599",
				"mass": 384.339216,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2904,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da28-0000-0000-0000-000000000000",
				"chemicalId": "05f5f862-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d18:1/24:1, d18:2/24:0)*",
				"plotName": null,
				"compoundId": "0000b831-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12107",
				"inChiKey": "WKZHECFHXLTOLJ-QYKFWSDSBN",
				"kegg": null,
				"mass": 812.677125,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2817,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da29-0000-0000-0000-000000000000",
				"chemicalId": "05f603f9-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoleoylglycerol (16:1)*",
				"plotName": null,
				"compoundId": "0000ccd0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11565",
				"inChiKey": null,
				"kegg": null,
				"mass": 328.261353,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2570,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da2a-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4fc-0000-0000-0000-000000000000",
				"chemicalName": "ergosterol",
				"plotName": null,
				"compoundId": "00006ba1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00878",
				"inChiKey": "DNVPQKQSNYMLRS-APGDWVJJBI",
				"kegg": "C01694",
				"mass": 396.339216,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2912,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da2b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5f5-0000-0000-0000-000000000000",
				"chemicalName": "campesterol",
				"plotName": null,
				"compoundId": "000084cd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02869",
				"inChiKey": "SGNBVLSWZMBQTH-PODYLUTMBW",
				"kegg": "C01789",
				"mass": 400.370516,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2908,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3304"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1258"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0538"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4616"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9240"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4616"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4114"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2991"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3858"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9013"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3176"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7485"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2106"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3549"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6957"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0499"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4856"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6674"
					}
				]
			},
			{
				"metaboliteId": "0078da2c-0000-0000-0000-000000000000",
				"chemicalId": "00000451-0000-0000-0000-000000000000",
				"chemicalName": "alpha-tocopherol",
				"plotName": null,
				"compoundId": "00000619-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01893",
				"inChiKey": "GVJHHUAWPYXKBD-IEOSBIPEBS",
				"kegg": "C02477",
				"mass": 430.381081,
				"pathwayType": "ANIMAL",
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3968,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6788"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9492"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9960"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2867"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6482"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7644"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0003"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5951"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2772"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1314"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0840"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3414"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8108"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9550"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2332"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3517"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0154"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6615"
					}
				]
			},
			{
				"metaboliteId": "0078da2d-0000-0000-0000-000000000000",
				"chemicalId": "05f5fb46-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl ethanolamide",
				"plotName": null,
				"compoundId": "0000cd80-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12252",
				"inChiKey": "KQXDGUVSAAQARU-HZJYTTRNBB",
				"kegg": null,
				"mass": 323.282429,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1927,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5095"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4193"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4278"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2961"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6559"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3611"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3567"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3499"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0778"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3201"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1967"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4707"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3131"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3148"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6378"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2981"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8912"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0145"
					}
				]
			},
			{
				"metaboliteId": "0078da2e-0000-0000-0000-000000000000",
				"chemicalId": "05f60581-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoleoyl-2-linolenoyl-GPC (16:1/18:3)*",
				"plotName": null,
				"compoundId": "0000cfbc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08008",
				"inChiKey": null,
				"kegg": null,
				"mass": 753.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2050,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da2f-0000-0000-0000-000000000000",
				"chemicalId": "000001f5-0000-0000-0000-000000000000",
				"chemicalName": "salicylate",
				"plotName": null,
				"compoundId": "000005eb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01895",
				"inChiKey": "4000",
				"kegg": "C00805",
				"mass": 138.0315,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5146,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1785"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1212"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0298"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9695"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6907"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2384"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3409"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2352"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2961"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1092"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1579"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6867"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7692"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4496"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9060"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6847"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1137"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6336"
					}
				]
			},
			{
				"metaboliteId": "0078da30-0000-0000-0000-000000000000",
				"chemicalId": "05f6047a-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPA (18:2)*",
				"plotName": null,
				"compoundId": "0000cdd2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07856",
				"inChiKey": null,
				"kegg": null,
				"mass": 434.24334,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2306,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0971"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3667"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5046"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5407"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8240"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5517"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3607"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1296"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0073"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1108"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1137"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3227"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8167"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4805"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0641"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7476"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6294"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3758"
					}
				]
			},
			{
				"metaboliteId": "0078da31-0000-0000-0000-000000000000",
				"chemicalId": "05f5e49c-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoylglycerol (18:1)",
				"plotName": null,
				"compoundId": "000052c0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11567",
				"inChiKey": "RZRNAYUHWVFMIP-KTKRTIGZBM",
				"kegg": null,
				"mass": 356.29266,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2557,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0798"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0670"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0501"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3326"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3452"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0980"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0355"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9279"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8997"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1592"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4952"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3012"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2777"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1706"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6543"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6989"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8090"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3199"
					}
				]
			},
			{
				"metaboliteId": "0078da32-0000-0000-0000-000000000000",
				"chemicalId": "05f5ef39-0000-0000-0000-000000000000",
				"chemicalName": "valylglycine",
				"plotName": null,
				"compoundId": "00009e1b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29127",
				"inChiKey": "IOUPEELXVYPCPG-UHFFFAOYAB",
				"kegg": null,
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 939,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da33-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4db-0000-0000-0000-000000000000",
				"chemicalName": "2-linoleoylglycerol (18:2)",
				"plotName": null,
				"compoundId": "00007efa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11538",
				"inChiKey": "IEPGNWMPIFDNSD-HZJYTTRNBY",
				"kegg": null,
				"mass": 354.27701,
				"pathwayType": "ANIMAL",
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2573,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3663"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0063"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0706"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6961"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2782"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8868"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8620"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6500"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8234"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8288"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4162"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8423"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8423"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8328"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3241"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3642"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2497"
					}
				]
			},
			{
				"metaboliteId": "0078da34-0000-0000-0000-000000000000",
				"chemicalId": "05f604e1-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinoleoyl-GPI (18:2/18:2)*",
				"plotName": null,
				"compoundId": "0000cdfe-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB09850",
				"inChiKey": null,
				"kegg": null,
				"mass": 858.525829,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2270,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6302"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6307"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0189"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7758"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5652"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6302"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1922"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0549"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4423"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6273"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5625"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3357"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2199"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7843"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2441"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2678"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6018"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1125"
					}
				]
			},
			{
				"metaboliteId": "0078da35-0000-0000-0000-000000000000",
				"chemicalId": "05f60b9c-0000-0000-0000-000000000000",
				"chemicalName": "coumaroylquinate (3)",
				"plotName": null,
				"compoundId": "0000d690-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 338.100168,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4372,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da36-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb7-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (14:0/18:1, 16:0/16:1) [2]*",
				"plotName": null,
				"compoundId": "0000d6aa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 566.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2589,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da37-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb8-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-arachidonoyl-glycerol (18:1/20:4) [1]*",
				"plotName": null,
				"compoundId": "0000d6b0-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07228",
				"inChiKey": null,
				"kegg": null,
				"mass": 642.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2639,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da38-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb9-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl-arachidonoyl-glycerol (18:1/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000d6b1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07228",
				"inChiKey": null,
				"kegg": null,
				"mass": 642.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2640,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da39-0000-0000-0000-000000000000",
				"chemicalId": "05f60b7a-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linoleoyl-digalactosylglycerol (16:0/18:2)*",
				"plotName": null,
				"compoundId": "0000d667-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QZXMUPATKGLZAP-XZBBILGWBU",
				"kegg": null,
				"mass": 916.612322,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2670,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4657"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9802"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8206"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5327"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4027"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3723"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8686"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4500"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6919"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1645"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2380"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0083"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4544"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0652"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6273"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9898"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3895"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3523"
					}
				]
			},
			{
				"metaboliteId": "0078da3a-0000-0000-0000-000000000000",
				"chemicalId": "05f61e1b-0000-0000-0000-000000000000",
				"chemicalName": "sphingadienine",
				"plotName": null,
				"compoundId": "0000e052-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "KWDXKYNWAKMLKK-YQMRQDNGBF",
				"kegg": null,
				"mass": 297.266779,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2698,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3111"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9691"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7014"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4447"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4576"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6796"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3577"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9847"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8704"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2919"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2115"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0237"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5405"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6364"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0307"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9315"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2505"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6920"
					}
				]
			},
			{
				"metaboliteId": "0078da3b-0000-0000-0000-000000000000",
				"chemicalId": "05f5ea09-0000-0000-0000-000000000000",
				"chemicalName": "oleanolate",
				"plotName": null,
				"compoundId": "0000958e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02364",
				"inChiKey": "MIJYXULNPSFWEK-GTOFXWBIBS",
				"kegg": "C17148",
				"mass": 456.360345,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4481,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2901"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3039"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2118"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0774"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0412"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0143"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2138"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6120"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1156"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6705"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5861"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5482"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1225"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6959"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6959"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3960"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3272"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3563"
					}
				]
			},
			{
				"metaboliteId": "0078da3c-0000-0000-0000-000000000000",
				"chemicalId": "05f60517-0000-0000-0000-000000000000",
				"chemicalName": "isocitric lactone",
				"plotName": null,
				"compoundId": "0000d5c4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "UKZDIMBDWHZXOK-UHFFFAOYAE",
				"kegg": null,
				"mass": 174.016438,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1288,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3806"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1372"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0043"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9295"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8186"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0395"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6338"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6800"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7525"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4531"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0480"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0858"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0058"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0874"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0804"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1593"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7758"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7018"
					}
				]
			},
			{
				"metaboliteId": "0078da3d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f99a-0000-0000-0000-000000000000",
				"chemicalName": "lignoceroyl sphingomyelin (d18:1/24:0)",
				"plotName": null,
				"compoundId": "0000dff2-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "QEDPUVGSSDPBMD-XTAIVQBEBF",
				"kegg": null,
				"mass": 814.692775,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2796,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da3e-0000-0000-0000-000000000000",
				"chemicalId": "05f60ba4-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-oleoyl-glycerol (16:0/18:1) [1]*",
				"plotName": null,
				"compoundId": "0000d69f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07102",
				"inChiKey": null,
				"kegg": "C13861",
				"mass": 594.522325,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2605,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da3f-0000-0000-0000-000000000000",
				"chemicalId": "000003ec-0000-0000-0000-000000000000",
				"chemicalName": "xanthine",
				"plotName": null,
				"compoundId": "00000c4b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00292",
				"inChiKey": "LRFVTYWOQMYALW-UHFFFAOYAT",
				"kegg": "C00385",
				"mass": 152.033426,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5000"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6117"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1198"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2689"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3415"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8026"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9403"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2071"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5067"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5910"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3963"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5323"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9822"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3952"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0112"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0219"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4009"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7867"
					}
				]
			},
			{
				"metaboliteId": "0078da40-0000-0000-0000-000000000000",
				"chemicalId": "05f5e22a-0000-0000-0000-000000000000",
				"chemicalName": "UDP-galactose",
				"plotName": null,
				"compoundId": "00003df4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00302",
				"inChiKey": "HSCJRCZFDFQWRP-ABVWGUQPBC",
				"kegg": "C00052",
				"mass": 566.05502,
				"pathwayType": "ANIMAL",
				"subPathway": "Nucleotide Sugar",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da41-0000-0000-0000-000000000000",
				"chemicalId": "05f5ebcc-0000-0000-0000-000000000000",
				"chemicalName": "isomaltose",
				"plotName": null,
				"compoundId": "00009b61-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02923",
				"inChiKey": "DLRVVLDZNNYCBX-RTPHMHGBBU",
				"kegg": "C00252",
				"mass": 342.116212,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycogen Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1147,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da42-0000-0000-0000-000000000000",
				"chemicalId": "000004bc-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyl-glucosamine 1-phosphate",
				"plotName": null,
				"compoundId": "00003d7d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01367",
				"inChiKey": "FZLJPEPAYPUMMR-FMDGEEDCBJ",
				"kegg": "C04256",
				"mass": 301.056272,
				"pathwayType": "ANIMAL",
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1257,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da43-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6a9-0000-0000-0000-000000000000",
				"chemicalName": "adenosine 3'-monophosphate (3'-AMP)",
				"plotName": null,
				"compoundId": "00008946-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03540",
				"inChiKey": "LNQVTSROQXJCDD-RWLQFSFNBK",
				"kegg": "C01367",
				"mass": 347.063084,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3762,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da44-0000-0000-0000-000000000000",
				"chemicalId": "0000043f-0000-0000-0000-000000000000",
				"chemicalName": "erucate (22:1n9)",
				"plotName": null,
				"compoundId": "00000610-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02068",
				"inChiKey": "DPUOLQHDNGRHBS-UHFFFAOYAW",
				"kegg": "C08316",
				"mass": 338.31848,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1374,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8319"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6079"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4605"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2750"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1912"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0531"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3143"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8754"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8793"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3840"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0232"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6467"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2843"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2843"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5532"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7422"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9201"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3866"
					}
				]
			},
			{
				"metaboliteId": "0078da45-0000-0000-0000-000000000000",
				"chemicalId": "00000470-0000-0000-0000-000000000000",
				"chemicalName": "valerate (5:0)",
				"plotName": null,
				"compoundId": "000082a3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00892",
				"inChiKey": "NQPDZGIKBAWPEJ-UHFFFAOYAU",
				"kegg": "C00803",
				"mass": 102.06808,
				"pathwayType": "ANIMAL",
				"subPathway": "Short Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da46-0000-0000-0000-000000000000",
				"chemicalId": "0000043b-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylmethionine",
				"plotName": null,
				"compoundId": "00000635-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11745",
				"inChiKey": "XUYPXLNMDZIRQH-LURJTMIEBN",
				"kegg": "C02712",
				"mass": 191.061616,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 372,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5139"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9428"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1841"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9766"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9848"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3634"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1196"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5152"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0437"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1688"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0421"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0184"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3109"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7336"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5459"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0666"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7689"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8734"
					}
				]
			},
			{
				"metaboliteId": "0078da47-0000-0000-0000-000000000000",
				"chemicalId": "0000074d-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyhippurate (salicylurate)",
				"plotName": null,
				"compoundId": "00004769-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00840",
				"inChiKey": "ONJSZLXSECQROL-UHFFFAOYAK",
				"kegg": "C07588",
				"mass": 195.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4059,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da48-0000-0000-0000-000000000000",
				"chemicalId": "05f5e6b6-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPG (18:0)",
				"plotName": null,
				"compoundId": "00008685-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HFJVKBVEKQHVTO-OZAIVSQSBD",
				"kegg": null,
				"mass": 512.31142,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2417,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da49-0000-0000-0000-000000000000",
				"chemicalId": "000000c5-0000-0000-0000-000000000000",
				"chemicalName": "S-adenosylhomocysteine (SAH)",
				"plotName": null,
				"compoundId": "0000a58e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00939",
				"inChiKey": null,
				"kegg": "C00021",
				"mass": 384.121588,
				"pathwayType": "ANIMAL",
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 382,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da4a-0000-0000-0000-000000000000",
				"chemicalId": "0000033c-0000-0000-0000-000000000000",
				"chemicalName": "arabinose",
				"plotName": null,
				"compoundId": "0000023f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00646",
				"inChiKey": "PYMYPHUHKUWMLA-WDCZJNDABW",
				"kegg": "C00216",
				"mass": 150.052825,
				"pathwayType": "ANIMAL",
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da4b-0000-0000-0000-000000000000",
				"chemicalId": "00000339-0000-0000-0000-000000000000",
				"chemicalName": "uracil",
				"plotName": null,
				"compoundId": "0000025d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00300",
				"inChiKey": "ISAKRJDGNUQOIC-UHFFFAOYAV",
				"kegg": "C00106",
				"mass": 112.027278,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3838,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9007"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7627"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0641"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8182"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6734"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1471"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5973"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2876"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1794"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3436"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4934"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1447"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6932"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1796"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7352"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2814"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0652"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2535"
					}
				]
			},
			{
				"metaboliteId": "0078da4c-0000-0000-0000-000000000000",
				"chemicalId": "0000016a-0000-0000-0000-000000000000",
				"chemicalName": "inosine 5'-monophosphate (IMP)",
				"plotName": null,
				"compoundId": "00000855-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00175",
				"inChiKey": "GRSZFWQUAKGDAV-RWLQFSFNBK",
				"kegg": "C00130",
				"mass": 348.047104,
				"pathwayType": "ANIMAL",
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3742,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da4d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e2ba-0000-0000-0000-000000000000",
				"chemicalName": "quinate",
				"plotName": null,
				"compoundId": "0000479f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03072",
				"inChiKey": "AAWZDTNXLSGCEK-WYWMIBKRBU",
				"kegg": "C00296",
				"mass": 192.063388,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4412,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0576"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3700"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3150"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4323"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3437"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9209"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0002"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1102"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2233"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0249"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0414"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0303"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5138"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5086"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4209"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7511"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6781"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0819"
					}
				]
			},
			{
				"metaboliteId": "0078da4e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4fe-0000-0000-0000-000000000000",
				"chemicalName": "threonate",
				"plotName": null,
				"compoundId": "00006c5a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00943",
				"inChiKey": "JPIJQSOTBSSVTP-GBXIJSLDBD",
				"kegg": "C01620",
				"mass": 136.037173,
				"pathwayType": "ANIMAL",
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3961,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0348"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1107"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6506"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9617"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7972"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2492"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0876"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5769"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8466"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8337"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-3.0572"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6683"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1689"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5783"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8042"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5561"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5993"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6160"
					}
				]
			},
			{
				"metaboliteId": "0078da4f-0000-0000-0000-000000000000",
				"chemicalId": "0000037c-0000-0000-0000-000000000000",
				"chemicalName": "nonadecanoate (19:0)",
				"plotName": null,
				"compoundId": "0000054c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00772",
				"inChiKey": "ISYWECDDZWTKFF-UHFFFAOYAR",
				"kegg": "C16535",
				"mass": 298.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1346,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4170"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0336"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4199"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3727"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0311"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8150"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8313"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6055"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8725"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4376"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1992"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1501"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0567"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3260"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1572"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3186"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3103"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5812"
					}
				]
			},
			{
				"metaboliteId": "0078da50-0000-0000-0000-000000000000",
				"chemicalId": "0000005a-0000-0000-0000-000000000000",
				"chemicalName": "2-methylcitrate",
				"plotName": null,
				"compoundId": "0000926b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00379",
				"inChiKey": "YNOXCRMFGMSKIJ-DFZHHIFOAQ",
				"kegg": "C02225",
				"mass": 206.042655,
				"pathwayType": "ANIMAL",
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1302,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da51-0000-0000-0000-000000000000",
				"chemicalId": "05f5e550-0000-0000-0000-000000000000",
				"chemicalName": "N-acetyltyrosine",
				"plotName": null,
				"compoundId": "00007e86-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00866",
				"inChiKey": "CAHKINHBCWCHCF-JTQLQIEIBT",
				"kegg": null,
				"mass": 223.084458,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 153,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da52-0000-0000-0000-000000000000",
				"chemicalId": "05f5e59e-0000-0000-0000-000000000000",
				"chemicalName": "docosadienoate (22:2n6)",
				"plotName": null,
				"compoundId": "00007e9f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61714",
				"inChiKey": "HVGRZDASOHMCSK-AVQMFFATBO",
				"kegg": "C16533",
				"mass": 336.302831,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1404,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da53-0000-0000-0000-000000000000",
				"chemicalId": "000003a4-0000-0000-0000-000000000000",
				"chemicalName": "caprylate (8:0)",
				"plotName": null,
				"compoundId": "00007eec-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00482",
				"inChiKey": "WWZKQHOCKIZLMA-UHFFFAOYAH",
				"kegg": "C06423",
				"mass": 144.11503,
				"pathwayType": "ANIMAL",
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1330,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1243"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6151"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3425"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1568"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0142"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3562"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7543"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2069"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8231"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8269"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6047"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2419"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0040"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7625"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8505"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3212"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5284"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8618"
					}
				]
			},
			{
				"metaboliteId": "0078da54-0000-0000-0000-000000000000",
				"chemicalId": "05f5e390-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-GPI (18:0)",
				"plotName": null,
				"compoundId": "00004b7c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61696",
				"inChiKey": "LXUGKKVCSTYZFK-DYLKWFMLBW",
				"kegg": null,
				"mass": 600.327464,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2425,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7609"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1521"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6633"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0455"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0707"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4456"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4792"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2530"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9619"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8060"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6309"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4140"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6958"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4140"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3285"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5332"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9043"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3555"
					}
				]
			},
			{
				"metaboliteId": "0078da55-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4bc-0000-0000-0000-000000000000",
				"chemicalName": "8-hydroxyoctanoate",
				"plotName": null,
				"compoundId": "000052f7-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61914",
				"inChiKey": "KDMSVYIHKLZKET-UHFFFAOYAJ",
				"kegg": null,
				"mass": 160.109944,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4143"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2789"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0104"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5141"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8638"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0096"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1801"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6819"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8056"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4462"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0239"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4748"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0777"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0777"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1873"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7604"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7345"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2262"
					}
				]
			},
			{
				"metaboliteId": "0078da56-0000-0000-0000-000000000000",
				"chemicalId": "05f5f182-0000-0000-0000-000000000000",
				"chemicalName": "traumatic acid",
				"plotName": null,
				"compoundId": "0000a7d4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00933",
				"inChiKey": "MAZWDMBCPDUFDJ-VQHVLOKHBT",
				"kegg": "C16308",
				"mass": 228.136159,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4568,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da57-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7dc-0000-0000-0000-000000000000",
				"chemicalName": "4-ethylphenylsulfate",
				"plotName": null,
				"compoundId": "00008d03-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB62551",
				"inChiKey": null,
				"kegg": "C13637",
				"mass": 202.029979,
				"pathwayType": "ANIMAL",
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4108,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da58-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0f9-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxydecanoate",
				"plotName": null,
				"compoundId": "0000a5f9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GHPVDCPCKSNJDR-UHFFFAOYAC",
				"kegg": null,
				"mass": 188.141245,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1732,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da59-0000-0000-0000-000000000000",
				"chemicalId": "05f5e637-0000-0000-0000-000000000000",
				"chemicalName": "eicosenoate (20:1)",
				"plotName": null,
				"compoundId": "00008333-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02231",
				"inChiKey": "BITHHVVYSMSWAG-KTKRTIGZBC",
				"kegg": "C16526",
				"mass": 310.28718,
				"pathwayType": "ANIMAL",
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1370,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6745"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7226"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4493"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0981"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1215"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3003"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1271"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4177"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0404"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3516"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0183"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7418"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7839"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.0416"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6703"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5911"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8824"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3443"
					}
				]
			},
			{
				"metaboliteId": "0078da5a-0000-0000-0000-000000000000",
				"chemicalId": "000001a2-0000-0000-0000-000000000000",
				"chemicalName": "methylmalonate (MMA)",
				"plotName": null,
				"compoundId": "000005d8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00202",
				"inChiKey": "ZIYVHBGGAOATLY-UHFFFAOYAT",
				"kegg": "C02170",
				"mass": 118.02661,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1598,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da5b-0000-0000-0000-000000000000",
				"chemicalId": "05f5f656-0000-0000-0000-000000000000",
				"chemicalName": "salidroside",
				"plotName": null,
				"compoundId": "0000b16b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "ILRCGYURZSFMEG-RKQHYHRCBF",
				"kegg": "C06046",
				"mass": 300.120903,
				"pathwayType": "ANIMAL",
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5115,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3113"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0321"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1884"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3711"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3638"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5908"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5351"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6679"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8038"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0834"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2127"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5503"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3792"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0516"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2835"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1573"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5346"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1131"
					}
				]
			},
			{
				"metaboliteId": "0078da5c-0000-0000-0000-000000000000",
				"chemicalId": "00000471-0000-0000-0000-000000000000",
				"chemicalName": "oleoyl ethanolamide",
				"plotName": null,
				"compoundId": "000094d6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB02088",
				"inChiKey": "BOWVQLFMWHZBEF-KTKRTIGZBW",
				"kegg": null,
				"mass": 325.298079,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1905,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4880"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0527"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8375"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3002"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0389"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6993"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1628"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2038"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6891"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6886"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5757"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1263"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1912"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.5618"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5959"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9432"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1145"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2087"
					}
				]
			},
			{
				"metaboliteId": "0078da5d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4c3-0000-0000-0000-000000000000",
				"chemicalName": "homocitrulline",
				"plotName": null,
				"compoundId": "0000567a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00679",
				"inChiKey": "XIGSAGMEBXLVJJ-YFKPBYRVBI",
				"kegg": "C02427",
				"mass": 189.111341,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 425,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1172"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8052"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5625"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0125"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4029"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4362"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5666"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4548"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2511"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5214"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3734"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1502"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1837"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5324"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1173"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0131"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6754"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2016"
					}
				]
			},
			{
				"metaboliteId": "0078da5e-0000-0000-0000-000000000000",
				"chemicalId": "00000520-0000-0000-0000-000000000000",
				"chemicalName": "nicotinamide ribonucleotide (NMN)",
				"plotName": null,
				"compoundId": "00005688-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00229",
				"inChiKey": "DAYLJWODMCOQEW-TURQNECABR",
				"kegg": "C00455",
				"mass": 334.056602,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3918,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da5f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e4ee-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylglycine",
				"plotName": null,
				"compoundId": "00006c3e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00532",
				"inChiKey": "OKJIRPAQVSHGFK-UHFFFAOYAB",
				"kegg": null,
				"mass": 117.042593,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 2,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3906"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1501"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6517"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2708"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0203"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.1834"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0334"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1150"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1645"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0450"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0026"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0757"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0232"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4389"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4009"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9509"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7157"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3216"
					}
				]
			},
			{
				"metaboliteId": "0078da60-0000-0000-0000-000000000000",
				"chemicalId": "000001eb-0000-0000-0000-000000000000",
				"chemicalName": "pyridoxal",
				"plotName": null,
				"compoundId": "00000673-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01545",
				"inChiKey": "RADKZDMFGJYCBB-UHFFFAOYAP",
				"kegg": "C00250",
				"mass": 167.058244,
				"pathwayType": "ANIMAL",
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4052,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5277"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3853"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3735"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6766"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2463"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0359"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1722"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6275"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2257"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9906"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9725"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9152"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4522"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7724"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7802"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1012"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1059"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2215"
					}
				]
			},
			{
				"metaboliteId": "0078da61-0000-0000-0000-000000000000",
				"chemicalId": "000001f0-0000-0000-0000-000000000000",
				"chemicalName": "glutathione, reduced (GSH)",
				"plotName": null,
				"compoundId": "0000084f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00125",
				"inChiKey": "RWSXRVCMGQZWBV-WDSKDSINBK",
				"kegg": "C00051",
				"mass": 307.083809,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da62-0000-0000-0000-000000000000",
				"chemicalId": "00000474-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamylglutamine",
				"plotName": null,
				"compoundId": "00000aaa-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11738",
				"inChiKey": "JBFYFLXEJFQWMU-WDSKDSINBP",
				"kegg": "C05283",
				"mass": 275.111737,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 551,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4581"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1464"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1125"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4324"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4759"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6082"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4492"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5412"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3963"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5288"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9566"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0531"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0797"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1023"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1417"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0511"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2571"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7792"
					}
				]
			},
			{
				"metaboliteId": "0078da63-0000-0000-0000-000000000000",
				"chemicalId": "00000369-0000-0000-0000-000000000000",
				"chemicalName": "thiamin (Vitamin B1)",
				"plotName": null,
				"compoundId": "000014dd-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00235",
				"inChiKey": null,
				"kegg": "C00378",
				"mass": 264.104482,
				"pathwayType": "ANIMAL",
				"subPathway": "Thiamine Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4025,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2443"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1980"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4018"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5917"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1426"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2968"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1235"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1923"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1339"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7717"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5594"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0197"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5274"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5715"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1680"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0236"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8908"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2818"
					}
				]
			},
			{
				"metaboliteId": "0078da64-0000-0000-0000-000000000000",
				"chemicalId": "05f5e558-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxylaurate",
				"plotName": null,
				"compoundId": "00007ec9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00387",
				"inChiKey": "MUCMKTPAZLSKTL-UHFFFAOYAV",
				"kegg": null,
				"mass": 216.172545,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1750,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3129"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3844"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1346"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8520"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3482"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6131"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.4632"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6786"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2501"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4401"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7499"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5061"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7278"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3333"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3868"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4224"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7742"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6800"
					}
				]
			},
			{
				"metaboliteId": "0078da65-0000-0000-0000-000000000000",
				"chemicalId": "05f5f8a8-0000-0000-0000-000000000000",
				"chemicalName": "N-formylphenylalanine",
				"plotName": null,
				"compoundId": "0000bd31-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "NSTPXGARCQOSAU-VIFPVBQEBQ",
				"kegg": null,
				"mass": 193.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 209,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7282"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7873"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0461"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6247"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7690"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0613"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8330"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7185"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8405"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1771"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5293"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2418"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7185"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1287"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1199"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0564"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4717"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8562"
					}
				]
			},
			{
				"metaboliteId": "0078da66-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9e4-0000-0000-0000-000000000000",
				"chemicalName": "gentisic acid-5-glucoside",
				"plotName": null,
				"compoundId": "0000be59-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CBTFERBMQQAROP-BZNQNGANBY",
				"kegg": null,
				"mass": 316.079432,
				"pathwayType": "ANIMAL",
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5358,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3378"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6784"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8375"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7680"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5815"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9122"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9543"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3265"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5696"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2551"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4390"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.3410"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7812"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1699"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5942"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5463"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6776"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3378"
					}
				]
			},
			{
				"metaboliteId": "0078da67-0000-0000-0000-000000000000",
				"chemicalId": "05f5f2fe-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPS (18:2)*",
				"plotName": null,
				"compoundId": "0000aa9c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 521.275369,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2412,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da68-0000-0000-0000-000000000000",
				"chemicalId": "05f5f60d-0000-0000-0000-000000000000",
				"chemicalName": "ferulic acid 4-sulfate",
				"plotName": null,
				"compoundId": "0000b80a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29200",
				"inChiKey": "PZPATWACAAOHTJ-HWKANZROBD",
				"kegg": null,
				"mass": 274.014723,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4409,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da69-0000-0000-0000-000000000000",
				"chemicalId": "000000f4-0000-0000-0000-000000000000",
				"chemicalName": "beta-alanine",
				"plotName": null,
				"compoundId": "00000037-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00056",
				"inChiKey": "UCMIRNVEIXFBKS-UHFFFAOYAL",
				"kegg": "C00099",
				"mass": 89.047679,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3857,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5141"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5786"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6772"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7350"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6857"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6723"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5723"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3953"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1958"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2453"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4272"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1164"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3766"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4404"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1377"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3722"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6664"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3357"
					}
				]
			},
			{
				"metaboliteId": "0078da6a-0000-0000-0000-000000000000",
				"chemicalId": "00000032-0000-0000-0000-000000000000",
				"chemicalName": "spermidine",
				"plotName": null,
				"compoundId": "000001e5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01257",
				"inChiKey": "ATHGHQPFGPMSJY-UHFFFAOYAK",
				"kegg": "C00315",
				"mass": 145.157897,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 470,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da6b-0000-0000-0000-000000000000",
				"chemicalId": "000001b0-0000-0000-0000-000000000000",
				"chemicalName": "nicotinamide",
				"plotName": null,
				"compoundId": "00000252-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01406",
				"inChiKey": "DFPAKSUCGFBDDF-UHFFFAOYAZ",
				"kegg": "C00153",
				"mass": 122.048013,
				"pathwayType": "ANIMAL",
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 3916,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8513"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2691"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9295"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.2622"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2559"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6140"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9550"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4151"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0384"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3644"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3181"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0736"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6885"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2017"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9281"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0118"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9679"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7558"
					}
				]
			},
			{
				"metaboliteId": "0078da6c-0000-0000-0000-000000000000",
				"chemicalId": "000000bd-0000-0000-0000-000000000000",
				"chemicalName": "N6,N6,N6-trimethyllysine",
				"plotName": null,
				"compoundId": "000005da-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01325",
				"inChiKey": "MXNRLFUSFKVQSK-UHFFFAOYAO",
				"kegg": "C03793",
				"mass": 188.152478,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 109,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3687"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3367"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3778"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4362"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5210"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1391"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3548"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3554"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6161"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5776"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8572"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1846"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6206"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3029"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0728"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6840"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1396"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1236"
					}
				]
			},
			{
				"metaboliteId": "0078da6d-0000-0000-0000-000000000000",
				"chemicalId": "05f5e9ce-0000-0000-0000-000000000000",
				"chemicalName": "stearoyl ethanolamide",
				"plotName": null,
				"compoundId": "000096e1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB13078",
				"inChiKey": "OTGQIQQTPXJQRG-UHFFFAOYAF",
				"kegg": null,
				"mass": 327.31373,
				"pathwayType": "ANIMAL",
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1909,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9688"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1295"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1214"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3982"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6020"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2080"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4315"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2898"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3144"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1886"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3837"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6569"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8390"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0658"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4574"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3722"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3501"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3174"
					}
				]
			},
			{
				"metaboliteId": "0078da6e-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ef-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-GPC (16:0)",
				"plotName": null,
				"compoundId": "000084a3-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10382",
				"inChiKey": "ASWBNKHCZGQVJV-UHFFFAOYAM",
				"kegg": null,
				"mass": 495.332489,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2317,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6050"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0644"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7332"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3931"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2884"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1327"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3773"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4313"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1876"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2284"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3940"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5923"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1677"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0446"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2424"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2202"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7109"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8142"
					}
				]
			},
			{
				"metaboliteId": "0078da6f-0000-0000-0000-000000000000",
				"chemicalId": "05f5e719-0000-0000-0000-000000000000",
				"chemicalName": "2-palmitoleoyl-GPC (16:1)*",
				"plotName": null,
				"compoundId": "00008beb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB10383",
				"inChiKey": "GKXPPJQBVCKAGD-DJYGDJEFBY",
				"kegg": null,
				"mass": 493.316839,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2320,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da70-0000-0000-0000-000000000000",
				"chemicalId": "05f5e722-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPE (18:2)*",
				"plotName": null,
				"compoundId": "00008ef8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11507",
				"inChiKey": "DBHKHNGBVGWQJE-HZJYTTRNBF",
				"kegg": null,
				"mass": 477.285539,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2382,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2126"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9303"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1740"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7885"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0027"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0196"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8928"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3250"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2879"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3683"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3654"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3406"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8289"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6077"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3008"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6459"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.9354"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7990"
					}
				]
			},
			{
				"metaboliteId": "0078da71-0000-0000-0000-000000000000",
				"chemicalId": "05f5e110-0000-0000-0000-000000000000",
				"chemicalName": "suberate (C8-DC)",
				"plotName": null,
				"compoundId": "00003d72-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00893",
				"inChiKey": "TYFQFVWCELRYAO-UHFFFAOYAN",
				"kegg": "C08278",
				"mass": 174.08921,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1500,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1980"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2993"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5184"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7054"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5918"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2895"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1164"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4857"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0783"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0176"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3726"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1827"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6862"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6862"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5839"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7199"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1646"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8332"
					}
				]
			},
			{
				"metaboliteId": "0078da72-0000-0000-0000-000000000000",
				"chemicalId": "000003a6-0000-0000-0000-000000000000",
				"chemicalName": "stachyose",
				"plotName": null,
				"compoundId": "00003e58-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03553",
				"inChiKey": "LMRVFVDBBWZLJM-KZCQQNKXBU",
				"kegg": "C01613",
				"mass": 666.221865,
				"pathwayType": "ANIMAL",
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1150,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3962"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1512"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1328"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0758"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7890"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0598"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1924"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8462"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8461"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8916"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2165"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9803"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6141"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9946"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9480"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0173"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2335"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9614"
					}
				]
			},
			{
				"metaboliteId": "0078da73-0000-0000-0000-000000000000",
				"chemicalId": "05f5f288-0000-0000-0000-000000000000",
				"chemicalName": "N-monomethylarginine",
				"plotName": null,
				"compoundId": "0000aa42-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB29416",
				"inChiKey": "NTNWOCRCBQPEKQ-YFKPBYRVBT",
				"kegg": "C03884",
				"mass": 188.127326,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 449,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6127"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1651"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8663"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4968"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2577"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8048"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8226"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9056"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3619"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4724"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4717"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5655"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.4850"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2878"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2840"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3692"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4411"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9920"
					}
				]
			},
			{
				"metaboliteId": "0078da74-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5be-0000-0000-0000-000000000000",
				"chemicalName": "N(4)-acetylspermidine",
				"plotName": null,
				"compoundId": "00007e64-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "OYYYKBDTBANKSJ-UHFFFAOYAP",
				"kegg": null,
				"mass": 187.168462,
				"pathwayType": "ANIMAL",
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 472,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da75-0000-0000-0000-000000000000",
				"chemicalId": "00000076-0000-0000-0000-000000000000",
				"chemicalName": "3-methoxytyramine",
				"plotName": null,
				"compoundId": "000096e6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00022",
				"inChiKey": "DIVQKHQLANKJQO-UHFFFAOYAB",
				"kegg": "C05587",
				"mass": 167.094629,
				"pathwayType": "ANIMAL",
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 187,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da76-0000-0000-0000-000000000000",
				"chemicalId": "05f5e21d-0000-0000-0000-000000000000",
				"chemicalName": "N-alpha-acetylornithine",
				"plotName": null,
				"compoundId": "000080d8-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03357",
				"inChiKey": "JRLGPAXAGHMNOL-LURJTMIEBY",
				"kegg": "C00437",
				"mass": 174.100442,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 437,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da77-0000-0000-0000-000000000000",
				"chemicalId": "05f5e5ee-0000-0000-0000-000000000000",
				"chemicalName": "gamma-glutamyl-epsilon-lysine",
				"plotName": null,
				"compoundId": "0000848e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03869",
				"inChiKey": "JPKNLFVGUZRHOB-YUMQZZPRBM",
				"kegg": null,
				"mass": 275.148121,
				"pathwayType": "ANIMAL",
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 557,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da78-0000-0000-0000-000000000000",
				"chemicalId": "05f5e636-0000-0000-0000-000000000000",
				"chemicalName": "N-acetylproline",
				"plotName": null,
				"compoundId": "00008653-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GNMSLDIYJOSUSW-UHFFFAOYAD",
				"kegg": null,
				"mass": 157.073893,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 435,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1310"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4749"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3068"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6120"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6815"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9280"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8415"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7801"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1733"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1667"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8272"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1382"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5185"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8933"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5333"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5700"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6320"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0538"
					}
				]
			},
			{
				"metaboliteId": "0078da79-0000-0000-0000-000000000000",
				"chemicalId": "05f5e671-0000-0000-0000-000000000000",
				"chemicalName": "isovalerylcarnitine (C5)",
				"plotName": null,
				"compoundId": "00008667-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00688",
				"inChiKey": "IGQBPDJNUXPEMT-UHFFFAOYAB",
				"kegg": null,
				"mass": 245.162708,
				"pathwayType": "ANIMAL",
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 306,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da7a-0000-0000-0000-000000000000",
				"chemicalId": "000001cd-0000-0000-0000-000000000000",
				"chemicalName": "phosphate",
				"plotName": null,
				"compoundId": "0000a47d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB01429",
				"inChiKey": "NBIIXXVUZAFLBC-DFZHHIFOAA",
				"kegg": "C00009",
				"mass": 97.976898,
				"pathwayType": "ANIMAL",
				"subPathway": "Oxidative Phosphorylation",
				"superPathway": "Energy",
				"pathwaySortOrder": 1310,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7720"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7623"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9690"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5806"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8543"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1107"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5428"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6200"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.5101"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9308"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9648"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7393"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4042"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8942"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0710"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5176"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2622"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4165"
					}
				]
			},
			{
				"metaboliteId": "0078da7b-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cf-0000-0000-0000-000000000000",
				"chemicalName": "tryptophan betaine",
				"plotName": null,
				"compoundId": "000090e9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB61115",
				"inChiKey": "AOHCBEAZXHZMOR-ZDUSSCGKBF",
				"kegg": "C09213",
				"mass": 246.136828,
				"pathwayType": "ANIMAL",
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 238,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da7c-0000-0000-0000-000000000000",
				"chemicalId": "000005ee-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyl-sphingosine (d18:1/16:0)",
				"plotName": null,
				"compoundId": "0000af4d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04949",
				"inChiKey": "YDNKGFDKKRUKPY-TURZORIXBD",
				"kegg": null,
				"mass": 537.512094,
				"pathwayType": "ANIMAL",
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2710,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da7d-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9aa-0000-0000-0000-000000000000",
				"chemicalName": "sphingomyelin (d17:1/16:0, d18:1/15:0, d16:1/17:0)*",
				"plotName": null,
				"compoundId": "0000ccd1-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "LQINJRUGTUOHGS-YPDYIYJKBT",
				"kegg": null,
				"mass": 688.551925,
				"pathwayType": "ANIMAL",
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2803,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8566"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0306"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2540"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3931"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4194"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0304"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2925"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2155"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4012"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2161"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4257"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3478"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6068"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2035"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6028"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8387"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8387"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2573"
					}
				]
			},
			{
				"metaboliteId": "0078da7e-0000-0000-0000-000000000000",
				"chemicalId": "05f6040f-0000-0000-0000-000000000000",
				"chemicalName": "uridine 2'-monophosphate (2'-UMP)*",
				"plotName": null,
				"compoundId": "0000ccdc-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 324.035871,
				"pathwayType": "ANIMAL",
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 3860,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da7f-0000-0000-0000-000000000000",
				"chemicalId": "05f60410-0000-0000-0000-000000000000",
				"chemicalName": "1-stearoyl-2-linoleoyl-GPE (18:0/18:2)*",
				"plotName": null,
				"compoundId": "0000ccde-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08994",
				"inChiKey": "YDTWOEYVDRKKCR-KNERPIHHBL",
				"kegg": null,
				"mass": 743.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2160,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9623"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2144"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6865"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1159"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5329"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4119"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1615"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6945"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7051"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7474"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3716"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2299"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6730"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4192"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0869"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1980"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2299"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3256"
					}
				]
			},
			{
				"metaboliteId": "0078da80-0000-0000-0000-000000000000",
				"chemicalId": "05f60415-0000-0000-0000-000000000000",
				"chemicalName": "1-oleoyl-2-linoleoyl-GPC (18:1/18:2)*",
				"plotName": null,
				"compoundId": "0000cce5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 783.577805,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2085,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4060"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0349"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9672"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9604"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4950"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7051"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9711"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2357"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0245"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8873"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3534"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1398"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3042"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1970"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3350"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8224"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3943"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6836"
					}
				]
			},
			{
				"metaboliteId": "0078da81-0000-0000-0000-000000000000",
				"chemicalId": "05f5e509-0000-0000-0000-000000000000",
				"chemicalName": "beta-sitosterol",
				"plotName": null,
				"compoundId": "00006b16-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00852",
				"inChiKey": "KZJWDPNRJALLNS-VJSFXXLFBZ",
				"kegg": "C01753",
				"mass": 414.386166,
				"pathwayType": "ANIMAL",
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2905,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5261"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0440"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1960"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3042"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5451"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.7803"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7297"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2253"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7820"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8158"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2792"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2925"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2432"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4784"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3686"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9075"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2420"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6351"
					}
				]
			},
			{
				"metaboliteId": "0078da82-0000-0000-0000-000000000000",
				"chemicalId": "05f60580-0000-0000-0000-000000000000",
				"chemicalName": "1,2-dilinolenoyl-GPC (18:3/18:3)*",
				"plotName": null,
				"compoundId": "0000cfbb-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB08206",
				"inChiKey": null,
				"kegg": null,
				"mass": 777.530855,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2106,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6423"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4829"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5203"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0176"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6180"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0289"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1594"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0313"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9058"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9766"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3767"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7685"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0748"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9509"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6319"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6672"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0857"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1887"
					}
				]
			},
			{
				"metaboliteId": "0078da83-0000-0000-0000-000000000000",
				"chemicalId": "05f60444-0000-0000-0000-000000000000",
				"chemicalName": "N-palmitoyl-sphinganine (d18:0/16:0)",
				"plotName": null,
				"compoundId": "0000cd7c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB11760",
				"inChiKey": "GCGTXOVNNFGTPQ-JHOUSYSJBP",
				"kegg": null,
				"mass": 539.527745,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydroceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2702,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9491"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2703"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4996"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1974"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4705"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9099"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4880"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8928"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2690"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2446"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6132"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3016"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5243"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9732"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2000"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6402"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.6402"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1266"
					}
				]
			},
			{
				"metaboliteId": "0078da84-0000-0000-0000-000000000000",
				"chemicalId": "05f5f0cc-0000-0000-0000-000000000000",
				"chemicalName": "N-methylaspartate",
				"plotName": null,
				"compoundId": "0000a7f4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HOKKHZGPKSLGJE-UHFFFAOYAN",
				"kegg": null,
				"mass": 147.053158,
				"pathwayType": "ANIMAL",
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 37,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da85-0000-0000-0000-000000000000",
				"chemicalId": "05f6051e-0000-0000-0000-000000000000",
				"chemicalName": "2-methylserine",
				"plotName": null,
				"compoundId": "0000cfed-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CDUUKBXTEOFITR-UHFFFAOYAW",
				"kegg": "C02115",
				"mass": 119.058243,
				"pathwayType": "ANIMAL",
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 15,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0102"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0483"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7012"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1125"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0550"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4011"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.5106"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2173"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0337"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9784"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1697"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3047"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8848"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4678"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5060"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3939"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2846"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1147"
					}
				]
			},
			{
				"metaboliteId": "0078da86-0000-0000-0000-000000000000",
				"chemicalId": "05f60573-0000-0000-0000-000000000000",
				"chemicalName": "oleoylcholine",
				"plotName": null,
				"compoundId": "0000d00c-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 367.34503,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1715,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0903"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8628"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2665"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0408"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3460"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8672"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2749"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1527"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2030"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3455"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1165"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4481"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2030"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0577"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2294"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.1152"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5529"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2459"
					}
				]
			},
			{
				"metaboliteId": "0078da87-0000-0000-0000-000000000000",
				"chemicalId": "05f60b72-0000-0000-0000-000000000000",
				"chemicalName": "2-hydroxyoleate",
				"plotName": null,
				"compoundId": "0000d688-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "JBSOOFITVPOOSY-KTKRTIGZBI",
				"kegg": null,
				"mass": 298.250795,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1736,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da88-0000-0000-0000-000000000000",
				"chemicalId": "05f5edc7-0000-0000-0000-000000000000",
				"chemicalName": "beta-citrylglutamate",
				"plotName": null,
				"compoundId": "0000d68b-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "GAQNUGISBQJMKO-UHFFFAOYAK",
				"kegg": "C20775",
				"mass": 321.069596,
				"pathwayType": "ANIMAL",
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 54,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4849"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6789"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0965"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6265"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7439"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5274"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2849"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1169"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5653"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0706"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7035"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7207"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8854"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6198"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5615"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3021"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3021"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6736"
					}
				]
			},
			{
				"metaboliteId": "0078da89-0000-0000-0000-000000000000",
				"chemicalId": "05f5e7cc-0000-0000-0000-000000000000",
				"chemicalName": "mannitol/sorbitol",
				"plotName": null,
				"compoundId": "0000b43e-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB00247",
				"inChiKey": "FBPFZTCFMRRESA-UHFFFAOYAI",
				"kegg": "C00794",
				"mass": 182.079038,
				"pathwayType": "ANIMAL",
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1198,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1146"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6186"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8160"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5730"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7768"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5316"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4468"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6356"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8606"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5863"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9523"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4677"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4354"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3403"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8718"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8663"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "2.0088"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6457"
					}
				]
			},
			{
				"metaboliteId": "0078da8a-0000-0000-0000-000000000000",
				"chemicalId": "000001bc-0000-0000-0000-000000000000",
				"chemicalName": "ornithine",
				"plotName": null,
				"compoundId": "000005d5-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB03374",
				"inChiKey": "AHLPHDHHMVZTML-BYPYZUCNBZ",
				"kegg": "C00077",
				"mass": 132.089878,
				"pathwayType": "ANIMAL",
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 418,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6682"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0897"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2987"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0018"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3495"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.7665"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6361"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6752"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4877"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5274"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5936"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2259"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5897"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3115"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4311"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5615"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5444"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1635"
					}
				]
			},
			{
				"metaboliteId": "0078da8b-0000-0000-0000-000000000000",
				"chemicalId": "000000b3-0000-0000-0000-000000000000",
				"chemicalName": "9,10-DiHOME",
				"plotName": null,
				"compoundId": "000095ff-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB04704",
				"inChiKey": "XEBKSQSGNGRGDW-YFHOEESVBC",
				"kegg": "C14828",
				"mass": 314.24571,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1795,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2953"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0296"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9489"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.8114"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5016"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1381"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8224"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1639"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0198"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8325"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2473"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2808"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7733"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7056"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1085"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5284"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1371"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8271"
					}
				]
			},
			{
				"metaboliteId": "0078da8c-0000-0000-0000-000000000000",
				"chemicalId": "05f5f9df-0000-0000-0000-000000000000",
				"chemicalName": "3-hydroxyhexanoate",
				"plotName": null,
				"compoundId": "0000cfee-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "HPMGFDVTYHWBAG-UHFFFAOYAJ",
				"kegg": null,
				"mass": 132.078644,
				"pathwayType": "ANIMAL",
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1744,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da8d-0000-0000-0000-000000000000",
				"chemicalId": "05f6050b-0000-0000-0000-000000000000",
				"chemicalName": "1-linoleoyl-GPG (18:2)*",
				"plotName": null,
				"compoundId": "0000d665-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 508.28012,
				"pathwayType": "ANIMAL",
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2421,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da8e-0000-0000-0000-000000000000",
				"chemicalId": "05f60b7b-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linolenoyl-digalactosylglycerol (16:0/18:3)",
				"plotName": null,
				"compoundId": "0000d66d-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "WVWINZZVFAFVMJ-ZPOBZWIOBD",
				"kegg": null,
				"mass": 914.596672,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2673,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.8359"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3419"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7341"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1258"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6972"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7898"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3215"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0234"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0169"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3614"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8918"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6756"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0435"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2957"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0097"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5191"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.1771"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4702"
					}
				]
			},
			{
				"metaboliteId": "0078da8f-0000-0000-0000-000000000000",
				"chemicalId": "05f5ed6b-0000-0000-0000-000000000000",
				"chemicalName": "leucylalanine",
				"plotName": null,
				"compoundId": "00009c4a-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB28922",
				"inChiKey": "HSQGMTRYSIHDAC-UHFFFAOYAG",
				"kegg": null,
				"mass": 202.131742,
				"pathwayType": "ANIMAL",
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 777,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da90-0000-0000-0000-000000000000",
				"chemicalId": "05f60b9b-0000-0000-0000-000000000000",
				"chemicalName": "coumaroylquinate (2)",
				"plotName": null,
				"compoundId": "0000d68f-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 338.100168,
				"pathwayType": "ANIMAL",
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da91-0000-0000-0000-000000000000",
				"chemicalId": "05f60bad-0000-0000-0000-000000000000",
				"chemicalName": "palmitoyl-arachidonoyl-glycerol (16:0/20:4) [2]*",
				"plotName": null,
				"compoundId": "0000d6ae-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07112",
				"inChiKey": null,
				"kegg": null,
				"mass": 616.506675,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2615,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da92-0000-0000-0000-000000000000",
				"chemicalId": "05f60bb6-0000-0000-0000-000000000000",
				"chemicalName": "diacylglycerol (14:0/18:1, 16:0/16:1) [1]*",
				"plotName": null,
				"compoundId": "0000d6a9-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 566.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2588,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			},
			{
				"metaboliteId": "0078da93-0000-0000-0000-000000000000",
				"chemicalId": "05f60baf-0000-0000-0000-000000000000",
				"chemicalName": "linoleoyl-linolenoyl-glycerol (18:2/18:3) [2]*",
				"plotName": null,
				"compoundId": "0000d6b4-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07250",
				"inChiKey": null,
				"kegg": null,
				"mass": 614.491025,
				"pathwayType": "ANIMAL",
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2634,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1664"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4177"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2938"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8747"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5073"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1128"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8810"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6544"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4280"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4382"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2419"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6143"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.5332"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0349"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6449"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.6651"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4317"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3021"
					}
				]
			},
			{
				"metaboliteId": "0078da94-0000-0000-0000-000000000000",
				"chemicalId": "05f60b8e-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-linolenoyl-galactosylglycerol (16:0/18:3)*",
				"plotName": null,
				"compoundId": "0000d674-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 752.543849,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2672,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4576"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1588"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6225"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3258"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9136"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4741"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2448"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1033"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2174"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.4856"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9856"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7287"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0421"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6393"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2060"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6640"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.7287"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4006"
					}
				]
			},
			{
				"metaboliteId": "0078da95-0000-0000-0000-000000000000",
				"chemicalId": "05f60418-0000-0000-0000-000000000000",
				"chemicalName": "1-palmitoyl-2-palmitoleoyl-GPC (16:0/16:1)*",
				"plotName": null,
				"compoundId": "0000ccf6-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB07969",
				"inChiKey": null,
				"kegg": null,
				"mass": 731.546505,
				"pathwayType": "ANIMAL",
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2020,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1633"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3823"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.0055"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6123"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2709"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.3250"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1370"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.8716"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.1284"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8919"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8440"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.3404"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.3309"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.4769"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6067"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.3445"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.3773"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7117"
					}
				]
			},
			{
				"metaboliteId": "0078da96-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecf9-0000-0000-0000-000000000000",
				"chemicalName": "digalactosylglycerol*",
				"plotName": null,
				"compoundId": "0000e002-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": "CPJPQXHWHOMKBP-HQDWSMQCBQ",
				"kegg": null,
				"mass": 416.152991,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2668,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2459"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6474"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.1938"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9641"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0439"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1298"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6567"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-2.2532"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7531"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.9792"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.5476"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4197"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.7265"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2089"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.6932"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7867"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.4860"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2651"
					}
				]
			},
			{
				"metaboliteId": "0078da97-0000-0000-0000-000000000000",
				"chemicalId": "05f5ecf8-0000-0000-0000-000000000000",
				"chemicalName": "galactosylglycerol",
				"plotName": null,
				"compoundId": "0000e001-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB06790",
				"inChiKey": "NHJUPBDCSOGIKX-NTXXKDEIBK",
				"kegg": "C05401",
				"mass": 254.100168,
				"pathwayType": "ANIMAL",
				"subPathway": "Galactosyl Glycerolipids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2667,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0074"
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.2443"
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.9953"
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.0910"
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.0197"
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.2864"
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.4100"
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8468"
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9991"
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9331"
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.1813"
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.7251"
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.0025"
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "0.6647"
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "1.2838"
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-1.2938"
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.8763"
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": "1",
						"qValue": "1",
						"foldChange": "-0.9144"
					}
				]
			},
			{
				"metaboliteId": "0078da98-0000-0000-0000-000000000000",
				"chemicalId": "05f6044e-0000-0000-0000-000000000000",
				"chemicalName": "myristoyl dihydrosphingomyelin (d18:0/14:0)*",
				"plotName": null,
				"compoundId": "0000e015-0000-0000-0000-000000000000",
				"compoundType": "NAMED",
				"hmdb": "HMDB12085",
				"inChiKey": null,
				"kegg": null,
				"mass": 676.551925,
				"pathwayType": "ANIMAL",
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2786,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "3b9c6b60-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b68-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b69-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b71-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b72-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b73-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b75-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b76-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b77-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b78-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b79-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					},
					{
						"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
						"pValue": null,
						"qValue": null,
						"foldChange": null
					}
				]
			}
		],
		"stats": [
			{
				"statsId": "3b9c6b60-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "28",
				"reportName": null,
				"foldChange": "28",
				"testSequence": 1,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 0,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b68-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "29",
				"reportName": null,
				"foldChange": "29",
				"testSequence": 1,
				"compSequence": 2,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 1,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b69-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "30",
				"reportName": null,
				"foldChange": "30",
				"testSequence": 1,
				"compSequence": 3,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 2,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b6b-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "31",
				"reportName": null,
				"foldChange": "31",
				"testSequence": 1,
				"compSequence": 4,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 3,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b6e-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "32",
				"reportName": null,
				"foldChange": "32",
				"testSequence": 1,
				"compSequence": 5,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 4,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b6f-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "33",
				"reportName": null,
				"foldChange": "33",
				"testSequence": 1,
				"compSequence": 6,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 5,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b71-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "34",
				"reportName": null,
				"foldChange": "34",
				"testSequence": 1,
				"compSequence": 7,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 6,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b72-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "35",
				"reportName": null,
				"foldChange": "35",
				"testSequence": 1,
				"compSequence": 8,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 7,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b73-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "36",
				"reportName": null,
				"foldChange": "36",
				"testSequence": 1,
				"compSequence": 9,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 8,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b75-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "38",
				"reportName": null,
				"foldChange": "38",
				"testSequence": 1,
				"compSequence": 11,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 9,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b76-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "39",
				"reportName": null,
				"foldChange": "39",
				"testSequence": 1,
				"compSequence": 12,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 10,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b77-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "40",
				"reportName": null,
				"foldChange": "40",
				"testSequence": 1,
				"compSequence": 13,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 11,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b78-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "41",
				"reportName": null,
				"foldChange": "41",
				"testSequence": 1,
				"compSequence": 14,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 12,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b79-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "42",
				"reportName": null,
				"foldChange": "42",
				"testSequence": 1,
				"compSequence": 15,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 13,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b7a-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "43",
				"reportName": null,
				"foldChange": "43",
				"testSequence": 1,
				"compSequence": 16,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 14,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b7b-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "44",
				"reportName": null,
				"foldChange": "44",
				"testSequence": 1,
				"compSequence": 17,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 15,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b7c-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "45",
				"reportName": null,
				"foldChange": "45",
				"testSequence": 1,
				"compSequence": 18,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 16,
				"customerVisible": true
			},
			{
				"statsId": "3b9c6b7d-0000-0000-0000-000000000000",
				"test": "ZSCORE",
				"comparison": "37",
				"reportName": null,
				"foldChange": "37",
				"testSequence": 1,
				"compSequence": 10,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 17,
				"customerVisible": true
			}
		],
		"customerVisible": true,
		"publishDate": "2016-07-22T00:00:00",
		"lastPublishDate": "2021-04-06T20:51:20.333543Z",
		"lastUpdateDate": "2021-04-06T20:51:20.333543Z"
	},
	{
		"sampleSetId": "707f174c-7e59-41f4-8f33-fc4aaad573d1",
		"projectId": "99fd4f47-c441-48ed-af75-a7e6fb7dcb02",
		"sample": {
			"sampleId": "707f174c-7e59-41f4-8f33-fc4aaad573d1",
			"name": "Phoenix_Beta_Project1-Plasma",
			"organism": "Mammal",
			"species": "Human",
			"matrix": "Blood",
			"matrixType": "Plasma"
		},
		"metabolites": [
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000071",
				"chemicalId": "00000000-0000-0000-0000-000000000071",
				"chemicalName": "5-hydroxy-1H-indole-3-acetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 290,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.301668959683647",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000098",
				"chemicalId": "00000000-0000-0000-0000-000000000098",
				"chemicalName": "4-hydroxy-2-quinolinecarboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001417",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 272,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.201851293169649",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000136",
				"chemicalId": "00000000-0000-0000-0000-000000000136",
				"chemicalName": "3-alpha,7-alpha,12-alpha-trihydroxy-5beta-cholanate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022842",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3453,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.870787182427126",
						"qValue": "0.662451255062179",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000144",
				"chemicalId": "00000000-0000-0000-0000-000000000144",
				"chemicalName": "4-hydroxyphenylacetate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000541",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 159,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.470650435027583",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000158",
				"chemicalId": "00000000-0000-0000-0000-000000000158",
				"chemicalName": "5,6-Dihydrothymine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001418",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4280,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0789434250231311",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000171",
				"chemicalId": "00000000-0000-0000-0000-000000000171",
				"chemicalName": "1,7-dihydro-6h-purin-6-one",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000003127",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4120,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.671594324818366",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000179",
				"chemicalId": "00000000-0000-0000-0000-000000000179",
				"chemicalName": "9,10-hydroxyoctadec-12(Z)-enoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038399",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2031,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.522780882007192",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000180",
				"chemicalId": "00000000-0000-0000-0000-000000000180",
				"chemicalName": "9,12-octadecadienoic acid (z,z)-",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001105",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1567,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.664886598200602",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000181",
				"chemicalId": "00000000-0000-0000-0000-000000000181",
				"chemicalName": "lauric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001645",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1502,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.513326533064266",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000194",
				"chemicalId": "00000000-0000-0000-0000-000000000194",
				"chemicalName": "N-formyl-L-methionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002829",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 437,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.739895878587381",
						"qValue": "0.640818577367289",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000197",
				"chemicalId": "00000000-0000-0000-0000-000000000197",
				"chemicalName": "S-adenosyl-L-homocysteine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042382",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 446,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.704514309153762",
						"qValue": "0.628286433618616",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000229",
				"chemicalId": "00000000-0000-0000-0000-000000000229",
				"chemicalName": "arachidonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001110",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1573,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.632557121730658",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000235",
				"chemicalId": "00000000-0000-0000-0000-000000000235",
				"chemicalName": "o-hydroxybenzeneacetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001432",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 156,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.405891811528722",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000240",
				"chemicalId": "00000000-0000-0000-0000-000000000240",
				"chemicalName": "3-(4-hydroxyphenyl)-2-hydroxypropanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032197",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 181,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.431560699476175",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000241",
				"chemicalId": "00000000-0000-0000-0000-000000000241",
				"chemicalName": "alpha-oxobenzenepropanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000566",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 150,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.990304712598864",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000273",
				"chemicalId": "00000000-0000-0000-0000-000000000273",
				"chemicalName": "cortisone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001769",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3315,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.577325057479938",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000302",
				"chemicalId": "00000000-0000-0000-0000-000000000302",
				"chemicalName": "deoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001114",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3479,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.222310830664805",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000314",
				"chemicalId": "00000000-0000-0000-0000-000000000314",
				"chemicalName": "docosanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012125",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1519,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.869650391313659",
						"qValue": "0.662451255062179",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000342",
				"chemicalId": "00000000-0000-0000-0000-000000000342",
				"chemicalName": "glycocholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018476",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3454,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.654473606517645",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000355",
				"chemicalId": "00000000-0000-0000-0000-000000000355",
				"chemicalName": "histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000059",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 70,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.429494301519639",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000356",
				"chemicalId": "00000000-0000-0000-0000-000000000356",
				"chemicalName": "hydrocortisone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001712",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.141498985683496",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000361",
				"chemicalId": "00000000-0000-0000-0000-000000000361",
				"chemicalName": "inosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001123",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.563335358161328",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000399",
				"chemicalId": "00000000-0000-0000-0000-000000000399",
				"chemicalName": "leukotriene B4",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037530",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2074,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.47262630809901",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000409",
				"chemicalId": "00000000-0000-0000-0000-000000000409",
				"chemicalName": "malic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001303",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1451,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.60286335468311",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000424",
				"chemicalId": "00000000-0000-0000-0000-000000000424",
				"chemicalName": "n-hexadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001336",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1510,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.89368176001386",
						"qValue": "0.668108208526027",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000437",
				"chemicalId": "00000000-0000-0000-0000-000000000437",
				"chemicalName": "nonanate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012035",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1492,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.886148603337258",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000439",
				"chemicalId": "00000000-0000-0000-0000-000000000439",
				"chemicalName": "octadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001358",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1514,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.920807053700631",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000452",
				"chemicalId": "00000000-0000-0000-0000-000000000452",
				"chemicalName": "palmitoleic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033447",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1529,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.613412651957105",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000466",
				"chemicalId": "00000000-0000-0000-0000-000000000466",
				"chemicalName": "phytanate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000598",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4999,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.997115386240616",
						"qValue": "0.692032170087906",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000477",
				"chemicalId": "00000000-0000-0000-0000-000000000477",
				"chemicalName": "pristanate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038271",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1652,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.415566464210518",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000500",
				"chemicalId": "00000000-0000-0000-0000-000000000500",
				"chemicalName": "riboflavine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001827",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Riboflavin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4332,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.63528678791903",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000519",
				"chemicalId": "00000000-0000-0000-0000-000000000519",
				"chemicalName": "tetradecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001365",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1507,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.62976296552986",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000535",
				"chemicalId": "00000000-0000-0000-0000-000000000535",
				"chemicalName": "uridine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000606",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.73504371119942",
						"qValue": "0.640154325505618",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000821",
				"chemicalId": "00000000-0000-0000-0000-000000000821",
				"chemicalName": "pseudouridine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033442",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4221,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.645649306490378",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000888",
				"chemicalId": "00000000-0000-0000-0000-000000000888",
				"chemicalName": "decanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001642",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1494,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.510574730640366",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000891",
				"chemicalId": "00000000-0000-0000-0000-000000000891",
				"chemicalName": "heptadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001121",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1512,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.752968941770399",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000892",
				"chemicalId": "00000000-0000-0000-0000-000000000892",
				"chemicalName": "nonadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001356",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1516,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.86997854415324",
						"qValue": "0.662451255062179",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000893",
				"chemicalId": "00000000-0000-0000-0000-000000000893",
				"chemicalName": "eicosanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001118",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1517,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.544338820392018",
						"qValue": "0.619915874328736",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000925",
				"chemicalId": "00000000-0000-0000-0000-000000000925",
				"chemicalName": "heptanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001644",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1490,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.647696676777571",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000926",
				"chemicalId": "00000000-0000-0000-0000-000000000926",
				"chemicalName": "hexanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032489",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.63030919350331",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000932",
				"chemicalId": "00000000-0000-0000-0000-000000000932",
				"chemicalName": "octanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032492",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1491,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.827242266873529",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000935",
				"chemicalId": "00000000-0000-0000-0000-000000000935",
				"chemicalName": "sucrose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001519",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Disaccharides and Oligosaccharides",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1325,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.450591455620098",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000000980",
				"chemicalId": "00000000-0000-0000-0000-000000000980",
				"chemicalName": "pentadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001361",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Saturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1509,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.352529983981154",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001021",
				"chemicalId": "00000000-0000-0000-0000-000000001021",
				"chemicalName": "L-pyroglutamic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001494",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 579,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.293853640474521",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001024",
				"chemicalId": "00000000-0000-0000-0000-000000001024",
				"chemicalName": "pantothenic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001508",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pantothenate and CoA Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.250239606324973",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001080",
				"chemicalId": "00000000-0000-0000-0000-000000001080",
				"chemicalName": "5-oxo-6,8,11,14-eicosatetraenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048465",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2149,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.461649832092448",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001082",
				"chemicalId": "00000000-0000-0000-0000-000000001082",
				"chemicalName": "N-acetyl-L-leucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001587",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.587653347011439",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001083",
				"chemicalId": "00000000-0000-0000-0000-000000001083",
				"chemicalName": "N-acetyl-L-methionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001589",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 435,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.900718687358623",
						"qValue": "0.67265789539115",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001084",
				"chemicalId": "00000000-0000-0000-0000-000000001084",
				"chemicalName": "N-acetyl-L-valine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001591",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 417,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.786317450832405",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001087",
				"chemicalId": "00000000-0000-0000-0000-000000001087",
				"chemicalName": "13-docosenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001552",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1547,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.820011718798728",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001102",
				"chemicalId": "00000000-0000-0000-0000-000000001102",
				"chemicalName": "gamma-L-glutamyl-L-tyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002734",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 646,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.765218155166723",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001111",
				"chemicalId": "00000000-0000-0000-0000-000000001111",
				"chemicalName": "DL-4-hydroxy-3-methoxymandelic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001567",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 202,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.784403482686938",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001123",
				"chemicalId": "00000000-0000-0000-0000-000000001123",
				"chemicalName": "chenodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001563",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3456,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.782043337727088",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001124",
				"chemicalId": "00000000-0000-0000-0000-000000001124",
				"chemicalName": "citric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001564",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1439,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.349282927156835",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001125",
				"chemicalId": "00000000-0000-0000-0000-000000001125",
				"chemicalName": "5,6-dihydrouracil",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001559",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4231,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.624773779835897",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001134",
				"chemicalId": "00000000-0000-0000-0000-000000001134",
				"chemicalName": "uric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001604",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.35423662473586",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001135",
				"chemicalId": "00000000-0000-0000-0000-000000001135",
				"chemicalName": "ursodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001605",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.82954511297097",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001136",
				"chemicalId": "00000000-0000-0000-0000-000000001136",
				"chemicalName": "valeric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033443",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Short Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1488,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.317621622328824",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001137",
				"chemicalId": "00000000-0000-0000-0000-000000001137",
				"chemicalName": "oleic ethanolamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038102",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2159,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.476407602407865",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001141",
				"chemicalId": "00000000-0000-0000-0000-000000001141",
				"chemicalName": "4-hydroxyphenylpyruvate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001669",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 175,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.616944828591156",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001143",
				"chemicalId": "00000000-0000-0000-0000-000000001143",
				"chemicalName": "butyric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040605",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Short Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.313453783178356",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001231",
				"chemicalId": "00000000-0000-0000-0000-000000001231",
				"chemicalName": "cis-11,14-eicosadienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000017805",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1570,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.67305234635356",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001239",
				"chemicalId": "00000000-0000-0000-0000-000000001239",
				"chemicalName": "DL-alpha-hydroxystearic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000017945",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1971,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.827799885012584",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001254",
				"chemicalId": "00000000-0000-0000-0000-000000001254",
				"chemicalName": "glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015122",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2841,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.124484623421121",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001384",
				"chemicalId": "00000000-0000-0000-0000-000000001384",
				"chemicalName": "naproxen",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012122",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5390,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.250486080584141",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001489",
				"chemicalId": "00000000-0000-0000-0000-000000001489",
				"chemicalName": "palmitoyl ethanolamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038165",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2162,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.76300032497038",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001492",
				"chemicalId": "00000000-0000-0000-0000-000000001492",
				"chemicalName": "linoleamide (18:2, n-6)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032455",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Amide",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1738,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0562754492646379",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001504",
				"chemicalId": "00000000-0000-0000-0000-000000001504",
				"chemicalName": "9,10-octadecenoamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032458",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Amide",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1744,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0615067199097166",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001628",
				"chemicalId": "00000000-0000-0000-0000-000000001628",
				"chemicalName": "glycochenodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032346",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3461,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.686329448657453",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001629",
				"chemicalId": "00000000-0000-0000-0000-000000001629",
				"chemicalName": "taurochenodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018494",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.429828516136733",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001668",
				"chemicalId": "00000000-0000-0000-0000-000000001668",
				"chemicalName": "taurodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012261",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.158472208609248",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000001869",
				"chemicalId": "00000000-0000-0000-0000-000000001869",
				"chemicalName": "2-hydroxyhippuric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018281",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4466,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.208320011894775",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000000002050",
				"chemicalId": "00000000-0000-0000-0000-000000002050",
				"chemicalName": "cis-5,8,11,14,17-eicosapentaenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018467",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.588388116982512",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000008",
				"chemicalId": "00000000-0000-0000-0000-000100000008",
				"chemicalName": "benzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015778",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4476,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.315027206301044",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000010",
				"chemicalId": "00000000-0000-0000-0000-000100000010",
				"chemicalName": "hydrocinnamic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015749",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4574,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.696832495694764",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000011",
				"chemicalId": "00000000-0000-0000-0000-000100000011",
				"chemicalName": "phenylacetic acid, 91 ion",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057745",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 155,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.396987517670362",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000014",
				"chemicalId": "00000000-0000-0000-0000-000100000014",
				"chemicalName": "hippuric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015753",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.373217171268064",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000016",
				"chemicalId": "00000000-0000-0000-0000-000100000016",
				"chemicalName": "suberic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015730",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1676,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.968100688415911",
						"qValue": "0.684266035105196",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000036",
				"chemicalId": "00000000-0000-0000-0000-000100000036",
				"chemicalName": "3-methyl-2-oxovaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015676",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 394,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.341944579391744",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000042",
				"chemicalId": "00000000-0000-0000-0000-000100000042",
				"chemicalName": "3-methyl-L-histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015677",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 72,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.625138452109484",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000295",
				"chemicalId": "00000000-0000-0000-0000-000100000295",
				"chemicalName": "tartaric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015336",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5073,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.458060910990259",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000409",
				"chemicalId": "00000000-0000-0000-0000-000100000409",
				"chemicalName": "2-isopropylmalic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015667",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4780,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.722750590684331",
						"qValue": "0.637337007557677",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000436",
				"chemicalId": "00000000-0000-0000-0000-000100000436",
				"chemicalName": "glycodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018477",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.281771672798616",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000437",
				"chemicalId": "00000000-0000-0000-0000-000100000437",
				"chemicalName": "theophylline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018394",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4593,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.541661298122782",
						"qValue": "0.619699528435282",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000463",
				"chemicalId": "00000000-0000-0000-0000-000100000463",
				"chemicalName": "DL-indole-3-lactic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018349",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 298,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.449725411941013",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000467",
				"chemicalId": "00000000-0000-0000-0000-000100000467",
				"chemicalName": "3-indoxyl sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027672",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 322,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.625575695892777",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000551",
				"chemicalId": "00000000-0000-0000-0000-000100000551",
				"chemicalName": "4-methyl-2-oxovaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022116",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 343,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.337674565781093",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000580",
				"chemicalId": "00000000-0000-0000-0000-000100000580",
				"chemicalName": "1,5-anhydro-D-glucitol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000020675",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1220,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.733799586952027",
						"qValue": "0.639899334754646",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000656",
				"chemicalId": "00000000-0000-0000-0000-000100000656",
				"chemicalName": "L-a-Lysophosphatidylinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019324",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2721,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.961481198153892",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000665",
				"chemicalId": "00000000-0000-0000-0000-000100000665",
				"chemicalName": "docosahexaenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044675",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1563,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.653529803437831",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000706",
				"chemicalId": "00000000-0000-0000-0000-000100000706",
				"chemicalName": "DL-alpha-hydroxyisocaproic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022132",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 347,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.180132402793516",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000708",
				"chemicalId": "00000000-0000-0000-0000-000100000708",
				"chemicalName": "isovaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044656",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 351,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.434679126531988",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000711",
				"chemicalId": "00000000-0000-0000-0000-000100000711",
				"chemicalName": "p-acetylphenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044620",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4517,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.647049245711986",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000743",
				"chemicalId": "00000000-0000-0000-0000-000100000743",
				"chemicalName": "2-hydroxyoctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022036",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1960,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.332634001809425",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000765",
				"chemicalId": "00000000-0000-0000-0000-000100000765",
				"chemicalName": "levulinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022177",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4747,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.497818342662159",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000773",
				"chemicalId": "00000000-0000-0000-0000-000100000773",
				"chemicalName": "(�)-3-hydroxyoctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022001",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1980,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.615441764758314",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000774",
				"chemicalId": "00000000-0000-0000-0000-000100000774",
				"chemicalName": "DL-3-phenyllactic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022130",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 151,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.30212881457045",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000779",
				"chemicalId": "00000000-0000-0000-0000-000100000779",
				"chemicalName": "2-propylpentanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022290",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Neurological",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.34784040937748",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000792",
				"chemicalId": "00000000-0000-0000-0000-000100000792",
				"chemicalName": "dehydroisoandrosterone 3-sulfate, sodium salt hydrate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032425",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3337,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.359573584229989",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000827",
				"chemicalId": "00000000-0000-0000-0000-000100000827",
				"chemicalName": "monopalmitin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021127",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2851,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.8577857316968",
						"qValue": "0.658656974254217",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000841",
				"chemicalId": "00000000-0000-0000-0000-000100000841",
				"chemicalName": "oxalic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000020694",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4356,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.294660465173123",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000870",
				"chemicalId": "00000000-0000-0000-0000-000100000870",
				"chemicalName": "saccharin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021151",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5041,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.331617623439348",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000882",
				"chemicalId": "00000000-0000-0000-0000-000100000882",
				"chemicalName": "DL-beta-hydroxymyristic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021158",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1986,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.584625567207605",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000924",
				"chemicalId": "00000000-0000-0000-0000-000100000924",
				"chemicalName": "1-oleoyl-rac-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021184",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2855,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.342191565208939",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000936",
				"chemicalId": "00000000-0000-0000-0000-000100000936",
				"chemicalName": "3-methyl-2-oxobutyric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044526",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 423,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.238690307796447",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000939",
				"chemicalId": "00000000-0000-0000-0000-000100000939",
				"chemicalName": "1,6-anhydro-beta-d-glucose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021049",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4763,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.234379779060974",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000943",
				"chemicalId": "00000000-0000-0000-0000-000100000943",
				"chemicalName": "2-oleoylglycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021232",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2871,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.135969399485953",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000956",
				"chemicalId": "00000000-0000-0000-0000-000100000956",
				"chemicalName": "8-hydroxyoctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021239",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2001,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.821555934182665",
						"qValue": "0.648461471110418",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000987",
				"chemicalId": "00000000-0000-0000-0000-000100000987",
				"chemicalName": "2-linoleoyl glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032506",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2872,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.787816080055884",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100000997",
				"chemicalId": "00000000-0000-0000-0000-000100000997",
				"chemicalName": "3-hydroxydecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022053",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1982,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.627724498543519",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001034",
				"chemicalId": "00000000-0000-0000-0000-000100001034",
				"chemicalName": "indole-3-acetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027513",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 299,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.36208715631212",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001040",
				"chemicalId": "00000000-0000-0000-0000-000100001040",
				"chemicalName": "1-linoleoyl-rac-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027447",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.525087191298766",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001048",
				"chemicalId": "00000000-0000-0000-0000-000100001048",
				"chemicalName": "2-monopalmitin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033419",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2868,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.353176610896623",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001051",
				"chemicalId": "00000000-0000-0000-0000-000100001051",
				"chemicalName": "1-methyl-L-histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000030460",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 71,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.880635222078938",
						"qValue": "0.66752795471003",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001063",
				"chemicalId": "00000000-0000-0000-0000-000100001063",
				"chemicalName": "7-Ketodeoxycholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031904",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3537,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.152807082078851",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001064",
				"chemicalId": "00000000-0000-0000-0000-000100001064",
				"chemicalName": "glycolithocholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031912",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3499,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.394746946936648",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001073",
				"chemicalId": "00000000-0000-0000-0000-000100001073",
				"chemicalName": "androsterone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031591",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3350,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.596446276826001",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001083",
				"chemicalId": "00000000-0000-0000-0000-000100001083",
				"chemicalName": "3-indolepropionic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032405",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 305,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.513714620752361",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001102",
				"chemicalId": "00000000-0000-0000-0000-000100001102",
				"chemicalName": "dodecanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032388",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1686,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.740290370256996",
						"qValue": "0.640818577367289",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001104",
				"chemicalId": "00000000-0000-0000-0000-000100001104",
				"chemicalName": "N-Acetyl-L-tyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032390",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.137474605721372",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001106",
				"chemicalId": "00000000-0000-0000-0000-000100001106",
				"chemicalName": "1,3-Dimethyluric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032391",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4596,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.930835485437849",
						"qValue": "0.678666241422417",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001108",
				"chemicalId": "00000000-0000-0000-0000-000100001108",
				"chemicalName": "3-Methylxanthine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032445",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4601,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.47680535299446",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001112",
				"chemicalId": "00000000-0000-0000-0000-000100001112",
				"chemicalName": "DL-beta-Hydroxylauric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032457",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1985,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0562183165043513",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001121",
				"chemicalId": "00000000-0000-0000-0000-000100001121",
				"chemicalName": "pyridoxic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031555",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4451,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.522123612312067",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001125",
				"chemicalId": "00000000-0000-0000-0000-000100001125",
				"chemicalName": "thr-phe",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031530",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 986,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.501064384284315",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001148",
				"chemicalId": "00000000-0000-0000-0000-000100001148",
				"chemicalName": "5-hydroxyhexanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031938",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1995,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.237793952503051",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001150",
				"chemicalId": "00000000-0000-0000-0000-000100001150",
				"chemicalName": "N-propionylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031932",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1772,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.499025713371312",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001170",
				"chemicalId": "00000000-0000-0000-0000-000100001170",
				"chemicalName": "3-hydroxy-2-ethylpropionic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032397",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 405,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.240498397148174",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001178",
				"chemicalId": "00000000-0000-0000-0000-000100001178",
				"chemicalName": "3-carboxyl-4-methyl-5-propyl-2-furanpropanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031787",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1705,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.30176965273003",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001181",
				"chemicalId": "00000000-0000-0000-0000-000100001181",
				"chemicalName": "7,10,13,16,19-Docosapentaenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032504",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1562,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.724615437928703",
						"qValue": "0.638185727143745",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001182",
				"chemicalId": "00000000-0000-0000-0000-000100001182",
				"chemicalName": "13c,16c-Docosadienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032415",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1577,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.705379988648479",
						"qValue": "0.628286433618616",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001193",
				"chemicalId": "00000000-0000-0000-0000-000100001193",
				"chemicalName": "7c, 10c, 13c, 16c Docosatetraenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032980",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1575,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.516998635068844",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001195",
				"chemicalId": "00000000-0000-0000-0000-000100001195",
				"chemicalName": "13c, 16c, 19c - Docosatrienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032417",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1564,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.298729093700167",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001197",
				"chemicalId": "00000000-0000-0000-0000-000100001197",
				"chemicalName": "10c-Undecenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032497",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1500,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.436565968280715",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001198",
				"chemicalId": "00000000-0000-0000-0000-000100001198",
				"chemicalName": "myristoleic acid (9c)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032418",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1526,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.695971193342033",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001226",
				"chemicalId": "00000000-0000-0000-0000-000100001226",
				"chemicalName": "Urobilinogen",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032426",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4412,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.109619722879363",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001229",
				"chemicalId": "00000000-0000-0000-0000-000100001229",
				"chemicalName": "stearidonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033969",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1557,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.57977604479286",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001231",
				"chemicalId": "00000000-0000-0000-0000-000100001231",
				"chemicalName": "nervonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052674",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1549,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.376143656894359",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001232",
				"chemicalId": "00000000-0000-0000-0000-000100001232",
				"chemicalName": "cis-5-Dodecenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033968",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.982646605009852",
						"qValue": "0.68874941298279",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001250",
				"chemicalId": "00000000-0000-0000-0000-000100001250",
				"chemicalName": "tauro beta-muricholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033983",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3466,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.756054948248625",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001253",
				"chemicalId": "00000000-0000-0000-0000-000100001253",
				"chemicalName": "N-alpha-acetyl-L-glutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033943",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 48,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.425989486148123",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001256",
				"chemicalId": "00000000-0000-0000-0000-000100001256",
				"chemicalName": "N-acetyl-L-phenylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033950",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 141,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.807167473765085",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001276",
				"chemicalId": "00000000-0000-0000-0000-000100001276",
				"chemicalName": "N-acetyl-L-isoleucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033967",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 387,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.72189017999505",
						"qValue": "0.637337007557677",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001277",
				"chemicalId": "00000000-0000-0000-0000-000100001277",
				"chemicalName": "cis-10-nonadecenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033972",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1540,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.863749464056788",
						"qValue": "0.65994934009622",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001278",
				"chemicalId": "00000000-0000-0000-0000-000100001278",
				"chemicalName": "cis-10-heptadecenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033971",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1531,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.716320233143827",
						"qValue": "0.634832828569073",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001279",
				"chemicalId": "00000000-0000-0000-0000-000100001279",
				"chemicalName": "gamma-muricholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034093",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3525,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.204413339929016",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001287",
				"chemicalId": "00000000-0000-0000-0000-000100001287",
				"chemicalName": "epiandrosterone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033973",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3342,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.427263734260302",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001292",
				"chemicalId": "00000000-0000-0000-0000-000100001292",
				"chemicalName": "(des-arg9) Bradykinin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034420",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1068,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.395074103464108",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001315",
				"chemicalId": "00000000-0000-0000-0000-000100001315",
				"chemicalName": "p-cresol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036103",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.317789763611069",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001322",
				"chemicalId": "00000000-0000-0000-0000-000100001322",
				"chemicalName": "ADSGEGDFXAEGGGVR",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057709",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1157,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.807920140569146",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001323",
				"chemicalId": "00000000-0000-0000-0000-000100001323",
				"chemicalName": "peptide - DSGEGDFXAEGGGVR",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031548",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1165,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.78686134183121",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001324",
				"chemicalId": "00000000-0000-0000-0000-000100001324",
				"chemicalName": "ADpSGEGDFXAEGGGVR",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057712",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1166,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.322604406710426",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001326",
				"chemicalId": "00000000-0000-0000-0000-000100001326",
				"chemicalName": "XHWESASXXR",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057710",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1073,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.767715492957701",
						"qValue": "0.644254381913934",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001327",
				"chemicalId": "00000000-0000-0000-0000-000100001327",
				"chemicalName": "HWESASXX",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057711",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1072,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.510517330718499",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001330",
				"chemicalId": "00000000-0000-0000-0000-000100001330",
				"chemicalName": "HXGXA",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062508",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1071,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.677287001632504",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001335",
				"chemicalId": "00000000-0000-0000-0000-000100001335",
				"chemicalName": "eicosenoate (20:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033587",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1543,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.877817307799774",
						"qValue": "0.666821368464117",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001337",
				"chemicalId": "00000000-0000-0000-0000-000100001337",
				"chemicalName": "alpha-linolenate/gamma-linolenate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034035",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1569,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.608442556224553",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001359",
				"chemicalId": "00000000-0000-0000-0000-000100001359",
				"chemicalName": "aconitate [cis or trans]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046173",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1441,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.728952127378312",
						"qValue": "0.639144588093757",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001397",
				"chemicalId": "00000000-0000-0000-0000-000100001397",
				"chemicalName": "1,3,7-trimethyluric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034404",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4599,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.496962682654544",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001399",
				"chemicalId": "00000000-0000-0000-0000-000100001399",
				"chemicalName": "1,7-dimethyluric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034400",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4597,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.706305698325331",
						"qValue": "0.628319634551831",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001417",
				"chemicalId": "00000000-0000-0000-0000-000100001417",
				"chemicalName": "phenylacetylglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035126",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1187,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.281073060656524",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001425",
				"chemicalId": "00000000-0000-0000-0000-000100001425",
				"chemicalName": "5,6-dihydrouridine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061833",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4222,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.690974185383745",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001426",
				"chemicalId": "00000000-0000-0000-0000-000100001426",
				"chemicalName": "3-(3-amino-3-carboxypropyl)uridine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062924",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4244,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.511669409497672",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001433",
				"chemicalId": "00000000-0000-0000-0000-000100001433",
				"chemicalName": "monoarachidonin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034397",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2862,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.736150976449374",
						"qValue": "0.640154325505618",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001435",
				"chemicalId": "00000000-0000-0000-0000-000100001435",
				"chemicalName": "mono-gamma-linolenin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034393",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2857,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.359617392232239",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001445",
				"chemicalId": "00000000-0000-0000-0000-000100001445",
				"chemicalName": "1-palmitoyl-2-hydroxy-sn-glycero-3-phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034428",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2582,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.26142621601114",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001446",
				"chemicalId": "00000000-0000-0000-0000-000100001446",
				"chemicalName": "5-methyluridine (ribothymidine)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035136",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.732961506152427",
						"qValue": "0.639899334754646",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001452",
				"chemicalId": "00000000-0000-0000-0000-000100001452",
				"chemicalName": "N-isovalerylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035107",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 353,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.338578784560921",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001462",
				"chemicalId": "00000000-0000-0000-0000-000100001462",
				"chemicalName": "1-Stearoyl-2-Hydroxy-sn-Glycero-3-[Phosph-rac-1-glycerol)]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2711,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.402416597931795",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001472",
				"chemicalId": "00000000-0000-0000-0000-000100001472",
				"chemicalName": "5,8,11-Eicosatriynoic acid (mead acid)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035174",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1579,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.944226840364425",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001485",
				"chemicalId": "00000000-0000-0000-0000-000100001485",
				"chemicalName": "gamma-glutamylisoleu",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034457",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 638,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.510641333609793",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001510",
				"chemicalId": "00000000-0000-0000-0000-000100001510",
				"chemicalName": "phenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032553",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 190,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.299884971592273",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001541",
				"chemicalId": "00000000-0000-0000-0000-000100001541",
				"chemicalName": "2-hydroxy-3-methylvaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036746",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 395,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.437036996678439",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001561",
				"chemicalId": "00000000-0000-0000-0000-000100001561",
				"chemicalName": "2-palmitoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047118",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2603,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.435065566601868",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001562",
				"chemicalId": "00000000-0000-0000-0000-000100001562",
				"chemicalName": "2-palmitoylglycerophosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035253",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2601,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.988300299877362",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001577",
				"chemicalId": "00000000-0000-0000-0000-000100001577",
				"chemicalName": "N-Acetyl-L-citrulline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048434",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.361934971377045",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001579",
				"chemicalId": "00000000-0000-0000-0000-000100001579",
				"chemicalName": "2-hydroxyhexadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035675",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1968,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.776521548477348",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001580",
				"chemicalId": "00000000-0000-0000-0000-000100001580",
				"chemicalName": "Docosapentaenoic acid, 4,7,10,13,16-",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037478",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1576,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.41463490148402",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001590",
				"chemicalId": "00000000-0000-0000-0000-000100001590",
				"chemicalName": "isobutyrylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 427,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.24109278038881",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001604",
				"chemicalId": "00000000-0000-0000-0000-000100001604",
				"chemicalName": "hydroquinone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035322",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5965,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.797554371626609",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001605",
				"chemicalId": "00000000-0000-0000-0000-000100001605",
				"chemicalName": "catechol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035320",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.482872701185897",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001609",
				"chemicalId": "00000000-0000-0000-0000-000100001609",
				"chemicalName": "7alpha-Hydroxy-3-oxo-4-cholestenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036776",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3206,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.307456575575908",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001611",
				"chemicalId": "00000000-0000-0000-0000-000100001611",
				"chemicalName": "3,7-Dihydroxy-5-cholestenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036803",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3208,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.441075990599944",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001613",
				"chemicalId": "00000000-0000-0000-0000-000100001613",
				"chemicalName": "tetradecanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035669",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1691,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.933803053261793",
						"qValue": "0.68012871265685",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001614",
				"chemicalId": "00000000-0000-0000-0000-000100001614",
				"chemicalName": "hexadecanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035678",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1695,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.291291747113164",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001615",
				"chemicalId": "00000000-0000-0000-0000-000100001615",
				"chemicalName": "octadecanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036754",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1698,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.379996646426835",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001617",
				"chemicalId": "00000000-0000-0000-0000-000100001617",
				"chemicalName": "undecanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042395",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1683,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.959913350564106",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001618",
				"chemicalId": "00000000-0000-0000-0000-000100001618",
				"chemicalName": "1-monomyristin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035625",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2848,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.188034532057075",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001624",
				"chemicalId": "00000000-0000-0000-0000-000100001624",
				"chemicalName": "3-(3-hydroxyphenyl)propionate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035635",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4571,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0233983159601211",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001654",
				"chemicalId": "00000000-0000-0000-0000-000100001654",
				"chemicalName": "1-arachidonoylglycerophosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034214",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2731,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.780831878271139",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001655",
				"chemicalId": "00000000-0000-0000-0000-000100001655",
				"chemicalName": "1-palmitoylglycerophosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035305",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2717,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.861346724687371",
						"qValue": "0.659266617328281",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001657",
				"chemicalId": "00000000-0000-0000-0000-000100001657",
				"chemicalName": "glycolithocholate sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032620",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3500,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.669970109640925",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001658",
				"chemicalId": "00000000-0000-0000-0000-000100001658",
				"chemicalName": "taurolithocholate 3-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036850",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3502,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.594349546618468",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001664",
				"chemicalId": "00000000-0000-0000-0000-000100001664",
				"chemicalName": "N6-succinyladenosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048130",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4174,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.96506051732701",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001723",
				"chemicalId": "00000000-0000-0000-0000-000100001723",
				"chemicalName": "2-hydroxycaproic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037073",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1958,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.879090500433776",
						"qValue": "0.66707201974738",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001731",
				"chemicalId": "00000000-0000-0000-0000-000100001731",
				"chemicalName": "indoleacetylglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042087",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 307,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.646098033245809",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001733",
				"chemicalId": "00000000-0000-0000-0000-000100001733",
				"chemicalName": "hexanoylglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054907",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glutamine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1781,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.423304069560477",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001734",
				"chemicalId": "00000000-0000-0000-0000-000100001734",
				"chemicalName": "N6-acetyl-L-lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036752",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 107,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.907840138869854",
						"qValue": "0.674415405312238",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001739",
				"chemicalId": "00000000-0000-0000-0000-000100001739",
				"chemicalName": "dihomo-linolenate (20:3n3 or n6)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035718",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1572,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.631033051755695",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001755",
				"chemicalId": "00000000-0000-0000-0000-000100001755",
				"chemicalName": "4-vinylphenylsulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036098",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4525,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.256275191217391",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001756",
				"chemicalId": "00000000-0000-0000-0000-000100001756",
				"chemicalName": "4-ethylphenylsulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036099",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4523,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.480753357081446",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001757",
				"chemicalId": "00000000-0000-0000-0000-000100001757",
				"chemicalName": "thymol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036095",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5077,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.327556040435743",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001777",
				"chemicalId": "00000000-0000-0000-0000-000100001777",
				"chemicalName": "1-oleoylglycerophosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036602",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2723,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.304965044068074",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001778",
				"chemicalId": "00000000-0000-0000-0000-000100001778",
				"chemicalName": "1-linoleoylglycerophosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036594",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2725,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.693996663493988",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001789",
				"chemicalId": "00000000-0000-0000-0000-000100001789",
				"chemicalName": "sucralose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036649",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4682,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.263710411052335",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001806",
				"chemicalId": "00000000-0000-0000-0000-000100001806",
				"chemicalName": "o-cresol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036845",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4559,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.335325970635039",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001809",
				"chemicalId": "00000000-0000-0000-0000-000100001809",
				"chemicalName": "2-ethylphenylsulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036847",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4520,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0588230496688511",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001868",
				"chemicalId": "00000000-0000-0000-0000-000100001868",
				"chemicalName": "4-allylphenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037181",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5088,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.175247478243084",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001987",
				"chemicalId": "00000000-0000-0000-0000-000100001987",
				"chemicalName": "5alpha-androstan-3beta,17beta-diol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037190",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3386,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.73308419582478",
						"qValue": "0.639899334754646",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001988",
				"chemicalId": "00000000-0000-0000-0000-000100001988",
				"chemicalName": "5alpha-pregnan-3beta,20alpha-diol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037198",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3280,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.435488248409244",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001989",
				"chemicalId": "00000000-0000-0000-0000-000100001989",
				"chemicalName": "glycocholenate sulfate, 510 ion",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057741",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3535,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.314909576497375",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001990",
				"chemicalId": "00000000-0000-0000-0000-000100001990",
				"chemicalName": "taurocholenate sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032807",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3536,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.251823649536168",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001992",
				"chemicalId": "00000000-0000-0000-0000-000100001992",
				"chemicalName": "4-androsten-3beta,17beta-diol disulfate 1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037202",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3363,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.529639433306752",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001993",
				"chemicalId": "00000000-0000-0000-0000-000100001993",
				"chemicalName": "pregnen-diol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032562",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3260,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.970568173762798",
						"qValue": "0.685035967468815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001994",
				"chemicalId": "00000000-0000-0000-0000-000100001994",
				"chemicalName": "4-androsten-3beta,17beta-diol disulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037203",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.587768780619961",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100001999",
				"chemicalId": "00000000-0000-0000-0000-000100001999",
				"chemicalName": "21-hydroxypregnenolone disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046115",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3253,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.361003087244515",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002009",
				"chemicalId": "00000000-0000-0000-0000-000100002009",
				"chemicalName": "5alpha-pregnan-3beta,20beta-diol monosulfate 1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037196",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3271,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.198314234912477",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002014",
				"chemicalId": "00000000-0000-0000-0000-000100002014",
				"chemicalName": "5alpha-pregnan-3beta,20alpha-diol monosulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037200",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3274,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.797256847571292",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002015",
				"chemicalId": "00000000-0000-0000-0000-000100002015",
				"chemicalName": "5alpha-pregnan-3alpha,20beta-diol disulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046172",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3281,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.698154809929728",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002017",
				"chemicalId": "00000000-0000-0000-0000-000100002017",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037184",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3380,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.333461925679405",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002018",
				"chemicalId": "00000000-0000-0000-0000-000100002018",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol monosulfate 1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037186",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3378,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0531107940569963",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002021",
				"chemicalId": "00000000-0000-0000-0000-000100002021",
				"chemicalName": "5alpha-androstan-3beta,17alpha-diol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037187",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.801904345377872",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002024",
				"chemicalId": "00000000-0000-0000-0000-000100002024",
				"chemicalName": "5alpha-androstan-3beta,17beta-diol monosulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037192",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3385,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.682455221482325",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002026",
				"chemicalId": "00000000-0000-0000-0000-000100002026",
				"chemicalName": "4-androsten-3alpha,17alpha-diol monosulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037207",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3367,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.699020559908285",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002027",
				"chemicalId": "00000000-0000-0000-0000-000100002027",
				"chemicalName": "4-androsten-3alpha,17alpha-diol monosulfate 3",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037209",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3368,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.675187291978032",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002028",
				"chemicalId": "00000000-0000-0000-0000-000100002028",
				"chemicalName": "4-androsten-3beta,17beta-diol monosulfate 1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037211",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3360,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.511826648744989",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002029",
				"chemicalId": "00000000-0000-0000-0000-000100002029",
				"chemicalName": "4-androsten-3beta,17beta-diol monosulfate 2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037210",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3361,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.607346917760851",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002067",
				"chemicalId": "00000000-0000-0000-0000-000100002067",
				"chemicalName": "pregn steroid monosulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032619",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.445618411360428",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002094",
				"chemicalId": "00000000-0000-0000-0000-000100002094",
				"chemicalName": "2,7,8-trimethyl-2-(beta-carboxy-ethyl)-6-hydroxychroman",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044876",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4368,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.380111641479849",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002102",
				"chemicalId": "00000000-0000-0000-0000-000100002102",
				"chemicalName": "N-acetyl-beta-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037432",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4241,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.178141866311787",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002122",
				"chemicalId": "00000000-0000-0000-0000-000100002122",
				"chemicalName": "3-hydroxyhippurate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039600",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4468,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.401981138170313",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002126",
				"chemicalId": "00000000-0000-0000-0000-000100002126",
				"chemicalName": "16a-hydroxy DHEA 3-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038168",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.567369546280888",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002128",
				"chemicalId": "00000000-0000-0000-0000-000100002128",
				"chemicalName": "5-pregnen-3b, 17-diol-20-one 3-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037482",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3247,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.326503368143692",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002129",
				"chemicalId": "00000000-0000-0000-0000-000100002129",
				"chemicalName": "pregnenolone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038170",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3243,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.608170243886464",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002135",
				"chemicalId": "00000000-0000-0000-0000-000100002135",
				"chemicalName": "5(?)hydroxy-6E,8Z,11Z,14Z,17Z-eicosapentaenoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046297",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2128,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.796462411653275",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002137",
				"chemicalId": "00000000-0000-0000-0000-000100002137",
				"chemicalName": "5-HETE",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037372",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2135,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.381829730411948",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002152",
				"chemicalId": "00000000-0000-0000-0000-000100002152",
				"chemicalName": "andro steroid monosulfate 1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032827",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3393,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.718224095578028",
						"qValue": "0.634928810485782",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002167",
				"chemicalId": "00000000-0000-0000-0000-000100002167",
				"chemicalName": "12-HETE",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037536",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2141,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.46767738492718",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002185",
				"chemicalId": "00000000-0000-0000-0000-000100002185",
				"chemicalName": "indole-3-carboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038116",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 316,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.484815568387099",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002196",
				"chemicalId": "00000000-0000-0000-0000-000100002196",
				"chemicalName": "13-HODE + 9-HODE",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037752",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2014,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.518106371130343",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002199",
				"chemicalId": "00000000-0000-0000-0000-000100002199",
				"chemicalName": "tridecenedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062919",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1690,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.320439387530151",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002253",
				"chemicalId": "00000000-0000-0000-0000-000100002253",
				"chemicalName": "cinnamoylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038637",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4853,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.295148976786929",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002344",
				"chemicalId": "00000000-0000-0000-0000-000100002344",
				"chemicalName": "13-methylmyristic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038293",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1637,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.509814606593393",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002356",
				"chemicalId": "00000000-0000-0000-0000-000100002356",
				"chemicalName": "17-methylstearic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038296",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1648,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.653700021176022",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002417",
				"chemicalId": "00000000-0000-0000-0000-000100002417",
				"chemicalName": "2,3-dihydroxyisovalerate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038276",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4772,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.040782555139723",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002488",
				"chemicalId": "00000000-0000-0000-0000-000100002488",
				"chemicalName": "isoursodeoxycholate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057577",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3509,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.450471139393538",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002528",
				"chemicalId": "00000000-0000-0000-0000-000100002528",
				"chemicalName": "sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046960",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6086,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.614125468052072",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002613",
				"chemicalId": "00000000-0000-0000-0000-000100002613",
				"chemicalName": "(R)-pantoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063061",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pantothenate and CoA Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4335,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.169612604930546",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002749",
				"chemicalId": "00000000-0000-0000-0000-000100002749",
				"chemicalName": "methylcysteine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039592",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 457,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.617381373319478",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002761",
				"chemicalId": "00000000-0000-0000-0000-000100002761",
				"chemicalName": "androsterone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061846",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3340,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.552631101413169",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002796",
				"chemicalId": "00000000-0000-0000-0000-000100002796",
				"chemicalName": "cis-4-decenoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062873",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1495,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.249291336700093",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002813",
				"chemicalId": "00000000-0000-0000-0000-000100002813",
				"chemicalName": "leukotriene B5",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046342",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Eicosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.729319122415556",
						"qValue": "0.639144588093757",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002910",
				"chemicalId": "00000000-0000-0000-0000-000100002910",
				"chemicalName": "1-methyl-5-imidazoleacetate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062946",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 92,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0846333640328635",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002911",
				"chemicalId": "00000000-0000-0000-0000-000100002911",
				"chemicalName": "glycoursodeoxycholate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039379",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3514,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.508782656952711",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002912",
				"chemicalId": "00000000-0000-0000-0000-000100002912",
				"chemicalName": "tauroursodeoxycholate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039378",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3518,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.308183736784346",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002914",
				"chemicalId": "00000000-0000-0000-0000-000100002914",
				"chemicalName": "desaminotyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039587",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4573,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.503848691268942",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002945",
				"chemicalId": "00000000-0000-0000-0000-000100002945",
				"chemicalName": "methyl palmitate (15 or 2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038768",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1642,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.916924507605692",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002951",
				"chemicalId": "00000000-0000-0000-0000-000100002951",
				"chemicalName": "eicosanodioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039831",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1702,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.64565778069958",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002952",
				"chemicalId": "00000000-0000-0000-0000-000100002952",
				"chemicalName": "docosadioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039837",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1704,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.649434972328779",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100002953",
				"chemicalId": "00000000-0000-0000-0000-000100002953",
				"chemicalName": "omega-hydroxypalmitate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039609",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2010,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.645432745819451",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003169",
				"chemicalId": "00000000-0000-0000-0000-000100003169",
				"chemicalName": "ile-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040008",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 853,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.972284616627557",
						"qValue": "0.685563253807449",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003239",
				"chemicalId": "00000000-0000-0000-0000-000100003239",
				"chemicalName": "N-palmitoyl taurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039835",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2174,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.775521355342871",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003240",
				"chemicalId": "00000000-0000-0000-0000-000100003240",
				"chemicalName": "stearoyl taurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039730",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2173,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.582649123351614",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003271",
				"chemicalId": "00000000-0000-0000-0000-000100003271",
				"chemicalName": "beta-citrylglutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054923",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 56,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.577125208059912",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003432",
				"chemicalId": "00000000-0000-0000-0000-000100003432",
				"chemicalName": "dihydroferulic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040481",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4879,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0392522206769217",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003542",
				"chemicalId": "00000000-0000-0000-0000-000100003542",
				"chemicalName": "his-ala",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042027",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 834,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.522233970779967",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003640",
				"chemicalId": "00000000-0000-0000-0000-000100003640",
				"chemicalName": "val-gln",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042079",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1033,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.439005496279577",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003641",
				"chemicalId": "00000000-0000-0000-0000-000100003641",
				"chemicalName": "val-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040475",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1034,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.954325570837319",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003686",
				"chemicalId": "00000000-0000-0000-0000-000100003686",
				"chemicalName": "N-palmitoyl glycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042092",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1823,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.603074718167571",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003696",
				"chemicalId": "00000000-0000-0000-0000-000100003696",
				"chemicalName": "succinimide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041888",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6199,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.625482083995865",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100003901",
				"chemicalId": "00000000-0000-0000-0000-000100003901",
				"chemicalName": "2-stearoylglycerophosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041220",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2670,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.858386032312312",
						"qValue": "0.658656974254217",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004083",
				"chemicalId": "00000000-0000-0000-0000-000100004083",
				"chemicalName": "glycohyocholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042574",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3526,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.444213694913194",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004089",
				"chemicalId": "00000000-0000-0000-0000-000100004089",
				"chemicalName": "2-hydroxydecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042489",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1962,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.336522939848472",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004111",
				"chemicalId": "00000000-0000-0000-0000-000100004111",
				"chemicalName": "2-hydroxy-4-methylphenyl hydrogen sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046146",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.457508239073131",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004112",
				"chemicalId": "00000000-0000-0000-0000-000100004112",
				"chemicalName": "2-hydroxy-3-methylphenyl hydrogen sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046165",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4502,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.383098887963213",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004182",
				"chemicalId": "00000000-0000-0000-0000-000100004182",
				"chemicalName": "3b-hydroxy-5-cholenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043507",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3539,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.835454653234734",
						"qValue": "0.650996591259171",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004208",
				"chemicalId": "00000000-0000-0000-0000-000100004208",
				"chemicalName": "O-methylcatechol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046111",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4501,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.508611747032782",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004243",
				"chemicalId": "00000000-0000-0000-0000-000100004243",
				"chemicalName": "gamma-CEHC glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042381",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4369,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.777788724894919",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004322",
				"chemicalId": "00000000-0000-0000-0000-000100004322",
				"chemicalName": "2-aminophenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043266",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5250,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.368526346612697",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004327",
				"chemicalId": "00000000-0000-0000-0000-000100004327",
				"chemicalName": "1-stearoylglycerophosphoserine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045966",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2702,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.340821291012601",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004414",
				"chemicalId": "00000000-0000-0000-0000-000100004414",
				"chemicalName": "2-Hydroxyphytanic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061702",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1654,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.992480944464332",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004442",
				"chemicalId": "00000000-0000-0000-0000-000100004442",
				"chemicalName": "1-arachidonoyl LPA",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046325",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2592,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.215678962994793",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004499",
				"chemicalId": "00000000-0000-0000-0000-000100004499",
				"chemicalName": "6-oxopiperidine-2-carboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043231",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 133,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.455944541885001",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004541",
				"chemicalId": "00000000-0000-0000-0000-000100004541",
				"chemicalName": "acisoga",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043258",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 551,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.907835020330136",
						"qValue": "0.674415405312238",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004561",
				"chemicalId": "00000000-0000-0000-0000-000100004561",
				"chemicalName": "N-formylanthranilic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043549",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 274,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.127443055029224",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004575",
				"chemicalId": "00000000-0000-0000-0000-000100004575",
				"chemicalName": "N2,N5-diacetylornithine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043591",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 510,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0194887197034808",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100004601",
				"chemicalId": "00000000-0000-0000-0000-000100004601",
				"chemicalName": "1H-indole-7-acetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057783",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Bacterial/Fungal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.494487883786421",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005367",
				"chemicalId": "00000000-0000-0000-0000-000100005367",
				"chemicalName": "N-acetyl alliin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045404",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4817,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.345019853771296",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005384",
				"chemicalId": "00000000-0000-0000-0000-000100005384",
				"chemicalName": "O-sulfo-L-tyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045413",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.387447661883296",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005389",
				"chemicalId": "00000000-0000-0000-0000-000100005389",
				"chemicalName": "3-(3-methoxy-4-(sulfooxy)phenyl)-2-propenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047114",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4901,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.309544260481532",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005391",
				"chemicalId": "00000000-0000-0000-0000-000100005391",
				"chemicalName": "3-[3-(sulfooxy)phenyl]propanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045415",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4568,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0702662238755315",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005396",
				"chemicalId": "00000000-0000-0000-0000-000100005396",
				"chemicalName": "5alpha-androstan-3alpha, 17beta-diol-17-glucosiduronate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047132",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3381,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.641898204132558",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005402",
				"chemicalId": "00000000-0000-0000-0000-000100005402",
				"chemicalName": "11-ketoetiocholanolone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047135",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3336,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.576486984885636",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005403",
				"chemicalId": "00000000-0000-0000-0000-000100005403",
				"chemicalName": "etiocholanolone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047112",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3353,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.854555045220237",
						"qValue": "0.657626917466409",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005418",
				"chemicalId": "00000000-0000-0000-0000-000100005418",
				"chemicalName": "17alpha-hydroxypregnanolone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047403",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3248,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.829485678417941",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005717",
				"chemicalId": "00000000-0000-0000-0000-000100005717",
				"chemicalName": "1-palmitoylglycerophosphoglycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045970",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2708,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.993820404691134",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005834",
				"chemicalId": "00000000-0000-0000-0000-000100005834",
				"chemicalName": "9-hydroxyoctadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047120",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2002,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.664162376094512",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100005864",
				"chemicalId": "00000000-0000-0000-0000-000100005864",
				"chemicalName": "methyl glucopyranoside (alpha + beta)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046144",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.785362747065895",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006005",
				"chemicalId": "00000000-0000-0000-0000-000100006005",
				"chemicalName": "5alpha-androstan-3alpha,17beta-diol monosulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037185",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3379,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.363271577985301",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006056",
				"chemicalId": "00000000-0000-0000-0000-000100006056",
				"chemicalName": "N-formyl-phenylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048433",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 227,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.537825585739028",
						"qValue": "0.617535982536699",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006082",
				"chemicalId": "00000000-0000-0000-0000-000100006082",
				"chemicalName": "2,4,5-trichloro-6-hydroxyisophthalonitrile",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048441",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6213,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.518118548939578",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006092",
				"chemicalId": "00000000-0000-0000-0000-000100006092",
				"chemicalName": "Phenol, 4-(2-aminoethyl)-, 1-(hydrogen sulfate)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048408",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 226,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.957834431220749",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006098",
				"chemicalId": "00000000-0000-0000-0000-000100006098",
				"chemicalName": "3-Pyridinol, 3-(hydrogen sulfate)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048448",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6221,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.303327907402327",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006116",
				"chemicalId": "00000000-0000-0000-0000-000100006116",
				"chemicalName": "4-sulfooxy-methylbenzoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048429",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4544,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.242970159672725",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006121",
				"chemicalId": "00000000-0000-0000-0000-000100006121",
				"chemicalName": "1-dihomo-linolenyl-glycerol (alpha, gamma)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048341",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2861,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.19992647103765",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006125",
				"chemicalId": "00000000-0000-0000-0000-000100006125",
				"chemicalName": "vanillic alcohol monosulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048733",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 228,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.990851622189085",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006126",
				"chemicalId": "00000000-0000-0000-0000-000100006126",
				"chemicalName": "Sulfated 4-vinylguaiacol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048442",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5116,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.597910383774183",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006171",
				"chemicalId": "00000000-0000-0000-0000-000100006171",
				"chemicalName": "Phenol, 2-methoxy-4-(2-propen-1-yl)-, 1-(hydrogen sulfate)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048715",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5122,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.196131239278529",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006173",
				"chemicalId": "00000000-0000-0000-0000-000100006173",
				"chemicalName": "pregnanolone/allopregnanolone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048394",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Progestin Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3288,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0913305336207244",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006190",
				"chemicalId": "00000000-0000-0000-0000-000100006190",
				"chemicalName": "N-acetyl-o-aminophenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048580",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5249,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.165512612006211",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006191",
				"chemicalId": "00000000-0000-0000-0000-000100006191",
				"chemicalName": "p-cresol-glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048841",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 225,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.727486217729949",
						"qValue": "0.639122253664276",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006203",
				"chemicalId": "00000000-0000-0000-0000-000100006203",
				"chemicalName": "acesulfame",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048569",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5043,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.192846406677841",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006260",
				"chemicalId": "00000000-0000-0000-0000-000100006260",
				"chemicalName": "6-sulfooxyindole",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048698",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6235,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.434298684168985",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006262",
				"chemicalId": "00000000-0000-0000-0000-000100006262",
				"chemicalName": "Phenol, 4-methoxy-, 1-(hydrogen sulfate)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048457",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 193,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.871127704548899",
						"qValue": "0.662451255062179",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006271",
				"chemicalId": "00000000-0000-0000-0000-000100006271",
				"chemicalName": "Ethyl paraben sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048463",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6230,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.410846995767733",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006282",
				"chemicalId": "00000000-0000-0000-0000-000100006282",
				"chemicalName": "Sulfated Umbelliferone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048674",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.552154022550811",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006361",
				"chemicalId": "00000000-0000-0000-0000-000100006361",
				"chemicalName": "dopamine sulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048407",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.488712153283605",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006367",
				"chemicalId": "00000000-0000-0000-0000-000100006367",
				"chemicalName": "3-hydroxyhexanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053230",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1979,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.269087191149051",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006370",
				"chemicalId": "00000000-0000-0000-0000-000100006370",
				"chemicalName": "3-hydroxy-5-cholestenoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054805",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3209,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.758652322956269",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006374",
				"chemicalId": "00000000-0000-0000-0000-000100006374",
				"chemicalName": "1,2,3-benzenetriol sulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048762",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6218,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.850684997278868",
						"qValue": "0.657221370159875",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006375",
				"chemicalId": "00000000-0000-0000-0000-000100006375",
				"chemicalName": "3-methoxycatechol sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048763",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4541,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.330064788763294",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006379",
				"chemicalId": "00000000-0000-0000-0000-000100006379",
				"chemicalName": "mannosyltryptophan",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048782",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 261,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.638012146931179",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006641",
				"chemicalId": "00000000-0000-0000-0000-000100006641",
				"chemicalName": "glycochenodeoxycholic acid sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052974",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3474,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.344784455322015",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006642",
				"chemicalId": "00000000-0000-0000-0000-000100006642",
				"chemicalName": "glycodeoxycholic acid sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052975",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3540,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.698030312766592",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006644",
				"chemicalId": "00000000-0000-0000-0000-000100006644",
				"chemicalName": "taurodeoxycholic acid sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062889",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3490,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.527955321264705",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006651",
				"chemicalId": "00000000-0000-0000-0000-000100006651",
				"chemicalName": "3,4-methylene heptanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053026",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5151,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.648749809950363",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100006726",
				"chemicalId": "00000000-0000-0000-0000-000100006726",
				"chemicalName": "linoleoyl ethanolamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052608",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2182,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.256835042237166",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100008929",
				"chemicalId": "00000000-0000-0000-0000-000100008929",
				"chemicalName": "2-methylcitrate/homocitrate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052282",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1460,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.357075767918251",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100008930",
				"chemicalId": "00000000-0000-0000-0000-000100008930",
				"chemicalName": "oleate/vaccenate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052285",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Monounsaturated Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1533,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.84495523252393",
						"qValue": "0.655928243923531",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100008933",
				"chemicalId": "00000000-0000-0000-0000-000100008933",
				"chemicalName": "3-hydroxyoctadecanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052938",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1989,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.242161796810656",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100008952",
				"chemicalId": "00000000-0000-0000-0000-000100008952",
				"chemicalName": "1-palmitoleoylglycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052431",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Monoacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2852,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.661407620183541",
						"qValue": "0.626186416222256",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009042",
				"chemicalId": "00000000-0000-0000-0000-000100009042",
				"chemicalName": "5-hydroxyindole sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052914",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 320,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.654435334635265",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009045",
				"chemicalId": "00000000-0000-0000-0000-000100009045",
				"chemicalName": "phenylacetylglutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052925",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1186,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.507696944325375",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009082",
				"chemicalId": "00000000-0000-0000-0000-000100009082",
				"chemicalName": "1-linoleoyl-glycero-3-phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052690",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2587,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.231042020413743",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009227",
				"chemicalId": "00000000-0000-0000-0000-000100009227",
				"chemicalName": "1-linoleoyl-glycero-3-phosphoglycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054885",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2715,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.454843370511481",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009264",
				"chemicalId": "00000000-0000-0000-0000-000100009264",
				"chemicalName": "glycochenodeoxycholic acid glucuronide (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052983",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3472,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.461920966126364",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009278",
				"chemicalId": "00000000-0000-0000-0000-000100009278",
				"chemicalName": "14-HDoHE/17-HDoHE",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053034",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Docosanoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2066,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.629193286722532",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100009394",
				"chemicalId": "00000000-0000-0000-0000-000100009394",
				"chemicalName": "7Z,10Z-hexadecadienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057652",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1566,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.591925660866374",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100010850",
				"chemicalId": "00000000-0000-0000-0000-000100010850",
				"chemicalName": "4-hydroxyphenylacetylglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000055017",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1189,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.321812085511996",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100010869",
				"chemicalId": "00000000-0000-0000-0000-000100010869",
				"chemicalName": "2,3-dihydroxy-2-methylbutanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057547",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0473598996916036",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100010896",
				"chemicalId": "00000000-0000-0000-0000-000100010896",
				"chemicalName": "2'-O-methyluridine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057655",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4223,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.694236187256399",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100010955",
				"chemicalId": "00000000-0000-0000-0000-000100010955",
				"chemicalName": "perfluorooctanesulfonic acid (PFOS)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057564",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6190,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.491713936625746",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100015638",
				"chemicalId": "00000000-0000-0000-0000-000100015638",
				"chemicalName": "2-hydroxynervonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061698",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1976,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.694622117613294",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100015640",
				"chemicalId": "00000000-0000-0000-0000-000100015640",
				"chemicalName": "N-palmitoyl-L-serine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057659",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2198,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.180332831398644",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100015762",
				"chemicalId": "00000000-0000-0000-0000-000100015762",
				"chemicalName": "7,13,16-docosatrienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057467",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1574,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.229028505271094",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100015785",
				"chemicalId": "00000000-0000-0000-0000-000100015785",
				"chemicalName": "nisinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057810",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1565,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.76976655905222",
						"qValue": "0.644254381913934",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100015971",
				"chemicalId": "00000000-0000-0000-0000-000100015971",
				"chemicalName": "cortolone glucuronide (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057699",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3326,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.190708305467863",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100016048",
				"chemicalId": "00000000-0000-0000-0000-000100016048",
				"chemicalName": "THC carboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061836",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5900,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.635378794415534",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100016110",
				"chemicalId": "00000000-0000-0000-0000-000100016110",
				"chemicalName": "2-butenoylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061785",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1828,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.561667351518787",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019794",
				"chemicalId": "00000000-0000-0000-0000-000100019794",
				"chemicalName": "hydroxy-CMPF",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062277",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1706,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.572934204493364",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019892",
				"chemicalId": "00000000-0000-0000-0000-000100019892",
				"chemicalName": "2-hydroxyarachidic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061700",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1973,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.411455112463224",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019957",
				"chemicalId": "00000000-0000-0000-0000-000100019957",
				"chemicalName": "N-stearoylserine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061822",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2197,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.746985694430638",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019966",
				"chemicalId": "00000000-0000-0000-0000-000100019966",
				"chemicalName": "3-hydroxyoleate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061843",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1990,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.94338928992837",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019972",
				"chemicalId": "00000000-0000-0000-0000-000100019972",
				"chemicalName": "dodecenedioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061864",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1688,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.18098543935085",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019975",
				"chemicalId": "00000000-0000-0000-0000-000100019975",
				"chemicalName": "hexadecenedioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061862",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1696,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.249937180260342",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019982",
				"chemicalId": "00000000-0000-0000-0000-000100019982",
				"chemicalName": "octadecadienedioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061860",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1700,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0584016229350987",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019984",
				"chemicalId": "00000000-0000-0000-0000-000100019984",
				"chemicalName": "glucuronide of C12H22O4 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061874",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6389,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.376809118624633",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100019990",
				"chemicalId": "00000000-0000-0000-0000-000100019990",
				"chemicalName": "glucuronide of C10H18O2 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061881",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6371,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.474621339165963",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020004",
				"chemicalId": "00000000-0000-0000-0000-000100020004",
				"chemicalName": "3-carboxy-4-methyl-5-pentyl-2-furanpropionic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061871",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1707,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.373614736021062",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020005",
				"chemicalId": "00000000-0000-0000-0000-000100020005",
				"chemicalName": "methylnaphthyl sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061872",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6172,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.610277965915905",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020006",
				"chemicalId": "00000000-0000-0000-0000-000100020006",
				"chemicalName": "methylnaphthyl sulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061873",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6173,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.462273935996941",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020014",
				"chemicalId": "00000000-0000-0000-0000-000100020014",
				"chemicalName": "glucuronide of C10H18O2 (7)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061887",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6377,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.411496568968893",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020015",
				"chemicalId": "00000000-0000-0000-0000-000100020015",
				"chemicalName": "glucuronide of C10H18O2 (8)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061888",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6378,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.473089672416397",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020204",
				"chemicalId": "00000000-0000-0000-0000-000100020204",
				"chemicalName": "N-acetyl-2-aminooctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062059",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Amino",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1756,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.44329287886839",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020207",
				"chemicalId": "00000000-0000-0000-0000-000100020207",
				"chemicalName": "genistein sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062061",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4874,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.674378499121536",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020214",
				"chemicalId": "00000000-0000-0000-0000-000100020214",
				"chemicalName": "glyco-beta-muricholic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062066",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3469,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.521731604228288",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020254",
				"chemicalId": "00000000-0000-0000-0000-000100020254",
				"chemicalName": "glycine conjugate of C10H14O2 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062146",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6418,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.232909803694088",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020259",
				"chemicalId": "00000000-0000-0000-0000-000100020259",
				"chemicalName": "glutamine conjugate of C7H12O2 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063658",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.654071723012045",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020261",
				"chemicalId": "00000000-0000-0000-0000-000100020261",
				"chemicalName": "glutamine conjugate of C6H10O2 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063657",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6399,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.699147265790971",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020343",
				"chemicalId": "00000000-0000-0000-0000-000100020343",
				"chemicalName": "5Z,8Z-tetradecadienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062850",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Long Chain Polyunsaturated Fatty Acid (n3 and n6)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.949814128626729",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020372",
				"chemicalId": "00000000-0000-0000-0000-000100020372",
				"chemicalName": "3-indoleglyoxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063583",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5214,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.626192392955334",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020378",
				"chemicalId": "00000000-0000-0000-0000-000100020378",
				"chemicalName": "2-hydroxysebacic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063357",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1709,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.289081620189709",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020414",
				"chemicalId": "00000000-0000-0000-0000-000100020414",
				"chemicalName": "6-bromotryptophan",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062503",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 327,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.76961779853122",
						"qValue": "0.644254381913934",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020416",
				"chemicalId": "00000000-0000-0000-0000-000100020416",
				"chemicalName": "delta-CEHC",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062287",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4376,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.395576214583992",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020419",
				"chemicalId": "00000000-0000-0000-0000-000100020419",
				"chemicalName": "1-carboxyethylphenylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062566",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 146,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.593706199927712",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020423",
				"chemicalId": "00000000-0000-0000-0000-000100020423",
				"chemicalName": "1-carboxyethylvaline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062562",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 420,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.701714500654671",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020425",
				"chemicalId": "00000000-0000-0000-0000-000100020425",
				"chemicalName": "1-carboxyethylleucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062559",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 340,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.753140188512632",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020427",
				"chemicalId": "00000000-0000-0000-0000-000100020427",
				"chemicalName": "1-carboxyethylisoleucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062558",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 391,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.993671856382281",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020478",
				"chemicalId": "00000000-0000-0000-0000-000100020478",
				"chemicalName": "dodecadienoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062276",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1689,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.620462023227083",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020480",
				"chemicalId": "00000000-0000-0000-0000-000100020480",
				"chemicalName": "gamma-CEHC sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062280",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4379,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.184143192535291",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020483",
				"chemicalId": "00000000-0000-0000-0000-000100020483",
				"chemicalName": "delta-CEHC glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062286",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4377,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.650763062927906",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020491",
				"chemicalId": "00000000-0000-0000-0000-000100020491",
				"chemicalName": "glucuronide of piperine metabolite C17H21NO3 (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062290",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5008,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.443729897974673",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020492",
				"chemicalId": "00000000-0000-0000-0000-000100020492",
				"chemicalName": "glucuronide of piperine metabolite C17H21NO3 (4)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062291",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5009,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.348682081034807",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020493",
				"chemicalId": "00000000-0000-0000-0000-000100020493",
				"chemicalName": "glucuronide of piperine metabolite C17H21NO3 (5)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062292",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5010,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.480193806523782",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020496",
				"chemicalId": "00000000-0000-0000-0000-000100020496",
				"chemicalName": "sulfate of piperine metabolite C16H19NO3 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062295",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5017,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.577353211407231",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020497",
				"chemicalId": "00000000-0000-0000-0000-000100020497",
				"chemicalName": "sulfate of piperine metabolite C16H19NO3 (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062296",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5018,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.265962449284145",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020502",
				"chemicalId": "00000000-0000-0000-0000-000100020502",
				"chemicalName": "sulfate of piperine metabolite C18H21NO3 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062297",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5023,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.58749566823472",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020504",
				"chemicalId": "00000000-0000-0000-0000-000100020504",
				"chemicalName": "sulfate of piperine metabolite C18H21NO3 (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062299",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5025,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.331793311950059",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020511",
				"chemicalId": "00000000-0000-0000-0000-000100020511",
				"chemicalName": "5-hydroxyindole glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063253",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 324,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.518846015967185",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020514",
				"chemicalId": "00000000-0000-0000-0000-000100020514",
				"chemicalName": "N-acetyltheanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062711",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5075,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.977179924193516",
						"qValue": "0.686960245625377",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020515",
				"chemicalId": "00000000-0000-0000-0000-000100020515",
				"chemicalName": "2-naphthol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062520",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6175,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.747251260328538",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020519",
				"chemicalId": "00000000-0000-0000-0000-000100020519",
				"chemicalName": "2,5-dimethylphenol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062533",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5202,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.210182009722224",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020536",
				"chemicalId": "00000000-0000-0000-0000-000100020536",
				"chemicalName": "4-ethylcatechol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063072",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4504,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.368480547539495",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020541",
				"chemicalId": "00000000-0000-0000-0000-000100020541",
				"chemicalName": "11beta-hydroxyandrosterone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063251",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3401,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.764495986562942",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020542",
				"chemicalId": "00000000-0000-0000-0000-000100020542",
				"chemicalName": "11beta-hydroxyetiocholanolone glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064418",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Androgenic Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3357,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.922616565077191",
						"qValue": "0.677394753951544",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020549",
				"chemicalId": "00000000-0000-0000-0000-000100020549",
				"chemicalName": "cholic acid glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062571",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Primary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3471,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.772938099488778",
						"qValue": "0.644821124353664",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020550",
				"chemicalId": "00000000-0000-0000-0000-000100020550",
				"chemicalName": "deoxycholic acid glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063610",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3484,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.417926271893949",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020578",
				"chemicalId": "00000000-0000-0000-0000-000100020578",
				"chemicalName": "4-allylcatechol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063597",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4506,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.430859336988487",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020585",
				"chemicalId": "00000000-0000-0000-0000-000100020585",
				"chemicalName": "2-hydroxyfluorene sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062528",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tobacco Metabolite",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4624,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.531996858715394",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020819",
				"chemicalId": "00000000-0000-0000-0000-000100020819",
				"chemicalName": "glycoursodeoxycholic acid sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063731",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3517,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.390461751243269",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020823",
				"chemicalId": "00000000-0000-0000-0000-000100020823",
				"chemicalName": "lithocholic acid sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062526",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3497,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.24021235317126",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020833",
				"chemicalId": "00000000-0000-0000-0000-000100020833",
				"chemicalName": "2-keto-n-caprylate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063120",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 348,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.944456070178531",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020837",
				"chemicalId": "00000000-0000-0000-0000-000100020837",
				"chemicalName": "2,6-dihydroxybenzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063042",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5961,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.252219145874823",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020851",
				"chemicalId": "00000000-0000-0000-0000-000100020851",
				"chemicalName": "3-ethylcatechol sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062796",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4897,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.162338753871617",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020852",
				"chemicalId": "00000000-0000-0000-0000-000100020852",
				"chemicalName": "3-ethylcatechol sulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062797",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4898,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.682888902355481",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020853",
				"chemicalId": "00000000-0000-0000-0000-000100020853",
				"chemicalName": "4-acetylcatechol sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062798",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4812,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.892428103796619",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020893",
				"chemicalId": "00000000-0000-0000-0000-000100020893",
				"chemicalName": "2,3-dihydroxy-5-methylthio-4-pentenoate (DMTPA)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062805",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 448,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.664887257047335",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020902",
				"chemicalId": "00000000-0000-0000-0000-000100020902",
				"chemicalName": "Fibrinopeptide A (2-15)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062809",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1158,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.811389829811275",
						"qValue": "0.647665685371287",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020903",
				"chemicalId": "00000000-0000-0000-0000-000100020903",
				"chemicalName": "Fibrinopeptide A (3-16)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062811",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1161,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.538756325493532",
						"qValue": "0.617535982536699",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020905",
				"chemicalId": "00000000-0000-0000-0000-000100020905",
				"chemicalName": "Fibrinopeptide A (8-16)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062812",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1164,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.407284119094123",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020908",
				"chemicalId": "00000000-0000-0000-0000-000100020908",
				"chemicalName": "Fibrinopeptide B (1-13)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062813",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1180,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.530940183498188",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020973",
				"chemicalId": "00000000-0000-0000-0000-000100020973",
				"chemicalName": "5-hydroxy-2-methylpyridine sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063230",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6252,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.45557494568137",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100020975",
				"chemicalId": "00000000-0000-0000-0000-000100020975",
				"chemicalName": "3-hydroxy-2-methylpyridine sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063227",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6254,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.301751490358936",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021015",
				"chemicalId": "00000000-0000-0000-0000-000100021015",
				"chemicalName": "branched-chain fatty acid 18:0 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062874",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1647,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.409636223100177",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021093",
				"chemicalId": "00000000-0000-0000-0000-000100021093",
				"chemicalName": "taurochenodeoxycholic acid 3-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062890",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3541,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.81515201894232",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021100",
				"chemicalId": "00000000-0000-0000-0000-000100021100",
				"chemicalName": "tetradecadienedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062918",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1694,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.269153858576374",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021103",
				"chemicalId": "00000000-0000-0000-0000-000100021103",
				"chemicalName": "pregnenetriol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062922",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3261,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.291357302925125",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021104",
				"chemicalId": "00000000-0000-0000-0000-000100021104",
				"chemicalName": "pregnenetriol disulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062921",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pregnenolone Steroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3262,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.430424953413315",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021105",
				"chemicalId": "00000000-0000-0000-0000-000100021105",
				"chemicalName": "eicosenedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062923",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1703,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.999969431244602",
						"qValue": "0.692653497968179",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021208",
				"chemicalId": "00000000-0000-0000-0000-000100021208",
				"chemicalName": "4-vinylcatechol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064579",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4530,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.481848501404371",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021252",
				"chemicalId": "00000000-0000-0000-0000-000100021252",
				"chemicalName": "ginkgolic acid C15:1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064200",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4716,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.135439733184498",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021331",
				"chemicalId": "00000000-0000-0000-0000-000100021331",
				"chemicalName": "metabolonic lactone sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063153",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6422,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.239491217202532",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021363",
				"chemicalId": "00000000-0000-0000-0000-000100021363",
				"chemicalName": "vanillic acid glycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063853",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5218,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.753778044319722",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021374",
				"chemicalId": "00000000-0000-0000-0000-000100021374",
				"chemicalName": "branched chain 14:0 dicarboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063191",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1693,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.235879425794054",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021502",
				"chemicalId": "00000000-0000-0000-0000-000100021502",
				"chemicalName": "2-decenoate (10:1n8)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063436",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Medium Chain Fatty Acid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.46527388950973",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021666",
				"chemicalId": "00000000-0000-0000-0000-000100021666",
				"chemicalName": "branched-chain, straight-chain, or cyclopropyl 10:1 fatty acid (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063615",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6430,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.576978072438065",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021667",
				"chemicalId": "00000000-0000-0000-0000-000100021667",
				"chemicalName": "branched-chain, straight-chain, or cyclopropyl 10:1 fatty acid (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063616",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6431,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.413080014128085",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021668",
				"chemicalId": "00000000-0000-0000-0000-000100021668",
				"chemicalName": "branched-chain, straight-chain, or cyclopropyl 10:1 fatty acid (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063617",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6432,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.700815792650703",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021707",
				"chemicalId": "00000000-0000-0000-0000-000100021707",
				"chemicalName": "3-bromo-5-chloro-2,6-dihydroxybenzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063681",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6260,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.410007236787008",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021709",
				"chemicalId": "00000000-0000-0000-0000-000100021709",
				"chemicalName": "branched-chain, straight-chain, or cyclopropyl 12:1 fatty acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063683",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6433,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.746224245402676",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021711",
				"chemicalId": "00000000-0000-0000-0000-000100021711",
				"chemicalName": "decadienedioic acid (C10:2-DC)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063685",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1682,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.14484848011143",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021712",
				"chemicalId": "00000000-0000-0000-0000-000100021712",
				"chemicalName": "deoxycholic acid (12 or 24)-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063688",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3482,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.457687172567271",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021726",
				"chemicalId": "00000000-0000-0000-0000-000100021726",
				"chemicalName": "GlcNAc sulfate conjugate of C21H34O2 steroid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063699",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6435,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.519050273070756",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021727",
				"chemicalId": "00000000-0000-0000-0000-000100021727",
				"chemicalName": "cis-3,4-methylene-heptanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064390",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Branched",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1643,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.797140256954158",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021728",
				"chemicalId": "00000000-0000-0000-0000-000100021728",
				"chemicalName": "cis-3,4-methylene-heptanoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064389",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1856,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.213904574240749",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021784",
				"chemicalId": "00000000-0000-0000-0000-000100021784",
				"chemicalName": "4-chlorobenzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063988",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6266,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.40459136513261",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021839",
				"chemicalId": "00000000-0000-0000-0000-000100021839",
				"chemicalName": "2-methoxyhydroquinone sulfate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064049",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4584,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.438406724906105",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100021918",
				"chemicalId": "00000000-0000-0000-0000-000100021918",
				"chemicalName": "2,4-di-tert-butylphenol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064328",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6177,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.48830275711902",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022003",
				"chemicalId": "00000000-0000-0000-0000-000100022003",
				"chemicalName": "bilirubin degradation product, C16H18N2O5 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064403",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6443,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.406798024644053",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022004",
				"chemicalId": "00000000-0000-0000-0000-000100022004",
				"chemicalName": "bilirubin degradation product, C16H18N2O5 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064404",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6444,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.816190811573063",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022005",
				"chemicalId": "00000000-0000-0000-0000-000100022005",
				"chemicalName": "bilirubin degradation product, C17H18N2O4 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064409",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6445,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.27028623092691",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022006",
				"chemicalId": "00000000-0000-0000-0000-000100022006",
				"chemicalName": "bilirubin degradation product, C17H18N2O4 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064407",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6446,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.358089584730114",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022007",
				"chemicalId": "00000000-0000-0000-0000-000100022007",
				"chemicalName": "bilirubin degradation product, C17H18N2O4 (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064408",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6447,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.323654525592222",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022008",
				"chemicalId": "00000000-0000-0000-0000-000100022008",
				"chemicalName": "bilirubin degradation product, C17H20N2O5 (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064405",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6448,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.26605620475322",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022009",
				"chemicalId": "00000000-0000-0000-0000-000100022009",
				"chemicalName": "bilirubin degradation product, C17H20N2O5 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064406",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6449,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.50117207575484",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022013",
				"chemicalId": "00000000-0000-0000-0000-000100022013",
				"chemicalName": "urocortisol glucuronide (4)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064411",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3310,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.42884151054478",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022014",
				"chemicalId": "00000000-0000-0000-0000-000100022014",
				"chemicalName": "bilirubin degradation product, C16H18N2O5 (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064414",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6450,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.682057623688503",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022015",
				"chemicalId": "00000000-0000-0000-0000-000100022015",
				"chemicalName": "bilirubin degradation product, C16H18N2O5 (4)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064415",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6451,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.851240151269665",
						"qValue": "0.657221370159875",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022029",
				"chemicalId": "00000000-0000-0000-0000-000100022029",
				"chemicalName": "propylene azelate (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064434",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6073,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.751184349037839",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-209f-0000-0000-000100022127",
				"chemicalId": "00000000-0000-0000-0000-000100022127",
				"chemicalName": "tetrahydrocortisone glucuronide (5)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064591",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Corticosteroids",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3323,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.395872877949864",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000093",
				"chemicalId": "00000000-0000-0000-0000-000000000093",
				"chemicalName": "2-oxopentanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000528",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1445,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.311755869435765",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000112",
				"chemicalId": "00000000-0000-0000-0000-000000000112",
				"chemicalName": "3-hydroxy-3-methylglutarate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000531",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Mevalonate Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3175,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.61493690883396",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000182",
				"chemicalId": "00000000-0000-0000-0000-000000000182",
				"chemicalName": "quinolinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001899",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4300,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.512122152112922",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000254",
				"chemicalId": "00000000-0000-0000-0000-000000000254",
				"chemicalName": "3-hydroxybutanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000542",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ketone Bodies",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1938,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.264295062156252",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000330",
				"chemicalId": "00000000-0000-0000-0000-000000000330",
				"chemicalName": "fumaric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001643",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1449,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.323908073159122",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000338",
				"chemicalId": "00000000-0000-0000-0000-000000000338",
				"chemicalName": "gluconic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000587",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4805,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.751236103088313",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000339",
				"chemicalId": "00000000-0000-0000-0000-000000000339",
				"chemicalName": "glutarate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000396",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1659,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.288334892731055",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000344",
				"chemicalId": "00000000-0000-0000-0000-000000000344",
				"chemicalName": "guanidineacetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043802",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 527,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.310330923034184",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000358",
				"chemicalId": "00000000-0000-0000-0000-000000000358",
				"chemicalName": "hypotaurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000590",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 470,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0173505554486136",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000363",
				"chemicalId": "00000000-0000-0000-0000-000000000363",
				"chemicalName": "inositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001124",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2201,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.442066079462585",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000381",
				"chemicalId": "00000000-0000-0000-0000-000000000381",
				"chemicalName": "L-2-aminoadipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000006146",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 123,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.00483701141706264",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000382",
				"chemicalId": "00000000-0000-0000-0000-000000000382",
				"chemicalName": "3-sulfo-L-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047089",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 481,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.889291061543382",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000418",
				"chemicalId": "00000000-0000-0000-0000-000000000418",
				"chemicalName": "methylmalonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001496",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1774,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.375291459074482",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000445",
				"chemicalId": "00000000-0000-0000-0000-000000000445",
				"chemicalName": "orotic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001505",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4207,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.219604088897043",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000482",
				"chemicalId": "00000000-0000-0000-0000-000000000482",
				"chemicalName": "2-hydroxypropanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000527",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1240,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.389818651750129",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000501",
				"chemicalId": "00000000-0000-0000-0000-000000000501",
				"chemicalName": "salicylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001515",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Topical Agents",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5959,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.394187819028878",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000572",
				"chemicalId": "00000000-0000-0000-0000-000000000572",
				"chemicalName": "glucose, formate adduct",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048152",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1223,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.165395046042934",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000796",
				"chemicalId": "00000000-0000-0000-0000-000000000796",
				"chemicalName": "alpha-ketobutyrate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000004968",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 454,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.715271115967775",
						"qValue": "0.634698418654095",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000803",
				"chemicalId": "00000000-0000-0000-0000-000000000803",
				"chemicalName": "D-mannose, formate adduct",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048153",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1352,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.181099466347623",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000818",
				"chemicalId": "00000000-0000-0000-0000-000000000818",
				"chemicalName": "malonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015872",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1472,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.462566133822827",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000823",
				"chemicalId": "00000000-0000-0000-0000-000000000823",
				"chemicalName": "pyruvate-2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048990",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1239,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.252883204524482",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000825",
				"chemicalId": "00000000-0000-0000-0000-000000000825",
				"chemicalName": "uracil",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000605",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4220,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.23447342577004",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000828",
				"chemicalId": "00000000-0000-0000-0000-000000000828",
				"chemicalName": "D-arabinose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000575",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1269,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.794490994423936",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000878",
				"chemicalId": "00000000-0000-0000-0000-000000000878",
				"chemicalName": "D-fructose, formate adduct",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048195",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1346,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.51062031369699",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000000914",
				"chemicalId": "00000000-0000-0000-0000-000000000914",
				"chemicalName": "D-ribose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001471",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1261,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.573671006453245",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001002",
				"chemicalId": "00000000-0000-0000-0000-000000001002",
				"chemicalName": "allantoin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001107",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4133,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.247408289651277",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001003",
				"chemicalId": "00000000-0000-0000-0000-000000001003",
				"chemicalName": "dulcitol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001117",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1362,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.300343930280268",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001004",
				"chemicalId": "00000000-0000-0000-0000-000000001004",
				"chemicalName": "xanthine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000003147",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4121,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.248138186379073",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001052",
				"chemicalId": "00000000-0000-0000-0000-000000001052",
				"chemicalName": "glyceric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001572",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1243,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.463910003036796",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001108",
				"chemicalId": "00000000-0000-0000-0000-000000001108",
				"chemicalName": "N-carbamoyl-L-aspartate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001594",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4205,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.405628782720019",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001110",
				"chemicalId": "00000000-0000-0000-0000-000000001110",
				"chemicalName": "N-acetyl-L-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001585",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 31,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.176197591877663",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001113",
				"chemicalId": "00000000-0000-0000-0000-000000001113",
				"chemicalName": "4-acetamidobutyric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001558",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 560,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.75010834954641",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001128",
				"chemicalId": "00000000-0000-0000-0000-000000001128",
				"chemicalName": "L-alpha-amino-n-butyric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042374",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 580,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.293472110878331",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000001537",
				"chemicalId": "00000000-0000-0000-0000-000000001537",
				"chemicalName": "1-Palmitoyl-Linoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052460",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2286,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.200483525603995",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000002029",
				"chemicalId": "00000000-0000-0000-0000-000000002029",
				"chemicalName": "azelaic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018362",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1679,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.543567806765149",
						"qValue": "0.619915874328736",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000002051",
				"chemicalId": "00000000-0000-0000-0000-000000002051",
				"chemicalName": "methylsuccinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015745",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 410,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.673525216916908",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000000002054",
				"chemicalId": "00000000-0000-0000-0000-000000002054",
				"chemicalName": "ethylmalonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015765",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 407,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.333441858679278",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000101",
				"chemicalId": "00000000-0000-0000-0000-000100000101",
				"chemicalName": "heptanedioic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015704",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1673,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.848852382927894",
						"qValue": "0.657221370159875",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000257",
				"chemicalId": "00000000-0000-0000-0000-000100000257",
				"chemicalName": "D-Glucuronic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015443",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1406,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.149947632411502",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000258",
				"chemicalId": "00000000-0000-0000-0000-000100000258",
				"chemicalName": "sn-glycerol-3-phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043847",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2842,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.272126216888742",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000282",
				"chemicalId": "00000000-0000-0000-0000-000100000282",
				"chemicalName": "N-acetyl-L-glutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015720",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 47,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.512987118122599",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000406",
				"chemicalId": "00000000-0000-0000-0000-000100000406",
				"chemicalName": "adonitol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015772",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1262,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.949806383324949",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000442",
				"chemicalId": "00000000-0000-0000-0000-000100000442",
				"chemicalName": "D(-)-quinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018335",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4905,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.277526366811842",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000447",
				"chemicalId": "00000000-0000-0000-0000-000100000447",
				"chemicalName": "2,5-dihydroxybenzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018280",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.290460694696421",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000707",
				"chemicalId": "00000000-0000-0000-0000-000100000707",
				"chemicalName": "maleic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000020676",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1671,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.760753382554587",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000787",
				"chemicalId": "00000000-0000-0000-0000-000100000787",
				"chemicalName": "n-acetyl-l-aspartic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022185",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 37,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.628744985565673",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000840",
				"chemicalId": "00000000-0000-0000-0000-000100000840",
				"chemicalName": "tartronic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000020693",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5206,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.447008798553572",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100000863",
				"chemicalId": "00000000-0000-0000-0000-000100000863",
				"chemicalName": "adipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021134",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1665,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.360288614579011",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001007",
				"chemicalId": "00000000-0000-0000-0000-000100001007",
				"chemicalName": "ribonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027731",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1263,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.187244376198137",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001022",
				"chemicalId": "00000000-0000-0000-0000-000100001022",
				"chemicalName": "threonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027738",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4353,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.890747760730763",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001026",
				"chemicalId": "00000000-0000-0000-0000-000100001026",
				"chemicalName": "galactonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027719",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1372,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.961312120963777",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001129",
				"chemicalId": "00000000-0000-0000-0000-000100001129",
				"chemicalName": "O-acetyl-L-homoserine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031539",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 23,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.198167520101325",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001145",
				"chemicalId": "00000000-0000-0000-0000-000100001145",
				"chemicalName": "3-hydroxysebacic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031943",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Monohydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1984,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.968509780994194",
						"qValue": "0.684266035105196",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001153",
				"chemicalId": "00000000-0000-0000-0000-000100001153",
				"chemicalName": "2-hydroxyadipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031934",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1667,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.167076013073315",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001211",
				"chemicalId": "00000000-0000-0000-0000-000100001211",
				"chemicalName": "sebacic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032398",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1681,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.995809598490685",
						"qValue": "0.691804814351507",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001257",
				"chemicalId": "00000000-0000-0000-0000-000100001257",
				"chemicalName": "N-Acetyl-L-asparagine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033942",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 42,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.676461721237228",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001300",
				"chemicalId": "00000000-0000-0000-0000-000100001300",
				"chemicalName": "alpha-hydroxyisovaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046537",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 424,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.546469684847421",
						"qValue": "0.621140254805774",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001320",
				"chemicalId": "00000000-0000-0000-0000-000100001320",
				"chemicalName": "erythronic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042420",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1431,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.641751907779846",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001402",
				"chemicalId": "00000000-0000-0000-0000-000100001402",
				"chemicalName": "5-acetylamino-6-formylamino-3-methyluracil",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034401",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4604,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.130234548213322",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001403",
				"chemicalId": "00000000-0000-0000-0000-000100001403",
				"chemicalName": "5-acetylamino-6-amino-3-methyluracil",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034424",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4603,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.153040407572043",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001416",
				"chemicalId": "00000000-0000-0000-0000-000100001416",
				"chemicalName": "orotidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035172",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Orotate containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4209,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.236346865480937",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001511",
				"chemicalId": "00000000-0000-0000-0000-000100001511",
				"chemicalName": "1-palmitoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047117",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2602,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.271781357416075",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001551",
				"chemicalId": "00000000-0000-0000-0000-000100001551",
				"chemicalName": "1-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052479",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2635,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.382608874428259",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001570",
				"chemicalId": "00000000-0000-0000-0000-000100001570",
				"chemicalName": "1-linoleoyl-2-hydroxy-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052485",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2673,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.627796955511033",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001571",
				"chemicalId": "00000000-0000-0000-0000-000100001571",
				"chemicalName": "1-arachidonoyl-2-hydroxy-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052486",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2686,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.507563879428461",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001586",
				"chemicalId": "00000000-0000-0000-0000-000100001586",
				"chemicalName": "gulonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046957",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4357,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.817523800852486",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001606",
				"chemicalId": "00000000-0000-0000-0000-000100001606",
				"chemicalName": "cholesterol sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035324",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3193,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.354217914323833",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001619",
				"chemicalId": "00000000-0000-0000-0000-000100001619",
				"chemicalName": "Glycerophosphoglycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048857",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycerolipid Metabolism ",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2847,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.598199367199696",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001662",
				"chemicalId": "00000000-0000-0000-0000-000100001662",
				"chemicalName": "Trimethylaminobutyrate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052805",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1931,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.33221529120307",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001671",
				"chemicalId": "00000000-0000-0000-0000-000100001671",
				"chemicalName": "1-(9Z-octadecenoyl)-2-hydroxy-sn-glycero-3-phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036812",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2586,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.906754945704526",
						"qValue": "0.674415405312238",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001730",
				"chemicalId": "00000000-0000-0000-0000-000100001730",
				"chemicalName": "3,4-dihydroxybutanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062952",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2037,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.699383225492674",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001740",
				"chemicalId": "00000000-0000-0000-0000-000100001740",
				"chemicalName": "mannitol/sorbitol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046142",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fructose, Mannose and Galactose Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1351,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.282452818916842",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001765",
				"chemicalId": "00000000-0000-0000-0000-000100001765",
				"chemicalName": "3-methyladipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036749",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1669,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.700688740671829",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001851",
				"chemicalId": "00000000-0000-0000-0000-000100001851",
				"chemicalName": "N-acetylserine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037076",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 11,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.427817578553725",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100001859",
				"chemicalId": "00000000-0000-0000-0000-000100001859",
				"chemicalName": "D-chiro-inositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037112",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Inositol Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2202,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": null,
						"qValue": null,
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002284",
				"chemicalId": "00000000-0000-0000-0000-000100002284",
				"chemicalName": "4-deoxythreonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062950",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2033,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.365145935261081",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002367",
				"chemicalId": "00000000-0000-0000-0000-000100002367",
				"chemicalName": "4-deoxyerythronate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063109",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dihydroxy",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2034,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.318008207927991",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002397",
				"chemicalId": "00000000-0000-0000-0000-000100002397",
				"chemicalName": "alpha-ketoglutaramate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062101",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 46,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.274371414943758",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002458",
				"chemicalId": "00000000-0000-0000-0000-000100002458",
				"chemicalName": "3-methylglutaconate, 99 ion",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057747",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 367,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.224311790967147",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002489",
				"chemicalId": "00000000-0000-0000-0000-000100002489",
				"chemicalName": "oxypurinol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041726",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Metabolic",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5802,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.628009083292387",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100002537",
				"chemicalId": "00000000-0000-0000-0000-000100002537",
				"chemicalName": "4-hydroxy-2-oxoglutaric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040062",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1664,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.602394016863983",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100003258",
				"chemicalId": "00000000-0000-0000-0000-000100003258",
				"chemicalName": "2-O-methylascorbic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063990",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4352,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.289529880572015",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100003695",
				"chemicalId": "00000000-0000-0000-0000-000100003695",
				"chemicalName": "D-mannonic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062864",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4959,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.761701584745159",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100004014",
				"chemicalId": "00000000-0000-0000-0000-000100004014",
				"chemicalName": "dexamethasone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042618",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Antiinflammatory, Immunosuppressant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5585,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.736888527068758",
						"qValue": "0.640154325505618",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100004046",
				"chemicalId": "00000000-0000-0000-0000-000100004046",
				"chemicalName": "N-acetylcarnosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043488",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 86,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.238068789624702",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100004396",
				"chemicalId": "00000000-0000-0000-0000-000100004396",
				"chemicalName": "3-Hydroxyadipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062069",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1668,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.814238843001355",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100005466",
				"chemicalId": "00000000-0000-0000-0000-000100005466",
				"chemicalName": "N-acetyltaurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048187",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 473,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.261340025684431",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100005946",
				"chemicalId": "00000000-0000-0000-0000-000100005946",
				"chemicalName": "2-keto-3-deoxy-D-gluconate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048141",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5129,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.803028464406316",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100006115",
				"chemicalId": "00000000-0000-0000-0000-000100006115",
				"chemicalName": "arabonate/xylonate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048255",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1283,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.832751388877435",
						"qValue": "0.650829647970713",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100006129",
				"chemicalId": "00000000-0000-0000-0000-000100006129",
				"chemicalName": "vanillactate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054910",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 201,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.401230025618847",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100006430",
				"chemicalId": "00000000-0000-0000-0000-000100006430",
				"chemicalName": "arabitol/xylitol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048885",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1277,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.266656652128741",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100006438",
				"chemicalId": "00000000-0000-0000-0000-000100006438",
				"chemicalName": "citraconate/glutaconate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047076",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.976390761351849",
						"qValue": "0.686960245625377",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100008928",
				"chemicalId": "00000000-0000-0000-0000-000100008928",
				"chemicalName": "2-hydroxybutyrate/2-hydroxyisobutyrate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052281",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 582,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.253367955518637",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009007",
				"chemicalId": "00000000-0000-0000-0000-000100009007",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-oleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052478",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2806,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.691536156687175",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009009",
				"chemicalId": "00000000-0000-0000-0000-000100009009",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-linoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052682",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2814,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.96414166345369",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009239",
				"chemicalId": "00000000-0000-0000-0000-000100009239",
				"chemicalName": "isocitric lactone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054724",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1444,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.961975687771112",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009266",
				"chemicalId": "00000000-0000-0000-0000-000100009266",
				"chemicalName": "glycodeoxycholic acid glucuronide (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052982",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Secondary Bile Acid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3545,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.955941685302891",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009329",
				"chemicalId": "00000000-0000-0000-0000-000100009329",
				"chemicalName": "ascorbic acid 2-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063590",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4348,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.416860213946092",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100009337",
				"chemicalId": "00000000-0000-0000-0000-000100009337",
				"chemicalName": "caffeic acid sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053254",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5203,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.385928573208066",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100010834",
				"chemicalId": "00000000-0000-0000-0000-000100010834",
				"chemicalName": "3-methylglutarate/2-methylglutarate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054676",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1662,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.963458198018454",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100015923",
				"chemicalId": "00000000-0000-0000-0000-000100015923",
				"chemicalName": "N-oxalyl glycine (NOG)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057776",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4969,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.333555989302916",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100019968",
				"chemicalId": "00000000-0000-0000-0000-000100019968",
				"chemicalName": "ribulonate/xylulonate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061858",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pentose Metabolism ",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1289,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.943211711398423",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100019978",
				"chemicalId": "00000000-0000-0000-0000-000100019978",
				"chemicalName": "octadecenedioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061861",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1699,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.690570456092875",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100019981",
				"chemicalId": "00000000-0000-0000-0000-000100019981",
				"chemicalName": "heptenedioate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061866",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1674,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.773557862910926",
						"qValue": "0.644821124353664",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020208",
				"chemicalId": "00000000-0000-0000-0000-000100020208",
				"chemicalName": "perfluorooctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062071",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6241,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.43731286953403",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020217",
				"chemicalId": "00000000-0000-0000-0000-000100020217",
				"chemicalName": "N,N,N-trimethyl-L-alanyl-L-proline betaine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062947",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 519,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.157742260571419",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020219",
				"chemicalId": "00000000-0000-0000-0000-000100020219",
				"chemicalName": "3-formylindole",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062863",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4785,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.566615061497115",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020272",
				"chemicalId": "00000000-0000-0000-0000-000100020272",
				"chemicalName": "dihydroferulic acid sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062857",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4880,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.591882614074364",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020409",
				"chemicalId": "00000000-0000-0000-0000-000100020409",
				"chemicalName": "ascorbic acid 3-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063687",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ascorbate and Aldarate Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4349,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.119125061537031",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020606",
				"chemicalId": "00000000-0000-0000-0000-000100020606",
				"chemicalName": "vanillate glucuronide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063842",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5217,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.144940103361634",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020822",
				"chemicalId": "00000000-0000-0000-0000-000100020822",
				"chemicalName": "3,4-dihydroxyhydrocinnamate sulfate (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062524",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4834,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.989136916097357",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100020910",
				"chemicalId": "00000000-0000-0000-0000-000100020910",
				"chemicalName": "Fibrinopeptide B (1-12)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062814",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1179,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.916914506006257",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100021198",
				"chemicalId": "00000000-0000-0000-0000-000100021198",
				"chemicalName": "picolinoylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063057",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1837,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.890043291843038",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100021220",
				"chemicalId": "00000000-0000-0000-0000-000100021220",
				"chemicalName": "succinoyltaurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063678",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 476,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0881003661740617",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100021324",
				"chemicalId": "00000000-0000-0000-0000-000100021324",
				"chemicalName": "3,5-dichloro-2,6-dihydroxybenzoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063589",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6259,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.239898931339345",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100021372",
				"chemicalId": "00000000-0000-0000-0000-000100021372",
				"chemicalName": "2-Hydroxy-4-(methylthio)butanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063739",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 449,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.807724108145117",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-305f-0000-0000-000100021467",
				"chemicalId": "00000000-0000-0000-0000-000100021467",
				"chemicalName": "pentoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063264",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6423,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.593708780094278",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000035",
				"chemicalId": "00000000-0000-0000-0000-000000000035",
				"chemicalName": "S-1-pyrroline-5-carboxylate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042370",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 62,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.977054698662615",
						"qValue": "0.686960245625377",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000055",
				"chemicalId": "00000000-0000-0000-0000-000000000055",
				"chemicalName": "1-methylnicotinamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027665",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4316,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.121226305692732",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000111",
				"chemicalId": "00000000-0000-0000-0000-000000000111",
				"chemicalName": "3-hydroxy-2-methylpropanoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001549",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 428,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.230006466381937",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000132",
				"chemicalId": "00000000-0000-0000-0000-000000000132",
				"chemicalName": "3-phospho-d-glycerate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001414",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycolysis, Gluconeogenesis, and Pyruvate Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1237,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.626058249769311",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000189",
				"chemicalId": "00000000-0000-0000-0000-000000000189",
				"chemicalName": "N('6)-trimethyl-l-lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001498",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 116,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.140264353228869",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000192",
				"chemicalId": "00000000-0000-0000-0000-000000000192",
				"chemicalName": "N-acetylputrescine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037496",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 538,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.559821247458173",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000209",
				"chemicalId": "00000000-0000-0000-0000-000000000209",
				"chemicalName": "adenosine 5'-monophosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032342",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4139,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.293076495995472",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000231",
				"chemicalId": "00000000-0000-0000-0000-000000000231",
				"chemicalName": "arginine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001638",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 483,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.221012051780658",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000232",
				"chemicalId": "00000000-0000-0000-0000-000000000232",
				"chemicalName": "arginino-succinate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015497",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 484,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.9613909051104",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000234",
				"chemicalId": "00000000-0000-0000-0000-000000000234",
				"chemicalName": "L-aspartic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000443",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 36,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.181103484478544",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000244",
				"chemicalId": "00000000-0000-0000-0000-000000000244",
				"chemicalName": "beta-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000055",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4239,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.205759953738911",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000249",
				"chemicalId": "00000000-0000-0000-0000-000000000249",
				"chemicalName": "beta-alanyl-L-histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001768",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 84,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.451217373487428",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000252",
				"chemicalId": "00000000-0000-0000-0000-000000000252",
				"chemicalName": "butanedioic acid, ammonia adduct",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052308",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1448,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.581356938970487",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000267",
				"chemicalId": "00000000-0000-0000-0000-000000000267",
				"chemicalName": "choline phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034396",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2233,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.531749466199184",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000275",
				"chemicalId": "00000000-0000-0000-0000-000000000275",
				"chemicalName": "creatinine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000513",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 529,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.155879910936783",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000278",
				"chemicalId": "00000000-0000-0000-0000-000000000278",
				"chemicalName": "cys-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035637",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 576,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.25612996387844",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000279",
				"chemicalId": "00000000-0000-0000-0000-000000000279",
				"chemicalName": "cystine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000056",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 462,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.626487730129415",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000310",
				"chemicalId": "00000000-0000-0000-0000-000000000310",
				"chemicalName": "DL-cystathionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015705",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 453,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.336740217190043",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000331",
				"chemicalId": "00000000-0000-0000-0000-000000000331",
				"chemicalName": "gamma-L-glutamyl-L-glutamic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036738",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 634,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.548048780409027",
						"qValue": "0.621140254805774",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000340",
				"chemicalId": "00000000-0000-0000-0000-000000000340",
				"chemicalName": "glycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000058",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 1,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.21506077710253",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000376",
				"chemicalId": "00000000-0000-0000-0000-000000000376",
				"chemicalName": "isoleucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001125",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 385,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.311271289731242",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000391",
				"chemicalId": "00000000-0000-0000-0000-000000000391",
				"chemicalName": "N(5)-(aminocarbonyl)-L-ornithine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002132",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 492,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0970761355069136",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000397",
				"chemicalId": "00000000-0000-0000-0000-000000000397",
				"chemicalName": "leucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000060",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 332,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.111210007411228",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000407",
				"chemicalId": "00000000-0000-0000-0000-000000000407",
				"chemicalName": "lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001301",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 105,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.208265957354252",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000415",
				"chemicalId": "00000000-0000-0000-0000-000000000415",
				"chemicalName": "L-methionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001302",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 434,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.445144132766479",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000432",
				"chemicalId": "00000000-0000-0000-0000-000000000432",
				"chemicalName": "niacinamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000594",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4304,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.111561482655331",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000444",
				"chemicalId": "00000000-0000-0000-0000-000000000444",
				"chemicalName": "L-ornithine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001493",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 487,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.186275461511364",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000460",
				"chemicalId": "00000000-0000-0000-0000-000000000460",
				"chemicalName": "phenylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000064",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phenylalanine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 140,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.10050702655304",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000461",
				"chemicalId": "00000000-0000-0000-0000-000000000461",
				"chemicalName": "phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042109",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Oxidative Phosphorylation",
				"superPathway": "Energy",
				"pathwaySortOrder": 1468,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.230729644453151",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000480",
				"chemicalId": "00000000-0000-0000-0000-000000000480",
				"chemicalName": "proline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001898",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 495,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.179111108738404",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000491",
				"chemicalId": "00000000-0000-0000-0000-000000000491",
				"chemicalName": "pyridoxal",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001651",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin B6 Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4450,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.929534170902487",
						"qValue": "0.678416860083147",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000503",
				"chemicalId": "00000000-0000-0000-0000-000000000503",
				"chemicalName": "L-serine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001648",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 10,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0766435239024517",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000507",
				"chemicalId": "00000000-0000-0000-0000-000000000507",
				"chemicalName": "spermine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000603",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 552,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.504681993693826",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000512",
				"chemicalId": "00000000-0000-0000-0000-000000000512",
				"chemicalName": "taurine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002125",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 471,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.116512123099547",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000533",
				"chemicalId": "00000000-0000-0000-0000-000000000533",
				"chemicalName": "urea",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001670",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 485,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0808416160909575",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000537",
				"chemicalId": "00000000-0000-0000-0000-000000000537",
				"chemicalName": "urocanic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000607",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 78,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.956646212155618",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000561",
				"chemicalId": "00000000-0000-0000-0000-000000000561",
				"chemicalName": "glutamic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000057",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 44,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.123217077691462",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000563",
				"chemicalId": "00000000-0000-0000-0000-000000000563",
				"chemicalName": "L-glutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000053",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 45,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.123541071985579",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000564",
				"chemicalId": "00000000-0000-0000-0000-000000000564",
				"chemicalName": "L-threonine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001284",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 17,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0730909988460364",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000565",
				"chemicalId": "00000000-0000-0000-0000-000000000565",
				"chemicalName": "L-tryptophan",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000054",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 254,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.40363524873133",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000566",
				"chemicalId": "00000000-0000-0000-0000-000000000566",
				"chemicalName": "L-valine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001649",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 416,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.436354022493481",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000799",
				"chemicalId": "00000000-0000-0000-0000-000000000799",
				"chemicalName": "betaine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000003141",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 7,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.203759611393777",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000800",
				"chemicalId": "00000000-0000-0000-0000-000000000800",
				"chemicalName": "L-cysteine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001868",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 455,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.947042385589952",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000806",
				"chemicalId": "00000000-0000-0000-0000-000000000806",
				"chemicalName": "dimethylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000005086",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 6,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.198215425709244",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000811",
				"chemicalId": "00000000-0000-0000-0000-000000000811",
				"chemicalName": "L-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001126",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 29,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.755392973491947",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000815",
				"chemicalId": "00000000-0000-0000-0000-000000000815",
				"chemicalName": "L-tyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001299",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 162,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.105000476643425",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000848",
				"chemicalId": "00000000-0000-0000-0000-000000000848",
				"chemicalName": "cotinine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000553",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tobacco Metabolite",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4610,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.2696087700059",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000849",
				"chemicalId": "00000000-0000-0000-0000-000000000849",
				"chemicalName": "caffeine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000569",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4590,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.239046488484024",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000880",
				"chemicalId": "00000000-0000-0000-0000-000000000880",
				"chemicalName": "adenine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000554",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4147,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.660599285844818",
						"qValue": "0.626186416222256",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000881",
				"chemicalId": "00000000-0000-0000-0000-000000000881",
				"chemicalName": "cytosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000573",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4252,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.50404197731895",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000000917",
				"chemicalId": "00000000-0000-0000-0000-000000000917",
				"chemicalName": "L-asparagine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000512",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 40,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.121223752347449",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001001",
				"chemicalId": "00000000-0000-0000-0000-000000001001",
				"chemicalName": "trans-4-hydroxyproline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032306",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 513,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.316476587932549",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001022",
				"chemicalId": "00000000-0000-0000-0000-000000001022",
				"chemicalName": "picolinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001512",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 282,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.637530026367555",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001023",
				"chemicalId": "00000000-0000-0000-0000-000000001023",
				"chemicalName": "sarcosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001516",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 5,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.605134381593572",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001025",
				"chemicalId": "00000000-0000-0000-0000-000000001025",
				"chemicalName": "DL-pipecolic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001444",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.151638495369172",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001026",
				"chemicalId": "00000000-0000-0000-0000-000000001026",
				"chemicalName": "o-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001600",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2238,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.118604119178847",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001053",
				"chemicalId": "00000000-0000-0000-0000-000000001053",
				"chemicalName": "3-ureidopropionic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000003155",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Uracil containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4238,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0650804925755581",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001114",
				"chemicalId": "00000000-0000-0000-0000-000000001114",
				"chemicalName": "DL-3-aminoisobutyric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001566",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Thymine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4283,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.478190495090018",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001140",
				"chemicalId": "00000000-0000-0000-0000-000000001140",
				"chemicalName": "gamma-L-glutamyl-L-glutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002730",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 635,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.200331939935222",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001161",
				"chemicalId": "00000000-0000-0000-0000-000000001161",
				"chemicalName": "N-tigloylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001598",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 402,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.921300191729042",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001162",
				"chemicalId": "00000000-0000-0000-0000-000000001162",
				"chemicalName": "N-acetylneuraminic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032377",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1418,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0248560082278371",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001206",
				"chemicalId": "00000000-0000-0000-0000-000000001206",
				"chemicalName": "D(S)-(+)-threo-isocitric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012110",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1443,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.646093393589313",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001215",
				"chemicalId": "00000000-0000-0000-0000-000000001215",
				"chemicalName": "aspartylglucosamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048149",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1430,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.322025117005414",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001221",
				"chemicalId": "00000000-0000-0000-0000-000000001221",
				"chemicalName": "creatine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027718",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Creatine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 528,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.153138056292742",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001224",
				"chemicalId": "00000000-0000-0000-0000-000000001224",
				"chemicalName": "cys-gly, oxidized acetate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018368",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 578,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.784344833435852",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001235",
				"chemicalId": "00000000-0000-0000-0000-000000001235",
				"chemicalName": "(gamma-L-glutamyl)-L-histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018245",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 637,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.797667753240831",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001242",
				"chemicalId": "00000000-0000-0000-0000-000000001242",
				"chemicalName": "1-methyladenosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015650",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4153,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.489648987846519",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001256",
				"chemicalId": "00000000-0000-0000-0000-000000001256",
				"chemicalName": "choline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015506",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2232,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.12953020232245",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001258",
				"chemicalId": "00000000-0000-0000-0000-000000001258",
				"chemicalName": "anthranilic acid, 120 ion",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057746",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 275,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.427862186121577",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001268",
				"chemicalId": "00000000-0000-0000-0000-000000001268",
				"chemicalName": "gamma-glu-leu",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018369",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 639,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.825388213620932",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001342",
				"chemicalId": "00000000-0000-0000-0000-000000001342",
				"chemicalName": "3-methoxy-L-tyrosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012017",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 204,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.557742899372846",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000000001442",
				"chemicalId": "00000000-0000-0000-0000-000000001442",
				"chemicalName": "beta-hydroxyisovaleric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000012129",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 363,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.837744241603932",
						"qValue": "0.651782383379777",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000007",
				"chemicalId": "00000000-0000-0000-0000-000100000007",
				"chemicalName": "carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015500",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Carnitine Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1932,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.111301480620724",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000015",
				"chemicalId": "00000000-0000-0000-0000-000100000015",
				"chemicalName": "4,8-dihydroxyquinoline-2-carboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015679",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 278,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.88815987673285",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000039",
				"chemicalId": "00000000-0000-0000-0000-000100000039",
				"chemicalName": "methionine sulfoxide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018374",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 440,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.19990032282636",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000054",
				"chemicalId": "00000000-0000-0000-0000-000100000054",
				"chemicalName": "5-hydroxylysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015685",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.104263542849482",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000096",
				"chemicalId": "00000000-0000-0000-0000-000100000096",
				"chemicalName": "4-Guanidinobutanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015681",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 563,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.431406523533176",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000263",
				"chemicalId": "00000000-0000-0000-0000-000100000263",
				"chemicalName": "L-beta-imidazolelactic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015716",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 83,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.717933661362726",
						"qValue": "0.634928810485782",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000265",
				"chemicalId": "00000000-0000-0000-0000-000100000265",
				"chemicalName": "L-kynurenine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015140",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 267,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.381047444274382",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000269",
				"chemicalId": "00000000-0000-0000-0000-000100000269",
				"chemicalName": "L-alpha-glycerophosphorylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000015990",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2236,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.135806162478199",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000285",
				"chemicalId": "00000000-0000-0000-0000-000100000285",
				"chemicalName": "N-alpha-acetyl-L-ornithine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032984",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 509,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0165928110725092",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000445",
				"chemicalId": "00000000-0000-0000-0000-000100000445",
				"chemicalName": "theobromine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018392",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4592,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.239848052547131",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000453",
				"chemicalId": "00000000-0000-0000-0000-000100000453",
				"chemicalName": "1,7-dimethylxanthine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018254",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4591,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.656649900655597",
						"qValue": "0.624992633142025",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000487",
				"chemicalId": "00000000-0000-0000-0000-000100000487",
				"chemicalName": "N-glycyl-L-valine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000018357",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 829,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.885694302161058",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000491",
				"chemicalId": "00000000-0000-0000-0000-000100000491",
				"chemicalName": "(gamma-L-glutamyl)-L-phenylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033422",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 643,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.95209234069947",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000715",
				"chemicalId": "00000000-0000-0000-0000-000100000715",
				"chemicalName": "1-methylguanidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048114",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 562,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.52120130040128",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000781",
				"chemicalId": "00000000-0000-0000-0000-000100000781",
				"chemicalName": "DL-hexanoyl-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032328",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1844,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.845855388277325",
						"qValue": "0.655928243923531",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000802",
				"chemicalId": "00000000-0000-0000-0000-000100000802",
				"chemicalName": "O-acetyl-L-carnitine hydrochloride",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032198",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Short Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1840,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0642625757256758",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000808",
				"chemicalId": "00000000-0000-0000-0000-000100000808",
				"chemicalName": "l-cysteine s-sulfate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022176",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 461,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.29714775693681",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000846",
				"chemicalId": "00000000-0000-0000-0000-000100000846",
				"chemicalName": "meso-erythritol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000020699",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4895,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.954834338209437",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000900",
				"chemicalId": "00000000-0000-0000-0000-000100000900",
				"chemicalName": "iminodiacetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000021025",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6164,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.233693598198719",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000961",
				"chemicalId": "00000000-0000-0000-0000-000100000961",
				"chemicalName": "homoarg",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022137",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 493,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.167760197579392",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000963",
				"chemicalId": "00000000-0000-0000-0000-000100000963",
				"chemicalName": "L-homocitrulline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022138",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 494,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.737712671300531",
						"qValue": "0.640154325505618",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100000998",
				"chemicalId": "00000000-0000-0000-0000-000100000998",
				"chemicalName": "citramalic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022158",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 63,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.302317833416744",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001002",
				"chemicalId": "00000000-0000-0000-0000-000100001002",
				"chemicalName": "ethylenediaminotetraacetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022163",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6155,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.342728289680873",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001006",
				"chemicalId": "00000000-0000-0000-0000-000100001006",
				"chemicalName": "N-acetylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027710",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 2,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.075367240588466",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001054",
				"chemicalId": "00000000-0000-0000-0000-000100001054",
				"chemicalName": "butyryl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032412",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1768,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0276303233775122",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001055",
				"chemicalId": "00000000-0000-0000-0000-000100001055",
				"chemicalName": "isobutyryl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033441",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 426,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.811052690244182",
						"qValue": "0.647665685371287",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001081",
				"chemicalId": "00000000-0000-0000-0000-000100001081",
				"chemicalName": "2-Pyrrolidinone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031675",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 60,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.17128124609859",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001086",
				"chemicalId": "00000000-0000-0000-0000-000100001086",
				"chemicalName": "N-(2-furoyl)glycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031536",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4967,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.913232628669126",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001092",
				"chemicalId": "00000000-0000-0000-0000-000100001092",
				"chemicalName": "trigonelline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032401",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4322,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.274132285717212",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001103",
				"chemicalId": "00000000-0000-0000-0000-000100001103",
				"chemicalName": "L-glutamic acid gamma-methyl ester",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033487",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 54,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.833758887567122",
						"qValue": "0.650829647970713",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001126",
				"chemicalId": "00000000-0000-0000-0000-000100001126",
				"chemicalName": "gamma glu-val",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043829",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 647,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.149279408675875",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001155",
				"chemicalId": "00000000-0000-0000-0000-000100001155",
				"chemicalName": "2-Methylbutyrylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000031928",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 399,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.43710741635561",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001162",
				"chemicalId": "00000000-0000-0000-0000-000100001162",
				"chemicalName": "propionyl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032452",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (also BCAA Metabolism)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1771,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.506191240361306",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001167",
				"chemicalId": "00000000-0000-0000-0000-000100001167",
				"chemicalName": "pro-hydroxy-pro",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035127",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 515,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.962404297646933",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001207",
				"chemicalId": "00000000-0000-0000-0000-000100001207",
				"chemicalName": "imidazole-4-acetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032349",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 97,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.761371474026263",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001208",
				"chemicalId": "00000000-0000-0000-0000-000100001208",
				"chemicalName": "1-methyl-imidazoleacetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032350",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 91,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.195652114122607",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001212",
				"chemicalId": "00000000-0000-0000-0000-000100001212",
				"chemicalName": "guanidinosuccinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032446",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Guanidino and Acetamido Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 564,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.835813753203255",
						"qValue": "0.650996591259171",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001254",
				"chemicalId": "00000000-0000-0000-0000-000100001254",
				"chemicalName": "N-acetyl-L-tryptophan",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033959",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 255,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0441715502824378",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001258",
				"chemicalId": "00000000-0000-0000-0000-000100001258",
				"chemicalName": "glycyl-L-leucine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034398",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 819,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.224912383418361",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001262",
				"chemicalId": "00000000-0000-0000-0000-000100001262",
				"chemicalName": "gamma-L-Glutamyl-epsilon-L-lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033934",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 641,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.153960222681123",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001266",
				"chemicalId": "00000000-0000-0000-0000-000100001266",
				"chemicalName": "N-alpha-Acetyl-L-arginine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033953",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 499,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.691974197503566",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001274",
				"chemicalId": "00000000-0000-0000-0000-000100001274",
				"chemicalName": "N-acetyl-DL-threonine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033939",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 18,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0489773242597984",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001293",
				"chemicalId": "00000000-0000-0000-0000-000100001293",
				"chemicalName": "N-acetyl-L-histidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033946",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 73,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.521372449925934",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001294",
				"chemicalId": "00000000-0000-0000-0000-000100001294",
				"chemicalName": "N-gamma-glutamylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033949",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 636,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.102238824708632",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001295",
				"chemicalId": "00000000-0000-0000-0000-000100001295",
				"chemicalName": "gamma-glu-trp",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033947",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 645,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.460650434277931",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001296",
				"chemicalId": "00000000-0000-0000-0000-000100001296",
				"chemicalName": "stachydrine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034384",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5063,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.218165360333863",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001313",
				"chemicalId": "00000000-0000-0000-0000-000100001313",
				"chemicalName": "gamma-glutamylmethionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044872",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 642,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.329168449199222",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001314",
				"chemicalId": "00000000-0000-0000-0000-000100001314",
				"chemicalName": "gamma-glutamylthreonine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033364",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 644,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.496548733532692",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001334",
				"chemicalId": "00000000-0000-0000-0000-000100001334",
				"chemicalName": "N-acetyl-DL-proline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034387",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.495014094430128",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001393",
				"chemicalId": "00000000-0000-0000-0000-000100001393",
				"chemicalName": "isovaleryl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034407",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 354,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.119834162703967",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001396",
				"chemicalId": "00000000-0000-0000-0000-000100001396",
				"chemicalName": "7-methylxanthine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034390",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4602,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.883869217939911",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001400",
				"chemicalId": "00000000-0000-0000-0000-000100001400",
				"chemicalName": "1-methyluric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034395",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4594,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.398159310098038",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001405",
				"chemicalId": "00000000-0000-0000-0000-000100001405",
				"chemicalName": "1-methylxanthine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034389",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4600,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.446469416753611",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001409",
				"chemicalId": "00000000-0000-0000-0000-000100001409",
				"chemicalName": "N1-methylinosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048351",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, (Hypo)Xanthine/Inosine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4126,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.844120360205326",
						"qValue": "0.655928243923531",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001411",
				"chemicalId": "00000000-0000-0000-0000-000100001411",
				"chemicalName": "beta-guanidinopropanoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035101",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4828,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.355326420798642",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001412",
				"chemicalId": "00000000-0000-0000-0000-000100001412",
				"chemicalName": "N2,N2-dimethylguanosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035137",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4192,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.583416836620965",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001413",
				"chemicalId": "00000000-0000-0000-0000-000100001413",
				"chemicalName": "N4-acetylcytidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035130",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Pyrimidine Metabolism, Cytidine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4256,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0050528709823744",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001415",
				"chemicalId": "00000000-0000-0000-0000-000100001415",
				"chemicalName": "N6-carbamoylthreonyladenosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035157",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Adenine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4164,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.115974942909753",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001423",
				"chemicalId": "00000000-0000-0000-0000-000100001423",
				"chemicalName": "p-hydroxyhippuric acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035527",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Benzoate Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4470,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.13265835659588",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001456",
				"chemicalId": "00000000-0000-0000-0000-000100001456",
				"chemicalName": "7-methylguanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035114",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Purine Metabolism, Guanine containing",
				"superPathway": "Nucleotide",
				"pathwaySortOrder": 4186,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.548011282842064",
						"qValue": "0.621140254805774",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001468",
				"chemicalId": "00000000-0000-0000-0000-000100001468",
				"chemicalName": "N1-Methyl-2-pyridone-5-carboxamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040469",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4324,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.392988260499043",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001469",
				"chemicalId": "00000000-0000-0000-0000-000100001469",
				"chemicalName": "N1-methyl-4-pyridone-3-carboxamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057584",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Nicotinate and Nicotinamide Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4325,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0965676333158184",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001509",
				"chemicalId": "00000000-0000-0000-0000-000100001509",
				"chemicalName": "2-methylbutyrylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045095",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 397,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.376161776073113",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001540",
				"chemicalId": "00000000-0000-0000-0000-000100001540",
				"chemicalName": "pyroglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046225",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6452,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.177723955643048",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001550",
				"chemicalId": "00000000-0000-0000-0000-000100001550",
				"chemicalName": "homostachydrine-1",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033009",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4933,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.462314888123663",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001593",
				"chemicalId": "00000000-0000-0000-0000-000100001593",
				"chemicalName": "glutaryl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044664",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 127,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.399039943419067",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001594",
				"chemicalId": "00000000-0000-0000-0000-000100001594",
				"chemicalName": "hydroxyisovaleroyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035433",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.256209074943316",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001597",
				"chemicalId": "00000000-0000-0000-0000-000100001597",
				"chemicalName": "tiglyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035428",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 400,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.2091228885225",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001612",
				"chemicalId": "00000000-0000-0000-0000-000100001612",
				"chemicalName": "N-Acetyl-aspartyl-glutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035665",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 55,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.538201773602895",
						"qValue": "0.617535982536699",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001620",
				"chemicalId": "00000000-0000-0000-0000-000100001620",
				"chemicalName": "sn-glycero-3-Phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037455",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2240,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.377030559279661",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001621",
				"chemicalId": "00000000-0000-0000-0000-000100001621",
				"chemicalName": "Glycerophosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052307",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2242,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0574852425363241",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001635",
				"chemicalId": "00000000-0000-0000-0000-000100001635",
				"chemicalName": "ectoine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035651",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6154,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.785635161098241",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001668",
				"chemicalId": "00000000-0000-0000-0000-000100001668",
				"chemicalName": "1-ribosyl-imidazole-4-acetic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061868",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 96,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.22026762287973",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001743",
				"chemicalId": "00000000-0000-0000-0000-000100001743",
				"chemicalName": "tryptophan betaine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037097",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 264,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.121104967895562",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001767",
				"chemicalId": "00000000-0000-0000-0000-000100001767",
				"chemicalName": "pyrraline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048428",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5117,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.864105034972709",
						"qValue": "0.65994934009622",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001768",
				"chemicalId": "00000000-0000-0000-0000-000100001768",
				"chemicalName": "N6-carboxymethyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036713",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Advanced Glycation End-product",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1435,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.224417702897681",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001810",
				"chemicalId": "00000000-0000-0000-0000-000100001810",
				"chemicalName": "dimethylarginine (ADMA + SDMA)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000036808",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 498,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.171365550036488",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001843",
				"chemicalId": "00000000-0000-0000-0000-000100001843",
				"chemicalName": "gamma-glutamylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037063",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 632,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.15796097870671",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001925",
				"chemicalId": "00000000-0000-0000-0000-000100001925",
				"chemicalName": "cyclo(leu-pro)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037104",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 762,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.419631248117612",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001946",
				"chemicalId": "00000000-0000-0000-0000-000100001946",
				"chemicalName": "cyclo(gly-pro)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037077",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 745,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.512801241321729",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001948",
				"chemicalId": "00000000-0000-0000-0000-000100001948",
				"chemicalName": "succinylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037058",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "TCA Cycle ",
				"superPathway": "Energy",
				"pathwaySortOrder": 1447,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.701078385553556",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100001956",
				"chemicalId": "00000000-0000-0000-0000-000100001956",
				"chemicalName": "N-methyl proline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037431",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 518,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.28657064339576",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002070",
				"chemicalId": "00000000-0000-0000-0000-000100002070",
				"chemicalName": "2-hydroxyglutarate, ammonia adduct",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052294",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Dicarboxylate",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1663,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.638360882438922",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002103",
				"chemicalId": "00000000-0000-0000-0000-000100002103",
				"chemicalName": "5-methylthioribose",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062100",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 447,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.298064182708405",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002113",
				"chemicalId": "00000000-0000-0000-0000-000100002113",
				"chemicalName": "cysteine sulfinic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037443",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 468,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.701693404687555",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002153",
				"chemicalId": "00000000-0000-0000-0000-000100002153",
				"chemicalName": "4-hydroxyproline betaine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038100",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4799,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.652186514273295",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002154",
				"chemicalId": "00000000-0000-0000-0000-000100002154",
				"chemicalName": "ergothioneine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037459",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4893,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.169517364522942",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002204",
				"chemicalId": "00000000-0000-0000-0000-000100002204",
				"chemicalName": "N-acetyl-3-methylhistidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043256",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 75,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.290627489975572",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002241",
				"chemicalId": "00000000-0000-0000-0000-000100002241",
				"chemicalName": "7-methylurate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039598",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Xanthine Metabolism",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4595,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.283063971419639",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002249",
				"chemicalId": "00000000-0000-0000-0000-000100002249",
				"chemicalName": "N-acetyl-cadaverine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043530",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 135,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.145355797505562",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002462",
				"chemicalId": "00000000-0000-0000-0000-000100002462",
				"chemicalName": "5-(galactosylhydroxy)-L-lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043582",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 120,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.37889917004178",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002466",
				"chemicalId": "00000000-0000-0000-0000-000100002466",
				"chemicalName": "L-cysteinylglycine disulfide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062103",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutathione Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 577,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.359519944566535",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002500",
				"chemicalId": "00000000-0000-0000-0000-000100002500",
				"chemicalName": "formiminoglutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043493",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 81,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.531660653852637",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002514",
				"chemicalId": "00000000-0000-0000-0000-000100002514",
				"chemicalName": "hydantoin-5-propionic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040473",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 77,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.819643608832325",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002544",
				"chemicalId": "00000000-0000-0000-0000-000100002544",
				"chemicalName": "4-hydroxy-L-glutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040499",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 51,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.701429867856186",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002679",
				"chemicalId": "00000000-0000-0000-0000-000100002679",
				"chemicalName": "gamma-carboxyglutamate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038754",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 53,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.299234206912626",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002769",
				"chemicalId": "00000000-0000-0000-0000-000100002769",
				"chemicalName": "argininate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057461",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 522,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.943462870686016",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002784",
				"chemicalId": "00000000-0000-0000-0000-000100002784",
				"chemicalName": "2-oxoarginine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000055072",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 491,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.133781092369505",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002806",
				"chemicalId": "00000000-0000-0000-0000-000100002806",
				"chemicalName": "gabapentin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038686",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Neurological",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5829,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.22616841765639",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002927",
				"chemicalId": "00000000-0000-0000-0000-000100002927",
				"chemicalName": "S-methylcysteine sulfoxide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043378",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 458,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.272679826494323",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100002928",
				"chemicalId": "00000000-0000-0000-0000-000100002928",
				"chemicalName": "benzoylecgonine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039678",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Analgesics, Anesthetics",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.122586050429741",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003179",
				"chemicalId": "00000000-0000-0000-0000-000100003179",
				"chemicalName": "leu-ala",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040010",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 867,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.212129527875149",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003183",
				"chemicalId": "00000000-0000-0000-0000-000100003183",
				"chemicalName": "leucylglutamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048189",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1047,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.342918537469511",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003185",
				"chemicalId": "00000000-0000-0000-0000-000100003185",
				"chemicalName": "leu-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040045",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 872,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.152941917484844",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003210",
				"chemicalId": "00000000-0000-0000-0000-000100003210",
				"chemicalName": "val-leu",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039994",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1037,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.305852786054102",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003260",
				"chemicalId": "00000000-0000-0000-0000-000100003260",
				"chemicalName": "carboxyethyl-GABA",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040007",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glutamate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 58,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.772003407455117",
						"qValue": "0.644821124353664",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003397",
				"chemicalId": "00000000-0000-0000-0000-000100003397",
				"chemicalName": "trimethylamine N-oxide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040406",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phospholipid Metabolism",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2243,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.122938562887625",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003415",
				"chemicalId": "00000000-0000-0000-0000-000100003415",
				"chemicalName": "N6-methyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062860",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 114,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.152423047111317",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003425",
				"chemicalId": "00000000-0000-0000-0000-000100003425",
				"chemicalName": "cis-urocanate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040410",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 79,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.592081930275508",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003434",
				"chemicalId": "00000000-0000-0000-0000-000100003434",
				"chemicalName": "imidazole propionate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040730",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 80,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.812416469798743",
						"qValue": "0.647754068132547",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003450",
				"chemicalId": "00000000-0000-0000-0000-000100003450",
				"chemicalName": "mirtazapine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Drug - Psychoactive",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5885,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.178740243570571",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003473",
				"chemicalId": "00000000-0000-0000-0000-000100003473",
				"chemicalName": "alliin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041494",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4816,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.146298866101065",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003588",
				"chemicalId": "00000000-0000-0000-0000-000100003588",
				"chemicalName": "phe-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041370",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 926,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.981184796048419",
						"qValue": "0.688407079303167",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003589",
				"chemicalId": "00000000-0000-0000-0000-000100003589",
				"chemicalName": "phe-ala",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000041374",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 921,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.201080352961962",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003674",
				"chemicalId": "00000000-0000-0000-0000-000100003674",
				"chemicalName": "pro-gly",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040703",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 947,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.79917385206774",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100003892",
				"chemicalId": "00000000-0000-0000-0000-000100003892",
				"chemicalName": "lanthionine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042002",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 466,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0143892331379889",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004059",
				"chemicalId": "00000000-0000-0000-0000-000100004059",
				"chemicalName": "histidine betaine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062278",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4931,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.503116722180702",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004284",
				"chemicalId": "00000000-0000-0000-0000-000100004284",
				"chemicalName": "dimethyl sulfone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043424",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6152,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.313725155594888",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004295",
				"chemicalId": "00000000-0000-0000-0000-000100004295",
				"chemicalName": "2-Piperidinone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043400",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4636,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.881816491778734",
						"qValue": "0.667707709094382",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004299",
				"chemicalId": "00000000-0000-0000-0000-000100004299",
				"chemicalName": "N-acetyl-1-methylhistidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043255",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 76,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0758566279012769",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004318",
				"chemicalId": "00000000-0000-0000-0000-000100004318",
				"chemicalName": "indolin-2-one",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043374",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 4938,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.609485297999684",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004509",
				"chemicalId": "00000000-0000-0000-0000-000100004509",
				"chemicalName": "S-allylcysteine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043239",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5044,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.177181596502916",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004523",
				"chemicalId": "00000000-0000-0000-0000-000100004523",
				"chemicalName": "N-delta-acetylornithine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043249",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 508,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.928578011539333",
						"qValue": "0.678416860083147",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004542",
				"chemicalId": "00000000-0000-0000-0000-000100004542",
				"chemicalName": "2-aminoheptanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043761",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Amino",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1750,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.125527554143396",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100004635",
				"chemicalId": "00000000-0000-0000-0000-000100004635",
				"chemicalName": "methionine sulfone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044878",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 439,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.233237086491575",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100005373",
				"chemicalId": "00000000-0000-0000-0000-000100005373",
				"chemicalName": "fructosyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046227",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 121,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.154944789999773",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100005383",
				"chemicalId": "00000000-0000-0000-0000-000100005383",
				"chemicalName": "1-methyl-2-piperidinecarboxylic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047101",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Bacterial/Fungal",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5328,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.957949565351012",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100005850",
				"chemicalId": "00000000-0000-0000-0000-000100005850",
				"chemicalName": "3-methylglutarylcarnitine-2",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046548",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Leucine, Isoleucine and Valine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 375,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.928285049123539",
						"qValue": "0.678416860083147",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006108",
				"chemicalId": "00000000-0000-0000-0000-000100006108",
				"chemicalName": "phenylacetylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048425",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Acetylated Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1184,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.138073820128348",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006123",
				"chemicalId": "00000000-0000-0000-0000-000100006123",
				"chemicalName": "N-acetyl histamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048679",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Histidine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 101,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.167575389790261",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006369",
				"chemicalId": "00000000-0000-0000-0000-000100006369",
				"chemicalName": "carbamoyl-alanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052340",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 35,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.905126472889472",
						"qValue": "0.674415405312238",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006378",
				"chemicalId": "00000000-0000-0000-0000-000100006378",
				"chemicalName": "N-acetylkynurenine (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048757",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 270,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.224714937889613",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006435",
				"chemicalId": "00000000-0000-0000-0000-000100006435",
				"chemicalName": "N-acetylglucosamine/N-acetylgalactosamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046539",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Aminosugar Metabolism",
				"superPathway": "Carbohydrate",
				"pathwaySortOrder": 1433,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.442555639199569",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100006614",
				"chemicalId": "00000000-0000-0000-0000-000100006614",
				"chemicalName": "adipoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052988",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1907,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.207211480641142",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100009232",
				"chemicalId": "00000000-0000-0000-0000-000100009232",
				"chemicalName": "L-thioproline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053231",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Chemical",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 6239,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.993479225973517",
						"qValue": "0.691101771582521",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100009246",
				"chemicalId": "00000000-0000-0000-0000-000100009246",
				"chemicalName": "2-methylserine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053229",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Glycine, Serine and Threonine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 16,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.804465914469553",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100009271",
				"chemicalId": "00000000-0000-0000-0000-000100009271",
				"chemicalName": "3-hydroxybutyrylcarnitine (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052984",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1920,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.234037638356862",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100010901",
				"chemicalId": "00000000-0000-0000-0000-000100010901",
				"chemicalName": "gamma-glutamyl-alpha-lysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000055015",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 640,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0149583703675255",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100015962",
				"chemicalId": "00000000-0000-0000-0000-000100015962",
				"chemicalName": "N-trimethyl 5-aminopentanoate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057687",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 138,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.281761571773654",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100015965",
				"chemicalId": "00000000-0000-0000-0000-000100015965",
				"chemicalName": "ethyl a-D-glucopyranoside",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063152",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5089,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.404024645064645",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100015991",
				"chemicalId": "00000000-0000-0000-0000-000100015991",
				"chemicalName": "Fibrinopeptide B",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061837",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1176,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.258552296115443",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100016038",
				"chemicalId": "00000000-0000-0000-0000-000100016038",
				"chemicalName": "(N(1) + N(8))-acetylspermidine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057814",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 550,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.182673456433835",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020203",
				"chemicalId": "00000000-0000-0000-0000-000100020203",
				"chemicalName": "3-hydroxystachydrine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062062",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5064,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.779137569493879",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020205",
				"chemicalId": "00000000-0000-0000-0000-000100020205",
				"chemicalName": "hydroxyasparagine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062060",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 43,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.575599492070177",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020211",
				"chemicalId": "00000000-0000-0000-0000-000100020211",
				"chemicalName": "3-hydroxybutyroylglycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062064",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1831,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.214045713418002",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020215",
				"chemicalId": "00000000-0000-0000-0000-000100020215",
				"chemicalName": "N-methylhydroxyproline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062068",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 512,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0476772274320825",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020241",
				"chemicalId": "00000000-0000-0000-0000-000100020241",
				"chemicalName": "gamma-glutamylcitrulline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062104",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Gamma-glutamyl Amino Acid",
				"superPathway": "Peptide",
				"pathwaySortOrder": 649,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.210086755207529",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020262",
				"chemicalId": "00000000-0000-0000-0000-000100020262",
				"chemicalName": "glutamine conjugate of C6H10O2 (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063660",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Partially Characterized Molecules",
				"superPathway": "Partially Characterized Molecules",
				"pathwaySortOrder": 6400,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.666694781938756",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020361",
				"chemicalId": "00000000-0000-0000-0000-000100020361",
				"chemicalName": "3-amino-2-piperidone",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062853",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 489,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0686527795032191",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020371",
				"chemicalId": "00000000-0000-0000-0000-000100020371",
				"chemicalName": "N,N-dimethylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063151",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Alanine and Aspartate Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 33,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.203699459165644",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020377",
				"chemicalId": "00000000-0000-0000-0000-000100020377",
				"chemicalName": "ethyl-beta-glucopyranoside",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064131",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.528634410157166",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020479",
				"chemicalId": "00000000-0000-0000-0000-000100020479",
				"chemicalName": "indoleacetoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062279",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 319,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.409145767770058",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020487",
				"chemicalId": "00000000-0000-0000-0000-000100020487",
				"chemicalName": "N-acetyl-isoputreanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062309",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 544,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.282256967244757",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020513",
				"chemicalId": "00000000-0000-0000-0000-000100020513",
				"chemicalName": "N-acetylhomocitrulline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062707",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Urea cycle; Arginine and Proline Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 525,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.560100291171648",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020545",
				"chemicalId": "00000000-0000-0000-0000-000100020545",
				"chemicalName": "N2-acetyl,N6,N6-dimethyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063254",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 110,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.890267483034658",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020546",
				"chemicalId": "00000000-0000-0000-0000-000100020546",
				"chemicalName": "N2-acetyl,N6-methyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062954",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 109,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.408928401402389",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020825",
				"chemicalId": "00000000-0000-0000-0000-000100020825",
				"chemicalName": "3-hydroxyhexanoylcarnitine (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062557",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1921,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0555959307776839",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020842",
				"chemicalId": "00000000-0000-0000-0000-000100020842",
				"chemicalName": "N,N-dimethyl-5-aminovalerate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062819",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 137,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.915699016388088",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100020912",
				"chemicalId": "00000000-0000-0000-0000-000100020912",
				"chemicalName": "Fibrinopeptide B (1-11)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062815",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fibrinogen Cleavage Peptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1178,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.891990016092626",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021123",
				"chemicalId": "00000000-0000-0000-0000-000100021123",
				"chemicalName": "hydroxy-N6,N6,N6-trimethyllysine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062959",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 117,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.168510043268236",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021238",
				"chemicalId": "00000000-0000-0000-0000-000100021238",
				"chemicalName": "phenylalanylhydroxyproline",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063039",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dipeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 933,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.513642109443932",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021329",
				"chemicalId": "00000000-0000-0000-0000-000100021329",
				"chemicalName": "(S)-a-Amino-omega-caprolactam",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063339",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5210,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.658499076535398",
						"qValue": "0.625106403000236",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021530",
				"chemicalId": "00000000-0000-0000-0000-000100021530",
				"chemicalName": "S-carboxyethylcysteine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064177",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Methionine, Cysteine, SAM and Taurine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 460,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.416675718655694",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021760",
				"chemicalId": "00000000-0000-0000-0000-000100021760",
				"chemicalName": "N-acetyl-L-2-aminoadipic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063920",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 139,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.22095673590089",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021990",
				"chemicalId": "00000000-0000-0000-0000-000100021990",
				"chemicalName": "3-hydroxyoctanoylcarnitine (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064393",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1924,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.299977350251",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021991",
				"chemicalId": "00000000-0000-0000-0000-000100021991",
				"chemicalName": "3-hydroxyoctanoylcarnitine (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064394",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1925,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.202068275404972",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100021992",
				"chemicalId": "00000000-0000-0000-0000-000100021992",
				"chemicalName": "cis-3,4-methyleneheptanoyl glycine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064386",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Glycine)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1814,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.694922067290515",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100022041",
				"chemicalId": "00000000-0000-0000-0000-000100022041",
				"chemicalName": "N,N-dimethyl-pro-pro",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064571",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Modified Peptides",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.620645095996881",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-400f-0000-0000-000100022120",
				"chemicalId": "00000000-0000-0000-0000-000100022120",
				"chemicalName": "oxindolylalanine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000064568",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tryptophan Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 263,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.519420519430479",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000050",
				"chemicalId": "00000000-0000-0000-0000-000000000050",
				"chemicalName": "1,4-butanediamine, n-(3-aminopropyl)-",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000485",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polyamine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 545,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.180023048871716",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000250",
				"chemicalId": "00000000-0000-0000-0000-000000000250",
				"chemicalName": "biliverdin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000002137",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4411,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.316764608156896",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000266",
				"chemicalId": "00000000-0000-0000-0000-000000000266",
				"chemicalName": "cholesterol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000000063",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3192,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0676922830783575",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000297",
				"chemicalId": "00000000-0000-0000-0000-000000000297",
				"chemicalName": "D-sphingosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000017747",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingosines",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3159,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.538147443576131",
						"qValue": "0.617535982536699",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000313",
				"chemicalId": "00000000-0000-0000-0000-000000000313",
				"chemicalName": "DL-sphinganine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000017769",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3000,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.58529879716806",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000498",
				"chemicalId": "00000000-0000-0000-0000-000000000498",
				"chemicalName": "retinol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001806",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.517359728378946",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000000922",
				"chemicalId": "00000000-0000-0000-0000-000000000922",
				"chemicalName": "N-stearoyl-D-erythrosphinganine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001759",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydroceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3012,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.207840818509069",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001090",
				"chemicalId": "00000000-0000-0000-0000-000000001090",
				"chemicalName": "bilirubin (Z,Z)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043807",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4408,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.521254242261524",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001094",
				"chemicalId": "00000000-0000-0000-0000-000000001094",
				"chemicalName": "thyroxine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046079",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tyrosine Metabolism",
				"superPathway": "Amino Acid",
				"pathwaySortOrder": 245,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.486654686151747",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001105",
				"chemicalId": "00000000-0000-0000-0000-000000001105",
				"chemicalName": "alpha-tocopherol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000001561",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4360,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.432989673573404",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001518",
				"chemicalId": "00000000-0000-0000-0000-000000001518",
				"chemicalName": "C16 ceramide (d18:1-16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044877",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3021,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.304854934125522",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001526",
				"chemicalId": "00000000-0000-0000-0000-000000001526",
				"chemicalName": "1-palmitoyl-2-oleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019263",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2408,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.487214585034246",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001528",
				"chemicalId": "00000000-0000-0000-0000-000000001528",
				"chemicalName": "L-alpha-phosphatidylinositol (Soy)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052450",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2536,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.24662340308706",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001538",
				"chemicalId": "00000000-0000-0000-0000-000000001538",
				"chemicalName": "sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019503",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3105,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.370692845943924",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001539",
				"chemicalId": "00000000-0000-0000-0000-000000001539",
				"chemicalName": "L-alpha-phosphatidylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052461",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2283,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.351660066371787",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000000001547",
				"chemicalId": "00000000-0000-0000-0000-000000001547",
				"chemicalName": "C18 ceramide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054979",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3025,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.44452035078001",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000616",
				"chemicalId": "00000000-0000-0000-0000-000100000616",
				"chemicalName": "L-a-Phosphatidylinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052449",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2556,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.406339998804798",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000626",
				"chemicalId": "00000000-0000-0000-0000-000100000626",
				"chemicalName": "D-erythro-Sphingosine-1-Phosphate",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034445",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingosines",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3161,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.508740904852649",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000639",
				"chemicalId": "00000000-0000-0000-0000-000100000639",
				"chemicalName": "1-Stearoyl-2-Oleoyl-sn-Glycero-3-[Phospho-L-Serine]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019265",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylserine (PS)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2505,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.556622959984798",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000657",
				"chemicalId": "00000000-0000-0000-0000-000100000657",
				"chemicalName": "1,2-Dipalmitoyl-sn-Glycero-3-Phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019130",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2278,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.289286020288698",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000672",
				"chemicalId": "00000000-0000-0000-0000-000100000672",
				"chemicalName": "1-Myristoyl-2-Palmitoyl-sn-Glycero-3-Phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000019258",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2262,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.654457919915223",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000776",
				"chemicalId": "00000000-0000-0000-0000-000100000776",
				"chemicalName": "palmitoyl-dl-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044681",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1864,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.613494631321375",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100000967",
				"chemicalId": "00000000-0000-0000-0000-000100000967",
				"chemicalName": "bradykinin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000022154",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1065,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.449176320630348",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001033",
				"chemicalId": "00000000-0000-0000-0000-000100001033",
				"chemicalName": "beta-sitosterol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000027414",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3221,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.347374795694567",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001216",
				"chemicalId": "00000000-0000-0000-0000-000100001216",
				"chemicalName": "delta-tocopherol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033418",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4364,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.923344598477686",
						"qValue": "0.677394753951544",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001237",
				"chemicalId": "00000000-0000-0000-0000-000100001237",
				"chemicalName": "(Hyp-3)-Bradykinin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033962",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1067,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.395015084674951",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001247",
				"chemicalId": "00000000-0000-0000-0000-000100001247",
				"chemicalName": "(+/-) Octanoylcarnitine chloride",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033936",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1852,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.943856477860789",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001251",
				"chemicalId": "00000000-0000-0000-0000-000100001251",
				"chemicalName": "DL-Decanoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033941",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1860,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.229342098193466",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001263",
				"chemicalId": "00000000-0000-0000-0000-000100001263",
				"chemicalName": "1-palmitoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033955",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2600,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.619406023555438",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001267",
				"chemicalId": "00000000-0000-0000-0000-000100001267",
				"chemicalName": "piperine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033935",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Food Component/Plant",
				"superPathway": "Xenobiotics",
				"pathwaySortOrder": 5005,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.787683768203897",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001269",
				"chemicalId": "00000000-0000-0000-0000-000100001269",
				"chemicalName": "campesterol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033997",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3224,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0940352141726457",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001270",
				"chemicalId": "00000000-0000-0000-0000-000100001270",
				"chemicalName": "myristoyl-DL-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033952",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1862,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.345953048973782",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001271",
				"chemicalId": "00000000-0000-0000-0000-000100001271",
				"chemicalName": "1-stearoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000033961",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2611,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.54239635804016",
						"qValue": "0.619699528435282",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001272",
				"chemicalId": "00000000-0000-0000-0000-000100001272",
				"chemicalName": "1-oleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048258",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2613,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.393699454352267",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001281",
				"chemicalId": "00000000-0000-0000-0000-000100001281",
				"chemicalName": "HWESASLLR",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044657",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Polypeptide",
				"superPathway": "Peptide",
				"pathwaySortOrder": 1074,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0623041431195522",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001391",
				"chemicalId": "00000000-0000-0000-0000-000100001391",
				"chemicalName": "stearoyl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034409",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1866,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.954782695589701",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001392",
				"chemicalId": "00000000-0000-0000-0000-000100001392",
				"chemicalName": "lauryl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034534",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1861,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.759219190644163",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001395",
				"chemicalId": "00000000-0000-0000-0000-000100001395",
				"chemicalName": "1-linoleoyl-2-hydroxy-sn-glycero-3-phosphatidylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000034419",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2616,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.601487496022458",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001461",
				"chemicalId": "00000000-0000-0000-0000-000100001461",
				"chemicalName": "1-stearoyl-2-Hydroxy-sn-Glycero-3-Phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042398",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2669,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.422538054501355",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001501",
				"chemicalId": "00000000-0000-0000-0000-000100001501",
				"chemicalName": "oleoyl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035160",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1886,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.632703407549441",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001567",
				"chemicalId": "00000000-0000-0000-0000-000100001567",
				"chemicalName": "1-palmitoyl-2-hydroxy-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035631",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2662,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.514424083557367",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001569",
				"chemicalId": "00000000-0000-0000-0000-000100001569",
				"chemicalName": "1-oleoyl-2-hydroxy-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000035628",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2671,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.418306351433836",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001856",
				"chemicalId": "00000000-0000-0000-0000-000100001856",
				"chemicalName": "1-stearoyl-2-oleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042448",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2425,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.237574568979879",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001869",
				"chemicalId": "00000000-0000-0000-0000-000100001869",
				"chemicalName": "1-stearoyl-2-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042450",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2333,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.417718958760558",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001870",
				"chemicalId": "00000000-0000-0000-0000-000100001870",
				"chemicalName": "1-palmitoyl-2-linoleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042449",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2410,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.557200529513397",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001876",
				"chemicalId": "00000000-0000-0000-0000-000100001876",
				"chemicalName": "sphinganine-1-phosphate (d18:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052605",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3001,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.499007180921418",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001878",
				"chemicalId": "00000000-0000-0000-0000-000100001878",
				"chemicalName": "C24:1 galactosyl ceramide (d18:1/24:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057369",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3063,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.92672468532913",
						"qValue": "0.678416860083147",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001882",
				"chemicalId": "00000000-0000-0000-0000-000100001882",
				"chemicalName": "C18 glycosyl ceramide (d18:1/18:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052234",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3058,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.854346003874925",
						"qValue": "0.657626917466409",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001950",
				"chemicalId": "00000000-0000-0000-0000-000100001950",
				"chemicalName": "bilirubin (E,E)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000032586",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4409,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.774090783798975",
						"qValue": "0.644821124353664",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001951",
				"chemicalId": "00000000-0000-0000-0000-000100001951",
				"chemicalName": "bilirubin (E,Z)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047886",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4410,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.351940228954944",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100001977",
				"chemicalId": "00000000-0000-0000-0000-000100001977",
				"chemicalName": "beta-cryptoxanthin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057591",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4435,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.264282598308342",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002106",
				"chemicalId": "00000000-0000-0000-0000-000100002106",
				"chemicalName": "oleoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037529",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3119,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.530472970159415",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002107",
				"chemicalId": "00000000-0000-0000-0000-000100002107",
				"chemicalName": "palmitoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000037506",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3103,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.506235237577221",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002227",
				"chemicalId": "00000000-0000-0000-0000-000100002227",
				"chemicalName": "17-(1,5-dimethylhexyl)-10,13-dimethyl-1,2,6,7,8,9,11,12,14,15,16,17-dodecahydrocyclopenta[a]phenanthren-3-one",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038125",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3219,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.727378275935038",
						"qValue": "0.639122253664276",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002254",
				"chemicalId": "00000000-0000-0000-0000-000100002254",
				"chemicalName": "stearoyl ethanolamide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038625",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Endocannabinoid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2163,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.917097058781619",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002259",
				"chemicalId": "00000000-0000-0000-0000-000100002259",
				"chemicalName": "cis-4-decenoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000038178",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1880,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.689650925370808",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002568",
				"chemicalId": "00000000-0000-0000-0000-000100002568",
				"chemicalName": "L-urobilin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040173",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hemoglobin and Porphyrin Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4414,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0496693385467394",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002873",
				"chemicalId": "00000000-0000-0000-0000-000100002873",
				"chemicalName": "lysophosphatidylcholine(24:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000049617",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2649,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.633974896654092",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002875",
				"chemicalId": "00000000-0000-0000-0000-000100002875",
				"chemicalName": "1-(1-enyl-palmitoyl)-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052474",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2833,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.446218971158257",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002989",
				"chemicalId": "00000000-0000-0000-0000-000100002989",
				"chemicalName": "1-oleoyl-2-linoleoyl-rac-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046798",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2926,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.170523346943277",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100002990",
				"chemicalId": "00000000-0000-0000-0000-000100002990",
				"chemicalName": "1-oleoyl-3-linoleoyl-rac-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046799",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2927,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.342184432174015",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100003000",
				"chemicalId": "00000000-0000-0000-0000-000100003000",
				"chemicalName": "1-palmitoylplasmenylethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039270",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2834,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.510438777681522",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100003001",
				"chemicalId": "00000000-0000-0000-0000-000100003001",
				"chemicalName": "1-stearoylplasmenylethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000039271",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2838,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.336253929141553",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100003151",
				"chemicalId": "00000000-0000-0000-0000-000100003151",
				"chemicalName": "linoleoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000046223",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1891,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.708207659504884",
						"qValue": "0.62922012066872",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100004054",
				"chemicalId": "00000000-0000-0000-0000-000100004054",
				"chemicalName": "heptadecanoyl-L-carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057512",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1865,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.13441989808524",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100004088",
				"chemicalId": "00000000-0000-0000-0000-000100004088",
				"chemicalName": "retinal (all trans; 11-cis)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000040807",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4437,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.238592508997894",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100004227",
				"chemicalId": "00000000-0000-0000-0000-000100004227",
				"chemicalName": "DL-2-Aminooctanoic acid",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000043343",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid, Amino",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1755,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.139270122191276",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100004328",
				"chemicalId": "00000000-0000-0000-0000-000100004328",
				"chemicalName": "myristoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042463",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3113,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.693579789017145",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100004329",
				"chemicalId": "00000000-0000-0000-0000-000100004329",
				"chemicalName": "palmitoleoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000042459",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3117,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.56474449551892",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005350",
				"chemicalId": "00000000-0000-0000-0000-000100005350",
				"chemicalName": "1-linolenoylglycerophosphocholine (18:3n3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000045951",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2618,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.378394914970767",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005372",
				"chemicalId": "00000000-0000-0000-0000-000100005372",
				"chemicalName": "1-oleoylplasmenylethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000044621",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysoplasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2836,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.572264354298154",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005985",
				"chemicalId": "00000000-0000-0000-0000-000100005985",
				"chemicalName": "myristoleoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047154",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3114,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.406569885817079",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005986",
				"chemicalId": "00000000-0000-0000-0000-000100005986",
				"chemicalName": "nervonoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047153",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3129,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.356323141171504",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005996",
				"chemicalId": "00000000-0000-0000-0000-000100005996",
				"chemicalName": "octadecenedioyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061865",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1918,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.585031729241639",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005998",
				"chemicalId": "00000000-0000-0000-0000-000100005998",
				"chemicalName": "octadecanedioyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061867",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Dicarboxylate)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1917,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.743250815638337",
						"qValue": "0.64259470217517",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100005999",
				"chemicalId": "00000000-0000-0000-0000-000100005999",
				"chemicalName": "7-hydroxycholesterol (alpha or beta)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000047890",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sterol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3241,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.957522828148687",
						"qValue": "0.683194104095321",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006051",
				"chemicalId": "00000000-0000-0000-0000-000100006051",
				"chemicalName": "myristoleoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048182",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1884,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.818788084113197",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006290",
				"chemicalId": "00000000-0000-0000-0000-000100006290",
				"chemicalName": "C20 Sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048490",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3121,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.402156437675962",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006292",
				"chemicalId": "00000000-0000-0000-0000-000100006292",
				"chemicalName": "20:1 SM  (11-eicosenoic acid)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048491",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3122,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.27756339276455",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006293",
				"chemicalId": "00000000-0000-0000-0000-000100006293",
				"chemicalName": "20:2 SM  (11,14 eicosadienoic acid)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057481",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3123,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.998711431909255",
						"qValue": "0.692460331449497",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006294",
				"chemicalId": "00000000-0000-0000-0000-000100006294",
				"chemicalName": "22:0 SM",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048492",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3106,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.390114207084493",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006295",
				"chemicalId": "00000000-0000-0000-0000-000100006295",
				"chemicalName": "22:1 SM",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000048493",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3126,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.44427480396614",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006296",
				"chemicalId": "00000000-0000-0000-0000-000100006296",
				"chemicalName": "22:2 SM  (13,16 docosadienoic acid)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057477",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3127,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.460936711098288",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006298",
				"chemicalId": "00000000-0000-0000-0000-000100006298",
				"chemicalName": "24:0 SM",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057330",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3108,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.447058299533331",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100006314",
				"chemicalId": "00000000-0000-0000-0000-000100006314",
				"chemicalName": "C15 sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052433",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3115,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.463473153069195",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008903",
				"chemicalId": "00000000-0000-0000-0000-000100008903",
				"chemicalName": "1,2-dilinoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052603",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2359,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.571245049293112",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008904",
				"chemicalId": "00000000-0000-0000-0000-000100008904",
				"chemicalName": "1-stearoyl-2-oleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052438",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2322,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.435721566052019",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008914",
				"chemicalId": "00000000-0000-0000-0000-000100008914",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052462",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2297,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.623431898305596",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008915",
				"chemicalId": "00000000-0000-0000-0000-000100008915",
				"chemicalName": "1-palmitoyl-2-docosahexaenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052610",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2303,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.575931503205725",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008916",
				"chemicalId": "00000000-0000-0000-0000-000100008916",
				"chemicalName": "1-stearoyl-2-docosahexaenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052611",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2339,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.472952580087512",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008919",
				"chemicalId": "00000000-0000-0000-0000-000100008919",
				"chemicalName": "1-(1Z-octadecenyl)-2-oleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052614",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2809,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.236311169291881",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008920",
				"chemicalId": "00000000-0000-0000-0000-000100008920",
				"chemicalName": "heptadecanoyl sphingomyelin",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052615",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3118,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.410688857679603",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008921",
				"chemicalId": "00000000-0000-0000-0000-000100008921",
				"chemicalName": "1-palmitoyl-2-stearoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052616",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2281,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.340377071987198",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008954",
				"chemicalId": "00000000-0000-0000-0000-000100008954",
				"chemicalName": "SM(d18:0/16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052434",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3099,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.423297277653777",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008955",
				"chemicalId": "00000000-0000-0000-0000-000100008955",
				"chemicalName": "23:0 SM",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052436",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3107,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.472566637172608",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008956",
				"chemicalId": "00000000-0000-0000-0000-000100008956",
				"chemicalName": "SM(d18:2/23:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052435",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3128,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.366901449881099",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008957",
				"chemicalId": "00000000-0000-0000-0000-000100008957",
				"chemicalName": "SM(d18:2/24:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3130,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.435250862238744",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008976",
				"chemicalId": "00000000-0000-0000-0000-000100008976",
				"chemicalName": "1-stearoyl-2-linoleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052446",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2429,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.441340556576428",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008977",
				"chemicalId": "00000000-0000-0000-0000-000100008977",
				"chemicalName": "1-stearoyl-2-arachidonoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052447",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2438,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.56934203071604",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008980",
				"chemicalId": "00000000-0000-0000-0000-000100008980",
				"chemicalName": "1-stearoyl-2-linoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052452",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2325,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.404940346110574",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008984",
				"chemicalId": "00000000-0000-0000-0000-000100008984",
				"chemicalName": "1-palmitoyl-2-palmitoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052470",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2279,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.788294782070745",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008985",
				"chemicalId": "00000000-0000-0000-0000-000100008985",
				"chemicalName": "1-palmitoyl-2-dihomo-linolenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052454",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2293,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.44201236721832",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008990",
				"chemicalId": "00000000-0000-0000-0000-000100008990",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052464",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2416,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.653324425349756",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008991",
				"chemicalId": "00000000-0000-0000-0000-000100008991",
				"chemicalName": "1-palmitoyl-2-docosahexaenoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052465",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2418,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.583738094658278",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008992",
				"chemicalId": "00000000-0000-0000-0000-000100008992",
				"chemicalName": "1-stearoyl-2-docosahexaenoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052466",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2442,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.424453483477698",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008993",
				"chemicalId": "00000000-0000-0000-0000-000100008993",
				"chemicalName": "1-palmitoyl-2-arachidonoyl-sn-glycero-3-phosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052467",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2539,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.403954281114598",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008994",
				"chemicalId": "00000000-0000-0000-0000-000100008994",
				"chemicalName": "1-stearoyl-2-linoleoyl-sn-glycero-3-phosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052468",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2543,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.79639004082366",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008998",
				"chemicalId": "00000000-0000-0000-0000-000100008998",
				"chemicalName": "gamma-tocopherol/beta-tocopherol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052473",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Tocopherol Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4382,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.477319418103942",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100008999",
				"chemicalId": "00000000-0000-0000-0000-000100008999",
				"chemicalName": "1-(1-enyl-stearoyl)-2-eicsoatetraenoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052475",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2818,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.577098312873333",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009002",
				"chemicalId": "00000000-0000-0000-0000-000100009002",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-arachidonoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052673",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2804,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.322904123176387",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009005",
				"chemicalId": "00000000-0000-0000-0000-000100009005",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-oleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052477",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2800,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.794601709581671",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009014",
				"chemicalId": "00000000-0000-0000-0000-000100009014",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052689",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2813,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.451228686034636",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009025",
				"chemicalId": "00000000-0000-0000-0000-000100009025",
				"chemicalName": "21:0 SM",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052495",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3124,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.405337431921126",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009026",
				"chemicalId": "00000000-0000-0000-0000-000100009026",
				"chemicalName": "SM(d18:0/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057331",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3100,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.377867696642983",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009027",
				"chemicalId": "00000000-0000-0000-0000-000100009027",
				"chemicalName": "SM(d18:0/18:0, d19:0/17:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057473",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3101,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.391329777495641",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009028",
				"chemicalId": "00000000-0000-0000-0000-000100009028",
				"chemicalName": "N-palmitoyl-dihydroceramide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052604",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydroceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3009,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.404569675080862",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009030",
				"chemicalId": "00000000-0000-0000-0000-000100009030",
				"chemicalName": "C16 lactosyl ceramide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053010",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lactosylceramides (LCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3087,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.522525872691981",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009038",
				"chemicalId": "00000000-0000-0000-0000-000100009038",
				"chemicalName": "SM(d18:0/14:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057365",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3098,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.657495498675303",
						"qValue": "0.624992633142025",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009052",
				"chemicalId": "00000000-0000-0000-0000-000100009052",
				"chemicalName": "1-palmitoyl-2-linoleoyl-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052633",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2906,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.226556590045481",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009055",
				"chemicalId": "00000000-0000-0000-0000-000100009055",
				"chemicalName": "1-palmitoyl-3-linoleoyl-sn-glycerol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052634",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2907,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.300088873063",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009066",
				"chemicalId": "00000000-0000-0000-0000-000100009066",
				"chemicalName": "1-palmitoyl-2-oleoyl-sn-glycero-3-phosphoinositol",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052669",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2534,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.125420631288279",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009069",
				"chemicalId": "00000000-0000-0000-0000-000100009069",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-linoleoyl-sn-glcyero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052677",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2801,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.307760018206313",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009075",
				"chemicalId": "00000000-0000-0000-0000-000100009075",
				"chemicalName": "1-palmitoleoyl-2-linoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052683",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2309,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.160710460465994",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009076",
				"chemicalId": "00000000-0000-0000-0000-000100009076",
				"chemicalName": "1-palmitoyl-2-linolenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052684",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2288,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.853809843007968",
						"qValue": "0.657626917466409",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009078",
				"chemicalId": "00000000-0000-0000-0000-000100009078",
				"chemicalName": "1-oleoyl-2-linoleoyl-sn-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052687",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2444,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.313423983007849",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009130",
				"chemicalId": "00000000-0000-0000-0000-000100009130",
				"chemicalName": "1-oleoyl-2-docosahexaenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052697",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2358,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.665901420851348",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009131",
				"chemicalId": "00000000-0000-0000-0000-000100009131",
				"chemicalName": "1-linoleoyl-2-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052710",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2365,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.647582686613287",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009139",
				"chemicalId": "00000000-0000-0000-0000-000100009139",
				"chemicalName": "1-myristoyl-2-arachidonoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053195",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2266,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.86066445245846",
						"qValue": "0.659266617328281",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009160",
				"chemicalId": "00000000-0000-0000-0000-000100009160",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-palmitoleoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052713",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2803,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.257589708158862",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009162",
				"chemicalId": "00000000-0000-0000-0000-000100009162",
				"chemicalName": "1-(1-enyl-palmitoyl)-2-palmitoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052716",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2802,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.339033038006607",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009181",
				"chemicalId": "00000000-0000-0000-0000-000100009181",
				"chemicalName": "1-stearoyl-2-oleoyl-glycero-3-phospho-(1'-myo-inositol)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052726",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylinositol (PI)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2542,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.300679920783234",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009204",
				"chemicalId": "00000000-0000-0000-0000-000100009204",
				"chemicalName": "1,2-dipalmitoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057341",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2404,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.531711876092887",
						"qValue": "0.61376715407899",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009217",
				"chemicalId": "00000000-0000-0000-0000-000100009217",
				"chemicalName": "1,2-dilinoleoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053174",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2452,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.00838167044840378",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009219",
				"chemicalId": "00000000-0000-0000-0000-000100009219",
				"chemicalName": "1-oleoyl-2-arachidonoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000055041",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2447,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.889207231896891",
						"qValue": "0.667876987790724",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009220",
				"chemicalId": "00000000-0000-0000-0000-000100009220",
				"chemicalName": "1-oleoyl-2-docosahexaenoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053209",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2451,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.83368052798039",
						"qValue": "0.650829647970713",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009222",
				"chemicalId": "00000000-0000-0000-0000-000100009222",
				"chemicalName": "1-linoleoyl-2-arachidonoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053189",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylethanolamine (PE)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2454,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.517138013908972",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009225",
				"chemicalId": "00000000-0000-0000-0000-000100009225",
				"chemicalName": "1-(1-enyl-stearoyl)-2-linoleoyl-glycero-3-phosphoethanolamine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052748",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Plasmalogen",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2810,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.483318863150279",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009233",
				"chemicalId": "00000000-0000-0000-0000-000100009233",
				"chemicalName": "palmitoylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000052944",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1943,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.476011623100847",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009272",
				"chemicalId": "00000000-0000-0000-0000-000100009272",
				"chemicalName": "glycosyl C16 Ceramide",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053013",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3057,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.513248539775445",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009331",
				"chemicalId": "00000000-0000-0000-0000-000100009331",
				"chemicalName": "oleoylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053260",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1944,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.29909673193834",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009332",
				"chemicalId": "00000000-0000-0000-0000-000100009332",
				"chemicalName": "arachidonoylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053261",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1952,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.381694276098874",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009343",
				"chemicalId": "00000000-0000-0000-0000-000100009343",
				"chemicalName": "1-linoleoyl-2-linolenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053176",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2360,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.297108750026185",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100009345",
				"chemicalId": "00000000-0000-0000-0000-000100009345",
				"chemicalName": "1-palmitoleoyl-2-linolenoyl-sn-glycero-3-phosphocholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000053180",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Phosphatidylcholine (PC)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2310,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.171839915055402",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010916",
				"chemicalId": "00000000-0000-0000-0000-000100010916",
				"chemicalName": "palmitoyl-oleoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054943",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2904,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.179221463900862",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010917",
				"chemicalId": "00000000-0000-0000-0000-000100010917",
				"chemicalName": "palmitoyl-oleoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054942",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2905,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.115628003571154",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010918",
				"chemicalId": "00000000-0000-0000-0000-000100010918",
				"chemicalName": "oleoyl-oleoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054945",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2923,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0657401404932671",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010919",
				"chemicalId": "00000000-0000-0000-0000-000100010919",
				"chemicalName": "oleoyl-oleoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054946",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2924,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0722465192360859",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010922",
				"chemicalId": "00000000-0000-0000-0000-000100010922",
				"chemicalName": "linoleoyl-arachidonoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054955",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2941,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.110012109114298",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010923",
				"chemicalId": "00000000-0000-0000-0000-000100010923",
				"chemicalName": "linoleoyl-arachidonoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054956",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2942,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.419855188454025",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010924",
				"chemicalId": "00000000-0000-0000-0000-000100010924",
				"chemicalName": "palmitoyl-arachidonoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054957",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2913,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0588898638548311",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010925",
				"chemicalId": "00000000-0000-0000-0000-000100010925",
				"chemicalName": "palmitoyl-arachidonoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054958",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2914,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.274895018703419",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010926",
				"chemicalId": "00000000-0000-0000-0000-000100010926",
				"chemicalName": "linoleoyl-linolenoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054963",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2933,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.245063472138265",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010927",
				"chemicalId": "00000000-0000-0000-0000-000100010927",
				"chemicalName": "linoleoyl-linolenoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054964",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2934,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.301137498533298",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010929",
				"chemicalId": "00000000-0000-0000-0000-000100010929",
				"chemicalName": "linoleoyl-docosahexaenoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054950",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2946,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.693531484133991",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010930",
				"chemicalId": "00000000-0000-0000-0000-000100010930",
				"chemicalName": "palmitoleoyl-linoleoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054967",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2911,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.448997057377167",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010936",
				"chemicalId": "00000000-0000-0000-0000-000100010936",
				"chemicalName": "oleoyl-arachidonoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054960",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2939,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.246449238224958",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010937",
				"chemicalId": "00000000-0000-0000-0000-000100010937",
				"chemicalName": "oleoyl-arachidonoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054961",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2940,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.20481188772686",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010939",
				"chemicalId": "00000000-0000-0000-0000-000100010939",
				"chemicalName": "palmitoyl-linolenoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054965",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2908,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.687357705481877",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010940",
				"chemicalId": "00000000-0000-0000-0000-000100010940",
				"chemicalName": "diacylglycerol (16:1/18:2 [2], 16:0/18:3 [1])",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054966",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2889,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.25628022307266",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010941",
				"chemicalId": "00000000-0000-0000-0000-000100010941",
				"chemicalName": "linoleoyl-linoleoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054968",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2931,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.479955009894392",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010942",
				"chemicalId": "00000000-0000-0000-0000-000100010942",
				"chemicalName": "linoleoyl-linoleoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054969",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2932,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0775922241902946",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010944",
				"chemicalId": "00000000-0000-0000-0000-000100010944",
				"chemicalName": "oleoyl-linolenoyl-glycerol [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000054970",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2929,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.732574396222531",
						"qValue": "0.639899334754646",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100010949",
				"chemicalId": "00000000-0000-0000-0000-000100010949",
				"chemicalName": "stearoyl-arachidonoyl-glycerol [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057450",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2937,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.82892067324615",
						"qValue": "0.650411964357869",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015609",
				"chemicalId": "00000000-0000-0000-0000-000100015609",
				"chemicalName": "N-palmitoyl-sphingadienine (d18:2/16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057416",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3031,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.920321582827688",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015620",
				"chemicalId": "00000000-0000-0000-0000-000100015620",
				"chemicalName": "lactosyl-N-nervonoyl-sphingosine (d18:1/24:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057370",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lactosylceramides (LCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3091,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.393841062299826",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015623",
				"chemicalId": "00000000-0000-0000-0000-000100015623",
				"chemicalName": "lactosyl-N-behenoyl-sphingosine (d18:1/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057422",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lactosylceramides (LCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3090,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.764297458899941",
						"qValue": "0.643493729725815",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015624",
				"chemicalId": "00000000-0000-0000-0000-000100015624",
				"chemicalName": "N-behenoyl-sphingadienine (d18:2/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057372",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3033,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.26162375718911",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015625",
				"chemicalId": "00000000-0000-0000-0000-000100015625",
				"chemicalName": "glycosyl-N-behenoyl-sphingadienine (d18:2/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057421",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3068,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.941719843218644",
						"qValue": "0.682964228594317",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015632",
				"chemicalId": "00000000-0000-0000-0000-000100015632",
				"chemicalName": "N-stearoyl-sphingadienine (d18:2/18:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057417",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3032,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.858687549445614",
						"qValue": "0.658656974254217",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015643",
				"chemicalId": "00000000-0000-0000-0000-000100015643",
				"chemicalName": "sphingadienine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057426",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingolipid Synthesis",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3002,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.291384133367482",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015681",
				"chemicalId": "00000000-0000-0000-0000-000100015681",
				"chemicalName": "palmitoleoyl-arachidonoyl-glycerol (16:1/20:4) [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057406",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2915,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.796201239568455",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015683",
				"chemicalId": "00000000-0000-0000-0000-000100015683",
				"chemicalName": "myristoyl-linoleoyl-glycerol (14:0/18:2) [1]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057407",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2898,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.616547491262735",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015684",
				"chemicalId": "00000000-0000-0000-0000-000100015684",
				"chemicalName": "myristoyl-linoleoyl-glycerol (14:0/18:2) [2]",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057408",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Diacylglycerol",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2899,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.79905063907752",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015723",
				"chemicalId": "00000000-0000-0000-0000-000100015723",
				"chemicalName": "hexadecasphingosine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057428",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingosines",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3167,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.755379027384211",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015727",
				"chemicalId": "00000000-0000-0000-0000-000100015727",
				"chemicalName": "ceramide (d16:1/24:1, d18:1/22:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057437",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3051,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.203965631876131",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015731",
				"chemicalId": "00000000-0000-0000-0000-000100015731",
				"chemicalName": "N-palmitoyl-heptadecasphingosine (d17:1/16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057430",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3043,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.692262525552861",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015735",
				"chemicalId": "00000000-0000-0000-0000-000100015735",
				"chemicalName": "ceramide (d18:1/14:0, d16:1/16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057432",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3047,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.817421233753003",
						"qValue": "0.647965784149188",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015744",
				"chemicalId": "00000000-0000-0000-0000-000100015744",
				"chemicalName": "ceramide (d18:2/24:1, d18:1/24:2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057443",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3052,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.430852386115263",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015745",
				"chemicalId": "00000000-0000-0000-0000-000100015745",
				"chemicalName": "glycosyl ceramide (d18:2/24:1, d18:1/24:2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057453",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3082,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.698553320254166",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015748",
				"chemicalId": "00000000-0000-0000-0000-000100015748",
				"chemicalName": "glycosyl-N-tricosanoyl-sphingadienine (d18:2/23:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057445",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3069,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.832163650194141",
						"qValue": "0.650829647970713",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015752",
				"chemicalId": "00000000-0000-0000-0000-000100015752",
				"chemicalName": "glycosyl-N-(2-hydroxynervonoyl)-sphingosine (d18:1/24:1(2OH))",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057444",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3064,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.979834371460086",
						"qValue": "0.688142291485093",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015755",
				"chemicalId": "00000000-0000-0000-0000-000100015755",
				"chemicalName": "ceramide (d18:1/20:0, d16:1/22:0, d20:1/18:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057440",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramides",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3050,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.563417437386538",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015760",
				"chemicalId": "00000000-0000-0000-0000-000100015760",
				"chemicalName": "linoleoylcholine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057463",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Choline)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1948,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.906951144258",
						"qValue": "0.674415405312238",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015772",
				"chemicalId": "00000000-0000-0000-0000-000100015772",
				"chemicalName": "1-cerotoyl-GPC (26:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061505",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Lysophospholipid",
				"superPathway": "Lipid",
				"pathwaySortOrder": 2657,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.927306977823619",
						"qValue": "0.678416860083147",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015786",
				"chemicalId": "00000000-0000-0000-0000-000100015786",
				"chemicalName": "sphingomyelin (d18:0/20:0, d16:0/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057476",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Dihydrosphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3102,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.45295880400305",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015787",
				"chemicalId": "00000000-0000-0000-0000-000100015787",
				"chemicalName": "sphingomyelin (d18:1/19:0, d19:1/18:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057475",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3120,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.516368834819796",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015788",
				"chemicalId": "00000000-0000-0000-0000-000100015788",
				"chemicalName": "sphingomyelin (d18:2/18:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057474",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3109,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.490881869990491",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015789",
				"chemicalId": "00000000-0000-0000-0000-000100015789",
				"chemicalName": "sphingomyelin (d18:2/24:2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057479",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3111,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.469767835866069",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015790",
				"chemicalId": "00000000-0000-0000-0000-000100015790",
				"chemicalName": "sphingomyelin (d18:2/21:0, d16:2/23:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057480",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3125,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.563349516282357",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015791",
				"chemicalId": "00000000-0000-0000-0000-000100015791",
				"chemicalName": "sphingomyelin (d18:2/23:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057482",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3110,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.423234947607684",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015792",
				"chemicalId": "00000000-0000-0000-0000-000100015792",
				"chemicalName": "sphingomyelin (d18:1/25:0, d19:0/24:1, d20:1/23:0, d19:1/24:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057478",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3131,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.308339941671406",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015793",
				"chemicalId": "00000000-0000-0000-0000-000100015793",
				"chemicalName": "sphingomyelin (d17:2/16:0, d18:2/15:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057483",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3116,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.640675637709409",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015831",
				"chemicalId": "00000000-0000-0000-0000-000100015831",
				"chemicalName": "linolenoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057511",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1892,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.673021735593666",
						"qValue": "0.626599897467349",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015832",
				"chemicalId": "00000000-0000-0000-0000-000100015832",
				"chemicalName": "behenoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057514",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1868,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.91903092429652",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015833",
				"chemicalId": "00000000-0000-0000-0000-000100015833",
				"chemicalName": "arachidoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057513",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1867,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.919008774489484",
						"qValue": "0.677300099532874",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015834",
				"chemicalId": "00000000-0000-0000-0000-000100015834",
				"chemicalName": "lignoceroylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057515",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1869,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.751794552241262",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015835",
				"chemicalId": "00000000-0000-0000-0000-000100015835",
				"chemicalName": "cerotoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057516",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Long Chain Saturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1870,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.648686105268582",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015836",
				"chemicalId": "00000000-0000-0000-0000-000100015836",
				"chemicalName": "ximenoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057517",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1890,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.0709486369785621",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015837",
				"chemicalId": "00000000-0000-0000-0000-000100015837",
				"chemicalName": "arachidonoylcarnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057518",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1894,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.517129332381786",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015838",
				"chemicalId": "00000000-0000-0000-0000-000100015838",
				"chemicalName": "eicosenoylcarnitine (C20:1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057519",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1887,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.804905196709345",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015839",
				"chemicalId": "00000000-0000-0000-0000-000100015839",
				"chemicalName": "dihomo-linoleoylcarnitine (C20:2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057520",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1893,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.792448196836832",
						"qValue": "0.645196807299381",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015840",
				"chemicalId": "00000000-0000-0000-0000-000100015840",
				"chemicalName": "dihomo-linolenoylcarnitine (20:3n3 or 6)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057521",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Polyunsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1895,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.25511492205808",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015882",
				"chemicalId": "00000000-0000-0000-0000-000100015882",
				"chemicalName": "glycosyl ceramide (d18:1/20:0, d16:1/22:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057595",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Hexosylceramides (HCER)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3078,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.372165352909838",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015966",
				"chemicalId": "00000000-0000-0000-0000-000100015966",
				"chemicalName": "carotene diol (1)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057635",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4431,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.561353543215431",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015967",
				"chemicalId": "00000000-0000-0000-0000-000100015967",
				"chemicalName": "carotene diol (2)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057636",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4432,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.768598939404771",
						"qValue": "0.644254381913934",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100015968",
				"chemicalId": "00000000-0000-0000-0000-000100015968",
				"chemicalName": "carotene diol (3)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000057637",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Vitamin A Metabolism",
				"superPathway": "Cofactors and Vitamins",
				"pathwaySortOrder": 4433,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.645805705045345",
						"qValue": "0.623796999928131",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100016069",
				"chemicalId": "00000000-0000-0000-0000-000100016069",
				"chemicalName": "cis-5-Dodecenoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000061769",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1882,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.85095929029615",
						"qValue": "0.657221370159875",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100020276",
				"chemicalId": "00000000-0000-0000-0000-000100020276",
				"chemicalName": "sphingomyelin (d17:1/14:0, d16:1/15:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062152",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3112,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.477385145164927",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021000",
				"chemicalId": "00000000-0000-0000-0000-000100021000",
				"chemicalName": "hydroxypalmitoyl sphingomyelin (d18:1/16:0(OH))",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062851",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Sphingomyelins",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3104,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.209956451425193",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021131",
				"chemicalId": "00000000-0000-0000-0000-000100021131",
				"chemicalName": "10-undecenoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063613",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1881,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.186168441731536",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021136",
				"chemicalId": "00000000-0000-0000-0000-000100021136",
				"chemicalName": "3-decenoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063361",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Monounsaturated)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1879,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.807600724750876",
						"qValue": "0.646355159160192",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021138",
				"chemicalId": "00000000-0000-0000-0000-000100021138",
				"chemicalName": "4-methylnonanoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063612",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Medium Chain)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1858,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.183516443100286",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021141",
				"chemicalId": "00000000-0000-0000-0000-000100021141",
				"chemicalName": "3-hydroxydecanoyl carnitine",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000063358",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Fatty Acid Metabolism (Acyl Carnitine, Hydroxy)",
				"superPathway": "Lipid",
				"pathwaySortOrder": 1927,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.332807672886184",
						"qValue": "0.613136873397655",
						"foldChange": ""
					}
				]
			},
			{
				"metaboliteId": "00000000-402f-0000-0000-000100021162",
				"chemicalId": "00000000-0000-0000-0000-000100021162",
				"chemicalName": "palmitoyl-sphingosine-phosphoethanolamine (d18:1/16:0)",
				"plotName": null,
				"compoundId": "00000000-0000-0000-0000-000000062984",
				"compoundType": "NAMED",
				"hmdb": null,
				"inChiKey": null,
				"kegg": null,
				"mass": 0,
				"pathwayType": null,
				"subPathway": "Ceramide PEs",
				"superPathway": "Lipid",
				"pathwaySortOrder": 3134,
				"libId": null,
				"platform": null,
				"pubChem": null,
				"cas": null,
				"statsDetails": [
					{
						"statsId": "00000000-0000-0000-0000-000000000001",
						"pValue": "0.746539638245822",
						"qValue": "0.642665643994081",
						"foldChange": ""
					}
				]
			}
		],
		"stats": [
			{
				"statsId": "00000000-0000-0000-0000-000000000001",
				"test": "Welch two-sample t-test",
				"comparison": "male_VS_female",
				"reportName": "female//male",
				"foldChange": "Batch-norm Imputed Data_female/Batch-norm Imputed Data_male",
				"testSequence": 1,
				"compSequence": 1,
				"enrichment": null,
				"customerName": "",
				"customerIndex": 0,
				"customerVisible": true
			}
		],
		"customerVisible": false,
		"publishDate": "2021-10-14T17:03:59.0659334Z",
		"lastPublishDate": "2021-10-14T17:05:01.1778694Z",
		"lastUpdateDate": "2021-10-14T17:05:01.1778694Z"
	}
];

export default data;