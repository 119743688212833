export default [
	{
		"projectId": "99fd4f47-c441-48ed-af75-a7e6fb7dcb02", 
		"projectCode": "PROJ-1019", 
		"sampleSets": [
			{
				"sampleSetId": "707f174c-7e59-41f4-8f33-fc4aaad573d1",
				"sampleSetName": "Phoenix_Beta_Project1-Plasma"
			}
		]
	},
	{
    "projectId": "0000168b-0000-0000-0000-000000000000",
		"projectCode": "COSU-0201-15VWBL", 
		"sampleSets": [
			{
				"sampleSetId": "00003aa3-0000-0000-0000-000000000000",
				"sampleSetName": "Effect of diet on cancer survivors, serum"
			},
			{
				"sampleSetId": "00003aa4-0000-0000-0000-000000000000",
				"sampleSetName": "Effect of diet on cancer survivors, food"
			}
		]
	}
]