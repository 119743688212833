import { call, put, takeLatest } from 'redux-saga/effects';

export const createRequestSagas = (
	sagaName,
	requestFunc,
	requestAction,
	successAction,
	failAction,
	preProcessor,
	postProcessor
) => {
	function* saga(action) {
		const response = yield call(requestFunc, preProcessor ? preProcessor(action.payload) : action.payload);
		let { error, status, payload } = response;
		if (postProcessor) payload = yield call(postProcessor, payload);
		if (status === 200 || !error) yield put(successAction(payload));
		else yield put(failAction({ error, status }));
	}

	function* sagas() {
		yield takeLatest(requestAction, saga);
	}

	return {
		[`${sagaName}Saga`]: saga,
		[`${sagaName}Sagas`]: sagas,
	};
};