import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { HGT } from '@/views/routes';
import { setProjectData } from '../redux/reducers/project.reducer';

export const SearchResult = ({
	className,
	data,
}) => {
	const dispatch = useDispatch();
	const [clicked, setClicked] = useState(false);
	if (!data) return;
	const { projectId, projectCode, sampleSets } = data;

	const onClick = useCallback(() => {
		if (!projectId || !sampleSets || !sampleSets.length)
			return;

		//dispatch(setProjectData(data));
		setClicked(true);
	}, [dispatch, projectId, sampleSets, history]);

	if (!projectId || (sampleSets.length === 0)) {
		return null;
	}
	return (
		<span className={className} onClick={onClick}>
			{projectCode}
			{clicked && <Redirect to={`/home/${HGT}/${projectCode}/${sampleSets[0].sampleSetId}`}/>}
		</span>
	)
}