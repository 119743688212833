import { createRequestSelectors } from "../helpers/selector.helpers";

export const {
	selectSearchState,

	selectResults,
	selectResultsComplete,
	selectResultsData,
	selectResultsIsLoading,
	selectResultsHasErrored,
} = createRequestSelectors("search", [
	"results"
]);
