import React, { useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import Icon from "@/components/controls/Icon.jsx";
import Input from "@/components/controls/Input.jsx";
import { setTitle, setChangesSaved } from "../../../redux/reducers/editor.reducer";
import { selectTitle } from "../../../redux/selectors/editor.selector";
import { Stack, Typography } from "@mui/material";
import * as styles from '../editor.module.scss'

export const TitleTextField = ({date, selectedSample}) => {

	const titleBox = useRef(null);
	const dispatch = useDispatch();
	const title = useSelector(selectTitle);

	const onTitleChange = useCallback((e) => {
		dispatch(setTitle(e.target.value));
		dispatch(setChangesSaved(false));
	}, [dispatch]);

	const onTitleBlur = useCallback(() => {
		if (title === "") dispatch(setTitle(selectedSample.name));
	}, [dispatch, title]);

	const onPencilClick = useCallback(() => {
		if (titleBox) titleBox.current.focus();
	}, []);

	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between" id="project-title-container" className={styles['project-title-container']}>
			<Stack spacing={2} alignItems="center" direction="row" className={styles["project-title-ui"]}>
				<Input inputRef={titleBox} value={title} className={styles["project-title"]} wrapperClass={styles["flex"]} focusOnSelect placeholder="custom title" onChange={onTitleChange} onBlur={onTitleBlur} data-testid="input" />
				<div className={styles["edit-title-icon"]} onClick={onPencilClick} data-testid="pencil">
					<Icon icon="pencil" size="large" />
				</div>
			</Stack>
			<Typography color="grey.main" variant="caption" className={styles["last-updated"]} data-testid="date">{date}</Typography>
		</Stack>
	)
}