import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "@/components/buttons/Button.jsx";
import Icon from "@/components/controls/Icon.jsx";
import { Switch } from "../components/controls/Switch.jsx";
import Table from "../components/controls/Table/Table.jsx";
import { STAT_ACTIONS } from "../redux/actionTypes.js";
import { selectStats } from "../redux/selectors/stats.selector";
import { dividendDivisorSwitch, formatDisplayString } from "@/utils/StringUtils.js";
import "./StatsEditorTable.scss";
import PropTypes from "prop-types";

export default function StatsEditorTable({ showHidden, onFieldAction, sortedStats }) {
	const statsDict = useSelector(selectStats);

	const SwitchCell = useCallback(({ row }) => {
		const onChange = useCallback((row) => () =>
			onFieldAction(STAT_ACTIONS.VISIBILITY, {
				statsId: row.original.statsId,
			}), []);

		return (
			<Switch
				onChange={onChange(row)}
				checked={row.original.customerVisible}
			/>
		)
	}, [onFieldAction]);

	const onSwap = useCallback((statsId) => () => {
		onFieldAction(STAT_ACTIONS.SWAP, { statsId });
	}, [onFieldAction]);

	const onUp = useCallback((statsId) => () => {
		onFieldAction(STAT_ACTIONS.MOVE_UP, { statsId });
	}, [onFieldAction]);

	const onDown = useCallback((statsId) => () => {
		onFieldAction(STAT_ACTIONS.MOVE_DN, { statsId });
	}, [onFieldAction]);

	const columns = useMemo(
		() => [
			{
				header: "Include",
				id: "sort",
				size: 20,
				cell: SwitchCell,
			},
			{
				header: "Test",
				id: "testCustomName",
				accessorKey: "compSequence",
				size: 60,
				cell: ({ row }) => (
					<div>
						TEST
						{row.original.compSequence}
					</div>
				),
			},
			{
				header: "Test Name",
				accessorKey: "test",
				id: "test",
			},

			{
				header: "Comparison",
				accessorKey: "comparison",
				id: "comparison",
			},
			{
				header: "Fold change",
				accessorKey: "foldChange",
				id: "foldChange",
				size: 400,
				cell: ({ row }) => {
					const { statsId, swapped, customerVisible } =
						row.original;

					const foldChangeFormated = formatDisplayString(
						row.original.foldChange
					);
					let folds = foldChangeFormated
						? foldChangeFormated.split("/")
						: [null, null],
						fold0 = swapped ? folds[1] : folds[0],
						fold1 = swapped ? folds[0] : folds[1],
						label = foldChangeFormated ? `(${fold0})/(${fold1})` : "";
					return (
						<div className="fold-change">
							<span title={fold0}>{fold0}</span>
							<span className="second-fold" title={fold1}>
								{fold1}
							</span>
							<span title="Swap">
								<Button
									onClick={onSwap(row.original.statsId)}
									disabled={!customerVisible}
									color="tertiary"
									alt2={true}
								>
									<Icon
										icon={`arrow-${swapped ? "right" : "left"}`}
										tableArrow
									/>
									<Icon
										icon={`arrow-${swapped ? "left" : "right"}`}
										tableArrow
									/>
								</Button>
							</span>
							<span title={label}>{label}</span>
						</div>
					);
				},
			},
			{
				header: "Report Name",
				id: "reportName",
				size: 200,
				cell: ({ row }) => {
					let label, title;
					const {
						statsId,
						customerVisible,
						foldChange,
						comparison,
						reportName,
						customerName,
						swapped
					} = row.original;

					const disabled = !customerVisible;
					if (reportName) label = reportName;
					else if (foldChange) label = foldChange;
					else label = comparison;
					title = label;
					label = customerName || label;
					label = label
						? label.replace(/[\(,\)]+/g, "").replace(/[\/\/]+/g, "/")
						: "Stats";
					const onBlur = useCallback((e) => {
						onFieldAction(STAT_ACTIONS.UPDATE_REPORT, {
							statsId,
							customerName: e.target.value,
						});
					}, []);
					label = swapped ? dividendDivisorSwitch(label) : label

					const onFocus = useCallback((e) => {
						e.target.select();
					}, []);
					return <input className="report-name-cell" disabled={disabled} title={title} onBlur={onBlur} onFocus={onFocus} defaultValue={label} />
				},
			},
			{
				header: "Seq.",
				accessorKey: "testSequence",
				size: 40,
				id: "testSequence",
				cell: ({ row }) => {
					let label, title;
					const {
						statsId,
						customerVisible,
						customerSequence,
					} = row.original;

					const disabled = !customerVisible;
					if (customerSequence) label = customerSequence;
					title = label;
					label = customerSequence || label;
					const onBlur = useCallback((e) => {
						let value = e.target.value;
						if (isNaN(value)) return;
						let number = parseInt(value);
						if (number <= 0) number = 1;
						onFieldAction(STAT_ACTIONS.UPDATE_SEQUENCE, {
							statsId,
							customerSequence: number,
						});
					}, []);

					const onFocus = useCallback((e) => {
						e.target.select();
					}, []);
					return (
						<input
							disabled={disabled}
							title={title}
							type="number"
							onBlur={onBlur}
							onFocus={onFocus}
							defaultValue={label}
						/>
					);
				},
			},
			{
				header: "Order",
				size: 30,
				id: "order",
				cell: ({ row }) => {
					const { statsId, customerVisible } = row.original;
					const disabled = !customerVisible;

					return (
						<div className="order-cell">
							<Button
								onClick={onUp(statsId)}
								title="Move up"
								color="tertiary"
								disabled={disabled}
								alt2={true}
							>
								<Icon icon="arrow-up" />
							</Button>
							<Button
								onClick={onDown(statsId)}
								title="Move down"
								color="tertiary"
								disabled={disabled}
								alt2={true}
							>
								<Icon icon="arrow-down" />
							</Button>
						</div>
					);
				},
			},
		],
		[onFieldAction]
	);

	let stats = Object.values(statsDict);
	if (!stats.length) return <p>No Stats Available</p>;
	let filteredStats = stats.filter(
		(stat) => showHidden || stat.customerVisible
	);
	const sortedFilteredStats = sortedStats.map(sortedStatId => filteredStats.find((filteredStat) => filteredStat.statsId === sortedStatId));
	return (
		<div>
			<Table
				columns={columns}
				data={sortedFilteredStats}
				className={"stats-table"}
				tableId={"stats-table"}
				sortable
				storeOpts
				resizable
				virtualize
			/>
		</div>
	);
}

StatsEditorTable.propTypes = {
	onFieldAction: PropTypes.func,
	showHidden: PropTypes.bool,
};

StatsEditorTable.defaultProps = {
	showHidden: true,
};
