import { configureStore } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './reducers'
import rootSaga from './sagas'

const initialState = {}
let store

export function initStore(preloadedState = initialState) {
	const sagaMiddleware = createSagaMiddleware();
	let s = configureStore({
		reducer: rootReducer,
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ thunk: false })
				.concat(sagaMiddleware)
	});
	sagaMiddleware.run(rootSaga)

	return s
}

export const initializeStore = preloadedState => {
	let _store = store ?? initStore(preloadedState)

	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState
		})

		store = undefined
	}

	if (typeof window === undefined) return _store
	if (!store) store = _store

	return _store
}

export function useStore(initialState) {
	const s = useMemo(() => initializeStore(initialState), [initialState])
	return s
}

export function getStore() {
	return store
}