import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setDuplicates } from "../../../redux/reducers/editor.reducer";
import { selectDuplicates, selectReorder } from "../../../redux/selectors/editor.selector";
import Flyout from '../../../components/flyout/Flyout.jsx';
import { Switch } from "../../../components/controls/Switch.jsx";
import TestEditorTable  from '../../../containers/TestEditorTable.jsx';
import * as styles from '../editor.module.scss'

export const ReorderFlyout = ({
	onTestAction,
	sequences,
}) => {

	const dispatch = useDispatch();
	const reorder = useSelector(selectReorder);
	const duplicates = useSelector(selectDuplicates);

	const toggleDuplicates = useCallback(() => {
		dispatch(setDuplicates(!duplicates));
	}, [dispatch, duplicates]);

	return (
		<Flyout isActive={reorder}>
			<div className={styles["reorder-table-header"]}>
				<h3>Reorder Test Sequences</h3>
				<div className={styles["dupe-switcher"]}>
					<div className={styles["column-header"]}>Remove Duplicate<br />%Fill/Mean columns</div>
					<Switch className={styles['dupe-switch']} checked={duplicates} onChange={toggleDuplicates} />
				</div>
			</div>
			<TestEditorTable
				onFieldAction={onTestAction}
				tests={Object.values(sequences)}
			/>
		</Flyout>
	)
}