import React, { useCallback } from "react";
import { flexRender } from "@tanstack/react-table";
import "./Table.scss";

function TableBody({ table, virtualize }) {
	const { rows } = table.getRowModel();

	const rowsToMap = rows;

	const renderRow = useCallback((row) => {
		return (
			<tr key={row.id} style={table.options.meta?.getRowStyles(row)}>
				{row.getVisibleCells().map((cell) => (
					<td
						key={cell.id}
						style={{
							...(cell.column.columnDef?.meta?.getStyles &&
								cell.column.columnDef?.meta?.getStyles(cell)),
						}}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</td>
				))}
			</tr>
		);
	}, [table, virtualize]);

	return (
		<tbody>
			{rowsToMap.map(renderRow)}
		</tbody>
	);
}
export default React.memo(
	TableBody,
	(prevProps, nextProps) => nextProps.isColumnResizing
);
