import { createSelector } from "@reduxjs/toolkit";

const selectStatsState = createSelector(
	state => state,
	state => state.stats
);

export const selectStats = createSelector(
    selectStatsState,
    state => state.dict
);

export const selectTests = createSelector(
    selectStatsState,
    state => state.tests
);