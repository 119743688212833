import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classNamesBinder from "classnames/bind";
import {
	resultsFetch
} from '../redux/reducers/search.reducer.js';
import * as styles from '../pages/editor/editor.module.scss'
import Button from '@/components/buttons/Button.jsx'
import Icon from '@/components/controls/Icon.jsx';
import { Search } from '../containers/Search.jsx';
import { SearchResult } from '../containers/SearchResult.jsx';
import { selectResults } from '../redux/selectors/search.selector.js';
import { Box, Stack, Typography } from '@mui/material';

const classNames = classNamesBinder.bind(styles);

export const SearchHgt = ({ activeSampleSet, onSave, onGenerate, changesSaved }) => {
	const dispatch = useDispatch();

	const { data: results, isLoading: resultIsLoading } = useSelector(selectResults);

	const onFetch = useCallback((query) => {
		dispatch(resultsFetch(query));
	}, [dispatch]);

	return (
		<Box>
			<Box mb={2} style={{ height: "2em" }} className={classNames("header")}>
				<Typography variant="h2" mb={0}>Metabolon Heatmap Generator</Typography>
			</Box>

			<Stack direction="row" justifyContent="space-between" className={classNames("content", "searchbar")}>
				<Search
					className={classNames("search")}
					onSearch={onFetch}
					isLoading={resultIsLoading}
					placeholder="Search by Project ID or Sample Set ID"
					resultComponent={SearchResult}
					results={results}
				/>

				{activeSampleSet &&
					<div>
						<Button onClick={onSave} className={classNames("save")} disabled={changesSaved}>
							Save <Icon icon="save" size="large" />
						</Button>
						<Button onClick={onGenerate} className={classNames("generate")}>
							Generate Heatmap <Icon icon="arrow-right" size="large" />
						</Button>
					</div>
				}
			</Stack>
		</Box>
	);
}