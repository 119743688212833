import mockSampleSets from '../data/data.js'
import mockSearchResults from '../data/mockSearchResults.js';
import mockPropertyMap from '../data/mockPropertyMap.js';
import FileUtils from '@/utils/FileUtils';

import * as config from './env.config.js';
require('whatwg-fetch');
import urljoin from 'url-join';

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";

const mockAPICall = (payload, returnData, ms = 500) => 
	new Promise(resolve => setTimeout(() => { 
		resolve({status: 200, ok: true, payload: returnData});
	}, ms))

const getToken = (key = "id_token") => localStorage.getItem(key) || null;

const getRequestConfig = (method, requestPayload, isJson = true) => {
  const config = {
    method,
		//mode: "no-cors",
    headers: {
      /* 'Authorization': 'Bearer' + ' ' + getToken(), 
      'Accept': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-XSS-Protection': 1 */
    },
  };
  if (requestPayload) {
    if (isJson) {
      config.body = JSON.stringify(requestPayload);
      config.headers['Content-Type'] = 'application/json';
    }
    else config.body = requestPayload;
  }
  return config;
};

export const get = async (endpoint, query="") => {
  try {
    if (Array.isArray(query)) {
      query = query.join('/');
    }
		if (query) endpoint = urljoin(endpoint, query);

    const response = await fetch(endpoint, getRequestConfig(GET, null));
		if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }    
    response.error = false;
		try {
			response.payload = await response.json();
		} catch(e) {// eslint-disable-next-line
			console.error(e.message);
			response.payload = null;
		}
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const post = async (endpoint, requestPayload, isJson) => {  
  try {
    const response = await fetch(endpoint, getRequestConfig(POST, requestPayload, isJson));
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }    
    response.error = false;
    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const put = async (endpoint, requestPayload, isJson) => {
  try {
    const response = await fetch(endpoint, getRequestConfig(PUT, requestPayload, isJson));
    if (response && !response.ok) {
      response.error = await response.json();
      return response;
    }
    response.error = false;
    return response;

  } catch (error) {
    return Promise.reject(error.message);
  }
};


export const postAndDownload = async (endpoint, requestPayload, fileName, type) => {  
  try {
    const response = await fetch(endpoint, getRequestConfig(POST, requestPayload, true));
    if (response && !response.ok) {
      response.error = await response.json()
      return response;
    }    
    response.error = false;

    const blob = await response.blob();
    FileUtils.download(blob, fileName, type)

    return response;
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const searchProjects = async (projectCode="PROJ-1019") => {
  return get(config.API_ENDPOINT_SEARCH_PROJECTS, projectCode);
};
export const searchProjectSampleSets = async (projectCode="PROJ-1019") => {
  return await get(config.API_ENDPOINT_SEARCH_PROJECT_SAMPLESETS, projectCode);
};
export const fetchSampleSet = async ({projectId, sampleSetId}) => {
  return await get(config.API_ENDPOINT_SAMPLESET, [ projectId, sampleSetId ]);
};
export const fetchStats = async ({sampleSetId}) => {
  return await get(config.API_ENDPOINT_GET_STATS, [ sampleSetId ]);
};
export const uploadStats = async ({sampleSetId}) => {
  return await get(config.API_ENDPOINT_PUT_STATS, [ sampleSetId ]);
};
export const fetchMetabolites = async ({sampleSetId}) => {
  return await get(config.API_ENDPOINT_GET_METABOLITES, [ sampleSetId ]);
};
export const fetchSample = async ({sampleSetId}) => {
  return await get(config.API_ENDPOINT_GET_SAMPLE, [ sampleSetId ]);
};

export const generateHeatmap = async (payload) => {
	return await postAndDownload(config.API_ENDPOINT_HEATMAP, payload, 'heatmap.zip', 'application/zip');
}
export const fetchLatestPropertyMap = async ({projectId, sampleSetId}) => {
  return await get(`${config.API_ENDPOINT_PROPMAPS_LATEST}?projectId=${projectId}&sampleSetId=${sampleSetId}`);
};
export const uploadPropertyMap = async (data) => {
	return await post(config.API_ENDPOINT_PROPMAPS_UPLOAD, data);
};

export const mockSearchSampleSets = async (projectCode) => {
	return await mockAPICall(projectCode, mockSearchResults);
};
export const mockFetchSampleSet = async ({projectId, sampleSetId}) => {
	let sampleSet = mockSampleSets.find((set) => set.sampleSetId === sampleSetId);
	return await mockAPICall(urljoin(projectId, sampleSetId), sampleSet);
};
export const mockGenerateHeatmap = async (sampleSet) => {
	return await mockAPICall(sampleSet, "upload merged data SUCCESS");
};
export const mockUploadPropertyMap = async (data) => {
	return await mockAPICall(data, "upload property map SUCCESS");
};
export const mockFetchLatestPropertyMap = async (data) => {
	return await mockAPICall(data, mockPropertyMap[data.sampleSetId]);
};

export const isMock = false;