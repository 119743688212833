export function clone(target) {
	if (!target) return target;
	return JSON.parse(JSON.stringify(target));
}

export function merge(target, source, overwrite=true) {
	return mkr.merge(target, source, overwrite);
}

export function filter(data=[], filters=[]){
	let filteredData = data.concat();
	filters.forEach((filter, n) => {
		filteredData = filteredData.filter(filter);
	});
	return filteredData;
}

export function replaceKey(src, key, newKey){
	if (key in src) {
		src[newKey] = src[key];
		delete src[key];
	}
}

export function setDefault(src, key, value) {
	if (src && !(key in src)) src[key] = value;
}

export function getDefault(src, key, value) {
	if (src && key in src) return src[key];
	return value;
}